import Numeral from '../lib/numeral';
import Vue from 'vue';
import Utils from './Utils';
import moment from 'moment';
import BigNumber from 'bignumber.js';
import COMMON_CONST from "./Const";

Vue.filter('formatUsdAmount', function (value, zeroValue) {
  if (window._.isNil(zeroValue)) {
    zeroValue = '';
  }
  return value ? Numeral(value).format("0,0") : zeroValue;
});

Vue.filter('formatNaturalPart', function (value) {
    return Utils.formatCurrencyAmount(Math.floor(value), 'usd', value >= 0 ? '0' : '');
});

Vue.filter('phoneNumber', function(value) {
  if (value) {
    return value.replace(/(\d{3})(\d{4})(\d{4})/, '$1.$2.$3');
  }
});

Vue.filter( 'uppercase', function (value) {
  if (value === "All") return value;
  if (!!value) return ('' + value).toUpperCase();
  return '';
});

Vue.filter( 'upperFirst', function (value) {
  return window._.upperFirst(value);
});

Vue.filter( 'getWallet', function (value) {
  if(value == 'main') return COMMON_CONST.MAIN_BALANCE;
  if(value == 'airdrop') return COMMON_CONST.DIVIDEND_NORMAL;
  if(value == 'dividend_bonus') return COMMON_CONST.DIVIDEND_BY_ADMIN;
});

Vue.filter( 'uppercaseFirst', function (value) {
  if(value && typeof(value) == 'string'){
    value = value.toLowerCase().split(" ");
    for (var i = 0, x = value.length; i < x; i++) {
      value[i] = value[i][0].toUpperCase() + value[i].substr(1);
    }
    return value.join(" ");
   }else{
    return window._.startCase(value);
   }
});

Vue.filter( 'getPrecision', function (value) {
  return Numeral(value).format(".[00000000]");
});

Vue.filter( 'getPrecisionNoDot', function (value) {
  return Numeral(value).format(".[00000000]").replace('.', '');
});

Vue.filter('naturalPartOfNumber', function (number) {
  return Math.floor(number);
});

Vue.filter('naturalPartOfNumberWithDot', function (number) {
  let value =  Math.floor(number);
  return Numeral(value).format("0,0") + '.';
});

Vue.filter("to0Precision", function (value) {
  return Numeral(value).format("0");
});
Vue.filter("to1Precision", function (value) {
  return Numeral(value).format("0.0");
});
Vue.filter('to2Precision', function (value) {
  return Numeral(value).format("0.00");
});
Vue.filter("to4Precision", function (value) {
  return Numeral(value).format("0.0000");
});
Vue.filter("to6Precision", function (value) {
  return Numeral(value).format("0.000000");
});
Vue.filter("to8Precision", function (value) {
  return Numeral(value).format("0.00000000");
});
Vue.filter('changedPercent', function (value) {
  let pattern = '0.00';
  if (value > 0) {
    pattern = `+${pattern}`;
  }
  return Numeral(value).format(pattern) + "%";
});
Vue.filter('floatToPercent', function (value) {
  return Numeral(value*100).format("0.00") + "%";
});
Vue.filter('changedPercentFee', function (value) {
  return Numeral(value*100).format("0.[0000]") + ' %';
});
Vue.filter('percentWithOneDecimal', function (value) {
  return Numeral(value).format("0.0") + "%";
});
Vue.filter('currencyName', function (value) {
  return Utils.getCurrencyName(value);
});
Vue.filter('fullName', function (value) {
  return window.i18n.t('currency.' + value + '.fullname');
});

Vue.filter('upperCaseFirstLetter', function (value) {
  switch (value.toLowerCase()) {
    case 'usd' :
    case 'ripple' :
      return value.toUpperCase();
    default :
      return value.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
  }
});

Vue.filter('formatCurrencyAmount', function(amount, currency, zeroValue) {
  return Utils.formatCurrencyAmount(amount, currency, zeroValue);
});

Vue.filter('formatCurrencyAmountDecimal', function(amount, numberOfDecimalDigits, zeroValue) {
  return Utils.formatCurrencyAmountDecimal(amount, numberOfDecimalDigits, zeroValue);
});

Vue.filter('formatliquidationPrice', function(amount, currency, zeroValue) {
  return Utils.formatliquidationPrice(amount, currency, zeroValue);
});

Vue.filter('formatMarginNumber', function(amount, numberOfDecimalDigits, zeroValue) {
  return Utils.formatMarginNumber(amount, numberOfDecimalDigits, zeroValue);
});

Vue.filter('formatMarginValue', function(amount) {
  return Utils.formatMarginValue(amount);
});

Vue.filter('formatOrderPrice', function(amount, currency, zeroValue) {
  if (amount) {
    return Utils.formatCurrencyAmount(amount, currency, zeroValue);
  } else {
    return window.i18n.t('order_list.market_price');
  }
});

Vue.filter('create_order_label', function(orderType, tradeType) {
  if (orderType && tradeType) {
    return window.i18n.t('create_order.button_texts.' + tradeType + '.' + orderType);
  } else {
    return '';
  }
});

Vue.filter('dateFormat', function (date) {
  return moment(date, 'YYYY-MM-DD HH:mm:ss').format('YYYY.MM.DD');
});
Vue.filter('dateFormat2', function (date) {
  return moment(date, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD');
});


Vue.filter('timeFormat', function(date) {
  return moment(date, 'YYYY-MM-DD HH:mm:ss').format('HH:mm:ss');
});

Vue.filter('timeFormat2', function(date) {
  return moment(date).format('HH:mm:ss');
});

Vue.filter('dateFormatSupport', function (date) {
  return moment(date, 'YYYY-MM-DD HH:mm:ss').format('YYYY-M-DD');
})

Vue.filter('date', function (date) {
  return moment(date, 'YYYY-MM-DD HH:mm:ss').format('MM.DD');
});

Vue.filter('time', function (date) {
  return moment(date, 'YYYY-MM-DD HH:mm:ss').format('HH:mm:ss');
});

Vue.filter('orderTime', function (timestamp) {
  return moment(timestamp, 'x').format('HH:mm:ss');
});

Vue.filter('orderDateTime', function (timestamp) {
  return moment(timestamp, 'x').format('MM.DD HH:mm:ss');
});

Vue.filter('orderDate', function (timestamp) {
  return moment(timestamp, 'x').format('MM.DD');
});

Vue.filter('timestampToDate', function (timestamp) {
  return moment(timestamp, 'x').format('YYYY-MM-DD HH:mm:ss');
});

Vue.filter('YearMonthDate', function (timestamp) {
  return moment(timestamp, 'x').format('YYYY-MM-DD');
});

Vue.filter('fourNumberId', function (id) {
  if (id != null) {
    let tmpId = "00000000" + id;
    return tmpId.substring(tmpId.length, tmpId.length - 4)
  }
});

Vue.filter('user_type', function (type) {
  switch(type) {
    case 'normal':
      return '일반';
    case 'company':
      return '회사';
    case 'referrer':
      return '추천인';
  }
  return type;
});

Vue.filter('order_status', function (order) {
  if (order.status == 'canceled') {
    return window.i18n.t('common.order_status.canceled');
  }

  // if (order.executed_quantity == order.quantity) {
  if (order.status == 'filled') {
    return window.i18n.t('common.order_status.filled');
  }

  if (order.status == 'pending') {
    return window.i18n.t('common.order_status.pending');
  }
  
  if (order.status == 'new') {
    return window.i18n.t('common.order_status.new');
  }

  if (order.status == 'open') {
    return window.i18n.t('common.order_status.open');
  }

  return window.i18n.t('common.order_status.partial_filled');
});

Vue.filter('account_status', (status) => {
  return window.i18n.t(`account.status.${status}`);
}) 

Vue.filter('order_type', function (order) {
  if (order.type == 'market') {
    return window.i18n.t('common.order_type.market');
  }
  
  if (order.type == 'stop_limit') {
    return window.i18n.t('common.order_type.stop_limit');
  }

  if (order.type == 'stop_market') {
    return window.i18n.t('common.order_type.stop_market');
  }

  if (order.type == 'take_profit_limit') {
    return window.i18n.t('common.order_type.take_profit_limit');
  }

  if (order.type == 'take_profit_market') {
    return window.i18n.t('common.order_type.take_profit_market');
  }

  if (order.type == 'trailing_stop') {
    return window.i18n.t('common.order_type.trailing_stop');
  }

  if (order.type == 'post_only') {
    return window.i18n.t('common.order_type.post_only');
  }

  if (order.type == 'liquidation') {
    return window.i18n.t('common.order_type.liquidation');
  }

  return window.i18n.t('common.order_type.limit');
});

Vue.filter('margin_order_status', function (order) {
  if(!order) return ''

  switch (order.status) {
    case 'canceled':
      return order.note == 'expired' ? window.i18n.t('common.order_status.expired') : window.i18n.t('common.order_status.canceled');
    case 'pending':
      return order.pair_type == 'ifd' ? window.i18n.t('common.order_status.new') : (order.remaining && new BigNumber(order.remaining).comparedTo(order.quantity) === 0
        ? window.i18n.t('common.order_status.new') : window.i18n.t('common.order_status.partial_filled'));
    case 'executed':
      return window.i18n.t('common.order_status.filled');
    case 'stopping':
      return window.i18n.t('common.order_status.untriggered');
    case 'executing':
      return window.i18n.t('common.order_status.partial_filled');
    case 'new':
      return order.pair_type == 'ifd' ? window.i18n.t('common.order_status.pending') : window.i18n.t('common.order_status.new');
    case 'removed':
      return window.i18n.t('common.order_status.removed');
    case '':
      return '';
  }
});

Vue.filter('kyc_status', function (record) {
  if (!record) return

  switch (record.status) {
    case 'pending': 
      return window.i18n.t('kyc.status_pending');
    case 'verified': 
      return window.i18n.t('kyc.status_verified');
    case 'rejected': 
      return window.i18n.t('kyc.status_rejected');
    default:
      return "";
  }
})

Vue.filter('kyc_status_account', function (record) {
  if (!record) return

  switch (record.kyc_status) {
    case 'pending': 
      return window.i18n.t('kyc.status_pending');
    case 'verified': 
      return window.i18n.t('kyc.status_verified');
    case 'rejected': 
      return window.i18n.t('kyc.status_rejected');
    default:
      return window.i18n.t('kyc.status_unverified');
  }
})

Vue.filter('order_note', function (order) {
  if(!order) return '';
  if (order.pair_type == 'ifd' || order.pair_type == 'oco') {
    if (order.reference_id) {
      return window.i18n.t(order.stop_type ? 'margin.stop_limit_id' : 'margin.limit_id', { id: order.reference_id });
    }
    return window.i18n.t(order.stop_type ? 'margin.stop_limit_id' : 'margin.limit_id', { id: order.id });
  }
  return '--';
});

Vue.filter('order_text', function (order) {
  if(!order) return '';
  if (order.note == 'liquidation') {
    return window.i18n.t('margin.liquidation');
  } else if (order.note == 'insurance_liquidation') {
    return window.i18n.t('margin.insurance_liquidation');
  } else if (order.note == 'insurance_funding') {
    return window.i18n.t('margin.insurance_funding');
  }
  return '--';
});

Vue.filter('securityLevel', function (level) {
  switch (level) {
    case 1:
      return window.i18n.t('security_level.email');
    case 2:
      return window.i18n.t('security_level.sms');
    case 3:
      return window.i18n.t('security_level.id');
    case 4:
      return window.i18n.t('security_level.bank');
    case 5:
      return window.i18n.t('security_level.otp');
  }
});

Vue.filter('tradeType', function (trade_type) {
  switch (trade_type) {
    case 'buy' :
      return window.i18n.t('common.trade_type.buy');
    case 'sell':
      return window.i18n.t('common.trade_type.sell');
  }
});

Vue.filter('number', function (number, round = 2) {
  if (!number || !isFinite(number)) {
    return 0;
  }
  return Number(parseFloat(number).toFixed(round));
});

Vue.filter('formatTimeStamp', function (timestamp, format) {
  return moment(timestamp, 'x').format(format);
});

Vue.filter('abs', function (number) {
  return Math.abs(number);
});

Vue.filter('transactionStatus', function (status) {
  return window.i18n.t('common.transaction_status.' + status);
});

Vue.filter('activeFundStatus', function (status) {
  if (status) return window.i18n.t(`withdraw_setting.enable`);
  return window.i18n.t(`withdraw_setting.disable`);
});

Vue.filter('convertToBigNumber', function (number) {
  if (!number) return "0";
  return (new BigNumber(number)).toString();
});

Vue.filter('mulBigNumber', function (number1, number2) {
  if (!number1 || !number2) return "0";
  return (new BigNumber(number1)).mul(number2).toString();
});

Vue.filter('divBigNumber', function (number1, number2) {
  if (!number1) return "0";
  if (!number2) return "1";
  return (new BigNumber(number1)).div(number2).toString();
});

Vue.filter('convertConditionStatus', function (condition) {
  if (condition == 'le') return '<=';
  if (condition == 'ge') return '>=';
  return '';
});

Vue.filter('paddingRight', function (value, length, character) {
  const number = parseFloat(value);
  if (isNaN(number) || length <= 0) {
    return value;
  }
  const strValue = `${value}`;
  const [strNaturalPart, strDecimalPart] = strValue.split('.');
  return `${strNaturalPart}.${window._.padEnd(strDecimalPart, length, character)}`;
});

Vue.filter('toNumber', function (value) {
  const number = parseFloat(value);
  if (isNaN(number)) {
    return value;
  }
  // is e number (Ex: 1e-7)
  if (number.toString().includes('e')) {
    return Utils.trimEndZero(new BigNumber(`${value || 0}`).toFixed(20));
  }
  return number;
});

Vue.filter('formatDate', (date, format = 'YYYY-MM-DD HH:mm:ss') => {
    if (!date) {
        return '';
    }
    return moment(date).format(format);
});

Vue.filter('floatToPercent', (value) => {
  if (value == 0) { return; };
  return `${Numeral(value * 100).format('0.00')}%`;
});

Vue.filter('toPercentage', (value) => {
  if (value == 0) { return "0%"; };
  return `${Numeral(value * 100).format('0.00')}%`;
});

Vue.filter('changedPercent', (value) => `${Numeral(value).format('0.00')}%`);
Vue.filter('changedPercentFee', (value) => `${Numeral(value * 100).format('0.[0000]')} %`);
Vue.filter('percentWithOneDecimal', (value) => `${Numeral(value).format('0.0')}%`);

Vue.filter('percent', (value) => {
  return new BigNumber(`${value || 0}`).times(100).toString() + '%';
});

Vue.filter('trimString', function (value) {
  return value.trim();
});

Vue.filter("avaiBalace", function (value) {
  if (typeof value !== 'undefined' && value !== null) {
    return Number(value).toFixed(8);
  }
  else {
    return value;
  }
});

Vue.filter( 'zoneName', function (value) {
  return window.i18n.t('trading_information.zone_' + value);
});

Vue.filter('ConvertWallet', function (value) {
  return value === 'spot'? 'Spot Wallet' : 'Future Wallet'
})

Vue.filter('firstLetterCapitalize', function (value ) {
  return value?.charAt(0) + value?.substring(1).toLowerCase();
})