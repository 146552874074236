import BaseRequest from '../lib/BaseRequest'

export default class BitmexTracedBotRequest extends BaseRequest {

  getAllBot (params) {
    let url = '/admin/api/bitmex/get-all-bot';
    return this.get(url, params);
  }

  createBot (params) {
    let url ='/admin/api/bitmex/create-bot';
    return this.post(url,params);
  }

  updateBot (params) {
    let url ='/admin/api/bitmex/update-bot';
    return this.put(url, params);
  }

  deleteBot (params) {
    let url ='/admin/api/bitmex/delete-bot';
    return this.del(url,params);
  }

  updateAll (params) {
    let url ='/admin/api/bitmex/update-all';
    return this.put(url, params);
  }
}
