<template>
    <div class="order-history clearfix mg_bot50" id="risk-management">
        <div class="row Rectangle-23">
            <div class="col-xs-4 text-center">
                <div>{{ $t('Users total positions') }}</div>
                <div class="row">
                    <div class="col-xs-6">
                        <div>{{ $t('Long') }}</div>
                        <div>{{ riskInformation.totalLongPosition | formatMarginNumber(0, '0') }}</div>
                        <div>{{ riskInformation.totalLongMargin | formatMarginNumber(8, '0') }} {{
                            $t('currency.btc.shortname') }}
                        </div>
                    </div>
                    <div class="col-xs-6">
                        <div>{{ $t('Short') }}</div>
                        <div>{{ riskInformation.totalShortPosition | formatMarginNumber(0, '0') }}</div>
                        <div>{{ riskInformation.totalShortMargin | formatMarginNumber(8, '0') }} {{
                            $t('currency.btc.shortname') }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xs-4 text-center">
                <div>{{ $t('Insurance fund balance') }}</div>
                <div>{{ riskInformation.insuranceFundBalance | formatMarginNumber(8, '0') }} {{
                    $t('currency.btc.shortname') }}
                </div>
            </div>
            <div class="col-xs-4">
                <div>{{ $t('Realtime profit:') }} {{ riskInformation.realTimeProfit | formatMarginNumber(8, '0') }}
                    {{ $t('currency.btc.shortname') }}</div>
                <div>{{ $t('Number of position:') }} {{ riskInformation.numberOfPosition | formatMarginNumber(8, '0') }}
                    {{ $t('currency.btc.shortname') }}</div>
                <div>{{ $t('Total profit:') }} {{ riskInformation.totalProfit | formatMarginNumber(8, '0') }}
                    {{ $t('currency.btc.shortname') }}</div>
            </div>
        </div>
        <div class="filter-container">
            <div class="filter-item date">
                <div class="text-pair">{{ $t('orders.order_history.date') }}:</div>
                <date-picker :format="customFormatter" class="date-picker-input no-right-radius"
                             v-model="startDate"></date-picker>
                <date-picker :format="customFormatter" class="date-picker-input no-left-radius"
                             v-model="endDate"></date-picker>
            </div>
            <div class="filter-item pair" >
                <div class="label-text text-pair">
                    <span>{{ $t('airdrop_setting.contract') }}:</span>
                </div>
                <div class="toolbar-element2">
                    <select-box
                            v-model="filterContract"
                            :options="contractOptions"/>
                </div>
            </div>
            <div class="filter-item btn-action">
                <button @click="search()" class="btn btn-primary">{{ $t('orders.order_history.search') }}</button>
                <button @click="refresh()" class="btn btn-reset">{{ $t('orders.order_history.reset') }}</button>
            </div>
            <div class="filter-item aver-price">
                Average Entry Price: 
                <span v-if="averageEntryPrice != null">{{ averageEntryPrice | formatMarginNumber(8, '0') }}</span>
                <span v-else>{{ '--' }}</span>
                BTC
            </div>
        </div>

        <div class="row Rectangle-23">
          <div class="col-xs-12 no-padding position-table">
            <div class="title-position">Position (Long/Short)</div>
            <table class="w-100">
              <tbody>
                <tr>
                  <td></td>
                  <td colspan="2">Group A</td>
                  <td colspan="2">Group B</td>
                </tr>
                <tr>
                  <td></td>
                  <td>Long</td>
                  <td>Short</td>
                  <td>Long</td>
                  <td>Short</td>
                </tr>
                <tr>
                  <td>Quantity</td>
                  <td>{{ positionInformation.totalLongPositionGroupA | formatMarginNumber(0, '0')  }}</td>
                  <td>{{ positionInformation.totalShortPositionGroupA | formatMarginNumber(0, '0') }}</td>
                  <td>{{ positionInformation.totalLongPositionGroupB  | formatMarginNumber(0, '0') }}</td>
                  <td>{{ positionInformation.totalShortPositionGroupB | formatMarginNumber(0, '0') }}</td>
                </tr>
                <tr>
                  <td>Profit</td>
                  <td colspan="2">{{ positionInformation.profitGroupA | formatMarginNumber(8, '0') }} {{
                    $t('currency.btc.shortname') }}</td>
                  <td colspan="2">{{ positionInformation.profitGroupB | formatMarginNumber(8, '0') }} {{
                    $t('currency.btc.shortname') }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <data-table :getData="getData" ref="datatable" :limit="10" :column="3" class="scroll height-custom">
          <th class="text-left" >{{ $t('user.email') }}</th>
          <th class="text-left">{{ 'Is MAM' }}</th>
          <th class="text-left">Unrealised Pnl</th>
          <th class="text-right">
            <button @click="addAccount()" class="btn btn-add">
              <i class="icon-plus"></i>
              {{ $t('account_setting.add_user') }}
            </button>
          </th>

          <template slot="body" slot-scope="props">
            <tr>
              <td class="text-left font14">
                <div class="item_email_setting font14">
                  <span class="txt_email_setting font14">{{ props.item.email }}</span>
                  <span class="tooltip_email_setting font14">{{ props.item.email }}</span>
                </div>
              </td>
              <td class="text-left font14">{{ props.item.is_mam ? 'Yes' : 'No' }}</td>
              <td class="text-left font14">{{ props.item.unrealised_pnl | formatMarginNumber(8, '0') }} BTC</td>
              <td class="text-center">
                <button class="btn btn-close-table" @click="confirmDeleteColumn(props.item.id)"><i class="icon-close2"></i></button>
              </td>
            </tr>
          </template>
        </data-table>


        <modal name="addUserEnableFee" width="450">
            <div slot="body" class="body-popup add-user-enable-fee">
                <div class="form-group-detail">
                    <div class="group-detail-form-group">
                        <label class="group-detail-form-label">{{ $t('airdrop_setting.email') }}</label>
                        <input class="group-detail-form-value" maxlength="190"
                               id="input-airdrop-title" type="text" name= "email" v-model="newUser.email"
                               data-vv-validate-on=""
                               :data-vv-as="$t('email')" v-validate="'required|email'"
                               v-bind:class="{'error-modal': errors.has('email')}"
                        />
                        <div class="clearifx"></div>
                        <div class="error-box">
                            <span v-show="showErrorModal && errors.has('email')" class="f-left is-error">{{ errors.first('email') }}</span>
                        </div>
                        <div class="form-check mt-1">
                            <input type="checkbox" v-model="newUser.isMam" id="isMam">
                            <label class="form-check-label" for="isMam"> {{ $t('enable_trading.is_mam')}}</label>
                        </div>
                    </div>
                </div>
            </div>
        </modal>

      <modal name="deleteUserEnableFee" width="450">
        <div slot="body" class="body-popup add-user-enable-fee">
          <div class="form-group-detail">
            <div class="group-detail-form-group">
              <label class="group-detail-form-label-1">{{ $t('airdrop_setting.confirm_remove') }}</label>
            </div>
          </div>
        </div>
      </modal>

    </div>
</template>

<script>
    import rf from '../../lib/RequestFactory';
    import Modal from '../../components/Modal';
    import DatePicker from 'vuejs-datepicker';
    import SelectBox from '../../components/SelectBox';
    import moment from "moment";

    export default {
      components: {
        Modal,
        DatePicker,
        SelectBox,
      },
      data() {
        return {
          titlePage: 'Risk Management',
          startDate: moment().subtract(1, 'week').toDate(),
          endDate: new Date(),
          riskInformation: {},
          filterContract: "",
          newUser:{},
          showErrorModal: false,
          contractOptions:[],
          key : 1,
          averageEntryPrice: 0,
          positionInformation:{},
        }
      },
      methods: {
        getData(params) {
          return rf
            .getRequest("MarginOrderRequest")
            .getUserGroupRiskInformation(params);
        },
        async search() {
          await this.fetchUserGroupData();
        },
        async refresh() {
          this.startDate = moment().subtract(1, 'week').toDate();
          this.endDate = new Date();
          this.filterContract = this.contractOptions[0];
          await this.fetchUserGroupData();
        },
        addAccount(){
          this.showErrorModal = false;
          this.errors.clear();
          this.newUser = {};

          CommonModal.show("addUserEnableFee", {
            position: "add-group customModal",
            mask: true,
            buttons: [
              {
                label: this.$t('common.action.cancel'),
                focused: true,
                style: {
                  width: "140px",
                  background: "rgb(0, 112, 192)"
                },
                callback: () => {
                  this.showErrorModal = false;
                  CommonModal.hide("addUserEnableFee");
                }
              },
              {
                label: this.$t('common.action.save'),
                focused: true,
                style: {
                  width: "140px",
                  background: "rgb(0, 112, 192)"
                },
                callback: () => {
                  this.onConfirmAddUser();
                }
              }
            ]
          });
        },
        async onConfirmAddUser(){
          let isValid = await this.validateModal();
          this.showErrorModal = false;
          if (!isValid) {
            this.showErrorModal = true;
            return ;
          }
          try {
            await rf.getRequest('MarginOrderRequest').addUserGroupRiskInformation(this.newUser);
            CommonModal.hide("addUserEnableFee");
            this.$toastr('success', window.i18n.t('common.alert.create.success'));
            this.newUser = {};
            await this.fetchUserGroupData();
          } catch (e) {
            console.log ('Error: ' + e)
            if (e.response.data.message) {
                this.$toastr('error', e.response.data.message);
            }
          }
        },
        async validateModal() {
          this.errors.clear();
          await this.$validator.validate('email');
          if (this.errors.count() > 0) {
              return false;
          }
          return true;
        },
        customFormatter(date) {
          return moment(date).format('YYYY - MM - DD');
        },
        async fetchUserGroupData () {
          try {
          let timez = moment.tz.guess();
            const params = {
                start_date: moment(this.startDate).startOf('day').format('x'),
                end_date: moment(this.endDate).endOf('day').format('x'),
                contract: this.filterContract.id,
                timezone: timez,
            };
            let fetch = await rf.getRequest('MarginOrderRequest').fetchUserGroupData(params)
            let price = await rf.getRequest('MarginOrderRequest').getAverageEntryPrice(params)
            let infor = await rf.getRequest('MarginOrderRequest').getUserGroupPositionInfo(params)
            this.$refs.datatable.refresh();
            this.averageEntryPrice = price && price.data ? price.data : 0;
            this.positionInformation = infor && infor.data ? infor.data : {};
          } catch (e) {
            console.log ('Error: ' + e)
          }
        },
        async getRiskInformation(){
          try {
            let res = await rf.getRequest('MarginOrderRequest').getRiskInformation();
              if (res && res.data) {
                  this.riskInformation = res.data;
              }
          } catch (e) {
            console.log ('Error: ' + e)
          }
        },
        async getListContractOption(){
          try {
            let res = await rf.getRequest('AdminRequest').getInstrument([]);
            if(res && res.data){
              this.contractOptions.push({'id': null, 'name': 'All'});
              window._.map(res.data, (item) => {
                  this.contractOptions.push({'id': item.symbol, 'name': item.symbol});
              });
              this.filterContract = this.contractOptions[0];
            }
          } catch (e) {
            console.log ('Error: ' + e)
          }
        },
        async deleteColumn(id){
          const params = {
            id
          };
          try {
            await rf.getRequest('MarginOrderRequest').deleteUserGroupRiskInformation(params)
            this.$toastr('success', window.i18n.t('enable_trading.remove_success'));
            CommonModal.hide("deleteUserEnableFee");
            await this.fetchUserGroupData();
          } catch (e) {
            console.log ('Error: ' + e)
            this.$toastr('error', window.i18n.t('enable_trading.remove_fail'));
          }
        },
        confirmDeleteColumn(item) {
          CommonModal.show("deleteUserEnableFee", {
            position: "add-group customModal",
            mask: true,
            buttons: [
              {
                label: this.$t('common.action.no'),
                focused: true,
                style: {
                    width: "140px",
                    background: "rgb(0, 112, 192)"
                },
                callback: () => {
                    this.showErrorModal = false;
                    CommonModal.hide("deleteUserEnableFee");
                }
              },
              {
                label: this.$t('common.action.yes'),
                focused: true,
                style: {
                    width: "140px",
                    background: "rgb(0, 112, 192)"
                },
                callback: () => {
                    this.deleteColumn(item);
                }
              }
            ]
          });
        },
      },
      mounted() {
        this.$emit('EVENT_PAGE_CHANGE', this);
      },
      async created() {
          await this.getListContractOption();
          await this.getRiskInformation();
          await this.fetchUserGroupData();
      }
    }
</script>

<style lang="scss" scoped>
@import "@/assets/sass/variables";

  .item_email_setting {
    display: inline-block;
    float: left;
    position: relative;
    .txt_email_setting {
      display: block;
      max-width: 150px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .tooltip_email_setting {
      position: absolute;
      top: 0px;
      left: 0px;
      line-height: 20px;
      padding: 5px 20px;
      left: 100%;
      background-color: $color_white;
      white-space: nowrap;
      width: auto;
      z-index: 10;
      font-size: $font_root;
      font-weight: 500;
      color: $color_mine_shaft;
      transition: 0.5s;
      display: none;
      box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.4);
      &:after {
        right: 100%;
        top: 50%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: rgba(136, 183, 213, 0);
        border-right-color: $color_white;
        border-width: 5px;
        margin-top: -5px;
      }
    }
    &:hover {
      .tooltip_email_setting {
        display: block;
        transition: 0.5s;
      }
    }
  }

.btn-close-table {
  width: 17px;
  height: 17px;
  text-align: center;
  line-height: 20px;
  padding: 0px;
  text-align: center;
  border: 0px;
  background-color: transparent;
  font-size: 16px;
  color: $color-denim;
  margin-left: 7px;
  i {
    &:before {
      color: $color-denim;
    }
  }
}

.Rectangle-23{
    background-color: $color_white;
    margin-top : 20px;
    margin-bottom: 20px;
}

.title-position{
    border-bottom: solid 1px $color-alto;
    margin-top:5px;
    padding-bottom:5px;
    text-align: center;
}
.position-table{
    border-right: solid 1px $color-alto;
    table{
        width: 100%;
        tr{
            &:first-child{
                border-bottom: solid 1px $color-alto;
            }
            td {
                padding: 8px 2px 4px 15px;
                text-align: center;
                border-right: solid 1px $color-alto;
                &:last-child{
                    border-right: 0;
                }
            }
        }
    }
}

.add-user-enable-fee {
    .is-error {
        color: $color_red_text !important;
    }
    .error-box {
        display: block;
        float: left;
        overflow: hidden;
        width: 100%;
        span {
            font-family: $font-family-roboto-medium !important;
        }
    }
}
.mt-1{
    margin-top:10px;
}

.btn-add {
    height: 22px;
    border-radius: 3px;
    background-color: $color-caribbean-green;
    border: 1px solid $color-caribbean-green;
    font-size: $font_mini_mini;
    letter-spacing: 0.77px;
    color: $color-white;
    text-align: center;
    padding: 1px 12px;
    padding-top: 2px;
    display: block;
    float: right;
    i {
        font-size: 8px;
        position: relative;
        top: 0px;
        margin-right: 4px;
    }
    &:hover {
        border-color: $color-aquamarine;
        background-color: $color-aquamarine;
    }
}

.row{
    margin-right: 0;
    margin-left: 0;
}

.min_85 {
    min-width: 85px;
}

.min_110 {
    min-width: 110px;
}

.w_125 {
    width: 125px;
}
.text-left {
    text-align: left;
}
.form-group-detail {
    .group-detail-form-group {
        .group-detail-form-label{
            width: 100%;
            color: $color-grey-dusty;
            float: left;
            font-weight: normal;
            font-family: $font-family-roboto;
            margin-bottom: 0px;
            margin-top: 10px;
        }
        .group-detail-form-label-1{
            width: 100%;
            margin-bottom: 30px;
            margin-top: 10px;
            line-height: 25px;
            text-align: center;
            font-size: 20px;
            color: rgb(42, 47, 50);
            font-family: "Roboto-Regular", sans-serif;
            font-size: 16px;
            font-weight: 600;
        }

        .group-detail-form-value {
            width: 100%;
            padding: 8px 16px;
            border: 1px solid #dee3eb;
            height: 35px;
            line-height: 35px;
            border-radius: 3px;
            font-family: $font-family-roboto;
            // font-weight: 600;
            &:focus {
                border-color: $color-jungle-green;
            }
        }
        #input-airdrop-title {
            &.error-modal {
                border: solid 1px $color-red-main;
                border-radius: 3px;
            }
        }
        .textarea_input {
            height: 120px;
        }
    }
    .error-box {
        display: block;
        float: left;
        overflow: hidden;
        width: 100%;
        span {
            font-family: $font-family-roboto-medium !important;
        }
    }
    .is-error {
        color: #ff5252 !important;
    }
}

.order-history {
    .filter-container {
        .form_check_hiden {
            float: left;
            margin-top: 25px;
            margin-right: 5px;
            margin-left: 5px;

            .input_check_hiden {
                float: left;
                width: 15px;
                height: 15px;
                margin: 8px 10px 5px 0px;
                cursor: pointer;
            }

            .label_check_hiden {
                float: left;
                height: 30px;
                line-height: 20px;
                padding: 5px 0px;
                margin: 0px;
                cursor: pointer;
                color: $color-grey-dusty;
                font-size: $font_smaller;
                font-weight: normal;
                font-family: $font-family-roboto;
            }
        }
    }
}

.table-user-groupA {
    background-color: $color_white;
    margin: 0;
    thead {
        font-size: $font-small;
        th{
            position: relative;
            background-color: $color_white;
            line-height: 15px;
            font-weight: 500;
            color: $color_grey;
            border-bottom: 1px solid $color_alto;
            height: 38px;
            display: table-cell;
            vertical-align: inherit;
            font-family: $font-family-roboto-medium;
        }
    }
    tbody {
        tr {
            vertical-align: top;
            overflow-y: hidden;
            transition-property: height;
            transition-duration: 0.3s, 0.3s;
            transition-timing-function: ease, ease-in;
            height: auto;
            background-color: $color_white;
            &:last-child {
                td {
                    .item_email_setting {
                        vertical-align: middle;
                        .tooltip_address_setting {
                            top: inherit;
                            bottom: 100%;
                            &::after {
                                top: inherit;
                                bottom: -10px;
                                border-top-color: $color_white;
                                border-bottom-color: transparent;
                            }
                        }
                    }
                }
            }

            td {
                height: 40px;
                overflow: initial;
                line-height: 23px;
                font-size: 14px !important;
                font-weight: 500;
                color: $color_mine_shaft;
                // padding: 8px 15px 4px 15px;
                border-top: 1px solid $color_catskill_white;
                font-family: $font-family-roboto;
                vertical-align: middle;
                padding: 4px 8px;
            }
        }
    }
}

.order-history {
    .filter-container {
        margin: 10px 0px 30px 0px;
        height: 55px;

        .filter-item {
            float: left;
            margin: 0 5px;

            input {
                margin-top: 23px;
                display: inline-block;
                width: 145px;
                font-family: $font-family-roboto;

                &:hover, &:active, &:focus {
                    border-color: $color-jungle-green;
                }
            }

            select {
                width: 65px;
                display: inline-block;
                font-family: $font-family-roboto;
                font-size: $font_root;

                &:hover, &:active, &:focus {
                    border-color: $color-jungle-green;
                }
            }

            button {
                width: auto;
                margin: 23px 2px 0px 2px;
                height: 30px;
                font-size: $font-smaller;
                font-family: $font-family-roboto-bold;
            }

            .btn-reset {
                background-color: $color-caribbean-green;
                border: 1px solid $color-caribbean-green;
                width: 80px;
                text-transform: uppercase;
                color: $color-white;
                font-weight: 500;

                &:hover {
                    border-color: $color-aquamarine;
                    background-color: $color-aquamarine;
                }
            }

            .btn-primary {
                padding: 0;
                min-width: 80px;

                &:hover {
                    border-color: $color-aquamarine;
                    background-color: $color-aquamarine;
                }
            }

            .coin-input {
                position: relative;
                display: inline-block;
                margin: 0 5px;
                margin-right: 0px;
                font-size: $font-small;
                font-family: $font-family-roboto;

                .coin-option {
                    background-color: $color-white;
                    max-height: 210px;
                    display: block;
                    overflow-y: auto;
                    margin-top: 5px;
                    position: absolute;
                    width: 79px;
                    left: 5px;
                    z-index: 10;
                    padding: 12px 0px;
                    box-shadow: 0px 3px 12px rgba(49, 49, 49, 0.51);

                    &:after {
                        bottom: 100%;
                        left: 50%;
                        border: solid transparent;
                        content: " ";
                        display: block;
                        height: 0;
                        width: 0;
                        position: absolute;
                        pointer-events: none;
                        border-color: rgba(136, 183, 213, 0);
                        border-bottom-color: $color_white;
                        border-width: 5px;
                        margin-left: -5px;
                    }

                    .option {
                        display: block;
                        width: 100%;
                        line-height: 20px;
                        cursor: pointer;
                        padding: 3px 14px;
                        font-size: 14px;
                        overflow: hidden;

                        &:hover {
                            color: $color-jungle-green;
                        }
                    }
                }
            }
        }

        .aver-price {
          margin-top: 25px;
          padding-right: 100px;
          float: right;   
        }

        .pair {
            input {
                width: 65px;
            }

            .text-pair {
                padding-left: 10px;
            }
        }

        .text-pair {
            color: $color-grey-dusty;
            font-family: $font-family-roboto;
            font-size: $font-small;
            margin-bottom: 5px;
        }

        .side {
            margin: 0 10px;
        }
    }

    .form-control {
        background: $color-white-smoke;
        height: 30px;
        font-size: $font_small;
        padding-left: 11px;
        padding-right: 0;
    }

    .datatable {
        button.btnForm.bt-action {
            padding-left: 5px !important;
            padding-right: 5px !important;
            font-weight: 400 !important;
        }
    }

    .buy {
      color: $text-color-jade;
    }

    .sell {
        color: $text-color-red;
    }

    .btn-detail {
        background-color: $color-caribbean-green;
        padding: 2px 10px;
        font-size: $font-smaller;
        color: $color-white;
        border: 1px solid $color-caribbean-green;
        border-radius: 3px;
        text-transform: uppercase;
        font-family: $font-family-roboto;

        &:hover {
            background-color: $color-aquamarine;
            border-color: $color-aquamarine;
        }
    ;
    }
}
</style>
<style lang="scss">
@import "@/assets/sass/variables";
  #risk-management {
    .toolbar-element2 {
      .button_drop_search {
        width: 100px;
      }
    }
    .modal-dialog {
      height: auto;
      .modal-content {
        height: auto;
        .modal-body {
          padding: 0px 30px 15px 30px;
          .group-detail-form-label {
            margin-bottom: 5px;
          }
        }
        .modal-footer {
          padding-bottom: 30px;
        }
      }
    }
  }
</style>