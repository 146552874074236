<template>
  <div id="bank_setting" class="clearfix mg_bot50">
    <div class="content_bank_setting clearfix">
      <div class="row">
        <div class="col-xs-6">    
          <div><label class="label_input">{{this.$t('bank_setting.bank_name')}}</label></div>   
          <input @focus="resetErrors" :maxlength="maxLength" type="text" class="Rectangle-6-Copy-9" :class="{ 'Rectangle-6-Copy-9-abled' : !isActive, 'error': errors.has('newBankName')}" :disabled="isActive" v-model="newBankName">
          <div v-show="errors.has('newBankName')" class="invalid-feedback">{{ errors.first('newBankName') }}</div>    
        </div>  
        <div class="col-xs-6">       
          <div><label class="label_input">{{this.$t('bank_setting.bank_branch')}}</label></div>   
          <input @focus="resetErrors" :maxlength="maxLength" type="text" class="Rectangle-6-Copy-9" :class="{ 'Rectangle-6-Copy-9-abled' : !isActive, 'error': errors.has('newBankBranch')}" :disabled="isActive" v-model="newBankBranch">
          <div v-show="errors.has('newBankBranch')" class="invalid-feedback">{{ errors.first('newBankBranch') }}</div>    
        </div> 
        <div class="col-xs-6">       
          <div><label class="label_input">{{this.$t('bank_setting.account_name')}}</label></div>   
          <input @focus="resetErrors" :maxlength="maxLength" type="text" class="Rectangle-6-Copy-9" :class="{ 'Rectangle-6-Copy-9-abled' : !isActive, 'error': errors.has('newAccontName')}" :disabled="isActive" v-model="newAccontName">    
          <div v-show="errors.has('newAccontName')" class="invalid-feedback">{{ errors.first('newAccontName') }}</div>          
        </div>      
        <div class="col-xs-6">       
          <div><label class="label_input">{{this.$t('bank_setting.account_no')}}</label></div>   
          <input @focus="resetErrors" :maxlength="maxLength" type="text" class="Rectangle-6-Copy-9" :class="{ 'Rectangle-6-Copy-9-abled' : !isActive, 'error': errors.has('newAccountNo')}" :disabled="isActive" @keypress="onKeypress" @paste="onPaste" v-model="newAccountNo">
          <div v-show="errors.has('newAccountNo')" class="invalid-feedback">{{ errors.first('newAccountNo') }}</div>              
        </div>
      </div>

      <button class="Rectangle-26-Copy-7" v-if="isActive" @click="active"><span class="text_in_button">{{this.$t('bank_setting.edit')}}</span></button>
      <div v-else>
        <button class="Rectangle-26-Copy-7" @click="disable"><span class="text_in_button">{{this.$t('bank_setting.return')}}</span></button>
        <button class="Rectangle-26-Copy-7" @click="settingBank"><span class="text_in_button">{{this.$t('bank_setting.save')}}</span></button>
      </div>

   </div>
  </div>
</template>

<script>
  import rf from '../../lib/RequestFactory';
  import CONST from '../../common/Const'

  export default {
    components: {
    },
    data() {
      return {
        titlePage: this.$t('bank_setting.bank_setting'),
        isActive: true,
        bankAccount: {
          id:null,
          bank_name: null,
          bank_branch: null,
          account_name: null,
          account_no: null,  
        },
        newBankName: null,
        newBankBranch: null,
        newAccontName: null,
        newAccountNo: null,
        maxLength: CONST.MAX_LENGTH_INPUT,
      }
    },

    methods: {
      onKeypress(event) {
        let stringValue = "" + this.value;
        let charCode = (event.which) ? event.which : event.keyCode;
        if ((stringValue.length >= 190) || (charCode === 46 && (~ stringValue.indexOf('.') < 0)) ||
          ((charCode > 31 && (charCode < 48 || charCode > 57) && (charCode < 65 || charCode > 90) && (charCode < 97 || charCode > 122) ))) {
          event.preventDefault();
        } else {
          this.$emit("keypressinput", event);
          return true;
        }
      },
      onPaste(event) {
        let data = event.clipboardData.getData('Text');
        let dataFull = data.replace(/[^0-9a-zA-Z]/gi, '');
        this.newAccountNo = dataFull;
        event.preventDefault();
      },
      validate() {
        this.errors.clear();
        if (!this.newBankName) {
          this.errors.add({field: 'newBankName', msg: this.$t('bank_setting.error.lose_bank_name')});
        }
        if(!this.newBankBranch) {
          this.errors.add({field: 'newBankBranch', msg: this.$t('bank_setting.error.lose_bank_branch')});
        }
        if(!this.newAccontName) {
          this.errors.add({field: 'newAccontName', msg: this.$t('bank_setting.error.lose_account_name')});
        }
        if(!this.newAccountNo) {
          this.errors.add({field: 'newAccountNo', msg: this.$t('bank_setting.error.lose_account_no')});
        }
        if (this.errors.count() > 0) {
          return false;
        }
        return true;   
      },
      active () {
        this.isActive = false;   
      },
      resetErrors () {
        this.errors.clear();
      },
      reset () {
        this.newBankName = this.bankAccount ? this.bankAccount.bank_name : "";
        this.newBankBranch = this.bankAccount ? this.bankAccount.bank_branch : "";
        this.newAccontName = this.bankAccount ? this.bankAccount.account_name : "";
        this.newAccountNo = this.bankAccount ? this.bankAccount.account_no : "";
      },
      disable () {
        this.isActive = true;
        this.reset();
        this.errors.clear();
      },      
      async getData () {        
        this.bankAccount = (await rf.getRequest('AdminRequest').getBankAccounts()).data;
        this.reset();
      },       
      async settingBank () {
        this.newBankName = this.newBankName ? this.newBankName.trim() : "";    
        this.newBankBranch = this.newBankBranch ? this.newBankBranch.trim() : "";    
        this.newAccontName = this.newAccontName ? this.newAccontName.trim() : "";    
        this.newAccountNo = this.newAccountNo ? this.newAccountNo.trim() : "";
        if(this.validate()) {
          let params = {
            id: this.bankAccount.id,
            bank_name: this.newBankName,
            account_no: this.newAccountNo,
            bank_branch: this.newBankBranch,
            account_name: this.newAccontName
          }
          rf.getRequest('AdminRequest').updateBankAccount(params)
          .then(data => {
            this.isActive = true;
            this.$toastor('success', this.$t('bank_setting.update_success_msg'));
            this.getData();
            this.reset();    
          })
        }  
      },      
    },

    mounted() {
      this.$emit('EVENT_PAGE_CHANGE', this);
      this.getData();
    }
  }
</script>

<style lang="scss" scoped>
@import "@/assets/sass/variables";
  
  #bank_setting {
    margin-top: 10px;
  }
  .content_bank_setting {
    width: 750px;
    display: block;
  }
.text_in_form {
  height: 17px;
  font-family: $font-family-roboto;
  font-size: $font_root;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $color-grey-dark;
}  
.text_in_button {
  height: 29px;
  font-family: $font-family-roboto;
  font-size: $font-smaller;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.42;
  letter-spacing: 0.9px;
  text-align: center;
  color: $color-white;  
}
.label_input {
  margin-bottom: 5px;
  height: 17px;
  font-family: $font-family-roboto;
  font-size: $font_root;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $color-grey-dusty;
}
.-Indicates-required {
  width: 148px;
  height: 29px;
  font-family: $font-family-roboto;
  font-size: $font-smaller;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.23;
  letter-spacing: normal;
  color: $color-denim;
}
.Rectangle-6-Copy-9 {
  background-color: $color-bright-gray;
  padding: 10px;
  margin-bottom: 20px;
  width: 360px;
  height: 35px;
  border-radius: 3px;
  border: solid 1px $color_alto;
}
.Rectangle-6-Copy-9-abled {
  background-color: $color-white-smoke;
}
input:focus { 
    border: solid 1px $color-jungle-green-light;
}
::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  width: 102px;
  height: 17px;
  font-family: $font-family-roboto;
  font-size: $font_root;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $color-grey-dark;
}
.Rectangle-23 {
  padding-top: 25px;
  padding-left: 26px;
  padding-bottom: 28px;
  padding-right: 5px;
  width: 580px;
  height: 370px;
  border: solid 1px $color_alto;
}
.Rectangle-23-Copy-11 {
  width: 400px;
  height: 160px;
  background-color: $color-white;
}
.mini-box {
  display: flex;
  flex-direction: row;
  margin-top: 17px;
  margin-bottom: 17px;
}

.aterisk {
  height: 24px;
  font-family: $font-family-roboto;
  font-size: $font_big_20;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $color-denim;  
}
.Rectangle-26-Copy-7 {
  width: 90px;
  margin-top: 15px;
  margin-right: 15px;
  height: 35px;
  border-radius: 3px;
  border-color: $color-caribbean-green;
  background-color: $color-caribbean-green;
  padding: 3px;
  line-height: 20px;
  text-align: center;
  &:hover {
    background-color: $color-aquamarine;
    border-color: $color-aquamarine;
  }
}
.col-xs-6 {
  padding-bottom: 5px;
}
.error {
  border: solid 1px $color-red-main;
}
.return_border {
  border: solid 1px $color_alto;
}
.invalid-feedback{
  margin-top: -20px;
  color: $color-red-main;
}
</style>