import { Validator } from 'vee-validate';
import Utils from './Utils';
import rf from '../lib/RequestFactory';

Validator.extend('is_eth_address', {
  getMessage: (field) => window.i18n.t('validation.is_contract_address', { attribute: field }),
  validate: (value, args) => ({ valid: Utils.isWalletAddress(args[0], value) }),
});

Validator.extend('is_contract_address', {
  getMessage: (field) => window.i18n.t('validation.is_contract_address', { attribute: field }),
  validate: (value, args) => {
    return rf.getRequest('WalletRequest').getErc20ContractInformation({ contract_address: value})
      .then((res) => ({ valid: !_.isEmpty(res.data) })).catch(() => ({ valid: false }));
  },
});


Validator.extend('is_contract_address_exist', {
  getMessage: (field) => window.i18n.t('validation.is_contract_address_exist', { attribute: field }),
  validate: (value, args) => {
    return rf.getRequest('WalletRequest').getErc20ContractInformation({ contract_address: value})
      .then((res) => ({ valid: !_.isEmpty(res.data) })).catch((error) => {
        if (error.response && error.response.data && error.response.data.message && error.response.data.message.error == 'symbol.exist') {
          return { valid: false }
        }
        return { valid: true }
      });
  },
});

Validator.extend('unregistered_token', {
  getMessage: (field) => window.i18n.t('validation.unregistered_token', { attribute: field }),
  validate: (value, args) => {
    const coins = window.app.$store.getters.coins || {};
    return { valid: !window._.find(coins, function(coin) { return coin.coin === value && coin.type === 'erc20' }) };
  },
});

Validator.extend('unregistered_coin', {
  getMessage: (field) => window.i18n.t('validation.unregistered_token', { attribute: field }),
  validate: (value, args) => {
    const coins = window.app.$store.getters.coins || {};
    return { valid: !window._.find(coins, function(coin) { return _.toLower(coin.coin) === _.toLower(value) }) };
  },
});

Validator.extend('unregistered_contract_address', {
  getMessage: (field) => window.i18n.t('validation.unregistered_contract_address', { attribute: field }),
  validate: (value, args) => {
    const coins = window.app.$store.getters.coins || {};
    return { valid: !window._.find(coins, function(coin) { return coin.contract_address === value && coin.type === 'erc20' }) };
  },
});

Validator.extend('unregistered_token_name', {
  getMessage: (field) => window.i18n.t('validation.unregistered_token_name', { attribute: field }),
  validate: (value, args) => {
    const coins = window.app.$store.getters.coins || {};
    return { valid: !window._.find(coins, function(coin) { return _.toLower(coin.name) === _.toLower(value) && coin.type === 'erc20' }) };
  },
});

Validator.extend('unregistered_coin_name', {
  getMessage: (field) => window.i18n.t('validation.unregistered_coin_name', { attribute: field }),
  validate: (value, args) => {
    const coins = window.app.$store.getters.coins || {};
    return { valid: !window._.find(coins, (coin) => _.toLower(coin.name) === _.toLower(value) && coin.coin !== args[0] )};
  },
});
