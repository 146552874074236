<template>
  <div id='email_send'>
    <div class="error-box">
    </div>
    <div class="page960">
      <div class="section">
        <div class="form-notice-detail-container">
          <div id="notice-detail">
            <label class="choose-option">
              <input type="radio" value="0" v-model="isAllUsers" >
              <span>{{ $t('email.list_user') }}</span>
              <span class="checkmark"></span>
            </label>
            <textarea class="email-input" v-model="inputUser" :disabled="isAllUsers == 1" @focus="resetError" :class="errors.has('email_error') ? 'error-inputUser' : ''"
              placeholder="Example multiple users: email1@gmail.com, email1@gmail.com2, ..."></textarea>
            <span class="f-left is-error">{{ errors.first('email_error') }}</span>
            <label class="choose-option padd-t40">
              <input type="radio" value="1" v-model="isAllUsers" @focus="resetError">
              <span>{{ $t('email.send_all') }}</span>
              <span class="checkmark"></span>
            </label>

            <div class="submit">
              <button class="btn-cancel" @click="cancel">{{ $t('notice.cancel') }}</button>
              <button class="btn-submit" @click="sendEmails()" :disabled="!submittable">{{ $t('notice.submit') }}</button>
            </div>

            <div class="sent-email-result">
                <data-table :getData="getEmailMarketingSendedHistories" ref="datatable" :column="3">
                  <!-- <template slot="colgroup_slot">
                    <colgroup>
                      <col width="20%" />
                      <col width="15%" />
                      <col width="65%" />
                    </colgroup>
                  </template> -->
                  <th data-sort-field="email" class="cl1 w1">{{ $t('email.email') }}</th>
                  <th data-sort-field="status" class="cl2 w2">{{ $t('email.status') }}</th>
                  <th class="cl3 w3">{{ $t('email.message') }}</th>
                  <template slot="body" slot-scope="props">
                    <tr>
                      <td class="cl1 w1 font-size-14">
                        <div class="over-flow-text" :title="props.item.email">{{ props.item.email }}</div>
                      </td>
                      <td class="cl1 w2 font-size-14">{{ props.item.status | upperFirst }}</td>
                      <td class="cl1 w3 font-size-14">{{ props.item.message }}</td>
                    </tr>
                  </template>
                </data-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import rf from '../../../lib/RequestFactory';
  import RemoveErrorsMixin from '../../../common/RemoveErrorsMixin';

  export default {
    components: {
    },
    mixins: [RemoveErrorsMixin],
    data() {
      return {
        titlePage: window.i18n.t('email.send_title_page'),
        emailSetting: {},
        isAllUsers: 0,
        inputUser: '',
        sentEmails: []
      }
    },
    computed: {
      emails() {
        if (window._.isEmpty(this.inputUser)) {
          return [];
        }
        return window._.split(this.inputUser, ',').map(function(item) {
          return item.trim();
        }).filter(function(e){return e !== ''});
      },
      submittable() {
        return !window._.isEmpty(this.emailSetting)
          && !window._.isEmpty(`${this.emailSetting.id}`);
      }
    },
    methods: {
      sendEmails() {
        if (!this.validate()) {
          return;
        }
        ConfirmationModal.show({
          type: 'confirm',
          title: this.$t('email.submit_data'),
          content         : '',
          btnCancelLabel  : this.$t('common.action.no'),
          btnConfirmLabel : this.$t('common.action.yes'),
          size            : 'small',
          onConfirm       : () => {
            this.sendEmailMarketing();
          },
          onCancel        : () => {}
        });
      },

      sendEmailMarketing() {
        if (!this.submittable) {
          this.$toastr('error', 'Template email is invalid or exists.');
          return;
        }
        const params = {
          id            : this.emailSetting.id,
          all_users     : this.isAllUsers,
          emails        : this.emails
        };
        rf.getRequest('AdminRequest').sendEmailMarketing(params).then(res => {
          this.$toastr('success', this.$t('contact.message.sendEmailSuccess'));
          this.$refs.datatable.refresh();
        }).catch(error => {
          if (!error.response) {
            Message.error(window.i18n.t('common.message.network_error'), {}, { position: "bottom_left" });
            return;
          }
          this.convertRemoteErrors(error);
        });
      },

      validate() {
        this.resetError();
        if (Number(this.isAllUsers) == 0 && window._.isEmpty(this.emails)) {
          this.errors.add({
            field: 'email_error',
            msg: this.$t('email.errors.required')
          });
        }
        return this.errors.items.length <= 0;
      },

      cancel() {
        this.$router.push({ name: 'Emails Marketing' });
      },

      getEmailMarketingSendedHistories(params) {
        const id = this.emailSetting.id || this.$route.query.id;
        return rf.getRequest('AdminRequest').getEmailMarketingSendedHistories(id, params);
      }
    },

    mounted() {
      this.$emit('EVENT_PAGE_CHANGE', this);
      const id = this.$route.query.id;
      if (window._.isEmpty(`${this.id}`)) {
        return;
      }
      rf.getRequest('AdminRequest').editEmailMarketing(id).then(res => {
        this.emailSetting = res.data || {};
      });
    }
  }
</script>

<style lang="scss">
@import "@/assets/sass/variables";
  #email_send {
    .button_drop_search{
      width: 55px;
    }
  }
</style>

<style lang="scss" scoped>
@import "@/assets/sass/variables";
  .error-inputUser{
    border: 1px solid $color-denim;
  }
  .padd-t40{
    margin-top: 40px ;
  }
  .w_250 {
    width: 250px;
  }
  .is-error {
      color: $color-denim !important;
  }
  .input-error {
      border: 1px solid $color-denim !important;
  }
  .f-left {
      float: left;
  }
  .required {
      color: $color-denim;
  }
  .error-box {
      margin-left: 160px;
  }
  .page960 {
      min-width: 970px;
  }
  .f-left {
      float: left;
  }
  button[disabled] {
      opacity: 0.4 !important;
  }
  .choose-option {
    display: block;
    position: relative;
    padding-left: 20px;
    margin-bottom: 15px;
    cursor: pointer;
    font-size: $font_big;
    width: 200px;
    font-weight: 500;
    font-family: $font-family-roboto-medium;
    input[type="radio"]:checked { 
      +span {
        color: $color-jungle-green;
        +span.checkmark {
          border: solid 4px $color-jungle-green;
        }
      }
    }
  }
  .choose-option input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  .checkmark {
    position: absolute;
    left: 0;
    height: 12px;
    width: 12px;
    border-radius: 50%;
    margin-top: 7px;
    border: solid 1px $color_grey;
  }
  .choose-option input:checked ~ .checkmark:after {
    display: block;
  }
  .email-input {
    width: 100% !important;
    min-height: 100px;
    color: $color-grey-dark;
    font-size: $font_root;
    font-family: $font-family-roboto-medium;
    background-color: $color-white-smoke;
    border-radius: 3px;
    padding: 10px 15px;
    outline: none;
    &:hover, &:active, &:focus {
      border-color: $color-jungle-green;
    }
    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: $color-grey-dark;
      opacity: 1; /* Firefox */
    }

    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: $color-grey-dark;
    }

    &::-ms-input-placeholder { /* Microsoft Edge */
      color: $color-grey-dark;
    }
  }
  .form-notice-detail-container {
      padding: 5px 0px 0;
      .notice-detail-country {
          min-height: 30px;
          .notice-detail-form-label {
              margin-right: 22px;
              width: 130px;
              line-height: 30px;
              text-align: right;
              font-size: $font_root;
              color: black;
              float: left;
          }
      }
      .filed-verification {
          .filed {
              .tip {
                  font-size: $font-smaller;
                  color: $color-grey-dusty;
                  padding: 20px 0;
              }
          }
      }
      .notice-detail-form-group {
          min-height: 30px;
          height: 70px;
          .notice-detail-form-label {
              margin-right: 30px;
              width: 130px;
              line-height: 30px;
              text-align: right;
              font-size: $font_root;
              color: black;
              float: left;
          }
          .col-md-6 {
              input {
                  width: 300px;
              }
          }
      }
      .submit {
          padding: 0px 0 20px 0px;
          text-align: left;
          float: left;
          .btn-cancel {
            margin-right: 15px;
            background-color: $color-caribbean-green;
            border-color: $color-jungle-green;
            &:hover, &:active, &:focus {
              background-color: $color-aquamarine;
              border: 1px solid $color-aquamarine;
            }
          }
          .btn-submit{
            background-color: $color-caribbean-green;
            border-color: $color-jungle-green;
            &:hover, &:active, &:focus {
              background-color: $color-aquamarine;
              border: 1px solid $color-aquamarine;
            }
          }
          button {
            left: 0;
            top: 0;
            cursor: pointer;
            height: 35px;
            line-height: 20px;
            text-align: center;
            min-width: 90px;
            display: inline-block;
            float: left;
            font-family: $font-family-roboto-bold;
            background-color: $color-caribbean-green;
            border: 1px solid $color-caribbean-green;
            color: $color-white;
            font-size: $font-smaller;
            letter-spacing: 0.9px;
            border-radius: 3px;
            padding: 7px 5px;
            text-transform: uppercase;
            &:hover, &:active, &:focus {
              background-color: $color-aquamarine;
              border: 1px solid $color-aquamarine;
            }
          }
      }
      .sent-email-result {
        max-width: 100%;
        .cl1 {
          .over-flow-text {
            max-width: 450px;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 14px;
            line-height: 40px;
          }
        }
      }
  }
  .w1 {
    width: 20%;
  }
  .w2 {
    width: 15%;
  }
  .w3 {
    width: 65%;
  }
  .font-size-14 {
    font-size: 14px !important;
  }
</style>
