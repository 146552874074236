<template>
  <div>
    <div class="VuePagination clearfix">
      <div class="group_number_items" v-show="records > 10">
        <!-- <span class="txt_nb_itme">Number items</span> -->
        <!-- <select class="list_number_items" v-model="limit">
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="50">50</option>
          <option value="100">100</option>
        </select> -->
        <SelectBox3
            v-model="limit"
            :options="[10, 20, 50]"/>
      </div>
      <ul v-show="totalPages > 1"
          class="pagination VuePagination__pagination">

        <!--<li class="VuePagination__pagination-item page-item VuePagination__pagination-item-prev-chunk "-->
            <!--:class="{disabled : !allowedChunk(-1)}">-->
          <!--<a class="page-link" href="javascript:void(0);"-->
             <!--@click="setChunk(-1)">&lt;&lt;</a>-->
        <!--</li>-->


        <li class="VuePagination__pagination-item page-item VuePagination__pagination-item-prev-page page-prev" :class="{disabled : !allowedPage(page - 1)}">
          <a class="page-link" @click="prev()">
            <img class="" src="@/assets/images/icon/arrow-right.svg">
<!--            <span class="icon-arrow10"></span> {{ $t('shared_components.pagination.pre') }}-->
          </a>
        </li>

        <template v-for="item in pages">
          <li class="VuePagination__pagination-item page-item" :class="{active: parseInt(page) === parseInt(item)}" :key="item">
            <a class="page-link" role="button" @click="setPage(item)">{{item}}</a>
          </li>
        </template>

        <li class="VuePagination__pagination-item page-item VuePagination__pagination-item-next-page page-next" :class="{disabled : !allowedPage(page + 1)}">
          <a class="page-link" @click="next()">
            <img class="" src="@/assets/images/icon/arrow-right.svg">
<!--            {{ $t('shared_components.pagination.next') }} <span class="icon-arrow9"></span>-->
          </a>
        </li>

        <!--<li class="VuePagination__pagination-item page-item VuePagination__pagination-item-next-chunk "-->
            <!--:class="{disabled : !allowedChunk(1)}">-->
          <!--<a class="page-link" href="javascript:void(0);"-->
             <!--@click="setChunk(1)">&gt;&gt;</a>-->
        <!--</li>-->
      </ul>
    </div>
  </div>
</template>

<script>
  import SelectBox3 from "./SelectBox3";
  export default {
    components: {
      SelectBox3
    },
    props: {
      pageParent: {
        type: Number,
        default: 1,
      },
      records: {
        type: Number,
        required: true
      },
      chunk: {
        type: Number,
        required: false,
        default: 6
      },
      perPage: {
        type: Number,
        required: true,
      },
    },
    data: function () {
      return {
        page: this.pageParent,
        limit: 10,
      }
    },
    watch: {
      records() {
        if (this.page > this.totalPages) {
          this.page = 1;
        }
      },
      pageParent() {
        this.page = this.pageParent;
      },
      limit(newValue) {
        this.$emit('change-limit', parseFloat(newValue));
      }
    },
    computed: {
      pages: function () {
        if (!this.records) 
          return []
        return range(this.paginationStart, this.chunk, this.totalPages)
      },
      totalPages: function () {
        return this.records ? Math.ceil(this.records / this.perPage) : 1;
      },
      totalChunks: function () {
        return Math.ceil(this.totalPages / this.chunk)
      },
      currentChunk: function () {
        return Math.ceil(this.page / this.chunk)
      },
      paginationStart: function () {
        return ((this.currentChunk - 1) * this.chunk) + 1
      },
      pagesInCurrentChunk: function () {

        return this.paginationStart + this.chunk <= this.totalPages ? this.chunk : this.totalPages - this.paginationStart + 1

      },
    },
    methods: {
      setPage: function (page) {
        if (this.allowedPage(page)) {
          this.paginate(page)
        }
      },
      paginate (page) {
        this.page = page
        this.$emit('Pagination:page', page)
      },
      next: function () {
        return this.setPage(this.page + 1)
      },
      prev: function () {
        return this.setPage(this.page - 1)
      },
      setChunk: function (direction) {
        this.setPage((((this.currentChunk - 1) + direction) * this.chunk) + 1)
      },
      allowedPage: function (page) {
        return page >= 1 && page <= this.totalPages
      },
      allowedChunk: function (direction) {
        return (parseInt(direction) === 1 && this.currentChunk < this.totalChunks)
          || (parseInt(direction) === -1 && this.currentChunk > 1)
      },
      allowedPageClass: function (direction) {
        return this.allowedPage(direction) ? '' : 'disabled'
      },
      allowedChunkClass: function (direction) {
        return this.allowedChunk(direction) ? '' : 'disabled'
      },
      activeClass: function (page) {
        return parseInt(this.page) === parseInt(page) ? 'active' : ''
      }
    }
  }

  function range (start, chunk, total) {
    if( start + chunk > total) {
      start = Math.max(total - chunk + 1, 1);
    }
    var end = chunk > total ? total : chunk;
    return Array.apply(0, Array(end))
      .map(function (element, index) {
        return index + start
      })
  }
</script>

<style lang="scss" scoped>
@import "@/assets/sass/variables";

  .VuePagination {
    margin: 15px 0px;
    display: flex;
    .group_number_items {
      display: inline-block;
      float: left;
      line-height: 20px;
      color: $color_dove_gray;
      font-size: $font-small;
      font-weight: 500;
      font-family: $font-family-roboto;

      .txt_nb_itme {
        display: inline-block;
        float: left;
        margin-right: 8px;
        line-height: 20px;
        padding: 5px 0px; 
      }
      .list_number_items {
        box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0.0);
        border-radius: 0px; 
        height: 30px;
        border: 1px solid $color_alto;
        background-color: transparent;
        color: $color_dove_gray;
        font-size: $font-small;
        line-height: 20px;
        padding: 4px;
        border-radius: 3px;
        //&:hover, &:active, &:focus {
        //  border-color: $color-jungle-green;
        //}
      }
    }
    .VuePagination__pagination {
      float: right;
      margin: 0px;
      font-family: $font-family-roboto;
      font-size: $font_root;
      display: flex;
      justify-content: end;
      li {
        a {
          background-color: transparent;
          border: 0px;
          height: 30px;
          min-width: 30px; 
          line-height: 20px;
          text-align: center;
          padding: 5px;
          color: $color_dove_gray;
          font-weight: 500;
          border-radius: 0px;
          cursor: pointer;
          &:hover {
            color: #2dac91;
            text-decoration: underline;
          }
        }
        &.disabled {
          a {
            color: #c7cbd3;
            cursor: not-allowed;
          }
        }
        &.active {
          a {
            color: #2dac91;
          }
        }
        &.page-next {
          a {
            width: 30px !important;
            min-width: 30px !important;
            display: flex;
            align-items: center;
            text-decoration: none;
            justify-content: center;
            span {
              font-size: $font_mini_mini;
              margin-left: 4px;
            }
          }
        }
        &.page-prev {
          a {
            //width: 60px !important;
            //min-width: 60px !important;
            width: 30px !important;
            min-width: 30px !important;
            display: flex;
            align-items: center;
            text-decoration: none;
            justify-content: center;
            transform: rotateY(180deg);
            span {
              font-size: $font_mini_mini;
              margin-right: 4px;
            }
          }
        }
      }
    }
  }

</style>
