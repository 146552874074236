<template>
  <div class="boxCore manual_entry history " id="manual_entry">
    <div class="datatable history">
      <div class="filter-container">
        <div class="filter-item date mr_left0">
          <div class="text-date">{{ $t('orders.order_history.date') }}:</div>
          <date-picker :format="customFormatter" class="date-picker-input no-right-radius"
                       v-model="startDate"></date-picker>
          <date-picker :format="customFormatter" class="date-picker-input no-left-radius"
                       v-model="endDate"></date-picker>
        </div>

        <div class="filter-item date toolbar-element2">
          <div class="text-pair">{{ $t('entry_leaderboard.contest') }}:</div>
          <select-box :options="contestOptions"
                      class="contest-list-select"
                      v-model="filters.contest"
          />
        </div>

        <div class="filter-item date toolbar-element2">
          <div class="text-pair">{{ $t('entry_leaderboard.team') }}:</div>
          <select-box :options="teamOptions" :disabled="!filters.contest.id"
               class="select-market" v-model="filters.team"
          />
        </div>


        <div class="filter-item amount">
          <div class="label-text text-pair" style="padding-left: 16px">{{ $t('airdrop_setting.email') }}:</div>
          <div class="toolbar-element2">
            <input :placeholder="$t('orders.order_history.search_by_email')" @keyup.enter="search" class="form-control" name="searchKey"
                   title="" type="text" v-model="filters.email"/>
          </div>
        </div>

        <div class="filter-item btn-action">
          <button @click="search()" class="btn btn-primary">{{ $t('orders.order_history.search') }}</button>
          <button @click="refresh()" class="btn btn-reset">{{ $t('orders.order_history.reset') }}</button>
        </div>
      </div>


      <data-table-4 :column='9'
                    :getData="getData"
                    :limit="10"
                    :onPageLoad="true"
                    @DataTable:finish="onDatatableFinish"
                    class="scroll"
                    id="entry-manual-history-table" ref="datatable">
        <th class="text-left col-1" data-sort-field="user_id">{{ $t('margin.orders.position.owner_id') }}</th>
        <th class="text-left col-2" data-sort-field="email">{{ $t('base_deposit_withdraw_title_email') }}</th>
        <th class="text-left col-3" data-sort-field="contest_name">Contest</th>
        <th class="text-left col-4" data-sort-field="team_name">Team</th>
        <th class="text-left col-1" data-sort-field="team_id">Team ID</th>
        <th class="text-left col-5" data-sort-field="bonus_amount">Paid Amount</th>
        <th class="text-left col-6" data-sort-field="status">{{ $t('airdrop_setting.status') }}</th>
        <th class="text-left col-7" data-sort-field="created_at">{{ $t('table.created_date')}}</th>
        <template slot="body" slot-scope="props">
          <tr>
            <td class="text-left" style="min-width: 100px;">
              {{ props.item.user_id }}
            </td>
            <!-- <td class="text-left" style="min-width: 200px;">
              {{ props.item.email }}
            </td> -->
            <td>
              <div class="item_email_setting">
                <span class="txt_email_setting">{{ props.item.email }}</span>
                <span class="tooltip_email_setting">{{ props.item.email }}</span>
              </div>
            </td>
            <!-- <td class="text-left" style="min-width: 200px;">
              {{ props.item.contest_name }}
            </td> -->
            <td>
              <div class="item_name_setting">
                <span class="txt_email_setting">{{ props.item.contest_name }}</span>
                <span class="tooltip_name_setting">{{ props.item.contest_name }}</span>
              </div>
            </td>
            <!-- <td class="text-left" style="min-width: 200px;">
              {{ props.item.team_name }}
            </td> -->
            <td>
              <div class="item_name_setting">
                <span class="txt_email_setting">{{ props.item.team_name }}</span>
                <span class="tooltip_name_setting">{{ props.item.team_name }}</span>
              </div>
            </td>
            <td class="text-left" style="min-width: 110px;">
              {{ props.item.team_id }}
            </td>
            <td class="text-left" style="min-width: 150px;">
              {{ props.item.bonus_amount | toNumber}} {{ props.item.bonus_currency | uppercase}}
            </td>
            <td class="text-left" style="min-width: 110px;">
              {{ props.item.status | upperFirst}}
              <!-- {{ props.item.status | getStatus}} -->
            </td>
            <td class="text-left" style="min-width: 172px;">
              {{ props.item.created_at }}
            </td>

          </tr>
        </template>
      </data-table-4>
    </div>
  </div>
</template>

<script>
  import rf from '../../lib/RequestFactory';
  import DatePicker from "vuejs-datepicker";
  import moment from "moment";
  import COMMON_CONST from "../../common/Const";
  import SelectBox from "../../components/SelectBox";
  import CoinInput from '../../common/CoinInput.vue'
  import CurrencyInput from "../../components/CurrencyInput";
  import DataTable4 from "../../components/DataTable4";

  export default {
    components: {
      DatePicker,
      SelectBox,
      CoinInput,
      CurrencyInput,
      DataTable4
    },
    props: {
      disabled: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        product_base: COMMON_CONST.PRODUCT_BASE,
        titlePage: 'Entry Manual Paid History',
        startDate: moment().subtract(1, 'week').toDate(),
        endDate: moment().toDate(),
        filters: {
          contest: {id: '', name: ''},
          team: {id: '', name: ''},
          email: '',
        },
        contestOptions: [{id: '', name: 'All'}],
        teamOptions: [{id: '', name: 'All'}],
      }
    },
    watch: {
      'filters.contest'() {
        this.getAllTeams();
      }
    },
    created() {
      this.getAllContests();
    },
    filters: {
      formatDate: function (val) {
        return val ? val.substring(0, 10) : '';
      },
      getStatus: function (val) {
        const status = COMMON_CONST.BONUS_BALANCE_TRANSFER_STATUS;
        let str = status[val];
        return window.i18n.t(str);
      }
    },
    methods: {
      getAllTeams() {
        let params = {
          "contest_id": this.filters.contest.id,
        }
        if (!this.filters.contest.id) {
          this.teamOptions = [{id: '', name: 'All'}];
          this.filters.team = this.teamOptions[0];
        } else {
          rf.getRequest('MarginOrderRequest').getAllTeamByContest(params).then((res) => {
            this.teamOptions = [{id: '', name: 'All'}, ...res.data];
            this.filters.team = this.teamOptions[0];
          })
        }
      },
      convertTime(time) {
        let stillUtc = moment.utc(time).toDate();
        let local = moment(stillUtc).local().format('YYYY-MM-DD HH:mm:ss');
        return local;
      },
      search() {
        let filterParams = {
          start_date: (moment(this.startDate)
              .startOf("day")
              .format("YYYY-MM-DD HH:mm:ss")),
          end_date: (moment(this.endDate)
              .endOf("day")
              .format("YYYY-MM-DD HH:mm:ss")),
          email: this.filters.email,
          contest: this.filters.contest.id,
          team: this.filters.team.id,
        };

        if (moment(this.startDate).format('YYYY MM DD') > moment(this.endDate).format('YYYY MM DD')) {
          this.$toastr("error", this.$t("common.date.warning"));
          return;
        }
        this.$refs.datatable.filter(filterParams);
        // this.$refs.datatable.filter(this.searchParams());
      },
      getData(params) {
        let filterParams = {
          start_date: (moment(this.startDate)
              .startOf("day")
              .format("YYYY-MM-DD HH:mm:ss")),
          end_date: (moment(this.endDate)
              .endOf("day")
              .format("YYYY-MM-DD HH:mm:ss")),
        };
        filterParams.type = this.filters.type;
        filterParams.type_manual = "entry";
        return rf.getRequest('AdminRequest').getManualDividendHistory(Object.assign(params, filterParams)).then(res => {
          if (res.data != undefined) {
            return res.data;
          }
        }).catch(error => {
          console.log(error);
        });
      },
      refresh() {
        this.resetInput();
        this.$refs.datatable.refresh();
      },

      resetInput() {
        this.filters = Object.assign(this.filters, {
          contest: {id: '', name: ''},
          team: {id: '', name: ''},
          email: '',
        });
        this.startDate = moment().subtract(1, 'week').toDate();
        this.endDate = moment().toDate();
      },
      getAllContests() {
        rf.getRequest('MarginOrderRequest').getAllContestsHavingTradingVolume().then((res) => {
          this.contestOptions = [{id: '', name: 'All'}, ...res.data]
        })
      },
      onDatatableFinish() {
        const datatable = this.$refs.datatable;
      },

      capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
      },

      formatDateTime(date) {
        return moment(date, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss');
      },

      customFormatter(date) {
        return moment(date).format('YYYY - MM - DD');
      },

    },
    mounted() {
      this.$emit('EVENT_PAGE_CHANGE', this);
    }

    // ALl on load binding

  }
</script>

<style lang="scss" scoped>
@import "@/assets/sass/variables";

  .col-1 {
    width: 10%;
  }

  .col-2 {
    width: 15%;
  }

  .col-3 {
    width: 20%;
  }

  .col-4 {
    width: 20%;
  }

  .col-5 {
    width: 10%;
  }

  .col-6 {
    width: 10%;
  }

  .col-7 {
    width: 15%;
  }

  .select_transaction_container {
    padding: 10px 0px 20px 0px;

    .input-radio {
      label {
        display: inline;
        font-weight: normal;
        text-align: center;
        margin-right: 25px;

        span {
          font-size: $font_big;
        }

        input[type="radio"] {
          display: inline-block;
          margin-top: 0px;
          position: relative;
          top: -2px;
        }
      }
    }
  }

  .tab-header {
    display: block;
    width: 100%;
    float: left;
    margin: 10px 0px 23px 0px;

    ul {
      list-style: none;
      padding-left: 0px;

      li {
        float: left;
        margin-right: 25px;
        font-size: $font_big;
        color: $color-grey-dark;
        display: block;

        > a {
          color: $color-grey-dark;
          font-family: $font-family-roboto-medium;
          font-weight: 500;
          font-size: $font_big;
          display: block;
          float: right;

          &::before {
            content: "";
            display: inline-block;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            border: solid 1px #979797;
          }

          span {
            padding-left: 5px;
          }
        }

        &.active {
          > a {
            &::before {
              border: solid 4px $color-jungle-green;
            }

            color: $color-jungle-green;
          }
        }
      }
    }
  }

  .empty-data {
    text-align: center;
    padding-top: 15px;
    background-color: $color_white;
  }

  .item_name_setting {
    display: inline-block;
    float: left;
    position: relative;

    .txt_email_setting {
      display: block;
      max-width: 100px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &:hover {
      .tooltip_name_setting {
        transition: 0.5s;
        display: inline-block;
      }
    }

    .tooltip_name_setting {
      position: absolute;
      top: 0px;
      line-height: 20px;
      padding: 5px 20px;
      left: 100%;
      background-color: $color_white;
      width: 300px;
      z-index: 10;
      font-size: $font_root;
      font-weight: 500;
      color: $color_mine_shaft;
      transition: 0.5s;
      display: none;
      box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.4);

      &:after {
        right: 100%;
        top: 50%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: rgba(136, 183, 213, 0);
        border-right-color: $color_white;
        border-width: 5px;
        margin-top: -5px;
      }
    }
  }

  .w_20 {
    width: 20%
  }

  .w_25 {
    width: 25%;
  }

  .opacity-row {
    opacity: 0.4;
  }

  .text-wrap {
    word-wrap: break-word;
  }

  .toolbar-element2 {
    display: inline-block;
    float: left;
  }

  .bw-element {
    float: left;
    font-size: $font_medium;
    margin-left: 5px;
    height: 30px;
    line-height: 30px;
    margin-right: 5px;
    color: $color-grey-dusty;
  }

  .checkbox {
    margin-top: 0px;
    display: inline-block;
    font-size: $font_root;
    margin-bottom: 0px;
    font-weight: normal;
    height: 30px;
    line-height: 30px;

    input[type="checkbox"] {
      display: none;
    }

    span {
      position: relative;
      content: "";
      display: inline-block;
      border: 1px solid $color_alto;
      width: 16px;
      height: 16px;
      top: 3px;
      left: 8px;
      border-radius: 2px;
      margin-right: 8px;
      position: relative;
      background-color: $color_white;
    }

    input:checked {
      & + span {
        &:after {
          opacity: 0.9;
          content: '';
          position: absolute;
          width: 14px;
          height: 7px;
          background: transparent;
          top: 2px;
          left: 0px;
          border: 3px solid $color_blue_logo;
          border-top: none;
          border-right: none;
          transform: rotate(-45deg);
          -webkit-transform: rotate(-45deg);
          -moz-transform: rotate(-45deg);
          -o-transform: rotate(-45deg);
          -ms-transform: rotate(-45deg);
        }
      }
    }
  }

  .manual_entry .history {
    margin-bottom: 100px;

    .filter-container {
      margin: 10px 0px 30px 0px;
      height: 50px;

      .filter-item.kyc {
        .toolbar-element2 {
          width: 100px;
        }
      }

      .filter-item {
        float: left;
        margin: 0 5px;

        &.mr_left0 {
          margin-left: 0px;
        }

        input {
          margin-top: 23px;
          display: inline-block;
          width: 145px;
          font-family: $font-family-roboto;

          &:hover, &:active, &:focus {
            border-color: $color-jungle-green;
          }
        }

        select {
          width: 65px;
          display: inline-block;
          font-family: $font-family-roboto;
          font-size: $font_root;

          &:hover, &:active, &:focus {
            border-color: $color-jungle-green;
          }
        }

        button {
          width: auto;
          margin: 23px 2px 0px 2px;
          height: 30px;
          font-size: $font-smaller;
          font-family: $font-family-roboto;
        }

        .btn-reset {
          background-color: $color-caribbean-green;
          border: 1px solid $color-caribbean-green;
          width: 80px;
          text-transform: uppercase;
          color: $color-white;
          font-weight: 600;

          &:hover {
            border-color: $color-aquamarine;
            background-color: $color-aquamarine;
          }
        }

        .btn-primary {
          padding: 0;
          min-width: 80px;

          &:hover {
            border-color: $color-aquamarine;
            background-color: $color-aquamarine;
          }
        }

      }

      .pair {
        input {
          width: 65px;
        }
      }

      .balance {
        .toolbar-element2 {
          width: 150px;
          margin-left: 15px;
          min-width: 172px;

        }
      }

      .amount {
        input {
          margin-top: 0px;
          height: 30px;
          margin-left: 16px;
        }
      }

      .text-date {
        color: $color-grey-dusty;
        margin-bottom: 5px;
        font-size: 13px;
        font-family: Roboto-Regular;
      }

      .text-pair {
        font-family: Roboto-Regular;
        color: $color-grey-dusty;
        margin-bottom: 5px;
        font-size: 13px;
      }

      .side {
        margin: 0 10px;
      }
    }

    .form-control {
      background: $color-white-smoke;
      height: 30px;
      font-size: $font_root;
      padding-left: 11px;
      padding-right: 0;
    }

    .datatable {
      button.btnForm.bt-action {
        padding-left: 5px !important;
        padding-right: 5px !important;
        font-weight: 400 !important;
      }

      table {
        thead {
          th {
            padding: 8px 10px 4px 14px !important;

            &:first-child:after {
              width: 15px;
            }
          }

        }

        tbody {
          tr {
            td {
              .item_email_setting {
                display: inline-block;
                float: left;
                position: relative;

                .txt_email_setting {
                  display: block;
                  max-width: 244px;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                }

                .tooltip_email_setting {
                  position: absolute;
                  top: 0px;
                  left: 0px;
                  line-height: 20px;
                  padding: 5px 20px;
                  left: 100%;
                  background-color: $color_white;
                  white-space: nowrap;
                  width: auto;
                  z-index: 10;
                  font-size: $font_root;
                  font-weight: 500;
                  color: $color_mine_shaft;
                  transition: 0.5s;
                  display: none;
                  box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.4);

                  &:after {
                    right: 100%;
                    top: 50%;
                    border: solid transparent;
                    content: " ";
                    height: 0;
                    width: 0;
                    position: absolute;
                    pointer-events: none;
                    border-color: rgba(136, 183, 213, 0);
                    border-right-color: $color_white;
                    border-width: 5px;
                    margin-top: -5px;
                  }
                }

                &:hover {
                  .tooltip_email_setting {
                    display: block;
                    transition: 0.5s;
                  }
                }
              }
            }
          }
        }
      }
    }

    .buy {
      color: $text-color-jade;
    }

    .sell {
      color: $text-color-red;
    }

    .btn-detail {
      background-color: $color-caribbean-green;
      padding: 2px 10px;
      font-size: $font-smaller;
      color: $color-white;
      border: 1px solid $color-caribbean-green;
      border-radius: 3px;
      text-transform: uppercase;
      font-family: $font-family-roboto;

      &:hover {
        background-color: $color-aquamarine;
        border-color: $color-aquamarine;
      }
    ;
    }
  }

  .form-group-detail {
    .group-detail-form-group {
      .group-detail-form-label {
        width: 100%;
        color: $color-grey-dusty;
        float: left;
        font-weight: normal;
        font-family: $font-family-roboto;
        margin-bottom: 0px;
        margin-top: 10px;
      }

      .group-detail-form-value {
        width: 100%;
        padding: 8px 16px;
        border: 1px solid #dee3eb;
        height: 35px;
        line-height: 35px;
        border-radius: 3px;
        font-family: $font-family-roboto;
        // font-weight: 600;
        &:focus {
          border-color: $color-jungle-green;
        }
      }

      .textarea_input {
        height: 120px;
      }
    }

    .error-box {
      display: block;
      float: left;
      overflow: hidden;
      width: 100%;

      span {
        font-family: $font-family-roboto-medium !important;
      }
    }

    .is-error {
      color: #ff5252 !important;
    }

    .input-error {
      border: 1px solid #f74940 !important;
    }
  }
</style>
<style lang="scss">
@import "@/assets/sass/variables";

  #manual_entry {
    #entry-manual-history-table {
      .box-table4 {
        overflow-x: inherit;
      }
    }

    .toolbar-element2 {
      .button_drop_search {
        width: 200px;
        display: flex;
        align-items: center;

        > span {
          word-break: break-word;
          max-width: 95%;
        }

        i {
          margin-left: auto !important;
        }
      }

      .list_search_select, .box_list_search_select {
        span {
          word-break: break-word;
          max-width: 95%;
        }
      }

      .box_list_search_select {
        width: 300px !important;
        max-height: 270px;
        overflow: overlay;
        box-shadow: 0px 3px 12px rgba(49, 49, 49, 0.51);

        &::-webkit-scrollbar {
          width: 5px;
        }

        &::-webkit-scrollbar-thumb {
          background: #ddd;
        }

        &::-webkit-scrollbar-track {
          background: #f1f1f1;
        }
      }
    }

    .history {
      table tbody td {
        padding-right: 0px !important;

        .item_email_setting {
          display: inline-block;
          float: left;
          position: relative;

          .txt_email_setting {
            display: block;
            max-width: 244px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }

          .tooltip_email_setting {
            position: absolute;
            top: 0px;
            left: 0px;
            line-height: 20px;
            padding: 5px 20px;
            left: 100%;
            background-color: $color_white;
            white-space: nowrap;
            width: auto;
            z-index: 10;
            font-size: $font_root;
            font-weight: 500;
            color: $color_mine_shaft;
            transition: 0.5s;
            display: none;
            box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.4);

            &:after {
              right: 100%;
              top: 50%;
              border: solid transparent;
              content: " ";
              height: 0;
              width: 0;
              position: absolute;
              pointer-events: none;
              border-color: rgba(136, 183, 213, 0);
              border-right-color: $color_white;
              border-width: 5px;
              margin-top: -5px;
            }
          }

          &:hover {
            .tooltip_email_setting {
              display: block;
              transition: 0.5s;
            }
          }

        }
      }
    }

    .tab-header {
      display: block;
      width: 100%;
      float: left;
      margin: 10px 0px 23px 0px;

      ul {
        list-style: none;
        padding-left: 0px;

        li {
          float: left;
          margin-right: 25px;
          font-size: $font_big;
          color: $color-grey-dark;
          display: block;

          > a {
            color: $color-grey-dark;
            font-family: $font-family-roboto-medium;
            font-weight: 500;
            font-size: $font_big;
            display: block;
            float: right;

            &::before {
              content: "";
              display: inline-block;
              width: 12px;
              height: 12px;
              border-radius: 50%;
              border: solid 1px #979797;
            }

            span {
              padding-left: 5px;
            }
          }

          &.active {
            > a {
              &::before {
                border: solid 4px $color-jungle-green;
              }

              color: $color-jungle-green;
            }
          }
        }
      }
    }

    .filter-item.amount {
      margin-left: -10px;
    }

    .filter-item.pair {
      .sc_search_select {
        .group_search_select {
          .button_drop_search {
            > span {
              max-width: 275px;
              text-overflow: ellipsis;
              line-height: 23px;
              display: block;
            }

            min-height: 30px;
            width: 300px;
            border-radius: 3px;
            font-size: $font-small;
            font-family: $font-family-roboto;

            i.icon-arrow1 {
              color: $color_dove_gray;
            }

            &:hover, &:active, &:focus {
              border-color: $color-jungle-green;

              i.icon-arrow1 {
                color: $color-jungle-green;
              }
            }
          }

          .box_list_search_select {
            max-height: 270px;
            overflow: overlay;
            width: 300px;
            box-shadow: 0px 3px 12px rgba(49, 49, 49, 0.51);

            &::-webkit-scrollbar {
              width: 5px;
            }

            &::-webkit-scrollbar-thumb {
              background: #ddd;
            }

            &::-webkit-scrollbar-track {
              background: #f1f1f1;
            }

            .list_search_select {
              li {
                word-break: break-all;
                font-family: $font-family-roboto;
                font-size: $font-small;

                &:hover, &:active, &:focus {
                  color: $color-jungle-green;
                }
              }
            }
          }
        }
      }
    }

    .error-msg {
      color: $color-red-main;
    }

    .unit {
      top: 50%;
      transform: translateY(-50%);
      right: 0;
      position: absolute;
      // width: 200px;
      text-align: center;
    }

    .borderrrr {
      border: solid 1px #299b82;
    }

    .error {
      border: solid 1px $color-red-main;
      color: $color-red-main
    }

    .Rectangle-6-Copy-9 {
      position: relative;
      overflow: hidden;
      display: block;
      padding-bottom: 1px;
      text-align: left;
      border-radius: 3px;
    }
  }
</style>