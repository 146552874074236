<template>
  <div class="deposit_pages boxCore" id="account_list">
    <div class="account_list-filter">
      <div class="filter-template">
        <div class='label-text'>{{ $t('funds.balances.from') }}</div>
        <date-picker v-model="startDate" class="date-picker-input" :format="customFormatter"></date-picker>
      </div>
      <div class="filter-template">
        <div class='label-text'>{{ $t('funds.balances.to') }}</div>
        <date-picker v-model="endDate" class="date-picker-input" :format="customFormatter"></date-picker>
      </div>
      <div class="filter-template type">
        <div class="label-text date-text">{{ $t('account.list.level') }}</div>
        <div class="toolbar-element">
          <select-box v-model="filters.level" :placeholder="$t('order.order_history.all')" :options="levelData" />
        </div>
      </div>
      <div class="filter-template status">
        <div class="label-text date-text">{{ $t('account.list.kyc_status') }}</div>
        <div class="toolbar-element">
          <select-box v-model="filters.kycStatus" :placeholder="$t('order.order_history.all')"
            :options="kycStatusData" />
        </div>
      </div>
      <div class="filter-template type">
        <div class="label-text date-text">{{ $t('account.list.fa') }}</div>
        <div class="toolbar-element">
          <select-box v-model="filters.fa" :placeholder="$t('order.order_history.all')" :options="faData" />
        </div>
      </div>
      <div class="filter-template status">
        <div class="label-text date-text">{{ $t('account.list.active_status') }}</div>
        <div class="toolbar-element">
          <select-box v-model="filters.status" :placeholder="$t('order.order_history.all')"
            :options="activeStatusData" />
        </div>
      </div>
      <div class="filter-template">
        <div class="label-text">&nbsp;</div>
        <input title type="text" :placeholder="$t('account.list.search_by')" @keyup.enter="search"
          class="form-control search_symbol" name="searchKey" v-model="filters.searchKey">
      </div>
      <div class="filter-template">
        <div class="label-text">&nbsp;</div>
        <button class="btn btn-reset btn-search" @click="search()">{{ $t('orders.open_order.search') }}</button>
        <button class="btn btn-reset" @click="refresh()">{{ $t('orders.open_order.reset') }}</button>
        <button class="btn btn-download" @click="downloadExcel()">
          <img src="@/assets/images/download-icon.svg" width="12" />
          {{ $t('kyc.data') }}
        </button>
      </div>
    </div>

    <div class="table-futures">
      <data-table-4 :getData="getData" ref="datatable" :column="12" :limit="10" :inLine="true"
        class="scroll" :total-user="totalRecords">
        <th class="text-left" data-sort-field="id account">{{ $t('order.open_order.id_account') }}</th>
        <th class="text-left" data-sort-field="email">{{ $t('airdrop_setting.email') }}</th>
        <th class="text-left" data-sort-field="level">{{ $t('account.list.level') }}</th>
        <th class="text-center" data-sort-field="kyc status">{{ $t('user.kyc_status') }}</th>
        <th class="text-center" data-sort-field="2fa">{{ $t('user.fa') }}</th>
        <th class="text-center" data-sort-field="active status">{{ $t('user.active_status') }}</th>
        <th class="text-left" data-sort-field="time-create">{{ $t('account.transaction.created_time') }}</th>
        <th class="text-center">{{ $t('order.open_order.action') }}</th>

        <template slot="body" slot-scope="props">
          <tr>
            <td class="text-left">
              {{ props.item.accountID }}
            </td>
            <td class="text-left">
              <div class="text-top">{{ props.item.email }} </div>
            </td>
            <td class="text-left">
              <div class="text-top">{{ props.item.level }} </div>
            </td>
            <td class="text-center">
              <div class="bt inactive" v-if="props.item.kyc_status == '' || props.item.kyc_status == 0">{{ props.item | kyc_status_account }}</div>
              <div class="bt" :class="props.item.kyc_status" v-else>{{ props.item | kyc_status_account }}</div>
            </td>
            <td class="text-center">
              <span>
                <img v-if="props.item['2FA'] === 1" src="@/assets/images/account/check.svg" width="20px"
                  height="20px" />
                <img v-else class="imge" src="@/assets/images/account/cancel.svg" width="20px" height="20px" />
              </span>
            </td>
            <td class="text-center">
              <div v-if="props.item.status === 'active'">{{ $t("account.status.active") }}</div>
              <div v-else>{{ $t("account.status.inactive") }}</div>
            </td>
            <td class="text-left">
              <div class="text-top">{{ props.item.creation_time | dateFormat2('YYYY-MM-dd') }} </div>
              <div class="text-bottom">{{ props.item.creation_time | timeFormat2('HH:mm:ss') }}</div>
            </td>
            <td class="text-center">
              <span class="img-all">
                <img @click="detailAccount(props.item)" class="imge" src="@/assets/images/future/List.svg" width="20px"
                  height="20px" />
              </span>
            </td>
          </tr>
        </template>
      </data-table-4>
    </div>

    <div class="clearfix clearfix-40"></div>
  </div>
</template>

<script>

import moment from "moment";
import COMMON_CONST from "../../common/Const";
import rf from "../../lib/RequestFactory";
import { mapActions } from "vuex";
import DatePicker from 'vuejs-datepicker';
import Modal from "../../components/Modal";
import SelectBox from "../../components/SelectBox";
import BigNumber from "bignumber.js";
import DataTable4 from "../../components/DataTable4.vue";

export default {
  name: "AccountList",
  components: {
    Modal,
    DatePicker,
    SelectBox,
    DataTable4
  },
  data() {
    return {
      titlePage: window.i18n.t('menu.account.account_list'),
      searchKey: '',
      isActive: true,
      startDate: moment().subtract(1, 'week').toDate(),
      endDate: moment().toDate(),
      filters: {
        searchKey: "",
        level: null,
        kycStatus: null,
        fa: null,
        status: null
      },
      isLoading: false,
      kycStatusData: [
        { id: '', name: window.i18n.t("order.order_history.all") },
        { id: 'pending', name: window.i18n.t("kyc.status_pending") },
        { id: 'verified', name: window.i18n.t("kyc.status_verified") },
        { id: 'rejected', name: window.i18n.t("kyc.status_rejected") },
      ],
      levelData: [
        { id: '', name: window.i18n.t("order.order_history.all") },
        { name: '1', id: '1' },
        { name: '2', id: '2' },
        { name: '3', id: '3' },
        { name: '4', id: '4' },
        { name: '5', id: '5' },
      ],
      faData: [
        { id: '', name: window.i18n.t("order.order_history.all") },
        { name: window.i18n.t("kyc.2fa.on"), id: '1' },
        { name: window.i18n.t("kyc.2fa.off"), id: '0' },
      ],
      activeStatusData: [
        { id: '', name: window.i18n.t("order.order_history.all") },
        { name: 'Active', id: 'active' },
        { name: 'Inactive', id: 'inactive' },
      ],
      totalRecords: 0,
    }
  },
  watch: {
    isActive() {
      this.$refs.datatable.refresh();
    }
  },
  methods: {
    selectTab(tab) {
      this.isActive = tab;
      this.filters.searchKey = '';
      this.$refs.datatable.refresh();
      this.startDate = moment().subtract(1, 'week').toDate();
      this.endDate = moment().toDate();
    },
    detailAccount(row) {
      this.$router.push({
        name: 'account_detail',
        query: { id: row.user_id },
        params: {
          startDate: this.startDate,
          endDate: this.endDate,
          filters: this.filters
        },
        props: true
      });
    },

    customFormatter(date) {
      return moment(date).format('YYYY-MM-DD');
    },
    search() {
      let filterParams = {
        start_date: moment(this.startDate)
          .startOf("day")
          .format("x"),
        end_date: moment(this.endDate)
          .endOf("day")
          .format("x")
      };
      if (this.customFormatter(this.startDate) === this.customFormatter(this.endDate)) {
        filterParams = {
          start_date: moment(this.startDate)
            .startOf("day")
            .format("x"),
          end_date: moment(this.startDate)
            .endOf("day")
            .format("x")
        }
      }
      if (this.customFormatter(this.startDate) > this.customFormatter(this.endDate)) {
        this.$toastr("error", this.$t("common.date.warning"));
        return;
      }
      if (this.filters.searchKey) {
        // filterParams.search_key = this.filters.searchKey;
        filterParams.s = this.filters.searchKey;
      }
      this.$refs.datatable.filter(filterParams);
    },
    refresh() {
      this.resetInput();
      this.$refs.datatable.refresh();
    },
    resetInput() {
      this.filters = Object.assign(this.filters, {
        searchKey: "",
        kycStatus: null,
        level: null,
        status: null,
        fa: null,
      });
      this.startDate = moment().subtract(1, 'week').toDate();
      this.endDate = moment().toDate();
      $(".datatable").find("th").attr("data-sort-order", "");
    },
    listenForNotification() {
      window.Echo.channel('App.Models.Admin')
        .listen('AdminNotificationUpdated', () => {
          this.$refs.datatable.refresh();
        });
    },
    getData(params) {
      let filterParams = {
        start_date: moment(this.startDate)
          .startOf("day")
          .format("x"),
        end_date: moment(this.endDate)
          .endOf("day")
          .format("x")
      };
      if (this.startDate > this.endDate && this.startDate.toDateString() != this.endDate.toDateString()) {
        this.$toastr("error", this.$t("common.date.warning"));
        return;
      }
      if (this.searchKey) {
        filterParams.s = this.searchKey;
      }
      if (this.filters.level && this.filters.level.id != '') {
        filterParams.level = this.filters.level.id;
      }
      if (this.filters.kycStatus && this.filters.kycStatus.id != '') {
        filterParams.kyc_status = this.filters.kycStatus.id;
      }
      if (this.filters.fa && this.filters.fa.id != '') {
        filterParams["2fa"] = this.filters.fa.id;
      }
      if (this.filters.status && this.filters.status.id != '') {
        filterParams.status = this.filters.status.id;
      }
      params.size = params.limit || 10;
      delete params.limit;
      const records = rf.getRequest('UserRequest').getAccountList(Object.assign({}, filterParams, params));
      records.then(data => {
        this.totalRecords = data.data.total;
      })
      return records;
    },
    getCurrencyPositionRecord(value) {
      if (value.contractType === 'COIN_M') {
        // return value.asset
        return 'Cont'
      } else {
        const index = value.symbol?.indexOf(value.asset);
        return value.symbol?.slice(0, index) + value.symbol?.slice(index + value.asset.length);
      }
    },
    downloadExcel() {
      let filterParams = {
        start_date: moment(this.startDate)
          .startOf("day")
          .format("x"),
        end_date: moment(this.endDate)
          .endOf("day")
          .format("x")
      };
      if (this.startDate > this.endDate && this.startDate.toDateString() != this.endDate.toDateString()) {
        this.$toastr("error", this.$t("common.date.warning"));
        return;
      }
      if (this.searchKey) {
        filterParams.search_key = this.searchKey;
      }
      if (this.filters.level && this.filters.level.id != '') {
        filterParams.level = this.filters.level.id;
      }
      if (this.filters.kycStatus && this.filters.kycStatus.id != '') {
        filterParams.kyc_status = this.filters.kycStatus.id;
      }
      if (this.filters.fa && this.filters.fa.id != '') {
        filterParams["2fa"] = this.filters.fa.id;
      }
      if (this.filters.status && this.filters.status.id != '') {
        filterParams.status = this.filters.status.id;
      }

      rf.getRequest('UserRequest').exportAccountData(filterParams).then(res => {
        if (res?.success) {
          window.open(`${process.env.VUE_APP_API_URL}/${res.data.path}`, '_blank')
        }
      })
    },
    filledOrder(value) {
      return new BigNumber(value.quantity).minus(value.remaining)
    },
    getOptionData() {
      this.kycStatusData = [{id: '', name: window.i18n.t("order.order_history.all")}]
      this.levelData = [{id: '', name: window.i18n.t("order.order_history.all")}]
      this.activeStatusData = [{id: '', name: window.i18n.t("order.order_history.all")}]
      rf.getRequest('UserRequest')
        .getOptionDataAccount('level')
        .then(res => {
          res?.data.map((item) => {
            this.levelData.push({
              id: item,
              name: item
            })
          })
        })
      rf.getRequest('UserRequest')
        .getOptionDataAccount('kyc_status')
        .then(res => {
          res?.data.map((item) => {
            this.kycStatusData.push({
              id: item,
              name: window.i18n.t(`kyc.status_${item}`)
            })
          })
        })
      rf.getRequest('UserRequest')
        .getOptionDataAccount('status')
        .then(res => {
          res?.data.map((item) => {
            this.activeStatusData.push({
              id: item,
              name: window.i18n.t(`account.status.${item}`)
            })
          })
        })
    },
    receiveParams() {
      const params = this.$route.params;
      if (Object.keys(params).length) {
        this.startDate = moment(params.startDate).toDate();
        this.endDate = moment(params.endDate).toDate();
        this.filters = params.filters;
      }
    }
  },
  created() {
    this.getOptionData();
    this.receiveParams();
  },  
  mounted() {
    this.$emit('EVENT_PAGE_CHANGE', this);
    this.listenForNotification();

  },

}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/variables";

.d-flex {
  display: flex;
}

.w-100 {
  width: 100%;
}

.buy {
  color: $text-color-jade !important;
}

.sell {
  color: $text-color-red !important;
}

#account_list {
  table {
    thead {
      th {
        &:first-child {
          width: 5%;
          min-width: 110px;
        }

        &:nth-child(2) {
          width: 10%;
          min-width: 100px;
        }

        &:nth-child(3) {
          width: 5%;
          min-width: 65px;
        }

        &:nth-child(4) {
          width: 6%;
          min-width: 90px;
        }

        &:nth-child(5) {
          width: 6%;
          min-width: 100px;
        }

        &:nth-child(6) {
          width: 8%;
          min-width: 85px;
        }

        &:nth-child(7) {
          width: 5%;
          min-width: 100px;
        }

        &:nth-child(8) {
          width: 1%;
          min-width: 80px;
        }
      }
    }
  }
}

.no-left-radius {
  input {
    border-radius: 0px 3px 3px 0px;
  }
}

.no-right-radius {
  input {
    border-radius: 3px 0px 0px 3px;
  }
}

td {
  word-break: break-all;
  vertical-align: middle !important;
  border-bottom: solid 1px $color-white-smoke;

  span, div {
    font-family: $mulish-regular;
  }

  .bt {
    width: 80px;
    height: 32px;
    border-radius: 3px;
    color: $color_black_logo;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 0px;
    margin: auto;

    &.pending {
      background: $color-pending-status;
    }

    &.verified {
      background: $color-filled-status;
    }

    &.rejected {
      background: $color-cancel-status;
    }

    &.inactive {
      background: $color_alto_gray;
    }
  }

  .img-all {
    .imge {
      margin-right: 6px;
      cursor: pointer;
    }
  }
}

.datatable {
  font-family: $font-family-roboto !important;
}

ul {
  list-style-type: none;
  padding: 0px;
}

.clearfix-40 {
  display: block;
  clear: both;
  height: 40px;
}

.deposit_pages {
  font-family: $font_family_root;

  .account_list-filter {
    display: flex;
    flex-wrap: wrap;
  }

  .filter_container {
    margin: 12px 0px;

    .title_item {
      color: $color_mine_shaft;
      font-size: $font_big_20;
      font-weight: 500;
      line-height: 28px;
      float: left;
    }

    .search_box {
      display: inline-block;
      float: right;
      width: 215px;
      max-width: 100%;

      .search_input {
        background-color: transparent;
        height: 28px;
        border: 1px solid $color_alto;
        padding: 4px 15px;
        line-height: 20px;
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: $font-small;
      }
    }
  }

  .item_email_user {
    display: inline-block;
    float: left;
    position: relative;

    .txt_email_user {
      display: block;
      max-width: 170px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .tooltip_email_user {
      position: absolute;
      top: 0px;
      left: 0px;
      line-height: 20px;
      padding: 5px 20px;
      left: 100%;
      background-color: $color_white;
      white-space: nowrap;
      width: auto;
      z-index: 10;
      font-size: $font_root;
      font-weight: 500;
      color: $color_mine_shaft;
      transition: 0.5s;
      display: none;
      box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.4);

      &:after {
        right: 100%;
        top: 50%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: rgba(136, 183, 213, 0);
        border-right-color: $color_white;
        border-width: 5px;
        margin-top: -5px;
      }
    }

    &:hover {
      .tooltip_email_user {
        display: block;
        transition: 0.5s;
      }
    }
  }

  .item_bank_user {
    display: inline-block;
    float: left;
    position: relative;

    .txt_bank_user {
      display: block;
      max-width: 250px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .tooltip_bank_user {
      position: absolute;
      top: 0px;
      left: 0px;
      line-height: 20px;
      padding: 5px 20px;
      left: 100%;
      background-color: $color_white;
      // white-space: nowrap;
      width: 250px;
      z-index: 10;
      font-size: $font_root;
      font-weight: 500;
      color: $color_mine_shaft;
      transition: 0.5s;
      display: none;
      box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.4);

      &:after {
        right: 100%;
        top: 12px;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: rgba(136, 183, 213, 0);
        border-right-color: $color_white;
        border-width: 5px;
        margin-top: -5px;
      }
    }

    &:hover {
      .tooltip_bank_user {
        display: block;
        transition: 0.5s;
      }
    }
  }

  .btn_Confirm,
  .btn_Reject {
    background-color: $color-caribbean-green;
    color: $color-white;
    text-transform: uppercase;
    width: 82px;
    height: 25px;
    line-height: 20px;
    padding: 0px 9px;
    text-align: center;
    font-size: $font-smaller;
    font-family: $font-family-roboto-bold;
    border: 1px solid $color-caribbean-green;
    border-radius: 3px;
    transition: 0.5s;

    &:hover {
      background-color: $color-aquamarine;
      border-color: $color-aquamarine;
      transition: 0.5s;
    }
  }

  .btn_Reject {
    margin-left: 10px;
  }

  .list_modal_deposit {
    margin-bottom: 25px;
    padding: 0px;

    li {
      line-height: 20px;
      margin-bottom: 10px;
      color: $color_dove_gray;
      font-size: $font_root;
      font-weight: 500;

      .text-right {
        font-weight: 600;
        color: $color_mine_shaft;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .content_modal_deposit {
    color: $color_mine_shaft;
    font-size: $font_semi_big;
    font-family: $font-family-roboto-medium;
    font-weight: 500;
    text-align: center;
    line-height: 24px;
  }
}

.select_transaction_container {
  padding: 10px 0px 20px 0px;

  .input-radio {
    label {
      display: inline;
      font-weight: normal;
      text-align: center;
      margin-right: 25px;

      span {
        font-size: $font_big;
      }

      input[type="radio"] {
        display: inline-block;
        margin-top: 0px;
        position: relative;
        top: -2px;
      }
    }
  }
}

.filter-container {
  margin: 10px 0 30px 0px;
  height: 60px;

  .filter-item {
    .label-text {
      font-size: $font-small;
      color: $color-grey-dusty;
      padding-bottom: 4px;
    }

    float: left;
    margin: 0px;
    margin-right: 20px;

    input {
      background-color: $color-white-smoke;
      margin-top: 22px;
      display: inline-block;
      width: 145px;
    }

    select {
      width: 65px;
      display: inline-block;
    }

    button {
      margin: 0 5px;
      width: auto;
      margin-top: 22px;
      height: 30px;
      font-size: 13px;
    }

    .btn-search,
    .btn-reset {
      font-size: $font-smaller;
      font-family: $font-family-roboto-bold;
      border-radius: 3px;
      border: 1px solid $color-caribbean-green;
      width: 85px;
      color: $color_white;
      text-transform: uppercase;
      background-color: $color-caribbean-green;

      &:hover {
        background-color: $color-aquamarine;
        border-color: $color-aquamarine;
        transition: 0.5s;
      }
    }
  }

  .btn-action {
    margin-left: -10px;
  }
}

.form-control {
  background: $color_concrete;
  height: 30px;
  font-size: $font-small;
  padding-left: 11px;
  padding-right: 0;

  &:hover,
  &:focus {
    border-color: $color-jungle-green;
  }
}

.datatable {
  button.btnForm.bt-action {
    padding-left: 5px !important;
    padding-right: 5px !important;
    font-weight: 400 !important;
  }
}

.buy {
  color: $text-color-jade;
}

.sell {
  color: $text-color-red;
}

.toolbar-element {
  display: inline-block;
  float: left;
  width: 120px;
}

td {
  font-family: $font-family-roboto;
}

.table-futures {
  margin-top: 24px;
}

.filter-template {
  margin-right: 15px;

  .label-text {
    color: $color-grey-dusty;
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 7px;
    margin-left: 5px;
    font-family: $mulish-regular;
  }

  .date-picker-input {
    display: inline-block;
    float: left;

    ::v-deep {
      input {
        padding: 0 5px 0px 36px;
        width: 135px !important;
        height: 32px;
        border: 1px solid #C7CBD3 !important;
        border-radius: 10px !important;
        background-image: url('@/assets/images/icon/date-time.svg') !important;
        background-color: $color-white !important;
        background-size: 12px !important;
        background-position: center left 12px !important;
        background-repeat: no-repeat !important;
        color: $dark-1;

        &:hover,
        &:active,
        &:focus {
          border-color: $color-jungle-green;
          z-index: 9;
        }
      }
    }

  }

  .btn-reset {
    background: $color-caribbean-green;
    border-radius: 10px;
    color: $color_white;
    font-weight: 700;
    width: 78px;
    height: 32px;
  }

  .btn-search {
    margin: 0 10px;
  }

  .btn-download {
    background: $color-caribbean-green;
    border-radius: 10px;
    color: $color_white;
    font-weight: 700;
    width: 95px;
    height: 32px;
    margin-left: 10px;
  }

  .search_symbol {
    background: $color-white;
    border: 1px solid #C7CBD3 !important;
    border-radius: 10px !important;
    width: 197px;
    height: 32px;
  }
}

.tab-header {
  display: block;
  width: 100%;
  float: left;
  margin: 10px 0px 23px 0px;

  ul {
    list-style: none;
    padding-left: 0px;

    li {
      float: left;
      margin-right: 25px;
      font-size: $font_big;
      color: $color-grey-dark;
      display: block;

      >a {
        color: $color-grey-dark;
        font-family: $font-family-roboto-medium;
        font-weight: 500;
        font-size: $font_big;
        display: block;
        float: right;

        &::before {
          content: "";
          display: inline-block;
          width: 12px;
          height: 12px;
          border-radius: 50%;
          border: solid 1px #979797;
        }

        span {
          padding-left: 5px;
        }
      }

      &.active {
        >a {
          &::before {
            border: solid 4px $color-jungle-green;
          }

          color: $color-jungle-green;
        }
      }
    }
  }
}

.input-dropdown {
  position: relative;

  input {
    background: $color-white;
    border: 1px solid #C7CBD3 !important;
    border-radius: 10px !important;
    width: 110px;
    height: 32px;
    cursor: pointer;
  }

  .icon-coin-input {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    color: #667186;

    i {
      font-size: 22px;
    }
  }

  .coin-option {
    position: absolute;
    top: 37px;
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0px 4px 15px rgba(0, 19, 54, 0.15);
    border-radius: 10px;
    padding: 17px 0;
    z-index: 3;

    &:after {
      bottom: 100%;
      left: 50%;
      border: solid transparent;
      content: " ";
      display: block;
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-color: rgba(136, 183, 213, 0);
      border-bottom-color: $color-white;
      border-width: 5px;
      margin-left: -5px;
    }

    .option {
      display: flex;
      align-items: center;
      width: 100%;
      line-height: normal;
      cursor: pointer;
      padding-left: 14px;
      font-size: 14px;
      font-weight: 500;
      overflow: hidden;
      color: #001336;
      height: 32px;

      &:hover {
        background: $color-athens;
      }
    }
  }
}
</style>

<style lang="scss">
@import "@/assets/sass/variables";

#account_list {
  .sc_search_select {
    .group_search_select {
      .button_drop_search {
        width: 100%;
        height: 32px;
        padding: 6px 10px 6px 10px !important;
        border-radius: 10px;
        margin-right: 20px;
        font-size: $font-small;
        font-family: $mulish-regular;
        background-color: $color-white;

        i.icon-arrow1 {
          margin-left: 5px;
          color: $color_dove_gray;
        }

        &:hover {
          border-color: $color-jungle-green;

          i.icon-arrow1 {
            color: $color-jungle-green;
          }
        }

      }

      .box_list_search_select {
        .list_search_select {
          border-radius: 10px;
          li {
            font-family: $mulish-regular;
            font-size: $font-small;

            &:hover,
            &:active,
            &:focus {
              color: $color-jungle-green;
            }
          }
        }
      }
    }
  }
}
</style>