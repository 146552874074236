<template>
    <div id="coldWalletSetting" class="clearfix mg_bot50">
        <div class="clearfix box-head">
            <span class="title_item">{{ $t('cold_wallet_setting.receive_title') }}</span>
        </div>
        <div class="clearfix box-content">
            <div class="wallet-setting" v-for="(item, index) in dataModel.cold_wallet">
                <div class="clearfix row-item col-50">
                    <!-- <label class="row-item-label" v-if="item.currency === 'omni.2'">{{ $t('cold_wallet_setting.usdt_address') }}</label>
                    <label class="row-item-label mw" v-else>{{ $t('cold_wallet_setting.address', { currency: item.coin.toUpperCase() }) }}</label> -->
                    <label class="row-item-label mw">{{ $t('cold_wallet_setting.address', { currency: item.coin && item.coin.toUpperCase() }) }}</label>
                    <div class="row-item-value">
                        <input @change="inputHandle(item)" v-model="item.address" :maxlength="maxLength" @click="commonHandle" :disabled="checkEditable(item.currency)" :class="{'input-error': errors.has(item.currency + '_address')}"/>
                        <div class="error-box">
                            <span v-show="errors.has(item.currency + '_address')" class="f-left is-error">{{ errors.first(item.currency + '_address') }}</span>
                        </div>
                    </div>
                </div>
<!--                <div class="clearfix row-item col-25">-->
<!--                    <label class="row-item-label">{{ $t('cold_wallet_setting.min_balance') }}</label>-->
<!--                    <div class="row-item-value" @click="commonHandle">-->
<!--                        <currency-input v-model="item.lowerThreshold" :maxLengthCus="maxLengthInput" :precision="8" :disabled="!editable" :class="{'input-error': errors.has(item.coin + '_min_balance')}"/>-->
<!--                        &lt;!&ndash; <input-only-number v-model="item.lowerThreshold" :disabled="!editable" :class="{'input-error': errors.has(item.coin + '_min_balance')}" /> &ndash;&gt;-->
<!--                        <div class="error-box">-->
<!--                            <span v-show="errors.has(item.coin + '_min_balance')" class="f-left is-error">{{ errors.first(item.coin + '_min_balance') }}</span>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--                <div class="clearfix row-item col-25">-->
<!--                    <label class="row-item-label">{{ $t('cold_wallet_setting.max_balance') }}</label>-->
<!--                    <div class="row-item-value" @click="commonHandle">-->
<!--                        <currency-input v-model="item.upperThreshold" :maxLengthCus="maxLengthInput" :precision="8" :disabled="!editable" :class="{'input-error': errors.has(item.coin + '_max_balance')}"/>-->
<!--                        &lt;!&ndash; <input-only-number v-model="item.upperThreshold" :disabled="!editable" :class="{'input-error': errors.has(item.coin + '_max_balance')}" /> &ndash;&gt;-->
<!--                        <div class="error-box">-->
<!--                            <span v-show="errors.has(item.coin + '_max_balance')" class="f-left is-error">{{ errors.first(item.coin + '_max_balance') }}</span>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
            </div>
        </div>
        <div class="clearfix box-head">
            <span class="title_item">{{ $t('cold_wallet_setting.notification_title') }}</span>
        </div>
        <div class="clearfix box-content">
            <div class="clearfix row-item col-75">
                <label class="row-item-label">{{ $t('cold_wallet_setting.holder_email') }}</label>
                <div class="row-item-value">
                    <input type="text" @change="emailHandle(dataModel)" :maxlength="maxLengthEmail" @click="commonHandle" v-model.trim="dataModel.cold_wallet_holder_email" :disabled="!editable" :class="{'input-error': errors.has('cold_wallet_holder_email')}" 
                    data-vv-validate-on="none"
                    v-validate="{required: true, regex: /^\w+([\.-]?\w+)+@\w+([\.:]?\w+)+(\.[a-zA-Z0-9]{2,3})+$/ }"/>
                    <div class="error-box">
                        <span v-show="errors.has('cold_wallet_holder_email')" class="f-left is-error">{{ errors.first('cold_wallet_holder_email') }}</span>
                    </div>
                </div>
            </div>
<!--            <div class="clearfix row-item col-75">-->
<!--                <label class="row-item-label">{{ $t('cold_wallet_setting.holder_name') }}</label>-->
<!--                <div class="row-item-value">-->
<!--                    <input type="text" v-model="dataModel.cold_wallet_holder_name" :maxlength="maxLength" @click="commonHandle" :disabled="!editable" :class="{'input-error': errors.has('cold_wallet_holder_name')}"/>-->
<!--                    <div class="error-box">-->
<!--                        <span v-show="errors.has('cold_wallet_holder_name')" class="f-left is-error">{{ errors.first('cold_wallet_holder_name') }}</span>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--            <div class="clearfix row-item col-75">-->
<!--                <label class="row-item-label">{{ $t('cold_wallet_setting.holder_mobile') }}</label>-->
<!--                <div class="row-item-value">-->
<!--                    <input type="text" v-model="dataModel.cold_wallet_holder_mobile_no" :maxlength="maxLength" @click="commonHandle" v-validate="{ required: true, regex: /^\+(?:[0-9] ?){6,14}[0-9]$/ }" data-vv-validate-on="none" :disabled="!editable" :class="{'input-error': errors.has('cold_wallet_holder_mobile_no')}"/>-->
<!--                    <div class="error-box">-->
<!--                        <span v-show="errors.has('cold_wallet_holder_mobile_no')" class="f-left is-error">{{ errors.first('cold_wallet_holder_mobile_no') }}</span>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
        </div>
        <div class="clearfix tool-action">
            <button class="btn-edit" v-if="!editable" @click="edit">{{ $t('common.action.edit') }}</button>
            <button class="btn-cancel" v-if="editable" @click="cancel">{{ $t('common.action.return') }}</button>
            <button class="btn-save" v-if="editable" @click="saveData">{{ $t('common.action.save') }}</button>
        </div>
        <loading-modal/>
    </div>
</template>

<script>
    import rf from '../../lib/RequestFactory';
    import InputOnlyNumber from '../../common/InputOnlyNumber';
    import Utils from '../../common/Utils';
    import CONST from '../../common/Const';
    import CurrencyInput from '../../components/CurrencyInput';
    import LoadingModal from '../../modals/ColdWalletLoadingModal.vue';

    export default {
        components: {
            InputOnlyNumber,
            CurrencyInput,
            LoadingModal
        },
        data() {
            return {
                titlePage: this.$t('cold_wallet_setting.title_page'),
                editable: false,
                dataModel: {
                    cold_wallet: [],
                    cold_wallet_holder_email: null,
                    cold_wallet_holder_name: null,
                    cold_wallet_holder_mobile_no: null
                },
                updateAddress: [],
                updateMail: [],
                maxLength: CONST.MAX_LENGTH_INPUT,
                maxLengthInput: 21,
                maxLengthEmail: 100,
            }
        },
        methods: {
            async inputHandle(item) {
                let res = await rf.getRequest('AdminRequest').getColdWalletSetting();

                if(item.currency === CONST.CURRENCY_ETH) {
                    for(let key in this.dataModel.cold_wallet) {
                        if(this.dataModel.cold_wallet[key].currency.substring(0, 5) === CONST.CURRENCY_TOKEN) {
                            this.dataModel.cold_wallet[key].address = item.address;
                        }
                    }
                }
                
                if (item.address !== "") {
                    for (let key in res.data.cold_wallet) {
                        if(res.data.cold_wallet[key].coin === item.coin) {
                            this.updateAddress.push({ coin: item.coin.toUpperCase(), 
                                                    oldAddress: res.data.cold_wallet[key].address,
                                                    newAddress: item.address });
                        }
                    }
                }
            },
            async emailHandle(item) {
                let res = await rf.getRequest('AdminRequest').getColdWalletSetting();
                if(item.cold_wallet_holder_email !== "") {
                    this.updateMail.push({ oldEmail: res.data.cold_wallet_holder_email, newEmail: item.cold_wallet_holder_email });
                }
            },
            checkEditable(currency) {
                if(!CONST.LIST_COIN.includes(currency)) return true;
                return !this.editable;
            },
            async loadData() {
                try {
                    let res = await rf.getRequest('AdminRequest').getColdWalletSetting();
                    this.dataModel = res.data;
                }
                catch (error) {
                    if (!error.response) {
                        this.$toastr('error', this.$t('common.message.network_error'));
                        return;  
                    }
                }
            },
            edit() {
                this.editable = !this.editable;
            }, 
            cancel() {
                this.editable = !this.editable;
                this.errors.clear();
                this.loadData();
            },
            async saveData() {
                this.$modal.show('cold-wallet-modal-loading');
                let validateResult = await this.validate();
                if (validateResult) {
                    try {
                        if (parseInt(this.updateAddress.length) > 0 || parseInt(this.updateMail.length) > 0) {
                            try {
                                await rf.getRequest('AdminRequest').sendMailUpdateColdWallet({ changedAddress: this.updateAddress, changedEmail: this.updateMail })
                            } 
                            catch(error) {
                                this.$modal.hide('cold-wallet-modal-loading');
                                this.$toastr('error', error);
                                return;
                            }  
                            this.updateAddress = [];
                            this.updateMail = [];
                        }
                        try {
                            await rf.getRequest('AdminRequest').updateColdWalletSetting({cold_wallet_holder_email: this.dataModel.cold_wallet_holder_email});
                        }
                        catch(error) {
                            this.$modal.hide('cold-wallet-modal-loading');
                            this.$toastr('error', error);
                            return;
                        }
                        this.editable = !this.editable;
                        this.$toastr('success', this.$t('common.alert.update.success'));
                    }
                    catch(error) {
                        this.$modal.hide('cold-wallet-modal-loading');
                        if (!error.response) {
                            this.$toastr('error', this.$t('common.message.network_error'));
                            return;
                        }
                        const errors = error.response.data.errors;
                        this.validateErrors = errors;

                        window._.each(this.validateErrors, (value, key) => {
                            if (key !== 'cold_wallet') {
                                this.$validator.errors.add({field: key, msg: value[0]});
                            }
                            else {
                                let arrErr = JSON.parse("[" + value[0] + "]");
                                for (var i = 0 ; i < arrErr.length; i++) {
                                    let item = arrErr[i];
                                    for (var property in item) {
                                        this.$validator.errors.add({field: property, msg: item[property][0]});
                                    }
                                }
                            }
                        });
                    }
                }
                this.$modal.hide('cold-wallet-modal-loading');
            },
            async validate() {
                this.errors.clear();

                // for (var i = 0; i < this.dataModel.cold_wallet.length; i++) {
                //     let item = this.dataModel.cold_wallet[i];
                //
                //     if (!(item.address || item.lowerThreshold || item.upperThreshold)) {
                //         continue;
                //     }
                //
                //     let result = await rf.getRequest('ValidateRequest').commonValidateAddress({ address: item.address, currency: item.currency });
                //
                //     if (!item.address) {
                //         this.$validator.errors.add({field: item.currency + '_address', msg: this.$t('cold_wallet_setting.address.invalid')});
                //     }
                //     else if (!result.data && item.coin != 'amal' && item.coin != 'usdt') {
                //         this.$validator.errors.add({field: item.currency + '_address', msg: this.$t('cold_wallet_setting.address_valid.invalid')});
                //     }
                //
                //     if(!item.lowerThreshold) {
                //         this.$validator.errors.add({field: item.coin + '_min_balance', msg: this.$t('cold_wallet_setting.min_balance.invalid')});
                //     }
                //
                //     if(item.lowerThreshold <= 0) {
                //         this.$validator.errors.add({field: item.coin + '_min_balance', msg: this.$t('cold_wallet_setting.min_balance.error')});
                //     }
                //     if(!item.upperThreshold) {
                //         this.$validator.errors.add({field: item.coin + '_max_balance', msg: this.$t('cold_wallet_setting.max_balance.invalid')});
                //     }
                //
                //     if(item.upperThreshold <= 0) {
                //         this.$validator.errors.add({field: item.coin + '_max_balance', msg: this.$t('cold_wallet_setting.max_balance.error')});
                //     }
                //
                //     if(parseFloat(item.upperThreshold) <= parseFloat(item.lowerThreshold)) {
                //         this.$validator.errors.add({field: item.coin + '_max_balance', msg: this.$t('cold_wallet_setting.max_min.invalid')});
                //     }
                // }

                if(!this.dataModel.cold_wallet_holder_email) {
                    this.$validator.errors.add({field: 'cold_wallet_holder_email', msg: this.$t('cold_wallet_setting.holder_email.invalid')});
                }

                // if(!this.dataModel.cold_wallet_holder_name) {
                //     this.$validator.errors.add({field: 'cold_wallet_holder_name', msg: this.$t('cold_wallet_setting.holder_name.invalid')});
                // }

                // if(!this.dataModel.cold_wallet_holder_mobile_no) {
                //     this.$validator.errors.add({field: 'cold_wallet_holder_mobile_no', msg: this.$t('cold_wallet_setting.holder_mobile_no.invalid')});
                // }

                // const contact_phone = this.dataModel.cold_wallet_holder_mobile_no ? this.dataModel.cold_wallet_holder_mobile_no : '';
                // let regexPhoneNumber = new RegExp("^\\+(?:[0-9] ?){6,14}[0-9]$");
                // if(contact_phone && !regexPhoneNumber.test(String(contact_phone))){
                //     this.errors.add({field: 'cold_wallet_holder_mobile_no', msg: this.$t('site_setting.phone_number_valid')});
                // }

                const contact_mail = this.dataModel.cold_wallet_holder_email ? this.dataModel.cold_wallet_holder_email : '';
                let regexMail = new RegExp("^\\w+([\\.-]?\\w+)+@\\w+([\\.:]?\\w+)+(\\.[a-zA-Z0-9]{2,3})+$");
                if(contact_mail && !regexMail.test(String(contact_mail))){
                    this.errors.add({field: 'cold_wallet_holder_email', msg: this.$t('cold_wallet_setting.holder_email_format.invalid')});
                }

                if (this.$validator.errors.items.length > 0) {
                    return false;
                }

                return true;
            },
            async format(coin) {
                try {
                    let data = await rf.getRequest('ValidateRequest').formatCurrency(coin);
                    return data.toUpperCase();
                }
                catch(error) {
                    this.$toastr('error', error);
                    return;
                }
            },
            commonHandle() {
                this.errors.clear();
            }
        },
        mounted() {
            this.$emit('EVENT_PAGE_CHANGE', this);
            this.loadData();
        },
        created() {
            this.loadData();
        },
    }
</script>

<style lang="scss" scoped>
@import "@/assets/sass/variables";
  .is-error {
    display: inline-block;
    line-height: 20px;
    padding-top: 5px;
  }
  .is-error {
    color: $color-denim !important;
  }
  .input-error {
    border: 1px solid $color-denim !important;
  }
  .f-left {
    float: left;
  }
  .error-box {
    display: block;
    float: left;
    overflow: hidden;
    width: 100%;
    span {
      font-family: $font-family-roboto-medium !important;
    }
  }
  .wallet-setting {
    float: left;
    width: 100%;
  }
  .box-head {
    padding: 15px 0px;
    .title_item {
      font-size: $font_big;
      line-height: normal;
    }
  }
  .box-content {
    padding: 20px 15px;
    border: 1px solid $color_alto;
    margin-bottom: 30px;
    float: left;
    width: 100%;
    .row-item {
      margin-bottom: 10px;
      .row-item-label {
        font-family: $font-family-roboto;
        font-size: $font-small;
        font-weight: normal;
        line-height: 35px;
        margin: 0;
      }
      .row-item-value {
        float: right;
        input {
          border-radius: 3px;
          border: solid 1px $color_alto;
          background-color: $color-white-smoke;
          height: 35px;
          padding: 8px 12px;
          width: 100%;
          &:focus {
            border-color: $color-jungle-green;
          }
          &.sub-address {
            margin-top: 5px;
            width: 50%;
          }
        }
      }
    }
  }
  .tool-action {
    float: left;
    display: flex;
    button {
      border: 0px;
      border-radius: 3px;
      background-color: $color-caribbean-green;
      height: 35px;
      min-width: 90px;
      color: $color-white;
      text-transform: uppercase;
      font-size: $font-smaller;
      font-family: $font-family-roboto-bold;
      margin-right: 10px;
      &:hover {
        background-color: $color-aquamarine;
      }
    }
  }
  .col-25 {
    float: left;
    width: 25%;
    .row-item-label {
      padding-right: 30px;
      width: 50%;
      text-align: right;
    }
    .row-item-value {
      width: 50%;
    }
  }
  .col-50 {
    float: left;
    width: 50%;
    .row-item-value {
      width: 70%;
    }
  }
  .col-75 {
    float: left;
    width: 75%;
    .row-item-value {
      width: 70%;
    }
  }
  input{
    &:disabled {
      background-color: $color-bright-gray !important;
    }
  }
  #coldWalletSetting {
    margin-bottom: 80px;
  }
</style>