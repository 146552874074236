import BaseRequest from '../lib/BaseRequest'

export default class UserRequest extends BaseRequest {

  getUsers(params) {
    let url = '/admin/api/users';
    return this.get(url, params);
  }

  getTotalUsers() {
    let url = '/admin/api/total-user';
    return this.get(url);
  }

  getReferrers(params) {
    let url = '/admin/api/referrers';
    return this.get(url, params);
  }

  updateUserInfo(data) {
    let url = `/admin/api/users/${data.id}`;
    return this.put(url, data);
  }

  getCurrentAdmin(useCache = true, params) {
    if (this.user && useCache) {
      return new Promise((resolve, reject) => {
        resolve(this.user);
      });
    }
    return new Promise((resolve, reject) => {
      const url = '/admin/api/user';
      const self = this;
      this.get(url, params)
        .then(function (user) {
            self.user = user;
            resolve(user);
          })
          .catch(function (error) {
            reject(error);
          });
    });
  }

  getUsersWithSetting(parmas) {
    let url = '/api/users';
    return this.get(url, parmas);
  }

  updateSettingOtp(id, state) {
    let url = `/admin/api/users/otp/${id}`;
    return this.put(url, {otp_verified: state});
  }

  disableAccount(id) {
    let url = `/admin/api/users/disable/${id}`;
    return this.put(url, {});
  }

  enableAccount(id) {
    let url = `/admin/api/users/enable/${id}`;
    return this.put(url, {});
  }

  getDeviceRegister(params) {
    let url = `/admin/api/user/${params.userId}/devices`;
    return this.get(url, params);
  }

  deleteDevice(params) {
    let url = `/admin/api/user/${params.userId}/device/${params.deviceId}`;
    return this.del(url, params);
  }

  getCustomerInformation (params) {
    let url = '/api/customer-info';
    return this.get(url, params);
  }

  getAccountList(params) {
    let url = '/admin/account';
    return this.get(url, params);
  }

  getAccountDetail(params) {
    let url = `/admin/account/${params}`;
    return this.get(url);
  }

  changeActiveStatusAccount(params) {
    let url = `/admin/account/${params.id}?status=${params.value}`;
    return this.post(url);
  }

  exportAccountData(params) {
    let url = `/admin/account/export`;
    return this.get(url, params);
  }

  getOptionDataAccount(params) {
    let url = `/admin/account/params/${params}`;
    return this.get(url);
  }

  getBalanceSpot(params) {
    let url = `/admin/account/${params.id}/balance/spot`;
    return this.get(url, params);
  }

  getBalanceFutures(params) {
    let url = `api/v1/balance/admin-get-balance-by-user`;
    return this.getFuture(url, params);
  }

  getLogsBalanceSpot(params) {
    let url = `/admin/account/${params.id}/logs/balance`;
    return this.get(url, params);
  }

  getLogsBalanceFutures(params) {
    let url = `api/v1/transactions/admin-get-transactions-by-user`;
    return this.getFuture(url, params);
  }

  getDownAffiliate(id, params) {
    let url = `/admin/account/${id}/affiliate/tree/down`
    return this.get(url, params);
  }

  getUpAffiliate(id, params) {
    let url = `/admin/account/${id}/affiliate/tree/up`
    return this.get(url, params);
  }

  getActivityHistory(id, params) {
    let url = `/admin/account/${id}/history/activity`
    return this.get(url, params);
  }

  getOpenOrderSpot(id, params) {
    let url = `/admin/account/${id}/spot/orders/open`
    return this.get(url, params);
  }

  getOrderHistorySpot(id, params) {
    let url = `/admin/account/${id}/spot/orders/history`
    return this.get(url, params);
  }

  getTradeHistorySpot(id, params) {
    let url = `/admin/account/${id}/spot/trade/history`
    return this.get(url, params);
  }
}
