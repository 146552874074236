<template>
  <div class="email_marketing_pages boxCore clearfix mg_bot50">
    <div class="email_marketing_list">
      <div class="filter-item">
        <router-link class="btn btn-success" to="/email-marketing/create">
          <span class="icon-plus"></span>  {{ $t('email.new') }}
        </router-link>
        <!--
        <input type="text" placeholder="Search" v-on:keyup.enter="search" class="form-control search_input" name="searchKey" v-model="filters.searchKey"/>
        -->
      </div>
    </div>

    <div class="datatable">
      <data-table :getData="getListEmailMarketing" :limit="10" ref="list_email" :column="2" class="scroll">
        <th class="text-left width-title" data-sort-field="title">{{ $t('email.title') }}</th>
        <th class="text-left">Action</th>
        <template slot="body" slot-scope="props">
          <tr>
            <td class="text-left width-title">
              <div class="over-flow-text" :title="props.item.title">{{ props.item.title }}</div>
            </td>
            <td class="text-left" >
              <span class="btn-icon bt-action icon-edit col-xs-2" @click="editEmail(props.item)"></span>
              <span class="btn-icon bt-delete icon-close2 col-xs-2" @click="deleteEmail(props.item.id)"></span>
              <span class="btn-icon bt-detail col-xs-5">
                <button class="btnForm bt-action" @click="sendEmail(props.item)">{{ $t('email.send') }}</button>
              </span>
            </td>
          </tr>
        </template>
      </data-table>
    </div>
  </div>
</template>

<script>
  import rf from '../../lib/RequestFactory';

  export default {
    components: {
    },
    data() {
      return {
        titlePage: 'Email Marketing',
        filters: {
          searchKey: '',
        },
      }
    },
    methods: {
      refresh() {
        this.$refs.list_email.refresh();
      },
      getListEmailMarketing(params) {
        return rf.getRequest('AdminRequest').getListEmailMarketing(params);
      },
      search() {
        let filterParams = {};
        if (this.filters.searchKey) {
          filterParams.search_key = this.filters.searchKey;
        }
        this.$refs.list_email.filter(filterParams);
      },

      editEmail(row) {
        this.$router.push({
          path: '/email-marketing/edit',
          query: {
              id: row ? row.id : null
          }
        });
      },
      sendEmail(row) {
        this.$router.push({
          path: '/email-marketing/send',
          query: {
              id: row ? row.id : null
          }
        });
      },
      deleteEmail(row) {
        ConfirmationModal.show({
          type: 'confirm',
          title: window.i18n.t('email.delete_data'),
          content         : '',
          btnCancelLabel  : this.$t('common.action.no'),
          btnConfirmLabel : this.$t('common.action.yes'),
          size            : 'small',
          onConfirm       : () => {
            rf.getRequest('AdminRequest').deleteEmailMarketing(row).then(res => {
              this.$toastr('success', this.$t('common.alert.delete.success'));
              this.refresh();
            });
          },
          onCancel        : () => {}
        });
      }
    },
    mounted() {
      this.$emit('EVENT_PAGE_CHANGE', this);
    }
  }
</script>
<style lang="scss" scoped>
@import "@/assets/sass/variables";

  .email_marketing_list {
    .filter-item {
      margin-bottom: 20px;
      width: 850px;
      max-width: 100%;
      
      .btn-success {
        background-color: $color-caribbean-green;
        color: $color-white;
        height: 30px;
        line-height: 30px;
        text-transform: uppercase;
        padding: 0 10px;
        border: 0px;
        font-size: $font-smaller;
        font-weight: 600;
        min-width: 200px;
        font-family: $font-family-roboto;
        &:hover, &:active, &:focus {
          background-color: $color-aquamarine;
        }
        .icon-plus{
          font-size: 10px;
        }
      }
    }
  }
  .search_input {
    display: inline-block;
    float: right;
    width: 215px;
    max-width: 100%;
    padding: 4px 15px;
    line-height: 20px;
    height: 30px;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 13px;
    border: 1px solid $color_alto;
    background-color: $color_concrete;
  }

    .datatable {
      width: 490px;
      max-width: 100%;
      table {
        th {
          &:nth-child(1) {
            width: 70%;
            font-size: $font-small;
          }
        }
        td {
          span {
            &.btn-icon {
              cursor: pointer;
              color: $color-jungle-green;
              padding: 0;
              line-height: 20px;
              &:hover{
                color: $color-blue-custom;
              };
            }
            &.bt-detail {
              float: right;
            }
          }
        }
        button.btnForm.bt-action {
          background-color: $color-caribbean-green;
          padding: 0px 10px;
          font-size: $font-smaller;
          color: $color-white;
          border: 1px solid $color-caribbean-green;
          border-radius: 3px;
          text-transform: uppercase;
          font-family: $font-family-roboto;
          font-weight: 600;
          &:hover{
            background-color: $color-aquamarine;
            border-color: $color-aquamarine;
          };
        }
        .icon-close2 {
          margin: 0 10px;
          font-size: $font_semi_big;
        }
        .icon-edit {
          font-size: $font_semi_big;
        }
        .width-title {
          width: 60% !important;
          font-size: 14px;
        }
        .over-flow-text {
          max-width: 250px;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
  }
</style>