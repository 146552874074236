<template>
    <div class="openOrder-container">
        <div class="openOrder-actions">
            <div class="openOrder-actions__tab">
                <button class="openOrder-btn" :class="{ active: openOrderTypeTab === 0 }"
                    @click="setOpenOrderTypeTabTypeTab(0)">{{
                    $t('account.detail.openOrder.tab.spot') }}</button>
                <button class="openOrder-btn" :class="{ active: openOrderTypeTab === 1 }"
                    @click="setOpenOrderTypeTabTypeTab(1)">{{
                    $t('account.detail.openOrder.tab.futures') }}</button>
            </div>

            <!-- <div class="openOrder-actions__filters">
                <div>
                    <div class="filter-title">{{ $t('account.detail.openOrder.filter.date') }}</div>
                    <div class="filter-flex">
                        <date-picker v-model="filters.startDate" class="date-picker-input" :format="formatDate"
                            ref="startDate"></date-picker>
                        <date-picker v-model="filters.endDate" class="date-picker-input" :format="formatDate"
                            ref="endDate"></date-picker>
                    </div>
                </div>

                <div>
                    <div class="filter-title">{{ $t('account.detail.openOrder.filter.pair') }}</div>

                    <div class="filter-flex">
                        <select-box v-model="filters.coin" :placeholder="$t('order.order_history.all')"
                            :options="pairCoinData" class="selectbox100" />
                        <div>/</div>
                        <select-box v-model="filters.currency" :placeholder="$t('order.order_history.all')"
                            :options="pairMarketData" class="selectbox100" />
                    </div>
                </div>

                <div>
                    <div class="filter-title">{{ $t('account.detail.openOrder.filter.type') }}</div>
                    <select-box v-model="filters.tradeType" :placeholder="$t('order.order_history.all')"
                        :options="tradeType" class="selectbox100" />
                </div>

                <div>
                    <div class="filter-title">{{ $t('account.detail.openOrder.filter.status') }}</div>
                    <select-box v-model="filters.status" :placeholder="$t('order.order_history.all')"
                        :options="statusType" class="selectbox100" />
                </div>

                <div class="filter-flex">
                    <input title type="text" :placeholder="$t('account.detail.openOrder.inputplace.search')"
                        @keyup.enter="search" class="form-control" name="searchKey" v-model="filters.searchKey">
                    <button class="openOrder-btn__search" @click="search()">{{ $t('account.detail.openOrder.btn.search')
                        }}</button>
                    <button class="openOrder-btn__search" @click="refresh()">{{
                    $t('account.detail.openOrder.btn.refresh')
                }}</button>
                </div>
            </div> -->
        </div>


        <open-order-spot v-if="openOrderTypeTab === 0" :accountId="$route.query.id" />
        <open-order-futures v-if="openOrderTypeTab === 1" :accountId="$route.query.id" />
        <!-- <data-table-4 :getData="getData" ref="datatable" :column="12" :limit="10" :inLine="true" class="scroll"
            :total-user="totalRecords">
            <th class="text-left" data-sort-field="id account accountId orderID">{{
                    $t('account.detail.openOrder.table.orderId') }}
            </th>
            <th class="text-left" data-sort-field="pair">{{ $t('account.detail.openOrder.table.pair') }}</th>
            <th class="text-center" data-sort-field="side">&emsp;{{ $t('account.detail.openOrder.table.side') }}</th>
            <th class="text-center" data-sort-field="type">&emsp;{{ $t('account.detail.openOrder.table.type') }}</th>
            <th class="text-left" data-sort-field="filled">{{ $t('account.detail.openOrder.table.filled') }}
            </th>
            <th class="text-left" data-sort-field="trigger">{{ $t('account.detail.openOrder.table.trigger') }}
            </th>
            <th class="text-left" data-sort-field="price">{{ $t('account.detail.openOrder.table.price') }}</th>
            <th class="text-left" data-sort-field="creationTime">{{
                    $t('account.detail.openOrder.table.creationTime') }}</th>
            <th class="text-center" data-sort-field="status">&emsp;{{ $t('account.detail.openOrder.table.status') }}
            </th>
            <th class="text-center" data-sort-field="action">&emsp;{{ $t('account.detail.openOrder.table.action') }}
            </th>

            <template slot="body" slot-scope="props">
                <tr>
                    <td class="text-left">
                        <span>{{ props.item.accountId }}</span>
                    </td>
                    <td class="text-left">
                        <div class="text-top"><span>{{ "SOL/USD" }}</span></div>
                    </td>
                    <td class="text-center">
                        <span
                            :class="{ 'side-buy': props.item.accountId % 2 === 0, 'side-sell': props.item.accountId % 2 !== 0 }">{{
                    props.item.accountId % 2 === 0
                        ? $t('account.detail.openOrder.table.column.sideBuy') :
                        $t('account.detail.openOrder.table.column.sideSell') }}</span>
                    </td>
                    <td class="text-center">
                        <div class="text-top"><span>{{ $t('account.detail.openOrder.table.column.typeLimit') }}</span>
                        </div>
                    </td>
                    <td class="text-left">
                        <p style="text-align: left">{{ "0.22196200" }}</p>
                        <p style="text-align: left">{{ "2.26559944" }}</p>
                    </td>
                    <td class="text-left">
                        <div class="text-top"><span>{{ ">= 66,000.54" }}</span></div>
                    </td>
                    <td class="text-left">
                        <div class="text-top"><span>{{ "66,000.54" }}</span></div>
                    </td>
                    <td class="text-center">
                        <p style="text-align: left">{{ formatDate(props.item.updatedAt) }}</p>
                        <p style="text-align: left">{{ formatDate(props.item.updatedAt, true) }}</p>
                    </td>
                    <td class="text-center">
                        <div class="status" :class="{
                    'pending': props.item.accountId % 2 === 0,
                    'partialFilled': props.item.accountId % 2 !== 0
                }">{{
                    props.item.accountId % 2 === 0
                        ? $t('account.detail.openOrder.table.column.statusPending') :
                        $t('account.detail.openOrder.table.column.statusPartial') }}</div>
                    </td>

                    <td class="text-center">
                        <img class="icon-action" src="@/assets/images/account/cancel.svg"
                            @click="actions(true, { accountId: props.item.accountId })" />
                        <img class="icon-action" src="@/assets/images/future/List.svg"
                            @click="actions(false, { accountId: props.item.accountId })" />
                    </td>
                </tr>
            </template>
        </data-table-4> -->
    </div>
</template>

<script>
import moment from "moment";
// import DatePicker from 'vuejs-datepicker';
// import rf from "../../lib/RequestFactory";
// import Modal from "../../components/Modal";
// import SelectBox from "../../components/SelectBox";
// import InputTextAndNumber from "../../components/InputTextAndNumber.vue";
// import UtilsUIComponent from "../../common/UtilsUIComponent";
// import DataTable4 from "../../components/DataTable4.vue";
import OpenOrderSpot from "../../pages/orders/OpenOrder.vue";
import OpenOrderFutures from "../../pages/futures_records/OpenOrderFutures.vue";

export default {
    name: "OpenOrder",
    components: {
        // Modal,
        // InputTextAndNumber,
        // DatePicker,
        // SelectBox,
        // DataTable4
        OpenOrderSpot,
        OpenOrderFutures
    },

    data() {

        return {
            openOrderTypeTab: 0,
            totalRecords: 10,
            pairCoinData: ["All", "BTC"],
            pairMarketData: ["All", "USDT"],
            tradeType: [],
            statusType: [],
            filters: {
                startDate: moment().subtract(1, 'week').toDate(),
                endDate: moment().toDate(),
                coin: "",
                currency: "",
                tradeType: "",
                status: "",
                searchKey: ""
            },
        }
    },
    watch: {
        isActive() {
            this.$refs.datatable.refresh();
        },
    },
    methods: {
        // actions: function (isCancel) {
        //     if (isCancel) {
        //         UtilsUIComponent.showModal({
        //             type: 'confirm',
        //             title: window.i18n.t("account.detail.dialog.confirm.title"),
        //             btnConfirmLabel: window.i18n.t("account.detail.dialog.confirm.btn"),
        //             btnCancelLabel: window.i18n.t("account.detail.dialog.close.btn"),
        //             onConfirm: () => {
        //                 console.log("Cancel Oke")
        //             }
        //         });
        //     } else {
        //         alert("Actions")
        //     }
        // },
        setOpenOrderTypeTabTypeTab: function (tabValue) {
            this.openOrderTypeTab = tabValue
        },
        // search: function () {
        //     console.log('Search Key', this.filters.searchKey)
        //     this.$refs.datatable.filter(this.filters.searchKey);
        // },
        // refresh() {
        //     this.requestCoinList();
        //     this.getOptionData();
        //     this.resetInput();
        //     this.$refs.datatable.refresh();
        // },
        // getData(params) {
        //     let filterParams = {
        //         start_date: moment(this.filters.startDate)
        //             .startOf("day")
        //             .format("x"),
        //         end_date: moment(this.filters.endDate)
        //             .endOf("day")
        //             .format("x")
        //     };
        //     if (this.filters.startDate > this.filters.endDate && this.filters.startDate.toDateString() != this.filters.endDate.toDateString()) {
        //         this.$toastr("error", this.$t("common.date.warning"));
        //         return;
        //     }
        //     if (this.filters.searchKey) {
        //         filterParams.search_key = this.filters.searchKey;
        //     }
        //     if (this.filters.coin) {
        //         filterParams.coin = this.filters.coin;
        //     }
        //     if (this.filters.currency && this.filters.currency.id != 'all') {
        //         filterParams.currency = this.filters.currency.id;
        //     }
        //     if (this.filters.tradeType && this.filters.tradeType.id != 'all') {
        //         filterParams.trade_type = this.filters.tradeType.id;
        //     }
        //     if (this.filters.status && this.filters.status.id != 'all') {
        //         filterParams.status = this.filters.status.id;
        //     }

        //     let meta = {}
        //     meta.symbol = this.filters.searchKey;
        //     params = Object.assign(params, meta);
        //     params.size = params.limit || 5;
        //     delete params.limit;

        //     const response = {}
        //     if (this.openOrderTypeTab === 0) response = this.getOpenOrderSpot(meta);
        //     if (this.openOrderTypeTab === 1) response = this.getOpenOrderFuture(meta);
        //     return response;
        // },
        // getOpenOrderSpot(params) {
        //     const id = this.$route.query.id;
        //     const response = rf.getRequest("UserRequest").getOpenOrderSpot(id, params);
        //     response.then(res => {
        //         this.totalRecords = res.data.total;
        //     })
        //     return response;
        // },
        // getOpenOrderFuture(params) {
        //     const id = this.$route.query.id;
        //     const response = rf.getRequest('AdminRequest').getOrderFutureRecords(id, params);
        //     response.then(data => {
        //         this.totalRecords = data.metadata.total;
        //     })
        //     return response;
        // },
        // getOptionData() {
        //     this.tradeType = [{ id: 'all', name: window.i18n.t('status.all') }]
        //     this.statusType = [{ id: 'all', name: window.i18n.t('status.all') }]
        //     rf.getRequest('OrderRequest')
        //         .getOptionFilter('type')
        //         .then(res => {
        //             res?.data.map((item) => {
        //                 this.tradeType.push({
        //                     id: item,
        //                     name: item
        //                 })
        //             })
        //         })
        //     rf.getRequest('OrderRequest')
        //         .getOptionFilter('status')
        //         .then(res => {
        //             res?.data.map((item) => {
        //                 this.statusType.push({
        //                     id: item,
        //                     name: item
        //                 })
        //             })
        //         })
        // },
        // formatDate(date, isTime) {
        //     if (isTime) return moment(date).format('HH:mm:ss')
        //     return moment(date).format('YYYY-MM-DD');
        // },
    },
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/variables";

.openOrder-container {

    .icon-action {
        width: 20px;
        height: 20px;
        margin: 6px;
        cursor: pointer;
    }

    /* .side {
        &-buy {
            color: #00DD31;
        }

        &-sell {
            color: #FF5757;
        }
    }

    .status {
        padding: 10px;

        &.pending {
            background-color: #FFE352;
        }

        &.partialFilled {
            background-color: #FFC590;
        }

        &.success {
            background-color: #53D768;
        }

        &.canceled {
            background-color: #FF8C8A;
        }
    }


    .filter-title {
        color: #999999;
        font-family: "Roboto-Regular";
        margin-bottom: 10px;
    }

    .filter-flex {
        display: flex;
        align-items: center;
        gap: 10px;
    }

    .date-picker-input {
        display: inline-block;
        float: left;

        ::v-deep {
            input {
                padding: 0 5px 0px 36px;
                width: 135px !important;
                height: 32px;
                border: 1px solid #C7CBD3 !important;
                border-radius: 10px !important;
                background-image: url('@/assets/images/icon/date-time.svg') !important;
                background-color: $color-white !important;
                background-size: 12px !important;
                background-position: center left 12px !important;
                background-repeat: no-repeat !important;
                color: $dark-1;

                &:hover,
                &:active,
                &:focus {
                    border-color: $color-jungle-green;
                    z-index: 9;
                }
            }
        }
    }

    .form-control {
        border-radius: 10px !important;
    }*/

    .openOrder-btn {
        background-color: transparent;
        padding: 10px;
        color: #999999;
        border: 0px transparent;
        margin-right: 10px;

        &.active {
            border-bottom: 2px solid #2D2D2D;
            color: black;
            font-family: "Roboto-Bold";
        }

        &__search {
            background-color: #00DDB3;
            color: white;
            font-family: "Roboto-Bold";
            border: none;
            min-width: 87px;
            border-radius: 10px;
            text-transform: uppercase;
            height: 32px;
        }
    }
}

.openOrder-actions {
    display: flex;
    flex-direction: column;
    margin: 50px 0px;
    margin-bottom: 0;

    &__tab {
        margin-bottom: 20px;
    }

    &__filters {
        display: flex;
        align-items: end;
        gap: 30px;
    }
}
</style>