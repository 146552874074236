<template>
  <div class="coin-information-page">
    <p class="tab-title">{{ $t('coin_information.tab_title') }}</p>
    <div class="content-wrapp">
    <div class="content-page">
    <div class="box-content clearfix">
      <div class="item left_content_regis">
        <div class="infor__name">{{ $t('coin_information.contact_address') }}</div>
        <div class="infor__content" :class="errors.has('contract_address.contract_address') ? 'active-warning' : ''">
          <input
            v-validate="'required|is_contract_address_exist|is_contract_address:eth|unregistered_contract_address|max:190'"
            data-vv-scope="contract_address"
            v-model.trim="contractAddress"
            :readonly="isVerifyAddress"
            data-vv-validate-on="none"
            maxlength="190"
            autocomplete="off"
            :data-vv-as="`contract address`"
            @focus="removeErrors"
            class="input_contract_address"
            name="contract_address"
          >
          <div class="warning-message">
            <span>{{ errors.first('contract_address.contract_address') }}</span>
          </div>
        </div>
        <div class="loading-wrap" v-if="isVerifyAddress">
          <div class="loading-content">
            <vue-loading :size="{ width: '20px', height: '20px' }" type="spin" class="loading-icon" color="#0f8ddb"/>
          </div>
        </div>
      </div>

      <div class="item right_content_regis">
        <span class="infor__name">{{ $t('coin_information.token_name') }}</span>
        <div class="infor__content">
          <div class="disable">
            <input
              v-model="data.params.coin_setting.name"
              data-vv-validate-on="none"
              name="token_name"
              :data-vv-as="`token name`"
              readonly
              autocomplete="off"
            >
          </div>
          <div class="warning-message">
            <span>{{ errors.first('token_name') }}</span>
          </div>
        </div>
      </div>

      <div class="clearfix"></div>

      <div class="item left_content_regis">
        <span class="infor__name">{{ $t('coin_information.token') }}</span>
        <div class="infor__content">
          <div class="disable">
            <input
              v-model="data.params.coin_setting.symbol"
              data-vv-scope="contract_address"
              data-vv-validate-on="none"
              autocomplete="off"
              readonly
              name="token"
             :data-vv-as="`token`"
            >
          </div>
          <!-- <div class="warning-message">
            <span>{{ errors.first('contract_address.token') }}</span>
          </div> -->
        </div>
      </div>

      <div class="item right_content_regis">
        <span class="infor__name">{{ $t('coin_information.icon') }}</span>
        <div class="infor__content icon__content-box" :class="errors.has('icon.icon') ? 'active-warning' : ''">
          <label for="icon" class="choose-label" @click="removeErrors">
            <input type="file" name="icon" id="icon"
                   accept="image/x-png,image/png"
                   ref="icon"
                   data-vv-scope="icon"
                   data-vv-validate-on="none"
                   v-validate="'required|ext:png|size:2048'"
                   @change="onChangeIcon">
            <span class="choose-btn">{{ $t('coin_information.btn_choose_file') }}</span>
          </label>
          <img :src="image" alt="" class="icon__image" v-if="image">
          <span class="image-note" v-if="!image">* {{ $t('coin_information.image_note') }}</span>          
        </div>
        <div class="warning-message" v-if="errors.first('icon.icon')">
          <span>{{ errors.first('icon.icon') }}</span>
          <!-- <span>{{ errorsImage }}</span> -->
        </div>
      </div>

      <div class="clearfix"></div>

      <div class="item left_content_regis">
        <span class="infor__name">{{ $t('coin_information.decimals') }}</span>
        <div class="infor__content disable">
          <input v-model="data.params.coin_setting.decimals" readonly>
        </div>
      </div>

      <div class="item right_content_regis">
        <span class="infor__name">{{ $t('coin_information.required_confirmations') }}</span>
        <div class="infor__content disable">
          <input v-model="data.params.coin_setting.required_confirmations" readonly>
        </div>
      </div>


      <div class="clearfix"></div>

      <div class="item left_content_regis">
        <span class="infor__name">{{ $t('coin_information.type') }}</span>
        <div class="infor__content disable">
          <input v-model="data.params.coin_setting.type" readonly>
        </div>
      </div>

      <div class="item right_content_regis">
        <span class="infor__name">{{ $t('coin_information.required_confirmations_explorer') }}</span>
        <div class="infor__content disable" disabled>
          <input v-model="data.params.coin_setting.explorer" readonly>
        </div>
      </div>
    </div>

    <div class="clearfix"></div>

    <div class="redirect-box">
      <button class="button--prev btn btn-primary" @click="onReturn">
        {{ $t('sales_point.clear') }}
      </button>
      <button class="button--next btn btn-primary" @click="next">{{ $t('coin_information.btn_next') }}</button>
    </div>
  </div>
    <loading-modal/>
  </div>
  </div>
</template>

<script>
  import rf from '../../../lib/RequestFactory';
  import { mapGetters } from 'vuex';
  import LoadingModal from '../../../modals/Erc20LoadingModal.vue';
  import MasterdataUtils from "@/common/MasterdataUtils";

  const REQUIRED_CONFIRMATIONS = 12;

  export default {
    name: 'registerERC20CoinInformation',
    data() {
      return {
        image: '',
        contractAddress: '',
        isVerifyAddress: false,
        errorsImage: '',
      }
    },
    components: {
      LoadingModal
    },
    inject: ['data'],
    computed: {
      ...mapGetters(['coins']),
    },
    watch: {
      contractAddress: [
        function () {
          if (!window.localStorage.getItem('masterdata')) {
            rf.getRequest('MasterdataRequest')._get();
          }
          this.removeErrors();
          if (this.contractAddress === this.data.params.coin_setting.contract_address) {
            return;
          }
          if (!this.contractAddress ||
            (this.data.params.coin_setting.contract_address &&
              this.contractAddress !== this.data.params.coin_setting.contract_address)) {
            this.resetData();
          }
        },
        _.debounce(async function () {
          if (this.contractAddress === this.data.params.coin_setting.contract_address) {
            return;
          }
          if (!this.contractAddress) {
            return;
          }
          this.isVerifyAddress = true;
          try {
            const data = await this.getContractAddressInfo(this.contractAddress);
            if (_.isEmpty(data)) {
              throw "Empty data";
            }
            this.setCoinSettingParams(data);
            this.isVerifyAddress = false;
          } catch (error) {
            this.isShowContractAddressError = true;
            await this.$validator.validate('contract_address.contract_address');
            this.isVerifyAddress = false;
            this.resetData();
          }
        }, 500)
      ],
    },
    mounted () {
      if (!window.localStorage.getItem('masterdata')) {
        rf.getRequest('MasterdataRequest')._get();
      }
      window.onbeforeunload = () => {
        return 'Are you sure you want to leave?';
      };
      this.contractAddress = this.data.params.coin_setting.contract_address;
      if (this.data.params.coin_setting.image) {
        this.setImage(this.data.params.coin_setting.image);
      }
    },
    methods: {
      removeErrors () {
        this.errors.clear('contract_address');
        this.errors.clear('icon');
        this.errors.clear();
      },
      onReturn () {
        this.contractAddress="";
        this.image="";
        this.errors.clear('contract_address');
        this.errors.clear('icon');
        this.errors.clear();
      },
      getExplorer () {
        const eth = _.find(this.coins, (coin) => coin.coin === 'eth' && coin.env === process.env.MIX_BLOCKCHAIN_NETWORK) || {};
        return eth.transaction_explorer;
      },
      async getContractAddressInfo (contractAddress) {
        const res = await rf.getRequest('WalletRequest').getErc20ContractInformation({contract_address: contractAddress});
        return res.data;
      },
      setCoinSettingParams(params) {
        this.data.params.coin_setting.contract_address = this.contractAddress;
        this.data.params.coin_setting.name = params.name;
        this.data.params.coin_setting.symbol = _.toUpper(params.networkSymbol);
        this.data.params.coin_setting.decimals = params.decimals;
        this.data.params.coin_setting.required_confirmations = REQUIRED_CONFIRMATIONS;
        this.data.params.coin_setting.type = _.toUpper(params.tokenType);
        this.data.params.coin_setting.explorer = this.getExplorer();
      },
      resetData() {
        let icon = null;
        if (this.data.params.coin_setting.image) {
          icon = this.data.params.coin_setting.image;
          // console.log(icon)
        }
        this.data.params.coin_setting = {};
        this.data.params.coin_setting.image = icon;
        this.data.params.trading_setting = [];
        this.data.params.withdrawal_setting = {};
      },
      next() {
        this.$validator.validate('contract_address.*').then(async (result) => {
          let result2 = true;
          if (!this.data.params.coin_setting.image) {
            result2 = await this.$validator.validate('icon.*');
          }
          if (!result || !result2) {
            return;
          }

          this.data.params.coin_setting.image_base64 = this.image;
          this.$emit('next-step');
        });
      },
      async onChangeIcon (event) {
        const files = event.target.files || e.dataTransfer.files;

        // debugger;
        if (files[0].type !== 'image/png') {
          this.errorsImage = this.$t("coin_information.image_error");
        }

        if (!files.length) {
          return;
        }
        const result2 = await this.$validator.validate('icon.*');
        if (!result2) {
          return;
        }
        this.errors.clear('icon');
        this.setImage(files[0]);
        this.data.params.coin_setting.image = files[0];
      },
      setImage(file) {
        const reader = new FileReader();

        reader.onload = (e) => {
          const result = event.target.result;
          if (!result) {
            return;
          }
          this.image = result;
        };

        reader.readAsDataURL(file);
      },
    }
  };
</script>

<style lang="scss" scoped>
@import "@/assets/sass/variables";
  label{
    margin: 3px 0px 0px 3px;
  }
  .input_contract_address:focus {
      border-color: $color-jungle-green !important;
  }
  .btn-primary{
    font-weight: 500;
    font-family: $font-family-roboto-bold;
    letter-spacing: 0.92px;
    width: 90px;
    text-align: center;
    padding: 5px;
    font-size: $font-smaller;
    background-color: $color-caribbean-green;
    border : 1px solid $color-caribbean-green;
    &:hover{
      background-color:$color-aquamarine;
      border : 1px solid $color-aquamarine;
    }
    &.button--prev {
      margin-right: 13px;
    }
  }
  .image-note {
    color: $color-denim;
    font-size: $font-smaller;
    bottom: 2px;
    margin-left: 5px;
    transform: -50%;
    line-height: 30px;
    font-family: $font-family-roboto;
  }

  .loading-wrap {
    position: relative;
    display: inline-block;
    height: 42px;
    vertical-align: middle;
    margin-left: 10px;
    .loading-content {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: all 0.3s ease-in-out;
    }
  }
  .coin-information-page {
    padding-top: $font_medium;

    .tab-title {
      text-transform: uppercase;
      font-size: $font_semi_big;
      font-family: $font-family-roboto-medium;
      color: $color-grey-dark;
      margin-bottom: 20px;
    }
  }

  .choose-label {
    margin-top: 5px;
    input[type='file'] {
      position: absolute;
      visibility: hidden;
      opacity: 0;
      visibility: hidden;
    }

    .choose-btn {
      display: inline-block;
      text-align: center;
      font-size: $font-smaller;
      font-family: $font-family-roboto-bold;
      text-transform: uppercase;
      color: $color-white;
      width: 110px;
      height: 25px;
      line-height: 25px;
      padding: 1px 13px;
      text-align: center;
      border-radius: $font-mini-3px;
      background-color: $color-caribbean-green;
      cursor: pointer;
      letter-spacing: 0.92px;
      &:hover {
        background-color: $color-aquamarine;
      }
    }

  }
  .icon__image {
    width: 25px;
    display: inline-block;
    margin-left: 12px;
    max-height: 25px;
  }
  .warning-message { 
    span {
      bottom: -20px;
      top: auto;
      font-family: $font-family-roboto;
      line-height: 20px;
      font-size: $font-small;
      color: $color-denim;
      display: block;
      margin-top: 3px;
    }
  }
  .box-content {
    margin-bottom: 20px;

    .item {
      margin-bottom: 16px;
      &.left_content_regis {
        width: 410px;
        padding-right: 50px;
        max-width: 50%;
        display: block;
        float: left;
      }
      &.right_content_regis {
        width: calc(100% - 410px);
        max-width: 360px;
        display: block;
        float: left;
      }

      .infor__name {
        display: block;
        width: 100%;
        font-size: $font_root;
        font-family: $font-family-roboto;
        line-height: 16px;
        margin-bottom: 5px;
        color: $color-grey-dusty;
      }

      .infor__content {
        // display: inline-block;
        width: 100%;
        .disable {
          background-color: $color-bright-gray;
          cursor: not-allowed;
          input {
            cursor: not-allowed;
          }
        }
        input {
          display: block;
          width: 100%;
          font-size: $font_root;
          line-height: 20;
          color: $color-grey-dark;
          padding: 8px 8px 8px 15px;
          background-color: transparent;
          border: solid 1px $color_alto;
          border-radius: $font-mini-3px;
          font-family: $font-family-roboto-medium;
          height: 35px;
        }
        &.active-warning {
          input {
            border-color: $color-denim;
          }
        }
        input[type='file'] {
          width: auto !important;
        }
        &.disable {
           input {
            background-color: $color-bright-gray;
           }
          input:hover {
            cursor: not-allowed;
          }
        }
      }

      .icon__content-box {
        // border: none;
        position: relative;
        border: solid 1px $color_alto;
        border-radius: $font-mini-3px;
        font-family: $font-family-roboto-medium;
        height: 35px;

        &.active-warning {
          // border-color: transparent !important;
          box-shadow: none !important;
           border-color: $color-denim;
        }
      }
    }
  }
</style>
