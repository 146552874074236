<template>
    <div class="account-balance">
        <div class="tabs-container">
            <div class="tab-item" v-for="(value, index) in tabs" :key="index"
                :class="index == tabActive && 'tab-active'" @click="tabActive = index">{{
                value }}</div>
        </div>
        <div class="box-table">
            <table>
                <thead>
                    <tr>
                        <th class="text-left " data-sort-field="coin">{{ $t('account.account_detail.coin') }}</th>
                        <th class="text-left " data-sort-field="in_order">{{ $t('account.account_detail.in_order') }}
                        </th>
                        <th class="text-left " data-sort-field="available">{{ $t('account.account_detail.available') }}
                        </th>
                        <th class="text-left" data-sort-field="total">{{ $t('account.account_detail.total') }}</th>
                        <th class="text-left ">{{ $t("account.account_detail.action") }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(value, index) in dataTable" :key="index">
                        <td class="text-left" style="min-width: 100px;">
                            {{ value.coin | uppercase }}
                        </td>
                        <td class="text-left" style="min-width: 200px;">
                            {{ value.inOrder | formatCurrencyAmountDecimal(2, '0') }}
                        </td>
                        <td class="text-left" style="min-width: 211px;">
                            {{ value.available | formatCurrencyAmountDecimal(2, '0') }}
                        </td>
                        <td class="text-left" style="min-width: 150px;">
                            {{ value.total | formatCurrencyAmountDecimal(2, '0') }}
                        </td>
                        <td class="text-left" style="min-width: 100px; width: 100px;">
                            <div class="action-wrap">
                                <img src="@/assets/images/icon/detail-icon.svg" width="20" @click="handleOpen(value)" />
                            </div>
                        </td>
                    </tr>
                    <template v-if="!this.dataTable || this.dataTable.length === 0">
                        <tr class="empty-data">
                            <td :colspan="5">
                                <span class="icon-nodata"></span>
                                <span>{{ $t('common.datatable.no_data') }}</span>
                            </td>
                        </tr>
                    </template>
                </tbody>
            </table>
        </div>
        <right-slide :dataDetail="dataDetail" :rightSlide="rightSlide" :onClose="handleClose" />
    </div>
</template>

<script>

import moment from "moment";
import rf from "../../lib/RequestFactory";
import RightSlide from "../../components/RightSlide";
import DataTable4 from "../../components/DataTable4.vue";

export default {
    name: "Account Detail",
    components: {
        RightSlide,
        DataTable4
    },
    data() {
        return {
            tabs: [
                window.i18n.t("account.account_detail.balance_spot"),
                window.i18n.t("account.account_detail.balance_future")
            ],
            dataTable: null,
            dataSpot: null,
            dataFuture: null,
            tabActive: 0,
            rightSlide: false,
            dataDetail: null
        }
    },
    watch: {
        tabActive: function (newValue) {
            this.getData();
            if (newValue === 0) this.dataTable = this.dataSpot;
            else this.dataTable = this.dataFuture;
        }
    },
    methods: {
        getData(params) {
            const id = this.$route.query.id
            if (this.tabActive == 0) this.getDataSpot({ ...params, id });
            else this.getDataFuture({ ...params, userId: id });
        },
        getDataSpot(params) {
            return rf.getRequest('UserRequest').getBalanceSpot(params).then(res => {
                if (res.success) {
                    const data = Object.entries(res.data[0]).map(([key, value]) => {
                        return {
                            inOrder: value.in_orders,
                            available: value.available_balance,
                            total: value.balance,
                            coin: key
                        }
                    })
                    this.dataSpot = data
                    this.dataTable = data
                }
            });
        },
        getDataFuture(params) {
            return rf.getRequest('UserRequest').getBalanceFutures(params).then(res => {
                if (res.code === 200) {
                    const data = res.data;
                    this.dataFuture = data
                    this.dataTable = data
                }
            });
        },
        onDatatableFinish() {
            const datatable = this.$refs.datatable;
        },
        handleClose() {
            this.dataDetail = null
            this.rightSlide = false
        },
        handleOpen(data) {
            this.dataDetail = data
            this.rightSlide = true
        }
    },
    created() {
        this.getData();
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/variables";

.account-balance {
    .tabs-container {
        display: flex;
        margin: 20px 0;
        margin-bottom: 15px;

        .tab-item {
            font-weight: 600;
            padding: 16px 10px;
            padding-right: 30px;
            color: $color-gray-dark;
            cursor: pointer;

            &:hover {
                background-color: $color-bright-gray;
            }

            &.tab-active {
                border-bottom: 2px solid $color-wood-smoke;
                color: $color-wood-smoke;
            }
        }
    }

    .box-table {
        padding: 10px 20px;
        background-color: $color_white;
        border-radius: 10px;
    }

    table {
        width: 100%;
        background-color: $color_white;
        border-radius: 10px;
        // border: $border_thin;
        overflow-y: hidden;

        thead {
            color: $color_grey;
            background-color: $color_white;
            border: none;
            overflow: hidden;

            th {
                user-select: none;
                position: relative;
                background-color: $color_white;
                line-height: 15px;
                font-size: $font_small;
                font-weight: 500;
                color: $color_grey;
                padding: 15px 15px;
                border-bottom: 1px solid $color_alto;
                font-family: $mulish-regular;
                height: 38px;

                &::after {
                    font-family: "icomoon" !important;
                    font-size: $font_small;
                    margin-left: 5px;
                    position: relative;
                    top: 0px;
                    width: 14px;
                    height: 14px;
                    display: inline-block;
                    line-height: 14px;
                    overflow: hidden;
                    position: relative;
                    top: 3px;
                    content: "";
                }

                &[data-sort-order] {
                    line-height: 15px;

                    &::after {
                        font-family: "icomoon" !important;
                        font-size: $font_small;
                        margin-left: 5px;
                        position: relative;
                        top: 0px;
                        width: 14px;
                        height: 14px;
                        display: inline-block;
                        line-height: 14px;
                        overflow: hidden;
                        position: relative;
                        top: 3px;
                        content: "";
                    }
                }

                &[data-sort-order="asc"] {
                    color: #2dac91;

                    &::after {
                        content: "\e906";
                    }
                }

                &[data-sort-order="desc"] {
                    color: #2dac91;

                    &::after {
                        content: "\e907";
                    }
                }
            }
        }

        tbody {
            tr {
                vertical-align: top;
                overflow-y: hidden;
                transition-property: height;
                transition-duration: 0.3s, 0.3s;
                transition-timing-function: ease, ease-in;
                height: auto;
                background-color: $color_white;
                border-bottom: 1px solid $color_catskill_white;

                &.active {
                    height: 100px;
                    max-height: 300px;
                    background-color: $color_grey_select;
                    transition-property: height;
                    transition-duration: 0.3s, 0.3s;
                    transition-timing-function: ease, ease-in;

                    .glyphicon-menu-down {
                        transition-duration: 0.5s;
                        transform: rotate(180deg);
                    }
                }

                &.empty-data {
                    td {
                        text-align: center;
                        color: $color_grey;
                        line-height: 200px;

                        p {
                            margin: 0px;
                        }
                    }
                }
            }

            td {
                height: 40px;
                overflow: initial;
                line-height: 23px;
                font-size: $font_root;
                font-weight: 500;
                color: $color-grey-dark;
                padding: 8px 25px 4px 15px;
                border-top: 1px solid $color-white-smoke;
                vertical-align: middle;
                font-family: $mulish-regular;
            }
        }
    }
}
</style>

<style lang="scss">
@import "@/assets/sass/variables";
</style>