import MasterdataUtils from '../common/MasterdataUtils';

export default class BaseRequest {
  async get(url, params = {}) {
    try {
      const response = await window.axios.get(process.env.VUE_APP_API_URL +  url, { params });
      return this._responseHandler(response);
    } catch (error) {
      this._errorHandler(error);
    }
  }

  async put (url, data = {}) {
    try {
      const response = await window.axios.put(process.env.VUE_APP_API_URL + url, data);
      return this._responseHandler(response);
    } catch (error) {
      this._errorHandler(error);
    }
  }

  async post(url, data = {}) {
    try {
      const response = await window.axios.post(process.env.VUE_APP_API_URL +  url, data);
      return this._responseHandler(response);
    } catch (error) {
      this._errorHandler(error);
    }
  }

  async del(url, params = {}) {
    try {
      const response = await window.axios.delete(process.env.VUE_APP_API_URL +  url, params);
      return this._responseHandler(response);
    } catch (error) {
      this._errorHandler(error);
    }
  }

  async _responseHandler (response) {
    const data = response.data;
    await this._checkMasterdataVersion(data);
    await this._checkMasterdataFutureVersion(data);
    return data;
  }

  _checkMasterdataVersion (data) {
    if (MasterdataUtils.isDataChanged(data.dataVersion)) {
      MasterdataUtils.clearMasterdata();
      window.app.$store.dispatch('getMasterdata');
    }
  }

  _checkMasterdataFutureVersion (data) {
    if (MasterdataUtils.isDataFutureChanged(data.dataVersion)) {
      MasterdataUtils.clearMasterdataFuture();
      window.app.$store.dispatch('getMasterdata');
    }
  }

  _errorHandler(err) {
    if (err.response && err.response.status === 401) { // Unauthorized (session timeout)
      window.location.href = '/admin/login';
    }
    throw err;
  }

}
