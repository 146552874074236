<template>
  <div class="user-information mg_bot50" id="userInformation">

    <div class="select_transaction_container">
      <div class="tab-header">
        <ul>
          <li :class="{'active': tableStatus === 'spot'}">
            <a
              href="javascript:void(0)"
              @click="selectTab('spot')"
            ><span>{{ $t('common.spot_exchange') }}</span></a>
          </li>
          <li :class="{'active': tableStatus === 'margin'}">
            <a
              href="javascript:void(0)"
              @click="selectTab('margin')"
            ><span>{{ $t('menu.margin_exchange') }}</span></a>
          </li>
        </ul>
      </div>
    </div>
    <div class="filter-container">
      <div class="filter-item date">
        <div class='title-text'>{{ $t('orders.open_order.date') }}:</div>
        <date-picker v-model="startDate" class="date-picker-input no-right-radius" format="yyyy - MM - dd"></date-picker>
        <date-picker v-model="endDate" class="date-picker-input no-left-radius" format="yyyy - MM - dd"></date-picker>
      </div>

      <div class="filter-item">
        <input
          title
          type="text"
          :placeholder="$t('orders.open_order.search_by_coin')"
          @keyup.enter="search"
          class="form-control"
          name="searchKey"
          v-model="filters.searchKey"
        >
      </div>

      <div class="filter-item">
        <button class="btn btn-search" @click="search()">{{ $t('orders.open_order.search') }}</button>
        <button class="btn btn-reset" @click="refresh()">{{ $t('orders.open_order.reset') }}</button>
      </div>
    </div>

    <div class="datatable">
      <data-table :getData="getData" ref="datatable" :limit="10" :column="4" @DataTable:finish="onDatatableFinish" class="scroll">
        <th class="text-left" width="15%" data-sort-field="coin">{{ $t('user_setting.coin')}}</th>
        <th class="text-left" width="15%" data-sort-field="receive_fee">{{ $t('user.receive_fee')}}</th>
        <th class="text-left" width="15%" data-sort-field="referral_fee">{{ $t('user.referral_fee')}}</th>
        <th class="text-left" width="15%" data-sort-field="net_fee">{{ $t('user.profit')}}</th>

        <template slot="body" slot-scope="props">
          <tr v-bind:class="{inactive: props.item.status == 'inactive'}">
            <td class="text-left">
              {{props.item.coin.toUpperCase()}}
            </td>
            <td class="text-left">
              {{props.item.receive_fee}}
            </td>
            <td class="text-left">
              {{props.item.referral_fee}}
            </td>
            <td class="text-left">
              {{props.item.net_fee}}
            </td>
          </tr>
        </template>
      </data-table>
    </div>
  </div>
</template>

<script>
  import rf from '../../lib/RequestFactory';
  import InputOnlyNumber from '../../common/InputOnlyNumber';
  import SelectBox from '../../components/SelectBox';
  import Modal from "../../components/Modal";
  import DatePicker from "vuejs-datepicker";
  import moment from "moment";
  import COMMON_CONST from "../../common/Const";

  export default {
    components: {
      InputOnlyNumber,
      Modal,
      DatePicker,
      SelectBox
    },
    data() {
      return {
        titlePage: this.$t('user.profit'),
        searchKey: '',
        params: {},

        startDate: moment()
        .subtract(6, "month")
        .toDate(),
        endDate: new Date(),
        filters: {
          searchKey: ""
        },
        groupSetting: [],
        datatableRows: [],
        groupRemove: '',
        userGroup: [],
        tableStatus: 'spot',
      }
    },
    watch: {
      tableStatus() {
        this.$refs.datatable.refresh();
      }
    },
    methods: {
      selectTab(tab) {
        this.tableStatus = tab;
      },
      search() {
        let filterParams = {
          start_date: moment(this.startDate)
            .startOf("day")
            .format("x"),
          end_date: moment(this.endDate)
            .endOf("day")
            .format("x")
        };
        if (this.startDate > this.endDate) {
          this.$toastr("error", this.$t("common.date.warning"));
          return;
        }
        if (this.filters.searchKey) {
          filterParams.search_key = this.filters.searchKey;
        }
        this.$refs.datatable.filter(filterParams);
      },
      refresh() {
        this.resetInput();
        this.$refs.datatable.refresh();
      },
      resetInput() {
        this.filters = Object.assign(this.filters, {
          searchKey: ""
        });
        this.startDate = moment()
          .subtract(6, "month")
          .toDate();
        this.endDate = new Date();
        $(".datatable").find("th").attr("data-sort-order", "");
      },
      
      onDatatableFinish() {
        window._.each(this.$refs.datatable.rows, item => {
          this.$set(item, 'editable', false);
          this.$set(item, 'submitting', false);
        });
      },

      getData(params) {
        if (this.startDate > this.endDate) {
          this.$toastr("error", this.$t("common.date.warning"));
          return;
        }

        params.start_date = moment(this.startDate).startOf("day").format("x");
        params.end_date = moment(this.endDate).endOf("day").format("x");
        if (this.filters.searchKey) {
          params.search_key = this.filters.searchKey;
        }

        let meta= {
          status: this.tableStatus
        };
        params = Object.assign(params, meta);

        return rf.getRequest('AdminRequest').getProfit(params)
          .then(res => {
            return res;
          });
      },

    },

    mounted() {
      this.$emit('EVENT_PAGE_CHANGE', this);
    },
  }
</script>

<style lang="scss">
@import "@/assets/sass/variables";
.group-list.user-list {
  .group-item {
    position: relative;
    &.group-item-del {
      .checkmark_box {
        top: 4px;
      }
      label {
        padding-left: 22px;
      }
    }
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 12px;
      width: 12px;
      z-index: 2;
    }
    .checkmark_box {
      position: absolute;
      top: 0;
      left: 0;
      height: 12px;
      width: 12px;
      background-color: $color-white;
      border: 1px solid $color_grey;
    }
    .checkmark_box:after {
      left: 3px;
      top: 0px;
      width: 4px;
      height: 7px;
      border: solid $color-white;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
    .checkmark_box:after {
        content: "";
        position: absolute;
        display: none;
    }
    input:checked ~ .checkmark_box {
      background-color: $color-aquamarine;
      border: 1px solid $color-blue-hover;
    }
    input:checked ~ .checkmark_box:after {
      display: block;
    }
  }
}
.datatable{
  font-family: $font-family-roboto !important;
}
.date-picker-input {
  display: inline-block;
  input {
    padding: 0 5px 0px 23px;
    width: 110px !important;
    border-radius: 3px;
    -webkit-box-shadow: none;
    box-shadow: none;
    height: 30px;
    line-height: 30px;
    border: 1px solid #d2d6de;
    background-image: url('@/assets/images/icon/date-time.svg') !important;
    background-color: $color-white-smoke !important;
    background-size: 12px !important;
    background-position: center left 5px !important;
    background-repeat: no-repeat !important;
    color: $color-grey-dusty;
    &:hover, &:active, &:focus {
      border-color: $color-jungle-green;
      z-index: 9;
    }
  }
}
.no-left-radius {
    input {
      border-radius: 0px 3px 3px 0px;
      margin-left: -1px;
    }
  }
  .no-right-radius {
    input {
      border-radius: 3px 0px 0px 3px;
    }
  }
.modal-content .modal-footer {
  text-align: center;
  margin-top: 30px;
}
.selectbox100{
  width: 100px !important;
}
#userInformation {
  font-family: $font-family-roboto;
  .sc_search_select {
    .group_search_select {
      .button_drop_search {
        // width: 55px;
        height: 30px !important;
        padding: 5px 10px 5px 10px !important;
        border-radius: 3px;
        font-size: $font-small;
        font-family: $font-family-roboto;
        i.icon-arrow1 {
          margin-left: 5px;
        }
        span {
          color: $color-grey-dusty !important;
        }
        &:hover, &:active, &:focus {
          border-color: $color-jungle-green;
          i.icon-arrow1 {
            color: $color-jungle-green;
          }
        }
        &.active {
          border-color: $color-jungle-green;
          i.icon-arrow1 {
            color: $color-jungle-green;
          }
        }
      }
      .box_list_search_select {
        .list_search_select {
          li {
            font-family: $font-family-roboto;
            font-size: $font-small;
            &:hover, &:active, &:focus {
              color: $color-jungle-green;
            }
          }
        }
      }
    }
  }
  .select-box-full {
    .group_search_select {
      .button_drop_search {
        height: 35px !important;
        font-size: $font_root;
        span {
          font-size: $font_root;
          line-height: 22px;
        }
        i.icon-arrow1 {
          font-size: $font_root;
        }
      }
      .box_list_search_select {
        ul {
          max-height: 180px;
          overflow-y: scroll;
        }
      }
    }
  }
  .box_select_user {
    .group_search_select {
      .button_drop_search {
        width: 90px !important;
        border-radius: 3px;
        i.icon-arrow1 {
          margin-left: 0px;
        }
      }
    }
  }
  td:first-child{
    padding: 8px 10px 4px 10px !important;
  }
}
@media only screen and (max-width: 1280px){
   body{
      min-width: 1280px !important;
    }
}
</style>

<style lang="scss" scoped>
@import "@/assets/sass/variables";

.title-text {
  font-family: $font-family-roboto;
  font-size: $font-small;
  color: $color-grey-dusty;
  padding-bottom: 3px;
}

table {
  thead {
    th {
      padding: 8px 10px 4px 10px !important;
      // text-align: center;
      color: $color_grey;
      &.non-sort {
        &::after {
          margin-left: 0px !important;
          width: 0px !important;
          height: 0px !important;
        }
      }
    }
  }
  tbody {
    tr {
      
      td {
        // text-align: center;
        vertical-align: middle;
        // padding-top: 5px;
        font-family: $font-family-roboto;
        padding: 8px 10px 4px 10px !important;
        .form-control {
          background-color: transparent !important;
          border-radius: 0px;
        }
      }
      
    }
  }
  
}


.user-information {
  .filter-container {
    margin: 5px 0px 15px 0px;
    height: 60px;
    .filter-item {
      float: left;
      margin: 0 5px;
      input {
        margin-top: 21px;
        display: inline-block;
        width: 145px;
        &:hover, &:active, &:focus {
          border-color: $color-jungle-green;
        }
      }
      select {
        width: 65px;
        display: inline-block;
        font-family: $font-family-roboto;
        font-size: $font_root;
        &:hover, &:active, &:focus {
          border-color: $color-jungle-green;
        }
      }
      button {
        margin: 0 5px;
        width: auto;
        margin-top: 21px;
        height: 30px;
        font-size: $font-smaller;
        font-family: $font-family-roboto;
        &:hover, &:active {
          border-color: $color-aquamarine;
          background-color: $color-aquamarine;
        }
      }
      .btn-search, .btn-reset, .btn-remove {
        margin-left: 0px;
        margin-right: 5px;
        margin-bottom: 10px;
        font-family: $font-family-roboto-bold;
        font-size: $font-smaller;
        border-radius: 3px;
        border: 1px solid $color-caribbean-green;
        width: 80px;
        color: $color_white;
        text-transform: uppercase;
        background-color: $color-caribbean-green;
        font-weight: 600;
      }
      .btn-remove {
        border: 1px solid $color-denim;
        color: $color-denim;
        background-color: transparent;
        &:hover, &:active, &:focus {
          color: $color-white;
        }
      }
    }
    .date {
      margin-right: 15px;
    }
    .pair {
      margin-right: 15px;
    }
  }
  .tool-button {
    margin-bottom: 15px;
    height: 30px;
    .btn {
      margin-top: 0px !important;
      min-width: 200px;
      .glyphicon {
        margin-right: 5px;
      }
    }
  }
  .form-control {
    background: $color-white-smoke;
    height: 30px;
    font-size: 13px;
    padding-left: 11px;
    padding-right: 0;
  }
  .datatable {
    button.btnForm.bt-action {
      padding-left: 5px !important;
      padding-right: 5px !important;
      font-weight: 400 !important;
    }
  }
  .buy {
      color: $text-color-jade;
    }
  .sell {
    color: $text-color-red;
  }
  .btn-detail {
    background-color: $color-caribbean-green;
    padding: 2px 10px;
    font-size: $font-smaller;
    color: $color-white;
    border:1px solid $color-caribbean-green;
    border-radius: 3px;
    text-transform: uppercase;
    font-family: $font-family-roboto;
    &:hover{
      background-color: $color-aquamarine;
      border-color: $color-aquamarine;
    };
  }
  .icon-notfound {
    font-size: 40px;
    padding-right: 15px;
  }
  .toolbar-element2 {
    display: inline-block;
    float: left;
  }
  .group-head {
    font-size: 20px;
    margin-bottom: 20px;
    font-family: $font-family-roboto-medium;
  }
  .group-list {
    border: 1px solid $color_alto;
    border-radius: 3px;
    padding: 15px 15px;
    height: 210px;
    overflow-y: scroll;
    .group-item{
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0px;
      }
      input{
        margin: 0px;
        margin-right: 10px;
      }
      label{
        font-family: $font-family-roboto;
        font-weight: normal;
        margin: 0px;
      }
    }
  }
  .user-list {
    height: 170px;
    margin-top: 15px;
  }
}
</style>

<style lang="scss" scoped>
@import "@/assets/sass/variables";
  .box_select_level {
    width: 40px;
  }
  .box_select_user {
    width: 80px;
    .group_search_select {
      width: 80px;
      .button_drop_search {
        width: 80px !important;
      }
    }
  }
  .cl1 {
    min-width: 52px;
  }
  .cl2 {
    min-width: 80px;
  }
  .cl3{
    min-width: 150px;
  }
  .cl4 {
      min-width: 95px;
  }
  .cl5 {
    min-width: 122px;
  }
  .cl6{
    min-width: 120px;
  }
  .cl7 {
    min-width: 130px;
  }
  .cl8 {
    min-width: 80px;
  }
  .cl9 {
    min-width: 90px;
  }
  // .cl3 {
  //   width: 280px;
  // }
  .cl10 {
    min-width: 50px;
  }
  // .cl4::after {
  //   width: 0px;
  //   height: 0px;
  //   display: none;
  // }
  .user-information {
    // width: 100%;
    .edit-input-number {
      input {
        width: 80px;
        padding-left: 5px;
        background: transparent;
        box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0);
        border-radius: 0px;
        border: 1px solid $color_alto;
      }
    }
    .select_user {
      box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0);
      border-radius: 0px;
      width: 80px;
      height: 27px;
      border: 1px solid $color_alto;
      line-height: 20px;
      padding: 3px 5px;
      background-color: transparent;
    }
    .item_email_user {
      position: relative;
      .txt_email_user {
        display: block;
        max-width: 240px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .tooltip_email_user {
        position: absolute;
        top: 0px;
        line-height: 20px;
        padding: 5px 20px;
        left: 45%;
        background-color: $color_white;
        white-space: nowrap;
        width: auto;
        z-index: 10;
        font-size: $font_root;
        font-weight: 500;
        color: $color_mine_shaft;
        transition: 0.5s;
        display: none;
        box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.4);
        &:after {
          right: 100%;
          top: 50%;
          border: solid transparent;
          content: " ";
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
          border-color: rgba(136, 183, 213, 0);
          border-right-color: $color_white;
          border-width: 5px;
          margin-top: -5px;
        }
      }
      &:hover {
        .tooltip_email_user {
          display: block;
          transition: 0.5s;
        }
      }
      .tooltip_group_user {
        left: 20px;
      }
    }
    .btn_save_user {
      background-color: transparent !important;
      font-size: $font_semi_big;
      height: 27px;
      padding: 2px 0px;
      line-height: 23px;
      float: right;
      margin-left: 15px;
      margin-right: 15px;
      .icon-save:before {
      color: $color_dove_gray;
      }
      &:active {
        box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0);
      }
      &:hover {
        color: $color-jungle-green;
        .icon-save:before {
          color: $color-jungle-green;
        }
      }
    }
    .btn_edit_user {
      background-color: transparent !important;
      font-size: $font_semi_big;
      height: 27px;
      padding: 2px 0px;
      line-height: 23px;
      float: right;
      margin-left: 15px;
      margin-right: 15px;
      .icon-save:before {
      color: $color_dove_gray;
      }
      &:active {
        box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0);
      }
      &:hover {
        color: $color-jungle-green;
        .icon-save:before {
          color: $color-jungle-green;
        }
      }
    }
  }

  // @media only screen and (min-width: 1399px) {
  //  .user-information .item_email_user .txt_email_user {
  //     max-width: 250px;
  //  }
  // }

  .select_transaction_container {
      display: block;
      width: 100%;
      float: left;
      margin: 10px 0px 15px 0px;
      ul {
        display: block;
        width: 100%;
        float: left;
        padding: 0 0 0 0;
        li {
          float: left;
          margin-right: 25px;
          font-size: 18px;
          color: $color-grey-dark;
          display: block;
          a {
            color: $color-grey-dark;
            font-family: $font-family-roboto-medium, sans-serif;
            font-weight: 500;
            font-size: $font_big;
            display: block;
            float: right;
            &:before {
              content: "";
              display: inline-block;
              width: 12px;
              height: 12px;
              border-radius: 50%;
              border: solid 1px #979797;
              margin-right: 3px;
            }
          }
          &.active {
            a {
              color: $color-jungle-green;
              &:before {
                border: solid 4px $color-jungle-green;
              }
            }
          }
        }
      }
    }
</style>

<style lang="scss">
@import "@/assets/sass/variables";
  #select-box-user-modal {
    .sc_search_select {
      .group_search_select {
        .button_drop_search {
          font-family: $font-family-roboto-medium !important;
          span {
            color: $color_mine_shaft !important;
          }
        }
      }
    }
  }
</style>