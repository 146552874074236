<template>
  <div class="coin-information-page">
    <p class="tab-title">{{ $t('withdrawal_information.tab_title') }}</p>
    <div class="content-page">
      <div class="box-content clearfix">
        
        <div class="item left_content_regis">
          <span class="infor__name">{{ $t('withdrawal_information.currency') }}</span>
          <div class="infor__content disable">
            <input v-model="token" disabled>
          </div>
        </div>

        <div class="item right_content_regis">
          <span class="infor__name">{{ $t('withdrawal_information.fee') }}</span>
          <div class="infor__content" :class="errors.has('fee') ? 'active-warning' : ''">
            <div class="input_box">
              <currency-input
                      v-validate="'required'"
                      :precision="handleDecimal(data.params.coin_setting.decimals)"
                      :max-length-cus="16"
                      :data-vv-as="`fee`"
                      v-model="data.params.withdrawal_setting.fee"
                      data-vv-validate-on="none"
                      class="form-input"
                      @focus="resetErrors"
                      name="fee"/>
              <span class="unit">{{ token }}</span>
            </div>
            <div class="warning-message">
              <span v-if="!clickReturn">{{ errors.first('fee') }}</span>
            </div>
          </div>

        </div>

        <div class="item left_content_regis list-group">
          <span class="infor__name">{{ $t('withdrawal_information.minimum_withdrawal_erc20') }}</span>
          <div class="box_minimum_withdrawal">
            <div class="box_flex" v-for="item in levels">
              <label class="box_label">Level {{item}}</label>
              <div class="infor__content" :class="errors.has(`minimum_withdrawal${item}`) ? 'active-warning' : ''">
                <div class="input_box">
                  <currency-input
                    v-validate.disable="`required`"
                    :precision="handleDecimal(data.params.coin_setting.decimals)"
                    :max-length-cus="16"
                    :data-vv-as="`level ${item}`"
                    v-model="data.params.withdrawal_setting['minium_withdrawal' + item]"
                    type="text"
                    data-vv-validate-on="none"
                    class="form-input"
                    @focus="resetErrors"
                    :name="`minimum_withdrawal${item}`"/>
                  <span class="unit roboto">{{ token }}</span>
                </div>
                <div class="warning-message">
                  <span v-if="!clickReturn">{{ errors.first(`minimum_withdrawal${item}`) }}</span>
                </div>
              </div>
            </div>
            <!-- <div class="panel-body">
              <div class="form-group" v-for="item in levels">
                <label class="col-sm-4">Level {{item}}</label>
                <div class="col-sm-8" :class="errors.has(`minimum_withdrawal${item}`) ? 'active-warning' : ''">
                    <currency-input
                            v-validate.disable="`required|min_value:${data.params.withdrawal_setting.fee}`"
                            :precision="8"
                            v-model="data.params.withdrawal_setting['minium_withdrawal' + item]"
                            data-vv-validate-on="none"
                            class="form-control"
                            :name="`minimum_withdrawal${item}`"/>
                    <div class="warning-message">
                      <span v-if="!clickReturn">{{ errors.first(`minimum_withdrawal${item}`) }}</span>
                    </div>
                </div>
              </div>
            </div> -->

          </div>
        </div>

        <div class="item right_content_regis">
          <span class="infor__name">{{ $t('withdrawal_information.limit') }}</span>
          <div class="box_minimum_withdrawal">
            <div class="box_flex" v-for="item in levels">
              <label class="box_label">Level {{item}}</label>
              <div class="infor__content" :class="errors.has(`limit${item}`) ? 'active-warning' : ''">
                <div class="input_box">
                  <currency-input
                    :precision="handleDecimal(data.params.coin_setting.decimals)"
                    :max-length-cus="16"
                    :data-vv-as="`level ${item}`"
                    v-validate="`required|min_value:${data.params.withdrawal_setting['minium_withdrawal' + item]}`"
                    v-model="data.params.withdrawal_setting['limit' + item]"
                    type="text"
                    data-vv-validate-on="none"
                    class="form-input"
                    @focus="resetErrors"
                    :name="`limit${item}`"/>
                  <span class="unit roboto">{{ token }}</span>
                </div>
                <div class="warning-message">
                  <span v-if="!clickReturn">{{ errors.first(`limit${item}`) }}</span>
                </div>
              </div>
            </div>

          </div>

        </div>

        <div class="clearfix"></div>
      </div>

      <div class="clearfix"></div>

      <div class="redirect-box">
        <!-- <button class="button--prev btn btn-primary" @click="onReturn">
          {{ $t('sales_point.return') }}
        </button> -->
        <button class="button--prev btn btn-primary" @click="$emit('pre-step')">
           {{ $t('trading_information.btn_back') }}
        </button>
        <button class="button--next btn btn-primary" @click="next">{{ $t('withdrawal_information.next') }}</button>
      </div>
    </div>
  </div>
</template>

<script>
  import InputOnlyNumber from '../../../common/InputOnlyNumber.vue';

  export default {
    name: 'registerERC20CoinInformation',
    components: { InputOnlyNumber },
    data () {
      return {
        clickReturn: false,
        levels: [1, 2, 3, 4]
      }
    },
    computed: {
      token () {
        return _.toUpper(this.data.params.coin_setting.symbol);
      }
    },
    inject: ['data'],
    mounted () {
      window.onbeforeunload = () => {
        return 'Are you sure you want to leave?';
      };
    },
    methods: {
      onReturn () {
        this.clickReturn = true;
      },
      resetErrors () {
        this.errors.clear();
      },
      handleDecimal(decimal) {
        return decimal > 8 ? 8 : decimal;
      },
      next () {
        this.clickReturn = true;
        this.$validator.validate().then((result) => {
          for (let i of this.levels) {
            this.data.params.withdrawal_setting['daily_limit' + i] = this.data.params.withdrawal_setting['limit' + i];
            if(this.data.params.withdrawal_setting['minium_withdrawal' + i]==0){
              this.errors.add({field: `minimum_withdrawal${i}`, msg: 'Withdrawal input must be greater than 0.'});
            }
            if(this.data.params.withdrawal_setting['limit' + i]==0){
              this.errors.add({field: `limit${i}`, msg: 'Limit must be greater than 0.'});
            }
          }

          if (!result || this.errors.any()) {
            this.clickReturn = false;
            return;
          }
          this.data.params.withdrawal_setting.currency = _.toLower(this.token);
          this.$emit('next-step');
        });
      }
    }
  };
</script>

<style lang="scss" scoped>
@import "@/assets/sass/variables";
  label{
    margin: 3px 0px 0px 3px;
  }
  .btn-primary{
    font-weight: 500;
    font-family: $font-family-roboto-bold;
    letter-spacing: 0.92px;
    width: 90px;
    text-align: center;
    padding: 5px;
    font-size: $font-smaller;
    background-color: $color-caribbean-green;
    border : 1px solid $color-caribbean-green;
    &:hover{
      background-color:$color-aquamarine;
      border : 1px solid $color-aquamarine;
    }
    &.button--prev {
      margin-right: 13px;
    }
  }
  .image-note {
    color: $color-denim;
    font-size: $font-small;
    bottom: 2px;
    margin-left: 5px;
    transform: -50%;
    line-height: 30px;
    font-family: $font-family-roboto;
  }

  .loading-wrap {
    position: relative;
    display: inline-block;
    height: 42px;
    vertical-align: middle;
    margin-left: 10px;
    .loading-content {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: all 0.3s ease-in-out;
    }
  }
  .coin-information-page {
    padding-top: $font_medium;
    display: block;
    max-width: 1000px;
    .tab-title {
      text-transform: uppercase;
      font-size: $font_semi_big;
      font-family: $font-family-roboto-medium;
      color: $color-grey-dark;
      margin-bottom: 20px;
    }
  }

  .choose-label {
    margin-top: 5px;
    input[type='file'] {
      position: absolute;
      visibility: hidden;
      opacity: 0;
      visibility: hidden;
    }

    .choose-btn {
      display: inline-block;
      text-align: center;
      font-size: $font-smaller;
      font-family: $font-family-roboto-bold;
      text-transform: uppercase;
      color: $color-white;
      width: 110px;
      height: 25px;
      line-height: 25px;
      padding: 1px 13px;
      text-align: center;
      border-radius: $font-mini-3px;
      background-color: $color-caribbean-green;
      cursor: pointer;
      letter-spacing: 0.92px;
      &:hover {
         background-color: $color-aquamarine;
      }
    }

  }
  .icon__image {
    width: 25px;
    display: inline-block;
    margin-left: 12px;
    max-height: 25px;
  }
  .warning-message { 
    span {
      bottom: -20px;
      top: auto;
      font-family: $font-family-roboto;
      line-height: 20px;
      font-size: $font-small;
      color: $color-denim;
      display: block;
      margin-top: 3px;
    }
  }
  .box-content {
    margin-bottom: 20px;

    .item {
      margin-bottom: 16px;
      &.left_content_regis {
        width: 410px;
        padding-right: 50px;
        max-width: 50%;
        display: block;
        float: left;
      }
      &.right_content_regis {
        width: calc(100% - 410px);
        max-width: 360px;
        display: block;
        float: left;
      }

      .infor__name {
        display: block;
        width: 100%;
        font-size: $font_root;
        font-family: $font-family-roboto;
        line-height: 16px;
        margin-bottom: 5px;
        color: $color-grey-dusty;
      }

      .infor__content {
        // display: inline-block;
        width: 100%;

        input {
          display: block;
          width: 100%;
          font-size: $font_root;
          line-height: 20;
          color: $color-grey-dark;
          padding: 8px 8px 8px 15px;
          background-color: transparent;
          border: solid 1px $color_alto;
          border-radius: $font-mini-3px;
          font-family: $font-family-roboto-medium;
          height: 35px;
        }
        &.active-warning {
          input {
            border-color: $color-denim;
          }
        }
        input[type='file'] {
          width: auto !important;
        }
        &.disable {
          background-color: $color-bright-gray;

          input:hover {
            cursor: not-allowed;
          }
        }
      }

      .icon__content-box {
        // border: none;
        position: relative;
        border: solid 1px $color_alto;
        border-radius: $font-mini-3px;
        font-family: $font-family-roboto-medium;
        height: 35px;

        &.active-warning {
          // border-color: transparent !important;
          box-shadow: none !important;
         border-color: $color-denim;
        }
      }
    }
  }
  .box_minimum_withdrawal {
    padding: 20px 25px 5px 25px;
    background: $color-white-smoke;
    border: 1px solid $color_alto;
    box-shadow: none;
    .box_flex {
      display: flex;
      padding-bottom: 15px;
    }
    .box_label {
      margin: 0px;
      font-weight: normal;
      font-family: $font-family-roboto;
      font-size: $font_root;
      color: $color-grey-dusty;
      margin-right: 20px;
      width: 60px;
      padding: 7px 0px;
      line-height: 20px;
      height: 35px;
    }
  }
  .input_box {
    position: relative;  
    display: block;
    height: 35px;
  }
  .unit {
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
    position: absolute;
    text-align: center;
    text-transform: uppercase;
    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
  }  
  .roboto {
    font-size: $font-family-roboto;
  }
</style>
