<template>
    <div class="activity-history-container">
        <data-table-4 :getData="getData" ref="datatable" :column="12" :limit="10"
            :inLine="true" class="scroll" :total-user="totalRecords">
            <th class="text-left" data-sort-field="id account">{{ $t('account.detail.activityhistory.id_account') }}</th>
            <th class="text-left" data-sort-field="email">{{ $t('account.detail.activityhistory.ip') }}</th>
            <th class="text-left" data-sort-field="level">&emsp;{{ $t('account.detail.activityhistory.device') }}</th>
            <th class="text-left" data-sort-field="time-create">{{ $t('account.detail.activityhistory.creationTime') }}</th>

            <template slot="body" slot-scope="props">
                <tr>
                    <td class="text-left">
                        <span>{{ props.item.id }}</span>
                    </td>
                    <td class="text-left">
                        <div class="text-top"><span>{{ props.item.ip }}</span></div>
                    </td>
                    <td class="text-left">
                        <span>{{ props.item.operating_system }}, {{ props.item.platform }}</span>
                    </td>
                    <td class="text-left">
                        <p style="text-align: left">{{ formatDate(props.item.creation_time) }}</p>
                        <p style="text-align: left">{{ formatDate(props.item.creation_time, true) }}</p>
                    </td>
                </tr>
            </template>
        </data-table-4>
    </div>
</template>

<script>

import moment from "moment";
import rf from "../../lib/RequestFactory";
import Modal from "../../components/Modal";
import DataTable4 from "../../components/DataTable4";

export default {
    name: "ActivityHistory",
    components: {
        Modal,
        DataTable4
    },
    data() {
        return {
            totalRecords: 0
        }
    },
    watch: {
        isActive() {
            this.$refs.datatable.refresh();
        }
    },
    methods: {
        getData(params) {
            const id = this.$route.query.id;
            let meta = {}
            params = Object.assign(params, meta);
            params.size = params.limit || 5;
            delete params.limit;

            const response = rf.getRequest('UserRequest').getActivityHistory(id, params);
            response.then(res => {
                this.totalRecords = res.data.total;
            })
            return response
        },
        formatDate(date, isTime) {
            if (isTime) return moment(date).format('HH:mm:ss')
            return moment(date).format('YYYY-MM-DD');
        },
    },
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/variables";

.activity-history-container {
    margin-top: 60px;
}
</style>