<template>
  <div class="deposit_pages boxCore" id="order_future">
    <div class="order_future-filter">
      <div class="filter-template">
        <div class='label-text'>{{ $t('funds.balances.from') }}</div>
        <date-picker v-model="startDate" class="date-picker-input" :format="customFormatter"></date-picker>
      </div>
      <div class="filter-template">
        <div class='label-text'>{{ $t('funds.history.to') }}</div>
        <date-picker v-model="endDate" class="date-picker-input" :format="customFormatter"></date-picker>
      </div>
      <div class="filter-template">
        <div class="label-text text-pair date-text">{{ $t('orders.open_order.symbol') }}</div>
        <div class="toolbar-element2">
          <select-box v-model="filters.symbol" :placeholder="$t('order.order_history.all')" :options="SymbolData" />
        </div>
      </div>
      <div class="filter-template type">
        <div class="label-text date-text">{{ $t('orders.open_order.type') }}</div>
        <div class="toolbar-element2">
          <select-box v-model="filters.side" :placeholder="$t('order.order_history.all')" :options="SideData" />
        </div>
      </div>
      <!-- <div class="filter-template">
        <div class='label-text'>Side</div>
        <div class="input-dropdown" v-click-outside="clickOut2">
          <input type="text" @click="showDropdownSide" :placeholder=" $t('order.order_history.all') " class="form-control" v-model="sideFilter" readonly/>
          <span class="icon-coin-input" @click="showDropdownSide"><i class="fa" :class="!isShowSideOptions? 'fa-angle-down' : 'fa-angle-up' "></i></span>
          <div class="coin-option" v-if="isShowSideOptions">
            <span class="option" @click="onSelectDropdown(null, 'side')">{{ $t('order.order_history.all') }}</span>
            <span class="option" v-for="type in SideData" :key="type.value" @click="onSelectDropdown(type, 'side')">{{ type.name  }}</span>

          </div>
        </div>
      </div> -->
      <!-- <div class="filter-template">
        <div class='label-text'>Status</div>
        <div class="input-dropdown" v-click-outside="clickOut3">
          <input type="text" @click="showDropdownStatus" :placeholder=" $t('order.order_history.all') " class="form-control" v-model="statusFilter" readonly/>
          <span class="icon-coin-input" @click="showDropdownStatus"><i class="fa" :class="!isShowStatusOptions? 'fa-angle-down' : 'fa-angle-up' "></i></span>
          <div class="coin-option" v-if="isShowStatusOptions">
            <span class="option" @click="onSelectDropdown(null, 'status')">{{ $t('order.order_history.all') }}</span>
            <span class="option" v-for="type in StatusData" :key="type.value" @click="onSelectDropdown(type, 'status')">{{ type.name  }}</span>

          </div>
        </div>
      </div> -->
      <div class="filter-template">
        <div class="label-text">&nbsp;</div>
        <input
            title
            type="text"
            :placeholder="$t('account.detail.affiliate.inputplace.search')"
            @keyup.enter="search"
            class="form-control search_symbol"
            name="searchKey"
            v-model="filters.searchKey"
        >
      </div>
      <div class="filter-template">
        <div class="label-text">&nbsp;</div>
        <button class="btn btn-reset btn-search" @click="search()">{{ $t('orders.open_order.search') }}</button>
        <button class="btn btn-reset" @click="refresh()">{{ $t('orders.open_order.reset') }}</button>
        <button class="btn btn-download" @click="downloadExcel()">
          <img src="@/assets/images/download-icon.svg" width="12" />
          {{ $t('orders.open_order.data') }}
        </button>
      </div>
    </div>

    <div class="table-futures">
      <data-table-futures :getData="getFuturePositionsHistoryRecords" ref="datatable" :column="12" :limit="10" :inLine="true" class="scroll" :total-user="totalRecords">
        <th class="text-left">{{ $t('order.open_order.id_position') }}</th>
        <th class="text-left">{{ $t('order.open_order.select_future') }}</th>
        <th class="text-left">{{ $t('order.open_order.side') }}</th>
        <th class="text-left">{{ $t('order.open_order.position') }}</th>
        <th class="text-left">{{ $t('order.open_order.avg_entry_price') }}</th>
        <th class="text-left">{{ $t('order.open_order.avg_exit_price') }}</th>
        <th class="text-left">{{ $t('order.open_order.margin') }}</th>
        <th class="text-left">{{ $t('order.open_order.realized_pnl') }}</th>
        <th class="text-left">{{ $t('order.open_order.time_open') }}</th>
        <th class="text-left">{{ $t('order.open_order.time_close') }}</th>
        <th class="text-left">{{$t('order.open_order.action')}}</th>

        <template slot="body" slot-scope="props">
          <tr>
            <td class="text-left" >
              {{ props.item.positionId }}
            </td>
            <td class="text-left">
              <div class="text-top">{{ props.item.symbol }} </div>
              <div class="text-bottom"> {{ props.item.marginMode | firstLetterCapitalize }} {{ Number(props.item.leverage) }}x</div>
            </td>
            <td class="text-left">
              <div class="buy" v-if="props.item.side === 'BUY'">
                Long
              </div>
              <div class="sell" v-else>
                Short
              </div>
            </td>
            <td class="text-left">
              <div class="text-top">{{props.item.quantity | formatCurrencyAmountDecimal(8, "0")}}  {{getCurrencyPositionRecord(props.item)}}</div>
              <!-- <div class="text-bottom"> &asymp; {{props.item.quantity * props.item.entryPrice | formatCurrencyAmountDecimal(2, "0")}} {{props.item?.asset}}</div> -->
            </td>
            <td class="text-left" >
              {{props.item.entryPrice | formatCurrencyAmountDecimal(2, "0")}}
            </td>
            <td class="text-left" >
              {{props.item.exitPrice | formatCurrencyAmountDecimal(2, "0")}}
            </td>
            <td class="text-left" >
              {{props.item.margin | formatCurrencyAmountDecimal(4, "0")}}
            </td>
            <td class="text-left" >
              {{props.item.realizedPnl | formatCurrencyAmountDecimal(4, "0")}}
            </td>
            <td class="text-left" >
              <div class="text-top">{{ props.item.openPositionTime | dateFormat2('YYYY-MM-dd') }} </div>
              <div class="text-bottom">{{ props.item.openPositionTime | timeFormat2('HH:mm:ss') }}</div>
            </td>
            <td class="text-left" >
              <div class="text-top">{{ props.item.closePositionTime | dateFormat2('YYYY-MM-dd') }} </div>
              <div class="text-bottom">{{ props.item.closePositionTime | timeFormat2('HH:mm:ss') }}</div>
            </td>
            <td class="text-left" >
            <span class="img-all">
                &emsp;<img @click="handleOpen(props.item)" class="imge" src="@/assets/images/future/List.svg" width="17px" height="17px"/>
            </span>
            </td>
          </tr>
        </template>
      </data-table-futures>
    </div>

    <!-- <div class="clearfix clearfix-40"></div> -->
    <right-slide :dataDetail="dataDetail" :rightSlide="rightSlide" :onClose="handleClose" />
  </div>
</template>

<script>

import moment from "moment";
import COMMON_CONST from "../../common/Const";
import rf from "../../lib/RequestFactory";
import {mapActions} from "vuex";
import DatePicker from 'vuejs-datepicker';
import Modal from "../../components/Modal";
import SelectBox from "../../components/SelectBox";
import BigNumber from "bignumber.js";
import RightSlide from "../../components/RightSlide.vue";

export default {
  name: "OrderFuturesRecords",
  components: {
    Modal,
    DatePicker,
    SelectBox,
    RightSlide
  },
  data() {
    return {
      titlePage: window.i18n.t('menu.futures.positions_history'),
      searchKey: '',
      isActive: true,
      startDate: moment().subtract(1, 'week').toDate(),
      endDate: moment().toDate(),
      filters: {
        searchKey: "",
        symbol: null,
        status: null,
        type: null,
      },
      symbolFilter: null,
      sideFilter: null,
      statusFilter: null,
      isLoading: false,
      SideData: [
          { id: "", name: window.i18n.t("order.order_history.all") },
          {name: 'Long', id: 'BUY'},
          {name: 'Short', id: 'SELL'},
      ],
      SymbolData: [{ id: "", name: window.i18n.t("order.order_history.all") }],
      StatusData: [
          {name: 'Pending', value: 'PENDING'},
          {name: 'Partial Filled', value: 'partial_filled'},
          {name: 'Open', value: 'OPEN'},
      ],
      isShowSymbolOptions: false,
      isShowSideOptions: false,
      isShowStatusOptions: false,
      totalRecords: 0,
      rightSlide: false,
      dataDetail: null,
    }
  },
  watch: {
    isActive() {
      this.$refs.datatable.refresh();
    }
  },
  methods: {
    selectTab(tab) {
      this.isActive = tab;
      this.filters.searchKey = '';
      this.$refs.datatable.refresh();
      this.startDate = moment().subtract(1, 'week').toDate();
      this.endDate = moment().toDate();
    },
    onSelectDropdown(value, type) {
      if (type == 'symbol') {
        this.isShowSymbolOptions = false;
        if( value === null ) {
          this.symbolFilter = 'All'
          this.filters[type] = null
        }else  {
          this.symbolFilter =  value?.name
          this.filters[type] = value.value;
        }
      } else if (type == 'status') {
        this.isShowStatusOptions = false;
        if( value === null ) {
          this.statusFilter = 'All'
          this.filters[type] = null
        }else  {
          this.statusFilter =  value?.name
          this.filters[type] = value.value;
        }
      } else {
        this.isShowSideOptions = false;
        if( value === null ) {
          this.sideFilter = 'All'
          this.filters[type] = null
        }else  {
          this.sideFilter =  value?.name
          this.filters[type] = value.value;
        }
      }
      //   this.filters[type] = null
      // }else if ( type !== 'side') {
      //   this.sideFilter =  value?.name
      //   this.filters[type] = value.value;
      // } else {
      //   console.log(22321)
      //   this.filters[type] = value
      // }
    },
    clickOut1() {
      this.isShowSymbolOptions = false;
    },
    clickOut2() {
      this.isShowSideOptions = false;
    },
    clickOut3() {
      this.isShowStatusOptions = false;
    },
    showDropdownSymbol() {
      this.isShowSymbolOptions = !this.isShowSymbolOptions;
    },
    showDropdownSide() {
      this.isShowSideOptions = !this.isShowSideOptions;
    },
    showDropdownStatus() {
      this.isShowStatusOptions = !this.isShowStatusOptions;
    },

    customFormatter(date) {
      return moment(date).format('YYYY-MM-DD');
    },
    search() {
      let filterParams = {
        start_date: moment(this.startDate)
            .startOf("day")
            .format("x"),
        end_date: moment(this.endDate)
            .endOf("day")
            .format("x")
      };
      if (this.customFormatter(this.startDate) === this.customFormatter(this.endDate)) {
        filterParams = {
          start_date: moment(this.startDate)
              .startOf("day")
              .format("x"),
          end_date: moment(this.startDate)
              .endOf("day")
              .format("x")
        }
      }
      if (this.customFormatter(this.startDate) > this.customFormatter(this.endDate)) {
        this.$toastr("error", this.$t("common.date.warning"));
        return;
      }
      if (this.filters.searchKey) {
        filterParams.search_key = this.filters.searchKey;
      }
      this.$refs.datatable.filter(filterParams);
    },
    refresh() {
      this.resetInput();
      this.$refs.datatable.refresh();
    },
    downloadExcel() {
      let filterParams = {
        start_date: moment(this.startDate)
          .startOf("day")
          .format("x"),
        end_date: moment(this.endDate)
          .endOf("day")
          .format("x")
      };
      if (this.startDate > this.endDate && this.startDate.toDateString() != this.endDate.toDateString()) {
        this.$toastr("error", this.$t("common.date.warning"));
        return;
      }
      if (this.filters.symbol) {
        filterParams.symbol = this.filters.symbol.id;
      }
      if (this.filters.searchKey) {
        filterParams.search_key = this.filters.searchKey;
      }
      if (this.filters.side && this.filters.side != '') {
        console.log("🚀 ~ downloadExcel ~ this.filters.side:", this.filters.side)
        filterParams.side = this.filters.side.id;
      }

      rf.getRequest('AdminRequest').exportFuturePositionsHistoryRecords(filterParams).then(res => {
        if (res?.code === 200) {
          // window.open(`${process.env.VUE_APP_API_URL}/${res.data.fileName}`, '_blank')
        // const decodedData = Buffer.from(res.data.base64Data, 'base64').toString();
        // console.log("🚀 ~ rf.getRequest ~ decodedData:", decodedData)
        const byteCharacters = atob(res.data.base64Data);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);

        // Create a Blob from ArrayBuffer
        const blob = new Blob([byteArray], {
            type:'application/vnd.openxmlformatsofficedocument.spreadsheetml.sheet',
        });

        // Create a temporary anchor element
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = res.data.fileName; // File name
        document.body.appendChild(link);

        // Programmatically click the link to trigger the download
        link.click();

        // Clean up
        document.body.removeChild(link);
        window.URL.revokeObjectURL(link.href);
        }
      })
    },
    onClose() {
      alert("close")
    },
    resetInput() {
      this.filters = Object.assign(this.filters, {
        searchKey: "",
        side: null,
        symbol: null,
        status: null,
        type: null,
      });
      this.symbolFilter = null
      this.sideFilter = null
      this.statusFilter = null
      this.startDate = moment().subtract(1, 'week').toDate();
      this.endDate = moment().toDate();
      $(".datatable").find("th").attr("data-sort-order", "");
    },
    listenForNotification() {
      window.Echo.channel('App.Models.Admin')
          .listen('AdminNotificationUpdated', () => {
            this.$refs.datatable.refresh();
          });
    },
    getFuturePositionsHistoryRecords(params) {
      let filterParams = {
        start_date: this.customFormatter(this.startDate),
        end_date: this.customFormatter(this.endDate)
      };
      if (this.customFormatter(this.startDate) === this.customFormatter(this.endDate)) {
        filterParams = {
          start_date: this.customFormatter(this.startDate),
          end_date: this.customFormatter(this.endDate)
        }
      }
     
      let meta = {
        isActive: this.isActive,
        from: filterParams.start_date,
        to: filterParams.end_date,
      };
      if (this.filters.searchKey) {
        meta.search_key = this.filters.searchKey;
      }
      if (this.filters.side && this.filters.side.id) {
        meta.side = this.filters.side.id;
      }
      if (this.filters.symbol) {
        meta.symbol = this.filters.symbol.id;
      }
      params = Object.assign(params, meta);
      params.size = params.limit || 5;
      delete params.limit;
      const records = rf.getRequest('AdminRequest').getFuturePositionsHistoryRecords(params);
      records.then(data => {
        this.totalRecords = data.metadata.total;
      })
      return records;
    },
    getCurrencyPositionRecord(value) {
       if(value.contractType === 'COIN_M') {
        // return value.asset
        return 'Cont'
      }else {
        const index = value.symbol?.indexOf(value.asset);
        return value.symbol?.slice(0, index) + value.symbol?.slice(index + value.asset.length);
      }
    },
    filledOrder(value) {
      // return new BigNumber(value.quantity).minus(value.remaining)
      return value.quantity
    },
    handleClose() {
      this.dataDetail = null;
      this.rightSlide = false;
    },
    handleOpen(data) {
      // console.log(data)
      this.dataDetail = data;
      this.rightSlide = true;
    },

    getOptionData() {
      this.SymbolData = [{ id: "", name: window.i18n.t("order.order_history.all") }];
      rf.getRequest("AdminRequest")
        .getInstruments()
        .then((res) => {
          res?.data.map((item) => {
            this.SymbolData.push({
              id: item.name,
              name: item.symbol
            })
          })
        });
    },
  },
  created() {
    this.getOptionData();
  },
  mounted() {
    this.$emit('EVENT_PAGE_CHANGE', this);
    this.listenForNotification();

  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/variables";
.d-flex {
  display: flex;
}
.w-100 {
  width: 100%;
}
.buy {
  color: $text-color-jade!important;
}
.sell {
  color: $text-color-red!important;
}
#order_future {
  table {
    thead {
      th {
        &:first-child {
          width: 8%;
          min-width: 110px;
        }
        &:nth-child(2) {
          width: 12%;
          min-width: 120px;
        }
        &:nth-child(3) {
          width: 7%;
          min-width: 80px;
        }
        &:nth-child(4) {
          width: 12%;
          min-width: 140px;
        }
        &:nth-child(5) {
          width: 12%;
          min-width: 130px;
        }
        &:nth-child(6) {
          width: 12%;
          min-width: 120px;
        }
        &:nth-child(7) {
          width: 11%;
          min-width: 110px;
        }
        &:nth-child(8) {
          width: 10%;
          min-width: 110px;
        }
        &:nth-child(9) {
          width: 11%;
          min-width: 110px;
        }
        &:nth-child(10) {
          width: 11%;
          min-width: 110px;
        }
        &:nth-child(11) {
          width: 5%;
          min-width: 65px;
        }
      }
    }
  }
}

.no-left-radius {
  input {
    border-radius: 0px 3px 3px 0px;
  }
}
.no-right-radius {
  input {
    border-radius: 3px 0px 0px 3px;
  }
}
td{
  word-break: break-all;
  vertical-align: middle!important;
  border-bottom: solid 1px $color-white-smoke;
  .bt {
    width: 100px;
    height: 36px;
    border-radius: 3px;
    color: $color_black_logo;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 12px;
    cursor: pointer;
    &.pending {
      background: $color-yellow-pale;
    }
    &.pending {
      background: $color-aero-orange;
    }
    &.open {
      background: $color_alto_gray;
    }
  }
  .img-all {
    .imge {
      margin-right: 6px;
      cursor: pointer;
    }
  }
}
.datatable{
  font-family: $font-family-roboto !important;
}

ul {
  list-style-type: none;
  padding: 0px;
}
.clearfix-40 {
  display: block;
  clear: both;
  height: 40px;
}
.deposit_pages {
  font-family: $font_family_root;
  .order_future-filter {
    display: flex;
    flex-wrap: wrap;
  }
}
.select_transaction_container {
  padding: 10px 0px 20px 0px;
  .input-radio {
    label {
      display: inline;
      font-weight: normal;
      text-align: center;
      margin-right: 25px;
      span {
        font-size: $font_big;
      }
      input[type="radio"] {
        display: inline-block;
        margin-top: 0px;
        position: relative;
        top: -2px;
      }
    }
  }
}
.filter-container {
  margin: 10px 0 30px 0px;
  height: 60px;
  .filter-item {
    .label-text {
      font-size: $font-small;
      color: $color-grey-dusty;
      padding-bottom: 4px;
    }
    float: left;
    margin: 0px;
    margin-right: 20px;
    input {
      background-color: $color-white-smoke;
      margin-top: 22px;
      display: inline-block;
      width: 145px;
    }
    select {
      width: 65px;
      display: inline-block;
    }
    button {
      margin: 0 5px;
      width: auto;
      margin-top: 22px;
      height: 30px;
      font-size: 13px;
    }
    .btn-search, .btn-reset {
      font-size: $font-smaller;
      font-family: $font-family-roboto-bold;
      border-radius: 10px;
      border: 1px solid $color-caribbean-green;
      width: 85px;
      color: $color_white;
      text-transform: uppercase;
      background-color: $color-caribbean-green;
      &:hover {
        background-color: $color-aquamarine;
        border-color: $color-aquamarine;
        transition: 0.5s;
      }
    }
  }
  .btn-action {
    margin-left: -10px;
  }
}
.form-control {
  background: $color_concrete;
  height: 30px;
  font-size: $font-small;
  padding-left: 11px;
  padding-right: 0;
  &:hover, &:focus {
    border-color: $color-jungle-green;
  }
}
.datatable {
  button.btnForm.bt-action {
    padding-left: 5px !important;
    padding-right: 5px !important;
    font-weight: 400 !important;
  }
}
.buy {
  color: $text-color-jade;
}
.sell {
  color: $text-color-red;
}
.toolbar-element2 {
  display: inline-block;
  float: left;
  width: 65px;
  min-width: 110px;
}
td {
  font-family: $font-family-roboto;
}
.table-futures {
  margin-top: 24px;
}
.filter-template {
  margin-right: 15px;
  .label-text {
    color: $dark-1;
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 7px;
  }
  .date-picker-input {
    display: inline-block;
    float: left;
    ::v-deep{
      input {
        padding: 0 5px 0px 36px;
        width: 135px !important;
        height: 32px;
        border: 1px solid #C7CBD3!important;
        border-radius: 10px!important;
        background-image: url('@/assets/images/icon/date-time.svg') !important;
        background-color: $color-white-smoke !important;
        background-size: 12px !important;
        background-position: center left 12px !important;
        background-repeat: no-repeat !important;
        color: $dark-1;
        &:hover, &:active, &:focus {
          border-color: $color-jungle-green;
          z-index: 9;
        }
      }
    }

  }
  .btn-reset {
    background: $color-caribbean-green;
    border-radius: 10px;
    color: $color_white;
    font-weight: 700;
    width: 78px;
    height: 32px;
  }
  .btn-search {
    margin: 0 10px;
  }
  .btn-download {
    background: $color-caribbean-green;
    border-radius: 10px;
    color: $color_white;
    font-weight: 700;
    width: 95px;
    height: 32px;
    margin-left: 10px;
  }
  .search_symbol {
    background: $color-white;
    border: 1px solid #C7CBD3!important;
    border-radius: 10px!important;
    width: 197px;
    height: 32px;
  }
}
.tab-header {
  display: block;
  width: 100%;
  float: left;
  margin: 10px 0px 23px 0px;
  ul {
    list-style: none;
    padding-left: 0px;
    li {
      float: left;
      margin-right: 25px;
      font-size: $font_big;
      color: $color-grey-dark;
      display: block;
      >a {
        color: $color-grey-dark;
        font-family: $font-family-roboto-medium;
        font-weight: 500;
        font-size: $font_big;
        display: block;
        float: right;
        &::before {
          content: "";
          display: inline-block;
          width: 12px;
          height: 12px;
          border-radius: 50%;
          border: solid 1px #979797;
        }
        span {
          padding-left: 5px;
        }
      }
      &.active {
        >a {
          &::before {
            border: solid 4px $color-jungle-green;
          }
          color: $color-jungle-green;
        }
      }
    }
  }
}
.input-dropdown {
  position: relative;
  input {
    background: $color-white;
    border: 1px solid #C7CBD3!important;
    border-radius: 10px!important;
    width: 110px;
    height: 32px;
    cursor: pointer;
  }
  .icon-coin-input {
    position: absolute;
    right: 10px;
    top: 50%;
    transform:translateY(-50%);
    cursor: pointer;
    color:#667186;
    i {
      font-size: 22px;
    }
  }
  .coin-option {
    position: absolute;
    top: 37px;
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0px 4px 15px rgba(0, 19, 54, 0.15);
    border-radius: 10px;
    padding: 17px 0;
    z-index: 3;
    &:after {
      bottom: 100%;
      left: 50%;
      border: solid transparent;
      content: " ";
      display: block;
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-color: rgba(136, 183, 213, 0);
      border-bottom-color: $color-white;
      border-width: 5px;
      margin-left: -5px;
    }
    .option {
      display: flex;
      align-items: center;
      width: 100%;
      line-height: normal;
      cursor: pointer;
      padding-left: 14px;
      font-size: 14px;
      font-weight: 500;
      overflow: hidden;
      color: #001336;
      height: 32px;

      &:hover {
        background: $color-athens;
      }
    }
  }
}
</style>

<style lang="scss">
@import "@/assets/sass/variables";
#deposit_fiat_pages {
  .sc_search_select {
    .group_search_select {
      .button_drop_search {
        width: 55px;
        height: 30px !important;
        padding: 5px 10px 5px 10px !important;
        border-radius: 3px;
        margin-right: 20px;
        font-size: $font-small;
        font-family: $font-family-roboto;
        i.icon-arrow1 {
          margin-left: 5px;
          color: $color_dove_gray;
        }
        &:hover {
          border-color: $color-jungle-green;
          i.icon-arrow1 {
            color: $color-jungle-green;
          }
        }

      }
      .box_list_search_select {
        .list_search_select {
          li {
            font-family: $font-family-roboto;
            font-size: $font-small;
            &:hover, &:active, &:focus {
              color: $color-jungle-green;
            }
          }
        }
      }
    }
  }
}
</style>