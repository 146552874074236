<template>
  <div class="deposit_pages boxCore" id="deposit_fiat_pages">
    <div class="datatable">
      <div class="select_transaction_container">
        <div class="tab-header">
          <ul>
            <li :class="{'active': transactionStatus === 'pending'}">
              <a
                href="javascript:void(0)"
                @click="selectTab('pending')"
              ><span>{{ $t('transactions_pending') }}</span></a>
            </li>
            <li :class="{'active': transactionStatus === 'history'}">
              <a
                href="javascript:void(0)"
                @click="selectTab('history')"
              ><span>{{ $t('transactions_history') }}</span></a>
            </li>
          </ul>
        </div>
      </div>

       <div class="filter-container">
        <div class="filter-item date">
          <div class='label-text'>{{ $t('orders.open_order.date') }}:</div>
          <date-picker v-model="startDate" class="date-picker-input no-right-radius" :format="customFormatter"></date-picker>
          <date-picker v-model="endDate" class="date-picker-input no-left-radius" :format="customFormatter"></date-picker>
        </div>
        <div class="filter-item">
          <input
            title
            type="text"
            :placeholder="$t('orders.open_order.search_by_email')"
            @keyup.enter="search"
            class="form-control"
            name="searchKey"
            v-model="filters.searchKey"
          >
        </div>
        <div class="filter-item btn-action">
          <button class="btn btn-search" @click="search()">{{ $t('orders.open_order.search') }}</button>
          <button class="btn btn-reset" @click="refresh()">{{ $t('orders.open_order.reset') }}</button>
        </div>
      </div>

      <div class="datatable">
        <data-table :getData="getPendingTransactions" ref="datatable" :limit="10" :column="7" class="scroll">
          <th class="text-left cl1" data-sort-field="created_at">{{ $t('usd_transaction.time') }}</th>
          <th class="text-left cl2" data-sort-field="email">{{ $t('usd_transaction.user') }}</th>
          <th class="text-left cl3" data-sort-field="code">{{ $t('funds.deposit_usd.pending_transaction.code') }}</th>
          <th class="text-left cl4" data-sort-field="bank_name">{{ $t('usd_transaction.bank_name') }}</th>
          <th class="text-left cl5" data-sort-field="account_name">{{ $t('usd_transaction.account') }}</th>
          <th class="text-left cl6" data-sort-field="amount">{{ $t('usd_transaction.amount') }}</th>
          <th v-if="transactionStatus =='pending'" class="text-left cl7">{{ $t('usd_transaction.status') }}</th>
          <th v-else data-sort-field="status" class="text-left cl7 min_w_95px">{{ $t('usd_transaction.status') }}</th>
          <template slot="body" slot-scope="props">
            <tr>
              <td class="text-left cl1" >
                <div>{{ props.item.created_at | formatTimeStamp('YYYY-MM-DD') }} {{ props.item.created_at | formatTimeStamp('HH:mm:ss') }}</div>
                <div></div>
              </td>
              <td class="text-left cl2">
                <div class="item_email_user">
                  <span class="txt_email_user">{{props.item.email}}</span>
                  <span class="tooltip_email_user">{{props.item.email}}</span>
                </div>
              </td>
              <td class="text-left cl3" >
                {{props.item.code}}
              </td>
              <td class="text-left cl4">
                 <div class="item_bank_user">
                  <span class="txt_bank_user">{{props.item.bank_name}}</span>
                  <span class="tooltip_bank_user">{{props.item.bank_name}}</span>
                </div>
              </td>
              <td class="text-left cl5">
                 <div class="item_bank_user">
                  <span class="txt_bank_user">{{props.item.account_name}} / {{props.item.account_no}}</span>
                  <span class="tooltip_bank_user">{{props.item.account_name}} / {{props.item.account_no}}</span>
                </div>
              </td>
              <td class="text-left cl6" >
                {{props.item.amount | formatUsdAmount('0')}}
              </td>
              <td class="text-left cl7">
                <div v-if="transactionStatus == 'pending'" >
                  <button class="btn btn_Confirm" @click="confirmTransaction(props.item)">{{ $t('common.action.confirm') }}</button>
                  <button class="btn btn_Reject" @click="rejectTransaction(props.item)">{{ $t('common.action.reject') }}</button>
                </div>
                <div v-else>{{ props.item.status}}</div>
              </td>
            </tr>
          </template>
        </data-table>
      </div>
    </div>

    <div class="clearfix clearfix-40"></div>

    <modal name="confirmTransaction" width="460" title="">
      <div slot="body" class="body-popup">

        <ul class="list_modal_deposit">
          <li>
            <div class="row">
              <div class="col-xs-4">
                <div class="text-left">{{ $t('usd_transaction.user1') }}</div>
              </div>
              <div class="col-xs-8">
                <div class="text-right" :title="selectedTransaction.email">{{selectedTransaction.email}}</div>
              </div>
            </div>
          </li>
          <li>
            <div class="row">
              <div class="col-xs-5">
                <div class="text-left">{{ $t('usd_transaction.account_name') }}</div>
              </div>
              <div class="col-xs-7">
                <div class="text-right" :title="selectedTransaction.account_name">{{selectedTransaction.account_name}}</div>
              </div>
            </div>
          </li>
          <li>
            <div class="row">
              <div class="col-xs-4">
                <div class="text-left">{{ $t('usd_transaction.amount') }}</div>
              </div>
              <div class="col-xs-8">
                <div class="text-right">{{selectedTransaction.amount | abs | formatCurrencyAmount}} {{'usd' | currencyName}}</div>
              </div>
            </div>
          </li>
        </ul>
        <div>
          <hr/>
        </div>
        <div class="content_modal_deposit">{{ $t('transactions.deposit_usd.confirm') }}</div>
      </div>
    </modal>


    <modal name="waitingTransaction" width="460" title="">
      <div slot="body" class="body-popup">

        <ul class="list_modal_deposit">
          <li>
            <div class="row">
              <div class="col-xs-4">
                <div class="text-left">{{ $t('usd_transaction.user1') }}</div>
              </div>
              <div class="col-xs-8">
                <div class="text-right" :title="selectedTransaction.email">{{selectedTransaction.email}}</div>
              </div>
            </div>
          </li>
          <li>
            <div class="row">
              <div class="col-xs-5">
                <div class="text-left">{{ $t('usd_transaction.account_name') }}</div>
              </div>
              <div class="col-xs-7">
                <div class="text-right" :title="selectedTransaction.account_name">{{selectedTransaction.account_name}}</div>
              </div>
            </div>
          </li>
          <li>
            <div class="row">
              <div class="col-xs-4">
                <div class="text-left">{{ $t('usd_transaction.amount') }}</div>
              </div>
              <div class="col-xs-8">
                <div class="text-right">{{selectedTransaction.amount | abs | formatCurrencyAmount}} {{'usd' | currencyName}}</div>
              </div>
            </div>
          </li>
        </ul>
        <div>
          <hr/>
        </div>
        <div class="content_modal_deposit">{{ $t('transactions.deposit_usd.confirm') }}</div>
        
        <vue-loading
          type="bars"
          v-if="isLoading"
          color="#2DAC91"
          :size="{ width: '60px', height: '80px' }">
        </vue-loading>
      </div>
    </modal>

    <modal name="rejectTransaction" width="460" >
      <div slot="body" class="body-popup">

        <ul class="list_modal_deposit">
          <li>
            <div class="row">
              <div class="col-xs-4">
                <div class="text-left">{{ $t('usd_transaction.user1') }}</div>
              </div>
              <div class="col-xs-8">
                <div class="text-right" :title="selectedTransaction.email">{{selectedTransaction.email}}</div>
              </div>
            </div>
          </li>
          <li>
            <div class="row">
              <div class="col-xs-5">
                <div class="text-left">{{ $t('usd_transaction.account_name') }}</div>
              </div>
              <div class="col-xs-7">
                <div class="text-right" :title="selectedTransaction.account_name">{{selectedTransaction.account_name}}</div>
              </div>
            </div>
          </li>
          <li>
            <div class="row">
              <div class="col-xs-4">
                <div class="text-left">{{ $t('usd_transaction.amount') }}</div>
              </div>
              <div class="col-xs-8">
                <div class="text-right">{{selectedTransaction.amount | abs | formatCurrencyAmount}} {{'usd' | currencyName}}</div>
              </div>
            </div>
          </li>
        </ul>
        <div>
          <hr/>
        </div>
        <div class="content_modal_deposit">{{ $t('transactions.deposit_usd.reject') }}</div>
      </div>
    </modal>
  </div>
</template>

<script>
  import rf from '../../lib/RequestFactory';
  import Modal from '../../components/Modal';
  import { mapGetters, mapActions } from 'vuex';
  import BigNumber from 'bignumber.js';
  import DatePicker from "vuejs-datepicker";
  import moment from "moment";
  import COMMON_CONST from "../../common/Const";
  import SelectBox from "../../components/SelectBox";
  export default {
    components: {
      Modal,
      DatePicker,
      SelectBox
    },
    data() {
      return {
        titlePage: 'Deposit',
        searchKey: '',
        selectedTransaction: {},
        searchKey: '',
        transactionStatus: 'pending',
        modalName: 'DetailErrorModal',
        transactionErrorDetail: '',
        titlePage: window.i18n.t("menu.deposit_usd"),
        startDate: moment().subtract(6, 'day').toDate(),
        endDate: moment().toDate(),
        coin: "",
        filters: {
          searchKey: ""
        },
        coins: [],
        coinMasters: [],
        isShowCoinOption: false,
        trades: [],
        isLoading: false,
      }
    },
    watch: {
      transactionStatus() {
        this.$refs.datatable.refresh();
      }
    },
    computed: {
      isHistoryTransacstion() {
        return this.transactionStatus === 'history';
      }
    },
    methods: {
      selectTab(tab) {
        this.transactionStatus = tab;
        this.filters.searchKey = '';
        this.$refs.datatable.refresh();
        this.startDate = moment().subtract(6, 'day').toDate();
        this.endDate = moment().toDate();
      },
      customFormatter(date) {
        return moment(date).format('YYYY - MM - DD');
      },
      search() {
        let filterParams = {
          start_date: moment(this.startDate)
            .startOf("day")
            .format("x"),
          end_date: moment(this.endDate)
            .endOf("day")
            .format("x")
        };
        if (this.customFormatter(this.startDate) === this.customFormatter(this.endDate)) {
          filterParams = {
            start_date: moment(this.startDate)
              .startOf("day")
              .format("x"),
            end_date: moment(this.startDate)
              .endOf("day")
              .format("x")
          }
        }
        if (this.customFormatter(this.startDate) > this.customFormatter(this.endDate)) {
          this.$toastr("error", this.$t("common.date.warning"));
          return;
        }
        if (this.filters.searchKey) {
          filterParams.search_key = this.filters.searchKey;
        }
        this.$refs.datatable.filter(filterParams);
      },
      refresh() {
        this.requestCoinList();
        this.resetInput();
        this.$refs.datatable.refresh();
      },
      resetInput() {
        this.filters = Object.assign(this.filters, {
          searchKey: ""
        });
        this.startDate = moment().subtract(6, "day").toDate();
        this.endDate = moment().toDate();
        $(".datatable").find("th").attr("data-sort-order", "");
      },
      getMarkets() {
        return window._.map(COMMON_CONST.MARKETS, item => {
          return { id: item, name: item.toUpperCase() };
        });
      },
      requestCoinList() {
        rf.getRequest("CoinSettingRequest")
          .getCoinList()
          .then(res => {
            this.coinMasters = window._.map(res.data, "coin");
          });
      },
      ...mapActions([
        'getUsdTransactions'
      ]),
      listenForNotification() {
        window.Echo.channel('App.Models.Admin')
          .listen('AdminNotificationUpdated', () => {
            this.$refs.datatable.refresh();
          });
      },
      getPendingTransactions(params) {
        let meta = {
          type: 'deposit',
          status: this.transactionStatus,
          start_date: (moment(this.startDate)
            .startOf("day")
            .format("x")),
          end_date: (moment(this.endDate)
            .endOf("day")
            .format("x"))
        };
        params = Object.assign(params, meta);
        return rf.getRequest('AdminRequest').getUsdTransaction(params);
      },
      rejectTransaction(transaction) {
        this.selectedTransaction = transaction;
        CommonModal.show('rejectTransaction', {
          position: 'center',
          mask: true,
          buttons: [
            {
              label: window.i18n.t('transactions.deposit_usd.no'),
              style: {
                background: 'rgb(191, 191, 191);'
              },
              callback: () => {
                CommonModal.hide('rejectTransaction');
              }
            },
            {
              label: window.i18n.t('transactions.deposit_usd.yes'),
              focused: true,
              style: {
                background: 'rgb(0, 112, 192)'
              },
              callback: () => {
                let data = {
                  transaction_id: transaction.id
                };
                return rf.getRequest('AdminRequest').rejectUsdTransaction(data).then(res => {
                  this.$toastr('success', this.$t('sales_point.update_success_msg'));
                  this.refresh();
                  this.$broadcast('UsdTransactionUpdated');
                  CommonModal.hide('rejectTransaction');
                }).catch(e => {
                  this.$toastr('error', this.$t('sales_point.update_error_msg'));
                  this.refresh();
                  this.$broadcast('UsdTransactionUpdated');
                  CommonModal.hide('rejectTransaction');
                });
              }
            }
          ]
        });
      },
      confirmTransaction(transaction) {
        this.selectedTransaction = transaction;
        CommonModal.show('confirmTransaction', {
          position: 'center',
          mask: true,
          buttons: [
            {
              label: window.i18n.t('transactions.deposit_usd.no'),
              style: {
                background: 'rgb(191, 191, 191);'
              },
              callback: () => {
                CommonModal.hide('confirmTransaction');
              }
            },
            {
              label: window.i18n.t('transactions.deposit_usd.yes'),
              focused: true,
              style: {
                background: 'rgb(0, 112, 192)'
              },
              callback: () => {
                let data = {
                  transaction_id: transaction.id
                };
                this.isLoading = true;
                CommonModal.hide('confirmTransaction');
                CommonModal.show('waitingTransaction');
                return rf.getRequest('AdminRequest').confirmUsdTransaction(data).then(res => {
                  this.$toastr('success', this.$t('sales_point.update_success_msg'));
                  this.refresh();
                  this.$broadcast('UsdTransactionUpdated');
                  this.isLoading = false;
                  CommonModal.hide('waitingTransaction');
                }).catch(e => {
                  this.$toastr('error', this.$t('sales_point.update_error_msg'));
                  this.refresh();
                  this.$broadcast('UsdTransactionUpdated');
                  CommonModal.hide('waitingTransaction');
                  CommonModal.hide('confirmTransaction');
                });
              }
            }
          ]
        });
      },
    },
    mounted() {
      this.$emit('EVENT_PAGE_CHANGE', this);
      this.listenForNotification();
    }
  }
</script>

<style lang="scss">
@import "@/assets/sass/variables";
  #deposit_fiat_pages {
    @media only screen and (max-width: 1380px) {
      table {
        thead {
          th {
            padding: 7px 2px 4px 5px !important;
          }
        }
      }
    }
    @media only screen and (max-width: 1725px){
      .deposit_pages .btn_Reject{
          // margin-top: 5px;
          margin-left: 0 !important;
      }
    }
  }
    .date-picker-input {
    display: inline-block;
    float: left;
    input {
      padding: 0 5px 0px 23px;
      width: 110px ;
      border-radius: 3px;
      -webkit-box-shadow: none;
      box-shadow: none;
      height: 30px;
      line-height: 30px;
      border: 1px solid #d2d6de;
      background-image: url('@/assets/images/icon/date-time.svg') !important;
      background-color: $color-white-smoke !important;
      background-size: 12px;
      background-position: center left 5px;
      background-repeat: no-repeat !important;
      color: $color-grey-dusty;
      &:hover, &:active, &:focus {
        border-color: $color-jungle-green;
        z-index: 9;
      }
    }
  }
  .no-left-radius {
    input {
      border-radius: 0px 3px 3px 0px;
    }
  }
  .no-right-radius {
    input {
      border-radius: 3px 0px 0px 3px;
    }
  }
</style>

<style lang="scss" scoped>
@import "@/assets/sass/variables";
  td{
    word-break: break-all;
  }
  .datatable{
    font-family: $font-family-roboto !important;
  }

  .cl1 {
    min-width:107px;
  }
  .cl2,.cl4, .cl6 {
    min-width: 250px;
  }
  .cl3 {
    min-width: 130px;
  }
  .cl5{
    min-width: 310px;
  }
  .cl7{
    min-width: 205px;
  }
  @media only screen and (max-width: 1920px){
    table tbody td {
      /*padding: 5px !important;*/
    }
    .cl1 {
      min-width:135px !important;
    }
    .cl2,.cl4, .cl6, .cl5 {
      min-width: 100px !important;;
    }
    .deposit_pages .item_bank_user .txt_bank_user,.deposit_pages .item_email_user .txt_email_user{
      max-width:100px !important;
    }
  }

  ul {
    list-style-type: none;
    padding: 0px;
  }
  .clearfix-40 {
    display: block;
    clear: both;
    height: 40px;
  }
  .deposit_pages {
    font-family: $font_family_root;
    .filter_container {
      margin: 12px 0px;
      .title_item {
        color: $color_mine_shaft;
        font-size: $font_big_20;
        font-weight: 500;
        line-height: 28px;
        float: left;
      }
      .search_box {
        display: inline-block;
        float: right;
        width: 215px;
        max-width: 100%;
        .search_input {
          background-color: transparent;
          height: 28px;
          border: 1px solid $color_alto;
          padding: 4px 15px;
          line-height: 20px;
          width: 100%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          font-size: $font-small;
        }
      }
    }
    .item_email_user {
      display: inline-block;
      float: left;
      position: relative;
      .txt_email_user {
        display: block;
        max-width: 170px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .tooltip_email_user {
        position: absolute;
        top: 0px;
        left: 0px;
        line-height: 20px;
        padding: 5px 20px;
        left: 100%;
        background-color: $color_white;
        white-space: nowrap;
        width: auto;
        z-index: 10;
        font-size: $font_root;
        font-weight: 500;
        color: $color_mine_shaft;
        transition: 0.5s;
        display: none;
        box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.4);
        &:after {
          right: 100%;
          top: 50%;
          border: solid transparent;
          content: " ";
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
          border-color: rgba(136, 183, 213, 0);
          border-right-color: $color_white;
          border-width: 5px;
          margin-top: -5px;
        }
      }
      &:hover {
        .tooltip_email_user {
          display: block;
          transition: 0.5s;
        }
      }
    }
    .item_bank_user {
      display: inline-block;
      float: left;
      position: relative;
      .txt_bank_user {
        display: block;
        max-width: 250px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .tooltip_bank_user {
        position: absolute;
        top: 0px;
        left: 0px;
        line-height: 20px;
        padding: 5px 20px;
        left: 100%;
        background-color: $color_white;
        // white-space: nowrap;
        width: 250px;
        z-index: 10;
        font-size: $font_root;
        font-weight: 500;
        color: $color_mine_shaft;
        transition: 0.5s;
        display: none;
        box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.4);
        &:after {
          right: 100%;
          top: 12px;
          border: solid transparent;
          content: " ";
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
          border-color: rgba(136, 183, 213, 0);
          border-right-color: $color_white;
          border-width: 5px;
          margin-top: -5px;
        }
      }
      &:hover {
        .tooltip_bank_user {
          display: block;
          transition: 0.5s;
        }
      }
    }
    .btn_Confirm,
    .btn_Reject {
        background-color: $color-caribbean-green;
        color: $color-white;
        text-transform: uppercase;
        width: 82px;
        height: 25px;
        line-height: 20px;
        padding: 0px 9px;
        text-align: center;
        font-size: $font-smaller;
        font-family: $font-family-roboto-bold;
        border: 1px solid $color-caribbean-green;
        border-radius: 3px;
        transition: 0.5s;
        &:hover {
          background-color: $color-aquamarine;
          border-color: $color-aquamarine;
          transition: 0.5s;
        }
    }
    .btn_Reject {
      margin-left: 10px;
    }
    .list_modal_deposit {
      margin-bottom: 25px;
      padding: 0px;
      li {
        line-height: 20px;
        margin-bottom: 10px;
        color: $color_dove_gray;
        font-size: $font_root;
        font-weight: 500;  
        .text-right {
          font-weight: 600;
          color: $color_mine_shaft;
          white-space: nowrap; 
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
    .content_modal_deposit {
      color: $color_mine_shaft;
      font-size: $font_semi_big;
      font-family: $font-family-roboto-medium;
      font-weight: 500;
      text-align: center;
      line-height: 24px;
    }
  }
  .select_transaction_container {
    padding: 10px 0px 20px 0px;
    .input-radio {
      label {
        display: inline;
        font-weight: normal;
        text-align: center;
        margin-right: 25px;
        span {
          font-size: $font_big;
        }
        input[type="radio"] {
          display: inline-block;
          margin-top: 0px;    
          position: relative;
          top: -2px;
        }
      }
    } 
  }
  .filter-container {
    margin: 10px 0 30px 0px;
    height: 60px;
    .filter-item {
      .label-text {
        font-size: $font-small;
        color: $color-grey-dusty;
        padding-bottom: 4px;
      }
      float: left;
      margin: 0px;
      margin-right: 20px;
      input {
        background-color: $color-white-smoke;
        margin-top: 22px;
        display: inline-block;
        width: 145px;
      }
      select {
        width: 65px;
        display: inline-block;
      }
      button {
        margin: 0 5px;
        width: auto;
        margin-top: 22px;
        height: 30px;
        font-size: 13px;
      }
      .btn-search, .btn-reset {
        font-size: $font-smaller;
        font-family: $font-family-roboto-bold;
        border-radius: 3px;
        border: 1px solid $color-caribbean-green;
        width: 85px;
        color: $color_white;
        text-transform: uppercase;
        background-color: $color-caribbean-green;
        &:hover {
          background-color: $color-aquamarine;
          border-color: $color-aquamarine;
          transition: 0.5s;
        }
      }
    }
    .btn-action {
      margin-left: -10px;
    }
  }
  .form-control {
    background: $color_concrete;
    height: 30px;
    font-size: $font-small;
    padding-left: 11px;
    padding-right: 0;
    &:hover, &:focus {
      border-color: $color-jungle-green;
    }
  }
  .datatable {
    button.btnForm.bt-action {
      padding-left: 5px !important;
      padding-right: 5px !important;
      font-weight: 400 !important;
    }
  }
  .buy {
    color: $text-color-jade;
  }
  .sell {
    color: $text-color-red;
  }
  .toolbar-element2 {
    display: inline-block;
    float: left;
    width: 65px;
  }
  td {
    font-family: $font-family-roboto;
  }
  .tab-header {
    display: block;
    width: 100%;
    float: left;
    margin: 10px 0px 23px 0px;
    ul {
      list-style: none;
      padding-left: 0px;
      li {
        float: left;
        margin-right: 25px;
        font-size: $font_big;
        color: $color-grey-dark;
        display: block;
        >a {
          color: $color-grey-dark;
          font-family: $font-family-roboto-medium;
          font-weight: 500;
          font-size: $font_big;
          display: block;
          float: right;
          &::before {
            content: "";
            display: inline-block;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            border: solid 1px #979797;
          }
          span {
            padding-left: 5px;
          }
        }
        &.active {
          >a {
            &::before {
              border: solid 4px $color-jungle-green;
            }
              color: $color-jungle-green;
          }
        }
      }
    }
  }
</style>

<style lang="scss">
@import "@/assets/sass/variables";
  #deposit_fiat_pages {
    .sc_search_select {
      .group_search_select {
        .button_drop_search {
          width: 55px;
          height: 30px !important;
          padding: 5px 10px 5px 10px !important;
          border-radius: 3px;
          margin-right: 20px;
          font-size: $font-small;
          font-family: $font-family-roboto;
          i.icon-arrow1 {
            margin-left: 5px;
            color: $color_dove_gray;
          }
          &:hover {
            border-color: $color-jungle-green;
            i.icon-arrow1 {
              color: $color-jungle-green;
            }
          }
          
        }
        .box_list_search_select {
          .list_search_select {
            li {
              font-family: $font-family-roboto;
              font-size: $font-small;
              &:hover, &:active, &:focus {
                color: $color-jungle-green;
              }
            }
          }
        }
      }
    }
  }
</style>
