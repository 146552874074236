<template>
    <div class="wallet-component" id="wallet-component">
        <!-- Refresh Button -->
        <button class="btn btn-update" @click="reset()">{{
            $t('resource.wallets.btn_refresh') }}</button>

        <!-- Network Table -->
        <div class="network-table">
            <data-table-4 :getData="getData" ref="datatable" :column="4" class="scroll">
                <th class="text-left">{{ $t('resource.wallets.network') }}</th>
                <th class="text-left">{{ $t('resource.wallets.withdraw') }}</th>
                <th class="text-left">{{ $t('resource.wallets.move_fund') }}</th>
                <th class="text-left">{{ $t('resource.wallets.vault') }}</th>

                <template slot="body" slot-scope="props">
                    <tr>
                        <td>
                            <div class="text-top">{{ props.items.name }}</div>
                            <div class="text-bottom"><small>{{ props.items.type }}</small></div>
                        </td>
                        <td>
                            {{ shortenAddress(props.items.withdraw.address) }}
                            <copy-button :text-to-copy="`${props.items.withdraw.address}`" /><br>
                            <p>{{ $t('resource.wallets.balance') }}: <strong>{{ props.items.withdraw.balance }}</strong></p>
                        </td>
                        <td>
                            {{ shortenAddress(props.items.moveFund.address) }}
                            <copy-button :text-to-copy="`${props.items.moveFund.address}`" /><br>
                            <p>{{ $t('resource.wallets.balance') }}:<strong> {{ props.items.moveFund.balance }}</strong></p>
                        </td>
                        <td>
                            {{ shortenAddress(props.items.vault.address) }}
                            <copy-button :text-to-copy="`${props.items.vault.address}`" /><br>
                            <p>{{ $t('resource.wallets.balance') }}:<strong> {{ props.items.vault.balance }}</strong></p>
                        </td>
                    </tr>
                </template>
            </data-table-4>

        </div>

        <!-- Wallet Config -->
        <div class="wallet-config">
            <h4 class="wallet-label">{{ $t('resource.wallets.label_wallet_config') }}</h4>
            <pre>{{ JSON.stringify(walletConfig, null, 2) }}</pre>
        </div>
    </div>
</template>

<script>
import rf from "../../lib/RequestFactory";
import DataTable4 from '../../components/DataTable4.vue';
import CopyButton from '../../components/CopyButton.vue';

export default {
    components: {
        DataTable4,
        CopyButton
    },
    data() {
        return {
            titlePage: window.i18n.t('menu.wallets'),
            networks: [
                {
                    name: "ETH",
                    type: "Ethereum",
                    withdraw: {
                        address: "0x17......2a3c",
                        balance: "0.05652145",
                    },
                    moveFund: {
                        address: "0x17......2a3c",
                        balance: "0.05652145",
                    },
                    vault: {
                        address: "0x17......2a3c",
                        balance: "0.05652145",
                    },
                },
                {
                    name: "BTC",
                    type: "Bitcoin",
                    withdraw: {
                        address: "0x17......2a3c",
                        balance: "0.05652145",
                    },
                    moveFund: {
                        address: "0x17......2a3c",
                        balance: "0.05652145",
                    },
                    vault: {
                        address: "0x17......2a3c",
                        balance: "0.05652145",
                    },
                },
            ],
            walletConfig: {
                APP_NAME: "Token_Staging",
                MODE_ENV: "staging",
                IS_PRIVATE: false,
                NETWORKS: ["ethereum", "bitcoin", "bsc", "polygon", "tron"],
                WITHDRAWAL_TYPE: {
                    INITIATE: "initiate",
                    SUCCESS: "success",
                    HOLDING: "holding",
                    FAIL: "fail",
                    CANCEL: "cancel",
                },
                ACCOUNT_TYPE: {
                    USER: "user",
                    SYSTEM: "system",
                },
                "EXPLORER TX HASH MAP": {},
            },
        };
    },
    methods: {
        getData() {
            const response = rf
                .getRequest("OrderRequest")
                .getOrdersPending();
            return response.then((res) => {
                res.data.data = this.networks;
                res.data.current_page = 1;
                res.data.total = 2;
                res.data.last_page = 1;
                res.data.per_page = 10;
                return res
            });
        },
        shortenAddress(address) {
            return `${address.slice(0, 5)}...${address.slice(-4)}`;
        },
        reset() {
            // Mock refreshing logic
            console.log("Refreshing data...");
        },
    },
    created() {

    },
    mounted() {
        this.$emit('EVENT_PAGE_CHANGE', this);
    },
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/variables";

.wallet-component {
    .btn.btn-update {
        margin: 0 5px;
        width: auto;
        margin-top: 18px;
        height: 32px;
        font-family: $mulish-bold;
        border-radius: 10px;
        text-transform: uppercase;
        border: 1px solid $color-caribbean-green;
        color: $color_white;
        background-color: $color-caribbean-green;
    }

    .refresh-button:hover {
        background-color: #218838;
    }

    .network-table {
        width: 100%;
        border-collapse: collapse;
        margin: 30px 0;

        table {
            tbody {
                td {
                    font-family: $mulish-medium;
                }
            }
        }
    }

    .network-table button {
        background: none;
        border: none;
        cursor: pointer;
    }

    .wallet-config {
        margin-top: 20px;
        background-color: $color-white;
        padding: 15px 30px;
        border-radius: 10px;
        font-family: $mulish-bold;

        .wallet-label {
            font-family: $mulish-bold;
        }
    }

    .wallet-config pre {
        background-color: #fff;
        padding: 10px;
        border-radius: 10px;
        font-size: 14px;
        white-space: pre-wrap;
        word-wrap: break-word;
        border-color: $color-bright-gray;
    }
}
</style>