<template>
  <div class="login_page">
    <div class="login_page_wrapper">
      <form>
        <div class="header">
          <img src="@/assets/images/icon/logo-light.svg" alt="logo_monas">
          <h3>Login Admin</h3>
        </div>
        <div class="form-group">
          <input type="text" name="email" v-model="email" :placeholder="$t('login.email2')" />
        </div>
        <div class="form-group">
          <input
            type="password"
            name="password"
            autocomplete="off"
            v-model="password"
            :placeholder="$t('common.placeholders.password')"
          />
        </div>
        <button @click="login" type="button">{{ $t('login.title') }}</button>
      </form>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'Login',
  data() {
    return {
      email: '',
      password: '',
    }
  },

  methods: {
    login() {
      const domain = process.env.VUE_APP_API_URL
      axios
        .post(domain + '/admin/login', {
          email: this.email,
          password: this.password,
        },
        )
        .then((response) => {
          localStorage.setItem('token', response.data.access_token)
          window.axios.defaults.headers.common['Authorization'] = response.data.token_type + ' ' + response.data.access_token;
          location.reload()
        })
        .catch((error) => {
          console.log(error)
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.login_page {
  background: #0e454c;
  .login_page_wrapper {
    width: 100%;
    height: 100vh;
    max-width: 300px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    form {
      width: 100%;
      background: #fff;
      padding: 20px;
      border-radius: 10px;
      .header {
        text-align: center;
        img {
          height: 50px;
          width: auto;
          margin-top: 15px;
        }
        h3 {
          color: #00DDB3;
          font-size: 20px;
          line-height: 30px;
          margin: 15px 0;
        }
      }
      .form-groupl {
        margin-bottom: 15px;
      }
      input {
        width: 100%;
        height: auto;
        font-size: 16px;
        border: 1px solid #c7cbd3;
        border-radius: 5px;
        padding: 10px;
        &:focus {
          outline: none;
        }
      }
      button {
        width: 100%;
        height: auto;
        font-size: 16px;
        background: #00DDB3 !important;
        color: #fff !important;
        border-radius: 5px;
        border: none;
        padding: 10px;
        &:hover {
          background: #57FBDC!important;
        }
      }
    }
  }
}
</style>
