import BaseRequest from '../lib/BaseRequest'

export default class FeeRequest extends BaseRequest {

  getTransactionFee(params) {
    let url = '/admin/api/transaction/fee';
    return this.get(url, params);
  }

  getOrderFee(params) {
    let url = '/admin/api/order/fee';
    return this.get(url, params);
  }

  getTotalTransactionFee(params) {
    let url = '/admin/api/transaction/fee-total';
    return this.get(url, params);
  }

  getTotalOrderFee(params) {
    let url = '/admin/api/order/fee-total';
    return this.get(url, params);
  }

  getReferrerFee(params) {
    let url = '/admin/api/user/referrer-fee';
    return this.get(url, params);
  }
}
