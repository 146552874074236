<template>
    <div id="manual_entry" class="boxCore manual_entry ">
        <div class="datatable">
            <div class="filter-container justify-space">
                    <div class="filter-item date">
                        <div class="text-pair">{{ $t('entry_leaderboard.contest') }}:</div>
                        <select-box v-model="filters.contest"
                            @input="onChangeFilterContest"
                            class="select-market" :options="contests"
                        />
                    </div>
                <div class="filter-item send-bonus">
                    <button @click="showBonusModal()" class="btn btn-add btn-primary"
                        :disabled="lstChecked.length == 0"
                    >
                        <i class="icon-plus"></i>&nbsp;&nbsp;
                        {{ $t('airdrop_setting.send_bonus') }}
                    </button>

                </div>
            </div>
            <data-table-5 :getData="getData"
                            :limit="10"
                            :column='6'
                            :onPageLoad="false"
                            @DataTable:finish="onDatatableFinish"
                            ref="datatable" class="scroll">
                    <th class="text-center non-sort" width="5%">
                        <input type="checkbox" v-model="isChecked"   @click="chkAllClick($event.target.checked)" />
                    </th>
                    <th class="text-left " >{{ $t('entry_manual_payment.team_name') }}</th>
                    <th class="text-left " >{{ $t('entry_manual_payment.team_id') }}</th>
                    <th class="text-left " >{{ $t('entry_manual_payment.leader') }}</th>
                    <th class="text-left ">{{ $t('entry_manual_payment.number_of_member') }}</th>
                    <th class="text-left ">{{ $t('entry_manual_payment.total_volume') }}</th>
                    <th class="text-left ">{{ $t('entry_manual_payment.total_roe') }}</th>
                    <template slot="body" slot-scope="props">
                        <tr>
                            <td class="text-center pl_18">
                                <input type="checkbox" :checked="lstChecked.indexOf('-' + props.item.id + '-') >= 0" @click="checkItemClick(props.item, $event.target.checked)" />
                            </td>
                            <!-- <td class="col-team"> {{props.item.name}}</td> -->
                             <td>
                            <div class="item_name_setting">
                                <span class="txt_email_setting">{{ props.item.name }}</span>
                                <span class="tooltip_name_setting">{{ props.item.name }}</span>
                            </div>
                            </td>
                            <td class="col-email"> {{props.item.id}}</td>
                            <td>
                            <div class="item_email_setting">
                                <span class="txt_email_setting">{{ props.item.leader.email }}</span>
                                <span class="tooltip_email_setting">{{ props.item.leader.email }}</span>
                            </div>
                            </td>
                            <td> {{props.item.number_entry_count}}</td>
                            <td>{{props.item.total_volume | formatCurrencyAmount(null, '0')}} BTC</td>
                            <td>{{props.item.total_roe | toPercentage}}</td>
                            <!-- <td>{{props.item.contest_id}}</td> -->
                            <!-- <td class="text-right">{{props.item.symbol}}</td> -->

                        </tr>
                    </template>
            </data-table-5>
        </div>
        <modal name="sendBonus" width="590" id="modal_send_bonus" :title="modalTitle">
            <div slot="body" class="body-popup">
                <div class="form-group-detail">
                    <div class="group-detail-form-group pay-amount">
                        <label class="group-detail-form-label">{{ $t('airdrop_setting.dividend_manual.pay_amount') }}</label>
                        <div class="clearifx"></div>
                        <div class="bonus-detail">
                            <div class="container-input">
                                <currency-input @focus="listError.bonusAmount = false"  :precision="precision" @keyup.enter="applyBonus" v-bind:class="{ 'form-control': true }" v-model="pay.amount" />
                                <span v-show="errors.has('bonusAmount')" class="f-left is-error">{{ errors.first('bonusAmount') }}</span>
                            </div>
                            <div class="toolbar-element2">
                                <select-box v-model="pay.currency"
                                            :placeholder="pay.currency.name"
                                            class="select-currency" :options="listCoin"
                                />
                            </div>
                        </div>
                        <div class="clearifx"></div>
                    </div>
                    <div class="clearifx"></div>
                    <div class="group-detail-form-group">
                        <label class="group-detail-form-label">{{ $t('airdrop_setting.dividend_manual.pay_for') }}</label>
                            <select-box v-model="pay.wallet"
                                        :placeholder="wallets[0].name"
                                        class="select-wallet" :options="wallets"
                            />
                        <div class="clearifx"></div>
                        <div class="error-box">
                            <span v-show="errors.has('memo')" class="f-left is-error">{{ errors.first('memo') }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
    import rf from '../../lib/RequestFactory';
    import BigNumber from 'bignumber.js';
    import DatePicker from "vuejs-datepicker";
    import moment from "moment";
    import COMMON_CONST from "../../common/Const";
    import SelectBox from "../../components/SelectBox";
    import CoinInput from '../../common/CoinInput.vue'
    import CurrencyInput from "../../components/CurrencyInput";
    import Modal from "../../components/Modal";
    import DataTable5 from "../../components/DataTable5";
    export default {
        components: {
            DatePicker,
            SelectBox,
            CoinInput,
            Modal,
            CurrencyInput,
            DataTable5
        },
        props: {
            disabled: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                product_base: COMMON_CONST.PRODUCT_BASE,
                titlePage: window.i18n.t("entry_leaderboard.manual1"),
                nullSearchKey: true,
                filters: {
                    contest: {id:'', name: ''},
                    team: {id:'', name: ''},
                    email: '',
                },
                listError: {
                  filterAmount: false,
                  bonusAmount: false,
                  referrer: false,
                  deposit: false,
                  number_amal_holding: false,
                  referrer_than_0: false,
                  deposit_than_0: false,
                  number_amal_holding_than_0: false,

                },
                pay: {
                    amount: "",
                    currency: "",
                    wallet: "",
                },
                currencies:  [
                    {id: "btc" , name: "BTC"},
                ],
                wallets: [
                    {id:COMMON_CONST.TYPE_MAIN_BALANCE.toLowerCase(), name: COMMON_CONST.MAIN_BALANCE},
                ],
                pairs: [],
                allPairs: [],
                contests: [],
                teams: [],
                lstChecked: '',
                isChecked : false,
                isOnline: true,
                datatableRows: [],
                modalTitle: window.i18n.t("airdrop_setting.bonus.bonus_setting"),
                refundModalTitle: window.i18n.t("entry_leaderboard.manual"),
                precision: 8,
                lstUsers: [],
                allTeamHasChecked: [],
                rootSymbols: [],
                childSymbols: [],
                listCoin: [{id: "btc" , name: "BTC"}],
                listDepositCoin: [],
            }
        },
        computed: {

        },

        methods: {
            onChangeFilterContest(value){
                this.allTeamHasChecked = [];
                this.isChecked= false;
                this.lstChecked = '';
                this.$refs.datatable.refresh();
            },
            getAllContests() {
                rf.getRequest('MarginOrderRequest').getAllContestsHavingTradingVolume().then((res)=> {
                    this.contests =  res.data;
                    this.filters.contest = res.data[0] ? res.data[0] :[];
                    this.$refs.datatable.refresh();
                    // this.contests.unshift({id: "", name: "All"});
                })
            },
            getAllTeams() {
                let params = {
                    "contest_id" : this.filters.contest.id || null,
                }
                rf.getRequest('MarginOrderRequest').getAllTeamByContest(params).then((res)=> {
                   this.teams = res.data;
                })
            },
            validateFilter() {
                let check = true;
                console.log(check);
                return check;
            },
            findObjectFromChosens(value) {
                return this.chosens.find(el => {
                return el.id == value;
                })
            },
            searchParams() {
                let params = {
                    email : this.filters.email,
                    contest: this.filters.contest.id || ''
                };
                return params;
            },
            search() {
                this.$refs.datatable.filter(this.searchParams());
            },
            getData(params) {
                let defaultParams = {
                    "contest_id": this.filters.contest.id || "",
                };

                return rf.getRequest('MarginOrderRequest').getAllEntriesByContest(Object.assign(params, defaultParams )).then(res => {
                    // if(res.data != undefined) {
                    //     this.lstUsers = res.data.data;
                    //     return res.data;
                    // }
                    return res;
                }).catch(error => {
                    console.log(error);
                });
            },
            refresh() {
                this.resetInput();
                this.isChecked = false;
            },
            resetInput() {
                this.$refs.datatable.clear();
            },
            onDatatableFinish() {
                const datatable = this.$refs.datatable;
            },
            // Get amount which hasn't calculate fee yet.
            getFullAmount(reductionAmount, fee) {
                return new BigNumber(`${Math.abs(reductionAmount)}`).add(`${fee}`).toString();
            },
            showBonusModal() {
              this.errors.clear();
                this.pay.currency = {id: 'btc', name: 'BTC'};
                CommonModal.show('sendBonus', {
                    position: "center",
                    mask: true,
                    buttons: [
                        {
                            label: this.$t('common.action.cancel'),
                            focused: true,
                            style: {
                                width: "140px",
                                background: "rgb(0, 112, 192)"
                            },
                            callback: () => {
                                CommonModal.hide("sendBonus");
                            }
                        },
                        {
                            label: this.$t('common.action.send'),
                            focused: true,
                            style: {
                                width: "140px",
                                background: "rgb(0, 112, 192)"
                            },
                            callback: () => {
                                this.applyBonus();
                            }
                        }
                    ],
                });
            },
            applyBonus() {
                this.errors.clear();
                let lstCheckedUsers = [];
                if(!this.isOnline){
                    this.$toastr('error', this.$t('common.Internet.connection.fail'));
                    return false;
                }
                if (!this.pay.amount) {
                    this.errors.add({field: 'bonusAmount', msg: this.$t('airdrop_setting.payout_amount_missing')});
                    return false;
                } else {
                    this.listError.filterAmount = false;
                }
                if (this.pay.amount == 0) {
                    this.errors.add({field: 'bonusAmount', msg: this.$t('entry.error.payout_amount_0')});
                    return false;
                } else {
                    this.listError.filterAmount = false;
                }
                window._.filter(this.allTeamHasChecked, (item) => {
                    window._.each(item.number_entry, (entry) =>{
                        lstCheckedUsers.push({
                            'user_id': entry.user_id,
                            'team_id': entry.team_id,
                            'contest_id': entry.contest_id,
                            'total_volume': item.total_volume,
                            'amount': this.pay.amount,
                            'wallet': 'main',
                            'email': entry.email,
                            // 'coin': window._.get(item, "coin", "usd"),
                            'coin': 'btc',
                            'market': window._.get(item, "symbol", "usdt"),
                            'filter_from': '0',
                            'filter_to': '0',
                            'type': "margin",
                            // 'bonus_currency': this.pay.currency.id,
                            'bonus_currency': 'btc',
                        });
                    })
                });
                rf.getRequest('AdminRequest').applyBonusBalance(lstCheckedUsers)
                    .then(data => {
                        if (data.success) {
                            this.$toastr('success', this.$t('airdrop_setting.send_bonus_success'));
                            CommonModal.hide('refundBonus');
                            // this.isChecked = false;
                            // this.chkAllClick(this.isChecked);
                            this.pay.amount = '';
                        } else {
                            CommonModal.hide('sendBonus');
                            this.$toastr('error', this.$t('airdrop_setting.send_bonus_error'))
                        }
                    }).catch(error => {
                    })
            },
            chkAllClick(isChecked) {
                this.datatableRows = this.$refs.datatable.rows;
                window._.forEach(this.datatableRows, item => {
                    this.checkItemClick(item, isChecked);
                });
            },
            checkItemClick(item, isChecked) {
                this.datatableRows = this.$refs.datatable.rows;
                const isUserExists = this.allTeamHasChecked.find(el=>el.id == item.id) !== undefined
                if(!isUserExists){
                    this.allTeamHasChecked.push(item)
                }
                if (isChecked && this.lstChecked.indexOf('-' + item.id + '-') < 0) {
                    this.lstChecked += '-' + item.id + '-';
                }
                if (!isChecked && this.lstChecked.indexOf('-' + item.id + '-') >= 0) {
                    this.lstChecked = this.lstChecked.replace('-' + item.id + '-', '');
                    this.allTeamHasChecked.splice(window._.findIndex(this.allTeamHasChecked,el=>el.id === item.id),1);
                }
                console.log(this.allTeamHasChecked);
                if (this.allTeamHasChecked.length == this.$refs.datatable.rows.length) {
                    this.isChecked = true;
                } else {
                    this.isChecked = false;
                }
            },
        },
        mounted() {
            this.$emit('EVENT_PAGE_CHANGE', this);
            this.getAllContests();
            window.addEventListener('online',(event) =>{
                this.isOnline = true;
            });
            window.addEventListener('offline',(event) =>{
                this.$toastr('error', this.$t('common.Internet.connection.fail'));
                this.isOnline = false;
            });

        }
        // ALl on load binding
    }
</script>

<style lang="scss" scoped>
@import "@/assets/sass/variables";
    .m-flex {
        display: flex;
    }
    input[type='number'] {
        -moz-appearance:textfield;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }
    .item_name_setting {
        display: inline-block;
        float: left;
        position: relative;
        .txt_email_setting {
            display: block;
            max-width: 100px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
        &:hover {
            .tooltip_name_setting {
                transition: 0.5s;
                display: inline-block;
            }
        }
        .tooltip_name_setting{
            position: absolute;
            top: 0px;
            line-height: 20px;
            padding: 5px 20px;
            left: 100%;
            background-color: $color_white;
            width: 300px;
            z-index: 10;
            font-size: $font_root;
            font-weight: 500;
            color: $color_mine_shaft;
            transition: 0.5s;
            display: none;
            box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.4);
            &:after {
                right: 100%;
                top: 50%;
                border: solid transparent;
                content: " ";
                height: 0;
                width: 0;
                position: absolute;
                pointer-events: none;
                border-color: rgba(136, 183, 213, 0);
                border-right-color: $color_white;
                border-width: 5px;
                margin-top: -5px;
            }
        }
    }
    .flex-justify {
        display: flex;

    }
    .col-team {
        max-width: 30%;
    }
    .col-email {
        max-width: 15%;
    }
    .empty-data {
        text-align: center;
        padding-top: 15px;
        background-color: $color_white;
    }

    .toolbar-element2 {
        display: inline-block;
        float: left;
    }
    .checkbox {
        margin-top: 0px;
        display: inline-block;
        font-size: $font_root;
        margin-bottom: 0px;
        font-weight: normal;
        height: 30px;
        line-height: 30px;
        input[type="checkbox"] {
            display: none;
        }
        span {
            position: relative;
            content: "";
            display: inline-block;
            border: 1px solid $color_alto;
            width: 16px;
            height: 16px;
            top: 3px;
            left: 8px;
            border-radius: 2px;
            margin-right: 8px;
            position: relative;
            background-color: $color_white;
        }
        input:checked {
            & + span{
                &:after{
                    opacity: 0.9;
                    content: '';
                    position: absolute;
                    width: 14px;
                    height: 7px;
                    background: transparent;
                    top: 2px;
                    left: 0px;
                    border: 3px solid $color_blue_logo;
                    border-top: none;
                    border-right: none;
                    transform: rotate(-45deg);
                    -webkit-transform: rotate(-45deg);
                    -moz-transform: rotate(-45deg);
                    -o-transform: rotate(-45deg);
                    -ms-transform: rotate(-45deg);
                }
            }
        }
    }
    .manual_entry {
        .filter-container {
            &.justify-space {
                justify-content: space-between;
            }

            margin: 10px 0px 30px 0px;
            height: 50px;
            .filter-item {
                float: left;
                margin: 0 5px;
                &.date {
                    width: 500px;
                    min-height: 30px;
                }
                &.date2 {
                    min-width: 120px;
                }
                &.send-bonus {
                    float: right;
                    button {
                        width: 160px;
                    }
                }
                &.search {
                    margin-left: 10px;
                }
                input {
                    display: inline-block;
                    width: 160px;
                    font-family: $font-family-roboto;
                    &:hover, &:active, &:focus {
                        border-color: $color-jungle-green;
                    }
                }
                select {
                    width: 65px;
                    display: inline-block;
                    font-family: $font-family-roboto;
                    font-size: $font_root;
                    &:hover, &:active, &:focus {
                        border-color: $color-jungle-green;
                    }
                }
                button {
                    width: auto;
                    margin: 23px 2px 0px 2px;
                    height: 30px;
                    font-size: $font-smaller;
                    font-family: $font-family-roboto;
                }
                .btn-reset {
                    background-color: $color-caribbean-green;
                    border: 1px solid $color-caribbean-green;
                    width: 80px;
                    text-transform: uppercase;
                    color: $color-white;
                    font-weight: 600;
                    &:hover {
                        border-color: $color-aquamarine;
                        background-color: $color-aquamarine;
                    }
                }
                .btn-primary {
                    padding: 0;
                    min-width: 80px;
                    &:hover {
                        border-color: $color-aquamarine;
                        background-color: $color-aquamarine;
                    }
                }
            }
            .text-date {
                color: $color-grey-dusty;
                margin-bottom: 5px;
                font-size: 13px;
                font-family: Roboto-Regular;
            }
            .text-pair {
                font-family: Roboto-Regular;
                color: $color-grey-dusty;
                margin-bottom: 5px;
                font-size: 13px;
                &.ml-14{
                    margin-left: 14px;
                }
            }

            .minimum-amount {
                // padding-left: 13px !important;

            }
            .side {
                margin: 0 10px;
            }
        }
        .form-control {
            background: $color-white-smoke;
            height: 30px;
            font-size: $font_root;
            padding-left: 11px;
            padding-right: 0;
        }
        .datatable{
            button.btnForm.bt-action{
                padding-left: 5px !important;
                padding-right: 5px !important;
                font-weight: 400 !important;
            }
            table {
                thead {
                    th{
                        padding: 8px 10px 4px 14px !important;
                       &:first-child:after{
                           width: 6px;
                       }
                    }
                }
                tbody {
                    .pl_18 {
                        padding-left: 18px;
                    }
                    td {
                        .item_email_setting {
                        display: inline-block;
                        float: left;
                        position: relative;
                        .txt_email_setting {
                            display: block;
                            max-width: 150px;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                        }
                        .tooltip_email_setting {
                            position: absolute;
                            top: 0px;
                            left: 0px;
                            line-height: 20px;
                            padding: 5px 20px;
                            left: 100%;
                            background-color: $color_white;
                            white-space: nowrap;
                            width: auto;
                            z-index: 10;
                            font-size: $font_root;
                            font-weight: 500;
                            color: $color_mine_shaft;
                            transition: 0.5s;
                            display: none;
                            box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.4);
                            &:after {
                            right: 100%;
                            top: 50%;
                            border: solid transparent;
                            content: " ";
                            height: 0;
                            width: 0;
                            position: absolute;
                            pointer-events: none;
                            border-color: rgba(136, 183, 213, 0);
                            border-right-color: $color_white;
                            border-width: 5px;
                            margin-top: -5px;
                            }
                        }
                        &:hover {
                            .tooltip_email_setting {
                            display: block;
                            transition: 0.5s;
                            }
                        }
                        }
                    }
                }
            }
        }
        .buy {
          color: $text-color-jade;
        }
        .sell {
            color: $text-color-red;
        }
        .btn-detail {
            background-color: $color-caribbean-green;
            padding: 2px 10px;
            font-size: $font-smaller;
            color: $color-white;
            border:1px solid $color-caribbean-green;
            border-radius: 3px;
            text-transform: uppercase;
            font-family: $font-family-roboto;
            &:hover{
                background-color: $color-aquamarine;
                border-color: $color-aquamarine;
            };
        }
    }
    .form-group-detail {
        .group-detail-form-group {
            .button_drop_search {
                height: 30px !important;
            }
            .group-detail-form-label{
                width: 100%;
                color: $color-grey-dusty;
                float: left;
                font-weight: normal;
                font-family: $font-family-roboto;
                margin-bottom: 0px;
                margin-top: 10px;
            }
            .group-detail-form-value {
                width: 100%;
                padding: 8px 16px;
                border: 1px solid #dee3eb;
                height: 35px;
                line-height: 35px;
                border-radius: 3px;
                font-family: $font-family-roboto;
                // font-weight: 600;
                &:focus {
                    border-color: $color-jungle-green;
                }
            }
            .textarea_input {
                height: 120px;
            }
        }
        .error-box {
            display: block;
            float: left;
            overflow: hidden;
            width: 100%;
            span {
                font-family: $font-family-roboto-medium !important;
            }
        }
        .is-error {
            color: #ff5252 !important;
            max-width: 180px;
            font-family: "Roboto-Regular", sans-serif;
        }
        .input-error {
            border: 1px solid #f74940 !important;
        }
    }
</style>
<style lang="scss">
@import "@/assets/sass/variables";
    .bonus-detail {
        width: 100%;
        display: inline-flex;
        .container-input {
            width: 100%;
            flex-flow: column;
            display: flex;
        }
    }
    #modal_send_bonus {
        .list_button_modal {
            margin-top: 120px !important;
        }
        input {
            height: 35px !important;
        }
        .sc_search_select {
            .group_search_select {
                .button_drop_search {
                    height: 35px !important;
                    padding-top: 8px;
                }
            }
        }
        .modal {
            .modal-dialog {
                .modal-content {
                    .modal-title {
                        text-transform: none;
                    }
                }
            }
        }
    }
    #disable-div {
        .button_drop_search {
            height: 30px;
            min-width: 65px !important;
            padding: 5px 0px 6px 6px !important;
            border-radius: 3px;
            font-size: $font-small;
            font-family: $font-family-roboto;
            float: left;
            color: #666666;
            font-size: 12px;
            line-height: 18px;
            border: 1px solid #CFCFCF;
            border-radius: 3px;
            i.icon-arrow1 {
                margin-left: 5px;
                color: #333333;;
                padding: 4px 2px;
                font-size: 11px;
                float: right;

            }
            &:hover{
                    cursor: not-allowed;
            }
        }
        .button_drop_search_1 {
            height: 30px;
            min-width: 170px !important;
            padding: 5px 0px 6px 6px !important;
            border-radius: 3px;
            font-size: $font-small;
            font-family: $font-family-roboto;
            float: left;
            color: #666666;
            font-size: 12px;
            line-height: 18px;
            border: 1px solid #CFCFCF;
            border-radius: 3px;
            i.icon-arrow1 {
                margin-left: 5px;
                color: #333333;;
                padding: 4px 2px;
                font-size: 11px;
                float: right;
            }
            &:hover{
                    cursor: not-allowed;
            }
        }

    }
    #manual_entry {
        .tab-header {
            display: block;
            width: 100%;
            float: left;
            margin: 10px 0px 23px 0px;
            ul {
                list-style: none;
                padding-left: 0px;
                li {
                    float: left;
                    margin-right: 25px;
                    font-size: $font_big;
                    color: $color-grey-dark;
                    display: block;
                    >a {
                        color: $color-grey-dark;
                        font-family: $font-family-roboto-medium;
                        font-weight: 500;
                        font-size: $font_big;
                        display: block;
                        float: right;
                        &::before {
                            content: "";
                            display: inline-block;
                            width: 12px;
                            height: 12px;
                            border-radius: 50%;
                            border: solid 1px #979797;
                        }
                        span {
                            padding-left: 5px;
                        }
                    }
                    &.active {
                        >a {
                            &::before {
                                border: solid 4px $color-jungle-green;
                            }
                            color: $color-jungle-green;
                        }
                    }
                }
            }
        }
        .filter-item.date {
          .sc_search_select {
            .group_search_select {
              .button_drop_search {
              >span{
                max-width: 275px;
                text-overflow: ellipsis;
                line-height: 23px;
                display: block;
                overflow: hidden;
                white-space: nowrap;
              }
                min-height: 30px;
                width: 300px;
                border-radius: 3px;
                font-size: $font-small;
                font-family: $font-family-roboto;
                i.icon-arrow1 {
                  color: $color_dove_gray;
                }
                &:hover, &:active, &:focus {
                  border-color: $color-jungle-green;
                  i.icon-arrow1 {
                      color: $color-jungle-green;
                  }
                }
              }
              .box_list_search_select {
                max-height: 270px;
                overflow: overlay;
                width: 300px;
                box-shadow: 0px 3px 12px rgba(49, 49, 49, 0.51);
                &::-webkit-scrollbar{
                    width: 5px;
                }
                &::-webkit-scrollbar-thumb{
                    background: #ddd;
                }
                &::-webkit-scrollbar-track{
                    background: #f1f1f1;
                }
                .list_search_select {
                  li {
                    word-break: break-all;
                    font-family: $font-family-roboto;
                    font-size: $font-small;
                    &:hover, &:active, &:focus {
                        color: $color-jungle-green;
                    }
                  }
                }
              }
            }
          }
        }
        .slb-fl-30{
            .button_drop_search {
                height: 30px;
                padding: 5px 5px;
            }
            .group_search_select{
                min-width: 65px;
                .box_list_search_select.active{
                    min-width: 77px;
                    li {
                        &:hover {
                            color:#2DAC91;
                        }
                    }
                }
            }
        }
        .slb-fl-30-1{
            display: flex;
            .button_drop_search {
                height: 30px;
                padding: 5px 5px;
            }
            .group_search_select{
                min-width: 170px;
                .box_list_search_select.active{
                    min-width: 200px;
                    li {
                        &:hover {
                            color:#2DAC91;
                        }
                    }
                }
            }
        }
        .error-msg{
            color: $color-red-main;
        }
        .pay-amount{
            position: relative;
        }
        .filter-item.amount{
            position: relative;
            margin-left: 20px;
            .error-msg{
                position: absolute;
                // margin-left: 13px;
            }
        }
        .filter-container.tool-button{
            margin:  15px 0px 0px 0px!important;
            .filter-item{
                button{
                    min-width: 200px;
                    margin: 0px;
                }
            }
        }
        .unit {
            top:50%;
            transform: translateY(-50%);
            right: 0;
            position: absolute;
            // width: 200px;
            text-align: center;
        }
        .borderrrr {
            border: solid 1px #299b82;
        }
        .error {
            border: solid 1px $color-red-main;
            color: $color-red-main
        }
        .Rectangle-6-Copy-9 {
            position: relative;
            overflow: hidden;
            display: block;
            padding-bottom: 1px;
            text-align: left;
            border-radius: 3px;
        }
        #modal_send_bonus {
            .modal-dialog {
                min-height: 330px;
            }
            .modal-content {
                height: auto;
                .modal-body {
                    padding: 0px 70px 15px 70px;
                }
                .modal-footer {
                    padding-bottom: 40px;
                }
            }
            .toolbar-element2
            {
                .select-currency{
                    width: 100%;
                    display: inline-block;
                }
                width: 53%;
                float:right;
              .box_list_search_select {
                width: 200px !important;
              }
            }
            .select-wallet {
                width: 100%;
                float: left;
                margin-bottom: 10px;
                .group_search_select {
                    .button_drop_search {
                        height: 35px !important;
                        padding-top: 8px;
                    }
                }
            }
            input {
                width: 70%;
                display: inline-block;
                background-color: #ffffff;
                height: 28px;
            }
            .modal-title {
                text-align: center;
                margin-top: 40px;
                font-family: $font-family-roboto-medium;
            }
        }
    }
</style>