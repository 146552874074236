<template>
  <div id="base_deposit_withdraw" class="boxCore airdrop_history">
    <div class="transaction">
        <div v-if="productBase !== 'spot'" class="select_transaction_container">
            <div class="tab-header">
                <ul>
                <li :class="{'active': historyType === 'spot'}">
                    <a
                    href="javascript:void(0)"
                    @click="selectTab('spot')"
                    ><span>{{ $t('leaderboard.spotExchange') }}</span></a>
                </li>
                <li :class="{'active': historyType === 'margin'}">
                    <a
                    href="javascript:void(0)"
                    @click="selectTab('margin')"
                    ><span>{{ $t('leaderboard.marginExchange') }}</span></a>
                </li>
                </ul>
            </div>
        </div>
      <div class="filter-container">
        <div class="filter-item date">
          <div class='label-text'>{{ $t('orders.open_order.date') }}:</div>
          <date-picker v-model="startDate" class="date-picker-input no-right-radius" :format="customFormatter"></date-picker>
          <date-picker v-model="endDate" class="date-picker-input no-left-radius" :format="customFormatter"></date-picker>
        </div>
        <div class="filter-item pair">
          <div class="label-text ml-15" >{{ $t('user_wallet.currency') }}:</div>
          <div class="btn-select-coin" >
            <select-box
              v-model="filters.currency"
              :placeholder="filters.currency"
              :options="historyType === 'spot' ? coinMasters : coinMastersMargin"
            />
          </div>
        </div>
        <div class="filter-item input_search">
          <input
            title
            type="text"
            :placeholder="$t('orders.open_order.search_by_email')"
            @keyup.enter="search"
            class="form-control input-search"
            name="searchKey"
            v-model="filters.searchKey"
          >
        </div>
        <div class="filter-item btn-action">
          <button class="btn btn-search" @click="search()">{{ $t('orders.open_order.search') }}</button>
          <button class="btn btn-reset" @click="refresh()">{{ $t('orders.open_order.reset') }}</button>
        </div>
        <div class="filter-item btn-action item-setting">
          <button class="btn" @click="showExtraSetting()">
            {{ $t('leaderboard.show_extra_setting') }}
          </button>
        </div>
        <div class="filter-item item-checkbox">
          <input type="checkbox" class="checkbox" :checked="isCheckShowOnUserUI" @click="checkShowOnUserUI"/>
          <label class="label-checkbox"> {{ $t('leaderboard.show_on_user_ui') }}</label>
        </div>

      </div>

      <div v-if="historyType === 'spot'" class="datatable" id = "table-trading-volume" style="width: 100%">
        <data-table :getData="getData" ref="datatable" :column="6" class="scroll" :limit="10">
          <th class="col_rank"  >{{ $t('leaderboard_table_title.Ranking') }}</th>
          <th class="col_email" >{{ $t('leaderboard_table_title.Email') }}</th>
          <th class="col_volume" >{{ $t('leaderboard_table_title.TradingVolume') }}</th>

          <template slot="body" slot-scope="props">
            <tr :class="{'opacity-row': props.item.status == 'canceled' && props.item.executed_quantity == 0}">
              <td class="col_rank">{{ props.item.ranking}} </td>
              <td class="col_email">{{ props.item.email }}</td>
              <td v-if ="settingUser['self_trading_volume_'+ historyType]" class="col_volume">{{ props.item.btc_volume | formatCurrencyAmount(filters.currency, '0') }} {{$t('currency.btc.shortname')}}</td>
              <td v-else class="col_volume">{{ props.item.trading_volume | formatCurrencyAmount(filters.currency, '0') }} {{$t('currency.btc.shortname')}}</td>
            </tr>
          </template>
        </data-table>
      </div>

      <div v-if="historyType === 'margin'" class="datatable" id = "table-trading-volume" style="width: 100%">
        <data-table :getData="getData" ref="datatable" :column="6" class="scroll" :limit="10">
          <th class="col_rank">{{ $t('leaderboard_table_title.Ranking') }}</th>
          <th class="col_email">{{ $t('leaderboard_table_title.Email') }}</th>
          <th class="col_volume">{{ $t('leaderboard_table_title.TradingVolume') }}</th>

          <template slot="body" slot-scope="props">
            <tr :class="{'opacity-row': props.item.status == 'canceled' && props.item.executed_quantity == 0}">
              <td class="col_rank">{{ props.item.ranking }} </td>
              <td class="col_email">{{ props.item.email }}</td>
              <td v-if ="settingUser['self_trading_volume_'+ historyType]" class="col_volume">{{ props.item.btc_volume | formatCurrencyAmount(filters.currency, '0') }} {{props.item.coin | uppercase}}</td>
              <td v-else class="col_volume">{{ props.item.trading_volume | formatCurrencyAmount(filters.currency, '0') }} {{props.item.coin | uppercase}}</td>
            </tr>
          </template>
        </data-table>
      </div>
    </div>

    <modal name="detailGroup" width="500" id="modal_add_group" :title="$t('leaderboard.show_extra_setting')">
      <div slot="body" class="body-popup">
        <div class="form-group-detail">

            <div class="group_head_airdrop_setting ml-5 row">
              <div class="col-md-5">
                <span class="title_item_airdrop_setting ">{{ $t('account_setting.trading_with_themselves')}}</span>
              </div>
              <div class="col-md-6">
                <label class="switch">
                  <input type="checkbox" v-model="settingUser['self_trading_volume_'+ historyType]" @click="changeStatusSetting('self_trading_volume_' + historyType)">
                  <span>
                    <i></i>
                  </span>
                </label>
              </div>
            </div>
            <div class="group_head_airdrop_setting ml-5 row">
              <div class="col-md-5">
                <span class="title_item_airdrop_setting ">{{ $t('account_setting.user_pass_kyc') }}</span>
              </div>
              <div class="col-md-6">
                <label class="switch">
                  <input type="checkbox" v-model="settingUser['trading_volume_kyc_'+ historyType]" @click="changeStatusSetting('trading_volume_kyc_' + historyType)">
                  <span>
                    <i></i>
                  </span>
                </label>
              </div>
            </div>

            <div class="group_head_airdrop_setting ml-5 d-flex">
              <div class="pdt">
                <span class="title_item_airdrop_setting ">{{ $t('account_setting.show_leaderboard_on_date_from_to') }} &nbsp</span>
              </div>
              <div class="">
                <date-picker  v-model="settingUser['trading_volume_start_'+ historyType]" class="date-picker-input no-right-radius" :format="customFormatter"></date-picker>
              </div>
              <div class="pdt">
                <span class="title_item_airdrop_setting ">&nbsp {{ $t('account_setting.to') }} &nbsp</span>
              </div>
              <div class="">              
                <date-picker v-model="settingUser['trading_volume_end_' + historyType]" class="date-picker-input no-left-radius" :format="customFormatter"></date-picker>
              </div>
            </div>

        </div>
      </div>
    </modal>

  </div>
</template>

<script>
  import rf from '../../lib/RequestFactory';
  import BigNumber from 'bignumber.js';
  import DatePicker from "vuejs-datepicker";
  import moment from "moment";
  import COMMON_CONST from "../../common/Const";
  import SelectBox from "../../components/SelectBox";
  import CoinInput from '../../common/CoinInput.vue'
  import Modal from '../../components/Modal';

  export default {
    components: {
      DatePicker,
      SelectBox,
      CoinInput,
      Modal
    },
    props: {
      type: {
        type: String,
        default: 'deposit'
      }
    },
    
    data() {
      return {
        searchKey: '',
        historyType: 'spot',
        productBase: COMMON_CONST.PRODUCT_BASE,
        coinMastersMargin:[{"id":0, "name":"BTC"}],
        titlePage: window.i18n.t("leaderboard_item.trading_volume_ranking"),
        startDate: moment().subtract(1, 'week').toDate(),
        endDate: moment().toDate(),
        coin: "",
        filters: {
          currency: "BTC",
          searchKey: ""
        },
        coins: [],
        coinMasters: [],
        isShowCoinOption: false,
        trades: [],
        markets: [],
        isCheckShowOnUserUI: false,
        dataTable: [],
        sortKey: "",
        editDateTimeApply: false,
        settingUser: {
          self_trading_volume_spot: false,
          trading_volume_kyc_spot: false,
          trading_volume_start_spot: moment().subtract(1, 'week').toDate(),
          trading_volume_end_spot: moment().toDate(),
          self_trading_volume_margin: false,
          trading_volume_kyc_margin: false,
          trading_volume_start_margin: moment().subtract(1, 'week').toDate(),
          trading_volume_end_margin: moment().toDate(),
        },
        tempDateRange: {
          trading_volume_start_spot: moment().subtract(1, 'week').toDate(),
          trading_volume_end_spot: moment().toDate(),
          trading_volume_start_margin: moment().subtract(1, 'week').toDate(),
          trading_volume_end_margin: moment().toDate(),

        }
      }
    },
    computed: {
      trading_volume_start_spot() {
        return this.settingUser.trading_volume_start_spot;
      },
      trading_volume_start_margin() {
        return this.settingUser.trading_volume_start_margin;
      },
      trading_volume_end_spot() {
        return this.settingUser.trading_volume_end_spot;
      },
      trading_volume_end_margin() {
        return this.settingUser.trading_volume_end_margin;
      }
      
    },
    watch: {
      historyType() {
        this.$refs.datatable.refresh();
        this.refresh();
      },
      trading_volume_start_spot(newV) {
        if (moment(newV).format('YYYY MM DD') > moment(this.trading_volume_end_spot).format('YYYY MM DD')) {
          this.$toastr("error", this.$t("common.date.warning"));
          this.getSettingsAdmin();
          return;
        }
        this.changeDateSetting('trading_volume_start_spot', newV);
      },
      trading_volume_end_spot(newV) {
        if (moment(newV).format('YYYY MM DD') < moment(this.trading_volume_start_spot).format('YYYY MM DD')) {
          this.$toastr("error", this.$t("common.date.warning"));
          this.getSettingsAdmin();
          return;
        }
        this.changeDateSetting('trading_volume_end_spot', newV);
      },
      trading_volume_start_margin(newV) {
        if (moment(newV).format('YYYY MM DD') > moment(this.trading_volume_end_margin).format('YYYY MM DD')) {
          this.$toastr("error", this.$t("common.date.warning"));
          this.getSettingsAdmin();
          return;
        }
        this.changeDateSetting('trading_volume_start_margin', newV);
      },
      trading_volume_end_margin(newV) {
        if (moment(newV).format('YYYY MM DD') < moment(this.trading_volume_start_margin).format('YYYY MM DD')) {
          this.$toastr("error", this.$t("common.date.warning"));
          this.getSettingsAdmin();
          return;
        }
        this.changeDateSetting('trading_volume_end_margin', newV);
      }
      
    },

    methods: {
      showExtraSetting(){
        this.errors.clear();
        CommonModal.show("detailGroup", {
            position: "group-setting-detail",
            mask: true,
            buttons: [],
        });
      },
      selectTab(tab) {
        this.historyType = tab;
        this.getLeaderboardSetting();
      },
      changeStatusSetting(key) {
        this.settingUser[key] = !this.settingUser[key]
        rf.getRequest('LeaderboardRequest').changeSetting({key, value: this.settingUser[key]});
        this.$refs.datatable.refresh();
      },
      checkShowOnUserUI(){
        this.isCheckShowOnUserUI = !this.isCheckShowOnUserUI;
        let param = {
          checked: this.isCheckShowOnUserUI ? "1" : "0",
          type: this.historyType
        }
        rf.getRequest('LeaderboardRequest').updateLeaderboardSetting(param);
      },
      
      convertTime(time) {
        let stillUtc = moment.utc(time).toDate();
        let local = moment(stillUtc).local().format('YYYY-MM-DD');
        return local;
      },
      trimNumber(number) {
        return new BigNumber(number);
      },
      search() {
        let filterParams = {
          start_date: (moment(this.startDate)
            .startOf("day")
            .format("x")/1000).toFixed(0),
          end_date: (moment(this.endDate)
            .endOf("day")
            .format("x")/1000).toFixed(0)
        };
        if (this.startDate > this.endDate) {
          this.$toastr("error", this.$t("common.date.warning"));
          return;
        }
        if (this.filters.searchKey) {
          filterParams.search_key = this.filters.searchKey;
        }
        if (this.filters.currency) {
          filterParams.currency = this.filters.currency;
        }
        this.$refs.datatable.$emit('DataTable:filter', filterParams);
      },
      refresh() {
        this.requestCoinList();
        this.resetInput();
        this.$refs.datatable.refresh();
      },
      resetInput() {
        this.filters = Object.assign(this.filters, {
          currency: "BTC",
          searchKey: ""
        });
        this.startDate = moment().subtract(1, 'week').toDate();
        this.endDate = moment().toDate();
      },

      getPendingTransactions(params) {
        const meta = {
          type: this.type,
          status: this.transactionStatus
        }
        if (!window._.isEmpty(this.searchKey)) {
          meta.search_key = this.searchKey;
        }
        params = Object.assign(params, meta);
        return rf.getRequest('AdminRequest').getTransactions(params).catch(err => {
          console.error(err);
        });
      },
      getData(params) { 
        let filterParams = {
          start_date: (moment(this.startDate)
            .startOf("day")
            .format("x")/1000).toFixed(0),
          end_date: (moment(this.endDate)
            .endOf("day")
            .format("x")/1000).toFixed(0)
        };
        let computedParams = {...params, type: this.historyType, currency: this.filters.currency};
        // return rf.getRequest('LeaderboardRequest').getTradingVolumeRanking(computedParams).then((res) =>{
        //     res.data = this.filtersDataTable(res.data, computedParams);
        //     return res.data;
        //   });
        return rf.getRequest('LeaderboardRequest').getTradingVolumeRanking(Object.assign(computedParams, filterParams)).then((res) =>{
          return res.data;
        })
      },

      filtersDataTable(data, param){
        let obj = [];
        let result = data;
        for ( let i=0; i < result.length; i++ ){
            obj[result[i]['user_id']] = result[i];
        }
        result = new Array();
        for ( let key in obj ) result.push(obj[key]);
        result.sort(function(a, b){
          if ( Number(a.volume) > Number(b.volume) ){
            return -1;
          }
          if ( Number(a.volume) < Number(b.volume) ){
            return 1;
          }
          return 0;
        });
        result = this.setRanking(result);
        result = this.searchDataTable(result, param);
        return result;
      },

      searchDataTable(data, param){
        let result = data;
        let arrTemp = [];
        if(param["start_date"]){
          let now = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
          let startDate = param["start_date"];
          let endDate = param["end_date"] ? param["end_date"] : now;
          arrTemp = [];
          result.forEach(function(item, key){
            if(item["created_at"] >= startDate && item["created_at"]<= endDate){
              arrTemp.push(item);
            }
          })
          result = arrTemp;
        }

        if(param["search_key"]){
          arrTemp = [];
          result.forEach(function(item, key){
            if(item["email"].includes(param["search_key"])){
              arrTemp.push(item);
            }
          })
          result = arrTemp;
        }

        if(param["sort"]){
          result = this.sortColumnTable(result, param["sort"], param["sort_type"]);
        }

        return result;
      },

      sortColumnTable(arr, key, type){
        let result = arr;
        if(type === "desc"){
          result.sort(function(a, b){
            if ( (key === "email" && a[key] > b[key]) || (key !== "email" && Number(a[key]) > Number(b[key]))){
              return -1;
            }
            if ( (key === "email" && a[key] < b[key]) || (key !== "emai" && Number(a[key]) < Number(b[key])) ){
              return 1;
            }
            return 0;
          });
        }

        if(type === "asc"){
          result.sort(function(a, b){
            if ( (key === "email" && a[key] < b[key]) || (key !== "email" && Number(a[key]) < Number(b[key]))){
              return -1;
            }
            if ( (key === "email" && a[key] > b[key]) || (key !== "emai" && Number(a[key]) > Number(b[key])) ){
              return 1;
            }
            return 0;
          });
        }
        return result;
      },
      
      setRanking(data){
        let lstVolumeRanking = data;
        for(let i=0; i< lstVolumeRanking.length; i++){
          lstVolumeRanking[i]["ranking"] = i +1;
        }
        return lstVolumeRanking;
      },

      onDatatableFinish() {
        const datatable = this.$refs.datatable;
        if (datatable.params.sort !== 'amount') {
          return;
        }
        const result = window._.chain(datatable.rows)
          .map(item => {
            item.amount = Math.abs(parseFloat(item.amount));
            return item;
          })
          .value();
          
        this.$refs.datatable.rows = result;
      },

      // Get amount which hasn't calculate fee yet.
      getFullAmount(reductionAmount, fee) {
        return new BigNumber(`${Math.abs(reductionAmount)}`).add(`${fee}`).toString();

      },
      formatDateTime(date) {
        return moment(date, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss');
      },
      customFormatter(date) {
        return moment(date).format('YYYY - MM - DD');
      },
      requestCoinList() {
        rf.getRequest("CoinSettingRequest")
          .getCoinList()
          .then(res => {
            if(window._.map(res.data, "coin")) {
              this.coinMasters = window._.map(res.data, function (item) {
                return item.coin.toUpperCase();
              });
            }
          })
          .catch(e => {
            console.error(e)
          });
      },
      getLeaderboardSetting(){
        rf.getRequest("LeaderboardRequest")
          .getLeaderboardSetting({"type": this.historyType})
          .then(res => {
            if(res.success){
              let result = res.data;
              this.isCheckShowOnUserUI = result.value === "1" ? true : false;
            }
          })
        .catch(e => {
          this.isCheckShowOnUserUI = false
          console.error(e)
        });
      },
      changeDateSetting(key, value) {
        rf.getRequest('LeaderboardRequest').changeSetting({key, value: value * 1});
      },
      getSettingsAdmin() {
        rf.getRequest('LeaderboardRequest').getSelfTradingSettings().then(res=>{
          for (let key in this.settingUser) {
            this.settingUser[key] = Number(res.data.find(el=> {
              if ( el.key == key) return el;
            }).value);
          }
          this.handleTimeByKey('trading_volume_start_spot');
          this.handleTimeByKey('trading_volume_end_spot');
          this.handleTimeByKey('trading_volume_start_margin');
          this.handleTimeByKey('trading_volume_end_margin');
        });
      },
      handleTimeByKey(key) {
        if (this.settingUser[key]) {
          let time = new Date(this.settingUser[key]);
          this.settingUser[key] = time;
          this.tempDateRange[key] = time;
        }
      }
    },

    mounted() {
      this.$emit('EVENT_PAGE_CHANGE', this);
    },
    created() {
      this.getLeaderboardSetting();
      this.requestCoinList();
      this.getSettingsAdmin();
      rf.getRequest('AdminRequest').getPriceGroupCurrency().then(res => {
        try {
          window._.map(res.data, item => {
            if (typeof item.currency !== 'undefined') {
              this.markets.push({ id: item.currency, name: item.currency.toUpperCase() });
            }
          });
        }
        catch (ex) {
          console.error(ex);
        }
      });

      this.$on('hideCommonModal', (modalName) => {
        if (modalName !== 'detailGroup'){
          return;
        }
        this.handleCancelEditDate();
      });
    }
  }
</script>

<style lang="scss" scoped>
@import "@/assets/sass/variables";
  .pdt {
    padding-top: 3px;
  }
  .ml-15{
    margin-left: 15px;
  }
  .d-flex{
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
  }

  .group_btn_edit .btn {
    background-color: #299b82;
    border: 1px solid #299b82;
    line-height: 20px;
    width: 85px;
    color: #ffffff;
    text-align: center;
    text-transform: uppercase;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    letter-spacing: 0.9px;
    font-size: 12px;
    font-family: "Roboto-Bold", sans-serif;
    padding: 5px;
    height: 30px;
    font-weight: 500;
  }

  .item-setting{
    float: right !important;
    margin: 0 !important;
    text-align: right;
    .btn {
      width: 100% !important;
    }
  }

  .select_transaction_container{
    margin-bottom: 0 !important;
  }

  .clearfix-50 {
    display: block;
    height: 50px;
  }
  .clearfix-20 {
    display: block;
    height: 20px;
  }
  .right {
    float: right;
  }
  .line_setting {
    background-color: $color_alto;
    display: block;
    width: 100%;
    height: 1px;
  }

  .group_head_airdrop_setting{
    margin-top: 10px;
    .title_item_airdrop_setting {
      line-height: 15px;
      margin-bottom: 7px;
      color: #666666;
      font-size: 14px;
    }
    .switch {
        display: inline-block;
        vertical-align: middle;
        cursor: pointer;
        input {
          display:none;
          &:checked +span {
            background: $color-jungle-green;
            text-align: left;
            &:before {
              content: "ON";
              color: $color_white;
              font-size: 12px;
            }
            i {
              left: 35px;
            }
            &:after {
              content: " ";
              color: $color_white;
            }
          }

        }
        span {
          display: inline-block;
          padding: 0px 6px 0 9px;
          width: 54px;
          height: 20px;
          line-height: 20px;
          border-radius: 11px;
          background: $color_grey_icon_light;
          position: relative;
          color: $color_white;
          font-size: 14px;
          text-align: right;
          &:after {
            content: "OFF";
            color: $color_white;
            line-height: 20px;
            font-size: 12px;
          }
          i {
            display: inline-block;
            width: 20px;
            height: 20px;
            border-radius: 100%;
            background: $color_white;
            position: absolute;
            left: 0;
            top: 0px;
            box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.5);
            -webkit-transition: all 0.3s ease-in-out;
            -moz-transition: all 0.3s ease-in-out;
            -ms-transition: all 0.3s ease-in-out;
            -o-transition: all 0.3s ease-in-out;
            transition: all 0.3s ease-in-out;
          }
        }
      }
  }
  .airdrop_history {
    .filter-container {
      .filter-item {
        .label-text {
          font-family: $font-family-roboto;
        }
      }
    }
  }
</style>
<style lang="scss">
@import "@/assets/sass/variables";
  #base_deposit_withdraw {
    &.airdrop_history {
      .filter-item.date {
        width: 235px;
        .date-picker-input {
          input {
            width: 115px !important;
          }
        }
      }
    }

    .filter-container{
      .item-checkbox{
        float: right !important;
        position: relative;
        .checkbox{
          width: 15px;
          height: 15px;
          position: absolute;
          margin-top: 29px;
          /* margin-top: 16px; */
          border: 1px solid #666666;
        }
        .label-checkbox {
          margin: 27px 10px 0px 19px;
          font-weight: unset;
          color: #666666;
        }
      }
      // table-trading-volume
      
    }
    #table-trading-volume{
      
      .sc_search_select {
        .group_search_select {
          .button_drop_search {
            background: transparent;
            padding: 7px 10px 5px 10px;
            width: 55px !important;
            height: 32px;
            border: 1px solid $color-alto;
            background-color: $color-white-smoke;
            border-radius: 3px;
            &.active {
              border: 1px solid $color-jungle-green;
            }
          }
          .box_list_search_select {
            ul {
              width: 55px;
              li {
                &:hover {
                  background-color: $color-jungle-green;
                  span,
                  a {
                    color: $color-white;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
</style>