<template>
  <div class="trade-history clearfix mg_bot50" id="trade_history">
    <div class="filter-container">
      <div class="filter-item date">
        <div class="text-pair no-padding">{{ $t('orders.trade_history.date') }}:</div>
        <date-picker v-model="startDate" class="date-picker-input no-right-radius"
          :format="customFormatter"></date-picker>
        <date-picker v-model="endDate" class="date-picker-input no-left-radius" :format="customFormatter"></date-picker>
      </div>
      <div class="filter-item search-box">
        <input title="" type="text" :placeholder="$t('order.order_history.search')" @keyup.enter="search"
          class="form-control" name="searchKey" v-model="filters.searchKey" />
      </div>
      <div class="filter-item">
        <button class="btn btn-primary" @click="search()">{{ $t('orders.trade_history.search') }}</button>
        <button class="btn btn-reset" @click="refresh()">{{ $t('orders.trade_history.reset') }}</button>
      </div>
    </div>
    <div class="datatable" id="trade-history-table">
      <data-table-4 :getData="getData" ref="datatable" :msgEmptyData="$t('margin.table_funding_history.empty_msg')"
        :column="11" class="scroll">
        <th data-sort-field="created_at" class="text-left">{{ $t('margin.trade_history.time') }}</th>
        <th>{{ $t('margin.trade_history.symbol') }}</th>
        <th>{{ $t('margin.orders.trade_history.owner_email') }}</th>
        <th>{{ $t('margin.orders.position.manager_email') }}</th>
        <th>{{ $t('margin.trade_history.fee_rate') }}</th>
        <th>{{ $t('margin.trade_history.fee') }}</th>
        <th>{{ $t('margin.trade_history.price') }}</th>
        <th>{{ $t('margin.trade_history.quantity') }}</th>
        <th><span class="fl-right">{{ $t('margin.orders.position.value') }}</span></th>
        <template slot="body" slot-scope="props">
          <tr>
            <td class="text-left">
              <div>{{ props.item.created_at | formatTimeStamp('YYYY-MM-DD') }}</div>
              <div>{{ props.item.created_at | formatTimeStamp('HH:mm:ss') }}</div>
            </td>
            <td class="text-left">{{ props.item.instrument_symbol }}</td>
            <td class="text-left" width="15%">
              <div class="item_email_setting">
                <span class="txt_email_setting">{{ getValueFunding(props.item.buy_owner_email,
          props.item.sell_owner_email) }}</span>
                <span class="tooltip_email_setting">{{ getValueFunding(props.item.buy_owner_email,
          props.item.sell_owner_email) }}</span>
              </div>
            </td>
            <td class="text-left" width="15%">
              <div class="item_email_setting">
                <span class="txt_email_setting">{{ getValueFunding(props.item.buy_manager_email,
          props.item.sell_manager_email) }}</span>
                <span class="tooltip_email_setting">{{ getValueFunding(props.item.buy_manager_email,
          props.item.sell_manager_email) }}</span>
              </div>
            </td>
            <td
              v-bind:class="['text-left', { 'blue': getValueFunding(props.item.sell_fee_rate, props.item.buy_fee_rate) < 0 }]">
              {{ (getValueFunding(props.item.sell_fee_rate, props.item.buy_fee_rate) * 100) | formatCurrencyAmount(null,
          '0') }}%</td>
            <td
              v-bind:class="['text-left', { 'blue': getValueFunding(props.item.sell_fee_rate, props.item.buy_fee) < 0 }]">
              {{ getValueFunding(props.item.sell_fee, props.item.buy_fee) | formatCurrencyAmount(null, '0') }}
              {{ (props.item.is_amal_fee_seller == 1 ? commonConst.CURRENCY_AMAL : commonConst.CURRENCY_BTC) |
          uppercase }}</td>
            <td class="text-left">{{ props.item.price | formatCurrencyAmount(null, '0') }}</td>
            <td class="text-left">{{ props.item.quantity | formatCurrencyAmount(null, '0')}}</td>
            <td class="text-right">{{props.item.amount| formatCurrencyAmount(null, '0')}}</td>
          </tr>
        </template>
      </data-table-4>
    </div>
  </div>
</template>

<script>
import rf from '../../lib/RequestFactory';
import Modal from '@/desktop/components/common/Modal';
import DatePicker from 'vuejs-datepicker';
import moment from 'moment';
import MasterdataUtils from '@/desktop/common/MasterdataUtils';
import COMMON_CONST from '../../common/Const';
import SelectBox from '../../components/SelectBox';
import DataTable4 from "../../components/DataTable4"

export default {
  components: {
    Modal,
    DatePicker,
    SelectBox,
    DataTable4
  },
  data() {
    return {
      titlePage: window.i18n.t('trade.funding_history'),
      startDate: moment().subtract(1, 'week').toDate(),
      endDate: new Date(),
      coin: '',
      filters: {
        coin: '',
        currency: '',
        searchKey: '',
      },
      coins: [],
      coinMasters: [],
      isShowCoinOption: false,
      trades: [],
      markets: [],
      commonConst: COMMON_CONST,
    }
  },
  methods: {
    customFormatter(date) {
      return moment(date).format('YYYY - MM - DD');
    },
    search() {
      let filterParams = {
        start_date: moment(this.startDate).startOf('day').format('x'),
        end_date: moment(this.endDate).endOf('day').format('x')
      };
      if (moment.unix(this.startDate / 1000).format("YYYY-MM-DD") > moment.unix(this.endDate / 1000).format("YYYY-MM-DD")) {
        this.$toastr('error', this.$t('common.date.warning'));
        return;
      }
      if (this.filters.searchKey) {
        filterParams.search_key = this.filters.searchKey;
      }
      if (this.filters.coin) {
        filterParams.coin = this.filters.coin;
      }
      if (this.filters.currency) {
        filterParams.currency = this.filters.currency.id;
      }
      filterParams.trade_type = 'funding';
      this.$refs.datatable.filter(filterParams);
    },
    refresh() {
      this.resetInput();
      this.$refs.datatable.refresh();
    },
    resetInput() {
      $("#trade-history-table").find("th").attr("data-sort-order", "");
      this.filters = Object.assign(this.filters, { coin: '', currency: '', searchKey: '' });
      this.startDate = moment().subtract(1, 'week').toDate();
      this.endDate = new Date();
    },
    onSearchCoin() {
      this.coins = _.filter(this.coinMasters, (item) => {
        return _.isMatch(item.toUpperCase(), this.filters.coin.toUpperCase());
      });
    },
    getValueFunding(v1, v2) {
      if (!v1 || v1 == 0) return v2;
      return v1;
    },
    onSelectCoin(coin) {
      this.isShowCoinOption = false;
      this.filters.coin = coin.toUpperCase();
    },
    clickOut() {
      this.isShowCoinOption = false;
    },
    showCoinOption() {
      this.isShowCoinOption = true;
      this.coins = this.coinMasters;
    },
    getData(params) {
      let defaultParams = {
        start_date: moment(this.startDate).startOf('day').format('x'),
        end_date: moment(this.endDate).endOf('day').format('x'),
        trade_type: 'funding'
      };
      return rf.getRequest('MarginOrderRequest').getTradeHistory(Object.assign(defaultParams, params));
    },

  },

  mounted() {
    this.$emit('EVENT_PAGE_CHANGE', this);
  },
  created() {

  }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.min-width-130 {
  min-width: 130px;
}

#trade-history-table {
  table {
    thead {
      tr {
        white-space: nowrap;
        text-overflow: ellipsis;

        th {
          padding: 5px 3px 5px 15px;

          &:last-child {
            padding-right: 15px;
            padding-left: 2px;
          }
        }
      }
    }

    tbody {
      tr {
        white-space: nowrap;
        text-overflow: ellipsis;

        td {
          padding: 8px 2px 4px 15px;

          &:last-child {
            padding-right: 15px;
            padding-left: 2px;
          }
        }
      }
    }
  }
}

.fl-right {
  float: right;
}

.group_tooltip {
  position: relative;
  display: block;
  width: auto;
  clear: both;

  .txt_tooltip {
    display: block;
    width: 70px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    float: left;
    cursor: pointer;
  }

  .content_tooltip {
    position: absolute;
    top: 0px;
    left: 100%;
    background: $color-white;
    box-shadow: 0px 0px 3px $color-grey-dark;
    padding: 5px;
    border-radius: 3px;
    line-height: 20px;
    min-width: 150px;
    max-width: 500px;
    width: auto;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &:hover {
    .content_tooltip {
      display: block;
    }
  }
}

.date-picker-input {
  display: inline-block;
  float: left;
  margin-right: 1px;

  input {
    padding: 0 5px 0px 23px;
    width: 110px !important;
    border-radius: 3px;
    -webkit-box-shadow: none;
    box-shadow: none;
    height: 30px;
    line-height: 30px;
    border: 1px solid #d2d6de;
    background-image: url('@/assets/images/icon/date-time.svg') !important;
    background-color: $color-white-smoke !important;
    background-size: 12px;
    background-position: center left 5px;
    background-repeat: no-repeat !important;
    color: $color-grey-dusty;

    &:hover,
    &:active,
    &:focus {
      border-color: $color-jungle-green;
      z-index: 9;
    }
  }
}

.no-left-radius {
  input {
    border-radius: 0px 3px 3px 0px;
    margin-left: -1px;
  }
}

.no-right-radius {
  input {
    border-radius: 3px 0px 0px 3px;
  }
}

.modal-content .modal-footer {
  text-align: center;
  margin-top: 30px;
}

#trade_history {

  .sc_search_select {
    .group_search_select {
      .button_drop_search {
        height: 32px;
        padding: 6px 10px 6px 10px !important;
        border-radius: 10px;
        font-size: $font-small;
        font-family: $mulish-regular;
        background-color: $color-white;

        span {
          font-size: $font-small;
        }

        i.icon-arrow1 {
          margin-left: 5px;
          color: $color_dove_gray;
        }

        &:hover {
          border-color: $color-jungle-green;

          i.icon-arrow1 {
            color: $color-jungle-green;
          }
        }
      }

      .box_list_search_select {
        .list_search_select {
          border-radius: 10px;

          li {
            font-family: $font-family-roboto;
            font-size: $font-small;

            &:hover,
            &:active,
            &:focus {
              color: $color-jungle-green;
            }
          }
        }
      }
    }
  }
}
</style>

<style lang="scss" scoped>
@import "@/assets/sass/variables";

#trade-history-table {
  table {
    thead {
      tr {
        white-space: nowrap;
        text-overflow: ellipsis;

        th {
          padding: 5px 3px 5px 15px;

          &:last-child {
            &::after {
              float: right;
            }
          }
        }
      }
    }

    tbody {
      tr {
        white-space: nowrap;
        text-overflow: ellipsis;

        td {
          padding: 8px 2px 4px 15px;

          &:last-child {
            padding-right: 15px;
            padding-left: 2px;
          }

          &.blue {
            color: $color-jungle-green;
          }
        }
      }
    }
  }
}

.item_email_setting {
  display: inline-block;
  float: left;
  position: relative;

  .txt_email_setting {
    display: block;
    max-width: 150px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .tooltip_email_setting {
    position: absolute;
    top: 0px;
    left: 0px;
    line-height: 20px;
    padding: 5px 20px;
    left: 100%;
    background-color: $color_white;
    white-space: nowrap;
    width: auto;
    z-index: 10;
    font-size: $font_root;
    font-weight: 500;
    color: $color_mine_shaft;
    transition: 0.5s;
    display: none;
    box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.4);

    &:after {
      right: 100%;
      top: 50%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-color: rgba(136, 183, 213, 0);
      border-right-color: $color_white;
      border-width: 5px;
      margin-top: -5px;
    }
  }

  &:hover {
    .tooltip_email_setting {
      display: block;
      transition: 0.5s;
    }
  }
}

.buy {
  color: $text-color-jade;
}

.sell {
  color: $text-color-red;
}

.item_email_user {
  display: inline-block;
  float: left;
  position: relative;

  .txt_email_user {
    display: block;
    max-width: 75px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .tooltip_email_user {
    position: absolute;
    top: 0px;
    left: 0px;
    line-height: 20px;
    padding: 5px 20px;
    left: 100%;
    background-color: $color_white;
    white-space: nowrap;
    width: auto;
    z-index: 10;
    font-size: $font_root;
    font-weight: 500;
    color: $color_mine_shaft;
    transition: 0.5s;
    display: none;
    box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.4);

    &:after {
      right: 100%;
      top: 50%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-color: rgba(136, 183, 213, 0);
      border-right-color: $color_white;
      border-width: 5px;
      margin-top: -5px;
    }
  }

  &:hover {
    .tooltip_email_user {
      display: block;
      transition: 0.5s;
    }
  }
}

.text-left {
  text-align: left;
}

.opacity-row {
  opacity: 0.4;
}

.trade-history {
  .filter-container {
    margin: 10px 0px 30px 0px;
    height: 55px;

    .filter-item {
      float: left;

      &.search-box {
        margin-left: 15px;
      }

      input {
        margin-top: 23px;
        margin-right: 5px;
        display: inline-block;
        width: 145px;
        font-family: $font-family-roboto;
        font-size: $font-small;

        &:hover,
        &:active,
        &:focus {
          border-color: $color-jungle-green;
        }
      }

      select {
        width: 65px;
        display: inline-block;
        font-family: $font-family-roboto;
      }

      button {
        width: auto;
        margin: 23px 2px 0px 2px;
        height: 30px;
        font-size: $font-smaller;
        font-size: $font-smaller;
        font-family: $font-family-roboto-bold;

        &:hover {
          border-color: $color-aquamarine;
          background-color: $color-aquamarine;
        }
      }

      .btn-reset {
        background-color: $color-caribbean-green;
        border: 1px solid $color-caribbean-green;
        width: 80px;
        text-transform: uppercase;
        color: $color-white;
        font-weight: 500;

        &:hover {
          border-color: $color-aquamarine;
          background-color: $color-aquamarine;
        }
      }

      .btn-primary {
        padding: 0;
        min-width: 80px;

        &:hover {
          border-color: $color-aquamarine;
          background-color: $color-aquamarine;
        }
      }

      .coin-input {
        position: relative;
        display: inline-block;
        margin: 0 10px;
        margin-right: 0px;
        font-size: $font-small;
        font-family: $font-family-roboto;

        .coin-option {
          background-color: $color-white;
          max-height: 210px;
          display: block;
          overflow-y: auto;
          margin-top: 5px;
          position: absolute;
          width: 79px;
          left: 5px;
          z-index: 10;
          padding: 12px 0px;
          box-shadow: 0px 3px 12px rgba(49, 49, 49, 0.51);

          &:after {
            bottom: 100%;
            left: 50%;
            border: solid transparent;
            content: " ";
            display: block;
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
            border-color: rgba(136, 183, 213, 0);
            border-bottom-color: $color_white;
            border-width: 5px;
            margin-left: -5px;
          }

          .option {
            display: block;
            width: 100%;
            line-height: 20px;
            cursor: pointer;
            padding: 3px 14px;
            overflow: hidden;

            &:hover {
              color: $color-jungle-green;
            }
          }
        }
      }

      .text-pair {
        padding-left: 20px;
        color: $color-grey-dusty;
        margin-bottom: 5px;
        font-family: $font-family-roboto;
        font-size: $font-small;
      }
    }

    .pair {
      input {
        width: 65px;
      }
    }
  }

  .form-control {
    background: $color-white-smoke;
    height: 30px;
    padding-left: 11px;
    padding-right: 0;
  }

  .toolbar-element2 {
    display: inline-block;
    float: left;
  }

  .bw-element {
    float: left;
    font-size: $font_medium;
    margin-left: 5px;
    height: 30px;
    line-height: 30px;
    margin-right: 5px;
    color: $color-grey-dusty;
  }

  .datatable {
    button.btnForm.bt-action {
      padding-left: 5px !important;
      padding-right: 5px !important;
      font-weight: 400 !important;
    }

    table {
      thead {
        th {
          padding: 5px 0px 0px 15px !important;
          text-align: left;
          // color: $color_grey;
          font-family: Roboto-Medium;
          font-size: 13px;
          font-weight: 500;

          &.text-c {
            text-align: center;
          }
        }

        th:last-child {
          // text-align: right;
          padding: 5px 25px 0px 15px !important;

          span {
            // float: right;
          }
        }

        tr {
          th {
            white-space: nowrap;
            text-overflow: ellipsis;
          }

          :nth-child(5) {
            width: 100px;
          }

          :nth-child(6) {
            width: 100px;
          }


        }
      }

      tbody {
        tr {
          tr {
            td {
              white-space: nowrap;
              text-overflow: ellipsis;
            }

            td:last-child {
              // text-align: right;
            }
          }
        }
      }
    }
  }
}
</style>
