<template>
  <modal name="cold-wallet-modal-loading"
         :click-to-close="false"
         width="700px" height="200px"
         id="save_cold_wallet"
         @opened="$emit('opened', $event)"
         @closed="$emit('closed', $event)">
    <div class="setting-popup">
      <div class="loading-content">
        <vue-loading :size="{ width: '75px', height: '75px' }" type="spin" class="loading-icon" color="#0f8ddb"/>
        <p class="popup__title">{{ status || $t('cold_wallet_setting.save_message') }}</p>
      </div>
    </div>
  </modal>
</template>

<script>
  export default {
    name: 'ModalLoading',
    data() {
      return {
        status: window.i18n.t('cold_wallet_setting.save_message'),
      }
    },
  };
</script>

<style lang="scss" scoped>
  .popup__title {
    text-align: center;
    font-size: 18px;
    margin-top: 22px;
  }

  .loading-content {
    position: absolute;
    top: 50%;
    width: 100% !important;
    transform: translateY(-50%);
    width: 100%;
    padding: 0 15px;
  }
</style>

<style lang="scss">
  #save_cold_wallet {
    .v--modal {
      background: transparent;
      box-shadow: none;
    }
  }
</style>
