<template>
  <div class="open-order clearfix mg_bot50" id="open-order">
    <div class="filter-container_order">
      <div class="filter-item date">
        <div class='date-text'>{{ $t('orders.open_order.from') }}</div>
        <date-picker v-model="startDate" class="date-picker-input no-right-radius"
          :format="customFormatter"></date-picker>
      </div>
      <div class="filter-item date">
        <div class='date-text'>{{ $t('orders.open_order.to') }}</div>
        <date-picker v-model="endDate" class="date-picker-input no-left-radius" :format="customFormatter"></date-picker>
      </div>
      <div class="filter-item pair-selector">
        <div class="label-text text-pair date-text">{{ $t('orders.open_order.pair') }}</div>
        <div class="coin-selector toolbar-element" v-click-outside="clickOut">
          <input type="text" @click="showCoinOption()" @keyup="onSearchCoin()"
            :placeholder="$t('order.order_history.coin')" @keyup.enter="search" class="form-control coin-input"
            v-model="filters.coin">
          <div class="coin-option" v-if="isShowCoinOption">
            <span class="option" v-for="coin in coins" @click="onSelectCoin(coin)" :key="coin">{{ coin | uppercase
              }}</span>
          </div>
        </div>
        <span class="bw-element">/</span>
        <div class="toolbar-element">
          <select-box v-model="filters.currency" :placeholder="$t('order.order_history.all')" :options="markets" />
        </div>
      </div>
      <!-- <div class="filter-item side">
        <div class="label-text date-text">{{ $t('orders.open_order.side') }}:</div>
        <div class="toolbar-element2">
          <select-box
              v-model="filters.tradeType"
              :placeholder="$t('order.order_history.all')"
              :options="tradeType"/>
        </div>
      </div> -->
      <div class="filter-item type">
        <div class="label-text date-text">{{ $t('orders.open_order.type') }}</div>
        <div class="toolbar-element2">
          <select-box v-model="filters.tradeType" :placeholder="$t('order.order_history.all')" :options="tradeType" />
        </div>
      </div>
      <div class="filter-item status">
        <div class="label-text date-text">{{ $t('orders.open_order.status') }}</div>
        <div class="toolbar-element2">
          <select-box v-model="filters.status" :placeholder="$t('order.order_history.all')" :options="statusType" />
        </div>
      </div>
      <div class="filter-item">
        <input title type="text" :placeholder="$t('orders.open_order.search')" @keyup.enter="search"
          class="form-control" name="searchKey" v-model="filters.searchKey">
      </div>
      <div class="filter-item btn-action">
        <button class="btn btn-primary" @click="search()">{{ $t('orders.open_order.search') }}</button>
        <button class="btn btn-reset" @click="refresh()">{{ $t('orders.open_order.reset') }}</button>
        <button v-if="!accountId" class="btn btn-reset" @click="downloadExcel()">
          <img src="@/assets/images/download-icon.svg" width="12" />
          {{ $t('orders.open_order.data') }}
        </button>
      </div>
    </div>
    <div class="datatable">
      <!-- <data-table-4 :getData="getData" ref="datatable" :column="11" class="scroll">
        <th data-sort-field="updated_at" class="cl1 text-left min_110">{{ $t('orders.open_order.time') }}</th>
        <th data-sort-field="email" class="cl2 min_85">{{ $t('orders.open_order.email') }}</th>
        <th data-sort-field="id" class="cl3 min_85">
          <div>{{ $t('orders.open_order.order_id') }}/</div>
          {{ $t('orders.open_order.pair') }}
        </th>
        <th data-sort-field="type" class="cl4 min_85">
          <div>{{ $t('orders.open_order.side') }}/</div>
          {{ $t('orders.open_order.type') }}
        </th>
        <th class="cl5 min_110">{{ $t('orders.open_order.average') }}</th>
        <th data-sort-field="price" class="cl6 min_110">
          <div>{{ $t('orders.open_order.price') }}/</div>Trigger
        </th>
        <th class="cl7 min_85">
          <div>{{ $t('orders.open_order.amount') }}/</div>
          {{ $t('orders.open_order.filled') }}
        </th>
        <th data-sort-field="fee" class="min_110">{{ $t('orders.open_order.fee') }}</th>
        <th class="cl9">{{ $t('orders.open_order.total') }}</th>
        <th class="cl10">{{ $t('orders.open_order.status') }}</th>
        <!--<th class="cl11"></th> ------- >
        <template slot="body" slot-scope="props">
          <tr>
            <td class="cl1 text-left" :class="{'opacity-row': props.item.status == 'canceled' && props.item.executed_quantity == 0}">
              <div>{{ props.item.updated_at | formatTimeStamp('YYYY-MM-DD') }}</div>
              <div>{{ props.item.updated_at | formatTimeStamp('HH:mm:ss') }}</div>
            </td>
            <td class="cl2" :class="{'opacity-row': props.item.status == 'canceled' && props.item.executed_quantity == 0}">
              <div class="item_email_user">
                <span class="txt_email_user">{{props.item.email}}</span>
                <span class="tooltip_email_user">{{props.item.email}}</span>
              </div>
            </td>
            <td class="cl3" :class="{'opacity-row': props.item.status == 'canceled' && props.item.executed_quantity == 0}">
              <div>{{ props.item.id }}</div>
              <div>{{ props.item.coin | uppercase }}/{{ props.item.currency | uppercase }}</div>
            </td>
            <td class="cl4" :class="{'opacity-row': props.item.status == 'canceled' && props.item.executed_quantity == 0}">
              <div
                :class="{ buy : props.item.trade_type === 'buy',sell :props.item.trade_type === 'sell'}"
              >{{ props.item.trade_type | tradeType }}</div>
              <div>{{ props.item.type | uppercaseFirst }}</div>
            </td>
            <td class="cl5" :class="{'opacity-row': props.item.status == 'canceled' && props.item.executed_quantity == 0}"
            >{{ props.item.executed_price | formatCurrencyAmount(props.item.currency, "0") }}</td>
            <td class="cl6" :class="{'opacity-row': props.item.status == 'canceled' && props.item.executed_quantity == 0}"> 
              <span
                v-if="props.item.type == 'market' || props.item.type == 'stop_market'"
              >{{ $t('orders.open_order.market') }}</span>
              <span v-else>{{ props.item.price | formatCurrencyAmount(props.item.currency, "0") }}</span>
            </td>
            <td class="cl7" :class="{'opacity-row': props.item.status == 'canceled' && props.item.executed_quantity == 0}">
              <div>{{ props.item.quantity | formatCurrencyAmount(props.item.coin, "0") }}</div>
              <div>{{ props.item.executed_quantity | formatCurrencyAmount(props.item.coin, "0") }}</div>
            </td>
            <td class="cl8" :class="{'opacity-row': props.item.status == 'canceled' && props.item.executed_quantity == 0}">
              <span
                v-if="props.item.trade_type === 'buy'"
              >{{ props.item.fee | formatCurrencyAmount(props.item.coin, "0") }}</span>
              <span v-else>{{ props.item.fee | formatCurrencyAmount(props.item.currency, "0") }}</span>
            </td>
            <td class="cl9" :class="{'opacity-row': props.item.status == 'canceled' && props.item.executed_quantity == 0}">
              <span v-if="props.item.type == 'market' || props.item.type == 'stop_market'">0</span>
              <span
                v-else
              >{{ props.item.price | mulBigNumber(props.item.quantity) | formatCurrencyAmount(props.item.currency, "0") }}</span>
            </td>
            <td class="cl10" :class="{'opacity-row': props.item.status == 'canceled' && props.item.executed_quantity == 0}">{{ props.item | order_status }}</td>
<--            <td class="cl11" :class="{'opacity-row': props.item.status == 'canceled' && props.item.executed_quantity == 0}">-->
      <!--              <button-->
      <!--                type="button"-->
      <!--                class="btn btn-detail"-->
      <!--                @click="viewDetail(props.item.id, props.item.trade_type)"-->
      <!--                :disabled="props.item.status == 'canceled' && props.item.executed_quantity == 0"-->
      <!--              >{{ $t('orders.order_history.view_detail') }}</button>-->
      <!--            </td>-- >
          </tr>
        </template>
</data-table-4> -->
      <data-table-4 :getData="getData" ref="datatable" :column="11" class="scroll">

        <!-- <th data-sort-field="email" class="cl2 min_85">{{ $t('orders.open_order.email') }}</th> -->
        <th data-sort-field="orderID" class="cl1 min_85">
          {{ $t('orders.open_order.order_id') }}
        </th>
        <th v-if="!accountId" data-sort-field="accountID" class="cl2 min_85 text-center">
          {{ $t('orders.open_order.account_id') }}
        </th>
        <th data-sort-field="coin" class="cl3 min_85">
          {{ $t('orders.open_order.pair') }}
        </th>
        <th data-sort-field="trade_type" class="cl4 min_85">
          {{ $t('orders.open_order.side') }}
        </th>
        <th data-sort-field="type" class="cl5 min_85">
          {{ $t('orders.open_order.type') }}
        </th>
        <th data-sort-field="quantity" class="cl6 min_110">
          {{ $t('orders.open_order.filled') }}/
          {{ $t('orders.open_order.quantity') }}
        </th>
        <th data-sort-field="base_price" class="cl7 min_110">
          {{ $t('orders.open_order.trigger') }}
        </th>
        <th data-sort-field="price" class="cl8 min_110">
          {{ $t('orders.open_order.price') }}
        </th>
        <th data-sort-field="time" class="cl9 text-left min_110">{{ $t('orders.open_order.time') }}</th>
        <th data-sort-field="status" class="cl10">{{ $t('orders.open_order.status') }}</th>
        <th class="cl10">{{ $t('orders.open_order.action') }}</th>
        <!--        <th class="cl11"></th>-->
        <template slot="body" slot-scope="props">
          <tr>
            <td class="cl1"
              :class="{ 'opacity-row': props.item.status == 'canceled' && props.item.executed_quantity == 0 }">
              <div>{{ props.item.orderID }}</div>
            </td>
            <td v-if="!accountId" class="cl2 text-center"
              :class="{ 'opacity-row': props.item.status == 'canceled' && props.item.executed_quantity == 0 }">
              <span class="text-account" @click="detailAccount(props.item)">{{ props.item.accountID }}</span>
              <span class="text-account" @click="detailAccount(props.item)">{{ props.item.user_id }}</span>
            </td>
            <td class="cl3"
              :class="{ 'opacity-row': props.item.status == 'canceled' && props.item.executed_quantity == 0 }">
              <div>{{ props.item.coin | uppercase }}/{{ props.item.currency | uppercase }}</div>
            </td>
            <td class="cl4"
              :class="{ 'opacity-row': props.item.status == 'canceled' && props.item.executed_quantity == 0 }">
              <div :class="{ buy: props.item.trade_type === 'buy', sell: props.item.trade_type === 'sell' }">{{
          props.item.trade_type |
          tradeType }}</div>
            </td>
            <td class="cl5"
              :class="{ 'opacity-row': props.item.status == 'canceled' && props.item.executed_quantity == 0 }">{{
          props.item | order_type }}</td>
            <td class="cl6"
              :class="{ 'opacity-row': props.item.status == 'canceled' && props.item.executed_quantity == 0 }">
              <div>{{ props.item.executed_quantity | convertToBigNumber | formatCurrencyAmountDecimal(8, "0") }}</div>
              <div>{{ props.item.quantity | convertToBigNumber | formatCurrencyAmountDecimal(8, "0") }}</div>
            </td>
            <td v-if="props.item.base_price" class="cl7"
              :class="{ 'opacity-row': props.item.status == 'canceled' && props.item.executed_quantity == 0 }">
              <span>{{ props.item.base_price | toNumber | formatCurrencyAmount(8, "0") }}</span>
            </td>
            <td v-else class="cl7"
              :class="{ 'opacity-row': props.item.status == 'canceled' && props.item.executed_quantity == 0 }">
              <span>--</span>
            </td>
            <td class="cl8"
              :class="{ 'opacity-row': props.item.status == 'canceled' && props.item.executed_quantity == 0 }">
              <span v-if="props.item.type == 'market' || props.item.type == 'stop_market'">Market</span>
              <span v-else>{{ props.item.price | toNumber |
          formatCurrencyAmount(8, "0") }}</span>
            </td>
            <td class="cl9 text-left"
              :class="{ 'opacity-row': props.item.status == 'canceled' && props.item.executed_quantity == 0 }">
              <div>{{ props.item.time | formatTimeStamp('YYYY-MM-DD') }}</div>
              <div>{{ props.item.time | formatTimeStamp('HH:mm:ss') }}</div>
            </td>

            <td class="cl10"
              :class="{ 'opacity-row': props.item.status == 'canceled' && props.item.executed_quantity == 0 }">
              <div v-if="props.item.status == 'canceled'" class="status cancel-status">{{ props.item | order_status }}
              </div>
              <div v-else-if="props.item.executed_quantity == props.item.quantity" class="status filled-status">{{
          props.item | order_status }}</div>
              <div v-else-if="props.item.status == 'pending'" class="status pending-status">{{ props.item | order_status
                }}</div>
              <div v-else-if="props.item.status == 'new'" class="status new-status">{{ props.item | order_status }}</div>
              <div v-else-if="props.item.status == 'open'" class="status open-status">{{ props.item | order_status }}
              </div>
              <div v-else class="status partial-status">{{ props.item | order_status }}</div>
            </td>
            <td class="cl11"
              :class="{ 'opacity-row': props.item.status == 'canceled' && props.item.executed_quantity == 0 }">
              <div class="action-wrap">
                <img class="btn-icon" src="@/assets/images/icon/cancel-icon.svg" width="20" @click="cancelOrder(props.item.orderID)" />
                <img class="btn-icon" src="@/assets/images/icon/detail-icon.svg" width="20" @click="handleOpen(props.item)" />
              </div>

            </td>
          </tr>
        </template>
      </data-table-4>
    </div>
    <modal name="orderDetailModal" :title="$t('orders.order_history.trade_history')">
      <div slot="body" class="body-popup" style="font-size: 10pt">
        <data-table :getData="getTradeHistory" :column="9" class="scroll">
          <th data-sort-field="order_Id" class="text-c">{{ $t('orders.order_history.order_id') }}</th>
          <th data-sort-field="created_at">{{ $t('orders.order_history.date') }}</th>
          <th data-sort-field="Email">{{ $t('orders.order_history.email') }}</th>
          <th data-sort-field="coin">{{ $t('orders.order_history.pair') }}</th>
          <th data-sort-field="transaction_type">{{ $t('orders.order_history.side') }}</th>
          <th data-sort-field="price">{{ $t('orders.order_history.price') }}</th>
          <th data-sort-field="quantity">{{ $t('orders.order_history.filled') }}</th>
          <th data-sort-field="Fee">{{ $t('orders.order_history.fee') }}</th>
          <th data-sort-field="amount"><span>{{ $t('orders.order_history.total') }}</span></th>
          <template slot="body" slot-scope="props">
            <tr>
              <td class="f-td">
                <span>{{ props.item.order_Id }}</span>
              </td>
              <td>{{ props.item.created_at | timestampToDate }}</td>
              <td>
                <span class="text-wrap">{{ props.item.Email }}</span>
              </td>
              <td>{{ props.item.coin | uppercase }}/{{ props.item.currency | uppercase }}</td>
              <td v-if="props.item.transaction_type == 'buy'" class="type-buy">{{ props.item.transaction_type |
          tradeType
                }}</td>
              <td v-else class="type-sell">{{ props.item.transaction_type | tradeType }}</td>
              <td>
                <span class="text-wrap"
                  v-if="props.item.type == 'market' || props.item.type == 'stop_market'">Market</span>
                <span class="text-wrap" v-else>{{ props.item.price | convertToBigNumber |
          formatCurrencyAmount(props.item.currency, "0") }}</span>
              </td>
              <td>{{ props.item.quantity | formatCurrencyAmount(props.item.coin) }}</td>
              <td>
                <span>{{ props.item.Fee | formatCurrencyAmount(props.item.coin, '0') }}</span>
              </td>
              <td>{{ props.item.amount | formatCurrencyAmount(props.item.coin) }}</td>
            </tr>
          </template>
        </data-table>

      </div>
      <div slot="footer"></div>
    </modal>
    <modal class="cancelOrder_modal" name="cancelOrder" :title="$t('modal.confirm')">
      <div slot="body" class="body-popup">
        {{ $t('orders.open_order.cancel_modal_content') }}
      </div>
    </modal>
    <modal class="kyc_modal" :enableClose="enableClose" name="loadingUpload">
      <div slot="body">
        <vue-loading type="bars" v-if="isLoading" color="#2DAC91" :size="{ width: '60px', height: '80px' }">
        </vue-loading>
      </div>
    </modal>
    <right-slide :dataDetail="dataDetail" :rightSlide="rightSlide" :onClose="handleClose" />

  </div>
</template>

<script>
import rf from "../../lib/RequestFactory";
import Modal from "../../components/Modal";
import DatePicker from "vuejs-datepicker";
import moment from "moment";
import COMMON_CONST from "../../common/Const";
import SelectBox from "../../components/SelectBox";
import DataTable4 from "../../components/DataTable4";
import RightSlide from "../../components/RightSlide";

export default {
  components: {
    Modal,
    DatePicker,
    SelectBox,
    DataTable4,
    RightSlide
  },
  props: {
    accountId: {
      type: String
    }
  },
  data() {
    return {
      titlePage: window.i18n.t("menu.spot.open_order"),
      startDate: moment()
        .subtract(6, "day")
        .toDate(),
      endDate: new Date(),
      coin: "",
      filters: {
        coin: window.i18n.t('order.order_history.all'),
        currency: "",
        tradeType: "",
        status: "",
        searchKey: ""
      },
      coins: [],
      idDetail: null,
      coinMasters: [],
      isShowCoinOption: false,
      trades: [],
      tradeType: [
        { id: "all", name: window.i18n.t('orders.open_order.all') },
        { id: "limit", name: window.i18n.t('limit') },
        { id: "stop_limit", name: window.i18n.t('stop_limit') },
        { id: "stop_maker", name: window.i18n.t('stop_maker') },
      ],
      statusType: [
        { id: "all", name: window.i18n.t('status.all') },
        { id: "pending", name: window.i18n.t('status.pending') },
        { id: "partial", name: window.i18n.t('status.partial') },
        { id: "open", name: window.i18n.t('status.open') }
      ],
      tradeTypeId: null,
      markets: [],
      rightSlide: false,
      dataDetail: null,
      isLoading: false,
      enableClose: false
    };
  },
  watch: {
    accountId: function (newVal) {
      console.log(newVal)
    }
  },
  methods: {
    search() {
      let filterParams = {
        start_date: moment(this.startDate)
          .startOf("day")
          .format("x"),
        end_date: moment(this.endDate)
          .endOf("day")
          .format("x")
      };
      if (this.startDate > this.endDate && this.startDate.toDateString() != this.endDate.toDateString()) {
        this.$toastr("error", this.$t("common.date.warning"));
        return;
      }
      if (this.filters.searchKey) {
        filterParams.search_key = this.filters.searchKey;
      }
      if (this.filters.coin) {
        filterParams.coin = this.filters.coin === window.i18n.t('order.order_history.all') ? '' : this.filters.coin;
      }
      if (this.filters.currency && this.filters.currency.id != '') {
        filterParams.currency = this.filters.currency.id;
      }
      if (this.filters.tradeType && this.filters.tradeType.id != '') {
        filterParams.type = this.filters.tradeType.id;
      }
      if (this.filters.status && this.filters.status.id != '') {
        filterParams.status = this.filters.status.id;
      }
      this.$refs.datatable.filter(filterParams);
    },
    refresh() {
      // this.requestCoinList();
      this.getOptionData();
      this.resetInput();
      this.$refs.datatable.refresh();
    },
    downloadExcel() {
      let filterParams = {
        start_date: moment(this.startDate)
          .startOf("day")
          .format("x"),
        end_date: moment(this.endDate)
          .endOf("day")
          .format("x")
      };
      if (this.startDate > this.endDate && this.startDate.toDateString() != this.endDate.toDateString()) {
        this.$toastr("error", this.$t("common.date.warning"));
        return;
      }
      if (this.filters.searchKey) {
        filterParams.search_key = this.filters.searchKey;
      }
      if (this.filters.coin) {
        filterParams.coin = this.filters.coin;
      }
      if (this.filters.currency && this.filters.currency.id != '') {
        filterParams.currency = this.filters.currency.id;
      }
      if (this.filters.tradeType && this.filters.tradeType.id != '') {
        filterParams.type = this.filters.tradeType.id;
      }
      if (this.filters.status && this.filters.status.id != '') {
        filterParams.status = this.filters.status.id;
      }

      rf.getRequest('OrderRequest').exportData(filterParams).then(res => {
        if (res?.success) {
          window.open(`${process.env.VUE_APP_API_URL}/${res.data.path}`, '_blank')
        }
      })
    },
    resetInput() {
      this.filters = Object.assign(this.filters, {
        coin: window.i18n.t('order.order_history.all'),
        currency: "",
        tradeType: "",
        searchKey: "",
        status: ""
      });
      this.startDate = moment()
        .subtract(6, "day")
        .toDate();
      this.endDate = new Date();

      $(".datatable").find("th").attr("data-sort-order", "");
    },
    onSearchCoin() {
      this.coins = _.filter(this.coinMasters, item => {
        return _.isMatch(item.toUpperCase(), this.filters.coin.toUpperCase());
      });
    },
    onSelectCoin(coin) {
      this.isShowCoinOption = false;
      this.filters.coin = coin === window.i18n.t('order.order_history.all') ? window.i18n.t('order.order_history.all') : coin.toUpperCase();
    },
    clickOut() {
      this.isShowCoinOption = false;
    },
    showCoinOption() {
      this.isShowCoinOption = true;
      this.coins = this.coinMasters;
    },
    getData(params) {
      let defaultParams = {
        ...params,
        start_date: moment(this.startDate)
          .startOf("day")
          .format("x"),
        end_date: moment(this.endDate)
          .endOf("day")
          .format("x"),
        size: params?.limit || 10
      };
      delete defaultParams.limit;

      if (this.accountId) return this.getOpenOrderSpot(defaultParams);

      return rf
        .getRequest("OrderRequest")
        .getOrdersPending(defaultParams);
    },
    getOpenOrderSpot(params) {
      const response = rf.getRequest("UserRequest").getOpenOrderSpot(this.accountId, params);
      response.then(res => {
        this.totalRecords = res.data.total;
      })
      return response;
    },
    /*
    getMarkets() {
      return window._.map(COMMON_CONST.MARKETS, item => {
        return { id: item, name: item.toUpperCase() };
      });
    },
    */
    requestCoinList() {
      rf.getRequest("CoinSettingRequest")
        .getCoinList()
        .then(res => {
          this.coinMasters = window._.map(res.data, "coin");
        });
    },
    viewDetail(id, tradeType) {
      this.tradeTypeId = tradeType;
      this.idDetail = id;
      $("body").addClass("modal-open");
      CommonModal.show("orderDetailModal", {
        position: "center",
        mask: true,
        buttons: [
          {
            label: "OK",
            focused: true,
            style: {
              width: "140px",
              background: "rgb(0, 112, 192)"
            },
            callback: () => {
              CommonModal.hide("orderDetailModal");

            }
          }
        ]
      });
    },

    getTradeHistory(params) {
      let orderId = this.idDetail;
      params['trade_type'] = this.tradeTypeId;
      return rf.getRequest('OrderRequest').getTradeByOrder(orderId, params);
    },
    getOptionData() {
      this.tradeType = [{ id: '', name: window.i18n.t('order.order_history.all') }]
      this.statusType = [{ id: '', name: window.i18n.t('order.order_history.all') }]
      this.markets = [{ id: '', name: window.i18n.t('order.order_history.all') }]
      rf.getRequest('OrderRequest')
        .getOptionFilterOpenOrder('type')
        .then(res => {
          res?.data.map((item) => {
            this.tradeType.push({
              id: item,
              name: window.i18n.t(`common.order_type.${item}`)
            })
          })
        })
      rf.getRequest('OrderRequest')
        .getOptionFilterOpenOrder('status')
        .then(res => {
          res?.data.map((item) => {
            this.statusType.push({
              id: item,
              name: window.i18n.t(`common.order_status.${item}`)
            })
          })
        })
      rf.getRequest('OrderRequest')
        .getOptionFilterOpenOrder('currency')
        .then(res => {
          res?.data.map((item) => {
            this.markets.push({
              id: item,
              name: item.toUpperCase()
            })
          })
        })
      rf.getRequest('OrderRequest')
        .getOptionFilterOpenOrder('coin')
        .then(res => {
          this.coinMasters = [window.i18n.t('order.order_history.all'), ...res.data]
        })
    },
    customFormatter(date) {
      return moment(date).format('YYYY - MM - DD');
    },
    handleClose() {
      this.dataDetail = null;
      this.rightSlide = false;
    },
    handleOpen(data) {
      this.dataDetail = data;
      this.rightSlide = true;
    },
    handleCancel(data) {

    },
    detailAccount(row) {
      this.$router.push({
        path: '/account/detail',
        query: { id: row.user_id },
      });
    },
    cancelOrder(id) {
      this.showModalConfirm('cancelOrder', this.submitCancelOrder, id);
    },
    showModalConfirm(modalName, callback, params) {
      CommonModal.show(modalName, {
        position: 'center',
        mask: true,
        buttons: [
          {
            label: window.i18n.t('common.action.no'),
            class: "btn-no",
            style: {
              background: 'rgb(191, 191, 191);'
            },
            callback: () => {
              CommonModal.hide(modalName);
            }
          },
          {
            label: window.i18n.t('common.action.yes'),
            focused: true,
            class: "btn-yes",
            style: {
              background: 'rgb(0, 112, 192)'
            },
            callback: () => {
              CommonModal.hide(modalName);
              this.loadingUploadBegin();
              callback(params);
              this.loadingUploadFinish();
            }
          }
        ]
      });
    },
    submitCancelOrder(data) {
      rf.getRequest('OrderRequest').cancelOrdersPending(data).then(res => {
        if (res.success) {
          this.$toastr('success', this.$t('orders.open_order.cancel_successful'));
          this.search();
        }
      }).catch(error => {
        this.$toastr('error', this.$t('orders.open_order.cancel_failed'));
        console.error(error);
      });
      this.search();
    },
    loadingUploadBegin() {
      this.enableClose = false;
      this.isLoading = true;
      CommonModal.show('loadingUpload');
    },
    loadingUploadFinish() {
      CommonModal.hide('loadingUpload');
    },
  },
  mounted() {
    this.$emit("EVENT_PAGE_CHANGE", this);
    // this.requestCoinList();
    this.getOptionData();
  },
  created() {
    // rf.getRequest('AdminRequest').getPriceGroupCurrency().then(res => {
    //   try {
    //     this.markets.push({ id: "all", name: window.i18n.t('orders.open_order.all') });
    //     window._.map(res.data, item => {
    //       if (typeof item.currency !== 'undefined') {
    //         this.markets.push({ id: item.currency, name: item.currency.toUpperCase() });
    //       }
    //     });
    //   }
    //   catch (ex) {
    //     console.error(ex);
    //   }
    // });
  }
};
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.date-picker-input {
  display: inline-block;
  float: left;

  ::v-deep {
    input {
      padding: 0 5px 0px 36px;
      width: 135px !important;
      height: 32px;
      border: 1px solid #C7CBD3 !important;
      border-radius: 10px !important;
      background-image: url('@/assets/images/icon/date-time.svg') !important;
      background-color: $color-white !important;
      background-size: 12px !important;
      background-position: center left 12px !important;
      background-repeat: no-repeat !important;
      color: $dark-1;

      &:hover,
      &:active,
      &:focus {
        border-color: $color-jungle-green;
        z-index: 9;
      }
    }
  }
}

.no-left-radius {
  input {
    border-radius: 0px 3px 3px 0px;
    margin-left: -1px;
  }
}

.no-right-radius {
  input {
    border-radius: 3px 0px 0px 3px;
  }
}

.modal-content .modal-footer {
  text-align: center;
  margin-top: 30px;
}

#open-order {
  font-family: $font_family_root;

  .sc_search_select {
    .group_search_select {
      .button_drop_search {
        height: 32px;
        padding: 6px 10px 6px 10px;
        border-radius: 10px;
        font-size: $font-small;
        font-family: $font-family-roboto;
        background-color: $color-white;

        span {
          font-size: $font-small;
        }

        i.icon-arrow1 {
          margin-left: 5px;
          color: $color_dove_gray;
        }

        &:hover {
          border-color: $color-jungle-green;

          i.icon-arrow1 {
            color: $color-jungle-green;
          }
        }
      }

      .box_list_search_select {
        .list_search_select {
          border-radius: 10px;

          li {
            font-family: $font-family-roboto;
            font-size: $font-small;
            padding: 6px 12px;

            &:hover,
            &:active,
            &:focus {
              color: $color-jungle-green;
            }
          }
        }
      }
    }
  }

  .modal-dialog {
    min-width: 400px;
    position: relative;
    top: -10%;
  }

  .modal-content {
    text-align: center;

    .modal-footer {
      text-align: center;
      margin-top: 30px;
    }

    .modal-header {
      padding: 30px 16px 16px 20px;

      .modal-title {
        font-family: Roboto-Medium;
        font-size: 18px;
        font-weight: 500;
        color: $color-grey-dark;
        text-transform: none;
      }
    }

    .modal-body {
      padding: 0;
    }

    .btn-no {
      background-color: $color-bright-gray;
      border-color: $color-bright-gray;
      color: $color-black;

      &:hover {
        opacity: 0.7;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
@import "@/assets/sass/variables";

// .min_85 {
//   min-width: 85px;
// }
// .min_110 {
//   min-width: 110px;
// }
.w_125 {
  width: 125px;
}

.item_email_user {
  display: inline-block;
  float: left;
  position: relative;

  .txt_email_user {
    display: block;
    max-width: 65px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .tooltip_email_user {
    position: absolute;
    top: 0px;
    left: 0px;
    line-height: 20px;
    padding: 5px 20px;
    left: 100%;
    background-color: $color_white;
    white-space: nowrap;
    width: auto;
    z-index: 10;
    font-size: $font_root;
    font-weight: 500;
    color: $color_mine_shaft;
    transition: 0.5s;
    display: none;
    box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.4);

    &:after {
      right: 100%;
      top: 50%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-color: rgba(136, 183, 213, 0);
      border-right-color: $color_white;
      border-width: 5px;
      margin-top: -5px;
    }
  }

  &:hover {
    .tooltip_email_user {
      display: block;
      transition: 0.5s;
    }
  }
}

.text-left {
  text-align: left;
}

.date-text {
  font-size: $font-root;
  color: $color-grey-dusty;
  font-family: $mulish-regular;
  margin-bottom: 7px;
  padding-left: 5px;
}

body.modal-open {
  overflow: hidden;
}

.body-popup {
  max-height: 400px;
  overflow-y: auto;

  table {
    thead {
      th:last-child {
        text-align: right;
        padding: 5px 20px 0px 15px !important;

        span {
          float: right;
        }
      }

      th {
        // text-align: center;
        padding: 5px 0px 0px 15px !important;
        color: $color_grey;
      }
    }

    tbody {
      tr {
        td:last-child {
          text-align: right;
          padding-right: 18px !important;
        }

        td:first-child {
          min-width: 82px;
        }

        td:nth-child(2) {
          min-width: 170px;
        }

        td:nth-child(3) {
          min-width: 250px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        td:nth-child(4) {
          min-width: 120px;
        }

        td:nth-child(5) {
          min-width: 70px;
        }

        td:nth-child(6) {
          min-width: 90px;
        }

        td:nth-child(7) {
          min-width: 100px;
        }

        td:nth-child(8) {
          min-width: 120px;
        }

        td:nth-child(9) {
          min-width: 100px;
        }

        td {
          // text-align: center;
          padding-top: 5px;

          &.type-buy {
            color: $color-jungle-green;
          }

          &.type-sell {
            color: $color_red_text;
          }
        }
      }
    }
  }
}

.empty-data {
  text-align: center;
  padding-top: 15px !important;
  color: $color_grey;
  font-size: 14px;
  background-color: $color_white;
}

.opacity-row {
  opacity: 0.4;
}

.text-wrap {
  word-wrap: break-word;
}

.checkbox {
  margin-top: 0px;
  display: inline-block;
  font-size: 14px;
  margin-bottom: 0px;
  font-weight: normal;
  height: 30px;
  line-height: 30px;

  input[type="checkbox"] {
    display: none;
  }

  span {
    position: relative;
    content: "";
    display: inline-block;
    border: 1px solid $color_alto;
    width: 16px;
    height: 16px;
    top: 3px;
    left: 8px;
    border-radius: 2px;
    margin-right: 8px;
    position: relative;
    background-color: $color_white;
  }

  input:checked {
    &+span {
      &:after {
        opacity: 0.9;
        content: "";
        position: absolute;
        width: 14px;
        height: 7px;
        background: transparent;
        top: 2px;
        left: 0px;
        border: 3px solid #0090eb;
        border-top: none;
        border-right: none;
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
      }
    }
  }
}

.open-order {
  .filter-container_order {
    margin: 10px 0;
    margin-bottom: 24px;
    display: flex;
    flex-wrap: wrap;

    .filter-item {
      float: left;
      margin: 0 5px;

      &:nth-child(3) {
        width: 180px;
      }

      &:nth-child(4) {
        width: 170px;
      }

      &:nth-child(5) {
        width: 120px;
        display: block;
      }

      &:nth-child(6) {
        display: flex;
        height: 100%;
        align-items: end;
      }

      &:nth-child(7) {
        display: flex;
        height: 100%;
        align-items: end;
      }

      input {
        margin-top: 27px;
        display: inline-block;
        width: 145px;

        &:hover,
        &:active,
        &:focus {
          border-color: $color-jungle-green;
        }
      }

      select {
        width: 65px;
        display: inline-block;
        font-family: $font-family-roboto;
        font-size: $font_root;

        &:hover,
        &:active,
        &:focus {
          border-color: $color-jungle-green;
        }
      }

      button {
        margin: 0 5px;
        width: auto;
        margin-top: 27px;
        height: 32px;
        font-size: $font-smaller;
        font-family: $font-family-roboto-bold;
        border-radius: 10px;
      }

      .btn-reset {
        font-size: $font-smaller;
        border-radius: 3px;
        border: 1px solid $color-caribbean-green;
        width: 80px;
        color: $color_white;
        text-transform: uppercase;
        background-color: $color-caribbean-green;
        font-weight: 500;
        border-radius: 10px;

        &:hover {
          background-color: $color-aquamarine;
          border-color: $color-aquamarine;
        }

        img {
          margin-right: 4px;
          margin-top: -2px;
        }
      }

      .btn-primary {
        padding: 0;
        background-color: $color-caribbean-green;
        font-size: $font-smaller;

        &:hover {
          background-color: $color-aquamarine;
          border-color: $color-aquamarine;
        }
      }

      .coin-selector {
        width: 75px;
        position: relative;

        .coin-input {
          display: inline-block;
          margin: 0 5px;
          margin-right: 0px;
          border-radius: 10px !important;
          background-color: $color-white;
          height: 32px;
          width: 100%;
        }

        .coin-option {
          background-color: $color-white;
          max-height: 210px;
          display: block;
          overflow-y: auto;
          margin-top: 5px;
          position: absolute;
          width: 79px;
          left: 5px;
          z-index: 10;
          padding: 12px 0px;
          box-shadow: 0px 3px 12px rgba(49, 49, 49, 0.51);
          border-radius: 10px;

          &:after {
            bottom: 100%;
            left: 50%;
            border: solid transparent;
            content: " ";
            display: block;
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
            border-color: rgba(136, 183, 213, 0);
            border-bottom-color: $color_white;
            border-width: 5px;
            margin-left: -5px;
          }

          .option {
            display: block;
            width: 100%;
            line-height: 20px;
            cursor: pointer;
            padding: 3px 14px;
            overflow: hidden;

            &:hover {
              color: $color-jungle-green;
            }
          }
        }
      }

      .form-control {
        background: $color-white-smoke;
        height: 32px;
        font-size: 13px;
        padding-left: 11px;
        padding-right: 0;
        border-radius: 10px !important;
      }
    }
  }

  .text-pair {
    padding-left: 10px;
  }

  .date-picker-input {
    display: inline-block;
    float: left;

    ::v-deep {
      input {
        padding: 0 5px 0px 36px;
        width: 135px !important;
        height: 32px;
        border: 1px solid #C7CBD3 !important;
        border-radius: 10px !important;
        background-image: url('@/assets/images/icon/date-time.svg') !important;
        background-color: $color-white !important;
        background-size: 12px !important;
        background-position: center left 12px !important;
        background-repeat: no-repeat !important;
        color: $dark-1;

        &:hover,
        &:active,
        &:focus {
          border-color: $color-jungle-green;
          z-index: 9;
        }
      }
    }
  }

  .bw-element {
    float: left;
    font-size: 15px;
    margin-left: 10px;
    height: 32px;
    line-height: 32px;
    margin-right: 5px;
    color: $color-grey-dusty;
  }
}

.datatable {
  button.btnForm.bt-action {
    padding-left: 5px !important;
    padding-right: 5px !important;
    font-weight: 400 !important;
  }
}

.buy {
  color: $text-color-jade;
}

.sell {
  color: $text-color-red;
}

.icon-notfound {
  font-size: 40px;
  padding-right: 15px;
}

.toolbar-element {
  display: inline-block;
  float: left;
  width: 80px;
}

.toolbar-element2 {
  display: inline-block;
  float: left;
  width: 100%;
}

td.cl1 {
  min-width: 60px;
}

td.cl2 {
  min-width: 105px;

  .text-account {
    text-decoration: underline;
    color: $color-blue-custom;
    cursor: pointer;
  }
}

td.cl3 {
  min-width: 110px;
}

td.cl4 {
  min-width: 85px;
}

td.cl5 {
  min-width: 98px;
}

td.cl6,
td.cl7,
td.cl8,
td.cl9 {
  min-width: 115px;
}

td.cl10 {
  min-width: 120px;
}

td.cl11 {
  min-width: 100px;
}

.action-wrap {
  display: flex;
  gap: 12px;

  .btn-icon {
    cursor: pointer;
  }
}

.status {
  min-height: 32px;
  padding-left: 4px;
  padding-right: 4px;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $color-wood-smoke;
  border-radius: 4px;

  &.pending-status {
    background-color: $color-pending-status;
  }

  &.open-status {
    background-color: $color-open-status;
  }

  &.partial-status {
    background-color: $color-partial-status;
  }

  &.filled-status {
    background-color: $color-filled-status;
  }

  &.cancel-status {
    background-color: $color-cancel-status;
  }
}
</style>
