import '@/common/bootstrap'
import Vue from 'vue'
import VueBroadcast from '@/common/VueBroadcast'
import GlobalSocket from '@/common/GlobalSocket'
import VeeValidate, { Validator } from 'vee-validate'
import App from './App.vue'
import DataTable from './components/DataTable'
import '@/common/Filters.js'
import ClickOutside from 'vue-click-outside'
import store from './store/index.js'
import CKEditor from '@ckeditor/ckeditor5-vue2'
import VueToastr from '@deveodk/vue-toastr'
import '@deveodk/vue-toastr/dist/@deveodk/vue-toastr.css'
import VModal from 'vue-js-modal'
import RouterUtils from './common/RouterUtils'
import Toasted from 'vue-toasted'
import DataTable2 from './components/DataTable2'
import DataTable3 from './components/DataTable3'
import DataTableFutures from './components/DataTableFutures'
import DataTableOrderBook from "./components/DataTableOrderBook";
import DataTableAffiliate from "./components/DataTableAffiliate.vue";
import DataTableActivityHistory from "./components/DataTableActivityHistory.vue";

import VueLoading from 'vue-loading-template'
import '@/common/vailidators'
import '@/common/custom_rules'
import CurrencyInput from './components/CurrencyInput.vue'
import BigNumber from 'bignumber.js'
import { VTooltip, VPopover } from 'v-tooltip'
import router from '@/routes'

Vue.use(VueLoading)
Vue.use(Toasted)
Vue.use(CKEditor)

Vue.use(VModal, { dialog: true })

Vue.use(VueToastr, {
  defaultPosition: 'toast-bottom-right',
  defaultType: 'info',
  defaultTimeout: 2000,
})

Vue.use(VueBroadcast)
Vue.use(VeeValidate)

Vue.component('data-table', DataTable)
Vue.component('data-table2', DataTable2)
Vue.component('data-table3', DataTable3)
Vue.component('data-table-futures', DataTableFutures)
Vue.component('data-table-affiliate', DataTableAffiliate)
Vue.component("data-table-order-book", DataTableOrderBook);
Vue.component('data-table-activity-history', DataTableActivityHistory)
Vue.component('currency-input', CurrencyInput)
Vue.directive('click-outside', ClickOutside)
Vue.directive('tooltip', VTooltip)
Vue.component('v-popover', VPopover)

Vue.mixin({
  methods: {
    getQuantityTransaction(amount, fee) {
      amount = parseFloat(amount)
      fee = parseFloat(fee)
      amount = new BigNumber(`${amount}`).absoluteValue()
      return new BigNumber(amount).minus(fee).toString()
    },
  },
})

const i18n = window.i18n
window.GlobalSocket = new GlobalSocket()
router.beforeEach((to, from, next) => {
  window.i18n.locale = localStorage.getItem("lang") || document.documentElement.lang

  if (to.path === 'login') {
    return next()
  }

  RouterUtils.validRouter(to).then((availableRoutePath) => {
    // if (to.name === RouterUtils.ROUTER_NOT_FOUND_NAME) {
    //   return next();
    // }
    // if (availableRoutePath) {
    //   // Sub Route must be has prefix router of parent.
    //   if (!to.path.includes(availableRoutePath)) {
    //     return next({path: availableRoutePath});
    //   }
    // } else {
    //   return next({ name: RouterUtils.ROUTER_NOT_FOUND_NAME });
    // }

    return next()
  })
})

Vue.config.productionTip = false

window.app = new Vue({
  i18n,
  router,
  store,
  created() {
    this.$store.dispatch('init')
    Validator.localize({
      en: {
        messages: {
          required: (field) =>
            window.i18n.t('validation.required', { attribute: field }),
        },
      },
    })
  },
  render: (h) => h(App),
}).$mount('#app')

