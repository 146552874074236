<template>
  <div class="content_usersetting mg_bot50 clearfix" id="user_settings_fee">

<!--    <div class="group_head_airdrop_setting ml-5">-->
<!--      <span class="title_item_airdrop_setting ">{{ $t('account_setting.enable_wallet_airdrop') }}</span>-->
<!--      <label class="switch">-->
<!--        <input type="checkbox" v-model="isWalletFeeActive" @click="changeStatusFee">-->
<!--        <span>-->
<!--          <i></i>-->
<!--        </span>-->
<!--      </label>-->
<!--    </div>-->
    <div class="filter_container clearfix">
      <!-- Select Markets -->
      <div class="clearfix col-xs-4 market-select-area">
        <span class="title_item select-market-text">
          {{ $t('circuit_breaker_setting.select_market') }}
        </span>
        <select-box v-model="currency" class="select-market" :options="markets"/>
      </div>

      <div class="search_box form-group col-xs-6">
        <input type="text" placeholder="Search" v-on:keyup.enter="search" @keyup="debounceSearch" class="form-control search_input" name="searchKey" v-model="searchKey"/>
      </div>

      <div class="clearfix"></div>

    </div>

    <div class="row">
      <div class="col-xs-4 col-sm-3 col-left-user">
        <div class="box_table_UserSetting_right">          
          <table class="table table_UserSetting_right " :class="balancesDefault ? 'table_hover' :''" :limit="10" :column="8">
            <thead>
            <tr>
              <th class="text-left" >{{ $t('enable_fee.no') }}</th>
              <th class="text-left" >{{ $t('enable_fee.currency') }}</th>
              <th class="text-left" >{{ $t('enable_fee.coin') }}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, key) in coinList" :key="key" @click="getDataUserTable(item)" :class="{'active': item.currency == selectedCoinPair.currency &&  item.coin == selectedCoinPair.coin}">
              <td class="text-left">
                <div class="text-bold-coin">{{ key + 1 }}</div>
              </td>
              <td class="text-left">
                <div class="item_email_setting font14">
                  {{ (item.currency || '--') | uppercase }}
                </div>
              </td>
              <td class="text-left">
                {{ (item.coin || '--') | uppercase }}
              </td>

            </tr>
            </tbody>
          </table>


        </div>
      </div>

      <div class="col-xs-8 col-sm-9 col-right-user">
        <div class="datatable box_UserSetting_right">
          <data-table :getData="getData" :totalUser="numberOfUser" ref="datatable" :limit="10" :column="5" class="scroll height-custom">
            <th class="text-left cl_170" data-sort-field="email">{{ $t('user.email') }}</th>
            <th class="text-left cl_120">{{ $t('enable_fee.currency') }}</th>
            <th class="text-left cl_110">{{ $t('enable_fee.coin') }}</th>
            <th class="text-left cl_120">
              {{ $t('enable_fee.fee_charge') }}
            </th>
            <th class="text-center cl_120">
              <button @click="addAccount()" class="btn btn-add" :disabled="!selectedCoinPair.currency || !selectedCoinPair.coin">
                <i class="icon-plus"></i>
                {{ $t('account_setting.add_account') }}
              </button>
            </th>

            <template slot="body" slot-scope="props">
              <tr v-bind:class="{inactive: props.item.status === 'inactive'}">

                <td class="text-left font14">
                  <div class="item_email_setting font14">
                    <span class="txt_email_setting font14">{{ props.item.email }}</span>
                    <span class="tooltip_email_setting font14">{{ props.item.email }}</span>
                  </div>
                </td>
                <td class="text-left font14">{{ props.item.currency | uppercase }}</td>
                <td class="text-left font14">{{ props.item.coin | uppercase }}</td>
                <td class="text-left cl_120">
                  <!--<button class="btn"-->
                    <!--:class="props.item.enable_fee ? 'btn_enable' : 'btn_disable'"-->
                    <!--@click.stop="onClickedUpdateUserSetting(props.item)"-->
                  <!--&gt;-->
                    <!--{{ props.item.enable_fee ? $t('common.action.enable') : $t('common.action.disable') }}-->
                  <!--</button>-->
                  <span class="" :class="props.item.enable_fee ? 'status-enabled' : 'status-disabled'">
                    {{ props.item.enable_fee ? $t('common.action.yes') : $t('common.action.no') }}
                  </span>
                </td>
                <td class="text-center">
                  <button class="btn btn-close-table" @click="confirmDeleteColumn(props.item)"><i class="icon-close2"></i></button>
                </td>

              </tr>
            </template>
          </data-table>
        </div>
      </div>

      <modal name="addUserEnableFee" width="450" :disabled="checkDisableBtn">
        <div slot="body" class="body-popup add-user-enable-fee">
          <div class="form-group-detail">


            <div class="group-detail-form-group">
              <label class="group-detail-form-label">{{ $t('airdrop_setting.email') }}</label>
              <input class="group-detail-form-value" maxlength="190"
                     id="input-airdrop-title" type="text" name= "email" v-model="newUser.email"
                     data-vv-validate-on=""
                     :data-vv-as="$t('airdrop_setting.email1')" v-validate="'required|email'"
                     v-bind:class="{'error-modal': errors.has('email')}"
              />
              <div class="clearifx"></div>
              <div class="error-box">
                <span v-show="showErrorModal && errors.has('email')" class="f-left is-error">{{ errors.first('email') }}</span>
              </div>
              <div class="form-check">
                <input type="checkbox" v-model="newUser.inThisMarket" @change="checkedCreateNewUser($event, 'inThisMarket')" id="inThisMarket">
                <label class="form-check-label" for="inThisMarket">{{ $t('enable_fee.this_market') }}</label>
              </div>
              <div class="form-check">
                <input type="checkbox" v-model="newUser.allMarket" @change="checkedCreateNewUser($event, 'allMarket')" id="allMarket">
                <label class="form-check-label" for="allMarket"> {{ $t('enable_fee.all_market')}}</label>
              </div>
            </div>

            <!--<div class="group-detail-form-group">-->
              <!--<label class="group-detail-form-label">{{ $t('airdrop_setting.lock_period') }}</label>-->
              <!--<currency-input class="group-detail-form-value" :value="newUser.period" v-model="newUser.period" v-bind:class="{ 'error-modal': errors.has('period') }"/>-->
              <!--<div class="clearifx"></div>-->
              <!--<div class="error-box">-->
                <!--<span v-show="errors.has('period')" class="f-left is-error">{{ errors.first('period') }}</span>-->
              <!--</div>-->
            <!--</div>-->


            <!--<div class="group-detail-form-group">-->
              <!--<label class="group-detail-form-label">{{ $t('airdrop_setting.unlock_percent_period') }}</label>-->
              <!--<currency-input :precision='2' class="group-detail-form-value" :value="newUser.unlock_percent" v-model="newUser.unlock_percent" v-bind:class="{ 'error-modal': errors.has('unlock_percent') }"/>-->
              <!--<div class="clearifx"></div>-->
              <!--<div class="error-box">-->
                <!--<span v-show="errors.has('unlock_percent')" class="f-left is-error">{{ errors.first('unlock_percent') }}</span>-->
              <!--</div>-->
            <!--</div>-->


          </div>
        </div>

      </modal>

    </div>
</div>
</template>

<script>
  import rf from '../../lib/RequestFactory';
  import BigNumber from 'bignumber.js';
  import Numeral from '../../lib/numeral';
  import SelectBox from '../../components/SelectBox';
  import Modal from "../../components/Modal";

  export default {
    components: {
      SelectBox,
      Modal,
    },
    data() {
      return {
        titlePage: this.$t('enable_fee.title'),
        searchKey: '',
        balances: [],
        balancesDefault: false,
        numberOfUser: 0,
        isChosen: false,
        markets: [],
        currency: {id: 'btc', name: 'BTC'},
        coinList: [],
        selectedCoinPair: {},
        hasError: false,
        showErrorModal: false,
        newUser: {},
        isWalletFeeActive: false,
        checkDisableBtn: true,
      }
    },
    watch: {
      currency(newValue) {
        this.$refs.datatable.$emit('DataTable:filter', {});
        this.getDataCoinList(newValue);
      }
    },
    methods: {
      trimNumber(number) {
        if(!number) return null;
        let zeroValue = '0';
        let numberOfDecimalDigits = 10;
        let format = numberOfDecimalDigits == 0 ?
                '0,0' :
                '0,0.[' + Array(numberOfDecimalDigits + 1).join('0') + ']';
        if (window._.isNil(zeroValue)) {
          zeroValue = '';
        }
        return Numeral(number).format(format);
      },
      changeStatusFee() {
        let params = {
          "enable_fee_amal" : !this.isWalletFeeActive,
        }
        rf.getRequest('AdminRequest').changeStatusFeeWallet(params).then(res =>{
          this.isWalletFeeActive = res.data.enable_fee_amal;
        })
      },
      showModal (config) {
        const type = config.type;
        const title = config.title;
        const content = config.content;
        const customContent = config.customContent;

        let btnCancelLabel = config.btnCancelLabel || this.$t('common.action.no');
        let btnConfirmLabel = config.btnConfirmLabel || this.$t('common.action.yes');
        if (config.noAction) {
          btnCancelLabel = null;
          btnConfirmLabel = null;
        }
        const onConfirm = config.onConfirm;
        const onCancel = config.onCancel;

        window.ConfirmationModal.show({
          type: type,
          title: title,
          content: content,
          customContent: customContent,
          btnCancelLabel: btnCancelLabel,
          btnConfirmLabel: btnConfirmLabel,
          onConfirm: onConfirm,
          onCancel: onCancel
        });
      },

      search() {
        this.$refs.datatable.$emit('DataTable:filter', {
          currency: this.selectedCoinPair.currency,
          coin: this.selectedCoinPair.coin,
          search_key: this.searchKey
        });
      },
      debounceSearch: _.debounce(function() {
        this.$refs.datatable.$emit('DataTable:filter', {
          currency: this.selectedCoinPair.currency,
          coin: this.selectedCoinPair.coin,
          search_key: this.searchKey
        });
      },1500),

      getData(params) {
        if (params.search_key) {
          return this.getUserListFromCoinPair(params);
        }

        if (params.currency && params.coin) {
          return this.getUserListFromCoinPair(params);
        }

        return this.resetDataUserTable();
      },

      getDataUserTable(params) {
        this.$refs.datatable.$emit('DataTable:filter', params);
        this.search_key = '';
      },

      resetDataUserTable() {
        // this.$refs.datatable.refresh();
        return new Promise((resolve, reject) => {
          return resolve({data: []});
        });
      },

      getUserListFromCoinPair(setting) {
        let currency = setting.currency;
        let coin = setting.coin;
        this.selectedCoinPair = {
          currency: currency,
          coin: coin,
        };

        let res = rf.getRequest('AdminRequest').getUserEnableFeeSetting(setting)
          .then(res => {
            if (!res.data || !res.data.data) return res;

            window._.map(res.data.data, currenItem => {
              let enableFee = false;
              if (currenItem.enable_fee == null || currenItem.enable_fee == 'enable') {
                enableFee = true;
              }
              currenItem.currency = setting.currency;
              currenItem.coin = setting.coin;
              currenItem.enable_fee = enableFee;
              currenItem.isWrite = false;
              currenItem.isReset = false;
            });

            return res;
          });

        return res;
      },

      getDataCoinList(params) {
        // Sample data:
        // coinList: [
        //   {
        //     id: '1',
        //     coin: 'BTC',
        //     currency: 'ETC',
        //   },
        // ],
        let newParams = {
          ...params,
          currency: this.currency.id
        };
        return rf.getRequest('AdminRequest').getEnableFeeSetting(newParams)
          .then(res => {
            let newCoinList = [];
            if (res.data) {
              newCoinList = res.data;
            }
            this.coinList = newCoinList;
            this.resetDataUserTable();
          });
      },
      
      // getBalances(id) {
      //   this.isChosen = true;
      //   this.balancesDefault =false;
      //   rf.getRequest('AdminRequest').getUserBalances({user_id: id}).then(res => {
      //       this.balances = res.data;
      //       this.balancesDefault = true;
      //   })
      //   .catch(e => {
      //       console.log(e)
      //   });
      // },

      // onClickedUpdateUserSetting(setting) {
      //   const title = setting && setting.otp_verified ? this.$t('user_setting.confirm_off_otp') : this.$t('user_setting.confirm_on_otp');
      //   this.showModal({
      //     type: 'confirm',
      //     title: title,
      //     onConfirm: () => {
      //       rf.getRequest('UserRequest').updateSettingOtp(setting.id, setting && !setting.otp_verified).then(res => {
      //         this.$toastr('success', window.i18n.t('user_setting.update_otp_msg'));
      //         setting.otp_verified = !setting.otp_verified;
      //       });
      //     }
      //   });
      // },

      // getTotalUser() {
      //   return rf.getRequest('UserRequest').getTotalUsers()
      //     .then(res => {
      //       this.numberOfUser = res.data;
      //     });
      // },


      addAccount() {
        this.errors.clear();
        this.newUser = {};
        this.checkDisableBtn = true;

        CommonModal.show("addUserEnableFee", {
          position: "add-group customModal",
          mask: true,
          buttons: [
            {
              label: this.$t('common.action.cancel'),
              focused: true,
              style: {
                width: "140px",
                background: "rgb(0, 112, 192)"
              },
              callback: () => {
                CommonModal.hide("addUserEnableFee");
              }
            },
            {
              label: this.$t('common.action.save'),
              focused: true,
              isDisableBtn: true,
              style: {
                width: "140px",
                background: "rgb(0, 112, 192)"
              },
              callback: () => {
                let item = this.newUser;
                this.createNewUser(item);
              }
            }
          ]
        });

      },

      checkedCreateNewUser(e, type) {
        if (type == 'inThisMarket') {
          this.newUser.allMarket = false;
          this.checkDisableBtn = e.target.checked ? false : true;
        } else if (type == 'allMarket') {
          this.newUser.inThisMarket = false;
          this.checkDisableBtn = e.target.checked ? false : true;
        }
      },

      async createNewUser(item) {
        let isValid = await this.validateModal();
        this.showErrorModal = false;
        if (!isValid) {
          this.showErrorModal = true;
          return ;
        }

        let params = {
          email: item.email,
          coin: this.selectedCoinPair.coin,
          currency: this.selectedCoinPair.currency,
          inThisMarket: item.inThisMarket,
          allMarket: item.allMarket
        }
        rf.getRequest('AdminRequest').createUserEnableFeeSetting(params).then(res => {

            CommonModal.hide("addUserEnableFee");
            this.$toastr('success', window.i18n.t('common.alert.create.success'));
            this.newUser = {};
            this.search();
          })
          .catch(e => {
            if (e.response.data.message) {
              this.$toastr('error', e.response.data.message);
            }
          })
      },

      async validateModal() {
        this.errors.clear();
        await this.$validator.validate('email');

        if (this.errors.count() > 0) {
          return false;
        }
        return true;
      },

      confirmDeleteColumn(item) {
        window.ConfirmationModal.show({
          type: 'primary',
          title: this.$t('airdrop_setting.confirm_remove'),
          btnCancelLabel: window.i18n.t('common.action.no'),
          btnConfirmLabel: window.i18n.t('common.action.yes'),
          onConfirm: () => {
            this.deleteColumn(item);
          }
        });
      },

      deleteColumn(item) {
        if ( (this.itemUser) &&item.user_id == this.itemUser.user_id) {
          this.itemUser = null;
          this.currentUser = false;
        }
        rf.getRequest('AdminRequest').deleteUserEnableFeeSetting(item).then(res =>{
            this.search();
            this.$toastr('success', window.i18n.t('enable_fee.remove_success'));
          })
          .catch(ex => {
            console.log(ex);
            this.$toastr('error', window.i18n.t('enable_fee.remove_fail'));
          });
      },

      onClickedUpdateUserSetting(setting) {
        const title = setting && setting.enable_fee ?
                        this.$t('enable_fee.user_setting.confirm_off') :
                        this.$t('enable_fee.user_setting.confirm_on');
        let params = {
            ...setting,
            'enable_fee': !setting.enable_fee,
        };

        this.showModal({
          type: 'confirm',
          title: title,
          onConfirm: () => {
            rf.getRequest('AdminRequest').updateUserEnableFeeSetting(params)
            .then(res => {
              setting.enable_fee = !setting.enable_fee;
              this.$toastr('success', window.i18n.t('enable_fee.user_setting.update_success'));
            }).catch(e => {
              this.$toastr('error', window.i18n.t('enable_fee.user_setting.update_fail'));
            });
          }
        });
      },

      initEmptyList() {
        let i = 0;
        this.coinList = [];
        while (i++ < 10) {
          this.coinList.push({});
        }
      },
      blockTabBrowser(e){
        if (e.key == 'Tab') {
          e.preventDefault();
        }
      },

      loadMarkets() {
        rf.getRequest('AdminRequest').getPriceGroupCurrency().then(res => {
          try {
            window._.map(res.data, item => {
              if (typeof item.currency !== 'undefined') {
                this.markets.push({ id: item.currency, name: item.currency.toUpperCase() });
              }
            });
          }
          catch (ex) {
            console.error(ex);
          }
        });
      },
      getFeeWalletSetting() {
        rf.getRequest('AdminRequest').getAllAirdropSetting().then(res => {
          if (res.data && res.data[0] && res.data[0].enable_fee_amal) {
            this.isWalletFeeActive = res.data[0].enable_fee_amal;

          }
        });
      }
    },
    created() {
      document.addEventListener('keydown', this.blockTabBrowser);
      this.loadMarkets();
      this.getDataCoinList({});
    },
    beforeDestroy() {
      document.removeEventListener('keydown',this.blockTabBrowser);
    },
    mounted() {
      // this.initEmptyList();
      this.$emit('EVENT_PAGE_CHANGE', this);
      this.getFeeWalletSetting();
    }
  }
</script>
<!--<style lang="scss">-->
  <!--.height-custom{-->
    <!--height: 100%;-->
    <!--width: 100%;-->
    <!--// overflow: hidden;-->
    <!--table{-->
      <!--// height : 445px;-->
      <!--// max-height: 445px;-->
    <!--}-->
  <!--}-->
  <!--.height-custom table{-->
    <!--width: 100%;-->
    <!--height: 100%;-->
    <!--overflow-y: hidden;-->
    <!--padding-right: 17px;-->
    <!--box-sizing: content-box;-->
  <!--}-->
  <!--.th-last{-->
    <!--padding: 0 !important;-->
  <!--}-->
<!--</style>-->
<style lang="scss" scoped>
@import "@/assets/sass/variables";
  .cl_address {
    width: 350px;
  }
  .cl_50 {
    width: 50px;
  }
  .cl_80 {
    width: 80px;
  }
  .cl_110 {
    width: 110px;
  }
  .cl_120 {
    max-width: 140px;
  }
  .cl_140 {
    max-width: 140px;
  }
  .cl_170 {
    max-width: 170px;
  }
  .font14 {
    font-size: $font-root !important;
  }
  .status-enabled {
    color: $color-jungle-green;
  }
  .status-disabled {
    color: $color_alizarin_crimson;
  }
  .content_modal_withdrawSetting {
    color: $color_mine_shaft;
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    line-height: $font_big_24;
  }
  .form-check {
    margin-top: 10px;
    & input {
      margin-right: 4px;
    }
  }
  .form-check-label {
    color: #666666;
    font-weight: normal;
    font-family: $font-family-roboto;
  }
  .title_item_airdrop_setting {
    font-size: 18px;
    color: #333333;
    font-family: "Roboto-Medium", sans-serif;
    line-height: 20px;
    margin-right: 12px;

  }
  .ml-5 {
    margin-bottom: 20px;
  }
  .switch {
      margin-bottom: 0px;
      display: inline-block;
      vertical-align: middle;
      cursor: pointer;
      input {
        display:none;
        &:checked +span {
          background: $color-jungle-green;
          text-align: left;
          &:before {
            content: "ON";
            color: $color_white;
            font-size: 12px;
          }
          i {
            left: 35px;
          }
          &:after {
            content: " ";
            color: $color_white;
          }
        }

      }
      span {
        display: inline-block;
        padding: 0px 6px 0 9px;
        width: 54px;
        height: 20px;
        line-height: 20px;
        border-radius: 11px;
        background: $color_grey_icon_light;
        position: relative;
        color: $color_white;
        font-size: 14px;
        text-align: right;
        &:after {
          content: "OFF";
          color: $color_white;
          line-height: 20px;
          font-size: 12px;
        }
        i {
          display: inline-block;
          width: 20px;
          height: 20px;
          border-radius: 100%;
          background: $color_white;
          position: absolute;
          left: 0;
          top: 0px;
          box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.5);
          -webkit-transition: all 0.3s ease-in-out;
          -moz-transition: all 0.3s ease-in-out;
          -ms-transition: all 0.3s ease-in-out;
          -o-transition: all 0.3s ease-in-out;
          transition: all 0.3s ease-in-out;
        }
      }
    }

  .content_usersetting {

    .btn_enable {
      background-color: transparent;
      text-transform: uppercase;
      width: 70px;
      height: 25px;
      line-height: 20px;
      padding: 0px 0px;
      text-align: center;
      font-size: $font-smaller;
      font-weight: 600;
      color: $color-caribbean-green;
      border: 1px solid $color-caribbean-green;
      border-radius: 3px;
      transition: 0.5s;
      &:hover {
        background-color: $color-aquamarine;
        border-color: $color-aquamarine;
        color: $color_white;
        transition: 0.5s;
      }
    }
    .btn_disable {
      background-color: transparent;
      text-transform: uppercase;
      width: 70px;
      height: 25px;
      line-height: 20px;
      padding: 0px 0px;
      text-align: center;
      font-size: $font-smaller;
      font-weight: 600;
      color: $color_alizarin_crimson;
      border: 1px solid $color_alizarin_crimson;
      border-radius: 3px;
      transition: 0.5s;
      &:hover {
        background-color: $color_alizarin_crimson;
        border-color: $color_alizarin_crimson;
        color: $color_white;
        transition: 0.5s;
      }
    }
    .btn_view {
      float: right;
      background-color: $color-aquamarine;
      text-transform: uppercase;
      width: 55px;
      height: 25px;
      line-height: 20px;
      padding: 0px 9px;
      text-align: center;
      font-size: $font-smaller;
      font-weight: 600;
      color: $color-white;
      border: 1px solid $color-aquamarine;
      border-radius: 3px;
      transition: 0.5s;
      &:hover {
        background-color: $color-aquamarine;
        border-color: $color-aquamarine;
        color: $color_white;
        transition: 0.5s;
      }
    }

    .filter_container {
      margin: 7px 0px 15px 0px;

      .title_item {
        color: $color-grey-dark;
        font-size: $font_root;
        font-family: $font-family-roboto;
        font-weight: 500;
        line-height: 30px;
        float: left;
        margin-right: 11px;
      }
      .search_box {
        padding-right: 0px;
        display: inline-block;
        float: right;
        width: 215px;
        max-width: 100%;
        margin-bottom: 0px;
        color: $color-grey-dusty;
        font-family: $font-family-roboto;
        .search_input {
          background-color: transparent;
          height: 28px;
          border: 1px solid $color_alto;
          padding: 4px 15px;
          line-height: 20px;
          width: 100%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          font-size: $font-small;
        }
      }
    }
    .btn_detail_user {
      float: right;
      background-color: transparent;
      text-transform: uppercase;
      width: 65px;
      height: 23px;
      line-height: 20px;
      padding: 0px 9px;
      text-align: center;
      font-size: $font-smaller;
      font-weight: 600;
      color: $color-caribbean-green;
      border: 1px solid $color-caribbean-green;
      border-radius: 20px;
      margin-left: 15px;
      transition: 0.5s;
      &:hover {
        background-color: $color-aquamarine;
        border-color: $color-aquamarine;
        color: $color_white;
        transition: 0.5s;
      }
    }

    .item_email_setting {
      display: inline-block;
      position: relative;
      vertical-align: middle;
      .txt_email_setting {
        display: block;
        min-width: 130px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .tooltip_email_setting, .tooltip_address_setting {
        position: absolute;
        top: 0px;
        line-height: 20px;
        padding: 5px 20px;
        left: 100%;
        background-color: $color_white;
        white-space: nowrap;
        width: auto;
        z-index: 10;
        font-size: $font-smaller;
        font-weight: 500;
        color: $color_mine_shaft;
        transition: 0.5s;
        display: none;
        box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.4);
        &:after {
          right: 100%;
          top: 50%;
          border: solid transparent;
          content: " ";
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
          border-color: rgba(136, 183, 213, 0);
          border-right-color: $color_white;
          border-width: 5px;
          margin-top: -5px;
        }
      }
      &:hover {
        .tooltip_email_setting, .tooltip_address_setting {
          display: block;
          transition: 0.5s;
        }
      }
      .tooltip_address_setting {
        top: 100%;
        left: 0;
        &:after {
          left: 25%;
          bottom: 100%;
          top: initial;
          border-bottom-color: $color_white;
          border-right-color: transparent;
        }
      }
    }
  }

  @media only screen and (min-width: 1399px) {
     .content_usersetting .item_email_setting  .txt_email_setting {
        max-width: 250px;
     }
  }

  .box_table_UserSetting_right {
    min-height: 439px;
    background-color: $color_white;
    // max-height: 440px;
    overflow-y: auto;
    overflow-x: auto;
  }
  .box_UserSetting_right {
    // min-height: 440px;
    // background-color: $color_white;
  }
  .detail_balance {
    text-overflow: ellipsis;
    width: 150px;
    overflow: hidden;
    vertical-align: middle;
  }
  .detail_available_balance {
    text-overflow: ellipsis;
    width: 180px;
    overflow: hidden;
    vertical-align: middle;
  }


  .add-user-enable-fee {
    .is-error {
      color: $color_red_text !important;
    }
    .error-box {
      display: block;
      float: left;
      overflow: hidden;
      width: 100%;
      span {
        font-family: $font-family-roboto-medium !important;
      }
    }
  }

  .btn-add {
    height: 22px;
    border-radius: 3px;
    background-color: $color-caribbean-green;
    border: 1px solid $color-caribbean-green;
    font-size: $font_mini_mini;
    letter-spacing: 0.77px;
    color: $color-white;
    text-align: center;
    padding: 1px 12px;
    padding-top: 2px;
    display: block;
    float: right;
    i {
      font-size: 8px;
      position: relative;
      top: 0px;
      margin-right: 4px;
    }
    &:hover {
      border-color: $color-aquamarine;
      background-color: $color-aquamarine;
    }
  }
  .btn-save-table,
  .btn-close-table,
  .btn-edit-table {
    width: 20px;
    height: 20px;
    text-align: center;
    line-height: 20px;
    padding: 0px;
    text-align: center;
    border: 0px;
    background-color: transparent;
    font-size: 16px;
    color: $color-jungle-green;
    margin-left: 7px;
    i {
      &:before {
        color: $color-jungle-green;
      }
    }
  }

  .form-group-detail {
    .group-detail-form-group {
      .group-detail-form-label{
        width: 100%;
        color: $color-grey-dusty;
        float: left;
        font-weight: normal;
        font-family: $font-family-roboto;
        margin-bottom: 0px;
        margin-top: 10px;
      }

      .group-detail-form-value {
        width: 100%;
        padding: 8px 16px;
        border: 1px solid #dee3eb;
        height: 35px;
        line-height: 35px;
        border-radius: 3px;
        font-family: $font-family-roboto;
        // font-weight: 600;
        &:focus {
          border-color: $color-jungle-green;
        }
      }
      #input-airdrop-title {
        &.error-modal {
          border: solid 1px $color-red-main;
          border-radius: 3px;
        }
      }
      .textarea_input {
        height: 120px;
      }
    }
    .error-box {
      display: block;
      float: left;
      overflow: hidden;
      width: 100%;
      span {
        font-family: $font-family-roboto-medium !important;
      }
    }
    .is-error {
      color: #ff5252 !important;
    }
  }

  .filter_container {
    margin: 10px 0px 25px 0px;
    .title_item {
      color: $color_mine_shaft;
      font-size: $font_big;
      font-family: $font-family-roboto-medium;
    }
    .select-market {
      font-family: $font-family-roboto;
      color: $color-grey-dark;
      font-size: $font-small;
      float: left;
    }
    .search_box {
      display: inline-block;
      float: right;
      width: 215px;
      max-width: 100%;
      .search_input {
        background-color: transparent;
        height: 28px;
        border: 1px solid $color_alto;
        padding: 4px 15px;
        line-height: 20px;
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: $font-small;
      }
    }
  }

  .table_UserSetting_right {
    background-color: $color_white;
    margin: 0;

    thead {
      font-size: $font-small;
      th{
        position: relative;
        background-color: $color_white;
        line-height: 15px;
        font-weight: 500;
        color: $color_grey;
        padding: 5px 25px 4px 15px !important;
        border-bottom: 1px solid $color_alto;
        height: 38px;
        display: table-cell;
        vertical-align: inherit;
        font-family: $font-family-roboto-medium;
      }
      
    }
    tbody {
       tr {
        vertical-align: top;
        overflow-y: hidden;
        transition-property: height;
        transition-duration: 0.3s, 0.3s;
        transition-timing-function: ease, ease-in;
        height: auto;
        background-color: $color_white;
        &:last-child {
          td {
            .item_email_setting {
              vertical-align: middle;
              .tooltip_address_setting {
                top: inherit;
                bottom: 100%;
                &::after {
                  top: inherit;
                  bottom: -10px;
                  border-top-color: $color_white;
                  border-bottom-color: transparent;
                }
              }
            }
          }
        }

        td {
          height: 40px;
          overflow: initial;
          line-height: 23px;
          font-size: 14px !important;
          font-weight: 500;
          color: $color_mine_shaft;
          // padding: 8px 15px 4px 15px;
          border-top: 1px solid $color_catskill_white;
          font-family: $font-family-roboto;
          vertical-align: middle;
          padding: 4px 8px;
        }
      }
    }

  }
  .text-bold-coin{
    text-overflow: ellipsis;
    width: 53px;
    overflow: hidden;
  }
  .text-bold-address{
    text-overflow: ellipsis;
    width: 320px;
    overflow: hidden;
    vertical-align: middle;
  }
  .text-bold-balance{
    text-overflow: ellipsis;
    width: 83px;
    overflow: hidden;
  }
  .text-bold-available-balance{
    text-overflow: ellipsis;
    max-width: 340px;
    overflow: hidden;
  }
  .market-select-area {
    padding-left: 0;
    .select-market-text {
      margin-right: 20px;
      color: #333333;
      font-size: 18px;
      font-family: "Roboto-Medium", sans-serif;
      line-height: 28px;
      float: left;
    }
  }

  .col-left-user {
    min-width: 330px;
  }
  .col-right-user {
    max-width: calc(100% - 330px);
  }

</style>


<style lang="scss">
@import "@/assets/sass/variables";
  #user_settings_fee {
    
    .customModal {
      height: auto;
      .modal-content {
        height: auto;
        .modal-body {
          padding: 0px 30px 15px 30px;
          .group-detail-form-label {
            margin-bottom: 5px;
          }
        }
        .modal-footer {
          padding-bottom: 30px;
        }
      }
    }
    .sc_search_select {
      .group_search_select {
        .button_drop_search {
          width: 100px;
          height: 30px !important;
          padding: 5px 10px 5px 10px !important;
          border-radius: 3px;
          margin-right: 20px;
          font-size: $font-small;
          font-family: $font-family-roboto-medium;
          color: $color-grey-dark;
          i.icon-arrow1 {
            margin-left: 5px;
            color: $color_grey;
          }
          &:hover {
            border-color: $color-jungle-green;
            i.icon-arrow1 {
              color: $color-jungle-green;
            }
          }
        }
        .box_list_search_select {
          .list_search_select {
            li {
              font-family: $font-family-roboto-medium;
              font-size: $font-small;
              &:hover, &:active, &:focus {
                color: $color-jungle-green;
              }
            }
          }
        }
      }
    }
    tr:hover {
      background-color: #ececec;
      cursor: pointer;
    }
    tr {
      &.empty-data {
        &:hover {
          background-color: transparent;
          cursor: initial;
        }
        border-bottom: 1px solid transparent;
      }
      &.active {
        td {
          background-color: #d6efe9;
          border-top: 1px solid #d6efe9;
        }
        position: relative;
        &:after {
          // border: solid transparent;
          // content: " ";
          // height: 0;
          // width: 0;
          // position: absolute;
          // pointer-events: none;
          // border-color: rgba(136, 183, 213, 0);
          // border-left-color: #d6efe9;
          // border-width: 20px;
          // margin-top: 0px;
          // right: -25px;
          display: none;
        }
      }
    }
    table {
      th {
        padding: 5px 25px 4px 15px !important;
      }
      tbody {
        td {
          padding: 5px 25px 4px 15px;
        }
      }
    }
  }
</style>