<template>
  <div class="network_page boxCore" id="network_page">
    <div class="d-flex w-100">
      <div class="filter-template">
        <div class="label-text">&nbsp;</div>
        <input title type="text" :placeholder="$t('resource.networks.search_by')" @keyup.enter="search"
          class="form-control search_symbol" name="searchKey" v-model="filters.searchKey">
      </div>
      <div class="filter-template">
        <div class="label-text">&nbsp;</div>
        <button class="btn btn-reset btn-search" @click="search()">{{ $t('orders.open_order.search') }}</button>
        <button class="btn btn-reset" @click="refresh()">{{ $t('orders.open_order.reset') }}</button>
      </div>
      <div class="filter-template right">
        <div class="label-text">&nbsp;</div>
        <router-link class="btn btn-reset" to="/network/create">
          <span class="icon-plus"></span>
          {{ $t('resource.networks.new_network') }}</router-link>
      </div>
    </div>

    <div class="table-futures">
      <data-table-4 :getData="getData" ref="datatable" :column="8" :limit="10" :inLine="true" class="scroll"
        :total-user="totalRecords">
        <th class="text-left">{{ $t('resource.networks.id') }}</th>
        <th class="text-center">{{ $t('resource.networks.currency') }}</th>
        <th class="text-left">{{ $t('resource.networks.info') }}</th>
        <th class="text-center">{{ $t('resource.networks.active_status') }}</th>
        <th class="text-center">{{ $t('resource.networks.allow_deposit') }}</th>
        <th class="text-center">{{ $t('resource.networks.allow_withdraw') }}</th>
        <th class="text-left">{{ $t('resource.networks.updated_time') }}</th>
        <th class="text-center">{{ $t('resource.networks.action') }}</th>

        <template slot="body" slot-scope="props">
          <tr>
            <td class="text-left">
              {{ props.item.id }}
            </td>
            <td class="text-center">
              <div class="text-top">{{ props.item.currency | uppercase }} </div>
            </td>
            <td class="text-left">
              {{ props.item.network_code | uppercase }} | {{ props.item.name }}
            </td>
            <td class="text-left">
              <span class="status">
                <div class="bt" :class="props.item.enable ? 'enable' : 'disable'">{{ props.item.enable |
                  activeFundStatus }}</div>
              </span>
            </td>
            <td class="text-left">
              <span class="status">
                <div v-if="props.item.network_deposit_enable" class="bt enable">On</div>
                <div v-else class="bt disable">Off</div>
              </span>
            </td>
            <td class="text-left">
              <span class="status">
                <div v-if="props.item.network_withdraw_enable" class="bt enable">On</div>
                <div v-else class="bt disable">Off</div>
              </span>
            </td>
            <td v-if="props.item.updated_at" class="text-left">
              <div class="text-top">{{ new Date(props.item.updated_at) | formatTimeStamp('YYYY-MM-DD') }} </div>
              <div class="text-bottom">{{ new Date(props.item.updated_at) | formatTimeStamp('HH:mm:ss') }}</div>
            </td>
            <td v-else class="text-left"> --</td>
            <td class="text-left">
              <span class="action">
                <img @click="gotoAccDetail(props.item.id)" class="imge" src="@/assets/images/icon/edit-icon.svg"
                  width="24px" height="24px" />
              </span>
            </td>
          </tr>
        </template>
      </data-table-4>
    </div>

    <div class="clearfix clearfix-40"></div>
  </div>
</template>

<script>

import moment from "moment";
import rf from "../../lib/RequestFactory";
import Modal from "../../components/Modal";
import BigNumber from "bignumber.js";
import DataTable4 from "../../components/DataTable4.vue";

export default {
  name: "OrderFuturesRecords",
  components: {
    Modal,
    DataTable4,
  },
  data() {
    return {
      titlePage: window.i18n.t('menu.resource.networks'),
      isActive: true,
      startDate: moment().subtract(1, 'week').toDate(),
      endDate: moment().toDate(),
      filters: {
        searchKey: "",
      },
      isLoading: false,
      totalRecords: 0,
      pagination: null
    }
  },
  watch: {
  },
  methods: {
    search() {
      let filterParams = {};
      if (this.filters.searchKey) {
        filterParams.currency = this.filters.searchKey;
      }
      this.$refs.datatable.filter(filterParams);
    },
    refresh() {
      this.resetInput();
      this.$refs.datatable.refresh();
    },
    resetInput() {
      this.filters = Object.assign(this.filters, {
        searchKey: "",
      });
      $(".datatable").find("th").attr("data-sort-order", "");
    },
    listenForNotification() {
      window.Echo.channel('App.Models.Admin')
        .listen('AdminNotificationUpdated', () => {
          this.$refs.datatable.refresh();
        });
    },
    getData(params) {
      let meta = {};
      if (this.filters.searchKey) {
        meta.currency = this.filters.searchKey;
      }
      params = Object.assign(params, meta);
      const records = rf.getRequest('MultiChainRequest').getNetworks(params);
      records.then(data => {
        this.totalRecords = data.data.total;
        this.pagination = {
          page: params?.page,
          limit: params?.limit
        }
      })
      return records;
    },
    handleClose() {
      this.dataDetail = null
      this.rightSlide = false
    },
    handleOpen(data) {
      this.dataDetail = data
      this.rightSlide = true
    },
    gotoAccDetail(id) {
      this.$router.push({
        name: 'NetworkEdit',
        query: { id: id },
        params: {
          currency: this.filters.searchKey,
          pagination: this.pagination
        }
      });
    },
  },
  created() {
    // this.getOptionData();
  },
  mounted() {
    this.$emit('EVENT_PAGE_CHANGE', this);
    if (this.$route.params.params) this.filters.searchKey = this.$route.params.params.currency;
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/variables";

#network_page {
  table {
    thead {
      th {
        padding-left: 8px !important;
        padding-right: 8px;

        &:first-child {
          width: 3%;
          min-width: 70px;
        }

        &:nth-child(2) {
          width: 5%;
          min-width: 100px;
        }

        &:nth-child(3) {
          width: 15%;
          min-width: 100px;
        }

        &:nth-child(4) {
          width: 10%;
          min-width: 110px;
        }

        &:nth-child(5) {
          width: 12%;
          min-width: 130px;
        }

        &:nth-child(6) {
          width: 12%;
          min-width: 130px;
          max-width: 130px;
        }

        &:nth-child(7) {
          width: 9%;
          min-width: 100px;
          padding-left: 20px;
        }

        &:nth-child(8) {
          width: 9%;
          min-width: 110px;
        }

        &:nth-child(9) {
          width: 6%;
          min-width: 70px;
        }

        &::after {
          display: none;
        }
      }
    }
  }
}

td {
  word-break: break-all;
  vertical-align: middle !important;
  border-bottom: solid 1px $color-white-smoke;
  padding: 8px !important;

  .bt {
    width: 80px;
    height: 30px;
    border-radius: 3px;
    color: $color_black_logo;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;

    &.enable {
      background: $color-filled-status;
    }

    &.disable {
      background: $color-cancel-status;
    }
  }

  .status {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .action {
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: center;

    img {
      cursor: pointer;
    }

    .btc {
      width: 72px;
      height: 28px;
      display: flex;
      align-items: center;
      justify-content: center;

      &.cancel {
        color: #FF8C8A;
        border: 1px solid #FF8C8A;
        border-radius: 1px;
      }

      &.approve {
        color: #53D768;
        border: 1px solid #53D768;
        border-radius: 1px;
      }
    }
  }
}

.datatable {
  font-family: $font-family-roboto !important;
}

ul {
  list-style-type: none;
  padding: 0px;
}

.clearfix-40 {
  display: block;
  clear: both;
  height: 40px;
}

.network_page {
  font-family: $font_family_root;
}

.form-control {
  background: $color_concrete;
  height: 30px;
  font-size: $font-small;
  padding-left: 11px;
  padding-right: 0;

  &:hover,
  &:focus {
    border-color: $color-jungle-green;
  }
}

.datatable {
  button.btnForm.bt-action {
    padding-left: 5px !important;
    padding-right: 5px !important;
    font-weight: 400 !important;
  }
}

.buy {
  color: $text-color-jade;
}

.sell {
  color: $text-color-red;
}

.toolbar-element {
  display: inline-block;
  float: left;
  width: 120px;
}

td {
  font-family: $font-family-roboto;
}

.table-futures {
  margin-top: 24px;
}

.filter-template {
  margin-right: 15px;

  &.right {
    flex: 1;
    text-align: right;
  }

  .btn-reset {
    background: $color-caribbean-green;
    border-radius: 10px;
    color: $color_white;
    font-weight: 700;
    height: 32px;

    .icon-plus {
      color: $color-white;
      margin-right: 8px;
    }
  }

  .label-text {
    color: $dark-1;
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 7px;
    margin-left: 5px;
    font-family: $mulish-regular;
    color: $color-grey-dusty;
  }

  .date-picker-input {
    display: inline-block;
    float: left;

    ::v-deep {
      input {
        padding: 0 5px 0px 36px;
        width: 135px !important;
        height: 32px;
        border: 1px solid #C7CBD3 !important;
        border-radius: 10px !important;
        background-image: url('@/assets/images/icon/date-time.svg') !important;
        background-color: $color-white !important;
        background-size: 12px !important;
        background-position: center left 12px !important;
        background-repeat: no-repeat !important;
        color: $dark-1;

        &:hover,
        &:active,
        &:focus {
          border-color: $color-jungle-green;
          z-index: 9;
        }
      }
    }

  }

  .btn-reset {
    background: $color-caribbean-green;
    border-radius: 10px;
    color: $color_white;
    font-weight: 700;
    height: 32px;
  }

  .btn-search {
    margin: 0 10px;
  }

  .btn-download {
    background: $color-caribbean-green;
    border-radius: 10px;
    color: $color_white;
    font-weight: 700;
    width: 95px;
    height: 32px;
    margin-left: 10px;
  }

  .search_symbol {
    background: $color-white;
    border: 1px solid #C7CBD3 !important;
    border-radius: 10px !important;
    width: 197px;
    height: 32px;
  }
}
</style>

<style lang="scss">
@import "@/assets/sass/variables";

#network_page {
  .sc_search_select {
    .group_search_select {
      .button_drop_search {
        width: 100%;
        height: 32px;
        padding: 6px 10px 6px 10px;
        border-radius: 10px;
        font-size: $font-small;
        font-family: $mulish-regular;
        background-color: $color-white;

        i.icon-arrow1 {
          margin-left: 5px;
          color: $color_dove_gray;
        }

        &:hover {
          border-color: $color-jungle-green;

          i.icon-arrow1 {
            color: $color-jungle-green;
          }
        }

      }

      .box_list_search_select {
        .list_search_select {
          border-radius: 10px;

          li {
            font-family: $font-family-roboto;
            font-size: $font-small;

            &:hover,
            &:active,
            &:focus {
              color: $color-jungle-green;
            }
          }
        }
      }
    }
  }
}
</style>