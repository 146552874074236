<template>
  <div class="content_usersetting mg_bot50 clearfix" id="user_settings">
    <div class="filter_container clearfix">
      <span class="title_item">
        <span class="title_text">{{ $t('user.user') }}</span>
      </span>
      <div class="search_box form-group col-xs-6">
        <input type="text" placeholder="Search" v-on:keyup.enter="search" @keyup="debounceSearch" class="form-control search_input" name="searchKey" v-model="searchKey"/>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-4">
        <div class="datatable">
          <data-table :getData="getData" :totalUser="numberOfUser" ref="datatable" :limit="10" :column="5" class="scroll height-custom">
            <th class="text-left cl_80" data-sort-field="id">{{ $t('user_setting.id') }}</th>
            <th class="text-left cl_170" data-sort-field="email">{{ $t('user.email') }}</th>
            <th class="text-left cl_110">{{$t('user.otp')}}</th>
            <!-- <th class="text-right cl_110 th-last">{{$t('user_setting.balance')}}</th> -->
            <template slot="body" slot-scope="props">
              <tr @click="getBalances(props.item.id)" v-bind:class="{inactive: props.item.status === 'inactive'}">
                <td class="text-left font14">{{ props.item.id }}</td>
                <td class="text-left font14 cl_170">
                  <div class="item_email_setting font14">
                    <span class="txt_email_setting font14">{{ props.item.email }}</span>
                    <span class="tooltip_email_setting font14">{{ props.item.email }}</span>
                  </div>
                </td>
                <td class="text-left cl_110">
                  <button class="btn" :disabled="props.item.security_setting && !props.item.security_setting.otp_verified" :class="props.item.security_setting && props.item.security_setting.otp_verified ? 'btn_disable' : 'btn_enable'"
                    @click.stop="onClickedUpdateOtp(props.item.security_setting)">
                    {{ props.item.security_setting && props.item.security_setting.otp_verified ? $t('common.action.disable') : $t('common.action.enable') }}
                  </button>
                </td>
                <!-- <td class="text-right cl_110">
                  <button @click="getBalances(props.item.id)" class="btn btn_view">View</button>
                </td> -->
              </tr>
            </template>
          </data-table>
        </div>
      </div>
      <div class="col-xs-8">
        <div class="box_table_UserSetting_right">
          <table class="table table_UserSetting_right " :class="balancesDefault ? 'table_hover' :''" :limit="10" :column="8">
            <thead>
              <tr>
                <th class="text-left" >{{ $t('user_setting.coin') }}</th>
                <th class="text-left" >{{ $t('user_setting.blockchain_address') }}</th>
                <th class="text-left" >{{ $t('user_setting.balance') }}</th>
                <th class="text-right" >Total Spot Balance</th>
                <th class="text-right">Total Future Balance</th>
                <!-- <th class="text-right" >{{ $t('table.airdrop_balance') }}</th> -->
                <!-- <th class="text-right" >{{ $t('table.available_airdrop_balance') }}</th> -->
<!--                <th class="text-right" >{{ $t('margin.balance') }}</th>-->
<!--                <th class="text-right" >{{ $t('margin.available_balance') }}</th>-->
                <!-- <th class="text-right" >{{ $t('table.perpetual_airdrop_balance') }}</th> -->
                <!-- <th class="text-right" >{{ $t('table.available_perpetual_airdrop_balance') }}</th> -->
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, key) in balances" :key = "key">
                  <td class="text-left">
                    <div class="text-bold-coin">{{ item.coin | uppercase }}</div>
                  </td>
                  <td class="text-left">
                    <div class="item_email_setting font14">
                      <div class="text-bold-address">{{ item.blockchain_address }}</div>
                      <span class="tooltip_address_setting font14">{{ item.blockchain_address }}</span>
                    </div>
                  </td>
                  <td class="text-left">
                    <div class="detail_balance">
                      {{ trimNumber(item.total_balance) }}
                    </div>
                  </td>
<!--                  <td class="text-right">-->
<!--                    <div class="detail_balance">-->
<!--                      {{ trimNumber(item.available_balance) }}-->
<!--                    </div>-->
<!--                  </td>-->
                  <td class="text-right">
                    <div class="detail_balance">
                      {{ trimNumber(item.spot_total_balance) }}
                    </div>
                  </td>
                  <td class="text-right">
                    <div class="detail_available_balance">
                      {{ trimNumber(item.future_total_balance) }}
                    </div>
                  </td>
                  <!-- <td class="text-right">
                    <div class="detail_balance">
                      <div v-if="item.airdrop_balance">{{ trimNumber(item.airdrop_balance) }}</div>
                      <div v-if="!item.airdrop_balance && isChosen">---</div>
                    </div>
                  </td> -->
                  <!-- <td class="text-right">
                    <div class="detail_available_balance">
                      <div v-if="item.available_airdrop_balance">{{ trimNumber(item.available_airdrop_balance) }}</div>
                      <div v-if="!item.available_airdrop_balance && isChosen">---</div>
                    </div>
                  </td> -->
<!--                  <td class="text-right">-->
<!--                    <div class="detail_balance">-->
<!--                      <div v-if="item.margin_balance">{{ trimNumber(item.margin_balance) }}</div>-->
<!--                      <div v-if="!item.margin_balance">-&#45;&#45;</div>-->
<!--                    </div>-->
<!--                  </td>-->
<!--                  <td class="text-right">-->
<!--                    <div class="detail_balance">-->
<!--                      <div v-if="item.margin_available_balance">{{ trimNumber(item.margin_available_balance) }}</div>-->
<!--                      <div v-if="!item.margin_available_balance">-&#45;&#45;</div>-->
<!--                    </div>-->
<!--                  </td>-->
                  <!-- <td class="text-right">
                    <div class="detail_balance">
                      <div v-if="item.perpetual_airdrop_balance">{{ trimNumber(item.perpetual_airdrop_balance) }}</div>
                      <div v-if="!item.perpetual_airdrop_balance && isChosen">---</div>
                    </div>
                  </td> -->
                  <!-- <td class="text-right">
                    <div class="detail_available_balance">
                      <div v-if="item.available_perpetual_airdrop_balance">{{ trimNumber(item.available_perpetual_airdrop_balance) }}</div>
                      <div v-if="!item.available_perpetual_airdrop_balance && isChosen">---</div>
                    </div>
                  </td> -->
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
</div>
</template>

<script>
  import rf from '../../lib/RequestFactory';
  import BigNumber from 'bignumber.js';
  import Numeral from '../../lib/numeral';
  export default {
    components: {
    },
    data() {
      return {
        titlePage: this.$t('user.user_setting'),
        searchKey: '',
        balances: [],
        balancesDefault: false,
        numberOfUser: 0,
        isChosen: false,
      }
    },
    methods: {
      trimNumber(number) {
        if(!number) return null;
        let zeroValue = '0';
        let numberOfDecimalDigits = 10;
        let format = numberOfDecimalDigits == 0 ?
                '0,0' :
                '0,0.[' + Array(numberOfDecimalDigits + 1).join('0') + ']';
        if (window._.isNil(zeroValue)) {
          zeroValue = '';
        }
        return Numeral(number).format(format);
      },
      showModal (config) {
        const type = config.type;
        const title = config.title;
        const content = config.content;
        const customContent = config.customContent;

        let btnCancelLabel = config.btnCancelLabel || this.$t('common.action.no');
        let btnConfirmLabel = config.btnConfirmLabel || this.$t('common.action.yes');
        if (config.noAction) {
          btnCancelLabel = null;
          btnConfirmLabel = null;
        }
        const onConfirm = config.onConfirm;
        const onCancel = config.onCancel;

        window.ConfirmationModal.show({
          type: type,
          title: title,
          content: content,
          customContent: customContent,
          btnCancelLabel: btnCancelLabel,
          btnConfirmLabel: btnConfirmLabel,
          onConfirm: onConfirm,
          onCancel: onCancel
        });
      },

      search() {
        this.$refs.datatable.$emit('DataTable:filter', {search_key: this.searchKey});
      },
      debounceSearch: _.debounce(function(){
        this.$refs.datatable.$emit('DataTable:filter', {search_key: this.searchKey});
      },1500),

      getData(params) {
        return rf.getRequest('UserRequest').getUsers(params)
          .then(res => {
            return res;
          });
      },

      getTotalUser() {
        return rf.getRequest('UserRequest').getTotalUsers()
          .then(res => {
            this.numberOfUser = res.data;
          });
      },
      
      getBalances(id) {
        this.isChosen = true;
        this.balancesDefault =false;
        rf.getRequest('AdminRequest').getUserBalances({user_id: id}).then(res => {
          this.balances = window._.map(res.data, (value) => value);
          this.balancesDefault = true;
        })
        .catch(e => {
            console.log(e)
        });
      },

      onClickedUpdateOtp(setting) {
        const title = setting && setting.otp_verified ? this.$t('user_setting.confirm_off_otp') : this.$t('user_setting.confirm_on_otp');
        this.showModal({
          type: 'confirm',
          title: title,
          onConfirm: () => {
            rf.getRequest('UserRequest').updateSettingOtp(setting.id, setting && !setting.otp_verified).then(res => {
              this.$toastr('success', window.i18n.t('user_setting.update_otp_msg'));
              setting.otp_verified = !setting.otp_verified;
            });
          }
        });
      },

      initEmptyList() {
        let i = 0;
        this.balances = [];
        while (i++ < 10) {
          this.balances.push({});
        }
      },
      blockTabBrowser(e){
        if (e.key == 'Tab') {
          e.preventDefault();
        }
      },
    },
    created() {
      document.addEventListener('keydown', this.blockTabBrowser);
    },
    beforeDestroy() {
      document.removeEventListener('keydown',this.blockTabBrowser);
    },
    mounted() {
      this.initEmptyList();
      this.$emit('EVENT_PAGE_CHANGE', this);
      this.getTotalUser();
    }
  }
</script>
<style lang="scss">
  .height-custom{
    height: 100%;
    width: 100%;
    // overflow: hidden;
    table{
      // height : 445px;
      // max-height: 445px;
    }
  }
  .height-custom table{
    width: 100%;
    height: 100%;
    overflow-y: hidden;
    padding-right: 17px;
    box-sizing: content-box;
  }
  .th-last{
    padding: 0 !important;
  }
</style>
<style lang="scss" scoped>
@import "@/assets/sass/variables";
  .cl_address {
    width: 350px;
  }
  .cl_80 {
    width: 80px;
  }
  .cl_110 {
    width: 110px;
  }
  .cl_170 {
    max-width: 170px;
  }
  .font14{
    font-size: $font-root !important;
  }
  .content_modal_withdrawSetting {
    color: $color_mine_shaft;
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    line-height: $font_big_24;
  }


  .content_usersetting {

    .btn_enable {
      background-color: transparent;
      text-transform: uppercase;
      width: 70px;
      height: 25px;
      line-height: 20px;
      padding: 0px 0px;
      text-align: center;
      font-size: $font-smaller;
      font-weight: 600;
      color: $color-caribbean-green;
      border: 1px solid $color-caribbean-green;
      border-radius: 3px;
      transition: 0.5s;
      &:hover {
        background-color: $color-aquamarine;
        border-color: $color-aquamarine;
        color: $color_white;
        transition: 0.5s;
      }
    }
    .btn_disable {
      background-color: transparent;
      text-transform: uppercase;
      width: 70px;
      height: 25px;
      line-height: 20px;
      padding: 0px 0px;
      text-align: center;
      font-size: $font-smaller;
      font-weight: 600;
      color: $color_alizarin_crimson;
      border: 1px solid $color_alizarin_crimson;
      border-radius: 3px;
      transition: 0.5s;
      &:hover {
        background-color: $color_alizarin_crimson;
        border-color: $color_alizarin_crimson;
        color: $color_white;
        transition: 0.5s;
      }
    }
    .btn_view {
      float: right;
      background-color: $color-caribbean-green;
      text-transform: uppercase;
      width: 55px;
      height: 25px;
      line-height: 20px;
      padding: 0px 9px;
      text-align: center;
      font-size: $font-smaller;
      font-weight: 600;
      color: $color-white;
      border: 1px solid $color-caribbean-green;
      border-radius: 3px;
      transition: 0.5s;
      &:hover {
        background-color: $color-aquamarine;
        border-color: $color-aquamarine;
        color: $color_white;
        transition: 0.5s;
      }
    }

    .filter_container {
      margin: 12px 0px;

      .title_item {
        color: $color_mine_shaft;
        font-size: $font_big_20;
        font-weight: 500;
        line-height: 28px;
        float: left;
      }
      .search_box {
        display: inline-block;
        float: right;
        width: 215px;
        max-width: 100%;
        .search_input {
          background-color: transparent;
          height: 28px;
          border: 1px solid $color_alto;
          padding: 4px 15px;
          line-height: 20px;
          width: 100%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          font-size: $font-small;
        }
      }
    }
    .btn_detail_user {
      float: right;
      background-color: transparent;
      text-transform: uppercase;
      width: 65px;
      height: 23px;
      line-height: 20px;
      padding: 0px 9px;
      text-align: center;
      font-size: $font-smaller;
      font-weight: 600;
      color: $color-caribbean-green;
      border: 1px solid $color-caribbean-green;
      border-radius: 20px;
      margin-left: 15px;
      transition: 0.5s;
      &:hover {
        background-color: $color-aquamarine;
        border-color: $color-aquamarine;
        color: $color_white;
        transition: 0.5s;
      }
    }

    .item_email_setting {
      display: inline-block;
      position: relative;
      vertical-align: middle;
      .txt_email_setting {
        display: block;
        width: 130px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .tooltip_email_setting, .tooltip_address_setting {
        position: absolute;
        top: 0px;
        line-height: 20px;
        padding: 5px 20px;
        left: 100%;
        background-color: $color_white;
        white-space: nowrap;
        width: auto;
        z-index: 10;
        font-size: $font-smaller;
        font-weight: 500;
        color: $color_mine_shaft;
        transition: 0.5s;
        display: none;
        box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.4);
        &:after {
          right: 100%;
          top: 50%;
          border: solid transparent;
          content: " ";
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
          border-color: rgba(136, 183, 213, 0);
          border-right-color: $color_white;
          border-width: 5px;
          margin-top: -5px;
        }
      }
      &:hover {
        .tooltip_email_setting, .tooltip_address_setting {
          display: block;
          transition: 0.5s;
        }
      }
      .tooltip_address_setting {
        top: 100%;
        left: 0;
        &:after {
          left: 25%;
          bottom: 100%;
          top: initial;
          border-bottom-color: $color_white;
          border-right-color: transparent;
        }
      }
    }
  }

  @media only screen and (min-width: 1399px) {
     .content_usersetting .item_email_setting  .txt_email_setting {
        max-width: 250px;
     }
  }

  .box_table_UserSetting_right {
    height: 439px;
    background-color: $color_white;
    max-height: 439px;
    overflow-y: auto;
    overflow-x: auto;
  }
  .detail_balance {
    text-overflow: ellipsis;
    width: 150px;
    overflow: hidden;
    vertical-align: middle;
  }
  .detail_available_balance {
    text-overflow: ellipsis;
    width: 180px;
    overflow: hidden;
    vertical-align: middle;
  }
  .table_UserSetting_right {
    background-color: $color_white;
    margin: 0;

    thead {
      font-size: $font-small;
      th{
        position: relative;
        background-color: $color_white;
        line-height: 15px;
        font-weight: 500;
        color: $color_grey;
        padding: 5px 25px 4px 15px !important;
        border-bottom: 1px solid $color_alto;
        height: 38px;
        display: table-cell;
        vertical-align: inherit;
      }
      
    }
    tbody {
       tr {
        vertical-align: top;
        overflow-y: hidden;
        transition-property: height;
        transition-duration: 0.3s, 0.3s;
        transition-timing-function: ease, ease-in;
        height: auto;
        background-color: $color_white;
        &:last-child {
          td {
            .item_email_setting {
              vertical-align: middle;
              .tooltip_address_setting {
                top: inherit;
                bottom: 100%;
                &::after {
                  top: inherit;
                  bottom: -10px;
                  border-top-color: $color_white;
                  border-bottom-color: transparent;
                }
              }
            }
          }
        }

        td {
          height: 40px;
          overflow: initial;
          line-height: 23px;
          font-size: 14px !important;
          font-weight: 500;
          color: $color_mine_shaft;
          // padding: 8px 15px 4px 15px;
          border-top: 1px solid $color_catskill_white;
          vertical-align: middle;
          padding: 4px 8px;
        }
      }
    }

  }
  .text-bold-coin{
    text-overflow: ellipsis;
    width: 53px;
    overflow: hidden;
  }
  .text-bold-address{
    text-overflow: ellipsis;
    width: 320px;
    overflow: hidden;
    vertical-align: middle;
  }
  .text-bold-balance{
    text-overflow: ellipsis;
    width: 83px;
    overflow: hidden;
  }
  .text-bold-available-balance{
    text-overflow: ellipsis;
    max-width: 340px;
    overflow: hidden;
  }
</style>

<style lang="scss">
@import "@/assets/sass/variables";
  #user_settings {
    .sc_search_select {
      .group_search_select {
        .button_drop_search {
          width: 55px;
          height: 30px !important;
          padding: 5px 10px 5px 10px !important;
          border-radius: 3px;
          margin-right: 20px;
          font-size: $font-small;
          font-family: $font-family-roboto;
          i.icon-arrow1 {
            margin-left: 5px;
            color: $color_dove_gray;
          }
          &:hover {
            border-color: $color-jungle-green;
            i.icon-arrow1 {
              color: $color-jungle-green;
            }
          }
        }
        .box_list_search_select {
          .list_search_select {
            li {
              font-family: $font-family-roboto;
              font-size: $font-small;
              &:hover, &:active, &:focus {
                color: $color-jungle-green;
              }
            }
          }
        }
      }
    }
    tr:hover {
      background-color: #ececec;
      cursor: pointer;
    }
    table {
      th {
        padding: 5px 25px 4px 15px !important;
      }
      tbody {
        td {
          padding: 5px 25px 4px 15px;
        }
      }
    }
  }
</style>
