<template>
  <div class="trading-list-page" id="trading_list_page">
    <div class="content-page table-device-list-wrapper">
      <button class="btn-create" :class="{ disable: disableRegister }" @click="registerTradingPair">
        <span class="icon-plus icon"></span> {{ $t('trading_information.btn_register_trading_pair') }}
      </button>
      <div class="content-box">
        <data-table :getData="getData" :limit="10" ref="list_email" :column="7" class="scroll">
          <th class="text-left">{{ $t('trading_information.pair') }}</th>
          <th class="text-left">{{ $t('trading_information.market_price') }}</th>
          <th class="text-left">{{ $t('trading_information.price_precision') }}</th>
          <th class="text-left">{{ $t('trading_information.minimum_amount') }}</th>
          <th class="text-left">{{ $t('trading_information.quantity_precision') }}</th>
          <th class="text-left">{{ $t('trading_information.minimum_total') }}</th>
          <th class="text-left">{{ $t('trading_information.taker_fee_percent') }}</th>
          <th class="text-left w_135">{{ $t('trading_information.maker_fee_percent') }}</th>
          <th class="text-left w_135">{{ $t('trading_information.zone') }}</th>
          <th class="text-right"></th>
          <template slot="body" slot-scope="props">
            <tr>
              <td class="text-left">{{ props.item.coin | uppercase }} / {{ props.item.currency | uppercase }}</td>
              <td class="text-left">{{ props.item.market_price | formatCurrencyAmount(props.item.currency, '0') }}</td>
              <td class="text-left">{{ props.item.price_precision | formatCurrencyAmount(props.item.coin, '0') }}</td>
              <td class="text-left">{{ props.item.minimum_quantity | formatCurrencyAmount(props.item.coin, '0') }}</td>
              <td class="text-left">{{ convertNumberToPrecision(props.item.quantity_precision) }}</td>
              <td class="text-left">{{ props.item.minimum_amount | formatCurrencyAmount(props.item.currency, '0') }}</td>
              <td class="text-left">{{ props.item.taker_fee }}</td>
              <td class="text-left">{{ props.item.maker_fee }}</td>
              <td class="text-left">{{ props.item.zone | zoneName}}</td>
              <td class="text-right">
                <i class="btn-icon icon-edit" @click="editTradingSetting(props.item)"></i>
                <i class="btn-icon icon-close2" @click="deleteEmail(props.item)"></i>
              </td>
            </tr>
          </template>
        </data-table>
      </div>

      <div class="redirect-box">
        <button class="btn btn-primary" @click="$emit('pre-step')">
          {{ $t('trading_information.btn_back') }}
        </button>
        <button :disabled="tradingList.length === 0" class="btn btn-primary btn-next" @click="$emit('next-step')">{{ $t('trading_information.btn_next') }}</button>
      </div>
    </div>
    <modal-trading-pair-information/>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import ModalTradingPairInformation from '../../../modals/TradingPairInformation.vue';
  import BigNumber from "bignumber.js";

  export default {
    data () {
      return {
        tradingList: []
      }
    },
    inject: ['data'],
    components: {
      ModalTradingPairInformation,
    },
    mounted () {
      window.onbeforeunload = () => {
        return 'Are you sure you want to leave?';
      };
    },
    computed: {
      ...mapState({
        masterdata: state => state.masterdata,
      }),
      disableRegister() {
        return this.data.params.trading_setting.length === this.markets.length;
      },
      markets () {
        return _.chain(this.masterdata.coin_settings || {})
          .map('currency')
          .union()
          .value() || [];
      }
    },
    methods: {
      convertNumberToPrecision (number) {
        if (!(parseInt(number) > 0)) {
          return '0';
        }
        return '0.' + '0'.repeat(number - 1) + 1;
      },

      getData () {
        return new Promise((resolve) => {
          let data = this.data.params.trading_setting;
          this.rows = data;
          this.tradingList = data;
          return resolve({ data });
        });
      },
      editTradingSetting (tradingSetting) {
        return this.$modal.show('modal-trading-pair-information', tradingSetting);
      },
      deleteTradingSetting (tradingSetting) {
        let tradingSettingIndex = _.findIndex(this.data.params.trading_setting, (pair) => pair.uuid === tradingSetting.uuid);
        this.data.params.trading_setting.splice(tradingSettingIndex, 1);
        // this.$toasted.show(
        //   window.i18n.t('trading_information.delete_msg'),
        //   {
        //     position: 'bottom-right',
        //     duration: 3000,
        //     className: 'toasted-success toasted-success2',
        //   }
        // );
      },
      registerTradingPair () {
        if (this.disableRegister) {
          return;
        }
        this.$modal.show('modal-trading-pair-information');
      },
      deleteEmail(row) {
        ConfirmationModal.show({
          type: 'confirm',
          title: window.i18n.t('email.delete_data'),
          content         : '',
          btnCancelLabel  : this.$t('common.action.no'),
          btnConfirmLabel : this.$t('common.action.yes'),
          size            : 'small',
          onConfirm       : () => {
            this.deleteTradingSetting(row);
            this.$toastr('success', this.$t('common.alert.delete.success'));
          },
          onCancel        : () => {}
        });
      }
    }
  };
</script>

<style lang="scss" scoped>
@import "@/assets/sass/variables";

  .btn-icon {
    font-size: $font_big_17;
    cursor: pointer;
    color: $color-caribbean-green;
    margin-right: 15px;
    position: relative;
    top: 4px;
    &:hover{
      color: $color-aquamarine;
    }
  }

  .btn-primary{
    font-weight: 500;
    font-family: $font-family-roboto-bold;
    letter-spacing: 0.92px;
    width: 90px;
    text-align: center;
    padding: 5px;
    font-size: $font-smaller;
    background-color: $color-caribbean-green;
    border : 1px solid $color-caribbean-green;
    &:hover{
      background-color:$color-aquamarine;
      border : 1px solid $color-aquamarine;
    }
    &.btn-next {
      margin-left: 13px;
    }
  }

  .text-right {
    text-align: right;
  }

  $columnTableMap: (
    1: 134px,
    2: 246px,
    3: 246px,
    4: 246px,
    5: 199px,
    6: 119px,
  );

  .trading-list-page {
    padding: 8px 0px;
    position: relative;

    .heading-title {
      padding-bottom: 25px;
      border-bottom: 1px solid $color_alto;
      margin-bottom: 20px;
    }

    .tab-title {
      text-transform: uppercase;
      font-size: $font_semi_big;
      font-weight: 700;
      color: $color-grey-dark;
      margin-bottom: 20px;
    }

    .content-page {
      margin-bottom: 34px;
    }

    .description-content {
      font-size: $font_semi_big;
      margin-bottom: 25px;
    }

    table {
      max-width: 1133px;
      table-layout: fixed;
      thead {
        th {

        }
      }

      td {
        padding: 4px 15px !important;
        font-family: $font-family-roboto;
      }

      @each $index, $width in $columnTableMap {
        th {
          &:nth-child(#{$index}) {
            width: $width;
          }
        }
      }

      tr td {
        // &:not(:nth-child(5)) {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        // }
      }
    }

    .btn-group .btn-item {
      &:not(:last-child) {
        margin-right: 10px;
      }
    }

    %btn-item {
      border-radius: 5px;
      outline: none;
      text-align: center;
      padding: 5px 0;
      font-weight: 700;
      font-size: 12px;
      color: $color-grey-dark;
      transition: all 0.3s ease-in-out;
    }

    .btn-create, .button-prev, .button-next {
      font-weight: bold !important;
      color: $color-white;
      font-size: $font-smaller;
      font-family: $font-family-roboto;
      &.disable {
        cursor: not-allowed;
      }
      @extend %btn-item;
      text-transform: uppercase;
      background-color: $color-caribbean-green;
      border: solid thin transparent;
      margin-right: 5px;
      padding: 5px 15px;
      border-radius: 3px;
      span {
        margin-right: 5px;
        font-weight: 600;
        font-size: $font_mini_mini;
      }
      &:hover{
        background-color: $color-aquamarine;
        border-color: $color-aquamarine;
      }
    }
    .btn-create {
      width: 210px;
      text-align: center;
      letter-spacing: 0.92px;
      padding: 7px;
      height: 30px;
      .icon {
        position: relative;
        top: -1px;
      }
    }

    .content-box {
      margin-bottom: 30px;
      margin-top: 15px;
    }
  }
  .w_135 {
    min-width: 135px;
  }
</style>

<style lang="scss">
  #trading_list_page {
    #v--modal-overlay {
      z-index: 9999;
    }
    #modal-erc-20 {
      &:after {
        content: "";
        position: fixed;
        z-index: 9998;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.3);
        display: block;
        transition: opacity 0.3s ease;
      }
    }
    .v--modal-box.v--modal {
      z-index: 9999
    }
  }
  .toasted-success {
    &.toasted-success2 {
      background-color: rgba(47, 193, 162, 0.7) !important;
    }
  }
</style>
