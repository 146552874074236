if ((~window.navigator.userAgent.indexOf('MSIE')) ||
  (~window.navigator.userAgent.indexOf('Trident/')) ||
  (~window.navigator.userAgent.indexOf('Edge/'))) {
  window.Promise = require('es6-promise').Promise;
  require('es6-object-assign').polyfill();
}
import "babel-polyfill";
import Echo from 'laravel-echo'
import VueI18n from 'vue-i18n';
import Vue from 'vue';
import messages from './messages';
import Utils from "./Utils";
import enLang from '../locales/en';
import koLang from '../locales/ko';

window._ = require('lodash');

/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

try {
  window.$ = window.jQuery = require('jquery');

  require('bootstrap-sass');
} catch (e) {
  console.error('Cannot load jQuery and Boostrap jQuery plugin');
}

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
window.axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token');

Vue.use(VueI18n);

window.io = require('socket.io-client')

window.Echo = new Echo({
  broadcaster: 'socket.io',
  host: process.env.VUE_APP_SOCKET_URL,
});

window.Echo2 = new Echo({
  broadcaster: 'socket.io',
  host: process.env.VUE_APP_SOCKET_FUTURES_URL,
  query: {
    authorization: ''
  },
  auth: {
    headers: {
      Authorization: '',
    },
  },
});

const allMessages = {
  en: enLang,
  kr: koLang
}
let locale = localStorage.getItem("lang") || document.documentElement.lang;
const i18n = new VueI18n({
  locale,
  fallbackLocale: 'en',
  messages: { [locale]: allMessages[locale] },
});
window.i18n = i18n;
