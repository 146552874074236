<template>
  <div class="account-detail boxCore" id="account-detail">
    <div class="account-information">
      <button class="btn-back" @click="handleBack()">Back</button>
      <div class="avatar">
        <img
          src="https://img.freepik.com/free-psd/3d-illustration-person-with-sunglasses_23-2149436188.jpg?size=338&ext=jpg&ga=GA1.1.2008272138.1722949200&semt=ais_hybrid" />
      </div>
      <div class="account-id">{{ $t('account.account_detail.account_id') }}: {{ dataAccount?.uid }}</div>
      <div class="activation-status">
        {{ $t('account.account_detail.activation_status') }}:
        <div class="switch-container">
          <label class="switch" @click="changeStatus">
            <input type="checkbox" :checked="dataAccount?.status == 'active'" :disabled="true">
            <span class="slider round"></span>
          </label>
        </div>
      </div>
      <div class="info-row">
        <span>{{ $t('account.account_detail.email') }}: </span>
        <span>{{ dataAccount?.email }}</span>
      </div>
      <div class="info-row">
        <span>{{ $t('account.account_detail.phone_number') }}: </span>
        <span>{{ dataAccount?.phone_no }}</span>
      </div>
      <div class="info-row">
        <span>{{ $t('account.account_detail.level') }}: </span>
        <span>{{ dataAccount?.security_level }}</span>
      </div>
      <div class="info-row">
        <span>{{ $t('account.account_detail.kyc_status') }}: </span>
        <span>{{ dataAccount | kyc_status_account }}</span>
      </div>
      <div class="info-row">
        <div class="joined-time">{{ $t('account.account_detail.joined_time') }}: {{ dataAccount?.creation_time |
      dateFormat2 }} {{ dataAccount?.creation_time | timeFormat2 }}</div>
      </div>
    </div>
    <div class="tabs-container">
      <div class="tab-item" v-for="(value, index) in tabs" :key="index" :class="index == tabActive && 'tab-active'"
        @click="changeTab(index)">{{
      value }}</div>
    </div>
    <div v-if="tabActive == 0"><spot-future :user-id="dataAccount?.uid" /></div>
    <div v-if="tabActive == 1">
      <balance />
    </div>
    <div v-if="tabActive == 2">
      <logs />
    </div>
    <div v-if="tabActive == 3">
      <transactions />
    </div>
    <div v-if="tabActive == 4">
      <affiliate />
    </div>
    <div v-if="tabActive == 5"><activity-history /></div>
    <div v-if="tabActive == 6"><open-order /></div>
    <div v-if="tabActive == 7"><order-history /></div>
    <div v-if="tabActive == 8"><trade-history /></div>

    <modal class="confirm_modal" name="confirm_modal" :title="$t('account.modal.confirm')">
      <div v-if="dataAccount?.status == 'inactive'" slot="body" class="body-popup">
        {{ $t('account.account_detail.confirm_active') }}
        <p class="text-center">User ID: <strong>{{ dataAccount?.uid }}</strong></p>
      </div>
      <div v-else slot="body" class="body-popup">
        {{ $t('account.account_detail.confirm_deactive') }}
        <p class="text-center">User ID: <strong>{{ dataAccount?.uid }}</strong></p>
      </div>
    </modal>

    <modal class="confirm_modal" :enableClose="enableClose" name="loadingUpload">
      <div slot="body">
        <vue-loading type="bars" v-if="isLoading" color="#2DAC91" :size="{ width: '60px', height: '80px' }">
        </vue-loading>
      </div>
    </modal>
  </div>
</template>

<script>
import rf from "../../lib/RequestFactory";
import ActivityHistory from "../accounts/ActivityHistory.vue";
import Affiliate from "../accounts/Affiliate.vue";
import Balance from "../accounts/Balance.vue";
import Logs from "../accounts/Logs.vue";
import OpenOrder from "../accounts/OpenOrder.vue";
import OrderHistory from "../accounts/OrderHistory.vue";
import SpotFuture from "../accounts/SpotFutureProfile.vue";
import TradeHistory from "../accounts/TradeHistory.vue";
import Transactions from "../accounts/Transactions.vue";
import Modal from "../../components/Modal.vue";


export default {
  name: "AccountDetail",
  components: {
    SpotFuture,
    Balance,
    Logs,
    Transactions,
    Affiliate,
    ActivityHistory,
    OpenOrder,
    OrderHistory,
    TradeHistory,
    Modal
  },
  data() {
    return {
      titlePage: window.i18n.t("account.account_detail"),
      tabs: [
        window.i18n.t("account.account_detail.spot_future"),
        window.i18n.t("account.account_detail.balance"),
        window.i18n.t("account.account_detail.logs"),
        window.i18n.t("account.account_detail.transactions"),
        window.i18n.t("account.account_detail.affiliate"),
        window.i18n.t("account.account_detail.activity_history"),
        window.i18n.t("account.account_detail.open_order"),
        window.i18n.t("account.account_detail.order_history"),
        window.i18n.t("account.account_detail.trade_history"),
      ],
      dataAccount: null,
      tabActive: 0,
      isLoading: false,
      enableClose: false
    }
  },
  created() {
    this.tabActive = this.$route.query.indexTab ?? 0;
    this.getAccountDetail();
  },
  watch: {
    '$route.query.indexTab'(newIndexTab, oldIndexTab) {
      console.log("oldIndexTab", oldIndexTab);
      console.log("newIndexTab", newIndexTab);
    },
  },
  methods: {
    changeTab: function (indexTab) {
      this.tabActive = indexTab
      this.$router.push({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          indexTab
        }
      });
    },
    getAccountDetail() {
      const accountID = this.$route.query.id;
      rf.getRequest('UserRequest').getAccountDetail(accountID).then(res => {
        if (res?.success) {
          this.dataAccount = res.data;
        }
      })
    },
    loadingUploadBegin() {
      this.enableClose = false;
      this.isLoading = true;
      CommonModal.show('loadingUpload');
    },
    loadingUploadFinish() {
      CommonModal.hide('loadingUpload');
    },
    changeStatus() {
      const params = {
        id: this.dataAccount?.id,
        value: this.dataAccount?.status == 'inactive' ? 'active' : 'inactive'
      }
      this.showModalConfirm('confirm_modal', this.changeStatusAccount, params);
    },
    changeStatusAccount(params) {
      rf.getRequest('UserRequest').changeActiveStatusAccount(params).then(res => {
        if (res?.success) {
          this.$toastr('success', this.$t('account.account_detail.message_change_status_success'));
          this.getAccountDetail();
        } else {
          this.$toastr('error', this.$t('account.account_detail.message_change_status_fail'));
        }
      })
    },
    showModalConfirm(modalName, callback, params) {
      CommonModal.show(modalName, {
        position: 'center',
        mask: true,
        buttons: [
          {
            label: window.i18n.t('common.action.no'),
            class: "btn-no",
            style: {
              background: 'rgb(191, 191, 191);'
            },
            callback: () => {
              CommonModal.hide(modalName);
            }
          },
          {
            label: window.i18n.t('common.action.yes'),
            focused: true,
            style: {
              background: 'rgb(0, 112, 192)'
            },
            callback: () => {
              CommonModal.hide(modalName);
              this.loadingUploadBegin();
              callback(params);
              this.loadingUploadFinish();
            }
          }
        ]
      });
    },
    handleBack() {
      const params = this.$route.params;
      if (Object.keys(params).length === 0) {
        this.$router.go(-1);
        return;
      }
      this.$router.push({
        name: 'account_list',
        params: params,
        props: true
      });
    }
  },
  mounted() {
    console.log(this.$route.params)
    this.$emit('EVENT_PAGE_CHANGE', this);
    // this.listenForNotification();
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/variables";

.account-detail {

  .account-information {
    position: relative;
    width: 600px;
    background-color: $color-white;
    padding: 30px 50px;
    border-radius: 10px;

    .avatar {
      width: 150px;
      height: 150px;
      overflow: hidden;
      border-radius: 150px;
      margin-right: auto;
      margin-left: auto;

      img {
        width: 100%;
        height: auto;
        object-fit: contain;
      }
    }

    .account-id {
      margin-top: 24px;
      font-weight: 700;
      font-size: large;
      text-align: center;
    }

    .activation-status {
      margin: 4px 0;
      margin-bottom: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      font-size: small;

      .switch-container {}
    }

    .info-row {
      display: flex;
      justify-content: space-between;
      font-size: medium;
      padding: 16px 0;
      border-bottom: 1px solid $color_alto_gray;

      span:first-child {
        color: $color_gray_dark;
        font-weight: 500;
      }

      span:last-child {
        font-weight: 700;
      }

      &:last-child {
        border: none;
        justify-content: end;
        padding: 4px;
        font-size: smaller;

        div {
          color: $color_gray_dark;
        }
      }
    }
  }

  .btn-back {
    position: absolute;
    left: 20px;
    top: 20px;
    margin-bottom: 10px;
    background-color: $color-caribbean-green;
    border: 1px solid $color-caribbean-green;
    font-size: 12px;
    line-height: 1;
    padding: 7px 10px;
    border-radius: 3px;
    font-weight: bold;
    color: #ffffff;
    text-transform: uppercase;
    transition: 0.5s;
    font-family: "Roboto-Regular", sans-serif;

    &:hover {
      background-color: $color-aquamarine;
      border-color: $color-aquamarine;
    }
  }

  .tabs-container {
    display: flex;
    margin: 40px 0;
    margin-bottom: 15px;
    border-bottom: 1px solid $color-bright-gray;

    .tab-item {
      font-weight: 600;
      padding: 16px 10px;
      padding-right: 30px;
      color: $color-gray-dark;
      cursor: pointer;

      &:hover {
        background-color: $color-bright-gray;
      }

      &.tab-active {
        border-bottom: 2px solid $color-wood-smoke;
        color: $color-wood-smoke;
      }
    }
  }
}
</style>

<style lang="scss">
@import "@/assets/sass/variables";

#account-detail {
  .modal {
    .body-popup {
      font-family: $mulish-medium;

      p,
      strong {
        font-family: $mulish-medium;
      }
    }

    .btn-no {
      background-color: $color-bright-gray;
      border-color: $color-bright-gray;
      color: $color-black;

      &:hover {
        opacity: 0.7;
      }
    }
  }
}
</style>