<template>
  <div class="buy-history" id="buy_history">

    <div class="select_transaction_container">
      <div class="tab-header">
        <ul>
          <li :class="{'active': historyType === 'BuyHistory'}">
            <a
              href="javascript:void(0)"
              @click="selectTab('BuyHistory')"
            ><span>{{ $t('salespoint.buy_history') }}</span></a>
          </li>
          <li :class="{'active': historyType === 'CashBack'}">
            <a
              href="javascript:void(0)"
              @click="selectTab('CashBack')"
            ><span>{{ $t('salespoint.buy_history.cash_back') }}</span></a>
          </li>
        </ul>
      </div>
    </div>

    <div class="filter-container">
      <div class="filter-item date">
        <div class="text-pair">{{ $t('salespoint.buy_history.date') }}:</div>
        <date-picker v-model="startDate" class="date-picker-input no-right-radius" :format="customFormatter"></date-picker>
        <date-picker v-model="endDate" class="date-picker-input no-left-radius" :format="customFormatter"></date-picker>
      </div>
      <div class="filter-item pair">
        <div class="label-text text-pair">{{ $t('salespoint.buy_history.pair') }}:</div>
        <div class="coin-input toolbar-element2">
          {{ $t('salespoint.buy_history.amal') }}
        </div>
        <span class="bw-element"> / </span>
        <CoinInput @onCoinChange='onCoinChange' isBuyHistory='true' ref='coinInput'/>    
        <!-- <div class="toolbar-element2">
          <select-box
            v-model="filters.currency"
            :placeholder="$t('salespoint.buy_history.all')"
            :options="markets"/>
        </div> -->
      </div>
      <div class="filter-item">
        <input title="" type="text" :placeholder="$t('salespoint.buy_history.search_by_email')" @keyup.enter="search"
               class="form-control" name="searchKey" v-model="filters.searchKey"/>
      </div>
      <div class="filter-item">
        <button class="btn btn-primary" @click="search()">{{ $t('salespoint.buy_history.search') }}</button>
        <button class="btn btn-reset" @click="refresh()">{{ $t('salespoint.buy_history.reset') }}</button>
      </div>
    </div>

    <div v-if="historyType === 'BuyHistory'" class="datatable" style="width: 100%">
      <data-table :getData="getData" ref="datatable" :column="6" class="scroll">
        <th class="col_time" data-sort-field="created_at">{{ $t('salespoint.buy_history.time') }}</th>
        <th class="col_email" data-sort-field="email">{{ $t('salespoint.buy_history.buyer_email') }}</th>
        <th class="col_currency" data-sort-field="currency">{{ $t('salespoint.buy_history.currency') }}</th>
        <th class="col_amount" data-sort-field="payment">{{ $t('salespoint.buy_history.amount') }}</th>
        <th class="col_total" data-sort-field="amount">{{ $t('salespoint.buy_history.amal') }}</th>
        <th class="col_bonus" data-sort-field="bonus">{{ $t('salespoint.buy_history.bonus') }}</th>

        <template slot="body" slot-scope="props">
          <tr :class="{'opacity-row': props.item.status == 'canceled' && props.item.executed_quantity == 0}">
            <td>{{ convertToLocalTime(props.item.created_at) }}</td>
            <td class="col_email">{{ props.item.email }}</td>
            <td class="col_currency">{{ props.item.currency | uppercase }}</td>
            <td class="col_amount">{{ props.item.payment | formatCurrencyAmount(null, '0') }}</td>
            <td class="col_total">{{ props.item.amount | formatCurrencyAmount(null, '0') }}</td>
            <td class="col_bonus">{{ props.item.bonus | formatCurrencyAmount(null, '0') }}</td>
          </tr>
        </template>
      </data-table>
    </div>

    <div v-if="historyType === 'CashBack'" class="datatable" style="width: 100%">
      <data-table :getData="getData" ref="datatable" :column="6" class="scroll">
        <th data-sort-field="created_at">{{ $t('salespoint.buy_history.time') }}</th>
        <th data-sort-field="referrer_email">{{ $t('salespoint.buy_history.referrer_email') }}</th>
        <th data-sort-field="referred_email">{{ $t('salespoint.buy_history.referred_email') }}</th>
        <th data-sort-field="currency">{{ $t('salespoint.buy_history.currency') }}</th>
        <th data-sort-field="rate">{{ $t('salespoint.buy_history.rate') }}</th>
        <th data-sort-field="bonus">{{ $t('salespoint.buy_history.cash_back') }}</th>

        <template slot="body" slot-scope="props">
          <tr :class="{'opacity-row': props.item.status == 'canceled' && props.item.executed_quantity == 0}">
            <td>{{ convertToLocalTime(props.item.created_at) }}</td>
            <td>{{ props.item.referrer_email }}</td>
            <td>{{ props.item.referred_email }}</td>
            <td>{{ props.item.currency | uppercase }}</td>
            <td>{{ props.item.rate | formatCurrencyAmount(null, '0')}}</td>
            <td>{{ props.item.bonus | formatCurrencyAmount(null, '0') }}</td>
          </tr>
        </template>
      </data-table>
    </div>

  </div>
</template>

<script>
  import rf from '../../lib/RequestFactory';
  import DatePicker from 'vuejs-datepicker';
  import moment from 'moment';
  import MasterdataUtils from '@/desktop/common/MasterdataUtils';
  import COMMON_CONST from '../../common/Const';
  import SelectBox from '../../components/SelectBox';
  import CoinInput from '../../common/CoinInput.vue';

  export default {
    components: {
      DatePicker,
      SelectBox,
      CoinInput,
    },
    data() {
      return {
        titlePage: window.i18n.t('salespoint.buy_history.amal_buy_history'),
        startDate: moment().subtract(1, 'week').toDate(),
        endDate: moment().toDate(),
        filters: {
          currency: '',
          searchKey: ''
        },
        coinMasters: [],
        markets: [],
        historyType: 'BuyHistory',
      }
    },
    watch: {
      historyType() {
        this.$refs.datatable.refresh();
        this.refresh();
      }
    },
    methods: {
      selectTab(tab) {
        this.historyType = tab;
      },
      convertToLocalTime(date) {
        var dateFormat = 'YYYY-MM-DD HH:mm:ss';
        var testDateUtc = moment.utc(date);
        var localDate = testDateUtc.local();
        return localDate.format(dateFormat);
      },
      search() {
        let filterParams = {
          start_date: moment(this.startDate).startOf('day').format('YYYY-MM-DD HH:mm:ss'),
          end_date: moment(this.endDate).endOf('day').format('YYYY-MM-DD HH:mm:ss')
        };
        
        if(this.startDate > this.endDate) {
          this.$toastr('error', this.$t('common.date.warning'));
          return;
        }
        if (this.filters.searchKey) {
          filterParams.search_key = this.filters.searchKey;
        }
        if (this.filters.currency) {
          filterParams.currency = this.filters.currency;
        }
        this.$refs.datatable.filter(filterParams);
      },
      refresh() {
        this.requestCoinList();
        this.resetInput();
        this.$refs.coinInput.refresh();
        this.$refs.datatable.refresh();
      },
      resetInput() {
        this.filters = Object.assign(this.filters, {currency: '', searchKey: ''});
        this.startDate = moment().subtract(1, 'week').toDate();
        this.endDate = new Date();
        $(".datatable").find("th").attr("data-sort-order", "");
      },
      getData(params) {
        if (this.historyType === 'BuyHistory') {
          return rf.getRequest('SalespointRequest').getBuyHistory(params);
        } else {
          return rf.getRequest('SalespointRequest').getCashBack(params);
        }
      },
      /*
      getMarkets() {
        return window._.map(COMMON_CONST.MARKETS, item => {
          return { id: item, name: item.toUpperCase() };
        });
      },
      */
      requestCoinList() {
        rf.getRequest('CoinSettingRequest').getCoinList().then(res => {
          this.coinMasters = window._.map(res.data, 'coin');
        });
      },
      onCoinChange(currency) {
        this.filters.currency = currency;
      },
      customFormatter(date) {
        return moment(date).format('YYYY - MM - DD');
      },
    },

    mounted() {
      this.$emit('EVENT_PAGE_CHANGE', this);
      this.requestCoinList();
    },
    created() {
      rf.getRequest('AdminRequest').getPriceGroupCurrency().then(res => {
        try {
          window._.map(res.data, item => {
            if (typeof item.currency !== 'undefined') {
              this.markets.push({ id: item.currency, name: item.currency.toUpperCase() });
            }
          });
        }
        catch (ex) {
          console.error(ex);
        }
      });
    }
  }
</script>

<style lang="scss">
@import "@/assets/sass/variables";
  .form-control:focus {
    border-color: $color-jungle-green !important;
  }
  // .toolbar-element22{
  //   &hover{
  //     border-color: $color-jungle-green;
  //   }
  // }
  .date-picker-input {
    display: inline-block;
    float: left;
    input {
      padding: 0 5px 0px 23px;
      width: 110px !important;
      border-radius: 3px;
      -webkit-box-shadow: none;
      box-shadow: none;
      height: 30px;
      line-height: 30px;
      border: 1px solid #d2d6de;
      background-image: url('@/assets/images/icon/date-time.svg') !important;
      background-color: $color-white-smoke !important;
      background-size: 12px;
      background-position: center left 5px;
      background-repeat: no-repeat !important;
      color: $color-grey-dusty;
      &:hover, &:active, &:focus {
        border-color: $color-jungle-green;
        z-index: 9;
      }
    }
  }
  .no-left-radius {
    input {
      border-radius: 0px 3px 3px 0px;
      margin-left: -1px;
    }
  }
  .no-right-radius {
    input {
      border-radius: 3px 0px 0px 3px;
    }
  }
  .modal-content .modal-footer {
    text-align: center;
    margin-top: 30px;
  }
  #buy_history {
    padding-bottom: 50px;
    .sc_search_select {
      .group_search_select {
        .button_drop_search {
          width: 55px !important;
          height: 30px !important;
          padding: 5px 10px 5px 10px !important;
          border-radius: 3px;
          margin-right: 20px;
          font-size: $font-small;
          font-family: $font-family-roboto;
          i.icon-arrow1 {
            margin-left: 5px;
            color: $color_dove_gray;
          }
          &:hover {
            border-color: $color-jungle-green;
            i.icon-arrow1 {
              color: $color-jungle-green;
            }
          }
          
        }
        .box_list_search_select {
          .list_search_select {
            li {
              font-family: $font-family-roboto;
              font-size: $font-small;
              &:hover, &:active, &:focus {
                color: $color-jungle-green;
              }
            }
          }
        }
      }
    }
  }
</style>

<style lang="scss" scoped>
@import "@/assets/sass/variables";

 .select_transaction_container {
    padding: 10px 0px 20px 0px;
    .input-radio {
      label {
        display: inline;
        font-weight: normal;
        text-align: center;
        margin-right: 25px;
        span {
          font-size: $font_big;
        }
        input[type="radio"] {
          display: inline-block;
          margin-top: 0px;    
          position: relative;
          top: -2px;
        }
      }
    } 
  }

  .tab-header {
    display: block;
    width: 100%;
    float: left;
    margin: 10px 0px 23px 0px;
    ul {
      list-style: none;
      padding-left: 0px;
      li {
        float: left;
        margin-right: 25px;
        font-size: $font_big;
        color: $color-grey-dark;
        display: block;
        >a {
          color: $color-grey-dark;
          font-family: $font-family-roboto-medium;
          font-weight: 500;
          font-size: $font_big;
          display: block;
          float: right;
          &::before {
            content: "";
            display: inline-block;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            border: solid 1px #979797;
          }
          span {
            padding-left: 5px;
          }
        }
        &.active {
          >a {
            &::before {
              border: solid 4px $color-jungle-green;
            }
              color: $color-jungle-green;
          }
        }
      }
    }
  }

  .opacity-row {
    opacity: 0.4;
  }

  .buy-history {
    .filter-container {
      margin: 40px 0px 30px 0px;
      height: 60px;
      .filter-item {
        float: left;
        input {
          margin-top: 23px;
          margin-right: 5px;
          display: inline-block;
          width: 145px;
          font-family: $font-family-roboto;
          &:hover, &:active, &:focus {
            border-color: $color-jungle-green;
          }
        }
        select {
          width: 65px;
          display: inline-block;
          font-family: $font-family-roboto;
          font-size: $font_root;
          &:hover, &:active, &:focus {
            border-color: $color-jungle-green;
          }
        }
        button {
          width: auto;
          margin: 23px 2px 0px 2px;
          height: 30px;
          font-size: $font-smaller;
          font-family: $font-family-roboto;
        }
        .btn-reset {
          background-color: $color-caribbean-green;
          border: 1px solid $color-caribbean-green;
          width: 80px;
          text-transform: uppercase;
          color: $color-white;
          font-weight: 600;
          &:hover {
            border-color: $color-aquamarine;
            background-color: $color-aquamarine;
          }
        }
        .btn-primary {
          padding: 0;
          min-width: 80px;
          background-color: $color-caribbean-green;
          border: 1px solid $color-caribbean-green;
          &:hover {
            border-color: $color-aquamarine;
            background-color: $color-aquamarine;
          }
        }
        .coin-input {
          width: 70px;
          position: relative;
          display: inline-block;
          font-size: $font-small;
          font-family: $font-family-roboto;
          background-color: $color-bright-gray;
          border: 1px solid $color_alto;
          border-radius: 3px;
          padding: 5px 10px 5px 10px;
          margin-right: 0px;
          height: 30px;
          .coin-option {
            background-color: $color-white;
            max-height: 210px;
            display: block;
            overflow-y: auto;
            margin-top: 5px;            
            position: absolute;
            width: 79px;
            left: 5px;
            z-index: 10;
            padding: 12px 0px;
            box-shadow: 0px 3px 12px rgba(49, 49, 49, 0.51);
            &:after {
                bottom: 100%;
                left: 50%;
                border: solid transparent;
                content: " ";
                display: block;
                height: 0;
                width: 0;
                position: absolute;
                pointer-events: none;
                border-color: rgba(136, 183, 213, 0);
                border-bottom-color: $color_white;
                border-width: 5px;
                margin-left: -5px;
            }
            .option {
              display: block;
              width: 100%;
              line-height: 20px;
              cursor: pointer;
              padding: 3px 14px;
              overflow: hidden;
              &:hover {
                color: $color-jungle-green;
              }
            }
          }
        }
        .text-pair {
          font-size: $font-small;
          font-family: $font-family-roboto;
          color: $color-grey-dusty;
          margin-bottom: 5px;
        }
      }
      .pair {
        padding: 0px 20px;
        input {
          width: 65px;
        }
      }
    }
    .form-control {
      background: $color-white-smoke;
      height: 30px;
      font-size: $font_small;
      padding-left: 11px;
      padding-right: 0;
    }
    .toolbar-element2 {
      display: inline-block;
      float: left;
    }
    .bw-element {
      float: left;
      font-size: $font_medium;
      margin-left: 5px;
      height: 30px;
      line-height: 30px;
      margin-right: 5px;
      color: $color-grey-dusty;
    }
    .datatable{
      button.btnForm.bt-action{
        padding-left: 5px !important;
        padding-right: 5px !important;
        font-weight: 400 !important;
      }
    }
  }
  tbody {
    td {
      font-family: $font-family-roboto;
    }
  }
  .col_time {
    width: 15%;
  }
  .col_email {
    width: 25%;
  }
  .col_currency {
    width: 15%;
  }
  .col_amount {
    width: 15%;
  }
  .col_total {
    width: 15%;
  }
  .col_bonus {
    width: 15%;
  }
</style>