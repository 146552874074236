<template>
  <div id="reward_center" class="reward_center mg_bot50">
    <div class="header header-action">
      <div class="header-add-reward">
        <button class="btn btn-add-new" @click="createReward" >
          <img class="" src="@/assets/images/icon/plusWhite.png">
          {{ $t('instruments.create') }}
        </button>
      </div>
      <div class="header-search">
        <input
          title
          type="text"
          :placeholder="$t('common.search')"
          @keyup.enter="search"
          class="form-control"
          name="searchKey"
          maxlength="30"
          v-model="searchKey"
        >
        <img class="icon-search" src="@/assets/images/icon/search.svg">

      </div>
    </div>
    <div class="header-tab-select">
      <div class="item-select"  :class=" contractType === 'USD_M'?  'selected' : ''" @click="contractType = 'USD_M'">USDⓈ-M</div>
      <div class="item-select" :class=" contractType === 'USD_M'?  '' : 'selected'" @click="contractType = 'COIN_M'">COIN-M</div>
    </div>
    <div class="datatable">
      <data-table6 :get-data="getData" ref="datatable"
                  :total-user="totalInstruments"
                  :in-line="true" :limit="10"
                  :msgEmptyData="$t('common.datatable.no_data2')"
                  :column="8" class="scroll">
        <!--        //:getData="" :totalUser="" ref="datatable" :limit="10" :column="8"-->
        <!--        @DataTable:finish=""-->
        <th class="" ></th>
        <th class="text-left w_120" >{{ $t('instruments.symbol') }}</th>
        <th class="text-center w_200" >{{ $t('instruments.underlying') }}</th>
        <th class="text-center w_120">{{ $t('instruments.collateral') }}</th>
        <th class="text-left w_200" v-if="contractType !== 'USD_M'" >{{ $t('instruments.contract_multipliter') }}</th>
        <th class="text-center w_200" >{{ $t('instruments.tarker_fee') }}</th>
        <th class="text-left w_120" >{{ $t('instruments.tick_size') }}</th>
        <th class="text-center w_200" >{{ $t('instruments.price_movement') }}</th>
        <th class="text-center w_200" >{{ $t('instruments.min_max_price') }}</th>
        <th class="text-center w_250" >{{ $t('instruments.limit_order_Price') }}</th>
        <th class="text-left w_200 "  >{{ $t('instruments.min_max_order_amt') }}</th>
        <th class="text-left w_200" >{{ $t('instruments.min_max_notional') }}</th>
        <th class="text-center w_200">{{ $t('instruments.max_leverage') }}</th>
        <th class="text-center w_250" v-if="contractType === 'USD_M'" >{{ $t('instruments.decimal_figures') }}</th>
        <th class="text-center w_250"  >{{ $t('instruments.decimal_figures_price') }}</th>
        <th class="text-center w_200" >{{ $t('instruments.decimal_figures_fee') }}</th>
        <th class="text-center w_200" >{{ $t('instruments.margin_notional') }}</th>
        <template slot="body" slot-scope="props">
          <tr v-bind:class="{inactive: props.item.status == 'inactive'}">
            <td class="text-left">
              <span type="button" @click="updateReward(props.item)">
                <img class="" src="@/assets/images/icon/edit-item.svg">
              </span>
            </td>
            <td class="text-left">
              <div class="item_email_user">
                <span class="txt_email_user">{{ props.item.symbol }}</span>
                <!--                <span class="tooltip_email_user">{{ props.item.name }}</span>-->
              </div>
            </td>
            <td class="text-center">
              <span>{{ props.item.underlyingSymbol }}</span>
            </td>
            <td class="text-center">
              <span>{{ props.item.collateralAsset | uppercase }}</span>
            </td>
            <td class="text-center" v-if="contractType === 'COIN_M'">
              <span>{{ props.item.multiplier }}</span>
            </td>
            <td class="text-center">
              <span>{{ props.item.makerFee }} / <br/> {{ props.item.takerFee}}</span>
            </td>
            <td class="text-center">
              <span>{{ props.item.tickSize  }}</span>
            </td>
            <td class="text-center">
              <span>{{ props.item.minPriceMovement  }}</span>
            </td>
            <td class="text-center">
              <span>{{ props.item.minPrice }} / <br/> {{props.item.maxPrice}} </span>
            </td>
            <td class="text-center">
              <span>{{ props.item.limitOrderPrice }} / <br/> {{props.item.floorRatio}} </span>
            </td>
            <td class="text-center">
              <span>{{ props.item.minOrderAmount }} / <br/> {{props.item.maxOrderAmount}} </span>
            </td>
            <td class="text-center">
              <span>{{ props.item.minNotional }} / <br /> {{props.item.maxNotinal}} </span>
            </td>
            <td class="text-center">
              <span>{{ props.item.maxLeverage }}</span>
            </td>
            <td class="text-center" v-if="contractType === 'USD_M'">
              <span>{{ props.item.maxFiguresForSize }}</span>
            </td>
            <td class="text-center">
              <span>{{ props.item.maxFiguresForPrice }}</span>
            </td>
            <td class="text-center">
              <span>{{ props.item.liqClearanceFee }}</span>
            </td>
            <td class="text-center">
              <span>{{ props.item.impactMarginNotional }}</span>
            </td>
          </tr>
        </template>
      </data-table6>
    </div>
  </div>

</template>

<script>
import InputOnlyNumber from "@/common/InputOnlyNumber.vue";
import InputTextAndNumber from "@/components/InputTextAndNumber.vue";
import Modal from "@/components/Modal.vue";
import DatePicker from "vuejs-datepicker";
import SelectBox from "@/components/SelectBox.vue";
import DataTable6 from "@/components/DataTable6.vue";
import CoinInput from "@/common/CoinInput.vue";
import datetime from "vuejs-datetimepicker";
import NumberInput from "../../common/NumberInput";
import rf from "@/lib/RequestFactory";
import moment from "moment/moment";
import dataTable from "@/components/DataTable.vue";

export default {
  components: {
    InputOnlyNumber,
    InputTextAndNumber,
    Modal,
    DatePicker,
    SelectBox,
    DataTable6,
    CoinInput,
    datetime,
  },
  data() {
    return {
      titlePage: window.i18n.t("menu.futures.market"),
      searchKey: '',
      dataTable: [],
      totalInstruments: 0,
      contractType: 'USD_M'
    }
  },
  watch: {
    contractType() {
      this.$refs.datatable.refresh()
    }
  },
  methods: {
    getData(params) {
      const paramsSearch = {
        "search": this.searchKey.trim(),
        "contractType": this.contractType
      }
      return rf.getRequest('AdminRequest').
      getInstrumentFuture(Object.assign(paramsSearch, params)).then(res => {
        this.totalInstruments = res.metadata.count
        this.dataTable = res.data
        return res.data
      })
    },
    search() {
      this.getData()
      this.$refs.datatable.refresh();
    },
    createReward() {
      this.$router.push({name: 'Create Market Futures', query: {contract: this.contractType}});
    },
    confirmRemoveReward(value) {
      window.ConfirmationModal.show({
        title: window.i18n.t('reward.remove_reward_center'),
        type: 'reward',
        class: 'reward-center',
        // content: window.i18n.t('reward.remove_reward_center'),
        btnConfirmLabel: window.i18n.t('common.action.yes'),
        btnCancelLabel: window.i18n.t('common.action.no'),
        onConfirm: () => {
          this.removeDevice(value);
        }
      });
    },
    updateReward(value) {
      if(value.underlyingSymbol) {
        this.$router.push({
          path: '/market-futures/update/',
          query: {id: value.underlyingSymbol, unique: value.id, contract: this.contractType},
        });
      }
    },
  },
  mounted() {
    this.$emit('EVENT_PAGE_CHANGE', this);
  },
  created() {
    this.getData()
  }
}

</script>

<style scoped lang="scss">
@import "@/assets/sass/variables";

#reward_center {
  .header {
    display: flex;
    justify-content: space-between;
    margin: 24px 0;
    align-items: center;

    .header-add-reward {
      .btn-add-new {
        //border: 1px solid $color-caribbean-green;
        display: flex;
        align-items: center;
        gap: 10px;
        width: 130px;
        max-width: 100%;
        font-size: $font_semi_big;
        color: $color-white;
        background-color: $color-caribbean-green;
        padding: 10.5px 22px;
        border-radius: 30px;
        font-family: $mulish-bold;
        height: 41px;

        span {
          margin-right: 5px;
        }
        img {
          width: 18px;
        }
      }
    }


    .header-search {
      gap: 10px;
      width: 300px;
      height: 32px;
      font-family: $mulish-medium;
      position: relative;

      .form-control {
        width: 100%;
        padding: 7px 35px;
        border-radius: 10px !important;
      }

      .icon-search {
        position: absolute;
        top: 25%;
        margin-left: 10px;
      }
    }


  }
  .header-tab-select {
    display: flex;
    gap: 30px;
    font-size:16px;
    margin-bottom: 30px;
    .item-select {
      cursor: pointer;
      &.selected {
        border-bottom: 4px solid #00DDB3;
      }

    }
  }
  ::-webkit-scrollbar {
    width: 12px !important;
    height: 6px;
  }
  ::-webkit-scrollbar:horizontal {
    height: 6px;
  }

  .w_70 {
    min-width: 70px;
  }
  .w_120{
    min-width: 120px;
  }
  .w_150{
    min-width: 150px;
  }

  .w_129{
    min-width: 129px;
  }
  .w_200{
    min-width: 200px;
  }
  .w_250{
    min-width: 250px;
  }
  .w_130{
    min-width: 130px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    //background: red;
    //width: 200px;
    display: none;
    background-color: transparent;
    border: none;
    outline: none;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #D9DCE1;
    border-radius: 20px;
    width: 200px;
  }
  .d-flex {
    display: flex;
    gap: 16px;
  }
  :deep(.tableContainer) {
    table {
      border-collapse: collapse;
      border-radius: 10px;
      display: block;
      overflow-x: auto;
      thead {
        tr  {
          white-space: break-spaces;
          vertical-align: baseline;
          th {
            font-size: $font-root;
            font-family: $mulish-medium;
            color: $dark-3;
            white-space: pre-line;
            padding-top: 16px;
            padding-bottom: 16px;
            vertical-align: middle;
          }
          th:first-child {
            min-width: 50px;
          }
        }
      }

      tr {
        td > div > span {
          font-family: $mulish-medium;
          font-size: $font-root;
          line-height: 25px;
          color: $dark-1;
        }
      }
    }
    .pagination {
      display:  flex;
      width: 100%;
      align-items: center;
      #total_user {
        width: 8% ;
        padding-bottom: 5px;
        margin-right: 5px;
        font-family: $mulish-medium;
        line-height: 25px;
        color: $dark-1;
      }
      .text-center {
        width: 100%;
        .VuePagination {
          width: 100%;
          display: flex;
          ul {
            justify-content: end;
          }
        }
      }
    }
  }
  :deep(.group_search_select) {
    max-width: 100%;
    display: flex;
    align-items: center;
    .button_drop_search {
      width: 100%;
      padding: 1px 7px;
      border-radius: 5px !important;
      border: 1px solid #C7CBD3 ;
      display: flex;
      align-items: center;
      gap: 7px;
      span {
        font-family: $mulish-medium;
        font-size: $font-root;
        line-height: 20px;
        color: $dark-1 !important;
      }
    }
    .box_list_search_select {
      .list_search_select {
        border-radius: 12px;
        > li {
          padding: 10.5px 16px;
          > span {
            font-family: $mulish-medium;
            font-size: 16px;
            line-height: 20px;
          }
        }
        > li:hover {
          background: $color-athens;
        }
      }
    }
  }
  :deep(.VuePagination__pagination){
    .page-link {
      background: #ffffff;
      border-radius: 5px !important;
      font-size: 14px;
      color: $dark-1;
      border: 1px solid #C7CBD3;
    }
    & .active > a {
      border: 1px solid #3A7DFF;
      color: #3A7DFF;
    }
  }
}


</style>