<template>
  <div class="tableContainer" :style="{ width: widthTable }">
    <div class="box-table4">
      <table>
        <slot name="colgroup_slot" />
        <thead>
          <tr @click="onSort">
            <slot />
          </tr>
        </thead>
        <tbody>
          <slot name="first_row" />
          <slot name="body" v-for="(row, index) in rows" :item="row" :index="index" />

          <template v-if="this.rows.length === 0">
            <tr class="empty-data">
              <td :colspan="column">
                <span class="icon-nodata"></span>
                <span>{{ msgEmptyData || $t('common.datatable.no_data') }}</span>
              </td>
            </tr>
          </template>
          <!--
          <template v-for="row in emptyRow">
            <tr>
              <template v-for="col in column">
                <td></td>
              </template>
            </tr>
          </template>
          -->
          <slot name="end_row" />
        </tbody>
      </table>
    </div>
    <template v-if="totalUser > 0 && !inLine">
      <div class="total_records">Total: <strong>{{ totalUser }} </strong> </div>
    </template>
    <template>
      <div class="pagination">
        <div class="total_records" v-if="totalUser > 0 && inLine">Total: <strong>{{ totalUser }} </strong> </div>
        <pagination v-if="lastPage > 1 || visiblePagination" ref="pagination" class="text-center pagging"
          :per-page="perPage" :records="totalRecord" :chunk="chunk" @change-limit="onChangeLimit($event)"
          @Pagination:page="onPageChange" :pageParent="page"></pagination>
      </div>
    </template>
  </div>
</template>

<script>
import Pagination from "./Pagination";

export default {
  components: {
    Pagination
  },
  props: {
    getData: {
      type: Function
    },
    limit: {
      type: Number,
      default: 10
    },
    column: {
      type: Number,
      default: 0
    },
    chunk: {
      type: Number,
      default: 6
    },
    widthTable: {
      type: String,
      default: "100%"
    },
    msgEmptyData: {
      type: String
    },
    storageName: {
      type: String,
      default: 'none'
    },
    totalUser: {
      type: Number,
      default: 0
    },
    inLine: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      visiblePagination: false,
      internalLimit: 0,
      maxPageWidth: 10,
      totalRecord: 0,
      lastPage: 0,
      page: 1,
      perPage: 10,
      fetching: false,
      rows: [],
      params: {},

      orderBy: null,
      sortedBy: null
    };
  },
  computed: {
    emptyRow() {
      let emptyRowCount = Math.max(this.internalLimit - _.size(this.rows), 0);
      return Math.min(emptyRowCount, this.internalLimit);
    }
  },
  watch: {
    limit(newValue) {
      this.internalLimit = newValue;
    }
  },
  methods: {
    onChangeLimit(limit) {
      if (this.storageName !== 'none') {
        if (!localStorage.getItem(this.storageName)) {
          try {
            let storageData = JSON.parse(localStorage.getItem(this.storageName));
            storageData.limit = limit;

            localStorage.setItem(this.storageName, JSON.stringify(storageData));
          }
          catch (ex) {
            console.error(ex);
          }
        }
      }

      this.visiblePagination = true;
      this.internalLimit = limit;
      // this.refresh();
      this.page = 1;
      this.fetch();
    },

    onPageChange(page) {
      if (this.storageName !== 'none') {
        if (!localStorage.getItem(this.storageName)) {
          try {
            let storageData = JSON.parse(localStorage.getItem(this.storageName));
            storageData.page = page;

            localStorage.setItem(this.storageName, JSON.stringify(storageData));
          }
          catch (ex) {
            console.error(ex);
          }
        }
      }

      this.page = page;
      this.fetch();
    },

    getTarget(target) {
      let node = target;
      while (node.parentNode.nodeName !== "TR") {
        node = node.parentNode;
      }
      return node;
    },

    getSortOrder(target) {
      let sortOrder = target.dataset.sortOrder;
      switch (sortOrder) {
        case "asc":
          sortOrder = "";
          break;
        case "desc":
          sortOrder = "asc";
          break;
        default:
          sortOrder = "desc";
      }
      return sortOrder;
    },

    setSortOrders(target, sortOrder) {
      let iterator = target.parentNode.firstChild;
      while (iterator) {
        iterator.dataset.sortOrder = "";
        iterator = iterator.nextElementSibling;
      }
      target.dataset.sortOrder = sortOrder;
    },

    onSort(event) {
      const target = this.getTarget(event.target);
      const orderBy = target.dataset.sortField;
      if (!orderBy) {
        return;
      }
      this.sortedBy = this.getSortOrder(target);
      this.orderBy = this.sortedBy ? orderBy : "";
      Object.assign(this.params, {
        sort: this.orderBy,
        sort_type: this.sortedBy
      });
      this.setSortOrders(target, this.sortedBy);
      this.fetch();
    },

    fetch() {
      const meta = {
        page: this.page,
        limit: this.internalLimit
      };

      this.fetching = true;
      Object.assign(this.params, {
        sort: this.orderBy,
        sort_type: this.sortedBy
      });
      this.getData(Object.assign(meta, this.params))
        .then(res => {
          const data = res.data;
          if (!data) {
            return;
          }
          if (!data.data) {

            this.rows = data;
            this.page = parseInt(data.current_page)
              ? parseInt(data.current_page)
              : parseInt(res.current_page);
            this.totalRecord = parseInt(data.total)
              ? parseInt(data.total)
              : parseInt(res.total);
            this.lastPage = parseInt(data.last_page)
              ? parseInt(data.last_page)
              : parseInt(res.last_page);
            this.perPage = parseInt(data.per_page)
              ? parseInt(data.per_page)
              : parseInt(res.per_page);
            this.$emit("DataTable:finish");
            return;
          }
          this.page = parseInt(data.current_page);
          this.totalRecord = parseInt(data.total);
          this.lastPage = parseInt(data.last_page);
          this.perPage = parseInt(data.per_page);
          this.rows = data.data;
          this.$emit("DataTable:finish");
        })
        .then(res => {
          this.fetching = false;
        });
    },

    refresh() {
      this.page = 1;
      this.params = {};
      this.orderBy = null;
      this.sortedBy = null;
      this.fetch();
    },

    filter(params) {
      this.page = 1;
      this.params = params;
      this.fetch();
    }
  },
  created() {
    this.internalLimit = this.limit;
    this.$on("DataTable:filter", params => {
      this.filter(params);
    });
    if (this.$route.params.pagination) {
      this.page = this.$route.params.pagination.page;
      this.internalLimit = this.$route.params.pagination.limit;
    }
    if (this.$route.params.params) {
      this.params = this.$route.params.params;
    }
    this.fetch();
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/variables";

.box-table4 {
  padding: 10px 20px;
  background-color: $color_white;
  border-radius: 10px;
  overflow: hidden;
  overflow-x: auto;

  &::-webkit-scrollbar {
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: #ddd;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #ccc;
  }
}

table {
  width: 100%;
  background-color: $color_white;
  border-radius: 10px;
  // border: $border_thin;
  overflow-y: hidden;

  thead {
    color: $color_grey;
    background-color: $color_white;
    border: none;
    overflow: hidden;

    th {
      user-select: none;
      position: relative;
      background-color: $color_white;
      line-height: 15px;
      font-size: $font_small;
      font-weight: 500;
      color: $color_grey;
      padding: 15px 15px;
      border-bottom: 1px solid $color_alto;
      font-family: $mulish-regular;
      height: 38px;

      &::after {
        font-family: "icomoon" !important;
        font-size: $font_small;
        margin-left: 5px;
        position: relative;
        top: 0px;
        width: 14px;
        height: 14px;
        display: inline-block;
        line-height: 14px;
        overflow: hidden;
        position: relative;
        top: 3px;
        content: "";
      }

      &[data-sort-order] {
        line-height: 15px;

        &::after {
          font-family: "icomoon" !important;
          font-size: $font_small;
          margin-left: 5px;
          position: relative;
          top: 0px;
          width: 14px;
          height: 14px;
          display: inline-block;
          line-height: 14px;
          overflow: hidden;
          position: relative;
          top: 3px;
          content: "";
        }
      }

      &[data-sort-order="asc"] {
        color: #2dac91;

        &::after {
          content: "\e906";
        }
      }

      &[data-sort-order="desc"] {
        color: #2dac91;

        &::after {
          content: "\e907";
        }
      }
    }
  }

  tbody {
    tr {
      vertical-align: top;
      overflow-y: hidden;
      transition-property: height;
      transition-duration: 0.3s, 0.3s;
      transition-timing-function: ease, ease-in;
      height: auto;
      background-color: $color_white;
      border-bottom: 1px solid $color_catskill_white;

      &.active {
        height: 100px;
        max-height: 300px;
        background-color: $color_grey_select;
        transition-property: height;
        transition-duration: 0.3s, 0.3s;
        transition-timing-function: ease, ease-in;

        .glyphicon-menu-down {
          transition-duration: 0.5s;
          transform: rotate(180deg);
        }
      }

      &.empty-data {
        td {
          text-align: center;
          color: $color_grey;
          line-height: 200px;

          p {
            margin: 0px;
          }
        }
      }
    }

    td {
      height: 40px;
      overflow: initial;
      line-height: 23px;
      font-size: $font_root;
      font-weight: 500;
      color: $color-grey-dark;
      padding: 8px 25px 4px 15px;
      border-top: 1px solid $color-white-smoke;
      vertical-align: middle;
      font-family: $mulish-regular;
    }
  }
}

.pagination {
  margin: 15px 0;
  display: flex;
  align-items: center;

  .total_records {
    display: inline-block;
    margin-right: 11px;
    font-family: $mulish-regular;
    white-space: nowrap;

    strong {
      font-family: $mulish-medium;
    }
  }

  .pagging {
    width: calc(100% - 70px);

    ::v-deep {
      .button_drop_search {
        background: $color-white;
        border: 1px solid #C7CBD3;
        border-radius: 5px;
        height: 32px;
        min-width: 54px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .VuePagination__pagination {
        li {
          background: #FFFFFF;
          border: 1px solid #C7CBD3;
          border-radius: 5px;
          margin-right: 8px;

          a {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 32px;
            min-width: 32px;
            margin-right: 0 !important;
          }

          &:last-child {
            margin-right: 0;
          }
        }

        li.active a {
          color: $color-caribbean-green !important;
        }

        .active {
          border: 1px solid $color-caribbean-green;
        }

        .disabled {}
      }
    }
  }

}
</style>
<style lang="scss">
#total_user {
  margin-top: 8px;
}
</style>
