import Vue from 'vue'
import VueRouter from 'vue-router'
import NotFound from './pages/NotFound'

//==================== Dashboard ======================
import Dashboard from './pages/dashboard/Dashboard'

// ========================= Users Item =========================
import Users from './pages/users/Users'
import Kyc from './pages/users/Kyc'
import KycSumsub from './pages/users/KycSumsub'
import KycDetail from './pages/users/KycDetail'
import KycDetailSumsub from './pages/users/KycDetailSumsub'
import UserSetting from './pages/users/UserSetting'
import UserEnableFeeSetting from './pages/users/UserEnableFeeSetting'
import EnableWithdrawalSetting from './pages/exchange/EnableWithdrawalSetting'
import EnableTradingSetting from './pages/exchange/EnableTradingSetting'
import DeviceManagement from './pages/users/DeviceManagement'
import UserGroupSetting from './pages/users/UserGroupSetting'
import UserAMALNetHolding from './pages/users/UserAMALNetHolding'
import CalculateProfit from './pages/exchange/CalculateProfit'
import TradingVolumeRanking from './pages/leaderboard/TradingVolumeRanking'

// ======================== Permssion Item =======================
import Permissions from './pages/permission/Permissions'
import ChangePermission from './pages/permission/ChangePermission'

// ======================== Exchange Item ========================
import WithdrawSetting from './pages/exchange/WithdrawSetting'
import TradingFeeSetting from './pages/exchange/TradingFeeSetting'
import WithdrawFeeSetting from './pages/exchange/WithdrawFeeSetting'
import CircuitBreakerSetting from './pages/circuit_breaker/CircuitBreakerSetting'
import PriceSetting from './pages/sales_point/PriceSetting'
import SiteSetting from './pages/site_settings/SiteSetting'
import BankSetting from './pages/exchange/BankSetting.vue'
import ExchangeBalanceDetail from './pages/exchange/ExchangeBalanceDetail'
// ========================== Orders Item ========================
import OpenOrder from './pages/orders/OpenOrder'
import OrderBook from './pages/orders/OrderBook'
import OrderHistory from './pages/orders/OrderHistory'
import TradeHistory from './pages/orders/TradeHistory'

// ======================= Transaction Item ======================
import DepositFiat from './pages/transactions/DepositFiat'
import WithdrawFiat from './pages/transactions/WithdrawFiat'
import Deposit from './pages/transactions/Deposit'
import Withdraw from './pages/transactions/Withdraw'

// ======================== Marketing Item =======================
import NoticeList from './pages/marketing/NoticeList'
import NoticeEdit from './pages/marketing/NoticeEdit'
import EmailMarketingList from './pages/marketing/EmailMarketingList'
import EmailMarketingEdit from './pages/marketing/EmailMarketingEdit'
import EmailMarketingSend from './pages/marketing/EmailMarketingSend'
import ExchangeBalance from './pages/exchange/ExchangeBalance'

//=========================== Wallet ===============================
import RegisterERC20 from './pages/register_erc20/RegisterERC20'
import ColdWalletSetting from './pages/wallet/ColdWalletSetting'
import AirdropHistory from './pages/airdrop/AirdropHistory'
import AirdropPaymentHistory from './pages/airdrop/AirdropPaymentHistory'
import CashbackHistory from './pages/airdrop/CashbackHistory'
import AirdropSetting from './pages/airdrop/AirdropSetting'
import AutoDividendSetting from './pages/airdrop/AutoDividendSetting'
import AutoDividendHistory from './pages/airdrop/AutoDividendHistory'
import ManualDividendHistory from './pages/airdrop/ManualDividendHistory'

// ======================== Account Item ========================
import AccountList from "./pages/accounts/AccountList";
import AccountDetail from "./pages/accounts/AccountDetail";

// ========================= Salespoint =========================
import BuyHistory from './pages/salespoint/BuyHistory'

// ============================Fund=====================
import DepositFund from "./pages/fund/DepositFund";
import WithdrawalsFund from "./pages/fund/WithdrawalsFund";

// ============================Referral Item=====================
import ReferralHistory from './pages/referral/ReferralHistory'
import ReferralSetting from './pages/referral/ReferralSetting'
import DividendSettingManual from './pages/airdrop/DividendSettingManual'

// ========================== Margin Orders Item ========================
import MarginOrder from './pages/margin_orders/Order'
import MarginPosition from './pages/margin_orders/Position'
import MarginTradeHistory from './pages/margin_orders/TradeHistory'
import FundingHistory from './pages/margin_orders/FundingHistory.vue'
import MarginTradingSetting from './pages/margin_orders/TradingSetting'

// ============================Instruments=====================
import Instruments from './pages/instruments/Instruments.vue'
import InstrumentResource from './pages/instruments/InstrumentResource'
import RiskManagement from './pages/margin_orders/RiskManagement'
import BitmexMappingInstrument from './pages/bitmex/BitmexMappingInstrument'
import BitmexAccount from './pages/bitmex/BitmexAccount.vue'
import TracedAccount from './pages/bitmex/TracedAccount.vue'
import MappingOrder from './pages/bitmex/MappingOrder'
import EntryManagement from './pages/margin_orders/EntryManagement'
import EntryLeaderboardPaymentManual from './pages/margin_orders/EntryLeaderboardPaymentManual.vue'
import ManualEntryLeaderboardPaidHistory from './pages/margin_orders/ManualEntryLeaderboardPaidHistory'
import InstrumentsFutures from './pages/instruments_futures/InstrumentsFuture.vue'

// ============================Reward Center=====================
import RewardCenter from './pages/reward_center/RewardCenter.vue'
import HotSearch from './pages/hot_search/HotSearch.vue'

import RewardCenterResource from './pages/reward_center/RewardCenterResource.vue'
import CreateInstruments from "@/pages/instruments_futures/CreateInstruments.vue";
import CreateNewTier from "@/pages/instruments_futures/CreateNewTier.vue";
import UpdateInstruments from "@/pages/instruments_futures/UpdateInstruments.vue";
// import OrderFuturesRecords from "./pages/futures_records/OrderFuturesRecords";
// import PositionFuturesRecords from "./pages/futures_records/PositionFuturesRecords";
// import TradeFuturesRecords from "./pages/futures_records/TradeFuturesRecords";
import MarketFutures from "./pages/futures_records/MarketFutures";
import OpenOrderFutures from "./pages/futures_records/OpenOrderFutures";
import OrderBookFutures from "./pages/futures_records/OrderBookFutures";
import OrderHistoryFutures from "./pages/futures_records/OrderHistoryFutures";
import TradeHistoryFutures from "./pages/futures_records/TradeHistoryFutures";
import OpenPositionFutures from "./pages/futures_records/OpenPositionFutures";
import PositionHistoryFutures from "./pages/futures_records/PositionHistoryFutures";
////////////// CMS //////////////////////////
import NotificationList from "./pages/cms/Notification/NotificationList.vue";
import NotificationDetail from "./pages/cms/Notification/NotificationDetail.vue";
import NotificationEdit from "./pages/cms/Notification/NotificationEdit.vue";
import MailList from "./pages/cms/MailTemplate/MailList.vue";
import MailDetail from "./pages/cms/MailTemplate/MailDetail.vue";
import MailEdit from "./pages/cms/MailTemplate/MailEdit.vue";
import AnnouncementList from "./pages/cms/Announcement/AnnouncementList.vue";
import AnnouncementDetail from "./pages/cms/Announcement/AnnouncementDetail.vue";
import AnnouncementEdit from "./pages/cms/Announcement/AnnouncementEdit.vue";
import FaqList from "./pages/cms/FAQ/FaqList.vue";
import FaqDetail from "./pages/cms/FAQ/FaqDetail.vue";
import FaqEdit from "./pages/cms/FAQ/FaqEdit.vue";
import QnaList from "./pages/cms/QNA/QnaList.vue";
import QnaDetail from "./pages/cms/QNA/QnaDetail.vue";
import QnaEdit from "./pages/cms/QNA/QnaEdit.vue";

// ============================Orderbook Bots=====================
import Bots from "./pages/orderbook_bots/Bots.vue";
import BotEdit from "./pages/orderbook_bots/BotEdit.vue";

// ================================ Resource =====================
import Networks from "./pages/resources/Networks.vue";
import NetworkEdit from "./pages/resources/NetworkEdit.vue";
import Coins from "./pages/resources/Coins.vue";
import CoinEdit from "./pages/resources/CoinEdit.vue";
import Wallets from "./pages/resources/Wallet.vue";
import AdminAccount from './pages/administrator/admin_account/AdminAccount.vue'
import AdminAccountEdit from './pages/administrator/admin_account/AdminAccountEdit.vue'
import Roles from './pages/administrator/role/Roles.vue'
import RoleEdit from './pages/administrator/role/RoleEdit.vue'

Vue.use(VueRouter)

const dividend = [
  {
    path: '/traced-account',
    name: 'TracedAccount',
    component: TracedAccount,
  },
  {
    path: '/mapping-order',
    name: 'MappingOrder',
    component: MappingOrder,
  },
  {
    path: '/bitmex-account',
    name: 'BitmexAccount',
    component: BitmexAccount,
  },
  {
    path: '/dividend-history',
    name: 'AirdropHistory',
    component: AirdropHistory,
  },
  {
    path: '/dividend-payment-history',
    name: 'AirdropPaymentHistory',
    component: AirdropPaymentHistory,
  },
  {
    path: '/cashback-history',
    name: 'CashbackHistory',
    component: CashbackHistory,
  },
  {
    path: '/dividend-setting',
    name: 'AirdropSetting',
    component: AirdropSetting,
  },
  {
    path: '/dividend-setting-manual',
    name: 'DividendSettingManual',
    component: DividendSettingManual,
  },
  {
    path: '/auto-dividend-setting',
    name: 'AutoDividendSetting',
    component: AutoDividendSetting,
  },
  // {
  //     "path": '/manual-dividend-setting',
  //     "name": 'ManualDividendSetting',
  //     "component": ManualDividendSetting
  // },
  {
    path: '/auto-dividend-history',
    name: 'AutoDividendHistory',
    component: AutoDividendHistory,
  },
  {
    path: '/manual-dividend-history',
    name: 'ManualDividendHistory',
    component: ManualDividendHistory,
  }
]

const routes = [
  {
    path: '/',
    redirect: { name: 'dashboard' },
    alias: '/home',
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard,
  },
  {
    path: '/deposit-usd',
    name: 'Deposit USD',
    component: DepositFiat,
  },
  {
    path: '/withdraw-usd',
    name: 'Withdraw USD',
    component: WithdrawFiat,
  },
  {
    path: '/deposit',
    name: 'Deposit',
    component: Deposit,
  },
  {
    path: '/withdraw',
    name: 'Withdraw',
    component: Withdraw,
  },
  {
    path: '/profit',
    name: 'profit',
    component: CalculateProfit,
  },
  {
    path: '/kyc',
    name: 'kyc',
    component: Kyc,
  },
  {
    path: '/kyc-sumsub',
    name: 'kyc-sumsub',
    component: KycSumsub,
  },
  {
    path: '/amal-net',
    name: 'amal_net',
    component: UserAMALNetHolding,
  },
  {
    path: '/users',
    name: 'users',
    component: Users,
  },
  {
    path: '/kyc/detail',
    name: 'kyc-detail',
    component: KycDetail,
  },
  {
    path: '/kyc-sumsub/detail',
    name: 'kyc-sumsub-detail',
    component: KycDetailSumsub,
  },
  {
    path: '/orders/market',
    name: 'market_spot',
    component: RegisterERC20,
  },
  {
    path: '/orders/open-order',
    name: 'open_order',
    component: OpenOrder,
  },
  {
    path: '/orders/order-book',
    name: 'order_book',
    component: OrderBook,
  },
  {
    path: '/orders/history',
    name: 'order_history',
    component: OrderHistory,
  },
  {
    path: '/notices',
    name: 'Notice',
    component: NoticeList,
  },
  {
    path: '/notice/edit',
    name: 'notice-edit',
    component: NoticeEdit,
  },
  {
    path: '/orders/trade-history',
    name: 'trade_history',
    component: TradeHistory,
  },
  {
    path: '/email-marketing',
    name: 'Emails Marketing',
    component: EmailMarketingList,
  },
  {
    path: '/email-marketing/edit',
    name: 'emailMarketingEdit',
    component: EmailMarketingEdit,
  },
  {
    path: '/email-marketing/create',
    name: 'emailMarketingCreate',
    component: EmailMarketingEdit,
  },
  {
    path: '/email-marketing/send',
    name: 'emailMarketingSend',
    component: EmailMarketingSend,
  },
  {
    path: '/user-setting',
    name: 'user_setting',
    component: UserSetting,
  },
  {
    path: '/user-enable-fee-setting',
    name: 'user_enable_fee_setting',
    component: UserEnableFeeSetting,
  },
  {
    path: '/account',
    name: 'account_list',
    component: AccountList,
  },
  {
    path: '/account/detail',
    name: 'account_detail',
    component: AccountDetail,
  },
  {
    path: '/account/kyc',
    name: 'account_kyc',
    component: KycSumsub,
  },
  {
    path: '/account/kyc/detail',
    name: 'account_kyc_detail',
    component: KycDetailSumsub,
  },
  {
    path: '/fund-withdraw',
    name: 'WithdrawalsFund',
    component: WithdrawalsFund,
  },
  {
    path: '/fund-deposit',
    name: 'DepositFund',
    component: DepositFund,
  },
  {
    path: '/enable-withdrawal-setting',
    name: 'enable_withdrawal_setting',
    component: EnableWithdrawalSetting,
  },
  {
    path: '/enable-trading-setting',
    name: 'enable_trading_setting',
    component: EnableTradingSetting,
  },
  {
    path: '/device-management',
    name: 'device_management',
    component: DeviceManagement,
  },
  {
    path: '/user-group-setting',
    name: 'user_group_setting',
    component: UserGroupSetting,
  },
  {
    path: '/withdraw-setting',
    name: 'withdraw_setting',
    component: WithdrawSetting,
  },
  {
    path: '/trading-fee-setting',
    name: 'trading_fee_setting',
    component: TradingFeeSetting,
  },
  {
    path: '/withdraw-fee-setting',
    name: 'withdraw_fee_setting',
    component: WithdrawFeeSetting,
  },
  {
    path: '/circuit-breaker-setting',
    name: 'circuit_breaker_setting',
    component: CircuitBreakerSetting,
  },
  {
    path: '/bank-setting',
    name: 'bank_setting',
    component: BankSetting,
  },
  {
    path: '/site-setting',
    name: 'site_setting',
    component: SiteSetting,
  },
  {
    path: '/exchange-balance',
    name: 'exchange_balance',
    component: ExchangeBalance,
  },
  // {
  //     "path": '/exchange-balance-detail',
  //     "name": 'exchange_balance_detail',
  //     "component": ExchangeBalanceDetail,
  // },
  // {
  //   path: '/permissions',
  //   name: 'Permissions',
  //   component: Permissions,
  // },
  {
    path: '/permissions/edit',
    name: 'ChangePermission',
    component: ChangePermission,
  },
  {
    path: '/register-erc20',
    name: 'setting:register-erc20',
    component: RegisterERC20,
  },
  {
    path: '/sales-point',
    name: 'setting:sales-point',
    component: PriceSetting,
  },
  {
    path: '/salespoint/buy-history',
    name: 'buy_history',
    component: BuyHistory,
  },
  {
    path: '/cold-wallet-setting',
    name: 'ColdWalletSetting',
    component: ColdWalletSetting,
  },
  {
    path: '/referral-history',
    name: 'ReferralHistory',
    component: ReferralHistory,
  },
  {
    path: '/referral-setting',
    name: 'ReferralSetting',
    component: ReferralSetting,
  },
  {
    path: '/margin/order',
    name: 'OrderMargin',
    component: MarginOrder,
  },
  {
    path: '/margin/position',
    name: 'PositionsMargin',
    component: MarginPosition,
  },
  {
    path: '/margin/risk-management',
    name: 'RiskManagement',
    component: RiskManagement,
  },
  {
    path: '/margin/entry-management',
    name: 'EntryManagement',
    component: EntryManagement,
  },
  {
    path: '/margin/entry-payment-manual',
    name: 'EntryLeaderboardPaymentManual',
    component: EntryLeaderboardPaymentManual,
  },
  {
    path: '/margin/entry-payment-manual-paid',
    name: 'ManualEntryLeaderboardPaidHistory',
    component: ManualEntryLeaderboardPaidHistory,
  },
  {
    path: '/margin/trade-history',
    name: 'TradeHistoryMargin',
    component: MarginTradeHistory,
  },
  {
    path: '/margin/funding-history',
    name: 'FundingHistory',
    component: FundingHistory,
  },
  {
    path: '/margin/trade-setting',
    name: 'MarginTradingSetting',
    component: MarginTradingSetting,
  },
  {
    path: '/instruments',
    name: 'Instruments',
    component: Instruments,
  },
  {
    path: '/instruments-resource',
    name: 'InstrumentResource',
    component: InstrumentResource,
  },
  {
    path: '/instruments-futures',
    name: 'Instruments Futures',
    component: InstrumentsFutures,
  },
  {
    path: '/instruments-futures/create',
    name: 'Create Instruments Futures',
    component: CreateInstruments,
  },
  {
    path: '/instruments-futures/update',
    name: 'Update Instruments Futures',
    component: UpdateInstruments,
  },
  {
    path: '/instruments-futures/create',
    name: 'Create New Tier',
    component: CreateNewTier,
  },
  {
    path: '/trading-volume-ranking',
    name: 'tradingVolumeRanking',
    component: TradingVolumeRanking,
  },
  {
    path: '/mapping-instrument',
    name: 'BitmexMappingInstrument',
    component: BitmexMappingInstrument,
  },
  {
    path: '/reward-center',
    name: 'Reward Center',
    component: RewardCenter,
  },
  // {
  //   path: '/hot-search',
  //   name: 'Hot Search',
  //   component: HotSearch,
  // },
  {
    path: '/reward-center-resource',
    name: 'Reward Center Resource',
    component: RewardCenterResource,
  },
  // {
  //   path: '/order-future-record',
  //   name: 'OrderFuturesRecords',
  //   component: OrderFuturesRecords,
  // },
  // {
  //   path: '/position-future-record',
  //   name: 'PositionFuturesRecords',
  //   component: PositionFuturesRecords,
  // },
  // {
  //   path: '/trade-future-record',
  //   name: 'TradeFuturesRecords',
  //   component: TradeFuturesRecords,
  // },
  {
    path: '/market-future-record',
    name: 'MarketFutures',
    component: MarketFutures,
  },

  {
    path: '/market-futures/create',
    name: 'Create Market Futures',
    component: CreateInstruments,
  },
  {
    path: '/market-futures/update',
    name: 'Update Market Futures',
    component: UpdateInstruments,
  },
  {
    path: '/market-futures/create',
    name: 'Create New Tier Market',
    component: CreateNewTier,
  },
  {
    path: '/open-order-future-record',
    name: 'OpenOrderFutures',
    component: OpenOrderFutures,
  },
  {
    path: '/order-book-future-record',
    name: 'OrderBookFutures',
    component: OrderBookFutures,
  },
  {
    path: '/order-history-future-record',
    name: 'OrderHistoryFutures',
    component: OrderHistoryFutures,
  },
  {
    path: '/trade-history-future-record',
    name: 'TradeHistoryFutures',
    component: TradeHistoryFutures,
  },
  {
    path: '/open-position-future-record',
    name: 'OpenPositionFutures',
    component: OpenPositionFutures,
  },
  {
    path: '/position-history-future-record',
    name: 'PositionHistoryFutures',
    component: PositionHistoryFutures,
  },
  {
    path: '/notifications',
    name: 'NotificationList',
    component: NotificationList
  },
  {
    path: '/notifications/detail',
    name: 'NotificationDetail',
    component: NotificationEdit
  },
  {
    path: '/notifications/edit',
    name: 'NotificationEdit',
    component: NotificationEdit
  },
  {
    path: '/notifications/create',
    name: 'NotificationCreate',
    component: NotificationEdit
  },
  {
    path: '/mail-templates',
    name: 'MailList',
    component: MailList
  },
  {
    path: '/mail-templates/detail',
    name: 'MailDetail',
    component: MailDetail
  },
  {
    path: '/mail-templates/edit',
    name: 'MailEdit',
    component: MailEdit
  },
  {
    path: '/mail-templates/create',
    name: 'MailCreate',
    component: MailEdit
  },
  {
    path: '/announcements',
    name: 'AnnouncementList',
    component: AnnouncementList
  },
  {
    path: '/announcements/detail',
    name: 'AnnouncementDetail',
    component: AnnouncementDetail
  },
  {
    path: '/announcements/edit',
    name: 'AnnouncementEdit',
    component: AnnouncementEdit
  },
  {
    path: '/announcements/create',
    name: 'AnnouncementCreate',
    component: AnnouncementEdit
  },
  {
    path: '/faq',
    name: 'FaqList',
    component: FaqList
  },
  {
    path: '/faq/detail',
    name: 'FaqDetail',
    component: FaqDetail
  },
  {
    path: '/faq/edit',
    name: 'FaqEdit',
    component: FaqEdit
  },
  {
    path: '/faq/create',
    name: 'FaqCreate',
    component: FaqEdit
  },
  {
    path: '/qna',
    name: 'QnaList',
    component: QnaList
  },
  {
    path: '/qna/detail',
    name: 'QnaDetail',
    component: QnaDetail
  },
  {
    path: '/qna/edit',
    name: 'QnaEdit',
    component: QnaEdit
  },
  {
    path: '/qna/create',
    name: 'QnaCreate',
    component: FaqEdit
  },
  {
    path: '/orderbook-bots',
    name: 'OrderbookBots',
    component: Bots
  },
  {
    path: '/orderbook-bots/edit',
    name: 'OrderbookBotEdit',
    component: BotEdit
  },
  {
    path: '/orderbook-bots/create',
    name: 'OrderbookBotCreate',
    component: BotEdit
  },
  {
    path: '/administrator/account',
    name: 'AdminAccount',
    component: AdminAccount
  },
  {
    path: '/administrator/account/edit',
    name: 'AdminAccountEdit',
    component: AdminAccountEdit
  },
  {
    path: '/administrator/account/create',
    name: 'AdminAccountCreate',
    component: AdminAccountEdit
  },
  {
    path: '/administrator/role',
    name: 'Roles',
    component: Roles
  },
  {
    path: '/administrator/role/edit',
    name: 'RoleEdit',
    component: RoleEdit
  },
  {
    path: '/administrator/role/create',
    name: 'RoleCreate',
    component: RoleEdit
  },
  // ////////////////////////////// 
  {
    path: '/networks',
    name: 'Networks',
    component: Networks
  },
  {
    path: '/network/edit',
    name: 'NetworkEdit',
    component: NetworkEdit
  },
  {
    path: '/network/create',
    name: 'NetworkCreate',
    component: NetworkEdit
  },
  {
    path: '/coins',
    name: 'Coins',
    component: Coins
  },
  {
    path: '/coin/edit',
    name: 'CoinEdit',
    component: CoinEdit
  },
  {
    path: '/coin/create',
    name: 'CoinCreate',
    component: CoinEdit
  },
  {
    path: '/wallets',
    name: 'Wallets',
    component: Wallets
  },
  ...dividend,
  { path: '*', name: 'Not Found', component: NotFound },
]

const router = new VueRouter({
  mode: 'history',
  base: '/admin',
  routes
})

export default router

