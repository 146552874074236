import { render, staticRenderFns } from "./Erc20LoadingModal.vue?vue&type=template&id=56fbf8cd&scoped=true"
import script from "./Erc20LoadingModal.vue?vue&type=script&lang=js"
export * from "./Erc20LoadingModal.vue?vue&type=script&lang=js"
import style0 from "./Erc20LoadingModal.vue?vue&type=style&index=0&id=56fbf8cd&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56fbf8cd",
  null
  
)

export default component.exports