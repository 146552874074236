<template>
  <modal name="erc20-modal-loading"
         :click-to-close="false"
         width="700px" height="200px"
         :class="'settingReviewSrc20'"
         @before-open="beforeOpened"
         @opened="$emit('opened', $event)"
         @closed="$emit('closed', $event)">
    <div class="setting-popup">
      <div class="loading-content">
        <vue-loading :size="{ width: '75px', height: '75px' }" type="spin" class="loading-icon" color="#0f8ddb"/>
        <p class="popup__title">{{ status || $t('erc20_loading_modal.loading_message') }}</p>
      </div>
    </div>
  </modal>
</template>

<script>
  import MasterdataUtils from '../common/MasterdataUtils';

  export default {
    name: 'ModalLoading',
    data() {
      return {
        status: window.i18n.t('erc20_loading_modal.loading_message'),
      }
    },
    methods: {
      beforeOpened() {
        window.Echo.private('RegisterERC20')
          .listen('CreateAccountsErc20', () => {
            this.status = window.i18n.t('erc20_loading_modal.loading_message');
          })
          .listen('CreateMigrationErc20', () => {
            this.status = window.i18n.t('erc20_loading_modal.create_account_message');
          })
          .listen('SetMarketPriceErc20', () => {
            this.status = window.i18n.t('erc20_loading_modal.create_migration_message');
          })
          .listen('UpdateMasterdataErc20', () => {
            this.status = window.i18n.t('erc20_loading_modal.set_market_price_message');
          })
          .listen('FinishedRegisterErc20', async (res) => {
            this.status = window.i18n.t('erc20_loading_modal.update_masterdata_message');
            await MasterdataUtils.clearMasterdata();
            await this.$store.dispatch('getMasterdata');
            this.$modal.hide('erc20-modal-loading');
            this.$router.push('/setting/coin');
          });
      },
    },
  };
</script>

<style lang="scss" scoped>

  .popup__title {
    text-align: center;
    font-size: 18px;
    margin-top: 22px;
  }
  .settingReviewSrc20 {
    background-color: transparent !important;
  }
  .loading-content {
    position: absolute;
    top: 50%;
    width: 100% !important;
    transform: translateY(-50%);
    width: 100%;
    padding: 0 15px;
    background-color: transparent;
  }
</style>
