<template>
  
  <div id="edit_emailMaketing">
    <div :class="emailId"></div>
    <div class="page960">
      <div class="section">
        <div class="form-notice-detail-container">
          <div id="notice-detail">
            <div class="notice-detail-form-group">
              <div><label class="notice-detail-form-label">{{ $t('email.title') }}</label></div>
              <input class="notice-detail-form-value" id="input-title" type="text" v-model="record.title"
                :class="{'input-error': errors.has('title')}" maxlength="190" />
                <div class="clearfix"></div>
              <div class="error-box">
                <span v-show="errors.has('title')" class="f-left is-error">{{ errors.first('title') }}</span>
              </div>
            </div>
            <div class="email-form-group clearfix">
              <label class="notice-detail-form-label">{{ $t('email.content') }}</label>
              <div>
                <ckeditor class="input_notice_detail" :class="{'input-error': errors.has('content')}" :editor="editor" v-model="record.content" :config="editorConfig"></ckeditor>
                <div class="clearfix"></div>
                <div class="error-box">
                <span v-show="errors.has('content')" class="f-left is-error">{{ errors.first('content') }}</span>
              </div>
              </div>
            </div>
            <div class="submit clearfix">
              <button class="btn-cancel" @click="cancel">{{ $t('notice.cancel') }}</button>
              <button class="btn-submit" @click="submitEmail(record.id)">{{ $t('notice.submit') }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import rf from '../../lib/RequestFactory';
  import DatePicker from 'vuejs-datepicker';
  import moment from 'moment';
  // import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ClassicEditor } from 'ckeditor5';

  import RemoveErrorsMixin from '../../common/RemoveErrorsMixin';

  export default {
    components: {
      DatePicker,
    },
    mixins: [RemoveErrorsMixin],
    data() {
      return {
        emailId: this.$route.query.id,
        titlePage: this.emailId ? window.i18n.t('email.edit_title_page') : window.i18n.t('email.new_title_page'),
        record: {},
        editor: ClassicEditor,
        editorData: {},
        editorConfig: {
          toolbar: [ 'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'undo', 'redo' ]
        }
      };
    },
    methods: {
      submitEmail(id) {
        if (!this.validate()) {
          return;
        }
        ConfirmationModal.show({
          type: 'confirm',
          title: this.$t('email.modal'),
          content         : '',
          btnCancelLabel  : this.$t('common.action.no'),
          btnConfirmLabel : this.$t('common.action.yes'),
          size            : 'small',
          onConfirm       : () => {
            this.requestHandler();
          },
          onCancel        : () => {}
        });
      },

      requestHandler() {
        this.sendRequest().then(res => {
          if (this.emailId) {
            this.$toastr('success', this.$t('common.alert.update.success'));
          }
          else {
            this.$toastr('success', this.$t('common.alert.create.success'));
          }
          var _cpn = this;
          setTimeout(function(){ 
            _cpn.$router.push({name: 'Emails Marketing'});
          }, 1000);
        }).catch(error => {
          if (!error.response) {
            Message.error(window.i18n.t('common.message.network_error'), {}, { position: "bottom_left" });
            return;
          }
          this.convertRemoteErrors(error);
        });
      },

      sendRequest() {
        const params = {
          title: this.record.title,
          content: this.record.content,
        };
        if (this.record && this.record.id) {
          params.id = this.record.id;
          return rf.getRequest('AdminRequest').updateEmailMarketing(params);
        }
        return rf.getRequest('AdminRequest').createEmailMarketing(params);
      },

      validate() {
          this.resetError();

          this.validateRequired(this.record.title, 'title');
          this.validateRequired(this.record.content, 'content');

          return this.errors.count() <= 0;
      },

      validateRequired(value, field) {
        if (_.isEmpty(value) || (
          typeof value === 'string' && _.isEmpty(
            value.replace(/(<([^>]+)>)/ig, '').replace(/&nbsp;/g, '').trim()
          )
        )) {
          this.errors.add({ field: field, msg: window.i18n.t('email.errors.' + field + '.required')});
        }
      },

      cancel() {
        this.$router.push({ name: 'Emails Marketing' });
      },
    },

    mounted() {
        this.titlePage = this.emailId ? window.i18n.t('email.edit_title_page') : window.i18n.t('email.new_title_page'),
        this.$emit('EVENT_PAGE_CHANGE', this);
        if (!this.emailId) {
            return;
        }
        rf.getRequest('AdminRequest').editEmailMarketing(this.emailId).then(res => {
            this.record = res.data || {};
        });
    }
  }
</script>

<style lang="scss">
@import "@/assets/sass/variables";
  .ck.ck-editor__main>.ck-editor__editable {
    background-color: $color-white-smoke;
    font-family: $font-family-roboto;
    &:hover, &:active, &:focus {
      border-color: $color-jungle-green;
    }
  }
  .ck.ck-toolbar {
    background-color: $color-white-smoke;
  }
  .input-error + .ck-editor {
    .ck-content {
      border-color: $color_alizarin_crimson !important;
    }
  }
  .ck-button__label {
    font-size: $font_root !important;
  }
</style>
<style lang="scss" scoped>
@import "@/assets/sass/variables";
    #input-title {
      width: 100%
    }
    .is-error {
      color: #ff5252 !important;
      display: inline-block;
      line-height: 20px;
      padding-top: 5px;
    }
    .input-error {
      border: 1px solid #ff5252 !important;
    }
    .f-left {
      float: left;
    }
    .required {
      color:#f00;
    }
    .error-box {
      display: block;
      float: left;
      overflow: hidden;
      width: 100%;
    }
    .page960 {
        width: 900px;
        min-width: 900px;
    }
    .f-left {
        float: left;
    }
    button[disabled] {
        opacity: 0.4 !important;
    }
    .form-notice-detail-container {
        padding: 10px 40px 0;
        padding-left: 0 !important;
        .filed-verification {
            .filed {
                .tip {
                    font-size: 12px;
                    color: #666;
                    padding: 20px 0;
                }
            }
        }
        .notice-detail-form-group {
            min-height: 30px;
            height: 70px;
            .notice-detail-form-label {
                margin-right: 30px;
                width: 130px;
                text-align: left;
                font-size: 14px;
                color: $color_dove_gray;
                float: left;
                font-weight: normal;
                font-family: $font-family-roboto;
            }
            .col-md-6 {
                input {
                    width: 300px;
                }
            }
            input {
                width: 800px;
                padding: 13px 12px;
                outline: none;
                border: 1px solid #cfcfcf;
                height: 35px;
                line-height: 35px;
                background: $color-white-smoke;
                border-radius: 3px;
                font-weight: 600;
                font-family: $font-family-roboto;
                &:hover, &:active, &:focus {
                  border-color: $color-jungle-green;
                }
            }
            .preView {
                width: 280px;
                height: 200px;
                background: #fdfdfd url('@/assets/images/preview-bg.png')no-repeat center;
                border: 1px dashed #e4e4e4;
                position: relative;
                float: left;
                margin-top: 10px;
                img {
                    width: 280px;
                    height: 200px;
                }
            }
            .file-btn {
                background-color: #0090eb;
                color: white;
                height: 32px;
                width: 100px;
                text-align: center;
                position: relative;
                padding: 8px 10px;
                display: inline-block;
                cursor: pointer;
                font-size: 14px;
                border: 1px solid #d4d4d4;
                line-height: 100%;
            }
            input[type=file] {
                position: absolute;
                left: 446px;
                top: 325px;
                cursor: pointer;
                height: 33px;
                width: 100px;
                opacity: 0;
            }
        }
        .email-form-group {
          margin-top: 25px;
          .notice-detail-form-label {
            text-align: left;
            font-size: 14px;
            color: $color_dove_gray;
            font-weight: normal;
            font-family: $font-family-roboto;
          }
        }
        .submit {
          margin-top: 30px;
          padding: 0;
          text-align: left;
          float: left;
          .btn-cancel {
            margin-right: 15px;
          }
          button {
            left: 0;
            top: 0;
            cursor: pointer;
            height: 35px;
            line-height: 20px;
            text-align: center;
            min-width: 90px;
            display: inline-block;
            float: left;
            font-family: $font-family-roboto-bold;
            background-color: $color-caribbean-green;
            border: 1px solid $color-caribbean-green;
            color: $color-white;
            font-size: $font-smaller;
            letter-spacing: 0.9px;
            border-radius: 3px;
            padding: 7px 5px;
            text-transform: uppercase;
            &:hover, &:active, &:focus {
              background-color: $color-aquamarine;
              border: 1px solid $color-blue-custom;
            }
          }
        }
    }
    .ck-content { height:300px; }
</style>

<style lang="scss">
    #edit_emailMaketing {
        .ck-editor__editable_inline {
            min-height: 350px;
        }
    }
</style>