import BaseRequest from '../lib/BaseRequest'

export default class OrderRequest extends BaseRequest {
  
  // getOrderHistory(params) {
  //   let url = '/admin/api/orders/history';
  //   return this.get(url, params);
  // }
  
  getTradeByOrder(id, params) {
    let url = '/admin/api/orders/trading-histories/' + id;
    return this.get(url, params)
  }
  
  // getTradeHistory(params) {
  //   let url = '/admin/api/orders/trading-histories';
  //   return this.get(url, params)
  // }
  
  getOrdersPending(params) {
    let url = '/admin/spot/orders/open';
    return this.get(url, params);
  }

  cancelOrdersPending(params) {
    let url = `/admin/spot/order/${params}/cancel`;
    return this.put(url);
  }

  getOptionFilterOpenOrder(params) {
    let url = `/admin/spot/orders/open/${params}`;
    return this.get(url);
  }

  getOptionFilterOrderHistory(params) {
    let url = `/admin/spot/orders/history/${params}`;
    return this.get(url);
  }

  getOptionFilterTradeHistory(params) {
    let url = `/admin/spot/orders/trade/history/${params}`;
    return this.get(url);
  }

  exportData(params) {
    let url = `/admin/spot/orders/open/export`;
    return this.get(url, params);
  }

  getOrderBookSpot(params) {
    let url = '/admin/spot/orderbook';
    return this.get(url, params);
  }

  getOrderHistory(params) {
    let url = '/admin/spot/orders/history';
    return this.get(url, params);
  }

  exportDataOrderHistory(params) {
    let url = `/admin/spot/orders/open/export`;
    return this.get(url, params);
  }

  getDataTradeHistory(params) {
    let url = '/admin/spot/orders/trade/history';
    return this.get(url, params)
  }

  exportDataTradeHistory(params) {
    let url = '/admin/spot/orders/trade/history/export';
    return this.get(url, params)
  }
}
