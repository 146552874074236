<template>
    <div class="adminAccountEdit_page" id="adminAccountEdit_page">
        <div class="form-container">
            <!-- Main Inputs -->

            <div class="form-group">
                <label class="label-text" for="email">{{ $t('admin.account.email') }}</label>
                <input @focus="resetErrors" :maxlength="maxLength" :class="{ 'error': errors.has('email') }"
                    v-model="formData.email" type="text" id="email"
                    :placeholder="$t('admin.account.enter_email')">
                <div v-show="errors.has('email')" class="invalid-feedback">{{
                    errors.first('email') }}
                </div>
            </div>

            <div class="form-group">
                <label class="label-text" for="password">{{ $t('admin.account.password') }}</label>
                <input @focus="resetErrors" :maxlength="maxLength" :class="{ 'error': errors.has('password') }"
                    type="text" id="password" :placeholder="$t('admin.account.enter_password')"
                    v-model="formData.password">
                <div v-show="errors.has('password')" class="invalid-feedback">{{ errors.first('password') }}
                </div>
            </div>

            <div class="form-group">
                <label class="label-text" for="role">{{ $t('admin.account.role') }}</label>
                <select-box :on-focus="resetErrors" :maxlength="maxLength" :class="{ 'error': errors.has('role') }"
                    id="role" :options="roleOptions" :value="formData.role"
                    :on-change="selected => formData.role = selected" :placeholder="$t('admin.account.select_role')" />
                <div v-show="errors.has('role')" class="invalid-feedback">{{ errors.first('role') }}</div>
            </div>

            <div class="action">
                <button class="btn btn-cancel" @click="$router.back()">{{
                    $t('admin.account.btn_cancel') }}</button>
                <button v-if="$route.query.id" class="btn btn-update" @click="updateAdminAccount()">{{
                    $t('admin.account.btn_update') }}</button>
                <button v-if="$route.query.id" class="btn btn-delete" @click="deleteAdminAccount()">{{
                    $t('admin.account.btn_delete') }}</button>
                <button v-if="!$route.query.id" class="btn btn-update" @click="createAdminAccount()">{{
                    $t('admin.account.btn_create') }}</button>
            </div>
        </div>

        <modal class="confirm_modal" name="update_modal" :title="$t('account.modal.confirm')">
            <div slot="body" class="body-popup">
                {{ $t('admin.account.popup_update_content') }}
            </div>
        </modal>

        <modal class="confirm_modal" name="delete_modal" :title="$t('account.modal.confirm')">
            <div slot="body" class="body-popup">
                {{ $t('admin.account.popup_delete_content') }}
            </div>
        </modal>

        <modal class="confirm_modal" name="create_modal" :title="$t('account.modal.confirm')">
            <div slot="body" class="body-popup">
                {{ $t('admin.account.popup_create_content') }}
            </div>
        </modal>

        <modal class="confirm_modal" :enableClose="enableClose" name="loadingUpload">
            <div slot="body">
                <vue-loading type="bars" v-if="isLoading" color="#2DAC91" :size="{ width: '60px', height: '80px' }">
                </vue-loading>
            </div>
        </modal>
    </div>
</template>

<script>
import rf from "../../../lib/RequestFactory";
import Modal from "../../../components/Modal";
import MultiSelectBox from "../../../components/MultiSelectBox.vue";
import Const from "../../../common/Const";
import Checkbox from "../../../components/Checkbox.vue";
import SelectBox from "../../../components/SelectBox";
import Utils from "../../../common/Utils";

export default {
    components: {
        Modal,
        MultiSelectBox,
        Checkbox,
        SelectBox
    },
    data() {
        return {
            titlePage: this.$route.query.id ? window.i18n.t('admin.account.title_edit') : window.i18n.t('admin.account.title_create'),
            isLoading: false,
            enableClose: true,
            formData: {
                email: '',
                password: '',
                role: ''
            },
            roleOptions: [
                { id: 'spot', name: "SPOT" },
                { id: 'future', name: "FUTURE" },
            ],
            maxLength: Const.MAX_LENGTH_INPUT
        };
    },
    methods: {
        createAdminAccount() {
            if (!this.validate()) return;
            this.showModalConfirm('create_modal', this.deleteNetwork);
        },
        updateAdminAccount() {
            if (!this.validate()) return;
            this.showModalConfirm('update_modal', this.deleteNetwork);
        },
        deleteAdminAccount() {
            this.showModalConfirm('delete_modal', this.deleteNetwork);
        },
        deleteNetwork(params) {
            // rf.getRequest('AAAAAAAAA').changeActiveStatusAccount(params).then(res => {
            //     if (res?.success) {
            //         this.$toastr('success', this.$t('bots.toast_delete_success'));
            //         this.getAccountDetail();
            //     } else {
            //         this.$toastr('error', this.$t('bots.toast_delete_fail'));
            //     }
            // });
            this.$toastr('error', this.$t('bots.toast_delete_fail'));
        },
        showModalConfirm(modalName, callback, params) {
            CommonModal.show(modalName, {
                position: 'center',
                mask: true,
                buttons: [
                    {
                        label: window.i18n.t('common.action.no'),
                        class: "btn-no",
                        style: {
                            background: 'rgb(191, 191, 191);'
                        },
                        callback: () => {
                            CommonModal.hide(modalName);
                        }
                    },
                    {
                        label: window.i18n.t('common.action.yes'),
                        focused: true,
                        style: {
                            background: 'rgb(0, 112, 192)'
                        },
                        callback: () => {
                            CommonModal.hide(modalName);
                            this.loadingUploadBegin();
                            callback(params);
                            this.loadingUploadFinish();
                        }
                    }
                ]
            });
        },
        validate() {
            this.errors.clear();
            if (!this.formData.email) {
                this.errors.add({ field: 'email', msg: this.$t('admin.account.error_email_field') });
            }
            if (!this.formData.password) {
                this.errors.add({ field: 'password', msg: this.$t('admin.account.error_password_field') });
            }
            if (!this.formData.role) {
                this.errors.add({ field: 'role', msg: this.$t('admin.account.error_role_field') });
            }
            if (this.errors.count() > 0) {
                return false;
            }
            return true;
        },
        loadingUploadBegin() {
            this.enableClose = false;
            this.isLoading = true;
            CommonModal.show('loadingUpload');
        },
        loadingUploadFinish() {
            CommonModal.hide('loadingUpload');
            this.isLoading = false;
            this.enableClose = true;
        },
    },
    created() {
        // this.getOptionData();
    },
    mounted() {
        this.$emit('EVENT_PAGE_CHANGE', this);
        if (this.$route.query.id) {
            this.formData = {
                email: "kyrieirving_2knba@gmail.com",
                password: "**********",
                role: { id: 'spot', name: "SPOT"}
            }
        }
    },
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/variables";

.adminAccountEdit_page {
    background-color: $color-white;
    border-radius: 10px;
    padding: 30px 40px;
    margin-top: 30px;

    .action {
        display: flex;
        margin-bottom: 30px;
    }

    .btn {
        margin: 0 5px;
        width: auto;
        margin-top: 18px;
        height: 32px;
        font-family: $mulish-bold;
        border-radius: 10px;
        text-transform: uppercase;

        &.btn-update {
            border: 1px solid $color-caribbean-green;
            color: $color_white;
            background-color: $color-caribbean-green;

            &:hover {
                background-color: $color-aquamarine;
                border-color: $color-aquamarine;
            }
        }

        &.btn-cancel {
            background-color: $color-bright-gray;
            border-color: $color-bright-gray;
            color: $color-black;

            &:hover {
                opacity: 0.7;
            }
        }

        &.btn-delete {
            background-color: $color-red-main;
            border-color: $color-red-main;
            color: $color-white;

            &:hover {
                opacity: 0.7;
            }
        }
    }

    .form-container {
        gap: 10px 20px;
        width: 40%;
    }

    .form-group {
        display: flex;
        flex-direction: column;

        &.status {
            border-radius: 10px;
            margin-top: 10px;
            padding: 8px 16px;
            border: 1px solid $color-bright-gray;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            .label-text {
                margin: 0;
                font-family: $mulish-medium;
                font-size: 16px;
            }
        }

        &.w-66 {
            width: calc(66% - 1px);
        }

        &.w-50 {
            width: calc(50% - 1px);
        }

        &.w-25 {
            width: calc(25% - 15px);
        }

        .input-group {
            position: relative;

            .unit {
                margin-right: 10px;
                font-family: $mulish-medium;
            }
        }
    }

    .label-text {
        font-size: $font-root;
        color: $color-grey-dusty;
        font-family: $mulish-regular;
        font-weight: 400;
        margin-bottom: 7px;
        padding-left: 5px;
    }

    input[type=text],
    input[type=number] {
        padding: 8px 16px;
        border: 1px solid $color-bright-gray !important;
        border-radius: 4px;
        font-size: 16px;
        border-radius: 10px !important;
        font-family: $mulish-medium;
        box-shadow: none !important;
        height: auto;
        width: 100%;

        &.error {
            border: solid 1px $color-red-main !important;
        }
    }

    input[type=number] {
        padding-right: 50px;
    }

    .invalid-feedback {
        color: $color-red-main;
        font-size: 14px;
    }

    .break-line {
        margin-top: 40px;
        margin-bottom: 30px;
        border-top: 1px solid $color-alto;
        position: relative;
        display: flex;
        align-items: center;

        .label {
            margin-left: 40px;
            background-color: $color-white;
            padding: 0 16px;
            font-family: $mulish-medium;
            font-size: large;
            color: $color-black;
            position: absolute;
        }
    }

    .quote-section {
        display: flex;
    }

    .network-section {
        flex: 1;

        &:first-child {
            padding-right: 30px;
            border-right: 1px dashed $color-alto;
        }

        &:last-child {
            padding-left: 30px;
        }

        .quote-item {
            display: flex;
            gap: 20px;

            img {
                margin-top: 8px;
                width: 18px;
                height: auto;
                cursor: pointer;
            }

            .form-group {
                display: flex;

                &:first-child {
                    margin-top: 20px;
                }

                input {
                    height: 32px;
                }
            }
        }
    }

    .network-label {
        font-family: $mulish-bold;

        img {
            margin-left: 16px;
            margin-top: -2px;
            width: 18px;
            height: 18px;
            cursor: pointer;

            &:hover {
                opacity: 0.8;
            }
        }
    }
}
</style>


<style lang="scss">
@import "@/assets/sass/variables";

#adminAccountEdit_page {

    .sc_search_select {
        border-radius: 10px;
        border: 1px solid $color-bright-gray;

        &.error {
            border: 1px solid $color-red-main;
        }

        .group_search_select {
            .button_drop_search {
                height: 38.84px;
                padding: 10px 16px;
                border-radius: 10px;
                font-family: $mulish-medium;
                background-color: $color-white;
                border: none;

                span {
                    font-family: $mulish-medium;
                    font-size: medium;
                }

                i.icon-arrow1 {
                    color: $color_dove_gray;
                    font-size: 14px;
                }
            }
        }

        .box_list_search_select {
            .list_search_select {
                border-radius: 10px;

                li {
                    font-family: $font-family-roboto;
                    font-size: $font-small;
                    padding: 6px 12px;

                    &:first-child {
                        display: none;
                    }

                    &:hover,
                    &:active,
                    &:focus {
                        color: $color-jungle-green;
                    }
                }
            }
        }
    }

    .modal {
        .body-popup {
            font-family: $mulish-medium;

            p,
            strong {
                font-family: $mulish-medium;
            }
        }

        .btn-no {
            background-color: $color-bright-gray;
            border-color: $color-bright-gray;
            color: $color-black;

            &:hover {
                opacity: 0.7;
            }
        }
    }
}
</style>