<template>
  <div class="deposit_pages boxCore" id="order_future">
    <div class="d-flex w-100">
      <div class="filter-template status">
        <div class="label-text date-text">{{ $t('orders.open_order.status') }}</div>
        <div class="toolbar-element">
          <select-box v-model="filters.symbol" :placeholder="$t('order.order_history.all')" :options="SymbolData" />
        </div>
      </div>
      <div class="filter-template">
        <div class="label-text">&nbsp;</div>
        <button class="btn btn-reset" @click="refresh()">{{ $t('orders.open_order.reset') }}</button>
      </div>
    </div>
    <div class="table-all">
      <div class="table-futures">
        <div class="table-wrap">
          <table class="buy-table">
            <thead>
              <tr>
                <th class="text-left buy" data-sort-field="price">{{ $t('order.open_order.price') }}</th>
                <th class="text-left buy" data-sort-field="quantity">{{ $t('order.open_order.quantity') }}</th>
                <th class="text-left buy" data-sort-field="total">{{ $t('order.open_order.total') }}</th>
              </tr>
            </thead>

            <tbody v-if="formatterOrderbook">
              <tr v-for="order in formatterOrderbook.asks">
                <td class="text-left">
                  {{ order[0] | to2Precision }}
                </td>
                <td class="text-left">
                  {{ order[1] | to2Precision }}
                </td>
                <td class="text-left">
                  {{ order[2] | to2Precision }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="table-wrap">
          <table class="sell-table">
            <thead>
              <tr>
                <th class="text-left sell" data-sort-field="price">{{ $t('order.open_order.price') }}</th>
                <th class="text-left sell" data-sort-field="quantity">{{ $t('order.open_order.quantity') }}</th>
                <th class="text-left sell" data-sort-field="total">{{ $t('order.open_order.total') }}</th>
              </tr>
            </thead>

            <tbody v-if="formatterOrderbook">
              <tr v-for="order in formatterOrderbook.bids">
                <td class="text-left">
                  {{ order[0] | to2Precision }}
                </td>
                <td class="text-left">
                  {{ order[1] | to2Precision }}
                </td>
                <td class="text-left">
                  {{ order[2] | to2Precision }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="filter-size">
      <div class="filter-template status">
        <div class="toolbar-element">
          <select-box v-model="filters.size" :placeholder="$t('order.order_history.all')" :options="sizeData" />
        </div>
      </div>
    </div>
    <div class="clearfix clearfix-40"></div>
  </div>
</template>

<script>

import moment from "moment";
import COMMON_CONST from "../../common/Const";
import rf from "../../lib/RequestFactory";
import { mapActions } from "vuex";
import DatePicker from 'vuejs-datepicker';
import Modal from "../../components/Modal";
import SelectBox from "../../components/SelectBox";
import BigNumber from "bignumber.js";
import { Buffer } from "buffer"

export default {
  name: "OrderFuturesRecords",
  components: {
    Modal,
    DatePicker,
    SelectBox
  },
  data() {
    return {
      titlePage: window.i18n.t("menu.futures.order_book"),
      filters: {
        symbol: { name: 'SOLUSDT', id: 'SOLUSDT' },
        size: { id: 10, name: '10' },
      },
      isLoading: false,
      SymbolData: [
        { name: 'SOLUSDT', id: 'SOLUSDT' },
        { name: 'BNBUSDT', id: 'BNBUSDT' },
        { name: 'BTCUSDT', id: 'BTCUSDT' },
        { name: 'BNBBTC', id: 'BNBBTC' },
        { name: 'BNBETH', id: 'BNBETH' },
      ],
      sizeData: [
        { id: 10, name: '10' },
        { id: 9999, name: 'All' }
      ],
      totalRecords: 0,

      orderbook: null,
      formatterOrderbook: null,
      precision: 0.1,
      maxFiguresForSize: 0
    }
  },
  watch: {
    'filters.symbol': {
      handler: function (newVal, oldVal) {
        if (!oldVal) return;
        if (oldVal) this.offOrderBook(oldVal.id);
        this.changeSymbol();
      },
      deep: true,
    },
    orderbook: {
      handler: function (oldVal, newVal) {
        if (!newVal) return;

        let bids = this.roundRows(newVal.bids, this.precision, BigNumber.ROUND_DOWN);
        let asks = this.roundRows(newVal.asks, this.precision, BigNumber.ROUND_UP);

        bids = this.groupRows(bids);
        asks = this.groupRows(asks);

        let buyRowNumber = this.filters.size.id;
        let sellRowNumber = this.filters.size.id;

        bids = bids.slice(0, buyRowNumber);
        asks = asks.slice(0, sellRowNumber);

        const formattedOrderbook = this.calculateTotalAndPercent(
          bids,
          asks,
          Number(this.maxFiguresForSize || 2)
        );
        formattedOrderbook.bids = this.addEmptyRows(
          formattedOrderbook.bids,
          buyRowNumber
        );
        formattedOrderbook.asks = this.addEmptyRows(
          formattedOrderbook.asks,
          sellRowNumber
        );

        this.formatterOrderbook = formattedOrderbook;
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    getFuturesOrderbook() {
      rf.getRequest('AdminRequest').getOrderbookSymbol(this.filters.symbol.id)
        .then(res => this.orderbook = res.data)
    },
    customFormatter(date) {
      return moment(date).format('YYYY-MM-DD');
    },
    refresh() {
      this.resetInput();
      this.$refs.datatable.refresh();
    },
    resetInput() {
      this.filters = Object.assign(this.filters, {
        symbol: { id: "SOLUSDT", name: "SOLUSDT" },
      });
      $(".datatable").find("th").attr("data-sort-order", "");
    },
    listenForNotification() {
      window.Echo.channel('App.Models.Admin')
        .listen('AdminNotificationUpdated', () => {
          this.$refs.datatable.refresh();
        });
    },
    filledOrder(value) {
      return new BigNumber(value.quantity).minus(value.remaining)
    },
    changeSymbol() {
      this.getFuturesOrderbook()
      this.listenForOrderBook();
    },
    offOrderBook(symbol) {
      window.Echo2.connector.socket.emit('leave', symbol);
    },
    listenForOrderBook() {
      window.Echo2.connector.socket.emit('join', this.filters.symbol.id);
      window.Echo2.connector.socket.on(`orderbook_${this.filters.symbol.id}`, (data) => {
        const decodedData = Buffer.from(data, 'base64').toString('ascii');
        this.applyOrderbookUpdate(JSON.parse(decodedData));
      })
    },
    updateRows(rows, newRows, comparator) {
      let updatedRows = []

      let rowIndex = 0;
      let newRowIndex = 0;
      while (rowIndex < rows.length && newRowIndex < newRows.length) {
        const price = rows[rowIndex][0];
        const quantity = rows[rowIndex][1];
        const newPrice = newRows[newRowIndex][0];
        const newQuantity = newRows[newRowIndex][1];
        const compareResult = comparator(price, newPrice);
        if (compareResult > 0) {
          // keep old price and quantity
          updatedRows.push([price, quantity]);
          rowIndex++;
        } else if (compareResult === 0) {
          // update quantity
          if (new BigNumber(newQuantity).gt(0)) {
            updatedRows.push([price, newQuantity]);
          }
          rowIndex++;
          newRowIndex++;
        } else {
          // insert new price and quantity
          if (new BigNumber(newQuantity).gt(0)) {
            updatedRows.push([newPrice, newQuantity]);
          }
          newRowIndex++;
        }
      }

      updatedRows = updatedRows.concat(rows.slice(rowIndex));
      updatedRows = updatedRows.concat(newRows.slice(newRowIndex)?.filter((row) => new BigNumber(row[1]).gt(0)));

      return updatedRows;
    },
    applyOrderbookUpdate(orderbookUpdate) {
      const orderbook = this.orderbook;
      if (!orderbookUpdate || !orderbook) return;

      orderbook.bids = this.updateRows(orderbook.bids, orderbookUpdate.bids, (n1, n2) =>
        new BigNumber(n1).comparedTo(n2),
      );
      orderbook.asks = this.updateRows(orderbook.asks, orderbookUpdate.asks, (n1, n2) =>
        new BigNumber(n2).comparedTo(n1),
      );
      orderbook.updatedAt = orderbookUpdate.updatedAt;
      this.orderbook = orderbook
    },
    calculateTotal(rows, precision) {
      let total = new BigNumber('0');
      const rowCount = rows.length;
      for (let i = 0; i < rowCount; i++) {
        total = total.plus(rows[i][1]);
        rows[i][1] = new BigNumber(rows[i][1]).toFixed(precision);
        rows[i].push(total.toFixed(precision));
      }
      return rows;
    },
    calculateTotalAndPercent(bids, asks, precision) {
      bids = this.calculateTotal(bids, precision);

      let totalBid = new BigNumber('0');
      if (bids.length > 0) {
        totalBid = new BigNumber(bids[bids.length - 1][2]);
      }

      asks = this.calculateTotal(asks, precision);
      let totalAsk = new BigNumber('0');
      if (asks.length > 0) {
        totalAsk = new BigNumber(asks[asks.length - 1][2]);
      }

      const total = BigNumber.max(totalBid, totalAsk);
      const addPercent = (row) => row.push(new BigNumber(row[2]).times(100).div(total).toFixed(2));
      bids.forEach(addPercent);
      asks.forEach(addPercent);

      return { bids, asks, updatedAt: 0 };
    },
    addEmptyRows(rows, length) {
      while (rows.length < length && !rows[0]) {
        rows.push(['\u2002', '', '', '0']);
      }
      return rows;
    },
    groupRows(rows) {
      if (rows.length === 0) {
        return [];
      }

      const groupedRows = [];
      let lastPrice = rows[0][0];
      let lastQuantity = rows[0][1];

      const rowCount = rows.length;
      for (let i = 1; i < rowCount; i++) {
        if (lastPrice === rows[i][0]) {
          lastQuantity = new BigNumber(lastQuantity).plus(rows[i][1]).toString();
        } else {
          groupedRows.push([lastPrice, lastQuantity]);
          lastPrice = rows[i][0];
          lastQuantity = rows[i][1];
        }
      }
      groupedRows.push([lastPrice, lastQuantity]);

      return groupedRows;
    },
    roundRows(rows, group, roundingMode) {
      const precision = -Math.log10(parseFloat(group));
      const roundPrice = (n) => {
        const quotient = new BigNumber(n).div(group).toFixed(0, roundingMode);
        const rounded = new BigNumber(quotient).times(group);
        return precision > 0 ? rounded.toFixed(precision) : rounded.toFixed(0);
      };
      const rowsFinal = rows?.filter((row) => Number(row[1]) > 0);
      return rowsFinal.map((row) => [roundPrice(row[0]), row[1]]);
    }
  },
  created() {
    this.getFuturesOrderbook();
  },
  beforeDestroy() {
    this.offOrderBook(this.filters.symbol.id);
  },
  mounted() {
    this.$emit('EVENT_PAGE_CHANGE', this);
    this.listenForNotification();
    this.listenForOrderBook();
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/variables";

.d-flex {
  display: flex;
}

.w-100 {
  width: 100%;
}

.buy {
  background: $text-color-red !important;
}

.sell {
  background: $text-color-jade !important;
}

#order_future {
  .table-wrap {
    flex: 1;
    min-height: 480px;
    margin-bottom: 40px;
  }

  table {
    width: 100%;

    thead {
      th {
        padding: 16px 12px;
        font-family: $mulish-medium;
        color: $color-white;

        &:first-child {
          width: 22%;
          min-width: 100px;
        }

        &:nth-child(2) {
          width: 25%;
          min-width: 120px;
        }

        &:nth-child(3) {
          width: 25%;
          min-width: 120px;
        }

        &:nth-child(4) {
          width: 28%;
          min-width: 125px;
        }
      }
    }

    tbody {
      td {
        padding: 10px 12px;
        font-family: $mulish-regular;
        border-bottom: 1px solid $color-bright-gray;
        background-color: $color-white;
      }
    }
  }
}

.no-left-radius {
  input {
    border-radius: 0px 3px 3px 0px;
  }
}

.no-right-radius {
  input {
    border-radius: 3px 0px 0px 3px;
  }
}

.toolbar-element {
  display: inline-block;
  float: left;
  width: 150px;
}

td {
  font-family: $font-family-roboto;
}

.table-futures {
  margin-top: 24px;
  display: flex;
  gap: 36px;
}

.filter-template {
  margin-right: 15px;

  .label-text {
    color: $dark-1;
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 7px;
    margin-left: 5px;
    font-family: $mulish-regular;
  }

  .btn-reset {
    background: $color-caribbean-green;
    border-radius: 10px;
    color: $color_white;
    font-weight: 700;
    width: 78px;
    height: 32px;
  }
}
</style>

<style lang="scss">
@import "@/assets/sass/variables";

#deposit_fiat_pages {
  .sc_search_select {
    .group_search_select {
      .button_drop_search {
        width: 55px;
        height: 30px !important;
        padding: 5px 10px 5px 10px !important;
        border-radius: 3px;
        margin-right: 20px;
        font-size: $font-small;
        font-family: $font-family-roboto;

        i.icon-arrow1 {
          margin-left: 5px;
          color: $color_dove_gray;
        }

        &:hover {
          border-color: $color-jungle-green;

          i.icon-arrow1 {
            color: $color-jungle-green;
          }
        }

      }

      .box_list_search_select {
        .list_search_select {
          li {
            font-family: $font-family-roboto;
            font-size: $font-small;

            &:hover,
            &:active,
            &:focus {
              color: $color-jungle-green;
            }
          }
        }
      }
    }
  }
}
</style>