<template>
  <div>
    <div :style="{width: widthTable}" :class="{'no-data': emptyData}" class="tableContainer">
      <table>
        <thead>
          <tr @click="onSort">
            <slot/>
          </tr>
        </thead>
        <tbody>
          <slot name="first_row"/>
          <template v-if="!(fetching && orderBy === null)">
            <slot
              name="body"
              v-for="(row, index) in rows"
              :item="row"
              :index="(index + pageStartIndex) <= totalRecord ? (index + pageStartIndex) : undefined "
            />

            <template v-if="this.rows.length === 0">
              <tr class="empty-data">
                <td :colspan="column">
                  <span class="icon-nodata"></span>
                  <span>{{ msgEmptyData || $t('common.datatable.no_data') }}</span>
                </td>
              </tr>
            </template>
            <!--
            <template v-for="row in emptyRow">
              <tr>
                <td :colspan="column">{{ msgEmptyData }}</td>
              </tr>
            </template>
            -->
          </template>
          <template v-else>
            <tr>
              <td :colspan="column">Loading...</td>
            </tr>
          </template>
          
          <slot name="end_row"/>
        </tbody>
      </table>
      <div class="wrap-footer">
        <template v-if="lastPage > 1 || visiblePagination">
          <pagination
            ref="pagination"
            :per-page="perPage"
            :records="totalRecord"
            :chunk="chunk"
            @change-limit="onChangeLimit($event)"
            @Pagination:page="onPageChange"
            :pageParent="page"
          ></pagination>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from "./Pagination";
import _ from "lodash";
export default {
  name: "DataTable",
  components: {
    Pagination
  },
  props: {
    getData: {
      type: Function
    },
    chunk: {
      type: Number,
      default: 6
    },
    limit: {
      type: Number,
      default: 10
    },
    widthTable: {
      type: String,
      default: "100%"
    },
    msgEmptyData: {
      type: String
    },
    searchAble: {
      type: Boolean,
      default: false
    },
    exportAbleFunction: {
      type: Function,
      default: undefined
    }
  },
  data() {
    return {
      visiblePagination: false,
      maxPageWidth: 10,
      totalRecord: 0,
      lastPage: 0,
      page: 1,
      perPage: 1,
      column: 0,
      fetching: false,
      rows: [],
      params: {},
      orderBy: null,
      sortedBy: null,
      internalLimit: null,
      keySearch: ""
    };
  },
  computed: {
    emptyData() {
      if (this.fetching) {
        return false;
      }
      return window._.isEmpty(this.rows);
    },
    pageStartIndex() {
      return this.perPage * (this.page - 1) + 1;
    },
    getMessagePage() {
      let from = (this.page - 1) * this.perPage + 1;
      let to = this.page * this.perPage;
      to = Math.min(to, this.totalRecord);
      from = Math.min(from, this.totalRecord);
      return `${window.i18n.t("display")}　${from}${window.i18n.t(
        "to"
      )}${to}${window.i18n.t("of")}${this.totalRecord}${window.i18n.t("end")}`;
    },
    emptyRow() {
      let emptyRowCount = Math.max(this.internalLimit - _.size(this.rows), 0);
      return emptyRowCount;
    }
  },
  created() {
    this.internalLimit = this.limit;
    this.fetch();
    this.$on("DataTable:filter", params => {
      this.filter(params);
    });
  },
  mounted() {
    this.column = _.chain(this.$slots.default)
      .filter(el => el.tag === "th")
      .value().length;
  },
  methods: {
    onChangeLimit(limit) {
      this.visiblePagination = true;
      this.internalLimit = limit;
      this.page = 1;
      this.refresh();
    },
    onPageChange(page) {
      this.page = page;
      this.fetch();
    },
    getTarget(target) {
      let node = target;
      while (node.parentNode.nodeName !== "TR") {
        node = node.parentNode;
      }
      return node;
    },
    getSortOrder(target) {
      let sortOrder = target.dataset.sortOrder;
      switch (sortOrder) {
        case "asc":
          sortOrder = "desc";
          break;
        case "desc":
          sortOrder = "";
          break;
        default:
          sortOrder = "asc";
      }
      return sortOrder;
    },
    setSortOrders(target, sortOrder) {
      let iterator = target.parentNode.firstChild;
      while (iterator) {
        iterator.dataset.sortOrder = "";
        iterator = iterator.nextElementSibling;
      }
      target.dataset.sortOrder = sortOrder;
    },
    onSort(event) {
      const target = this.getTarget(event.target);
      const orderBy = target.dataset.sortField;
      if (!orderBy) {
        return;
      }
      this.sortedBy = this.getSortOrder(target);
      this.orderBy = this.sortedBy ? orderBy : "";
      Object.assign(this.params, {
        sort: this.orderBy,
        sort_type: this.sortedBy
      });
      this.setSortOrders(target, this.sortedBy);
      this.fetch();
    },
    fetch() {
      const meta = {
        page: this.page,
        limit: this.internalLimit
      };
      this.fetching = true;
      this.getData(Object.assign(meta, this.params))
        .then(res => {
          const data = res.data;
          this.column = _.chain(this.$slots.default)
            .filter(el => el.tag === "th")
            .value().length;
          if (!data || !data.data) {
            this.rows = res.data || res || [];
            this.page = parseInt(res.current_page);
            this.totalRecord = parseInt(res.total);
            this.lastPage = parseInt(res.last_page);
            
            this.perPage = parseInt(res.per_page)
            this.$emit("DataTable:finish");
            return;
          }
          this.page = parseInt(data.current_page);
          this.totalRecord = parseInt(data.total);
          this.lastPage = parseInt(data.last_page);
          this.perPage = parseInt(data.per_page);
          this.rows = data.data;
          this.$emit("DataTable:finish");
        })
        .then(() => {
          setTimeout(() => {
            this.fetching = false;
          }, 300);
        });
    },
    refresh() {
      this.$nextTick(() => {
        this.page = 1;
        this.params = {};
        this.fetch();
      });
    },
    filter(params) {
      this.page = 1;
      this.params = params;
      this.fetch();
    },
    exportExcel() {
      this.exportAbleFunction();
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/variables";
  table {
  width: 100%;
  background-color: $color_white;
  // border: $border_thin;
  overflow-y: hidden;
  thead {
    color: $color_grey;
    background-color: $color_white;
    border: none;
    overflow: hidden;
    th {
      user-select: none;
      position: relative;
      background-color: $color_white;
      line-height: 15px;
      font-size: $font-small;
      font-weight: 500;
      color: $color_grey;
      padding: 5px 15px;
      border-bottom: 1px solid $color_alto;
      height: 40px;
      font-family: $font-family-roboto-medium;
      &::after {
        font-family: "icomoon" !important;
        font-size: $font_root;
        margin-left: 5px;
        position: relative;
        top: 0px;
        width: 14px;
        height: 14px;
        display: inline-block;
        line-height: 14px;
        overflow: hidden;
        position: relative;
        top: 1px;
        content: "";
      }
      &[data-sort-order] {
        line-height: 15px;
        &::after {
          font-family: "icomoon" !important;
          font-size: $font_root;
          margin-left: 5px;
          position: relative;
          top: 0px;
          width: 14px;
          height: 14px;
          display: inline-block;
          line-height: 14px;
          overflow: hidden;
          position: relative;
          top: 3px;
          content: "";
        }
      }
      &[data-sort-order="asc"] {
        color: #2dac91;
        &::after {
          content: "\e906";
        }
      }
      &[data-sort-order="desc"] {
        color: #2dac91;
        &::after {
          content: "\e907";
        }
      }
    }
  }
  tbody {
    tr {
      vertical-align: top;
      overflow-y: hidden;
      transition-property: height;
      transition-duration: 0.3s, 0.3s;
      transition-timing-function: ease, ease-in;
      height: auto;
      background-color: $color_white;
      border-bottom: 1px solid $color_catskill_white;
      &.active {
        height: 100px;
        max-height: 300px;
        background-color: $color_grey_select;
        transition-property: height;
        transition-duration: 0.3s, 0.3s;
        transition-timing-function: ease, ease-in;
        .glyphicon-menu-down {
          transition-duration: 0.5s;
          transform: rotate(180deg);
        }
      }
      &.empty-data {
        td {
          text-align: center;
          color: $color_grey;
          line-height: 200px;
        }
      }
    }
    td {
      height: 40px;
      overflow: initial;
      line-height: 23px;
      font-size: $font_root;
      font-weight: 500;
      color: $color_mine_shaft;
      padding: 8px 15px 4px 15px;
      border-top: 1px solid $color_catskill_white;
    }
  }
}
</style>
