<template>
  <input type="text"
         :maxlength="maxLength"
         v-model="model"
         ref="input"
         @keypress="eventKeyPress"
         @focus="$emit('focus')"
         @blur="handleBlur"/>
</template>

<script>
  import CONST from '../common/Const'
  export default {
    data() {
      return {
        model: "",
        maxLength: CONST.MAX_LENGTH_INPUT,
      }
    },
    props: ['value'],
    watch: {
      value() {
        this.init();
      },
      model(newValue) {
        this.$emit('input', newValue);
      }
    },
    methods: {
      eventKeyPress(event) {
        let stringValue = "" + this.value;
        let charCode = (event.which) ? event.which : event.keyCode;
        if ((stringValue.length >= 190) || (charCode === 46 && (~ stringValue.indexOf('.') < 0)) ||
          ((charCode > 31 && (charCode < 48 || charCode > 57) && (charCode < 65 || charCode > 90) && (charCode < 97 || charCode > 122) ))) {
          event.preventDefault();
        } else {
          this.$emit("keypressinput", event);
          return true;
        }
      },

      init() {
        if (!this.value) {
          this.model = "";
          return;
        }
        this.model = this.value;
      },
      focus() {
        this.$refs.input.focus();
      },
      handleBlur(evt) {
        evt.target.value = insertNumber(evt.target.value);
        this.model = insertNumber(evt.target.value);
      }
    },
    directives: {
      "number-only": {
        bind(el, binding) {
          el.value = insertNumber(el.value);
          binding.value = el.value;
        },

        inserted(el, bind) {
          el.value = insertNumber(el.value);
          bind.value = el.value;
        },

        update(el, bind) {
          el.value = insertNumber(el.value);
          bind.value = el.value;
        },
      }
    },

    mounted(){
      this.init();
    }
  }

  function insertNumber(newValue) {
    newValue = "" + newValue;
    // newValue = newValue.match(/(\d)+(\.)?(\d)?/gi) ? newValue.match(/(\d)+(\.)?(\d)?/gi).join('') : "";
    return newValue;
  }
</script>

<style scoped>

</style>