<template>
  <div>
    <div class="page960">
      <div class="section">
        <div class="form-notice-detail-container">
          <div id="notice-detail">
            <div class="notice-detail-form-group">
              <label class="notice-detail-form-label">{{ $t('notice.title') }}</label>
              <div class="f-left"><input :maxlength="maxLength" class="notice-detail-form-value" id="input-title" type="text" v-model="record.title" :class="{'input-error': errors.has('title')}" /></div>
              <div class="clearifx"></div>
              <div class="error-box">
                <span v-show="errors.has('title')" class="f-left is-error">{{ errors.first('title') }}</span>
              </div>
            </div>
            <div class="notice-detail-form-group clearfix">
              <label class="notice-detail-form-label">{{ $t('notice.support') }}</label>
              <div class="f-left"><input :maxlength="maxLength" class="notice-detail-form-value" id="input-support" type="link" v-model="record.support_url" :class="{'input-error': errors.has('support_url')}"/></div>
              <div class="clearifx"></div>
              <div class="error-box">
                <span v-show="errors.has('support_url')" class="f-left is-error">{{ errors.first('support_url') }}</span>
              </div>
            </div>
            <div class="notice-detail-form-group clearfix">
              <div class="box_datetime_a">
                <div class="row">
                  <div class="col-md-6">
                    <label class="notice-detail-form-label">{{ $t('notice.start_at') }}</label>
                    <date-picker v-model="started_at" class="date-picker-input date-notice" format="yyyy-MM-dd"></date-picker>
                  </div>
                  <div class="col-md-6">
                    <label class="notice-detail-form-label">{{ $t('notice.end_at') }}</label>
                    <date-picker v-model="ended_at" class="date-picker-input date-notice" format="yyyy-MM-dd" :class="{'input-error': errors.has('end_at')}"></date-picker>
                    <div>
                      <span v-show="errors.has('end_at')" class="f-left is-error">{{ errors.first('end_at') }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="clearfix"></div>
            <div class="notice-detail-form-group notice-image clearfix">
              <span>{{ $t('notice.banner') }}</span>
              <div class="upload-file">
                <div class="uploadBox">
                  <div class="file-btn">
                    {{ $t('notice.choose_file') }}
                  <input class="" type="file" @change="(event) => previewImage(event, $refs.banner)" ref="file" accept="image/jpg, image/jpeg, image/png">

                  </div>
                </div>
              </div>
              <div class="clearfix"></div>
              <div class="preView" :class="{'input-error': errors.has('banner_url')}">
                <img v-if="!record.id" class="preview" ref="banner" >
                <img v-if="record.id" ref="banner" :src="`${apiUrl}/${record.banner_url}`"/>
              </div>
              <div class="error-box">
                <span v-show="errors.has('banner_url')" class="f-left is-error">{{ errors.first('banner_url') }}</span>
              </div>
            </div>
            <div class="clearfix"></div>
            <div class="submit">
              <button class="btn-cancel" @click="cancel">{{ $t('notice.cancel') }}</button>
              <button class="file-btn" @click="submitNotice(record.id)">{{ $t('notice.submit') }}</button>
            </div>
          </div>
        </div>
      </div>
      <message></message>
      <modal name="submitNotice" width="420" height="259" title="">
        <div slot="body" class="body-popup" style="font-size: 16px; padding: 10px 20px 0px; font-weight: 600;">
          {{ $t('notice.submit_data') }}
        </div>
      </modal>
    </div>
  </div>
</template>

<script>
  import rf from '../../lib/RequestFactory';
  import Modal from '@/desktop/components/common/Modal';
  import DatePicker from 'vuejs-datepicker';
  import moment from 'moment';
  import MasterdataUtils from '@/desktop/common/MasterdataUtils';
  import CONST from '../../common/Const';
  import Message from '@/desktop/components/common/Message';

  const IMAGE_FILE_UPLOAD_SIZE = 10485760; // 10Mb
  export default {

    components: {
        Modal,
        DatePicker,
        Message
      },

    data() {
      return {
        noticeId: this.$route.query.id,
        titlePage: this.$route.query.id ? window.i18n.t('notice.edit_title_page') : window.i18n.t('notice.new_title_page'),
        started_at: new Date(),
        ended_at: moment().add(6, 'day').toDate(),
        record: {
          title: "",
          support_url: ""
        },
        maxLength: CONST.MAX_LENGTH_INPUT,
        validateErrors:{},
        apiUrl: process.env.VUE_APP_STORAGE_URL
      };
    },
    methods: {
      submitNotice(id) {
        if (!this.validate()) {
          return;
        }
        this.record.title = this.record.title.trim(); 
        this.record.support_url = this.record.support_url.trim(); 
        ConfirmationModal.show({
          type: 'confirm',
          title: this.$t('notice.submit_data'),
          content         : '',
          btnCancelLabel  : this.$t('common.action.no'),
          btnConfirmLabel : this.$t('common.action.yes'),
          size            : 'small',
          onConfirm       : () => {
            this.requestHandler();
          },
          onCancel        : () => {}
        });
      },

      getParams() {
        let fd = new FormData();

        const bannerUrl = this.$refs.file.files[0] || this.record.banner_url;

        fd.append('title', this.record.title);
        fd.append('support_url', this.record.support_url);
        fd.append('started_at', moment(this.started_at).format('x'));
        fd.append('ended_at', moment(this.ended_at).format('x'));
        fd.append('banner_url', bannerUrl);

        return fd;
      },

      requestHandler() {
        if (!this.validate()) {
          return;
        }
        this.getRequest().then(res => {
          this.$toastr('success', this.noticeId ? window.i18n.t('common.alert.update.success') : window.i18n.t('common.alert.create.success'));

          var _cpn = this;
          setTimeout(function(){ 
            _cpn.$router.push({name: 'Notice'});
          }, 1000);
        }).catch(error => {
          if (!error.response) {
            Message.error(window.i18n.t('common.message.network_error'), {}, { position: "bottom_left" });
            return;
          }
          const errors = error.response.data.errors;
          this.validateErrors = errors;

          window._.each(this.validateErrors, (value, key) => {
            let msg = value[0].formatUnicorn({attribute: window.i18n.t('notice.' + key).toLowerCase(), values:".jpg, .jpeg, .png, .pneg"});
            this.$validator.errors.add({field: key, msg: msg});
          });
        });
      },

      getRequest() {
        const params = this.getParams();
        if (this.record && this.record.id) {
          params.append('id', this.record.id);
          return rf.getRequest('AdminRequest').updateNotice(params);
        }
        return rf.getRequest('AdminRequest').createNotice(params);
      },

      validate() {
        this.errors.clear();

        this.validateRequired(this.record.title, 'title');
        this.validateRequired(this.record.support_url, 'support_url');

        // update notice
        if (this.record.banner_url) {
          if (this.$refs.file.files[0]) {
            this.validateImage(this.$refs.file.files[0], 'banner_url');
          }
        }
        else { // create notice
          this.validateImage(this.$refs.file.files[0], 'banner_url');
        }

        this.validateDate(moment(this.started_at).format('x'),  moment(this.ended_at).format('x'), 'end_at');

        if(this.errors.count() != 0) {
          return false;
        }

        return true;
      },

      validateRequired(value, field) {
        if (_.isEmpty(value ? value.trim() : "")) {
          this.errors.add({ field: field, msg: window.i18n.t('notice.errors.' + field + '.required')});
        }
      },

      validateImage(value, field) {
        if (typeof(value) == "undefined") {
          this.errors.add({ field: field, msg: window.i18n.t('notice.errors.' + field + '.required')});
        }
        else if (value.size > IMAGE_FILE_UPLOAD_SIZE) {
          this.errors.add({ field: field, msg: window.i18n.t('notice.errors.maxsize')});
        }
      },

      validateDate(start_at, end_at, field) {
        if (start_at > end_at) {
          this.errors.add({ field: field, msg: window.i18n.t('notice.errors.date')});
        }
      },

      cancel() {
        this.$router.push({
          name: 'Notice'
        });
      },

      previewImage: function(event, ref) {
        this.errors.remove('banner_url');
        var input = event.target;
        if (input.files && input.files[0]) {
          const file = input.files[0];
          const  fileType = file['type'];
          const validImageTypes = ['image/jpg', 'image/jpeg', 'image/png'];
          if (input.files && validImageTypes.includes(fileType)) {
            var reader = new FileReader();
            reader.onload = (e) => {
              ref.src = e.target.result;
            }
            reader.readAsDataURL(input.files[0]);
          } else {
            this.errors.add({ field: 'banner_url', msg: window.i18n.t('notice.validate.image_type')});
            ref.src.replace('/thumbs','');
            input.value = '';
          }
        } else {
          this.errors.add({ field: 'banner_url', msg: window.i18n.t('notice.validate.image_type')});
          ref.src.replace('/thumbs','');
          input.value = '';
        }
      },

      showSuccess(message) {
        window.Message.success(message);
      }

    },

    mounted() {
      this.$emit('EVENT_PAGE_CHANGE', this);
      if (!this.noticeId) {
        return;
      }
      rf.getRequest('AdminRequest').getEditNotice(this.noticeId).then(res => {
        this.record = res.data || {};
        this.started_at = moment(this.record.started_at, 'x').toDate();
        this.ended_at = moment(this.record.ended_at, 'x').toDate();
      });
    }
  }

  String.prototype.formatUnicorn = String.prototype.formatUnicorn ||
  function () {
      "use strict";
      var str = this.toString();
      if (arguments.length) {
          var t = typeof arguments[0];
          var key;
          var args = ("string" === t || "number" === t) ?
              Array.prototype.slice.call(arguments)
              : arguments[0];

          for (key in args) {
              str = str.replace(new RegExp("\\{" + key + "\\}", "gi"), args[key]);
          }
      }
      return str;
  };
</script>

<style lang="scss">
@import "@/assets/sass/variables";
  #notice-detail {
    .date-picker-input {
      width: 100%;
      display: inline-block;
      input {
        width: 100% !important;
        padding: 0 30px 0px 10px;
        border-radius: 3px;
        -webkit-box-shadow: none;
        box-shadow: none;
        height: 30px !important;
        line-height: 30px !important;
        border: 1px solid #d2d6de;
        background-image: url('@/assets/images/icon/date-time.svg') !important;
        background-color: $color-white;
        background-size: 16px;
        background-position: center right 10px;
        background-repeat: no-repeat !important;
        color: $color-grey-dark;
        font-family: $font-family-roboto-medium;
        font-size: $font_root;
        &:hover, &:active, &:focus {
          border-color: $color-jungle-green;
          z-index: 9;
        }
      }
    }
  }
  .date-notice input{
    // padding: 0 30px 0px 25px !important;
      background-position: center right 10px !important;
      background-size:14px !important;
  }
</style>

<style lang="scss" scoped>
@import "@/assets/sass/variables";
  .is-error {
    display: inline-block;
    line-height: 20px;
    padding-top: 5px;
  }
.box_datetime_a {
  width: 580px;
  max-width: 100%
}
.is-error {
  color: #ff5252 !important;
}
.input-error {
  border: 1px solid #ff5252 !important;
}
.f-left {
  float: left;
}
.required {
  color:#f00;
}
.error-box {
    display: block;
    float: left;
    overflow: hidden;
    width: 100%;
  span {
    font-family: $font-family-roboto-medium !important;
  }
}
.page960 {
  width: 1129px;
  min-width: 1129px;
}
.f-left {
  float: left;
}
button[disabled] {
  opacity: 0.4 !important;
}
.form-notice-detail-container {
  padding: 0px 40px 0 0 !important;
  .notice-detail-country {
    min-height: 30px;
    .notice-detail-form-label {
      margin-right: 22px;
      width: 130px;
      text-align: right;
      font-size: 14px;
      color: black;
      float: left;
      margin-bottom: 0px;
      margin-top: 10px;
    }
  }
  .filed-verification {
    .filed {
      .tip {
        font-size: 12px;
        color: #666;
        padding: 20px 0;
      }
    }
  }
  .notice-detail-form-group {
    height: auto;
    float: left;
    &.notice-image {
      height: auto;
      margin-top: 15px;
      span {
        color: $color_dove_gray;
        font-family: $font-family-roboto;
        font-weight: normal;
      }
    }
    .notice-detail-form-label {
      width: 100% ;
      text-align: left;
      font-size: 14px;
      color: $color_dove_gray;
      float: left;
      font-weight: normal;
      font-family: $font-family-roboto;
      margin-bottom: 0px;
      margin-top: 10px;
    }
    .upload-file {
      display: inline-block;
      margin-left: 10px;
    }
    .col-md-6 {
      input {
        width: 275px;
      }
    }
    input {
      width: 580px;
      padding: 13px 12px;
      outline: none;
      border: 1px solid #dee3eb;
      height: 35px;
      line-height: 35px;
      background-color: $color-white-smoke;
      border-radius: 3px;
      font-family: $font-family-roboto;
      font-weight: 600;
      &:hover, &:active, &:focus {
        border-color: $color-jungle-green;
      }
    }
    .preView {
      width: 270px;
      height: 150px;
      background-color :#d8d8d8;
      position: relative;
      float: left;
      margin-top: 10px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .file-btn {
      background-color: $color-white-smoke;
      color: $color-jungle-green;
      width: 90px;
      height: 23px;
      text-align: center;
      position: relative;
      padding: 0 10px;
      display: inline-block;
      cursor: pointer;
      font-size: $font_mini_mini;
      border: 1px solid $color-caribbean-green;
      text-transform: uppercase;
      border-radius: 3px;
      font-weight: 600;
      line-height: 23px;
      font-family: $font-family-roboto;
      &:hover {
        background-color: $color-aquamarine;
        border: 1px solid $color-aquamarine;
        color: $color-white;
      }
    }
    input[type=file] {
      position: absolute;
      left: 0;
      top: 0;
      cursor: pointer;
      height: 100%;
      width: 100%;
      opacity: 0;
    }
  }
  .submit {
    padding: 30px 0 30px 0;
    text-align: left;
    .file-btn {
      background-color: $color-caribbean-green;
      color: $color-white;
      height: 35px;
      position: relative;
      padding: 0px 20px;
      display: inline-block;
      cursor: pointer;
      font-size: $font-smaller;
      border: 1px solid $color-caribbean-green;
      text-align:center;
      text-transform: uppercase;
      font-weight: 600;
      border-radius: 3px;
      &:hover {
        background-color: $color-aquamarine;
        border-color: $color-aquamarine;
      }
    }
    .btn-cancel {
      background-color: $color-caribbean-green;
      color: $color-white;
      height: 35px;
      position: relative;
      padding: 0px 20px;
      display: inline-block;
      cursor: pointer;
      font-size: $font-smaller;
      border: 1px solid $color-caribbean-green;
      text-align:center;
      text-transform: uppercase;
      font-weight: 600;
      border-radius: 3px;
      margin-right: 10px;
      &:hover {
        background-color: $color-aquamarine;
        border-color: $color-aquamarine;
      }
    }
    button {
      position: absolute;
      left: 0;
      top: 0;
      cursor: pointer;
      height: 32px;
      width: 98px;
      font-family: $font-family-roboto;
    }
  }
}
</style>