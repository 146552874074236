<template>
  <div class="sc_search_select" :class="{ 'disabled-select': disabled }" v-click-outside="turnOffSelect">
    <div class="group_search_select">
      <div class="button_drop_search" :class="[{ active: isShow }]" @click="toggleSelect()">
        <slot name="label_selected" v-if="customLabel" :selected="value"></slot>
        <span v-else v-html="label" />
        <i class="icon-arrow1"></i>
      </div>
      <div class="box_list_search_select active" v-show="isShow">
        <ul class="list_search_select">
          <li>
            <div class="group-search-input" v-show="searchable">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M11.4994 6.99941C11.4994 7.59035 11.383 8.17552 11.1568 8.72148C10.9307 9.26745 10.5992 9.76352 10.1814 10.1814C9.76352 10.5992 9.26745 10.9307 8.72148 11.1569C8.17552 11.383 7.59036 11.4994 6.99941 11.4994C6.40846 11.4994 5.8233 11.383 5.27733 11.1569C4.73137 10.9307 4.23529 10.5992 3.81743 10.1814C3.39956 9.76352 3.0681 9.26745 2.84195 8.72148C2.6158 8.17552 2.49941 7.59035 2.49941 6.99941C2.49941 5.80593 2.97351 4.66134 3.81743 3.81743C4.66134 2.97351 5.80593 2.49941 6.99941 2.49941C8.19288 2.49941 9.33747 2.97351 10.1814 3.81743C11.0253 4.66134 11.4994 5.80593 11.4994 6.99941ZM10.6794 11.7394C9.47379 12.6754 7.95678 13.1167 6.43721 12.9735C4.91764 12.8304 3.50975 12.1135 2.50016 10.9688C1.49056 9.82412 0.955164 8.3377 1.00294 6.81216C1.05073 5.28661 1.67811 3.83661 2.75736 2.75736C3.83662 1.67811 5.28661 1.05073 6.81216 1.00294C8.33771 0.955161 9.82412 1.49056 10.9688 2.50016C12.1135 3.50975 12.8304 4.91764 12.9735 6.43721C13.1167 7.95677 12.6754 9.47378 11.7394 10.6794L14.7794 13.7194C14.8531 13.7881 14.9122 13.8709 14.9532 13.9629C14.9942 14.0549 15.0162 14.1542 15.018 14.2549C15.0198 14.3556 15.0012 14.4556 14.9635 14.549C14.9258 14.6424 14.8696 14.7272 14.7984 14.7984C14.7272 14.8697 14.6424 14.9258 14.549 14.9635C14.4556 15.0012 14.3556 15.0198 14.2549 15.018C14.1542 15.0162 14.0548 14.9942 13.9629 14.9532C13.8709 14.9122 13.7881 14.8531 13.7194 14.7794L10.6794 11.7394Z"
                  fill="#C7CBD3" />
              </svg>
              <input autocomplete="off" name="search-term" placeholder="Search" type="text" class="form-control"
                v-model="searchTerm" :disabled="disabled" />
            </div>
          </li>
          <li v-for="option in filteredOptions" @click="select(option)">
            <slot v-if="customLabel" name="option" :option="option"></slot>
            <span v-else class="full_name_coin_select" v-html="getSelectedValue(option)" />
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    placeholder: {
      type: String
    },
    customLabel: {
      type: Boolean,
      default: false
    },
    options: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    value: { default: "" },
    searchable: { default: false },
    onFocus: {
      type: Function,
      default: () => {}
    },
    onChange: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      isShow: false,
      searchTerm: '',
      filteredOptions: this.options
    };
  },
  computed: {
    label() {
      const selected = this.getSelectedValue(this.value);
      return selected || this.placeholder;
    }
  },
  watch: {
    options() {
      this.filteredOptions = this.options;
    },
    searchTerm(st) {
      this.filteredOptions = this.options.filter(option => option.name.toLowerCase().includes(st.toLowerCase()))
    }
  },
  methods: {
    toggleSelect() {
      this.isShow = !this.isShow;
      this.onFocus();
    },
    turnOffSelect() {
      this.isShow = false;
    },
    select(option) {
      this.$emit("input", option);
      this.onChange(option);
      this.turnOffSelect();
    },
    getSelectedValue(option) {
      const selected = this.options.find(item => {
        if (window._.isObject(option)) {
          return item.id === option.id;
        }
        return item === option;
      });
      if (typeof selected !== 'undefined') {
        return window._.isObject(option) ? selected.name : selected;
      }
      else {
        return null;
      }
    },
    includes(str, query) {
      /* istanbul ignore else */
      if (str === undefined) str = "undefined";
      if (str === null) str = "null";
      if (str === false) str = "false";
      const text = str.toString().toLowerCase();
      const value = query
        .trim()
        .toString()
        .toLowerCase();
      return text.includes(value);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/variables";

.pr-5 {
  padding-right: 5px;
}

::-webkit-scrollbar {
  width: 5px;
}

.disabled-select {
  .group_search_select {
    cursor: not-allowed !important;

    .button_drop_search {
      pointer-events: none;
    }
  }
}

.icon-class {
  .sc_search_select {
    .group_search_select {
      .box_list_search_select {
        min-width: 150px;
        left: 50%;
        transform: translateX(-50%);

        .list_search_select {
          max-height: 150px;
          overflow-y: auto;
        }
      }
    }
  }
}

.icon-type {
  .sc_search_select {
    .group_search_select {
      .box_list_search_select {
        .list_search_select {
          max-height: 150px;
          overflow-y: auto;
        }
      }
    }
  }
}

.sc_search_select {
  .tit_search_select {
    font-size: $font-title-size-big;
    color: $color-white;
    margin-bottom: 20px;
    line-height: 44px;
  }

  .group_search_select {
    position: relative;

    .button_drop_search {
      cursor: pointer;
      height: 27px;
      overflow: hidden;
      line-height: 18px;
      padding: 3px 5px;
      border: 1px solid $color-alto;
      border-radius: 3px;

      // &.active{
      //   background: -moz-linear-gradient(180deg, $background-default 60%, #CCCFD1 100%);/* FF3.6+ */
      //   background: -webkit-gradient(linear, 180deg, color-stop(60%, FFFFFF), color-stop(100%, CCCFD1));/* Chrome,Safari4+ */
      //   background: -webkit-linear-gradient(180deg, $background-default 60%, #CCCFD1 100%);/* Chrome10+,Safari5.1+ */
      //   background: -o-linear-gradient(180deg, $background-default 60%, #CCCFD1 100%);/* Opera 11.10+ */
      //   background: -ms-linear-gradient(180deg, $background-default 60%, #CCCFD1 100%);/* IE10+ */
      //   filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#1301FE', endColorstr='#F4F60C', GradientType='1'); /* for IE */
      //   background: linear-gradient(180deg, $background-default 60%, #CCCFD1 100%);/* W3C */
      // }
      span {
        display: inline-block;
        float: left;
        color: $color-grey-dusty;
        font-size: $font-smaller;
        line-height: 18px;
      }

      .icon-arrow1 {
        padding: 4px 0px;
        font-size: 11px;
        float: right;
      }

      &.active {
        display: block;
        visibility: visible;
        opacity: 1;
      }
    }

    .box_list_search_select {
      padding-top: 5px;
      position: absolute;
      display: none;
      width: 100%;
      top: 100%;
      left: 0px;
      z-index: 111;
      float: left;
      visibility: hidden;
      opacity: 0;
      max-height: 470px;

      ul {
        margin: 0;
      }

      &.active {
        display: block;
        visibility: visible;
        opacity: 1;
      }
    }

    .list_search_select {
      background: $background-default;
      box-shadow: 0px 3px 12px rgba(49, 49, 49, 0.51);
      list-style-type: none;
      max-height: 400px;
      position: relative;
      min-width: 100px;
      padding: 12px 0px;
      overflow-y: auto;

      &:after {
        bottom: 100%;
        left: 50%;
        border: solid transparent;
        content: " ";
        display: block;
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: rgba(136, 183, 213, 0);
        border-bottom-color: $color-white;
        border-width: 5px;
        margin-left: -5px;
      }

      li {
        display: block;
        width: 100%;
        line-height: 20px;
        cursor: pointer;
        padding: 3px 14px;
        font-size: $font-root;
        overflow: hidden;

        &:hover {
          background: $color-aqua-day;
        }
      }
    }
  }

  .group-search-input {
    position: relative;

    svg {
      position: absolute;
      transform: translateY(-50%);
      top: 50%;
      left: 16px;
      width: 16px;
      height: 16px;
    }

    input {
      height: 36px;
      border-radius: 8px !important;
      border: 1px solid rgba(199, 203, 211, 0.50) !important;
      background: rgba(199, 203, 211, 0.15) !important;
      display: flex;
      padding: 9px 16px 9px 40px;
      align-items: center;
      gap: 8px;
      flex: 1 0 0;
      color: #C7CBD3;
      font-size: $font_root;
      font-family: $mulish-medium;
      font-weight: 500;

      &::placeholder {
        color: #C7CBD3;
      }
    }
  }
}
</style>
