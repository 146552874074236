<template>
  <div id="new_tier">
    <div class="header" v-if="!isEdit">
      <span class="sub-title" @click="backToCreateInstruments">  <i class="icon-arrow4" ></i> {{ $t('instruments.previous') }} </span>
    </div>
    <div class="header-add-reward">
      <button class="btn btn-add-new" @click="addNewTier"  :disabled="leverageMargin.length >= 5" >
        <img class="" src="@/assets/images/icon/plus.svg">
        {{ $t('instruments.create_new_tier') }}
      </button>
    </div>
    <div class="tableContainer" >
    <div class="datatable">
      <table>
        <thead>
        <tr >
          <th class="text-left">{{ $t('reward_center.no') }}</th>
          <th class="text-left" >{{ $t('instruments.position_bracket') }}</th>
          <th class="text-left">{{ $t('instruments.max_leverage') }}</th>
          <th class="text-left" >{{ $t('instruments.maintenace_margin') }}</th>
          <th class="text-left" >{{ $t('instruments.maintenace_amount') }}</th>
          <th class="text-right" ></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(row, index) in leverageMargin" >
          <td class="text-left">{{  index + 1 }}</td>
          <td class="text-left">
            <div class="position-bracket">
                  <input v-if="index == 0" type="text" class="input form-group" disabled="true" v-model="row.min = 0">
                  <input v-else type="text" class="input form-group" disabled="true" v-model=" row.min  = leverageMargin[index - 1 ].max ">
                  - <number-input
              ref="max"
              id="max"
              :precision="8"
              :restrict-step-price="true"
              :name="'max'+index"
              class="input form-group"
              :class="{ errorInput: errors.has('max'+index)}"
              v-model="row.max"
              data-vv-validate-on="none"
              @focus="removeValidate('max'+index)"
              
            >
            </number-input>

            </div>
            <span class="error-message" v-if="errors.has('max'+index)"> {{ errors.first('max'+ index ) }} </span>

          </td>
          <td class="text-left">
            <div>
<!--              <input type="text" class="input form-group" v-model="row.maxLeverage" >-->
              <number-input
                class="input form-group"
                :class="{ errorInput: errors.has('maxLevegrage')}"
                @input="maxLeverage(row, index)"
                :name="'maxLevegrage'+index"
                ref="maxLevegrage"
                data-vv-validate-on="none"
                v-validate="'required'"
                v-model="row.maxLeverage "
                @focus="removeValidate('maxLevegrage'+index)"
                
              />
            </div>
            <span class="error-message" v-if="errors.has('maxLevegrage'  + index)"> {{ errors.first('maxLevegrage'  + index) }} </span>

          </td>
          <td class="text-left">
            <div>
              <input type="text" class="input form-group"
                     @input="maxMaintenance(row, index)"
                     :name="'maintenanceMarginRate'+index"
                     ref="maintenanceMarginRate"
                     v-validate="'required'"
                     v-model="row.maintenanceMarginRate"
                     @focus="removeValidate('maintenanceMarginRate'+index)"
              >
            </div>
            <span class="error-message" v-if="errors.has('maintenanceMarginRate'+index)"> {{ errors.first('maintenanceMarginRate' + index) }} </span>
          </td>
          <td class="text-left">
            <div>
              <input type="text" class="input form-group"
                     disabled="true"
                     :value="row.maintenanceAmount ?? 0"
              >
            </div>
          </td>

          <td class="text-right d-flex">
              <span @click.prevent="removeNewTier(index)">
                <img class="" src="@/assets/images/icon/remove-item.svg">
              </span>
          </td>
         </tr>
        </tbody>
      </table>
    </div>
      </div>
      <button v-if="!isEdit" class="submit-instruments" @click="saveInstruments"> {{ $t('user.save') }} </button>
  </div>

</template>

<script>
import NumberInput from "@/common/NumberInput.vue";
import rf from "@/lib/RequestFactory";
import BigNumber from "bignumber.js";

export default {
  props: {
    isEdit: {default: false},
    instrumentTier: {
      type: Array,
      default() {
          return []
      }
    },
    changeLeverage: null
  },
  data() {
    return {
      titlePage: "Create market",
      leverageMargin: [{}],
      leverageMarginDeleted: [],
      leverage: this.$route.params.maxLeverage * 5
    }
  },
  watch: {
    'leverageMargin': {
      handler: function (newVal, oldVal) {
        this.$props.changeLeverage(newVal, this.leverageMarginDeleted)
      },
      deep: true
    }
  },
  components: {
    NumberInput
  },
  methods: {
    addNewTier() {
      this.leverageMargin.push({})
    },
    async saveInstruments() {
      for (let i = 0; i < this.leverageMargin.length; i++) {
        const { max } = this.leverageMargin[i];
        this.leverageMargin[i].tier = i+1
        this.leverageMargin[i].contractType = this.$route.query.contract
          this.checkDataWithZero(max, 'max'+i);
      }
      await this.$validator.validateAll()
      if (this.errors.any()) {
        return
      }
      let valueInstruments = this.$route.params.value
      valueInstruments.instrument.contractType = this.$route.query.contract
      const data  = {
        ...valueInstruments,
        leverageMargin: this.leverageMargin
      }
      return rf.getRequest('AdminRequest').
      createInstrumentFuture(Object.assign(data)).then(res => {
        this.$toastr('success', this.$t('user.succes'));
        this.$router.push({name: 'Instruments Futures'});
      }).catch(err => {
        this.$toastr('error', 'Symbol already exist');
      })
    },
    removeValidate(data) {
      this.errors.remove(data);
    },
    checkDataWithZero(value, field) {
      if (value <= 0 || value == undefined) {
        this.errors.add({field: field , msg: 'The field is required' });
        return false;
      }
      return true;
    },

    onUpdateInstruments() {
      this.leverageMargin =  this.removeTrailingZeros(this.instrumentTier)
    },

    removeTrailingZeros(arr) {
    for (let i = 0; i < arr.length; i++) {
      for (let prop in arr[i]) {
        if (typeof arr[i][prop] === "string" && arr[i][prop].includes(".")) {
          arr[i][prop] = arr[i][prop].replace(/\.?0+$/, "");
        }
      }
    }
    return arr;
    },

    removeNewTier(index){
      if (this.leverageMargin[index].id) 
        this.leverageMarginDeleted = [
            ...this.leverageMarginDeleted, 
            {
              ...this.leverageMargin[index],
              isDeleted: true
            } 
          ]
      this.leverageMargin.splice(index, 1)
    },

    backToCreateInstruments() {
      this.$router.push({name: 'Create Market Futures',
        query: {contract: this.$route.query.contract},
        params: {
          value: this.$route.params.value,
          dataTier: this.leverageMargin,
          maxLeverage: this.$route.params.maxLeverage,
          impactMarginNotional: this.$route.params.impactMarginNotional
      }});
    },
    maxMaintenance(row, index) {
      if(row.maintenanceMarginRate < 1/(row.maxLeverage/100)) {
        const dataMargin = row.maintenanceMarginRate
        this.maxMaintenanceAmount(row, index)
        return   dataMargin
      } else {
        const value =  1/(row.maxLeverage/100)
        const dataMargin = row.maintenanceMarginRate =  value
        this.maxMaintenanceAmount(row, index)
        return dataMargin
      }
    },
    maxMaintenanceAmount(row, index) {
      if(index === 0 ) {
        row.maintenanceAmount = 0
      } else {
        // Maintenance amount of previous tier + The first value of Position Bracket of current tier *
        // (Maintenance margin rate of current tier - Maintenance margin rate of previous tier)
        const prevRow = this.leverageMargin[index - 1];
        // const value = prevRow.maintenanceAmount + (row.min * (row.maintenanceMarginRate - prevRow.maintenanceMarginRate) / 100);
        const value = new BigNumber(prevRow.maintenanceAmount).add(new BigNumber(row.min).mul(new BigNumber(row.maintenanceMarginRate).sub(prevRow.maintenanceMarginRate)).div(100));
        row.maintenanceAmount = value.toString()
      }
    },
    maxLeverage(row, index) {
      if(row.maintenanceMarginRate) {
        this.maxMaintenance(row, index)
      }
      if(row.maxLeverage > this.leverage) {
        return row.maxLeverage = this.leverage
      }
    },
    async validateTradingTier() {
      for (let i = 0; i < this.leverageMargin.length; i++) {
        const { max } = this.leverageMargin[i];
        this.leverageMargin[i].tier = i+1
        this.leverageMargin[i].contractType = this.$route.query.contract
          this.checkDataWithZero(max, 'max'+i);
      }
      await this.$validator.validateAll()
      return this.errors.any()
    },
  },

  mounted() {
    const dataTier = this.$route.params.dataTier
    if(dataTier) {
      this.leverageMargin = dataTier
    }

  },
  created() {
    if(this.isEdit){
      this.onUpdateInstruments()
    }
  },

}



</script>

<style scoped lang="scss">
@import "@/assets/sass/variables";
#new_tier{
  .header {
    margin-top: 24px;
    .sub-title {
      font-size: $font_big_20;
      margin: 26px 0px ;
      padding: 7px 20px;
      font-family: $mulish-regular;
      line-height: 25px;
      font-weight: bold;
      align-items: center;
      gap: 16px;
      color: $dark-1;
      border: 2px solid $color-gray-ghost;
      border-radius: 20px;
      cursor: pointer;
      .icon-arrow3:before{
        font-size: $font-root;
        color: $dark-1;
      }
    }

  }
  :deep(input[disabled]) {
    background-color: $color-athens !important;
  }
  .header-add-reward {
    margin:  40px 0;
    .btn-add-new {
      //border: 1px solid $color-caribbean-green;
      display: flex;
      align-items: center;
      gap: 10px;
      width: 205px;
      max-width: 100%;
      font-size: $font_semi_big;
      color: $color-grey-dark;
      background-color: $color-caribbean-green;
      padding: 10.5px 22px;
      border-radius: 30px;
      font-family: $mulish-bold;
      height: 41px;

      span {
        margin-right: 5px;
      }
    }
  }
  .tableContainer {
    .datatable{
      table {
        width: 100%;
        background-color: $color_white;
        // border: $border_thin;
        overflow-y: hidden;
        thead {
          color: $color_grey;
          background-color: $color_white;
          border: none;
          overflow: hidden;
          th {
            user-select: none;
            position: relative;
            background-color: $color_white;
            line-height: 15px;
            font-size: $font_small;
            font-weight: 500;
            color: $color_grey;
            padding: 5px 15px;
            border-bottom: 1px solid $color_alto;
            font-family: $font-family-roboto-medium;
            height: 38px;
            &::after {
              font-family: "icomoon" !important;
              font-size: $font_small;
              margin-left: 5px;
              position: relative;
              top: 0px;
              width: 14px;
              height: 14px;
              display: inline-block;
              line-height: 14px;
              overflow: hidden;
              position: relative;
              top: 3px;
              content: "";
            }
            &[data-sort-order] {
              line-height: 15px;
              &::after {
                font-family: "icomoon" !important;
                font-size: $font_small;
                margin-left: 5px;
                position: relative;
                top: 0px;
                width: 14px;
                height: 14px;
                display: inline-block;
                line-height: 14px;
                overflow: hidden;
                position: relative;
                top: 3px;
                content: "";
              }
            }
            &[data-sort-order="asc"] {
              color: #2dac91;
              &::after {
                content: "\e906";
              }
            }
            &[data-sort-order="desc"] {
              color: #2dac91;
              &::after {
                content: "\e907";
              }
            }
          }
        }
        tbody {
          tr {
            height: 100px !important;
            vertical-align: top;
            overflow-y: hidden;
            transition-property: height;
            transition-duration: 0.3s, 0.3s;
            transition-timing-function: ease, ease-in;
            height: auto;
            background-color: $color_white;
            border-bottom: 1px solid $color_catskill_white;
            &.active {
              height: 100px;
              max-height: 300px;
              background-color: $color_grey_select;
              transition-property: height;
              transition-duration: 0.3s, 0.3s;
              transition-timing-function: ease, ease-in;
              .glyphicon-menu-down {
                transition-duration: 0.5s;
                transform: rotate(180deg);
              }
            }
            &.empty-data {
              td {
                text-align: center;
                color: $color_grey;
                line-height: 200px;
                p {
                  margin: 0px;
                }
              }
            }
          }
          td {
            height: 40px;
            overflow: initial;
            line-height: 23px;
            font-size: $font_root;
            font-weight: 500;
            color: $color-grey-dark;
            padding: 8px 25px 4px 15px;
            border-top: 1px solid $color-white-smoke;
            vertical-align: middle;
            font-family: $font-family-roboto;
            div {
              .form-group {
                padding: 10.5px 16px;
                border: 1px solid #C7CBD3 !important;
                border-radius: 12px !important;
                width: 160px;
                height: 41px;
                position: relative;
              }
            }
          }
        }
      }
      .pagination {
        display:  flex;
        width: 100%;
        align-items: center;
        #total_user {
          width: 8% ;
          padding-bottom: 5px;
          margin-right: 5px;
          font-family: $mulish-medium;
          line-height: 25px;
          color: $dark-1;
        }
        .text-center {
          width: 100%;
          .VuePagination {
            width: 100%;
            display: flex;
            ul {
              justify-content: end;
            }
          }
        }
      }
    }
  }
  .submit-instruments {
    margin-top: 20px;
    padding: 10.5px 30px;
    width: 125px;
    height: 41px;
    background-color: $color-caribbean-green;
    color: $dark-1;
    border: none;
    border-radius: 30px;
    font-size: $font-semi_big;
    color: $color-grey-dark;
    font-family: $mulish-bold;
  }
  .error-message {
    position: absolute;
    color: $color-denim;
  }
}

</style>

