import BaseRequest from '../lib/BaseRequest';

export default class NotificationRequest extends BaseRequest {

  getNotifications() {
    let url = '/admin/api/notifications';
    return this.get(url);
  }

  markAsRead(notificationId) {
    let url = `/admin/api/notifications/read/${notificationId}`;
    return this.get(url);
  }

  getListTypeInquiry() {
    let url = '/admin/inquiry/type';
    return this.get(url);
  }

  getInquiries(params) {
    let url = '/admin/inquiries';
    return this.get(url, params);
  }

  getDetailInquiry(id) {
    let url = `/admin/inquiry/${id}`;
    return this.get(url);
  }

  updateInquiry(id, params) {
    let url = `/admin/inquiry/${id}`;
    return this.put(url, params);
  }
}
