import BaseRequest from './BaseRequest'

export default class TransactionRequest extends BaseRequest {
  getPaymentHistories(params) {
    let url = '/admin/api/payment-histories';
    return this.get(url, params);
  }

  getExternalWithdraws(params) {
    let url = '/admin/api/transactions/external-withdraws';
    return this.get(url, params);
  }

  changeStatus(params) {
    let url = '/admin/api/transactions/change-status';
    return this.get(url, params);
  }

  registrationRemittance(params) {
    let url = '/admin/api/transactions/registration-remittance';
    return this.post(url, params);
  }

  getTransaction(transactionId) {
    let url = `/admin/api/transactions/${transactionId}`;
    return this.get(url);
  }

  approveTransaction(params) {
    let url = `/admin/api/transactions/approve`;
    return this.put(url, params);
  }

  getWithdrawalHistory(params) {
    let url = '/admin/api/transactions/withdrawal-history';
    return this.get(url, params);
  }

  getTransactionWithdrawByAccount(id, params) {
    let url = `/admin/account/${id}/transactions/withdraw`;
    return this.get(url, params);
  }

  getTransactionDepositByAccount(id, params) {
    let url = `/admin/account/${id}/transactions/deposit`;
    return this.get(url, params);
  }

  getTransactionTransferByAccount(id, params) {
    let url = `/admin/account/${id}/transactions/transfer`;
    return this.get(url, params);
  }
}
