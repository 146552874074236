import BaseRequest from './BaseRequest'

export default class LeaderboardRequest extends BaseRequest {

  getTradingVolumeRanking(params) {
    let url = '/admin/api/leaderboard/get-top-trading-volume-ranking';
    return this.get(url, params);
  }

  updateLeaderboardSetting(params){
    let url = '/admin/api/leaderboard/update-leaderboard-setting';
    return this.put(url, params);
  }

  changeSetting(params){
    let url = '/admin/api/leaderboard/change-trading-setting';
    return this.post(url, params);
  }

  getSelfTradingSettings(){
    let url = '/admin/api/leaderboard/get-self-trading-setting';
    return this.get(url);
  }

  getLeaderboardSetting(params){
    let url = '/admin/api/leaderboard/get-leaderboard-setting';
    return this.get(url, params);
  }

  getSettings(){
    let url = '/admin/api/site-settings';
    return this.get(url);
  }

  setSettings(params){
    let url = `/admin/api/site-settings/update`;
    return this.post(url, params);
  }
}
