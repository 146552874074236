<template>
  <div class="instruments mg_bot50" id="instruments">
    <div class="filter-container">
      <div class="filter-item">
        <button class="btn btn-search" @click="createInstrument">
          <span class="glyphicon glyphicon-plus"></span>{{ $t('instruments.create_instrument') }}
        </button>
      </div>
      <div class="filter-item t-right">
        <input
          title
          type="text"
          :placeholder="$t('common.search')"
          @keyup.enter="search"
          class="form-control"
          name="searchKey"
          v-model="searchKey"
        >
      </div>
      
    </div>

    

    <div class="datatable">
      <data-table-4 :getData="loadData" ref="datatable" :limit="10" 
        :column="24" @DataTable:finish="onDatatableFinish"
        class="scroll">
        <th class="non-sort"></th>
        <th class="text-left"  data-sort-field="symbol">{{ $t('instruments.symbol') }}</th>
        <th class="text-left"  data-sort-field="root_symbol">{{ $t('instruments.root_symbol') }}</th>
        <th class="text-left"  data-sort-field="state">{{ $t('instruments.state') }}</th>
        <th class="text-left" data-sort-field="type">{{ $t('instruments.type') }}</th>
        <th class="text-left" data-sort-field="expiry">{{ $t('instruments.expiry') }}</th>
        <th class="text-left"  data-sort-field="base_underlying">{{ $t('instruments.base_underlying') }}</th>
        <th class="text-left"  data-sort-field="quote_currency">{{ $t('instruments.quote_currency') }}</th>
        <th class="text-left"  data-sort-field="underlying_symbol">{{ $t('instruments.underlying_symbol') }}</th>
        <th class="text-left"  data-sort-field="init_margin">{{ $t('instruments.init_margin') }}</th>
        <th class="text-left" data-sort-field="maint_margin">{{ $t('instruments.maint_margin') }}</th>
        <th class="text-left" data-sort-field="maker_fee">{{ $t('instruments.maker_fee') }}</th>
        <th class="text-left"  data-sort-field="taker_fee">{{ $t('instruments.taker_fee') }}</th>
        <th class="text-left"  data-sort-field="settlement_fee">{{ $t('instruments.settlement_fee') }}%</th>
        <th class="text-left"  data-sort-field="reference_index">{{ $t('instruments.reference_index') }}</th>
        <th class="text-left"  data-sort-field="settlement_index">{{ $t('instruments.settlement_index') }}</th>
        <th class="text-left"  data-sort-field="funding_base_index">{{ $t('instruments.funding_base_index') }}</th>
        <th class="text-left" data-sort-field="funding_quote_index">{{ $t('instruments.funding_quote_index') }}</th>
        <th class="text-left" data-sort-field="funding_premium_index">{{ $t('instruments.funding_premium_index') }}</th>
        <th class="text-left"  data-sort-field="tick_size">{{ $t('instruments.tick_size') }}</th>
        <th class="text-left"  data-sort-field="max_price">{{ $t('instruments.max_price') }}</th>
        <th class="text-left"  data-sort-field="max_order_qty">{{ $t('instruments.max_order_qty') }}</th>
        <th class="text-left"  data-sort-field="multiplier">{{ $t('instruments.multiplier') }}</th>
        <th class="text-left"  data-sort-field="risk_limit">{{ $t('instruments.risk_limit') }}</th>
        <th class="text-right"  data-sort-field="risk_step"><span class="fl-right">{{ $t('instruments.risk_step') }}</span></th>
        
        
        <template slot="body" slot-scope="props">
          <tr>
            <td class="text-left">
              <button v-if="!props.item.isEdit" class="btn btn-edit-table" @click="cloneColumn(props.item)" ><i class="icon-coppy"></i></button>
              <button v-if="props.item.isEdit" class="btn btn-edit-table" @click="resetColumn(props.item)"><i class="icon-reload3"></i></button>
              <button v-if="!props.item.isEdit" :disabled="props.item.state != 'Pending'" class="btn btn-edit-table" @click="editColumn(props.item)"><i class="icon-edit"></i></button>
              <button v-if="props.item.isEdit" class="btn btn-save-table" @click="saveColumn(props.item)"><i class="icon-save"></i></button>
              <button v-if="props.item.isRemove" :disabled="props.item.state != 'Pending'" class="btn btn-close-table" @click="confirmDeleteColumn(props.item.id)" ><i class="icon-close2"></i></button>
            </td>
            <td>
              <span v-if="!props.item.isEdit" class="text-left">{{props.item.symbol}}</span>
              <input-text-and-number v-else :maxlength="190" class="input_table w_200px" v-model ="newItem.symbol" name=""/>
            </td>
            <td>
              <span v-if="!props.item.isEdit" class="text-left">{{props.item.root_symbol}}</span>
              <select-box v-else v-model="root_symbol" class="select-market" :options="coinActive"/>
            </td>
            <td>
              <span v-if="!props.item.isEdit" class="text-left" id = "instrument-state">{{props.item.state}}</span>
              <select-box v-else v-model="state" class="select-market" :options="states"/>
            </td>
            <td>
              <span v-if="!props.item.isEdit" class="text-left" id = "instrument-type">{{handleValueType(props.item.type)}}</span>
              <select-box v-else v-model="type" class="select-market" :options="types"/>
            </td>
            <td>
              <span v-if="!props.item.isEdit" class="text-left">{{ convertToLocalTime(props.item.expiry) }}</span>
              <span v-else-if ="props.item.isEdit && type.id != 1"></span>
              <datetime v-else format="YYYY-MM-DD H:i:s" :value="timeExiry" v-model="timeExiry"/>
            </td>
            <td>
              <span v-if="!props.item.isEdit" class="text-left">{{props.item.base_underlying}}</span>
              <!-- <input v-else :maxlength="190" class="input_table w_200px" v-model ="newItem.base_underlying" name="">
               -->
               <select-box v-else v-model="base_underlying" class="select-market" :options="coinActive"/>
            </td>
            <td>
              <span v-if="!props.item.isEdit" class="text-left">{{props.item.quote_currency}}</span>
              <!-- <input v-else :maxlength="190" class="input_table w_200px" v-model ="newItem.quote_currency" name=""> -->
              <select-box v-else v-model="quote_currency" class="select-market" :options="coinActive"/>
            </td>
            <td>
              <span v-if="!props.item.isEdit" class="text-left">{{props.item.underlying_symbol}}</span>
              <select-box v-else v-model="underlying_symbol" class="select-market" :options="coinActive"/>
            </td>
            <td>
              <span v-if="!props.item.isEdit" class="text-left">{{percentNumber(props.item.init_margin)}}%</span>
              <currency-input class="input_table w_200px" v-else v-model="newItem.init_margin" :precision="8" />
            </td>
            <td>
              <span v-if="!props.item.isEdit" class="text-left">{{percentNumber(props.item.maint_margin)}}%</span>
              <currency-input class="input_table w_200px" v-else v-model="newItem.maint_margin" :precision="8" />
            </td>
            <td>
              <span v-if="!props.item.isEdit" class="text-left">{{percentNumber(props.item.maker_fee )}}%</span>
              <currency-input v-else :allowNegative="true" :precision="8" class="input_table w_200px" v-model="newItem.maker_fee" name=""/>
            </td>
            <td>
              <span v-if="!props.item.isEdit" class="text-left">{{percentNumber(props.item.taker_fee)}}%</span>
              <currency-input v-else :allowNegative="true" :precision="8" class="input_table w_200px" v-model="newItem.taker_fee" name=""/>
            </td>
            <td>
              <span v-if="!props.item.isEdit" class="text-left">{{percentNumber(props.item.settlement_fee)}}%</span>
              <currency-input v-else :allowNegative="true" class="input_table w_200px" v-model="newItem.settlement_fee" :precision="8" />

            </td>
            <td>
              <span v-if="!props.item.isEdit" class="text-left">{{props.item.reference_index}}</span>
              <select-box v-else v-model="reference_index" class="select-market" :options="reference_indexs"/>
            </td>
            <td>
              <span v-if="!props.item.isEdit" class="text-left">{{props.item.settlement_index}}</span>
              <select-box v-else v-model="settlement_index" class="select-market" :options="settlement_indexs"/>
            </td>
            <td>
              <span v-if="!props.item.isEdit" class="text-left">{{props.item.funding_base_index}}</span>
              <div v-else>
                <span v-if = "typeIsFeature" class="text-left"></span>
                <select-box v-else v-model="funding_base_index" class="select-market" :options="funding_base_indexs"/>
              </div>
            </td>
            <td>
              <span v-if="!props.item.isEdit" class="text-left">{{props.item.funding_quote_index}}</span>
              <div v-else>
                <span v-if = "typeIsFeature" class="text-left"></span>
                <select-box v-else v-model="funding_quote_index" class="select-market" :options="funding_quote_indexs"/>
              </div>
            </td>
            <td>
              <span v-if="!props.item.isEdit" class="text-left">{{props.item.funding_premium_index}}</span>
              <div v-else>
                <span v-if = "typeIsFeature" class="text-left"></span>
                <select-box v-else v-model="funding_premium_index" class="select-market" :options="funding_premium_indexs"/>
              </div>
             </td>
            <td>
              <span v-if="!props.item.isEdit" class="text-left">{{props.item.tick_size |formatCurrencyAmount(null, '0')}}</span>
              <currency-input class="input_table w_200px" v-else v-model="newItem.tick_size" :precision="8" />
            </td>
            <td>
              <span v-if="!props.item.isEdit" class="text-left">{{props.item.max_price |formatCurrencyAmount(null, '0')}}</span>
              <currency-input class="input_table w_200px" v-else v-model="newItem.max_price" :precision="8" />
            </td>
            <td>
              <span v-if="!props.item.isEdit" class="text-left">{{props.item.max_order_qty |formatCurrencyAmount(null, '0')}}</span>
              <currency-input class="input_table w_200px" v-else v-model="newItem.max_order_qty" :precision="0" />
            </td>
            <td>
              <span v-if="!props.item.isEdit" class="text-left">{{props.item.multiplier |formatCurrencyAmount(null, '0')}}</span>
              <currency-input v-else :allowNegative="true" :precision="0" class="input_table w_200px" v-model="newItem.multiplier" name=""/>
            </td>
            <td>
              <span v-if="!props.item.isEdit" class="text-left">{{props.item.risk_limit |formatCurrencyAmount(null, '0')}}</span>
              <currency-input class="input_table w_200px" v-else v-model="newItem.risk_limit" :precision="8" />
            </td>
            <td class="text-right-last" >
              <span v-if="!props.item.isEdit" >{{props.item.risk_step |formatCurrencyAmount(null, '0')}}</span>
              <currency-input class="input_table w_200px" v-else v-model="newItem.risk_step" :precision="8" />
            </td>
        
          </tr>
        </template>
      </data-table-4>
    </div>

  </div>
</template>

<script>
  import rf from '../../lib/RequestFactory';
  import InputOnlyNumber from '../../common/InputOnlyNumber';
  import SelectBox from '../../components/SelectBox';
  import InputTextAndNumber from '../../components/InputTextAndNumber';
  import Modal from "../../components/Modal";
  import DatePicker from "vuejs-datepicker";
  import moment from "moment";
  import COMMON_CONST from "../../common/Const";
  import DataTable4 from "../../components/DataTable4";
  import BigNumber from 'bignumber.js';
  import CoinInput from '../../common/CoinInput.vue';
  import datetime from 'vuejs-datetimepicker';
  import Utils from "../../common/Utils";
  const INSTRUMENT_TYPES = {
    CALL_OPTION: 0,
    FUTURE: 1,
    PERPETUAL: 2,
    PUT_OPTION: 3,
  };

  export default {
    components: {
      InputOnlyNumber,
      InputTextAndNumber,
      Modal,
      DatePicker,
      SelectBox,
      DataTable4,
      CoinInput,
      datetime
    },
    data() {
      return {
        titlePage: this.$t('instruments.menu'),
        searchKey: '', 
        oldSettings: {},
        isCurrentItem: false,
        newItem: {},
        currentItem: {},
        timeExiry: null,
        tableData:[],
        isNeedLoad : false,
        isExistClone: false,
        isClone: false,
        state: {},
        type: {},
        funding_base_index: {},
        base_underlying: {},
        funding_premium_index: {},
        funding_quote_index: {},
        quote_currency: {},
        reference_index: {},
        settlement_index: {},
        root_symbol: {},
        underlying_symbol: {},
        coinActive: [],
        funding_base_indexs: [],
        reference_indexs: [],
        settlement_indexs: [],
        funding_premium_indexs: [],
        funding_quote_indexs: [],
        typeIsFeature : true,
        states: [
          {
            id : 'Open',
            name : this.$t("instruments.open")
          },
          {
            id : 'Pending',
            name : this.$t("instruments.pending")
          },
          {
            id : 'Close',
            name : this.$t("instruments.close")
          }
        ],
        types: [
          {
            id : INSTRUMENT_TYPES.FUTURE,
            name : this.$t("instruments.types.future"),
          },
          {
            id : INSTRUMENT_TYPES.PERPETUAL,
            name : this.$t("instruments.types.perpetual"),
          },
        ],
      }
    },
    watch: {
      root_symbol(newValue) {
        if (this.root_symbol && this.root_symbol.id) {
          this.getCoinIndexActive(this.root_symbol.id, "reference_indexs");
          this.getCoinIndexActive(this.root_symbol.id, "settlement_indexs");
        }
      },
      base_underlying(newValue) {
        if (this.base_underlying && this.base_underlying.id) {
          this.getCoinIndexActive(this.base_underlying.id, "funding_base_indexs");
        }
      },
      quote_currency(newValue) {
        if (this.quote_currency && this.quote_currency.id) {
          this.getCoinIndexActive(this.quote_currency.id, "funding_quote_indexs");
        }
      },
      type(newV) {
        if (newV  && newV.id) {
          if (newV.id == 2) {
            this.typeIsFeature = false;
            this.timeExiry = "";

          } else {
            this.typeIsFeature = true;
          }
        }
        if (this.quote_currency && this.quote_currency.id && this.base_underlying && this.base_underlying.id && this.root_symbol && this.root_symbol) { 
          this.getCoinIndexActive(this.quote_currency.id, "funding_quote_indexs");
          this.getCoinIndexActive(this.base_underlying.id, "funding_base_indexs");
          this.getCoinIndexActive(this.root_symbol.id, "reference_indexs");
          this.getCoinIndexActive(this.root_symbol.id, "settlement_indexs");
          this.getCoinIndexActive("ALL", "funding_premium_indexs");
        }
      }
    },
    methods: {
      dropdownChange(valueReturn) {
        this.dataInstrument[valueReturn.params] = valueReturn.value;
      },
      createInstrument() {
        this.$router.push({ name: 'InstrumentResource' });
      },
      refresh() {
        this.$refs.datatable.refresh();
      },
      symbolRootChange(currency) {
        this.newItem.root_symbol = currency;
      },
      baseUnderlying(currency) {
        this.newItem.base_underlying = currency;
      },
      quoteCurrency(currency) {
        this.newItem.quote_currency = currency;
      },
      referenceIndex(currency) {
        this.newItem.reference_index = currency;
      },
      settlementIndex(currency) {
        this.newItem.settlement_index = currency;
      },
      fundingBaseIndex(currency) {
        this.newItem.funding_base_index = currency;
      },
      fundingQuoteIndex(currency) {
        this.newItem.funding_quote_index = currency;
      },
      fundingPremiumIndex(currency) {
        this.newItem.funding_premium_index = currency;
      },
      handleValueType(value) {
        if (this.types) {
          let type = window._.find(this.types, { id: value });
          if (type) {
            return type.name;
          }
        }
        return '';
      },
      onDatatableFinish() {
        window._.each(this.$refs.datatable.rows, item => {
          this.$set(item, 'editable', false);
          this.$set(item, 'submitting', false);
        });
      },
      getData(params) {
        let defaultParams = {
          "search" : this.searchKey,
        }
        return rf.getRequest('InstrumentRequest').getInstruments(Object.assign(defaultParams, params)).then(res =>{
          let data = res.data;
          data.data.forEach(element => {
            element['isEdit'] = false;
            element['isReset'] = false;
            element['isRemove'] = true;
            element['maker_fee'] =  Utils.formatCurrencyAmount(element['maker_fee'], null, '0');
            element['init_margin'] =  Utils.formatCurrencyAmount(element['init_margin'], null, '0');
            element['maint_margin'] =  Utils.formatCurrencyAmount(element['maint_margin'], null, '0');
            element['max_price'] =  Utils.formatCurrencyAmount(element['max_price'], null, '0');
            element['settlement_fee'] =  Utils.formatCurrencyAmount(element['settlement_fee'], null, '0');
            element['tick_size'] =  Utils.formatCurrencyAmount(element['tick_size'], null, '0');
            element['taker_fee'] =  Utils.formatCurrencyAmount(element['taker_fee'], null, '0');
            element['risk_limit'] =  Utils.formatCurrencyAmount(element['risk_limit'], null, '0');
            element['risk_step'] =  Utils.formatCurrencyAmount(element['risk_step'], null, '0');

            if (element['type'] == 1 ) {
              element['funding_base_index'] = null;
              element['funding_premium_index'] = null;
              element['funding_quote_index'] = null;
            }
          });
          this.isExistClone = false;
          this.tableData = data;
          // return data;
        });
      },
      async loadData(params) {
        if (!this.isClone || this.isNeedLoad)
        {
          await this.getData(params);
          this.isNeedLoad = false;
        }
        this.isClone = false;
        return new Promise((resolve) => {
            let data = this.tableData;
            return resolve({data});
        });
      },
      search() {
        this.getData();
        this.$refs.datatable.refresh();
      },
      confirmDeleteColumn(item) {
        window.ConfirmationModal.show({
          type: 'primary',
          title: this.$t('address.confirm_remove_address'),
          btnCancelLabel: window.i18n.t('common.action.no'),
          btnConfirmLabel: window.i18n.t('common.action.yes'),
          onConfirm: () => {
            this.deleteColumn(item);
          }
        });
      },
      deleteColumn(item) {
        let params = {
          "id" : item,
        }
        rf.getRequest('InstrumentRequest').deleteInstruments(params).then(res => {
          this.isNeedLoad = true;
          this.$refs.datatable.refresh();
          this.isCurrentItem = false;
          this.currentItem = {};
          this.$toastr('success', window.i18n.t('instruments.remove_success'));
        })
        .catch(e => {
          this.$toastr('error', window.i18n.t('instruments.remove_failed'));
          console.log(e);
        })
      },
      percentNumber(value) {
        return new BigNumber(value).mul(100).toString();
      },
      setOldSettings(item) {
        this.oldSettings = {...item};
      },
      editColumn(item) {
        if (item && item.expiry) {
          this.timeExiry = this.convertToLocalTime(item.expiry);
        } 
        if (this.isCurrentItem) {
          this.isCurrentItem = false;
          if (this.currentItem) {
            this.currentItem.isEdit = false;
          } 
        }
        if (item && item.type) {
          if (item.type == 2) {
            this.typeIsFeature = false;
          } else {
            this.typeIsFeature = true;
          }
        } 
        this.fillDataTable(item);
        this.settingCoinActive(item, 'root_symbol');
        this.settingCoinActive(item, 'base_underlying');
        this.settingCoinActive(item, 'quote_currency');
        this.settingCoinActive(item, 'underlying_symbol');
        this.setOldSettings(item);
        let clone_item = {...item}
        this.copyValueOld(clone_item);
        item.isEdit = true;
        this.isCurrentItem = true;
        this.newItem = {...item};
        this.currentItem = item;
      },
      settingCoinActive(item, field) {
        let tmp = {
          id : item[field],
          name : item[field]
        }
        // return tmp;
        this[field] = {...tmp};
      },
      resetColumn(item) {
        if (item.isRemove) {
          item.isEdit = false;
        } else {
          this.isNeedLoad = true;
          this.isExistClone = false;
          this.refresh();
        }
        
        this.isCurrentItem = false;
        this.currentItem = {};
      },
      cloneValueItem(name, value) {
        if (value)
        {
          this[name] = {
            "id" : value,
            "name" : value
          }
        }
      },
      copyValueOld(clone_item) {
        this.cloneValueItem("root_symbol", clone_item.root_symbol);
        this.cloneValueItem("state", clone_item.state);
        this.cloneValueItem("base_underlying", clone_item.base_underlying);
        this.cloneValueItem("quote_currency", clone_item.quote_currency);
        this.cloneValueItem("reference_index", clone_item.reference_index);
        this.cloneValueItem("settlement_index", clone_item.settlement_index);
        this.cloneValueItem("funding_base_index", clone_item.funding_base_index);
        this.cloneValueItem("funding_quote_index", clone_item.funding_quote_index);
        this.cloneValueItem("funding_premium_index", clone_item.funding_premium_index);
        this.cloneValueItem("underlying_symbol", clone_item.underlying_symbol);
      },
      cloneColumn(item) {
        if (!this.isExistClone) {
          this.isExistClone = true;
          if (this.isCurrentItem) {
            this.isCurrentItem = false;
            if (this.currentItem) {
              this.currentItem.isEdit = false;
            } 
          }
          if (item && item.expiry) {
            this.timeExiry = this.convertToLocalTime(item.expiry);
          } 
          let clone_item = {...item}
          if (clone_item && clone_item.type) {
          if (clone_item.type == 2) {
            this.typeIsFeature = false;
          } else {
            this.typeIsFeature = true;
          }
        } 
          // this.root_symbol = 
          // {
          //   "id" : clone_item.root_symbol,
          //   "name" : clone_item.root_symbol
          // };
          this.copyValueOld(clone_item);
          clone_item.isEdit = true;
          clone_item.isRemove = false;
          delete clone_item.id;
          this.newItem = {... clone_item};
          this.currentItem = this.newItem;
          this.fillDataTable(item);
          this.tableData.data.unshift(clone_item);
          this.isClone =true;
          this.$refs.datatable.refresh();
        }
      },
      fillDataTable(item) {
        this.type = window._.find(this.types, { id: item.type });
        this.state = this.states.find(obj => {
          return obj.id == item.state;
        })
      },
      convertToLocalTime(date) {
        // if (date == null) return null;
        // var dateFormat = 'YYYY-MM-DD HH:mm:ss';
        // var testDateUtc = moment.utc(date);
        // var localDate = testDateUtc.local();
        // return localDate.format(dateFormat);
        return date;
      },
      convertToUtcTime(date) {
        var dateFormat = 'YYYY-MM-DD HH:mm:ss';
        // return moment(date).utc().format(dateFormat);
        return moment(date).format(dateFormat);
      },
      saveColumn(item) {
        let params = {...this.newItem};
        params.state = this.state.id || '';
        params.type = this.type.id || '';
        params.expiry = this.timeExiry || '';
        params.base_underlying = this.base_underlying.id || '';
        params.funding_base_index = this.funding_base_index.id || '';
        params.funding_premium_index = this.funding_premium_index.id || '';
        params.funding_quote_index = this.funding_quote_index.id || '';
        params.quote_currency = this.quote_currency.id || '';
        params.reference_index = this.reference_index.id || '';
        params.settlement_index = this.settlement_index.id || '';
        params.root_symbol = this.root_symbol.id || '';
        params.underlying_symbol = this.underlying_symbol.id || '';
        if (params && params.init_margin) {

          if (params.type == 1) {
            if (params.expiry != '' && moment(params.expiry).isBefore(moment())) {
              this.$toastr('error', this.$t("instruments.validate.invalidExpiry"));
              return;
            }
            if (params.expiry != '') params.expiry = this.convertToUtcTime(params.expiry);
          }
          if (params.isRemove) {
            rf.getRequest("InstrumentRequest").updateInstruments(params).then(res => {
              item.isEdit = false;
              item.isRemove = true;
              this.isNeedLoad = true;
              this.isExistClone = false;
              this.$refs.datatable.refresh();
              this.$toastr('success', this.$t("address.updated_success"));
            })
            .catch(ex=> {
              console.log(ex);
              item.isEdit = true;
              this.$toastr('error', ex.response.data.message);
            });
          } else {
            this.tableData.data[0] = this.newItem;
            rf.getRequest("InstrumentRequest").createInstrument(params).then(res => {
              this.tableData.data[0].isEdit = false;
              this.tableData.data[0].isRemove = true;
              this.isNeedLoad = true;
              this.isExistClone = false;
              this.$refs.datatable.refresh();
              this.$toastr('success', this.$t("address.updated_success"));
            })
            .catch(ex=> {
              console.log(ex);
              this.newItem.isRemove = false;
              this.$toastr('error', ex.response.data.message);
            });
          }
        } else {
          this.$toastr('error', this.$t('instrument.init_margin_required'));
        }
      },
      getCoinActive() {
        rf.getRequest("InstrumentRequest").getCoinActive()
          .then(res => {
            res.data.forEach(el => {
              this.coinActive.push({
                id : el,
                name : el
              })
            });
          })
          .catch(e => {
            console.log(e)
          });
      },
      getCoinIndexActive(coin, field) {
        let params = {
          type : coin
        }
        if (this.type && this.type.id && this.type.id == 0) {
          let tmp = {
              id : null,
              name: "None"
          }
          this.reference_indexs = [tmp];
          this.settlement_indexs = [tmp];
          this.funding_base_indexs = [tmp];
          this.funding_quote_indexs = [tmp];
          this.funding_premium_indexs = [tmp]; 
          this.reference_index = tmp;
          this.settlement_index = tmp;
          this.funding_base_index = tmp;
          this.funding_quote_index = tmp;
          this.funding_premium_index = tmp; 
        } else {
          rf.getRequest("InstrumentRequest").getCoinIndexActive(params)
            .then(res => {
              this[field] = [];
              res.data.forEach(el => {
                this[field].push({
                  id : el,
                  name : el
                })
              });
            })
            .catch(e => {
              console.log(e)
            });
        }
      },
    },
    mounted() {
      this.$emit('EVENT_PAGE_CHANGE', this);
      this.getCoinIndexActive("ALL", "funding_premium_indexs");
    },
    created() {
      this.getCoinActive();
    }
  }
</script>

<style lang="scss">
@import "@/assets/sass/variables";
.group-list.user-list {
  .group-item {
    position: relative;
    &.group-item-del {
      .checkmark_box {
        top: 4px;
      }
      label {
        padding-left: 22px;
      }
    }
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 12px;
      width: 12px;
      z-index: 2;
    }
    .checkmark_box {
      position: absolute;
      top: 0;
      left: 0;
      height: 12px;
      width: 12px;
      background-color: $color-white;
      border: 1px solid $color_grey;
    }
    .checkmark_box:after {
      left: 3px;
      top: 0px;
      width: 4px;
      height: 7px;
      border: solid $color-white;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
    .checkmark_box:after {
        content: "";
        position: absolute;
        display: none;
    }
    input:checked ~ .checkmark_box {
      background-color: $color-aquamarine;
      border: 1px solid $color-blue-hover;
    }
    input:checked ~ .checkmark_box:after {
      display: block;
    }
  }
}
.datatable{
  font-family: $font-family-roboto !important;
}
.date-picker-input {
  display: inline-block;
  input {
    padding: 0 5px 0px 23px;
    width: 110px !important;
    border-radius: 3px;
    -webkit-box-shadow: none;
    box-shadow: none;
    height: 30px;
    line-height: 30px;
    border: 1px solid #d2d6de;
    background-image: url('@/assets/images/icon/date-time.svg') !important;
    background-color: $color-white-smoke !important;
    background-size: 12px !important;
    background-position: center left 5px !important;
    background-repeat: no-repeat !important;
    color: $color-grey-dusty;
    &:hover, &:active, &:focus {
      border-color: $color-jungle-green;
      z-index: 9;
    }
  }
}
.no-left-radius {
    input {
      border-radius: 0px 3px 3px 0px;
      margin-left: -1px;
    }
  }
  .no-right-radius {
    input {
      border-radius: 3px 0px 0px 3px;
    }
  }
.modal-content .modal-footer {
  text-align: center;
  margin-top: 30px;
}
.selectbox100{
  width: 100px !important;
}
#instruments {
  font-family: $font-family-roboto;
  .sc_search_select {
    .group_search_select {
      .button_drop_search {
        // width: 55px;
        height: 30px !important;
        padding: 5px 10px 5px 10px !important;
        border-radius: 3px;
        font-size: $font-small;
        font-family: $font-family-roboto;
        i.icon-arrow1 {
          margin-left: 5px;
        }
        span {
          color: $color-grey-dusty !important;
        }
        &:hover, &:active, &:focus {
          border-color: $color-jungle-green;
          i.icon-arrow1 {
            color: $color-jungle-green;
          }
        }
        &.active {
          border-color: $color-jungle-green;
          i.icon-arrow1 {
            color: $color-jungle-green;
          }
          .list_search_select {
            // width: 75px;
            li {
              font-family: $font-family-roboto;
              font-size: $font-small;
              &:hover, &:active, &:focus {
                color: $color-jungle-green;
              }
            }
          }
        }
      }
      .box_list_search_select {
        .list_search_select {
          width: 100%;
          max-height: 150px;
          overflow-y: auto;
          /* width */
          &::-webkit-scrollbar {
            width: 10px;
          }

          /* Track */
          &::-webkit-scrollbar-track {
            background: #f1f1f1;
          }

          /* Handle */
          &::-webkit-scrollbar-thumb {
            background: #888;
          }

          /* Handle on hover */
          &::-webkit-scrollbar-thumb:hover {
            background: #555;
          }
          li {
            font-family: $font-family-roboto;
            font-size: $font-small;
            &:hover, &:active, &:focus {
              color: $color-jungle-green;
            }
          }
        }
      }
    }
  }
  .box_select_user {
    .group_search_select {
      .button_drop_search {
        width: 90px !important;
        border-radius: 3px;
        i.icon-arrow1 {
          margin-left: 0px;
        }
      }
    }
  }
  td:first-child{
    padding: 8px 10px 4px 10px !important;
  }
}
@media only screen and (max-width: 1280px){
   body{
      min-width: 1280px !important;
    }
}
</style>

<style lang="scss" scoped>
@import "@/assets/sass/variables";
.title-text {
  font-family: $font-family-roboto;
  font-size: $font-small;
  color: $color-grey-dusty;
  padding-bottom: 3px;
}
.text-right-last {
  text-align: right;
  // padding-right: 30px !important;
}
.t-right {
  float: right !important;
}
.input_table {
    border-radius: 3px;
    border: solid 1px $color_alto;
    height: 28px;
    padding: 4px 12px;
    font-size: $font_root;
    color: $color-grey-dark;
    line-height: 20px;
    background-color: transparent;
    &:hover,
    &:active,
    &:focus {
      border-color: $color-jungle-green;
    }
  }
table {
  thead {
    th {
      padding: 8px 10px 4px 10px !important;
      // text-align: center;
      color: $color_grey;
      font-family: "Roboto-Regular", sans-serif;
      text-overflow: ellipsis;
      white-space: nowrap;
      &.non-sort {
        &::after {
          margin-left: 0px !important;
          width: 0px !important;
          height: 0px !important;
        }
      }
    }
  }
  tbody {
    tr {
      
      td {
        min-width: 113px;
        // text-align: center;
        vertical-align: middle;
        // padding-top: 5px;
        font-family: $font-family-roboto;
        padding: 8px 10px 4px 10px !important;
        font-family: "Roboto-Regular", sans-serif;
        text-overflow: ellipsis;
        white-space: nowrap;
        .form-control {
          background-color: transparent !important;
          border-radius: 0px;
        }
        .btn-save-table,
      .btn-close-table,
      .btn-edit-table {
        width: 20px;
        height: 20px;
        text-align: center;
        line-height: 20px;
        padding: 0px;
        text-align: center;
        border: 0px;
        background-color: transparent;
        font-size: 16px;
        color: $color-jungle-green;
        margin-left: 7px;
        i {
          &:before {
            color: $color-jungle-green;
          }
        }
      }
      }
      
    }
  }
  
}
.instruments {
  .filter-container {
    margin: 0px 0px 10px 0px;
    height: 60px;
    .filter-item {
      float: left;
      margin: 0 5px;
      input {
        margin-top: 21px;
        display: inline-block;
        width: 215px;
        &:hover, &:active, &:focus {
          border-color: $color-jungle-green;
        }
      }
      select {
        width: 65px;
        display: inline-block;
        font-family: $font-family-roboto;
        font-size: $font_root;
        &:hover, &:active, &:focus {
          border-color: $color-jungle-green;
        }
      }
      button {
        margin: 0 5px;
        width: auto;
        margin-top: 21px;
        height: 30px;
        font-size: $font-smaller;
        font-family: $font-family-roboto;
        &:hover, &:active {
          border-color: $color-aquamarine;
          background-color: $color-aquamarine;
        }
      }
      .btn-search, .btn-reset, .btn-remove {
        margin-left: 0px;
        margin-right: 5px;
        font-family: $font-family-roboto-bold;
        font-size: $font-smaller;
        border-radius: 3px;
        border: 1px solid $color-caribbean-green;
        width: 200px;
        color: $color_white;
        text-transform: uppercase;
        background-color: $color-caribbean-green;
        font-weight: 600;
      }
      .btn-remove {
        border: 1px solid $color-denim;
        color: $color-denim;
        background-color: transparent;
        &:hover, &:active, &:focus {
          color: $color-white;
        }
      }
    }
    .date {
      margin-right: 15px;
    }
    .pair {
      margin-right: 15px;
    }
  }
  .tool-button {
    margin-bottom: 15px;
    height: 30px;
    .btn {
      margin-top: 0px !important;
      min-width: 200px;
      .glyphicon {
        margin-right: 5px;
      }
    }
  }
  .form-control {
    background: $color-white-smoke;
    height: 30px;
    font-size: 13px;
    padding-left: 11px;
    padding-right: 0;
  }
  .datatable {
    button.btnForm.bt-action {
      padding-left: 5px !important;
      padding-right: 5px !important;
      font-weight: 400 !important;
    }
  }
  .buy {
      color: $text-color-jade;
    }
  .sell {
    color: $text-color-red;
  }
  .btn-detail {
    background-color: $color-caribbean-green;
    padding: 2px 10px;
    font-size: $font-smaller;
    color: $color-white;
    border:1px solid $color-caribbean-green;
    border-radius: 3px;
    text-transform: uppercase;
    font-family: $font-family-roboto;
    &:hover{
      background-color: $color-aquamarine;
      border-color: $color-aquamarine;
    };
  }
  .icon-notfound {
    font-size: 40px;
    padding-right: 15px;
  }
  .toolbar-element2 {
    display: inline-block;
    float: left;
  }
  .group-head {
    font-size: 20px;
    margin-bottom: 20px;
    font-family: $font-family-roboto-medium;
  }
  .group-list {
    border: 1px solid $color_alto;
    border-radius: 3px;
    padding: 15px 15px;
    height: 210px;
    overflow-y: scroll;
    .group-item{
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0px;
      }
      input{
        margin: 0px;
        margin-right: 10px;
      }
      label{
        font-family: $font-family-roboto;
        font-weight: normal;
        margin: 0px;
      }
    }
  }
  .user-list {
    height: 170px;
    margin-top: 15px;
  }
}
</style>

<style lang="scss" scoped>
@import "@/assets/sass/variables";
  .fl-right {
    padding-top: 4px;
    padding-left: 4px;
  }
  .box_select_level {
    width: 40px;
  }
  .box_select_user {
    width: 80px;
    .group_search_select {
      width: 80px;
      .button_drop_search {
        width: 80px !important;
      }
    }
  }
  .cl1 {
    min-width: 52px;
  }
  .cl2 {
    min-width: 80px;
  }
  .cl3{
    min-width: 150px;
  }
  .cl4 {
      min-width: 95px;
  }
  .cl5 {
    min-width: 122px;
  }
  .cl6{
    min-width: 120px;
  }
  .cl7 {
    min-width: 130px;
  }
  .cl8 {
    min-width: 80px;
  }
  .cl9 {
    min-width: 90px;
  }
  // .cl3 {
  //   width: 280px;
  // }
  .cl10 {
    min-width: 50px;
  }
  // .cl4::after {
  //   width: 0px;
  //   height: 0px;
  //   display: none;
  // }
  .instruments {
    // width: 100%;
    .edit-input-number {
      input {
        width: 80px;
        padding-left: 5px;
        background: transparent;
        box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0);
        border-radius: 0px;
        border: 1px solid $color_alto;
      }
    }
    .select_user {
      box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0);
      border-radius: 0px;
      width: 80px;
      height: 27px;
      border: 1px solid $color_alto;
      line-height: 20px;
      padding: 3px 5px;
      background-color: transparent;
    }
    .item_email_user {
      position: relative;
      .txt_email_user {
        display: block;
        max-width: 240px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .tooltip_email_user {
        position: absolute;
        top: 0px;
        line-height: 20px;
        padding: 5px 20px;
        left: 45%;
        background-color: $color_white;
        white-space: nowrap;
        width: auto;
        z-index: 10;
        font-size: $font_root;
        font-weight: 500;
        color: $color_mine_shaft;
        transition: 0.5s;
        display: none;
        box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.4);
        &:after {
          right: 100%;
          top: 50%;
          border: solid transparent;
          content: " ";
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
          border-color: rgba(136, 183, 213, 0);
          border-right-color: $color_white;
          border-width: 5px;
          margin-top: -5px;
        }
      }
      &:hover {
        .tooltip_email_user {
          display: block;
          transition: 0.5s;
        }
      }
      .tooltip_group_user {
        left: 20px;
      }
    }
    .btn_save_user {
      background-color: transparent !important;
      font-size: $font_semi_big;
      height: 27px;
      padding: 2px 0px;
      line-height: 23px;
      float: right;
      margin-left: 15px;
      margin-right: 15px;
      .icon-save:before {
      color: $color_dove_gray;
      }
      &:active {
        box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0);
      }
      &:hover {
        color: $color-jungle-green;
        .icon-save:before {
          color: $color-jungle-green;
        }
      }
    }
    .btn_edit_user {
      background-color: transparent !important;
      font-size: $font_semi_big;
      height: 27px;
      padding: 2px 0px;
      line-height: 23px;
      float: right;
      margin-left: 15px;
      margin-right: 15px;
      .icon-save:before {
      color: $color_dove_gray;
      }
      &:active {
        box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0);
      }
      &:hover {
        color: $color-jungle-green;
        .icon-save:before {
          color: $color-jungle-green;
        }
      }
    }
  }
  // @media only screen and (min-width: 1399px) {
  //  .instruments .item_email_user .txt_email_user {
  //     max-width: 250px;
  //  }
  // }
</style>

<style lang="scss">
@import "@/assets/sass/variables";
  #select-box-user-modal {
    .sc_search_select {
      .group_search_select {
        .button_drop_search {
          font-family: $font-family-roboto-medium !important;
          span {
            color: $color_mine_shaft !important;
          }
        }
      }
    }
  }
</style>