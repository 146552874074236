import BaseRequest from './BaseRequest'

export default class TrezorRequest extends BaseRequest {

  createAddress(params) {
    let url = '/admin/api/trezor/create-receive-address';
    return this.post(url, params);
  }

  getGeneralInformation(params) {
    let url = '/admin/api/trezor-general-information';
    return this.get(url, params);
  }

  getDevices(params) {
    let url = '/admin/api/trezor/devices';
    return this.get(url, params);
  }

  getHashDevices(params) {
    let url = '/admin/api/trezor/hash-devices';
    return this.get(url, params);
  }

  registerDevice(params) {
    let url = '/admin/api/trezor/devices';
    return this.post(url, params);
  }

  enableDevice(deviceId) {
    let url = `/admin/api/trezor/${deviceId}/enable`;
    return this.put(url);
  }

  disableDevice(deviceId) {
    let url = `/admin/api/trezor/${deviceId}/disable`;
    return this.put(url);
  }

  getAddressSummary(params) {
    let url = `/admin/api/trezor/devices/address-summary`;
    return this.get(url, params);
  }

  addAddressHasTag(params) {
    let url = `/admin/api/trezor/add-address-has-tag`;
    return this.post(url, params);
  }

  createAddressHasTag(params) {
    let url = `/admin/api/trezor/create-address-has-tag`;
    return this.post(url, params);
  }
}
