import { Validator } from 'vee-validate';
import Vue from 'vue';
import _ from 'lodash';

// load errors from server

Vue.mixin({
  methods: {
    resetErrors () {
      this.errors.clear();
    },
    loadErrorsFromServer (errors) {
      this.resetErrors();
      if (_.isEmpty(errors)) {
        return;
      }

      _.forEach(errors, (error, key) => {
        this.errors.add({
          field: key,
          msg: _.head(error),
        });
      });

    },
    showSuccess (message) {
      this.$toasted.show(
        message,
        {
          position: 'bottom-right',
          duration: 3000,
          className: 'toasted-success',
          iconPack: 'custom-class',
          icon: 'iconmo-checked toasted-icon',
        },
      );
    },
    showError (message) {
      this.$toasted.show(
        message,
        {
          position: 'bottom-right',
          duration: 3000,
          className: 'toasted-fail',
          iconPack: 'custom-class',
          icon: 'iconmo-close toasted-icon',
        },
      );
    },
  },
});

Validator.prototype._formatErrorMessage = function _formatErrorMessage (field, rule, data, targetName) {
  if (data === void 0) {
    data = {};
  }
  if (targetName === void 0) {
    targetName = null;
  }

  const name = this._getFieldDisplayName(field);
  const params = this._getLocalizedParams(rule, targetName);

  return getFieldMessage(this.locale, field.name, rule.name, [name, params, data]);
};

function hasDictionaryMessage (locale, key) {
  return !!(window.app &&
    window.app.$validator &&
    window.app.$validator.dictionary &&
    window.app.$validator.dictionary.container[locale].messages &&
    window.app.$validator.dictionary.container[locale].messages[key]
  );
}

function getFieldMessage (locale, field, rule, data) {
  const path = `validation.custom.${field}.${rule}`;
  if (window.i18n.te(path)) {
    return window.i18n.t(path, locale, data);
  }

  return getMessage(locale, rule, data);
}

function getMessage (locale, rule, data) {
  if (hasDictionaryMessage(locale, rule)) {
    const _message = window.app.$validator.dictionary.container[locale].messages[rule];
    return typeof _message === 'function' ? _message(...data) : _message;
  }

  const path = `validation.${rule}`;

  if (!window.i18n.te(path)) {
    if (hasDictionaryMessage(locale, '_default')) {
      const _message = window.app.$validator.dictionary.container[locale].messages._default;
      return typeof _message === 'function' ? _message(...data) : _message;
    }
    return window.i18n.t(('validation.messages._default'), locale, data);
  }

  const params = {};
  _.forEach(window.i18n.t(path).match(/{([^}]+)}/g), (field, index) => {
    params[field.replace(/[{:}]/g, '')] = data[index];
  });
  return window.i18n.t(path, locale, params);
}
