<template>
  <div class="register-erc20 mg_bot50">
    <div v-if="step === STEP_1" :key="STEP_1">
      <coin-information @next-step="step = STEP_2"/>
    </div>
    <div v-if="step === STEP_2" :key="STEP_2">
      <trading-information @pre-step="step = STEP_1" @next-step="step = STEP_3"/>
    </div>
    <div v-if="step === STEP_3" :key="STEP_3">
      <withdrawal-information @pre-step="step = STEP_2" @next-step="step = STEP_4"/>
    </div>
    <div v-if="step === STEP_4" :key="STEP_4">
      <review-register-erc20 @pre-step="step = STEP_3" @back-step1="step = STEP_1" @complete="complete"/>
    </div>
  </div>
</template>
<script>
  import CoinInformation from './steps/CoinInformation.vue';
  import TradingInformation from './steps/TradingInformation.vue';
  import WithdrawalInformation from './steps/WithdrawalInformation.vue';
  import ReviewRegisterErc20 from './steps/ReviewRegisterErc20.vue';

  const STEP_1 = 'coin_information';
  const STEP_2 = 'trading_information';
  const STEP_3 = 'withdrawal_information';
  const STEP_4 = 'review_information';

  export default {
    components: {
      CoinInformation,
      ReviewRegisterErc20,
      TradingInformation,
      WithdrawalInformation,
    },
    data () {
      return  {
        STEP_1,
        STEP_2,
        STEP_3,
        STEP_4,
        step: STEP_1,
        params: {
          coin_setting: {
            decimals: '',
            required_confirmations: '',
            type: '',
            explorer: '',
          },
          trading_setting: [],
          withdrawal_setting: {},
        },
        titlePage: window.i18n.t('trading_information.heading_title'),
      };
    },
    provide () {
      const data = {};
      this.params.coin_setting.explorer = this.explorder;
      Object.defineProperty(data, 'params', {
        enumerable: true,
        get: () => {
          return this.params || {};
        },
        set: (val) => this.params = { ...val },
      });
      return { data };
    },
    methods: {
      complete() {

      }
    },
    mounted() {
      this.$emit('EVENT_PAGE_CHANGE', this);
    }
  }
</script>
