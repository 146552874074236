<template>
  <div id="base_airdrop_setting" class="boxCore">
    <div class="transaction">
      <div class="clearfix clearfix-10"></div>
      <div class="flex">
        <div class="group_head_airdrop_setting pt-5">
          <span class="title_item_airdrop_setting ">{{ $t('account_setting.enable_wallet_airdrop_pay_amal') }}</span>
          <label class="switch">
            <input type="checkbox" v-model="isActiveDividendWallet" @click="changeStatusUsingWallet">
            <span>
              <i></i>
            </span>
          </label>
        </div>
        <div class="group_head_airdrop_setting">
          <span class="title_item_airdrop_setting">{{ $t('account_setting.enable_airdrop') }}</span>
          <label class="switch">
            <input type="checkbox" v-model="isActive" @click="changeStatus">
            <span>
              <i></i>
            </span>
          </label>
        </div>
      </div>
      <div class="clearfix clearfix-20"></div>
     <!--  <div class="filter_container clearfix">
       <span class="title_item">{{ $t('account_setting.payout_currency') }}</span>
     </div> -->

      <div class="clearfix"></div>
      <div class="content_setting clearfix">
        <div class="left_content_setting">
          <div class="group_input_left clearfix">
            <label>{{ $t('account_setting.default_lock_period')  }}</label>
            <div class= "input_view">
                <currency-input :value="settings.period" @focus="magic_flag1 = true" @blur="magic_flag1 = false" 
                  v-bind:class="{ able: isEdit, 'able-active': !isEdit, 'error': errors.has('st_period')}" 
                  :data-vv-as="$t('account_setting.default_lock_period')" v-model="settings.period" :disabled="isEdit" />
                <span class="unit" v-bind:class="{ 'abled': isEdit, 'abled-active': !isEdit}">{{ $t('withdrawal_information.days') }}</span>
            </div>
            <div class="error-box">
              <span v-show="errors.has('st_period')" class="f-left is-error">{{ errors.first('st_period') }}</span>
            </div>
          </div>
          <div class="clearfix"></div>
          <div class="group_input_left clearfix">
            <label>{{ $t('account_setting.default_unlock_percent_period')  }}</label>
            <div class= "input_view">
                <currency-input :value="settings.unlock_percent" :precision="2" @focus="magic_flag1 = true" @blur="magic_flag1 = false" 
                  v-bind:class="{ able: isEdit, 'able-active': !isEdit,'error': errors.has('st_unlock_percent')}" v-model="settings.unlock_percent" :disabled="isEdit" />
                <span class="unit" v-bind:class="{ 'abled': isEdit, 'abled-active': !isEdit}">%</span>
            </div>
            <div class="error-box">
              <span v-show="errors.has('st_unlock_percent')" class="f-left is-error">{{ errors.first('st_unlock_percent') }}</span>
            </div>
          </div>
          <div class="clearfix"></div>
          <div class="group_input_left clearfix">
            <label>{{ $t('account_setting.airdrop_payout_amount')  }}</label>
            <!-- <div class="input_view">
              <div v-bind:class="{'input_left':true}">
                <currency-input :value="settings.payout_amount" :precision="2" @focus="magic_flag1 = true" @blur="magic_flag1 = false" 
                  v-bind:class="{ able: isEdit, 'able-active': !isEdit, 'error': errors.has('st_payout_amount')}" v-model="settings.payout_amount" :disabled="isEdit" />
              </div>
              <input v-if ="isEdit" class="select_right_input" disabled="disabled" v-model="currency" name=""></input>
              <select-box v-else disabled="disabled" class="select_right select_dropdown_right_input" v-model="currency" :options="markets"/>
            </div>
            <div class="error-box">
              <span v-show="errors.has('st_payout_amount')" class="f-left is-error">{{ errors.first('st_payout_amount') }}</span>
            </div> -->
            <div class="clearfix clearfix-15"></div>
            <div class="form-check group_airdrop_payout clearfix">
              <div class="group_input_ardrop_payout">
                <currency-input class="input_airdrop_payout" :precision="8" type="text" :disabled="isEdit || !coinActive.btc" v-model="coinAmount.btc"/>
                <span class="coin_airdrop_payout">{{$t('currency.btc.shortname')}}</span>
              </div>
              <label class="form-check-label" :disabled="isEdit" v-bind:class="{ active: coinActive.btc }">
                <span class="icon_check_label"></span>
                <input type="radio" class="form-check-input icon_check_payout" name="optradio" :checked="coinActive.btc" :disabled="isEdit" v-on:change="changeStatusRadio('btc')">
                <span class="txt-inactive">{{$t('airdrop_active')}}</span>
              </label>
            </div>
            <div class="clearfix"></div>
            <div class="error-box">
              <span v-show="errors.has('payout_amount_btc')" class="f-left is-error">{{ errors.first('payout_amount_btc') }}</span>
            </div>
            <div class="clearfix clearfix-15"></div>
            <div class="form-check group_airdrop_payout clearfix">
              <div class="group_input_ardrop_payout">
                <currency-input class="input_airdrop_payout" :precision="8" type="text" :disabled="isEdit || !coinActive.eth" v-model="coinAmount.eth"/>
                <span class="coin_airdrop_payout">{{$t('currency.eth.shortname')}}</span>
              </div>
              <label class="form-check-label" :disabled="isEdit" v-bind:class="{ active: coinActive.eth }">
                <span class="icon_check_label"></span>
                <input type="radio" class="form-check-input icon_check_payout" name="optradio" :checked="coinActive.eth" :disabled="isEdit" v-on:change="changeStatusRadio('eth')">
                <span class="txt-inactive">{{$t('airdrop_active')}}</span>
              </label>
            </div>
            <div class="clearfix"></div>
            <div class="error-box">
              <span v-show="errors.has('payout_amount_eth')" class="f-left is-error">{{ errors.first('payout_amount_eth') }}</span>
            </div>
            <div class="clearfix clearfix-15"></div>
            <div class="form-check group_airdrop_payout clearfix">
              <div class="group_input_ardrop_payout">
                <currency-input class="input_airdrop_payout" type="text" :precision="8" :disabled="isEdit || !coinActive.amal" v-model="coinAmount.amal"/>
                <span class="coin_airdrop_payout">{{$t('currency.amal.shortname')}}</span>
              </div>
              <label class="form-check-label" :disabled="isEdit" v-bind:class="{ active: coinActive.amal }">
                <span class="icon_check_label"></span>
                <input type="radio" class="form-check-input icon_check_payout"  name="optradio" :checked="coinActive.amal" :disabled="isEdit" v-on:change="changeStatusRadio('amal')">
                <span class="txt-inactive">{{$t('airdrop_active')}}</span>
              </label>
            </div>
            <div class="clearfix"></div>
            <div class="error-box">
              <span v-show="errors.has('payout_amount_amal')" class="f-left is-error">{{ errors.first('payout_amount_amal') }}</span>
            </div>

          </div>
          <div class="clearfix"></div>
          <div class="group_input_left clearfix">
            <label>{{ $t('account_setting.set_payout_time')  }}</label>
            <div class="input_view" id ="input_time">
              <time-picker v-if ="!isEdit" v-model="time" @change="changeTime" class= "input_time"></time-picker>
              <input v-if="isEdit" disabled="disabled" v-model="settings.payout_time" name="">
              <span v-if="isEdit" class="unit"><i class="icon-calendar"></i></span>
            </div>
          </div>
          <div class="clearfix"></div>
            <div class="error-box">
              <span v-show="errors.has('payout_time')" class="f-left is-error">{{ errors.first('payout_time') }}</span>
            </div>
          <div class="clearfix"></div>
          <div class="group_input_left clearfix">
            <label>{{ $t('account_setting.set_minumum_amount')  }}</label>
            <div class= "input_view">
                <currency-input :value="settings.min_hold_amal" :precision="2" @focus="magic_flag1 = true" @blur="magic_flag1 = false" 
                  v-bind:class="{ able: isEdit, 'able-active': !isEdit,'error': errors.has('st_min_hold_amal')}" v-model="settings.min_hold_amal" :disabled="isEdit" />
                <span class="unit" v-bind:class="{ 'abled': isEdit, 'abled-active': !isEdit}">{{$t('currency.amal.shortname')}}</span>
            </div>
             <div class="error-box">
              <span v-show="errors.has('st_min_hold_amal')" class="f-left is-error">{{ errors.first('st_min_hold_amal') }}</span>
            </div>
          </div>
          <div class="group_input_left clearfix">
            <label>{{ $t('account_setting.total_supply')  }}</label>
            <div class= "input_view">
                <currency-input :value="settings.total_supply" :precision="2" @focus="magic_flag1 = true" @blur="magic_flag1 = false" 
                  v-bind:class="{ able: isEdit, 'able-active': !isEdit,'error': errors.has('st_total_supply')}" v-model="settings.total_supply" :disabled="isEdit" />
                <span class="unit" v-bind:class="{ 'abled': isEdit, 'abled-active': !isEdit}">{{$t('currency.amal.shortname')}}</span>
            </div>
             <div class="error-box">
              <span v-show="errors.has('st_total_supply')" class="f-left is-error">{{ errors.first('st_total_supply') }}</span>
            </div>
             <div class="error-box">
              <span v-show="errors.has('st_total_supply_too_big')" class="f-left is-error">{{ $t('airdrop.error.total_supply_too_big') }}</span>
            </div>
          </div>
          <div class="clearfix"></div>
        </div>
        <div class="right_content_setting">
          <div class="box_table_right_setting">
            <table class="table">
              <thead>
                
                <tr>
                  <th>{{$t('airdrop_setting.airdrop_total_paid')}}</th>
                  <th class="w_90px">{{$t('airdrop_setting.currency')}}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{btc_total_paid | formatCurrencyAmount(null, '0')}}</td>
                  <td>{{$t('currency.btc.shortname')}}</td>
                </tr>
                <tr>
                  <td>{{eth_total_paid | formatCurrencyAmount(null, '0')}}</td>
                  <td>{{$t('currency.eth.shortname')}}</td>
                </tr>
                <tr>
                  <td>{{amal_total_paid | formatCurrencyAmount(null, '0')}}</td>
                  <td>{{$t('currency.amal.shortname')}}</td>
                </tr>
                
              </tbody>
            </table>
          </div>
        </div>
        <div class="clearfix"></div>
        <div class="group_btn_edit">
          <button class="btn btn-edit" v-if="isActive && isEdit" @click="editSetting">{{ $t('sales_point.edit') }}</button>
          <div v-else>
            <button class="btn btn-edit" v-if="isActive && !isEdit" @click="resetSetting">{{ $t('sales_point.return') }}</button>
            <button class="btn btn-edit" v-if="isActive && !isEdit" @click="saveSetting">{{ $t('sales_point.submit') }}</button>
          </div>
        </div>
      </div>

      <div class="clearfix clearfix-50"></div>
      <div class="line_setting"></div>
      <div class="clearfix clearfix-20"></div>
      
      <div class="content_table_aridrop" id ="table_airdrop_content">
        <div class="head_table_aridrop clearfix">
          <span class="title_table_aridrop">{{ $t('account_setting.list_account') }}</span>
          <input type="text" v-model ="search" class="search_table_aridrop" :placeholder="$t('account_setting.airdrop.search')">
        </div>
        <div class="clearfix clearfix-15"></div>
        <div class="datatable">
          <data-table :getData="getData" ref="datatable" :limit="10" :column="5" class="scroll">
            
            <th class="text-left" data-sort-field="updated_at">{{ $t('account_setting.updated_time') }}</th>
            <th class="text-left" data-sort-field="email">{{ $t('user.email') }}</th>
            <th class="text-left" data-sort-field="period">{{ $t('account_setting.lock_period') }} (days)</th>
            <th class="text-left" data-sort-field="unlock_percent">{{ $t('account_setting.unlock_percent_period')}} (%)</th>
            <th class="text-right after-none w_170px"><button @click="addAccount()" class="btn btn-add"><i class="icon-plus"></i>{{ $t('account_setting.add_account') }}</button></th>

            <template slot="body" slot-scope="props">
              <tr>
                <td class="text-left">
                    <span class="txt_email_user">{{ props.item.updated_at }}</span>
                </td>
                <td class="text-left">
                    <span v-if ="!props.item.isWrite" class="txt_email_user">{{ props.item.email }}</span>
                    <input v-else :maxlength="190" class="input_table w_200px" v-model ="newEmail" name="">
                </td>
                <td class="text-left">
                    <span v-if ="!props.item.isWrite" class="txt_email_user">{{ props.item.period }}</span>
                    <input v-else :maxlength="16" class="input_table w_120px" type= "number"  v-model ="newPeriod" name="" @input="checkMaxlength(newPeriod)">
                </td>
                <td class="text-left">
                    <span v-if ="!props.item.isWrite" class="txt_email_user">{{ props.item.unlock_percent | to2Precision }}</span>
                    <input v-else class="input_table w_120px"  type= "number" v-model="newPercent" name="" @input ="checkPrecision(newPercent)">
                </td>
                <td class="text-right">
                  <button class="btn btn-edit-table" v-if="props.item.isReset" @click="resetColumn(props.item)"><i class="icon-reload3"></i></button>
                  <button class="btn btn-edit-table" v-if="!props.item.isWrite" @click="writeColumn(props.item)"><i class="icon-edit"></i></button>
                  <button class="btn btn-save-table" v-if="props.item.isWrite" @click="confirmColumn(props.item)"><i class="icon-save"></i></button>
                  <button class="btn btn-close-table" @click="confirmDeleteColumn(props.item)"><i class="icon-close2"></i></button>
                </td>
              </tr>
            </template>
          </data-table>
        </div>
      </div>

    </div>
    <modal name="addUserAirdrop" width="590">
      <div slot="body" class="body-popup">
        <div class="form-group-detail">
          <div class="group-detail-form-group">
            <label class="group-detail-form-label">{{ $t('airdrop_setting.email') }}</label>
            <input class="group-detail-form-value" maxlength="190" id="input-airdrop-title" type="text" name= "email" 
              data-vv-validate-on=""
              :data-vv-as="$t('airdrop_setting.email1')" v-validate="'required|email'" v-model="newUser.email"
              v-bind:class="{'error-modal': errors.has('email')}" 
            />
            <div class="clearifx"></div>
            <div class="error-box">
              <span v-show="showErrorModal && errors.has('email')" class="f-left is-error">{{ errors.first('email') }}.</span>
            </div>
          </div>
          <div class="group-detail-form-group">
            <label class="group-detail-form-label">{{ $t('airdrop_setting.lock_period') }}</label>
            <currency-input class="group-detail-form-value" :value="newUser.period" v-model="newUser.period" v-bind:class="{ 'error-modal': errors.has('period') }"/>
            <div class="clearifx"></div>
            <div class="error-box">
              <span v-show="errors.has('period')" class="f-left is-error">{{ errors.first('period') }}</span>
            </div>
          </div>
          <div class="group-detail-form-group">
            <label class="group-detail-form-label">{{ $t('airdrop_setting.unlock_percent_period') }}</label>
              <currency-input :precision='2' class="group-detail-form-value" :value="newUser.unlock_percent" v-model="newUser.unlock_percent" v-bind:class="{ 'error-modal': errors.has('unlock_percent') }"/>
            <div class="clearifx"></div>
            <div class="error-box">
              <span v-show="errors.has('unlock_percent')" class="f-left is-error">{{ errors.first('unlock_percent') }}</span>
            </div>
          </div>
          
        </div>
      </div>
      
    </modal>
  </div>
</template>

<script>
  import rf from '../../lib/RequestFactory';
  import BigNumber from 'bignumber.js';
  import DatePicker from "vuejs-datepicker";
  import Modal from "../../components/Modal";
  import moment from "moment";
  import COMMON_CONST from "../../common/Const";
  import SelectBox from '../../components/SelectBox';
  import TimePicker from 'vue2-timepicker';
  import CurrencyInput from '../../components/CurrencyInput';
  import momentTz from "moment-timezone";

  export default {
    components: {
      DatePicker,
      SelectBox,
      Modal,
      TimePicker,
      CurrencyInput,
    },
    props: {
    },
    data() {
      return {
        titlePage: window.i18n.t("airdrop_setting.airdrop_setting"),
        currency: '',
        time: {
          HH: '0',
          mm: '0',
        },
        ACTIVE: 'Active',
        INACTIVE: 'Inactive',
        amount: {name: 'AML'},
        markets: Object.values(COMMON_CONST.AIRDROP_LIST_COINS),
        MARKETS_AMOUNT: ['AML', 'ETH', 'BTC'],
        isActive: false,
        isActiveDividendWallet : false,
        settings: {},
        oldSetting: {},
        search : '',
        newEmail:'',
        newPeriod: '',
        newPercent: '',
        itemUserOld: {},
        currentUser: false,
        itemUser: null,
        isEdit: true,
        newUser: {},
        hasError: false,
        showErrorModal: false,
        coinAmount: {
          'btc' : 0,
          'eth' : 0,
          'amal': 0,
        },
        oldCoinAmount : {},
        btc_total_paid: 0,
        amal_total_paid: 0,
        eth_total_paid: 0,
        coinActive: {
          'btc': false,
          'eth': false,
          'amal': false,
        },
        checkTime: true,
        oldActive:{},
        maxLengthInput: COMMON_CONST.MAX_LENGTH_INPUT,
        timezone: 0,
      }
    },
    watch: {
      search() {
        this.$refs.datatable.refresh();
      },
      "newUser.email" : function(newValue, oldValue) {
        this.errors.clear();
      },
      "newUser.period" : function(newValue, oldValue) {
        this.errors.clear();
      },
      "newUser.unlock_percent" : function(newValue, oldValue) {
        this.errors.clear();
      }
    }, 
    methods: {
      checkMaxlength(value) {
        let vString = value.toString();
        if (vString.length > 16 ) this.newPeriod = vString.substring(0, vString.length - 1);
      },
      checkPrecision(value) {
        let vString = value.toString();
        if (vString.length > 16 ) this.newPercent = vString.substring(0, vString.length - 1);
        if (vString.indexOf('.') < 0) return; 
        let arr = vString.split('.');
        if (arr.length > 2) this.newPercent = vString.substring(0, vString.length - 1);
        if (arr[1].length > 2) this.newPercent = vString.substring(0, vString.length - 1);
      },
      precision(a) {
        let b = a;
        if (!isFinite(b)) return 0;
        var e = 1, p = 0;
        while (Math.round( b * e) / e !== b) { e *= 10; p++; }
        return p;
      },
      getTimeZone() {
        let now = new Date();
        this.timeZone = now.getHours() - now.getUTCHours();
      },
      changeTime(eventData) {
        if(eventData.data.HH == "" && eventData.data.mm == "") {
          this.checkTime = false;
          return ;
        }
        this.checkTime = true;
        this.settings.payout_time = this.time.HH + ":" + this.time.mm;
      },
      getData(params) {
        let defaultParams = {
          "email" : this.search,
        }
        return rf.getRequest('AdminRequest').getListUserAirdropSetting(Object.assign(defaultParams, params)).then(res =>{
          let data = res.data;
          data.data.forEach(element => {
            element['isWrite'] = false;
            element['isReset'] = false;
          });
         
          return data;
        });
        
      },   
      writeColumn(item) {
        if (this.currentUser) {
          this.currentUser = false;
          if (this.itemUser) {
            this.itemUser.isWrite = false;
            this.itemUser.isReset = false;
          } 
        }
        
        this.newEmail = item.email;
        this.newPeriod = item.period;
        this.newPercent = item.unlock_percent;
        item.isWrite = true;
        this.currentUser = true;
        this.itemUser = item;
        this.itemUserOld = {...this.itemUser};
        item.isReset = true;
      },
      resetColumn(item) {
        item.isReset = false;
        item.isWrite = false;
        this.currentUser = false;
        this.itemUser = null;
        item.email = this.itemUserOld.email;
        item.period = this.itemUserOld.period;
        item.unlock_percent = this.itemUserOld.unlock_percent;
        
      },
      confirmColumn(item) {
        if(!this.validate()) {
          this.$refs.datatable.refresh();
          this.itemUser.isWrite = false; 
          this.itemUser.isReset = false;
          return;
        }
        
        if (item.email != this.newEmail) {
          item.email = this.newEmail;
        }
        if (item.period != this.newPeriod) {
          item.period = this.newPeriod;
        }
        if (item.unlock_percent != this.newPercent) {
          item.unlock_percent = this.newPercent;
        }
        rf.getRequest('AdminRequest').updateUserAirdropSetting(item).then(res =>{
          item.isWrite = false;
          item.isReset = false;
          this.$refs.datatable.refresh();
          this.$toastr('success', this.$t("address.updated_success"));

        })
        .catch(ex=> {
          console.log(ex);
          item.isWrite = true;
          item.isReset = true;
          this.$toastr('error', ex.response.data.message);
        });
      },
      validate() {
        this.errors.clear();

        if (!this.newEmail || this.newEmail == '') {
          this.$toastr('error', this.$t('common.error.required', { field: 'email' }));
          this.errors.add({field: 'default', msg: 'error'});
        }

        if (!this.newPeriod) {
          this.$toastr('error', this.$t('common.error.required', { field: 'lock period' }));
          this.errors.add({field: 'default', msg: 'error'});
        }

        if (this.errors.count() > 0) {
          return false;
        }

        return true;   
      },
      confirmDeleteColumn(item) {
        window.ConfirmationModal.show({
          type: 'primary',
          title: this.$t('airdrop_setting.confirm_remove'),
          btnCancelLabel: window.i18n.t('common.action.no'),
          btnConfirmLabel: window.i18n.t('common.action.yes'),
          onConfirm: () => {
            this.deleteColumn(item);
          }
        });
      },

      deleteColumn(item) {
        
        if ( (this.itemUser) &&item.user_id == this.itemUser.user_id) {
          this.itemUser = null;
          this.currentUser = false;
        } 
        rf.getRequest('AdminRequest').deleteUserAirdropSetting(item).then(res =>{
            this.$refs.datatable.refresh();
            this.$toastr('success', window.i18n.t('airdrop_setting.remove_success'));
          })
          .catch(ex=> {
            console.log(ex);
          });
      },
      changeStatus() {
        this.isEdit = true;
        let params = {
          "status" : !this.isActive,
        }
        rf.getRequest('AdminRequest').changeStatusAirdropSetting(params).then(res =>{
          this.isActive = res.data.enable;
          this.getSettings();
          this.getAllSetting();

        })
      },
      changeStatusUsingWallet() {
        let params = {
          "status" : !this.isActiveDividendWallet,
        }
        rf.getRequest('AdminRequest').changeStatusEnableFeeWallet(params).then(res =>{
          this.isActiveDividendWallet = res.data.enable_wallet_pay_fee;
          this.getSettings();
          this.getAllSetting();

        })
      },
      editSetting() {
        this.isEdit = false;
      },
      changeStatusRadio(value) {
        this.coinActive.btc = false;
        this.coinActive.eth = false;
        this.coinActive.amal = false;
        this.coinActive[value] = true;
        this.currency = value;
      },
      async saveSetting() {
        let isValid = await this.validateSetting();
        if (!isValid) {
          return ;
        }
        let hour = this.time.HH - this.timeZone;
        let minute = this.time.mm;
        if(hour > 24) hour = hour - 24;
        else if(hour < 0) hour = 24 + hour;
        if( hour < 10) hour = '0' + hour;
        let params = {
          currency : this.currency,
          period : this.settings.period,
          unlock_percent : this.settings.unlock_percent,
          payout_time : hour + ":" + minute,
          min_hold_amal : this.settings.min_hold_amal,
          payout_amount_btc: this.coinAmount.btc,
          payout_amount_eth: this.coinAmount.eth,
          payout_amount_amal: this.coinAmount.amal,
          total_supply: this.settings.total_supply,
        }
        rf.getRequest('AdminRequest').updateAirdropSetting(params).then(res =>{
          this.getSettings();
          this.getAllSetting();
          this.$toastr('success', this.$t("address.updated_success"));
           this.isEdit = true;
        }).catch(e => {
          this.$toastr('error', e.response.data.message);
          this.settings = {
            ...this.oldSetting
          };
          this.coinActive = {
            ...this.oldActive
          };
          this.coinAmount = {
            ...this.oldCoinAmount
          }
          this.isEdit = false;
        });
        

      },
      validateSetting() {
        this.errors.clear();
        if (!this.settings.period) {
          this.errors.add({field: 'st_period', msg: this.$t('airdrop.error.field_required',{field_name: this.$t('account_setting.default_lock_period1')})});
        }

        if (!this.settings.unlock_percent) {
          this.errors.add({field: 'st_unlock_percent', msg: this.$t('airdrop.error.field_required',{field_name: this.$t('account_setting.default_unlock_percent_period1')})});
        }

        if (!this.checkTime) {
          this.errors.add({field: 'payout_time', msg: this.$t('airdrop.error.field_required',{field_name: this.$t('account_setting.set_payout_time1')})});
        }

        if (this.settings.unlock_percent < 0) {
          this.errors.add({field: 'st_unlock_percent', msg: this.$t('airdrop.error.field_min',{field_name: this.$t('account_setting.default_unlock_percent_period1')})});
        }

        if (!this.coinAmount.btc && this.coinActive.btc) {
          this.errors.add({field: 'payout_amount_btc', msg: this.$t('airdrop.error.field_required',{field_name: this.$t('account_setting.airdrop_payout_amount1')})});
        }

        if (this.coinAmount.btc == 0 && this.coinActive.btc) {
          this.errors.add({field: 'payout_amount_btc', msg: this.$t('airdrop.error.field_min',{field_name: this.$t('account_setting.airdrop_payout_amount1')})});
        }

        if (!this.coinAmount.eth && this.coinActive.eth) {
          this.errors.add({field: 'payout_amount_eth', msg: this.$t('airdrop.error.field_required',{field_name: this.$t('account_setting.airdrop_payout_amount1')})});
        }

        if (this.coinAmount.eth == 0 && this.coinActive.eth) {
          this.errors.add({field: 'payout_amount_eth', msg: this.$t('airdrop.error.field_min',{field_name: this.$t('account_setting.airdrop_payout_amount1')})});
        }

        if (!this.coinAmount.amal && this.coinActive.amal) {
          this.errors.add({field: 'payout_amount_amal', msg: this.$t('airdrop.error.field_required',{field_name: this.$t('account_setting.airdrop_payout_amount1')})});
        }

        if (this.coinAmount.amal == 0 && this.coinActive.amal) {
          this.errors.add({field: 'payout_amount_amal', msg: this.$t('airdrop.error.field_min',{field_name: this.$t('account_setting.airdrop_payout_amount1')})});
        }

        if (!this.settings.min_hold_amal) {
          this.errors.add({field: 'st_min_hold_amal', msg: this.$t('airdrop.error.field_required',{field_name: this.$t('account_setting.set_minumum_amount1')})});
        }
        if (this.settings.min_hold_amal == 0) {
          this.errors.add({field: 'st_min_hold_amal', msg: this.$t('airdrop.error.field_min',{field_name: this.$t('account_setting.set_minumum_amount1')})});
        }
        if (!this.settings.total_supply) {
          this.errors.add({field: 'st_total_supply', msg: this.$t('airdrop.error.field_required',{field_name: this.$t('account_setting.total_supply1')})});
        }
        if (this.settings.total_supply > 210000000) {
          this.errors.add({field: 'st_total_supply_too_big', msg: this.$t('airdrop.error.field_required',{field_name: this.$t('account_setting.total_supply1')})});
        }
        if (this.settings.unlock_percent > 100) {
          this.errors.add({field: 'st_unlock_percent', msg: this.$t('airdrop.error.field_max',{field_name: this.$t('account_setting.default_unlock_percent_period1')})});
        }
        if (this.errors.count() > 0) {
          return false;
        }

        return true;   
      },

      resetSetting() {
        this.settings = {
          ...this.oldSetting
        };
        this.coinActive = {
          ...this.oldActive
        };

        this.coinAmount = {
          ...this.oldCoinAmount
        };
        if (this.settings.payout_time) {
          let arrTime = this.settings.payout_time.split(":");
          this.time.HH = arrTime[0];
          this.time.mm = arrTime[1];

        }
        
        this.errors.clear();
        this.isEdit = true;
        
      },
      addAccount() {
        this.errors.clear();
        this.newUser = {};
        CommonModal.show("addUserAirdrop", {
          position: "add-group",
          mask: true,
          buttons: [
            {
              label: this.$t('common.action.cancel'),
              focused: true,
              style: {
                width: "140px",
                background: "rgb(0, 112, 192)"
              },
              callback: () => {
                CommonModal.hide("addUserAirdrop");
              }
            },
            {
              label: this.$t('common.action.save'),
              focused: true,
              style: {
                width: "140px",
                background: "rgb(0, 112, 192)"
              },
              callback: () => {
                let item = this.newUser;
                this.createNewUser(item);
              }
            }
          ] 
        });
      },
      
      async createNewUser(item) {
        let isValid = await this.validateModal();
        this.showErrorModal = false;
        if (!isValid) {
          this.showErrorModal = true;
          return ;
        }
        let params = {
          email : item.email,
          period : item.period,
          unlock_percent : item.unlock_percent,
        }
        rf.getRequest('AdminRequest').createAirdropUserSetting(params).then(res => {

          this.$toastr('success', window.i18n.t('common.alert.create.success'));
          CommonModal.hide("addUserAirdrop");
          this.newUser = {};
          this.$refs.datatable.refresh();
          
        })
        .catch(e => {
          if (e.response.data.message) {
            this.$toastr('error', e.response.data.message);
          }
        })
      },
      async validateModal() {
        this.errors.clear();
        await this.$validator.validate('email');
        
        if (!this.newUser.period) {
          this.errors.add({field: 'period', msg: this.$t('airdrop.error.field_required', {field_name: this.$t('account_setting.default_lock_period2')})});
        }
        if (!this.newUser.unlock_percent) {
          this.errors.add({field: 'unlock_percent', msg: this.$t('airdrop.error.field_required', {field_name: this.$t('account_setting.default_unlock_percent_period2')})});
        }
        if (this.newUser.unlock_percent > 100) {
          this.errors.add({field: 'unlock_percent', msg: this.$t('airdrop.error.field_max',{field_name: this.$t('account_setting.default_unlock_percent_period2')})});
        }
        if (this.errors.count() > 0) {
          return false;
        }
        return true;
      },

      getSettings() {
        rf.getRequest('AdminRequest').getAirdropSettingToRender(this.timeZone).then(res => {
          if (res.data) {
            let data = res.data;
            data.period = data.period.toString();
            this.settings = data;
            this.isActive = this.settings.enable;
            this.isActiveDividendWallet = this.settings.enable_wallet_pay_fee;
            this.currency = this.settings.currency;
            this.changeStatusRadio(this.currency);
            this.oldActive = { ...this.coinActive};
            let time = this.settings.payout_time.split(':');
            if (time) {
              this.time = {
                HH : time[0],
                mm : time[1],
              }
            }
            
            this.oldSetting = {
              ...res.data
            };
          }
        });
      },

      getAllSetting() {
        rf.getRequest('AdminRequest').getAllAirdropSetting().then(res => {
          if (res.data) {
            res.data.map(item => {
              this.getSettingTable(item);
            });
            this.oldCoinAmount ={ ...this.coinAmount};
          }
        });
      },
      
    
      getSettingTable(item) {
        if(item.currency == 'btc') {
          this.coinAmount.btc = item.payout_amount;
          this.btc_total_paid = item.total_paid;
        }
        if(item.currency == 'eth') {
          this.coinAmount.eth = item.payout_amount;
          this.eth_total_paid = item.total_paid;
        }
        if(item.currency == 'amal') {
          this.coinAmount.amal = item.payout_amount;
          this.amal_total_paid = item.total_paid;
        }
        
      },
    },
    mounted() {
      this.getTimeZone();
      this.$emit('EVENT_PAGE_CHANGE', this);
      this.errors.clear();
      this.getSettings();
      this.getAllSetting();

    },
  }
</script>

<style lang="scss" scoped>
@import "@/assets/sass/variables";
  .error-modal {
    border: solid 1px $color-red-main;
    border-radius: 3px;
    
  }
  input[type='number'] {
    -moz-appearance:textfield;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  .error {
   border: solid 1px $color-red-main !important;
   border-radius: 3px !important;
  }

  .error-box {
    color : $color-red-main;
  }

  .clearfix-10 {
    display: block;
    height: 10px;
  }
  .clearfix-15 {
    display: block;
    height: 15px;
  }
  .pt-5{
    margin-bottom: 15px;
  }
  .clearfix-20 {
    display: block;
    height: 20px;
  }
  .clearfix-50 {
    display: block;
    height: 50px;
  }
  .after-none {
    &:after {
      display: none;
    }
  }

  
  .w_120px {
    width: 120px;
  }
  .w_170px {
    width: 170px;
  }
  .w_200px {
    width: 200px;
  }
  .input_table {
    border-radius: 3px;
    border: solid 1px $color_alto;
    height: 28px;
    padding: 4px 12px;
    font-size: $font_root;
    color: $color-grey-dark;
    line-height: 20px;
    background-color: transparent;
    &:hover,
    &:active,
    &:focus {
      border-color: $color-jungle-green;
    }
  }

  #base_airdrop_setting {
    .group_head_airdrop_setting {
      display: block;
      .title_item_airdrop_setting {
        display: inline-block;
        float: left;
        font-size: $font_big;
        color: $color-grey-dark;
        font-family: $font-family-roboto-medium;
        line-height: 20px;
        margin-right: 12px;
      }
    }
    .form-group-detail {
      .group-detail-form-group {
        .group-detail-form-label{
          width: 100%;
          color: $color-grey-dusty;
          float: left;
          font-weight: normal;
          font-family: $font-family-roboto;
          margin-bottom: 0px;
          margin-top: 10px;
        }

        .group-detail-form-value {
          width: 100%;
          padding: 8px 16px;
          border: 1px solid #dee3eb;
          height: 35px;
          line-height: 35px;
          border-radius: 3px;
          font-family: $font-family-roboto;
          // font-weight: 600;
          &:focus {
            border-color: $color-jungle-green;
          }
        }
        #input-airdrop-title {
          &.error-modal {
            border: solid 1px $color-red-main;
            border-radius: 3px;
          }
        }
        .textarea_input {
          height: 120px;
        }
      }
      .error-box {
        display: block;
        float: left;
        overflow: hidden;
        width: 100%;
        span {
          font-family: $font-family-roboto-medium !important;
        }
      }
      .is-error {
        color: #ff5252 !important;
      }
    }
    .switch {
      margin-bottom: 0px;
      display: inline-block;
      vertical-align: middle;
      cursor: pointer;
      input {
        display:none;
        &:checked +span {
          background: $color-jungle-green;
          text-align: left;
          &:before {
            content: "ON";
            color: $color_white;
            font-size: 12px;
          }
          i {
            left: 35px;
          }
          &:after {
            content: " ";
            color: $color_white;
          }
        }

      }
      span {
        display: inline-block;
        padding: 0px 6px 0 9px;
        width: 54px;
        height: 20px;
        line-height: 20px;
        border-radius: 11px;
        background: $color_grey_icon_light;
        position: relative;
        color: $color_white;
        font-size: 14px;
        text-align: right;
        &:after {
          content: "OFF";
          color: $color_white;
          line-height: 20px;
          font-size: 12px;
        }
        i {
          display: inline-block;
          width: 20px;
          height: 20px;
          border-radius: 100%;
          background: $color_white;
          position: absolute;
          left: 0;
          top: 0px;
          box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.5);
          -webkit-transition: all 0.3s ease-in-out;
          -moz-transition: all 0.3s ease-in-out;
          -ms-transition: all 0.3s ease-in-out;
          -o-transition: all 0.3s ease-in-out;
          transition: all 0.3s ease-in-out;
        }
      }
    }
    .filter_container {
      margin: 10px 0px 25px 0px;
      .title_item {
        color: $color_mine_shaft;
        font-size: $font_big;
        font-family: $font-family-roboto-medium;
        line-height: 28px;
        float: left;
      }
    }
    .line_setting {
      background-color: $color_alto;
      display: block;
      width: 100%;
      height: 1px;
    }
    .content_setting {
      .left_content_setting {
        width: 390px;
        display: block;
        float: left;
        padding-right: 30px;
        .group_airdrop_payout {
          display: block;
          width: 100%;
          .group_input_ardrop_payout {
            display: block;
            float: left;
            width: calc(100% - 110px);
            position: relative;
            .input_airdrop_payout {
              width: 100%;
              height: 35px;
              line-height: 20px;
              padding: 8px 60px 7px 15px;
              font-size: $font_root;
              font-family: $font-family-roboto-medium;
              color: $color-grey-dark;
              border-radius: 3px;
              border: 1px solid $color_alto;
              &:hover,
              &:active,
              &:focus {
                border-color: $color-jungle-green;
              }
              &[disabled] {
                background-color: $color-bright-gray;
                border-color: $color_alto;
                cursor: not-allowed;
                &:hover,
                &:active,
                &:focus {
                  border-color: $color_alto;
                }
              }
            }
            .coin_airdrop_payout {
              position: absolute;
              top: 2px;
              right: 12px;
              padding: 6px 0px 5px 5px;
              line-height: 20px;
              font-size: $font_root;
              font-family: $font-family-roboto-medium;
              color: $color-grey-dark;
            }
          }
          .form-check-label {
            width: 110px;
            display: block;
            float: right;
            min-height: 35px;
            margin: 0px;
            font-weight: 500;
            color: $color-grey-dark;
            font-size: $font_root;
            font-family: $font-family-roboto-medium;
            padding: 7px 0px 7px 15px;
            line-height: 20px;
            cursor: pointer;
            .icon_check_label {
              display: block;
              width: 12px;
              height: 12px;
              border-radius: 50%;
              position: relative;
              float: left;
              border: solid 1px #979797;
              margin: 4px 5px 4px 0px;
            }
            &.active {
              .icon_check_label {
                border: 4px solid #2dac91;
              }
              .txt-inactive{
                color: #2dac91;
              }
            }
            &[disabled] {
              cursor: not-allowed !important;
            }
            .icon_check_payout {
              margin: 4px 5px 4px 0px;
              float: left;
              position: relative;
              display: none;
            }
          }
        }
        .group_input_left {
          display: block;
          width: 100%;
          margin-bottom: 15px;
          label {
            font-family: $font-family-roboto;
            font-size: $font_root;
            color: $color-grey-dusty;
            margin-bottom: 4px;
            line-height: 20px;
            font-weight: 500;
          }
          .input_view {
            width: 100%;
            display: flex;
            position: relative;
            .input_left {
              width: 75%;
              margin-right: 15px;
              float: left;
              }
            input {
              width: 100%;
              height: 35px;
              line-height: 20px;
              padding: 8px 40px 7px 15px;
              font-size: $font_root;
              font-family: $font-family-roboto-medium;
              color: $color-grey-dark;
              border-radius: 3px;
              border: 1px solid $color_alto;
              &[disabled] {
                background-color: $color-bright-gray;
              }
            }
            .select_right_input {
              width: 85px;
              float: right;
              padding: 0;
              margin-left: auto;
              padding-left:24px;
            }
            .select_dropdown_right_input {
              width: 85px;
              float: right;
              padding: 0;
              margin-left: auto;
              background-color: $color_white;
            }
            .unit {
              position: absolute;
              line-height: 20px;
              font-size: $font_root;
              font-family: $font-family-roboto-medium;
              color: $color-grey-dark;
              right: 10px;
              // top: 8px;
            }
          }
        }
      }
      .right_content_setting {
        width: calc(100% - 390px);
        display: block;
        float: left;
        .w_90px {
          width: 90px;
        }
        .box_table_right_setting {
          display: block;
          width: 500px;
          max-width: 100%;
          background-color: $color-white;
          margin-top: 23px;
          table {
            width: 100%;
            th {
              height: 38px;
              padding: 9px 18px 9px 20px;
              line-height: 20px;
              border-bottom: 1px solid $color-white-smoke;
              font-size: $font-small;
              color: $color_grey;
              font-family: $font-family-roboto-medium;
              font-weight: 500;
            }
            td {
              height: 40px;
              line-height: 20px;
              padding: 10px 18px 10px 20px;
              font-size: $font_root;
              color: $color-grey-dark;
              font-family: $font-family-roboto;
            }
          }
        }
      }
      .btn-edit {
        width: 90px;
        height: 35px;
        margin-right: 15px;
        background-color: $color-caribbean-green;
        border-color: $color-caribbean-green;
        border-radius: 3px;
        color: $color-white;
        text-transform: uppercase;
        letter-spacing: 0.92px;
        font-size: 12px;
        font-family: $font-family-roboto-medium;
        padding: 9px;
        margin-top: 10px;
        &:hover {
          background-color: $color-aquamarine;
          border-color: $color-aquamarine;
        }
      }
    } 
    .content_table_aridrop {
      .title_table_aridrop {
        color: $color_mine_shaft;
        font-size: $font_big;
        font-family: $font-family-roboto-medium;
        line-height: 28px;
        float: left;
      }
      .search_table_aridrop {
        width: 215px;
        height: 30px;
        border-radius: 3px;
        border: solid 1px $color_alto;
        background-color: transparent;
        font-size: 13px;
        color: $color-grey-dusty;
        font-family: $font-family-roboto;
        display: block;
        float: right;
        padding: 5px 15px;
        &:hover,
        &:active,
        &:focus {
          border-color: $color-jungle-green;
        }
      }
      .btn-add {
        height: 22px;
        border-radius: 3px;
        background-color: $color-caribbean-green;
        border: 1px solid $color-caribbean-green;
        font-size: $font_mini_mini;
        letter-spacing: 0.77px;
        color: $color-white;
        text-align: center;
        padding: 1px 12px;
        padding-top: 2px;
        display: block;
        float: right;
        i {
          font-size: 8px;
          position: relative;
          top: 0px;
          margin-right: 4px;
        }
        &:hover {
          border-color: $color-aquamarine;
          background-color: $color-aquamarine;
        }
      }
      .btn-save-table,
      .btn-close-table,
      .btn-edit-table {
        width: 20px;
        height: 20px;
        text-align: center;
        line-height: 20px;
        padding: 0px;
        text-align: center;
        border: 0px;
        background-color: transparent;
        font-size: 16px;
        color: $color-jungle-green;
        margin-left: 7px;
        i {
          &:before {
            color: $color-jungle-green;
          }
        }
      }
    }
  }
</style>

<style lang="scss">  
@import "@/assets/sass/variables";
  
  #input_time {
    .input_time {
      width: 100%;
    }
    .display-time {
      width: 100%;
      height: 35px;
      line-height: 20px;
      padding: 8px 40px 7px 15px;
      font-size: 14px;
      font-family: "Roboto-Medium", sans-serif;
      color: #333333;
      border-radius: 3px;
      border: 1px solid #CFCFCF;
    }
  }
  #base_airdrop_setting {
    .sc_search_select {
      float: left;
      width: 85px;
      &[disabled] {
        background-color: $color-bright-gray;
      }
      .group_search_select {
        .button_drop_search {
          height: 35px !important;
          padding: 8px 10px !important;
          span {
            font-size: $font-small;
            font-family: $font-family-roboto;
          }
          i {
            color: $color-grey-dusty;
          }
        }
        .list_search_select {
          li {
            font-size: $font-small;
            font-family: $font-family-roboto;
            &:hover, &:active, &:focus {
              color: $color-jungle-green;
            }
          }
        }
      }
    }
    .list_button_modal {
      padding-top: 20px;
    }
    #table_airdrop_content {
      .sc_search_select{
        width: 55px;
      }
    }
  }
</style>