import BaseRequest from '../lib/BaseRequest'

export default class CoinSettingRequest extends BaseRequest {
    getTransactionUnits(params)
    {
        let url = '/admin/api/transaction-units';
        return this.get(url, params);
    }
    getCoinList(params)
    {
        let url = '/admin/api/coins-confirmations';
        return this.get(url, params);
    }

    updateSetting(id, params)
    {
        let url = `/admin/api/coins-confirmations/update/${id}`;
        return this.put(url, params);
    }

    updateSettingAll(params)
    {
        let url = `/admin/api/coins-confirmations/update-all`;
        return this.put(url, params);
    }

    getTradingFee(params)
    {
        let url = '/admin/api/fee-levels';
        return this.get(url, params);
    }

    updateTradingFee(id, params)
    {
        let url = `/admin/api/fee-levels/${id}`;
        return this.put(url, params);
    }

    getWithdrawLimit(params)
    {
        let url = '/admin/api/withdrawal-limit-levels';
        return this.get(url, params);
    }

    updateWithdrawLimit(id, params)
    {
        let url = `/admin/api/withdrawal-limit-levels/update/${id}`;
        return this.put(url, params);
    }

    getTotalBalances()
    {
        let url = '/admin/api/total-balance';
        return this.get(url);
    }
    getWalletBalances()
    {
        let url = '/admin/api/wallet-balance';
        return this.get(url)
    }
    getListCoins()
    {
        let url = '/admin/getAllCoins';
        return this.get(url);
    }
}
