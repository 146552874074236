<template>
  <div id="new-reward">
    <div class="sub-title" @click="cancelAddReward">
      <img class="" src="@/assets/images/icon/arrow-left.svg">
      {{ subTitle }}</div>
    <div class="form-input">
      <div class="col-sm-4 form-group">
        <label class="label-input">{{ $t('reward_center.voucher.name') }}</label>
        <input
          ref="voucher"
          id="voucher_name"
          :maxlength="maxLength"
          autocomplete="off"
          name="voucher"
          data-vv-validate-on="none"
          v-validate="'required|max:28'"
          data-vv-as="voucher"
          type="text" class="form-control"
          :class="{ errorInput: errors.has('name') }"
          @focus="resetError"
          v-model="dataVoucher.name"
          :disabled="isEdit"
        />
        <span class="error-message" v-if="errors.has('voucher')"> {{ errors.first('voucher') }} </span>
      </div>
      <div class="col-sm-4 form-group">
        <label class="label-input">{{ $t('reward_center.beneficiary') }}</label>
        <select-box v-model="dataVoucher.type" v-validate="'required'" name="beneficiary" class="select-market"
                    @focus="resetError"
                    :options="voucherType"
                    :disabled="isEdit"
        />
        <span class="error-message" v-if="errors.has('beneficiary')"> {{ errors.first('beneficiary') }} </span>
      </div>
      <div class="col-sm-4 form-group">
        <label class="label-input">{{ $t('reward_center.currency') }}</label>
        <input
          ref="currency"
          id="currency"
          autocomplete="off"
          name="currency"
          data-vv-validate-on="none"
          type="text" class="form-control"
          :class="{ errorInput: errors.has('currency') }"
          @focus="resetError"
          v-model="dataVoucher.currency"
          :disabled="true"
        />
        <span class="error-message" v-if="errors.has('currency')"> {{ errors.first('currency') }} </span>
      </div>
      <div class="col-sm-4 form-group">
        <label class="label-input">{{ $t('reward_center.trading_volume') }}</label>
        <number-input
          ref="settlement-fee"
          id="settlement-fee"
          :precision="3"
          :rational-number="10"
          :restrict-step-price="true"
          name="trading"
          @focus="resetError"
          class="form-control padding-unit"
          :class="{ errorInput: errors.has('trading')}"
          v-model="dataVoucher.conditions_use"
        >
        </number-input>
        <span class="error-message" v-if="errors.has('trading')"> {{ errors.first('trading') }} </span>
      </div>
      <div class="col-sm-4 form-group">
        <label class="label-input">{{ $t('reward_center.reward') }}</label>
        <number-input
          ref="settlement-fee"
          id="settlement-fee"
          :precision="3"
          :rational-number="10"
          :restrict-step-price="true"
          name="reward"
          @focus="resetError"
          class="form-control padding-unit"
          :class="{ errorInput: errors.has('reward')}"
          v-model="dataVoucher.amount"
        >
        </number-input>
        <span class="error-message" v-if="errors.has('reward')"> {{ errors.first('reward') }} </span>
      </div>
      <div class="col-sm-4 form-group">
        <label class="label-input">{{ $t('reward_center.expired_time') }}</label>
        <number-input
          ref="settlement-fee"
          id="settlement-fee"
          :maxlength="2"
          :restrict-step-price="true"
          name="expired"
          @focus="resetError"
          class="form-control padding-unit"
          :class="{ errorInput: errors.has('expired')}"
          v-model="dataVoucher.expires_date_number"
        >
        </number-input>
        <span class="error-message" v-if="errors.has('expired')"> {{ errors.first('expired') }} </span>
      </div>
      <div class="form-action col-sm-4">
        <button class="btn btn-normal " @click="cancelAddReward">
          {{ $t('common.action.cancel') }}
        </button>
        <button class="btn btn-submit " @click="onSubmit" :disabled="isSubmitting">
          {{ $t('common.confirm') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import InputOnlyNumber from "@/common/InputOnlyNumber.vue";
import InputTextAndNumber from "@/components/InputTextAndNumber.vue";
import Modal from "@/components/Modal.vue";
import DatePicker from "vuejs-datepicker";
import SelectBox from "@/components/SelectBox.vue";
import DataTable4 from "@/components/DataTable4.vue";
import CoinInput from "@/common/CoinInput.vue";
import datetime from "vuejs-datetimepicker";
import rf from "@/lib/RequestFactory";
import DropDownList from "../../common/DropDownList";
import NumberInput from "@/common/NumberInput.vue";
import RemoveErrorsMixin from "@/common/RemoveErrorsMixin";
import {Validator} from 'vee-validate';
import BigNumber from "bignumber.js";
import {params} from "superagent/lib/utils";

export default {
  components: {
    InputOnlyNumber,
    InputTextAndNumber,
    Modal,
    DatePicker,
    SelectBox,
    DataTable4,
    CoinInput,
    datetime,
    DropDownList,
    NumberInput,
  },
  mixins: [RemoveErrorsMixin],
  data() {
    return {
      titlePage: this.$t('reward_center.title_page'),
      titleSub: this.$t('reward_center.title_page'),
      searchKey: '',
      dataTable: [],
      totalVoucer: 0,
      maxLength: 28,
      isSubmitting: false,
      dataVoucher: {
        name: '',
        type: '',
        currency: "USDT",
        conditions_use: 0,
        amount: 0,
        expires_date_number: 0,
      },
      voucherType: [
        "Spot Wallet",
        "Future Wallet",
      ],
    }
  },
  methods: {
    getData(id) {
      rf.getRequest('RewardCenterRequest').detailReward(id).then(res => {
        this.updateReward(res.data)

      })
    },

    checkDataWithZero(value, field) {
      if (value <= 0 || typeof (value) == 'undefined') {
        this.errors.add({
          field: field,
          msg: window.i18n.t('errors.required')
        });
        return false;
      }
      return true;
    },

    cancelAddReward() {
      this.$router.push({name: 'Reward Center'});
    },

    async onSubmit() {
      if (this.isSubmitting) {
        return;
      }
      this.resetError();
      this.checkDataWithZero(this.dataVoucher.expires_date_number, 'expired')
      this.checkDataWithZero(this.dataVoucher.amount, 'reward')
      this.checkDataWithZero(this.dataVoucher.conditions_use, 'trading')
      await this.$validator.validateAll();
      if (this.errors.any()) {
        return;
      }
      if (this.$route.query.id) {
        this.updateRewardCenter(this.$route.query.id)
      } else {
        this.createReward()
      }

    },
    createReward() {
      let dataValue = {...this.dataVoucher}
      const params = {
        currency: dataValue.currency.toLowerCase(),
        type: this.convertTypeReward(dataValue.type)
      }
      rf.getRequest('RewardCenterRequest').addNewReward(Object.assign(this.convertDataReward(), params)).then((res) => {
        this.$toastr('success', this.$t('user.succes'));
        this.$router.push({name: 'Reward Center'});

      }).catch((err) => {
        if( err.response.data.message === 'voucher.no.create') {
          this.errors.add({field: 'beneficiary', msg: this.$t('voucher.no.create')});
        }
        else{
          this.errors.add({field: 'voucher', msg: this.$t('reward_center.unique_voucher')});
        }
      });
    },
    convertTypeReward(type) {
      switch (type) {
        case 'Spot Wallet':
          return 'spot'
        case 'Future Wallet':
          return 'future'
        case 'spot':
          return 'Spot Wallet'
        case 'future':
          return 'Future Wallet'
      }
    },
    updateReward(data) {
      let valueUpdate = data
      valueUpdate = {
        name: valueUpdate.name,
        type: this.convertTypeReward(valueUpdate?.type),
        currency: valueUpdate?.currency?.toUpperCase(),
        conditions_use: new BigNumber(valueUpdate.conditions_use),
        amount: new BigNumber(valueUpdate.amount),
        expires_date_number: valueUpdate.expires_date_number
      }
      return Object.assign(this.dataVoucher, valueUpdate)
    },

    updateRewardCenter(id) {
      rf.getRequest('RewardCenterRequest').updateReward(Object.assign(this.convertDataReward(), params), id).then(res => {
        this.$toastr('success', this.$t('user.succes'));
        this.$router.push({name: 'Reward Center'});
      }).catch(err => {

      })
    },
    convertDataReward() {
      let dataValue = {...this.dataVoucher}
      const params = {
        currency: dataValue.currency.toLowerCase(),
        type: this.convertTypeReward(dataValue.type)
      }
      return Object.assign(dataValue, params)
    }
  },
  computed: {
    subTitle(){
       return this.$route.query.id?  this.$t('reward_center.title_page_edit') : this.$t('reward_center.title_page_create')
    },
    isEdit() {
      return !!this.$route.query.id
    }
  },
  mounted() {
    this.$emit('EVENT_PAGE_CHANGE', this);
  },

  created() {
    if (this.$route.query.id) {
      this.getData(this.$route.query.id)
    }
    Validator.localize({
      en: {
        messages: {
          required: (field) =>
            window.i18n.t('errors.required'),
        },
      },
    })
  },
}

</script>

<style scoped lang="scss">
@import "@/assets/sass/variables";

#new-reward {
  .sub-title {
    font-size: $font_big_20;
    margin: 26px 0px ;
    font-family: $mulish-regular;
    line-height: 25px;
    color: $dark-1;
    display: flex;
    align-items: center;
    gap: 16px;
  }

  .form-input {
    display: flex;
    padding: 20px;
    //flex-direction: column;
    flex-wrap: wrap;
    background: $color-white;
    border-radius: 10px;
    .form-group {

      label {
        font-weight: normal;
        font-size: $font_semi_big;
        line-height: 20px;
        color: $dark-1;
        margin-bottom: 10px;
      }
      .form-control {
        width: 355px;
        max-width: 100%;
        border-radius: 12px !important;
        padding: 12px 16px 12px 16px;
        height: 41px;
        border: 1px solid #C7CBD3;
        color: $dark-1;
        font-size: $font_semi_big;
        & :hover :focus {
          box-shadow: none;
        }
      }

    }

    //input[type="text"]:disabled {
    //  color: $color-grey-dusty;
    //}
    .form-action {
      display: flex;
      align-items: center;
      gap: 20px;
      margin-top: 25px;
      .btn {
        width: 150px;
        height: 41px;
        color: $dark-1;
        font-size: $font-semi_big;
        border-radius: 30px;
        font-family: $mulish-bold;
      }
      .btn-normal {
        line-height: 20px;
        text-align: center;
        -webkit-transition: 0.5s;
        transition: 0.5s;
        border: 1px solid #DEDEDE;
        background: transparent;
        &:hover {
          background: $color_silver;
        }
      }
      .btn-submit {
        background: $color-caribbean-green;
        -webkit-transition: 0.5s;
        transition: 0.5s;
        line-height: 20px;
        &:hover {
          background: $color-aquamarine;
        }
      }
    }
  }

  :deep(.group_search_select) {
    width: 355px ;
    max-width: 100%;
    display: flex;
    align-items: center;
    .button_drop_search {
      width: 100%;
      padding: 10px 16px;
      height: 41px;
      border-radius: 12px;
      border: 1px solid rgb(193, 193, 193) !important;
      span {
        font-family: $mulish-medium;
        font-size: 16px;
        line-height: 20px;
        color: $dark-1;
      }
    }
    .box_list_search_select {
      .list_search_select {
        border-radius: 12px;
        > li {
          padding: 10.5px 20px;
          > span {
            font-family: $mulish-medium;
            font-size: 16px;
            line-height: 20px;
          }
        }
        > li:hover {
          background: $color-athens;
        }
      }
    }
  }

  .error-message {
    color: $color-denim;
  }
}

</style>