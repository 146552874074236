import BaseRequest from '../lib/BaseRequest'

export default class BitmexAccountRequest extends BaseRequest {
    getBitmexAccount(params)
    {
        let url = '/admin/api/bitmex/account';
        return this.get(url, params);
    }
    getKey()
    {
        let url = '/admin/api/bitmex/account/get-key';
        return this.get(url);
    }
    getPosition(params)
    {
        let url = '/admin/api/bitmex/position';
        return this.get(url, params);
    }

    submitAccount($params)
    {
        let url = '/admin/api/bitmex/account';
        return this.post(url, $params);
    }

    setLeverage($params)
    {
        let url = '/admin/api/bitmex/leverage';
        return this.post(url, $params);
    }
}
