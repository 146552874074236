<template>
  <div class="order_book boxCore" id="orderbook_spot">
    <div class="d-flex w-100">
      <div class="filter-template">
        <div class="label-text text-pair date-text">{{ $t('orders.open_order.pair') }}:</div>
        <div class="coin-selector toolbar-element2" v-click-outside="clickOut">
          <input type="text" @click="showCoinOption()" @keyup="onSearchCoin()"
            :placeholder="$t('order.order_history.coin')" @keyup.enter="search" class="form-control coin-input"
            v-model="filters.coin">
          <div class="coin-option" v-if="isShowCoinOption">
            <span class="option" v-for="coin in coinMasters" @click="onSelectCoin(coin)" :key="coin.id">{{ coin.name |
          uppercase
              }}</span>
          </div>
        </div>
        <span class="bw-element">/</span>
        <div class="toolbar-element2">
          <select-box v-model="filters.currency" :placeholder="$t('order.order_history.all')" :options="markets" />
        </div>
      </div>
      <div class="filter-template">
        <div class="label-text">&nbsp;</div>
        <button class="btn btn-reset" @click="refresh()">{{ $t('orders.open_order.reset') }}</button>
      </div>
    </div>
    <div class="table-all">
      <div class="table-futures">
        <div class="table-wrap">
          <table class="buy-table">
            <thead>
              <tr>
                <th class="text-left buy" data-sort-field="price">{{ $t('order.open_order.price') }}</th>
                <th class="text-left buy" data-sort-field="quantity">{{ $t('order.open_order.quantity') }}</th>
                <th class="text-left buy" data-sort-field="total">{{ $t('order.open_order.total') }}</th>
              </tr>
            </thead>

            <tbody v-if="buyOrderBook">
              <tr v-for="(order, index) in buyOrderBookDisplay" :key="`buyOrder-${index}`">
                <td class="text-left">
                  {{ order.price | formatCurrencyAmountDecimal(8, '0') }}
                </td>
                <td class="text-left">
                  {{ order.quantity | formatCurrencyAmountDecimal(8, '0') }}
                </td>
                <td class="text-left">
                  {{ calculateTotalQuantityCoinForDisplay(buyOrderBookDisplay, order, index, 'buy') | toNumber |
          formatCurrencyAmountDecimal(8, '0') }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="table-wrap">
          <table class="sell-table">
            <thead>
              <tr>
                <th class="text-left sell" data-sort-field="price">{{ $t('order.open_order.price') }}</th>
                <th class="text-left sell" data-sort-field="quantity">{{ $t('order.open_order.quantity') }}</th>
                <th class="text-left sell" data-sort-field="total">{{ $t('order.open_order.total') }}</th>
              </tr>
            </thead>

            <tbody v-if="sellOrderBook">
              <tr v-for="(order, index) in sellOrderBookDisplay" :key="`sellOrder-${index}`">
                <td class="text-left">
                  {{ order.price | formatCurrencyAmountDecimal(8, '0') }}
                </td>
                <td class="text-left">
                  {{ order.quantity | formatCurrencyAmountDecimal(8, '0') }}
                </td>
                <td class="text-left">
                  {{ calculateTotalQuantityCoinForDisplay(sellOrderBookDisplay, order, index, 'sell') | toNumber |
          formatCurrencyAmountDecimal(8, '0') }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="filter-size">
      <div class="filter-template status">
        <div class="toolbar-element">
          <select-box v-model="filters.size" :placeholder="$t('order.order_history.all')" :options="sizeData" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import moment from "moment";
import COMMON_CONST from "../../common/Const";
import rf from "../../lib/RequestFactory";
import { mapActions } from "vuex";
import DatePicker from 'vuejs-datepicker';
import Modal from "../../components/Modal";
import SelectBox from "../../components/SelectBox";
import BigNumber from "bignumber.js";

const SELL = 'sell';
const BUY = 'buy';

export default {
  name: "OrderFuturesRecords",
  components: {
    Modal,
    DatePicker,
    SelectBox
  },
  data() {
    return {
      titlePage: window.i18n.t('menu.spot.order_book'),
      filters: {
        coin: "BTC",
        currency: { id: "usdt", name: "USDT" },
        size: { id: 10, name: "10" }
      },
      sizeData: [
        { id: 10, name: "10" },
        { id: 99999, name: "All" }
      ],
      buyOrderBook: [],
      sellOrderBook: [],
      maxSizeDisp: 12,
      minSizeDisp: 7,

      totalRecords: null,
      coinMasters: [],
      markets: [],
      isShowCoinOption: false,
    }
  },
  watch: {
    isActive() {
      this.$refs.datatable.refresh();
    },
    filters: function () {
      this.getPriceGroupsByPairCurrency();
    }
  },
  computed: {
    sellOrderBookDisplay() {
      // Show all sellOrderBook
      let currentSellOrderBook = [];
      const length = this.sellOrderBook.length;
      if (!length) {
        return [];
      }

      currentSellOrderBook = window._.slice(this.sellOrderBook, length - this.filters.size.id, length);

      return currentSellOrderBook;
    },
    buyOrderBookDisplay() {
      const length = this.buyOrderBook.length;
      if (!length) {
        return [];
      }
      return window._.slice(this.buyOrderBook, 0, this.filters.size.id);
    }
  },
  methods: {
    customFormatter(date) {
      return moment(date).format('YYYY-MM-DD');
    },
    refresh() {
      this.resetInput();
      this.$refs.datatable.refresh();
    },
    resetInput() {
      this.filters = Object.assign(this.filters, {
        coin: "BTC",
        currency: { id: "usdt", name: "USDT" },
        size: { id: 10, name: "10" }
      });
    },
    listenForNotification() {
      window.Echo.channel('App.Models.Admin')
        .listen('AdminNotificationUpdated', () => {
          this.$refs.datatable.refresh();
        });
    },
    onSearchCoin() {
      this.coins = _.filter(this.coinMasters, item => {
        return _.isMatch(item.name.toUpperCase(), this.filters.coin.toUpperCase());
      });
    },
    onSelectCoin(coin) {
      this.isShowCoinOption = false;
      this.filters.coin = coin.name.toUpperCase();
    },
    clickOut() {
      this.isShowCoinOption = false;
    },
    showCoinOption() {
      this.isShowCoinOption = true;
      this.coins = this.coinMasters;
    },
    requestCoinList() {
      rf.getRequest("CoinSettingRequest")
        .getCoinList()
        .then(res => {
          // this.coinMasters.push({ id: "all", name: window.i18n.t('orders.open_order.all') });
          window._.map(res.data, item => {
            if (typeof item.coin !== 'undefined') {
              this.coinMasters.push({ id: item.coin, name: item.coin.toUpperCase() });
            }
          });
        });
    },
    requestGroupCurrency() {
      rf.getRequest('AdminRequest').getPriceGroupCurrency().then(res => {
        try {
          // this.markets.push({ id: "all", name: window.i18n.t('orders.open_order.all') });
          window._.map(res.data, item => {
            if (typeof item.currency !== 'undefined') {
              this.markets.push({ id: item.currency, name: item.currency.toUpperCase() });
            }
          });
        }
        catch (ex) {
          console.error(ex);
        }
      });
    },
    listenForOrderBook() {
      window.Echo.channel('App.OrderBook')
        .listen('OrderBookUpdated', (orderBook) => {
          this.onReceiveOrderBook(orderBook.data);
        });
    },
    unlistenForOrderbook() {
      window.Echo.leave('App.OrderBook');
    },
    onReceiveOrderBook(data) {
      if (this.filters.currency.id != data.currency || this.filters.coin.toLowerCase() != data.coin) {
        return;
      }
      if (data.isFullOrderBook) {
        this.rebuildOrderBook(data.orderBook);
      } else {
        this.buyOrderBook = window._.concat(this.buyOrderBook, data.orderBook.buy);
        this.sellOrderBook = window._.concat(this.sellOrderBook, data.orderBook.sell);

        this.onOrderBookUpdated();
      }
    },
    rebuildOrderBook(data) {
      if (this.disableByBetaTester) {
        data.buy = [];
        data.sell = [];
        this.buyOrderBook = [];
        this.sellOrderBook = [];
      }
      this.convertOrderBookDataType(data);
      const orderBook = this.transformOrderBook(data);

      this.buyOrderBook = window._.orderBy(data.buy, (row) => parseFloat(row.price), ['desc']);
      this.sellOrderBook = window._.orderBy(data.sell, (row) => parseFloat(row.price), ['asc']);
      this.buyOrderBook = this.getRoundedData(this.buyOrderBook, this.getExponent(this.buyOrderBook[0].price), 'buy')
      this.sellOrderBook = this.getRoundedData(this.sellOrderBook, this.getExponent(this.sellOrderBook[0].price), 'sell')

      this.fillRowBlankIfNeed(this.sellOrderBook, SELL);
      this.fillRowBlankIfNeed(this.buyOrderBook, BUY);

      this.onOrderBookUpdated();
    },
    onOrderBookUpdated() {
      if (window._.isEmpty(this.buyOrderBook) || window._.isEmpty(this.sellOrderBook) || !this.tickerSize) {
        return;
      }

      // price of trade Buy is 0 (price after a round by priceGroup) => display random any number.
      this.reCalcBuyOrderBookIfNeed(this.buyOrderBook);

      this.calculateQuantityPercent();
    },
    reCalcBuyOrderBookIfNeed(data) {
      window._.map(data, item => {
        if (parseFloat(item.price) === 0) {
          item.price = new BigNumber(1).div(`${Math.pow(10, this.selectedPriceGroup.precision)}`).toString();
        }
        return item;
      });
    },
    calculateQuantityPercent() {
      const maxBuyRow = this.getMaxQuantity(this.buyOrderBook);
      const maxSellRow = this.getMaxQuantity(this.sellOrderBook);

      const maxBuyQuantity = maxBuyRow ? maxBuyRow.quantity : 0;
      const maxSellQuantity = maxSellRow ? maxSellRow.quantity : 0;
      const maxQuantity = Math.max(maxBuyQuantity, maxSellQuantity);

      this.calculatePercent(this.buyOrderBook, maxQuantity);
      this.calculatePercent(this.sellOrderBook, maxQuantity);
    },
    getMaxQuantity(data) {
      return window._.chain(data)
        .filter(item => item.quantity !== BLANK)
        .map(item => {
          item.quantity = parseFloat(item.quantity);
          return item;
        })
        .maxBy('quantity')
        .value();
    },
    calculatePercent(data, maxQuantity) {
      for (let i in data) {
        let row = data[i];
        if (maxQuantity) {
          row.percent = row.quantity * 100 / maxQuantity;
        } else {
          row.percent = 0;
        }
      }
    },
    transformOrderBook(data) {
      let orderBook = {};
      orderBook.buy = window._.values(data.buy);
      orderBook.sell = window._.values(data.sell);
      return orderBook;
    },
    convertOrderBookDataType(orderBook) {
      this.convertStringToNumber(orderBook.buy);
      this.convertStringToNumber(orderBook.sell);
    },
    convertStringToNumber(data) {
      window._.each(data, item => {
        item.price = parseFloat(item.price);
        item.quantity = parseFloat(item.quantity);
        item.count = parseFloat(item.count);
        item.percent = 0;

        // Flag which show border when row is clicked.
        item.isClickedRow = false;
      });
    },
    getRoundedData(data, decimal, type) {
      // Create new array with rounded prices
      const roundedData = data.map(obj => {
        return {
          quantity: obj.quantity,
          price: type === 'sell' ? parseFloat(this.roundToDecimalSell(obj.price, decimal)) : parseFloat(this.roundToDecimalBuy(obj.price, decimal))
        };
      });
      return roundedData
    },
    roundToDecimalBuy(number, decimalPrecision) {
      const factor = 10 ** decimalPrecision;
      return Math.round(number * factor) / factor;
    },
    roundToDecimalSell(value, precision) {
      return new BigNumber(value).toFormat(precision, BigNumber.ROUND_UP).replace(/,/g, '');
    },
    fillRowBlankIfNeed(subOrderBook, tradeType) {
      // Remove row blank
      this.removeRowBlank(subOrderBook);
      if (subOrderBook.length > this.maxSizeDisp) {
        return;
      }
      const data = [];
      while (data.length < (this.maxSizeDisp - subOrderBook.length)) {
        const item = this.createRowSubOrderBook();
        data.push(item);
      }
      if (tradeType === SELL) {
        this.sellOrderBook = window._.concat(data, subOrderBook);
      } else {
        this.buyOrderBook = window._.concat(subOrderBook, data);
      }
    },
    removeRowBlank(data) {
      window._.remove(data, item => !item.price || !item.quantity);
    },
    createRowSubOrderBook() {
      return {
        price: undefined,
        quantity: 0,
        percent: 0
      };
    },
    calculateTotalQuantityCoinForDisplay(inputList, item, index, type) {
      let list = window._.cloneDeep(inputList);
      if (item.price == undefined) {
        return undefined;
      }

      let subListOrderBook = window._.slice(list, index);
      if (type == 'buy') {
        subListOrderBook = window._.slice(list, 0, index + 1);
      }
      const [price, maxAmount] = this.getPriceAndAmountSubOrderBook(item.price, subListOrderBook);

      return maxAmount;
    },
    getPriceAndAmountSubOrderBook(price, subOrderBook) {
      const maxAmount = window._.sumBy(subOrderBook, row => row.quantity);
      return [price, maxAmount];
    },
    getExponent(num) {
      return Math.floor(Math.log10(num)) > 0 ? Math.floor(Math.log10(num)) : Math.floor(Math.log10(num)) * -1;
    },
    getPriceGroupsByPairCurrency() {
      if (window._.isEmpty(this.priceGroups)) {
        this.priceGroupsByPair = [];
        return;
      }
      const priceGroupFilter = window._.filter(this.priceGroups, item => {
        return item.currency === this.currency && item.coin === this.coin;
      });

      // Sort by precision asc
      this.priceGroupsByPair = window._.sortBy(priceGroupFilter, 'precision');

      let isSetPrecision = false;
      this.priceGroupsByPair.forEach(item => {
        if (item.value == this.coinSettingByPair.price_precision) {
          this.selectedPriceGroup = item;
          isSetPrecision = true;
        }
      });
      if (!isSetPrecision) {
        this.selectedPriceGroup = window._.last(this.priceGroupsByPair);
      }
    },
  },
  mounted() {
    this.$emit('EVENT_PAGE_CHANGE', this);
    this.listenForNotification();
    this.listenForOrderBook();
  },
  beforeDestroy() {
    this.unlistenForOrderbook();
  },
  created() {
    this.requestCoinList();
    this.requestGroupCurrency();
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/variables";

.d-flex {
  display: flex;
}

.w-100 {
  width: 100%;
}

.buy {
  background: $text-color-red !important;
}

.sell {
  background: $text-color-jade !important;
}

#orderbook_spot {
  .table-wrap {
    flex: 1;
    min-height: 480px;
    margin-bottom: 40px;
  }

  table {
    width: 100%;

    thead {
      th {
        padding: 16px 12px;
        font-family: $mulish-medium;
        color: $color-white;

        &:first-child {
          width: 22%;
          min-width: 100px;
        }

        &:nth-child(2) {
          width: 25%;
          min-width: 120px;
        }

        &:nth-child(3) {
          width: 25%;
          min-width: 120px;
        }

        &:nth-child(4) {
          width: 28%;
          min-width: 125px;
        }
      }
    }

    tbody {
      td {
        padding: 10px 12px;
        font-family: $mulish-regular;
        border-bottom: 1px solid $color-bright-gray;
        background-color: $color-white;
      }
    }
  }
}

.no-left-radius {
  input {
    border-radius: 0px 3px 3px 0px;
  }
}

.no-right-radius {
  input {
    border-radius: 3px 0px 0px 3px;
  }
}

.toolbar-element {
  display: inline-block;
  float: left;
  width: 80px;
}

td {
  font-family: $font-family-roboto;
}

.table-futures {
  margin-top: 24px;
  display: flex;
  gap: 36px;
}

.filter-template {
  margin-right: 15px;

  .label-text {
    color: $dark-1;
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 7px;
    margin-left: 5px;
    font-family: $mulish-regular;
  }

  .btn-reset {
    background: $color-caribbean-green;
    border-radius: 10px;
    color: $color_white;
    font-weight: 700;
    width: 78px;
    height: 32px;
  }

  .coin-selector {
    width: 75px;
    position: relative;

    .coin-input {
      display: inline-block;
      margin: 0 5px;
      margin-right: 0px;
      border-radius: 10px !important;
      background-color: $color-white;
      height: 32px;
      width: 100%;
    }

    .coin-option {
      background-color: $color-white;
      max-height: 210px;
      display: block;
      overflow-y: auto;
      margin-top: 5px;
      position: absolute;
      width: 79px;
      left: 5px;
      z-index: 10;
      padding: 12px 0px;
      box-shadow: 0px 3px 12px rgba(49, 49, 49, 0.51);
      border-radius: 10px;

      &:after {
        bottom: 100%;
        left: 50%;
        border: solid transparent;
        content: " ";
        display: block;
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: rgba(136, 183, 213, 0);
        border-bottom-color: $color_white;
        border-width: 5px;
        margin-left: -5px;
      }

      .option {
        display: block;
        width: 100%;
        line-height: 20px;
        cursor: pointer;
        padding: 3px 14px;
        overflow: hidden;

        &:hover {
          color: $color-jungle-green;
        }
      }
    }
  }

  .form-control {
    background: $color-white-smoke;
    height: 32px;
    font-size: 13px;
    padding-left: 11px;
    padding-right: 0;
    border-radius: 10px !important;
  }

  .toolbar-element2 {
    width: 100px;
    float: left;
  }

  .bw-element {
    float: left;
    font-size: $font_medium;
    margin-left: 10px;
    height: 30px;
    line-height: 30px;
    margin-right: 5px;
    color: $color-grey-dusty;
  }
}
</style>

<style lang="scss">
@import "@/assets/sass/variables";

#orderbook_spot {
  .sc_search_select {
    .group_search_select {
      .button_drop_search {
        width: 100%;
        height: 32px !important;
        padding: 6px 10px 6px 10px !important;
        border-radius: 10px;
        margin-right: 20px;
        font-size: $font-small;
        background-color: $color-white;
        font-family: $mulish-regular;

        i.icon-arrow1 {
          margin-left: 5px;
          color: $color_dove_gray;
        }

        &:hover {
          border-color: $color-jungle-green;

          i.icon-arrow1 {
            color: $color-jungle-green;
          }
        }

      }

      .box_list_search_select {
        .list_search_select {
          li {
            font-family: $font-family-roboto;
            font-size: $font-small;

            &:hover,
            &:active,
            &:focus {
              color: $color-jungle-green;
            }
          }
        }
      }
    }
  }
}
</style>