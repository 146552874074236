<template>
  <div id="mail-edit">
    <div class="page960">
      <div class="section">
        <div class="form-notice-detail-container">
          <div id="notice-detail">
            <div class="notice-detail-form-group">
              <label class="notice-detail-form-label">{{ $t('cms.mail_template.language') }}</label>
              <div class="f-left"><select-box class="notice-detail-form-value" v-model="record.lang"
                  :class="{ 'input-error': errors.has('lang') }" :options="langOptions"
                  :placeholder="$t('cms.mail_template.select_language')" /></div>
              <div class="clearifx"></div>
              <div class="error-box">
                <span v-show="errors.has('lang')" class="f-left is-error">{{ errors.first('lang') }}</span>
              </div>
            </div>
            <div class="notice-detail-form-group">
              <label class="notice-detail-form-label">{{ $t('cms.mail_template.type') }}</label>
              <div class="f-left">
                <div class="input-dropdown" v-click-outside="clickOutsideDropdown">
                  <input type="text" @click="showDropdown" :placeholder="$t('cms.mail_template.select_type')"
                    class="form-control" v-model="record.type" />
                  <span class="icon-coin-input" @click="showDropdown"><i class="fa"
                      :class="!isShowDropdown ? 'fa-angle-down' : 'fa-angle-up'"></i></span>
                  <div class="coin-option" v-if="isShowDropdown">
                    <span class="option create-new" @click="createNewType">
                      <span class="icon-plus"></span>
                      {{ $t('cms.mail_template.create_type') }}</span>
                    <span class="option" v-for="option in typesOption" @click="onSelectDropdown(option)">{{ option.name
                      }}</span>
                  </div>
                </div>
              </div>
              <div class="error-box">
                <span v-show="errors.has('type')" class="f-left is-error">{{ errors.first('type') }}</span>
              </div>
            </div>
            <div class="notice-detail-form-group">
              <label class="notice-detail-form-label">{{ $t('cms.mail_template.name') }}</label>
              <div class="f-left"><input :maxlength="maxLength" class="notice-detail-form-value" id="input-name"
                  v-model="record.name" :class="{ 'input-error': errors.has('name') }" /></div>
              <div class="clearifx"></div>
              <div class="error-box">
                <span v-show="errors.has('name')" class="f-left is-error">{{ errors.first('name') }}</span>
              </div>
            </div>
            <div class="notice-detail-form-group clearfix">
              <label class="notice-detail-form-label">{{ $t('cms.mail_template.subject') }}</label>
              <div class="f-left"><input :maxlength="maxLength" class="notice-detail-form-value" id="input-subject"
                  v-model="record.subject" :class="{ 'input-error': errors.has('subject') }" />
              </div>
              <div class="clearifx"></div>
              <div class="error-box">
                <span v-show="errors.has('subject')" class="f-left is-error">{{ errors.first('subject')
                  }}</span>
              </div>
            </div>
            <div class="notice-detail-form-group clearfix">
              <label class="notice-detail-form-label label-inline">{{ $t('cms.mail_template.default') }}</label>
              <div class="switch-container">
                <label class="switch" @click="record.status = !record.status">
                  <input type="checkbox" :checked="record.status">
                  <span class="slider round"></span>
                </label>
              </div>
            </div>
            <div class="clearfix"></div>
            <div class="notice-detail-form-group notice-image clearfix">
              <span class="notice-detail-form-label label-inline">{{ $t('</>') }}</span>
              <div class="clearfix"></div>
              <div class="code-container">
                <div class="code">
                  <textarea v-model="record.code" @input="event => record.code = event.target.value"></textarea>
                </div>
                <div class="preview">
                  <div class="content-preview" v-html="record.code"></div>
                  <button v-if="!!record.code" class="btn-zoom" @click="isPreview = true"><span class="icon-plus"></span></button>
                </div>
              </div>
            </div>
            <div class="clearfix"></div>
            <div class="submit">
              <button class="btn-cancel" @click="cancel">{{ $t('notice.cancel') }}</button>
              <button class="file-btn" @click="submitNotice(record.id)">{{ $t('notice.submit') }}</button>
            </div>
          </div>
        </div>
      </div>
      <message></message>
      <modal name="submitNotice" width="420" height="259" title="">
        <div slot="body" class="body-popup" style="font-size: 16px; padding: 10px 20px 0px; font-weight: 600;">
          {{ $t('notice.submit_data') }}
        </div>
      </modal>
      <type-modal class="newType_modal" name="newType" :title="$t('cms.notification.add_new_type')">
        <div slot="body" class="body-popup">
          <div class="notice-detail-form-group clearfix">
            <div class="f-left"><input :maxlength="maxLength" class="notice-detail-form-value" id="input-newType"
                :placeholder="$t('cms.notification.enter_type')" v-model="newType"
                :class="{ 'input-error': errors.has('newType') }" />
            </div>
            <div class="clearifx"></div>
            <div class="error-box">
              <span v-show="errors.has('newType')" class="f-left is-error">{{ errors.first('newType')
                }}</span>
            </div>
          </div>
        </div>
      </type-modal>
    </div>
    <div v-if="isPreview" class="mail-preview">
      <div class="mail-container">
        <button type="button" class="btn-close" @click="closePreview">
          <span aria-hidden="true" class="icon-close"></span>
        </button>
        <div class="content" v-html="record.code"></div>
      </div>
    </div>
  </div>
</template>

<script>
import rf from '../../../lib/RequestFactory';
import Modal from '@/desktop/components/common/Modal';
import TypeModal from "../../../components/Modal";
import DatePicker from 'vuejs-datepicker';
import moment from 'moment';
import CONST from '../../../common/Const';
import Message from '@/desktop/components/common/Message';
import SelectBox from '../../../components/SelectBox.vue';

const IMAGE_FILE_UPLOAD_SIZE = 10485760; // 10Mb
export default {

  components: {
    Modal,
    DatePicker,
    Message,
    SelectBox,
    TypeModal
  },

  data() {
    return {
      noticeId: this.$route.query.id,
      titlePage: this.$route.query.id ? window.i18n.t('cms.mail_template.title_edit') : window.i18n.t('cms.mail_template.title_create'),
      started_at: new Date(),
      ended_at: moment().add(6, 'day').toDate(),
      record: {
        title: "",
        support_url: "",
        lang: { id: 'en', name: "English" },
        code: '<h1 style="color: blue">1241234234234</h1>'
      },
      maxLength: CONST.MAX_LENGTH_INPUT,
      validateErrors: {},
      apiUrl: process.env.VUE_APP_STORAGE_URL,
      isShowDropdown: false,
      newType: "",
      isLoading: false,
      enableClose: false,
      isPreview: false,
      langOptions: [
        { id: 'en', name: "English" },
        { id: 'ko', name: "Korean" },
        { id: 'vi', name: "Vietnamese" }
      ],
      typesOption: [
        { value: 'system_message', name: window.i18n.t('cms.notification.type_system_message') },
        { value: 'event', name: window.i18n.t('cms.notification.type_event') },
        { value: 'campaign', name: window.i18n.t('cms.notification.type_campaign') },
        { value: 'new_listing', name: window.i18n.t('cms.notification.type_new_listing') },
        { value: 'copy_trade', name: window.i18n.t('cms.notification.type_copy_trade') },
        { value: 'announcement', name: window.i18n.t('cms.notification.type_announcement') },
      ],
    };
  },
  methods: {
    submitNotice(id) {
      if (!this.validate()) {
        return;
      }
      this.record.title = this.record.title.trim();
      this.record.support_url = this.record.support_url.trim();
      ConfirmationModal.show({
        type: 'confirm',
        title: this.$t('notice.submit_data'),
        content: '',
        btnCancelLabel: this.$t('common.action.no'),
        btnConfirmLabel: this.$t('common.action.yes'),
        size: 'small',
        onConfirm: () => {
          this.requestHandler();
        },
        onCancel: () => { }
      });
    },

    getParams() {
      let fd = new FormData();

      const bannerUrl = this.$refs.file.files[0] || this.record.banner_url;

      fd.append('title', this.record.title);
      fd.append('support_url', this.record.support_url);
      fd.append('started_at', moment(this.started_at).format('x'));
      fd.append('ended_at', moment(this.ended_at).format('x'));
      fd.append('banner_url', bannerUrl);

      return fd;
    },

    requestHandler() {
      if (!this.validate()) {
        return;
      }
      this.getRequest().then(res => {
        this.$toastr('success', this.noticeId ? window.i18n.t('common.alert.update.success') : window.i18n.t('common.alert.create.success'));

        var _cpn = this;
        setTimeout(function () {
          _cpn.$router.push({ name: 'Notice' });
        }, 1000);
      }).catch(error => {
        if (!error.response) {
          Message.error(window.i18n.t('common.message.network_error'), {}, { position: "bottom_left" });
          return;
        }
        const errors = error.response.data.errors;
        this.validateErrors = errors;

        window._.each(this.validateErrors, (value, key) => {
          let msg = value[0].formatUnicorn({ attribute: window.i18n.t('notice.' + key).toLowerCase(), values: ".jpg, .jpeg, .png, .pneg" });
          this.$validator.errors.add({ field: key, msg: msg });
        });
      });
    },

    getRequest() {
      const params = this.getParams();
      if (this.record && this.record.id) {
        params.append('id', this.record.id);
        return rf.getRequest('AdminRequest').updateNotice(params);
      }
      return rf.getRequest('AdminRequest').createNotice(params);
    },

    validate() {
      this.errors.clear();

      this.validateRequired(this.record.title, 'title');
      this.validateRequired(this.record.support_url, 'support_url');

      // update notice
      if (this.record.banner_url) {
        if (this.$refs.file.files[0]) {
          this.validateImage(this.$refs.file.files[0], 'banner_url');
        }
      }
      else { // create notice
        this.validateImage(this.$refs.file.files[0], 'banner_url');
      }

      this.validateDate(moment(this.started_at).format('x'), moment(this.ended_at).format('x'), 'end_at');

      if (this.errors.count() != 0) {
        return false;
      }

      return true;
    },

    validateRequired(value, field) {
      if (_.isEmpty(value ? value.trim() : "")) {
        this.errors.add({ field: field, msg: window.i18n.t('notice.errors.' + field + '.required') });
      }
    },

    validateImage(value, field) {
      if (typeof (value) == "undefined") {
        this.errors.add({ field: field, msg: window.i18n.t('notice.errors.' + field + '.required') });
      }
      else if (value.size > IMAGE_FILE_UPLOAD_SIZE) {
        this.errors.add({ field: field, msg: window.i18n.t('notice.errors.maxsize') });
      }
    },

    validateDate(start_at, end_at, field) {
      if (start_at > end_at) {
        this.errors.add({ field: field, msg: window.i18n.t('notice.errors.date') });
      }
    },

    cancel() {
      this.$router.push({
        name: 'Notice'
      });
    },

    previewImage: function (event, ref) {
      this.errors.remove('banner_url');
      var input = event.target;
      if (input.files && input.files[0]) {
        const file = input.files[0];
        const fileType = file['type'];
        const validImageTypes = ['image/jpg', 'image/jpeg', 'image/png'];
        if (input.files && validImageTypes.includes(fileType)) {
          var reader = new FileReader();
          reader.onload = (e) => {
            ref.src = e.target.result;
          }
          reader.readAsDataURL(input.files[0]);
        } else {
          this.errors.add({ field: 'banner_url', msg: window.i18n.t('notice.validate.image_type') });
          ref.src.replace('/thumbs', '');
          input.value = '';
        }
      } else {
        this.errors.add({ field: 'banner_url', msg: window.i18n.t('notice.validate.image_type') });
        ref.src.replace('/thumbs', '');
        input.value = '';
      }
    },

    showSuccess(message) {
      window.Message.success(message);
    },

    onSelectDropdown(value) {
      this.record.type = value.name;
      this.clickOutsideDropdown();
    },
    showDropdown() {
      this.isShowDropdown = !this.isShowDropdown;
    },
    clickOutsideDropdown() {
      this.isShowDropdown = false;
    },
    createNewType() {
      this.showModalConfirm("newType", this.addNewType, { type: this.newType })
    },
    showModalConfirm(modalName, callback, params) {
      CommonModal.show(modalName, {
        position: 'center',
        mask: true,
        buttons: [
          {
            label: window.i18n.t('common.action.cancel'),
            class: "btn-no",
            style: {
              background: 'rgb(191, 191, 191);'
            },
            callback: () => {
              CommonModal.hide(modalName);
            }
          },
          {
            label: window.i18n.t('common.action.create'),
            focused: true,
            class: "btn-yes",
            style: {
              background: 'rgb(0, 112, 192)'
            },
            callback: () => {
              CommonModal.hide(modalName);
              this.loadingUploadBegin();
              callback(params);
              this.loadingUploadFinish();
            }
          }
        ]
      });
    },
    addNewType() {
      // code request api 
    },
    loadingUploadBegin() {
      this.enableClose = false;
      this.isLoading = true;
      CommonModal.show('loadingUpload');
    },
    loadingUploadFinish() {
      CommonModal.hide('loadingUpload');
    },
    openPreview() {
      this.isPreview = true;
    },
    closePreview() {
      this.isPreview = false;
    }
  },

  mounted() {
    this.$emit('EVENT_PAGE_CHANGE', this);
    if (!this.noticeId) {
      return;
    }
    rf.getRequest('AdminRequest').getEditNotice(this.noticeId).then(res => {
      this.record = res.data || {};
      this.started_at = moment(this.record.started_at, 'x').toDate();
      this.ended_at = moment(this.record.ended_at, 'x').toDate();
    });
  }
}

String.prototype.formatUnicorn = String.prototype.formatUnicorn ||
  function () {
    "use strict";
    var str = this.toString();
    if (arguments.length) {
      var t = typeof arguments[0];
      var key;
      var args = ("string" === t || "number" === t) ?
        Array.prototype.slice.call(arguments)
        : arguments[0];

      for (key in args) {
        str = str.replace(new RegExp("\\{" + key + "\\}", "gi"), args[key]);
      }
    }
    return str;
  };
</script>

<style lang="scss">
@import "@/assets/sass/variables";

#mail-edit {
  .sc_search_select {
    .group_search_select {
      .button_drop_search {
        height: 35px;
        padding: 8px 10px 8px 10px;
        border-radius: 10px;
        font-size: $font-small;
        font-family: $font-family-roboto;
        background-color: $color-white;
        width: 580px;

        span {
          font-family: $mulish-regular;
          font-size: 14px;
        }

        i.icon-arrow1 {
          margin-left: 5px;
          color: $color_dove_gray;
        }

        &:hover {
          border-color: $color-jungle-green;

          i.icon-arrow1 {
            color: $color-jungle-green;
          }
        }
      }

      .box_list_search_select {
        .list_search_select {
          border-radius: 10px;

          li {
            font-family: $font-family-roboto;
            font-size: $font-small;
            padding: 6px 12px;

            &:hover,
            &:active,
            &:focus {
              color: $color-jungle-green;
            }
          }
        }
      }
    }
  }

  .btn-no {
    background-color: $color-bright-gray;
    border-color: $color-bright-gray;
    color: $color-black;

    &:hover {
      opacity: 0.7;
    }
  }

  .modal {
    input {
      width: 300px;
      padding: 13px 12px;
      outline: none;
      border: 1px solid $color-alto;
      height: 40px;
      line-height: 35px;
      background-color: $color-white;
      border-radius: 3px;
      font-family: $mulish-regular;
      border-radius: 10px;

      &:hover,
      &:active,
      &:focus {
        border-color: $color-jungle-green;
      }
    }
  }


}
</style>

<style lang="scss" scoped>
@import "@/assets/sass/variables";

.is-error {
  display: inline-block;
  line-height: 20px;
  padding-top: 5px;
}

.box_datetime_a {
  width: 580px;
  max-width: 100%
}

.is-error {
  color: #ff5252 !important;
}

.input-error {
  border: 1px solid #ff5252 !important;
}

.f-left {
  float: left;
}

.required {
  color: #f00;
}

.error-box {
  display: block;
  float: left;
  overflow: hidden;
  width: 100%;

  span {
    font-family: $font-family-roboto-medium !important;
  }
}

.page960 {
  width: 1129px;
  min-width: 1129px;
}

.f-left {
  float: left;
}

button[disabled] {
  opacity: 0.4 !important;
}

#mail-edit {

  .mail-preview {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #00000046;

    .mail-container {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;

      .btn-close {
        position: absolute;
        top: 30px;
        right: 30px;
        background: none;
        border: none;

        .icon-close {
          color: $color-white;
        }
      }

      .content {
        background-color: $color-white;
        padding: 50px 100px;
      }
    }
  }
}

.form-notice-detail-container {
  padding: 0px 40px 0 0 !important;

  .notice-detail-country {
    min-height: 30px;

    .notice-detail-form-label {
      margin-right: 22px;
      width: 130px;
      text-align: right;
      font-size: 14px;
      color: black;
      float: left;
      margin-bottom: 0px;
      margin-top: 10px;
    }
  }

  .filed-verification {
    .filed {
      .tip {
        font-size: 12px;
        color: #666;
        padding: 20px 0;
      }
    }
  }

  .notice-detail-form-group {
    height: auto;
    float: left;
    min-width: 500px;

    &.notice-image {
      height: auto;
      margin-top: 15px;

      span {
        color: $color_dove_gray;
        font-family: $font-family-roboto;
        font-weight: normal;
      }
    }

    .notice-detail-form-label {
      width: 100%;
      text-align: left;
      font-size: 14px;
      color: $color_dove_gray;
      float: left;
      font-weight: normal;
      font-family: $mulish-regular;
      margin-bottom: 0px;
      margin-top: 10px;
      margin-left: 5px;

      &.label-inline {
        display: inline;
        width: auto;
        margin-top: 24px;
        margin-right: 16px;
      }
    }

    .switch-container {
      margin-top: 22px;
    }

    .upload-file {
      display: inline-block;
      margin-left: 10px;
    }

    .col-md-6 {
      input {
        width: 275px;
      }
    }

    .code-container {
      position: relative;
      display: flex;
      gap: 36px;

      .preview {
        width: 580px;
        max-height: 300px;
        background-color: #ffffff;
        border-radius: 10px;

        .content-preview {
          height: 0;
          transform: scale(0.4);
        }
      }

      .btn-zoom {
        position: absolute;
        top: 20px;
        right: 20px;
        width: 24px;
        height: 24px;
        border-radius: 24px;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
          background-color: $color-white;
          opacity: 0.8;
        }
      }
    }

    textarea {
      width: 580px;
      min-height: 300px;
      padding: 13px 12px;
      outline: none;
      border: 1px solid $color-alto;
      background-color: $color-white;
      border-radius: 3px;
      font-family: $mulish-regular;
      border-radius: 10px;

      &:hover,
      &:active,
      &:focus {
        border-color: $color-jungle-green;
      }
    }

    input {
      width: 580px;
      padding: 13px 12px;
      outline: none;
      border: 1px solid $color-alto;
      height: 35px;
      line-height: 35px;
      background-color: $color-white;
      border-radius: 3px;
      font-family: $mulish-regular;
      border-radius: 10px;

      &:hover,
      &:active,
      &:focus {
        border-color: $color-jungle-green;
      }
    }

    .preView {
      width: 580px;
      height: 360px;
      background-color: #d8d8d8;
      position: relative;
      float: left;
      margin-top: 10px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .file-btn {
      background-color: $color-white-smoke;
      color: $color-jungle-green;
      width: 90px;
      height: 23px;
      text-align: center;
      position: relative;
      padding: 0 10px;
      display: inline-block;
      cursor: pointer;
      font-size: $font_mini_mini;
      border: 1px solid $color-caribbean-green;
      text-transform: uppercase;
      border-radius: 3px;
      font-weight: 600;
      line-height: 23px;
      font-family: $font-family-roboto;

      &:hover {
        background-color: $color-aquamarine;
        border: 1px solid $color-aquamarine;
        color: $color-white;
      }
    }

    input[type=file] {
      position: absolute;
      left: 0;
      top: 0;
      cursor: pointer;
      height: 100%;
      width: 100%;
      opacity: 0;
    }
  }

  .submit {
    padding: 30px 0 30px 0;
    text-align: left;

    .file-btn {
      background-color: $color-caribbean-green;
      color: $color-white;
      height: 35px;
      position: relative;
      padding: 0px 20px;
      display: inline-block;
      cursor: pointer;
      font-size: $font-smaller;
      border: 1px solid $color-caribbean-green;
      text-align: center;
      text-transform: uppercase;
      font-weight: 600;
      border-radius: 10px;

      &:hover {
        background-color: $color-aquamarine;
        border-color: $color-aquamarine;
      }
    }

    .btn-cancel {
      background-color: $color-bright-gray;
      color: $color-black;
      height: 35px;
      position: relative;
      padding: 0px 20px;
      display: inline-block;
      cursor: pointer;
      font-size: $font-smaller;
      border: 1px solid $color-bright-gray;
      text-align: center;
      text-transform: uppercase;
      font-weight: 600;
      border-radius: 10px;
      margin-right: 10px;

      &:hover {
        opacity: 0.7;
      }
    }

    button {
      position: absolute;
      left: 0;
      top: 0;
      cursor: pointer;
      height: 32px;
      width: 98px;
      font-family: $font-family-roboto;
    }
  }

  .input-dropdown {
    position: relative;

    input {
      background: $color-white;
      border: 1px solid #C7CBD3 !important;
      border-radius: 10px !important;
      width: 580px;
      height: 35px;
      cursor: pointer;
    }

    .icon-coin-input {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
      color: #667186;

      i {
        font-size: 22px;
      }
    }

    .coin-option {
      position: absolute;
      top: 37px;
      width: 100%;
      background: #FFFFFF;
      box-shadow: 0px 4px 15px rgba(0, 19, 54, 0.15);
      border-radius: 10px;
      padding: 17px 0;
      z-index: 3;

      &:after {
        bottom: 100%;
        left: 50%;
        border: solid transparent;
        content: " ";
        display: block;
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: rgba(136, 183, 213, 0);
        border-bottom-color: $color-white;
        border-width: 5px;
        margin-left: -5px;
      }

      .option {
        display: flex;
        align-items: center;
        width: 100%;
        line-height: normal;
        cursor: pointer;
        padding-left: 14px;
        font-size: 14px;
        font-weight: 500;
        overflow: hidden;
        color: #001336;
        height: 32px;

        &:hover {
          background: $color-athens;
        }

        &.create-new {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 16px;
          border: 1px dashed $color-filled-status;
          border-radius: 4px;
          margin: 0 16px;
          width: calc(100% - 32px);
        }
      }
    }
  }
}
</style>