<template>
  <div id="update_instruments" class="reward_center mg_bot50">
    <div class="header header-action">
      <div class="tab-container">
        <div class="tab-element" @click="changeTab('instruments')" v-bind:class="{'active' : selectedTab === 'instruments' }">
          {{$t('order.open_order.market')}}
        </div>
        <div class="tab-element" @click="changeTab('tier')" v-bind:class="{'active' : selectedTab === 'tier' }">
          {{$t('instruments.trading_tier')}}
        </div>
      </div>
  </div>
    <div class="contain">
      <div v-if="selectedTab === 'instruments'">
        <CreateInstruments ref="instruments"  :contract="contractType" :isEdit="isEdit" :instruments="dataTable.instrument" :key="syncData" @input="dataChange($event)" :changeInstrument="dataChangeInstrument" :changeTrading="dataChangeTrading" :updateError="dataError" >

        </CreateInstruments>
      </div>
      <div v-else>
        <CreateNewTier ref="tier" :isEdit="isEdit" :key="syncData" :instrumentTier="dataTable.tradingTier" :changeLeverage="dataChangeLeverage" :updateError="dataError" ></CreateNewTier>
      </div>
    </div>
    <button class="submit-instruments" @click="updateInstruments()"> {{ $t('user.save') }} </button>
  </div>
</template>

<script>
import InputOnlyNumber from "@/common/InputOnlyNumber.vue";
import InputTextAndNumber from "@/components/InputTextAndNumber.vue";
import Modal from "@/components/Modal.vue";
import DatePicker from "vuejs-datepicker";
import SelectBox from "@/components/SelectBox.vue";
import DataTable4 from "@/components/DataTable4.vue";
import CoinInput from "@/common/CoinInput.vue";
import datetime from "vuejs-datetimepicker";
import NumberInput from "../../common/NumberInput";
import rf from "@/lib/RequestFactory";
import moment from "moment/moment";
import dataTable from "@/components/DataTable.vue";
import InstrumentsFuture from "@/pages/instruments_futures/InstrumentsFuture.vue";
import CreateInstruments from "@/pages/instruments_futures/CreateInstruments.vue";
import CreateNewTier from "@/pages/instruments_futures/CreateNewTier.vue";
import {params} from "superagent/lib/utils";

export default {
  components: {
    CreateInstruments,
    InstrumentsFuture,
    InputOnlyNumber,
    InputTextAndNumber,
    Modal,
    DatePicker,
    SelectBox,
    DataTable4,
    CoinInput,
    CreateNewTier,
    datetime,
  },
  data() {
    return {
      titlePage: this.$t('menu.futures.market'),
      searchKey: '',
      dataTable: [],
      totalVoucer: 0,
      selectedTab: 'instruments',
      isEdit: true,
      syncData: false,
      symbol: '',
      contractType: this.$route.params.contractType,
      isError: false,
      leverageMargin: [{}],
      leverageMarginDeleted: [],
      tradingRules: {
        minPrice: "",
        limitOrderPrice: "",
        floorRatio: 0,
        minOrderAmount : "",
        maxOrderAmount : "",
        minNotional: "",
        maxNotinal: "",
        liqClearanceFee: "",
        maxLeverage: 0,
      },
      instrument: {
        symbol: "",
        rootSymbol: "",
        underlyingSymbol: "",
        makerFee: "",
        takerFee: "",
        tickSize: "",
        multiplier: '',
        maxPrice: "",
        minPriceMovement: "",
        maxFiguresForSize: "",
        maxFiguresForPrice: "",
        impactMarginNotional: ""
      },
    }
  },
  watch: {
    'selectedTab': {
      handler: function (newVal, oldVal) {
        if (oldVal === "instruments") {
          this.dataTable.instrument = {...this.dataTable.instrument, ...this.instrument, ...this.tradingRules}
        } else {
          this.dataTable.tradingTier = this.leverageMargin
        } 
      },
    }
  },
  methods: {
    initData() {
      const params = {
        underlyingSymbol: this.$route.query.id
      }
      rf.getRequest('AdminRequest').
      getDetailInstrument(params).then(res => {
        this.syncData = true
        this.dataTable = res.data
        this.leverageMargin = res.data.tradingTier
      })
    },
    dataChange(e) {
      this.symbol = e
    },
    dataError(value) {
      this.isError = value
    },
    async changeTab(tab) {
      if (tab === 'tier') {
        if (!await this.$refs.instruments.validateInstrument()) this.selectedTab = tab
      } else {
        if (!await this.$refs.tier.validateTradingTier()) this.selectedTab = tab;
      }
    },
    dataChangeInstrument(value) {
      this.instrument = value
    },
    dataChangeTrading(value) {
      this.tradingRules = value
    },
    dataChangeLeverage(value, deletedValue) {
      this.leverageMargin = value
      this.leverageMarginDeleted = deletedValue
    },
    async updateInstruments() {
      // const data = {
      //   symbol: this.symbol,
      //   id: this.$route.query.unique
      // }
      if (
        (!await this.$refs.instruments?.validateInstrument() && this.selectedTab === 'instruments')
        || (!await this.$refs.tier?.validateTradingTier() && this.selectedTab === 'tier')
      ) 
        {
          rf.getRequest('AdminRequest').updateInstrument({
            id: this.$route.query.unique,
            instrument: this.instrument,
            tradingRules: this.tradingRules,
            leverageMargin: [
              ...this.leverageMargin,
              ...this.leverageMarginDeleted
            ]
          }).then(res => {
            this.$toastr('success', this.$t('user.succes'));
            this.$router.push({name: 'Instruments Futures'});
          })
        }
    }

  },
  mounted() {
    this.$emit('EVENT_PAGE_CHANGE', this);
    this.initData()
  },
  created() {
    if(!this.$route.query.id) {
      this.$router.push({
        name: 'Instruments Futures',
      });
    }
  }
}

</script>

<style scoped lang="scss">
@import "@/assets/sass/variables";

#update_instruments {
  .header {
    display: flex;
    justify-content: space-between;
    margin: 24px 0;
    align-items: center;

    .header-add-reward {
      .btn-add-new {
        //border: 1px solid $color-caribbean-green;
        display: flex;
        align-items: center;
        gap: 10px;
        width: 205px;
        max-width: 100%;
        font-size: $font_semi_big;
        color: $color-grey-dark;
        background-color: $color-caribbean-green;
        padding: 10.5px 22px;
        border-radius: 30px;
        font-family: $mulish-bold;
        height: 41px;

        span {
          margin-right: 5px;
        }
      }
    }


    .tab-container {
      height: 70px;
      .tab-element {
        line-height: 13px;
        height: 16px;
        padding: unset !important;
        font-size: 20px;
        font-weight: 400;
        //color: $color-auro-metal !important;
        border: unset !important;
        background: unset !important;
        cursor: pointer;
        position: relative;
        z-index : 1;
        background-color: var(--background-color-primary);
        margin-right: 40px;
        height: 37px;
        &.active {
          color: var(--text-primary-color) !important;
          background-color: unset !important;
          &::before {
            content: "";
            position: absolute;
            left: 0;
            bottom: 0;
            border-bottom: 4px solid $color-caribbean-green;
            top: 16px;
            width: -webkit-fill-available;
            border-radius: 4px;
          }
        }
      }
    }


    .header-search {
      gap: 10px;
      width: 300px;
      height: 32px;
      font-family: $mulish-medium;
      position: relative;

      .form-control {
        width: 100%;
        padding: 7px 35px;
        border-radius: 10px !important;
      }

      .icon-search {
        position: absolute;
        top: 25%;
        margin-left: 10px;
      }
    }


  }

  .d-flex {
    display: flex;
    gap: 16px;
  }
  :deep(.tableContainer) {
    table {
      border-collapse: collapse;
      border-radius: 10px;
      overflow: hidden;

      thead {
        tr > th {
          font-size: $font-root;
          font-family: $mulish-medium;
          color: $dark-3;
        }
      }

      tr {
        td > div > span {
          font-family: $mulish-medium;
          font-size: $font-root;
          line-height: 25px;
          color: $dark-1;
        }
      }
    }
    .pagination {
      display:  flex;
      width: 100%;
      align-items: center;
      #total_user {
        width: 8% ;
        padding-bottom: 5px;
        margin-right: 5px;
        font-family: $mulish-medium;
        line-height: 25px;
        color: $dark-1;
      }
      .text-center {
        width: 100%;
        .VuePagination {
          width: 100%;
          display: flex;
          ul {
            justify-content: end;
          }
        }
      }
    }
  }
  :deep(.group_search_select) {
    max-width: 100%;
    display: flex;
    align-items: center;
    .button_drop_search {
      width: 100%;
      padding: 1px 7px;
      border-radius: 5px !important;
      border: 1px solid #C7CBD3 ;
      display: flex;
      align-items: center;
      gap: 7px;
      span {
        font-family: $mulish-medium;
        font-size: $font-root;
        line-height: 20px;
        color: $dark-1 !important;
      }
    }
    .box_list_search_select {
      .list_search_select {
        border-radius: 12px;
        > li {
          padding: 10.5px 16px;
          > span {
            font-family: $mulish-medium;
            font-size: 16px;
            line-height: 20px;
          }
        }
        > li:hover {
          background: $color-athens;
        }
      }
    }
  }
  :deep(.VuePagination__pagination){
    .page-link {
      background: #ffffff;
      border-radius: 5px !important;
      font-size: 14px;
      color: $dark-1;
      border: 1px solid #C7CBD3;
    }
    & .active > a {
      border: 1px solid #3A7DFF;
      color: #3A7DFF;
    }
  }
  .submit-instruments {
    margin-top: 20px;
    padding: 10.5px 30px;
    width: 125px;
    height: 41px;
    background-color: $color-caribbean-green;
    color: $dark-1;
    border: none;
    border-radius: 30px;
    font-size: $font-semi_big;
    color: $color-grey-dark;
    font-family: $mulish-bold;
  }
}


</style>