<template>
  <div id="reward_center" class="reward_center mg_bot50">
    <div class="header header-action">
      <div class="header-add-reward">
        <button class="btn btn-add-new" @click="createReward" :disabled="totalVoucer >= 2 ">
          <img class="" src="@/assets/images/icon/plus.svg">
          {{ $t('reward_center.create') }}
        </button>
      </div>
      <div class="header-search">
        <input
          title
          type="text"
          :placeholder="$t('common.search')"
          @keyup.enter="search"
          class="form-control"
          name="searchKey"
          maxlength="30"
          v-model="searchKey"
        >
        <img class="icon-search" src="@/assets/images/icon/search.svg">

      </div>
    </div>
    <div class="datatable">
      <data-table :get-data="getData" ref="datatable"
                  :total-user="totalVoucer"
                  :in-line="true" :limit="10"
                  :msgEmptyData="$t('common.datatable.no_data2')"
                  :column="8" class="scroll">
        <!--        //:getData="" :totalUser="" ref="datatable" :limit="10" :column="8"-->
        <!--        @DataTable:finish=""-->
        <th class="text-left">{{ $t('reward_center.no') }}</th>
        <th class="text-left" >{{ $t('reward_center.voucher.name') }}</th>
        <th class="text-left">{{ $t('reward_center.beneficiary') }}</th>
        <th class="text-left" >{{ $t('reward_center.currency') }}</th>
        <th class="text-left" >{{ $t('reward_center.trading_volume') }}</th>
        <th class="text-left" >{{ $t('reward_center.reward') }}</th>
        <th class="text-left"  width="5%" >{{ $t('reward_center.expired_time') }}</th>
        <th class="text-right" ></th>
        <template slot="body" slot-scope="props">
          <tr v-bind:class="{inactive: props.item.status == 'inactive'}">
            <td class="text-left">{{(( dataTable.current_page - 1) * dataTable.total) + ((props.index + 1) )}}</td>
            <td class="text-left">
              <div class="item_email_user">
                <span class="txt_email_user">{{ props.item.name }}</span>
                <!--                <span class="tooltip_email_user">{{ props.item.name }}</span>-->
              </div>
            </td>
            <td class="text-left">
              <span>{{ props.item.type | ConvertWallet }}</span>
            </td>
            <td class="text-left">
              <span>{{ props.item.currency | uppercase }}</span>
            </td>
            <td class="text-left">
              <span>{{ props.item.conditions_use | convertToBigNumber }}</span>
            </td>
            <td class="text-left">
              <span>{{ props.item.amount  | convertToBigNumber }}</span>
            </td>
            <td class="text-center">
              <span>{{ props.item.expires_date_number | convertToBigNumber }}</span>
            </td>
            <td class="text-right d-flex">
              <span @click.prevent="confirmRemoveReward(props.item)">
                <img class="" src="@/assets/images/icon/remove-item.svg">
              </span>
              <span type="button" @click="updateReward(props.item)"
              >
                <img class="" src="@/assets/images/icon/edit-item.svg">
              </span>

            </td>
          </tr>
        </template>
      </data-table>
    </div>
  </div>

</template>

<script>
import InputOnlyNumber from "@/common/InputOnlyNumber.vue";
import InputTextAndNumber from "@/components/InputTextAndNumber.vue";
import Modal from "@/components/Modal.vue";
import DatePicker from "vuejs-datepicker";
import SelectBox from "@/components/SelectBox.vue";
import DataTable4 from "@/components/DataTable4.vue";
import CoinInput from "@/common/CoinInput.vue";
import datetime from "vuejs-datetimepicker";
import NumberInput from "../../common/NumberInput";
import rf from "@/lib/RequestFactory";
import moment from "moment/moment";
import dataTable from "@/components/DataTable.vue";

export default {
  components: {
    InputOnlyNumber,
    InputTextAndNumber,
    Modal,
    DatePicker,
    SelectBox,
    DataTable4,
    CoinInput,
    datetime,
  },
  data() {
    return {
      titlePage: this.$t('reward_center.title_page'),
      searchKey: '',
      dataTable: [],
      totalVoucer: 0,
    }
  },
  methods: {
    getData(params) {
      const paramsSearch = {
        "keyword": this.searchKey.trim(),
      }
      return rf.getRequest('RewardCenterRequest').getRewardCenter(Object.assign(paramsSearch, params)).then(res => {
        this.totalVoucer = res.data.total
        this.dataTable = res.data
        return res
      })
    },
    search() {
      this.getData()
      this.$refs.datatable.refresh();
    },
    createReward() {
      this.$router.push({name: 'Reward Center Resource'});
    },
    removeDevice(value) {
      rf.getRequest('RewardCenterRequest').deleteReward(value.id).then((res) => {
        this.$toastr('success', this.$t('address.deleted_success'));
        this.$refs.datatable.refresh();
      });
    },
    confirmRemoveReward(value) {
      window.ConfirmationModal.show({
        title: window.i18n.t('reward.remove_reward_center'),
        type: 'reward',
        class: 'reward-center',
        // content: window.i18n.t('reward.remove_reward_center'),
        btnConfirmLabel: window.i18n.t('common.action.yes'),
        btnCancelLabel: window.i18n.t('common.action.no'),
        onConfirm: () => {
          this.removeDevice(value);
        }
      });
    },
    updateReward(value) {
      this.$router.push({
        path: '/reward-center-resource',
        query: {id: value.id},
      });
    },
  },
  mounted() {
    this.$emit('EVENT_PAGE_CHANGE', this);
  },
  created() {
    this.getData()
  }
}

</script>

<style scoped lang="scss">
@import "@/assets/sass/variables";

#reward_center {
  .header {
    display: flex;
    justify-content: space-between;
    margin: 24px 0;
    align-items: center;

    .header-add-reward {
      .btn-add-new {
        //border: 1px solid $color-caribbean-green;
        display: flex;
        align-items: center;
        gap: 10px;
        width: 205px;
        max-width: 100%;
        font-size: $font_semi_big;
        color: $color-grey-dark;
        background-color: $color-caribbean-green;
        padding: 10.5px 22px;
        border-radius: 30px;
        font-family: $mulish-bold;
        height: 41px;

        span {
          margin-right: 5px;
        }
      }
    }

    .header-search {
      gap: 10px;
      width: 300px;
      height: 32px;
      font-family: $mulish-medium;
      position: relative;

      .form-control {
        width: 100%;
        padding: 7px 35px;
        border-radius: 10px !important;
      }

      .icon-search {
        position: absolute;
        top: 25%;
        margin-left: 10px;
      }
    }


  }

  .d-flex {
    display: flex;
    gap: 16px;
  }
  :deep(.tableContainer) {
    table {
      border-collapse: collapse;
      border-radius: 10px;
      overflow: hidden;

      thead {
        tr > th {
          font-size: $font-root;
          font-family: $mulish-medium;
          color: $dark-3;
        }
      }

      tr {
        td > div > span {
          font-family: $mulish-medium;
          font-size: $font-root;
          line-height: 25px;
          color: $dark-1;
        }
      }
    }
    .pagination {
      display:  flex;
      width: 100%;
      align-items: center;
      #total_user {
        width: 8% ;
        padding-bottom: 5px;
        margin-right: 5px;
        font-family: $mulish-medium;
        line-height: 25px;
        color: $dark-1;
      }
      .text-center {
        width: 100%;
        .VuePagination {
          width: 100%;
          display: flex;
          ul {
            justify-content: end;
          }
        }
      }
    }
  }
  :deep(.group_search_select) {
    max-width: 100%;
    display: flex;
    align-items: center;
    .button_drop_search {
      width: 100%;
      padding: 1px 7px;
      border-radius: 5px !important;
      border: 1px solid #C7CBD3 ;
      display: flex;
      align-items: center;
      gap: 7px;
      span {
        font-family: $mulish-medium;
        font-size: $font-root;
        line-height: 20px;
        color: $dark-1 !important;
      }
    }
    .box_list_search_select {
      .list_search_select {
        border-radius: 12px;
        > li {
          padding: 10.5px 16px;
          > span {
            font-family: $mulish-medium;
            font-size: 16px;
            line-height: 20px;
          }
        }
        > li:hover {
          background: $color-athens;
        }
      }
    }
  }
  :deep(.VuePagination__pagination){
    .page-link {
      background: #ffffff;
      border-radius: 5px !important;
      font-size: 14px;
      color: $dark-1;
      border: 1px solid #C7CBD3;
    }
    & .active > a {
      border: 1px solid #3A7DFF;
      color: #3A7DFF;
    }
  }
}


</style>