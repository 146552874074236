<template>
  <div class="open-order_future boxCore" id="order_future">
    <div class="d-flex w-100">
      <div class="filter-template">
        <div class="label-text">{{ $t("funds.balances.from") }}</div>
        <date-picker
          v-model="startDate"
          class="date-picker-input"
          :format="customFormatter"
        ></date-picker>
      </div>
      <div class="filter-template">
        <div class="label-text">{{ $t("funds.history.to") }}</div>
        <date-picker
          v-model="endDate"
          class="date-picker-input"
          :format="customFormatter"
        ></date-picker>
      </div>
      <div class="filter-template">
        <div class="label-text text-pair date-text">
          {{ $t("orders.open_order.symbol") }}
        </div>
        <div class="toolbar-element2">
          <select-box
            v-model="filters.symbol"
            :placeholder="$t('order.order_history.all')"
            :options="SymbolData"
          />
        </div>
      </div>
      <div class="filter-template type">
        <div class="label-text date-text">
          {{ $t("orders.open_order.type") }}
        </div>
        <div class="toolbar-element2">
          <select-box
            v-model="filters.type"
            :placeholder="$t('order.order_history.all')"
            :options="TypesData"
          />
        </div>
      </div>
      <div class="filter-template status">
        <div class="label-text date-text">
          {{ $t("orders.open_order.status") }}
        </div>
        <div class="toolbar-element2">
          <select-box
            v-model="filters.status"
            :placeholder="$t('order.order_history.all')"
            :options="StatusData"
          />
        </div>
      </div>
      <div class="filter-template">
        <div class="label-text">&nbsp;</div>
        <input
          title
          type="text"
          :placeholder="$t('account.detail.affiliate.inputplace.search')"
          @keyup.enter="search"
          class="form-control search_symbol"
          name="searchKey"
          v-model="filters.searchKey"
        />
      </div>
      <div class="filter-template">
        <div class="label-text">&nbsp;</div>
        <button class="btn btn-reset btn-search" @click="search()">
          {{ $t("orders.open_order.search") }}
        </button>
        <button class="btn btn-reset" @click="refresh()">
          {{ $t("orders.open_order.reset") }}
        </button>
        <button
          v-if="!accountId"
          class="btn btn-download"
          @click="downloadExcel()"
        >
          <img src="@/assets/images/download-icon.svg" width="12" />
          {{ $t("orders.open_order.data") }}
        </button>
      </div>
    </div>

    <div class="table-futures">
      <data-table-futures
        :getData="getOrderFutureRecords"
        ref="datatable"
        :column="12"
        :limit="10"
        :inLine="true"
        class="scroll"
        :total-user="totalRecords"
      >
        <th class="text-left">{{ $t("order.open_order.id_order") }}</th>
        <th v-if="!accountId" class="text-left">
          {{ $t("order.open_order.id_account") }}
        </th>
        <th class="text-left" data-sort-field="symbol">
          {{ $t("order.open_order.symbol") }}
        </th>
        <th class="text-left" data-sort-field="side">
          {{ $t("order.open_order.side") }}
        </th>
        <th class="text-left">{{ $t("order.open_order.type") }}</th>
        <th class="text-left" data-sort-field="filled">
          {{ $t("order.open_order.filled")}}/{{$t("order.open_order.quantity") }}
        </th>
        <th class="text-left" data-sort-field="stop price">
          {{ $t("order.open_order.trigger") }}
        </th>
        <th class="text-left" data-sort-field="price">
          {{ $t("order.open_order.price") }}
        </th>
        <th class="text-left" data-sort-field="time">
          {{ $t("cms.announcement.created_time") }}
        </th>
        <th class="text-center" data-sort-field="status">
          {{ $t("funds.history.status") }}
        </th>
        <th class="text-left">{{ $t("order.open_order.action") }}</th>

        <template slot="body" slot-scope="props">
          <tr>
            <td class="text-left">
              {{ props.item.id }}
            </td>
            <td v-if="!accountId" class="text-left">
              <span class="text-account" @click="detailAccount(props.item)">{{
                props.item.userUid
              }}</span>
            </td>
            <td class="text-left">
              <div class="text-top">{{ props.item.symbol }}</div>
              <!-- <div class="text-bottom"> {{ props.item.marginMode }} . {{ Number(props.item.leverage) }}x</div> -->
            </td>
            <td class="text-left">
              <div class="buy" v-if="props.item.side === 'BUY'">
                {{ props.item.side | firstLetterCapitalize }}
              </div>
              <div class="sell" v-else>
                {{ props.item.side | firstLetterCapitalize }}
              </div>
            </td>
            <td class="text-left">
              {{ { type: props.item.customType.toLowerCase() } | order_type }}
            </td>
            <td class="text-left">
              {{
                (props.item.quantity - props.item.remaining)
                  | formatCurrencyAmountDecimal(8, "0")
              }}
              <br />
              {{ props.item.quantity | formatCurrencyAmountDecimal(8, "0") }}
            </td>
            <td class="text-left">
              <!-- {{ props.item.lockPrice | to2Precision }} -->
              <div v-if="props.item.tpSLPrice">
                {{ props.item.tpSLPrice | formatCurrencyAmountDecimal(8, "0") }}
              </div>
              <div v-else>--</div>
            </td>
            <!-- <td class="text-left">
              {{ props.item.leverage | to2Precision }}x
            </td> -->
            <td class="text-left">
              {{ props.item.price | formatCurrencyAmountDecimal(8, "0") }}
            </td>
            <td class="text-left">
              <div class="text-top">
                {{ props.item.createdAt | dateFormat2("YYYY-MM-dd") }}
              </div>
              <div class="text-bottom">
                {{ props.item.createdAt | timeFormat2("HH:mm:ss") }}
              </div>
            </td>
            <td class="text-left">
              <div
                class="bt pending"
                v-if="props.item.customStatus === 'PENDING'"
              >
                Pending
              </div>
              <div
                class="bt partial_filled"
                v-else-if="props.item.customStatus === 'Partial_Filled'"
              >
                Partial Filled
              </div>
              <div class="bt open" v-else>Open</div>
            </td>
            <td class="text-left">
              <span class="img-all">
                <img
                  @click="cancelOrder(props.item)"
                  class="imge"
                  src="/images/icon/cancel-gi.svg"
                  width="18px"
                  height="18px"
                />
                <img
                  @click="handleOpen(props.item)"
                  class="imge"
                  src="@/assets/images/future/List.svg"
                  width="17px"
                  height="17px"
                />
              </span>
            </td>
          </tr>
        </template>
      </data-table-futures>
    </div>

    <div class="clearfix clearfix-40"></div>
    <right-slide
      :dataDetail="dataDetail"
      :rightSlide="rightSlide"
      :onClose="handleClose"
    />
    <modal class="cancelOrder_modal" name="cancelOrder" :title="$t('modal.confirm')">
      <div slot="body" class="body-popup">
        {{ $t("orders.open_order.cancel_modal_content") }}
      </div>
    </modal>
    <modal class="kyc_modal" :enableClose="enableClose" name="loadingUpload">
      <div slot="body">
        <vue-loading
          type="bars"
          v-if="isLoading"
          color="#2DAC91"
          :size="{ width: '60px', height: '80px' }"
        >
        </vue-loading>
      </div>
    </modal>
  </div>
</template>

<script>
import moment from "moment";
import COMMON_CONST from "../../common/Const";
import rf from "../../lib/RequestFactory";
import { mapActions } from "vuex";
import DatePicker from "vuejs-datepicker";
import Modal from "../../components/Modal";
import SelectBox from "../../components/SelectBox";
import RightSlide from "../../components/RightSlide";
import BigNumber from "bignumber.js";
import { Buffer } from "buffer";

export default {
  name: "OrderFuturesRecords",
  components: {
    Modal,
    DatePicker,
    SelectBox,
    RightSlide,
  },
  props: {
    accountId: {
      type: String,
    },
  },
  data() {
    return {
      titlePage: window.i18n.t('menu.futures.open_order'),
      searchKey: "",
      isActive: true,
      startDate: moment().subtract(1, "week").toDate(),
      endDate: moment().toDate(),
      filters: {
        searchKey: "",
        symbol: null,
        status: null,
        type: null,
      },
      isLoading: false,
      TypesData: [
        { name: window.i18n.t("order.order_history.all"), id: "" },
        { name: window.i18n.t("order_type.limit"), id: "LIMIT" },
        { name: window.i18n.t("order_type.market"), id: "MARKET" },
        { name: window.i18n.t("order_type.stop_limit"), id: "STOP_LIMIT" },
        { name: window.i18n.t("order_type.stop_market"), id: "STOP_MARKET" },
        { name: window.i18n.t("order_type.trailing_stop"), id: "TRAILING_STOP" },
        { name: window.i18n.t("order_type.liquidation"), id: "LIQUIDATION" },
        { name: window.i18n.t("order_type.take_profit_market"), id: "TAKE_PROFIT_MARKET" },
        { name: window.i18n.t("order_type.stop_loss_market"), id: "STOP_LOSS_MARKET" },
      ],
      SymbolData: [{ id: "", name: window.i18n.t("order.order_history.all") }],
      StatusData: [
        { name: window.i18n.t("order.order_history.all"), id: "" },
        { name: "Pending", id: "PENDING" },
        { name: "Partial Filled", id: "PARTIALLY_FILLED" },
        { name: "Open", id: "ACTIVE" },
      ],
      totalRecords: 0,
      rightSlide: false,
      dataDetail: null,
      enableClose: false,
      isLoading: false,
    };
  },
  watch: {
    isActive() {
      this.$refs.datatable.refresh();
    },
  },
  methods: {
    selectTab(tab) {
      this.isActive = tab;
      this.filters.searchKey = "";
      this.$refs.datatable.refresh();
      this.startDate = moment().subtract(1, "week").toDate();
      this.endDate = moment().toDate();
    },
    customFormatter(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    search() {
      let filterParams = {
        start_date: moment(this.startDate).startOf("day").format("x"),
        end_date: moment(this.endDate).endOf("day").format("x"),
      };
      if (
        this.customFormatter(this.startDate) >
        this.customFormatter(this.endDate)
      ) {
        this.$toastr("error", this.$t("common.date.warning"));
        return;
      }
      if (this.filters.searchKey) {
        filterParams.search_key = this.filters.searchKey;
      }
      if (this.filters.symbol) {
        filterParams.symbol = this.filters.symbol.id;
      }
      if (this.filters.type) {
        filterParams.type = this.filters.type.id;
      }
      // if (this.filters.status) {
      //   filterParams.status = this.filters.status.id;
      // }
      if (this.filters.status && this.filters.status.id) {
        filterParams.status = this.filters.status.id;
      } else {
        filterParams.getOpenOrder = true;
      }
      this.$refs.datatable.filter(filterParams);
    },
    refresh() {
      this.resetInput();
      this.$refs.datatable.refresh();
    },
    submitCancelOrder(order) {
      const params = {
        userId: order.userId,
        orderId: order.id,
      };
      rf.getRequest("AdminRequest")
        .cancelOrderFutureRecord(params)
        .then((res) => {
          if (res.code === 200) {
            this.$toastr("success", "Cancel successful");
            this.search();
          } else {
            this.$toastr("error", "Cancel failed");
          }
        });
    },
    resetInput() {
      this.filters = Object.assign(this.filters, {
        searchKey: "",
        side: null,
        symbol: null,
        status: null,
        type: null,
      });
      this.typeFilter = null;
      this.symbolFilter = null;
      this.startDate = moment().subtract(1, "week").toDate();
      this.endDate = moment().toDate();
      $(".datatable").find("th").attr("data-sort-order", "");
    },
    listenForNotification() {
      window.Echo.channel("App.Models.Admin").listen(
        "AdminNotificationUpdated",
        () => {
          this.$refs.datatable.refresh();
        }
      );
    },
    getOrderFutureRecords(params) {
      let filterParams = {
        start_date: this.customFormatter(this.startDate),
        end_date: this.customFormatter(this.endDate),
      };
      if (
        this.customFormatter(this.startDate) ===
        this.customFormatter(this.endDate)
      ) {
        filterParams = {
          start_date: this.customFormatter(this.startDate),
          end_date: this.customFormatter(this.endDate),
        };
      }

      let meta = {
        // isActive: this.isActive,
        from: filterParams.start_date,
        to: filterParams.end_date,
      };
      // if (this.filters.searchKey) {
      //   meta.search_key = this.filters.searchKey;
      // }
      // if (this.filters.type && this.filters.type.id != '') {
      //   meta.type = this.filters.type.id;
      // }
      if (this.filters.status && this.filters.status.id != "") {
        delete meta.getOpenOrder;
        delete params.getOpenOrder;
        meta.status = this.filters.status.id;
      } else {
        delete meta.status;
        delete params.status;
        meta.getOpenOrder = true;
      }
      if (this.accountId || this.accountId != "") {
        meta.userId = this.accountId;
      }
      params = Object.assign(params, meta);
      params.size = params.limit || 10;
      delete params.limit;
      const records = rf
        .getRequest("AdminRequest")
        .getOrderFutureRecords(params);
      records.then((data) => {
        // console.log("🚀 ~ getOrderFutureRecords ~ data:", data)
        this.totalRecords = data.metadata.total;
      });
      return records;
    },
    getCurrencyPositionRecord(value) {
      if (value.contractType === "COIN_M") {
        // return value.asset
        return "Cont";
      } else {
        const index = value.symbol?.indexOf(value.asset);
        return (
          value.symbol?.slice(0, index) +
          value.symbol?.slice(index + value.asset.length)
        );
      }
    },
    filledOrder(value) {
      return new BigNumber(value.quantity).minus(value.remaining);
    },
    handleClose() {
      this.dataDetail = null;
      this.rightSlide = false;
    },
    handleOpen(data) {
      this.dataDetail = data;
      this.rightSlide = true;
    },
    getOptionData() {
      this.SymbolData = [{ id: "", name: window.i18n.t("order.order_history.all") }];
      rf.getRequest("AdminRequest")
        .getInstruments()
        .then((res) => {
          res?.data.map((item) => {
            this.SymbolData.push({
              id: item.name,
              name: item.symbol,
            });
          });
        });
    },

    downloadExcel() {
      let filterParams = {
        from: moment(this.startDate).format("YYYY-MM-DD"),
        to: moment(this.endDate).format("YYYY-MM-DD"),
      };
      if (
        this.startDate > this.endDate &&
        this.startDate.toDateString() != this.endDate.toDateString()
      ) {
        this.$toastr("error", this.$t("common.date.warning"));
        return;
      }
      if (this.filters.symbol) {
        filterParams.symbol = this.filters.symbol.id;
      }
      if (this.filters.searchKey) {
        filterParams.search_key = this.filters.searchKey;
      }
      if (this.filters.type && this.filters.type.id != "") {
        filterParams.type = this.filters.type.id;
      }
      if (this.filters.status && this.filters.status.id != "") {
        filterParams.status = this.filters.status.id;
      } else {
        filterParams.getOpenOrder = true;
      }

      rf.getRequest("AdminRequest")
        .exportOrderFutureRecords(filterParams)
        .then((res) => {
          if (res?.code === 200) {
            // window.open(`${process.env.VUE_APP_API_URL}/${res.data.fileName}`, '_blank')
            // const decodedData = Buffer.from(res.data.base64Data, 'base64').toString();
            // console.log("🚀 ~ rf.getRequest ~ decodedData:", decodedData)
            const byteCharacters = atob(res.data.base64Data);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);

            // Create a Blob from ArrayBuffer
            const blob = new Blob([byteArray], {
              type: "application/vnd.openxmlformatsofficedocument.spreadsheetml.sheet",
            });

            // Create a temporary anchor element
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = res.data.fileName; // File name
            document.body.appendChild(link);

            // Programmatically click the link to trigger the download
            link.click();

            // Clean up
            document.body.removeChild(link);
            window.URL.revokeObjectURL(link.href);
          }
        })
        .catch((error) => {
          alert(error);
          console.log("🚀 ~ rf.getRequest ~ error:", error);
        });
    },
    detailAccount(row) {
      this.$router.push({
        path: "/account/detail",
        query: { id: row.userId },
      });
    },
    cancelOrder(order) {
      this.showModalConfirm("cancelOrder", this.submitCancelOrder, order);
    },
    showModalConfirm(modalName, callback, params) {
      CommonModal.show(modalName, {
        position: "center",
        mask: true,
        buttons: [
          {
            label: window.i18n.t("common.action.no"),
            class: "btn-no",
            style: {
              background: "rgb(191, 191, 191);",
            },
            callback: () => {
              CommonModal.hide(modalName);
            },
          },
          {
            label: window.i18n.t("common.action.yes"),
            focused: true,
            class: "btn-yes",
            style: {
              background: "rgb(0, 112, 192)",
            },
            callback: () => {
              CommonModal.hide(modalName);
              this.loadingUploadBegin();
              callback(params);
              this.loadingUploadFinish();
            },
          },
        ],
      });
    },
    loadingUploadBegin() {
      this.enableClose = false;
      this.isLoading = true;
      CommonModal.show("loadingUpload");
    },
    loadingUploadFinish() {
      CommonModal.hide("loadingUpload");
    },
  },
  created() {
    this.getOptionData();
  },
  mounted() {
    this.$emit("EVENT_PAGE_CHANGE", this);
    this.listenForNotification();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/variables";

.d-flex {
  display: flex;
  align-items: end;
}

.w-100 {
  width: 100%;
}

.buy {
  color: $text-color-jade !important;
}

.sell {
  color: $text-color-red !important;
}

#order_future {
  table {
    thead {
      th {
        &:first-child {
          width: 9%;
          min-width: 100px;
        }

        &:nth-child(2) {
          width: 9%;
          min-width: 120px;
        }

        &:nth-child(3) {
          width: 8%;
          min-width: 80px;
        }

        &:nth-child(4) {
          width: 6%;
          min-width: 80px;
        }

        &:nth-child(5) {
          width: 11%;
          min-width: 125px;
        }

        &:nth-child(6) {
          width: 13%;
          min-width: 125px;
        }

        &:nth-child(7) {
          width: 9%;
          min-width: 100px;
        }

        &:nth-child(8) {
          width: 9%;
          min-width: 100px;
        }

        &:nth-child(9) {
          width: 9%;
          min-width: 100px;
        }

        &:nth-child(10) {
          width: 150px;
          min-width: 150px;
          max-width: 150px;
        }

        &:nth-child(11) {
          width: 8%;
          min-width: 90px;
        }
      }
    }
  }

  .filter-template {
    margin-right: 15px;
    float: left;

    &:nth-child(3) {
      width: 120px;
    }

    &:nth-child(4) {
      width: 120px;
    }

    &:nth-child(5) {
      width: 120px;
      display: block;
    }

    &:nth-child(6) {
      display: flex;
      height: 100%;
      align-items: end;
    }

    &:nth-child(7) {
      display: flex;
      height: 100%;
      align-items: end;
    }

    .label-text {
      color: $dark-1;
      font-weight: 500;
      font-size: 14px;
      margin-bottom: 7px;
      margin-left: 5px;
      font-family: $mulish-regular;
    }

    .date-picker-input {
      display: inline-block;
      float: left;

      ::v-deep {
        input {
          padding: 0 5px 0px 36px;
          width: 135px !important;
          height: 32px;
          border: 1px solid #c7cbd3 !important;
          border-radius: 10px !important;
          background-image: url("@/assets/images/icon/date-time.svg") !important;
          background-color: $color-white !important;
          background-size: 12px !important;
          background-position: center left 12px !important;
          background-repeat: no-repeat !important;
          color: $dark-1;

          &:hover,
          &:active,
          &:focus {
            border-color: $color-jungle-green;
            z-index: 9;
          }
        }
      }
    }

    .btn-reset {
      background: $color-caribbean-green;
      border-radius: 10px;
      color: $color_white;
      font-weight: 700;
      width: 78px;
      height: 32px;
    }

    .btn-search {
      margin: 0 10px;
    }

    .btn-download {
      background: $color-caribbean-green;
      border-radius: 10px;
      color: $color_white;
      font-weight: 700;
      width: 95px;
      height: 32px;
      margin-left: 10px;
    }

    .search_symbol {
      background: $color-white;
      border: 1px solid #c7cbd3 !important;
      border-radius: 10px !important;
      width: 197px;
      height: 32px;
    }

    .bw-element {
      float: left;
      font-size: 15px;
      margin-left: 10px;
      height: 32px;
      line-height: 32px;
      margin-right: 5px;
      color: $color-grey-dusty;
    }

    .coin-selector {
      width: 75px;
      position: relative;

      .coin-input {
        display: inline-block;
        margin: 0 5px;
        margin-right: 0px;
        border-radius: 10px !important;
        background-color: $color-white;
        height: 32px;
        width: 100%;
      }

      .coin-option {
        background-color: $color-white;
        max-height: 210px;
        display: block;
        overflow-y: auto;
        margin-top: 5px;
        position: absolute;
        width: 79px;
        left: 5px;
        z-index: 10;
        padding: 12px 0px;
        box-shadow: 0px 3px 12px rgba(49, 49, 49, 0.51);
        border-radius: 10px;

        &:after {
          bottom: 100%;
          left: 50%;
          border: solid transparent;
          content: " ";
          display: block;
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
          border-color: rgba(136, 183, 213, 0);
          border-bottom-color: $color_white;
          border-width: 5px;
          margin-left: -5px;
        }

        .option {
          display: block;
          width: 100%;
          line-height: 20px;
          cursor: pointer;
          padding: 3px 14px;
          overflow: hidden;

          &:hover {
            color: $color-jungle-green;
          }
        }
      }
    }

    .form-control {
      background: $color-white-smoke;
      height: 32px;
      font-size: 13px;
      padding-left: 11px;
      padding-right: 0;
      border-radius: 10px !important;
    }

    .toolbar-element2 {
      display: inline-block;
      float: left;
      width: 100%;
    }
  }
}

.no-left-radius {
  input {
    border-radius: 0px 3px 3px 0px;
  }
}

.no-right-radius {
  input {
    border-radius: 3px 0px 0px 3px;
  }
}

td {
  word-break: break-all;
  vertical-align: middle !important;
  border-bottom: solid 1px $color-white-smoke;

  &:nth-child(10) {
    padding-left: 10px;
  }

  .bt {
    width: 100px;
    height: 36px;
    border-radius: 3px;
    color: $color_black_logo;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 12px;
    cursor: default;

    &.pending {
      background: $color-pending-status;
    }

    &.partial_filled {
      background: $color-partial-status;
    }

    &.open {
      background: $color-open-status;
    }
  }

  .img-all {
    .imge {
      margin-right: 6px;
      cursor: pointer;
    }
  }

  .text-account {
    text-decoration: underline;
    color: $color-blue-custom;
    cursor: pointer;
  }
}

.datatable {
  font-family: $font-family-roboto !important;
}

ul {
  list-style-type: none;
  padding: 0px;
}

.clearfix-40 {
  display: block;
  clear: both;
  height: 40px;
}

.buy {
  color: $text-color-jade;
}

.sell {
  color: $text-color-red;
}

.toolbar-element2 {
  display: inline-block;
  float: left;
  width: 65px;
  min-width: 110px;
}

td {
  font-family: $font-family-roboto;
}

.table-futures {
  margin-top: 24px;
}

.tab-header {
  display: block;
  width: 100%;
  float: left;
  margin: 10px 0px 23px 0px;

  ul {
    list-style: none;
    padding-left: 0px;

    li {
      float: left;
      margin-right: 25px;
      font-size: $font_big;
      color: $color-grey-dark;
      display: block;

      > a {
        color: $color-grey-dark;
        font-family: $font-family-roboto-medium;
        font-weight: 500;
        font-size: $font_big;
        display: block;
        float: right;

        &::before {
          content: "";
          display: inline-block;
          width: 12px;
          height: 12px;
          border-radius: 50%;
          border: solid 1px #979797;
        }

        span {
          padding-left: 5px;
        }
      }

      &.active {
        > a {
          &::before {
            border: solid 4px $color-jungle-green;
          }

          color: $color-jungle-green;
        }
      }
    }
  }
}

.input-dropdown {
  position: relative;

  input {
    background: $color-white;
    border: 1px solid #c7cbd3 !important;
    border-radius: 10px !important;
    width: 110px;
    height: 32px;
    cursor: pointer;
  }

  .icon-coin-input {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    color: #667186;

    i {
      font-size: 22px;
    }
  }

  .coin-option {
    position: absolute;
    top: 37px;
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 4px 15px rgba(0, 19, 54, 0.15);
    border-radius: 10px;
    padding: 17px 0;
    z-index: 3;

    &:after {
      bottom: 100%;
      left: 50%;
      border: solid transparent;
      content: " ";
      display: block;
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-color: rgba(136, 183, 213, 0);
      border-bottom-color: $color-white;
      border-width: 5px;
      margin-left: -5px;
    }

    .option {
      display: flex;
      align-items: center;
      width: 100%;
      line-height: normal;
      cursor: pointer;
      padding-left: 14px;
      font-size: 14px;
      font-weight: 500;
      overflow: hidden;
      color: #001336;
      height: 32px;

      &:hover {
        background: $color-athens;
      }
    }
  }
}
</style>

<style lang="scss">
@import "@/assets/sass/variables";

#order_future {
  .sc_search_select {
    .group_search_select {
      .button_drop_search {
        height: 32px;
        padding: 6px 10px 6px 10px !important;
        border-radius: 10px;
        font-size: $font-small;
        font-family: $mulish-regular;
        background-color: $color-white;

        i.icon-arrow1 {
          margin-left: 5px;
          color: $color_dove_gray;
        }

        &:hover {
          border-color: $color-jungle-green;

          i.icon-arrow1 {
            color: $color-jungle-green;
          }
        }
      }

      .box_list_search_select {
        .list_search_select {
          border-radius: 10px;

          li {
            font-family: $font-family-roboto;
            font-size: $font-small;

            &:hover,
            &:active,
            &:focus {
              color: $color-jungle-green;
            }
          }
        }
      }
    }
  }
}
</style>
