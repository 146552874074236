<template>
  <div id="new-reward">
    <div class="header" v-if="!isEdit">
      <span class="sub-title" @click="nextStepInstruments"> {{ $t('coin_information.btn_next') }} <i class="icon-arrow3" ></i> </span>
    </div>
    <div class="form-input grid">
      <div class=" form-group">
        <div  class="item-input">
          <label class="label-input">{{ $t('instruments.symbol') }} </label>
          <input
            ref="voucher"
            id="voucher_name"
            :maxlength="maxLength20"
            autocomplete="off"
            name="voucher"
            data-vv-validate-on="none"
            v-validate="'required|max:20'"
            data-vv-as="voucher"
            type="text" class="form-control"
            :class="{ errorInput: errors.has('voucher') }"
            @focus="resetError"
            @input="$emit('input' ,dataInstruments.instrument.symbol)"
            v-model="dataInstruments.instrument.symbol"
          />
          <span class="error-message" v-if="errors.has('voucher')"> {{ errors.first('voucher') }} </span>
        </div>
        <div  class="item-input">
          <label class="label-input">{{ $t('instruments.underlying') }}</label>
          <select-box v-model="dataInstruments.instrument.underlyingSymbol"
                      name="underlyingSymbol" class="select-market"
                      @focus="resetError"
                      :options="dataUnder"
                      
                      @input="selectedCoin($event)"
                      :class="{ errorInputDropDown: errors.has('underlyingSymbol') }"
                      :searchable="true"
          />
          <span class="error-message" v-if="errors.has('underlyingSymbol')"> {{ errors.first('underlyingSymbol') }} </span>
        </div>
        <div  class="item-input">
          <label class="label-input">{{ $t('instruments.collateral') }}</label>
          <input
            ref="collateral"
            :maxlength="maxLength"
            autocomplete="off"
            name="rootSymbol"
            data-vv-validate-on="none"
            v-validate="'required|max:28'"
            data-vv-as="rootSymbol"
            type="text" class="form-control"
            :class="{ errorInput: errors.has('rootSymbol') }"
            @focus="resetError"
            v-model="dataInstruments.instrument.rootSymbol"
            :disabled="true"
          />
          <span class="error-message" v-if="errors.has('rootSymbol')"> {{ errors.first('rootSymbol') }} </span>
        </div>
        <div  class="item-input" v-if="contractType" >
          <label class="label-input">{{ $t('instruments.contract_multiplier') }}</label>
          <number-input
            ref="voucher"
            id="voucher_name"
            :maxlength="maxLength"
            autocomplete="off"
            name="contract_multiplier"
            data-vv-validate-on="none"
            v-validate="'required|max:28'"
            :precision="8"
            :rational-number="4"
            :restrict-step-price="true"
            data-vv-as="voucher"
            type="text" class="form-control"
            :class="{ errorInput: errors.has('contract_multiplier') }"
            @focus="resetError"
            v-model="dataInstruments.instrument.multiplier"
            
          />
          <span> 1 Cont = 1 USD </span>
        </div>
        <div  class="item-input">
          <label class="label-input">{{ $t('instruments.marker_fee_percent') }}</label>
          <div class="number-percent">
            <number-input
              v-inputOnlyTwoRationalNumbers
              ref="tarker_fee_percent"
              id="tarker_fee_percent"
              :precision="8"
              :rational-number="2"
              :restrict-step-price="true"
              name="makerFee"
              class="form-control padding-unit"
              @focus="resetError"
              :class="{ errorInput: errors.has('makerFee')}"
              v-model="dataInstruments.instrument.makerFee"
              
            >
                            @focus="removeValidate('tarker_fee_percent')"
            </number-input>

            <span class="unit w-20"
                  :class="{ 'error-message' : errors.has('makerFee')}">%</span>
          </div>

        <span class="error-message" v-if="errors.has('makerFee')"> {{ errors.first('makerFee') }} </span>
        </div>
        <div  class="item-input">
          <label class="label-input">{{ $t('instruments.tarker_fee_percent') }}</label>
          <div class="number-percent">
            <number-input
              v-inputOnlyTwoRationalNumbers
              ref="marker_fee_percent"
              id="marker_fee_percent"
              :precision="8"
              :rational-number="2"
              :restrict-step-price="true"
              name="takerFee"
              @focus="resetError"
              class="form-control padding-unit"
              :class="{ errorInput: errors.has('takerFee')}"
              v-model="dataInstruments.instrument.takerFee"
              
            >

<!--              &lt;!&ndash;            v-model="dataInstrument.settlement_fee"&ndash;&gt;-->
            </number-input>

            <span class="unit w-20"
                  :class="{ 'error-message' : errors.has('takerFee')}">%</span>
          </div>
          <span class="error-message" v-if="errors.has('takerFee')"> {{ errors.first('takerFee') }} </span>

        </div>
        <div  class="item-input">
          <label class="label-input">{{ $t('instruments.limit_buy_order_price_cap_ratio') }}</label>
          <div class="number-percent">
            <div class="number-percent">
              <number-input
                v-inputOnlyTwoRationalNumbers
                ref="price_cap_ratio"
                id="price_cap_ratio"
                :precision="8"
                :rational-number="2"
                @focus="resetError"
                :restrict-step-price="true"
                name="price_cap_ratio"
                class="form-control padding-unit"
                :class="{ errorInput: errors.has('price_cap_ratio')}"
                          v-model="dataInstruments.tradingRules.limitOrderPrice"
                

              >
              </number-input>

              <span class="unit w-20"
                    :class="{ 'error-message' : errors.has('price_cap_ratio')}">%</span>

            </div>
            <span class="error-message" v-if="errors.has('price_cap_ratio')"> {{ errors.first('price_cap_ratio') }} </span>
          </div>
        </div>
        <div  class="item-input">
          <label class="label-input">{{ $t('instruments.limit_buy_order_price_floor_ratio') }}</label>
          <div class="number-percent">
            <div class="number-percent">
              <number-input
                v-inputOnlyTwoRationalNumbers
                ref="price_floor_ratio"
                id="price_floor_ratio"
                :precision="8"
                :rational-number="2"
                :restrict-step-price="true"
                name="price_floor_ratio"
                @focus="resetError"
                class="form-control padding-unit"
                :class="{ errorInput: errors.has('price_floor_ratio')}"
                          v-model="dataInstruments.tradingRules.floorRatio"
                
              >
              </number-input>

              <span class="unit w-20"
                    :class="{ 'error-message' : errors.has('price_floor_ratio')}">%</span>
            </div>
            <span class="error-message" v-if="errors.has('price_floor_ratio')"> {{ errors.first('price_floor_ratio') }} </span>

          </div>
        </div>
      </div>
      <div class=" form-group">
<!--        <label class="label-input">{{ $t('reward_center.beneficiary') }}</label>-->
<!--        <select-box v-model="dataVoucher.type" v-validate="'required'" name="beneficiary" class="select-market"-->
<!--                    @focus="resetError"-->
<!--                    :options="voucherType"-->
<!--                    -->
<!--        />-->
<!--        <span class="error-message" v-if="errors.has('beneficiary')"> {{ errors.first('beneficiary') }} </span>-->
        <div  class="item-input">
          <label class="label-input">{{ $t('instruments.create.min_tick_size') }}</label>
          <number-input
            ref="min_tick_size"
            id="min_tick_size"
            name="min_tick_size"
            data-vv-validate-on="none"
            v-validate="'required|max:28'"
            :precision="8"
            :rational-number="2"
            data-vv-as="voucher"
            type="text" class="form-control"
            :class="{ errorInput: errors.has('min_tick_size') }"
            :value="dataInstruments.instrument.tickSize"
            @focus="resetError"
            @blur="onBlurMinTickSize"
            
            v-model="dataInstruments.instrument.tickSize"
          > </number-input>
          <span> All tick sizes:
          <span class="tickSize" v-if="!dataInstruments.instrument.tickSize" > N/A </span>
          <span class="tickSize"  v-else> {{allTickSize(dataInstruments.instrument.tickSize)}} </span>
          </span>

          <span class="error-message" v-if="errors.has('min_tick_size')"> {{ errors.first('min_tick_size') }} </span>
        </div>
        <div  class="item-input">
          <label class="label-input">{{ $t('instruments.min_price') }}</label>
          <number-input
            ref="min_price"
            id="min_price"
            :maxlength="maxLength"
            autocomplete="off"
            :precision="8"
            :rational-number="2"
            name="min_price"
            data-vv-validate-on="none"
            v-validate="'required|max:28'"
            data-vv-as="min_price"
            type="text" class="form-control"
            :class="{ errorInput: errors.has('min_price') }"
            @focus="resetError"
            v-model="dataInstruments.tradingRules.minPrice"
            
          />
          <span class="error-message" v-if="errors.has('min_price')"> {{ errors.first('min_price') }} </span>
        </div>
        <div  class="item-input">
          <label class="label-input">{{ $t('instruments.create.max_price') }}</label>
          <number-input
            ref="max_price"
            id="max_price"
            :maxlength="maxLength"
            autocomplete="off"
            name="max_price"
            :precision="8"
            :rational-number="6"
            data-vv-validate-on="none"
            v-validate="'required|max:28'"
            data-vv-as="voucher"
            type="text" class="form-control"
            :class="{ errorInput: errors.has('max_price') }"
            @focus="resetError"
            v-model="dataInstruments.instrument.maxPrice"
            
          />
          <span class="error-message" v-if="errors.has('max_price')"> {{ errors.first('max_price') }} </span>
        </div>
        <div  class="item-input">
          <label class="label-input">{{ $t('instruments.min_order_price') }}</label>
          <number-input
            ref="min_order_price"
            id="min_order_price"
            :maxlength="maxLength"
            :maxLengthCus="8"
            :precision="8"
            autocomplete="off"
            name="min_order_price"
            data-vv-validate-on="none"
            v-validate="'required|max:8'"
            data-vv-as="min_order_price"
            type="text" class="form-control"
            :class="{ errorInput: errors.has('min_order_price') }"
            @focus="resetError"
            v-model="dataInstruments.tradingRules.minOrderAmount"
            
          >
          </number-input>
          <span class="error-message" v-if="errors.has('min_order_price')"> {{ errors.first('min_order_price') }} </span>
        </div>
        <div  class="item-input">
          <label class="label-input">{{ $t('instruments.max_order_price') }}</label>
          <number-input
            ref="max_order_price"
            id="max_order_price"
            :maxlength="maxLength"
            :maxLengthCus="8"
            :precision="8"
            autocomplete="off"
            name="max_order_price"
            data-vv-validate-on="none"
            v-validate="'required|max:8'"
            data-vv-as="max_order_price"
            type="text" class="form-control"
            :class="{ errorInput: errors.has('max_order_price') }"
            @focus="resetError"
            v-model="dataInstruments.tradingRules.maxOrderAmount"
            
          />
          <span class="error-message" v-if="errors.has('max_order_price')"> {{ errors.first('max_order_price') }} </span>
        </div>
        <div  class="item-input">
          <label class="label-input">{{ $t('instruments.figures_for_price') }}</label>
          <number-input
            ref="figures_for_price"
            id="figures_for_price"
            :maxLengthCus="-1" 
            autocomplete="off"
            name="figures_for_price"
            data-vv-validate-on="figures_for_price"
            v-validate="'required'"
            data-vv-as="voucher"
            type="text" class="form-control"
            :class="{ errorInput: errors.has('figures_for_price') }"
            @focus="resetError"
            v-model="dataInstruments.instrument.maxFiguresForPrice"
            
          />
          <span class="error-message" v-if="errors.has('figures_for_price')"> {{ errors.first('figures_for_price') }} </span>
        </div>
        <div  class="item-input" v-if="!contractType">
          <label class="label-input">{{ $t('instruments.figures_for_size') }}</label>
          <number-input
            ref="figures_for_size"
            id="figures_for_size"
            :maxLengthCus="-1"
            autocomplete="off"
            name="figures_for_size"
            data-vv-validate-on="none"
            v-validate="'required'"
            data-vv-as="voucher"
            type="text" class="form-control"
            :class="{ errorInput: errors.has('figures_for_size') }"
            @focus="resetError"
            v-model="dataInstruments.instrument.maxFiguresForSize"
            
          />
          <span class="error-message" v-if="errors.has('figures_for_size')"> {{ errors.first('figures_for_size') }} </span>
        </div>
      </div>
      <div class=" form-group">
        <div  class="item-input">
          <label class="label-input">{{ $t('instruments.liquidateion') }}</label>
          <div class="number-percent">
            <number-input
              v-inputOnlyTwoRationalNumbers
              ref="settlement-fee"
              id="settlement-fee"
              :precision="8"
              :rational-number="2"
              :restrict-step-price="true"
              @focus="resetError"
              name="liquidateion"
              class="form-control padding-unit"
              :class="{ errorInput: errors.has('liquidateion')}"
              
              v-model="dataInstruments.tradingRules.liqClearanceFee"
            >

            </number-input>

            <span class="unit w-20"
                  :class="{ 'error-message' : errors.has('liquidateion')}">%</span>
          </div>
          <span class="error-message" v-if="errors.has('liquidateion')"> {{ errors.first('liquidateion') }} </span>
        </div>
        <div  class="item-input max-levarage">
          <label class="label-input">{{ $t('instruments.create.max_leverage') }}</label>
          <div>
            <input
              ref="max_leverage"
              id="max_leverage"
              :maxlength="maxLength"
              autocomplete="off"
              name="max_leverage"
              data-vv-validate-on="none"
              v-validate="'required|max:28'"
              data-vv-as="max_leverage"
              type="text" class="form-control"
              :class="{ errorInput: errors.has('max_leverage') }"
              @focus="resetError"
              @input="maxValue(40)"
              v-model="maxLeverage"
              
            />
            <p> X 5 =</p>
            <input
              ref="voucher"
              id="voucher_name"
              :maxlength="maxLength"
              autocomplete="off"
              name="voucher"
              data-vv-validate-on="none"
              v-validate="'required|max:28'"
              disabled="true"
              type="text" class="form-control"
              :class="{ errorInput: errors.has('name') }"
              @focus="resetError"
              v-model="dataInstruments.tradingRules.maxLeverage = maxLeverage * 5 "
            />
          </div>
          <span class="error-message" v-if="errors.has('voucher')"> {{ errors.first('max_leverage') }} </span>
        </div>
        <div  class="item-input">
          <label class="label-input">{{ $t('instruments.max-nation') }}</label>
          <number-input
            ref="max-nation"
            id="max-nation"
            :maxlength="maxLength"
            autocomplete="off"
            name="max-nation"
            data-vv-validate-on="none"
            :precision="8"
            :rational-number="8"
            data-vv-as="max-nation"
            type="text" class="form-control"
            :class="{ errorInput: errors.has('max-nation') }"
            @focus="resetError"
            v-model="dataInstruments.tradingRules.maxNotinal"
            
          ></number-input>
          <span class="error-message" v-if="errors.has('max-nation')"> {{ errors.first('max-nation') }} </span>
        </div>
        <div  class="item-input">
          <label class="label-input">{{ $t('instruments.min-nation') }}</label>
          <number-input
            ref="min-nation"
            id="min-nation"
            :maxlength="maxLength"
            autocomplete="off"
            name="min-nation"
            data-vv-validate-on="none"
            :precision="8"
            :rational-number="8"
            data-vv-as="min-nation"
            type="text" class="form-control"
            :class="{ errorInput: errors.has('min-nation') }"
            @focus="resetError"
            v-model="dataInstruments.tradingRules.minNotional"
            
          />
          <span class="error-message" v-if="errors.has('min-nation')"> {{ errors.first('min-nation') }} </span>
        </div>
        <div  class="item-input">
          <label class="label-input">{{ $t('instruments.min-price-movement') }}<img src="@/assets/images/icon/instruments-min-price-movement-info.svg" v-tooltip="$t('instruments.min-price-movement.tip')" class="label-input-info-icon"/></label>
          <number-input
            ref="impact-nation"
            id="impact-nation"
            :precision="8"
            :rational-number="2"
            :maxlength="maxLength"
            autocomplete="off"
            name="min-price-movement"
            data-vv-validate-on="none"
            data-vv-as="voucher"
            type="text" class="form-control"
            :class="{ errorInput: errors.has('impact-nation') }"
            @focus="resetError"
            v-model="dataInstruments.instrument.minPriceMovement"
            
          />
          <span class="error-message" v-if="errors.has('min-price-movement')"> {{ errors.first('min-price-movement') }} </span>
        </div>
        <div  class="item-input impact-notional">
          <label class="label-input">{{ $t('instruments.impact-nation') }}</label>
          <div class="impact-notional-form">
            <div>
              <number-input
                ref="impact-nation"
                id="impact-nation"
                :maxLengthCus="-1"
                autocomplete="off"
                name="impact-nation"
                data-vv-validate-on="none"
                v-validate="'required|max:28'"
                :precision="8"
                data-vv-as="impactMarginNotional"
                type="text" class="form-control"
                :class="{ errorInput: errors.has('impact-nation') }"
                @focus="resetError"
                v-model="impactMarginNotional"
                
              />
              <span>=</span>
              <p>{{$t('instruments.max_leverage')}}</p>
            </div>
            <div class="total-result-impact" >
              {{ impactNation }}
            </div>
          </div>
          <span class="error-message" v-if="errors.has('impact-nation')"> {{ errors.first('impact-nation') }} </span>
          <p class="impact_nation_des">(Impact margin notional = Constant number/ Max Leverage)</p>
        </div>
      </div>
      </div>
    </div>
</template>

<script>
import InputOnlyNumber from "@/common/InputOnlyNumber.vue";
import InputTextAndNumber from "@/components/InputTextAndNumber.vue";
import Modal from "@/components/Modal.vue";
import DatePicker from "vuejs-datepicker";
import SelectBox from "@/components/SelectBox.vue";
import DataTable4 from "@/components/DataTable4.vue";
import CoinInput from "@/common/CoinInput.vue";
import datetime from "vuejs-datetimepicker";
import DropDownList from "../../common/DropDownList";
import NumberInput from "@/common/NumberInput.vue";
import RemoveErrorsMixin from "@/common/RemoveErrorsMixin";
import {Validator} from 'vee-validate';
import _ from "lodash";
import rf from "@/lib/RequestFactory";

export default {
  components: {
    InputOnlyNumber,
    InputTextAndNumber,
    Modal,
    DatePicker,
    SelectBox,
    DataTable4,
    CoinInput,
    datetime,
    DropDownList,
    NumberInput,
  },
  mixins: [RemoveErrorsMixin],
  props: {
    isEdit: {default: false},
    instruments: {},
    contract: 'COIN_M',
    changeInstrument: {
      type: Function
    },
    changeTrading: {
      type: Function
    },
    updateError: {
      type: Function
    }
  },
  data() {
    return {
      titlePage: this.$t('instruments.future_coinM'),
      titleSub: this.$t('reward_center.voucher.name'),
      searchKey: '',
      maxLength: 28,
      maxLength20: 20,
      isSubmitting: false,
      dataVoucher: {
        name: '',
        type: '',
        currency: "USDT",
        conditions_use: 0,
        amount: 0,
        expires_date_number: 0,
      },
      maxLeverage: "",
      impactMarginNotional: "",
      dataInstruments: {
        tradingRules: {
          minPrice: "",
          limitOrderPrice: "",
          floorRatio: 0,
          minOrderAmount : "",
          maxOrderAmount : "",
          minNotional: "",
          maxNotinal: "",
          liqClearanceFee: "",
          maxLeverage: 0,
        },
        instrument: {
          symbol: "",
          rootSymbol: "",
          underlyingSymbol: "",
          makerFee: "",
          takerFee: "",
          tickSize: "",
          multiplier: '',
          maxPrice: "",
          minPriceMovement: "",
          maxFiguresForSize: "",
          maxFiguresForPrice: "",
          impactMarginNotional: ""
        },
      },
      dataUnder: []
    }
  },
  watch: {
    maxLeverage(newVal) {
      this.onUpdateImpact(newVal)
    },
    'dataInstruments.instrument': {
      handler: function (newVal, oldVal) {
        this.$props.changeInstrument(newVal)
      },
      deep: true
    },
    'dataInstruments.tradingRules': {
      handler: function (newVal, oldVal) {
        this.$props.changeTrading(newVal)
      },
      deep: true
    },
  },

  methods: {
    checkDataWithZero(value, field) {
      if (value <= 0 || typeof (value) == 'undefined') {
        this.errors.add({
          field: field,
          msg: window.i18n.t('errors.required')
        });
        return false;
      }
      return true;
    },

    allTickSize(value) {
      return `${value*1} / ${value*10} / ${value*100} / ${value*1000}`;
    },

    onBlurMinTickSize() {
      if(+this.dataInstruments.instrument.tickSize === 0) {
        this.dataInstruments.instrument.tickSize = 1;
      }
    },

    async validate() {
      await this.$validator.validateAll();
      this.checkDataWithZero(this.dataInstruments.instrument.underlyingSymbol, 'underlyingSymbol')
      this.checkDataWithZero(this.dataInstruments.instrument.rootSymbol, 'rootSymbol')
      if(this.contractType) {
        this.checkDataWithZero(this.dataInstruments.instrument.multiplier, 'multiplier')
      } else if (this.contractType) {
        this.checkDataWithZero(this.dataInstruments.tradingRules.maxOrderAmount, 'max_order_price')
      }
      this.checkDataWithZero(this.dataInstruments.instrument.takerFee, 'takerFee')
      this.checkDataWithZero(this.dataInstruments.instrument.makerFee, 'makerFee')
      this.checkDataWithZero(this.dataInstruments.tradingRules.limitOrderPrice, 'price_cap_ratio')
      this.checkDataWithZero(this.dataInstruments.tradingRules.floorRatio, 'price_floor_ratio')
      this.checkDataWithZero(this.dataInstruments.tradingRules.liqClearanceFee, 'liquidateion')
      this.checkDataWithZero(this.dataInstruments.instrument.tickSize, 'min_tick_size')
      this.checkDataWithZero(this.dataInstruments.tradingRules.minPrice, 'minPrice')
      this.checkDataWithZero(this.dataInstruments.instrument.maxPrice, 'max_price')
      this.checkDataWithZero(this.dataInstruments.tradingRules.minOrderAmount, 'min_order_price')
      this.checkDataWithZero(this.impactMarginNotional, 'impact_nation')
      this.checkDataWithZero(this.dataInstruments.instrument.minPriceMovement, 'min-price-movement')
      this.checkDataWithZero(this.dataInstruments.tradingRules.minNotional, 'min-nation')
      this.checkDataWithZero(this.dataInstruments.tradingRules.maxNotinal, 'max-nation')
    },

    async nextStepInstruments() {
      await this.validate()
      if (this.errors.any()) {
        return
      }
      this.$router.push({
          name: 'Create New Tier Market',
          params: {
            value: this.dataInstruments,
            dataTier: this.$route.params.dataTier,
            maxLeverage: this.maxLeverage,
            impactMarginNotional: this.impactMarginNotional
          },
          query: {contract: this.$route.query.contract}
        });
    },

    selectedCoin(e){
      this.dataInstruments.instrument.rootSymbol = e.replace(/USDM|USDT|USD/, '');
    },

    maxValue( max) {
      if(this.maxLeverage === '') {
        return ''
      }
      if(!/^\d+$/.test(this.maxLeverage)) {
        return this.maxLeverage = this.maxLeverage.replace(/\D/g, '');
      }
      if (this.maxLeverage > max) {
        return this.maxLeverage = max
      } else if (this.maxLeverage < 1 ) {
        return this.maxLeverage = 1
      }
      else {
        return this.maxLeverage
      }
    },

    onInstrumentsUpdate() {
      const tradingRules = this.dataInstruments.tradingRules;
      const instrument = this.dataInstruments.instrument;
      const instruments = this.instruments || {};
      Object.entries(instruments).forEach(([key, value]) => {
        if( key ==='maxLeverage' ) {
          this.maxLeverage = this.removeTrailingZeros(value) / 5;
        }
        if( key === 'impactMarginNotional' ) {
          this.impactMarginNotional = this.removeTrailingZeros(value)
        }
        if (tradingRules.hasOwnProperty(key)) {
          tradingRules[key] =  this.removeTrailingZeros(value);
        }
        if (instrument.hasOwnProperty(key)) {
          instrument[key] = this.removeTrailingZeros(value);
        } else {
          instrument.rootSymbol = this.instruments.collateralAsset;
        }
      });
    },

    removeTrailingZeros(number) {
      if (isNaN(Number(number))) {
        return number;
      }
      return Number(number.toString().replace(/(\d+\.\d+?)0+$/, '$1'));
    },

    onUpdateImpact(value) {
      if(this.isEdit) {
        this.impactMarginNotional = this.impactMarginNotional * value
      }
    },

    initData() {
      const params = {
        contractType: this.$route.query.contract
      }
      rf.getRequest('AdminRequest').getAsset(params).then(res =>{
        this.dataUnder = res.data
      })
    },

    async validateInstrument() {
      await this.validate()
      return this.errors.any()
    }
  },
  computed: {
    // isEdit() {
    //   return !!this.$route.query.id
    // },

    contractType() {
      this.titlePage = this.$route.query.contract === 'COIN_M' ? this.$t('instruments.future_coinM') : this.$t('instruments.future_usdM')
      return this.$route.query.contract === 'COIN_M' || this.contract === 'COIN_M'
    },

    impactNation() {
        const total = this.impactMarginNotional / this.maxleverage
        if(isNaN(total) || total == 'Infinity') {
          return '---'
        }
        return this.dataInstruments.instrument.impactMarginNotional = total
    },

    maxleverage() {
        let total = this.maxLeverage * 5
        if(!this.maxLeverage) {
          return 0
        }
        if(total > 40){
          return 40
        }
        else if(total  < 1   ) {
          return 1
        }
        else {
          return total
        }
    },
    coins () {
       const data = localStorage.getItem('masterdata' )
      const coinSettings = JSON.parse(data).coin_settings;
      const dataUnderlying = [];
      for (let i = 0; i < coinSettings.length; i++) {
        const { coin, currency } = coinSettings[i];
        if (['usdt', 'usd'].includes(currency)) {
          dataUnderlying.push(`${coin}${currency}`.toUpperCase());
        }
      }
      return dataUnderlying;
    },

  },
  mounted() {
    this.$emit('EVENT_PAGE_CHANGE', this);
    const val = this.$route.params.value
    if(val) {
      this.dataInstruments = val
      this.maxLeverage = this.$route.params.maxLeverage
      this.impactMarginNotional = this.$route.params.impactMarginNotional
    }
  },


  created() {
    if(this.isEdit) {
      this.onInstrumentsUpdate()
    }
    this.initData()
    Validator.localize({
      en: {
        messages: {
          required: (field) =>
            window.i18n.t('errors.required'),
        },
      },
    })
  },
  directives: {
    "inputOnlyTwoRationalNumbers": {
      bind(el, binding, vnode) {
        el.addEventListener('blur', () => {
          // el.value = el.value ? Number(el.value.toString()) : '';
          // if (String(el.value).includes(',')) {
          //     el.value = String(el.value).replace(/\D/g, "");
          // }
          // if (!String(el.value).includes('.') && (Number(String(el.value).replace(/\D/g, "")) ==0)) {
          //     el.value = el.value.slice(0, -1);
          // }
        })
      }
    },
    "inputLowerOne" :{
      bind(el, binding, vnode){
        el.addEventListener('keyup', () => {
          // if (String(el.value).includes(',')) {
          //     el.value = String(el.value).replace(/\D/g, "");
          // }
          // // if (!String(el.value).includes('.')){
          //     if(Math.abs((Number(String(el.value).replace(/\D/g, "")) > 1))) {
          //         console.log(Math.abs((Number(String(el.value).replace(/\D/g, "")))));
          //         if (String(el.value).includes('-')) {
          //             console.log(el.value);
          //             console.log(Number('-' + ((String(el.value).replace(/\D/g, "")).substring(0, 2)).replace(/-/g, "")));
          //             vnode.context.multiplier = Number('-1');
          //             el.value = Number('-1');
          //         } else {
          //             vnode.context.multiplier = Number('1');
          //             el.value = Number('1');
          //         }
          //     }
          // // }
        })
      }
    }
  }

}

</script>

<style scoped lang="scss">
@import "@/assets/sass/variables";

#new-reward {
  .header {
    display: flow-root;
    .sub-title {
      font-size: $font_big_20;
      margin: 26px 0px ;
      padding: 7px 20px;
      font-family: $mulish-regular;
      line-height: 25px;
      align-items: center;
      gap: 16px;
      color: $color-caribbean-green;
      float: right;
      border: 2px solid $color-caribbean-green;
      border-radius: 20px;
      cursor: pointer;
      .icon-arrow3:before{
        font-size: $font-root;
        color: $color-caribbean-green;
      }
    }

  }
  input[disabled] {
    background-color: $color-athens !important;
  }
  .form-input {
    display: flex;
    padding: 20px;
    border-radius: 10px;
    gap: 24px;
    .form-group {
      background: #FFFFFF;
      border-radius: 10px;
      margin: auto;
      padding: 24px;
      width: 90%;
      min-height: 750px;
      .item-input{
        margin-bottom: 16px;

        .label-input-info-icon {
          transform: translate(5px, -1px);
        }
        .number-percent{
          position: relative;
          .unit{
            right: 3%
          }
        }
      }
      .max-levarage{
        div {
          display: flex;
          gap: 8px;
          align-items: center;
          input {
            width: 40%;
          }
        }
      }
      .impact-notional {
        .impact-notional-form {
          display: flex;
          justify-content: space-between;
          div{
            position: relative;
            width: 45%;
            input {
              //width: 60%;
            }
            p {
              margin-top: 20px;
              //width: 60%;
              border: 0;
              outline: 0;
              background: transparent;
              border-top: 1px solid $color-gray-ghost;
              text-align: center;
              padding-top: 15px;
            }
            span {
              position: absolute;
              left: 110%;
              top: 51px;
            }
          }
          div:last-child {
            display: flex;
            align-items: center;  
            width: 45%;
            padding-top: 18px;
          }
        }
      }
      .impact_nation_des {
        font-weight: 400;
        font-size: $font-root;
        line-height: 18px;
        color: $color-gray-ghost;
        margin-top:16px;
      }
      label {
        font-weight: normal;
        font-size: $font_semi_big;
        line-height: 20px;
        color: $dark-1;
        margin-bottom: 10px;
      }
      .form-control {
        max-width: 100%;
        border-radius: 12px !important;
        padding: 12px 16px 12px 16px;
        height: 41px;
        border: 1px solid #C7CBD3;
        color: $dark-1;
        font-size: $font_semi_big;
        & :hover :focus {
          box-shadow: none;
        }
      }

    }
    .item-input {

    }
    //input[type="text"]:disabled {
    //  color: $color-grey-dusty;
    //}
    .form-action {
      display: flex;
      align-items: center;
      gap: 20px;
      margin-top: 25px;
      .btn {
        width: 150px;
        height: 41px;
        color: $dark-1;
        font-size: $font-semi_big;
        border-radius: 30px;
        font-family: $mulish-bold;
      }
      .btn-normal {
        line-height: 20px;
        text-align: center;
        -webkit-transition: 0.5s;
        transition: 0.5s;
        border: 1px solid #DEDEDE;
        background: transparent;
        &:hover {
          background: $color_silver;
        }
      }
      .btn-submit {
        background: $color-caribbean-green;
        -webkit-transition: 0.5s;
        transition: 0.5s;
        line-height: 20px;
        &:hover {
          background: $color-aquamarine;
        }
      }
    }
  }

  .errorInput {
    border: 1px solid $color-denim !important;
  }
  :deep(.group_search_select) {
    max-width: 100%;
    display: flex;
    align-items: center;
    .button_drop_search {
      width: 100%;
      padding: 10px 16px;
      height: 41px;
      border-radius: 12px;
      border: 1px solid rgb(193, 193, 193) !important;
      span {
        font-family: $mulish-medium;
        font-size: 16px;
        line-height: 20px;
        color: $dark-1;
      }
    }
    .box_list_search_select {
      .list_search_select {
        border-radius: 12px;
        max-height: 200px;
        overflow: auto;
        > li {
          padding: 10.5px 20px;
          > span {
            font-family: $mulish-medium;
            font-size: 16px;
            line-height: 20px;
          }
        }
        > li:hover {
          background: $color-athens;
        }
      }
    }
  }
  :deep(.errorInputDropDown){
    .button_drop_search {
      border: 1px solid $color-denim !important;
    }

  }
  :deep(.disabled-select) {
    background-color: $color-athens !important;
  }
  .error-message {
    color: $color-denim;
  }
}

</style>