<template>
    <div class="admin_account clearfix mg_bot50" id="admin_account">
        <div class="filter-container_order">
            <div class="filter-item">
                <div class="label-text date-text">{{ $t('admin.account.role') }}</div>
                <div class="toolbar-element2">
                    <select-box v-model="filters.status" :placeholder="$t('order.order_history.all')"
                        :options="statusType" />
                </div>
            </div>
            <div class="filter-item">
                <input title type="text" :placeholder="$t('admin.account.search_by')" @keyup.enter="search"
                    class="form-control" name="searchKey" v-model="filters.searchKey">
            </div>
            <div class="filter-item action-wrap">
                <button class="btn btn-primary" @click="search()">{{ $t('orders.open_order.search') }}</button>
                <button class="btn btn-reset" @click="refresh()">{{ $t('orders.open_order.reset') }}</button>
            </div>
            <div class="filter-item right">
                <router-link class="btn btn-reset" to="/administrator/account/create">
                    <span class="icon-plus"></span>
                    {{ $t('admin.account.btn_new_account') }}</router-link>
            </div>
        </div>

        <div class="datatable">
            <data-table-4 :getData="getData" ref="datatable" :column="11" class="scroll">
                <th class="cl1 min_85">
                    {{ $t('admin.account.id') }}
                </th>
                <th class="cl2 min_85">
                    {{ $t('admin.account.email') }}
                </th>
                <th class="cl3 min_85 text-center">
                    {{ $t('admin.account.role') }}
                </th>
                <th class="cl4 min_85">
                    {{ $t('admin.account.2fa') }}
                </th>
                <th class="cl5 min_110">
                    {{ $t('admin.account.created_time') }}
                </th>
                <th class="cl6 min_110">
                    {{ $t('admin.account.updated_time') }}
                </th>
                <th class="cl7">{{ $t('bots.action') }}</th>
                <!--        <th class="cl11"></th>-->
                <template slot="body" slot-scope="props">
                    <tr>
                        <td class="cl1">
                            <div>{{ props.item.orderID }}</div>
                        </td>
                        <td class="cl2">
                            <div>{{ props.item.email || "kyrieirving_nba2k@gmail.com" }}</div>
                        </td>
                        <td class="cl3 text-center">
                            <div>
                                {{ props.item.role || "super admin" | uppercase }}
                            </div>
                        </td>
                        <td class="cl4 text-center">
                            <div class="status" :class="'on'">{{ 'on' | upperFirst }}
                            </div>
                        </td>
                        <td class="cl5 text-left"
                            :class="{ 'opacity-row': props.item.status == 'canceled' && props.item.executed_quantity == 0 }">
                            <div>{{ props.item.time | formatTimeStamp('YYYY-MM-DD') }}</div>
                            <div>{{ props.item.time | formatTimeStamp('HH:mm:ss') }}</div>
                        </td>
                        <td class="cl6 text-left"
                            :class="{ 'opacity-row': props.item.status == 'canceled' && props.item.executed_quantity == 0 }">
                            <div>{{ props.item.time | formatTimeStamp('YYYY-MM-DD') }}</div>
                            <div>{{ props.item.time | formatTimeStamp('HH:mm:ss') }}</div>
                        </td>
                        <td class="cl7"
                            :class="{ 'opacity-row': props.item.status == 'canceled' && props.item.executed_quantity == 0 }">
                            <div class="action-wrap">
                                <img class="btn-icon" src="@/assets/images/icon/edit-icon.svg" width="20"
                                    @click="onEdit(props.item.orderID)" />
                                <!-- <img class="btn-icon" src="@/assets/images/icon/delete-icon.svg" width="20"
                                    @click="onDelete(props.item)" /> -->
                            </div>
                        </td>
                    </tr>
                </template>
            </data-table-4>
        </div>
        <modal class="cancelOrder_modal" name="delete_modal" title="CONFIRM">
            <div slot="body" class="body-popup">
                {{ $t('bots.popup_delete_content') }}
            </div>
        </modal>
        <modal class="kyc_modal" :enableClose="enableClose" name="loadingUpload">
            <div slot="body">
                <vue-loading type="bars" v-if="isLoading" color="#2DAC91" :size="{ width: '60px', height: '80px' }">
                </vue-loading>
            </div>
        </modal>
    </div>
</template>

<script>
import rf from "../../../lib/RequestFactory";
import Modal from "../../../components/Modal";
import moment from "moment";
import COMMON_CONST from "../../../common/Const";
import SelectBox from "../../../components/SelectBox";
import DataTable4 from "../../../components/DataTable4";
import CopyButton from "../../../components/CopyButton.vue";
import { upperFirst } from "lodash";

export default {
    components: {
        Modal,
        SelectBox,
        DataTable4,
        CopyButton
    },
    data() {
        return {
            titlePage: window.i18n.t("menu.administrator.admin_account"),
            filters: {
                coin: "All",
                currency: "",
                status: "",
                searchKey: ""
            },
            coins: [],
            coinMasters: [],
            isShowCoinOption: false,
            isLoading: false,
            enableClose: false,
            tabs: [
                window.i18n.t('account.detail.openOrder.tab.spot'),
                window.i18n.t('account.detail.openOrder.tab.futures'),
            ],
            tabActive: 0,

        };
    },
    methods: {
        search() {
            let filterParams = {
                search_key: '',
                coin: '',
                currency: '',
                status: ''
            };
            if (this.filters.searchKey) {
                filterParams.search_key = this.filters.searchKey;
            }
            if (this.filters.coin) {
                filterParams.coin = this.filters.coin === 'All' ? '' : this.filters.coin;
            }
            if (this.filters.currency && this.filters.currency.id != '') {
                filterParams.currency = this.filters.currency.id;
            }
            if (this.filters.status && this.filters.status.id != '') {
                filterParams.status = this.filters.status.id;
            }
            this.$refs.datatable.filter(filterParams);
        },
        refresh() {
            // this.requestCoinList();
            this.getOptionData();
            this.resetInput();
            this.$refs.datatable.refresh();
        },
        resetInput() {
            this.filters = Object.assign(this.filters, {
                coin: "All",
                currency: "",
                searchKey: "",
                status: ""
            });
            $(".datatable").find("th").attr("data-sort-order", "");
        },
        onSearchCoin() {
            this.coins = _.filter(this.coinMasters, item => {
                return _.isMatch(item.toUpperCase(), this.filters.coin.toUpperCase());
            });
        },
        onSelectCoin(coin) {
            this.isShowCoinOption = false;
            this.filters.coin = coin === "All" ? "All" : coin.toUpperCase();
        },
        clickOut() {
            this.isShowCoinOption = false;
        },
        showCoinOption() {
            this.isShowCoinOption = true;
            this.coins = this.coinMasters;
        },
        getData(params) {
            let defaultParams = { ...params };
            delete defaultParams.limit;
            return rf
                .getRequest("OrderRequest")
                .getOrdersPending(defaultParams);
        },
        requestCoinList() {
            rf.getRequest("CoinSettingRequest")
                .getCoinList()
                .then(res => {
                    this.coinMasters = window._.map(res.data, "coin");
                });
        },
        getOptionData() {
            this.statusType = [{ id: '', name: window.i18n.t('status.all') }]
            this.markets = [{ id: '', name: window.i18n.t('status.all') }]
            rf.getRequest('OrderRequest')
                .getOptionFilterOpenOrder('status')
                .then(res => {
                    res?.data.map((item) => {
                        this.statusType.push({
                            id: item,
                            name: window.i18n.t(`common.order_status.${item}`)
                        })
                    })
                })
            rf.getRequest('OrderRequest')
                .getOptionFilterOpenOrder('currency')
                .then(res => {
                    res?.data.map((item) => {
                        this.markets.push({
                            id: item,
                            name: item.toUpperCase()
                        })
                    })
                })
            rf.getRequest('OrderRequest')
                .getOptionFilterOpenOrder('coin')
                .then(res => {
                    this.coinMasters = ['All', ...res.data]
                })
        },
        customFormatter(date) {
            return moment(date).format('YYYY - MM - DD');
        },
        onDelete(id) {
            this.showModalConfirm('delete_modal', this.deleteBot, id);
        },
        showModalConfirm(modalName, callback, params) {
            CommonModal.show(modalName, {
                position: 'center',
                mask: true,
                buttons: [
                    {
                        label: window.i18n.t('common.action.no'),
                        class: "btn-no",
                        style: {
                            background: 'rgb(191, 191, 191);'
                        },
                        callback: () => {
                            CommonModal.hide(modalName);
                        }
                    },
                    {
                        label: window.i18n.t('common.action.yes'),
                        focused: true,
                        class: "btn-yes",
                        style: {
                            background: 'rgb(0, 112, 192)'
                        },
                        callback: () => {
                            CommonModal.hide(modalName);
                            this.loadingUploadBegin();
                            callback(params);
                            this.loadingUploadFinish();
                        }
                    }
                ]
            });
        },
        deleteBot(data) {
            // rf.getRequest('AAAA').cancelOrdersPending(data).then(res => {
            //   if (res.success) {
            //     this.$toastr('success', this.$t('bots.toast_delete_success'));
            //     this.search();
            //   }
            // }).catch(error => {
            //   this.$toastr('error', this.$t('bots.toast_delete_fail'));
            //   console.error(error);
            // });
            this.$toastr('error', this.$t('bots.toast_delete_fail'));
            this.search();
        },
        loadingUploadBegin() {
            this.enableClose = false;
            this.isLoading = true;
            CommonModal.show('loadingUpload');
        },
        loadingUploadFinish() {
            CommonModal.hide('loadingUpload');
        },
        changeTab(index) {
            this.tabActive = index;
        },
        onEdit(id) {
            this.$router.push({
                name: "AdminAccountEdit",
                query: {
                    id: id
                }
            })
        }
    },
    mounted() {
        this.$emit("EVENT_PAGE_CHANGE", this);
        this.getOptionData();
    },
    created() {
    }
};
</script>

<style lang="scss">
@import "@/assets/sass/variables";

#admin_account {
    font-family: $font_family_root;

    .sc_search_select {
        .group_search_select {
            .button_drop_search {
                height: 32px;
                padding: 6px 10px 6px 10px;
                border-radius: 10px;
                font-size: $font-small;
                font-family: $font-family-roboto;
                background-color: $color-white;

                span {
                    font-size: $font-small;
                }

                i.icon-arrow1 {
                    margin-left: 5px;
                    color: $color_dove_gray;
                }

                &:hover {
                    border-color: $color-jungle-green;

                    i.icon-arrow1 {
                        color: $color-jungle-green;
                    }
                }
            }
        }
    }

    .modal-dialog {
        min-width: 400px;
        position: relative;
        top: -10%;
    }

    .modal-content {
        text-align: center;

        .modal-footer {
            text-align: center;
            margin-top: 30px;
        }

        .modal-header {
            padding: 30px 16px 16px 20px;

            .modal-title {
                font-family: Roboto-Medium;
                font-size: 18px;
                font-weight: 500;
                color: $color-grey-dark;
                text-transform: none;
            }
        }

        .modal-body {
            padding: 0;
        }

        .btn-no {
            background-color: $color-bright-gray;
            border-color: $color-bright-gray;
            color: $color-black;

            &:hover {
                opacity: 0.7;
            }
        }
    }
}
</style>

<style lang="scss" scoped>
@import "@/assets/sass/variables";

// .min_85 {
//   min-width: 85px;
// }
// .min_110 {
//   min-width: 110px;
// }
.w_125 {
    width: 125px;
}

.text-left {
    text-align: left;
}

.date-text {
    font-size: $font-root;
    color: $color-grey-dusty;
    font-family: $mulish-regular;
    margin-bottom: 7px;
    padding-left: 5px;
    width: 100%;
}

body.modal-open {
    overflow: hidden;
}

.empty-data {
    text-align: center;
    padding-top: 15px !important;
    color: $color_grey;
    font-size: 14px;
    background-color: $color_white;
}

.opacity-row {
    opacity: 0.4;
}

.text-wrap {
    word-wrap: break-word;
}

.admin_account {
    .filter-container_order {
        margin: 10px 0;
        margin-bottom: 24px;
        height: 60px;

        .filter-item {
            float: left;
            margin: 0 5px;

            &:nth-child(3) {
                width: 180px;
            }

            &.right {
                float: right;
            }

            input {
                margin-top: 27px;
                display: inline-block;
                width: 145px;

                &:hover,
                &:active,
                &:focus {
                    border-color: $color-jungle-green;
                }
            }

            select {
                width: 65px;
                display: inline-block;
                font-family: $font-family-roboto;
                font-size: $font_root;

                &:hover,
                &:active,
                &:focus {
                    border-color: $color-jungle-green;
                }
            }

            button {
                margin: 0 5px;
                width: auto;
                margin-top: 27px;
                height: 32px;
                font-size: $font-smaller;
                font-family: $font-family-roboto-bold;
                border-radius: 10px;
            }

            .btn-reset {
                display: flex;
                align-items: center;
                font-size: $font-smaller;
                border: 1px solid $color-caribbean-green;
                color: $color_white;
                text-transform: uppercase;
                background-color: $color-caribbean-green;
                font-weight: 700;
                border-radius: 10px;
                padding: 0 20px;
                height: 32px;
                gap: 4px;
                margin-top: 27px;

                &:hover {
                    background-color: $color-aquamarine;
                    border-color: $color-aquamarine;
                }

                span {
                    color: $color-white;
                }
            }

            .btn-primary {
                padding: 0;
                background-color: $color-caribbean-green;
                font-size: $font-smaller;

                &:hover {
                    background-color: $color-aquamarine;
                    border-color: $color-aquamarine;
                }
            }

            .coin-selector {
                width: 75px;
                position: relative;

                .coin-input {
                    display: inline-block;
                    margin: 0 5px;
                    margin-right: 0px;
                    border-radius: 10px !important;
                    background-color: $color-white;
                    height: 32px;
                    width: 100%;
                }

                .coin-option {
                    background-color: $color-white;
                    max-height: 210px;
                    display: block;
                    overflow-y: auto;
                    margin-top: 5px;
                    position: absolute;
                    width: 79px;
                    left: 5px;
                    z-index: 10;
                    padding: 12px 0px;
                    box-shadow: 0px 3px 12px rgba(49, 49, 49, 0.51);
                    border-radius: 10px;

                    &:after {
                        bottom: 100%;
                        left: 50%;
                        border: solid transparent;
                        content: " ";
                        display: block;
                        height: 0;
                        width: 0;
                        position: absolute;
                        pointer-events: none;
                        border-color: rgba(136, 183, 213, 0);
                        border-bottom-color: $color_white;
                        border-width: 5px;
                        margin-left: -5px;
                    }

                    .option {
                        display: block;
                        width: 100%;
                        line-height: 20px;
                        cursor: pointer;
                        padding: 3px 14px;
                        overflow: hidden;

                        &:hover {
                            color: $color-jungle-green;
                        }
                    }
                }
            }

            .form-control {
                background: $color-white-smoke;
                height: 32px;
                font-size: 13px;
                padding-left: 11px;
                padding-right: 0;
                border-radius: 10px !important;
            }
        }
    }

    .text-pair {
        padding-left: 10px;
    }

    .bw-element {
        float: left;
        font-size: 15px;
        margin-left: 10px;
        height: 32px;
        line-height: 32px;
        margin-right: 5px;
        color: $color-grey-dusty;
    }

    .tabs-container {
        display: flex;
        margin: 30px 0;
        margin-bottom: 15px;

        .tab-item {
            font-weight: 600;
            padding: 16px 10px;
            padding-right: 30px;
            color: $color-gray-dark;
            cursor: pointer;

            &:hover {
                background-color: $color-bright-gray;
            }

            &.tab-active {
                border-bottom: 2px solid $color-wood-smoke;
                color: $color-wood-smoke;
            }
        }
    }
}

.icon-notfound {
    font-size: 40px;
    padding-right: 15px;
}

.toolbar-element {
    display: inline-block;
    float: left;
    width: 80px;
}

.toolbar-element2 {
    display: inline-block;
    float: left;
    width: 120px;
}

.action-wrap {
    display: flex;
    flex: 1;
    gap: 12px;

    .btn-icon {
        cursor: pointer;
    }
}

th.cl4 {
    padding: 25px;
}

.status {
    height: 32px;
    width: 50px;
    color: $color-wood-smoke;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;

    &.on {
        background-color: $color-filled-status;
    }

    &.off {
        background-color: $color-cancel-status;
    }
}
</style>