<template>
  <div class="notice_pages boxCore clearfix mg_bot50">
    <div class="list-notice">
      <div class="filter-container">
        <div class="filter-item date">
          <div class="text-pair">{{ $t('notice.start_at') }}:</div>
          <date-picker
            v-model="startDate"
            class="date-picker-input no-right-radius"
            :format="customFormatter"
          ></date-picker>
        </div>
        <div class="filter-item date">
          <div class="text-pair">{{ $t('notice.end_at') }}:</div>
          <date-picker
            v-model="endDate"
            class="date-picker-input no-left-radius"
            :format="customFormatter"
          ></date-picker>
        </div>
        <div class="filter-item">
          <input
            title=""
            type="text"
            :placeholder="$t('notice.search_by_title')"
            @keyup.enter="search"
            class="form-control"
            name="searchKey"
            v-model="filters.searchKey"
          />
        </div>
        <div class="filter-item">
          <button class="btn btn-primary" @click="search()">
            {{ $t('notice.search') }}
          </button>
          <button class="btn btn-reset" @click="refresh()">
            {{ $t('notice.reset') }}
          </button>
        </div>
        <div class="filter-item right">
          <router-link class="btn btn-success" to="/notice/edit"
            ><span class="icon-plus"></span>
            {{ $t('notice.new_title_page') }}</router-link
          >
        </div>
      </div>
    </div>
    <div class="datatable" style="width: 100%">
      <data-table
        :getData="getListNotices"
        ref="list_notice"
        :column="5"
        class="scroll"
        :storageName="'objTableNotice'"
      >
        <th class="text-left width-title" data-sort-field="title">
          {{ $t('notice.title') }}
        </th>
        <th class="text-left width-support" data-sort-field="support_url">
          {{ $t('notice.support') }}
        </th>
        <th class="text-left width-date" data-sort-field="started_at">
          {{ $t('notice.start_at') }}
        </th>
        <th class="text-left width-date" data-sort-field="ended_at">
          {{ $t('notice.end_at') }}
        </th>
        <th class="text-left"></th>
        <template slot="body" slot-scope="props">
          <tr>
            <td class="text-left width-title">
              <div class="over-flow-text" :title="props.item.title">
                {{ props.item.title }}
              </div>
            </td>
            <td class="text-left width-support">
              <div class="over-flow-text" :title="props.item.support_url">
                {{ props.item.support_url }}
              </div>
            </td>
            <td class="text-left width-date">
              {{ props.item.started_at | formatTimeStamp('YYYY - MM - DD') }}
            </td>
            <td class="text-left width-date">
              {{ props.item.ended_at | formatTimeStamp('YYYY - MM - DD') }}
            </td>
            <td class="text-center">
              <span
                class="btnForm bt-action btn-icon icon-edit"
                @click="editNotice(props.item)"
              ></span>
              <span
                class="btnForm bt-delete btn-icon icon-close2"
                @click="deleteNotice(props.item.id)"
              ></span>
            </td>
          </tr>
        </template>
      </data-table>
    </div>
    <modal name="deleteNotice" width="420" height="259" title="">
      <div
        slot="body"
        class="body-popup"
        style="
          font-size: 16px;
          padding: 10px 33px 0px;
          text-align: center;
          font-weight: 600;
        "
      >
        {{ $t('notice.delete_data') }}
      </div>
    </modal>
  </div>
</template>

<script>
import rf from '../../lib/RequestFactory'
import Modal from '../../components/Modal'
import DatePicker from 'vuejs-datepicker'
import moment from 'moment'

export default {
  components: {
    DatePicker,
    Modal,
  },
  data() {
    let data = {
      titlePage: window.i18n.t('menu.notices'),
      noticeSearchKey: '',
      detail: false,
      startDate: moment().subtract(1, 'week').toDate(),
      endDate: moment().toDate(),
      filters: {
        searchKey: '',
      },
    }

    if (!localStorage.getItem('objTableNotice')) {
      try {
        let storageData = JSON.parse(localStorage.getItem('objTableNotice'))

        data.noticeSearchKey = storageData.noticeSearchKey
        data.startDate = storageData.startDate
        data.endDate = storageData.endDate
        data.filters.searchKey = storageData.searchKey
      } catch (ex) {
        console.error(ex)
      }
    } else {
      let storageData = {
        startDate: data.startDate,
        endDate: data.endDate,
        searchKey: data.filters.searchKey,
        page: 1,
      }
      localStorage.setItem('objTableNotice', JSON.stringify(storageData))
    }

    return data
  },

  methods: {
    customFormatter(date) {
      return moment(date).format('YYYY - MM - DD')
    },
    search() {
      let filterParams = this.getRangeDate()
      if (this.startDate > this.endDate) {
        this.$toastr('error', this.$t('common.date.warning'))
        return
      }
      if (this.filters.searchKey) {
        filterParams.search_key = this.filters.searchKey
      }
      this.saveFilterParam()
      this.$refs.list_notice.filter(filterParams)
    },

    refresh() {
      this.resetInput()
      this.search()
    },

    resetInput() {
      this.filters = Object.assign({ searchKey: '' })
      this.startDate = moment().subtract(1, 'week').toDate()
      this.endDate = moment().toDate()
    },

    getListNotices(params) {
      const defaultParams = this.getRangeDate()
      try {
        let storageData = JSON.parse(localStorage.getItem('objTableNotice'))
        params.search_key = storageData.searchKey
        params.page = storageData.page
        params.limit = storageData.limit
      } catch (ex) {
        console.log(ex)
      }
      return rf
        .getRequest('AdminRequest')
        .getNotices(Object.assign(defaultParams, params))
    },

    getRangeDate() {
      let defaultParams = {}
      if (this.startDate) {
        defaultParams.start_date = moment(this.startDate)
          .startOf('day')
          .format('x')
      }
      if (this.endDate) {
        defaultParams.end_date = moment(this.endDate).endOf('day').format('x')
      }
      return defaultParams
    },

    editNotice(row) {
      this.$router.push({
        path: '/notice/edit',
        query: {
          id: row ? row.id : null,
        },
      })
    },

    deleteNotice(row) {
      ConfirmationModal.show({
        type: 'confirm',
        title: this.$t('notice.delete_data'),
        content: '',
        btnCancelLabel: this.$t('common.action.no'),
        btnConfirmLabel: this.$t('common.action.yes'),
        size: 'small',
        onConfirm: () => {
          return rf
            .getRequest('AdminRequest')
            .deleteNotice(row)
            .then((res) => {
              this.$toastr('success', this.$t('common.alert.delete.success'))
              this.search()
            })
        },
        onCancel: () => {},
      })
    },

    saveFilterParam() {
      try {
        let storageData = JSON.parse(localStorage.getItem('objTableNotice'))
        storageData.startDate = this.startDate
        storageData.endDate = this.endDate
        storageData.searchKey = this.filters.searchKey
        storageData.page = 1
        localStorage.setItem('objTableNotice', JSON.stringify(storageData))
      } catch (ex) {
        console.error(ex)
      }
    },
  },

  mounted() {
    this.$emit('EVENT_PAGE_CHANGE', this)
  },
}
</script>

<style lang="scss">
@import '@/assets/sass/variables';
.datatable {
  .sc_search_select {
    .group_search_select {
      .button_drop_search {
        min-width: 55px !important;
      }
    }
  }
}
.date-picker-input {
  display: inline-block;
  float: left;
  input {
    padding: 0 5px 0px 23px;
    width: 120px !important;
    border-radius: 3px;
    -webkit-box-shadow: none;
    box-shadow: none;
    height: 30px;
    line-height: 30px;
    border: 1px solid #d2d6de;
    background-image: url('@/assets/images/icon/date-time.svg') !important;
    background-color: $color-white-smoke !important;
    background-size: 12px;
    background-position: center left 5px;
    background-repeat: no-repeat !important;
    color: $color-grey-dusty;
    font-size: $font-small;
    font-family: $font-family-roboto;
    &:hover,
    &:active,
    &:focus {
      border-color: $color-jungle-green;
      z-index: 9;
    }
  }
}
.no-left-radius {
  input {
    border-radius: 0px 3px 3px 0px;
    margin-left: -1px;
  }
}
.no-right-radius {
  input {
    border-radius: 3px 0px 0px 3px;
  }
}
</style>

<style lang="scss" scoped>
@import '@/assets/sass/variables';
.notice_pages {
  .list-notice {
    width: 100%;
    display: block;
  }
  .filter-container {
    margin: 10px 0px 30px 0px;
    float: left;
    width: 100%;
    .filter-item {
      float: left;
      input {
        display: inline-block;
        margin-top: 23px;
        margin-left: 25px;
        height: 30px;
        &:hover,
        &:active,
        &:focus {
          border-color: $color-jungle-green;
        }
      }
      .form-control {
        width: 145px;
        background: $color-white-smoke;
        font-family: $font-family-roboto;
      }
      button {
        margin: 0 5px;
        width: auto;
        margin-top: 23px;
        margin-right: 0;
        margin-left: 5px;
        font-family: $font-family-roboto;
      }
      .btn-reset {
        text-transform: uppercase;
        height: 30px;
        padding: 0 20px;
        font-size: $font-smaller;
        background-color: $color-caribbean-green;
        border: 1px solid $color-caribbean-green;
        width: 85px;
        color: $color-white;
        font-weight: 600;
        &:hover {
          border-color: $color-aquamarine;
          background-color: $color-aquamarine;
        }
      }
      .btn-primary {
        padding: 0;
        height: 30px;
        margin-left: 10px;
        font-size: $font-smaller;
        min-width: 85px;
        background-color: $color-caribbean-green;
        border: 1px solid $color-caribbean-green;
        &:hover {
          border-color: $color-aquamarine;
          background-color: $color-aquamarine;
        }
      }
      .btn-success {
        background-color: $color-caribbean-green;
        height: 30px;
        line-height: 30px;
        padding: 0 15px;
        margin-left: 15px;
        border: 0px;
        text-transform: uppercase;
        font-size: $font-smaller;
        min-width: 200px;
        font-family: $font-family-roboto;
        font-weight: 600;
        &:hover {
          border-color: $color-aquamarine;
          background-color: $color-aquamarine;
        }
        .icon-plus {
          font-size: 10px;
          margin-right: 3px;
          // &:before{
          //   height: 10px;
          //   width: 10px;
          //   }
        }
      }
      a {
        margin-top: 23px;
      }
      .text-pair {
        color: $color-grey-dusty;
        margin-bottom: 5px;
        font-family: $font-family-roboto;
        font-size: $font-small;
      }
    }
    .right {
      float: right;
    }
  }

  .search_input {
    width: 250px;
    display: inline-block;
    margin: 0px 10px;
  }

  .datatable {
    table {
      td {
        font-size: 14px;
        span {
          &.btn-icon {
            cursor: pointer;
            color: $color-jungle-green;
            padding: 0;
            line-height: 20px;
            &:hover {
              color: $color-blue-custom;
            }
          }
        }
      }
      .icon-close2 {
        margin: 0 10px;
        font-size: $font_semi_big;
      }
      .icon-edit {
        font-size: $font_semi_big;
      }
    }
    .width-title {
      width: 30%;
      .over-flow-text {
        max-width: 260px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .width-support {
      width: 30%;
      .over-flow-text {
        max-width: 400px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .width-date {
      width: 15%;
    }
  }
}
</style>
