<template>
  <div id="base_referral_setting" class="boxCore">
    <div class="transaction">
      <div class="clearfix clearfix-10"></div>
      <span class="tit_enable_referral">{{ $t('referral_item.referral_enable') }}</span>
      <label class="switch">
        <input type="checkbox" v-model="isActive" @click="changeStatus">
        <span>
          <i></i>
        </span>
      </label>

      
      <div  class="clearfix clearfix-25"></div>

      <div class="box_content_referral clearfix">
        <div class="left_content_referral clearfix">
          <div v-if="isEdit" class="group_refund_rate clearfix">
            <label class="name">{{ $t('referral_item.number_of_referral_level') }}</label>   
            <div class="gr_input_level" v-bind:class="{disabled : isEdit}">
              <currency-input v-model="level_active" :precision="8" disabled="disabled"/>
            </div>
          </div>
          <div v-else id="select_level" class="group_level_referral clearfix" >
            <label class="name">{{ $t('referral_item.number_of_referral_level') }}</label>
            <select-box disabled="disabled" class="select_right" v-model="level_active"  :options="REFERRAL_LEVEL"/>
          </div>

          <div class="clearfix clearfix-13"></div>

          <div class="box_content_level_active clearfix" :class="{'active': level_active === '1' || level_active === '2' || level_active === '3' || level_active === '4' || level_active === '5'}">
            <h2 class="title_content_level">{{ $t('referral_item.entry_program') }}</h2>
            <div class="clearfix clearfix-13"></div>
            <div class="box_level_referral clearfix" v-if="level_active === '1' || level_active === '2' || level_active === '3' || level_active === '4' || level_active === '5'">
              <label class="name">{{ $t('referral_item.refund_percent_at_level') }} 1</label>
              <div class="gr_input_level" v-bind:class="{disabled : isEdit, 'error': errors.has('st_over_total_percent') || errors.has('st_percent_at_lv_1') }">
                <currency-input v-model="percent_at_lv_1" v-if="isEdit" :precision="8" disabled="disabled"/>
                <currency-input v-model="percent_at_lv_1" :precision="8" v-else />
                <span class="unit" >%</span>
              </div>
            </div>
            <div class="error-box">
              <span v-show="errors.has('st_percent_at_lv_1')" class="f-left is-error">{{ errors.first('st_percent_at_lv_1') }}</span>
            </div>

            <div class="clearfix clearfix-15" v-if="level_active === '2' || level_active === '3' || level_active === '4' || level_active === '5'"></div>
            <div class="box_level_referral clearfix" v-if="level_active === '2' || level_active === '3' || level_active === '4'|| level_active === '5'" >
              <label class="name">{{ $t('referral_item.refund_percent_at_level')}} 2</label>
              <div class="gr_input_level" v-bind:class="{disabled : isEdit, 'error': errors.has('st_over_total_percent') || errors.has('st_percent_at_lv_2')}">
                <currency-input v-model="percent_at_lv_2" v-if="isEdit" :precision="8" disabled="disabled"/>
                <currency-input v-model="percent_at_lv_2" :precision="8" v-else />
                <span class="unit" >%</span>
              </div>
            </div>
            <div class="error-box">
              <span v-show="errors.has('st_percent_at_lv_2')" class="f-left is-error">{{ errors.first('st_percent_at_lv_2') }}</span>
            </div>

            <div class="clearfix clearfix-15" v-if="level_active === '3' || level_active === '4'|| level_active === '5'"></div>
            <div class="box_level_referral clearfix" v-if="level_active === '3' || level_active === '4' || level_active === '5'">
              <label class="name">{{ $t('referral_item.refund_percent_at_level') }} 3</label>
              <div class="gr_input_level" v-bind:class="{disabled : isEdit, 'error': errors.has('st_over_total_percent') || errors.has('st_percent_at_lv_3')}">
                <currency-input v-model="percent_at_lv_3" :precision="8" v-if="isEdit" disabled="disabled"/>
                <currency-input v-model="percent_at_lv_3" :precision="8" v-else />
                <span class="unit" >%</span>
              </div>
            </div>
            <div class="error-box">
              <span v-show="errors.has('st_percent_at_lv_3')" class="f-left is-error">{{ errors.first('st_percent_at_lv_3') }}</span>
            </div>

            <div class="clearfix clearfix-15" v-if="level_active === '4' || level_active === '5'"></div>
            <div class="box_level_referral clearfix" v-if="level_active === '4' || level_active === '5'">
              <label class="name">{{ $t('referral_item.refund_percent_at_level') }} 4</label>
              <div class="gr_input_level" v-bind:class="{disabled : isEdit, 'error': errors.has('st_over_total_percent') || errors.has('st_percent_at_lv_4')}">
                <currency-input v-model="percent_at_lv_4" :precision="8" v-if="isEdit" disabled="disabled"/>
                <currency-input v-model="percent_at_lv_4" :precision="8" v-else />
                <span class="unit" >%</span>
              </div>
            </div>
            <div class="error-box">
              <span v-show="errors.has('st_percent_at_lv_4')" class="f-left is-error">{{ errors.first('st_percent_at_lv_4') }}</span>
            </div>
            
            <div class="clearfix clearfix-15" v-if="level_active === '5'"></div>
            <div class="box_level_referral clearfix" v-if="level_active === '5'">
              <label class="name">{{ $t('referral_item.refund_percent_at_level') }} 5</label>
              <div class="gr_input_level" v-bind:class="{disabled : isEdit, 'error': errors.has('st_over_total_percent') || errors.has('st_percent_at_lv_5')}">
                <currency-input v-model="percent_at_lv_5" :precision="8" v-if="isEdit" disabled="disabled"/>
                <currency-input v-model="percent_at_lv_5" :precision="8" v-else />
                <span class="unit" >%</span>
              </div>
            </div>
            <div class="error-box">
              <span v-show="errors.has('st_percent_at_lv_5')" class="f-left is-error">{{ errors.first('st_percent_at_lv_5') }}</span>
            </div>
            <div class="error-box">
              <span v-show="errors.has('st_over_total_percent')" class="f-left is-error">{{ errors.first('st_over_total_percent') }}</span>
            </div>
          </div>
        </div>
        <div class="right_content_referral clearfix">
          <div v-if="isEdit" class="group_refund_rate clearfix">
            <label class="name">{{ $t('referral_item.next_program_conditional') }}</label>   
            <div class="gr_input_level" v-bind:class="{disabled : isEdit}">
              <currency-input v-model="number_people_in_next_program"  disabled="disabled"/>
              <span class="unit">{{ $t('referral_item.people') }}</span>
            </div>
          </div>
          <div v-else id="select_level" class="group_level_referral clearfix" >
            <label class="name">{{ $t('referral_item.next_program_conditional') }}</label>
            <div class="group_next_program clearfix" v-bind:class="{'error': errors.has('st_number_referrer')}">
              <currency-input v-model="number_people_in_next_program" />
              <span class="unit">{{ $t('referral_item.people') }}</span>
            </div>
          </div>
          <div class="error-box">
              <span v-show="errors.has('st_number_referrer')" class="f-left is-error">{{ errors.first('st_number_referrer') }}</span>
          </div>

          <div class="clearfix clearfix-13"></div>
          
          <div class="box_content_next_program_active clearfix" :class="{'active': level_active === '1' || level_active === '2' || level_active === '3' || level_active === '4' || level_active === '5'}">
            <h2 class="title_content_level">{{ $t('referral_item.next_program') }}</h2>
            <div class="clearfix clearfix-13"></div>
            <div class="box_level_referral clearfix" v-if="level_active === '1' || level_active === '2' || level_active === '3' || level_active === '4' || level_active === '5'">
              <label class="name">{{ $t('referral_item.refund_percent_at_level') }} 1</label>
              <div class="gr_input_level" v-bind:class="{disabled : isEdit, 'error': errors.has('st_over_total_percent_next_program') || errors.has('st_percent_at_next_program_lv_1') }">
                <currency-input v-model="percent_at_next_program_lv_1" v-if="isEdit" :precision="8" disabled="disabled"/>
                <currency-input v-model="percent_at_next_program_lv_1" :precision="8" v-else />
                <span class="unit" >%</span>
              </div>
            </div>
            <div class="error-box">
              <span v-show="errors.has('st_percent_at_next_program_lv_1')" class="f-left is-error">{{ errors.first('st_percent_at_next_program_lv_1') }}</span>
            </div>

            <div class="clearfix clearfix-15" v-if="level_active === '2' || level_active === '3' || level_active === '4' || level_active === '5'"></div>
            <div class="box_level_referral clearfix" v-if="level_active === '2' || level_active === '3' || level_active === '4'|| level_active === '5'" >
              <label class="name">{{ $t('referral_item.refund_percent_at_level')}} 2</label>
              <div class="gr_input_level" v-bind:class="{disabled : isEdit, 'error': errors.has('st_over_total_percent_next_program') || errors.has('st_percent_at_next_program_lv_2') }">
                <currency-input v-model="percent_at_next_program_lv_2" v-if="isEdit" :precision="8" disabled="disabled"/>
                <currency-input v-model="percent_at_next_program_lv_2" :precision="8" v-else />
                <span class="unit" >%</span>
              </div>
            </div>
            <div class="error-box">
              <span v-show="errors.has('st_percent_at_next_program_lv_2')" class="f-left is-error">{{ errors.first('st_percent_at_next_program_lv_2') }}</span>
            </div>

            <div class="clearfix clearfix-15" v-if="level_active === '3' || level_active === '4'|| level_active === '5'"></div>
            <div class="box_level_referral clearfix" v-if="level_active === '3' || level_active === '4' || level_active === '5'">
              <label class="name">{{ $t('referral_item.refund_percent_at_level') }} 3</label>
              <div class="gr_input_level" v-bind:class="{disabled : isEdit, 'error': errors.has('st_over_total_percent_next_program') || errors.has('st_percent_at_next_program_lv_3')}">
                <currency-input v-model="percent_at_next_program_lv_3" :precision="8" v-if="isEdit" disabled="disabled"/>
                <currency-input v-model="percent_at_next_program_lv_3" :precision="8" v-else />
                <span class="unit" >%</span>
              </div>
            </div>
            <div class="error-box">
              <span v-show="errors.has('st_percent_at_next_program_lv_3')" class="f-left is-error">{{ errors.first('st_percent_at_next_program_lv_3') }}</span>
            </div>

            <div class="clearfix clearfix-15" v-if="level_active === '4' || level_active === '5'"></div>
            <div class="box_level_referral clearfix" v-if="level_active === '4' || level_active === '5'">
              <label class="name">{{ $t('referral_item.refund_percent_at_level') }} 4</label>
              <div class="gr_input_level" v-bind:class="{disabled : isEdit, 'error': errors.has('st_over_total_percent_next_program') || errors.has('st_percent_at_next_program_lv_4')}">
                <currency-input v-model="percent_at_next_program_lv_4" :precision="8" v-if="isEdit" disabled="disabled"/>
                <currency-input v-model="percent_at_next_program_lv_4" :precision="8" v-else />
                <span class="unit" >%</span>
              </div>
            </div>
            <div class="error-box">
              <span v-show="errors.has('st_percent_at_next_program_lv_4')" class="f-left is-error">{{ errors.first('st_percent_at_next_program_lv_4') }}</span>
            </div>
            
            <div class="clearfix clearfix-15" v-if="level_active === '5'"></div>
            <div class="box_level_referral clearfix" v-if="level_active === '5'">
              <label class="name">{{ $t('referral_item.refund_percent_at_level') }} 5</label>
              <div class="gr_input_level" v-bind:class="{disabled : isEdit, 'error': errors.has('st_over_total_percent_next_program') | errors.has('st_percent_at_next_program_lv_5')}">
                <currency-input v-model="percent_at_next_program_lv_5" :precision="8" v-if="isEdit" disabled="disabled"/>
                <currency-input v-model="percent_at_next_program_lv_5" :precision="8" v-else />
                <span class="unit" >%</span>
              </div>
            </div>
            <div class="error-box">
              <span v-show="errors.has('st_percent_at_next_program_lv_5')" class="f-left is-error">{{ errors.first('st_percent_at_next_program_lv_5') }}</span>
            </div>
            <div class="error-box">
              <span v-show="errors.has('st_over_total_percent_next_program')" class="f-left is-error">{{ errors.first('st_over_total_percent_next_program') }}</span>
            </div>
          </div>

        </div>
      </div>
      
      <div class="clearfix clearfix-15"></div>

      <div class="group_refund_rate box_refund_rate_bottom clearfix">
        <label class="name">{{ $t('referral_item.refund_rate') }}</label>
        <div class="gr_input_level" v-bind:class="{disabled : isEdit, 'error': errors.has('st_refund_rate')}">
          <currency-input v-model="refund_rate" :precision="8" v-if="isEdit" disabled="disabled" />
          <currency-input v-model="refund_rate" :precision="8" v-else />
          <span class="unit">%</span>
        </div>
        <div class="error-box">
          <span v-show="errors.has('st_refund_rate')" class="f-left is-error">{{ errors.first('st_refund_rate') }}</span>
        </div>
        <div class="error-box">
          <span v-show="errors.has('st_refund_rate_too_big')" class="f-left is-error">{{ errors.first('st_refund_rate_too_big') }}</span>
        </div>
      </div>
      
      <div class="clearfix"></div>

      <div class="clearfix clearfix-30"></div>
      <div class="redirect-box">
        <button class="btn button-cancel" v-if="isEdit && isActive" @click="editSetting">{{ $t('notice.edit') }}</button>
        <div v-if="!isEdit && isActive">
          <button class="btn button-cancel" @click="cancelSetting">{{ $t('notice.cancel') }}</button>
          <button class="btn button-submit" @click="submitSetting">{{ $t('notice.submit') }}</button>
        </div>
      </div>
      <div class="clearfix clearfix-30"></div>

    </div>
  </div>
</template>

<script>
  import rf from '../../lib/RequestFactory';
  import BigNumber from 'bignumber.js';
  import moment from "moment";
  import COMMON_CONST from "../../common/Const";
  import InputOnlyNumber from "../../common/InputOnlyNumber";
  import CurrencyInput from '../../components/CurrencyInput';
  import SelectBox from '../../components/SelectBox';

  export default {
    components: {
      InputOnlyNumber,
      SelectBox,
      CurrencyInput,
    },
    props: {
    },
    data() {
      return {
        isActive: false,
        titlePage: window.i18n.t("referral_item.referral_setting"),
        REFERRAL_LEVEL: [ '1', '2', '3', '4', '5'],
        level_active: '',
        percent_at_lv_1: 0,
        percent_at_lv_2: 0,
        percent_at_lv_3: 0,
        percent_at_lv_4: 0,
        percent_at_lv_5: 0,
        refund_rate: 0,
        percent_at_next_program_lv_1: 0,
        percent_at_next_program_lv_2: 0,
        percent_at_next_program_lv_3: 0,
        percent_at_next_program_lv_4: 0,
        percent_at_next_program_lv_5: 0,
        number_people_in_next_program: 0,
        isEdit: true,

        settings: {},
        old_settings: {},
      }
    },
    watch: {
     
    },
    methods: {
      getSettings() {
        rf.getRequest('AdminRequest').getReferralSetting().then(res => {
          this.settings = res.data;
          this.old_settings = res.data;
          this.fillSetting(this.settings);
        })
      },

      fillSetting(settings) {
          if(!settings) return;
          this.isActive = settings.enable;
          this.level_active = settings.number_of_levels.toString();
          this.percent_at_lv_1 = this.removeDot(settings.refund_percent_at_level_1);
          this.percent_at_lv_2 = this.removeDot(settings.refund_percent_at_level_2);
          this.percent_at_lv_3 = this.removeDot(settings.refund_percent_at_level_3);
          this.percent_at_lv_4 = this.removeDot(settings.refund_percent_at_level_4);
          this.percent_at_lv_5 = this.removeDot(settings.refund_percent_at_level_5);
          this.refund_rate = this.removeDot(this.settings.refund_rate);
          this.percent_at_next_program_lv_1 = this.removeDot(settings.refund_percent_in_next_program_lv_1);
          this.percent_at_next_program_lv_2 = this.removeDot(settings.refund_percent_in_next_program_lv_2);
          this.percent_at_next_program_lv_3 = this.removeDot(settings.refund_percent_in_next_program_lv_3);
          this.percent_at_next_program_lv_4 = this.removeDot(settings.refund_percent_in_next_program_lv_4);
          this.percent_at_next_program_lv_5 = this.removeDot(settings.refund_percent_in_next_program_lv_5);
          this.number_people_in_next_program = this.settings.number_people_in_next_program;
      },

      changeStatus() {
        this.errors.clear();
        let params = {
          'status' : !this.isActive
        }
        rf.getRequest('AdminRequest').changeStatusReferralSetting(params);
      },

      editSetting() {
        this.isEdit = !this.isEdit;
      },

      cancelSetting() {
        this.errors.clear();
        this.fillSetting(this.old_settings);
        this.isEdit = true;
      },

      removeDot (str) {
        return new BigNumber(`${str || 0}`).toString();
      },

      async submitSetting() {
        let isValid = await this.validateSetting();
        if (!isValid) {
          return ;
        }
        let params = {
          number_of_levels : this.level_active || 0,
          refund_percent_at_level_1 : this.removeDot(this.percent_at_lv_1 || 0),
          refund_percent_at_level_2 : this.removeDot(this.percent_at_lv_2 || 0),
          refund_percent_at_level_3 : this.removeDot(this.percent_at_lv_3 || 0),
          refund_percent_at_level_4 : this.removeDot(this.percent_at_lv_4 || 0),
          refund_percent_at_level_5 : this.removeDot(this.percent_at_lv_5 || 0),
          refund_rate : this.removeDot(this.refund_rate || 0),
          refund_percent_in_next_program_lv_1 : this.removeDot(this.percent_at_next_program_lv_1 || 0),
          refund_percent_in_next_program_lv_2 : this.removeDot(this.percent_at_next_program_lv_2 || 0),
          refund_percent_in_next_program_lv_3 : this.removeDot(this.percent_at_next_program_lv_3 || 0),
          refund_percent_in_next_program_lv_4 : this.removeDot(this.percent_at_next_program_lv_4 || 0),
          refund_percent_in_next_program_lv_5 : this.removeDot(this.percent_at_next_program_lv_5 || 0),
          number_people_in_next_program : this.number_people_in_next_program || 0
        }
        console.log(params);
        rf.getRequest('AdminRequest').updateReferralSetting(params).then(res =>{
          this.getSettings();
          this.$toastr('success', this.$t("referralSetting_update_success"));
           this.isEdit = true;
        }).catch(e => {
          console.log(JSON.stringify(e));
          this.$toastr('error', e.response.data.message);
          this.settings = {
          ...this.oldSetting
          };
          this.isEdit = false;
        });

      },
      validateSetting() {
        let list_percent_at_level =  [
          this.percent_at_lv_1,
          this.percent_at_lv_2,
          this.percent_at_lv_3,
          this.percent_at_lv_4,
          this.percent_at_lv_5,
        ];
        let list_percent_at_next_program =  [
          this.percent_at_next_program_lv_1,
          this.percent_at_next_program_lv_2,
          this.percent_at_next_program_lv_3,
          this.percent_at_next_program_lv_4,
          this.percent_at_next_program_lv_5,
        ];
        
        this.errors.clear();
        if (!this.refund_rate) {
          this.errors.add({field: 'st_refund_rate', msg: this.$t('referrer.error.field_required',{field_name: this.$t('referral_setting.refund_rate')})});
        }
        if (this.refund_rate > 100) {
          this.errors.add({field: 'st_refund_rate_too_big', msg: this.$t('referral_setting.refund_rate_too_big')});
        }
        if (!this.number_people_in_next_program) {
          this.errors.add({field: 'st_number_referrer', msg: this.$t('referrer.error.field_required',{field_name: this.$t('referral_setting.next_program_conditional')})});
        }
        if (!this.percent_at_lv_1) {
          this.errors.add({field: 'st_percent_at_lv_1', msg: this.$t('referrer.error.field_required',{field_name: this.$t('referral_setting.percent_at_lv_1')})});
        }
        if (!this.percent_at_lv_2) {
          this.errors.add({field: 'st_percent_at_lv_2', msg: this.$t('referrer.error.field_required',{field_name: this.$t('referral_setting.percent_at_lv_2')})});
        }
        if (!this.percent_at_lv_3) {
          this.errors.add({field: 'st_percent_at_lv_3', msg: this.$t('referrer.error.field_required',{field_name: this.$t('referral_setting.percent_at_lv_3')})});
        }
        if (!this.percent_at_lv_4) {
          this.errors.add({field: 'st_percent_at_lv_4', msg: this.$t('referrer.error.field_required',{field_name: this.$t('referral_setting.percent_at_lv_4')})});
        }
        if (!this.percent_at_lv_5) {
          this.errors.add({field: 'st_percent_at_lv_5', msg: this.$t('referrer.error.field_required',{field_name: this.$t('referral_setting.percent_at_lv_5')})});
        }
        if (!this.percent_at_next_program_lv_1) {
          this.errors.add({field: 'st_percent_at_next_program_lv_1', msg: this.$t('referrer.error.field_required',{field_name: this.$t('referral_setting.percent_at_lv_1')})});
        }
        if (!this.percent_at_next_program_lv_2) {
          this.errors.add({field: 'st_percent_at_next_program_lv_2', msg: this.$t('referrer.error.field_required',{field_name: this.$t('referral_setting.percent_at_lv_2')})});
        }
        if (!this.percent_at_next_program_lv_3) {
          this.errors.add({field: 'st_percent_at_next_program_lv_3', msg: this.$t('referrer.error.field_required',{field_name: this.$t('referral_setting.percent_at_lv_3')})});
        }
        if (!this.percent_at_next_program_lv_4) {
          this.errors.add({field: 'st_percent_at_next_program_lv_4', msg: this.$t('referrer.error.field_required',{field_name: this.$t('referral_setting.percent_at_lv_4')})});
        }
        if (!this.percent_at_next_program_lv_5) {
          this.errors.add({field: 'st_percent_at_next_program_lv_5', msg: this.$t('referrer.error.field_required',{field_name: this.$t('referral_setting.percent_at_lv_5')})});
        }
        let sum_entry_program = 0;
        for(let i=0; i < this.level_active; i++) {
          let percent = list_percent_at_level[i] || 0;
            sum_entry_program+=parseInt(percent);
        };
        if (sum_entry_program > 100) {
          this.errors.add({field: 'st_over_total_percent', msg: this.$t('referral_setting.require_total_percent')});
        }
        let sum_next_program = 0;
        for(let i=0; i < this.level_active; i++) {
          let percent = list_percent_at_next_program[i] || 0;
            sum_next_program+=parseInt(percent);
        };
        if (sum_next_program > 100) {
          this.errors.add({field: 'st_over_total_percent_next_program', msg: this.$t('referral_setting.require_total_percent')});
        }
        if (this.errors.count() > 0) {
          return false;
        }

        return true;   
      },
      

    },
    mounted() {
      this.$emit('EVENT_PAGE_CHANGE', this);
    },
    created() {
      this.getSettings();
    }
  }
</script>

<style lang="scss" scoped>
@import "@/assets/sass/variables";
  .tit_enable_referral {
    display: inline-block;
    float: left;
    font-size: 18px;
    color: #333333;
    font-family: "Roboto-Medium", sans-serif;
    line-height: 20px;
    margin-right: 12px;
  }
  .clearfix-10 {
    display: block;
    height: 10px;
  }
  .clearfix-13 {
    display: block;
    height: 13px;
  }
  .clearfix-15 {
    display: block;
    height: 15px;
  }
  .clearfix-20 {
    display: block;
    height: 20px;
  }
  .clearfix-25 {
    display: block;
    height: 25px;
  }
  .clearfix-30 {
    display: block;
    height: 30px;
  }
  .clearfix-50 {
    display: block;
    height: 50px;
  }
  .after-none {
    &:after {
      display: none;
    }
  }
  .w_120px {
    width: 120px;
  }
  .w_150px {
    width: 150px;
  }
  .w_200px {
    width: 200px;
  }
  .title_content_level {
    font-size: $font_root;
    color: #000000;
    font-family: $font-family-roboto-medium;
    margin: 0px;
    padding: 0px;
    line-height: 16px;
  }
  .input_table {
    border-radius: 3px;
    border: solid 1px $color_alto;
    height: 28px;
    padding: 4px 12px;
    font-size: $font_root;
    color: $color-grey-dark;
    line-height: 20px;
    background-color: transparent;
    &:hover,
    &:active,
    &:focus {
      border-color: $color-jungle-green;
    }
  }
  .error {
   border: solid 1px $color-red-main !important;
   border-radius: 3px !important;
  }
  .error-box {
    color : $color-red-main;
  }
  .is-error {
        color: #ff5252 !important;
      }
  #base_referral_setting {
    width: 740px;
    max-width: 100%;
    display: block;
    .box_content_referral {
      display: block;
      width: 100%;
      .left_content_referral {
        display: block;
        float: left;
        width: calc( 100% - 50% - 10px );
        margin-right: 20px;
        .box_content_level_active {
          padding: 20px;
          border: solid 1px transparent;
          border-radius: 2px;
          &.active {
            border: solid 1px $color_alto;
          }
        }
      }
      .right_content_referral {
        display: block;
        float: right;
        width: calc( 100% - 50% - 10px );
        .group_next_program {
          display: block;
          position: relative;
          input {
            width: 100%;
            border-radius: 3px;
            border: solid 1px $color_alto;
            height: 35px;
            background-color: transparent;
            padding: 8px 55px 8px 15px;
            line-height: 20px;
            font-size: $font_root;
            font-family: $font-family-roboto-medium;
            color: $color-grey-dark;
          }
          .unit {
            display: inline-block;
            position: absolute;
            right: 9px;
            font-size: $font_root;
            line-height: 20px;
            color: $color-grey-dark;
            font-family: $font-family-roboto;
          }
        }
        .box_content_next_program_active {
          padding: 20px;
          border: solid 1px transparent;
          border-radius: 2px;
          &.active {
            border: solid 1px $color_alto;
          }
        }
      }
    }
    .switch {
      margin-bottom: 0px;
      display: inline-block;
      vertical-align: middle;
      cursor: pointer;
      input {
        display:none;
        &:checked +span {
          background: $color-jungle-green;
          text-align: left;
          &:before {
            content: "ON";
            color: $color_white;
            font-size: 12px;
          }
          i {
            left: 35px;
          }
          &:after {
            content: " ";
            color: $color_white;
          }
        }

      }
      span {
        display: inline-block;
        padding: 0px 6px 0 9px;
        width: 54px;
        height: 20px;
        line-height: 20px;
        border-radius: 11px;
        background: $color_grey_icon_light;
        position: relative;
        color: $color_white;
        font-size: 14px;
        text-align: right;
        &:after {
          content: "OFF";
          color: $color_white;
          line-height: 20px;
          font-size: 12px;
        }
        i {
          display: inline-block;
          width: 20px;
          height: 20px;
          border-radius: 100%;
          background: $color_white;
          position: absolute;
          left: 0;
          top: 0px;
          box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.5);
          -webkit-transition: all 0.3s ease-in-out;
          -moz-transition: all 0.3s ease-in-out;
          -ms-transition: all 0.3s ease-in-out;
          -o-transition: all 0.3s ease-in-out;
          transition: all 0.3s ease-in-out;
        }
      }
    }
    .group_level_referral {
      label {
        &.name {
          font-family: $font-family-roboto;
          font-weight: 500;
          font-size: $font_root;
          line-height: 16px;
          color: $color-grey-dusty;
          margin-bottom: 5px;
        }
      }
    }
    .disabled {
      background-color: $color-bright-gray;
    }
    .box_level_referral {
      display: block;
      width: 100%;
      .gr_input_level {
        display: block;
        float: right;
        width: 135px;
        position: relative;
        input {
          width: 100%;
          border-radius: 3px;
          border: solid 1px $color_alto;
          height: 35px;
          background-color: transparent;
          padding: 8px 25px 8px 15px;
          line-height: 20px;
          font-size: $font_root;
          font-family: $font-family-roboto-medium;
          color: $color-grey-dark;
        }
        .unit {
          display: inline-block;
          position: absolute;
          right: 9px;
          font-family: $font-family-roboto-medium;
          font-size: $font_root;
          line-height: 20px;
        }
      }
      label {
        &.name {
          display: block;
          float: left;
          width: calc(100% - 135px);
          text-align: right;
          padding-top: 10px;
          padding-bottom: 10px;
          padding-right: 15px;
          font-family: $font-family-roboto;
          font-weight: 500;
          font-size: $font_root;
          line-height: 15px;
          color: $color-grey-dusty;
          margin-bottom: 0px;
          text-align: left;
        }
      }
    }
    .line_page {
      display: block;
      width: 100%;
      height: 1px;
      background-color: $color_alto;
    }
    .group_refund_rate {
      display: block;
      width: 100%;
      &.box_refund_rate_bottom {
        float: left;
        width: calc( 100% - 50% - 10px );
        margin-right: 20px;
      }
      .gr_input_level {
        display: block;
        float: right;
        width: 100%;
        position: relative;
        input {
          width: 100%;
          border-radius: 3px;
          border: solid 1px $color_alto;
          height: 35px;
          background-color: transparent;
          padding: 8px 25px 8px 15px;
          line-height: 20px;
          font-size: $font_root;
          font-family: $font-family-roboto-medium;
          color: $color-grey-dark;
        }
        .unit {
          display: inline-block;
          position: absolute;
          right: 9px;
          font-family: $font-family-roboto-medium;
          font-size: $font_root;
          line-height: 20px;
        }
      }
      label {
        &.name {
          font-family: $font-family-roboto;
          font-weight: 500;
          font-size: $font_root;
          line-height: 16px;
          color: $color-grey-dusty;
          margin-bottom: 5px;
        }
      }
    }
    .button-cancel, .button-submit {
      letter-spacing: 0.92px;
      width: 90px;
      text-align: center;
      padding: 5px;
      font-weight: 500;
      font-family: $font-family-roboto-bold;
      background-color: $color-caribbean-green;
      border: 1px solid $color-caribbean-green;
      line-height: 20px;
      height: 35px;
      border-radius: 3px;
      font-size: $font-smaller;
      color: $color-white;
      text-transform: uppercase;
      transition: 0.5s;
      float: left;
      &.disable {
        cursor: not-allowed;
      }
      &:hover{
        background-color:$color-aquamarine;
        border : 1px solid $color-aquamarine;
        color: $color-white;
      }
    }
    .button-submit {
      margin-left: 15px;
    }
  }
</style>

<style lang="scss">  
@import "@/assets/sass/variables";
  #base_referral_setting {
    .sc_search_select {
      float: left;
      width: 100%;
      font-family: $font-family-roboto-medium;
      &[disabled] {
        background-color: $color-bright-gray;
      }
      .group_search_select {
        .button_drop_search {
          height: 35px !important;
          padding: 8px 15px !important;
          font-size: $font_root;
          font-family: $font-family-roboto-medium;
          span {
            font-size: $font_root;
            font-family: $font-family-roboto-medium;
            color: $color-grey-dark;
          }
          i {
            color: $color-grey-dark;
          }
        }
        .list_search_select {
          // &:after {
          //   display: none;
          // }
          li {
            font-size: $font_root;
            font-family: $font-family-roboto-medium;
            &:hover, &:active, &:focus {
              color: $color-grey-dark;
            }
          }
        }
      }
    }
  }

  #select_level {
    .sc_search_select {
      &[disabled] {
        background-color: transparent;
      }
    }
  }
</style>