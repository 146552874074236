import BaseRequest from '../lib/BaseRequest'

export default class SalespointRequest extends BaseRequest {
  
  getBuyHistory(params) {
    let url = '/admin/api/salespoint/buy-history';
    return this.get(url, params);
  }
  getCashBack(params) {
    let url = '/admin/api/salespoint/cash-back-history';
    return this.get(url, params);
  }
  priceSetting(id, params) {
    let url = `/admin/api/aml-settings/${id}`;
    return this.put(url, params);
  }
  getPrice() {
    let url = `/admin/api/aml-settings`;
    return this.get(url);
  }  

}