export default class BaseRequest {
  get(url, params = {}) {
    return new Promise((resolve, reject) => {
      window.axios
        .get(process.env.VUE_APP_API_URL + url, {
          params: params
        })
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  }

  async getFuture(url, params = {}) {
    return new Promise((resolve, reject) => {
      window.axios
          .get(process.env.VUE_APP_API_FUTURE_URL + url, {
            params: params
          })
          .then(function (response) {
            resolve(response.data);
          })
          .catch(function (error) {
            reject(error);
          });
    });
  }

  put(url, data = {}) {
    return new Promise((resolve, reject) => {
      window.axios
        .put(process.env.VUE_APP_API_URL + url, data)
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  }

  putFuture(url, data = {}) {
    return new Promise((resolve, reject) => {
      window.axios
          .put(process.env.VUE_APP_API_FUTURE_URL + url, data)
          .then(function (response) {
            resolve(response.data);
          })
          .catch(function (error) {
            reject(error);
          });
    });
  }

  post(url, data = {}) {
    return new Promise((resolve, reject) => {
      window.axios
        .post(process.env.VUE_APP_API_URL+ url, data)
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  }

  postFuture(url, data = {}) {
    return new Promise((resolve, reject) => {
      window.axios
          .post(process.env.VUE_APP_API_FUTURE_URL + url, data)
          .then(function (response) {
            resolve(response.data);
          })
          .catch(function (error) {
            reject(error);
          });
    });
  }

  del(url, params = {}) {
    return new Promise((resolve, reject) => {
      window.axios
        .delete(process.env.VUE_APP_API_URL + url, { params: params } )
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  }

  delFuture(url, params = {}) {
    return new Promise((resolve, reject) => {
      window.axios
        .delete(process.env.VUE_APP_API_FUTURE_URL + url, { params: params } )
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  }

  _responseHandler(resolve, res) {
    return resolve(res.body.data);
  }

  _errorHandler(reject, err) {
    window.app.$broadcast('EVENT_COMMON_ERROR', err);
    return reject(err);
  }

}
