<template>
  <div id="referrer_history1" class="referrer_history1">
    <div class="filter-container">
      <div class="filter-item date">
        <div class='label-text'>{{ $t('orders.open_order.from') }}</div>
        <date-picker v-model="startDate" class="date-picker-input no-right-radius"
          :format="customFormatter"></date-picker>
      </div>
      <div class="filter-item date">
        <div class='label-text'>{{ $t('orders.open_order.to') }}</div>
        <date-picker v-model="endDate" class="date-picker-input no-left-radius" :format="customFormatter"></date-picker>
      </div>
      <div class="filter-item pair">
        <div class="label-text">{{ $t('user_wallet.currency') }}</div>
        <CoinInput :key="keyCoinInput" @onCoinChange='onCoinChange' />
      </div>
      <div class="filter-item type">
        <div class="label-text">{{ $t('order.trade_history.type') }}</div>
        <select-box v-model="filters.type" :options="typeTrade" :placeholder="$t('order.order_history.all')" />
      </div>
      <div class="filter-item input_search">
        <input title type="text" :placeholder="$t('orders.open_order.search_by_email')" @keyup.enter="search"
          class="form-control input-search" name="searchKey" v-model="filters.searchKey">
      </div>
      <div class="filter-item btn-action">
        <button class="btn btn-search" @click="search()">{{ $t('orders.open_order.search') }}</button>
        <button class="btn btn-reset" @click="refresh()">{{ $t('orders.open_order.reset') }}</button>
      </div>
    </div>

    <div class="datatable">
      <data-table :getData="getHistory" :limit="10" :column='7' @DataTable:finish="onDatatableFinish"
        :msgEmptyData="$t('refferal.table.empty_msg')" ref="datatable" class="scroll">
        <th class="text-left" data-sort-field="email">{{ $t('base_deposit_withdraw_title_email') }}</th>
        <th class="text-left" data-sort-field="coin">{{ $t('base_deposit_withdraw_title_currency') }}</th>
        <th class="text-left" data-sort-field="amount">{{ $t('orders.order_history.amount') }}</th>
        <th class="text-left" data-sort-field="transaction_owner_email">{{ $t('orders.open_order.transaction_owner')
          }}</th>
        <th class="text-left" data-sort-field="order_transaction_id">{{ $t('orders.open_order.order_transaction_id')
          }}</th>
        <th class="text-left" data-sort-field="type">{{ $t('orders.open_order.type') }}</th>
        <th class="text-left" id="created_at_last_child" data-sort-field="created_at">{{
          $t('orders.open_order.created_date') }}</th>
        <template slot="body" slot-scope="props">
          <tr>
            <td class="text-left">
              <div class="item_email_user">
                <span class="txt_email_user">{{ props.item.email }}</span>
              </div>
            </td>
            <td class="text-left">
              <div class="text-break">{{ props.item.coin | uppercase }}</div>
            </td>
            <td class="text-left">
              <div class="text-break">{{ props.item.amount }}</div>
            </td>
            <td class="text-left">
              {{ props.item.transaction_owner_email }}
            </td>
            <td class="text-left">
              {{ props.item.order_transaction_id }}
            </td>
            <td class="text-left">
              {{ $t(`common.${props.item.type}_exchange`) }}
            </td>
            <td class="text-left">
              {{ convertTime(props.item.created_at) }}
            </td>
          </tr>
        </template>
      </data-table>
    </div>
  </div>
</template>

<script>
import rf from '../../lib/RequestFactory';
import BigNumber from 'bignumber.js';
import DatePicker from "vuejs-datepicker";
import moment from "moment";
import COMMON_CONST from "../../common/Const";
import SelectBox from "../../components/SelectBox";
import CoinInput from '../../common/CoinInput.vue'
import { lowerCase } from 'lodash';

export default {
  components: {
    DatePicker,
    SelectBox,
    CoinInput
  },
  props: {
    type: {
      type: String,
      default: 'deposit'
    }
  },
  data() {
    return {
      searchKey: '',
      titlePage: window.i18n.t("referral_item.referral_history"),
      startDate: moment().subtract(1, 'week').toDate(),
      endDate: moment().toDate(),
      coin: "",
      filters: {
        currency: "",
        searchKey: "",
        type: ""
      },
      coins: [],
      coinMasters: [],
      isShowCoinOption: false,
      trades: [],
      typeTrade: [
        { id: "", name: window.i18n.t('common.all') },
        { id: "spot", name: window.i18n.t('common.spot_exchange') },
        { id: "future", name: window.i18n.t('common.future_exchange') }
      ],
      markets: [],
      keyCoinInput: 0,
    }
  },
  methods: {
    onCoinChange(currency) {
      this.filters.currency = currency;
    },
    trimNumber(number) {
      return new BigNumber(number);
    },
    convertTime(time) {
      let stillUtc = moment.utc(time).toDate();
      let local = moment(stillUtc).local().format('YYYY-MM-DD HH:mm:ss');
      return local;
    },
    customFormatter(date) {
      return moment(date).format('YYYY - MM - DD');
    },
    search() {
      let filterParams = {
        start_date: (moment(this.startDate)
          .startOf("day")
          .format("x") / 1000).toFixed(0),
        end_date: (moment(this.endDate)
          .endOf("day")
          .format("x") / 1000).toFixed(0)
      };

      if (this.customFormatter(this.startDate) === this.customFormatter(this.endDate)) {
        filterParams = {
          start_date: (moment(this.startDate)
            .startOf("day")
            .format("x") / 1000).toFixed(0),
          end_date: (moment(this.startDate)
            .endOf("day")
            .format("x") / 1000).toFixed(0)
        }
      }

      if (this.customFormatter(this.startDate) > this.customFormatter(this.endDate)) {
        this.$toastr("error", this.$t("common.date.warning"));
        return;
      }
      if (this.filters.searchKey && this.filters.searchKey != "") {
        filterParams.search_key = this.filters.searchKey;
        filterParams.start_date = null;
        filterParams.end_date = null;
      }
      if (this.filters.searchKey) {
        filterParams.search_key = this.filters.searchKey;
      }
      if (this.filters.currency) {
        filterParams.currency = this.filters.currency;
      }
      if (this.filters.type.id) {
        filterParams.type = this.filters.type.id;
      }
      this.$refs.datatable.filter(filterParams);

    },
    refresh() {
      this.requestCoinList();
      this.resetInput();
      this.keyCoinInput++;
      this.$refs.datatable.refresh();
    },
    resetInput() {
      this.filters = Object.assign(this.filters, {
        currency: "",
        searchKey: "",
        type: ""
      });
      this.startDate = moment().subtract(-6, 'day').toDate();
      this.endDate = moment().toDate();
      $(".datatable").find("th").attr("data-sort-order", "");
    },

    getHistory(params) {
      if (!this.filters.searchKey || this.filters.searchKey == "") {
        params = {
          ...params,
          start_date: (moment(this.startDate)
            .startOf("day")
            .format("x") / 1000).toFixed(0),
          end_date: (moment(this.endDate)
            .endOf("day")
            .format("x") / 1000).toFixed(0)
        };
      }
      return rf.getRequest('AdminRequest').getReferralHistory(params).then(res => {
        return res.data;
      })
    },

    onDatatableFinish() {
      const datatable = this.$refs.datatable;
      if (datatable.params.sort !== 'amount') {
        return;
      }
      const result = window._.chain(datatable.rows)
        .map(item => {
          item.amount = Math.abs(parseFloat(item.amount));
          return item;
        })
        .value();

      this.$refs.datatable.rows = result;
    },

    // Get amount which hasn't calculate fee yet.
    getFullAmount(reductionAmount, fee) {
      return new BigNumber(`${Math.abs(reductionAmount)}`).add(`${fee}`).toString();

    },
    formatDateTime(date) {
      return moment(date, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss');
    },
    customFormatter(date) {
      return moment(date).format('YYYY - MM - DD');
    },
    requestCoinList() {
      rf.getRequest("CoinSettingRequest")
        .getCoinList()
        .then(res => {
          if (window._.map(res.data, "coin")) {
            this.coinMasters = window._.map(res.data, "coin");
          }
        })
        .catch(e => {
          console.error(e)
        });
    },
  },

  mounted() {
    this.$emit('EVENT_PAGE_CHANGE', this);
  },
  created() {
    this.requestCoinList();
    rf.getRequest('AdminRequest').getPriceGroupCurrency().then(res => {
      try {
        window._.map(res.data, item => {
          if (typeof item.currency !== 'undefined') {
            this.markets.push({ id: item.currency, name: item.currency.toUpperCase() });
          }
        });
      }
      catch (ex) {
        console.error(ex);
      }
    });
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/variables";

.fl-right {
  float: right;
  padding-top: 5px;
  padding-left: 5px;
}

.empty-data {
  text-align: center;
  padding-top: 15px;
  background-color: $color_white;
}

.opacity-row {
  opacity: 0.4;
}

.text-wrap {
  word-wrap: break-word;
}

.text-right-pr-32 {
  padding-right: 32px;
  text-align: right;
}

.toolbar-element2 {
  display: inline-block;
  float: left;
}

.bw-element {
  float: left;
  font-size: $font_medium;
  margin-left: 5px;
  height: 30px;
  line-height: 30px;
  margin-right: 5px;
  color: $color-grey-dusty;
}

.checkbox {
  margin-top: 0px;
  display: inline-block;
  font-size: $font_root;
  margin-bottom: 0px;
  font-weight: normal;
  height: 30px;
  line-height: 30px;

  input[type="checkbox"] {
    display: none;
  }

  span {
    position: relative;
    content: "";
    display: inline-block;
    border: 1px solid $color_alto;
    width: 16px;
    height: 16px;
    top: 5px;
    left: 8px;
    border-radius: 2px;
    margin-right: 8px;
    position: relative;
    background-color: $color_white;
  }

  input:checked {
    &+span {
      &:after {
        opacity: 0.9;
        content: '';
        position: absolute;
        width: 14px;
        height: 7px;
        background: transparent;
        top: 2px;
        left: 0px;
        border: 3px solid $color_blue_logo;
        border-top: none;
        border-right: none;
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
      }
    }
  }
}

.referrer_history1 {
  .filter-container {
    display: block;
    float: left;
    width: 100%;
    margin: 5px 0px 30px 0px;

    .filter-item {
      display: inline-block;
      float: left;
      margin: 0 0 0 0;
      margin-right: 20px;

      .label-text {
        color: #666;
        font-weight: 500;
        font-size: 14px;
        margin-bottom: 7px;
      }

      .date-picker-input {
        display: inline-block;
        float: left;

        ::v-deep {
          input {
            padding: 0 5px 0px 36px;
            width: 135px !important;
            height: 32px;
            border: 1px solid #C7CBD3 !important;
            border-radius: 10px !important;
            background-image: url('@/assets/images/icon/date-time.svg') !important;
            background-color: $color-white !important;
            background-size: 12px !important;
            background-position: center left 12px !important;
            background-repeat: no-repeat !important;
            color: $dark-1;

            &:hover,
            &:active,
            &:focus {
              border-color: $color-jungle-green;
              z-index: 9;
            }
          }
        }
      }

      &.pair {
        .coin-inputt.toolbar-element22 {
          ::placeholder {
            color: #5c5c5c !important;
          }

          input {
            padding: 8px 5px 7px 11px;
            margin: 0 0 0 0;
            width: 200px !important;
          }

          .coin-option {
            left: 0px;
          }

          .icon-coin-input {
            i {
              font-size: 16px;
              color: $color-grey-dusty;
            }
          }
        }
      }

      &.type {
        min-width: 100px;

        .sc_search_select {
          .group_search_select {
            .button_drop_search {
              text-align: center;
              min-height: 30px;
              padding-left: 14px;
              padding-top: 6px;
              font-size: 13px;

              span {
                font-size: 13px;
                font-family: $font-family-roboto;
              }

              &:hover,
              &:active {
                border-color: #2dac91;
              }
            }

            .list_search_select {
              li {
                font-family: "Roboto-Regular", sans-serif;

                span {
                  font-size: 13px;
                }

                &:hover {
                  background: $color-bright-gray;

                  .code_coin_slect,
                  .full_name_coin_select {
                    color: #2dac91;
                  }
                }
              }
            }
          }
        }
      }

      &.input_search {
        width: 145px;
        margin-right: 10px;

        input {
          margin: 27px 0 0 0;
          padding: 8px 5px 7px 16px;
          color: $color-grey-dusty;
          font-size: $font-small;
          font-family: $font-family-roboto;
          height: 30px;
          background-color: transparent;
          border-radius: 10px !important;
        }
      }

      &.btn-action {
        padding-top: 27px;
      }
    }
  }

  .btn-search {
    background: $color-caribbean-green;
    border-radius: 10px;
    color: $color_white;
    font-weight: 700;
    width: 78px;
    height: 32px;
  }

  .btn-search {
    margin: 0 10px;
  }

  .btn-reset {
    background: $color-caribbean-green;
    border-radius: 10px;
    color: $color_white;
    font-weight: 700;
    width: 95px;
    height: 32px;
    margin-left: 10px;
  }

  .form-control {
    background: $color-white-smoke;
    height: 30px;
    font-size: $font_root;
    padding-left: 11px;
    padding-right: 0;
  }

  .datatable {
    th {
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    button.btnForm.bt-action {
      padding-left: 5px !important;
      padding-right: 5px !important;
      font-weight: 400 !important;
    }
  }

  .buy {
    color: $text-color-jade;
  }

  .sell {
    color: $text-color-red;
  }

  .btn-detail {
    background-color: $color-caribbean-green;
    padding: 2px 10px;
    font-size: $font-smaller;
    color: $color-white;
    border: 1px solid $color-caribbean-green;
    border-radius: 3px;
    text-transform: uppercase;
    font-family: $font-family-roboto;

    &:hover {
      background-color: $color-aquamarine;
      border-color: $color-aquamarine;
    }

    ;
  }

  #created_at_last_child::after {
    top: 5px;
  }
}
</style>
<style lang="scss">
@import "@/assets/sass/variables";

#referrer_history1 {
  .sc_search_select {
    .group_search_select {
      .button_drop_search {
        width: 100%;
        height: 30px !important;
        padding: 5px 10px 5px 10px !important;
        border-radius: 10px;
        font-size: $font-small;
        font-family: $font-family-roboto;
        background-color: $color-white !important;

        i.icon-arrow1 {
          margin-left: 5px;
          color: $color_dove_gray;
        }

        &:hover {
          border-color: $color-jungle-green;

          i.icon-arrow1 {
            color: $color-jungle-green;
          }
        }

      }

      .box_list_search_select {
        width: 150px;
        .list_search_select {
          border-radius: 10px !important;

          li {
            font-family: $font-family-roboto;
            font-size: $font-small;

            &:hover,
            &:active,
            &:focus {
              color: $color-jungle-green;
            }
          }
        }
      }
    }
  }

  .coin-inputt {
    width: 100px;
    height: 30px !important;
    border-radius: 10px !important;
    font-size: $font-small;
    font-family: $font-family-roboto;
    background-color: $color-white !important;

    .icon-coin-input {
      position: absolute;
      left: initial;
      right: 25px;
      width: 0;

      i {
        color: $color_dove_gray !important;
      }
    }

    .coin-option {
      width: 100%;
      border-radius: 10px;

      &::-webkit-scrollbar {
        width: 0px;
      }
    }
  }
}
</style>