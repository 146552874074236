<template>
    <div class="account-logs">
        <div class="tabs-container">
            <div class="tab-item" v-for="(value, index) in tabs" :key="index"
                :class="index == tabActive && 'tab-active'" @click="tabActive = index">{{
                value }}</div>
        </div>
        <data-table-4 v-if="tabActive == 0" :getData="getData" :limit="10" :column='9' :onPageLoad="true" :inLine="true"
            @DataTable:finish="onDatatableFinish" ref="datatable" class="scroll" :totalUser="totalRecords">
            <th class="text-left " data-sort-field="id">{{ $t('account.logs.id') }}</th>
            <th class="text-left ">{{ $t('account.logs.type') }}</th>
            <th class="text-left " data-sort-field="amount">{{ $t('account.logs.amount') }}</th>
            <th class="text-left" data-sort-field="note">{{ $t('account.logs.note') }}</th>
            <th class="text-left " data-sort-field="create_time">{{ $t("account.logs.create_time") }}</th>
            <template slot="body" slot-scope="props">
                <tr>
                    <td class="text-left" style="min-width: 150px;">
                        {{ props.item.id}}
                    </td>
                    <td class="text-left" style="min-width: 134px;">
                        {{ props.item.transaction_type | uppercase }}
                    </td>
                    <td v-if="props.item.amount >= 0" class="text-left text-green">
                        {{ props.item.amount | formatCurrencyAmountDecimal(2, '0') }} {{ props.item.currency | uppercase }}
                    </td>
                    <td v-else class="text-left text-red" >
                        {{ props.item.amount | formatCurrencyAmountDecimal(2, '0') }} {{ props.item.currency | uppercase }}
                    </td>
                    <td v-if="props.item.transaction_type === 'buy'" class="text-left" style="min-width: 211px;">
                        {{ props.item.buyer_id }}
                    </td>
                    <td v-else class="text-left" style="min-width: 211px;">
                        {{ props.item.seller_id }}
                    </td>
                    <td class="text-left" style="min-width: 172px;">
                        <p>{{ formatDate(props.item.created_at) }}</p>
                        <p>{{ formatDate(props.item.created_at, true) }}</p>
                    </td>
                </tr>
            </template>
        </data-table-4>
        <data-table-futures v-else :getData="getData" :limit="10" :column='9' :onPageLoad="true" :inLine="true"
            @DataTable:finish="onDatatableFinish" ref="datatable" class="scroll" :totalUser="totalRecords">
            <th class="text-left " data-sort-field="id">{{ $t('account.logs.id') }}</th>
            <th class="text-left ">{{ $t('account.logs.type') }}</th>
            <th class="text-left " data-sort-field="amount">{{ $t('account.logs.amount') }}</th>
            <th class="text-left" data-sort-field="note">{{ $t('account.logs.note') }}</th>
            <th class="text-left " data-sort-field="create_time">{{ $t("account.logs.create_time") }}</th>
            <template slot="body" slot-scope="props">
                <tr>
                    <td class="text-left" style="min-width: 150px;">
                        {{ props.item.id}}
                    </td>
                    <td class="text-left" style="min-width: 134px;">
                        {{ props.item.type | uppercase }}
                    </td>
                    <td v-if="props.item.amount >= 0" class="text-left text-green">
                        {{ props.item.amount | formatCurrencyAmountDecimal(2, '0') }} {{ props.item.asset | uppercase }}
                    </td>
                    <td v-else class="text-left text-red" >
                        {{ props.item.amount | formatCurrencyAmountDecimal(2, '0') }} {{ props.item.asset | uppercase }}
                    </td>
                    <td v-if="props.item.transaction_type === 'buy'" class="text-left" style="min-width: 211px;">
                        {{ props.item.buyer_id }}
                    </td>
                    <td v-else class="text-left" style="min-width: 211px;">
                        {{ props.item.seller_id }}
                    </td>
                    <td class="text-left" style="min-width: 172px;">
                        <p>{{ formatDate(props.item.createdAt) }}</p>
                        <p>{{ formatDate(props.item.createdAt, true) }}</p>
                    </td>
                </tr>
            </template>
        </data-table-futures>
        <right-slide :dataDetail="dataDetail" :rightSlide="rightSlide" :onClose="handleClose" />
    </div>
</template>

<script>

import moment from "moment";
import rf from "../../lib/RequestFactory";
import RightSlide from "../../components/RightSlide";
import DataTable4 from "../../components/DataTable4.vue";

export default {
    name: "Account Detail",
    components: {
        RightSlide,
        DataTable4
    },
    data() {
        return {
            tabs: [
                window.i18n.t("account.logs.spot"),
                window.i18n.t("account.logs.futures")
            ],
            tabActive: 0,
            totalRecords: 0,
            rightSlide: false,
            dataDetail: null
        }
    },
    methods: {
        getData(params) {
            const id = this.$route.query.id
            if (this.tabActive == 0) return this.getDataSpot({ ...params, id });
            else return this.getDataFutures({ ...params, userId: id });
        },
        getDataSpot(params) {
            const filterParams = {
                page: params?.page || 1,
                size: params?.limit || 10,
                id: this.$route.query.id
            }
            if (params?.limit) delete params.limit;
            const response = rf.getRequest('UserRequest').getLogsBalanceSpot(filterParams) 
            response.then(res => {
                if (res.code === 200) {
                    this.totalRecords = res.data.total
                }
            });
            return response
        },
        getDataFutures(params) {
            const filterParams = {
                page: params?.page || 1,
                size: params?.limit || 10,
                userId: this.$route.query.id
            }
            if (params?.limit) delete params.limit;
            const response = rf.getRequest('UserRequest').getLogsBalanceFutures(filterParams) 
            response.then(res => {
                if (res.success) {
                    this.totalRecords = res.metadata.total
                }
            });
            return response
        },
        onDatatableFinish() {
            const datatable = this.$refs.datatable;
        },
        formatDate(date, isTime) {
            if (isTime) return moment(date).format('HH:mm:ss')
            return moment(date).format('YYYY-MM-DD');
        },
        handleClose() {
            this.dataDetail = null
            this.rightSlide = false
        },
        handleOpen(data) {
            this.dataDetail = data
            this.rightSlide = true
        }
    },
    // watch: {
    //     tabActive: function(newVal) {
    //         // this.$refs.datatable.filter();
    //         this.getData();
    //     }
    // },
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/variables";

.account-logs {
    .tabs-container {
        display: flex;
        margin: 20px 0;
        margin-bottom: 15px;

        .tab-item {
            font-weight: 600;
            padding: 16px 10px;
            padding-right: 30px;
            color: $color-gray-dark;
            cursor: pointer;

            &:hover {
                background-color: $color-bright-gray;
            }

            &.tab-active {
                border-bottom: 2px solid $color-wood-smoke;
                color: $color-wood-smoke;
            }
        }

        table {
            tbody {
                td {
                    p {
                        font-family: $mulish-regular !important;
                    }
                    .text-left.textGreen {
                        color: $text-color-jade;
                    }
                    .text-left.textRed {
                        color: $text-color-red;
                    }
                }
            }
        }
    }
}
</style>

<style lang="scss">
@import "@/assets/sass/variables";
</style>