<template>
    <label class="checkbox-container">
        <input type="checkbox" v-model="checked" @input="event => onChange(event)">
        <span class="checkmark"></span>
    </label>
</template>

<script>
export default {
    props: {
        checked: {
            type: Boolean,
            default: false
        },
        handleChecked: {
            type: Function,
            default: () => {}
        }
    },
    data() {
        return {
            // isChecked: false
        } 
    },
    methods: {
        onChange(event) {
            // this.isChecked = event.target.checked;
            this.handleChecked(event.target.checked);
        }
    },
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/variables";

/* The checkbox-container */
.checkbox-container {
    display: block;
    position: relative;
    padding-left: 35px;
    height: 22px;
    margin: 0 !important;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 22px;
    width: 22px;
    border-radius: 6px !important;
    background-color: #eee;
}

/* On mouse-over, add a grey background color */
.checkbox-container:hover input~.checkmark {
    background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked~.checkmark {
    background-color: $color-caribbean-green;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked~.checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid $color-white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
</style>