import BaseRequest from "../lib/BaseRequest";

export default class AdminRequest extends BaseRequest {
  clearCache() {
    let url = "/admin/api/clear-cache";
    return this.post(url);
  }

  getAffiliateAccounts(params) {
    let url = "/api/v1/order"; // Config to test an example 
    return this.get(url, params);
  }

  updateBankAccount(params) {
    let url = "/admin/api/bank-account";
    return this.put(url, params);
  }

  deleteBankAccount(params) {
    let url = "/admin/api/bank-account";
    return this.del(url, params);
  }
}
