<template>

  <header class="main-header">
    <!-- Logo -->
    <a href="/" class="logo">
      <!-- mini logo for sidebar mini 50x50 pixels -->
      <span class="logo-mini"><img src="@/assets/images/icon/logo-dark.svg" alt=""></span>
      <!-- logo for regular state and mobile devices -->
      <span class="logo-lg"><img src="@/assets/images/icon/logo-dark.svg" alt=""></span>
    </a>

    <!-- Header Navbar: style can be found in header.less -->
    <nav class="navbar_admin">
      <!-- Sidebar toggle button-->
      <a href="#" class=" button_navbar" data-toggle="push-menu" role="button"
        :class="[expand ? 'icon-menu-3' : 'icon-menu-2']" @click="changeExpand">
        <span class="sr-only">Toggle navigation</span>
      </a>

      <!-- Navbar Right Menu -->
      <div class="navbar_admin_custom">
        <ul class="navbar_nav">

          <!-- <li class="item dropdown notifications-menu">
            <a href="#" class="dropdown-toggle notify_head" data-toggle="dropdown">
              <i class="icon-bell"></i>
              <span class="number_warning">{{ unreadNotificationsCount }}</span>
            </a>
            <div class="dropdown-menu">
              <ul class="list_warning">
                <li class="header"><span>You have {{ unreadNotificationsCount }} notifications</span></li>
                <li>
                 <span>inner menu: contains the actual data</span>
                 <ul class="menu">
                   <li v-for="notification in notifications"
                       :class="{unread: !notification.read_at}"
                       @click.stop="markAsRead(notification)">
                     <a href="javascript:void(0)" :title="notification.data.data">
                       {{notification.data.data}}
                     </a>
                   </li>
                 </ul>
                </li>
                <li class="footer"><a href="#">View all</a></li>
              </ul>
            </div>
          </li> -->
          <li class="item user_navbar">
            <span class="email_user_navbar">{{ currentAdmin?.email }}</span>
            <i class="icon-menu" @click="showLogout = !showLogout">
            </i>
          </li>

          <li class="item dropdown notifications-menu">
            <a href="#" class="dropdown-toggle lang_show" :class="{ open: showLang }" data-toggle="dropdown"
              @click="showLang = !showLang">
              <span class="txt_lang_show">{{ lang }}</span>
              <i class="icon_dropdow icon-arrow1"></i>
            </a>
            <div class="dropdown-menu" v-show="showLang" @focus="showLang = false">
              <ul class="list_lang">
                <li>
                  <a @click="changeLanguage('en')" :class="{ 'active': lang == 'en' }">
                    <img class="img_lang_navbar" src="@/assets/images/flags/uk_24_36.png" height="14px" /> English
                  </a>
                </li>
                <li>
                  <a @click="changeLanguage('kr')" :class="{ 'active': lang == 'kr' }">
                    <img class="img_lang_navbar" src="@/assets/images/flags/kr_24_36.png" height="14px" /> Korean
                  </a>
                </li>
              </ul>
            </div>
          </li>
          <!-- <li class="item dropdown notifications-menu"></li> -->
        </ul>
        <div id="dropdown-logout" class="dropdown-head" v-if="showLogout" @focus="showLogout = true">
          <ul>
            <li><a href="#" @click="showModalChangePassword()">Change password</a></li>
            <li>
              <a href="#" class="" @click.stop="logout()">Logout</a>
            </li>
          </ul>
        </div>
      </div>

    </nav>
    <ChangePassword></ChangePassword>
  </header>

</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import rf from '../lib/RequestFactory';
import Utils from '@/desktop/common/Utils';
import RemoveErrorsMixin from './RemoveErrorsMixin';
import ChangePassword from '@/pages/ChangePassword';

export default {
  components: {
    ChangePassword
  },
  mixins: [RemoveErrorsMixin],
  data() {
    return {
      csrfToken: window.csrf_token,
      notifications: [],
      lang: localStorage.getItem("lang") || document.documentElement.lang,
      showLang: false,
      currentAdmin: {},
      expand: true,
      showLogout: false,
    }
  },

  computed: {
    unreadNotificationsCount() {
      return _.chain(this.notifications)
        .filter((item) => {
          return item.read_at === null;
        })
        .size()
        .value();
    },
    hideDropdownLogout() {
      return this.showLogout;
    }
  },
  methods: {
    logout() {
      localStorage.removeItem('token');
      window.axios.defaults.headers.common['Authorization'] = '';
      location.reload()
    },
    changeExpand() {
      this.expand = !this.expand;
      if (!this.expand) {
        document.body.classList.add('sidebar-collapse');
        document.getElementById('slider').classList.add('main-sidebar__mobile');
      }
      else {
        document.body.classList.remove('sidebar-collapse');
        document.getElementById('slider').classList.remove('main-sidebar__mobile');
      }
    },
    showModalChangePassword() {
      this.showLogout = false;
      window.ChangePassword.show('ChangePassword');
    },
    markAsRead(notification) {
      if (notification.read_at) {
        return;
      }
      rf.getRequest('NotificationRequest').markAsRead(notification.id).then(res => {
        notification.read_at = res.data.read_at;
      });
    },

    listenForNotification() {
      window.Echo.channel('App.Models.Admin')
        .listen('AdminNotificationUpdated', () => {
          this.getNotifications();
        });
    },

    getNotifications() {
      rf.getRequest('NotificationRequest').getNotifications().then(res => {
        this.notifications = res.data.data;
      });
    },

    changeLanguage(languge) {
      localStorage.setItem("lang", languge);
      this.$i18n.locale = languge;
      this.lang = languge;
      window.location.reload();
      // const currentRouter = this.$router.currentRoute;
      // const newQuery = Object.assign(currentRouter.query, {lang: lang});

      // const params = this.toParams(newQuery);
      // window.location.href = `${window.location.origin}${window.location.pathname}?${params}`; 
    },

    toParams(obj) {
      return Object.keys(obj).map(function (key) {
        return `${key}=${obj[key]}`;
      }).join('&');
    },

    getCurrentAdmin() {
      rf.getRequest('UserRequest').getCurrentAdmin().then(res => {
        this.currentAdmin = res.data;
      }).catch(error => {
        if (!error.response) {
          Message.error(window.i18n.t('common.message.network_error'), {}, { position: "bottom_left" });
          return;
        }
        this.convertRemoteErrors(error);
      });
    }
  },
  created() {
    let self = this;
    window.addEventListener('click', function (e) {
      if (!self.$el.contains(e.target)) {
        self.showLogout = false;
      }
    })
  },
  mounted() {
    this.getCurrentAdmin();
    window.addEventListener('click', function (e) {

    })
    // this.getNotifications();
    // this.listenForNotification();
  }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.sidebar-mini.sidebar-collapse .main-header {

  .main-header {

    .logo {
      width: 100px;

      .logo-mini {
        display: block;
        margin-left: -15px;
        margin-right: -15px;
        font-size: $font_big_20;
      }

      .logo-lg {
        display: none;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
@import "@/assets/sass/variables";

.main-header {
  position: relative;
  max-height: 100px;
  z-index: 1030;

  .logo {
    background-color: $color-eden-dark;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    transition: width 0.3s ease-in-out;
    display: block;
    float: left;
    height: 50px;
    font-size: $font_big_20;
    line-height: 50px;
    text-align: center;
    width: 230px;
    font-family: $font-family-roboto;
    padding: 0 15px;
    font-weight: 300;
    overflow: hidden;

    &:hover {
      background-color: $color-eden;
    }

    .logo-mini {
      display: none;

      img {
        width: $font_title_size_medium_small;
        height: $font_title_size_medium_small;
      }
    }

    .logo-lg {
      text-align: left;
      padding-left: 5px;

      img {
        width: $font_title_size_medium_small;
        height: $font_title_size_medium_small;
        margin-top: -20px;
        position: relative;
        top: -2px;
      }

      span {
        color: $color_white;
        font-size: $font_big_20;
        font-weight: 600;
        display: inline-block;
        line-height: 32px;
      }
    }
  }

  .navbar_admin {

    .button_navbar {
      display: block;
      width: 50px;
      height: 50px;
      float: left;
      text-align: center;
      line-height: 50px;
      overflow: hidden;
      color: $color-jungle-green;
      font-size: $font_big_20;

      &:hover {
        color: $color_eden;
      }
    }

    .navbar_admin_custom {
      float: right;
      margin-right: 20px;

      .navbar_nav {
        margin: 10px 0px 10px 0px;

        .item {
          display: inline-block;
          float: left;
          font-size: $font_root;
          color: $color-grey-dusty;
          margin-left: 8px;

          &.dropdown {
            margin-left: 24px;

            &::after {
              display: none;
            }

            .dropdown-toggle span {
              margin-right: 8px;
            }
          }

          a {
            color: $color_white;
            display: inline-block;
          }

          .notify_head {
            position: relative;
            padding-left: 10px;

            .icon-bell {
              font-size: $font_big_23;
            }

            .number_warning {
              position: absolute;
              display: block;
              min-width: 15px;
              height: 15px;
              padding: 0px 3px;
              border-radius: 8px;
              background-color: $color_corn_pale;
              text-align: center;
              line-height: 15px;
              overflow: hidden;
              top: 0px;
              left: 0px;
            }
          }

          .lang_show {
            display: inline-flex;
            align-items: center;
            float: left;
            line-height: 20px;
            height: 28px;
            border: solid 2px $color_white;
            border-radius: 8px;
            padding: 0px 10px;
            text-transform: uppercase;
            min-width: 47px;

            .icon_dropdow {
              color: $color_silver;
              font-size: 12px;
              transition: 0.3s ease all;
            }

            .txt_lang_show {
              font-weight: 500;
            }

            &.open {
              border-color: $color-jungle-green ;
              color: $color_white;

              .icon_dropdow {
                color: $color-jungle-green;
                transform: rotate(180deg);
              }
            }
          }

          .button_out_admin {
            height: 31px;
            line-height: 31px;
            color: $color-jungle-green;
            background-color: $color_white;
            padding: 0px 9px 2px;
            border: 1px solid $color-caribbean-green;
            border-radius: 3px;
            font-weight: 600;
            min-width: 75px;

            i {
              font-style: initial;
              text-transform: uppercase;
            }

            &:hover {
              border-color: $color-aquamarine;
              background-color: $color-aquamarine;
              color: $color_white;
            }
          }

          .dropdown-menu {
            color: $color_grey_text;
            left: auto;
            right: 0px;
            background: transparent;
            border: 0px;
            border-radius: 0px;
            display: block;

            >ul {
              background-color: $color_white;
              box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.4);
              position: relative;
              z-index: 999;
              padding: 10px 0px;
              list-style-type: none;
              border-radius: 10px;

              &:after {
                bottom: 100%;
                right: 10%;
                border: solid transparent;
                content: " ";
                height: 0;
                width: 0;
                position: absolute;
                pointer-events: none;
                border-color: rgba(136, 183, 213, 0);
                border-bottom-color: $color_white;
                border-width: 5px;
                margin-right: 0px;
              }

              li {
                line-height: 20px;
                font-size: $font-small;

                span {
                  padding: 0px 10px;
                  display: block;
                }

                a {
                  display: block;
                  background-color: transparent;
                  color: $color_grey_text;
                  cursor: pointer;
                  padding: 8px 10px;
                  padding-left: 16px;
                  line-height: 20px;

                  &.active {
                    background-color: $color-jungle-green-active;
                  }

                  &:hover {
                    background-color: $color-jungle-green-hover;
                  }
                }
              }
            }
          }
        }

        .user_navbar {
          margin-left: 10px;
          position: relative;

          .icon-avatar {
            font-size: $font_big_23;
            float: left;
            margin-right: 6px;
          }

          .icon-menu {
            font-size: $font_big_20;
            line-height: 1.4;
            color: $color-jungle-green;

            &:hover {
              cursor: pointer;
            }
          }

          .email_user_navbar {
            line-height: 28px;
            display: inline-block;
            float: left;
            padding-right: 20px;
          }

        }

      }

      .dropdown-head {
        font-family: $font-family-roboto-medium;
        width: 160px;
        background: $color-eden;
        margin-top: 2%;
        margin-right: 17px;
        right: 0;
        position: absolute;
        min-height: 85px;
        border-radius: 3px;

        ul {
          margin: 0;
          padding: 0;

          li:first-child {
            border-bottom: 1px solid $color-white-smoke;
          }

          li {
            position: relative;
            overflow: hidden;
            display: block;
            color: $color-white;
            font-size: 14px;
            line-height: 20px;
            padding: 15px 0px;
            text-align: center;

            a {
              font-size: $font_root;
              color: $color_white;

              &:hover {
                color: $color-jungle-green;
              }
            }
          }

        }
      }
    }
  }
}

.list_lang {
  .img_lang_navbar {
    height: 14px;
    width: 21px;
    margin-right: 5px;
    position: relative;
    top: -1px;
    box-shadow: 0px 0px 4px 0px #88888878;
  }
}
</style>