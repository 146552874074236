<template>
    <masked-input
            type="text"
            :mask="createNumberMask"
            :guide="false"
            v-bind:value="internalValue"
            v-on:input="updateValue"
            @focus="$emit('focus')"
            @blur="onBlur"
            @keyup="$emit('keyup')"
            ref="input">
    </masked-input>

</template>
<script>

    import MaskedInput from 'vue-text-mask'
    import BigNumber from 'bignumber.js';

    export default {
        components: {
            MaskedInput
        },
        props: {
            value: '',
            focusHandler: {
                default: function() {},
            },
            restrictStepPrice: false,
            precision: {},
            maxLengthCus: {
                default: 20
            },
            allowNegative: {type: Boolean, default: false},
            rationalNumber: {type: Number, default: 100000000000000000}
        },
        watch: {
            value: function(val) {
                this.updateValue(val);
            }
        },
        data() {
            return {
                internalValue: '',
                internalPrecision: 0
            };
        },
        methods: {
            createNumberMask: function(value) {
                var standardizedValue = this.standardize(value)
                var formatedValue = this.formatNumber(standardizedValue);

                var rationalNumberCnt = 0;
                var isBeforeDot = true;
                var negativeChar = /-/;

                var result = [];
                for (var i = 0; i < formatedValue.length; i++) {
                    var char = formatedValue.charAt(i);
                    if (char >= '0' && char <= '9') {
                        if(!isBeforeDot || rationalNumberCnt < this.rationalNumber){
                            result.push(/\d/);
                            rationalNumberCnt++;
                        }
                    } else if (char === '.') {
                        if(result[result.length - 1] !== negativeChar){
                            result.push(/\./);
                            isBeforeDot = false;
                        }
                    } else if (char == '-') {
                        result.push(negativeChar);
                    } else {
                        result.push(char);
                    }
                }
                return result;
            },

            updateValue: function(value) {
                if (value == undefined) {
                    this.internalValue = undefined;
                    return;
                }

                var stringValue = value && value != '-' ? this.removeExponentByBigNumber(value.toString()) : value;
                stringValue = this.restrictValueFormatted(stringValue);
                var standardizedValue = this.standardize(stringValue); // "400,000.9000" --> "400000.9000"
                var formattedValue = this.formatNumber(standardizedValue);
                this.internalValue = formattedValue;

                var newValue = standardizedValue ?
                    standardizedValue == '-' ?
                        standardizedValue :
                        new BigNumber(standardizedValue)
                    : undefined;

                if (newValue === undefined && newValue !== value) {
                    this.$emit('input', newValue);
                    return true;
                }

                if ( (value && !value.isBigNumber) || (newValue && !newValue.eq(value)) ) {
                    // If Auto format: 1.0000 --> 1,
                    // use: this.$emit('input', newValue.toString());
                    this.$emit('input', standardizedValue);
                }
            },

            restrictValueFormatted(inputValue) {
                let currentValue = inputValue + '';

                if (this.restrictStepPrice) {
                    let valueArr = currentValue.split(".");
                    if (valueArr.length > 1) {
                        let currentDecimalValue = valueArr[1];
                        if (currentDecimalValue && currentDecimalValue.length > 0) {
                            switch (this.precision) {
                                case 1:
                                    if (currentDecimalValue[0] < 5) {
                                        currentValue = valueArr[0] + '.0';
                                    } else if (currentDecimalValue[0] >= 5) {
                                        currentValue = valueArr[0] + '.5';
                                    }
                                    break;
                                case 2:
                                    if (currentDecimalValue[1] < 5) {
                                        currentValue = valueArr[0] + '.' + currentDecimalValue[0] + '0';
                                    } else if (currentDecimalValue[1] >= 5) {
                                        currentValue = valueArr[0] + '.' + currentDecimalValue[0] + '5';
                                    }
                                    break;
                                case 5:
                                default:
                            }
                        }
                    }
                    return currentValue;
                }

                return inputValue;
            },

            newBigNumber(value) {
                if (window._.isEmpty(value)) {
                    value = 0;
                }
                return new BigNumber(value);
            },

            removeExponent: function(data) {
                var data = String(data).split(/[eE]/);
                if(data.length== 1) return data[0];

                var z = '', sign= this<0? '-':'',
                    str= data[0].replace('.', ''),
                    mag= Number(data[1])+ 1;

                if(mag<0){
                    z= sign + '0.';
                    while(mag++) z += '0';
                    return z + str.replace(/^\-/,'');
                }
                mag -= str.length;
                while(mag--) z += '0';  // has error with data = 1000000000000000
                return str + z;
            },

            removeExponentByBigNumber: function(inputData) {
                var data = String(inputData).split(/[eE]/); // Eg: 5.0499999999999949500505e+15 --> ["5.0499999999999949500505", "+15"]
                if(data.length == 1) return data[0];

                var mag = Number(data[1])+ 1; // Eg: mag = 15 + 1 = 16
                var currentPrecision = Math.abs(mag) + this.internalPrecision;
                var result = this.newBigNumber(inputData).toPrecision(currentPrecision); // Eg: Result: 5049999999999994.95005

                return result;
            },

            onFocus: function() {
                this.$emit('focus');
            },

            onBlur: function() {
                this.$emit('blur');
            },

            standardize: function(value) {
                var precision = this.internalPrecision;
                if(typeof value !== 'string'){
                    value = value.toString();
                }
                var result = value.trim().replace(/[^0-9\.]/g, '');
                if (this.allowNegative && value.indexOf('-') === 0) {
                    result = '-' + result;
                }
                var dotIndex = result.indexOf('.');
                if (dotIndex == 0) {
                    result = '0' + result;
                } else if (dotIndex > 0) {
                    result = result.substring(0, dotIndex + 1) + result.substring(dotIndex + 1).replace(/[\.]/g, '');
                    if (precision > 0) {
                        result = result.slice(0, dotIndex + precision + 1);
                    } else {
                        result = result.slice(0, dotIndex);
                    }
                }
                result = this.removeLeadingZeros(result);
                return this.trimNumber(result);
            },

            trimNumber (number) {
                const dotIndex = number.indexOf('.');
                let maxLength = this.maxLengthCus;
                if(maxLength === -1) return number; // unlimited characters input with prop maxLengthCus === -1
                if (dotIndex > 0 && dotIndex < this.maxLengthCus) {
                    maxLength += 1;
                }
                if (dotIndex == this.maxLengthCus) {
                    maxLength -= 1;
                }
                if (number.length > maxLength) {
                    number = number.substring(0, maxLength);
                }
                return number;
            },

            removeLeadingZeros: function(value) {
                var result = value;
                while (true) {
                    if (result.length < 2) break;
                    if (result.charAt(0) == '0' && result.charAt(1) != '.') {
                        result = result.slice(1);
                    } else {
                        break;
                    }
                }
                return result;
            },

            formatNumber: function(value) {
                var result = value + '';
                var x = result.split('.');
                var x1 = x[0];
                var x2 = x.length > 1 ? '.' + x[1] : '';
                var rgx = /(\d+)(\d{3})/;
                while (rgx.test(x1)) {
                    x1 = x1.replace(rgx, '$1' + ',' + '$2');
                }
                result = x1 + x2;
                return result;
            },

            setPrecision(precision) {
                this.internalPrecision = precision;
            },

            focus() {
                this.$refs.input.$refs.input.focus();
            }
        },
        mounted() {
            this.$refs.input.$refs.input.autocomplete="new-password";
        },
        created() {
            this.internalPrecision = Number(this.precision);
            if (this.value) {
                this.updateValue(this.value)
            }
        },
    }
</script>