<template>
  <div id="sales_point" class="clearfix mg_bot50">
    <div class="row">
      <div class="col-xs-7">
        <div class="Rectangle-23">
          <div class="Price-setting">
            <strong>{{this.$t('sales_point.price_setting')}}</strong>
          </div>
          <div class="mini-box">
            <div class="setting_title">
              <label class="setting_price" for="">{{this.$t('sales_point.price_setting.default_price')}}<span class="aterisk">{{this.$t('sales_point.price_setting.asterisk')}}</span></label>                             
            </div>
            <div v-bind:class="{ 'Rectangle-6-Copy-9': isActive, 'Rectangle-6-Copy-9-active': !isActive, 'borderrrr': magic_flag1, 'error': errors.has('default') }">
                <currency-input :value="newPrice" :precision="2" @focus="magic_flag1 = true" @blur="magic_flag1 = false" v-bind:class="{ able: isActive, 'able-active': !isActive }" v-model="newPrice" :disabled="isActive" />
                <span class="unit" v-bind:class="{ 'abled': isActive, 'abled-active': !isActive}">{{this.$t('sales_point.price_setting.usd')}}</span>
            </div>
            <div class="BTC-000000001">
              <div>{{this.$t('sales_point.price_setting.amal_btc')}}: <span class="text-style-1"><b>{{this.prices.btc_price | formatCurrencyAmount(null, '0') }}</b></span> </div>
              <div>{{this.$t('sales_point.price_setting.amal_eth')}}: <span class="text-style-1"><b>{{this.prices.eth_price | formatCurrencyAmount(null, '0') }}</b></span> </div>
              <div>{{this.$t('sales_point.price_setting.amal_usdt')}}: <span class="text-style-1"><b>{{this.prices.usdt_price | formatCurrencyAmount(null, '0') }}</b></span> </div>
            </div>                        
          </div>
          <div v-show="errors.has('default')" class="invalid-feedback">{{ errors.first('default') }}</div>

          <div class="mini-box">
            <div class="setting_title">
              <label class="setting_price" for="">{{this.$t('sales_point.referrer_commision')}}</label>
            </div>
            <div class="mr_20" v-bind:class="{ 'Rectangle-6-Copy-9': isActive, 'Rectangle-6-Copy-9-active': !isActive, 'borderrrr': magic_flag2, 'error': errors.has('referrer_commision')  }">
              <currency-input :value="referrerCommision" :precision="2" @focus="magic_flag2 = true" @blur="magic_flag2 = false" v-bind:class="{ able: isActive, 'able-active': !isActive }" v-model="referrerCommision" :disabled="isActive" />
              <span class="unit w-30" v-bind:class="{ 'abled': isActive, 'abled-active': !isActive}">%</span>
            </div>
          </div>
          <div v-show="errors.has('referrer_commision')" class="invalid-feedback">{{ errors.first('referrer_commision') }}</div>

          <div class="mini-box">
            <div class="setting_title">
              <label class="setting_price" for="">{{this.$t('sales_point.referred_bonus')}}</label>
            </div>
            <div class="mr_20" v-bind:class="{ 'Rectangle-6-Copy-9': isActive, 'Rectangle-6-Copy-9-active': !isActive, 'borderrrr': magic_flag3, 'error': errors.has('referred_bonus')  }">
              <currency-input :value="referredBonus" :precision="2" @focus="magic_flag3 = true" @blur="magic_flag3 = false" v-bind:class="{ able: isActive, 'able-active': !isActive }" v-model="referredBonus" :disabled="isActive" />
              <span class="unit w-30" v-bind:class="{ 'abled': isActive, 'abled-active': !isActive}">%</span>
            </div>
          </div>
          <div v-show="errors.has('referred_bonus')" class="invalid-feedback">{{ errors.first('referred_bonus') }}</div>
          
          <!--  -->
          <div class="mini-box">
            <div class="setting_title">
              <label class="setting_price" for="">{{this.$t('sales_point.bonus_level_1')}}</label>
            </div>
            <div class="mr_20" v-bind:class="{ 'Rectangle-6-Copy-9': isActive, 'Rectangle-6-Copy-9-active': !isActive, 'borderrrr': magic_flag5, 'error': errors.has('bonus_1_amal')  }">
              <currency-input :placeholder="$t('salespoint.buy_history.amount')" :value="amalBonus1" :precision="0" @focus="magic_flag5 = true" @blur="magic_flag5 = false" v-bind:class="{ able: isActive, 'able-active': !isActive }" v-model="amalBonus1" :disabled="isActive" />
              <span class="unit" v-bind:class="{ 'abled': isActive, 'abled-active': !isActive}">{{this.$t('sales_point.price_setting.amal')}}</span>
            </div>
            <div v-bind:class="{ 'bonus_box': isActive, 'bonus_box_active': !isActive, 'borderrrr': magic_flag6, 'error': errors.has('bonus_1_percent')  }">
              <currency-input :placeholder="$t('salespoint.bonus')" :value="percentBonus1" :precision="2" @focus="magic_flag6 = true" @blur="magic_flag6 = false" v-bind:class="{ able: isActive, 'able-active': !isActive }" v-model="percentBonus1" :disabled="isActive" />
              <span class="unit w-30" v-bind:class="{ 'abled': isActive, 'abled-active': !isActive}">%</span>
            </div>
          </div>
          <div v-show="errors.has('bonus_1')" class="invalid-feedback">{{ errors.first('bonus_1') }}</div>

          <div class="mini-box">
            <div class="setting_title">
              <label class="setting_price" for="">{{this.$t('sales_point.bonus_level_2')}}</label>
            </div>
            <div class="mr_20" v-bind:class="{ 'Rectangle-6-Copy-9': isActive, 'Rectangle-6-Copy-9-active': !isActive, 'borderrrr': magic_flag7, 'error': errors.has('bonus_2_amal')  }">
              <currency-input :placeholder="$t('salespoint.buy_history.amount')" :value="amalBonus2" :precision="0" @focus="magic_flag7 = true" @blur="magic_flag7 = false" v-bind:class="{ able: isActive, 'able-active': !isActive }" v-model="amalBonus2" :disabled="isActive" />
              <span class="unit" v-bind:class="{ 'abled': isActive, 'abled-active': !isActive}">{{this.$t('sales_point.price_setting.amal')}}</span>
            </div>
            <div v-bind:class="{ 'bonus_box': isActive, 'bonus_box_active': !isActive, 'borderrrr': magic_flag8, 'error': errors.has('bonus_2_percent')  }">
              <currency-input :placeholder="$t('salespoint.bonus')" :value="percentBonus2" :precision="2" @focus="magic_flag8 = true" @blur="magic_flag8 = false" v-bind:class="{ able: isActive, 'able-active': !isActive }" v-model="percentBonus2" :disabled="isActive" />
              <span class="unit w-30" v-bind:class="{ 'abled': isActive, 'abled-active': !isActive}">%</span>
            </div>  
          </div>
          <div v-show="errors.has('bonus_2')" class="invalid-feedback">{{ errors.first('bonus_2') }}</div>
          
        </div>
        <div class="Rectangle-23-copy">
          <div class="mini-box">
            <div class="setting_title">
              <label class="setting_price" for="">{{this.$t('sales_point.price_setting.total_supply')}}<span class="aterisk">{{this.$t('sales_point.price_setting.asterisk')}}</span></label>
            </div> 
            <div v-bind:class="{ 'Rectangle-6-Copy-9': isActive, 'Rectangle-6-Copy-9-active': !isActive, 'borderrrr': magic_flag4, 'error': errors.has('total')  }">
              <currency-input :value="newTotalSupply" :precision="0" @focus="magic_flag4 = true" @blur="magic_flag4 = false" v-bind:class="{ able: isActive, 'able-active': !isActive }" v-model="newTotalSupply" :disabled="isActive" />
              <span class="unit" v-bind:class="{ 'abled': isActive, 'abled-active': !isActive}">{{this.$t('sales_point.price_setting.amal')}}</span>
            </div>
                             
          </div>
          <div v-show="errors.has('total')" class="invalid-feedback">{{ errors.first('total') }}</div>
          <div class="mini-box">
            <div class="setting_title">
              <label class="setting_price" for="">{{this.$t('sales_point.price_setting.remaining_supply')}}</label>
            </div>
            <div class="Rectangle-6-Copy-9">
              <currency-input :value="newRemain" :precision="8" class="able" v-model="newRemain" disabled/>  
              <span class="unit abled">{{this.$t('sales_point.price_setting.amal')}}</span>
            </div>
            <span class="BTC-000000001">
              <!-- <div>&nbsp;<span class="text-style-1">&nbsp;</span></div> -->
               <div class="form-check form_check_hiden" >
                  <input type="checkbox" v-model="newRemainAML" class=" checkbox-custom" id="exampleCheck1" :disabled="isActive">
                  <label class="form-check-label label_check_hiden checkbox-custom-label" :class="{'checkbox-custom-disable' : !newRemainAML,'checkbox-background-disable': isActive}"  for="exampleCheck1"> {{ $t('aml.setting.titlecheckbox') }}</label>
                </div>  
            </span>     
          </div>
        </div>
      </div>

      <div class="col-xs-5">
        <div class="Rectangle-23-Copy-11">
            <data-table :getData="getData" :limit="3" :column="3" ref="table">
              <th class="text-left cl_total">{{this.$t('sales_point.total_amal_sold')}}</th>
              <th class="text-left cl_currency">{{this.$t('sales_point.currency')}}</th>
              <th class="text-right cl_receive">{{this.$t('sales_point.total_received')}}</th>
              <template slot="body" slot-scope="props">
                <tr>
                  <td class="text-left cl_total">{{ props.item.sold | formatCurrencyAmount(null, '0') }}</td>
                  <td class="text-left cl_currency">{{ props.item.currency | uppercase }}</td>
                  <td class="text-right cl_receive">{{ props.item.receive | formatCurrencyAmount(null, '0')  }}</td>
                </tr>  
              </template>
            </data-table>
        </div>
      </div>
    </div>

    <div class="-Indicates-required">{{this.$t('sales_point.indeicates_required')}}</div>
    <button class="Rectangle-26-Copy-7" v-if="isActive" @click="active"><span class="text_in_button">{{this.$t('sales_point.edit')}}</span></button>
    <div v-else>
      <button class="Rectangle-26-Copy-7" @click="disable"><span class="text_in_button">{{this.$t('sales_point.return')}}</span></button>
      <button class="Rectangle-26-Copy-7" @click="settingPrice"><span class="text_in_button">{{this.$t('sales_point.submit')}}</span></button>
    </div>
  </div>
</template>

<script>
  import rf from '../../lib/RequestFactory';
  import CurrencyInput from '../../components/CurrencyInput';

  export default {
    components: {
      CurrencyInput
    },
    data() {
      return {
        usd: this.$t('sales_point.price_setting.usd'),
        aml: this.$t('sales_point.price_setting.amal'),
        isActive: true,
        titlePage: this.$t('sales_point.title'),
        prices: {
          btc_price: 0,
          eth_price: 0,
          usdt_price: 0,
          // btc_price_presenter: 0,
          // eth_price_presenter: 0,
          // usdt_price_presenter: 0,
          // btc_price_presentee: 0,
          // eth_price_presentee: 0,
          // usdt_price_presentee: 0,
          show_remaining_aml: 0,
          amal_bonus_1: 0,
          percent_bonus_1: 0,
          amal_bonus_2: 0,
          percent_bonus_2: 0,
          referrer_commision_percent: 0,
          referred_bonus_percent: 0,
        },
        magic_flag1: false,
        magic_flag2: false,
        magic_flag3: false,
        magic_flag4: false,
        magic_flag5: false,
        magic_flag6: false,
        magic_flag7: false,
        magic_flag8: false,
        newPrice: null,
        // newPricePresenter: null,
        // newPricePresentee: null,
        newTotalSupply: null,
        newRemain: null,
        newRemainAML: 0,
        amalBonus1: null,
        percentBonus1: null,
        amalBonus2: null,
        percentBonus2: null,
        referrerCommision: null,
        referredBonus: null,
      }
    },

    methods: {
      reset () {
        this.newPrice = this.prices.usd_price;
        // this.newPricePresenter = this.prices.usd_price_presenter;
        // this.newPricePresentee = this.prices.usd_price_presentee;
        this.newTotalSupply = this.prices.total;
        this.newRemain = this.prices.amount;
        this.newRemainAML = this.prices.show_remaining_aml;
        this.amalBonus1 = this.prices.amal_bonus_1;
        this.percentBonus1 = this.prices.percent_bonus_1;
        this.amalBonus2 = this.prices.amal_bonus_2;
        this.percentBonus2 = this.prices.percent_bonus_2;
        this.referrerCommision = this.prices.referrer_commision_percent;
        this.referredBonus = this.prices.referred_bonus_percent;
      },
      active () {
        this.isActive = false;
      },
      disable () {
        this.isActive = true;
        this.reset();
        this.errors.clear();
      },      
      async getData () {
        const res = await rf.getRequest('SalespointRequest').getPrice();
        this.prices = res.data;
        const res1 = await rf.getRequest('SettingRequest').getRemainAMLSetting();
        let objAppend = {
          show_remaining_aml : Number(res1.data.value),
        }
        this.prices = Object.assign(this.prices, objAppend);
        const eth = {
          sold: this.prices.eth_sold_amount,
          currency: 'ETH',
          receive: this.prices.eth_sold_money
        }
        const usd = {
          sold: this.prices.usd_sold_amount,
          currency: 'USD',
          receive: this.prices.usd_sold_money
        }
        const btc = {
          sold: this.prices.btc_sold_amount,
          currency: 'BTC',
          receive: this.prices.btc_sold_money
        }
        const usdt = {
          sold: this.prices.usdt_sold_amount,
          currency: 'USDT',
          receive: this.prices.usdt_sold_money
        }
        const show_remaining_aml = this.prices.show_remaining_aml; 
        this.reset();
        return {data: [eth, usd, btc, usdt]};
      },
      validate() {
        this.errors.clear();

        if (!this.newPrice) {
          this.errors.add({field: 'default', msg: this.$t('sales_point.error.lose_default_price')});
        }
        // if(!this.referrerCommision) {
        //   this.errors.add({field: 'referrer_commision', msg: this.$t('sales_point.error.lose_referrer_commision')});
        // }
        // if(!this.referredBonus) {
        //   this.errors.add({field: 'referred_bonus', msg: this.$t('sales_point.error.lose_referred_bonus')});
        // }
        if(!this.newTotalSupply) {
          this.errors.add({field: 'total', msg: this.$t('sales_point.error.lose_total_supply')});
        }

        if(this.newPrice <= 0) {
          this.errors.add({field: 'default', msg: this.$t('sales_point.error.price_default_price')});
        }

        if(parseFloat(this.referrerCommision) > 100) {
          this.errors.add({field: 'referrer_commision', msg: this.$t('sales_point.error.bonus_percent')});
        }

        if(parseFloat(this.referredBonus) > 100) {
          this.errors.add({field: 'referred_bonus', msg: this.$t('sales_point.error.bonus_percent')});
        }

        if(parseFloat(this.referrerCommision) == 0) {
          this.errors.add({field: 'referrer_commision', msg: this.$t('sales_point.error.bonus_diff_0')});
        }

        if(parseFloat(this.referredBonus) == 0) {
          this.errors.add({field: 'referred_bonus', msg: this.$t('sales_point.error.bonus_diff_0')});
        }
        // if(this.referrerCommision <= 0) {
        //   this.errors.add({field: 'referrer_commision', msg: this.$t('sales_point.error.price_persenter')});
        // }
        // if(this.referredBonus <= 0) {
        //   this.errors.add({field: 'referred_bonus', msg: this.$t('sales_point.error.price_persentee')});
        // }
        if(this.newTotalSupply < 0) {
          this.errors.add({field: 'total', msg: this.$t('sales_point.error.price_total_supply')});
        }

        if(!!this.amalBonus1 ^ !!this.percentBonus1) {
          this.errors.add({field: 'bonus_1', msg: this.$t('sales_point.error.bonus')});
          if (!this.amalBonus1) {
            this.errors.add({field: 'bonus_1_amal', msg: this.$t('sales_point.error.bonus')});
          } else {
            this.errors.add({field: 'bonus_1_percent', msg: this.$t('sales_point.error.bonus')});
          }
        }

        if(!!this.amalBonus2 ^ !!this.percentBonus2) {
          this.errors.add({field: 'bonus_2', msg: this.$t('sales_point.error.bonus')});
          if (!this.amalBonus2) {
            this.errors.add({field: 'bonus_2_amal', msg: this.$t('sales_point.error.bonus')});
          } else {
            this.errors.add({field: 'bonus_2_percent', msg: this.$t('sales_point.error.bonus')});
          }
        }

        if(parseFloat(this.percentBonus1) > 100) {
          this.errors.add({field: 'bonus_1', msg: this.$t('sales_point.error.bonus_percent')});
        }

        if(parseFloat(this.percentBonus2) > 100) {
          this.errors.add({field: 'bonus_2', msg: this.$t('sales_point.error.bonus_percent')});
        }

        if(parseFloat(this.percentBonus1) == 0) {
          this.errors.add({field: 'bonus_1', msg: this.$t('sales_point.error.bonus_diff_0')});
          this.errors.add({field: 'bonus_1_percent', msg: this.$t('sales_point.error.bonus_diff_0')});
        }

        if(parseFloat(this.percentBonus2) == 0) {
          this.errors.add({field: 'bonus_2', msg: this.$t('sales_point.error.bonus_diff_0')});
          this.errors.add({field: 'bonus_2_percent', msg: this.$t('sales_point.error.bonus_diff_0')});
        }

        if(this.amalBonus1 && this.amalBonus2 && parseInt(this.amalBonus1) >= parseInt(this.amalBonus2)) {
          this.errors.add({field: 'bonus_1', msg: this.$t('sales_point.error.bonus_1_2')});
          this.errors.add({field: 'bonus_1_amal', msg: this.$t('sales_point.error.bonus_1_2')});
        }

        if (this.errors.count() > 0) {
          return false;
        }

        return true;   
      },
      settingPrice () {    
        if(!this.validate()) return;
        if(this.newRemainAML != this.prices.show_remaining_aml){
        rf.getRequest('SettingRequest').updateRemainAMLSetting({});
        }
        const params = {
          total: this.newTotalSupply,
          usd_price: this.newPrice,
          usd_price_presenter: this.newPricePresenter,
          usd_price_presentee: this.newPricePresentee,
          amal_bonus_1: this.amalBonus1,
          percent_bonus_1: this.percentBonus1,
          amal_bonus_2: this.amalBonus2,
          percent_bonus_2: this.percentBonus2,
          referrer_commision_percent: this.referrerCommision,
          referred_bonus_percent: this.referredBonus,
        }
        rf.getRequest('SalespointRequest').priceSetting(this.prices.id, params)
        .then(data => {
          this.isActive = true;
          $('input').prop("disabled", true);        
          this.$toastr('success', this.$t('sales_point.update_success_msg'));
          this.getData();
          this.$refs.table.refresh();
        })
      }         
    },

    mounted() {
      this.$emit('EVENT_PAGE_CHANGE', this);
    }
  }
</script>

<style lang="scss" scoped>
@import "@/assets/sass/variables";
  
  #sales_point {
    margin-top: 10px;
    font-family: $font-family-roboto;
  }
  .checkbox-custom {
    display: none;
  }
  .checkbox-custom-label {
    display: inline-block;
    position: relative;
    vertical-align: middle;
    margin: 5px;
    cursor: pointer;
    font-weight: normal;
    font-size: 14px;
  }
  .checkbox-background-disable:before{
    background: $color_dark_custom !important;
  }
  .checkbox-custom-disable:before{
    background: $color_dark_custom !important;
  }
  .checkbox-custom + .checkbox-custom-label:before {
    content: '';
    background: $color-blue-hover;
    display: inline-block;
    vertical-align: middle;
    width: 14px;
    height: 14px;
    margin-right: 10px;
    text-align: center;
}

.checkbox-custom:checked + .checkbox-custom-label:after {
    content: "";
    padding: 1px;
    text-align: center;
    position: absolute;
    width: 6px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    top: 4px;
    left: 4px;
}
  // .form-check-label{
  //   line-height: 7px;
  //   display: -webkit-inline-box;
  // }
  // input[type="checkbox"] {
  //   -webkit-appearance: checkbox;
  //   width: 15px;
  //   height: 15px;
  //   background:white;
  //   border-radius: 3px;
  //   border:1px solid #555;
  //   cursor: pointer;
    
  // }
  //   input:checked {
  //     & + span{
  //       &:after{
  //         opacity: 0.9;
  //         content: '';
  //         position: absolute;
  //         width: 14px;
  //         height: 7px;
  //         background: transparent;
  //         top: 2px;
  //         left: 0px;
  //         border: 3px solid $color_blue_logo;
  //         border-top: none;
  //         border-right: none;
  //         transform: rotate(-45deg);
  //         -webkit-transform: rotate(-45deg);
  //         -moz-transform: rotate(-45deg);
  //         -o-transform: rotate(-45deg);
  //         -ms-transform: rotate(-45deg);
  //     }
  //   }
  // }
  .text_in_button {
    height: 29px;
    font-family: $font-family-roboto;
    font-size: $font-smaller;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 2.42;
    letter-spacing: 0.9px;
    text-align: center;
    color: $color-white;
  }  
  .AML-Setting {
    font-family: $font-family-roboto;
    font-size: $font_big;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $color-grey-dark;
    padding-bottom: 20px;
  }
  .Price-setting {
    font-family: $font-family-roboto;
    font-size: $font_root;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $color-grey-dark;
    padding-bottom: 20px;
  }
  input {
    color: $color-grey-dark;
    font-family: $font-family-roboto;
    font-size: $font_root;
    border: none;
  }
  .setting_title {
    width: 120px;
    .setting_price {
      font-family: $font-family-roboto;
      font-size: $font_root;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $color-grey-dark;
      margin-bottom: 0px;
    }
  }
  .-Indicates-required {
    font-family: $font-family-roboto;
    font-size: $font-small;
    color: $color-denim;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .Rectangle-6-Copy-9-active {
    position: relative;  
    overflow: hidden;
    display: block;
    padding-bottom: 1px;
    text-align: left;
    width: 260px;
    height: 35px;
    border-radius: 3px;
    border: solid 1px #cfcfcf;
  }
  .Rectangle-6-Copy-9 {
    position: relative;  
    overflow: hidden;
    display: block;
    padding-bottom: 1px;
    text-align: left;
    width: 260px;
    height: 35px;
    border-radius: 3px;
    border: solid 1px #cfcfcf;
  }
  .bonus_box_active {
    position: relative;  
    overflow: hidden;
    display: block;
    padding-bottom: 1px;
    text-align: left;
    width: 190px;
    height: 35px;
    border-radius: 3px;
    border: solid 1px #cfcfcf;
  }
  .bonus_box {
    position: relative;  
    overflow: hidden;
    display: block;
    padding-bottom: 1px;
    text-align: left;
    width: 190px;
    height: 35px;
    border-radius: 3px;
    border: solid 1px #cfcfcf;
  }
  .borderrrr {
    border: solid 1px #299b82;
  }
  .disable {
    padding-left: 10px;
    height: 33px;
    width: 195px;    
  }
  .abled-active {
    background-color: #f8f8f8;
  }
  .able {
    padding-left: 10px;
    height: 33px;
    width: 100%;
  }
  .able-active {
    padding-left: 10px;
    height: 33px;
    width: 195px;
    background-color: #f8f8f8;
  }  
  .unit {
    top:50%;
    transform: translateY(-50%);
    right: 0;
    position: absolute;
    width: 60px;
    text-align: center;
    text-transform: uppercase;
  }  
  .Rectangle-23 {
    padding: 25px;
    padding-bottom: 0px;
    border: solid 1px $color_alto;
  }
  .Rectangle-23-copy {
    padding: 25px;
    padding-top: 20px;
    padding-bottom: 0px;
    border: solid 1px $color_alto;
    border-top: none;
  }
  .Rectangle-23-Copy-11 {
    background-color: $color-white;
  }
  .mini-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom: 20px;
  }
  .BTC-000000001 {
    padding-left: 20px;
    font-family: $font-family-roboto;
    font-size: $font-small;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.54;
    letter-spacing: normal;
    color: $color-grey-dusty;
  }
  .BTC-000000001 .text-style-1 {
    font-weight: 500;
    color: $color-grey-dark;
  }
  .aterisk {
    height: 24px;
    font-family: $font-family-roboto;
    font-size: $font_big;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    padding-left: 5px;
    color: $color-denim;  
  }
  .Rectangle-26-Copy-7 {
    width: 95px;
    height: 35px;
    border-radius: 3px;
    margin-right: 15px;
    background-color: $color-caribbean-green;
    border-color: $color-caribbean-green;
    &:hover {
      background-color: $color-aquamarine;
      border-color: $color-aquamarine;
    }
  }
  .cl_total {
    width: 40%;
    &:after {
      content: none;
    }
  }
  .cl_currency {
    width: 20%;
    &:after {
      content: none;
    }
  }
  .cl_receive {
    width: 40%;
    &:after {
      content: none;
    }
  }
  th {
    font-family: $font-family-roboto-medium;
    padding: 5px 25px 4px 15px !important;
  }
  td {
    font-family: $font-family-roboto;
  }
  .error {
    border: solid 1px $color-red-main;
  }
  .invalid-feedback{
    padding-left: 120px;
    margin-top: -15px;
    margin-bottom: 20px;
    color: $color-red-main;
  }
  .mr_20 {
    margin-right: 20px;
  }
  .w-30 {
    width: 30px;
  }
</style>