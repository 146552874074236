<template>
    <div id="siteSettingForm">
        <div class="content_sitesetting">
          <div class="panel_content clearfix">
            <h3 class="name_page">{{$t('site_setting.general')}}</h3>
            <div class="row">
              <!-- <div class="form-group col-xs-3">
                <label for="app_name">{{$t('site_setting.app_name')}}</label>
                <input class="form-control" name="app_name" id="app_name" v-model="siteSetting.app_name">
              </div>
              <div class="form-group col-xs-3">
                <label for="short_name">{{$t('site_setting.short_name')}}</label>
                <input class="form-control" name="short_name" id="short_name" v-model="siteSetting.short_name">
              </div> -->
              <div class="form-group col-xs-6">
                <label for="site_email">{{$t('site_setting.site_email')}}</label>
                <input
                  :maxlength="maxLength"
                  class="form-control" 
                  name="email"
                  data-vv-validate-on="none"
                  v-validate="{required: false, regex: /^\S+@\S+\.\S+$/ }"
                  id="site_email" 
                  v-model="siteSetting.contact_email"
                  @focus="resetError"
                  :class="{ error: errors.has('email') }"/>
                <span class="invalid-feedback"> {{ errors.first('email') }} </span>
              </div>
              <div class="form-group col-xs-6">
                <label for="site_phone_number">{{$t('site_setting.site_phone_number')}}</label>
                <input
                  :maxlength="maxLength"
                  class="form-control"
                  :name="$t('site_setting.phone_number')"
                  id="site_phone_number"
                  v-model="siteSetting.contact_phone"
                  v-validate="{ required: false, regex: /^\+(?:[0-9] ?){6,14}[0-9]$/ }"
                  data-vv-validate-on="none"
                  @focus="resetError"
                  :class="{ error: errors.has('phone number') }"/>
                  <span class="invalid-feedback"> {{ errors.first('phone number') }} </span>
              </div>
              <!-- <div class="form-group col-xs-2">
                <label for="currency">{{$t('Currency of Country')}}</label>
                <select 
                  v-validate="'required'"
                  class="form-control"
                  name="language"
                  id="language"
                  v-model="siteSetting.currency_country"
                  :class="{ error: errors.has('language') }">
                  <option :value="currency" v-for="currency in currencies">{{ currency | uppercase }}</option>
                </select>
                <span class="invalid-feedback"> {{ errors.first('language') }} </span>
              </div> -->
            </div>
            <div class="form-group">
              <label for="site_phone_number">{{$t('site_setting.copyright')}}</label>
              <input
                :maxlength="maxLength"
                class="form-control"
                name="copyright"
                id="copyright"
                v-model="siteSetting.copyright"
                data-vv-validate-on="none"
                v-validate=""
                @focus="resetError"
                :class="{ error: errors.has('copyright') }"/>
                <span class="invalid-feedback"> {{ errors.first('copyright') }} </span>
            </div>
            <!-- <div class="form-group">
              <label for="language">{{$t('site_setting.language')}}</label>
              <select required class="form-control" name="language" id="language" v-model="siteSetting.language">
                  <option value="en">English</option>
                  <option value="vi">Vietnamese</option>
              </select>
            </div> -->
          </div>
          <div class="clearfix clearfix-15"></div>
          <div class="panel_content clearfix">
            <h3 class="name_page">{{$t('site_setting.mobile_application')}}</h3>
            <div class="row">
              <div class="form-group col-xs-4">
                <label for="ios_app_link">{{$t('site_setting.ios_app_link')}}</label>
                <input :maxlength="maxLength" class="form-control" name="ios_app_link" id="ios_app_link" v-model="siteSetting.ios_app_url">
              </div>
              <div class="form-group col-xs-4">
                <label for="android_app_link">{{$t('site_setting.android_app_link')}}</label>
                <input :maxlength="maxLength" class="form-control" name="android_app_link" id="android_app_link" v-model="siteSetting.android_app_url">
              </div>
              <div class="form-group col-xs-4">
                <label for="app_link">{{$t('site_setting.link_table')}}</label>
                <input :maxlength="maxLength" class="form-control" name="app_link" id="app_link" v-model="siteSetting.app_link">
              </div>
              <div class="col-xs-12 form-group">
                <button class="btn btn-primary mr-10" @click.stop="submit()">{{$t('button.done')}}</button>
                <button class="btn btn-primary mr-10" @click.stop="reset()">{{$t('button.reset')}}</button>
              </div>
            </div>
            <div class="clearfix clearfix-15"></div>
            <social-network></social-network>
          </div>
          <h3 class="name_page hot-search-title">{{ $t('site_setting.hot_search_setting') }}</h3>

          <div class="row-hot-search">
          <div class="col-xs-5">
            <h5>{{$t('site_setting.available_pairs')}}</h5>
            <ul class="list-group">
              <li
                v-for="item in availableItems"
                :key="item.value"
                class="list-group-item"
                :class="{ 'list-group-item-active': isSelected(item) }"
                @click="toggleSelection(item)"
              >
                {{ item.label }}
              </li>
            </ul>
          </div>
            <div class="col-xs-2 text-center">
                <div class="btn-group-vertical">
                    <button type="button" @click="moveSelectedToRight" class="btn btn-block btn-primary">>></button>
                    <button type="button" @click="moveSelectedToLeft" class="btn btn-block btn-primary"><<</button>
                </div>
            </div>
              <div class="col-xs-5">
                <h5>{{$t('site_setting.selected_pairs')}}</h5>
                <ul class="list-group">
                  <li
                    v-for="item in selectedItems"
                    :key="item.value"
                    class="list-group-item"
                    :class="{ 'list-group-item-active': isSelected(item) }"
                    @click="toggleSelection(item)"
                  >
                    {{ item.label }}
                  </li>
                </ul>
                <div 
                    v-if="selectedItems.length !== 0"
                >
                    <button class="update-btn"
                    @click="updateHotSearch()">
                      {{$t('site_setting.update')}}
                    </button>
                </div>
              </div>
            </div>
          </div>
        <div>
      </div>
    </div>     
</template>

<script>
  import rf from '../../lib/RequestFactory';
  import SocialNetwork from './SocialNetwork'
  import RemoveErrorsMixin from '../../common/RemoveErrorsMixin';
  import CONST from '../../common/Const'
  import HotSearchRequest from '../../requests/HotSearchRequest';

  export default {
    components: {
      SocialNetwork
    },
    name: 'MultiselectComponent',

    mixins: [RemoveErrorsMixin],
    data() {
      return {
        titlePage: this.$t('site_setting.site_setting'),
        searchKey: '',
        siteSetting: {},
        currencies: [],
        maxLength: CONST.MAX_LENGTH_INPUT,
        availableItems: [
          { value: 1, text: 'Item 1' },
          { value: 2, text: 'Item 5' },
          { value: 3, text: 'Item 2' },
          { value: 4, text: 'Item 4' },
          { value: 5, text: 'Item 3' }
        ],
        titlePage: 'HOT SEARCH',
        selectedItems: [],
        selected: [],
      }
    },
    methods: {
       async submit() {
        this.resetError();
        const contact_phone = this.siteSetting.contact_phone ? this.siteSetting.contact_phone : '';
        let regexPhoneNumber = new RegExp("^\\+(?:[0-9] ?){6,14}[0-9]$");
        if(contact_phone && !regexPhoneNumber.test(String(contact_phone))){
          this.errors.add({field: 'phone number', msg: this.$t('site_setting.phone_number_valid')});
          return
        }
        await this.$validator.validateAll();
        if (this.errors.any()) {
          return;
        }
        rf.getRequest('SettingRequest').updateSiteSetting(this.siteSetting.id, this.siteSetting).then(res => {
          this.$toastr('success', this.$t('site_setting.update_success_msg'));
        });
      },
      getData() {
        const hotSearchRequest = new HotSearchRequest();

        return hotSearchRequest.getHotSearch().then(res => {
          this.selectedItems = res.data.options.filter((v)=> res.data.values.includes(v.value))
          return this.availableItems = res.data.options.filter((v)=> !res.data.values.includes(v.value))
        })    
      },
      toggleSelection(item) {
      const index = this.selected.findIndex(i => i.value === item.value);
      if (index > -1) {
        this.selected.splice(index, 1);
      } else {
        this.selected.push(item);
      }
    },
    isSelected(item) {
      return this.selected.some(i => i.value === item.value);
    },
    moveSelectedToRight() {
      const selectedItems = this.availableItems.filter(item => this.selected.some(i => i.value === item.value));
      this.selectedItems = [...this.selectedItems, ...selectedItems];
      this.availableItems = this.availableItems.filter(item => !this.selected.some(i => i.value === item.value));
      this.selected = [];
    },
    moveSelectedToLeft() {
      const selectedItems = this.selectedItems.filter(item => this.selected.some(i => i.value === item.value));
      this.availableItems = [...this.availableItems, ...selectedItems];
      this.selectedItems = this.selectedItems.filter(item => !this.selected.some(i => i.value === item.value));
      this.selected = [];
    },
    updateHotSearch(){
        const hotSearchRequest = new HotSearchRequest();
        let body = []
        this.selectedItems.map((v)=> body = [...body, v.value])
        hotSearchRequest.updateHotSearch({'symbols':body})
        .then((res) => {
            if(res.success){
                alert('Update Success')
            }
        }
        )
    },
      reset() {
        this.siteSetting["contact_phone"]="";
        this.siteSetting["contact_email"]="";
        this.siteSetting["copyright"]="";
        this.siteSetting["ios_app_url"]="";
        this.siteSetting["android_app_url"]="";
        this.siteSetting["app_link"]="";
      },

      getCurrencyCountries() {
        rf.getRequest('AdminRequest').getCountries().then(res => {
          this.currencies = window._.chain(res.data)
              .filter(item => item.iso_code)
              .orderBy(['iso_code'], ['asc'])
              .map(item => item.iso_code)
              .uniq()
              .value();
        }).catch(error => {
          if (!error.response) {
            Message.error(window.i18n.t('common.message.network_error'), {}, { position: "bottom_left" });
            return;
          }
          this.convertRemoteErrors(error);
        });
      }
    },

    mounted() {
      this.$emit('EVENT_PAGE_CHANGE', this);
      this.getCurrencyCountries();
      rf.getRequest('SettingRequest').editSiteSetting().then(res => {
        this.siteSetting = res.data;
      });
    },
    created() {
    this.getData()
    }
    }
</script>


<style lang="scss" scoped>
@import "@/assets/sass/variables";
  
  .mr-10 {
    margin-right: 10px; 
  }
  .clearfix-15 {
    height: 15px;
    display: block;
    clear: both;
  }
  .error {
    border: 1px solid #e2221e !important;
  }
  .invalid-feedback {
    color: #e2221e;
  }
  #siteSettingForm {
    padding-bottom: 50px;
    .content_sitesetting {

      .name_page {
        margin: 0px 0px 14px 0px;
        line-height: 26px;
        font-size: $font_big;
        font-weight: 600;
        color: $color_mine_shaft;
      }
      .form-group {
        margin-bottom: 15px;
        label {
          font-weight: 500;
          line-height: 17px;
          margin-bottom: 5px;
          color: $color_dove_gray;
          font-size: $font_root;
        }

        select.form-control,
        input.form-control {
          border: 1px solid $color_alto;
          background-color: transparent;
          height: 35px;
          line-height: 20px;
          padding: 7px 15px;
          color: $color_mine_shaft;
          font-size: $font_root;
          font-weight: 500;
        }
      }
    }
  }
  .row-hot-search{
    display: flex;
}
.list-group-item {
  cursor: pointer;
  position: relative;
  padding: 10px;
}
.list-group-item-active {
  background-color: #007bff; 
  color: white;
}
.btn-group-vertical {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.btn {
  margin-bottom: 5px;
}
.update-btn{
    background: #0e454c;
    color: white;
    border-radius: 10px;
    padding: 5px;
}
.update-btn:hover{
    background: #118696;
    color: white;
    border-radius: 10px;
    padding: 5px;
}
.hot-search-title{
  margin-top: 14px !important;
  margin-bottom: 7px !important;
}

</style>
