<template>
  <div class="content-mapping-order" id="content-mapping-order">
    <div class="filter-container">
      <div class="filter-item pair" >
        <div class="label-text text-pair">
          <span>Symbol:</span>
        </div>
        <div class="toolbar-element2">
          <select-box
              :options="symbolOptions"
              v-model="filter.symbol"/>
        </div>
      </div>
      <div class="filter-item pair" >
        <div class="label-text text-pair">
          <span>Status:</span>
        </div>
        <div class="toolbar-element2">
          <select-box
              :options="statusOptions"
              v-model="filter.status"/>
        </div>
      </div>
      <div class="filter-item btn-action">
        <button @click="search()" class="btn btn-primary">{{ $t('orders.order_history.search') }}</button>
        <button @click="refresh()" class="btn btn-reset">{{ $t('orders.order_history.reset') }}</button>
      </div>
    </div>
    <div class="row no-margin">
      <div class="datatable">
        <data-table-4 :getData="getData"
                    :limit="10"
                    :column="18"
                    ref="datatable" class="scroll">
          <th class="mw-170">Time</th>
          <th>Trade Id</th>
          <th>Buy order Id</th>
          <th>Sell order Id</th>
          <th class="text-left">
            User email
          </th>
          <th>Bot email</th>
          <th>Symbol</th>
          <th>Price</th>
          <th>User order side</th>
          <th>User order quantity</th>
          <th>Bitmex order Id</th>
          <th>Bitmex account Id</th>
          <th>Bitmex account email</th>
          <th>Bitmex order side</th>
          <th>Bitmex matched order quantity</th>
          <th>Bitmex remainning order quantity</th>
          <th>Status</th>
          <th><span class="note-span-header">Note</span></th>
          <th>Retry</th>
          <th class="mw-150">Max retry</th>

          <template slot="body" slot-scope="props">
            <tr>
              <td class="text-left">
                <div>{{ convertToLocalTime(props.item.updated_at) }}</div>
              </td>
              <td>
                <span class="text-left">{{props.item.trade_id}}</span>
              </td>
              <td>{{props.item.buy_order_id}}</td>
              <td>{{props.item.sell_order_id}}</td>
              <td>
                <span>{{props.item.user_email}}</span>
              </td>
              <td>{{props.item.bot_email}}</td>
              <td>{{props.item.symbol}}</td>
              <td>{{props.item.price |formatCurrencyAmount(null, '0')}}</td>
              <td class="text-capital">{{props.item.user_order_side}}</td>
              <td>{{props.item.user_order_qty |formatCurrencyAmount(null, '0')}}</td>
              <!-- <td>{{props.item.bitmex_order_id}}</td> -->
              <td class="text-left w_125">
                <div class="item_email_setting">
                    <span class="txt_email_setting">{{ props.item.bitmex_order_id }}</span>
                    <span class="tooltip_email_setting">{{ props.item.bitmex_order_id }}</span>
                </div>
              </td>
              <td>{{props.item.bitmex_account_id}}</td>
              <td>{{props.item.bitmex_account_email}}</td>
              <td class="text-capital">{{props.item.bitmex_order_side}}</td>
              <td>{{props.item.bitmex_matched_order_qty |formatCurrencyAmount(null, '0')}}</td>
              <td>{{props.item.bitmex_remaining_order_qty |formatCurrencyAmount(null, '0')}}</td>
              <td class="text-capital">{{props.item.status}}</td>
              <td class="text-left w_125">
                <div v-if="props.item.status != 'failed'" class="item_email_setting">
                    <span class="txt_email_setting">{{ props.item.note }}</span>
                    <span class="tooltip_email_setting">{{ props.item.note }}</span>
                </div>
                <div v-else class="form-button">
                  <button @click="showFailedReason(props.item.note)" class="btn btn_disable_all">{{ 'Show Error' }}</button>
                </div>
              </td>
              <td>{{props.item.retry}}</td>
              <td :key='index' v-if="props.item.status == 'pending' || props.item.status == 'retrying'">
                <span v-if="!props.item.isEdit">{{props.item.max_retry}}</span>
                <currency-input v-if="props.item.isEdit" :maxLengthCus='2' class="input_table w_45px" v-model="props.item.new_max_retry" />
                <button  v-if="!props.item.isEdit" class="btn btn-edit-table" @click="editColumn(props.item)"><i class="icon-edit"></i></button>
                <button v-if="props.item.isEdit" class="btn btn-edit-table" @click="resetColumn(props.item)"><i class="icon-reload3"></i></button>
                <button v-if="props.item.isEdit" class="btn btn-save-table" @click="saveColumn(props.item)"><i class="icon-save"></i></button>
              </td>
            </tr>
          </template>
        </data-table-4>
      </div>
    </div>
    <modal name="showFailedReason" width="450">
      <div slot="body" class="body-popup add-user-enable-fee">
        <div class="form-group-detail">
          <div class="group-detail-form-group">
            <label class="group-detail-form-label-1">{{ reason }}</label>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
  import rf from '../../lib/RequestFactory';
  import moment from "moment";
  import DataTable4 from "../../components/DataTable4";
  import SelectBox from '../../components/SelectBox';
  import Modal from '../../components/Modal';

  export default {
  name: "MappingOrder",
  components:{
      DataTable4,
      SelectBox,
      Modal,
  },
  data:function(){
    return {
      titlePage:"Mapping Order",
      symbolOptions:[{'id':"",'name':"All"}],
      statusOptions:[
          {'id':"",'name':"All"},
          {'id':"pending",'name':"Pending"},
          {'id':"retrying",'name':"Retrying"},
          {'id':"done",'name':"Done"},
          {'id':"failed",'name':"Failed"}
      ],
      filter:{
          status:{'id':"",'name':"All"},
          symbol:{'id':"",'name':"All"},
      },
      index: 0,
      reason: '',
    }
  },
  methods:{
    convertToLocalTime(date) {
      return moment.utc(date).local().format('YYYY-MM-DD HH:mm:ss');
    },
    getData:function(params){
      const param = {
        ...params,
        status : this.filter.status.id,
        symbol: this.filter.symbol.id,
      };
     return rf.getRequest('BitmexMappingOrder').getMappingOrder(param);
    },
    showFailedReason (reason) {
      this.reason = reason;
      CommonModal.show("showFailedReason", {
        position: "bitmex",
        mask: true,
      });
    },
    editColumn(item) {
      item.isEdit = true;
      this.index++;
    },
    resetColumn(item) {
      item.isEdit = false;
      this.index++;
    },
    saveColumn(item) {
      if (!this.validateTable(item)) return;
      rf.getRequest('BitmexMappingOrder').updateMaxRetry({ max_retry: item.new_max_retry, id: item.id }).then(res => {
        if (res && res.data) {
          this.$refs.datatable.refresh();
          this.$toastr('success', this.$t("Updated successfully!"));
          item.isEdit = false;
          this.index++;
        }
      }).catch(e => {
        this.$toastr('error', 'Update failed!');
        item.isEdit = false;
        this.index++;
      });
    },
    validateTable (item) {
      this.errors.clear();

      if (!item.new_max_retry) {
        this.$toastr('error', 'The max retry field is required.');
        this.errors.add({field: 'default', msg: 'error'});
      }

      if (item.new_max_retry > 30) {
        this.$toastr('error', 'The max retry value must be less than or equal to 30.');
        this.errors.add({field: 'default', msg: 'error'});
      }

      if (this.errors.count() > 0) {
        return false;
      }

      this.errors.clear();

      return true; 

    },
    getSymbolOptions:function(){
      rf.getRequest('MappingInstrument').getMappingInstrument().then((response)=>{
          const symbols = [{id:"",name:"All"}]
        window._.forEach(response.data,function(e){
            symbols.push({id:e.symbol,name:e.symbol});
        });
        this.symbolOptions = symbols;
      })
    },
    search:function(){
      this.$refs.datatable.refresh();
    },
    refresh:function(){
      this.filter = {
        status:{'id':"",'name':"All"},
        symbol:{'id':"",'name':"All"},
      }
      this.$refs.datatable.refresh();
    }
  },
  created() {
      this.getSymbolOptions();
  },
  mounted() {
    this.$emit('EVENT_PAGE_CHANGE', this);
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/variables";

.form-button {
  display: inline-block;
  .btn_disable_all {
    min-width: 100px;
    text-align: center;
    line-height: 21px;
    padding: 0px 5px;
    border: 1px solid;
    background-color: transparent;
    font-family: $font_family_root;
    border-radius: 3px;
    height: 25px;
    font-size: $font-smaller;
    font-weight: 600;
    text-transform: uppercase;
    transition: 0.5s;
    &:disabled{
      background-color : $color-bright-gray;
      border-color : $color-bright-gray;
      color: $color_mine_shaft;
    }
  }
  .btn_disable_all {
    border-color: $color-denim;
    color: $color-white;
    background-color: $color-denim;
    &:hover {
      background-color: $color-denim;
      border-color: $color-denim;
      color: $color-white;
      transition: 0.5s;
    }
    &:disabled{
      background-color : $color-bright-gray;
      border-color : $color-bright-gray;
      color: $color_mine_shaft;
    }
  }
}
.w_45px {
  width: 45px;
}
.input_table {
  border-radius: 3px;
  border: solid 1px $color_alto;
  height: 28px;
  padding: 4px 12px;
  font-size: $font_root;
  color: $color-grey-dark;
  line-height: 20px;
  background-color: transparent;
  &:hover,
  &:active,
  &:focus {
    border-color: $color-jungle-green;
  }
}

.btn-edit-table,
.btn-save-table {
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  padding: 0px;
  text-align: center;
  border: 0px;
  background-color: transparent;
  font-size: 16px;
  color: $color-jungle-green;
  margin-left: 7px;
  i {
    &:before {
      color: $color-jungle-green;
    }
  }
}
.item_email_setting {
  display: inline-block;
  float: left;
  position: relative;
  .txt_email_setting {
    display: block;
    max-width: 150px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .tooltip_email_setting {
    position: absolute;
    top: 0px;
    left: 0px;
    line-height: 20px;
    padding: 5px 20px;
    left: 100%;
    background-color: $color_white;
    white-space: nowrap;
    width: auto;
    z-index: 10;
    font-size: $font_root;
    font-weight: 500;
    color: $color_mine_shaft;
    transition: 0.5s;
    display: none;
    box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.4);
    &:after {
      right: 100%;
      top: 50%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-color: rgba(136, 183, 213, 0);
      border-right-color: $color_white;
      border-width: 5px;
      margin-top: -5px;
    }
  }
  &:hover {
    .tooltip_email_setting {
      display: block;
      transition: 0.5s;
    }
  }
}
.w_125 {
  width: 125px;
}
.no-margin{
  margin: 0;
}
.text-capital{
  text-transform: capitalize;
}
.filter-container {
  margin: 10px 0px 20px 0px;
  height: 55px;

  .filter-item {
    float: left;
    margin: 0 5px;

    input {
      margin-top: 23px;
      display: inline-block;
      width: 145px;
      font-family: $font-family-roboto;

      &:hover, &:active, &:focus {
        border-color: $color-jungle-green;
      }
    }

    select {
      width: 65px;
      display: inline-block;
      font-family: $font-family-roboto;
      font-size: $font_root;

      &:hover, &:active, &:focus {
        border-color: $color-jungle-green;
      }
    }

    button {
      width: auto;
      margin: 23px 2px 0px 2px;
      height: 30px;
      font-size: $font-smaller;
      font-family: $font-family-roboto-bold;
    }

    .btn-reset {
      background-color: $color-caribbean-green;
      border: 1px solid $color-caribbean-green;
      width: 80px;
      text-transform: uppercase;
      color: $color-white;
      font-weight: 500;

      &:hover {
        border-color: $color-aquamarine;
        background-color: $color-aquamarine;
      }
    }

    .btn-primary {
      padding: 0;
      min-width: 80px;

      &:hover {
        border-color: $color-aquamarine;
        background-color: $color-aquamarine;
      }
    }

    .coin-input {
      position: relative;
      display: inline-block;
      margin: 0 5px;
      margin-right: 0px;
      font-size: $font-small;
      font-family: $font-family-roboto;

      .coin-option {
        background-color: $color-white;
        max-height: 210px;
        display: block;
        overflow-y: auto;
        margin-top: 5px;
        position: absolute;
        width: 79px;
        left: 5px;
        z-index: 10;
        padding: 12px 0px;
        box-shadow: 0px 3px 12px rgba(49, 49, 49, 0.51);

        &:after {
          bottom: 100%;
          left: 50%;
          border: solid transparent;
          content: " ";
          display: block;
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
          border-color: rgba(136, 183, 213, 0);
          border-bottom-color: $color_white;
          border-width: 5px;
          margin-left: -5px;
        }

        .option {
          display: block;
          width: 100%;
          line-height: 20px;
          cursor: pointer;
          padding: 3px 14px;
          font-size: 14px;
          overflow: hidden;

          &:hover {
            color: $color-jungle-green;
          }
        }
      }
    }
  }

  .aver-price {
    margin-top: 25px;
    padding-right: 100px;
    float: right;
  }

  .pair {
    input {
      width: 65px;
    }
  }

  .text-pair {
    color: $color-grey-dusty;
    font-family: $font-family-roboto;
    font-size: $font-small;
    margin-bottom: 5px;
  }

  .side {
    margin: 0 10px;
  }
}
table{
  thead{
    th{
      text-transform: capitalize;
    }
  }
  tbody{
    td{
      word-break: initial ;
    }
  }
  .note-column{
    word-break: break-all;
  }
  .note-span-header{
    width: 100px;
    display: inline-block;
  }
}
.mw-150 {
  min-width: 150px;
}
.mw-170 {
  min-width: 170px;
}

.form-group-detail {
    .group-detail-form-group {
        .group-detail-form-label{
            width: 100%;
            color: $color-grey-dusty;
            float: left;
            font-weight: normal;
            font-family: $font-family-roboto;
            margin-bottom: 0px;
            margin-top: 10px;
        }
        .group-detail-form-label-1{
            width: 100%;
            margin-bottom: 30px;
            margin-top: 10px;
            line-height: 25px;
            text-align: center;
            font-size: 20px;
            color: rgb(42, 47, 50);
            font-family: "Roboto-Regular", sans-serif;
            font-size: 16px;
            font-weight: 600;
        }

        .group-detail-form-value {
            width: 100%;
            padding: 8px 16px;
            border: 1px solid #dee3eb;
            height: 35px;
            line-height: 35px;
            border-radius: 3px;
            font-family: $font-family-roboto;
            // font-weight: 600;
            &:focus {
                border-color: $color-jungle-green;
            }
        }
        #input-airdrop-title {
            &.error-modal {
                border: solid 1px $color-red-main;
                border-radius: 3px;
            }
        }
        .textarea_input {
            height: 120px;
        }
    }
    .error-box {
        display: block;
        float: left;
        overflow: hidden;
        width: 100%;
        span {
            font-family: $font-family-roboto-medium !important;
        }
    }
    .is-error {
        color: #ff5252 !important;
    }
}
</style>
<style lang="scss">
#content-mapping-order {
  .toolbar-element2 {
    .button_drop_search {
      width: 100px;
      margin-left: 0;
    }
  }
  .group_number_items {
    .button_drop_search {
      width: 50px;
      margin-left: 0;
    }
  }
  #content-mapping-order {
    .modal-dialog {
      height: auto;
      .modal-content {
        height: 200px !important;
        .modal-body {
          padding: 0px 30px 15px 30px;
          .group-detail-form-label {
            margin-bottom: 5px;
          }
        }
        .modal-footer {
          padding-bottom: 30px;
        }
      }
    }
  }
}
</style>