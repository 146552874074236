<template>
  <div class="order-history clearfix mg_bot50" id="entry-management">
    <div class="row Rectangle-23">
      <div class="col-xs-7 pb-10" v-if="currentContest.id">
        <div class="row">
          <div class="col-xs-3" style="padding-top: 3px">Contest list</div>
          <div class="col-xs-6">
            <div class="toolbar-element2">
              <select-box v-model="currentContest" class="contest-list-select" :options="contestList"/>
            </div>
          </div>
          <div class="col-xs-3" style="padding-top: 3px; padding-right: 5px">
            <div v-if="currentContest && currentContest.status">Status: {{ currentContest.status | uppercase }}</div>
          </div>
        </div>
      </div>
      <div class="col-xs">
        <div class="filter-container">
          <div :key='index' class="filter-item btn-action">
            <button v-if="currentContest && !currentContest.id && previousContest.id" @click="onCancel()" class="btn btn-reset">Cancel</button>
            <button v-if="currentContest && !currentContest.id" @click="onSaveContest()" class="btn btn-reset">Save</button>
            <button v-else @click="createContest()" class="btn btn-primary">New</button>
            <button v-if="currentContest.id && (!currentContest.is_team_battle || (currentContest.status === 'draft'&& currentContest.is_team_battle)) " @click="editContest()" class="btn btn-reset">Save</button>
            <button v-if="currentContest && currentContest.status ==='draft'" @click="startContest()" class="btn btn-reset">Start</button>
            <button v-if="currentContest && currentContest.status ==='started'" @click="stopContest()" class="btn btn-reset">Stop</button>
          </div>
        </div>
      </div>
      <div class="col-xs-12" v-if="currentContest.id">
        <div class="col-xs-12">Note:</div>
        <div class="col-xs-12">
          <ul class="note-wrapper">
            <li>Once the contest has been started, you can only edit Number of users (UI).</li>
            <li>Only one contest can be started at a time.</li>
            <li>All dates and times are in UTC.</li>
          </ul>
        </div>
      </div>
    </div>

    <div class="row" style="margin-bottom: 10px">
      <div class="col-xs-2" style="padding-top: 5px">Type battle</div>
      <div class="col-xs-3">
        <div class="form-check group_airdrop_payout clearfix">
          <label class="form-check-label">
            <span class="icon_check_label"></span>
            <input type="radio" class="form-check-input icon_check_payout"
                   :data-vv-as="'type battle'" v-validate="'required'"
                   v-bind:class="{'error-modal': errors.has('optradio')}"
                   :disabled="!isEditable()"  name="optradio" v-model="draftContest.is_team_battle" v-bind:value="0">
            <span class="txt-inactive">Individual</span>
          </label>
          <label class="form-check-label">
            <span class="icon_check_label"></span>
            <input type="radio" class="form-check-input icon_check_payout"
                   :data-vv-as="'type battle'" v-validate="'required'"
                   v-bind:class="{'error-modal': errors.has('optradio')}"
                   :disabled="!isEditable()" name="optradio" v-model="draftContest.is_team_battle" v-bind:value="1">
            <span class="txt-inactive">Team</span>
          </label>
          <div v-show="errors.has('optradio')" class="invalid-feedback">{{ errors.first('optradio') }}</div>
        </div>
      </div>
    </div>
    <div class="row" style="margin-bottom: 10px">
      <div class="col-xs-2" style="padding-top: 5px">Contest name</div>
      <div class="col-xs-3">
        <input title="" type="text" class="form-control" v-model="draftContest.name"
          name="contestName"
          :disabled="!isEditable()"
          :data-vv-as="'contest name'" v-validate="'required'"
          v-bind:class="{'error-modal': errors.has('contestName')}"
         maxlength="190"
        />
        <div v-show="errors.has('contestName')" class="invalid-feedback">{{ errors.first('contestName') }}</div>
      </div>
      <div class="col-xs-1"></div>
      <div class="col-xs-2" style="padding-top: 5px">Minimum margin (BTC)</div>
      <div class="col-xs-3">
         <currency-input :precision="8" class="form-control" v-model="draftContest.minimum_margin"
         :max-length-cus="15"
         data-vv-validate-on="blur"
         :data-vv-as="'minimum margin (BTC) '"
         v-validate="'required'"
          name="minimumMargin"
         v-bind:class="{'error-modal': errors.has('minimumMargin')}"
           @input="onChangeMinimumMargin"
          :disabled="!isEditable()"
         />

        <div v-show="errors.has('minimumMargin')" class="invalid-feedback">{{ errors.first('minimumMargin') }}</div>
      </div>
    </div>

    <div class="row" style="margin-bottom: 10px">
      <div class="col-xs-2" style="padding-top: 5px">Start entry time</div>
      <div class="col-xs-3">
        <div class="datetime-wrapper">
          <date-picker :format="customFormatter" class="date-picker-input" v-model="startEntryDate"
            name="startEntryDate"
            :data-vv-as="'start entry time'" v-validate="'required'"
            v-bind:class="{'error-modal': errors.has('startEntryDate'),'disabled-datepicker':!isEditable()}"
            :disabled-picker="!isEditable()"
          />
          <time-picker hide-clear-button class="date-picker-input"
            v-bind:class="{'disabled-datepicker':!isEditable()}"
           v-model="startEntryTime"/>
        </div>
         <div v-show="errors.has('startEntryDate')" class="invalid-feedback">{{ errors.first('startEntryDate') }}</div>
      </div>
      <div class="col-xs-1"></div>
      <div class="col-xs-2" style="padding-top: 5px">End entry time</div>
      <div class="col-xs-3">
        <div class="datetime-wrapper">
          <date-picker :format="customFormatter" class="date-picker-input" v-model="endEntryDate"
            name="endEntryDate"
            :data-vv-as="'end entry time'" v-validate="'required'"
           :disabled-picker="!isEditable()"
          v-bind:class="{'error-modal': errors.has('startEntryDate'),'disabled-datepicker':!isEditable()}"
          />
          <time-picker hide-clear-button class="date-picker-input"
            v-bind:class="{'disabled-datepicker':!isEditable()}"
           v-model="endEntryTime"/>
        </div>
         <div v-show="errors.has('endEntryDate')" class="invalid-feedback">{{ errors.first('endEntryDate') }}</div>
      </div>
    </div>

    <div class="row" style="margin-bottom: 10px">
      <div class="col-xs-2" style="padding-top: 5px">Start contest time</div>
      <div class="col-xs-3">
        <div class="datetime-wrapper">
          <date-picker :format="customFormatter" class="date-picker-input" v-model="startContestDate"
              name="startContestDate"
              :data-vv-as="'start contest time'" v-validate="'required'"
              v-bind:class="{'error-modal': errors.has('startEntryDate'),'disabled-datepicker':!isEditable()}"
          />
        <time-picker hide-clear-button class="date-picker-input" v-model="startContestTime"
            v-bind:class="{'disabled-datepicker':!isEditable()}"
        />
        </div>
        <div v-show="errors.has('startContestDate')" class="invalid-feedback">{{ errors.first('startContestDate') }}</div>
      </div>
      <div class="col-xs-1"></div>
      <div class="col-xs-2" style="padding-top: 5px">End contest time</div>
      <div class="col-xs-3">
        <div class="datetime-wrapper">
          <date-picker :format="customFormatter" class="date-picker-input" v-model="endContestDate"
              name="endContestDate"
              :data-vv-as="'end contest time'" v-validate="'required'"
              v-bind:class="{'error-modal': errors.has('startEntryDate'),'disabled-datepicker':!isEditable()}"
             :disabled-picker="!isEditable()"
          />
          <time-picker hide-clear-button class="date-picker-input" v-model="endContestTime"
              v-bind:class="{'disabled-datepicker':!isEditable()}"
          />
        </div>
         <div v-show="errors.has('endContestDate')" class="invalid-feedback">{{ errors.first('endContestDate') }}</div>
      </div>
    </div>

    <div class="row" v-if="draftContest.is_team_battle===0">
      <div class="col-xs-2" style="padding-top: 5px">Number of users (UI)</div>
      <div class="col-xs-3">
        <currency-input :index="index" :precision="0"
                        maxlength="10"
                        class="form-control" v-model="draftContest.number_of_users"/>
      </div>
      <div class="col-xs-1"></div>
      <div v-if="currentContest.id" class="col-xs-2" style="padding-top: 5px">Real number of users</div>
      <div class="col-xs-3">
        <currency-input :index="index" :precision="0" v-if="currentContest.id" disabled
                        maxlength="10"
                        class="form-control" v-model="draftContest.real_number_of_users"/>
      </div>
    </div>

    <div v-else-if="draftContest.is_team_battle===1">
      <div class="row" style="margin-bottom: 10px">
        <div class="col-xs-2" style="padding-top: 5px">Minimum member</div>
        <div class="col-xs-3">
          <currency-input :index="index" :precision="0" :disabled="!isEditable()"
                          :data-vv-as="'minimum member '"
                          data-vv-validate-on="blur"
                          @input="(value) => onChangeInput(value, 'minimum_member','minimum member')"
                          v-validate="'required'"
                          name="minimum_member"
                          v-bind:class="{'error-modal': errors.has('minimum_member')}"
                          maxlength="10"
                          class="form-control" v-model="draftContest.minimum_member"/>
          <div v-show="errors.has('minimum_member')" class="invalid-feedback">{{ errors.first('minimum_member') }}</div>
        </div>
        <div class="col-xs-1"></div>
        <div class="col-xs-2" style="padding-top: 5px">Minimum volume</div>
        <div class="col-xs-3">
          <currency-input :index="index" :precision="8" :disabled="!isEditable()"
                          :data-vv-as="'minimum volume'"
                          data-vv-validate-on="blur"
                          v-validate="'required'"
                          name="minimum_volume"
                          @input="(value) => onChangeInput(value, 'minimum_volume','minimum volume')"
                          v-bind:class="{'error-modal': errors.has('minimum_volume')}"
                          :maxLengthCus="15"
                          class="form-control" v-model="draftContest.minimum_volume"/>
          <div v-show="errors.has('minimum_volume')" class="invalid-feedback">{{ errors.first('minimum_volume') }}</div>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-2" style="padding-top: 5px">Maximum member</div>
        <div class="col-xs-3">
          <currency-input :index="index" :precision="0" :disabled="!isEditable()"
                          :data-vv-as="'maximum member'"
                          data-vv-validate-on="blur"
                          v-validate="'required'"
                          name="maximum_member"
                          @input="(value) => onChangeInput(value, 'maximum_member','maximum member')"
                          v-bind:class="{'error-modal': errors.has('maximum_member')}"
                          :maxLengthCus="8"
                          class="form-control" v-model="draftContest.maximum_member"/>
          <div v-show="errors.has('maximum_member')" class="invalid-feedback">{{ errors.first('maximum_member') }}</div>
        </div>
      </div>
    </div>

    <data-table v-if="currentContest.id && currentContest.is_team_battle ===0" :getData="getData" ref="datatable" :limit="10" :column="3" class="scroll height-custom">
      <th class="text-left">Id</th>
      <th class="text-left">Email</th>
      <th class="text-right">ROE</th>

      <template slot="body" slot-scope="props">
        <tr>
          <td class="text-left font14">{{ props.item.id }}</td>
          <td class="text-left font14">
            <div class="item_email_setting font14">
              <span class="txt_email_setting font14" style="max-width: 200px">{{ props.item.email }}</span>
              <span class="tooltip_email_setting font14">{{ props.item.email }}</span>
            </div>
          </td>
          <td class="text-right font14">{{ props.item.roe | toPercentage }}</td>
        </tr>
      </template>
    </data-table>

    <div class="row leader-grouped-table" id="leader-grouped-table" v-if="currentContest.id && currentContest.is_team_battle ===1">
      <div class="col-md-6 table-team">
        <data-table-4 :getData="getDataTeam" ref="datatableteam" :limit="10" :column="6" class="scroll height-custom" id="tr-entry-management">
          <th class="text-left">Team Name</th>
          <th class="text-left">Team ID</th>
          <th class="text-left">Leader</th>
          <th class="text-left nopadding-left-right" style="width: 23%" >Number Of Members</th>
          <th class="text-left" style="width: 18%">Total Volume</th>
          <th class="text-right" style="width: 15%">Average ROE</th>
          <template slot="body" slot-scope="props">
            <tr :class="{selectedTeamClass: props.item.id === selectedTeamId}" @click="selectedTeamId = props.item.id">
              <td class="text-left font14 team-name-td">
                <div class="item_name_setting font14">
                  <span class="txt_email_setting font14">{{ props.item.name }}</span>
                  <div class="tooltip_name_setting font14">{{ props.item.name }}</div>
                </div>
              </td>
              <td class="text-left font14 team-name-td">
                  <span class="txt_email_setting font14">{{ props.item.id }}</span>
              </td>
              <td class="text-left font14">
                <div class="item_email_setting font14">
                  <span class="txt_email_setting font14">{{ props.item.leader.email }}{{ props.item.leader.email }}{{ props.item.leader.email }}</span>
                  <span class="tooltip_email_setting font14">{{ props.item.leader.email }}</span>
                </div>
              </td>
              <td class="text-left font14 nopadding-left-right">{{ props.item.number_entry_count }}</td>
              <td class="text-left font14">{{ subValue(props.item.total_volume, props.item.volume_user_out)| formatCurrencyAmount(null, '0') }} BTC</td>
              <td class="text-right font14">{{ subValue(props.item.total_roe, props.item.roe_user_out) / props.item.number_entry_count | toPercentage }}</td>
            </tr>
          </template>
        </data-table-4>
      </div>
      <div class="col-md-6">
        <data-table-4 :getData="getDataMember" :key="indexTableMember" ref="dataMemberTable" :limit="10" :column="4" class="scroll height-custom">
          <th class="text-left">Id</th>
          <th class="text-left">Email</th>
          <th class="text-left">ROE</th>
          <th class="text-right">Trading Volume</th>
          <template slot="body" slot-scope="props">
            <tr>
              <td class="text-left font14">{{ props.item.id }}</td>
              <td class="text-left font14">
                <div class="item_email_setting font14">
                  <span class="txt_email_setting font14">{{ props.item.email }}</span>
                  <span class="tooltip_email_setting font14">{{ props.item.email }}</span>
                </div>
              </td>
              <td class="font14">{{ props.item.roe | toPercentage }}</td>
              <td class="text-right font14">{{ props.item.total_volume | formatCurrencyAmount(null,'0')}} BTC</td>
            </tr>
          </template>
        </data-table-4>
      </div>

    </div>

    <modal name="confirmModal" width="450">
      <div slot="body" class="body-popup add-user-enable-fee">
        <div class="form-group-detail">
          <div class="group-detail-form-group text-center">
            <label class="group-detail-form-label-1">{{ currentContest && currentContest.status ==='started' ? 'Do you really want to stop this contest ?'
              : 'Do you really want to start this contest ?' }}</label>
          </div>
        </div>
      </div>
    </modal>

  </div>
</template>

<script>
  import rf from '../../lib/RequestFactory';
  import Modal from '../../components/Modal';
  import DatePicker from 'vuejs-datepicker';
  import TimePicker from 'vue2-timepicker';
  import SelectBox from '../../components/SelectBox';
  import CurrencyInput from '../../components/CurrencyInput';
  import DataTable4 from "../../components/DataTable4"
  import moment from "moment";
  import BigNumber from 'bignumber.js';
  import Utils from "../../common/Utils";


  export default {
    components: {
      Modal,
      DatePicker,
      TimePicker,
      SelectBox,
      CurrencyInput,
      DataTable4
    },
    data() {
      return {
        titlePage: 'Entry Leaderboard Management',
        startDate: moment().subtract(1, 'week').toDate(),
        endDate: new Date(),
        key : 1,

        contestList: [],
        currentContest: {},
        previousContest:{},
        draftContest: {},

        startEntryDate: "",
        endEntryDate: "",
        startContestDate: "",
        endContestDate: "",

        startEntryTime: { HH: '00', mm: '00'},
        endEntryTime: { HH: '00', mm: '00'},
        startContestTime: { HH: '00', mm: '00'},
        endContestTime: { HH: '00', mm: '00'},

        index: 0,

        selectedTeamId: "",
        indexTableMember: 0,
      }
    },
    watch: {
      'currentContest.id' () {
        this.draftContest = {...this.currentContest}
        this.selectedTeamId = "";
        this.indexTableMember++;
        this.currentContestDateConvert();
        this.$nextTick()
            .then(()=>{
              this.errors.clear();
            })
        if(this.$refs.datatableteam){
          this.$refs.datatableteam.refresh();
        }else if(this.$refs.datatable){
          this.$refs.datatable.refresh();
        }
      },
      'selectedTeamId'(){
        if(this.$refs.dataMemberTable){
          this.$refs.dataMemberTable.refresh();
        }
      },
      'draftContest.is_team_battle'(){
        this.$nextTick()
            .then(()=>{
              this.errors.remove('minimum_member');
              this.errors.remove('minimum_volume');
              this.errors.remove('maximum_member');
            })
      }
    },
    methods: {
      onChangeMinimumMargin(value){
        if(!value){
          this.errors.add({field: 'minimumMargin', msg: 'The minimum margin (BTC) field is required.'});
        }else{
          this.errors.remove('minimumMargin');
        }
      },
      subValue(value1, value2){
        return new BigNumber(`${value1 || 0}`).sub(`${value2 || 0}`).toString();
      },
      onChangeInput(value, input, name){
        if(!value){
          this.errors.add({field: input, msg: `The ${name} field is required.`});
        }else{
          this.errors.remove(input);
        }
      },
      getData(params) {
        const contest_id = this.currentContest.id;
        return rf
          .getRequest("MarginOrderRequest")
          .getEntriesByContest({ ...params, contest_id });
      },
      getDataTeam(params){
        const contest_id = this.currentContest.id;
        return rf
            .getRequest("MarginOrderRequest")
            .getTeamEntryByContest({ ...params, contest_id });
      },
      getDataMember(params){
        const team_id = this.selectedTeamId;
        if(!team_id) return new Promise(resolve => resolve([]));
        return rf
            .getRequest("MarginOrderRequest")
            .getMemberByTeamId({ ...params, 'teamId':team_id });
      },
      async validateForm() {
        this.errors.clear();
        await this.$validator.validate();

        if(this.startEntryDate && this.endEntryDate){
          const {startEntryTime,endEntryTime} = this;
          const startEntryDate = moment(this.startEntryDate).set("hour", startEntryTime.HH).set('minute', startEntryTime.mm);
          const endEntryDate = moment(this.endEntryDate).set("hour", endEntryTime.HH).set('minute', endEntryTime.mm);
          if(endEntryDate.isSameOrBefore(startEntryDate)){
            this.errors.add({field: 'endEntryDate', msg: 'The end entry time must be after the start entry time.'});
          }
        }

        if(this.startContestTime && this.endContestTime){
          const {startContestTime,endContestTime} = this;
          const startContestDate = moment(this.startContestDate).set("hour", startContestTime.HH).set('minute', startContestTime.mm);
          const endContestDate = moment(this.endContestDate).set("hour", endContestTime.HH).set('minute', endContestTime.mm);
          if(endContestDate.isSameOrBefore(startContestDate)){
            this.errors.add({field: 'endContestDate', msg: 'The end contest time must be after the start contest time.'});
          }
        }

        if(this.startContestTime && this.endEntryDate){
          const {startContestTime, endEntryTime} = this;
          const startContestDate = moment(this.startContestDate).set("hour", startContestTime.HH).set('minute', startContestTime.mm);
          const endEntryDate = moment(this.endEntryDate).set("hour", endEntryTime.HH).set('minute', endEntryTime.mm);
          if(startContestDate.isSameOrBefore(endEntryDate)){
            this.errors.add({field: 'startContestDate', msg: 'The start contest time must be after the end entry time.'});
          }
        }

        if(this.draftContest.minimum_margin==0){
          this.errors.add({field: 'minimumMargin', msg: 'The minimum amount must be greater than 0.'});
        }
        if(this.draftContest.minimum_member==0 && this.draftContest.is_team_battle){
          this.errors.add({field: 'minimum_member', msg: 'The minimum member must be greater than 0.'});
        }
        if(this.draftContest.maximum_member==0 && this.draftContest.is_team_battle){
          this.errors.add({field: 'maximum_member', msg: 'The maximum member must be greater than 0.'});
        }
        if(new BigNumber(`${this.draftContest.maximum_member || 0}`).comparedTo(`${this.draftContest.minimum_member || 0}`) < 0){
          this.errors.add({field: 'maximum_member', msg: 'The maximum member must be equal or greater than the minimum member.'});
        }

        if(this.draftContest.minimum_volume==0 && this.draftContest.is_team_battle){
          this.errors.add({field: 'minimum_volume', msg: 'The minimum volume must be greater than 0.'});
        }

        if (this.errors.count() > 0) {
            return false;
        }
        return true;
      },
      isEditable(){
        return !this.currentContest.id || this.currentContest.status==='draft';
      },
      customFormatter(date) {
        return moment(date).format('YYYY - MM - DD');
      },
      async getContestList (dataSearch) {
        try {
          let res = await rf.getRequest('MarginOrderRequest').getAllContests();
          if(res && res.data){
            window._.map(res.data, (item) => {
              item.minimum_margin = Utils.formatCurrencyAmount(item.minimum_margin, null, 0);
              item.minimum_volume = Utils.formatCurrencyAmount(item.minimum_volume, null, "0");
              item.minimum_member =  item.minimum_member || "0";
              item.number_of_users = item.number_of_users || "0";
              this.contestList.push(item);
            });
            this.currentContest = _.find(this.contestList, dataSearch);
            if (!this.currentContest) this.currentContest = this.contestList[0] || {};
            if(res.data.length===0){
              this.errors.clear();
            }
          }
        } catch (e) {
          console.log ('Error: ' + e)
        }
      },
      currentContestDateConvert(){
        const currentContest = this.currentContest;
        const startEntryDateTime = moment(currentContest.start_entry_time);
        const endEntryDateTime = moment(currentContest.end_entry_time);
        const startContestDateTime = moment(currentContest.start_contest_time);
        const endContestDateTime = moment(currentContest.end_contest_time);

        this.startEntryDate = this.isCurrentContestEmpty() ? "" : startEntryDateTime.format('Y-MM-DD');
        this.endEntryDate = this.isCurrentContestEmpty() ? "" : endEntryDateTime.format('Y-MM-DD');
        this.startContestDate = this.isCurrentContestEmpty() ? "" : startContestDateTime.format('Y-MM-DD');
        this.endContestDate = this.isCurrentContestEmpty() ? "" : endContestDateTime.format('Y-MM-DD');

        const timeData = {startEntryTime:startEntryDateTime,endEntryTime:endEntryDateTime,
        startContestTime:startContestDateTime,endContestTime:endContestDateTime};

        window._.forIn(timeData,(data,key)=>{
          const HH = this.isCurrentContestEmpty() ? '00' : `${data.format('HH')}`;
          const mm = this.isCurrentContestEmpty() ? '00' : `${data.format('mm')}`;
          this[key] = {HH,mm};
        });
      },
     async onSaveContest(){
        if(!await this.validateForm()){
          return;
        }
        const {startEntryTime,endEntryTime,startContestTime,endContestTime} = this;
        const formatType = 'Y-MM-DD HH:mm:ss';
        const start_entry_time = moment(this.startEntryDate).set("hour",startEntryTime.HH).set('minute',startEntryTime.mm).format(formatType);
        const end_entry_time = moment(this.endEntryDate).set("hour",endEntryTime.HH).set('minute',endEntryTime.mm).format(formatType);
        const start_contest_time = moment(this.startContestDate).set("hour",startContestTime.HH).set('minute',startContestTime.mm).format(formatType);
        const end_contest_time = moment(this.endContestDate).set("hour",endContestTime.HH).set('minute',endContestTime.mm).format(formatType);
        const params = {
          ...this.draftContest,
          start_entry_time,
          end_entry_time,
          start_contest_time,
          end_contest_time,
        }
         try {
            const contest = await rf.getRequest('MarginOrderRequest').createNewContest(params);
            this.$toastr('success', window.i18n.t('common.alert.create.success'));
            this.contestList = [];
            await this.getContestList(['id', contest.data.id]);
            this.index++;
          } catch (e) {
            console.log ('Error: ' + e)
            if (e.response.data.message) {
                this.$toastr('error', e.response.data.message);
            }
          }
      },
      onCancel(){
        this.currentContest = this.previousContest;
      },
      isCurrentContestEmpty(){
        return window._.isEmpty(this.currentContest);
      },
      createContest(){
        this.previousContest = this.currentContest;
        this.currentContest = {};
      },
      async startContest(){
        CommonModal.show("confirmModal", {
            position: "add-group customModal",
            mask: true,
            buttons: [
              {
                label: 'No',
                focused: true,
                style: {
                  width: "140px",
                  background: "rgb(0, 112, 192)"
                },
                callback: async () => {
                  CommonModal.hide("confirmModal");
                }
              },
              {
                label: 'Yes',
                focused: true,
                style: {
                  width: "140px",
                  background: "rgb(0, 112, 192)"
                },
                callback: async () => {
                   try {
                    const params = {id : this.currentContest.id};
                    const contest = await rf.getRequest('MarginOrderRequest').startContest(params);
                    this.currentContest.status = 'started';
                    this.$toastr('success','Started contest successfully!');
                    CommonModal.hide("confirmModal");
                  } catch (e) {
                    console.log ('Error: ' + e)
                    if (e.response.data.message) {
                        this.$toastr('error', e.response.data.message);
                    }
                  }
                }
              }
            ]
          });
      },
       stopContest(){
        CommonModal.show("confirmModal", {
            position: "add-group customModal",
            mask: true,
            buttons: [
              {
                label: 'No',
                focused: true,
                style: {
                  width: "140px",
                  background: "rgb(0, 112, 192)"
                },
                callback: async () => {
                  CommonModal.hide("confirmModal");
                }
              },
              {
                label: 'Yes',
                focused: true,
                style: {
                  width: "140px",
                  background: "rgb(0, 112, 192)"
                },
                callback: async () => {
                   try {
                    const params = {id : this.currentContest.id};
                    const contest = await rf.getRequest('MarginOrderRequest').stopContest(params);
                    this.currentContest.status = 'closed';
                    this.$toastr('success', 'Closed contest successfully!');
                    CommonModal.hide("confirmModal");
                  } catch (e) {
                    console.log ('Error: ' + e)
                    if (e.response.data.message) {
                        this.$toastr('error', e.response.data.message);
                    }
                  }
                }
              }
            ]
          });
      },
      async editContest(){
         try {
          if(!await this.validateForm()){
          return;
        }
        const {startEntryTime,endEntryTime,startContestTime,endContestTime} = this;
        const formatType = 'Y-MM-DD HH:mm:ss';
        const start_entry_time = moment(this.startEntryDate).set("hour",startEntryTime.HH).set('minute',startEntryTime.mm).format(formatType);
        const end_entry_time = moment(this.endEntryDate).set("hour",endEntryTime.HH).set('minute',endEntryTime.mm).format(formatType);
        const start_contest_time = moment(this.startContestDate).set("hour",startContestTime.HH).set('minute',startContestTime.mm).format(formatType);
        const end_contest_time = moment(this.endContestDate).set("hour",endContestTime.HH).set('minute',endContestTime.mm).format(formatType);
        const params = {
          ...this.draftContest,
          start_entry_time,
          end_entry_time,
          start_contest_time,
          end_contest_time,
        }
          const contestId = this.draftContest.id;
          await rf.getRequest('MarginOrderRequest').editContest(params);
          this.$toastr('success', 'Update contest successfully!');
          this.contestList = [];
          await this.getContestList(['id', contestId]);
          this.index++;
        } catch (e) {
          console.log ('Error: ' + e)
          if (e.response.data.message) {
              this.$toastr('error', e.response.data.message);
          }
        }
      }
    },
    mounted() {
      this.$nextTick()
          .then(()=>{
            this.errors.clear();
          })
      this.$emit('EVENT_PAGE_CHANGE', this);
    },
    created() {
        this.getContestList(['status', 'started']);
    }
  }
</script>

<style lang="scss" scoped>
@import "@/assets/sass/variables";
  
  #leader-grouped-table{
    table{
      td{
        white-space: nowrap;
      }
    }
  }

  .nopadding-left-right{
    padding-left: 0;
    padding-right: 0;
  }

  .item_email_setting {
    display: inline-block;
    float: left;
    position: relative;
    .txt_email_setting {
      display: block;
      max-width: 100px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .tooltip_email_setting {
      position: absolute;
      top: 0px;
      left: 0px;
      line-height: 20px;
      padding: 5px 20px;
      left: 30%;
      background-color: $color_white;
      white-space: nowrap;
      width: auto;
      z-index: 10;
      font-size: $font_root;
      font-weight: 500;
      color: $color_mine_shaft;
      transition: 0.5s;
      display: none;
      box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.4);
      &:after {
        right: 100%;
        top: 50%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: rgba(136, 183, 213, 0);
        border-right-color: $color_white;
        border-width: 5px;
        margin-top: -5px;
      }
    }
    &:hover {
      .tooltip_email_setting {
        display: block;
        transition: 0.5s;
      }
    }
  }

  .item_name_setting {
    display: inline-block;
    float: left;
    position: relative;
    .txt_email_setting {
      display: block;
      max-width: 100px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    &:hover {
      .tooltip_name_setting {
        transition: 0.5s;
        display: inline-block;
      }
    }
    .tooltip_name_setting{
      position: absolute;
      top: 0px;
      line-height: 20px;
      padding: 5px 20px;
      left: 100%;
      background-color: $color_white;
      width: 300px;
      z-index: 10;
      font-size: $font_root;
      font-weight: 500;
      color: $color_mine_shaft;
      transition: 0.5s;
      display: none;
      box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.4);
      &:after {
        right: 100%;
        top: 50%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: rgba(136, 183, 213, 0);
        border-right-color: $color_white;
        border-width: 5px;
        margin-top: -5px;
      }
    }
  }
.Rectangle-23{
    margin-top: 10px;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid rgb(194, 189, 179);
}

.d-none{
  display: none;
}
.note-wrapper{
  padding-inline-start: 60px;
}
.invalid-feedback{
  color: $color-red-main;
}

.datetime-wrapper{
  display: flex;
}

.mt-1{
    margin-top:10px;
}
.tableContainer{
  margin-top: 30px;
}

.form-check-label{
  margin-right: 30px;
  .txt-inactive{
    font-weight: 300;
  }
  &[disabled] {
    cursor: not-allowed !important;
  }
}

.btn-add {
    height: 22px;
    border-radius: 3px;
    background-color: $color-caribbean-green;
    border: 1px solid $color-caribbean-green;
    font-size: $font_mini_mini;
    letter-spacing: 0.77px;
    color: $color-white;
    text-align: center;
    padding: 1px 12px;
    padding-top: 2px;
    display: block;
    float: right;
    i {
        font-size: 8px;
        position: relative;
        top: 0px;
        margin-right: 4px;
    }
    &:hover {
        border-color: $color-aquamarine;
        background-color: $color-aquamarine;
    }
}

.row{
    margin-right: 0;
    margin-left: 0;
}

.min_85 {
    min-width: 85px;
}

.min_110 {
    min-width: 110px;
}

.w_125 {
    width: 125px;
}
.text-left {
    text-align: left;
}
.team-name-td{

}


.order-history {
    .filter-container {
        .filter-item {
            float: left;
            margin: 0 5px;

            input {
                margin-top: 23px;
                display: inline-block;
                width: 145px;
                font-family: $font-family-roboto;

                &:hover, &:active, &:focus {
                    border-color: $color-jungle-green;
                }
            }

            select {
                width: 65px;
                display: inline-block;
                font-family: $font-family-roboto;
                font-size: $font_root;

                &:hover, &:active, &:focus {
                    border-color: $color-jungle-green;
                }
            }

            button {
                width: auto;
                height: 27px;
                font-size: $font-smaller;
                font-family: $font-family-roboto-bold;
            }

            .btn-reset {
                background-color: $color-caribbean-green;
                border: 1px solid $color-caribbean-green;
                width: 80px;
                text-transform: uppercase;
                color: $color-white;
                font-weight: 500;
                margin-left: 10px;

                &:hover {
                    border-color: $color-aquamarine;
                    background-color: $color-aquamarine;
                }
            }

            .btn-primary {
                padding: 0;
                min-width: 80px;

                &:hover {
                    border-color: $color-aquamarine;
                    background-color: $color-aquamarine;
                }
            }

            .coin-input {
                position: relative;
                display: inline-block;
                margin: 0 5px;
                margin-right: 0px;
                font-size: $font-small;
                font-family: $font-family-roboto;

                .coin-option {
                    background-color: $color-white;
                    max-height: 210px;
                    display: block;
                    overflow-y: auto;
                    margin-top: 5px;
                    position: absolute;
                    width: 79px;
                    left: 5px;
                    z-index: 10;
                    padding: 12px 0px;
                    box-shadow: 0px 3px 12px rgba(49, 49, 49, 0.51);

                    &:after {
                        bottom: 100%;
                        left: 50%;
                        border: solid transparent;
                        content: " ";
                        display: block;
                        height: 0;
                        width: 0;
                        position: absolute;
                        pointer-events: none;
                        border-color: rgba(136, 183, 213, 0);
                        border-bottom-color: $color_white;
                        border-width: 5px;
                        margin-left: -5px;
                    }

                    .option {
                        display: block;
                        width: 100%;
                        line-height: 20px;
                        cursor: pointer;
                        padding: 3px 14px;
                        font-size: 14px;
                        overflow: hidden;

                        &:hover {
                            color: $color-jungle-green;
                        }
                    }
                }
            }
        }

        .btn-action {
          float: right;
        }

        .aver-price {
          margin-top: 25px;
          padding-right: 100px;
          float: right;
        }

        .pair {
            input {
                width: 65px;
            }

            .text-pair {
                padding-left: 10px;
            }
        }

        .text-pair {
            color: $color-grey-dusty;
            font-family: $font-family-roboto;
            font-size: $font-small;
            margin-bottom: 5px;
        }

        .side {
            margin: 0 10px;
        }
    }

    .form-control {
        height: 30px;
        font-size: $font_small;
        padding-left: 11px;
        padding-right: 0;
    }

    .datatable {
        button.btnForm.bt-action {
            padding-left: 5px !important;
            padding-right: 5px !important;
            font-weight: 400 !important;
        }
    }

    .buy {
      color: $text-color-jade;
    }

    .sell {
        color: $text-color-red;
    }

    .btn-detail {
        background-color: $color-caribbean-green;
        padding: 2px 10px;
        font-size: $font-smaller;
        color: $color-white;
        border: 1px solid $color-caribbean-green;
        border-radius: 3px;
        text-transform: uppercase;
        font-family: $font-family-roboto;

        &:hover {
            background-color: $color-aquamarine;
            border-color: $color-aquamarine;
        }
    }
    .pb-10 {
      padding-bottom: 10px;
    }
}
</style>
<style lang="scss">
@import "@/assets/sass/variables";
  #entry-management {
    .modal-content {
      height: auto;
    }
    .group-detail-form-label-1 {
      min-width: 290px;
      font-size: 16px;
    }
    .sc_search_select {
      .icon-arrow1 {
        margin-left: 5px;
      }
    }
    .display-time {
      height: 30px;
      padding-left: 23px;
    }
    .time-picker {
      width: 110px;
    }
    .toolbar-element2 {
      .button_drop_search {
        width: 250px;
        >span{
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 90%;
        }
        &:hover, &:active, &:focus {
          border-color: $color-jungle-green;
          i.icon-arrow1 {
            color: $color-jungle-green;
          }
        }
      }
      .list_search_select,.box_list_search_select {
        width: 250px;
        span{
          word-break:break-word;
          max-width: 95%;
        }
        li {
          word-break: break-all;
          font-family: $font-family-roboto;
          font-size: $font-small;
          &:hover, &:active, &:focus {
            color: $color-jungle-green;
          }
        }
      }
      .box_list_search_select{
        max-height: 270px;
        overflow: overlay;
        box-shadow: 0px 3px 12px rgba(49, 49, 49, 0.51);
        &::-webkit-scrollbar{
          width: 5px;
        }
        &::-webkit-scrollbar-thumb{
          background: #ddd;
        }
        &::-webkit-scrollbar-track{
          background: #f1f1f1;
        }
      }
    }
    .date-picker-input input{
      background-color: white !important;
    }
    .disabled-datepicker{
      cursor: not-allowed !important;
      input{
      pointer-events: none;
      background-color: #eeeeee !important;

      }
    }
    .contest-list-select{
      .button_drop_search{
        background-color: #ffffff;
      }
    }
    #tr-entry-management{
      thead{
        tr {
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
    .table-team{
      tr:hover {
        background-color: #ececec;
        cursor: pointer;
      }
      tr {
        &.empty-data {
          &:hover {
            background-color: transparent;
            cursor: initial;
          }
          border-bottom: 1px solid transparent;
        }
        &.selectedTeamClass {
          td {
            background-color: #d6efe9;
            border-top: 1px solid #d6efe9;
          }
          position: relative;
          &:after {
            border: solid transparent;
            content: " ";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
            border-color: rgba(136, 183, 213, 0);
            border-left-color: #d6efe9;
            border-width: 20px;
            margin-top: 0px;
            right: -25px;
          }
        }
      }
    }
    .datetime-wrapper{
      .date-picker-input{
        width: 50%;
        input{
          width: 100% !important;
        }
      }
      .time-picker{
        .dropdown{
          width: 12em;
          .select-list{
            width: 100%;
          }
        }
      }
    }
  }
</style>