<template>
  <modal name="modal-trading-pair-information"
         width="590px" height="auto"
         class="modal-support-scroll"
         id="modal-erc-20"
         @before-open="beforeOpened"
         @before-close="beforeClosed"
         @opened="$emit('opened', $event)"
         @closed="$emit('closed', $event)">
    <div class="setting-popup" :class="params.class">
      <div class="btn-close" @click="$modal.hide('modal-trading-pair-information')">
        <i class="icon-close icon-close-popup"/>
      </div>
      <div class="popup-content">
        <div class="popup-title">{{ $t('modal_trading_pair_information.popup_title') }}</div>
        <div class="popup-form">
          <div class="form-group clearfix">
            <div class="form-item clearfix">
              <span class="option__name">{{ $t('modal_trading_pair_information.pair') }}</span>
              <div class="option__input-wrapper" :class="errors.has('pair') ? 'active-warning': ''">
                <select v-model="tradingSetting.currency" data-vv-validate-on="none" name="pair" v-validate="'required'" @change="onSelectMarket" class="option__input">
                  <option :value="market" v-for="market in markets">{{ token | uppercase }} / {{ market | uppercase }}</option>
                </select>
                <div class="warning-message">
                  <span>{{ errors.first('pair') }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="clearfix clearfix-7"></div>
          <div class="form-group clearfix">
            <p class="form-item__title">
              {{ $t('modal_trading_pair_information.trading_fee') }}
            </p>
            <!-- <div class="form-item clearfix">
              <span class="option__name">{{ $t('withdrawal_information.taker_fee_percent') }}</span>
              <div class="option__input-wrapper" :class="errors.has('taker_fee') ? 'active-warning': ''">
                <currency-input
                        v-validate="'required|max_value:1'"
                        :precision="8"
                        v-model="tradingSetting.taker_fee"
                        data-vv-validate-on="none"
                        @focus="resetErrors"
                        class="option__input"
                        name="fee"/>
                <div class="warning-message">
                  <span>{{ errors.first('taker_fee') }}</span>
                </div>
              </div>
            </div> -->
            <div class="form-item clearfix">
              <span class="option__name">{{ $t('trading_information.taker_fee_percent') }}</span>
              <div class="option__input-wrapper" :class="errors.has('taker_fee') ? 'active-warning': ''">
                <currency-input
                        v-validate="'required|max_value:100|min_value:0'"
                        :precision="8"
                        :max-length-cus="16"
                        v-model="tradingSetting.taker_fee"
                        data-vv-validate-on="none"
                        @focus="resetErrors"
                        class="option__input"
                        :data-vv-as="`taker fee`"
                        name="taker_fee"/>
                <div class="warning-message" v-if="errors.has('taker_fee')">
                  <span>{{ errors.first('taker_fee') }}</span>
                  <!-- <span>{{ errorsTakerFee }}</span> -->
                </div>
              </div>
            </div>

            <div class="form-item clearfix">
              <span class="option__name">{{ $t('trading_information.maker_fee_percent') }}</span>
              <div class="option__input-wrapper" :class="errors.has('maker_fee') ? 'active-warning': ''">
                <currency-input
                        v-validate="'required|max_value:100|min_value:0'"
                        :precision="8"
                        :max-length-cus="16"
                        v-model="tradingSetting.maker_fee"
                        data-vv-validate-on="none"
                        type="text"
                        @focus="resetErrors"
                        class="option__input"
                        :data-vv-as="`maker fee`"
                        name="maker_fee"/>
                <div class="warning-message" v-if="errors.has('maker_fee')">
                  <span>{{ errors.first('maker_fee') }}</span>
                  <!-- <span>{{ errorsMakerFee }}</span> -->
                </div>
              </div>
            </div>

          </div>
          
          <div class="clearfix clearfix-7"></div>

          <div class="form-group clearfix">
            <p class="form-item__title">
              {{ $t('modal_trading_pair_information.trading_pair') }}
            </p>

            <div class="form-item clearfix">
              <span class="option__name">{{ $t('modal_trading_pair_information.market_price') }}</span>
              <div class="option__input-wrapper" :class="errors.has('market_price') ? 'active-warning': ''">
                <currency-input
                        v-validate="'required|min_value:0.0000001|max_value:99999999999999'"
                        :precision="8"
                        :max-length-cus="16"
                        :data-vv-as="`market price`"
                        v-model="tradingSetting.market_price"
                        data-vv-validate-on="none"
                        @focus="resetErrors"
                        class="option__input"
                        name="market_price"/>
                <div class="warning-message" v-if="errors.has('market_price')">
                  <span>{{ errors.first('market_price') }}</span>
                  <!-- <span>{{ errorsMarketPrice }}</span> -->
                </div>
              </div>
            </div>

            <div class="form-item clearfix">
              <span class="option__name">{{ $t('trading_information.price_precision') }}</span>
              <div class="option__input-wrapper" :class="errors.has('precision') ? 'active-warning': ''">
                <currency-input
                        v-validate="tradingSetting.currency ? `required|min_value:1` : 'required|min_value:1'"
                        :precision="0"
                        v-model="precision"
                        :max-length-cus="16"
                        :data-vv-as="`precision`"
                        data-vv-validate-on="none"
                        type="text"
                        @focus="resetErrors"
                        class="option__input"
                        name="precision"/>
                <div class="warning-message">
                  <span>{{ errors.first('precision') }}</span>
                  <!-- <span>{{ errorsPrecision }}</span> -->
                </div>
              </div>
            </div>

            <div class="form-item clearfix">
              <span class="option__name">{{ $t('modal_trading_pair_information.minimum_amount') }}</span>
              <div class="option__input-wrapper" :class="errors.has('minimum_amount') ? 'active-warning' : ''">
                <currency-input
                        v-validate="'required|min_value:0.0000001'"
                        :precision="8"
                        :max-length-cus="16"
                        :data-vv-as="`minimum amount`"
                        v-model="tradingSetting.minimum_quantity"
                        data-vv-validate-on="none"
                        @focus="resetErrors"
                        class="option__input"
                        name="minimum_amount"/>
                <div class="warning-message" v-if="errors.has('minimum_amount')">
                  <span>{{ errors.first('minimum_amount') }}</span>
                  <!-- <span>{{ errorsMinimumAmount }}</span> -->
                </div>
              </div>
            </div>

            <div class="form-item clearfix">
              <span class="option__name">{{ $t('modal_trading_pair_information.quantity_precision') }}</span>
              <div class="option__input-wrapper" :class="errors.has('quantity_precision') ? 'active-warning' : ''">
                <currency-input
                        v-validate="'required|min_value:1|max_value:10'"
                        :precision="0"
                        :max-length-cus="16"
                        :data-vv-as="`quantity precision`"
                        v-model="tradingSetting.quantity_precision"
                        data-vv-validate-on="none"
                        @focus="resetErrors"
                        class="option__input"
                        name="quantity_precision"/>
                <div class="warning-message" v-if="errors.has('quantity_precision')">
                  <span>{{ errors.first('quantity_precision') }}</span>
                  <!-- <span>{{ errorsMinimumAmount }}</span> -->
                </div>
              </div>
            </div>

            <div class="form-item clearfix">
              <span class="option__name">{{ $t('modal_trading_pair_information.minimum_total') }}</span>
              <div class="option__input-wrapper" :class="errors.has('minimum_total') ? 'active-warning' : ''">
                <currency-input
                        v-validate="'required|min_value:0.0000001'"
                        :precision="8"
                        :max-length-cus="16"
                        :data-vv-as="`minimum total`"
                        v-model="tradingSetting.minimum_amount"
                        data-vv-validate-on="none"
                        @focus="resetErrors"
                        class="option__input"
                        name="minimum_total"/>
                <div class="warning-message" v-if="errors.has('minimum_total')">
                  <span>{{ errors.first('minimum_total') }}</span>
                  <!-- <span>{{ errorsMinimumTotal }}</span> -->
                </div>
              </div>
            </div>

            <div class="form-item clearfix">
              <span class="option__name">{{ $t('trading_information.zone') }}</span>
              <div class="option__input-wrapper" :class="errors.has('pair') ? 'active-warning': ''">
                <select v-model="tradingSetting.zone" data-vv-validate-on="none" name="zone" v-validate="'required'" class="option__input">
                  <option value="0" selected>{{ 0 | zoneName }}</option>
                  <option value="1">{{ 1 | zoneName }}</option>
                </select>
                <div class="warning-message">
                  <span>{{ errors.first('pair') }}</span>
                </div>
              </div>
            </div>

          </div>
        </div>

        <div class="button-group">
          <button class="button-item button-01 button--cancel" @click="$modal.hide('modal-trading-pair-information')">
            {{ $t('modal_trading_pair_information.btn_cancel') }}
          </button>
          <button class="button-item button-01 button--cancel" @click="submit">
            {{ $t('modal_trading_pair_information.btn_submit') }}
          </button>
        </div>
      </div>
    </div>
    <loading-modal />
  </modal>
</template>

<script>
  import InputOnlyNumber from '../common/InputOnlyNumber.vue';
  import LoadingModal from './Erc20LoadingModal';
  import { mapState } from 'vuex';
  import Utils from '../common/Utils';

  export default {
    name: 'ModalTradingPairInformtaion',
    data () {
      return {
        params: {},
        tradingSetting: { zone: "0" },
        markets: [],
        precision: '',
        precisionMax: 5,
        errorsTakerFee: '',
        errorsMakerFee: '',
        errorsPrecision: '',
        errorsMarketPrice: '',
        errorsMinimumAmount: '',
        errorsMinimumTotal: ''
      };
    },
    components: {
      LoadingModal,
      InputOnlyNumber,
    },
    inject: ['data'],
    computed: {
      ...mapState({
        masterdata: state => state.masterdata,
      }),
      token () {
        return this.data.params.coin_setting.symbol;
      },
    },
    methods: {
      resetErrors () {
        this.errors.clear();
      },
      getPrecisionMax () {
        return Utils.getPrecisionMax(this.tradingSetting.currency);
      },
      getAvailableMarkets () {
        if (!this.masterdata) {
          return [];
        }
        const selectedMarkets =  _.chain(this.data.params.trading_setting)
          .filter((pair) => this.tradingSetting.currency !== pair.currency)
          .map('currency')
          .union()
          .value();

        return _.chain(this.masterdata.coin_settings)
          .filter((pair) => !_.includes(selectedMarkets, pair.currency))
          .map('currency')
          .union()
          .value();
      },
      onSelectMarket () {
        this.tradingSetting.coin = _.toLower(this.token);
        this.$nextTick(async () => {
          // this.precisionMax = await this.getPrecisionMax();
          this.resetErrors();
        });
      },
      async beforeOpened (event) {
        this.params = event.params || {};
        this.tradingSetting = { ...this.params || {} };
        this.tradingSetting.zone = 0;
        this.tradingSetting.coin = this.token;
        this.markets = this.getAvailableMarkets();

        this.precision = Utils.convertPrecisionToNumber(this.tradingSetting.price_precision) || '';

        // if (this.tradingSetting.currency) {
        //   this.precisionMax = await this.getPrecisionMax();
        // }

        this.$emit('before-opened', event);
      },
      beforeClosed (event) {
        this.params = {payload: {}};
        this.$emit('before-closed', event);
      },
      validateCustom () {

        const valueTankerFee = parseFloat(this.tradingSetting.taker_fee);

        if (valueTankerFee > 100) {
          this.errorsTakerFee = this.$t('sales_point.error.bonus_percent');
        } else if (valueTankerFee == 0) {
          this.errorsTakerFee = this.$t('sales_point.error.bonus_diff_0');
        } else if (isNaN(valueTankerFee)) {
          this.errorsTakerFee = this.$t('sales_point.error.take_fee_required');
        } else {
          this.errorsTakerFee = '';
        }

        const valueMakerFee = parseFloat(this.tradingSetting.maker_fee);

        if (valueMakerFee > 100) {
          this.errorsMakerFee = this.$t('sales_point.error.bonus_percent');
        } else if (valueMakerFee == 0) {
          this.errorsMakerFee = this.$t('sales_point.error.bonus_diff_0');
        } else if (isNaN(valueMakerFee)) {
          this.errorsMakerFee = this.$t('sales_point.error.maker_fee_required');
        } else {
          this.errorsMakerFee = '';
        }

        const valuePrecision = parseFloat(this.precision);

        if (valuePrecision > this.precisionMax) {
          this.errorsPrecision = this.$t('');
        } else if (valuePrecision == 0) {
          this.errorsPrecision = this.$t('sales_point.error.bonus_diff_precision_0');
        } else if (isNaN(valuePrecision)) {
          this.errorsPrecision = this.$t('sales_point.error.precision_required');
        } else {
          this.errorsPrecision = '';
        }

        const valueMarketPrice = parseFloat(this.tradingSetting.market_price);

        if (valueMarketPrice > 99999999999999) {
          this.errorsMarketPrice = this.$t('');
        } else if (valueMarketPrice == 0) {
          this.errorsMarketPrice = this.$t('sales_point.error.market_than_0');
        } else if (isNaN(valueMarketPrice)) {
          this.errorsMarketPrice = this.$t('sales_point.error.market_required');
        } else {
          this.errorsMarketPrice = '';
        }

        const valueMinimumAmount = parseFloat(this.tradingSetting.minimum_quantity);

        if (valueMinimumAmount > 99999999999999) {
          this.errorsMinimumAmount = this.$t('');
        } else if (valueMinimumAmount == 0) {
          this.errorsMinimumAmount = this.$t('sales_point.error.minimum_amount_than_0');
        } else if (isNaN(valueMinimumAmount)) {
          this.errorsMinimumAmount = this.$t('sales_point.error.minimum_amount_required');
        } else {
          this.errorsMinimumAmount = '';
        }

        const valueQtyPrecision = parseFloat(this.tradingSetting.quantity_precision);

        if (valueQtyPrecision > 99999999999999) {
          this.errorsMinimumAmount = this.$t('');
        } else if (valueQtyPrecision == 0) {
          this.errorsMinimumAmount = this.$t('sales_point.error.quantity_precision_than_0');
        } else if (isNaN(valueQtyPrecision)) {
          this.errorsMinimumAmount = this.$t('sales_point.error.quantity_precision_required');
        } else {
          this.errorsMinimumAmount = '';
        }

        const valueMinimumTotal = parseFloat(this.tradingSetting.minimum_amount);

        if (valueMinimumTotal > 99999999999999) {
          this.errorsMinimumTotal = this.$t('');
        } else if (valueMinimumTotal == 0) {
          this.errorsMinimumTotal = this.$t('sales_point.error.minimum_total_than_0');
        } else if (isNaN(valueMinimumTotal)) {
          this.errorsMinimumTotal = this.$t('sales_point.error.minimum_total_required');
        } else {
          this.errorsMinimumTotal = '';
        }
        


      },
      submit () {

        this.validateCustom();

        this.errors.clear();
        const valuePrecision = parseFloat(this.precision);
        if (valuePrecision && valuePrecision > 5) {
          this.errors.add({field: 'precision', msg: this.$t('trading_information.precision_max', {precision: 5})});
          return;
        }

        this.$validator.validate().then((result) => {
          if (!result) {
            this.$modal.show('modal-loading');
            return;
          }

          this.tradingSetting.price_precision = Utils.convertNumberToPrecision(this.precision);

          let tradingSettingIndex = _.findIndex(this.data.params.trading_setting, (pair) => pair.uuid === this.tradingSetting.uuid);
          if (tradingSettingIndex > -1) {
            this.data.params.trading_setting.splice(tradingSettingIndex, 1, this.tradingSetting);
          } else {
            this.tradingSetting.uuid = Utils.uuid();
            this.data.params.trading_setting.push(this.tradingSetting);
          }

          this.$modal.hide('modal-trading-pair-information');
        });
      }
    },
  };
</script>

<style lang="scss" scoped>
@import "@/assets/sass/variables";

  @media screen and (max-height: 909px) {
    .modal-support-scroll {
      overflow-y: auto;
      padding: 30px 0;
    }
  }

  .setting-popup {
    .popup-content {
      width: auto;
      margin: 50px 120px;
    }
  }

  .clearfix-7 {
    display: block;
    width: 100%;
    height: 7px;
  }

  .popup-title {
    margin-bottom: 15px !important;
    font-family: $font-family-roboto-medium;
    line-height: 40px;
    color: $color-grey-dark;
    text-align: center;
    font-size: $font_big_20;
  }

  .popup-form {
    margin-bottom: 17px;
  }
  .btn-close {
    position: absolute;
    top: 24px;
    right: 24px;
    font-size: $font-smaller;
    width: 25px;
    height: 25px;
    background: transparent;
    color: $color_grey;
    text-align: center;
    line-height: 25px;
    padding: 2px;
    border-radius: 50%;
    cursor: pointer;
    &:hover {
      background-color: $color_alto;
      color: $color-white;
    }
  }
  .form-group {
    margin-bottom: 0px !important;

    .form-item {
      margin-bottom: 15px;
      .option__name {
        font-size: $font_root;
        line-height: 2.07;
        color: $color-grey-dusty;
        width: 133px;
        display: inline-block;
        float: left;
        font-family: $font-family-roboto;        
        padding: 8px 0px;
        height: 35px;
        line-height: 20px;
      }

      .option__input-wrapper {
        display: inline-block;
        width: calc(100% - 133px);
        position: relative;
        float: right;
        .option__input {
          background-color: $color-white;
          font-size: $font_root;
          color: $color-grey-dark;
          font-family: $font-family-roboto-medium;
          padding: 8px 17px 8px 8px;
          width: 100%;
          line-height: 20px;
          height: 35px;
          border: solid 1px $color_alto;
          border-radius: 3px;
        }
        &.active-warning {
          .option__input {
            border: solid 1px $color-denim;
          }
        }
        .warning-message {
          margin-top: 0 !important;
          span {
            bottom: 0px;
            top: auto;
            position: relative !important;
            color: $color-denim;
            font-family: $font-family-roboto;
            margin-top: 3px;
            font-size: $font-smaller;
          }
        }
      }

    }
    .form-item__title {
      font-size: $font_semi_big;
      font-family: $font-family-roboto-medium;
      line-height: 19px;
      color: $color-grey-dark;
      margin-bottom: 14px;
    }

  }

  .button-group {
    overflow: auto;
    width: 100%;
    text-align: center;

    .button-item {
      margin: 0;
      vertical-align: middle;

      &:first-child {
        margin-right: 6px;
      }

      &:last-child {
        margin-left: 6px;
      }
    }
  }

  .button-01 {
    display: inline-block;
    width: 90px;
    font-size: 12px;
    padding: 9px 05px;
    letter-spacing: 0.5px;
    text-align: center;
    border-radius: 3px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    background-color: $color-caribbean-green;
    border : 1px solid $color-caribbean-green;
    color: $color-white;
    transition: background-color 0.3s ease-in-out;
    letter-spacing: 0.92px;
    height: 35px;
    font-family: $font-family-roboto-bold;
    &:hover{
      background-color: $color-aquamarine;
      border: 1px solid $color-aquamarine;
    }
  }
</style>
