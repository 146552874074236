<template>
  <div class="botEdit_page" id="botEdit_page">
    <div class="action">
      <button class="btn btn-cancel" @click="$router.back()">{{
        $t('bots.btn_cancel') }}</button>
      <button v-if="$route.query.id" class="btn btn-update" @click="updateBot()">{{
        $t('bots.btn_update') }}</button>
      <button v-if="$route.query.id" class="btn btn-delete" @click="deleteBot()">{{
        $t('bots.btn_delete') }}</button>
      <button v-if="!$route.query.id" class="btn btn-update" @click="createBot()">{{
        $t('bots.btn_create') }}</button>
    </div>
    <div class="form-container">
      <!-- Main Inputs -->
      <div class="form-group">
        <label class="label-text" for="type">{{ $t('bots.type') }}</label>
        <select-box :on-focus="resetErrors" :maxlength="maxLength" :class="{ 'error': errors.has('type') }" id="type"
          :options="walletOptions" :value="formData.type" :on-change="selected => formData.type = selected"
          :placeholder="$t('bots.select_type')" />
        <div v-show="errors.has('type')" class="invalid-feedback">{{ errors.first('type') }}</div>
      </div>


      <div class="form-group">
        <label class="label-text" for="start_sleep_time">{{ $t('bots.start_sleep_time') }}</label>
        <input @focus="resetErrors" :maxlength="maxLength" :class="{ 'error': errors.has('start_sleep_time') }"
          v-model="formData.startSleepTime" type="text" id="start_sleep_time"
          :placeholder="$t('bots.enter_start_sleep_time')">
        <div v-show="errors.has('start_sleep_time')" class="invalid-feedback">{{ errors.first('start_sleep_time') }}
        </div>
      </div>

      <div class="form-group">
        <label class="label-text" for="end_sleep_time">{{ $t('bots.end_sleep_time') }}</label>
        <input @focus="resetErrors" :maxlength="maxLength" :class="{ 'error': errors.has('end_sleep_time') }"
          type="text" id="end_sleep_time" :placeholder="$t('bots.enter_end_sleep_time')"
          v-model="formData.endSleepTime">
        <div v-show="errors.has('end_sleep_time')" class="invalid-feedback">{{ errors.first('end_sleep_time') }}</div>
      </div>

      <div class="form-group">
        <label class="label-text" for="symbol">{{ $t('bots.symbol') }}</label>
        <select-box :on-focus="resetErrors" :maxlength="maxLength" :class="{ 'error': errors.has('symbol') }"
          id="symbol" :options="walletOptions" :value="formData.symbol" :placeholder="$t('bots.select_symbol')"
          :on-change="selected => formData.symbol = selected" />
        <div v-show="errors.has('symbol')" class="invalid-feedback">{{ errors.first('symbol') }}</div>
      </div>

      <div class="form-group">
        <label class="label-text" for="api_key">{{ $t('bots.api_key') }}</label>
        <input @focus="resetErrors" :maxlength="maxLength" :class="{ 'error': errors.has('api_key') }"
          v-model="formData.apiKey" type="number" id="api_key" :placeholder="$t('bots.enter_api_key')">
        <div v-show="errors.has('api_key')" class="invalid-feedback">{{ errors.first('api_key') }}</div>
      </div>

      <div class="form-group">
        <label class="label-text" for="secret_key">{{ $t('bots.secret_key') }}</label>
        <input @focus="resetErrors" :maxlength="maxLength" :class="{ 'error': errors.has('secret_key') }" type="text"
          id="secret_key" :placeholder="$t('bots.enter_secret_key')" v-model="formData.secretKey">
        <div v-show="errors.has('secret_key')" class="invalid-feedback">{{ errors.first('secret_key') }}</div>
      </div>
      <div class="form-group w-25">
        <label class="label-text" for="min_quote_quantity">{{ $t('bots.min_quote_quantity') }}</label>
        <input @focus="resetErrors" :maxlength="maxLength" :class="{ 'error': errors.has('min_quote_quantity') }"
          type="text" id="min_quote_quantity" :placeholder="$t('bots.enter_min_quote_quantity')"
          v-model="formData.minQuoteQuantity">
        <div v-show="errors.has('min_quote_quantity')" class="invalid-feedback">{{ errors.first('min_quote_quantity') }}
        </div>
      </div>
      <div class="form-group w-25">
        <label class="label-text" for="max_quote_quantity">{{ $t('bots.max_quote_quantity') }}</label>
        <input @focus="resetErrors" :maxlength="maxLength" :class="{ 'error': errors.has('max_quote_quantity') }"
          type="text" id="max_quote_quantity" :placeholder="$t('bots.enter_max_quote_quantity')"
          v-model="formData.maxQuoteQuantity">
        <div v-show="errors.has('max_quote_quantity')" class="invalid-feedback">{{ errors.first('max_quote_quantity') }}
        </div>
      </div>
      <div class="form-group w-25">
        <label class="label-text" for="base_precision">{{ $t('bots.base_precision') }}</label>
        <input @focus="resetErrors" :maxlength="maxLength" :class="{ 'error': errors.has('base_precision') }"
          type="text" id="base_precision" :placeholder="$t('bots.enter_base_precision')"
          v-model="formData.basePrecision">
        <div v-show="errors.has('base_precision')" class="invalid-feedback">{{ errors.first('base_precision') }}</div>
      </div>
      <div class="form-group w-25">
        <label class="label-text" for="quote_precision">{{ $t('bots.quote_precision') }}</label>
        <input @focus="resetErrors" :maxlength="maxLength" :class="{ 'error': errors.has('quote_precision') }"
          type="text" id="quote_precision" :placeholder="$t('bots.enter_quote_precision')"
          v-model="formData.quotePrecision">
        <div v-show="errors.has('quote_precision')" class="invalid-feedback">{{ errors.first('quote_precision') }}</div>
      </div>
    </div>

    <div class="break-line"></div>
    <!-- Networks Settings -->
    <div class="quote-section">
      <div class="network-section">
        <h4 class="network-label">
          {{ $t('bots.asks') }}
        </h4>
        <div v-for="(ask, index) in asks" :key="ask.id" class="quote-item">
          <div class="form-group w-25">
            <label class="label-text">{{ " " }}</label>
            <div class="input-group">
              <input @focus="resetErrors" :maxlength="maxLength"
                :class="{ 'error': errors.has(`ask.percent[${index}]`) }" type="text" :placeholder="0"
                v-model="ask.percent"><span class="unit">%</span>
            </div>
            <div v-show="errors.has(`ask.percent[${index}]`)" class="invalid-feedback">{{
              errors.first(`ask.percent[${index}]`)
              }}
            </div>
          </div>
          <div class="form-group w-50">
            <label class="label-text" for="quote">{{ $t('bots.quote') }}</label>
            <input @focus="resetErrors" :maxlength="maxLength" :class="{ 'error': errors.has(`ask.quote[${index}]`) }"
              type="text" id="quote" :placeholder="0" v-model="ask.quote">
            <div v-show="errors.has(`ask.quote[${index}]`)" class="invalid-feedback">{{
              errors.first(`ask.quote[${index}]`) }}
            </div>
          </div>

          <img src="@/assets/images/icon/delete-icon.svg" @click="removeAsk(index)" />
        </div>
        <!-- Add Item Button -->
        <button class="btn-add" @click="addAsk()">
          <span class="icon-plus"></span>
          {{ $t('bots.btn_add_item') }}
        </button>
      </div>

      <div class="network-section">
        <h4 class="network-label">
          {{ $t('bots.bids') }}
        </h4>
        <div v-for="(bid, index) in bids" :key="bid.id" class="quote-item">
          <div class="form-group w-25">
            <label class="label-text">{{ " " }}</label>
            <div class="input-group">
              <input @focus="resetErrors" :maxlength="maxLength"
                :class="{ 'error': errors.has(`bid.percent[${index}]`) }" type="text" :placeholder="0"
                v-model="bid.percent"><span class="unit">%</span>
            </div>
            <div v-show="errors.has(`bid.percent[${index}]`)" class="invalid-feedback">{{
              errors.first(`bid.percent[${index}]`)
              }}
            </div>
          </div>
          <div class="form-group w-50">
            <label class="label-text" for="quote">{{ $t('bots.quote') }}</label>
            <input @focus="resetErrors" :maxlength="maxLength" :class="{ 'error': errors.has(`bid.quote[${index}]`) }"
              type="text" id="quote" :placeholder="0" v-model="bid.quote">
            <div v-show="errors.has(`bid.quote[${index}]`)" class="invalid-feedback">{{
              errors.first(`bid.quote[${index}]`) }}
            </div>
          </div>

          <img src="@/assets/images/icon/delete-icon.svg" @click="removeBid(index)" />
        </div>
        <!-- Add Item Button -->
        <button class="btn-add" @click="addBid()">
          <span class="icon-plus"></span>
          {{ $t('bots.btn_add_item') }}
        </button>
      </div>
    </div>

    <modal class="confirm_modal" name="update_modal" :title="$t('account.modal.confirm')">
      <div slot="body" class="body-popup">
        {{ $t('bots.popup_update_content') }}
      </div>
    </modal>

    <modal class="confirm_modal" name="delete_modal" :title="$t('account.modal.confirm')">
      <div slot="body" class="body-popup">
        {{ $t('bots.popup_delete_content') }}
      </div>
    </modal>

    <modal class="confirm_modal" name="create_modal" :title="$t('account.modal.confirm')">
      <div slot="body" class="body-popup">
        {{ $t('bots.popup_create_content') }}
      </div>
    </modal>

    <modal class="confirm_modal" :enableClose="enableClose" name="loadingUpload">
      <div slot="body">
        <vue-loading type="bars" v-if="isLoading" color="#2DAC91" :size="{ width: '60px', height: '80px' }">
        </vue-loading>
      </div>
    </modal>
  </div>
</template>

<script>
import rf from "../../lib/RequestFactory";
import Modal from "../../components/Modal";
import MultiSelectBox from "../../components/MultiSelectBox.vue";
import Const from "../../common/Const";
import Checkbox from "../../components/Checkbox.vue";
import SelectBox from "../../components/SelectBox";
import Utils from "../../common/Utils";

export default {
  components: {
    Modal,
    MultiSelectBox,
    Checkbox,
    SelectBox
  },
  data() {
    return {
      titlePage: this.$route.query.id ? window.i18n.t('bots.title_edit') : window.i18n.t('bots.title_create'),
      isLoading: false,
      enableClose: true,
      formData: {
        type: '',
        startSleepTime: '',
        endSleepTime: '',
        symbol: '',
        apiKey: '',
        secretKey: '',
        minQuoteQuantity: '',
        maxQuoteQuantity: '',
        basePrecision: '',
        quotePrecision: ''
      },
      asks: [
        { id: Utils.uuid(), isSaved: true, percent: 0, quote: 1000 },
      ],
      bids: [
        { id: Utils.uuid(), isSaved: true, percent: 0, quote: 1000 },
      ],
      exchangeOptions: [
        { id: 'binance', name: "BINANCE" },
        { id: 'coinbase', name: "COINBASE" },
      ],
      walletOptions: [
        { id: 'spot', name: "SPOT" },
        { id: 'future', name: "FUTURE" },
      ],
      networkOptions: [
        { id: 0, name: "ETHEREUM" },
        { id: 1, name: "BINANCE" },
        { id: 2, name: "SONALA" },
      ],
      maxLength: Const.MAX_LENGTH_INPUT
    };
  },
  methods: {
    handleCheck(value, field, index) {
      this.networks[index][field] = value;
    },
    addAsk() {
      this.asks.push({
        id: Utils.uuid(),
        percent: '',
        quote: ''
      });
    },
    addBid() {
      this.bids.push({
        id: Utils.uuid(),
        percent: '',
        quote: ''
      });
    },
    removeAsk(index) {
      this.asks.splice(index, 1);
    },
    removeBid(index) {
      this.bids.splice(index, 1);
    },
    createBot() {
      if (!this.validate()) return;
      this.showModalConfirm('create_modal', this.deleteNetwork);
    },
    updateBot() {
      if (!this.validate()) return;
      this.showModalConfirm('update_modal', this.deleteNetwork);
    },
    deleteBot() {
      this.showModalConfirm('delete_modal', this.deleteNetwork);
    },
    deleteNetwork(params) {
      // rf.getRequest('AAAAAAAAA').changeActiveStatusAccount(params).then(res => {
      //     if (res?.success) {
      //         this.$toastr('success', this.$t('bots.toast_delete_success'));
      //         this.getAccountDetail();
      //     } else {
      //         this.$toastr('error', this.$t('bots.toast_delete_fail'));
      //     }
      // });
      this.$toastr('error', this.$t('bots.toast_delete_fail'));
    },
    showModalConfirm(modalName, callback, params) {
      CommonModal.show(modalName, {
        position: 'center',
        mask: true,
        buttons: [
          {
            label: window.i18n.t('common.action.no'),
            class: "btn-no",
            style: {
              background: 'rgb(191, 191, 191);'
            },
            callback: () => {
              CommonModal.hide(modalName);
            }
          },
          {
            label: window.i18n.t('common.action.yes'),
            focused: true,
            style: {
              background: 'rgb(0, 112, 192)'
            },
            callback: () => {
              CommonModal.hide(modalName);
              this.loadingUploadBegin();
              callback(params);
              this.loadingUploadFinish();
            }
          }
        ]
      });
    },
    validate() {
      this.errors.clear();
      if (!this.formData.type) {
        this.errors.add({ field: 'type', msg: this.$t('bots.error_name_field') });
      }
      if (!this.formData.startSleepTime) {
        this.errors.add({ field: 'start_sleep_time', msg: this.$t('bots.error_start_sleep_time_field') });
      }
      if (!this.formData.endSleepTime) {
        this.errors.add({ field: 'end_sleep_time', msg: this.$t('bots.error_end_sleep_time_field') });
      }
      if (!this.formData.symbol) {
        this.errors.add({ field: 'symbol', msg: this.$t('bots.error_symbol_field') });
      }
      if (!this.formData.apiKey) {
        this.errors.add({ field: 'api_key', msg: this.$t('bots.error_api_key_field') });
      }
      if (!this.formData.secretKey) {
        this.errors.add({ field: 'secret_key', msg: this.$t('bots.error_secret_key_field') });
      }
      if (!this.formData.minQuoteQuantity) {
        this.errors.add({ field: 'min_quote_quantity', msg: this.$t('bots.error_min_quote_quantity_field') });
      }
      if (!this.formData.maxQuoteQuantity) {
        this.errors.add({ field: 'max_quote_quantity', msg: this.$t('bots.error_max_quote_quantity_field') });
      }
      if (!this.formData.basePrecision) {
        this.errors.add({ field: 'base_precision', msg: this.$t('bots.error_base_precision_field') });
      }
      if (!this.formData.quotePrecision) {
        this.errors.add({ field: 'quote_precision', msg: this.$t('bots.error_quote_precision_field') });
      }
      if (this.asks.length > 0) {
        this.validateAsks();
      }
      if (this.bids.length > 0) {
        this.validateBids();
      }
      if (this.errors.count() > 0) {
        return false;
      }
      return true;
    },
    validateAsks() {
      this.asks.forEach((network, index) => {
        if (!network.percent) {
          this.errors.add({ field: `ask.percent[${index}]`, msg: this.$t('bots.error_percent_field') });
        }
        if (!network.quote) {
          this.errors.add({ field: `ask.quote[${index}]`, msg: this.$t('bots.error_quote_field') });
        }
      });
    },
    validateBids() {
      this.bids.forEach((network, index) => {
        if (!network.percent) {
          this.errors.add({ field: `bid.percent[${index}]`, msg: this.$t('bots.error_percent_field') });
        }
        if (!network.quote) {
          this.errors.add({ field: `bid.quote[${index}]`, msg: this.$t('bots.error_quote_field') });
        }
      });
    },
    loadingUploadBegin() {
      this.enableClose = false;
      this.isLoading = true;
      CommonModal.show('loadingUpload');
    },
    loadingUploadFinish() {
      CommonModal.hide('loadingUpload');
      this.isLoading = false;
      this.enableClose = true;
    },
  },
  created() {
    // this.getOptionData();
  },
  mounted() {
    this.$emit('EVENT_PAGE_CHANGE', this);
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/variables";

.botEdit_page {
  background-color: $color-white;
  border-radius: 10px;
  padding: 30px 40px;
  margin-top: 30px;

  .action {
    display: flex;
    justify-content: right;
    margin-bottom: 30px;
  }

  .btn {
    margin: 0 5px;
    width: auto;
    margin-top: 18px;
    height: 32px;
    font-family: $mulish-bold;
    border-radius: 10px;
    text-transform: uppercase;

    &.btn-update {
      border: 1px solid $color-caribbean-green;
      color: $color_white;
      background-color: $color-caribbean-green;

      &:hover {
        background-color: $color-aquamarine;
        border-color: $color-aquamarine;
      }
    }

    &.btn-cancel {
      background-color: $color-bright-gray;
      border-color: $color-bright-gray;
      color: $color-black;

      &:hover {
        opacity: 0.7;
      }
    }

    &.btn-delete {
      background-color: $color-red-main;
      border-color: $color-red-main;
      color: $color-white;

      &:hover {
        opacity: 0.7;
      }
    }
  }

  .form-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px 20px;
  }

  .form-group {
    display: flex;
    flex-direction: column;
    width: calc((100% - 40px) / 3);

    &.status {
      border-radius: 10px;
      margin-top: 10px;
      padding: 8px 16px;
      border: 1px solid $color-bright-gray;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .label-text {
        margin: 0;
        font-family: $mulish-medium;
        font-size: 16px;
      }
    }

    &.w-66 {
      width: calc(66% - 1px);
    }

    &.w-50 {
      width: calc(50% - 1px);
    }

    &.w-25 {
      width: calc(25% - 15px);
    }

    .input-group {
      position: relative;

      .unit {
        margin-right: 10px;
        font-family: $mulish-medium;
      }
    }
  }

  .label-text {
    font-size: $font-root;
    color: $color-grey-dusty;
    font-family: $mulish-regular;
    font-weight: 400;
    margin-bottom: 7px;
    padding-left: 5px;
  }

  input[type=text],
  input[type=number] {
    padding: 8px 16px;
    border: 1px solid $color-bright-gray !important;
    border-radius: 4px;
    font-size: 16px;
    border-radius: 10px !important;
    font-family: $mulish-medium;
    box-shadow: none !important;
    height: auto;
    width: 100%;

    &.error {
      border: solid 1px $color-red-main !important;
    }
  }

  input[type=number] {
    padding-right: 50px;
  }

  .invalid-feedback {
    color: $color-red-main;
    font-size: 14px;
  }

  .break-line {
    margin-top: 40px;
    margin-bottom: 30px;
    border-top: 1px solid $color-alto;
    position: relative;
    display: flex;
    align-items: center;

    .label {
      margin-left: 40px;
      background-color: $color-white;
      padding: 0 16px;
      font-family: $mulish-medium;
      font-size: large;
      color: $color-black;
      position: absolute;
    }
  }

  .quote-section {
    display: flex;
  }

  .network-section {
    flex: 1;

    &:first-child {
      padding-right: 30px;
      border-right: 1px dashed $color-alto;
    }

    &:last-child {
      padding-left: 30px;
    }

    .quote-item {
      display: flex;
      gap: 20px;

      img {
        margin-top: 8px;
        width: 18px;
        height: auto;
        cursor: pointer;
      }

      .form-group {
        display: flex;

        &:first-child {
          margin-top: 20px;
        }

        input {
          height: 32px;
        }
      }
    }
  }

  .network-label {
    font-family: $mulish-bold;

    img {
      margin-left: 16px;
      margin-top: -2px;
      width: 18px;
      height: 18px;
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  .btn-add {
    color: $color-caribbean-green;
    border-radius: 10px;
    font-weight: 700;
    height: 32px;
    font-family: $mulish-medium;
    border: none;
    font-size: medium;
    background: none;
    transition: all linear 0.3s;
    padding: 0 16px;
    margin-left: 30px;
    margin-top: 30px;

    &:hover {
      background-color: $color_grey_head;
    }

    .icon-plus {
      color: $color-caribbean-green;
      margin-right: 8px;
    }
  }
}
</style>


<style lang="scss">
@import "@/assets/sass/variables";

#botEdit_page {
  .multi-select-dropdown {
    border-radius: 10px;
    border: 1px solid $color-bright-gray;

    &.error {
      border: 1px solid $color-red-main;
    }

    .selected-items {
      height: 38.84px;
      gap: 8px;
      border: none;

      .selected-item {
        margin: 0;
      }

      input {
        display: none;
      }
    }
  }

  .sc_search_select {
    border-radius: 10px;
    border: 1px solid $color-bright-gray;

    &.error {
      border: 1px solid $color-red-main;
    }

    .group_search_select {
      .button_drop_search {
        height: 38.84px;
        padding: 10px 16px;
        border-radius: 10px;
        font-family: $mulish-medium;
        background-color: $color-white;
        border: none;

        span {
          font-family: $mulish-medium;
          font-size: medium;
        }

        i.icon-arrow1 {
          color: $color_dove_gray;
          font-size: 14px;
        }
      }
    }

    .box_list_search_select {
      .list_search_select {
        border-radius: 10px;

        li {
          font-family: $font-family-roboto;
          font-size: $font-small;
          padding: 6px 12px;

          &:first-child {
            display: none;
          }

          &:hover,
          &:active,
          &:focus {
            color: $color-jungle-green;
          }
        }
      }
    }
  }

  .modal {
    .body-popup {
      font-family: $mulish-medium;

      p,
      strong {
        font-family: $mulish-medium;
      }
    }

    .btn-no {
      background-color: $color-bright-gray;
      border-color: $color-bright-gray;
      color: $color-black;

      &:hover {
        opacity: 0.7;
      }
    }
  }
}
</style>