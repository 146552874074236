<template>
  <div class="margin-order clearfix mg_bot50" id="margin-order">

    <div class="select_tab_container">
      <div class="tab-header">
        <ul>
          <li :class="{'active': tab === tabDefs[0]}">
            <a
              href="javascript:void(0)"
              @click="selectTab(tabDefs[0])"
            ><span>{{ tabNameDefs[0] }}</span></a>
          </li>
          <li :class="{'active': tab === tabDefs[1]}">
            <a
              href="javascript:void(0)"
              @click="selectTab(tabDefs[1])"
            ><span>{{ tabNameDefs[1] }}</span></a>
          </li>
        </ul>
      </div>
    </div>

    <div class="filter-container">
      <div class="filter-item date">
        <div class='date-text'>{{ $t('orders.open_order.date') }}:</div>
        <date-picker v-model="startDate" class="date-picker-input no-right-radius" :format="customFormatter"></date-picker>
        <date-picker v-model="endDate" class="date-picker-input no-left-radius" :format="customFormatter"></date-picker>
      </div>
      <div class="filter-item side">
        <div class="label-text date-text">{{ $t('orders.open_order.side') }}:</div>
        <div class="toolbar-element2">
          <select-box
              v-model="filters.side"
              :placeholder="$t('common.trade_type.all')"
              :options="sideDefs"/>
        </div>
      </div>
      <div class="filter-item">
        <input
          title
          type="text"
          :placeholder="$t('orders.open_order.search')"
          @keyup.enter="search"
          class="form-control"
          name="searchKey"
          v-model="filters.searchKey"
        >
      </div>
      <div class="filter-item btn-action">
        <button class="btn btn-primary" @click="search()">{{ $t('orders.open_order.search') }}</button>
        <button class="btn btn-reset" @click="refresh()">{{ $t('orders.open_order.reset') }}</button>
      </div>
    </div>

    <div class="datatable">
      <data-table-4 :getData="getData" ref="datatable" :column="11" :msgEmptyData="msg_empty" class="scroll">
        <th data-sort-field="updated_at" class="text-left">{{ $t('margin_orders.orders.updated_at') }}</th>
        <th class="text-left">{{ $t('margin_orders.orders.instrument_symbol') }}</th>
        <th class="text-left">{{ $t('margin_orders.orders.side') }}</th>
        <th class="text-left">{{ $t('margin_orders.orders.type') }}</th>
        <th class="text-left">{{ $t('margin.orders.position.owner_email') }}</th>
        <!-- <th data-sort-field="manager_email" class="text-left">{{ $t('margin.orders.position.manager_email') }}</th> -->
        <th class="text-left">{{ $t('margin_orders.orders.quantity') }}</th>
        <th class="text-left">{{ $t('margin_orders.orders.price') }}</th>
        <th class="text-left">{{ $t('margin_orders.order_value') }}</th>
        <th class="text-left">{{ $t('margin_orders.orders.remaining') }}</th>
        <th class="text-left">{{ $t('margin_orders.orders.stop_price') }}</th>
        <th class="text-left">{{ $t('margin_orders.orders.status') }}</th>
        <th class="text-left">{{ $t('margin_orders.orders.reference_id') }}</th>
        <th class="text-left min-width-80">{{ $t('margin_orders.orders.note') }}</th>
        <th class="text-left mgr-10" >{{ $t('margin_orders.orders.text') }}</th>
        <template slot="body" slot-scope="props">
          <tr>
            <td class="text-left">
              <div>{{ props.item.updated_at | formatTimeStamp('YYYY-MM-DD') }}</div>
              <div>{{ props.item.updated_at | formatTimeStamp('HH:mm:ss') }}</div>
            </td>
            <td class="text-left">{{props.item.instrument_symbol}}</td>
            <td class="text-left">{{props.item.side | tradeType}}</td>
            <td class="text-left">{{props.item.type | upperFirst}}</td>
            <td class="text-left w_125">
              <div class="item_email_setting">
                  <span class="txt_email_setting">{{ props.item.owner_email }}</span>
                  <span class="tooltip_email_setting">{{ props.item.owner_email }}</span>
              </div>
            </td>
            <!-- <td class="text-left">{{props.item.manager_email}}</td> -->
            <td class="text-left">{{props.item.quantity | formatCurrencyAmount(null, '0') }}</td>
            <td class="text-left" v-if="props.item.price">{{props.item.price | formatCurrencyAmount(null, '0') }}</td>
            <td class="text-left" v-else>--</td>
            <td class="text-left" v-if="props.item.order_value">{{props.item.order_value | formatCurrencyAmount(null, '0') }}</td>
            <td class="text-left" v-else>--</td>
            <td class="text-left">{{props.item.remaining | formatCurrencyAmount(null, '0') }}</td>
            <td class="text-left" v-if="props.item.stop_price">{{props.item.stop_price | formatCurrencyAmount(null, '0') }}</td>
            <td class="text-left" v-else>--</td>
            <td class="text-left">{{props.item | margin_order_status}}</td>
            <td class="text-left" v-if="props.item.reference_id">{{props.item.reference_id}}</td>
            <td class="text-left" v-else>--</td>
            <td class="text-left">{{props.item | order_note}}</td>
            <td class="text-right-pr-15">{{props.item | order_text}}</td>
          </tr>
        </template>
      </data-table-4>
    </div>
  </div>
</template>

<script>
import DatePicker from "vuejs-datepicker";
import moment from "moment";
import rf from "../../lib/RequestFactory";
import COMMON_CONST from "../../common/Const";
import SelectBox from "../../components/SelectBoxTabFocus";
import DataTable4 from "../../components/DataTable4"

export default {
  components: {
    DatePicker,
    SelectBox,
    DataTable4
  },
  data() {
    return {
      titlePage: window.i18n.t("margin_orders.title"),
      startDate: moment().subtract(1, "week").toDate(),
      endDate: new Date(),
      filters: {
        side: "",
        searchKey: ""
      },
      tab: 'open',
      tabDefs: ['open', 'history'],
      tabNameDefs: [
        window.i18n.t("margin_orders.active_order"),
        window.i18n.t("margin_orders.order_history")
      ],
      sideDefs: [
        { id: "", name: window.i18n.t('common.all') },
        { id: "buy", name: window.i18n.t('common.trade_type.buy') },
        { id: "sell", name: window.i18n.t('common.trade_type.sell') }
      ],
      msg_empty: this.$t('margin.table_order_active.empty_msg'),
    };
  },
  watch: {
    tab(value) {
      if (value == 'open') {
        this.msg_empty = this.$t('margin.table_order_active.empty_msg')
      } else {
        this.msg_empty = this.$t('margin.table_order_history.empty_msg')
      }
      this.refresh();
    }
  },
  methods: {
    selectTab(tab) {
      this.tab = tab;
    },
    search() {
      let filterParams = {
        start_date: moment(this.startDate).startOf("day").format("x"),
        end_date: moment(this.endDate).endOf("day").format("x")
      };
      if (moment.unix(this.startDate / 1000).format("YYYY-MM-DD") > moment.unix(this.endDate / 1000).format("YYYY-MM-DD")) {
        this.$toastr("error", this.$t("common.date.warning"));
        return;
      }
      if (this.filters.searchKey && this.filters.searchKey != "") {
        filterParams.search_key = this.filters.searchKey;
        // filterParams.start_date = null;
        // filterParams.end_date = null;
      }
      if (this.filters.side) {
        filterParams.side = this.filters.side.id;
      }
      this.$refs.datatable.filter(filterParams);
    },
    refresh() {
      this.resetInput();
      this.$refs.datatable.refresh();
    },
    resetInput() {
      this.filters = Object.assign(this.filters, {
        side: "",
        searchKey: ""
      });
      this.startDate = moment().subtract(1, 'week').toDate();
      this.endDate = new Date();
      $(".datatable").find("th").attr("data-sort-order", "");
    },
    getData(params) {
      let defaultParams = {
        tab: this.tab
      };
      if (!this.filters.searchKey || this.filters.searchKey == "") {
        defaultParams = {
          ...defaultParams,
          start_date: moment(this.startDate).startOf("day").format("x"),
          end_date: moment(this.endDate).endOf("day").format("x")
        };
      }
      return rf
        .getRequest("MarginOrderRequest")
        .getOrders(Object.assign(defaultParams, params));
    },
    customFormatter(date) {
      return moment(date).format('YYYY - MM - DD');
    },
  },
  mounted() {
    this.$emit("EVENT_PAGE_CHANGE", this);
  },
  created() {
  }
};
</script>

<style lang="scss">
@import "@/assets/sass/variables";
.min-width-80 {
  min-width: 80px;
}
.date-picker-input {
  display: inline-block;
  input {
    padding: 0 5px 0px 23px;
    width: 110px;
    border-radius: 3px;
    -webkit-box-shadow: none;
    box-shadow: none;
    height: 30px;
    line-height: 30px;
    border: 1px solid #d2d6de;
    background-image: url('@/assets/images/icon/date-time.svg') !important;
    background-color: $color-white-smoke !important;
    background-size: 12px;
    background-position: center left 5px;
    background-repeat: no-repeat !important;
    color: $color-grey-dusty;
    &:hover, &:active, &:focus {
      border-color: $color-jungle-green;
      z-index: 9;
    }
  }
}
.no-left-radius {
  input {
    border-radius: 0px 3px 3px 0px;
    margin-left: -1px;
  }
}
.no-right-radius {
  input {
    border-radius: 3px 0px 0px 3px;
  }
}
#margin-order {
  font-family: $font_family_root;
  .sc_search_select {
    .group_search_select {
      .button_drop_search {
        width: 100%;
        height: 30px !important;
        padding: 5px 10px 5px 10px !important;
        border-radius: 3px;
        margin-right: 0px;
        font-size: $font-small !important; 
        font-family: $font-family-roboto;
        background-color: transparent;
        span{
          font-size: $font-small;
        }
        i.icon-arrow1 {
          margin-left: 5px;
          color: $color_dove_gray;
        }
        &:focus,
        &:active,
        &:hover {
          border-color: $color-jungle-green;
          i.icon-arrow1 {
            color: $color-jungle-green;
          }
        }
      }
      .box_list_search_select {
        .list_search_select {
          li {
            font-family: $font-family-roboto;
            font-size: $font-small;
            &:hover, &:active, &:focus {
              color: $color-jungle-green;
            }
          }
        }
      }
    }
  }
  tr {
    white-space: nowrap !important;
    text-overflow: ellipsis !important;
  }
}
</style>

<style lang="scss" scoped>
@import "@/assets/sass/variables";
.blue {
  color: $color-jungle-green;
}
.item_email_setting {
      display: inline-block;
      float: left;
      position: relative;
      .txt_email_setting {
        display: block;
        max-width: 150px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .tooltip_email_setting {
        position: absolute;
        top: 0px;
        left: 0px;
        line-height: 20px;
        padding: 5px 20px;
        left: 100%;
        background-color: $color_white;
        white-space: nowrap;
        width: auto;
        z-index: 10;
        font-size: $font_root;
        font-weight: 500;
        color: $color_mine_shaft;
        transition: 0.5s;
        display: none;
        box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.4);
        &:after {
          right: 100%;
          top: 50%;
          border: solid transparent;
          content: " ";
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
          border-color: rgba(136, 183, 213, 0);
          border-right-color: $color_white;
          border-width: 5px;
          margin-top: -5px;
        }
      }
      &:hover {
        .tooltip_email_setting {
          display: block;
          transition: 0.5s;
        }
      }
  }
.select_tab_container {
  padding: 10px 0px 50px 0px;
  .input-radio {
    label {
      display: inline;
      font-weight: normal;
      text-align: center;
      margin-right: 25px;
      span {
        font-size: $font_big;
      }
      input[type="radio"] {
        display: inline-block;
        margin-top: 0px;
        position: relative;
        top: -2px;
      }
    }
  }
}
.text-right-pr-15{
  text-align: right;
  padding-right: 15px;
}
.tab-header {
  display: block;
  width: 100%;
  float: left;
  margin: 10px 0px 23px 0px;
  ul {
    list-style: none;
    padding-left: 0px;
    li {
      float: left;
      margin-right: 25px;
      font-size: $font_big;
      color: $color-grey-dark;
      display: block;
      >a {
        color: $color-grey-dark;
        font-family: $font-family-roboto-medium;
        font-weight: 500;
        font-size: $font_big;
        display: block;
        float: right;
        &::before {
          content: "";
          display: inline-block;
          width: 12px;
          height: 12px;
          border-radius: 50%;
          border: solid 1px #979797;
        }
        span {
          padding-left: 5px;
        }
      }
      &.active {
        >a {
          &::before {
            border: solid 4px $color-jungle-green;
          }
            color: $color-jungle-green;
        }
      }
    }
  }
}
.w_125 {
  width: 125px;
}
.item_email_user {
  display: inline-block;
  float: left;
  position: relative;
  .txt_email_user {
    display: block;
    max-width: 65px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .tooltip_email_user {
    position: absolute;
    top: 0px;
    left: 0px;
    line-height: 20px;
    padding: 5px 20px;
    left: 100%;
    background-color: $color_white;
    white-space: nowrap;
    width: auto;
    z-index: 10;
    font-size: $font_root;
    font-weight: 500;
    color: $color_mine_shaft;
    transition: 0.5s;
    display: none;
    box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.4);
    &:after {
      right: 100%;
      top: 50%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-color: rgba(136, 183, 213, 0);
      border-right-color: $color_white;
      border-width: 5px;
      margin-top: -5px;
    }
  }
  &:hover {
    .tooltip_email_user {
      display: block;
      transition: 0.5s;
    }
  }
}
.text-left{
  text-align : left;
}
.text-right{
  text-align : right;
}
.date-text {
  font-size: $font-small;
  color: $color-grey-dusty;
  font-family: $font-family-roboto;
}
.empty-data {
  text-align: center;
  padding-top: 15px !important;
  color: $color_grey;
  font-size: 14px;
  background-color: $color_white;
}
.opacity-row {
  opacity: 0.4;
}
.text-wrap {
  word-wrap: break-word;
}
.checkbox {
  margin-top: 0px;
  display: inline-block;
  font-size: 14px;
  margin-bottom: 0px;
  font-weight: normal;
  height: 30px;
  line-height: 30px;
  input[type="checkbox"] {
    display: none;
  }
  span {
    position: relative;
    content: "";
    display: inline-block;
    border: 1px solid $color_alto;
    width: 16px;
    height: 16px;
    top: 3px;
    left: 8px;
    border-radius: 2px;
    margin-right: 8px;
    position: relative;
    background-color: $color_white;
  }
  input:checked {
    & + span {
      &:after {
        opacity: 0.9;
        content: "";
        position: absolute;
        width: 14px;
        height: 7px;
        background: transparent;
        top: 2px;
        left: 0px;
        border: 3px solid #0090eb;
        border-top: none;
        border-right: none;
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
      }
    }
  }
}
.margin-order {
  .filter-container {
    margin: 10px 0;
    height: 60px;
    .filter-item {
      float: left;
      margin: 0 5px;
      input {
        margin-top: 18px;
        display: inline-block;
        width: 145px;
        &:hover, &:active, &:focus {
          border-color: $color-jungle-green;
        }
      }
      select {
        width: 65px;
        display: inline-block;
        font-family: $font-family-roboto;
        font-size: $font_root;
        &:hover, &:active, &:focus {
          border-color: $color-jungle-green;
        }
      }
      button {
        margin: 0 5px;
        width: auto;
        margin-top: 18px;
        height: 30px;
        font-size: $font-smaller;
        font-family: $font-family-roboto-bold;
        &:hover {
          border-color: $color-aquamarine;
          background-color: $color-aquamarine;
        }
      }
      .btn-reset {
        font-size: $font-smaller;
        border-radius: 3px;
        border: 1px solid $color-caribbean-green;
        width: 80px;
        color: $color_white;
        text-transform: uppercase;
        background-color: $color-caribbean-green;
        font-weight: 500;
      }
      .btn-primary {
        padding: 0;
        background-color: $color-caribbean-green;
        font-size: $font-smaller;
      }
    }
    .pair {
      input {
        width: 65px;
      }
    }
    .text-pair {
      padding-left: 10px;
    }
  }
  .form-control {
    background: $color-white-smoke;
    height: 30px;
    font-size: 13px;
    padding-left: 11px;
    padding-right: 0;
  }
  .datatable {
    button.btnForm.bt-action {
      padding-left: 5px !important;
      padding-right: 5px !important;
      font-weight: 400 !important;
    }
  }
  .buy {
    color: $text-color-jade;
  }
  .sell {
    color: $text-color-red;
  }
  .btn-detail {
    background-color: $color-caribbean-green;
    padding: 2px 10px;
    font-size: $font-smaller;
    color: $color-white;
    border:1px solid $color-caribbean-green;
    border-radius: 3px;
    text-transform: uppercase;
    font-family: $font-family-roboto;
    &:hover{
      background-color: $color-aquamarine;
      border-color: $color-aquamarine;
    };
  }
  .icon-notfound {
    font-size: 40px;
    padding-right: 15px;
  }
  .toolbar-element2 {
    display: inline-block;
    float: left;
    width: 60px;
  }
  .bw-element {
    float: left;
    font-size: 15px;
    margin-left: 5px;
    height: 30px;
    line-height: 30px;
    margin-right: 5px;
    color: $color-grey-dusty;
  }
  th.text-left.mgr-10::after {
    margin-left: -17px !important;
    left:-24px;
  }
  td.cl1{
    min-width: 115px;
  }
  td.cl2 {
    min-width: 105px;
  }
  td.cl3 {
    min-width: 110px;
  }
  td.cl4 {
    min-width: 85px;
  }
  td.cl5 {
    min-width: 98px;
  }
  td.cl6, td.cl7, td.cl8, td.cl9 {
    min-width: 115px;
  }
  td.cl10{
    min-width: 120px;
  }
  td.cl11{
    min-width: 100px;
  }
}
</style>
