<template>
  <div id="social-network" class="panel_content clearfix">
    <h3 class="name_page">{{$t('site_setting.social_network.title')}}</h3>
    <div class="clearfix">
      <table class="table table_site_setting">
        <thead>
          <tr>
            <th class="text-left">{{$t('site_setting.name_table')}}</th>
            <th class="text-left">{{$t('site_setting.icon_table')}}</th>
            <th class="text-left">{{$t('site_setting.type_table')}}</th>
            <th class="text-left">{{$t('site_setting.link_table')}}</th>
            <th class="text-left">{{$t('site_setting.status_table')}}</th>
            <th class="text-right"><span class="add_network" @click="addRow">{{$t('site_setting.add_row_table')}}</span></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(socialNetwork, index) in socialNetworks" :key="index">
            <td class="text-ellipsis">
              <input :maxlength="maxLength" v-if="isChange === index" class="edit_name_network" type="text" v-model="socialNetwork.name">
              <span v-else :title="socialNetwork.name">{{ socialNetwork.name }}</span>
            </td>
            <td class="icon-class">
              <template v-if="(isChange == index)">
                <select-box :options="iconClasses" v-model="socialNetwork.icon_class" customLabel :class="{ error2: errors.has('type_class') }" @focus="clearClass">
                  <template slot="label_selected" slot-scope="props">
                    <span :class="props.selected" class="pr-5"></span>
                    <span class="custom-font">
                      {{ props.selected }}
                    </span>
                  </template>
                  <template slot="option" slot-scope="props">
                    <span class="full_name_coin_select">
                      <i :class="props.option" class="pr-5"></i>
                      <span class="custom-font">{{ props.option }}</span>
                    </span>
                  </template>
                </select-box>
                <div v-show="errors.has('icon_class') && checkIncludeArray(index, 0) && isChange == index" class="invalid-feedback">{{ errors.first('icon_class') }}</div>
              </template>
              <template v-else>
                <span><i :class="socialNetwork.icon_class"></i>
                <span class="custom-font">{{ socialNetwork.icon_class }}</span></span>
              </template>
            </td>
            <td class="icon-type">
              <select-box :options="listNetworks" v-if="(isChange == index)" class="box_select_user custom-font" v-model="socialNetwork.type" :class="{ error2: errors.has('type_class') }" @focus="clearClass"/>
              <span v-else><span class="custom-font">{{ socialNetwork.type }}</span></span>
              <!-- <select class="list_network" :disabled="!(isChange == index)" v-model="socialNetwork.type">
                <option v-for="item in listNetworks" :value="item">{{ item }}</option>
              </select> -->
              <div v-show="errors.has('type_class') && checkIncludeArray(index, 0) && isChange == index" class="invalid-feedback">{{ errors.first('type_class') }}</div>
            </td>
            <td class="text-ellipsis">
              <input :maxlength="maxLength" v-if="isChange === index" class="edit_link_name" type="text" v-model="socialNetwork.link" :class="{ error: errors.has('link_class') }" @focus="clearClass"/>
              <span v-else :title="socialNetwork.link">{{ socialNetwork.link }}</span>
              <div v-show="errors.has('link_class') && checkIncludeArray(index, 0) && isChange == index" class="invalid-feedback">{{ errors.first('link_class') }}</div>
            </td>
            <td>
                <select-box :options="socialNetworkStatusList" v-model="socialNetwork.is_active" class="box_select_user" customLabel v-if="(isChange == index)">
                  <template slot="label_selected" slot-scope="props">
                    <span>{{ props.selected ? $t('site_setting.enable') : $t('site_setting.disable') }}</span>
                  </template>
                  <template slot="option" slot-scope="props">
                    <span class="full_name_coin_select">{{ props.option ? $t('site_setting.enable') : $t('site_setting.disable') }}</span>
                  </template>
                </select-box>

             <!--  <select class="list_network" :disabled="!(isChange == index)" v-model="socialNetwork.is_active">
                <option value="1">Enable</option>
                <option value="0">Disable</option>
              </select>    -->
              <span v-else>{{ socialNetwork.is_active ? $t('site_setting.enable') : $t('site_setting.disable') }}</span>
            </td>
            <td class="text-right">
              <span class="btn btn_close_network" @click="confirmRemoveSocialNetwork(socialNetwork)"><i class="icon-close2"></i></span>
              <span v-if="isChange == index" :class="index" class="btn btn_save_network" @click="updateSocialNetWork(socialNetwork , index, 0)"><i class="icon-save"></i></span>
              <span v-else class="btn btn_edit_network" @click="changeSocialnetwork(socialNetwork, index, 0)"><i class="icon-edit"></i></span>
              <span v-if="isChange == index" class="btn btn_close_network" @click="revertData(socialNetwork, index)"><i class="icon-reload3"></i></span>

            </td>
          </tr>
          <tr v-for="(addSocialNetwork, index) in addSocialNetworks" :key = "index">
            <td class="text-ellipsis">
              <input :maxlength="maxLength" class="edit_name_network" type="text" v-model="addSocialNetwork.name" >
            </td>
            <td class="icon-class" @click="clearNewClass">
              <select-box :options="iconClasses" v-model="addSocialNetwork.icon_class" customLabel :class="{ error2: errors.has('new_icon_class') }"  >
                <template slot="label_selected" slot-scope="props">
                    <span :class="props.selected" class="pr-5"></span>
                    <span class="custom-font">{{ props.selected }}</span>
                </template>
                <template slot="option" slot-scope="props">
                  <span class="full_name_coin_select">
                    <i :class="props.option" class="pr-5"></i>
                    <span class="custom-font">{{ props.option }}</span>
                  </span>
                </template>
              </select-box>
              <div v-show="errors.has('new_icon_class') && checkIncludeArray(index, 1)" class="invalid-feedback">{{ errors.first('new_icon_class') }}</div>
            </td>
            <td class="icon-type" @click="clearNewClass">
              <select-box :options="listNetworks" class="box_select_user custom-font" v-model="addSocialNetwork.type" :class="{ error2: errors.has('new_type_class') }" />
              <div v-show="errors.has('new_type_class') && checkIncludeArray(index, 1)" class="invalid-feedback">{{ errors.first('new_type_class') }}</div>
              <!-- <select class="list_network" ref="new_type" v-model="addSocialNetwork.type">
                <option v-for="item in listNetworks" :value="item">{{ item }}</option>
              </select> -->
            </td>
            <td class="text-ellipsis">
              <input :maxlength="maxLength" class="edit_link_name" type="text" v-model="addSocialNetwork.link" :class="{ error: errors.has('new_link_class') }" @focus="clearNewClass">
              <div v-show="errors.has('new_link_class') && checkIncludeArray(index, 1)" class="invalid-feedback">{{ errors.first('new_link_class') }}</div>
            </td>
            <td>
              <select-box :options="socialNetworkStatusList" v-model="addSocialNetwork.is_active" class="box_select_user" customLabel>
                <template slot="label_selected" slot-scope="props">
                  <span>{{ props.selected ? $t('Enable') : $t('Disable') }}</span>
                </template>
                <template slot="option" slot-scope="props">
                  <span class="full_name_coin_select">{{ props.option ? $t('Enable') : $t('Disable') }}</span>
                </template>
              </select-box>
              <!-- <select class="list_network" v-model="addSocialNetwork.is_active">
                <option value="1">Enable</option>
                <option value="0">Disable</option>
              </select> -->
            </td>
            <td class="text-right">
              <span class="btn btn_close_network" @click="removeAddSocialNetwork(index)"><i class="icon-close2"></i></span>
              <span class="btn btn_save_network" @click="addSocialNetWork(addSocialNetwork, index, 1)"><i class="icon-save"></i></span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
  import Const from '../../common/Const';
  import rf from '../../lib/RequestFactory';
  import SelectBox from '../../components/SelectBox';
  import CONST from '../../common/Const'

  export default {
    components: {
      SelectBox
    },
    data () {
      return {
        arrChange: [],
        isChanging: undefined,
        isChange: undefined,
        listNetworks: Const.LIST_NETWORKS,
        iconClasses: Const.ICON_CLASSES,
        socialNetworks: [],
        addSocialNetworks: [],
        socialNetworkStatusList: [0, 1],
        maxLength: CONST.MAX_LENGTH_INPUT,
      }
    },
    methods: {
      getSocialNetworks () {
        rf.getRequest('SettingRequest').getSocialNetworks().then((res) => {
          this.socialNetworks = res.data || [];
        });
      },
      revertData(){
        this.clearClass();
        this.getSocialNetworks();
      },
      checkIncludeArray(index, status){
        if(status === 0){
          if(this.isChange === index){
            return true;
          }else{
            return false;
          }
        }else{
          if(this.isChanging === index){
            return true;
          }else{
            return false;
          }
        }
        // return true;
      },
      changeSocialnetwork(socialNetwork, index, status){
        if(this.isChange !== index){
          this.getSocialNetworks();
          this.isChange = index;
        }
      },
      addRow () {
        this.addSocialNetworks.push({});
        this.clearNewClass();
      },
      removeAddSocialNetwork (index) {
        this.addSocialNetworks.splice(index, 1);
      },
      addSocialNetWork (newSocialNetwork, index, status) {
        if(this.isChanging !== index ){
          this.isChanging = index;
        }
        var newName = newSocialNetwork.name ? newSocialNetwork.name : '';
        const newLink = newSocialNetwork.link ? newSocialNetwork.link : '';
        newSocialNetwork.name = newName.trim();
        newSocialNetwork.link = newLink.trim();
        if (!this.validate(newSocialNetwork, index , status)){
          return;
        }
        rf.getRequest('SettingRequest').addSocialNetwork(newSocialNetwork).then((res) => {
          this.isChange = undefined;
          this.addSocialNetworks.splice(index, 1);
          this.isChanging = undefined;
          this.clearNewClass();
          this.getSocialNetworks();
          this.$toastr('success', window.i18n.t('site_setting.social_network.create_success'));
        });
      },
      updateSocialNetWork (socialNetwork, index, status) {
        const name = socialNetwork.name ? socialNetwork.name : '';
        const link = socialNetwork.link ? socialNetwork.link : '';
        socialNetwork.name = name.trim();
        socialNetwork.link = link.trim();
        if (!this.validate(socialNetwork ,index, status)){
          return;
        }
        rf.getRequest('SettingRequest').updateSocialNetWork(socialNetwork).then((res) => {
          this.isChange = undefined;
          this.clearClass();
          this.getSocialNetworks();
          this.$toastr('success', window.i18n.t('site_setting.social_network.update_success'));
        });
      },
      removeSocialNetwork (socialNetwork, index) {
        rf.getRequest('SettingRequest').removeSocialNetwork(socialNetwork.id).then((res) => {
          this.getSocialNetworks()
          this.$toastr('success', window.i18n.t('site_setting.social_network.remove_success'));
        });
      },
      confirmRemoveSocialNetwork (socialNetwork, index) {
        window.ConfirmationModal.show({
          type: 'primary',
          title: this.$t('site_setting.social_network.confirm_remove'),
          btnCancelLabel: window.i18n.t('common.action.no'),
          btnConfirmLabel: window.i18n.t('common.action.yes'),
          onConfirm: () => {
            this.removeSocialNetwork(socialNetwork, index);
          }
        });
      },
      clearAllClass(){
        this.clearClass();
        this.clearNewClass();
      },
      clearClass(){
        this.errors.remove('icon_class');
        this.errors.remove('type_class');
        this.errors.remove('link_class');
      },
      clearNewClass(){
        this.errors.remove('new_icon_class');
        this.errors.remove('new_type_class');
        this.errors.remove('new_link_class');
      },
      validate (socialNetwork, index , status) {
        // if (!socialNetwork.name) {
        //   this.$toastr('error', window.i18n.t('site_setting.social_network.errors.required.name'));
        //   return false;
        // }
        // this.errors.clear();
        let count = 0;
        if (!socialNetwork.icon_class) {
          // this.$toastr('error', window.i18n.t('site_setting.social_network.errors.required.icon_class'));
          if(status === 0){
            this.errors.add({field: 'icon_class', msg: this.$t('site_setting.social_network.errors.required.icon_class')});
          }
          if(status === 1){
            this.errors.add({field: 'new_icon_class', msg: this.$t('site_setting.social_network.errors.required.icon_class')});
          }
          // return false;
          count ++;
        }else{
          if(status === 0){
            this.errors.remove('icon_class');
          }
          if(status === 1){
            this.errors.remove('new_icon_class');
          }
        }
        if (!socialNetwork.type) {
          if(status === 0){
            this.errors.add({field: 'type_class', msg: this.$t('site_setting.social_network.errors.required.type')});
          }
          if(status === 1){
            this.errors.add({field: 'new_type_class', msg: this.$t('site_setting.social_network.errors.required.type')});
          }
          // this.$toastr('error', window.i18n.t('site_setting.social_network.errors.required.type'));
          // return false;
          count++
        }else{
          if(status === 0){
            this.errors.remove('type_class');
          }
          if(status === 1){
            this.errors.remove('new_type_class');
          }
        }
        // if (!socialNetwork.link) {
        //   if(status === 0){
        //     this.errors.add({field: 'link_class', msg: this.$t('site_setting.social_network.errors.required.link')});
        //   }
        //   if(status === 1){
        //     this.errors.add({field: 'new_link_class', msg: this.$t('site_setting.social_network.errors.required.link')});
        //   }
        //   // this.$toastr('error', window.i18n.t('site_setting.social_network.errors.required.link'));
        //   // return false;
        //   count++
        // }else{
        //   if(status === 0){
        //     this.errors.remove('link_class');
        //   }
        //   if(status === 1){
        //     this.errors.remove('new_link_class');
        //   }
        // }
        if(count !== 0){
          return false
        }else{
          return true;
        }
      }
    },
    mounted () {
      this.getSocialNetworks();
    }
  }
</script>
<style lang="scss" scoped>
@import "@/assets/sass/variables";
  .text-right{
    width: 130px !important;
  }
  .icon-class{
    width:  205px;
  }
  .error {
    border: 1px solid #e2221e !important;
  }
  .error2{
    border: 1px solid #e2221e !important;
    border-radius: 5px;
  }
  .name_page {
    margin: 0px 0px 14px 0px;
    line-height: 26px;
    font-size: $font_big;
    font-weight: 600;
    color: $color_mine_shaft;
  }
  .table_site_setting {
    background-color: $color_white;
    margin: 0px;

    tr {
      font-family: $font-family-roboto;
      th {
        font-family: $font-family-roboto-medium;
        font-size: $font-small;
        line-height: 22px;
        color: $color_grey;
        padding: 9px 15px;
        border-bottom: 1px solid $color_alto; 
      }
      td {
        line-height: 27px;
        font-size: $font_root;
        color: $color_mine_shaft;
        padding: 6px 15px;
        border-top: 1px solid $color_catskill_white;
        max-width: 250px;
        &.text-ellipsis {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
  }
  .type_network, .name_network{
    display: inline-block;
    float: left;
    line-height: 27px;
    margin-right: 5px; 
  }
  .link_network {
    display: inline-block;
    float: left;
    line-height: 27px;
    max-width: 185px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .add_network {
    border: 1px solid $color-caribbean-green;
    line-height: 20px;
    padding: 3px 12px;
    font-size: $font_root;
    border-radius: 3px;
    text-align: center;
    font-size: $font_mini_mini;
    font-family: $font-family-roboto-bold;
    text-transform: uppercase;
    background-color: $color-caribbean-green;
    color: $color-white;
    transition: 0.5s;
    min-width: 86px;
    cursor: pointer;
    &:focus,
    &:active,
    &:hover {
      background-color: $color-aquamarine;
      border-color: $color-aquamarine;
      color: $color_white;
      transition: 0.5s;
    }
  }
  .edit_name_network,.edit_link_name {
    border: 1px solid $color_alto;
    padding: 3px 13px;
    width: 100%;
    background-color: transparent; 
    border-radius: 3px;
  }
  .box_icon_network {
    display: block;
    width: 25px;
    height: 25px;
    line-height: 25px;
    text-align: center;
    background-color: $color_grey;
    border-radius: 3px;
    float: left;
    margin-right: 8px; 
    img {
      width: 100%;
      height: 100%;
    }
  }
  .btn_choose_file {
    background-color: transparent;
    float: left;
    height: 23px;
    line-height: 18px;
    padding: 2px 10px;
    border: 1px solid $color-caribbean-green;
    color: $color-jungle-green;
    font-size: $font-smaller;
    font-weight: 600;
    text-transform: uppercase;
    margin: 1px 0px;
    border-radius: 20px;
    transition: 0.5s;
    &:focus,
    &:active,
    &:hover {
      background-color: $color-jungle-green;
      border-color: $color-jungle-green;
      color: $color_white;
      transition: 0.5s;
    }
  }
  .btn_edit_network {
    // background-color: transparent !important;
    color: $color-jungle-green;
    .icon-edit:before {
      color: $color-jungle-green;
    }
    font-size: $font_semi_big;
    color: $color_dove_gray;
    height: 27px;
    padding: 2px 0px;
    line-height: 23px;
    float: right;
    margin-left: 15px;
    &:active {
      box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0);
    }
    &:hover {
      color: $color-aquamarine;
    }
  }
  .btn_save_network {
    // background-color: transparent !important;
    color: $color-jungle-green;
    .icon-save:before {
      color: $color-jungle-green;
    }
    font-size: $font_semi_big;
    color: $color_dove_gray;
    height: 27px;
    padding: 2px 0px;
    line-height: 23px;
    float: right;
    margin-left: 15px;
    // .icon-save:before {
    //   color: $color_dove_gray;
    // }
    &:active {
      box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0);
    }
    &:hover {
      color: $color-blue-custom;
      .icon-save:before {
        color: $color-blue-custom;
      }
    }
  }
  .btn_close_network {
    // background-color: transparent !important;
    color: $color-jungle-green;
    .icon-save:before {
      color: $color-jungle-green;
    }
    font-size: $font_semi_big;
    height: 27px;
    padding: 2px 0px;
    line-height: 23px;
    float: right;
    margin-left: 15px;
    // .icon-save:before {
    // color: $color_dove_gray;
    // }
    &:active {
      box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0);
    }
    &:hover {
      color: $color-blue-custom;
      .icon-save:before {
        color: $color-blue-custom;
      }
    }
  }
  .list_network {
    height: 27px;
    border: 1px solid $color_alto;
    line-height: 20px;
    padding: 3px 13px 3px 0;
    background-color: transparent;
  }
  .edit_link_network {
    background-color: transparent; 
    height: 27px;
    border: 1px solid $color_alto;
    padding: 3px 13px;
    line-height: 20px;
    width: 220px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  input[readonly] {
    border: none;
    padding: 0;
  }
  select[disabled] {
    -webkit-appearance: none;
    border: none;
  }
  .invalid-feedback{
    color: $color-red-main;
    font-family: $font-family-roboto !important;
  }
</style>

<style lang="scss">
@import "@/assets/sass/variables";
  #social-network {
    .button_drop_search{
      height: 35px !important;
      padding: 7px 6px !important;
    }
    .box_select_user {
      width: 150px;
      .list_search_select {
        min-width: 100%;
        width: 100%;
      }
    }
    .style_icon {

      .icon-facebook:after {
        display: block;
        content: "\E913";
        font-family: "icomoon" !important;
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: red;
      }
    }
  }
  .custom-font, .custom-font span, .box_select_user span{
    font-family: $font-family-roboto !important;
    font-size: $font_root !important;
  }
  
</style>
