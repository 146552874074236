<template>
  <div class="userInformation boxCore clearfix mg_bot50">
    <div class="filter_container clearfix">
      <span class="title_item">{{ $t('trading_fee_setting.trading_withdrawFeeSetting') }}</span>
      <!-- <span class="search_box">
        <input type="text" placeholder="Search" class="form-control search_input" name="searchKey"
          v-model="searchKey" v-on:keyup.enter="search"/>
      </span> -->
    </div>
    <div class="datatable">
      <data-table :getData="getData" ref="withdrawalFeeDatatable" :limit="10" :column="8" class="scroll"
        @DataTable:finish="onDatatableFinish">
        <th class="text-left w_11" data-sort-field="id">{{ $t('withdraw_fee_setting.id') }}</th>
        <th class="text-left w_11" data-sort-field="security_level">{{ $t('user.security_level') }}</th>
        <th class="text-left w_11" data-sort-field="currency">{{ $t('withdraw_fee_setting.currency') }}</th>
        <th class="text-left w_15">{{ $t('withdraw_fee_setting.limit') }}</th>
        <th class="text-left w_15">{{ $t('withdraw_fee_setting.daily_limit') }}</th>
        <th class="text-left w_15">
          {{ $t('withdraw_fee_setting.fee') }}
        </th>
        <th class="text-left w_18">{{ $t('withdraw_fee_setting.minium_withdrawal') }}</th>
        <th class="text-right"></th>
        <template slot="body" slot-scope="props">
          <tr>
            <td class="text-left">{{ props.item.id }}</td>
            <td class="text-left"><span>{{ props.item.security_level }}</span></td>
            <td class="text-left">{{ props.item.currency | uppercase }}</td>
            <td class="text-left">
              <input-only-number v-if="props.item.editable" name="limit" class="form-control"
                :class="{'only-interger': props.item.currency === 'usd', 'only-interger': decimalCoin[props.item.currency] == 0 }"
                maxlength="16"
                :precision="decimalCoin[props.item.currency]"
                v-model="props.item.limit" @dirty="onHandleDirtyData($event, props.item)"/>
              <span v-else>{{ props.item.limit | toNumber }}</span>
            </td>
            <td class="text-left">
              <input-only-number v-if="props.item.editable" name="daily_limit" class="form-control"
                :class="{'only-interger': props.item.currency === 'usd', 'only-interger': decimalCoin[props.item.currency] == 0 }"
                maxlength="16"
                :precision="decimalCoin[props.item.currency]"
                v-model="props.item.daily_limit" @dirty="onHandleDirtyData($event, props.item)"/>
              <span v-else>{{ props.item.daily_limit | toNumber }}</span>
            </td>
            <td class="text-left">
              <input-only-number v-if="props.item.editable" name="fee" class="form-control"
                maxlength="16"
                :precision="decimalCoin[props.item.currency]"
                :class="{'only-interger': props.item.currency === 'usd', 'only-interger': decimalCoin[props.item.currency] == 0 }"
                v-model="props.item.fee" @dirty="onHandleDirtyData($event, props.item)" />
              <span v-else>{{ props.item.fee | toNumber }}</span>
            </td>
            <td class="text-left">
              <input-only-number v-if="props.item.editable" name="minium_withdrawal" class="form-control"
                maxlength="16"
                :precision="decimalCoin[props.item.currency]"
                :class="{'only-interger': props.item.currency === 'usd', 'only-interger': decimalCoin[props.item.currency] == 0 }"
                v-model="props.item.minium_withdrawal" @dirty="onHandleDirtyData($event, props.item)"/>
              <span v-else>{{ props.item.minium_withdrawal | toNumber }}</span>
            </td>
            <td class="text-right">
              <button v-if="props.item.editable" class="btn btn_save_network" @click="change(props.item)">
                <i class="icon-save"></i>
              </button>
              <button v-else class="btn btn_edit_network" @click="props.item.editable = true">
                <i class="icon-edit"></i>
              </button>
            </td>
          </tr>
        </template>
      </data-table>
    </div>
  </div>
</template>

<script>
  import rf from '../../lib/RequestFactory';
  import InputOnlyNumber from './InputOnlyByNumberFee';
  import BigNumber from 'bignumber.js';

  export default {
    components: {
      InputOnlyNumber
    },
    data() {
      return {
        titlePage: this.$t('withdraw_fee_setting.withdraw_fee_setting'),
        searchKey: '',
        decimalCoin: {},
      }
    },
    methods: {
      search() {
        this.$refs.withdrawalFeeDatatable.$emit('DataTable:filter', {search_key: this.searchKey});
      },
      validateData(item) {
        const regex = /(?:(\.\d*?[1-9]+)|\.)0*$/gm;
        const subst = `$1`;
        return (item)
      },
      getData(params) {
        return rf.getRequest('CoinSettingRequest').getWithdrawLimit(params)
        .then(res=>{
          let dataRewrite = res.data;
          let newData = res.data.data;
          newData.forEach(el => {
            el.id,
            el.currency,
            el.security_level,
            el.limit = el.limit ? new  BigNumber(el.limit).toFixed(8).replace(/(?:(\.\d*?[1-9]+)|\.)0*$/gm, "$1")  : 0,
            el.fee = el.fee ? new BigNumber(el.fee).toFixed(8).replace(/(?:(\.\d*?[1-9]+)|\.)0*$/gm, "$1")  : 0,
            el.daily_limit = el.daily_limit ? new BigNumber(el.daily_limit).toFixed(8).replace(/(?:(\.\d*?[1-9]+)|\.)0*$/gm, "$1") : 0,
            el.minium_withdrawal = el.minium_withdrawal ? new BigNumber(el.minium_withdrawal).toFixed(8).replace(/(?:(\.\d*?[1-9]+)|\.)0*$/gm, "$1") : 0,
            el.days,
            el.created_at,
            el.updated_at
          });
          dataRewrite.data = newData;
          return {
            data: dataRewrite,
          };
        });
      },

      onDatatableFinish() {
        window._.each(this.$refs.withdrawalFeeDatatable.rows, item => {
          this.$set(item, 'editable', false);
          this.$set(item, 'isDirty', false);
        });
      },

      onHandleDirtyData($event, item) {
        item.isDirty = $event;
      },
      checkShowToast(item, key, value) {
        const toastName = {
          required : "trading_fee_setting.validate.required",
          overMessage : "trading_fee_setting.under_8_chdecial_part_under_8_characteraracter",
          // isValid: "trading_fee_setting.validate.isvalid"
        };
        if(!item[key] || !item[key].trim() ) {
          this.$toastr('error', this.$t(toastName.required,{0: value}));
          return false;
        }
        // if(item[key].substring(item[key].length - 1 , item[key].length) == '.'){
        //   item[key] = item[key] ? new BigNumber(item[key]).toFixed(8).replace(/(?:(\.\d*?[1-9]+)|\.)0*$/gm, "$1")  : 0;
        //   console.log(item[key]);
        // }
        let checkDecimal = item[key]  ? item[key].split(".") : "";
        if(checkDecimal[1] && checkDecimal[1].length > 8) {
          this.$toastr('error', this.$t('trading_fee_setting.decial_part_under_8_character',{0: value}));
          return false;
        }
        // if(checkDecimal[0] && checkDecimal[0].length > 8) {
        //   this.$toastr('error', this.$t('trading_fee_setting.decial_part_under_8_character',{0: value}));
        //   return false;
        // }

        return true;
      },
      checkDailyLimit(item) {
        let splitLimit = item.limit ? item.limit : 0;
        let splitDailyLimit = item.daily_limit ? item.daily_limit : 0;
        if (Number(splitLimit) > Number(splitDailyLimit)) {
          this.$toastr('error', this.$t('trading_fee_setting.dailyLimit_higher_Limit'));
          return false;
        }
        return true;
      },
      checkValidateNumber(item){
        let retval = null;
        switch(false){
          case this.checkShowToast(item, 'limit', 'limit') :
          break;
          case this.checkShowToast(item, 'daily_limit', 'daily limit') :
          break;
          case this.checkShowToast(item, 'fee', 'fee') :
          break;
          case this.checkShowToast(item, 'minium_withdrawal', 'minium withdrawal') :
          break;
          case this.checkDailyLimit(item) :
          break;
          default: retval = true;
        }
        if ( retval ) return  retval
      },
      handleInformationCoins() {
        rf.getRequest('AdminRequest').getInformationCoins()
        .then(res => {
          res.data.forEach(coin => {
            this.decimalCoin[coin.coin] = coin.decimal > 8 ? 8 : coin.decimal;
          });
        })
        .catch(ex => {
          console.log(ex);
        })
      },
      async change(item) {
        // No change data.
        if (!item.isDirty) {
          item.editable = !item.editable;
          item.limit = item.limit ? new BigNumber(item.limit).toFixed(8).replace(/(?:(\.\d*?[1-9]+)|\.)0*$/gm, "$1")  : 0;
          item.fee = item.fee ? new BigNumber(item.fee).toFixed(8).replace(/(?:(\.\d*?[1-9]+)|\.)0*$/gm, "$1")  : 0;
          item.daily_limit = item.daily_limit ? new BigNumber(item.daily_limit).toFixed(8).replace(/(?:(\.\d*?[1-9]+)|\.)0*$/gm, "$1") : 0;
          item.minium_withdrawal = item.minium_withdrawal ? new BigNumber(item.minium_withdrawal).toFixed(8).replace(/(?:(\.\d*?[1-9]+)|\.)0*$/gm, "$1") : 0;
          return;
        }
        let checkNumber = await this.checkValidateNumber(item);
        item.limit = item.limit ? new BigNumber(item.limit).toFixed(8).replace(/(?:(\.\d*?[1-9]+)|\.)0*$/gm, "$1") : 0;
        item.fee = item.fee ? new BigNumber(item.fee).toFixed(8).replace(/(?:(\.\d*?[1-9]+)|\.)0*$/gm, "$1")  : 0;
        item.daily_limit = item.daily_limit ? new BigNumber(item.daily_limit).toFixed(8).replace(/(?:(\.\d*?[1-9]+)|\.)0*$/gm, "$1") : 0;
        item.minium_withdrawal = item.minium_withdrawal ? new BigNumber(item.minium_withdrawal).toFixed(8).replace(/(?:(\.\d*?[1-9]+)|\.)0*$/gm, "$1") : 0;
        if(checkNumber) {
          ConfirmationModal.show({
            type            : 'confirm',
            title           : window.i18n.t('withdraw_setting.change_confirm'),
            content         : '',
            btnCancelLabel  : this.$t('common.action.no'),
            btnConfirmLabel : this.$t('common.action.yes'),
            size            : 'small',
            onConfirm       : () => {
              const params = {
                limit: item.limit,
                daily_limit: item.daily_limit,
                fee: item.fee,
                minium_withdrawal: item.minium_withdrawal
              };
              rf.getRequest('CoinSettingRequest').updateWithdrawLimit(item.id, params).then(res => {
                item.editable = !item.editable;
                this.$toastr('success', this.$t('trading_fee_setting.update_success_msg'));
              })
              .catch(e =>{
                this.$toastr('error', e.response.data.message);
              }) },
            onCancel: () => {}
          })
        }
      }
    },

    mounted() {
      this.$emit('EVENT_PAGE_CHANGE', this);
      this.handleInformationCoins();
    }
  }
</script>

<style lang="scss" scoped>
@import "@/assets/sass/variables";

  th, td {
    font-family: $font_family_root;
  }

  .w_11 {
    width: 11%;
  }
  .w_15 {
    width: 15%;
  }
  .w_18 {
    width: 18%;
  }

  .userInformation {

    .filter_container {
      margin: 20px 0px;
      .title_item {
        color: $color_mine_shaft;
        font-size: $font_big;
        font-family: $font-family-roboto-medium;
        line-height: 28px;
        float: left;
      }
      .search_box {
        display: inline-block;
        float: right;
        width: 215px;
        max-width: 100%;
        .search_input {
          background-color: transparent;
          height: 28px;
          border: 1px solid $color_alto;
          padding: 4px 15px;
          line-height: 20px;
          width: 100%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          font-size: $font-small;
        }
      }
    }
    .datatable {
      .form-control {
        background-color: transparent;
        height: 27px;
        border: 1px solid $color_alto;
        padding: 3px 13px;
        line-height: 20px;
        width: 140px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
    .btn_edit_network {
      background-color: transparent !important;
      font-size: $font_semi_big;
      color: $color-jungle-green;
      height: 27px;
      padding: 2px 0px;
      line-height: 23px;
      margin-left: 15px;
      &:active {
        box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0);
      }
      &:hover {
        color: $color-jungle-green;
      }
    }
    .btn_save_network {
      background-color: transparent !important;
      font-size: $font_semi_big;
      color: $color-jungle-green;
      height: 27px;
      padding: 2px 0px;
      line-height: 23px;
      margin-left: 15px;
      .icon-save:before {
        color: $color-jungle-green;
      }
      &:active {
        box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0);
      }
      &:hover {
        color: $color-jungle-green;
        .icon-save:before {
          color: $color-jungle-green;
        }
      }
    }
  }
</style>