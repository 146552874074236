<template>
  <div id="withdrawal_pages" class="clearfix mg_bot50">
    <base-deposit-withdraw-history type="withdraw" />
  </div>
</template>

<script>
  import BaseDepositWithdrawHistory from './BaseDepositWithdrawHistory';

  export default {
    components: {
      BaseDepositWithdrawHistory,
    },
    data() {
      return {
        titlePage: window.i18n.t("withdraw_setting.withdraw"),
      }
    },
    created() {
      this.$emit('EVENT_PAGE_CHANGE', this);
    }
  }
</script>

<style lang="scss" scoped>
@import "@/assets/sass/variables";
</style>
