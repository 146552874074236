import { render, staticRenderFns } from "./ManualDividendHistory.vue?vue&type=template&id=bcf9a922&scoped=true"
import script from "./ManualDividendHistory.vue?vue&type=script&lang=js"
export * from "./ManualDividendHistory.vue?vue&type=script&lang=js"
import style0 from "./ManualDividendHistory.vue?vue&type=style&index=0&id=bcf9a922&prod&lang=scss&scoped=true"
import style1 from "./ManualDividendHistory.vue?vue&type=style&index=1&id=bcf9a922&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bcf9a922",
  null
  
)

export default component.exports