import BaseRequest from '../lib/BaseRequest'


export default class RewardCenterRequest extends BaseRequest {

    getRewardCenter(params) {
        let url = '/admin/api/vouchers';
        return this.get(url, params);
    }

    deleteReward(rewardId) {
        let url = `/admin/api/vouchers/${rewardId}/delete`
        return this.post(url);
    }

    addNewReward(params) {
        let url = `/admin/api/vouchers`;
        return this.post(url, params);
    }

    updateReward(params, id ) {
        let url = `/admin/api/vouchers/${id}`;
        return this.put(url, params);
    }

    detailReward(id) {
        let url = `/admin/api/vouchers/${id}`;
        return this.get(url);

    }

}
