<template>
    <div>
        <div class="dropdownList-inputt toolbar-element22" @click="onClickWrap" v-click-outside="clickOut" v-if="!disabled">
            <input type="text" @click="showListOption()" @keyup="onSearchCoin()"
                   :placeholder="$t('instruments.dropdown.placeholder')" class="form-control dropdownList-inputt"
                   v-model="dataValue" readonly />
            <span class="icon-dropdown-input" @click="showListOption()"><i class="fa fa-angle-down"></i></span>
            <div class="dropdown-option" v-if="isShowListOption">
                <!--    <span class="option" @click="onSelectValue(null)">{{ $t('common.all') }}</span>-->
                <span class="option" v-for="(dataValue,index) in dataList" :key="index" @click="onSelectValue(dataValue)">{{ dataValue | uppercase }}</span>
            </div>
        </div>
        <div class="dropdownList-disabled" v-else>
            <input type="text" class="form-control dropdownList-disabled" disabled />
            <span class="icon-dropdown-input" ><i class="fa fa-angle-down"></i></span>
        </div>
    </div>
</template>
<script>
    import rf from '../lib/RequestFactory';

    export default {
        props: ['paramRequest','isDisabled'],
        data() {
            return {
                dataValue: '',
                dataList: [],
                dataMasters: [],
                isShowListOption: false,
                disabled: false
            }
        },
        methods: {
            onClickWrap() {
                this.$emit('onclick');
            },
            onSearchCoin() {
                // try {
                //   this.dataList = _.filter(this.dataMasters, (item) => {
                //     return _.isMatch(item.toUpperCase(), this.filters.currency.toUpperCase());
                //   });
                // }
                // catch(e) {
                //   console.log(e);
                // }
            },
            showListOption() {
                this.isShowListOption = !this.isShowListOption;
                this.dataList = this.dataMasters.map(x => x.toUpperCase());
            },
            requestDataList() {
                if (this.paramRequest) {
                    this.dataMasters = Object.values(this.paramRequest)[0];
                }
                this.disabled =  this.isDisabled === true ? true : false;
            },
            refresh() {
                this.dataValue = null;
                this.$emit('onSelectValue', this.dataValue);
            },
            onSelectValue(dataValue) {
                this.isShowListOption = false;
                this.dataValue = dataValue;
                let valueReturn = {value: this.dataValue, params: Object.keys(this.paramRequest)[0]}
                console.log(JSON.stringify(valueReturn));
                this.$emit('onSelectValue', valueReturn)
            },
            clickOut() {
                this.isShowListOption = false;
            },
        },
        watch: {
            paramRequest: function (newVal, oldVal) {
            },
            disabled: function (newVal, oldVal) {
            },
            isDisabled: function (newVal, oldVal) {
                if(newVal != oldVal) {
                    this.disabled =  newVal === true ? true : false;
                }
            },
        },
        created() {
            this.requestDataList();
        },
    }
</script>

<style lang="scss" scoped>
@import "@/assets/sass/variables";
    .dropdownList-disabled{
        background-color: $color-bright-gray;
        position: relative;
        display: inline-block;
        height: 35px;
        width: 100%;
        font-family: $font-family-roboto;
        font-size: $font-small;
        cursor: pointer;
        .icon-dropdown-input {
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
            cursor: pointer;

            i {
                color: $color-grey;
                font-size: 22px;
            }
        }
    }
    .dropdownList-inputt {
        background: transparent;
        position: relative;
        display: inline-block;
        height: 35px;
        width: 100%;
        font-family: $font-family-roboto;
        font-size: $font-small;
        cursor: pointer;

        &:hover, &:active {
            border-color: $color-jungle-green;
        }

        .icon-dropdown-input {
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
            cursor: pointer;

            i {
                font-size: 22px;
            }
        }

        .dropdown-option {
            background-color: $color-white;
            max-height: 135px;
            display: block;
            overflow-y: auto;
            margin-top: 5px;
            position: absolute;
            width: 100%;
            left: 5px;
            z-index: 10;
            padding: 12px 0px;
            box-shadow: 0px 3px 12px rgba(49, 49, 49, 0.51);

            &:after {
                bottom: 100%;
                left: 50%;
                border: solid transparent;
                content: " ";
                display: block;
                height: 0;
                width: 0;
                position: absolute;
                pointer-events: none;
                border-color: rgba(136, 183, 213, 0);
                border-bottom-color: $color-white;
                border-width: 5px;
                margin-left: -5px;
            }

            .option {
                display: block;
                width: 100%;
                line-height: normal;
                cursor: pointer;
                padding: 3px 14px;
                font-size: 13px;
                overflow: hidden;

                &:hover {
                    color: $color-white;
                    background: $color-jungle-green-light;
                }
            }
        }
    }
</style>
