<template>
  <div id="message" v-if="messages.length"  :class="configs.position">
    <div id="message_content" v-for="message in messages" :class="message.type">
      <span :class="setIcon(message.type)"></span>
      <div class="message-text">
        <strong>{{ message.type.charAt(0).toUpperCase() + message.type.slice(1) }}: </strong>
        <span v-if="typeof message.content === 'string'">{{ message.content | upperFirst }}</span>
      </div>

      <!-- <strong class="message-type">{{$t('shared_components.alert.'+message.type)}}:</strong> -->
      <!-- <span v-if="typeof message.content === 'string'">{{ message.content | upperFirst }}</span>
      <component v-bind:is="message.content" v-bind="message.params" v-if="(typeof message.content !== 'string')">
      </component> -->
    </div>
  </div>
</template>

<script>
  window.Message = {
    primary: function(content, params, configs){
      window.app.$broadcast('showMessage', 'primary', content, params, configs);
    },
    warning: function(content, params, configs){
      window.app.$broadcast('showMessage', 'warning', content, params, configs);
    },
    success: function(content, params, configs){
      window.app.$broadcast('showMessage', 'success', content, params, configs);
    },
    error: function(content, params, configs){
      window.app.$broadcast('showMessage', 'error', content, params, configs);
    }
  }
  export default {
    data(){
      return {
        messages: [],
        showTime: 5000,
        configs: {}
      }
    },
    methods: {
      setIcon(type) {
        if(type == 'success') {
          return 'icon-save';
        }
        else if (type == 'error') {
          return 'icon-close';
        }
      }
    },
    created () {
      var self = this;
      
      this.$on('showMessage', (type, content, params, configs) => {
        var newMessage = {
          type    : type,
          content : content,
          params  : params,
          timer   : null
        };

        if (!configs) {
          configs = {
            position: 'bottom_right'
          };
        }
        this.configs = Object.assign(this.configs, configs);
        
        newMessage.timer = setTimeout(
          function(){
            self.messages.splice(self.messages.indexOf(newMessage), 1);
          },
          self.showTime
        );

        this.messages.push(newMessage);
      });
    }
  };

</script>
<style lang="scss" scoped>
@import "@/assets/sass/variables";
#message {
  /*display      : none;*/
  position     : fixed;
  min-height   : 40px;
  min-width    : 350px;
  line-height: 9px;
  z-index      : 10000;
  padding      : 15px;
  &.center {
    margin-left: -143.5px;
    left: 50%;
    text-align: 50%;
    color: red;
    margin-top: -37.5px;
    top: 50%;
  }

  &.bottom_right {
    bottom: 10px;
    right: 20px;
  }

  &.bottom_left {
    bottom: 10px;
    right: 20px;
  }

  &.top_left {
    top: 30px;
    right: 0px;
  }

  #message_content {
    padding: 10px 20px 10px 20px;
    display: flex;
    &.success {
      background-color: $color-aquamarine;
      border: 1px solid $color-blue-custom;
      border-radius: 3px;
      .icon-save {
        padding-right: 13px;
        font-size: $font-big-20;
        font-weight: bold;
        &:before {
          color: #ffffff;
        }
      }
      .message-text {
        align-self: center;
        strong, span{
          color: $color-white;
        }
      }
    }
    &.error {
      background-color: $color-denim;
      border: 1px solid $color-denim;
      border-radius: 3px;
      .icon-close {
        padding-right: 13px;
        font-size: $font-big-20;
        font-weight: bold;
        &:before {
          color: #ffffff;
        }
      }
      .message-text {
        align-self: center;
        strong, span{
          color: $color-white;
        }
      }
    }
  }
}
</style>
