export default {
  "window.suffix_title": "Exchange",
  "about.about_us": "About Us",
  "about.advantage": "Our Advantages",
  "about.coin": "Multiple-Coin Support",
  "about.coin_reason": "BTC, ETH, LTC, ...",
  "about.device": "All Devices Covered",
  "about.device_reason": "Support and FAQs available in multiple languages",
  "about.envi": "Multiple-Language Support",
  "about.envi_reason": "Web, Android, iOS, Mobile Web",
  "about.liqui": "High Liquidity",
  "about.liqui_reason": "Abundant resources and partners",
  "about.perform": "High Performance",
  "about.perform_reason": "Capable of processing 1,400,000 orders per second",
  "about.product": "Proven Products",
  "about.product_reason":
    "The underlying :APP_NAME platform has been deployed on 30+ exchanges already. It supports all devices and multiple languages, offering a seamless user experience.",
  "about.resources": "Industry Resources",
  "about.resources_reason":
    "We have solid relationships with industry leaders, simply look at the list of investors and advisors who are willing to stand behind our platform.",
  "about.safe": "Safety Stability",
  "about.safe_reason": "Multi-tier & multi-cluster system architecture",
  "about.team_strong": "Strong Team",
  "about.team_strong_reason":
    "Our team have experience in both wall-street and crypto finance. Our team also have a track record of successful startups under our belt.",
  "about.tech": "Superior Technology",
  "about.tech_reason":
    "Our certified matching engine is capable of processing 1,400,000 orders per second, making :APP_NAME one of the fastest exchange in the market today.",
  "about.tit":
    "Launched in 2018 :APP_NAME is a  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, qttuis nostrud exercitation ullamco labor ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. ",
  "account.address_manager": "Address Management",
  "account.address_manager_content":
    "Address Management allows you to save and write memos for each of your withdrawal addresses. The optional Whitelist function helps protect your funds by only allowing withdrawals to whitelisted addresses.",
  "account.bank": ":0",
  "account.btn_general": "General",
  "account.btn_pending": "Waiting",
  "account.btn_rejected": "Has been rejected",
  "account.btn_unverified": "Unverified",
  "account.btn_uver_bank": "Unverified",
  "account.btn_verified": "Verified",
  "account.btn_verify_bank": "Verify",
  "account.button_show": "View more",
  "account.change": "Change",
  "account.daily_higher_limit": "Higher Limit",
  "account.daily_limit_withdraw": "24h Withdrawal Limit",
  "account.date": "Date",
  "account.device": "Device",
  "account.device_management": "Device Management",
  "account.devices_allowed":
    "These devices are currently allowed to access your account.",
  "account.disable": "Disable",
  "account.discount": "Using USD to pay for fees (25% discount)",
  "account.enable": "Enable",
  "account.error_bank": "* Used for withdrawal and security modifications",
  "account.google_auth": "Google Authentication",
  "account.lost_google_authenticator": "Lost Your Google Authentication?",
  "account.google_auth_content":
    "Used for withdrawals and security modifications",
  "account.identity.choose_file": "Choose File",
  "account.identity.country": "Country/territory",
  "account.identity.errors.character_limit":
    "This field must have number character between :min and :max",
  "account.identity.errors.exist": "identity card already exists",
  "account.identity.errors.format_number":
    "This field only contains character between 0 and 9, does not contain specical character.",
  "account.identity.errors.maxsize": "Size image must be less 2Mb",
  "account.identity.errors.required": "This field is required.",
  "account.identity.example": "Example",
  "account.identity.female": "Female",
  "account.identity.fullname": "Fullname",
  "account.identity.header_identity": "Personal",
  "account.identity.header_verification": "Verification Documents",
  "account.identity.identityID": "Valid Identity Card",
  "account.identity.identity_back":
    "Identity Card Back Side/Passport data page",
  "account.identity.identity_cover": "Identity Card Front Side/Passport cover",
  "account.identity.identity_front": "Identity Card Front Side",
  "account.identity.identity_image_back":
    "Please make sure that the photo is complete and clearly visible, in PNG or JPG format. Id card must be in the valid period.",
  "account.identity.identity_valid_card": "Valid Identity Card",
  "account.identity.image_identity":
    "If you do not have a passport, please upload a photo of the front of your Driver's License or National ID document.",
  "account.identity.image_passport":
    "Images must be submitted in PNG or JPG format.Please ensure that the subject of the photo is complete and clearly visible.If you do not have a passport, please upload a photo of the front of your Driver's License or National ID document.",
  "account.identity.image_passport_data":
    "Images must be submitted in PNG or JPG format.Please ensure that the subject of the photo is complete and clearly visible. Please make sure the photo is complete and clearly visible, in PNG or JPG format. Identity card must be in the valid period.",
  "account.identity.male": "Male",
  "account.identity.passport": "Passport/ID Number",
  "account.identity.selfie": "Selfie With Photo ID And Note",
  "account.identity.sex": "Gender",
  "account.identity.submit": "Submit",
  "account.identity.success": "Create success",
  "account.identity.update_success": "Update success",
  "account.identity.sure_back":
    "Please make sure that the photo is complete and clearly visible, in PNG or JPG format. Id card must be in the valid period.",
  "account.identity.sure_front":
    "If you do not have a passport, please upload a photo of the front of your Driver's License or National ID document.",
  "account.identity.text_condition":
    "The uploaded images should clearly show the face and text information.",
  "account.identity.text_date": "Note with today's date",
  "account.identity.text_face": "Face clearly visible",
  "account.identity.text_image": "Photo ID clearly visible",
  "account.identity.text_selfie":
    "Please provide a photo of you holding your Identity Card front side. In the same picture, make a reference to :APP_NAME and today's date displayed. Make sure your face is clearly visible and that all passport details are clearly readable.",
  "account.identity.text_write": 'Note with word ":APP_NAME"',
  "account.identity.verification_other":
    "This verification option applies to users with ID from all countries and territories outside mainland VietNam.You must use your real identity during this verification process. Your personal information will be securely protected.The following forms of government-issued ID can be used:",
  "account.identity.verification_other1": "   1. Passport",
  "account.identity.verification_other2": "   2. Driver's license",
  "account.identity.verification_other3": "   3. Government-issued ID Card",
  "account.invalid_device":
    "It appears that you may be signing in from a device we are unfamiliar with or one that you have not used for a long period of time. For your security, a confirmation email has been sent to your email address.",
  "account.ip": "IP",
  "account.ip_address": "IP Address",
  "account.last_login": "Last login",
  "account.last_login_time": "Last login Time",
  "account.location": "Location",
  "account.login": "Login Password",
  "account.lv": "Lv",
  "account.modal.agreement_otp.policy_content_text1": "1. Email Subject:",
  "account.modal.agreement_otp.policy_content_text2":
    "Request stop Google Authenticator",
  "account.modal.agreement_otp.policy_content_text3": "2. Email body",
  "account.modal.agreement_otp.policy_content_text4": "- Real name",
  "account.modal.agreement_otp.policy_content_text5":
    "- Your mobile phone number",
  "account.modal.agreement_otp.policy_content_text6":
    "- E-mail when you join :APP_NAME",
  "account.modal.agreement_otp.policy_content_text7": "3. Attachments",
  "account.modal.agreement_otp.policy_content_text8":
    "- Identity card front side",
  "account.modal.agreement_otp.policy_content_text9":
    "- Identity card back side",
  "account.modal.agreement_otp.policy_content_text10":
    "- Selfie with identity card and a note",
  "account.modal.agreement_otp.policy_content_text11":
    "Please make sure that the photo is complete and clearly visible.",
  "account.modal.agreement_otp.policy_content_text12":
    "Please provide a photo of you holding your Identity Card front side.",
  "account.modal.agreement_otp.policy_content_text13":
    "In the same picture, make a reference to bit-Castle and today’s date displayed.",
  "account.modal.agreement_otp.policy_content_text14":
    "Make sure your face is clearly visible and that all passport details are clearly readable.",
  "account.modal.agreement_otp.policy_content":
    "<span>If you also lost your recovery code and can not stop using Google Authenticator, Please send us your request email to help@mangoexchange</span>",
  "account.modal.agreement_otp.title": "Lost recovery code",
  "account.modal.cancel_2fa.guide":
    '<p class="font-18">To stop using 2 factor authentication (OTP), you have to enter the 6 digits verification number from the Google Authenticator app.</p><p class="font-18">If you lost your phone or uninstalled the app, you can alternatively enter the 16 character recovery code in the input below to disable it.</p>',
  "account.modal.cancel_2fa.input_blank":
    "You need to enter Google Authentication OTP code",
  "account.modal.cancel_2fa.otp": "OTP",
  "account.modal.cancel_2fa.otp_code": "Authorization number",
  "account.modal.cancel_2fa.otp_policy": "Lost your recovery code too?",
  "account.modal.cancel_2fa.otp_restore": "Recovery code",
  "account.modal.cancel_2fa.stop_use_otp": "Stop using OTP",
  "account.modal.cancel_2fa.success": "Deactivate OTP successfully.",
  "account.modal.cancel_2fa.title": "Disable Google Authentication",
  "account.modal.confirm": '<span class="font-18">Confirm</span>',
  "account.modal.confirm_trusted_device":
    "Do you want to delete the selected trusted device?",
  "account.modal.date_time_pick.text1": "Date Range:",
  "account.modal.date_time_pick.text2": "Last 24 hours",
  "account.modal.date_time_pick.text3": "Yesterday",
  "account.modal.date_time_pick.text4": "Last 2 weeks",
  "account.modal.date_time_pick.text5": "Month to date",
  "account.modal.date_time_pick.text6": "Past month",
  "account.modal.date_time_pick.text7": "Past 3 months",
  "account.modal.date_time_pick.text8": "Custom (max range 3 months)",
  "account.modal.date_time_pick.text9": "Export",
  "account.modal.date_time_pick.title": "Export Commission History",
  "account.modal.lost_otp.guide":
    "To stop using 2 factor authentication (OTP), you have to enter your recovery code.",
  "account.modal.lost_otp.otp": "OTP",
  "account.modal.lost_otp.otp_code": "Authorization number",
  "account.modal.lost_otp.otp_policy": "Lost your recovery code too?",
  "account.modal.lost_otp.otp_restore": "Recovery code",
  "account.modal.lost_otp.stop_use_otp": "Stop using OTP",
  "account.modal.lost_otp.title": "Stop using OTP",
  "account.ni_bank": "NI Bank Account",
  "account.no_commission_history": "You have not commission history.",
  "account.no_referral_history": "You have not referral history.",
  "account.otp.authentication_failed": "Authentication failed.",
  "account.otp.back": "Back Step",
  "account.otp.backup_text1":
    "Resetting your Google Authentication requires opening a support ticket and takes at least 7 days to process.",
  "account.otp.backup_text2":
    "Please save this Key on paper.This Key will allow you to recover your Google Authentication in case of phone loss",
  "account.otp.backup_title": "STEP 3. Save backup recovery code",
  "account.otp.enable_des": "Enable Google Authentication",
  "account.otp.enable_step1": "1. Download App",
  "account.otp.enable_step2": "2. Scan QR Code",
  "account.otp.enable_step3": "3. Backup Recovery Code",
  "account.otp.enable_step4": "4. Enable Google Authentication",
  "account.otp.enable_text1": "I have installed the app",
  "account.otp.enable_text2": "I have scanned the QR code",
  "account.otp.enable_text3": "I have already written down the 16-Digit Key",
  "account.otp.enable_title": "Step 4. Enable Google Authentication",
  "account.otp.install_text":
    "Download and install the Google Authentication app.",
  "account.otp.install_title": "Step 1. Download App",
  "account.otp.next": "Next step",
  "account.otp.qr_text":
    "If you are unable to scan the QR code, please enter this code manually into the app.",
  "account.otp.qt_title": "Step 2. Scan QR Code",
  "account.otp.success": "Activate OTP successfully.",
  "account.otp.txt_pass": "Login Password",
  "account.phone_number_in_use":
    "This phone number is already associated with a :APP_NAME account.",
  "account.recent_activity": "Recent Activity",
  "account.referral_code": "Your referral code:",
  "account.referral_notification":
    "Get up to 20% commission when referring friends to :APP_NAME. Share this link: ",
  "account.referral_program": "Referral Program",
  "account.submit": "Submit Verification Documents",
  "account.change_verify_kyc": "Change Verification Documents",
  "account.title_device":
    "These devices are currently allowed to access your account",
  "account.tooltip1":
    "When the function is turned on, your account will only be able to withdraw to whitelisted withdrawal addresses.",
  "account.tooltip2":
    "When the function is turned off, your account is able to withdraw to any withdrawal address.",
  "account.turn_off_mode_whitelist":
    "After turning off this function, you will be able to withdraw to any withdrawal address. Do you want to turn it off?",
  "account.turn_on_mode_whitelist":
    "After turning on this function, you will only be able to withdraw to whitelisted withdrawal address. Do you want to turn it on?",
  "account.white_list": "Whitelist",
  "account.white_list_off": "Whitelist Off",
  "account.white_list_on": "Whitelist On",
  "account.withdrawal_address": "Withdrawal Address Management",
  "address.add_address": "Withdrawal Address Management",
  "address.add_success": "Created successfully!",
  "address.add_whitelist": "Add to Whitelist",
  "address.address": "Address",
  "address.all": "All",
  "address.coin": "Coin",
  "address.coin_name": "Coin Name",
  "address.confirm_remove_address": "Do you want to delete it?",
  "address.date": "Date",
  "address.delete": "Delete",
  "address.deleted_success": "Successfully deleted!",
  "address.errors.address_required": "The address field is required",
  "address.errors.blockchain_address": "The withdrawal address is not valid",
  "address.errors.coin_required": "The coin field is required",
  "address.errors.exist_withdraw_address": "This address already exists",
  "address.errors.label_required": "The label field is required",
  "address.errors.max_length": "The :field must not exceed :amount characters",
  "address.errors.min_length":
    "The :field must not less than :amount characters",
  "address.errors.tag_required": "The destination tag field is required",
  "address.label": "Label",
  "address.label_hint": "The label must not exceed 20 characters",
  "address.memo": "Memo/Tag/...",
  "address.none": "None",
  "address.not_whitelisted": "Not Whitelisted",
  "address.only_dis": "Only display whitelisted addresses",
  "address.remove_whitelist": "Remove from Whitelist",
  "address.select_coin": "Select coin",
  "address.submit": "Submit",
  "address.tag": "Tag",
  "address.tooltip1":
    "When the function is turned on, your account will only be able to withdraw to whitelisted withdrawal addresses.",
  "address.tooltip2":
    "When the function is turned off, your account is able to withdraw to any withdrawal address.",
  "address.turn_off_mode_whitelist":
    "After turning off this function, you will be able to withdraw to any withdrawal address. Do you want to turn it off?",
  "address.turn_on_mode_whitelist":
    "After turning on this function, you will only be able to withdraw to whitelisted withdrawal address. Do you want to turn it on?",
  "address.turn_on_whitelist":
    "Please turn on withdrawal whilelist function first.",
  "address.update_white_list":
    "Please turn on withdrawal whitelist function first.",
  "address.updated_success": "Updated successfully!",
  "address.white_list_off": "Whitelist Off",
  "address.white_list_on": "Whitelist On",
  "address.whitelisted": "Whitelisted",
  "address.withdrawal_address": "Withdrawal Address Management",
  "admin.deposit_approved": "Deposit success :amountUSD\n:time\n\n:APP_NAME",
  "admin.deposit_rejected":
    "Deposit failed :amountUSD\n:time\nInformation mismatch\n:APP_NAME",
  "admin.new_deposit": "New deposit :amountUSD\n:time\n\n:APP_NAME",
  "admin.new_withdrawal": "New withdrawal :amountUSD\n:time\n\n:APP_NAME",
  "admin.withdraw_approved":
    "Withdrawal success :amountUSD :bankName bank :bankAccount\n:time\n\n:APP_NAME",
  "admin.withdraw_status_pending": "Pending",
  "admin.withdraw_status_success": "Success",
  "auth.confirmation_email_subject":
    "【:APP_NAME】Activate your account on :APP_NAME",
  "auth.email_confirm_success": "Your email has been confirmed.",
  "auth.email_confirmation_code_invalid": "Activation code is invalid.",
  "auth.failed": "These credentials do not match our records.",
  "auth.forgot_password": "Forgot password?",
  "auth.otp_code_invalid": "Google Authentication code is invalid.",
  "auth.otp_code_required": "The Google Authentication code field is required.",
  "auth.otp_not_used": "Please wait for next verification code to generate.",
  "auth.send_mail_failed": "Failed to send email, please try again.",
  "auth.throttle":
    "Too many login attempts. Please try again in :seconds seconds.",
  "change_password_form.confirm":
    "For security purposes, no withdrawals are permitted for 24 hours after modification of security methods.",
  "change_password_form.confirm_new_password": "Repeat new password",
  "change_password_form.error_confirm":
    "The new password confirmation does not match.",
  "change_password_form.error_duplicated_password":
    "The new password must differ from your previous password",
  "change_password_form.error_message": "Your password is not changed!!!",
  "change_password_form.new_password": "New password",
  "change_password_form.old_password": "Old password",
  "change_password_form.require_confirm": "Confirm new password is required",
  "change_password_form.require_new": "New password is required",
  "change_password_form.require_old": "Old password is required",
  "change_password_form.submit_button": "Submit",
  "change_password_form.submit_button_processing": "Processing...",
  "change_password_form.successful_message":
    "Your password has been changed successfully!",
  "change_password_form.title": "Change Password",
  "client_download.desktop": "Desktop",
  "client_download.destop_content":
    "Our desktop clients are tailor-made to provide a smooth and efficient trading experience.",
  "client_download.more_way": "More ways to get :APP_NAME",
  "client_download.provide_tool":
    "We're provided some tools you need for trading on the go",
  "client_download.sp_download": "Smartphone apps",
  "client_download.track": "Track and manage trades wherever you are!",
  "common.refresh": "Refresh",
  "common.action.cancel": "Cancel",
  "common.action.no": "No",
  "common.action.ok": "OK",
  "common.action.yes": "Yes",
  "common.confirm": "Confirm",
  "common.datatable.data_empty": "Empty Data.",
  "common.datatable.empty_data": "Data empty.",
  "common.error.required": "The :field field is required",
  "common.max": "Max",
  "common.no_select": "None",
  "common.order_status.canceled": "Canceled",
  "common.order_status.filled": "Filled",
  "common.order_status.partial_filled": "Partial Filled",
  "common.placeholders.email": "Email",
  "common.status.expired": "Expired",
  "common.placeholders.login_password": "Login Password",
  "common.placeholders.otp": "Google Authentication Code",
  "common.placeholders.password": "Password",
  "common.placeholders.referral": "Referral ID (optional)",
  "common.placeholders.repeat_password": "Repeat Password",
  "common.processing": "Processing...",
  "common.trade_type.buy": "Buy",
  "common.trade_type.sell": "Sell",
  "common.transaction_status.cancel": "Canceled",
  "common.transaction_status.error": "Error",
  "common.transaction_status.pending": "Pending",
  "common.transaction_status.rejected": "Rejected",
  "common.transaction_status.success": "Success",
  "common.transaction_status.submitted": "Submitted",
  "common.transaction_type.internal": "Internal",
  "common.transaction_type.external": "External",
  "common.window_title": ":APP_NAME",
  "common_action.cancel": "Cancel",
  "common_action.confirm": "Confirm",
  "common_action.ok": "Ok",
  "common.date.warning":
    "The date range is invalid. The end date is smaller than the start date!",
  "contact.contact_us": "Contact Us",
  "contact.got_question": "Got a Question?",
  "contact.non_account_related":
    "For non-account-related communications, join our online communities:",
  "contact.please": "Still can’t find what you’re looking for? Please",
  "contact.please_check":
    "Please first check the official :APP_NAME FAQ to see if your question has already been answered",
  "contact.submit_request": "submit a request",
  "contact.view_suport": "view support documentation",
  "currency.ada.fullname": "Cardano",
  "currency.ada.shortname": "ADA",
  "currency.mgc.fullname": "Mango Coin",
  "currency.mgc.shortname": "MGC",
  "currency.bch.fullname": "Bitcoin Cash",
  "currency.bch.shortname": "BCH",
  "currency.btc.fullname": "Bitcoin",
  "currency.btc.shortname": "BTC",
  "currency.eos.fullname": "EOS",
  "currency.eos.shortname": "EOS",
  "currency.eth.fullname": "Ethereum",
  "currency.eth.shortname": "ETH",
  "currency.tomo.fullname": "TomoChain",
  "currency.tomo.shortname": "TOMO",
  "currency.tusd.fullname": "TrueUSD",
  "currency.tusd.shortname": "TUSD",
  "currency.usd.fullname": "United States Dollar",
  "currency.usd.shortname": "USD",
  "currency.xlm.fullname": "Stellar",
  "currency.xlm.shortname": "XLM",
  "currency.xrp.fullname": "Ripple",
  "currency.xrp.shortname": "XRP",
  "emails.account": "Account",
  "emails.account_info":
    "bank: :bank, account number: :accountNumber, account holder: :name",
  "emails.amount": "amount:",
  "emails.cannot_click_link":
    "If you can not click on the link, kindly copy and paste the address into the address bar. Thank you",
  "emails.confirm_email":
    "Click on the link below to activate your account. By verifying your email, we strives to provide customers with more convenient and safer cryptocurrency exchange service.",
  "emails.confirm_kyc": "Your Kyc was",
  "emails.date": "Time: ",
  "emails.deposit_alerts.text1": "We received the loading order",
  "emails.deposit_alerts.text2": "from you.",
  "emails.deposit_alerts.text3":
    "This amount has been added to your balance at :APP_NAME.",
  "emails.deposit_alerts.title": "【:APP_NAME】Refill request has been made",
  "emails.deposit_approved_text1":
    ":email, your deposit request has been approved.",
  "emails.deposit_approved_title": "【:APP_NAME】 Deposit Completed",
  "emails.deposit_rejected_text1": ":email, your deposit request has failed.",
  "emails.deposit_rejected_text2":
    "Please check the deposit rules, your account information, deposit code and apply again.",
  "emails.deposit_rejected_title": "【:APP_NAME】 Deposit Failed",
  "emails.deposit_usd_alerts.account_name": "Account name",
  "emails.deposit_usd_alerts.account_no": "Account no",
  "emails.deposit_usd_alerts.amount": "Amount",
  "emails.deposit_usd_alerts.bank_branch": "Bank branch",
  "emails.deposit_usd_alerts.bank_name": "Bank name",
  "emails.deposit_usd_alerts.code": "Code",
  "emails.deposit_usd_alerts.text1":
    "The system has just received a request to check the user's recharge transaction",
  "emails.deposit_usd_alerts.text2": "Transaction information",
  "emails.deposit_usd_alerts.title": "New recharge request",
  "emails.received_liquid_position.subject":
    "【GS Exchange】Liquidation Notice: Your {symbol} position has been liquidated",
  "emails.liquid_position_line_1":
    "Your short {symbol} position of {current_qty} contracts has been liquidated.",
  "emails.liquid_position_line_2":
    "The Mark Price of {symbol} recently rose to {mark_price}. Your position had a liquidation price of {liquidation_price}. The position has been taken over by the Liquidation Engine.",
  "emails.liquid_position_line_3": "{symbol} Position Details",
  "emails.liquid_position_line_4":
    "You were short {current_qty}  contracts of XBTUSD at {leverage} leverage.",
  "emails.liquid_position_line_5":
    "With {maint_margin}% Maintenance Margin, your liquidation price was {liquidation_price} and your bankruptcy price was {bankrupt_price} (i.e. 0% margin).",
  "emails.liquid_position_line_6":
    "At the time of liquidation {date}, the price of the .{symbol} Index rose to {index_price}. The contract you were trading, {symbol}, had a Mark Price of {mark_price}, which is based on this index.",
  "emails.liquid_position.side": "Side",
  "emails.liquid_position.qty": "Qty",
  "emails.liquid_position.lev": "Lev.",
  "emails.liquid_position.mark_price": "Mark Price",
  "emails.liquid_position.liq_price": "Liq. Price",
  "emails.hello": "Hello,",
  "emails.kyc_rejected": "rejected",
  "emails.kyc_tilte": "【:APP_NAME】Confirm Kyc on",
  "emails.kyc_verified": "verified",
  "emails.new_deposit_amount": "Amount: ",
  "emails.new_deposit_text1": "We have received your deposit request.",
  "emails.new_deposit_title": "New Deposit Request",
  "emails.new_device.text1":
    "You recently attempted to sign into your :APP_NAME account from a new device. As a security measure, we require additional confirmation before allowing access to your :APP_NAME account",
  "emails.new_device.text2":
    "If this was legitimate activity, you can authorize your new device below",
  "emails.new_login.text1":
    "The system has detected that your account is logged in from an unused IP address",
  "emails.new_login.text2":
    "If this activity is not your own operation, please disable that device and contact us immediately",
  "emails.new_withdrawal_text1": "We have received your withdrawal request.",
  "emails.new_withdrawal_title": "New Withdrawal Request",
  "emails.no_reply": "This is an automated message. Please do not reply.",
  "emails.on": "on",
  "emails.say_hello": "Hello :email?",
  "emails.team": "Team :APP_NAME",
  "emails.team_address": "(CO) :APP_NAME - street xxx",
  "emails.team_inc": "© :APP_NAME Inc. All rights reserved.",
  "emails.team_inform": "will inform you",
  "emails.team_service_center":
    'Service center / email. <a href="mailto:cs@mango.exchange" target="_blank">cs@mango.exchange</a> / tel. +84 915673038',
  "emails.team_slogan": "The best cryptocurrency trading",
  "emails.thankyou": "Thanks,",
  "emails.warning":
    "If this activity is not your own operation, please contact us immediately.",
  "emails.warning_title": ":APP_NAME Security Warning",
  "emails.welcome": "Welcome to :APP_NAME",
  "emails.withdraw_alerts.text1": "Request withdrawal",
  "emails.withdraw_alerts.text2":
    "from the :APP_NAME account has been successfully implemented.",
  "emails.withdraw_alerts.text3": "You can track its confirmation status here",
  "emails.withdraw_alerts.title":
    "【:APP_NAME】Request for withdrawal successfully!",
  "emails.withdraw_errors_alerts.text1": "Your transaction request withdrawal ",
  "emails.withdraw_errors_alerts.text2":
    " has just failed. Do not worry, your case is being processed. Please wait for feedback from us.",
  "emails.withdraw_errors_alerts.title": "【:APP_NAME】Withdrawal error",
  "emails.withdraw_usd_alerts.account_name": "Account name",
  "emails.withdraw_usd_alerts.account_no": "ccount no",
  "emails.withdraw_usd_alerts.amount": "Amount",
  "emails.withdraw_usd_alerts.bank_branch": "Bank branch",
  "emails.withdraw_usd_alerts.bank_name": "Bank name",
  "emails.withdraw_usd_alerts.text1":
    "The system has just received a request for a user's withdrawal",
  "emails.withdraw_usd_alerts.text2": "Transaction information",
  "emails.withdraw_usd_alerts.title": "Request a new withdrawal",
  "emails.withdrawal_approved_text1":
    ":email, your withdrawal request has been processed.",
  "emails.withdrawal_approved_title": "【:APP_NAME】 Withdrawal Completed",
  "exchange.basic.chart.btn_add_chart": "Add Chart",
  "exchange.basic.chart.buy_order": "Buy orders",
  "exchange.basic.chart.candle": "Candlesticks",
  "exchange.basic.chart.depth": "Depth",
  "exchange.basic.chart.msg_close_chart": "Do you want to close this chart ?",
  "exchange.basic.chart.price": "Price",
  "exchange.basic.chart.sell_order": "Sell orders",
  "exchange.basic.label_hour": "Hour",
  "exchange.basic.label_minute": "Minute",
  "exchange.basic.time.hour": ":number hour",
  "exchange.basic.time.hours": ":number hours",
  "exchange.basic.time.minute": ":number minute",
  "exchange.basic.time.minutes": ":number minutes",
  "exchange.basic.time.one_day": "1 day",
  "exchange.basic.time.one_month": "1 month",
  "exchange.basic.time.one_week": "1 week",
  "exchange.basic.trading_page.change_24h": "24h Change",
  "exchange.basic.trading_page.confirm": "Confirm",
  "exchange.basic.trading_page.evaluation_amount": "Evaluation",
  "exchange.basic.trading_page.high": "High",
  "exchange.basic.trading_page.high_24h": "24h High",
  "exchange.basic.trading_page.holding_balance": "Balance",
  "exchange.basic.trading_page.last_price": "Last Price",
  "exchange.basic.trading_page.low": "Low",
  "exchange.basic.trading_page.low_24h": "24h Low",
  "exchange.basic.trading_page.total_purchase_amount": "Purchased",
  "exchange.basic.trading_page.volume": "Volume",
  "exchange.basic.trading_page.volume_24h": "24h Volume",
  "exchange.basic.trading_page.yeild": "Yeild",
  "exchange.basic.trading_page.yeild_tooltip.row1":
    "1. Based on USD converted value.",
  "exchange.basic.trading_page.yeild_tooltip.row2":
    "2. BTC, ETH markets are also converted to USD value.",
  "exchange.basic.trading_page.yeild_tooltip.row3":
    "3. In the case of deposits and withdrawals, deposits are assumed to be 'buy' and withdrawals are assumed to be 'sells' based on the current USD at the time of deposit and withdrawal.",
  "exchange.basic.trading_page.yeild_tooltip.row4":
    "4. Yeild = (evaluation amount - total purchase amount) ÷ total purchase amount × 100",
  "exchange.basic.trading_page.yeild_tooltip.row5":
    "5. Assets sold are not included in the calculation. Only assets that we currently hold are eligible.",
  "exchange.basic.trading_page.yeild_tooltip.row6":
    "6. This return rate does not represent the absolute yeild of the investment. Please use it as reference for investment only.",
  "favourite.market_price.change": "Change",
  "favourite.market_price.coin": "Name",
  "favourite.market_price.current_price": "Last Price",
  "favourite.market_price.favorites": "Favorites",
  "favourite.market_price.percent_day_before": "± %",
  "favourite.market_price.transaction_amount": "Volume",
  "favourite.market_price.volume": "Vol",
  "fee_guide.actual_purcharse": "Actual purchase amount",
  "fee_guide.actual_sell": "Actual Sell",
  "fee_guide.commission_rate": "1. Commission rate by transaction volume",
  "fee_guide.commission_tip1":
    ":APP_NAME applies a different fee according to the transaction volume as above. Fee ratings are subject to change. ",
  "fee_guide.commission_tip2":
    "Volume ranking is done once a day at 00:00 every day.",
  "fee_guide.commission_tip3": "Maker / Taker fee is the same.",
  "fee_guide.example": "Example:",
  "fee_guide.fee": "Fee",
  "fee_guide.fomula_actual_pucharse":
    "= Filled amount (1 BTC) - 1 BTC x transaction fee rate (0.2%) = 0.998 BTC",
  "fee_guide.for_example": "For example:",
  "fee_guide.formula_actual_sell": "= Filled amount (1 BTC)",
  "fee_guide.formula_fee":
    "= Filled amount (1 BTC) x Transaction Fee Rate (0.2%) = 0.002 BTC",
  "fee_guide.formula_sell_fee":
    "= Filled amount (1 BTC) x Price (5,000,000 USD) x Transaction Fee Rate (0.2%) = 10,000 USD",
  "fee_guide.formula_subtotal":
    "= Quantity (1 BTC) x Price (5,000,000 USD) = 5,000,000 USD",
  "fee_guide.formula_transaction_value":
    "= Quantity (1 BTC) x Price (USD 5,000,000) - Fees (USD 5,000) = 4,990,000 USD",
  "fee_guide.last_30_days": "Last 30 days",
  "fee_guide.less_than": "Less than",
  "fee_guide.level_name": "Level:",
  "fee_guide.level_title": "Your current level is",
  "fee_guide.rating": "Level",
  "fee_guide.subtotal": "Total USD (Transaction Value)",
  "fee_guide.text13": "Maker / Taker fee is the same.",
  "fee_guide.text14":
    "In case of a buy order, the quantity obtained by subtracting the quantity corresponding to the commission rate from the filled quantity will be the actual quantity.",
  "fee_guide.text15":
    "In case of a sell order, the amount of the sale price minus the commission rate is the actual selling price.",
  "fee_guide.text16":
    "If the order is filled, the commission rate is 0.2%, the price is USD 5,000,000 and the quantity is 1 BTC",
  "fee_guide.text20":
    "If a sell order is filled, the commission rate is 0.2%, the price is USD 5,000,000 and the quantity is 1 BTC",
  "fee_guide.text24":
    "You can check the actual fee, discount, etc. on order screen.",
  "fee_guide.title": "Fee Information",
  "fee_guide.transaction_fee": "1. Transaction fee calculation method ",
  "fee_guide.transaction_value": "Payment USD (Transaction Value)",
  "funds.action.deposit": "deposit",
  "funds.action.placeholder_input_coin": "Please input keyword of the coin",
  "funds.action.withdraw": "withdraw",
  "funds.balances.avaliable_balance": "Available Balance",
  "funds.balances.balance": "Balances",
  "funds.balances.no_data": "You have no Funds.",
  "funds.balances.btc_value": "BTC Value",
  "funds.balances.coin": "Coin",
  "funds.balances.deposit": "Deposit",
  "funds.balances.estimated_value": "Estimated Value",
  "funds.balances.hide": "Hide",
  "funds.balances.hide_small_assets": "Hide small assets",
  "funds.balances.in_order": "In Order",
  "funds.balances.in_use": "In Use",
  "funds.balances.infomation": "Information",
  "funds.balances.name": "Name",
  "funds.balances.show_all": "Show All",
  "funds.balances.small_balance": "Small Balances",
  "funds.balances.small_balance_notice":
    "Balances valued less than :amount BTC",
  "funds.balances.total_balance": "Total Balance",
  "funds.balances.withdrawal": "Withdrawal",
  "funds.balances.suspend": "Suspend",
  "funds.balances.trade": "Trade",
  "funds.balances.withdrawal_limit_24": "24 Withdrawal Limit",
  "funds.deposit.available_balance": "Available Balance",
  "funds.deposit.choice": "Choice",
  "funds.deposit.coppy_address": "Copy Address",
  "funds.deposit.copy_error": "Copy error",
  "funds.deposit.copy_success": "Copied",
  "funds.deposit.copy_tag": "Copy Tag",
  "funds.deposit.deposit_address": "Deposit Address",
  "funds.deposit.deposit_tag": "Deposit Tag",
  "funds.deposit.history": "History",
  "funds.deposit.important.text2":
    "<span class='icon-favorites-2'></span> Send only :name to this deposit address. Sending any other currency to this address may result in the loss of your deposit",
  "funds.deposit.in_order": "In Order",
  "funds.deposit.not_arrive": "Deposit Did Not Arrive",
  "funds.deposit.no_data": "You have no deposit history.",
  "funds.deposit.show_qr": "Show QR Code",
  "funds.deposit.tips.text1": "Please note",
  "funds.deposit.tips.text2":
    ":name will be deposited immediately after :count network confirmations.",
  "funds.deposit.tips.text3":
    "After making a deposit, you can track its progress on the <a class='link-primary' href='/funds/history?type=deposit' target='_blank'>history</a> page",
  "funds.deposit.title_page": "Deposit",
  "funds.deposit.tooltip":
    "If your deposit does not arrive for a long time, it may be caused by congested blockchain or incomplete transaction.  <a class='link-primary' href=\"#\">More Reference</a>",
  "funds.deposit.total_balance": "Total Balance",
  "funds.deposit.view_all": "View All",
  "funds.deposit.what": "What’s :name?",
  "funds.deposit_usd.cancel_deposit_usd": "Cancel",
  "funds.deposit_usd.deposit_amount": "Deposit amount",
  "funds.deposit_usd.deposit_note":
    "Please make a transfer of money according to the information below. We will verify and transfer this amount to your account. Thank you!",
  "funds.deposit_usd.errors.min_amount":
    "The minimum withdrawal amount must be greater than :min_amount",
  "funds.deposit_usd.errors.required": "The :field field is required",
  "funds.deposit_usd.guide.text1":
    "1. Please send the same amount as the deposit amount.",
  "funds.deposit_usd.guide.text10":
    "4. Please contact our customer support in case of late payment.",
  "funds.deposit_usd.guide.text11":
    "- If the required deposit amount and the transfered amount are different, or you forget to enter the deposit code, please submit a request in our support center.",
  "funds.deposit_usd.guide.text12":
    "※ The withdrawal will be limited during the check-in time of the banking system.",
  "funds.deposit_usd.guide.text2":
    "- If the deposit amount is different from the actual deposit amount, the deposit will not be processed.",
  "funds.deposit_usd.guide.text3": "2. Be sure to enter 'Deposit code'.",
  "funds.deposit_usd.guide.text4":
    "- When depositing money, please enter the deposit code as the transfer content. Please make sure you only use the deposit code as transfer content and nothing else. The deposit process can be delayed when you do not enter the deposit code.",
  "funds.deposit_usd.guide.text7":
    "3. Deposits will be processed within a maximum of 10 minutes.",
  "funds.deposit_usd.guide.text8":
    "- Under normal circumstances, the average time takes about 3 to 5 minutes to process the deposit.",
  "funds.deposit_usd.guide.text9":
    "We will notify you once the deposit has been processed.",
  "funds.deposit_usd.notes": "Notes",
  "funds.deposit_usd.other_deposit": "Make another transaction",
  "funds.deposit_usd.pending_transaction.account_name": "Account name",
  "funds.deposit_usd.pending_transaction.account_no": "Account no",
  "funds.deposit_usd.pending_transaction.amount": "Amount",
  "funds.deposit_usd.pending_transaction.bank_branch": "Bank branch",
  "funds.deposit_usd.pending_transaction.bank_name": "Bank name",
  "funds.deposit_usd.pending_transaction.code": "Code",
  "funds.deposit_usd.submit_deposit_usd": "Submit",
  "funds.deposit_usd.success_message": "The transaction is processing ...",
  "funds.deposit_usd.transaction_history_table.account": "Account",
  "funds.deposit_usd.transaction_history_table.amount": "Amount",
  "funds.deposit_usd.transaction_history_table.bank": "Bank",
  "funds.deposit_usd.transaction_history_table.code": "Code",
  "funds.deposit_usd.transaction_history_table.status": "Status",
  "funds.deposit_usd.transaction_history_table.time": "Time",
  "funds.history.address": "Address",
  "funds.history.amount": "Amount",
  "funds.history.coin": "Coin",
  "funds.history.date": "Date",
  "funds.history.deposit": "Deposit",
  "funds.history.export_complete_deposit_history":
    "Export Complete Deposit History",
  "funds.history.export_complete_withdraw_history":
    "Export Complete Withdrawal History",
  "funds.history.from": "From",
  "funds.history.history": "History",
  "funds.history.infomation": "Information",
  "funds.history.status": "Status",
  "funds.history.tag": "Tag",
  "funds.history.to": "To",
  "funds.history.transaction_history": "Transaction History",
  "funds.history.txid": "Txid",
  "funds.history.withdrawal": "Withdrawal",
  "funds.select_coin": "Select coin/token to :action",
  "funds.withdraw_usd.account_info": "Account Information",
  "funds.withdraw_usd.account_name": "Account name",
  "funds.withdraw_usd.account_no": "Account no",
  "funds.withdraw_usd.amount": "Amount",
  "funds.withdraw_usd.available": "Available",
  "funds.withdraw_usd.bank_branch": "Bank branch",
  "funds.withdraw_usd.bank_info": "Bank Information",
  "funds.withdraw_usd.bank_name": "Bank name",
  "funds.withdrawals.add_new_address":
    "Please input your new withdrawal address below",
  "funds.withdrawals.amount": "Amount",
  "funds.withdrawals.id": "ID",
  "funds.withdrawals.type": "Type",
  "funds.withdrawals.sender": "Sender",
  "funds.withdrawals.amount": "Amount",
  "funds.withdrawals.receiver": "Receiver",
  "funds.withdrawals.hash": "Hash",
  "funds.withdrawals.status": "Status",
  "funds.withdrawals.created_time": "Created time",
  "funds.withdrawals.action": "Action",
  "funds.withdrawals.available_balance": "Available Balance",
  "funds.withdrawals.choice": "Choice",
  "funds.withdrawals.no_data": "You have no withdrawals history.",
  "funds.withdrawals.confirm_otp": "Confirm OTP",
  "funds.withdrawals.confirm_withdraw": "Confirm withdraw",
  "funds.withdrawals.did_not_receive_the_mail": "Did Not Receive The Mail",
  "funds.withdrawals.empty_whitelist.address_management": "Address Management",
  "funds.withdrawals.empty_whitelist.text": "You have no whitelist address.",
  "funds.withdrawals.error_message.address_not_exist_in_whitelist":
    "This address is not in the whitelist addresses",
  "funds.withdrawals.error_message.amount_withdraw_is_positive":
    "The withdrawal amount must be a negative number",
  "funds.withdrawals.error_message.minimum_withdraw":
    "You have a smaller withdrawal than allowed",
  "funds.withdrawals.error_message.not_enough_balance":
    "The money in the account is not enough to make the transaction",
  "funds.withdrawals.error_message.over_daily_limit":
    "You have withdrawn the limit of the day",
  "funds.withdrawals.error_message.over_limit": "You have withdrawn the limit",
  "funds.withdrawals.error_message.over_one_time_limit":
    "You have overdrawn once",
  "funds.withdrawals.errors.account_no_length": "The account no is not valid",
  "funds.withdrawals.errors.blockchain_address":
    "The withdrawal address is not valid",
  "funds.withdrawals.errors.exist_withdraw_address":
    "This address already exists",
  "funds.withdrawals.errors.max_balance": "Your account balance is not enough",
  "funds.withdrawals.errors.max_once_amount":
    "The amount you withdraw is more than once allowed",
  "funds.withdrawals.errors.min_amount":
    "The minimum withdrawal amount is :min_amount",
  "funds.withdrawals.errors.not_allowed_withdraw":
    "Your security level doesn't be allowed to withdraw",
  "funds.withdrawals.errors.otp_length_digits":
    "The authentication code must be 6 digits",
  "funds.withdrawals.errors.otp_required":
    "The authentication code field is required",
  "funds.withdrawals.errors.required": "The :field field is required",
  "funds.withdrawals.history": "History",
  "funds.withdrawals.important.text1": "Important",
  "funds.withdrawals.important.text2": "Minimum Withdrawal",
  "funds.withdrawals.important.text3":
    "Do not withdraw directly to a crowfund or ICO. We will not credit your account with tokens from that sale.",
  "funds.withdrawals.in_order": "In Order",
  "funds.withdrawals.input_address_error": "Invalid address",
  "funds.withdrawals.loading": "Processing...",
  "funds.withdrawals.submit": "Submit",
  "funds.withdrawals.success_message": "Successful transaction",
  "funds.withdrawals.tips.text1": "Please note",
  "funds.withdrawals.tips.text2":
    "Once you have submitted your withdrawal request, we will send a confirmation email. Please then click on the confirmation link in your email.",
  "funds.withdrawals.tips.text3":
    "After making a withdrawal, you can track its progress on the <a style='color: #265ac3;font-weight: bold;' href='/funds/history?type=withdraw' target='_blank'>history</a> page.",
  "funds.withdrawals.title_page": "Withdrawals",
  "funds.withdrawals.tooltip":
    'If your can not find the email, please check your spam or other folders <a href=":url" target="_blank">Still Not Receive.</a>',
  "funds.withdrawals.total_balance": "Total Balance",
  "funds.withdrawals.transaction_fee": "Transaction Fee",
  "funds.withdrawals.use_a_new_address": "Use a new address",
  "funds.withdrawals.view_all": "View All",
  "funds.withdrawals.what": "What’s :name?",
  "funds.withdrawals.withdrawal_address": "Withdrawal Address",
  "funds.withdrawals.withdrawal_limit_24": "24h withdrawal limit",
  "funds.withdrawals.withdrawal_placeholder_address": "Address",
  "funds.withdrawals.withdrawal_placeholder_available": "Available",
  "funds.withdrawals.withdrawal_placeholder_label": "Label",
  "funds.withdrawals.xrp_tag": "Withdrawal Tag",
  "funds.withdrawals.you_will_get": "You Will Get",
  "funds.disable_coin_msg": "Contract Frozen, Waiting for Upgrade",
  "landing.change": "24h Change",
  "landing.chart.change": "Change",
  "landing.chart.last_price": "Last Price",
  "landing.clients_download": "Download",
  "landing.clients_text1": "More ways to get :APP_NAME",
  "landing.clients_text2":
    "We've provided some tools you need for trading on the go",
  "landing.coin": "Coin",
  "landing.coin_markets": ":coin Markets",
  "landing.favorites": "Favorites",
  "landing.high": "24h High",
  "landing.last_price": "Last Price",
  "landing.low": "24h Low",
  "landing.pair": "Pair",
  "landing.price": "Price",
  "landing.register": "Register now",
  "landing.gotrade": "Go to Trade",
  "landing.slogan": "Exchange the World",
  "landing.volume": "24h Volume",
  "landing.current_rate": "Current <br/> Rate",
  "login.active_device_failed":
    "Your authorization link is invalid or expired!",
  "login.active_device_success":
    "You have successfully authorized a new device. Please try to log in with it again!<p></p><p><strong>Device: </strong> :platform :operating_system</p><p><strong>IP address: </strong> :latest_ip_address</p>",
  "login.confirm_failed": "Your confirmation link is invalid or expired!",
  "login.confirm_success": "Your email has been confirmed!",
  "login.dont_has_account": "Need an Account",
  "login.forgot": "Forgot your password",
  "login.sub_title": "Please check you are visiting",
  "login.title": "Login",
  "login.title_otp": "Google Authentication",
  "m_account.btn_continue": "I understand, Continue",
  "m_account.change_pass": "Change Password",
  "m_account.gg_auth": "Google Authenticator",
  "m_account.go_pc":
    "Please go to :APP_NAME PC website to finish Google Authenticator setting.",
  "m_account.id": "ID",
  "m_account.identity_auth": "Identity Authentication",
  "m_account.language": "Language",
  "m_account.logout": "Logout",
  "m_account.lv": "Lv",
  "m_account.referral.commission": "Commission",
  "m_account.referral.commission_rate": "Commission Rate:",
  "m_account.referral.count": "NO.",
  "m_account.referral.detail": "Program Details",
  "m_account.referral.detail_notice":
    ":APP_NAME reserves the right to change the terms of the referral program at any time due to changing market conditions, risk of fraud, or any other factors we deem relevant.",
  "m_account.referral.estimate": "Estimated Commission Value",
  "m_account.referral.get_link": "1. Get Your Link",
  "m_account.referral.info_get_link":
    "Register and generate referral links and QR codes",
  "m_account.referral.info_invite":
    "Invite your friends to register through the referral link or QR codes and get rewards once they complete a trade",
  "m_account.referral.info_reward": "Receive up to 40% commission in real-time",
  "m_account.referral.invite": "2. Invite Friends",
  "m_account.referral.line1": "Effective as of 2018/05/19 0:00 AM (UTC)",
  "m_account.referral.line2":
    "The commission you receive from the referral program will initially be set at a rate of 20%. For accounts holding Amount of :COIN_HOLDING or more, this rate will increase to 40%.",
  "m_account.referral.line3":
    "The fee commission will be sent instantly in real-time to your :APP_NAME account as your referee completes each trade and will be paid to you in whatever token/cryptocurrency the original fee was paid in.",
  "m_account.referral.line4":
    "There is no limit to the number of friends you can refer, although we do reserve the right to adjust or change the referral program rules at any time.",
  "m_account.referral.line5":
    "Each referee must be signed up through your Referral Link, QR Code or Referral ID.",
  "m_account.referral.line6":
    ":APP_NAME will check for duplicate or fake accounts and will not pay out referral bonuses on these accounts. Duplicate or shared finances will result in disqualification.",
  "m_account.referral.my_referral": "My Referrals",
  "m_account.referral.notice": "* Important Notice:",
  "m_account.referral.referral_friend": "Referral Friends",
  "m_account.referral.referral_id": "My Referral ID:",
  "m_account.referral.referral_link": "Referral Link:",
  "m_account.referral.reward": "3. Get Rewards",
  "m_account.referral.text29": "Invite friends Scan QR Code to get crypto",
  "m_account.referral.title": "Referral Program",
  "m_account.title":
    "Please do not disclose Google Authentication codes to anyone,including :APP_NAME customer support.",
  "m_account.unverified": "Unverified",
  "m_account.verified": "Verified",
  "m_common.action.cancel": "Cancel",
  "m_common.action.confirm": "Confirm",
  "m_common.action.more": "More",
  "m_common.action.off": "OFF",
  "m_common.action.on": "ON",
  "m_common.time.hour": ":number hour",
  "m_common.time.hours": ":number hours",
  "m_common.time.label_minute": "Minute",
  "m_common.time.minute": ":number minute",
  "m_common.time.minutes": ":number minutes",
  "m_exchange.chart.amount": "Amount",
  "m_exchange.chart.buy": "Buy",
  "m_exchange.chart.candle": "Candle",
  "m_exchange.chart.charts": "Charts",
  "m_exchange.chart.depth": "Depth",
  "m_exchange.chart.high": "High",
  "m_exchange.chart.low": "Low",
  "m_exchange.chart.market_trade_history": "Market Trade History",
  "m_exchange.chart.price": "Price",
  "m_exchange.chart.sell": "Sell",
  "m_exchange.chart.time": "Time",
  "m_exchange.chart.trade_detail": "Trade Detail",
  "m_exchange.chart.trade_history": "Trade History",
  "m_exchange.chart.volume": "Vol",
  "m_exchange.open_order.open_order": "Open Order",
  "m_exchange.trade.available": "available",
  "m_exchange.trade.buy": "Buy",
  "m_exchange.trade.sell": "Sell",
  "m_exchange.trade.trade": "Trade",
  "m_funds.coin_info.available": "Available",
  "m_funds.coin_info.deposit": "Deposit",
  "m_funds.coin_info.estimate": "Estimated",
  "m_funds.coin_info.go_to_trade": "Go To Trade",
  "m_funds.coin_info.in_order": "In Order",
  "m_funds.coin_info.total": "Total",
  "m_funds.coin_info.withdraw": "Withdraw",
  "m_funds.current": "Current",
  "m_funds.deposit.save_qr_code": "Save QR Code",
  "m_funds.deposit.select_coin": "Select Coin",
  "m_funds.deposit_usd.available_balance": "Available Balance",
  "m_funds.deposit_usd.deposit_amount": "Deposit Amount",
  "m_funds.deposit_usd.deposit_usd_tab": "Deposit USD",
  "m_funds.deposit_usd.errors.required": "The {field} field is required",
  "m_funds.deposit_usd.guide.text1":
    "1. Please send the same amount as the deposit amount.",
  "m_funds.deposit_usd.guide.text10":
    "4. Please contact our customer support in case of late payment.",
  "m_funds.deposit_usd.guide.text11":
    "- If the required deposit amount and the transfered amount are different, or you forget to enter the deposit code, please submit a request in our support center.",
  "m_funds.deposit_usd.guide.text12":
    "※ The withdrawal will be limited during the check-in time of the banking system.",
  "m_funds.deposit_usd.guide.text2":
    "- If the deposit amount is different from the actual deposit amount, the deposit will not be processed.",
  "m_funds.deposit_usd.guide.text3": "2. Be sure to enter 'Deposit code'.",
  "m_funds.deposit_usd.guide.text4":
    "- When depositing money, please enter the deposit code as the transfer content. Please make sure you only use the deposit code as transfer content and nothing else. The deposit process can be delayed when you do not enter the deposit code.",
  "m_funds.deposit_usd.guide.text7":
    "3. Deposits will be processed within a maximum of 10 minutes.",
  "m_funds.deposit_usd.guide.text8":
    "- Under normal circumstances, the average time takes about 3 to 5 minutes to process the deposit.",
  "m_funds.deposit_usd.guide.text9":
    "We will notify you once the deposit has been processed.",
  "m_funds.deposit_usd.history_tab": "History",
  "m_funds.deposit_usd.in_order": "In Order",
  "m_funds.deposit_usd.notes": "Please Note",
  "m_funds.deposit_usd.submit_deposit_usd": "Submit",
  "m_funds.deposit_usd.total_balance": "Total Balance",
  "m_funds.deposit_usd.transaction_history_table.cancel": "Cancel",
  "m_funds.deposit_usd.transaction_history_table.no": "No",
  "m_funds.deposit_usd.transaction_history_table.text1":
    "Do you want to cancel ?",
  "m_funds.deposit_usd.transaction_history_table.yes": "Yes",
  "m_funds.transaction_history.deposit_history": "Deposit History",
  "m_funds.transaction_history.no_deposit_history":
    "You have not Deposit History",
  "m_funds.transaction_history.no_withdraw_history":
    "You have not Withdrawal History",
  "m_funds.transaction_history.withdraw_history": "Withdrawal History",
  "m_funds.withdraw_usd.history_tab": "History",
  "m_funds.withdraw_usd.limit_and_notice": "Limit & Notice",
  "m_funds.withdraw_usd.withdraw_usd_tab": "Withdrawal USD",
  "m_funds.withdrawals.but_withdraw": "Withdraw",
  "m_funds.withdrawals.but_withdrawal": "Withdrawal",
  "m_funds.withdrawals.errors.required": "The field is required",
  "m_funds.withdrawals.fee": "Fee",
  "m_funds.withdrawals.limit_and_notice": "Limit & Notice",
  "m_funds.withdrawals.limit_and_notice_modal.in_use": "In Use",
  "m_funds.withdrawals.limit_and_notice_modal.title": "Limit & Notice",
  "m_funds.withdrawals.limit_and_notice_modal.withdrawal_limit_24":
    "24h Withdrawal Limit",
  "m_funds.withdrawals.notes": "<span>  Minimum withdrawal: :min :coin</span>",
  "m_funds.withdrawals.notes2":
    "  Do not withdrawal directly to a crowdfund or ICO. We will not credit your account with tokens from that sale.",
  "m_funds.withdrawals.notes3":
    "  Once you have submitted your withdrawal request, we will send a confirmation email. Please then click on the confirmation link in your email.",
  "m_funds.withdrawals.select_coin": "Select coin",
  "m_funds.withdrawals.withdrawal_amount": "Withdrawal Amount",
  "m_landing.change_24h": "24h Change%",
  "m_landing.deposit": "Deposit",
  "m_landing.gainers": "Gainers",
  "m_landing.losers": "Losers",
  "m_landing.more": "More",
  "m_landing.support": "Support",
  "m_landing.top24btc": "BTC 24h Volume Top",
  "m_landing.withdraw": "Withdrawal",
  "m_menu.deposits": "Deposits",
  "m_menu.home": "Home",
  "m_menu.language": "Language",
  "m_menu.markets": "Markets",
  "m_order_history.avg": "Avg",
  "m_order_history.conditions": "Conditions",
  "m_order_history.more": "More",
  "m_order_history.trade_detail": "Trade Detail",
  "m_order_history.trading_price": "Trading price",
  "m_pair_navigation.change_24h": "24h Change%",
  "m_pair_navigation.last_price": "Last Price",
  "m_pair_navigation.name": "Name",
  "m_pair_navigation.vol": "Vol",
  "menu.about": "About",
  "menu.address": "Address Management",
  "menu.api": "API",
  "menu.apply_to_list": "Apply to List",
  "menu.balances": "Balances",
  "menu.change_password": "Change Password",
  "menu.contact": "Contact",
  "menu.deposit_usd": "Deposit USD",
  "menu.deposits": "Deposits",
  "menu.estimated_value": "Estimated Value",
  "menu.exchange": "Exchange",
  "menu.fees": "Exchange fees",
  "menu.funds": "Funds",
  "menu.history": "History",
  "menu.history_title": "History",
  "menu.info": "Info",
  "menu.join_us": "Join Us",
  "cms.notification.type": "Type",
  "cms.notification.type_all": "All",
  "cms.notification.type_system_message": "System Message",
  "cms.notification.type_event": "Event",
  "cms.notification.type_campaign": "Campaign",
  "cms.notification.type_new_listing": "New Listing",
  "cms.notification.type_copy_trade": "Copy Trade",
  "cms.notification.type_announcement": "Announcement",
  "cms.notification.search_by": "Search by title, message",
  "cms.notification.created_time": "Created time",
  "cms.notification.id": "ID",
  "cms.notification.language": "Language",
  "cms.notification.title": "Title",
  "cms.notification.message": "Message",
  "cms.notification.status": "Status",
  "cms.notification.action": "Action",
  "cms.notification.image": "Image",
  "cms.notification.popup_content_close":
    "Are you sure you want to close this notification?",
  "cms.notification.popup_content_open":
    "Are you sure you want to open this notification?",
  "cms.notification.toast_close_success": "Close Successful",
  "cms.notification.toast_close_failed": "Close Failed",
  "cms.notification.toast_open_success": "Open Successful",
  "cms.notification.toast_open_failed": "Open Failed",
  "cms.notification.toast_update_failed": "Update Failed",
  "cms.notification.toast_update_successful": "Update Successful",
  "cms.notification.toast_create_failed": "Create Failed",
  "cms.notification.toast_create_successful": "Create Successful",
  "cms.notification.href_url": "Href URL",
  "cms.notification.title_create": "Notification: Create",
  "cms.notification.title_edit": "Notification: Edit",
  "cms.notification.popup_content_edit":
    "Are you sure you want to update this notification?",
  "cms.notification.select_type": "Select type...",
  "cms.notification.create_type": "Create Type",
  "cms.notification.add_new_type": "Add New Type",
  "cms.notification.enter_type": "Enter Type",
  "cms.notification.new": "New",
  "cms.mail_template.created_time": "Created time",
  "cms.mail_template.id": "ID",
  "cms.mail_template.language": "Language",
  "cms.mail_template.name": "Name",
  "cms.mail_template.subject": "Subject",
  "cms.mail_template.type": "Type",
  "cms.mail_template.action": "Action",
  "cms.mail_template.default": "Default",
  "cms.mail_template.select_language": "Select language...",
  "cms.mail_template.select_type": "Select type...",
  "cms.mail_template.title_edit": "Mail Template: Edit",
  "cms.mail_template.title_create": "Mail Template: New",
  "cms.mail_template.create_type": "Create Type",
  "cms.mail_template.toast_update_failed": "Update Failed",
  "cms.mail_template.toast_update_successful": "Update Successful",
  "cms.mail_template.toast_create_failed": "Create Failed",
  "cms.mail_template.toast_create_successful": "Create Successful",
  "cms.announcement.search_by": "Search by title, category",
  "cms.announcement.created_time": "Created time",
  "cms.announcement.id": "ID",
  "cms.announcement.language": "Language",
  "cms.announcement.title": "Title",
  "cms.announcement.status": "Status",
  "cms.announcement.category": "Category",
  "cms.announcement.action": "Action",
  "cms.announcement.popular": "Popular",
  "cms.announcement.select_language": "Select language...",
  "cms.announcement.select_category": "Select category...",
  "cms.announcement.create_category": "Create category",
  "cms.announcement.title_edit": "Announcement: Edit",
  "cms.announcement.title_create": "Announcement: New",
  "cms.announcement.toast_update_failed": "Update Failed",
  "cms.announcement.toast_update_successful": "Update Successful",
  "cms.announcement.toast_create_failed": "Create Failed",
  "cms.announcement.toast_create_successful": "Create Successful",
  "cms.announcement.toast_delete_success": "Delete Successful",
  "cms.announcement.toast_delete_failed": "Delete Failed",
  "cms.announcement.search_by": "Search by title, category",
  "cms.announcement.type_maintenance": "Maintenance",
  "cms.announcement.type_coin_listings": "Coin Listings",
  "cms.announcement.type_latest_news": "Latest News",
  "cms.announcement.add_new_category": "Add New Category",
  "cms.announcement.enter_category": "Enter Category",
  "cms.announcement.active_status": "Active Status",
  "cms.faq.search_by": "Search by question",
  "cms.faq.created_time": "Created time",
  "cms.faq.id": "ID",
  "cms.faq.language": "Language",
  "cms.faq.question": "Question",
  "cms.faq.status": "Status",
  "cms.faq.category": "Category",
  "cms.faq.action": "Action",
  'cms.faq.status_1': 'Pending',
  'cms.faq.status_2': 'Replied',
  "cms.faq.select_language": "Select language...",
  "cms.faq.select_category": "Select category...",
  "cms.faq.active_status": "Active Status",
  "cms.faq.toast_delete_success": "Delete Successful",
  "cms.faq.toast_delete_failed": "Delete Failed",
  "cms.faq.toast_update_failed": "Update Failed",
  "cms.faq.toast_update_successful": "Update Successful",
  "cms.faq.toast_create_failed": "Create Failed",
  "cms.faq.toast_create_successful": "Create Successful",
  "cms.faq.popup_delete_confirm":
    "Are you sure you want to delete this question?",
  "cms.faq.user_id": "User ID",
  "cms.faq.title_edit": "Inquiry: Edit",
  "cms.faq.title_create": "Inquiry: New",
  'cms.qna.category_1': 'Account Functions',
  'cms.qna.category_2': 'Crypto Deposit/Withdrawal',
  'cms.qna.category_3': 'Spot Trading',
  "cms.qna.title_edit": "Inquiry: Reply",
  "cms.qna.title_create": "Inquiry: New",
  "cms.qna.question_author": "Question Author",
  "cms.qna.question_created": "Question Date Created",
  "cms.announcement.search_by": "Search by title, category",
  "cms.announcement.type_maintenance": "Maintenance",
  "cms.announcement.type_coin_listings": "Coin Listings",
  "cms.announcement.type_latest_news": "Latest News",
  "cms.announcement.add_new_category": "Add New Category",
  "cms.announcement.enter_category": "Enter Category",
  "menu.lang.en": "English",
  "menu.lang.ja": "日本人",
  "menu.lang.vis": "Visayan",
  "menu.lang.il": "Ilocano",
  "menu.lang.tl": "Tagalog",
  "menu.login": "Login",
  "menu.logout": "Logout",
  "menu.news": "News",
  "menu.one_day": "1 Day",
  "menu.one_month": "1 Month",
  "menu.one_week": "1 Week",
  "menu.open_orders": "Open Orders",
  "menu.or": "or",
  "menu.order_history": "Order History",
  "menu.privacy": "Privacy Policy",
  "menu.register": "Register",
  "menu.support": "Support",
  "menu.support_withdrawal": "Withdraw guide",
  "menu.terms": "Terms Of Use",
  "menu.three_months": "3 Months",
  "menu.trade_history": "Trade History",
  "menu.withdraw_usd": "Withdrawal USD",
  "menu.withdrawals": "Withdrawals",
  "menu.cms.notification": "Notifications",
  "menu.cms.mail_template": "Mail Temapltes",
  "menu.cms.announcement": "Announcement",
  "menu.cms.faq": "FAQ",
  "menu.cms.qna": "Inquiry",
  "messages.error.balance_insufficient": "Balance is not enough",
  "messages.error.spot.balance_insufficient": "Exchange balance is not enough",
  "messages.error.airdrop.balance_insufficient":
    "Airdrop balance is not enough",
  "messages.error.margin.balance_insufficient": "Margin balance is not enough",
  "messages.error.main.available_balance_insufficient":
    "Available balance is not enough",
  "messages.error.spot.available_balance_insufficient":
    "Available exchange balance is not enough.",
  "messages.error.airdrop.available_balance_insufficient":
    "Available airdrop balance is not enough.",
  "messages.error.margin.available_balance_insufficient":
    "Available margin balance is not enough.",
  "messages.error.buy.empty_base_price": "Please input buy stop price.",
  "messages.error.buy.empty_limit": "Please input buy limit price.",
  "messages.error.buy.empty_price": "Please input buy price.",
  "messages.error.buy.empty_quantity": "Please input buy amount.",
  "messages.error.empty_total": "Total is required",
  "messages.error.minimum_base_price": "Stop Price must be at least :minimum.",
  "messages.error.minimum_limit": "Limit must be at least :minimum.",
  "messages.error.minimum_price": "Price must be at least :minimum.",
  "messages.error.minimum_quantity": "Amount must be at least :minimum.",
  "messages.error.minimum_total": "Total must be at least :minimum.",
  "messages.error.sell.empty_base_price": "Please input sell stop price.",
  "messages.error.sell.empty_limit": "Please input sell limit price.",
  "messages.error.sell.empty_price": "Please input sell price.",
  "messages.error.sell.empty_quantity": "Please input sell amount.",
  "messages.insufficient_balance": "Insufficient balance.",
  "messages.send_contact_success": "Send email is successful",
  "messages.send_contact_wrong_data": "Wrong data",
  "messages.sesstion_terminated":
    "Your current session is terminated because someone logged into this account from another device or browser.",
  "messages.successful_login":
    ":APP_NAME login :email\nLogin from :device\n:time",
  "messages.unauthorize": "Unauthorized",
  "messages.your_otp_code": "Your authentication code is :otp",
  "order.open_order.no_data": "You have no open order.",
  "order.open_order.action": "Action",
  "order.open_order.amount": "Amount",
  "order.open_order.cancel": "Cancel",
  "order.open_order.cancel_all": "Cancel All",
  "order.open_order.cancel_all_message": "Do you want to cancel all orders?",
  "order.open_order.cancel_limit_order": "Cancel Limit Order",
  "order.open_order.cancel_limit_price_message":
    "Do you want to cancel all Limit Orders?",
  "order.open_order.cancel_one_message": "Do you want to cancel this order?",
  "order.open_order.cancel_order_success": "Your order has been canceled",
  "order.open_order.cancel_orders_success": "Your orders have been canceled",
  "order.open_order.cancel_stop_limit_order": "Cancel Stop Limit Order",
  "order.open_order.cancel_stop_limit_price_message":
    "Do you want to cancel all Stop Limit Orders?",
  "order.open_order.cancel_stop_market_order": "Cancel Stop Market Order",
  "order.open_order.cancel_stop_market_price_message":
    "Do you want to cancel all Stop Market Orders?",
  "order.open_order.date": "Date",
  "order.open_order.deposit": "Deposit",
  "order.open_order.empty_open_order": "You don't have open order.",
  "order.open_order.filled": "Filled",
  "order.open_order.hide_other_pairs": "Hide other pairs",
  "order.open_order.hide_small_assets": "Hide small assets",
  "order.open_order.infomation": "Information",
  "order.open_order.limit_order": "Limit Order",
  "order.open_order.market": "Market",
  "order.open_order.open_order": "Open Order",
  "order.open_order.pair": "Pair",
  "order.open_order.price": "Price",
  "order.open_order.side": "Side",
  "order.open_order.size": "Size",
  "order.open_order.stop_limit_order": "Stop_Limit Order",
  "order.open_order.total": "Total",
  "order.open_order.trigger_conditions": "Trigger Conditions",
  "order.open_order.type": "Type",
  "order.open_order.withdrawal": "Withdrawal",
  "order.open_order.withdrawal_limit_24": "24 Withdrawal Limit",
  "order.open_order.avg_entry_price": "Avg. Entry Price",
  "order.open_order.avg_exit_price": "Avg. Exit Price",
  "order.order_book.amount": "Amount",
  "order.order_book.decimals": "decimals",
  "order.order_book.price": "Price",
  "order.order_book.total": "Total",
  "order.order_form.amount": "Amount",
  "order.order_form.balance": ":param Balance",
  "order.order_form.buy": "Buy",
  "order.order_form.limit": "Limit",
  "order.order_form.limited_price": "Limit",
  "order.order_form.login": "Login",
  "order.order_form.or": "or",
  "order.order_form.price": "Price",
  "order.order_form.register": "Register",
  "order.order_form.sell": "Sell",
  "order.order_form.stop": "Stop",
  "order.order_form.stop_limit_tooltip":
    "A Stop-Limit order is an order to buy or sell a coin with a given price once the price reaches a specified trigger price.",
  "order.order_form.stop_market_tooltip":
    "A Stop-Market order is an order to buy or sell a coin with a market price once the price reaches a specified trigger price.",
  "order.order_form.success": ":trade_type order is created successfully!",
  "order.order_form.total": "Total",
  "order.order_form.trade": "to trade.",
  "order.order_form.view_more": "View more...",
  "order.order_history.action": "Action",
  "order.order_history.no_data": "You have no order history.",
  "order.order_history.amount": "Amount",
  "order.order_history.average": "Average",
  "order.order_history.buy": "Buy",
  "order.order_history.cancel": "Cancel",
  "order.order_history.success": "Success",
  "order.order_history.cancel_all": "Cancel All",
  "order.order_history.coin": "Coin",
  "order.order_history.date": "Date",
  "order.order_history.deposit": "Deposit",
  "order.order_history.empty_order_history": "You don't have order history.",
  "order.order_history.export_complete_order_history":
    "Export Complete Order History",
  "order.order_history.export_complete_order_history_tooltip":
    "Only Export Complete Order History on 6 months",
  "order.order_history.export_complete_trade_history":
    "Export Complete Trade History",
  "order.order_history.export_complete_trade_history_tooltip":
    "Only Export Complete Trade History on 6 months",
  "order.order_history.fee": "Fee",
  "order.order_history.filled": "Filled",
  "order.order_history.hide_all_canceled": "Hide All Canceled",
  "order.order_history.infomation": "Information",
  "order.order_history.limit_order": "Limit Order",
  "order.order_history.market": "Market",
  "order.order_history.order_history": "Order History",
  "order.order_history.pair": "Pair",
  "order.order_history.price": "Price",
  "order.order_history.reset": "Reset",
  "order.order_history.search": "Search",
  "order.order_history.sell": "Sell",
  "order.order_history.side": "Side",
  "order.order_history.status": "Status",
  "order.order_history.stop_limit_order": "Stop_Limit Order",
  "order.order_history.total": "Total",
  "order.order_history.trigger_conditions": "Trigger Conditions",
  "order.order_history.type": "Type",
  "order.order_history.withdrawal": "Withdrawal",
  "order.order_history.withdrawal_limit_24": "24 Withdrawal Limit",
  "order.recent_trades.market": "Market",
  "order.recent_trades.recent_trades": "Recent Trades",
  "order.recent_trades.yours": "Yours",
  "order.trade_history.action": "Action",
  "order.trade_history.no_data": "You have no trade history.",
  "order.trade_history.all": "All",
  "order.trade_history.amount": "Amount",
  "order.trade_history.average": "Average",
  "order.trade_history.buy": "Buy",
  "order.trade_history.cancel": "Cancel",
  "order.trade_history.cancel_all": "Cancel All",
  "order.trade_history.coin": "Coin",
  "order.trade_history.date": "Date",
  "order.trade_history.deposit": "Deposit",
  "order.trade_history.export_complete_trade_history":
    "Export Complete Trade History",
  "order.trade_history.fee": "Fee",
  "order.trade_history.filled": "Filled",
  "order.trade_history.hide_all_canceled": "Hide All Canceled",
  "order.trade_history.infomation": "Information",
  "order.trade_history.limit_order": "Limit Order",
  "order.trade_history.market": "Market",
  "order.trade_history.my_history": "My order history",
  "order.trade_history.pair": "Pair",
  "order.trade_history.price": "Price",
  "order.trade_history.reset": "Reset",
  "order.trade_history.search": "Search",
  "order.trade_history.sell": "Sell",
  "order.trade_history.side": "Side",
  "order.trade_history.status": "Status",
  "order.trade_history.stop_limit_order": "Stop_Limit Order",
  "order.trade_history.total": "Total",
  "order.trade_history.trade_history": "Trade History",
  "order.trade_history.trigger_conditions": "Trigger Conditions",
  "order.trade_history.type": "Type",
  "order.trade_history.withdrawal": "Withdrawal",
  "order.trade_history.withdrawal_limit_24": "24 Withdrawal Limit",
  "order_mobile.charts": "Charts",
  "order_mobile.group": "Group",
  "order_mobile.max_amount": "Max Amount",
  "order_mobile.open_order": "Open Order",
  "order_mobile.total": "Total",
  "order_mobile.trade": "Trade",
  "passwords.password":
    "Passwords must be at least six characters and match the confirmation.",
  "passwords.reset": "Your password has been reset, now you can login.",
  "passwords.sent": "We have e-mailed your password reset link!",
  "passwords.token": "This password reset token is invalid.",
  "passwords.user": "We can't find a user with that e-mail address.",
  "refferal.alert":
    "You are not logged in, please login and share with your friends.",
  "refferal.alert_bank_account_content":
    'Please submit <span style="color: #f29600;">KYC</span> before verify NI Bank account. We’ll use your KYC information to verify bank account',
  "refferal.confirm_bank_account_content":
    "I have not had NI Bank account yet, please help me to creat one",
  "refferal.confirm_bank_account_title":
    "Do you want to verify NI Bank account for USD withdrawals using KYC information?",
  "refferal.get_rewards": "Get Rewards",
  "refferal.login": "Login",
  "refferal.not_on_site": "Not on :APP_NAME yet? ",
  "refferal.register": "Register",
  "refferal.copy_link": "Copy Link",
  "refferal.text1": "Register and generate referral links and QR codes ",
  "refferal.text10": "Referral Friends",
  "refferal.text11": "Date",
  "refferal.text12": "Program Details",
  "refferal.text13": "Effective as of 2018/05/19 0:00 AM (UTC)",
  "refferal.text14":
    "The commission you receive from the referral program will initially be set at a rate of 20%. For accounts holding 500 :COIN_HOLDING or more, this rate will increase to 40%.",
  "refferal.text15":
    "The fee commission will be sent instantly in real-time to your :APP_NAME account as your referee completes each trade and will be paid to you in whatever token/cryptocurrency the original fee was paid in.",
  "refferal.text16":
    "There is no limit to the number of friends you can refer, although we do reserve the right to adjust or change the referral program rules at any time.",
  "refferal.text18":
    "Each referee must be signed up through your Referral Link, QR Code or Referral ID.",
  "refferal.text19":
    ":APP_NAME will check for duplicate or fake accounts and will not pay out referral bonuses on these accounts. Duplicate or shared finances will result in disqualification.",
  "refferal.text2": "Invite Friends ",
  "refferal.text20": "* Important Notice",
  "refferal.text21":
    ":APP_NAME reserves the right to change the terms of the referral program at any time due to changing market conditions, risk of fraud, or any other factors we deem relevant.",
  "refferal.text22": "Commission",
  "refferal.text23": "Estimated <br/> Commission Value",
  "refferal.text24": "Latest Commission History",
  "refferal.text25": "Copied",
  "refferal.text26": "Export",
  "refferal.text3":
    "Invite your friends to register through the referral link or QR codes and get rewards once they complete a trade",
  "refferal.text4": "Get Paid",
  "refferal.text5": "Receive up to 40% commission in real-time",
  "refferal.text6": "My Referral ID:",
  "refferal.text7": "Your Commission Rate:",
  "refferal.text8": "Referral Link:",
  "refferal.text9": "Share:",
  "refferal.title": "Referral Program",
  "refferal.title1": "Get Your Link",
  "refferal.top": "NO.",
  "register.error_terms": "Please accept the terms",
  "register.has_account": "Already have an account?",
  "register.sub_title": "Create your account",
  "register.success":
    "<span>Congratulations! </span><strong>:email</strong><p>Please check your mailbox to verify your account</p>",
  "register.terms_1": "I agree to the",
  "register.terms_2": "terms",
  "register.title": "Register",
  "reset_password.resetted_password":
    "Your password has been reset, now you can login.",
  "reset_password.send_mail_title": "Send Password Reset Email",
  "reset_password.sent_mail":
    '<strong style="line-height: 25px;color: #333;font-size: 16px;font-weight: 400">A password reset email has been sent to your registered email address.</strong><br>Please follow the instructions in the email and reset your password within 24 hours of receiving the email.',
  "reset_password.sub_title_1": "Please input your email",
  "reset_password.sub_title_2": "Enter your new password",
  "reset_password.submit_btn": "Submit",
  "reset_password.title": "Reset Password",
  "reset_password.title_forgot": "Forgot Password",
  "service_center.send_contact_subject": "Send contact to :APP_NAME",
  "shared_components.alert.error": "Error",
  "shared_components.alert.primary": "Info",
  "shared_components.alert.success": "Success",
  "shared_components.alert.warning": "Warning",
  "shared_components.common.confirmation_modal.awesome": "Awesome",
  "shared_components.common.confirmation_modal.danger": "Danger",
  "shared_components.common.confirmation_modal.info": "Information",
  "shared_components.common.confirmation_modal.warning": "Warning",
  "shared_components.google_authentication_modal.lost_code":
    "Lost Your Google Authentication",
  "shared_components.google_authentication_modal.otp_code_invalid":
    "Google Authentication code is invalid.",
  "shared_components.google_authentication_modal.submit_button": "Submit",
  "shared_components.google_authentication_modal.title":
    "Google Authentication",
  "shared_components.pagination.next": "Next",
  "shared_components.pagination.pre": "Previous",
  "masterdata.text": "Masterdata is changed.</br>Please reload page again.",
  "unregistered_session.login": "Login",
  "unregistered_session.text":
    "Your current session is terminated. Please log in again!",
  "validation.messages._default": "The {0} value is not valid.",
  "validation.messages.after": "The {0} must be after {1}.",
  "validation.messages.after2": "The {0} must be after or equal to {1}.",
  "validation.messages.alpha_dash":
    "The {0} field may contain alpha-numeric characters as well as dashes and underscores.",
  "validation.messages.alpha_num":
    "The {0} field may only contain alpha-numeric characters.",
  "validation.messages.alpha_spaces":
    "The {0} field may only contain alphabetic characters as well as spaces.",
  "validation.messages.alpha":
    "The {0} field may only contain alphabetic characters.",
  "validation.messages.before": "The {0} must be before {1}.",
  "validation.messages.before2": "The {0} must be before or equal to {1}.",
  "validation.messages.between": "The {0} field must be between {1} and {2}.",
  "validation.messages.confirmed": "The {0} confirmation does not match.",
  "validation.messages.credit_card": "The {0} field is invalid.",
  "validation.messages.date_between": "The {0} must be between {1} and {2}.",
  "validation.messages.date_format": "The {0} must be in the format {1}.",
  "validation.messages.decimal":
    "The {0} field must be numeric and may contain {1} decimal points.",
  "validation.messages.digits":
    "The {0} field must be numeric and exactly contain {1} digits.",
  "validation.messages.dimensions":
    "The {0} field must be {1} pixels by {2} pixels.",
  "validation.messages.email": "The {0} field must be a valid email.",
  "validation.messages.ext": "The {0} field must be a valid file.",
  "validation.messages.image": "The {0} field must be an image.",
  "validation.messages.in": "The {0} field must be a valid value.",
  "validation.messages.integer": "The {0} field must be an integer.",
  "validation.messages.ip": "The {0} field must be a valid ip address.",
  "validation.messages.length": "The {0} length must be {1}.",
  "validation.messages.length2": "The {0} length be between {1} and {2}.",
  "validation.messages.max":
    "The {0} field may not be greater than {1} characters.",
  "validation.messages.max_value": "The {0} field must be {1} or less.",
  "validation.messages.mimes": "The {0} field must have a valid file type.",
  "validation.messages.min": "The {0} field must be at least {1} characters.",
  "validation.messages.min_value": "The {0} field must be {1} or more.",
  "validation.messages.not_in": "The {0} field must be a valid value.",
  "validation.messages.numeric":
    "The {0} field may only contain numeric characters.",
  "validation.messages.regex": "The {0} field format is invalid.",
  "validation.messages.required": "The {0} field is required.",
  "validation.messages.size": "The {0} size must be less than {1}.",
  "validation.messages.url": "The {0} field is not a valid URL.",
  "validation.accepted": "The :attribute must be accepted.",
  "validation.active_url": "The :attribute is not a valid URL.",
  "validation.after": "The :attribute must be a date after :date.",
  "validation.after_or_equal":
    "The :attribute must be a date after or equal to :date.",
  "validation.alpha": "The :attribute may only contain letters.",
  "validation.alpha_dash":
    "The :attribute may only contain letters, numbers, and dashes.",
  "validation.alpha_num":
    "The :attribute may only contain letters and numbers.",
  "validation.array": "The :attribute must be an array.",
  "validation.attributes.account_name": "account name",
  "validation.attributes.account_number": "account number",
  "validation.attributes.bank_id": "bank",
  "validation.attributes.base_price": "STOP Price",
  "validation.attributes.date_of_birth": "birthday",
  "validation.attributes.new_password": "new password",
  "validation.attributes.otp_recovery_code": "recovery code",
  "validation.attributes.password": "password",
  "validation.attributes.quantity": "Amount",
  "validation.attributes.wallet_address": "wallet address",
  "validation.attributes.withdrawal_address": "withdrawal address",
  "validation.before": "The :attribute must be a date before :date.",
  "validation.before_or_equal":
    "The :attribute must be a date before or equal to :date.",
  "validation.between.array":
    "The :attribute must have between :min and :max items.",
  "validation.between.file":
    "The :attribute must be between :min and :max kilobytes.",
  "validation.between.numeric": "The :attribute must be between :min and :max.",
  "validation.between.string":
    "The :attribute must be between :min and :max characters.",
  "validation.boolean": "The :attribute field must be true or false.",
  "validation.confirmed": "The :attribute confirmation does not match.",
  "validation.correct_otp": "The authentication code is incorrect.",
  "validation.custom.agree_term.required":
    "You must read and agree with Term of Use.",
  "validation.custom.attribute-name.rule-name": "custom-message",
  "validation.custom.base_price.min": "Stop Price must be at least :min",
  "validation.custom.base_price.precision":
    "Stop Price must have the precision of :precision",
  "validation.custom.id_back.image": "File must be an image.",
  "validation.custom.id_back.mimes": "File must be a file of type: :values.",
  "validation.custom.id_front.image": "File must be an image.",
  "validation.custom.id_front.mimes": "File must be a file of type: :values.",
  "validation.custom.id_selfie.image": "File must be an image.",
  "validation.custom.id_selfie.mimes": "File must be a file of type: :values.",
  "validation.custom.password.confirmed":
    "Passwords must match the password prompt.",
  "validation.custom.password.correct_password": "The password is incorrect",
  "validation.custom.password.min":
    "Passwords must contain at least 8 characters, capital letters, lowercase letters, numbers and must match the duplicate password box.",
  "validation.custom.password.regex":
    "Passwords must contain at least 8 characters, capital letters, lowercase letters, numbers and must match the duplicate password box.",
  "validation.custom.price.min": "Price must be at least :min",
  "validation.custom.price.precision":
    "Price must have the precision of :precision",
  "validation.custom.quantity.min": "Amount must be at least :min",
  "validation.custom.quantity.precision":
    "Amount must have the precision of :precision",
  "validation.custom.total.min": "Total must be at least :min",
  "validation.custom.total.precision":
    "Total must have the precision of :precision",
  "validation.date": "The :attribute is not a valid date.",
  "validation.date_format": "The :attribute does not match the format :format.",
  "validation.different": "The :attribute and :other must be different.",
  "validation.digits": "The :attribute must be :digits digits.",
  "validation.digits_between":
    "The :attribute must be between :min and :max digits.",
  "validation.dimensions": "The :attribute has invalid image dimensions.",
  "validation.distinct": "The :attribute field has a duplicate value.",
  "validation.email": "The :attribute must be a valid email address.",
  "validation.exists": "The selected :attribute is invalid.",
  "validation.file": "The :attribute must be a file.",
  "validation.filled": "The :attribute field must have a value.",
  "validation.image": "The :attribute must be an image.",
  "validation.in": "The selected :attribute is invalid.",
  "validation.in_array": "The :attribute field does not exist in :other.",
  "validation.integer": "The :attribute must be an integer.",
  "validation.ip": "The :attribute must be a valid IP address.",
  "validation.ipv4": "The :attribute must be a valid IPv4 address.",
  "validation.ipv6": "The :attribute must be a valid IPv6 address.",
  "validation.is_withdrawal_address": "The :attribute is Invalid.",
  "validation.json": "The :attribute must be a valid JSON string.",
  "validation.max.array": "The :attribute may not have more than :max items.",
  "validation.max.file":
    "The :attribute may not be greater than :max kilobytes.",
  "validation.max.numeric": "The :attribute may not be greater than :max.",
  "validation.max.string":
    "The :attribute may not be greater than :max characters.",
  "validation.mimes": "The :attribute must be a file of type: :values.",
  "validation.mimetypes": "The :attribute must be a file of type: :values.",
  "validation.min.array": "The :attribute must have at least :min items.",
  "validation.min.file": "The :attribute must be at least :min kilobytes.",
  "validation.min.numeric": "The :attribute must be at least :min.",
  "validation.min.string": "The :attribute must be at least :min characters.",
  "validation.min_value": ":attribute must be at least :min",
  "validation.not_in": "The selected :attribute is invalid.",
  "validation.numeric": "The :attribute must be a number.",
  "validation.otp": "Authentication failed.",
  "validation.otp_not_used":
    "Please wait for next verification code to generate.",
  "validation.otp_or_google_auth_required":
    "You need to enter Google Authentication Code",
  "validation.password_white_space": "Password should not contain white space.",
  "validation.precision": ":attribute must have the precision of :precision",
  "validation.present": "The :attribute field must be present.",
  "validation.regex": "The :attribute format is invalid.",
  "validation.required": "The :attribute field is required.",
  "validation.required_if":
    "The :attribute field is required when :other is :value.",
  "validation.required_unless":
    "The :attribute field is required unless :other is in :values.",
  "validation.required_with":
    "The :attribute field is required when :values is present.",
  "validation.required_with_all":
    "The :attribute field is required when :values is present.",
  "validation.required_without":
    "The :attribute field is required when :values is not present.",
  "validation.required_without_all":
    "The :attribute field is required when none of :values are present.",
  "validation.same": "The :attribute and :other must match.",
  "validation.size.array": "The :attribute must contain :size items.",
  "validation.size.file": "The :attribute must be :size kilobytes.",
  "validation.size.numeric": "The :attribute must be :size.",
  "validation.size.string": "The :attribute must be :size characters.",
  "validation.string": "The :attribute must be a string.",
  "validation.timezone": "The :attribute must be a valid zone.",
  "validation.unique": "The :attribute has already been taken.",
  "validation.unique_email": "The email has already been taken.",
  "validation.unique_referrer_code": "The referral code is invalid.",
  "validation.unique_withdrawal_address_in_user":
    "The withdrawal address has already been existed.",
  "validation.uploaded": "The :attribute failed to upload.",
  "validation.url": "The :attribute format is invalid.",
  "validation.verified_email": "The email hasn't been confirmed yet.",
  "validation.verify_otp_or_google_auth":
    "You need to enter Google Authentication Code field exactly",
  "validation.verify_otp_recovery_code": "The :attribute is invalid.",
  "validation.verify_otp_recovery_code_with_auth":
    "The :attribute is incorrect.",
  "withdraw_guide.currency": "Currency",
  "withdraw_guide.deposit_fee": "Deposit fee",
  "withdraw_guide.email": "Email verification",
  "withdraw_guide.fee": "Fee",
  "withdraw_guide.fee_withdrawal": "Withdrawal fee",
  "withdraw_guide.free": "Free",
  "withdraw_guide.gotp": "Google OTP",
  "withdraw_guide.identity_verification": "Identity Verification",
  "withdraw_guide.level": "Step break",
  "withdraw_guide.level_name": "Level ",
  "withdraw_guide.level_title": "Current certification level is",
  "withdraw_guide.limit_tip1":
    "The withdrawal limit is different depending on the individual authentication level.",
  "withdraw_guide.limit_tip2":
    "Depending on the market situation and the regulations of the relevant bank (bank), the above withdrawal limit may be changed without prior notice.",
  "withdraw_guide.limit_tip3":
    "If we suspect fraudulent transactions, withdrawals may be restricted.",
  "withdraw_guide.limit_tip4":
    "The withdrawal fee will be charged at a fixed amount, regardless of the amount of the currency",
  "withdraw_guide.limit_tip5":
    "In the case of a crypto-currency withdrawal, the actual fee may vary or be higher or lower than the displayed fee depending on the status of the block chain network.",
  "withdraw_guide.limit_title1":
    "1. Limit of withdrawal by authentication step",
  "withdraw_guide.limit_title2":
    "2. Deposit and withdrawal fee and minimum withdrawal amount",
  "withdraw_guide.minimum_withdrawal": "Minimum withdrawal amount",
  "withdraw_guide.one_day": "1 day",
  "withdraw_guide.one_time": "1 time",
  "withdraw_guide.phone": "Phone authentication",
  "withdraw_guide.security_method": "Authentication method",
  "withdraw_guide.support_withdrawal": "Deposit and withdrawal instructions",
  "withdraw_guide.usd": "usd",
  "trade_type.buy": "Buy",
  "trade_type.sell": "Sell",
  "common.message.network_error": "Cannot connect to server.",
  "common.status.success": "Success",
  "common.status.pending": "Pending",
  "common.status.cancel": "Canceled",
  "common.status.error": "Error",
  "common.search": "Search",
  "common.action.confirm": "Confirm",
  "common.action.reject": "Reject",
  "common.action.cancel": "Cancel",
  "common.action.create": "Create",
  "common.kyc.pending": "Pending",
  "common.kyc.verified": "Verified",
  "common.order_status.pending": "Pending",
  "common.empty_data": "Data empty.",
  "menu.orders.orders": "Orders",
  "menu.orders.spot": "Spot",
  "menu.orders.open_order": "Open Order",
  "menu.orders.order_history": "Order History",
  "menu.orders.trade_history": "Trade History",
  "menu.notices": "Notices",
  "menu.email_marketing": "Email Marketing",
  "menu.home": "Home",
  "usd_transaction.time": "Time",
  "usd_transaction.user": "Email",
  "usd_transaction.account": "Account",
  "usd_transaction.bank_name": "Bank",
  "usd_transaction.bank_branch": "Branch",
  "usd_transaction.status": "Status",
  "usd_transaction.amount": "Amount",
  "kyc.list": "List KYC",
  "kyc.input_search": "Search",
  "kyc.created_at": "Time",
  "kyc.email": "Email",
  "kyc.name": "Full Name",
  "kyc.country": "Country",
  "kyc.id_number": "Passport/ID Number",
  "kyc.status": "Status",
  "kyc.gender": "Gender",
  "kyc.id_front": "Identity Card Front Side/Passport cover",
  "kyc.id_back": "Identity Card Back Side/Passport data page",
  "kyc.id_selfie": "Selfie With Photo ID And Note",
  "kyc.btn_detail": "Detail",
  "kyc.btn_verify": "Verify",
  "kyc.btn_reject": "Reject",
  "kyc.notice_verify_confirm": "Do you want to confirm this KYC?",
  "kyc.notice_reject_confirm": "Do you want to reject this KYC?",
  "kyc.detail_kyc": "Detail KYC",
  "kyc.search": "Search",
  "kyc.reset": "Reset",
  "kyc.data": "Data",
  "kyc.action": "Action",
  "kyc.status_pending": "Pending",
  "kyc.status_verified": "Verified",
  "kyc.status_unverified": "Unverified",
  "kyc.status_rejected": "Rejected",
  "kyc.account_id": "User ID",
  "user.id": "ID",
  "user.user": "User",
  "user.user_information": "User Information",
  "user.date": "Date",
  "user.email": "Email",
  "user.fee_level": "Fee Level",
  "user.security_level": "Security Level",
  "user.max_security_level": "Max Security Level",
  "user.referrer_code": "Referral Code",
  "user.referrer_mail": "Referrer Mail",
  "user.type": "Type",
  "user.status": "Status",
  "user.normal": "normal",
  "user.bot": "bot",
  "user.inactive": "inactive",
  "user.active": "active",
  "user.save": "Save",
  "user.edit": "Edit",
  "user.device": "Device",
  "user.recent_activity": "Recent Activity",
  "device_management.title_device_management": "Device Management",
  "user.succes": "Updated successfully!",
  "user.user_setting": "User Setting",
  "notice.edit_title_page": "Edit Notice",
  "notice.new_title_page": "Create Notice",
  "notice.title": "Title",
  "notice.date": "Date",
  "notice.search": "Search",
  "notice.reset": "Reset",
  "notice.new": "New",
  "notice.support": "Link Support",
  "notice.start": "Started",
  "notice.end": "Ended",
  "notice.edit": "Edit",
  "notice.delete": "Delete",
  "notice.start_at": "Start at",
  "notice.end_at": "End at",
  "notice.banner": "Banner",
  "notice.submit": "Submit",
  "notice.cancel": "Cancel",
  "notice.confirm": "Confirm",
  "notice.search_by_title": "Search by title.....",
  "notice.choose_file": "Choose File",
  "notice.delete_data": "Do you want to delete this notice?",
  "notice.submit_data": "Do you want to submit this notice?",
  "notice.errors.required": "This field is required",
  "notice.errors.maxsize": "Size image must be less 2Mb",
  "notice.errors.date": "End date must be more than start date",
  "notice.noticeEdit": "Edit notice",
  "qna.submit_data": "Do you want to submit this answer?",
  "orders.open_order.open_order": "Open Order",
  "orders.open_order.date": "Date",
  "orders.open_order.from": "From",
  "orders.open_order.to": "To",
  "orders.open_order.pair": "Pair",
  "orders.open_order.symbol": "Symbol",
  "orders.open_order.country": "Country",
  "orders.open_order.search_by_email": "Search by email",
  "orders.open_order.search": "Search",
  "orders.open_order.reset": "Refresh",
  "orders.open_order.time": "Time",
  "orders.open_order.trade_id": "Trade Id",
  "orders.open_order.order_id": "Order Id",
  "orders.open_order.email": "Email",
  "orders.open_order.price": "Price",
  "orders.open_order.filled": "Filled",
  "orders.open_order.fee": "Fee",
  "orders.open_order.total": "Total",
  "orders.open_order.type": "Type",
  "orders.open_order.action": "Action",
  "orders.open_order.amount": "Amount",
  "orders.open_order.trigger_condition": "Trigger condition",
  "orders.open_order.status": "Status",
  "orders.open_order.average": "Average",
  "orders.open_order.side": "Side",
  "orders.open_order.buy": "Buy",
  "orders.open_order.sell": "Sell",
  "orders.open_order.all": "All",
  "orders.open_order.trade_history": "Trade History",
  "orders.open_order.index": "No.",
  "orders.open_order.view_detail": "View Detail",
  "orders.open_order.market": "Market",
  "orders.open_order.cancel_successful": "Cancel Successful",
  "orders.open_order.cancel_failed": "Cancel Failed",
  "orders.open_order.cancel_modal_content": "Can you cancel this order?",
  "orders.order_history.order_history": "Order History",
  "orders.order_history.date": "Date",
  "orders.order_history.pair": "Pair",
  "orders.order_history.search_by_email": "Search by email",
  "orders.order_history.search": "Search",
  "orders.order_history.reset": "Reset",
  "orders.order_history.time": "Time",
  "orders.order_history.trade_id": "Trade Id",
  "orders.order_history.order_id": "Order Id",
  "orders.order_history.email": "Email",
  "orders.order_history.price": "Price",
  "orders.order_history.filled": "Filled",
  "orders.order_history.fee": "Fee",
  "orders.order_history.total": "Total",
  "orders.order_history.type": "Type",
  "orders.order_history.action": "Action",
  "orders.order_history.amount": "Amount",
  "orders.order_history.trigger_condition": "Trigger condition",
  "orders.order_history.status": "Status",
  "orders.order_history.average": "Avg. Price",
  "orders.order_history.quantity": "Quantity",
  "orders.order_history.account_id": "User ID",
  "orders.order_history.side": "Side",
  "orders.order_history.buy": "Buy",
  "orders.order_history.sell": "Sell",
  "orders.order_history.all": "All",
  "orders.order_history.hide_all_canceled": "Hide All Canceled",
  "orders.order_history.trade_history": "Trade History",
  "orders.order_history.index": "No.",
  "orders.order_history.view_detail": "View Detail",
  "orders.order_history.market": "Market",
  "orders.trade_history.trade_history": "Trade History",
  "orders.trade_history.date": "Date",
  "orders.trade_history.pair": "Pair",
  "orders.trade_history.search_by_email": "Search by email",
  "orders.trade_history.search": "Search",
  "orders.trade_history.reset": "Reset",
  "orders.trade_history.time": "Time",
  "orders.trade_history.trade_id": "Trade Id",
  "orders.trade_history.original_buy_order_id": "Original Buy Order Id",
  "orders.trade_history.buyer_email": "Buyer Email",
  "orders.trade_history.original_sell_order_id": "Original Sell Order Id",
  "orders.trade_history.seller_email": "Seller email",
  "orders.trade_history.price": "Price",
  "orders.trade_history.filled": "Filled",
  "orders.trade_history.buy_fee": "Buy Fee",
  "orders.trade_history.sell_fee": "Sell Fee",
  "orders.trade_history.total": "Total",
  "orders.trade_history.market": "Market",
  "orders.trade_history.all": "All",
  "orders.trade_history.data": "Data",
  "orders.trade_history.account_id": "User ID",
  "orders.trade_history.buy_id": "Buy ID",
  "orders.trade_history.sell_id": "Sell ID",
  "orders.trade_history.side": "Side",
  "orders.trade_history.quantity": "Quantity",
  "orders.trade_history.action": "Action",
  "account.status.active": "Active",
  "account.status.inactive": "Inactive",
  "account.list.level": "Level",
  "account.list.kyc_status": "KYC Status",
  "account.list.fa": "2FA",
  "account.list.active_status": "Active Status",
  "account.list.search_by": "Search by account Id, email",
  "account.account_detail.message_change_status_success":
    "Change activation status successful!",
  "account.account_detail.message_change_status_fail":
    "Change activation status failed!",
  "account.account_detail.joined_time": "Joined time",
  "account.account_detail.activation_status": "Activation Status",
  "account.account_detail.email": "Email",
  "account.account_detail.phone_number": "Phone Number",
  "account.account_detail.level": "Level",
  "account.account_detail.kyc_status": "KYC Status",
  "account.account_detail": "Account Detail",
  "account.account_detail.account_id": "User ID",
  "account.account_detail.spot_future": "Spot/Futures Profile",
  "account.account_detail.balance": "Balance",
  "account.account_detail.logs": "Logs",
  "account.account_detail.transactions": "Transactions",
  "account.account_detail.affiliate": "Affiliate",
  "account.account_detail.activity_history": "Activity Hitory",
  "account.account_detail.open_order": "Open Order",
  "account.account_detail.order_history": "Order History",
  "account.account_detail.trade_history": "Trade History",
  "account.account_detail.update": "Update",
  "account.account_detail.confirm_active":
    "Are you sure you want to active this account?",
  "account.account_detail.confirm_deactive":
    "Are you sure you want to inactive this account?",
  "account.spot_future.confirm_update_spot":
    "Are you sure you want to update spot profile?",
  "account.spot_future.confirm_update_futures":
    "Are you sure you want to update futures profile?",
  "account.spot_future.spot_profile": "Spot Profile",
  "account.spot_future.future_profile": "Future Profile",
  "account.spot_future.allow_trade": "Allow Trade",
  "account.spot_future.market_maker": "Maket Maker Account",
  "account.spot_future.trading_fee": "Trading Fee",
  "account.spot_future.pre_trading": "Pre-trading Pairs",
  "account.spot_future.popup_text_content":
    "Are you sure you want to deactivate this account?",
  "account.spot_future.update_successful": "Update Successful!",
  "account.spot_future.update_failed": "Update Failed!",
  "account.account_detail.balance": "Balance",
  "account.account_detail.coin": "Coin",
  "account.account_detail.in_order": "In Order",
  "account.account_detail.available": "Available",
  "account.account_detail.total": "Total",
  "account.account_detail.action": "Action",
  "account.account_detail.balance_spot": "Spot Balance",
  "account.account_detail.balance_future": "Futures Balance",
  "account.logs.spot": "Spot Balance Logs",
  "account.logs.futures": "Futures Balance Logs",
  "account.logs.id": "ID",
  "account.logs.type": "Type",
  "account.logs.amount": "Amount",
  "account.logs.note": "Note",
  "account.logs.create_time": "Created time",
  "account.transaction.withdraw_transaction": "Withdraw Transaction",
  "account.transaction.deposit_transaction": "Deposit Transaction",
  "account.transaction.transfer_transaction": "Transfer Transaction",
  "account.transaction.id": "ID",
  "account.transaction.type": "Type",
  "account.transaction.amount": "Amount",
  "account.transaction.receiver": "Reciver",
  "account.transaction.hash": "Hash",
  "account.transaction.status": "Status",
  "account.transaction.created_time": "Created time",
  "account.transaction.action": "Action",
  "account.transaction.from": "From",
  "account.transaction.to": "To",
  "email.new_title_page": "Email Marketing Create",
  "email.new": "Create Email Marketing",
  "email.title": "Title",
  "email.content": "Content",
  "email.submit_data": "Do you really want ?",
  "email.delete_data": "Do you want delete ?",
  "email.send_all": "Send all User",
  "email.list_user": "Send list User",
  "withdraw_fee_setting.title": "Withdraw fee setting",
  "withdraw_fee_setting.daily_limit": "Daily Limit",
  "withdraw_setting.change_confirm": "Do you want to change withdraw fee?",
  "withdraw_fee_setting.disable_confirm": "Do you want to disable withdraw?",
  "withdraw_fee_setting.disable_all_confirm":
    "Do you want to disable all withdraw?",
  "withdraw_fee_setting.enable_confirm": "Do you want to enable withdraw?",
  "withdraw_fee_setting.enable_all_confirm": "Do you want to enable withdraw?",
  "withdraw_fee_setting.disable_deposit_confirm":
    "Do you want to disable deposit?",
  "withdraw_fee_setting.disable_all_deposit_confirm":
    "Do you want to disable all deposit?",
  "withdraw_fee_setting.enable_deposit_confirm":
    "Do you want to enable deposit?",
  "withdraw_fee_setting.enable_all_deposit_confirm":
    "Do you want to enable all deposit?",
  "withdraw_fee_setting.update_success_msg": "Updated successfully!",
  "withdraw_fee_setting.disable_success_msg": "Disable success",
  "withdraw_fee_setting.disable_all_success_msg": "Disable all success",
  "withdraw_fee_setting.enable_success_msg": "Enable success",
  "withdraw_fee_setting.enable_all_success_msg": "Enable all success",
  "withdraw_fee_setting.withdraw_setting": "Transaction Setting",
  "site_setting.android_app_link": "Android app link",
  "site_setting.site_email": "Email",
  "site_setting.app_name": "App Name",
  "site_setting.short_name": "Short Name",
  "site_setting.copyright": "Copyright",
  "site_setting.facebook_icon": "Facebook icon",
  "site_setting.facebook_link": "Facebook link",
  "site_setting.ios_app_link": "iOs app link",
  "site_setting.language": "Language",
  "site_setting.site_phone_number": "Phone number",
  "site_setting.site_setting": "Site setting",
  "site_setting.twitter_icon": "Twitter icon",
  "site_setting.twitter_link": "Twitter link",
  "site_setting.update_success_msg": "Updated successfully!",
  "site_setting.youtube_icon": "Youtube icon",
  "site_setting.youtube_link": "Youtube link",
  "site_setting.name": "text",
  "site_setting.icon": "file",
  "site_setting.icon_hover": "text",
  "site_setting.link": "text",
  "site_setting.general": "General",
  "site_setting.mobile_application": "Mobile application",
  "site_setting.name_table": "Name",
  "site_setting.icon_table": "Icon Class",
  "site_setting.icon_hover_table": "Icon hover",
  "site_setting.type_table": "Type",
  "site_setting.link_table": "Link",
  "site_setting.add_row_table": "+ Add row",
  'site_setting.enable': 'Enable',
  'site_setting.disable': 'Disable',
  'site_setting.hot_search_setting': 'Hot Search Setting',
  'site_setting.available_pairs': 'Available Pairs',
  'site_setting.selected_pairs': 'Selected Pairs',
  'site_setting.update': 'Update',
  "site_setting.status_table": "Status",
  "site_setting.social_network.title": "Social network",
  "site_setting.social_network.confirm_remove":
    "Do you want to remove Social network?",
  "site_setting.social_network.create_success":
    "Added Social network successfully!",
  "site_setting.social_network.update_success":
    "Updated Social network successfully!",
  "site_setting.social_network.remove_success":
    "Removed Social network successfully!",
  "site_setting.social_network.errors.required.name":
    "The name field is required.",
  "site_setting.social_network.errors.required.icon_class":
    "The icon class field is required.",
  "site_setting.social_network.errors.required.type":
    "The type field is required.",
  "trading_fee_setting.fee_maker": "Fee Maker",
  "trading_fee_setting.fee_taker": "Fee Taker",
  "trading_fee_setting.mgc_taker": "MGC Amount",
  "trading_fee_setting.id": "ID",
  "trading_fee_setting.trading_fee_setting": "FEE",
  "trading_fee_setting.trading_feeSetting": "Trading Fee Setting",
  "trading_fee_setting.trading_withdrawFeeSetting": "Withdrawal Fee Setting",
  "trading_fee_setting.update_confirm": "Do you want to change trading fee?",
  "trading_fee_setting.update_success_msg": "Updated successfully!",
  "button.done": "Done",
  "button.reset": "Reset",
  "user_setting.coin": "Coin",
  "user_setting.total_users": "Total User: ",
  "user_setting.blockchain_address": "Blockchain Address",
  "user_setting.balance": "Balance",
  "user_setting.available_balance": "Available Balance",
  "user_setting.confirm_off_otp": "Do you want to disable OTP?",
  "user_setting.confirm_on_otp": "Do you want to enable OTP?",
  "user_setting.update_otp_msg": "Updated setting OTP successfully!",
  "user_setting.confirm_disable_account": "Do you want to disable account?",
  "user_setting.disable_account_success_msg": "Disable account successfully?",
  "user_setting.confirm_enable_account": "Do you want to enable account?",
  "user_setting.enable_account_success_msg": "Enable account successfully?",
  "not_found.title": "Page Not Found",
  "not_found.text1": "We can’t find the page you’re looking for.",
  "not_found.text2":
    "You can either return to the previous page, visit our homepage or contact our support team.",
  "not_found.landing": "Landing",
  "login.admin": "Login Admin",
  "login.email": "E-Mail Address",
  "login.email2": "E-Mail",
  "common.order_status.partial_filled": "Partial Filled",
  "common.order_status.canceled": "Canceled",
  "user.referrer": "referrer",
  "user.device": "Divice",
  "user.otp": "Otp",
  "email.errors.required": "You need sent all Users or list users",
  "email.email_title": "This is title",
  "email.email_content": "This is content",
  "email.fail": "Fail",
  "exchange_balance.available_balance": "Available balances",
  "exchange_balance.exchange_balance": "Exchange balance",
  "exchange_fee.level": "Level",
  "withdraw_setting.disable_all": "Disable all",
  "withdraw_setting.enable_all": "Enable all",
  "withdraw_setting.enable": "Enable",
  "withdraw_setting.disable": "Disable",
  "withdraw_fee_setting.id": "ID",
  "withdraw_fee_setting.currency": "Currency",
  "withdraw_fee_setting.limit": "Limit",
  "withdraw_fee_setting.fee": "Fee",
  "withdraw_fee_setting.is_visible": "Is Visible",
  "withdraw_fee_setting.minium_withdrawal": "Minium Withdrawal",


  





    "dashboard.total_account": "Total Account",
    "dashboard.total_verified": "Total Verified Account",
    "dashboard.total_secured": "Total Secured Account",
    "dashboard.total_deposited": "Total Deposited Account",
    "dashboard.total_withdrawal": "Total Withdrawal",
    "dashboard.total_deposit": "Total Deposit",
    "dashboard.total_withdrawal_usd": "Total Withdrawal USD",
    "dashboard.total_deposited_usd": "Total Deposited USD",
    "dashboard.total_new_account": "Total New Account",
    "entry_manual_payment.team_name": "Team Name",
    "entry_manual_payment.team_id": "Team ID",
    "entry_manual_payment.leader": "Leader",
    "entry_manual_payment.number_of_me mber": "Number of Member",
    "entry_manual_payment.total_volume": "Total Volume",
    "entry_manual_payment.total_roe": "Total ROE",
    "referrer.error.field_required": "The {field_name} field is required.",
    "referral_setting.refund_rate": "refund rate",
    "referral_setting.refund_rate_too_big":
      "Percent value must be smaller than or equal to 100.",
    "referral_setting.next_program_conditional": "next program conditional",
    "referral_setting.require_total_percent":
      "Total percent of all levels mustn't bigger than 100%",
    "referral_setting.percent_at_lv_1": "referral percent at level 1",
    "referral_setting.percent_at_lv_2": "referral percent at level 2",
    "referral_setting.percent_at_lv_3": "referral percent at level 3",
    "referral_setting.percent_at_lv_4": "referral percent at level 4",
    "referral_setting.percent_at_lv_5": "referral percent at level 5",
    "notifications.confirmation_reset_password.receiving_text":
      "You are receiving this message because we received a reset password request for your account.",
    "notifications.confirmation_reset_password.please_click":
      "Please click below link to reset your password.",
    "notifications.confirmation_reset_password.valid_24h":
      "The link above is valid for 24 hours.",
    "notifications.confirmation_reset_password.please_confirm":
      "Please complete your password changing within 24 hours of receiving this message.",
    "notifications.deposit_withdraw_usd_alerts.hello": "Hello ",
    "window.suffix_title": "Exchange",
    "about.about_us": "About Us",
    "about.advantage": "Our Advantages",
    "about.coin": "Multiple-Coin Support",
    "about.coin_reason": "BTC, ETH, LTC, ...",
    "about.device": "All Devices Covered",
    "about.device_reason": "Support and FAQs available in multiple languages",
    "about.envi": "Multiple-Language Support",
    "about.envi_reason": "Web, Android, iOS, Mobile Web",
    "about.liqui": "High Liquidity",
    "about.liqui_reason": "Abundant resources and partners",
    "about.perform": "High Performance",
    "about.perform_reason": "Capable of processing 1,400,000 orders per second",
    "about.product": "Proven Products",
    "about.product_reason":
      "The underlying {APP_NAME} platform has been deployed on 30+ exchanges already. It supports all devices and multiple languages, offering a seamless user experience.",
    "about.resources": "Industry Resources",
    "about.resources_reason":
      "We have solid relationships with industry leaders, simply look at the list of investors and advisors who are willing to stand behind our platform.",
    "about.safe": "Safety Stability",
    "about.safe_reason": "Multi-tier & multi-cluster system architecture",
    "about.team_strong": "Strong Team",
    "about.team_strong_reason":
      "Our team have experience in both wall-street and crypto finance. Our team also have a track record of successful startups under our belt.",
    "about.tech": "Superior Technology",
    "about.tech_reason":
      "Our certified matching engine is capable of processing 1,400,000 orders per second, making {APP_NAME} one of the fastest exchange in the market today.",
    "about.tit":
      "Launched in 2018 {APP_NAME} is a  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, qttuis nostrud exercitation ullamco labor ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. ",
    "account.address_manager": "Address Management",
    "account.address_manager_content":
      "Address Management allows you to save and write memos for each of your withdrawal addresses. The optional Whitelist function helps protect your funds by only allowing withdrawals to whitelisted addresses.",
    "account.bank": "{0}",
    "account.btn_general": "General",
    "account.btn_pending": "Verifying",
    "account.btn_rejected": "Rejected",
    "account.btn_unverified": "Unverified",
    "account.btn_uver_bank": "Unverified",
    "account.btn_verified": "Verified",
    "account.btn_verify_bank": "Verify",
    "account.button_show": "View more",
    "account.change": "Change",
    "account.daily_higher_limit": "Higher Limit",
    "account.daily_limit_withdraw": "24h Withdrawal Limit",
    "account.date": "Date",
    "account.email_desc":
      "If you disable, you will lose notification for Login, Withdrawal, Deposit.",
    "account.device": "Device",
    "account.device_management": "Device Management",
    "account.devices_allowed":
      "These devices are currently allowed to access your account.",
    "account.disable": "Disable",
    "account.email_disable_confirm":
      "Do you want to disable receive notification?",
    "account.discount": "Using AMAL to pay for fees (50% discount)",
    "account.enable": "Enable",
    "account.error_bank": "* Used for withdrawal and security modifications",
    "account.google_auth": "Google Authentication",
    "account.lost_google_authenticator": "Lost Your Google Authentication?",
    "account.google_auth_content":
      "Used for withdrawals and security modifications",
    "account.identity.choose_file": "Choose File",
    "account.identity.country": "Country/territory",
    "account.identity.errors.character_limit":
      "This field contains only {min} to {max} include numbers or characters.",
    "account.identity.errors.exist": "identity card already exists",
    "account.identity.errors.format_number":
      "This field contains only 9 to 12 include numbers or characters, does not contain special characters.",
    "account.identity.errors.maxsize": "Size image must be less than 10MB.",
    "account.identity.errors.required": "This field is required.",
    "account.identity.errors.fullName.required":
      "The fullname field is required.",
    "account.identity.errors.passportID.required":
      "The passport/ID number field is required.",
    "account.identity.errors.id_front.required":
      "The identity card back side/passport data page field is required.",
    "account.identity.errors.id_back.required":
      "The identity card back side/passport data page field is required.",
    "account.identity.errors.id_selfie.required":
      "The selfie with photo id and note field is required.",
    "account.identity.example": "Example",
    "account.identity.female": "Female",
    "account.identity.fullname": "Fullname",
    "account.identity.header_identity": "Personal",
    "account.identity.header_verification": "Verification Documents",
    "account.identity.identityID": "Valid Identity Card",
    "account.identity.identity_back":
      "Identity Card Back Side/Passport data page",
    "account.identity.identity_cover":
      "Identity Card Front Side/Passport cover",
    "account.identity.identity_front": "Identity Card Front Side",
    "account.identity.identity_image_back":
      "Please make sure that the photo is complete and clearly visible, in PNG or JPG format. Id card must be in the valid period.",
    "account.identity.identity_valid_card": "Valid Identity Card",
    "account.identity.image_identity":
      "If you do not have a passport, please upload a photo of the front of your Driver's License or National ID document.",
    "account.identity.image_passport":
      "Images must be submitted in PNG or JPG format.Please ensure that the subject of the photo is complete and clearly visible.If you do not have a passport, please upload a photo of the front of your Driver's License or National ID document.",
    "account.identity.image_passport_data":
      "Images must be submitted in PNG or JPG format.Please ensure that the subject of the photo is complete and clearly visible. Please make sure the photo is complete and clearly visible, in PNG or JPG format. Identity card must be in the valid period.",
    "account.identity.male": "Male",
    "account.identity.passport": "Passport/ID Number",
    "account.identity.selfie": "Selfie With Photo ID And Note",
    "account.identity.sex": "Gender",
    "account.identity.submit": "Submit",
    "account.identity.success": "Create success",
    "account.identity.update_success": "Update success",
    "account.identity.sure_back":
      "Please make sure that the photo is complete and clearly visible, in PNG or JPG format. Id card must be in the valid period.",
    "account.identity.sure_front":
      "If you do not have a passport, please upload a photo of the front of your Driver's License or National ID document.",
    "account.identity.text_condition":
      "The uploaded images should clearly show the face and text information.",
    "account.identity.text_date": "Note with today's date",
    "account.identity.text_face": "Face clearly visible",
    "account.identity.text_image": "Photo ID clearly visible",
    "account.identity.text_selfie":
      "Please provide a photo of you holding your Identity Card front side. In the same picture, make a reference to {APP_NAME} and today's date displayed. Make sure your face is clearly visible and that all passport details are clearly readable.",
    "account.identity.text_write": 'Note with word "{APP_NAME}"',
    "account.identity.verification_other":
      "This verification option applies to users with ID from all countries and territories outside mainland VietNam.You must use your real identity during this verification process. Your personal information will be securely protected.The following forms of government-issued ID can be used:",
    "account.identity.verification_other1": "<span>1.</span>Passport",
    "account.identity.verification_other2": "<span>2.</span>Driver's license",
    "account.identity.verification_other3":
      "<span>3.</span>Government-issued ID Card",
    "account.invalid_device":
      "It appears that you may be signing in from a device we are unfamiliar with or one that you have not used for a long period of time. For your security, a confirmation email has been sent to your email address.",
    "account.ip": "IP",
    "account.ip_address": "IP Address",
    "account.last_login": "Last login",
    "account.last_login_time": "Last login Time",
    "account.line": "Line",
    "account.line.desc":
      "Enable your account to receive notification for Login, Withdrawal, Deposit.",
    "account.location": "Location",
    "account.login": "Login Password",
    "account.lv": "Lv",
    "account.modal.agreement_otp.policy_content_text1": "1.",
    "account.modal.agreement_otp.policy_content_text2":
      "Email Subject: Request stop Google Authenticator",
    "account.modal.agreement_otp.policy_content_text3": "2. Email body",
    "account.modal.agreement_otp.policy_content_text4": "- Real name",
    "account.modal.agreement_otp.policy_content_text5":
      "- Your mobile phone number",
    "account.modal.agreement_otp.policy_content_text6":
      "- E-mail when you join {APP_NAME}",
    "account.modal.agreement_otp.policy_content_text7": "3. Attachments",
    "account.modal.agreement_otp.policy_content_text8":
      "- Identity card front side",
    "account.modal.agreement_otp.policy_content_text9":
      "- Identity card back side",
    "account.modal.agreement_otp.policy_content_text10":
      "- Selfie with identity card and a note",
    "account.modal.agreement_otp.policy_content_text11":
      "Please make sure that the photo is complete and clearly visible.",
    "account.modal.agreement_otp.policy_content_text12":
      "Please provide a photo of you holding your Identity Card front side.",
    "account.modal.agreement_otp.policy_content_text13":
      "In the same picture, make a reference to bit-Castle and today’s date displayed.",
    "account.modal.agreement_otp.policy_content_text14":
      "Make sure your face is clearly visible and that all passport details are clearly readable.",
    "account.modal.agreement_otp.policy_content":
      "If you also lost your recovery code and can not stop using Google Authenticator, Please send us your request email to {contact_email}",
    "account.modal.agreement_otp.title": "Lost your recovery code too?",
    "account.modal.cancel_2fa.guide":
      '<p class="font-18">To stop using 2 factor authentication (OTP), you have to enter the 6 digits verification number from the Google Authenticator app.</p><p class="font-18">If you lost your phone or uninstalled the app, you can alternatively enter the 16 character recovery code in the input below to disable it.</p>',
    "account.modal.cancel_2fa.input_blank":
      "You need to enter Google Authentication OTP code",
    "account.modal.cancel_2fa.otp": "OTP",
    "account.modal.cancel_2fa.otp_code": "Authorization number",
    "account.modal.cancel_2fa.otp_policy": "Lost your recovery code too?",
    "account.modal.cancel_2fa.otp_restore": "Recovery code",
    "account.modal.cancel_2fa.stop_use_otp": "Stop using OTP",
    "account.modal.cancel_2fa.success": "Deactivated OTP successfully!",
    "account.modal.cancel_2fa.title": "Disable Google Authentication",
    "account.modal.confirm": '<span class="font-18">Confirm</span>',
    "account.modal.confirm_trusted_device":
      "Do you want to delete the selected device?",
    "reward.modal.confirm_remove_reward": "Do you want to delete this reward?",
    "account.modal.date_time_pick.text1": "Date Range:",
    "account.modal.date_time_pick.text2": "Last 24 hours",
    "account.modal.date_time_pick.text3": "Yesterday",
    "account.modal.date_time_pick.text4": "Last 2 weeks",
    "account.modal.date_time_pick.text5": "Month to date",
    "account.modal.date_time_pick.text6": "Past month",
    "account.modal.date_time_pick.text7": "Past 3 months",
    "account.modal.date_time_pick.text8": "Custom (max range 3 months)",
    "account.modal.date_time_pick.text9": "Export",
    "account.modal.date_time_pick.title": "Export Commission History",
    "account.modal.lost_otp.guide":
      "To stop using 2 factor authentication (OTP), you have to enter your recovery code.",
    "account.modal.lost_otp.otp": "OTP",
    "account.modal.lost_otp.otp_code": "Authorization number",
    "account.modal.lost_otp.otp_policy": "Lost your recovery code too?",
    "account.modal.lost_otp.otp_restore": "Recovery code",
    "account.modal.lost_otp.stop_use_otp": "Stop using OTP",
    "account.modal.lost_otp.title": "Stop using OTP",
    "account.ni_bank": "NI Bank Account",
    "account.no_commission_history": "You have not commission history.",
    "account.no_referral_history": "You have not referral history.",
    "account.otp.authentication_failed": "Authentication failed.",
    "account.otp.back": "Back Step",
    "account.otp.backup_text1":
      "Resetting your Google Authentication requires opening a support ticket and takes at least 7 days to process.",
    "account.otp.backup_text2":
      "Please save this Key on paper. This Key will allow you to recover your Google Authentication in case of phone loss.",
    "account.otp.backup_title": "Step 3. Save Backup Recovery Code",
    "account.otp.enable_des": "Enable Google Authentication",
    "account.otp.enable_step1": "1. Download App",
    "account.otp.enable_step2": "2. Scan QR Code",
    "account.otp.enable_step3": "3. Backup Recovery Code",
    "account.otp.enable_step4": "4. Enable Google Authentication",
    "account.otp.enable_text1": "I have installed the app",
    "account.otp.enable_text2": "I have scanned the QR code",
    "account.otp.enable_text3": "I have already written down the 16-Digit Key",
    "account.otp.enable_title": "Step 4. Enable Google Authentication",
    "account.otp.install_text":
      "Download and install the Google Authentication app.",
    "account.otp.install_title": "Step 1. Download App",
    "account.otp.next": "Next step",
    "account.otp.qr_text":
      "If you are unable to scan the QR code, please enter this code manually into the app.",
    "account.otp.qt_title": "Step 2. Scan QR Code",
    "account.otp.success": "Activate OTP successfully!",
    "account.otp.txt_pass": "Login Password",
    "account.otp.should_enable_first":
      "Please enable Google Authentication first.",
    "account.phone_number_in_use":
      "This phone number is already associated with a {APP_NAME} account.",
    "account.recent_activity": "Recent Activity",
    "account.referral_code": "Your referral code:",
    "account.referral_notification":
      "Get up to 20% commission when referring friends to {APP_NAME}. Share this link: ",
    "account.referral_program": "Referral Program",
    "account.submit": "Submit Verification Documents",
    "account.telegram": "Telegram",
    "account.telegram.desc":
      "Enable your account to receive notification for Login, Withdrawal, Deposit.",
    "account.change_verify_kyc": "Change Verification Documents",
    "account.title_device":
      "These devices are currently allowed to access your account",
    "account.tooltip1":
      "When the function is turned on, your account will only be able to withdraw to whitelisted withdrawal addresses.",
    "account.tooltip2":
      "When the function is turned off, your account is able to withdraw to any withdrawal address.",
    "account.turn_off_mode_whitelist":
      "After turning off this function, you will be able to withdraw to any withdrawal address. Do you want to turn it off?",
    "account.turn_on_mode_whitelist":
      "After turning on this function, you will only be able to withdraw to whitelisted withdrawal address. Do you want to turn it on?",
    "account.white_list": "Whitelist",
    "account.white_list_off": "Whitelist Off",
    "account.white_list_on": "Whitelist On",
    "account.withdrawal_address": "Withdrawal Address Management",
    "account.inactive": "Please verify your email to active account.",
    "address.add_address": "Withdrawal Address Management",
    "address.add_success": "Created successfully!",
    "address.address": "Address",
    "address.all": "All",
    "address.coin": "Coin",
    "address.coin_name": "Coin Name",
    "address.confirm_remove_address": "Do you want to delete it?",
    "instrument.confirm_remove": "Do you want to confirm this instrument?",
    "address.date": "Date",
    "address.delete": "Delete",
    "address.deleted_success": "Deleted successfully!",
    "address.errors.address_required": "The address field is required.",
    "address.errors.blockchain_address": "The withdrawal address is not valid.",
    "address.errors.coin_required": "The coin field is required.",
    "address.errors.exist_withdraw_address": "This address already exists.",
    "address.errors.label_required": "The label field is required.",
    "address.errors.max_length":
      "The {field} must not exceed {amount} characters.",
    "address.errors.min_length":
      "The {field} must not less than {amount} characters.",
    "address.errors.tag_required": "The destination tag field is required.",
    "address.label": "Label",
    "address.label_hint": "The label must not exceed 20 characters",
    "address.memo": "Memo/Tag/...",
    "address.none": "None",
    "address.not_whitelisted": "Not Whitelisted",
    "address.only_dis": "Only display whitelisted addresses",
    "address.remove_whitelist": "Remove from Whitelist",
    "address.select_coin": "Select coin",
    "address.submit": "Submit",
    "address.tag": "Tag",
    "address.tooltip1":
      "When the function is turned on, your account will only be able to withdraw to whitelisted withdrawal addresses.",
    "address.tooltip2":
      "When the function is turned off, your account is able to withdraw to any withdrawal address.",
    "address.turn_off_mode_whitelist":
      "After turning off this function, you will be able to withdraw to any withdrawal address. Do you want to turn it off?",
    "address.turn_on_mode_whitelist":
      "After turning on this function, you will only be able to withdraw to whitelisted withdrawal address. Do you want to turn it on?",
    "address.turn_on_whitelist":
      "Please turn on withdrawal whilelist function first.",
    "address.update_white_list":
      "Please turn on withdrawal whitelist function first.",
    "address.updated_success": "Updated successfully!",
    "address.white_list_off": "Whitelist Off",
    "address.white_list_on": "Whitelist On",
    "address.whitelisted": "Whitelisted",
    "address.withdrawal_address": "Withdrawal Address Management",
    "address.table.empty_msg": "You have no withdrawal address",
    "admin.role": "Role",
    "admin.deposit_approved":
      "Deposit success {amountUSD}\n{time}\n\n{APP_NAME}",
    "admin.deposit_rejected":
      "Deposit failed {amountUSD}\n{time}\nInformation mismatch\n{APP_NAME}",
    "admin.new_deposit": "New deposit {amountUSD}\n{time}\n\n{APP_NAME}",
    "admin.new_withdrawal": "New withdrawal {amountUSD}\n{time}\n\n{APP_NAME}",
    "admin.withdraw_approved":
      "Withdrawal success {amountUSD} {bankName} bank {bankAccount}\n{time}\n\n{APP_NAME}",
    "admin.withdraw_status_pending": "Pending",
    "admin.withdraw_status_success": "Success",
    "admin.change_password-success": "The admin password was changed.",
    "admin.login_again": "Please login again!",
    "aml.setting.titlecheckbox": "Display on web",
    approval_pending: "Approval Pending",
    "approval_pending.amount": "Amount",
    "approval_pending.approval_request": "Approval Request",
    "approval_pending.created_date": "Created Date",
    "approval_pending.currency": "Currency",
    "approval_pending.detail": "DETAIL",
    "approval_pending.title": "Approval Pending",
    "approval_pending.transaction_id": "Transaction ID",
    "approval_pending.user_name": "Email",
    "approval_pending.view": "VIEW",
    approval_request: "Approval Request",
    approved_at: "Approved at",
    approved_by: "Approved by",
    "approved_order.amount": "Amount",
    "approved_order.btn_detail": "DETAIL",
    "approved_order.btn_search": "Search",
    "approved_order.btn_view": "VIEW",
    "approved_order.created_date": "Created Date",
    "approved_order.currency": "Currency",
    "approved_order.heading_title": "Approved Transactions",
    "approved_order.no": "No",
    "approved_order.transaction_id": "Transaction ID",
    "approved_order.user_name": "Email",
    "approved_order.withdraw_approval": "Withdrawal approver",
    approved_transaction: "Approved Transaction",
    "approved_transaction_page.approved_date": "Approved Date",
    "approved_transaction_page.administrator_information":
      "Administrator information",
    "approved_transaction_page.withdrawal_currency": "Withdraw currency",
    "approved_transaction_page.withdrawal_address": "Withdraw address",
    "approved_transaction_page.country": "Country",
    "approved_transaction_page.created_date": "Created Date",
    "approved_transaction_page.email": "Email",
    "approved_transaction_page.header_title":
      "[Detail] Transaction: {transaction_id}",
    "approved_transaction_page.quantity": "Amount",
    "approved_transaction_page.transaction_id": "Transaction ID",
    "approved_transaction_page.transaction_detail": "Transaction detail",
    "approved_transaction_page.customer_information": "Customer information",
    "approved_transaction_page.transaction_hash": "Transaction hash",
    "approved_transaction_page.remark": "Remarks",
    approved_transactions: "Approved transactions",
    "auth.confirmation_email_subject":
      "【Exchange】Activate your account on :APP_NAME",
    "auth.email_confirm_success": "Your email has been confirmed.",
    "auth.email_confirmation_code_invalid": "Activation code is invalid.",
    "auth.failed": "These credentials do not match our records.",
    "auth.forgot_password": "Forgot password?",
    "auth.otp_code_invalid": "Google Authentication code is invalid.",
    "auth.otp_code_required":
      "The Google Authentication code field is required.",
    "auth.otp_not_used": "Please wait for next verification code to generate.",
    "auth.send_mail_failed": "Failed to send email, please try again.",
    "auth.throttle":
      "Too many login attempts. Please try again in {seconds} seconds.",
    "bank_setting.bank_setting": "Bank Setting",
    "bank_setting.bank_name": "Bank name",
    "bank_setting.bank_branch": "Bank branch",
    "bank_setting.account_name": "Account name",
    "bank_setting.account_no": "Account no",
    "bank_setting.edit": "EDIT",
    "bank_setting.return": "RETURN",
    "bank_setting.save": "SAVE",
    "bank_setting.error.lose_bank_name": "The bank name field is required.",
    "bank_setting.error.lose_bank_branch": "The bank branch field is required.",
    "bank_setting.error.lose_account_name":
      "The account name field is required.",
    "bank_setting.error.lose_account_no": "The account no field is required.",
    "bank_setting.update_success_msg": "Updated successfully!",
    base_deposit_withdraw_title_time: "Time",
    base_deposit_withdraw_title_email: "Email",
    base_deposit_withdraw_title_transactionId: "Transaction Id",
    base_deposit_withdraw_title_currency: "Currency",
    base_deposit_withdraw_title_amount: "Amount/Fee",
    base_deposit_withdraw_title_status: "Status",
    base_deposit_withdraw_title_collect: "Collect",

    "change_password_form.confirm":
      "For security purposes, no withdrawals are permitted for 24 hours after modification of security methods.",
    "change_password_form.confirm_new_password": "Confirm Password",
    "change_password_form.error_confirm":
      "The new password confirmation does not match.",
    "change_password_form.error_duplicated_password":
      "The new password must differ from your previous password.",
    "change_password_form.error_message": "Your password is not changed.",
    "change_password_form.new_password": "New Password",
    "change_password_form.old_password": "Old Password",
    "change_password_form.require_confirm": "Confirm new password is required",
    "change_password_form.require_new": "New password is required.",
    "change_password_form.require_old": "Old password is required.",
    "change_password_form.submit_button": "Submit",
    "change_password_form.submit_button_processing": "Processing...",
    "change_password_form.successful_message":
      "Your password has been changed successfully!",
    "change_password_form.title": "Change Admin Password",
    "change_password_form.title_confirm": "Confirm Password",
    "change_password_form.error_password": "The password is not correct!",
    "client_download.desktop": "Desktop",
    "client_download.destop_content":
      "Our desktop clients are tailor-made to provide a smooth and efficient trading experience.",
    "client_download.more_way": "More ways to get {APP_NAME}",
    "client_download.provide_tool":
      "We're provided some tools you need for trading on the go",
    "client_download.sp_download": "Smartphone apps",
    "client_download.track": "Track and manage trades wherever you are!",
    "common.all": "All",
    "common.refresh": "Refresh",
    "common.action.send": "Send",
    "common.action.refund": "OK",
    "common.action.send_email": "Send Email",
    "common.action.cancel": "Cancel",
    "common.action.no": "No",
    "common.action.ok": "OK",
    "common.action.yes": "Yes",
    "common.action.return": "Return",
    "common.action.save": "Save",
    "common.action.edit": "Edit",
    "common.action.enable": "Enable",
    "common.action.disable": "Disable",
    "common.action.waiting": "Waiting",
    "common.action.close": "Close",
    "common.announcements": "Announcements",
    "common.begin.trading": "Start Trading Now!",
    "common.confirm": "Confirm",
    "common.create_account_amanpuri": "Create an account with the Exchange",
    "common.datatable.data_empty": "Empty Data.",
    "common.datatable.empty_data": "Data empty.",
    "common.datatable.no_data": "You have no data.",
    "common.datatable.no_data2": "No result found.",
    "common.exchange.now": "Exchange Now",
    "common.error.required": "The {field} field is required.",
    "common.error.required_airdrop": "The {field} is required.",
    "common.margin_exchange": "Margin Exchange",
    "common.margin_exchange.desc":
      "Up to 100x leverage. Trading without expiry dates. Industry-leading security. Welcome to Bitcoin's most advanced trading platform.",
    "common.margin_exchange.title":
      "A high-leverage contract that never expires!",
    "common.max": "Max",
    "common.market_info": "Market Information",
    "common.mobile_app": "Mobile app",
    "common.mobile_app.desc":
      "Smartphone apps. Track and manage trades wherever you are!",
    "common.mobile_app.title": "Make and track your transactions on the go!",
    "common.no_select": "None",
    "common.chose_select": "Choose",
    "common.order_status.canceled": "Canceled",
    "common.order_status.filled": "Filled",
    "common.order_status.pending": "Pending",
    "common.order_status.stopping": "Stopping",
    "common.order_status.open": "Open",
    "common.order_status.untriggered": "Untriggered",
    "common.order_status.executed": "Executed",
    "common.order_status.executing": "Executing",
    "common.order_status.removed": "Removed",
    "common.order_status.expired": "Expired",
    "common.order_status.partial_filled": "Partial Filled",
    "common.order_status.new": "New",
    "common.order_type.limit": "Limit",
    "common.order_type.market": "Market",
    "common.order_type.stop_limit": "Stop Limit",
    "common.order_type.stop_market": "Stop Market",
    "common.order_type.take_profit_market": "Take Profit Market",
    "common.order_type.take_profit_limit": "Take Profit Limit",
    "common.order_type.oco": "OCO",
    "common.order_type.ifd": "IFD",
    "common.order_type.trailing_stop": "Trailing Stop",
    "common.order_type.liquidation": "Liquidation",
    "common.order_type.post_only": "Post Only",
    "common.placeholders.email": "Email",
    "common.status.expired": "Expired",
    "common.placeholders.login_password": "Login Password",
    "common.placeholders.otp": "Google Authentication Code",
    "common.placeholders.password": "Password",
    "common.placeholders.referral": "Referral ID (optional)",
    "common.placeholders.repeat_password": "Confirm Password",
    "common.placeholders.confirm_password": "Confirm Password",
    "common.processing": "Processing...",
    "common.sales_point": "Sales Point",
    "common.sales_point.desc":
      "Transfer from one wallet to another within minutes*.Easy-to-use service with 130+ cryptocurrencies and live 24/7 support It's that simple.",
    "common.sales_point.title": "The fastest way to exchange crypto",
    "common.slogan_create_account": "Bitcoin trading safe and secure Exchange",
    "common.spot_exchange": "Spot Exchange",
    "common.future_exchange": "Future Exchange",
    "common.margin_exchange": "Margin Exchange",
    "common.spot_exchange.desc":
      "Transfer from one wallet to another within minutes*.Easy-to-use service with 130+ cryptocurrencies and live 24/7 support It's that simple.",
    "common.spot_exchange.title":
      "is the most advanced cryptocurrency exchange",
    "common.trade_type.all": "All",
    "common.trade_type.buy": "Buy",
    "common.trade_type.sell": "Sell",
    "common.transaction_status.cancel": "Canceled",
    "common.transaction_status.error": "Error",
    "common.transaction_status.pending": "Pending",
    "common.transaction_status.rejected": "Rejected",
    "common.transaction_status.success": "Success",
    "common.transaction_status.submitted": "Submitted",
    "common.transaction_type.internal": "Internal",
    "common.transaction_type.external": "External",
    "common.window_title": "{APP_NAME}",
    "common_action.cancel": "Cancel",
    "common_action.confirm": "Confirm",
    "common_action.ok": "Ok",
    "common.date.warning":
      "The date range is invalid. The end date is smaller than the start date!",
    "common.date.warning2": "The date range is invalid!",
    "common.alert.create.success": "Created successfully!",
    "common.alert.update.success": "Updated successfully!",
    "common.alert.delete.success": "Deleted successfully!",
    "common.Internet.connection.fail": "Internet connection fail!",
    "contact.contact_us": "Contact Us",
    "contact.got_question": "Got a Question?",
    "contact.message.sendEmailSuccess": "Email sent successfully!",
    "contact.non_account_related":
      "For non-account-related communications, join our online communities:",
    "contact.please": "Still can’t find what you’re looking for? Please",
    "contact.please_check":
      "Please first check the official {APP_NAME} FAQ to see if your question has already been answered",
    "contact.submit_request": "submit a request",
    "contact.view_suport": "view support documentation",
    "currency.fullname.variable": "currency.{currency_name}.fullname",
    "currency.ada.fullname": "Cardano",
    "currency.ada.shortname": "ADA",
    "currency.amal.fullname": "Exchange",
    "currency.amal.shortname": "AMAL",
    "currency.mgc.fullname": "Mango Coin",
    "currency.mgc.shortname": "MGC",
    "currency.bch.fullname": "Bitcoin Cash",
    "currency.bch.shortname": "BCH",
    "currency.btc.fullname": "Bitcoin",
    "currency.btc.shortname": "BTC",
    "currency.eos.fullname": "EOS",
    "currency.eos.shortname": "EOS",
    "currency.eth.fullname": "Ethereum",
    "currency.eth.shortname": "ETH",
    "currency.ltc.fullname": "LiteCoin",
    "currency.ltc.shortname": "LTC",
    "currency.usdt.fullname": "USDT",
    "currency.usdt.shortname": "USDT",
    "currency.tomo.fullname": "TomoChain",
    "currency.tomo.shortname": "TOMO",
    "currency.tusd.fullname": "TrueUSD",
    "currency.tusd.shortname": "TUSD",
    "currency.usd.fullname": "United States Dollar",
    "currency.usd.shortname": "USD",
    "currency.xlm.fullname": "Stellar",
    "currency.xlm.shortname": "XLM",
    "currency.xrp.fullname": "Ripple",
    "currency.xrp.shortname": "XRP",
    "denial_page.amount": "Amount",
    "denial_page.btn_detail": "DETAIL",
    "denial_page.btn_search": "Search",
    "denial_page.created_date": "Created Date",
    "denial_page.currency": "Currency",
    "denial_page.heading_title": "Rejected Transactions",
    "denial_page.no": "No",
    "denial_page.transaction_id": "Transaction ID",
    "denial_page.user_name": "Email",
    "emails.account": "Account",
    "emails.account_info":
      "bank: {bank}, account number: {accountNumber}, account holder: {name}",
    "emails.amount": "amount:",
    "emails.cannot_click_link":
      "If you can not click on the link, kindly copy and paste the address into the address bar. Thank you",
    "emails.confirm_email":
      "Click on the link below to activate your account. By verifying your email, we strives to provide customers with more convenient and safer cryptocurrency exchange service.",
    "emails.confirm_kyc": "Your KYC was",
    "emails.date": "Time: ",
    "emails.deposit_alerts.text1": "We received the loading order",
    "emails.deposit_alerts.text2": "from you.",
    "emails.deposit_alerts.text3":
      "This amount has been added to your balance at {APP_NAME}.",
    "emails.deposit_alerts.title": "【{APP_NAME}】Refill request has been made",
    "emails.unlock_airdrop_fail.text1": "We are sorry",
    "emails.unlock_airdrop_fail.text2":
      "This is to notify you that due to unlocking fail ",
    "emails.unlock_airdrop_fail.text3":
      "AMAL in your dividend balance , we have canceled it and try it on the next period",
    "emails.unlock_airdrop_fail.title":
      "【{APP_NAME}】Unlock Dividend has failed",
    "emails.deposit_approved_text1":
      "{email}, your deposit request has been approved.",
    "emails.deposit_approved_title": "【{APP_NAME}】 Deposit Completed",
    "emails.deposit_rejected_text1":
      "{email}, your deposit request has failed.",
    "emails.deposit_rejected_text2":
      "Please check the deposit rules, your account information, deposit code and apply again.",
    "emails.deposit_rejected_title": "【{APP_NAME}】 Deposit Failed",
    "emails.deposit_usd_alerts.account_name": "Account name",
    "emails.deposit_usd_alerts.account_no": "Account no",
    "emails.deposit_usd_alerts.amount": "Amount",
    "emails.deposit_usd_alerts.bank_branch": "Bank branch",
    "emails.deposit_usd_alerts.bank_name": "Bank name",
    "emails.deposit_usd_alerts.code": "Code",
    "emails.deposit_usd_alerts.text1":
      "The system has just received a request to check the user's recharge transaction",
    "emails.deposit_usd_alerts.text2": "Transaction information",
    "emails.deposit_usd_alerts.title": "New recharge request",
    "emails.unlock_airdrop_fail_alert.title": " Unlock Dividend has failed",
    "emails.unlock_airdrop_fail_alert.text1":
      "The system has been failed at unlocking AMAL in user's dividend wallet.",
    "emails.unlock_airdrop_fail_alert.text2": " Detail Unlocking",
    "emails.unlock_airdrop_fail_alert.email": "Email",
    "emails.unlock_airdrop_fail_alert.amount": "Amount",
    "emails.hello": "Hello,",
    "emails.kyc_rejected": "rejected",
    "emails.kyc_tilte": "【{APP_NAME}】Confirm KYC on",
    "emails.kyc_verified": "verified",
    "emails.new_deposit_amount": "Amount: ",
    "emails.new_deposit_text1": "We have received your deposit request.",
    "emails.new_deposit_title": "New Deposit Request",
    "emails.new_device.text1":
      "You recently attempted to sign into your {APP_NAME} account from a new device. As a security measure, we require additional confirmation before allowing access to your {APP_NAME} account",
    "emails.new_device.text2":
      "If this was legitimate activity, you can authorize your new device below",
    "emails.new_login.text1":
      "The system has detected that your account is logged in from an unused IP address",
    "emails.new_login.text2":
      "If this activity is not your own operation, please disable that device and contact us immediately",
    "emails.new_login.new_ip_title":
      "【Exchange】Warning: Successful Login From New IP",
    "emails.new_withdrawal_text1": "We have received your withdrawal request.",
    "emails.new_withdrawal_title": "New Withdrawal Request",
    "emails.no_reply": "This is an automated message. Please do not reply.",
    "emails.on": "on",
    "emails.say_hello": "Hello {email}?",
    "emails.team": "Team {APP_NAME}",
    "emails.team_address": "(CO) {APP_NAME} - street xxx",
    "emails.team_inc": "© {APP_NAME} Inc. All rights reserved.",
    "emails.team_inform": "will inform you",
    "emails.team_service_center":
      'Service center / email. <a href="mailto: {email} " target="_blank"> {email} </a> / tel. {phone}',
    "emails.team_slogan": "The best cryptocurrency trading",
    "emails.thankyou": "Thanks,",
    "emails.warning":
      "If this activity is not your own operation, please contact us immediately.",
    "emails.warning_title": "{APP_NAME} Security Warning",
    "emails.welcome": "Welcome to :APP_NAME",
    "emails.footer.the_best_cryptocurrency": "The best cryptocurrency trading",
    "emails.footer.service_center": "Service center",
    "emails.registed.confirmation_email_subject":
      "【Exchange】Activate your account on :APP_NAME",
    "emails.registed.click_on_link":
      "Registration has not been completed, please click below link to complete verification. By verifying your email, we strives to provide customers with more convenient and safer cryptocurrency exchange service.",
    "emails.registed.valid_24h": "The above link is valid for 24 hours.",
    "emails.registed.please_complete":
      "Please complete your account verification within 24 hours of receiving this e-mail.",
    "emails.registed.thank_you": "Thank you!",
    "emails.confirmation_reset_password.subject": "【Exchange】Reset Password",
    "emails.confirmation_reset_password.dear_account": "Dear ",
    "emails.confirmation_reset_password.receiving_text":
      "You are receiving this email because we received a reset password request for your account.",
    "emails.confirmation_reset_password.please_click":
      "Please click below link to reset your password.",
    "emails.confirmation_reset_password.valid_24h":
      "The link above is valid for 24 hours.",
    "emails.confirmation_reset_password.please_confirm":
      "Please complete your password changing within 24 hours of receiving this e-mail.",
    "emails.confirmation_reset_password.thank_you": "Thank you!",
    "emails.login_new_device.subject":
      "【Exchange】Warning: Authorize New Device",
    "emails.login_new_device.dear_account": "Dear ",
    "emails.login_new_device.attemped_access":
      "You recently attempted to sign into your Exchange exchange account from a new device. As a security measure, we require additional confirmation before allowing access to your Exchange account ",
    "emails.login_new_device.email": "Email",
    "emails.login_new_device.device": "Device ",
    "emails.login_new_device.time": "Time ",
    "emails.login_new_device.ip": "IP Address ",
    "emails.login_new_device.legitimate_activity":
      "If this was legitimate activity, you can authorize your new device below ",
    "emails.login_new_device.team": " Team",
    "emails.login_new_device.thank_you": "Thank you!",
    "emails.confirm_kyc.dear_name": "Dear Mr./Ms ",
    "emails.confirm_kyc.subject": "【Exchange】KYC verification :status",
    "emails.confirm_kyc.title_header": "【Exchange】:status your KYC",
    "emails.confirm_kyc.line_1": "It is Exchange support.",
    "emails.confirm_kyc.line_2": "Thank you for waiting.",
    "emails.confirm_kyc.line_3":
      "Since the verification of identity confirmation has done, so please log in to user page from here and check the result of the examination.",
    "emails.confirm_kyc.line_4":
      "If the message implying that you need to submit an identification document is not listed at the top of my page, verification of your identity has been successfully completed.",
    "emails.confirm_kyc.line_5":
      "If the message is on the top of my page, it is not approved due to inappropriate submission or an image error, so please check the identity confirmation document once again and resubmit it.",
    "emails.confirm_kyc.line_6":
      "We can not tell you why the submitted documents were inadequate, but reasons for becoming inadequate mainly will be below, so please confirm whether the photo you submitted once is not applicable to the following.",
    "emails.confirm_kyc.line_7":
      "Personal identification document is not specified",
    "emails.confirm_kyc.line_8":
      "Your face on the identification document and selfie photo is different",
    "emails.confirm_kyc.line_9":
      "Documents submitted to ID SERPHY, Exchange name, date, the person himself is not shown together",
    "emails.confirm_kyc.line_10": "Not facing the front",
    "emails.confirm_kyc.line_11":
      '"Whole face" is not shown on the selfie photo (the entire head is not shown)',
    "emails.confirm_kyc.line_12": "The image is dark and the face is not clear",
    "emails.confirm_kyc.line_13": "Image upload failed",
    "emails.confirm_kyc.line_14":
      "I'm sorry for troubling. We appreciate your cooperation.",
    "emails.contact.subject": "【Exchange】Contact Us",
    "emails.contact.dear_account": "Dear ",
    "emails.contact.line_1": "Thank you for contacting us.",
    "emails.contact.line_2":
      "We will contact you again from the person in charge.",
    "emails.received_verify_document.dear_account": "Dear ",
    "emails.received_verify_document.subject":
      "【Exchange】Received your identification verification document",
    "emails.received_verify_document.line_1":
      "Thank you for your continued support of Exchange.",
    "emails.received_verify_document.line_2":
      "We have received your identity verification document.",
    "emails.received_verify_document.line_3":
      "We will review the identity verification document in order of submitted documents.",
    "emails.received_verify_document.line_4":
      "Please kindly wait until confirmation.",
    "emails.received_verify_document.line_5":
      "Additional time may be required depending on the application status. We thank you for your understanding.",
    "emails.received_verify_document.line_6":
      "We ask for your continued support of Exchange.",
    "emails.received_liquid_position.subject":
      "【Exchange】Liquidation Notice: Your {symbol} position has been liquidated",
    "emails.liquid_position_line_1":
      "Your short {symbol} position of {current_qty} contracts has been liquidated.",
    "emails.liquid_position_line_2":
      "The Mark Price of {symbol} recently rose to {mark_price}. Your position had a liquidation price of {liquidation_price}. The position has been taken over by the Liquidation Engine.",
    "emails.liquid_position_line_3": "{symbol} Position Details",
    "emails.liquid_position_line_4":
      "You were short {current_qty}  contracts of XBTUSD at {leverage} leverage.",
    "emails.liquid_position_line_5":
      "With {maint_margin}% Maintenance Margin, your liquidation price was {liquidation_price} and your bankruptcy price was {bankrupt_price} (i.e. 0% margin).",
    "emails.liquid_position_line_6":
      "At the time of liquidation {date}, the price of the .{symbol} Index rose to {index_price}. The contract you were trading, {symbol}, had a Mark Price of {mark_price}, which is based on this index.",
    "emails.liquid_position.side": "Side",
    "emails.liquid_position.qty": "Qty",
    "emails.liquid_position.lev": "Lev.",
    "emails.liquid_position.mark_price": "Mark Price",
    "emails.liquid_position.liq_price": "Liq. Price",
    "emails.received_verify_document.line_7":
      "This e-mail has been sent from our send-only e-mail address and cannot be replied to.",
    "emails.withdraw_verify.subject": "【Exchange】Confirm Withdraw",
    "emails.withdraw_verify.line_1": "Dear ",
    "emails.withdraw_verify.line_2": "You have a following withdrawal request:",
    "emails.withdraw_verify.line_3": "Currency",
    "emails.withdraw_verify.line_4": "Quantity",
    "emails.withdraw_verify.line_5": "Withdrawal address",
    "emails.withdraw_verify.line_6": "Created date",
    "emails.withdraw_verify.line_7":
      "Please click bellow link to confirm this transaction.",
    "emails.withdraw_verify.line_8": "Thank you!",
    "emails.withdraw_alerts.subject": "【Exchange】Confirm Withdraw",
    "emails.withdraw_alerts.line_1": "Dear ",
    "emails.withdraw_alerts.line_2":
      "The following withdrawal request was approved.",
    "emails.withdraw_alerts.line_3": "Currency",
    "emails.withdraw_alerts.line_4": "Quantity",
    "emails.withdraw_alerts.line_5": "Withdrawal address",
    "emails.withdraw_alerts.line_6": "Created date",
    "emails.withdraw_alerts.line_7":
      "We will move to withdrawal processing from this.",
    "emails.withdraw_alerts.line_8":
      "Thank you for your continued support of Exchange Exchange.",
    "emails.withdraw_alerts.line_9":
      "This e-mail has been sent from our send-only email address.",
    "emails.withdraw_alerts.line_10":
      "Therefore, we cannot identify the content even if you reply. ",
    "emails.withdraw_alerts.line_11": "Thank you!",
    "emails.withdraw_errors_alerts.subject": "【Exchange】Rejected Withdraw",
    "emails.withdraw_errors_alerts.line_1": "Hello ",
    "emails.withdraw_errors_alerts.line_2":
      "The following withdrawal request was rejected.",
    "emails.withdraw_errors_alerts.line_3": "Currency",
    "emails.withdraw_errors_alerts.line_4": "Quantity",
    "emails.withdraw_errors_alerts.line_5": "Withdrawal address",
    "emails.withdraw_errors_alerts.line_6": "Created date",
    "emails.withdraw_errors_alerts.line_7":
      "There was some unknow errors lead to we have to reject your request.",
    "emails.withdraw_errors_alerts.line_8":
      "Please check your request carefully and submit again.",
    "emails.withdraw_errors_alerts.line_9": "Thank you!",
    "emails.withdrawal_canceled.subject": "【Exchange】Withdrawal Canceled ",
    "emails.withdrawal_canceled.dear_name": "Dear <strong>:email</strong>,",
    "emails.withdrawal_canceled.line_1":
      "Your bellow withdrawal request has been canceled by Exchange system.",
    "emails.withdrawal_canceled.line_2": "Currency",
    "emails.withdrawal_canceled.line_3": "Quantity",
    "emails.withdrawal_canceled.line_4": "Withdrawal address",
    "emails.withdrawal_canceled.line_5": "Created date",
    "emails.withdrawal_canceled.line_6":
      "There are two cases to cancel your request.",
    "emails.withdrawal_canceled.line_7":
      "Case 1: Admin cancel your request by some reasons.",
    "emails.withdrawal_canceled.line_8": "Case 2: Your request has expired.",
    "emails.withdrawal_canceled.line_9": "Thank you!",
    "emails.ban_account.subject": "【Exchange】Account locked",
    "emails.ban_account.dear_name": "Dear <strong>:email</strong>,",
    "emails.ban_account.line_1":
      "Your account was locked by Exchange Exchange Administrator.",
    "emails.ban_account.line_2": "You will cannot login at this time.",
    "emails.ban_account.line_3":
      "To unlock account, please contact us by this link:",
    "emails.withdraw_alerts.text1": "Request withdrawal",
    "emails.withdraw_alerts.text2":
      "from the {APP_NAME} account has been successfully implemented.",
    "emails.withdraw_alerts.text3":
      "You can track its confirmation status here",
    "emails.withdraw_alerts.title":
      "【{APP_NAME}】Request for withdrawal successfully!",
    "emails.withdraw_errors_alerts.text1":
      "Your transaction request withdrawal",
    "emails.withdraw_errors_alerts.text2":
      " has just failed. Do not worry, your case is being processed. Please wait for feedback from us.",
    "emails.withdraw_errors_alerts.title": "【{APP_NAME}】Withdrawal error",
    "emails.withdraw_usd_alerts.account_name": "Account name",
    "emails.withdraw_usd_alerts.account_no": "ccount no",
    "emails.withdraw_usd_alerts.amount": "Amount",
    "emails.withdraw_usd_alerts.bank_branch": "Bank branch",
    "emails.withdraw_usd_alerts.bank_name": "Bank name",
    "emails.withdraw_usd_alerts.text1":
      "The system has just received a request for a user's withdrawal",
    "emails.withdraw_usd_alerts.text2": "Transaction information",
    "emails.withdraw_usd_alerts.title": "Request a new withdrawal",
    "emails.withdrawal_approved_text1":
      "{email}, your withdrawal request has been processed.",
    "emails.withdrawal_approved_title": "【{APP_NAME}】 Withdrawal Completed",
    "emails.deposit_withdraw_usd_alerts.hello": "Hello <strong>:email</strong>",
    "emails.deposit_withdraw_usd_alerts.time": "Time",
    "emails.deposit_withdraw_usd_alerts.deposit.approved.title":
      "【Exchange】Deposit Completed",
    "emails.deposit_withdraw_usd_alerts.deposit.approved.line_1":
      "Your deposit request has completed.",
    "emails.deposit_withdraw_usd_alerts.deposit.approved.line_2":
      "Please login to check your balance.",
    "emails.deposit_withdraw_usd_alerts.deposit.approved.amount":
      "Deposit amount",
    "emails.deposit_withdraw_usd_alerts.deposit.rejected.title":
      "【Exchange】Deposit Failed",
    "emails.deposit_withdraw_usd_alerts.deposit.rejected.line_1":
      "Your deposit request has failed.",
    "emails.deposit_withdraw_usd_alerts.deposit.rejected.line_2":
      "Please check the deposit rules, your account information, deposit code and try again.",
    "emails.deposit_withdraw_usd_alerts.deposit.rejected.amount":
      "Deposit amount",
    "emails.deposit_withdraw_usd_alerts.withdrawal.approved.title":
      "【Exchange】Withdrawal Completed",
    "emails.deposit_withdraw_usd_alerts.withdrawal.approved.line_1":
      "Your withdrawal request has completed.",
    "emails.deposit_withdraw_usd_alerts.withdrawal.approved.amount":
      "Withdrawal amount",
    "emails.deposit_withdraw_usd_alerts.withdrawal.approved.account_bank":
      "Account bank",
    "emails.deposit_withdraw_usd_alerts.withdrawal.approved.account_number":
      "Account number",
    "emails.deposit_withdraw_usd_alerts.withdrawal.approved.account_holder":
      "Account holder",
    "emails.deposit_withdraw_usd_alerts.withdrawal.rejected.title":
      "【Exchange】Withdrawal Failed",
    "emails.deposit_withdraw_usd_alerts.withdrawal.rejected.line_1":
      "Your withdrawal request has failed.",
    "emails.deposit_withdraw_usd_alerts.withdrawal.rejected.line_2":
      "Please check the withdrawal rules, your account information and try again.",
    "emails.deposit_withdraw_usd_alerts.withdrawal.rejected.amount":
      "Withdrawal amount",
    "emails.deposit_withdraw_usd_alerts.withdrawal.rejected.account_bank":
      "Account bank",
    "emails.deposit_withdraw_usd_alerts.withdrawal.rejected.account_number":
      "Account number",
    "emails.deposit_withdraw_usd_alerts.withdrawal.rejected.account_holder":
      "Account holder",
    "emails.change_coldWallet_setting.subject":
      "【Exchange】Change cold wallet setting completed",
    "emails.change_coldWallet_setting.line_1":
      "You received this email because the cold wallet setting was changed.",
    "emails.change_coldWallet_setting.line_2": "Detail changed setting:",
    "emails.change_coldWallet_setting.line_3":
      "{coin} address is changed from {oldAddress} to {newAddress}",
    "emails.change_coldWallet_setting.line_4":
      "Cold wallet holder email is changed from {oldEmail} to {newEmail}",
    "emails.register_completed.subject": "【Exchange】Register completed",
    "emails.register_completed.hello": "Hi <strong>{email}</strong>,",
    "emails.register_completed.line_1":
      "Congratulation, your registration is completed.",
    "emails.register_completed.line_2":
      "Please login {APP_NAME} to start trading.",
    "beta_tester.add_beta_tester": "Add Beta Tester",
    "beta_tester.is_tester": "Is Tester",
    "beta_tester.active_beta_tester_success": "Active Beta Testers successful!",
    "beta_tester.active_beta_tester_fail": "Active Beta Testers fail!",
    "beta_tester.active": "active",
    "beta_tester.inactive": "inactive",
    "beta_tester.waiting": "waiting",
    "exchange_balance_detail.title": "Exchange Balance Detail",
    "exchange_balance_detail.daily": "Daily",
    "exchange_balance_detail.hour": "Hour",
    "exchange_balance_detail.weekly": "Weekly",
    "exchange_balance_detail.monthly": "Monthly",
    "exchange_balance_detail.yearly": "Yearly",
    "exchange_balance_detail.search_place_holder": "Search by format {0}",
    "exchange_balance_detail.data_table.title.from": "From",
    "exchange_balance_detail.data_table.title.to": "To",
    "exchange_balance_detail.data_table.search_message.required":
      "Search by {0} is required !",
    "exchange_balance_detail.data_table.search_message.isNotValid":
      "Search by {0} isn't valid !",
    "exchange.basic.chart.btn_add_chart": "Add Chart",
    "exchange.basic.chart.buy_order": "Buy orders",
    "exchange.basic.chart.candle": "Candlesticks",
    "exchange.basic.chart.depth": "Depth",
    "exchange.basic.chart.msg_close_chart": "Do you want to close this chart ?",
    "exchange.basic.chart.price": "Price",
    "exchange.basic.chart.sell_order": "Sell orders",
    "exchange.basic.label_hour": "Hour",
    "exchange.basic.label_minute": "Minute",
    "exchange.basic.time.hour": "{number} hour",
    "exchange.basic.time.hours": "{number} hours",
    "exchange.basic.time.minute": "{number} minute",
    "exchange.basic.time.minutes": "{number} minutes",
    "exchange.basic.time.one_day": "1 day",
    "exchange.basic.time.one_month": "1 month",
    "exchange.basic.time.one_week": "1 week",
    "exchange.basic.trading_page.change_24h": "24h Change",
    "exchange.basic.trading_page.confirm": "Confirm",
    "exchange.basic.trading_page.evaluation_amount": "Evaluation",
    "exchange.basic.trading_page.high": "High",
    "exchange.basic.trading_page.high_24h": "24h High",
    "exchange.basic.trading_page.holding_balance": "Balance",
    "exchange.basic.trading_page.last_price": "Last Price",
    "exchange.basic.trading_page.low": "Low",
    "exchange.basic.trading_page.low_24h": "24h Low",
    "exchange.basic.trading_page.total_purchase_amount": "Purchased",
    "exchange.basic.trading_page.volume": "Volume",
    "exchange.basic.trading_page.volume_24h": "24h Volume",
    "exchange.basic.trading_page.yeild": "Yeild",
    "exchange.basic.trading_page.yeild_tooltip.row1":
      "1. Based on USD converted value.",
    "exchange.basic.trading_page.yeild_tooltip.row2":
      "2. BTC, ETH markets are also converted to USD value.",
    "exchange.basic.trading_page.yeild_tooltip.row3":
      "3. In the case of deposits and withdrawals, deposits are assumed to be 'buy' and withdrawals are assumed to be 'sells' based on the current USD at the time of deposit and withdrawal.",
    "exchange.basic.trading_page.yeild_tooltip.row4":
      "4. Yeild = (evaluation amount - total purchase amount) ÷ total purchase amount × 100",
    "exchange.basic.trading_page.yeild_tooltip.row5":
      "5. Assets sold are not included in the calculation. Only assets that we currently hold are eligible.",
    "exchange.basic.trading_page.yeild_tooltip.row6":
      "6. This return rate does not represent the absolute yeild of the investment. Please use it as reference for investment only.",
    "exchange.table.hot_wallet_address": "Hot Wallet Address",
    "exchange.table.hot_wallet_balance": "Hot Wallet Balance",
    "exchange.table.cold_wallet_balance": "Cold Wallet Balance",
    "exchange.table.hot_wallet_collected_fee": "Hot Wallet Collected Fee",
    "exchange.table.cold_wallet_collected_fee": "Cold Wallet Collected Fee",
    "exchange.table.total_wallet_balance": "Total Wallet Balance",
    "exchange.table.total_user_balance": "Total User Balance",
    "exchange.table.profit": "Exchange Profit",
    "favourite.market_price.change": "Change",
    "favourite.market_price.coin": "Name",
    "favourite.market_price.current_price": "Last Price",
    "favourite.market_price.favorites": "Favorites",
    "favourite.market_price.percent_day_before": "± %",
    "favourite.market_price.transaction_amount": "Volume",
    "favourite.market_price.volume": "Vol",
    "fee_guide.actual_purcharse": "Actual purchase amount",
    "fee_guide.actual_sell": "Actual Sell",
    "fee_guide.commission_rate": "Commission rate by transaction volume",
    "fee_guide.commission_tip1":
      "{APP_NAME} applies a different fee according to the transaction volume as above. Fee ratings are subject to change. ",
    "fee_guide.commission_tip2":
      "Volume ranking is done once a day at {time} every day.",
    "fee_guide.commission_tip3": "Maker / Taker fee is the same.",
    "fee_guide.example": "Example:",
    "fee_guide.fee": "Fee",
    "fee_guide.fomula_actual_pucharse":
      "= Filled amount (1 BTC) - 1 BTC x transaction fee rate (0.2%) = 0.998 BTC",
    "fee_guide.for_example": "For example:",
    "fee_guide.formula_actual_sell": "= Filled amount (1 BTC)",
    "fee_guide.formula_fee":
      "= Filled amount (1 BTC) x Transaction Fee Rate (0.2%) = 0.002 BTC",
    "fee_guide.formula_sell_fee":
      "= Filled amount (1 BTC) x Price (5,000,000 USD) x Transaction Fee Rate (0.2%) = 10,000 USD",
    "fee_guide.formula_subtotal":
      "= Quantity (1 BTC) x Price (5,000,000 USD) = 5,000,000 USD",
    "fee_guide.formula_transaction_value":
      "= Quantity (1 BTC) x Price (USD 5,000,000) - Fees (USD 5,000) = 4,990,000 USD",
    "fee_guide.last_30_days": "Last 30 days",
    "fee_guide.less_than": "Less than",
    "fee_guide.level_name": "Level:",
    "fee_guide.level_title": "Your current level is",
    "fee_guide.rating": "Level",
    "fee_guide.subtotal": "Total USD (Transaction Value)",
    "fee_guide.text13": "Maker / Taker fee is the same.",
    "fee_guide.text14":
      "In case of a buy order, the quantity obtained by subtracting the quantity corresponding to the commission rate from the filled quantity will be the actual quantity.",
    "fee_guide.text15":
      "In case of a sell order, the amount of the sale price minus the commission rate is the actual selling price.",
    "fee_guide.text16":
      "If the order is filled, the commission rate is 0.2%, the price is USD 5,000,000 and the quantity is 1 BTC",
    "fee_guide.text20":
      "If a sell order is filled, the commission rate is 0.2%, the price is USD 5,000,000 and the quantity is 1 BTC",
    "fee_guide.text24":
      "You can check the actual fee, discount, etc. on order screen.",
    "fee_guide.title": "Fee Information",
    "fee_guide.transaction_fee": "Transaction fee calculation method ",
    "fee_guide.transaction_value": "Payment USD (Transaction Value)",
    "funds.action.deposit": "deposit",
    "funds.action.placeholder_input_coin": "Please input keyword of the coin",
    "funds.action.withdraw": "withdraw",
    "funds.balances.amount_is_positive": "Amount is Positive",
    "funds.balances.amount_can_not_greater_than_available_balance":
      "Amount can’t greater than balance avaiable balance",
    "funds.balances.amount_can_not_greater_than_exchange_available_balance_without_orderbook":
      "Available balance is not enough. ",
    "funds.balances.approx": "Approx. AMAL Value",
    "funds.balances.assign": "Assign",
    "funds.balances.avaliable_balance": "Available Balance",
    "funds.balances.balance": "Balances",
    "funds.balances.convert_small_balance_success": "Convert Balance Success",
    "funds.balances.curency": "Curency",
    "funds.balances.exchange_balance": "Exchange balance",
    "funds.balances.no_data": "You have no funds.",
    "funds.balances.btc_value": "BTC Value",
    "funds.balances.convert_to_amal": "Convert to AMAL",
    "funds.balances.convert_sm_balance": "Convert Small Balances to AMAL",
    "funds.balances.coin": "Coin",
    "funds.balances.deposit": "Deposit",
    "funds.balances.estimated_value": "Estimated Value",
    "funds.balances.from": "From",
    "funds.balances.hide": "Hide",
    "funds.balances.hide_small_assets": "Hide small assets",
    "funds.balances.interval": "Interval",
    "funds.balances.in_order": "In Order",
    "funds.balances.in_use": "In Use",
    "funds.balances.infomation": "Information",
    "funds.balances.main_account": "Main Account",
    "main_balance": "Main Balance",
    "airdrop_balance": "Dividend Balance",
    "dividend_bonus_balance": "Dividend Bonus Balance",
    "funds.balances.margin_balance": "Margin balance",
    "funds.balances.mam_balance": "MAM balance",
    "funds.balances.min_amount_is_invalid":
      "The amount must be at least 0.00000001",
    "funds.balances.name": "Name",
    "funds.balances.show_all": "Show All",
    "funds.balances.small_balance": "Small Balances",
    "funds.balances.small_balance_notice":
      "Balances valued less than {amount} BTC",
    "funds.balances.P_L": "P/L",
    "funds.balances.revoke": "Revoke",
    "funds.balances.value": "Value",
    "funds.balances.to": "To",
    "funds.balances.tranfer": "Transfer",
    "funds.balances.transfer": "Transfer",
    "funds.balances.transfer_balance_fails": "Transfer balance fails",
    "funds.balances.transfer_balance_success": "Transfer balance success",
    "funds.balances.total_balance": "Total Balance",
    "funds.balances.withdrawal": "Withdrawal",
    "funds.balances.suspend": "Suspend",
    "funds.balances.trade": "Trade",
    "funds.balances.withdrawal_limit_24": "24 Withdrawal Limit",
    "funds.convert_to_amal": "Convert to AMAL",
    "funds.deposit.available_balance": "Available Balance",
    "funds.deposit.choice": "Choice",
    "funds.deposit.coppy_address": "Copy Address",
    "funds.deposit.copy_error": "Copy error",
    "funds.deposit.copy_success": "Copied",
    "funds.deposit.copy_tag": "Copy Tag",
    "funds.deposit.deposit_address": "{name} Deposit Address",
    "funds.deposit.deposit_tag": "{name} Deposit Tag",
    "funds.deposit.history": "History",
    "funds.deposit.important.text2":
      "<span class='icon-favorites-2'></span> Send only {name} to this deposit address. Sending any other currency to this address may result in the loss of your deposit",
    "funds.deposit.in_order": "In Order",
    "funds.deposit.not_arrive": "Deposit Did Not Arrive",
    "funds.deposit.no_data": "You have no deposit history.",
    "funds.deposit.show_qr": "Show QR Code",
    "funds.deposit.tips.text1": "Please note",
    "funds.deposit.tips.text2":
      "{name} will be deposited immediately after {count} network confirmations.",
    "funds.deposit.tips.text3":
      "After making a deposit, you can track its progress on the <a class='link-primary' href='/funds/history?type=deposit' target='_blank'>history</a> page",
    "funds.deposit.title_page": "Deposit",
    "funds.deposit.tooltip":
      "If your deposit does not arrive for a long time, it may be caused by congested blockchain or incomplete transaction.  <a class='link-primary' href=\"#\">More Reference</a>",
    "funds.deposit.total_balance": "Total Balance",
    "funds.deposit.view_all": "View All",
    "funds.deposit.what": "What’s {name}?",
    "funds.deposit_usd.cancel_deposit_usd": "Cancel",
    "funds.deposit_usd.deposit_amount": "Deposit amount",
    "funds.deposit_usd.deposit_note":
      "Please make a transfer of money according to the information below. We will verify and transfer this amount to your account. Thank you!",
    "funds.deposit_usd.errors.min_amount":
      "The minimum withdrawal amount must be greater than {min_amount}.",
    "funds.deposit_usd.errors.required": "The {field} field is required.",
    "funds.deposit_usd.guide.text1":
      "1. Please send the same amount as the deposit amount.",
    "funds.deposit_usd.guide.text10":
      "4. Please contact our customer support in case of late payment.",
    "funds.deposit_usd.guide.text11":
      "If the required deposit amount and the transfered amount are different, or you forget to enter the deposit code, please submit a request in our support center.",
    "funds.deposit_usd.guide.text12":
      "※ The withdrawal will be limited during the check-in time of the banking system.",
    "funds.deposit_usd.guide.text2":
      "- If the deposit amount is different from the actual deposit amount, the deposit will not be processed.",
    "funds.deposit_usd.guide.text3": "2. Be sure to enter 'Deposit code'.",
    "funds.deposit_usd.guide.text4":
      "- When depositing money, please enter the deposit code as the transfer content. Please make sure you only use the deposit code as transfer content and nothing else. The deposit process can be delayed when you do not enter the deposit code.",
    "funds.deposit_usd.guide.text7":
      "3. Deposits will be processed within a maximum of 10 minutes.",
    "funds.deposit_usd.guide.text8":
      "- Under normal circumstances, the average time takes about 3 to 5 minutes to process the deposit.",
    "funds.deposit_usd.guide.text9":
      "We will notify you once the deposit has been processed.",
    "funds.deposit_usd.notes": "Notes",
    "funds.deposit_usd.other_deposit": "Make another transaction",
    "funds.deposit_usd.pending_transaction.account_name": "Account name",
    "funds.deposit_usd.pending_transaction.account_no": "Account no",
    "funds.deposit_usd.pending_transaction.amount": "Amount",
    "funds.deposit_usd.pending_transaction.bank_branch": "Bank branch",
    "funds.deposit_usd.pending_transaction.bank_name": "Bank name",
    "funds.deposit_usd.pending_transaction.code": "Code",
    "funds.deposit_usd.submit_deposit_usd": "Submit",
    "funds.deposit_usd.success_message": "The transaction is processing ...",
    "funds.deposit_usd.transaction_history_table.account": "Account",
    "funds.deposit_usd.transaction_history_table.amount": "Amount",
    "funds.deposit_usd.transaction_history_table.bank": "Bank",
    "funds.deposit_usd.transaction_history_table.code": "Code",
    "funds.deposit_usd.transaction_history_table.status": "Status",
    "funds.deposit_usd.transaction_history_table.time": "Time",
    "funds.history.address": "Address",
    "funds.history.amount": "Amount",
    "funds.history.coin": "Coin",
    "funds.history.date": "Date",
    "funds.history.deposit": "Deposit",
    "funds.history.export_complete_deposit_history":
      "Export Complete Deposit History",
    "funds.history.export_complete_withdraw_history":
      "Export Complete Withdrawal History",
    "funds.history.from": "From",
    "funds.history.history": "History",
    "funds.history.infomation": "Information",
    "funds.history.status": "Status",
    "funds.history.tag": "Tag",
    "funds.history.to": "To",
    "funds.history.transaction_history": "Transaction History",
    "funds.history.txid": "Txid",
    "funds.history.withdrawal": "Withdrawal",
    "funds.select_coin": "Select coin/token to {action}",
    "funds.withdraw_usd.account_info": "Account Information",
    "funds.withdraw_usd.account_name": "Account name",
    "funds.withdraw_usd.account_no": "Account no",
    "funds.withdraw_usd.amount": "Amount",
    "funds.withdraw_usd.available": "Available",
    "funds.withdraw_usd.bank_branch": "Bank branch",
    "funds.withdraw_usd.bank_info": "Bank Information",
    "funds.withdraw_usd.bank_name": "Bank name",
    "funds.withdrawals.add_new_address":
      "Please input your new withdrawal address below",
    "funds.withdrawals.add_whitelist_address":
      "You have no whitelist address. Please manage your addresses on {APP_NAME} PC website.",
        "funds.withdrawals.id": "ID",
    "funds.withdrawals.type": "Type",
    "funds.withdrawals.sender": "Sender",
    "funds.withdrawals.amount": "Amount",
    "funds.withdrawals.receiver": "Receiver",
    "funds.withdrawals.hash": "Hash",
    "funds.withdrawals.status": "Status",
    "funds.withdrawals.created_time": "Created time",
    "funds.withdrawals.action": "Action",
    "funds.withdrawals.available_balance": "Available Balance",
    "funds.withdrawals.choice": "Choice",
    "funds.withdrawals.no_data": "You have no withdrawals history.",
    "funds.withdrawals.no_usd_data": "You have no withdrawal USD history.",
    "funds.withdrawals.confirm_otp": "Confirm OTP",
    "funds.withdrawals.confirm_withdraw": "Confirm withdraw",
    "funds.withdrawals.did_not_receive_the_mail": "Did Not Receive The Mail",
    "funds.withdrawals.empty_whitelist.address_management":
      "Address Management",
    "funds.withdrawals.empty_whitelist.text": "You have no whitelist address.",
    "funds.withdrawals.error_message.address_not_exist_in_whitelist":
      "This address is not in the whitelist addresses.",
    "funds.withdrawals.error_message.amount_withdraw_is_positive":
      "The withdrawal amount must be a negative number.",
    "funds.withdrawals.error_message.minimum_withdraw":
      "You have a smaller withdrawal than allowed.",
    "funds.withdrawals.error_message.not_enough_balance":
      "The money in the account is not enough to make the transaction.",
    "funds.withdrawals.error_message.over_daily_limit":
      "You have withdrawn the limit of the day.",
    "funds.withdrawals.error_message.over_limit":
      "You have withdrawn the limit.",
    "funds.withdrawals.error_message.over_one_time_limit":
      "You have overdrawn once.",
    "funds.withdrawals.error_message.withdraw_is_blocking":
      "Withdrawal is blocking",
    "funds.withdrawals.errors.account_no_length":
      "The account no is not valid.",
    "funds.withdrawals.errors.blockchain_address":
      "The withdrawal address is not valid.",
    "funds.withdrawals.errors.exist_withdraw_address":
      "This address already exists.",
    "funds.withdrawals.errors.max_balance":
      "Your account balance is not enough.",
    "funds.withdrawals.errors.max_once_amount":
      "The amount you withdraw is more than once allowed.",
    "funds.withdrawals.errors.min_amount":
      "The minimum withdrawal amount is {min_amount}.",
    "funds.withdrawals.errors.not_allowed_withdraw":
      "Your security level doesn't be allowed to withdraw.",
    "funds.withdrawals.errors.otp_length_digits":
      "The authentication code must be 6 digits.",
    "funds.withdrawals.errors.otp_required":
      "The authentication code field is required.",
    "funds.withdrawals.errors.required": "The {field} field is required.",
    "funds.withdrawals.history": "History",
    "funds.withdrawals.important.text1": "Important",
    "funds.withdrawals.important.text2": "Minimum Withdrawal",
    "funds.withdrawals.important.text3":
      "Do not withdraw directly to a crowfund or ICO. We will not credit your account with tokens from that sale.",
    "funds.withdrawals.in_order": "In Order",
    "funds.withdrawals.input_address_error": "Invalid address.",
    "funds.withdrawals.loading": "Processing...",
    "funds.withdrawals.submit": "Submit",
    "funds.withdrawals.success_message": "Successful transaction.",
    "funds.withdrawals.tips.text1": "Please note",
    "funds.withdrawals.tips.text2":
      "Once you have submitted your withdrawal request, we will send a confirmation email. Please then click on the confirmation link in your email.",
    "funds.withdrawals.tips.text3":
      "After making a withdrawal, you can track its progress on the <a style='color: #265ac3;font-weight: bold;' href='/funds/history?type=withdraw' target='_blank'>history</a> page.",
    "funds.withdrawals.title_page": "Withdrawals",
    "funds.withdrawals.tooltip":
      'If your can not find the email, please check your spam or other folders <a href="{url}" target="_blank">Still Not Receive.</a>',
    "funds.withdrawals.total_balance": "Total Balance",
    "funds.withdrawals.transaction_fee": "Transaction Fee",
    "funds.withdrawals.use_a_new_address": "Use a new address",
    "funds.withdrawals.view_all": "View All",
    "funds.withdrawals.what": "What’s {name}?",
    "funds.withdrawals.withdrawal_address": "Withdrawal Address",
    "funds.withdrawals.withdrawal_limit_24": "24h withdrawal limit",
    "funds.withdrawals.withdrawal_placeholder_address": "Address",
    "funds.withdrawals.withdrawal_placeholder_available": "Available",
    "funds.withdrawals.withdrawal_placeholder_label": "Label",
    "funds.withdrawals.xrp_tag": "Withdrawal Tag",
    "funds.withdrawals.you_will_get": "You Will Get",
    "funds.withdrawals.network": "Network",
    "funds.contracts": "Contracts",
    "funds.contract": "Contract",
    "funds.disable_coin_msg": "Contract Frozen, Waiting for Upgrade",
    "landing.banner.desc":
      "Up to 100x leverage. Trading without expiry dates. Industry-leading security. Welcome to Bitcoin's most advanced trading platform.",
    "landing.banner.title.span": "Smart Bitcoin -",
    "landing.banner.title.text": "Virtual Currency Exchange",
    "landing.change": "24h Change",
    "landing.chart.change": "Change",
    "landing.chart.last_price": "Last Price",
    "landing.clients_download": "Download",
    "landing.clients_text1": "More ways to get {APP_NAME}",
    "landing.clients_text2":
      "We've provided some tools you need for trading on the go",
    "landing.coin": "Coin",
    "landing.coin_markets": "{coin} Markets",
    "landing.favorites": "Favorites",
    "landing.high": "24h High",
    "landing.last_price": "Last Price",
    "landing.low": "24h Low",
    "landing.pair": "Pair",
    "landing.price": "Price",
    "landing.register": "Register now",
    "landing.gotrade": "Go to Trade",
    "landing.slogan": "Exchange the World",
    "landing.volume": "24h Volume",
    "landing.current_rate": "Current <br/> Rate",
    "login.active_device_failed":
      "Your authorization link is invalid or expired!",
    "login.active_device_success":
      "You have successfully authorized a new device. Please try to log in with it again!<p></p><p style='color: #12575f; font-size{14px}'>Device: <strong style='font-family : 'Roboto-Bold', sans-serif'>{platform} {operating_system}</strong></p><p style='color: #12575f; font-size{14px}'>IP address: <strong style='font-family : 'Roboto-Bold', sans-serif'>{latest_ip_address}</strong> </p>",
    "login.confirm_failed": "Your confirmation link is invalid or expired!",
    "login.confirm_success": "Your email has been confirmed!",
    "login.confirm_email_failed":
      "We can't find a user with that e-mail address.",
    "login.dont_has_account": "Need an account",
    "login.forgot": "Forgot your password",
    "login.sub_title": "Please check you are visiting",
    "login.title": "Login",
    "login.title_otp": "Google Authentication",
    "login.resend_confirm_mail_success": "Resent confirmation email to {email}",
    "m_account.btn_continue": "I understand, Continue",
    "m_account.change_pass": "Change Password",
    "m_account.gg_auth": "Google Authenticator",
    "m_account.go_pc":
      "Please go to {APP_NAME} PC website to finish Google Authenticator setting.",
    "m_account.id": "ID",
    "m_account.identity_auth": "Identity Authentication",
    "m_account.language": "Language",
    "m_account.logout": "Logout",
    "m_account.lv": "Lv",
    "m_account.referral.commission": "Commission",
    "m_account.referral.commission_rate": "Commission Rate:",
    "m_account.referral.count": "NO.",
    "m_account.referral.detail": "Program Details",
    "m_account.referral.detail_notice":
      "{APP_NAME} reserves the right to change the terms of the referral program at any time due to changing market conditions, risk of fraud, or any other factors we deem relevant.",
    "m_account.referral.estimate": "Estimated Commission Value",
    "m_account.referral.get_link": "1. Get Your Link",
    "m_account.referral.info_get_link":
      "Register and generate referral links and QR codes",
    "m_account.referral.info_invite":
      "Invite your friends to register through the referral link or QR codes and get rewards once they complete a trade",
    "m_account.referral.info_reward":
      "Receive up to 40% commission in real-time",
    "m_account.referral.invite": "2. Invite Friends",
    "m_account.referral.line1": "Effective as of 2018/05/19 0{00} AM (UTC)",
    "m_account.referral.line2":
      "The commission you receive from the referral program will initially be set at a rate of 20%. For accounts holding Amount of {COIN_HOLDING} or more, this rate will increase to 40%.",
    "m_account.referral.line3":
      "The fee commission will be sent instantly in real-time to your {APP_NAME} account as your referee completes each trade and will be paid to you in whatever token/cryptocurrency the original fee was paid in.",
    "m_account.referral.line4":
      "There is no limit to the number of friends you can refer, although we do reserve the right to adjust or change the referral program rules at any time.",
    "m_account.referral.line5":
      "Each referee must be signed up through your Referral Link, QR Code or Referral ID.",
    "m_account.referral.line6":
      "{APP_NAME} will check for duplicate or fake accounts and will not pay out referral bonuses on these accounts. Duplicate or shared finances will result in disqualification.",
    "m_account.referral.my_referral": "My Referrals",
    "m_account.referral.notice": "* Important Notice:",
    "m_account.referral.referral_friend": "Referral Friends",
    "m_account.referral.referral_id": "My Referral ID:",
    "m_account.referral.referral_link": "Referral Link:",
    "m_account.referral.reward": "3. Get Rewards",
    "m_account.referral.text29": "Invite friends Scan QR Code to get crypto",
    "m_account.referral.title": "Referral Program",
    "m_account.title":
      "Please do not disclose Google Authentication codes to anyone,including {APP_NAME} customer support.",
    "m_account.unverified": "Unverified",
    "m_account.verified": "Verified",
    "m_common.action.cancel": "Cancel",
    "m_common.action.confirm": "Confirm",
    "m_common.action.more": "More",
    "m_common.action.off": "OFF",
    "m_common.action.on": "ON",
    "m_common.time.hour": "{number} hour",
    "m_common.time.hours": "{number} hours",
    "m_common.time.label_minute": "Minute",
    "m_common.time.minute": "{number} minute",
    "m_common.time.minutes": "{number} minutes",
    "m_exchange.chart.amount": "Amount",
    "m_exchange.chart.buy": "Buy",
    "m_exchange.chart.candle": "Candle",
    "m_exchange.chart.charts": "Charts",
    "m_exchange.chart.depth": "Depth",
    "m_exchange.chart.high": "High",
    "m_exchange.chart.low": "Low",
    "m_exchange.chart.market_trade_history": "Market Trade History",
    "m_exchange.chart.price": "Price",
    "m_exchange.chart.sell": "Sell",
    "m_exchange.chart.time": "Time",
    "m_exchange.chart.trade_detail": "Trade Detail",
    "m_exchange.chart.trade_history": "Trade History",
    "m_exchange.chart.volume": "Vol",
    "m_exchange.open_order.open_order": "Open Order",
    "m_exchange.trade.available": "available",
    "m_exchange.trade.buy": "Buy",
    "m_exchange.trade.sell": "Sell",
    "m_exchange.trade.trade": "Trade",
    "m_funds.coin_info.available": "Available",
    "m_funds.coin_info.deposit": "Deposit",
    "m_funds.coin_info.estimate": "Estimated",
    "m_funds.coin_info.go_to_trade": "Go To Trade",
    "m_funds.coin_info.in_order": "In Order",
    "m_funds.coin_info.total": "Total",
    "m_funds.coin_info.withdraw": "Withdraw",
    "m_funds.current": "Current",
    "m_funds.deposit.save_qr_code": "Save QR Code",
    "m_funds.deposit.select_coin": "Select Coin",
    "m_funds.deposit_usd.available_balance": "Available Balance",
    "m_funds.deposit_usd.deposit_amount": "Deposit Amount",
    "m_funds.deposit_usd.amal_amount": "Amal Holding",
    "m_funds.deposit_usd.deposit_usd_tab": "Deposit USD",
    "m_funds.deposit_usd.errors.required": "The {field} field is required.",
    "m_funds.deposit_usd.guide.text1":
      "1. Please send the same amount as the deposit amount.",
    "m_funds.deposit_usd.guide.text10":
      "4. Please contact our customer support in case of late payment.",
    "m_funds.deposit_usd.guide.text11":
      "- If the required deposit amount and the transfered amount are different, or you forget to enter the deposit code, please submit a request in our support center.",
    "m_funds.deposit_usd.guide.text12":
      "※ The withdrawal will be limited during the check-in time of the banking system.",
    "m_funds.deposit_usd.guide.text2":
      "- If the deposit amount is different from the actual deposit amount, the deposit will not be processed.",
    "m_funds.deposit_usd.guide.text3": "2. Be sure to enter 'Deposit code'.",
    "m_funds.deposit_usd.guide.text4":
      "- When depositing money, please enter the deposit code as the transfer content. Please make sure you only use the deposit code as transfer content and nothing else. The deposit process can be delayed when you do not enter the deposit code.",
    "m_funds.deposit_usd.guide.text7":
      "3. Deposits will be processed within a maximum of 10 minutes.",
    "m_funds.deposit_usd.guide.text8":
      "- Under normal circumstances, the average time takes about 3 to 5 minutes to process the deposit.",
    "m_funds.deposit_usd.guide.text9":
      "We will notify you once the deposit has been processed.",
    "m_funds.deposit_usd.history_tab": "History",
    "m_funds.deposit_usd.in_order": "In Order",
    "m_funds.deposit_usd.notes": "Please Note",
    "m_funds.deposit_usd.submit_deposit_usd": "Submit",
    "m_funds.deposit_usd.total_balance": "Total Balance",
    "m_funds.deposit_usd.transaction_history_table.cancel": "Cancel",
    "m_funds.deposit_usd.transaction_history_table.no": "No",
    "m_funds.deposit_usd.transaction_history_table.text1":
      "Do you want to cancel ?",
    "m_funds.deposit_usd.transaction_history_table.yes": "Yes",
    "m_funds.transaction_history.deposit_history": "Deposit History",
    "m_funds.transaction_history.no_deposit_history":
      "You have not Deposit History",
    "m_funds.transaction_history.no_withdraw_history":
      "You have not Withdrawal History",
    "m_funds.transaction_history.withdraw_history": "Withdrawal History",
    "m_funds.withdraw_usd.history_tab": "History",
    "m_funds.withdraw_usd.limit_and_notice": "Limit & Notice",
    "m_funds.withdraw_usd.withdraw_usd_tab": "Withdrawal USD",
    "m_funds.withdrawals.but_withdraw": "Withdraw",
    "m_funds.withdrawals.but_withdrawal": "Withdrawal",
    "m_funds.withdrawals.errors.required": "The field is required.",
    "m_funds.withdrawals.fee": "Fee",
    "m_funds.withdrawals.limit_and_notice": "Limit & Notice",
    "m_funds.withdrawals.limit_and_notice_modal.in_use": "In Use",
    "m_funds.withdrawals.limit_and_notice_modal.title": "Limit & Notice",
    "m_funds.withdrawals.limit_and_notice_modal.withdrawal_limit_24":
      "24h Withdrawal Limit",
    "m_funds.withdrawals.notes":
      "<span>  Minimum withdrawal: {min} {coin}</span>",
    "m_funds.withdrawals.notes2":
      "  Do not withdrawal directly to a crowdfund or ICO. We will not credit your account with tokens from that sale.",
    "m_funds.withdrawals.notes3":
      "  Once you have submitted your withdrawal request, we will send a confirmation email. Please then click on the confirmation link in your email.",
    "m_funds.withdrawals.select_coin": "Select coin",
    "m_funds.withdrawals.withdrawal_amount": "Withdrawal Amount",
    "m_landing.change_24h": "24h Change%",
    "m_landing.deposit": "Deposit",
    "m_landing.gainers": "Gainers",
    "m_landing.losers": "Losers",
    "m_landing.more": "More",
    "m_landing.support": "Support",
    "m_landing.top24btc": "BTC 24h Volume Top",
    "m_landing.withdraw": "Withdrawal",
    "m_menu.deposits": "Deposits",
    "m_menu.home": "Home",
    "m_menu.language": "Language",
    "m_menu.markets": "Markets",
    "m_order_history.avg": "Avg",
    "m_order_history.conditions": "Conditions",
    "m_order_history.more": "More",
    "m_order_history.trade_detail": "Trade Detail",
    "m_order_history.trading_price": "Trading price",
    "m_pair_navigation.change_24h": "24h Change%",
    "m_pair_navigation.last_price": "Last Price",
    "m_pair_navigation.name": "Name",
    "m_pair_navigation.vol": "Vol",
    "m_salespoint.buy_history": "Buy History",
    "menu.about": "About",
    "menu.account": "Account",
    "menu.address": "Address Management",
    "menu.api": "API",
    "menu.apply_to_list": "Apply to List",
    "menu.balances": "Balances",
    "menu.change_password": "Change Password",
    "menu.contact": "Contact",
    "menu.deposit_usd": "Deposit USD",
    "menu.deposits": "Deposits",
    "menu.estimated_value": "Estimated Value",
    "menu.exchange": "Exchange",
    "menu.fees": "Exchange fees",
    "menu.funds": "Funds",
    "menu.history": "History",
    "menu.history_title": "History",
    "menu.info": "Info",
    "menu.join_us": "Join Us",
    "instruments.create": "Create",
    "instruments.create_ins": "Create Instruments",
    "instruments.create_new_tier": "Create New Tier",
    "instruments.tier": "Tier",
    "instruments.trading_tier": "Trading Tier",
    "instruments.position_bracket": "Position Bracket",
    "instruments.previous": "Previous",
    "instruments.future_coinM": "Futures Instruments COIN-M",
    "instruments.future_usdM": "Futures Instruments USD-M",
    "instruments.menu": "Instruments",
    "instruments.symbol": "Symbol",
    "instruments.root_symbol": "Root Symbol",
    "instruments.state": "State",
    "instruments.type": "Type",
    "instruments.underlying": "Underlying Asset &\n Quote currency",
    "instruments.contract_multiplier": "Contract multiplier",
    "instruments.collateral": "Collateral Asset",
    "instruments.tarker_fee": "Maker / Taker Fee",
    "instruments.tarker_fee_percent": "Taker Fee (%)",
    "instruments.limit_buy_order_price_cap_ratio":
      "Limit buy order price cap ratio (%)",
    "instruments.limit_buy_order_price_floor_ratio":
      "Limit buy order price floor ratio (%)",
    "instruments.create.min_tick_size": "Min tick size",
    "instruments.min_price": "Min Price",
    "instruments.create.max_price": "Max Price",
    "instruments.min_order_price": "Min order amount",
    "instruments.max_order_price": "Max order amount",
    "instruments.figures_for_price": "Max No. of decimal figures for price",
    "instruments.figures_for_size": "Max No. of decimal figures for size",
    "instruments.liquidateion": "Liquidation clearance fee (%)",
    "instruments.create.max_leverage": "Max leverage",
    "instruments.max-nation": "Max notional value",
    "instruments.min-nation": "Min notional value",
    "instruments.min-price-movement": "Minimum price movement",
    "instruments.min-price-movement.tip": "Must set equal to Min tick set",
    "instruments.impact-nation": "Impact margin notional",
    "instruments.marker_fee_percent": "Marker Fee (%)",
    "instruments.min_tick_size": "Min. Tick Size",
    "instruments.price_movement": "Min. Price Movement",
    "instruments.min_max_price": "Min Price / Max Price",
    "instruments.limit_order_Price":
      "Limit Order Price Buy Cap/ \nSell Floor Ratio",
    "instruments.min_max_order_amt": "Min / Max Order Amt",
    "instruments.min_max_notional": "Min / Max Notional Value",
    "instruments.max_leverage": "Max Leverage",
    "instruments.maintenace_margin": "Maintenance Margin Rate",
    "instruments.maintenace_amount": "Maintenance Amount",
    "instruments.decimal_figures": "Max No. of Decimal Figures for Size",
    "instruments.contract_multipliter": "Contract Multiplier",
    "instruments.decimal_figures_price": "Max No. of Decimal Figures for Price",
    "instruments.decimal_figures_fee": "Liq. Clearance Fee",
    "instruments.margin_notional": "Impact margin notional",
    "instruments.expiry": "Expiry",
    "instruments.base_underlying": "Base Underlying",
    "menu.instruments_future": "Futures Instruments",
    "title.instruments_future": "Futures Instruments USDⓈ-M",
    "instruments.quote_currency": "Quote Currency",
    "instruments.underlying_symbol": "Underlying Symbol",
    "instruments.init_margin": "Init Rate",
    "instruments.maint_margin": "Maint Rate",
    "instruments.maker_fee": "Maker Fee",
    "instruments.taker_fee": "Taker Fee",
    "instruments.settlement_fee": "Settlement Fee",
    "instruments.reference_index": "Reference Index",
    "instruments.settlement_index": "Settlement Index",
    "instruments.funding_base_index": "Funding Base Index",
    "instruments.funding_quote_index": "Funding Quote Index",
    "instruments.funding_premium_index": "Funding Premium Index",
    "instruments.tick_size": "Tick Size",
    "instruments.max_price": "Max Price",
    "instruments.max_order_qty": "Max Order Qty",
    "instruments.multiplier": "Multiplier",
    "instruments.risk_limit": "Risk Limit",
    "instruments.risk_step": "Risk Step",
    "instruments.create_instrument": "Create Instruments",
    "instruments.open": "Open",
    "instruments.close": "Close",
    "instruments.pending": "Pending",
    "instruments.cancel": "Cancel",
    "instruments.submit": "Submit",
    "instruments.validate.incorrectExpiry":
      "The expiry date must be greater than today.",
    "instruments.validate.invalidExpiry": "The expiry date is invalid.",
    "instruments.validate.required": "The {field} is required.",
    "instruments.create.success": "Created successfully!",
    "instruments.dropdown.placeholder": "-- Please select --",
    "instruments.value.must.lower":
      "Maint rate value must be smaller than the init rate value.",
    "instruments.value.must.difference":
      "The {field1} difficult than {field2}.",
    "instruments.remove_success": "Deleted successfully!",
    "instruments.remove_failed": "Instrument removed fail!",
    "instruments.types.call_option": "Call Option",
    "instruments.types.future": "Future",
    "instruments.types.perpetual": "Perpetual",
    "instruments.types.put_option": "Put Option",
    "menu.margin_exchange": "Margin Exchange",
    "reward_center.title_page": "Reward Management",
    "reward_center.title_page_create": "Add New Reward ",
    "reward_center.title_page_edit": "Edit Reward ",
    "reward_center.create": "Add New Reward",
    "reward.remove_reward_center": "Do you want to delete this reward?",
    "reward_center.no": "No",
    "reward_center.voucher.name": "Voucher Name",
    "reward_center.beneficiary": "Beneficiary",
    "reward_center.currency": "Currency",
    "reward_center.trading_volume": "Trading volume",
    "reward_center.reward": "Reward",
    "reward_center.expired_time": "Expired time (days)",
    "reward_center.label.currency": "Currency",
    "reward_center.unique_voucher": "Voucher name already exists",
    "voucher.no.create": "Delete the current voucher before adding a new one",
    "cms.notification.type": "Type",
    "cms.notification.type_all": "All",
    "cms.notification.type_system_message": "System Message",
    "cms.notification.type_event": "Event",
    "cms.notification.type_campaign": "Campaign",
    "cms.notification.type_new_listing": "New Listing",
    "cms.notification.type_copy_trade": "Copy Trade",
    "cms.notification.type_announcement": "Announcement",
    "cms.notification.search_by": "Search by title, message",
    "cms.notification.created_time": "Created time",
    "cms.notification.id": "ID",
    "cms.notification.language": "Language",
    "cms.notification.title": "Title",
    "cms.notification.message": "Message",
    "cms.notification.status": "Status",
    "cms.notification.action": "Action",
    "cms.notification.image": "Image",
    "cms.notification.popup_content_close": "Are you sure you want to close this notification?",
    "cms.notification.popup_content_open": "Are you sure you want to open this notification?",
    "cms.notification.toast_close_success": "Close Successful",
    "cms.notification.toast_close_failed": "Close Failed",
    "cms.notification.toast_open_success": "Open Successful",
    "cms.notification.toast_open_failed": "Open Failed",
    "cms.notification.toast_update_failed": "Update Failed",
    "cms.notification.toast_update_successful": "Update Successful",
    "cms.notification.toast_create_failed": "Create Failed",
    "cms.notification.toast_create_successful": "Create Successful",
    "cms.notification.href_url": "Href URL",
    "cms.notification.title_create": "Notification: Create",
    "cms.notification.title_edit": "Notification: Edit",
    "cms.notification.popup_content_edit": "Are you sure you want to update this notification?",
    "cms.notification.select_type": "Select type...",
    "cms.notification.create_type": "Create Type",
    "cms.notification.add_new_type": "Add New Type",
    "cms.notification.enter_type": "Enter Type",
    "cms.notification.new": "New",
    "cms.mail_template.created_time": "Created time",
    "cms.mail_template.id": "ID",
    "cms.mail_template.language": "Language",
    "cms.mail_template.name": "Name",
    "cms.mail_template.subject": "Subject",
    "cms.mail_template.type": "Type",
    "cms.mail_template.action": "Action",
    "cms.mail_template.default": "Default",
    "cms.mail_template.select_language": "Select language...",
    "cms.mail_template.select_type": "Select type...",
    "cms.mail_template.title_edit": "Mail Template: Edit",
    "cms.mail_template.title_create": "Mail Template: New",
    "cms.mail_template.create_type": "Create Type",
    "cms.mail_template.toast_update_failed": "Update Failed",
    "cms.mail_template.toast_update_successful": "Update Successful",
    "cms.mail_template.toast_create_failed": "Create Failed",
    "cms.mail_template.toast_create_successful": "Create Successful",
    "cms.announcement.popup_delete_confirm": "Are you sure you want to delete this announcement?",
    "cms.announcement.search_by": "Search by title, category",
    "cms.announcement.created_time": "Created time",
    "cms.announcement.id": "ID",
    "cms.announcement.language": "Language",
    "cms.announcement.title": "Title",
    "cms.announcement.status": "Status",
    "cms.announcement.category": "Category",
    "cms.announcement.action": "Action",
    "cms.announcement.popular": "Popular",
    "cms.announcement.select_language": "Select language...",
    "cms.announcement.select_category": "Select category...",
    "cms.announcement.create_category": "Create category",
    "cms.announcement.title_edit": "Announcement: Edit",
    "cms.announcement.title_create": "Announcement: New",
    "cms.announcement.toast_update_failed": "Update Failed",
    "cms.announcement.toast_update_successful": "Update Successful",
    "cms.announcement.toast_create_failed": "Create Failed",
    "cms.announcement.toast_create_successful": "Create Successful",
    "cms.announcement.toast_delete_success": "Delete Successful",
    "cms.announcement.toast_delete_failed": "Delete Failed",
    "cms.announcement.search_by": "Search by title, category",
    "cms.announcement.type_maintenance": "Maintenance",
    "cms.announcement.type_coin_listings": "Coin Listings",
    "cms.announcement.type_latest_news": "Latest News",
    "cms.announcement.add_new_category": "Add New Category",
    "cms.announcement.enter_category": "Enter Category",
    "cms.announcement.active_status": "Active Status",
    "cms.faq.search_by": "Search by question",
    "cms.faq.created_time": "Created time",
    "cms.faq.id": "ID",
    "cms.faq.language": "Language",
    "cms.faq.question": "Question",
    "cms.faq.status": "Status",
    "cms.faq.category": "Category",
    "cms.faq.action": "Action",
    "cms.faq.select_language": "Select language...",
    "cms.faq.select_category": "Select category...",
    "cms.faq.active_status": "Active Status",
    "cms.faq.toast_delete_success": "Delete Successful",
    "cms.faq.toast_delete_failed": "Delete Failed",
    "cms.faq.toast_update_failed": "Update Failed",
    "cms.faq.toast_update_successful": "Update Successful",
    "cms.faq.toast_create_failed": "Create Failed",
    "cms.faq.toast_create_successful": "Create Successful",
    "cms.faq.popup_delete_confirm": "Are you sure you want to delete this question?",
    "cms.faq.user_id": "User ID",
    "cms.faq.title_edit": "FAQ: Edit",
    "cms.faq.title_create": "FAQ: New",
    "cms.qna.title_edit": "Inquiry: Reply",
    "cms.qna.title_create": "Inquiry: New",
    "resource.networks.id": "ID",
    "resource.networks.currency": "Currency",
    "resource.networks.info": "Info",
    "resource.networks.active_status": "Active Status",
    "resource.networks.allow_deposit": "Allow Deposit",
    "resource.networks.allow_withdraw": "Allow Withdraw",
    "resource.networks.updated_time": "Updated Time",
    "resource.networks.action": "Action",
    "resource.networks.search_by": "Search by currency",
    "resource.networks.new_network": "New Network",
    "resource.networks.name": "Name",
    "resource.networks.symbol": "Symbol",
    "resource.networks.code": "Code",
    "resource.networks.chain_id": "Chain ID",
    "resource.networks.currency": "Currency",
    "resource.networks.deposit_confirmation": "Deposit Confirmation",
    "resource.networks.explorer_url": "Explorer URL",
    "resource.networks.enter_name": "Enter Name",
    "resource.networks.enter_symbol": "Enter Symbol",
    "resource.networks.enter_code": "Enter Code",
    "resource.networks.enter_chain_id": "Enter Chain ID",
    "resource.networks.enter_currency": "Enter Currency",
    "resource.networks.enter_deposit_confirmation": "Enter Deposit Confirmation",
    "resource.networks.enter_explorer_url": "Enter Explorer URL",
    "resource.networks.btn_cancel": "Cancel",
    "resource.networks.btn_create": "Create",
    "resource.networks.btn_update": "Update",
    "resource.networks.btn_new_coin": "New Coin",
    "resource.networks.toast_create_success": "Create Successful",
    "resource.networks.toast_create_failed": "Create Failed",
    "resource.networks.toast_update_success": "Update Successful",
    "resource.networks.toast_update_fail": "Update Failed",
    "resource.networks.title_edit": "Network: Edit",
    "resource.networks.title_create": "Network: Create",
    "resource.networks.popup_update_confirm": "Are you sure you want to update this network?",
    "resource.networks.popup_create_confirm": "Are you sure you want to create this network?",
    "resource.networks.error_name_field": "The name field is required.",
    "resource.networks.error_symbol_field": "The symbol field is required.",
    "resource.networks.error_code_field": "The code field is required.",
    "resource.networks.error_chain_id_field": "The chain ID field is required.",
    "resource.networks.error_currency_field": "The currency field is required.",
    "resource.networks.error_deposit_confirmation_field": "The Deposit Confirmation field is required.",
    "resource.networks.error_explorer_url_field": "The explorer URL field is required.",
    "resource.coins.id": "ID",
    "resource.coins.info": "Info",
    "resource.coins.work_on": "Work on Wallets",
    "resource.coins.status": "Status",
    "resource.coins.updated_time": "Updated Time",
    "resource.coins.action": "Action",
    "resource.coins.popup_disable_content": "Are you sure you want to disable this coin?",
    "resource.coins.popup_enable_content": "Are you sure you want to enable this coin?",
    "resource.coins.popup_update_content": "Are you sure you want to update this coin?",
    "resource.coins.popup_create_content": "Are you sure you want to create this coin?",
    "resource.coins.popup_delete_network_content": "Are you sure you want to delete this network?",
    "resource.coins.btn_cancel": "Cancel",
    "resource.coins.btn_confirm": "Confirm",
    "resource.coins.btn_create": "Create",
    "resource.coins.toast_disable_success": "Disable Successful",
    "resource.coins.toast_disable_fail": "Disable Failed",
    "resource.coins.toast_enable_success": "Enable Successful",
    "resource.coins.toast_enable_fail": "Enable Failed",
    "resource.coins.toast_create_success": "Create Successful",
    "resource.coins.toast_create_fail": "Create Failed",
    "resource.coins.toast_update_success": "Update Successful",
    "resource.coins.toast_update_fail": "Update Failed",
    "resource.coins.toast_delete_success": "Delete Successful",
    "resource.coins.toast_delete_fail": "Delete Failed",
    "resource.coins.title_edit": "Coin: Edit",
    "resource.coins.title_create": "Coin: Create",
    "resource.coins.name": "Name",
    "resource.coins.symbol": "Symbol",
    "resource.coins.exchanges": "Exchanges",
    "resource.coins.price": "Price",
    "resource.coins.precision": "Precision",
    "resource.coins.wallets": "Wallets",
    "resource.coins.fixed_price": "Fixed Price",
    "resource.coins.networks_setting": "Networks Setting",
    "resource.coins.allow_withdraw": "Allow Withdraw",
    "resource.coins.allow_deposit": "Allow Deposit",
    "resource.coins.enable": "Enable",
    "resource.coins.upload_coin_icon": "Upload Coin Icon",
    "resource.coins.contract_address": "Contract Address",
    "resource.coins.network": "Network",
    "resource.coins.token_explorer": "Token Explorer URL",
    "resource.coins.enter_token_explorer": "Enter Token Explorer URL",
    "resource.coins.explorer_url": "Explorer URL",
    "resource.coins.withdraw_fee": "Withdraw Fee",
    "resource.coins.min_deposit": "Min Deposit",
    "resource.coins.min_withdraw": "Min Withdraw",
    "resource.coins.add_item": "Add Item",
    "resource.coins.enter_symbol": "Enter symbol",
    "resource.coins.enter_contract_address": "Enter Contract Address",
    "resource.coins.enter_network": "Enter network",
    "resource.coins.enter_explorer_url": "Enter Explorer URL",
    "resource.coins.enter_name": "Enter Name",
    "resource.coins.enter_precision": "Enter Precision",
    "resource.coins.error_name_field": "The name field is required.",
    "resource.coins.error_address_contract_field_invalid": "The contract address field is invalid.",
    "resource.coins.error_symbol_field": "The symbol field is required.",
    "resource.coins.error_exchanges_field": "The exchanges field is required.",
    "resource.coins.error_price_field": "The price field is required.",
    "resource.coins.error_precision_field": "The precision field is required.",
    "resource.coins.error_wallets_field": "The wallets field is required.",
    "resource.coins.error_address_contract_field": "The contract address field is required.",
    "resource.coins.error_network_field": "The network field is required.",
    "resource.coins.error_token_explorer_field": "The token explorer URL field is required.",
    "resource.coins.error_explorer_url_field": "The explorer URL field is required.",
    "resource.coins.error_withdraw_fee_field": "The withdraw fee field is required.",
    "resource.coins.error_min_deposit_field": "The min deposit field is required.",
    "resource.coins.error_min_withdraw_field": "The min withdraw field is required.",
    "resource.coins.error_icon_image_field": "The icon image field is required.",
    "resource.wallets.btn_refresh": "Refresh",
    "resource.wallets.network": "Network",
    "resource.wallets.withdraw": "Withdraw",
    "resource.wallets.move_fund": "Move Fund",
    "resource.wallets.vault": "Vault",
    "resource.wallets.balance": "Balance",
    "resource.wallets.label_wallet_config": "Wallet Config",
    "bots.symbol": "Symbol",
    "bots.status": "Status",
    "bots.search_by": "Search by ID",
    "bots.btn_search": "Search",
    "bots.refresh": "Refresh",
    "bots.btn_new_bots": "New Bots",
    "bots.btn_delete": "Delete",
    "bots.btn_cancel": "Cancel",
    "bots.btn_update": "Update",
    "bots.btn_add_item": "Add Item",
    "bots.id": "ID",
    "bots.type": "Type",
    "bots.api_key": "API Key",
    "bots.status": "Status",
    "bots.updated_time": "Updated Time",
    "bots.action": "Action",
    "bots.toast_delete_success": "Delete Successful",
    "bots.toast_delete_fail": "Delete Failed",
    "bots.toast_create_success": "Create Successful",
    "bots.toast_create_fail": "Create Failed",
    "bots.toast_update_success": "Update Successful",
    "bots.toast_update_fail": "Update Failed",
    "bots.popup_delete_content": "Are you sure you want to delete this bot?",
    "bots.popup_create_content": "Are you sure you want to create this bot?",
    "bots.popup_update_content": "Are you sure you want to update this bot?",
    "bots.title_edit": "Orderbook Bot: Edit",
    "bots.title_create": "Orderbook Bot: Create",
    "bots.start_sleep_time": "Start of Sleep Time",
    "bots.end_sleep_time": "End of Sleep Time",
    "bots.secret_key": "Secret Key",
    "bots.min_quote_quantity": "Min Quote Quantity",
    "bots.max_quote_quantity": "Max Quote Quantity",
    "bots.base_precision": "Base Precision",
    "bots.quote_precision": "Quote Precision",
    "bots.asks": "Asks",
    "bots.bids": "Bids",
    "bots.quote": "Quote",
    "bots.enter_start_sleep_time": "Enter Start of Sleep Time",
    "bots.enter_end_sleep_time": "Enter End of Sleep Time",
    "bots.enter_secret_key": "Enter Secret Key",
    "bots.enter_min_quote_quantity": "Enter Min Quote Quantity",
    "bots.enter_max_quote_quantity": "Enter Max Quote Quantity",
    "bots.enter_base_precision": "Enter Base Precision",
    "bots.enter_quote_precision": "Enter Quote Precision",
    "bots.enter_api_key": "Enter API Key",
    "bots.select_type": "Select Type",
    "bots.select_symbol": "Select Symbol",
    "bots.error_name_field": "The name field is required",
    "bots.error_start_sleep_time_field": "The start of sleep time is required",
    "bots.error_end_sleep_time_field": "The end of sleep time field is required",
    "bots.error_symbol_field": "The symbol field is required",
    "bots.error_api_key_field": "The API key field is required",
    "bots.error_secret_key_field": "The secret key field is required",
    "bots.error_min_quote_quantity_field": "The min quote quantity field is required",
    "bots.error_max_quote_quantity_field": "The max quote quantity field is required",
    "bots.error_base_precision_field": "The base precision field is required",
    "bots.error_base_precision_field": "The quote precision field is required",
    "bots.error_percent_field": "The percent field is required",
    "bots.error_quote_field": "The quote field is required",
    "admin.account.title": "Admin Account",
    "admin.account.title_create": "Admin Account: Create",
    "admin.account.title_edit": "Admin Account: Edit",
    "admin.account.role": "Role",
    "admin.account.search_by": "Search by email",
    "admin.account.btn_search": "Search",
    "admin.account.btn_refresh": "Refresh",
    "admin.account.btn_new_account": "New Account",
    "admin.account.btn_cancel": "Cancel",
    "admin.account.btn_update": "Update",
    "admin.account.btn_create": "Create",
    "admin.account.btn_delete": "Delete",
    "admin.account.btn_confirm": "Confirm",
    "admin.account.id": "ID",
    "admin.account.email": "Email",
    "admin.account.2fa": "2FA",
    "admin.account.created_time": "Created Time",
    "admin.account.updated_time": "Updated Time",
    "admin.account.action": "Action",
    "admin.account.password": "Password",
    "admin.account.role": "Role",
    "admin.account.enter_password": "Enter Password",
    "admin.account.enter_email": "Enter Email",
    "admin.account.select_role": "Select Role",
    "admin.account.toast_delete_success": "Delete Successful",
    "admin.account.toast_delete_fail": "Delete Failed",
    "admin.account.toast_create_success": "Create Successful",
    "admin.account.toast_create_fail": "Create Failed",
    "admin.account.toast_update_success": "Update Successful",
    "admin.account.toast_update_fail": "Update Failed",
    "admin.account.popup_delete_content": "Are you sure you want to delete this admin account?",
    "admin.account.popup_create_content": "Are you sure you want to create this admin account?",
    "admin.account.popup_update_content": "Are you sure you want to update this admin account?",
    "admin.account.error_password_field": "The password field is required",
    "admin.account.error_email_field": "The email field is required",
    "admin.account.error_role_field": "The role field is required",
    "menu.lang.en": "English",
    "menu.lang.ja": "日本人",
    "menu.lang.vis": "Visayan",
    "menu.lang.il": "Ilocano",
    "menu.lang.tl": "Tagalog",
    "menu.login": "Login",
    "menu.logout": "Logout",
    "menu.news": "News",
    "menu.one_day": "1 Day",
    "menu.one_month": "1 Month",
    "menu.one_week": "1 Week",
    "menu.open_orders": "Open Orders",
    "menu.or": "or",
    "menu.order_history": "Order History",
    "menu.privacy": "Privacy Policy",
    "menu.register": "Register",
    "menu.sales_point": "Sales Point",
    "menu.support": "Support",
    "menu.support_withdrawal": "Withdraw guide",
    "menu.spot_exchange": "Spot Exchange",
    "menu.terms": "Terms Of Use",
    "menu.three_months": "3 Months",
    "menu.trade_history": "Trade History",
    "menu.wallets": "Wallets",
    "menu.withdraw_usd": "Withdrawal USD",
    "menu.withdraw_usd_admin": "Withdraw USD",
    "menu.withdrawals": "Withdrawals",
    "menu.resource": "Resources",
    "menu.resource.networks": "Networks",
    "menu.resource.coins": "Coins",
    "menu.resource.wallets": "Wallets",
    "menu.orderbook_bots": "Orderbook Bots",
    "messages.error.balance_insufficient": "Balance is not enough",
    "messages.error.spot.balance_insufficient":
      "Exchange balance is not enough",
    "messages.error.airdrop.balance_insufficient":
      "Dividend balance is not enough",
    "messages.error.margin.balance_insufficient":
      "Margin balance is not enough",
    "messages.error.main.available_balance_insufficient":
      "Available balance is not enough",
    "messages.error.spot.available_balance_insufficient":
      "Available exchange balance is not enough.",
    "messages.error.airdrop.available_balance_insufficient":
      "Available dividend balance is not enough.",
    "messages.error.margin.available_balance_insufficient":
      "Available margin balance is not enough.",
    "margin.positions": "Positions",
    "margin.trade_history.time": "Time",
    "margin.trade_history.trade_id": "Trade Id",
    "margin.trade_history.buy_order": "Buy Order",
    "margin.trade_history.sell_order": "Sell Order",
    "margin.trade_history.symbol": "Symbol",
    "margin.trade_history.buy_fee": "Buy Fee",
    "margin.trade_history.fee": "Fee",
    "margin.trade_history.sell_fee": "Sell Fee",
    "margin.trade_history.buyer_email": "Buyer Email/Buy Fee",
    "margin.trade_history.seller_email": "Seller Email/Sell Fee",
    "margin.trade_history.price": "Price",
    "margin.trade_history.quantity": "Quantity",
    "margin.trade_history.amount": "Amount",
    "trade.exec_type_sell": "Trading",
    "trade.exec_type_buy": "Trading",
    "trade.exec_type_funding": "Funding",
    "trade.funding_history": "Funding History",
    "margin.trade_history.buy_fee_rate": "Buy Fee Rate",
    "margin.trade_history.fee_rate": "Fee Rate",
    "margin.trade_history.sell_fee_rate": "Sell Fee Rate",
    "margin.trade_history.exec_type": "Exec Type",
    "margin.trade_history.type": "Type",
    "margin.trade_history.buy_account": "Buy Account",
    "margin.trade_history.sell_account": "Sell Account",
    "margin.orders.position.creator_id": "Creator Id",
    "margin.orders.position.owner_id": "User Id",
    "margin.orders.position.owner_email": "Owner Email",
    "margin.orders.position.manager_email": "Manager Email",
    "margin.orders.position.manager_owner_email": "Manage Owner Email",
    "margin.orders.position.commission": "Commission",
    "margin.orders.position.symbol": "Symbol",
    "margin.orders.position.currency": "Currency",
    "margin.orders.position.underlying": "Underlying",
    "margin.orders.position.quote_currency": "Quote Currency",
    "margin.orders.position.leverage": "Leverage",
    "margin.orders.position.unrealised_pnl": "Unrealised PNL",
    "margin.orders.position.opening_qty": "Opening Qty",
    "margin.orders.position.opening_cost": "Opening Cost",
    "margin.orders.position.current_qty": "Size",
    "margin.orders.position.current_cost": "Current Cost",
    "margin.orders.position.realised_cost": "Realised Cost",
    "margin.orders.position.unrealised_cost": "Unrealised Cost",
    "margin.orders.position.risk_limit": "Risk Limit",
    "margin.orders.position.risk_value": "Risk Value",
    "margin.orders.position.deleverage_percentile": "Deleverage Percentile",
    "margin.orders.position.margin": "Margin",
    "margin.orders.position.init_margin": "Init Rate",
    "margin.orders.position.maint_margin": "Maint Rate",
    "margin.orders.position.required_init_margin_percent":
      "Required Init Rate Percent",
    "margin.orders.position.required_maint_margin_percent":
      "Required Maint Rate Percent",
    "margin.orders.position.avg_cost_price": "Avg Cost Price",
    "margin.orders.position.margin_call_price": "Margin Call Price",
    "margin.orders.position.liquidation_price": "Liquidation Price",
    "margin.orders.position.bankrupt_price": "Bankrupt Price",
    "margin.orders.position.mark_price": "Mark Price",
    "margin.orders.position.mark_value": "Mark Value",
    "margin.orders.position.entry_price": "Entry Price",
    "margin.orders.position.entry_value": "Entry Value",
    "margin.orders.position.value": "Value",
    "margin.orders.position.email": "Email",
    "margin.orders.position.open_order_buy_qty": "Open Order Buy Qty",
    "margin.orders.position.open_order_sell_qty": "Open Order Sell Qty",
    "margin.orders.position.open_order_buy_value": "Open Order Buy Value",
    "margin.orders.position.open_order_sell_value": "Open Order Sell Value",
    "margin.orders.position.multiplier": "Multiplier",
    "margin.orders.position.is_cross": "Is Cross",
    "margin.orders.position.created_at": "Time",
    "margin.orders.trade_history.buy_owner_email": "Buy Owner Email",
    "margin.orders.trade_history.owner_email": "Owner Email",
    "margin.orders.trade_history.sell_owner_email": "Sell Owner Email",
    "margin.orders.trade_history.buy_manager_email": "Buy Manager Email",
    "margin.orders.trade_history.sell_manager_email": "Sell Manager Email",
    "margin.orders.trade_history.manage_sell_owner_email":
      "Manage Sell Owner Email",
    "margin.orders.trade_history.manage_buy_owner_email":
      "Manage Buy Owner Email",
    "margin.table_trade_history.empty_msg": "You have no trade history.",
    "margin.table_funding_history.empty_msg": "You have no funding history.",
    "margin.table_order_history.empty_msg": "You have no order history.",
    "margin.table_order_active.empty_msg": "You have no active order.",
    "margin.table_position.empty_msg": "You have no position.",
    "margin.limit_id": "Limit_{id}",
    "margin.stop_limit_id": "Stop_Limit_{id}",
    "margin.liquidation": "Liquidation",
    "margin.insurance_liquidation": "Insurance Liquidation",
    "margin.insurance_funding": "Insurance Funding",
    "margin.balance": "Margin Balance",
    "margin.available_balance": "Available Margin Balance",
    "messages.error.buy.empty_base_price": "Please input buy stop price.",
    "messages.error.buy.empty_limit": "Please input buy limit price.",
    "messages.error.buy.empty_price": "Please input buy price.",
    "messages.error.buy.empty_quantity": "Please input buy amount.",
    "messages.error.empty_total": "Total is required",
    "messages.error.minimum_base_price":
      "Stop price must be at least {minimum}.",
    "messages.error.minimum_limit": "Limit must be at least {minimum}.",
    "messages.error.minimum_price": "Price must be at least {minimum}.",
    "messages.error.minimum_quantity": "Amount must be at least {minimum}.",
    "messages.error.minimum_total": "Total must be at least {minimum}.",
    "messages.error.sell.empty_base_price": "Please input sell stop price.",
    "messages.error.sell.empty_limit": "Please input sell limit price.",
    "messages.error.sell.empty_price": "Please input sell price.",
    "messages.error.sell.empty_quantity": "Please input sell amount.",
    "messages.insufficient_balance": "Insufficient balance.",
    "messages.send_contact_success": "Send email is successful.",
    "messages.send_contact_wrong_data": "Wrong data.",
    "messages.sesstion_terminated":
      "Your current session is terminated because someone logged into this account from another device or browser.",
    "messages.successful_login":
      "{APP_NAME} login {email}\nLogin from {device}\n{time}",
    "messages.unauthorize": "Unauthorized",
    "messages.your_otp_code": "Your authentication code is {otp}",
    "messages.disable_coin_msg": "Contract Frozen, Waiting for Upgrade",
    "modal_trading_pair_information.amount_limit": "Amount limit",
    "modal_trading_pair_information.btn_cancel": "CANCEL",
    "modal_trading_pair_information.btn_submit": "SUBMIT",
    "modal_trading_pair_information.day": "day",
    "modal_trading_pair_information.days": "days",
    "modal_trading_pair_information.fee": "Fee",
    "modal_trading_pair_information.taker": "Taker Fee",
    "modal_trading_pair_information.market_price": "Market Price",
    "modal_trading_pair_information.minimum_amount": "Minimum Amount",
    "modal_trading_pair_information.quantity_precision": "Quantity Precision",
    "modal_trading_pair_information.minimum_total": "Minimum Total",
    "modal_trading_pair_information.pair": "Pair",
    "modal_trading_pair_information.popup_title": "Trading Pair Information",
    "modal_trading_pair_information.precision": "Precision",
    "modal_trading_pair_information.time_reset": "Time reset",
    "modal_trading_pair_information.total_limit": "Total limit",
    "modal_trading_pair_information.trading_fee": "Trading Fee",
    "modal_trading_pair_information.trading_limit": "Trading limit",
    "modal_trading_pair_information.trading_pair": "Trading Pair",
    "modal.change_password": "Change Password",
    "common.validate.confirm_password": "confirm password",
    "order.open_order.no_data": "You have no open order.",
    "order.open_order.action": "Action",
    "order.open_order.amount": "Amount",
    "order.open_order.avg_entry_price": "Avg. Entry Price",
    "order.open_order.avg_exit_price": "Avg. Exit Price",
    "order.open_order.cancel": "Cancel",
    "order.open_order.cancel_all": "Cancel All",
    "order.open_order.cancel_all_message": "Do you want to cancel all orders?",
    "order.open_order.cancel_limit_order": "Cancel Limit Order",
    "order.open_order.create_time": "Create Time",
    "order.open_order.cancel_limit_price_message":
      "Do you want to cancel all Limit Orders?",
    "order.open_order.cancel_one_message": "Do you want to cancel this order?",
    "order.open_order.cancel_order_success": "Your order has been canceled",
    "order.open_order.cancel_orders_success": "Your orders have been canceled",
    "order.open_order.cancel_stop_limit_order": "Cancel Stop Limit Order",
    "order.open_order.cancel_stop_limit_price_message":
      "Do you want to cancel all Stop Limit Orders?",
    "order.open_order.cancel_stop_market_order": "Cancel Stop Market Order",
    "order.open_order.cancel_stop_market_price_message":
      "Do you want to cancel all Stop Market Orders?",
    "order.open_order.date": "Date",
    "order.open_order.deposit": "Deposit",
    "order.open_order.empty_open_order": "You don't have open order.",
    "order.open_order.filled": "Filled",
    "order.open_order.filled_quantity": "Filled/Quantity",
    "order.open_order.hide_other_pairs": "Hide other pairs",
    "order.open_order.hide_small_assets": "Hide small assets",
    "order.open_order.id_account": "User ID",
    "order.open_order.id_buy": "Buy ID",
    "order.open_order.id_order": "Order ID",
    "order.open_order.id_position": "Position ID",
    "order.open_order.id_sell": "Sell ID",
    "order.open_order.id_trade": "Trade ID",
    "order.open_order.infomation": "Information",
    "order.open_order.limit_order": "Limit Order",
    "order.open_order.liq_price": "Liq. Price",
    "order.open_order.market": "Market",
    "order.open_order.margin": "Margin",
    "order.open_order.open_order": "Open Order",
    "order.open_order.pair": "Pair",
    "order.open_order.position": "Position",
    "order.open_order.price": "Price",
    "order.open_order.quantity": "Quantity",
    "order.open_order.qty": "Qty",
    "order.open_order.select_future": "Select Future",
    "order.open_order.realized_pnl": "Reallized Pnl",
    "order.open_order.side": "Side",
    "order.open_order.size": "Size",
    "order.open_order.stop_limit_order": "Stop_Limit Order",
    "order.open_order.symbol": "Symbol",
    "order.open_order.tiered": "Tiered MMR",
    "order.open_order.total": "Total",
    "order.open_order.time": "Time",
    "order.open_order.time_open": "Time Open",
    "order.open_order.time_close": "Time Close",
    "order.open_order.trigger": "Trigger",
    "order.open_order.trigger_conditions": "Trigger Conditions",
    "order.open_order.type": "Type",
    "order.open_order.roe": "ROE",
    "order.open_order.withdrawal": "Withdrawal",
    "order.open_order.withdrawal_limit_24": "24 Withdrawal Limit",
    "order.open_order.withdrawal": "Withdrawal",
    "order.order_book.amount": "Amount",
    "order.order_book.decimals": "decimals",
    "order.order_book.price": "Price",
    "order.order_book.total": "Total",
    "order.order_form.unrealized": "Unrealized Pnl",
    "order.order_form.roe": "ROE",
    "order.order_form.balance": "{param} Balance",
    "order.order_form.buy": "Buy",
    "order.order_form.limit": "Limit",
    "order.order_form.limited_price": "Limit",
    "order.order_form.login": "Login",
    "order.order_form.or": "or",
    "order.order_form.price": "Price",
    "order.order_form.register": "Register",
    "order.order_form.sell": "Sell",
    "order.order_form.stop": "Stop",
    "order.order_form.stop_limit_tooltip":
      "A Stop-Limit order is an order to buy or sell a coin with a given price once the price reaches a specified trigger price.",
    "order.order_form.stop_market_tooltip":
      "A Stop-Market order is an order to buy or sell a coin with a market price once the price reaches a specified trigger price.",
    "order.order_form.success": "{trade_type} order is created successfully!",
    "order.order_form.total": "Total",
    "order.order_form.trade": "to trade.",
    "order.order_form.view_more": "View more...",
    "order.order_history.action": "Action",
    "order.order_history.no_data": "You have no order history.",
    "order.order_history.all": "All",
    "order.order_history.amount": "Amount",
    "order.order_history.average": "Average",
    "order.order_history.buy": "Buy",
    "order.order_history.cancel": "Cancel",
    "order.order_history.success": "Success",
    "order.order_history.cancel_all": "Cancel All",
    "order.order_history.coin": "Coin",
    "order.order_history.date": "Date",
    "order.order_history.deposit": "Deposit",
    "order.order_history.empty_order_history": "You don't have order history.",
    "order.order_history.export_complete_order_history":
      "Export Complete Order History",
    "order.order_history.export_complete_order_history_tooltip":
      "Only Export Complete Order History on 6 months",
    "order.order_history.export_complete_trade_history":
      "Export Complete Trade History",
    "order.order_history.export_complete_trade_history_tooltip":
      "Only Export Complete Trade History on 6 months",
    "order.order_history.fee": "Fee",
    "order.order_history.filled": "Filled",
    "order.order_history.hide_all_canceled": "Hide All Canceled",
    "order.order_history.infomation": "Information",
    "order.order_history.limit_order": "Limit Order",
    "order.order_history.market": "Market",
    "order.order_history.order_history": "Order History",
    "order.order_history.pair": "Pair",
    "order.order_history.price": "Price",
    "order.order_history.reset": "Reset",
    "order.order_history.download": "Download",
    "order.order_history.search": "Search",
    "order.order_history.sell": "Sell",
    "order.order_history.side": "Side",
    "order.order_history.status": "Status",
    "order.order_history.stop_limit_order": "Stop_Limit Order",
    "order.order_history.total": "Total",
    "order.order_history.trigger_conditions": "Trigger Conditions",
    "order.order_history.type": "Type",
    "order.order_history.withdrawal": "Withdrawal",
    "order.order_history.withdrawal_limit_24": "24 Withdrawal Limit",
    "order.recent_trades.market": "Market",
    "order.recent_trades.recent_trades": "Recent Trades",
    "order.recent_trades.yours": "Yours",
    "order.trade_history.action": "Action",
    "order.trade_history.no_data": "You have no trade history.",
    "order.trade_history.all": "All",
    "order.trade_history.amount": "Amount",
    "order.trade_history.average": "Average",
    "order.trade_history.buy": "Buy",
    "order.trade_history.cancel": "Cancel",
    "order.trade_history.cancel_all": "Cancel All",
    "order.trade_history.coin": "Coin",
    "order.trade_history.date": "Date",
    "order.trade_history.deposit": "Deposit",
    "order.trade_history.export_complete_trade_history":
      "Export Complete Trade History",
    "order.trade_history.fee": "Fee",
    "order.trade_history.filled": "Filled",
    "order.trade_history.hide_all_canceled": "Hide All Canceled",
    "order.trade_history.infomation": "Information",
    "order.trade_history.limit_order": "Limit Order",
    "order.trade_history.market": "Market",
    "order.trade_history.my_history": "My order history",
    "order.trade_history.pair": "Pair",
    "order.trade_history.price": "Price",
    "order.trade_history.reset": "Reset",
    "order.trade_history.search": "Search",
    "order.trade_history.sell": "Sell",
    "order.trade_history.side": "Side",
    "order.trade_history.status": "Status",
    "order.trade_history.stop_limit_order": "Stop_Limit Order",
    "order.trade_history.total": "Total",
    "order.trade_history.trade_history": "Trade History",
    "order.trade_history.trigger_conditions": "Trigger Conditions",
    "order.trade_history.type": "Type",
    "order.trade_history.withdrawal": "Withdrawal",
    "order.trade_history.withdrawal_limit_24": "24 Withdrawal Limit",
    "order_mobile.charts": "Charts",
    "order_mobile.group": "Group",
    "order_mobile.max_amount": "Max Amount",
    "order_mobile.open_order": "Open Order",
    "order_mobile.total": "Total",
    "order_mobile.trade": "Trade",
    "passwords.same_old_password": "New password cannot be the same as old.",
    "passwords.password":
      "Passwords must be at least six characters and match the confirmation.",
    "passwords.reset": "Your password has been reset, now you can login.",
    "passwords.sent": "We have e-mailed your password reset link!",
    "passwords.token": "This password reset token is invalid.",
    "passwords.user": "We can't find a user with that e-mail address.",
    "refferal.alert":
      "You are not logged in, please login and share with your friends.",
    "refferal.alert_bank_account_content":
      'Please submit <span style="color: #f29600;">KYC</span> before verify NI Bank account. We’ll use your KYC information to verify bank account',
    "refferal.confirm_bank_account_content":
      "I have not had NI Bank account yet, please help me to creat one",
    "refferal.confirm_bank_account_title":
      "Do you want to verify NI Bank account for USD withdrawals using KYC information?",
    "refferal.get_rewards": "Get Rewards",
    "refferal.table.empty_msg": "You have no referral history.",
    "refferal.login": "Login",
    "refferal.not_on_site": "Not on {APP_NAME} yet? ",
    "refferal.register": "Register",
    "refferal.copy_link": "Copy Link",
    "refferal.text1": "Register and generate referral links and QR codes ",
    "refferal.text10": "Referral Friends",
    "refferal.text11": "Date",
    "refferal.text12": "Program Details",
    "refferal.text13": "Effective as of {effectTime} (UTC)",
    "refferal.text14":
      "The commission you receive from the referral program will initially be set at a rate of 20%. For accounts holding 500 {COIN_HOLDING} or more, this rate will increase to 40%.",
    "refferal.text15":
      "The fee commission will be sent instantly in real-time to your {APP_NAME} account as your referee completes each trade and will be paid to you in whatever token/cryptocurrency the original fee was paid in.",
    "refferal.text16":
      "There is no limit to the number of friends you can refer, although we do reserve the right to adjust or change the referral program rules at any time.",
    "refferal.text18":
      "Each referee must be signed up through your Referral Link, QR Code or Referral ID.",
    "refferal.text19":
      "{APP_NAME} will check for duplicate or fake accounts and will not pay out referral bonuses on these accounts. Duplicate or shared finances will result in disqualification.",
    "refferal.text2": "Invite Friends ",
    "refferal.text20": "* Important Notice",
    "refferal.text21":
      "{APP_NAME} reserves the right to change the terms of the referral program at any time due to changing market conditions, risk of fraud, or any other factors we deem relevant.",
    "refferal.text22": "Commission",
    "refferal.text23": "Estimated <br/> Commission Value",
    "refferal.text24": "Latest Commission History",
    "refferal.text25": "Copied",
    "refferal.text26": "Export",
    "refferal.text3":
      "Invite your friends to register through the referral link or QR codes and get rewards once they complete a trade",
    "refferal.text4": "Get Paid",
    "refferal.text5": "Receive up to 40% commission in real-time",
    "refferal.text6": "My Referral ID:",
    "refferal.text7": "Your Commission Rate:",
    "refferal.text8": "Referral Link:",
    "refferal.text9": "Share:",
    "refferal.title": "Referral Program",
    "refferal.title1": "Get Your Link",
    "refferal.top": "NO.",
    "register.error_terms": "Please accept the terms",
    "register.has_account": "Already have an account?",
    "register.sub_title": "Create your account",
    "register.success":
      "<span>Congratulations! </span><strong>{email}</strong><p>Please check your mailbox to verify your account</p>",
    "register.terms_1": "I agree to the",
    "register.terms_2": "terms",
    "register.title": "Register",
    "register.sendConfirmEmail": "Send confirmation email",
    reset_passsword_complete: "Password Reset Complete",
    "reset_password.resetted_password":
      "Your password has been reset, now you can login.",
    "reset_password.send_mail_title": "Send Password Reset Email",
    "reset_password.send_mail_title_2": "A password reset email has been sent",
    "reset_password.sent_mail":
      '<strong style="line-height: 25px;color: #333;font-size: 16px;font-weight: 400">A password reset email has been sent to your registered email address.</strong><br>Please follow the instructions in the email and reset your password within 24 hours of receiving the email.',
    "reset_password.sent_mail_2":
      "Please access the URL and reset your password within 24 hours of receiving the email",
    "reset_password.sub_title_1": "Please input your email",
    "reset_password.sub_title_2": "Enter your new password",
    "reset_password.submit_btn": "Submit",
    "reset_password.title": "Reset Password",
    "reset_password.title_forgot": "Forgot Password",
    sendConfirmEmail: "Send confirmation email",
    "service_center.send_contact_subject": "Send contact to {APP_NAME}",
    "shared_components.alert.error": "Error",
    "shared_components.alert.primary": "Info",
    "shared_components.alert.success": "Success",
    "shared_components.alert.warning": "Warning",
    "shared_components.common.confirmation_modal.awesome": "Awesome",
    "shared_components.common.confirmation_modal.danger": "Danger",
    "shared_components.common.confirmation_modal.info": "Information",
    "shared_components.common.confirmation_modal.warning": "Warning",
    "shared_components.google_authentication_modal.lost_code":
      "Lost Your Google Authentication",
    "shared_components.google_authentication_modal.otp_code_invalid":
      "Google Authentication code is invalid.",
    "shared_components.google_authentication_modal.submit_button": "Submit",
    "shared_components.google_authentication_modal.title":
      "Google Authentication",
    "shared_components.pagination.next": "Next",
    "shared_components.pagination.pre": "Prev",
    "sales_point.sales_point": "Sales Point",
    "sales_point.title": "AMAL Setting",
    "sales_point.price_setting": "Price setting",
    "sales_point.total_amal_sold": "Total AMAL Sold",
    "sales_point.currency": "Currency",
    "sales_point.total_received": "Total Received",
    "sales_point.price_setting.default_price": "Default price",
    "sales_point.price_setting.presenter": "Referrer Price",
    "sales_point.price_setting.presentee": "Referred Price",
    "sales_point.price_setting.total_supply": "Total supply",
    "sales_point.price_setting.remaining_supply": "Remaining supply",
    "sales_point.indeicates_required": "* Indicates required fields",
    "sales_point.price_setting.amal_btc": "AMAL/BTC",
    "sales_point.price_setting.amal_eth": "AMAL/ETH",
    "sales_point.price_setting.amal_usdt": "AMAL/USDT",
    "sales_point.price_setting.asterisk": "*",
    "sales_point.price_setting.usd": " USD",
    "sales_point.price_setting.amal": " AMAL",
    "sales_point.edit": "EDIT",
    "sales_point.submit": "SUBMIT",
    "sales_point.return": "RETURN",
    "sales_point.clear": "Clear",
    "sales_point.update_success_msg": "Updated successfully!",
    "sales_point.update_error_msg": "Unknown error.",
    "sales_point.error.lose_default_price":
      "The default price field is required.",
    "sales_point.error.lose_referrer_commision":
      "The referrer commision field is required.",
    "sales_point.error.lose_referred_bonus":
      "The referred bonus price field is required.",
    "sales_point.error.lose_total_supply":
      "The total supply field is required.",
    "sales_point.error.price_persenter":
      "Value of referrer price must be greater than 0.",
    "sales_point.error.price_persentee":
      "Value of referred price must be greater than 0.",
    "sales_point.error.price_default_price":
      "Value of default price must be greater than 0.",
    "sales_point.error.price_total_supply":
      "Value of total supply must be greater than 0.",
    "sales_point.bonus_level_1": "Bonus level 1",
    "sales_point.bonus_level_2": "Bonus level 2",
    "sales_point.bonus": "Bonus",
    "sales_point.referrer_commision": "Referrer commision",
    "sales_point.referred_bonus": "Referred bonus",
    "sales_point.error.bonus":
      "Both of two bonus fields are required or empty.",
    "sales_point.error.bonus_percent":
      "Percent value must be smaller than or equal to 100.",
    "sales_point.error.bonus_diff_0": "Percent value must be different from 0.",
    "sales_point.error.take_fee_required":
      "The take fee (%) field is required.",
    "sales_point.error.maker_fee_required":
      "The maker fee (%) field is required.",
    "sales_point.error.bonus_diff_precision_0":
      "Value of precision must be greater than 0.",
    "sales_point.error.precision_required": "The precision field is required.",
    "sales_point.error.market_than_0":
      "Value of market price must be greater than 0.",
    "sales_point.error.market_required": "The market price field is required.",
    "sales_point.error.minimum_amount_than_0":
      "Value of minimum amount must be greater than 0.",
    "sales_point.error.minimum_amount_required":
      "The minimum amount field is required.",
    "sales_point.error.quantity_precision_than_0":
      "Value of quantity precision must be greater than 0.",
    "sales_point.error.quantity_precision_required":
      "The quantity precision field is required.",
    "sales_point.error.minimum_total_than_0":
      "Value of minimum total must be greater than 0.",
    "sales_point.error.minimum_total_required":
      "The  minimum total field is required.",
    "sales_point.error.bonus_1_2":
      "Bonus level 1 must be smaller than bonus level 2.",
    "masterdata.text": "Masterdata is changed.</br>Please reload page again.",
    "unregistered_session.login": "Login",
    "unregistered_session.text":
      "Your current session is terminated. Please log in again!",
    "validation.messages._default": "The {0} value is not valid.",
    "validation.messages.after": "The {0} must be after {1}.",
    "validation.messages.after2": "The {0} must be after or equal to {1}.",
    "validation.messages.alpha_dash":
      "The {0} field may contain alpha-numeric characters as well as dashes and underscores.",
    "validation.messages.alpha_num":
      "The {0} field may only contain alpha-numeric characters.",
    "validation.messages.alpha_spaces":
      "The {0} field may only contain alphabetic characters as well as spaces.",
    "validation.messages.alpha":
      "The {0} field may only contain alphabetic characters.",
    "validation.messages.before": "The {0} must be before {1}.",
    "validation.messages.before2": "The {0} must be before or equal to {1}.",
    "validation.messages.between": "The {0} field must be between {1} and {2}.",
    "validation.messages.confirmed": "The {0} confirmation does not match.",
    "validation.messages.confirmed2": "The {0} does not match.",
    "validation.messages.credit_card": "The {0} field is invalid.",
    "validation.messages.date_between": "The {0} must be between {1} and {2}.",
    "validation.messages.date_format": "The {0} must be in the format {1}.",
    "validation.messages.decimal":
      "The {0} field must be numeric and may contain {1} decimal points.",
    "validation.messages.digits":
      "The {0} field must be numeric and exactly contain {1} digits.",
    "validation.messages.dimensions":
      "The {0} field must be {1} pixels by {2} pixels.",
    "validation.messages.email": "The {0} field must be a valid email.",
    "validation.messages.ext": "The {0} field must be a valid file.",
    "validation.messages.image": "The {0} field must be an image.",
    "validation.messages.in": "The {0} field must be a valid value.",
    "validation.messages.integer": "The {0} field must be an integer.",
    "validation.messages.ip": "The {0} field must be a valid ip address.",
    "validation.messages.length": "The {0} length must be {1}.",
    "validation.messages.length2": "The {0} length be between {1} and {2}.",
    "validation.messages.max":
      "The {0} field may not be greater than {1} characters.",
    "validation.messages.max_value": "The {0} field must be {1} or less.",
    "validation.messages.mimes": "The {0} field must have a valid file type.",
    "validation.messages.min": "The {0} field must be at least {1} characters.",
    "entry.error.payout_amount_0":
      "Value of pay amount must be greater than 0.",
    "validation.messages.min_value": "The {0} field must be {1} or more.",
    "validation.messages.not_in": "The {0} field must be a valid value.",
    "validation.messages.numeric":
      "The {0} field may only contain numeric characters.",
    "validation.messages.regex": "The {0} field format is invalid.",
    "validation.messages.required": "The {0} field is required.",
    "validation.messages.size": "The {0} size must be less than {1}.",
    "validation.messages.url": "The {0} field is not a valid URL.",
    "validation.accepted": "The {attribute} must be accepted.",
    "validation.active_url": "The {attribute} is not a valid URL.",
    "validation.after": "The {attribute} must be a date after {date}.",
    "validation.after_or_equal":
      "The {attribute} must be a date after or equal to {date}.",
    "validation.alpha": "The {attribute} may only contain letters.",
    "validation.alpha_dash":
      "The {attribute} may only contain letters, numbers, and dashes.",
    "validation.alpha_num":
      "The {attribute} may only contain letters and numbers.",
    "validation.array": "The {attribute} must be an array.",
    "validation.attributes.account_name": "account name",
    "validation.attributes.account_number": "account number",
    "validation.attributes.bank_id": "bank",
    "validation.attributes.base_price": "STOP Price",
    "validation.attributes.date_of_birth": "birthday",
    "validation.attributes.new_password": "new password",
    "validation.attributes.otp_recovery_code": "authentication code",
    "validation.attributes.password": "password",
    "validation.attributes.quantity": "Amount",
    "validation.attributes.wallet_address": "wallet address",
    "validation.attributes.withdrawal_address": "withdrawal address",
    "validation.attributes.authentication_code": "authentication code",
    "validation.before": "The {attribute} must be a date before {date}.",
    "validation.before_or_equal":
      "The {attribute} must be a date before or equal to {date}.",
    "validation.between.array":
      "The {attribute} must have between {min} and {max} items.",
    "validation.between.file":
      "The {attribute} must be between {min} and {max} kilobytes.",
    "validation.between.numeric":
      "The {attribute} must be between {min} and {max}.",
    "validation.between.string":
      "The {attribute} must be between {min} and {max} characters.",
    "validation.boolean": "The {attribute} field must be true or false.",
    "validation.confirmed": "The {attribute} confirmation does not match.",
    "validation.correct_otp": "The authentication code is incorrect.",
    "validation.custom.agree_term.required":
      "You must read and agree with Term of Use.",
    "validation.custom.attribute-name.rule-name": "custom-message",
    "validation.custom.base_price.min": "Stop price must be at least {min}",
    "validation.custom.base_price.precision":
      "Stop price must have the precision of {precision}",
    "validation.custom.id_back.image": "File must be an image.",
    "validation.custom.id_back.mimes": "File must be a file of type: {values}.",
    "validation.custom.id_front.image": "File must be an image.",
    "validation.custom.id_front.mimes":
      "File must be a file of type: {values}.",
    "validation.custom.id_selfie.image": "File must be an image.",
    "validation.custom.id_selfie.mimes":
      "File must be a file of type: {values}.",
    "validation.custom.image": "File must be an image.",
    "validation.custom.image.mimes": "File must be a file of type: {values}.",
    "validation.custom.password.confirmed":
      "Passwords must match the password prompt.",
    "validation.custom.password.correct_password": "The password is incorrect",
    "validation.custom.password.min":
      "Passwords must contain at least 8 characters, capital letters, lowercase letters, numbers and must match the duplicate password box.",
    "validation.custom.password.regex":
      "Passwords must contain at least 8 characters, capital letters, lowercase letters, numbers and must match the duplicate password box.",
    "validation.custom.price.min": "Price must be at least {min}",
    "validation.custom.price.precision":
      "Price must have the precision of {precision}",
    "validation.custom.quantity.min": "Amount must be at least {min}",
    "validation.custom.quantity.precision":
      "Amount must have the precision of {precision}",
    "validation.custom.total.min": "Total must be at least {min}",
    "validation.custom.total.precision":
      "Total must have the precision of {precision}",
    "validation.date": "The {attribute} is not a valid date.",
    "validation.date_format":
      "The {attribute} does not match the format {format}.",
    "validation.different": "The {attribute} and {other} must be different.",
    "validation.digits": "The {attribute} must be {digits} digits.",
    "validation.digits_between":
      "The {attribute} must be between {min} and {max} digits.",
    "validation.dimensions": "The {attribute} has invalid image dimensions.",
    "validation.distinct": "The {attribute} field has a duplicate value.",
    "validation.email": "The {attribute} must be a valid email address.",
    "validation.exists": "The selected {attribute} is invalid.",
    "validation.file": "The {attribute} must be a file.",
    "validation.filled": "The {attribute} field must have a value.",
    "validation.image": "The {attribute} must be an image.",
    "validation.in": "The selected {attribute} is invalid.",
    "validation.in_array": "The {attribute} field does not exist in {other}.",
    "validation.integer": "The {attribute} must be an integer.",
    "validation.ip": "The {attribute} must be a valid IP address.",
    "validation.ipv4": "The {attribute} must be a valid IPv4 address.",
    "validation.ipv6": "The {attribute} must be a valid IPv6 address.",
    "validation.is_withdrawal_address": "The {attribute} is invalid.",
    "validation.json": "The {attribute} must be a valid JSON string.",
    "validation.max.array":
      "The {attribute} may not have more than {max} items.",
    "validation.max.file":
      "The {attribute} may not be greater than {max} kilobytes.",
    "validation.max.numeric": "The {attribute} may not be greater than {max}.",
    "validation.max.string":
      "The {attribute} may not be greater than {max} characters.",
    "validation.mimes": "The {attribute} must be a file of type: {values}.",
    "validation.mimetypes": "The {attribute} must be a file of type: {values}.",
    "validation.min.array": "The {attribute} must have at least {min} items.",
    "validation.min.file": "The {attribute} must be at least {min} kilobytes.",
    "validation.min.numeric": "The {attribute} must be at least {min}.",
    "validation.min.string":
      "The {attribute} must be at least {min} characters.",
    "validation.min_value": "{attribute} must be at least {min}",
    "validation.not_in": "The selected {attribute} is invalid.",
    "validation.numeric": "The {attribute} must be a number.",
    "validation.otp": "Authentication failed.",
    "validation.otp_not_used":
      "Please wait for next verification code to generate.",
    "validation.otp_or_google_auth_required":
      "You need to enter Google Authentication Code",
    "validation.otp_incorrect": "The authentication code is incorrect.",
    "validation.password_white_space":
      "Password should not contain whitespace.",
    "validation.precision":
      "{attribute} must have the precision of {precision}",
    "validation.present": "The {attribute} field must be present.",
    "validation.regex": "The {attribute} format is invalid.",
    "validation.required": "The {attribute} field is required.",
    "validation.required_if":
      "The {attribute} field is required when {other} is {value}.",
    "validation.required_unless":
      "The {attribute} field is required unless {other} is in {values}.",
    "validation.required_with":
      "The {attribute} field is required when {values} is present.",
    "validation.required_with_all":
      "The {attribute} field is required when {values} is present.",
    "validation.required_without":
      "The {attribute} field is required when {values} is not present.",
    "validation.required_without_all":
      "The {attribute} field is required when none of {values} are present.",
    "validation.same": "The {attribute} and {other} must match.",
    "validation.size.array": "The {attribute} must contain {size} items.",
    "validation.size.file": "The {attribute} must be {size} kilobytes.",
    "validation.size.numeric": "The {attribute} must be {size}.",
    "validation.size.string": "The {attribute} must be {size} characters.",
    "validation.string": "The {attribute} must be a string.",
    "validation.timezone": "The {attribute} must be a valid zone.",
    "validation.unique": "The {attribute} has already been taken.",
    "validation.unique_email": "The email has already been taken.",
    "validation.unique_referrer_code": "The referral code is invalid.",
    "validation.unique_withdrawal_address_in_user":
      "The withdrawal address has already been existed.",
    "validation.uploaded": "The {attribute} failed to upload.",
    "validation.url": "The {attribute} format is invalid.",
    "validation.verified_email": "The email hasn't been confirmed yet.",
    "validation.verify_otp_or_google_auth":
      "You need to enter Google Authentication Code field exactly",
    "validation.verify_otp_recovery_code": "The {attribute} is invalid.",
    "validation.verify_otp_recovery_code_with_auth":
      "The {attribute} is incorrect.",
    "validation.authen_code_required":
      "The authentication code field is required.",
    "errors.required": "This field is required",
    "validation.unverified_email":
      "Please verify your email to active account.",
    "withdraw_guide.currency": "Currency",
    "withdraw_guide.deposit_fee": "Deposit fee",
    "withdraw_guide.email": "Email verification",
    "withdraw_guide.fee": "Fee",
    "withdraw_guide.fee_withdrawal": "Withdrawal fee",
    "withdraw_guide.free": "Free",
    "withdraw_guide.gotp": "Google OTP",
    "withdraw_guide.identity_verification": "Identity Verification",
    "withdraw_guide.level": "Step break",
    "withdraw_guide.level_name": "Level ",
    "withdraw_guide.level_title": "Current certification level is",
    "withdraw_guide.limit_tip1":
      "The withdrawal limit is different depending on the individual authentication level.",
    "withdraw_guide.limit_tip2":
      "Depending on the market situation and the regulations of the relevant bank (bank), the above withdrawal limit may be changed without prior notice.",
    "withdraw_guide.limit_tip3":
      "If we suspect fraudulent transactions, withdrawals may be restricted.",
    "withdraw_guide.limit_tip4":
      "The withdrawal fee will be charged at a fixed amount, regardless of the amount of the currency",
    "withdraw_guide.limit_tip5":
      "In the case of a crypto-currency withdrawal, the actual fee may vary or be higher or lower than the displayed fee depending on the status of the block chain network.",
    "withdraw_guide.limit_title1":
      "1. Limit of withdrawal by authentication step",
    "withdraw_guide.limit_title2":
      "2. Deposit and withdrawal fee and minimum withdrawal amount",
    "withdraw_guide.minimum_withdrawal": "Minimum withdrawal amount",
    minimum_withdrawal: "level",
    "withdraw_guide.one_day": "1 day",
    "withdraw_guide.one_time": "1 time",
    "withdraw_guide.phone": "Phone authentication",
    "withdraw_guide.security_method": "Authentication method",
    "withdraw_guide.support_withdrawal": "Deposit and withdrawal instructions",
    "withdraw_guide.usd": "usd",
    "trade_type.buy": "Buy",
    "trade_type.sell": "Sell",
    "common.message.network_error": "Cannot connect to server.",
    "common.status.success": "Success",
    "common.status.pending": "Pending",
    "common.status.cancel": "Canceled",
    "common.status.error": "Error",
    "common.search": "Search",
    "common.action.confirm": "Confirm",
    "common.action.reject": "Reject",
    "common.action.cancel": "Cancel",
    "common.action.create": "Create",
    "common.kyc.pending": "Pending",
    "common.kyc.verified": "Verified",
    "common.empty_data": "Data empty.",
    "menu.orders.orders": "Orders",
    "menu.orders.spot": "Spot",
    "menu.orders.open_order": "Open Order",
    "menu.orders.order_book": "Order Book",
    "menu.orders.order_history": "Order History",
    "menu.orders.trade_history": "Trade History",
    "menu.notices": "Notices",
    "menu.email_marketing": "Email Marketing",
    "menu.home": "Home",
    "usd_transaction.time": "Time",
    "usd_transaction.user": "Email",
    "usd_transaction.user1": "User",
    "usd_transaction.account": "Account",
    "usd_transaction.account_name": "Account Name",
    "usd_transaction.bank_name": "Bank",
    "usd_transaction.bank_branch": "Branch",
    "usd_transaction.status": "Status",
    "usd_transaction.amount": "Amount",
    "transactions.deposit_usd.yes": "YES",
    "transactions.deposit_usd.no": "NO",
    "transactions.deposit_usd.confirm":
      "Do you want to confirm this transaction?",
    "transactions.deposit_usd.reject":
      "Do you want to reject this transaction?",
    "transactions.withdrawal.send": "Do you want to send this transaction?",
    "transactions.withdrawal.cancel": "Do you want to cancel this transaction?",
    "kyc.list": "List KYC",
    "kyc.input_search": "Search",
    "kyc.created_at": "Time",
    "kyc.email": "Email",
    "kyc.name": "Full Name",
    "kyc.country": "Country",
    "kyc.id_number": "Passport/ID Number",
    "kyc.status": "Status",
    "kyc.gender": "Gender",
    "kyc.id_front": "Identity Card Front Side/Passport cover",
    "kyc.id_back": "Identity Card Back Side/Passport data page",
    "kyc.id_selfie": "Selfie With Photo ID And Note",
    "kyc.btn_detail": "Detail",
    "kyc.btn_verify": "Verify",
    "kyc.btn_reject": "Reject",
    "kyc.notice_verify_confirm": "Do you want to confirm this KYC?",
    "kyc.notice_reject_confirm": "Do you want to reject this KYC?",
    "kyc.detail_kyc": "Detail KYC",
    "kyc.msg_verified":
      "KYC is approved by the administrator. You can't change the information.",
    "kyc.msg_verified.successfully": "Verified successfully!",
    "kyc.msg_rejected.successfully": "Rejected successfully!",
    "kyc.search": "Search",
    "kyc.reset": "Reset",
    "kyc.data": "Data",
    "kyc.action": "Action",
    "kyc.status_pending": "Pending",
    "kyc.status_verified": "Verified",
    "kyc.status_unverified": "Unverified",
    "kyc.status_rejected": "Rejected",
    "kyc.account_id": "User ID",
    "user.id": "ID",
    "user.user": "User",
    "user.level": "Level",
    "user.kyc_status": "KYC Status",
    "user.fa": "2FA",
    "user.active_status": "Active Status",
    "user.user_information": "User Information",
    "user.amal_net_title": "User AMAL Net Ranking",
    "user.profit": "Exchange Profit Detail",
    "user.receive_fee": "Receive Fee",
    "user.referral_fee": "Referral Fee",
    "user.amal_in": "AMAL Buy",
    "user.amal_out": "AMAL Sell",
    "user.amal_net": "AMAL Net",
    "user.ranking": "Ranking",
    "user.date": "Date",
    "user.email": "Email",
    "user.fee_level": "Fee Level",
    "user.security_level": "Security Level",
    "user.max_security_level": "Max Security Level",
    "user.referrer_code": "Referral Code",
    "user.referrer_mail": "Referrer Mail",
    "user.type": "Type",
    "user.status": "Status",
    "user.group": "Group",
    "user.memo": "Memo",
    "user.normal": "normal",
    "user.bot": "bot",
    "user.inactive": "inactive",
    "user.active": "active",
    "user.enable_fee.enable": "enable",
    "user.enable_fee.disable": "disable",
    "user.save": "Save",
    "user.edit": "Edit",
    "user.device": "Device",
    "user.recent_activity": "Recent Activity",
    "user.action.add_to_group": "Add to group",
    "user.action.delete_from_group": "Delete from group",
    "user.alert.no_user_selected": "You must select at least one user!",
    "user.alert.no_group_selected": "You must select at least one group!",
    "device_management.title_device_management": "Device Management",
    "user.succes": "Updated successfully!",
    "user.user_setting": "User Setting",
    "mam.manage_sub_orders": "Manage Sub Orders",
    "mam.mam_funds": "Funds",
    "mam.join": "Join",
    "notice.edit_title_page": "Update Notices",
    "notice.new_title_page": "Create Notices",
    "notice.title": "Title",
    "notice.date": "Date",
    "notice.search": "Search",
    "notice.reset": "Reset",
    "notice.new": "New",
    "notice.support": "Link Support",
    "notice.start": "Started",
    "notice.end": "Ended",
    "notice.edit": "Edit",
    "notice.delete": "Delete",
    "notice.start_at": "Start at",
    "notice.end_at": "End at",
    "notice.banner": "Banner",
    "notice.submit": "Submit",
    "notice.cancel": "Cancel",
    "notice.confirm": "Confirm",
    "notice.search_by_title": "Search by title...",
    "notice.choose_file": "Choose File",
    "notice.delete_data": "Do you want to delete this notice?",
    "notice.submit_data": "Do you want to submit this notice?",
    "notice.errors.required": "This field is required.",
    "notice.errors.title.required": "The title field is required.",
    "notice.errors.support_url.required": "The link support field is required.",
    "notice.errors.banner_url.required": "The banner field is required.",
    "notice.errors.maxsize": "Size image must be less than 10MB.",
    "notice.errors.date":
      "The date range is invalid. The end date is smaller than the start date!",
    "notice.noticeEdit": "Edit notice",
    "qna.submit_data": "Do you want to submit this answer?",
    "notice.banner_url": "Banner",
    "notice.validate.image_type":
      "File must be a file of type: .jpg, .jpeg, .png.",
    "orders.open_order.open_order": "Open Order",
    "orders.open_order.date": "Date",
    "orders.open_order.from": "From",
    "orders.open_order.to": "To",
    "orders.open_order.pair": "Pair",
    "orders.open_order.symbol": "Symbol",
    "orders.open_order.country": "Country",
    "orders.open_order.data": "Data",
    "orders.open_order.search_by_email": "Search by email",
    "orders.open_order.search_by_email_id": "Search by email or id",
    "orders.open_order.search_by_coin": "Search by coin",
    "orders.open_order.search": "Search",
    "orders.open_order.reset": "Refresh",
    "orders.open_order.time": "Time",
    "orders.open_order.trade_id": "Trade Id",
    "orders.open_order.order_id": "Order Id",
    "orders.open_order.email": "Email",
    "orders.open_order.price": "Price",
    "orders.open_order.filled": "Filled",
    "orders.open_order.fee": "Fee",
    "orders.open_order.total": "Total",
    "orders.open_order.type": "Type",
    "orders.open_order.order_transaction_id": "Order Transaction ID",
    "orders.open_order.transaction_owner": "Transaction Owner",
    "orders.open_order.created_date": "Created Date",
    "orders.open_order.action": "Action",
    "orders.open_order.amount": "Amount",
    "orders.open_order.trigger_condition": "Trigger condition",
    "orders.open_order.status": "Status",
    "orders.open_order.average": "Average",
    "orders.open_order.side": "Side",
    "orders.open_order.buy": "Buy",
    "orders.open_order.sell": "Sell",
    "orders.open_order.all": "All",
    "orders.open_order.trade_history": "Trade History",
    "orders.open_order.index": "No.",
    "orders.open_order.view_detail": "View Detail",
    "orders.open_order.market": "Market",
    "orders.open_order.cancel_successful": "Cancel Successful",
    "orders.open_order.cancel_failed": "Cancel Failed",
    "orders.open_order.cancel_modal_content": "Can you cancel this order?",
    "orders.order_book.order_id": "Open ID",
    "orders.order_book.price": "Price",
    "orders.order_book.quantity": "Quantity",
    "orders.order_book.total": "Total",
    "orders.order_history.order_history": "Order History",
    "orders.order_history.date": "Date",
    "orders.order_history.pair": "Pair",
    "orders.order_history.search_by_email": "Search by email",
    "orders.order_history.search": "Search",
    "orders.order_history.reset": "Reset",
    "orders.order_history.time": "Time",
    "orders.order_history.trade_id": "Trade Id",
    "orders.order_history.order_id": "Order Id",
    "orders.open_order.account_id": "User ID",
    "orders.open_order.quantity": "Quantity",
    "orders.open_order.trigger": "Trigger",
    "orders.order_history.email": "Email",
    "orders.order_history.price": "Price",
    "orders.order_history.filled": "Filled",
    "orders.order_history.fee": "Fee",
    "orders.order_history.total": "Total",
    "orders.order_history.type": "Type",
    "orders.order_history.action": "Action",
    "orders.order_history.amount": "Amount",
    "orders.order_history.trigger_condition": "Trigger condition",
    "orders.order_history.status": "Status",
    "orders.order_history.average": "Avg. Price",
    "orders.order_history.quantity": "Quantity",
    "orders.order_history.account_id": "User ID",
    "orders.order_history.side": "Side",
    "orders.order_history.buy": "Buy",
    "orders.order_history.sell": "Sell",
    "orders.order_history.all": "All",
    "orders.order_history.hide_all_canceled": "Hide All Canceled",
    "orders.order_history.trade_history": "Trade History",
    "orders.order_history.index": "No.",
    "orders.order_history.view_detail": "Detail",
    "orders.order_history.market": "Market",
    "orders.trade_history.trade_history": "Trade History",
    "orders.trade_history.date": "Date",
    "orders.trade_history.pair": "Pair",
    "orders.trade_history.search_by_email": "Search by email",
    "orders.trade_history.search": "Search",
    "orders.trade_history.reset": "Reset",
    "orders.trade_history.time": "Time",
    "orders.trade_history.trade_id": "Trade Id",
    "orders.trade_history.original_buy_order_id": "Original Buy Order Id",
    "orders.trade_history.buyer_email": "Buyer Email",
    "orders.trade_history.original_sell_order_id": "Original Sell Order Id",
    "orders.trade_history.seller_email": "Seller Email",
    "orders.trade_history.price": "Price",
    "orders.trade_history.filled": "Filled",
    "orders.trade_history.buy_fee": "Buy Fee",
    "orders.trade_history.sell_fee": "Sell Fee",
    "orders.trade_history.total": "Total",
    "orders.trade_history.market": "Market",
    "orders.trade_history.all": "All",
    "orders.trade_history.data": "Data",
    "orders.trade_history.account_id": "User ID",
    "orders.trade_history.buy_id": "Buy ID",
    "orders.trade_history.sell_id": "Sell ID",
    "orders.trade_history.side": "Side",
    "orders.trade_history.quantity": "Quantity",
    "orders.trade_history.action": "Action",
    "account.status.active": "Active",
    "account.status.inactive": "Inactive",
    "account.list.level": "Level",
    "account.list.kyc_status": "KYC Status",
    "account.list.fa": "2FA",
    "account.list.active_status": "Active Status",
    "account.list.search_by": "Search by account Id, email",
    "account.account_detail.message_change_status_success": "Change activation status successfully!",
    "account.account_detail.message_change_status_fail": "Change activation status failed!",
    "account.account_detail.joined_time": "Joined time",
    "account.account_detail.activation_status": "Activation Status",
    "account.account_detail.email": "Email",
    "account.account_detail.phone_number": "Phone Number",
    "account.account_detail.level": "Level",
    "account.account_detail.kyc_status": "KYC Status",
    "account.account_detail": "Account Detail",
    "account.account_detail.account_id": "User ID",
    "account.account_detail.spot_future": "Spot/Futures Profile",
    "account.account_detail.balance": "Balance",
    "account.account_detail.logs": "Logs",
    "account.account_detail.transactions": "Transactions",
    "account.account_detail.affiliate": "Affiliate",
    "account.account_detail.activity_history": "Activity History",
    "account.account_detail.open_order": "Open Order",
    "account.account_detail.order_history": "Order History",
    "account.account_detail.trade_history": "Trade History",
    "account.account_detail.update": "Update",
    "account.account_detail.confirm_active": "Are you sure you want to active this account?",
    "account.account_detail.confirm_deactive": "Are you sure you want to inactive this account?",
    "account.spot_future.confirm_update_spot": "Are you sure you want to update spot profile?",
    "account.spot_future.confirm_update_futures": "Are you sure you want to update futures profile?",
    "account.spot_future.spot_profile": "Spot Profile",
    "account.spot_future.future_profile": "Future Profile",
    "account.spot_future.allow_trade": "Allow Trade",
    "account.spot_future.market_maker": "Maket Maker Account",
    "account.spot_future.trading_fee": "Trading Fee",
    "account.spot_future.pre_trading": "Pre-trading Pairs",
    "account.spot_future.popup_text_content": "Are you sure you want to deactivate this account?",
    "account.spot_future.update_successful": "Update Successful!",
    "account.spot_future.update_failed": "Update Failed!",
    "account.account_detail.balance": "Balance",
    "account.account_detail.coin": "Coin",
    "account.account_detail.in_order": "In Order",
    "account.account_detail.available": "Available",
    "account.account_detail.total": "Total",
    "account.account_detail.action": "Action",
    "account.account_detail.balance_spot": "Spot Balance",
    "account.account_detail.balance_future": "Futures Balance",
    "account.logs.spot": "Spot Balance Logs",
    "account.logs.futures": "Futures Balance Logs",
    "account.logs.id": "ID",
    "account.logs.type": "Type",
    "account.logs.amount": "Amount",
    "account.logs.note": "Note",
    "account.logs.create_time": "Created time",
    "account.transaction.withdraw_transaction": "Withdraw Transaction",
    "account.transaction.deposit_transaction": "Deposit Transaction",
    "account.transaction.transfer_transaction": "Transfer Transaction",
    "account.transaction.id": "ID",
    "account.transaction.type": "Type",
    "account.transaction.amount": "Amount",
    "account.transaction.receiver": "Reciver",
    "account.transaction.hash": "Hash",
    "account.transaction.status": "Status",
    "account.transaction.created_time": "Created time",
    "account.transaction.action": "Action",
    "account.transaction.from": "From",
    "account.transaction.to": "To",
    "account.transaction.network": "Network",
    "email.modal": "Do you want to submit this email marketing?",
    "email.edit_title_page": "Edit Email Marketing",
    "email.new_title_page": "Create Email Marketing",
    "email.send_title_page": "Send Email",
    "email.new": "Create Email Marketing",
    "email.title": "Title",
    "email.content": "Content",
    "email.submit_data": "Do you want to send this email?",
    "email.delete_data": "Do you want to delete?",
    "email.send_all": "Send All User",
    "email.list_user": "Send List User",
    "email.send": "Send",
    "email.errors.title.required": "The title field is required.",
    "email.errors.content.required": "The content field is required.",
    "withdraw_fee_setting.title": "Withdraw fee setting",
    "withdraw_fee_setting.daily_limit": "Daily Limit",
    "withdraw_setting.change_confirm": "Do you want to change withdrawal fee?",
    "withdraw_fee_setting.disable_confirm":
      "Do you want to disable withdrawal?",
    "withdraw_fee_setting.disable_all_confirm":
      "Do you want to disable withdrawal of all currencies?",
    "withdraw_fee_setting.enable_confirm": "Do you want to enable withdrawal?",
    "withdraw_fee_setting.enable_all_confirm":
      "Do you want to enable withdrawal of all currencies?",
    "withdraw_fee_setting.disable_deposit_confirm":
      "Do you want to disable deposit?",
    "withdraw_fee_setting.disable_all_deposit_confirm":
      "Do you want to disable deposit of all currencies?",
    "withdraw_fee_setting.enable_deposit_confirm":
      "Do you want to enable deposit?",
    "withdraw_fee_setting.enable_all_deposit_confirm":
      "Do you want to enable deposit of all currencies?",
    "withdraw_fee_setting.update_success_msg": "Updated successfully!",
    "withdraw_fee_setting.disable_success_msg": "Disabled successfully!",
    "withdraw_fee_setting.disable_all_success_msg":
      "Disabled all successfully!",
    "withdraw_fee_setting.enable_success_msg": "Enabled successfully!",
    "withdraw_fee_setting.enable_all_success_msg": "Enabled all successfully!",
    "withdraw_fee_setting.withdraw_setting": "Transaction Setting",
    withdraq_fial_popup_confirmation_label:
      "Do you want to confirm this transaction?",
    "currency.amal": "AMAL",
    "salespoint.balances": "Balances",
    "salespoint.bonus": "Bonus",
    "salespoint.buy": "Buy",
    "salespoint.buy_history": "Buy History",
    "salespoint.buy_history.amount": "Amount",
    "salespoint.buy_history.amal": "AMAL",
    "salespoint.buy_history.bonus": "Bonus Token",
    "salespoint.buy_history.currency": "Currency",
    "salespoint.buy_history.time": "Time",
    "salespoint.buy_history.cash_back": "Referral Fee",
    "salespoint.buy_history.referrer_email": "Referrer Email",
    "salespoint.buy_history.referred_email": "Referred Email",
    "salespoint.buy_history.rate": "Rate (%)",
    "salespoint.buy_success": "Your order has been done!",
    "salespoint.buy_fail": "Your order has failed!",
    "salespoint.export_complete_buy_history": "Export Complete AMAL History",
    "salespoint.export_complete_buy_history_tooltip":
      "Only Export Complete Buy History on 6 months",
    "salespoint.login": "Login",
    "salespoint.or": "or",
    "salespoint.register": "Register",
    "salespoint.to_trade": "to trade",
    "salespoint.total_remaining_supply": "Total AMAL remaining supply",
    "site_setting.android_app_link": "Android app link",
    "site_setting.site_email": "Email",
    "site_setting.app_name": "App Name",
    "site_setting.short_name": "Short Name",
    "site_setting.copyright": "Copyright",
    "site_setting.facebook_icon": "Facebook icon",
    "site_setting.facebook_link": "Facebook link",
    "site_setting.ios_app_link": "iOs app link",
    "site_setting.language": "Language",
    "site_setting.site_phone_number": "Phone number",
    "site_setting.phone_number": "phone number",
    "site_setting.phone_number_valid":
      "The phone number must start by character '+' and national code, the length must be between 7~15 digits.",
    "site_setting.site_setting": "Site Setting",
    "site_setting.twitter_icon": "Twitter icon",
    "site_setting.twitter_link": "Twitter link",
    "site_setting.update_success_msg": "Updated successfully!",
    "site_setting.youtube_icon": "Youtube icon",
    "site_setting.youtube_link": "Youtube link",
    "site_setting.name": "text",
    "site_setting.icon": "file",
    "site_setting.icon_hover": "text",
    "site_setting.link": "text",
    "site_setting.general": "General",
    "site_setting.mobile_application": "Mobile application",
    "site_setting.name_table": "Name",
    "site_setting.icon_table": "Icon Class",
    "site_setting.icon_hover_table": "Icon hover",
    "site_setting.type_table": "Type",
    "site_setting.link_table": "Link",
    "site_setting.add_row_table": "+ Add row",
    "site_setting.status_table": "Status",
    "site_setting.social_network.title": "Social network",
    "site_setting.social_network.confirm_remove":
      "Do you want to remove Social network?",
    "site_setting.social_network.create_success":
      "Added Social network successfully!",
    "site_setting.social_network.update_success":
      "Updated Social network successfully!",
    "site_setting.social_network.remove_success":
      "Removed Social network successfully!",
    "site_setting.social_network.errors.required.name":
      "The name field is required.",
    "site_setting.social_network.errors.required.link":
      "The link field is required.",
    "site_setting.social_network.errors.required.icon_class":
      "The icon class field is required.",
    "site_setting.social_network.errors.required.type":
      "The type field is required.",
    "site_setting.social_network.errors.required.phone_number":
      "The phone number field is required.",
    "trading_fee_setting.fee_maker": "Fee Maker",
    "trading_fee_setting.fee_taker": "Fee Taker",
    "trading_fee_setting.coin_hodling": "{COIN_HOLDING} Holding",
    "trading_fee_setting.msg_coin_holding":
      "Setting coin holding must be increased by each level.",
    "trading_fee_setting.id": "Id",
    "user_setting.total_users": "Total User: ",
    "user_setting.reward": "Total Reward: ",
    "trading_fee_setting.coin": "Coin",
    "trading_fee_setting.trading_fee_setting": "Fee",
    "trading_fee_setting.trading_feeSetting": "Trading Fee Setting",
    "trading_fee_setting.trading_withdrawFeeSetting": "Withdrawal Fee Setting",
    "trading_fee_setting.update_confirm": "Do you want to change trading fee?",
    "trading_fee_setting.update_success_msg": "Updated successfully!",
    "trading_fee_setting.trading_selectMarket": "Select Market",
    "trading_fee_setting.under_101_percent": "The {0} field isn't over 100%.",
    "trading_fee_setting.validate.required": "The {0} field is required.",
    "trading_fee_setting.validate.isvalid": "The {0} filed is valid!",
    "trading_fee_setting.decial_part_under_2_character":
      "The decimal part of {0} field isn't over 2.",
    "trading_fee_setting.decial_part_under_8_character":
      "The decimal part of {0} field isn't over 8 .",
    "trading_fee_setting.dailyLimit_higher_Limit":
      "Limit value must be smaller than the daily limit.",
    "tradding_fee_setting.fee_taker": "fee taker",
    "tradding_fee_setting.fee_maker": "fee maker",
    "circuit_breaker_setting.title": "Circuit Breaker Setting",
    "circuit_breaker_setting.select_market": "Select Market",
    "circuit_breaker_setting.enable_circuit_breaker": "Enable Circuit Breaker",
    "circuit_breaker_setting.coin": "Coin",
    "circuit_breaker_setting.range_listen_time": "Range Listen Time (hour)",
    "circuit_breaker_setting.range_percent": "Range Percent (%)",
    "circuit_breaker_setting.block_time": "Block Time (hour)",
    "circuit_breaker_setting.enable_column": "Status",
    "circuit_breaker_setting.enabled": "Enable",
    "circuit_breaker_setting.disabled": "Disable",
    "circuit_breaker_setting.update_confirm":
      "Do you want to change this record?",
    "circuit_breaker_setting.update_success_msg": "Updated successfully!",
    "circuit_breaker_setting.update_enable_confirm":
      "Do you want to change status?",
    "circuit_breaker_setting.validation.block_trading":
      "Trading coin pair is blocking !",
    "circuit_breaker_setting.validation.block_time":
      "The block time field is required",
    "circuit_breaker_setting.validation.range_listen_time":
      "The range listen time field is required",
    "circuit_breaker_setting.validation.circuit_breaker_percent":
      "The range percent field is required",
    "circuit_breaker_setting.validation.range_listen_time.empty_value":
      "Value of range listen time must be greater than 0.",
    "circuit_breaker_setting.validation.circuit_breaker_percent.empty_value":
      "Value of range percent must be greater than 0.",
    "circuit_breaker_setting.validation.block_time.empty_value":
      "Value of block time must be greater than 0.",
    "circuit_breaker_setting.update_fail_msg": "Update Fail !",
    "button.done": "Done",
    "button.reset": "Reset",
    "user_setting.coin": "Coin",
    "user_setting.total_users": "Total User: ",
    "user_setting.id": "Id",
    "user_setting.blockchain_address": "Blockchain Address",
    "user_setting.balance": "Total Balance",
    "user_setting.available_balance": "Total Available Balance",
    "user_setting.confirm_off_otp": "Do you want to disable OTP?",
    "user_setting.confirm_on_otp": "Do you want to enable OTP?",
    "user_setting.update_otp_msg": "Updated setting OTP successfully!",
    "user_setting.confirm_disable_account": "Do you want to disable account?",
    "user_setting.disable_account_success_msg":
      "Disabled account successfully!",
    "auto_dividend.confirm_off_pair": "Do you want to disable this pair?",
    "auto_dividend.confirm_off_pair_margin":
      "Do you want to disable this contract?",
    "auto_dividend.confirm_on_pair": "Do you want to enable this pair?",
    "auto_dividend.confirm_on_pair_margin":
      "Do you want to enable this contract?",
    "auto_dividend.update_status_msg": "Updated successfully!",
    "entry_leaderboard.contest": "Contest",
    "entry_leaderboard.manual": "Entry Leaderboard Manual Payment",
    "entry_leaderboard.manual1": "Entry Payment Manual",
    "entry_leaderboard.team": "Team",
    "entry_leaderboard.team_id": "Team ID",
    "user_setting.enable_account_success_msg": "Enabled account successfully!",
    "user_setting.confirm_on_otp": "Do you want to enable OTP?",
    "enable_fee.this_market": "Add all pair in this market",
    "enable_fee.all_market": "Add all pair",
    "enable_fee.user_setting.confirm_off": "Are you sure disable fee setting ?",
    "enable_fee.user_setting.confirm_on": "Are you sure enable fee setting ?",
    "enable_fee.user_setting.update_success":
      "Update enable fee user setting successfully!",
    "enable_fee.user_setting.update_fail":
      "Update enable fee user setting fail!",
    "enable_fee.title": "User Enable Fee Setting",
    "enable_fee.remove_success": "User removed successfully!",
    "enable_fee.remove_fail": "User removed fail!",
    "enable_fee.currency": "Currency",
    "enable_fee.coin": "Coin",
    "enable_fee.no": "No.",
    "enable_fee.status": "Status",
    "enable_fee.fee_charge": "Fee Charge",
    "enable_fee.user_was_exist": "The account has already been existed.",
    "enable_fee.user_not_exist": "The account is not existed.",
    "enable_withdrawal.this_market": "Add all pair in this market",
    "enable_withdrawal.all_market": "Add all pair",
    "enable_withdrawal.all_coins": "Add all coins",
    "enable_withdrawal.user_setting.confirm_off":
      "Are you sure disable withdrawal setting ?",
    "enable_withdrawal.user_setting.confirm_on":
      "Are you sure enable withdrawal setting ?",
    "enable_withdrawal.user_setting.update_success":
      "Update enable withdrawal user setting successfully!",
    "enable_withdrawal.user_setting.update_fail":
      "Update enable withdrawal user setting fail!",
    "enable_withdrawal.title": "User Withdrawal Setting",
    "enable_withdrawal.remove_success": "User removed successfully!",
    "enable_withdrawal.remove_fail": "User removed fail!",
    "enable_withdrawal.currency": "Currency",
    "enable_withdrawal.coin": "Coin",
    "enable_withdrawal.no": "No.",
    "enable_withdrawal.status": "Status",
    "enable_withdrawal.user_was_exist": "The account has already been existed.",
    "enable_withdrawal.user_not_exist": "The account is not existed.",
    "enable_trading.this_market": "Add all pair in this market",
    "enable_trading.all_market": "Add all pair",
    "enable_trading.all_contract": "Add all contract",
    "enable_trading.remove_this_market": "Remove all pair in this market",
    "enable_trading.remove_all_market": "Remove all pair",
    "enable_trading.remove_all_contract": "Remove all contract",
    "enable_trading.is_mam": "Add for MAM user",
    "enable_trading.remove_is_mam": "Remove for MAM user",
    "enable_trading.all_coins": "Add all coins",
    "enable_trading.user_setting.confirm_off":
      "Do you want to disable trading setting?",
    "enable_trading.user_setting.confirm_on":
      "Do you want to enable trading setting?",
    "enable_trading.user_setting.update_success":
      "Update enable trading user setting successfully!",
    "enable_trading.user_setting.update_fail":
      "Update enable trading user setting fail!",
    "enable_trading.title": "Trading Setting",
    "enable_trading.remove_success": "User removed successfully!",
    "enable_trading.remove_fail": "User removed fail!",
    "enable_trading.currency": "Currency",
    "enable_trading.coin": "Coin",
    "enable_trading.contract": "Contract",
    "enable_trading.mam_user": "MAM User",
    "enable_trading.is_beta_tester": "Beta Tester",
    "enable_trading.no": "No.",
    "enable_trading.status": "Status",
    "enable_trading.user_was_exist": "The account has already been existed.",
    "enable_trading.user_not_exist": "The account is not existed.",
    "enable_trading.show_beta_tester": "Show Beta Tester",
    "not_found.title": "Page Not Found",
    "not_found.text1": "We can’t find the page you’re looking for.",
    "not_found.text2":
      "You can either return to the previous page, visit our homepage or contact our support team.",
    "not_found.landing": "Landing",
    "login.admin": "Login Admin",
    "login.email": "E-Mail Address",
    "login.email2": "Email",
    "user.referrer": "referrer",
    "user.otp": "OTP",
    "email.errors.required": "List user field is required.",
    "email.email": "Email",
    "email.email_title": "This is title",
    "email.email_content": "This is content",
    "email.status": "Status",
    "email.message": "Message",
    "email.fail": "Fail",
    "exchange_balance.available_balance": "Available balances",
    "exchange_balance.exchange_balance": "Exchange Balance",
    "exchange_balance.available_exchange_balance": "Available Exchange Balance",
    "exchange_fee.level": "Level",
    "withdraw_setting.disable_all": "Disable all",
    "withdraw_setting.enable_all": "Enable all",
    "withdraw_setting.enable": "Enabled",
    "withdraw_setting.disable": "Disabled",
    "withdraw_fee_setting.id": "Id",
    "withdraw_fee_setting.currency": "Currency",
    "withdraw_fee_setting.limit": "Limit",
    "withdraw_fee_setting.fee": "Fee",
    "withdraw_fee_setting.is_visible": "Is Visible",
    "withdraw_fee_setting.minium_withdrawal": "Minium Withdrawal",
    "withdraw_setting.id": "ID",
    "withdraw_setting.enable_confirm": "Do you want to enable withdrawal?",
    "withdraw_setting.enable_all_confirm":
      "Do you want to enable withdrawal of all currencies?",
    "withdraw_setting.disable_deposit_confirm":
      "Do you want to disable deposit?",
    "withdraw_setting.disable_all_deposit_confirm":
      "Do you want to disable deposit of all currencies?",
    "withdraw_setting.enable_deposit_confirm": "Do you want to enable deposit?",
    "withdraw_setting.enable_all_deposit_confirm":
      "Do you want to enable deposit of all currencies?",
    "withdraw_setting.name": "Name",
    "withdraw_setting.deposit": "Deposit",
    "withdraw_setting.withdraw": "Withdraw",
    "withdraw_setting.update_success_msg": "Updated successfully!",
    "withdraw_setting.disable_success_msg": "Disabled successfully!",
    "withdraw_setting.disable_all_success_msg": "Disabled all successfully!",
    "withdraw_setting.enable_success_msg": "Enabled successfully!",
    "withdraw_setting.enable_all_success_msg": "Enabled all successfully!",
    "withdraw_setting.withdraw_setting": "Transaction Setting",
    "withdraw_setting.disable_confirm": "Do you want to disable withdrawal?",
    "withdraw_setting.disable_all_confirm":
      "Do you want to disable withdrawal of all currencies?",
    "user.login_history": "History",
    trezor_address_for_deposit: "Trezor address for deposit",
    trezor_device: "Trezor devices",
    "trezor_device.created_at": "Created at",
    "trezor_device.device_id": "Device ID",
    "trezor_device.device_name": "Device Name",
    "trezor_device.disable": "Disable",
    "trezor_device.enable": "Enable",
    "trezor_device.model": "Model",
    "trezor_device.register_new_device": "Create trezor",
    "trezor_device.title": "Trezor devices",
    trezor_device_information: "Trezor Device Information",
    trezor_label: "TREZOR label",
    trezor_path_for_deposit: "Trezor path for deposit",
    trezor_wallet: "Trezor Wallet",
    "trezor_wallet.address": "Address",
    "trezor_wallet.balance": "Balance",
    "trezor_wallet.currency": "Currency",
    "trezor_wallet.details": "Details",
    "trezor_wallet.device": "Device",
    "trezor_wallet.device_name": "Device Name",
    "trezor_wallet.path": "Path",
    "trezor_wallet.summary": "Summary",
    "trezor_wallet.title": "Trezor Wallet",
    "trezor_wallet.total_wallet": "Total Wallet",
    "modal.trezor_device_information": "Trezor Device Information",
    "modal.device_name": "Device Name",
    "modal.device_id": "Device Id",
    "modal.model": "Model",
    datatable_empty: "There is no data available in the table.",
    datatable_search_empty: "There are no results that match your search.",
    "wallet.create_receive_address.currency": "Currency",
    "wallet.create_receive_address.currency_list": "Currency List",
    "wallet.create_receive_address.generate_address": "Create Address",
    "wallet.create_receive_address.the_number_of_remaining":
      "The Number of Remaining",
    "wallet.create_receive_address.title": "Create Receive Address",
    "wallet.create_receive_address.get_req_success":
      "Created address successfully!",
    "wallet.create_receive_address.return_req_leave": "Do you want to leave?",
    "wallet.create_receive_address.address": "{coin_name} Address ",
    "wallet.create_receive_address.coin_title":
      "{coin_full_name} ({coin_name}) Create Receive address",
    "wallet.create_receive_address.total_number": "Total Number",
    "wallet.create_receive_address.lose_address": "{field} is required",
    receive_address_creation: "receive address creation",
    "receive_address_creation.warning_text":
      "Input must be integer and larger than 0.",
    "receive_address_creation.author": "Author",
    "receive_address_creation.input_contents_to_be_created":
      "Input contents to be created",
    "receive_address_creation.number_created": "Number created",
    "receive_address_creation.please_do_not_use_trezor_for_transmission":
      "Please do not use TREZOR for transmission",
    "receive_address_creation.start_creating": "Start Creating",
    "receive_address_creation.title": "receive address creation",
    "receive_address_creation.trezor_label": "TREZOR label",
    user_wallet: "User wallet",
    "user_wallet.address": "Address",
    "user_wallet.available_balance": "Available Balance",
    "user_wallet.balance": "Balance",
    "user_wallet.currency": "Currency",
    "user_wallet.details": "Details",
    "user_wallet.device_name": "Device Name",
    "user_wallet.path": "Path",
    "user_wallet.summary": "Summary",
    "user_wallet.title": "User wallet",
    "user_wallet.total_wallet": "TOTAL WALLET",
    "user_wallet.total_wallet_2": "Total Wallet",
    "user_wallet.user_name": "Email",
    "coin_information.btn_back": "Back",
    "coin_information.btn_choose_file": "Choose file",
    "coin_information.btn_next": "Next",
    "coin_information.contact_address": "Contract Address",
    "coin_information.decimals": "Decimals",
    "coin_information.explorer": "Explorer",
    "coin_information.heading_title": "Register new ERC20",
    "coin_information.icon": "Icon",
    "coin_information.image_note": "The image must be a file of type: png",
    "coin_information.image_error": "File must be a file of type: .png.",
    "coin_information.required_confirmations": "Required Confirmations",
    "coin_information.required_confirmations_explorer":
      "Required Confirmations Explorer",
    "coin_information.tab_title": "Token Information",
    "coin_information.token": "Token",
    "coin_information.token_name": "Token name",
    "coin_information.type": "Type",
    "airdrop_setting.remaining": "Remaining Amount",
    airdrop_active: "Active",
    "airdrop_setting.airdrop_total_paid": "Total Paid",
    "airdrop_setting.airdrop_remaining": "Dividend Remaining Amount",
    "airdrop_setting.date_from_to": "Date From-To",
    "airdrop_setting.date_to": "Date To",
    "airdrop_setting.search_date_range": "Date From-To",
    "airdrop_setting.airdrop_setting": "Dividend Setting",
    "account_setting.symbol": "Symbol",
    "account_setting.leverage": "Leverage",
    "account_setting.crossMargin": "Is Cross",
    "account_setting.deleveragePercentile": "Deleverage Percentile",
    "account_setting.currentQty": "CurrentQty",
    "account_setting.markPrice": "Mark Price",
    "account_setting.realisedPnl": "RealisedPnl",
    "account_setting.unrealisedPnl": "UnrealisedPnl",
    "account_setting.liquidPrice": "Liquid Price",
    "account_setting.bankruptPrice": "Bankrupt Price",
    "account_setting.initMargin": "Init Rate",
    "account_setting.maintMargin": "Maint Rate",
    "account_setting.position": "Position",
    "bitmex.account": "Bitmex Account",
    "account_setting.key_id": "Key ID",
    "account_setting.ker_secret": "Key Secret",
    "bitmex.is_cross": "Is Cross",
    "bitmex.isleverage": "Is Leverage",
    "account_setting.key_name": "Key Name",
    "account_setting.email": "Email",
    "account_setting.balance": "Balance",
    "account_setting.available_balance": "Available Balance",
    "airdrop_setting.email": "Email",
    "airdrop_setting.status": "Status",
    "airdrop_setting.dividend_setting_manual": "Manual Payment Dividend",
    "airdrop_setting.minimum_amount": "Minimum amount",
    "airdrop_setting.deposit_amount": "Deposit amount",
    "airdrop_setting.number_of_referrer": "Referrer",
    "airdrop_setting.amal_holding": "AMAL Holding",
    "airdrop_setting.kyc": "KYC",
    "airdrop_setting.self_trading": "Self Trading",
    "airdrop_setting.send_bonus": "Send bonus",
    "airdrop_setting.refund_bonus": "Refund bonus",
    "airdrop_setting.send_bonus_success": "Send bonus successfully!",
    "airdrop_setting.cashback_success": "Cashback successfully!",
    "airdrop_setting.refund_bonus_success": "Refund Bonus Successfully! ",
    "airdrop_setting.send_bonus_error": "Send bonus fail",
    "airdrop_setting.refund_bonus_error": "Refund bonus fail",
    "airdrop_setting.auto_dividend_setting.confirm_status_disable":
      "Deactive all selected records?",
    "airdrop_setting.auto_dividend_setting.confirm_status_enable":
      "Active all selected records?",
    "airdrop_setting.refund_amount_missing": "The amount field is required.",
    "airdrop_setting.amount_missing": "This field is required.",
    "airdrop_setting.deposit_missing": "The deposit amount field is required.",
    "airdrop_setting.deposit_than_0":
      "The deposit amount must be greater than 0.",
    "airdrop_setting.holding_missing": "The amal holding field is required.",
    "airdrop_setting.holding_than_0":
      "The AMAL holding must be greater than 0.",
    "airdrop_setting.referrer_missing": "The referrer field is required.",
    "airdrop_setting.referrer_than_0": "The referrer must be greater than 0.",
    "airdrop_setting.payout_amount_missing":
      "The pay amount field is required. ",
    "airdrop_setting.dividend_manual.pay_amount": "Pay Amount",
    "airdrop_setting.dividend_manual.refund_amount": "Amount",
    "airdrop_setting.dividend_manual.pay_for": "Wallet",
    "airdrop_setting.auto_dividend_history": "Auto Dividend Paid History",
    "airdrop_setting.manual_dividend_history": "Manual Paid History",
    "airdrop_setting.bonus.status": "Status",
    "airdrop_setting.bonus.last_paid": "Last Paid",
    "auto_dividend.confirm_off_all_pair": "Do you want to disable all pairs?",
    "auto_dividend.confirm_on_all_pair": "Do you want to enable all pairs?",
    pending: "Pending",
    success: "Success",
    fail: "Fail",
    "airdrop_setting.bonus.bonus_setting": "Bonus Setting",
    "airdrop_setting.bonus.refund_bonus_setting": "Take Cashback",
    "airdrop_setting.trading_volume": "Trading Volume",
    "airdrop_setting.auto_dividend_title": "Auto Dividend Setting",
    "airdrop_setting.enable_auto_dividend": "Enable",
    "airdrop_setting.time_apply_from": "Apply From",
    "airdrop_setting.time_apply_to": "Apply To",
    "airdrop_setting.market": "Pair",
    "airdrop_setting.contract": "Contract",
    "airdrop_setting.minimum_trade_amount": "Lot",
    "airdrop_setting.max_bonus": "Max Bonus",
    "airdrop_setting.reset_bonus": "Reset Bonus",
    "airdrop_setting.confirm_reset_bonus": "Do you want to reset bonus?",
    "airdrop_setting.max_bonus_low": "max bonus",
    "airdrop_setting.payout_amount": "Payout Amount",
    "airdrop_setting.payout_for": "Payout Wallet",
    "airdrop_setting.total_bonus_dividend": "Total Bonus Dividend",
    "airdrop_setting.currency": "Currency",
    "airdrop_setting.email": "Email",
    "airdrop_setting.email1": "email",
    "airdrop_setting.period": "Period",
    "airdrop_setting.lock_period": "Lock Period",
    "airdrop_setting.unlock_percent": "Unlock percent",
    "airdrop_setting.unlock_percent_period": "Unlock Percent/Period",
    "airdrop_setting.confirm_remove": "Do you want to remove this user?",
    "airdrop.error.field_required": "The {field_name} field is required.",
    "airdrop.error.field_min": "Value of {field_name} must be greater than 0.",
    "airdrop.error.field_max":
      "Value of {field_name} must be smaller than 100.",
    "airdrop.error.total_supply_too_big":
      "Total supply must not greater than 210,000,000",
    "airdrop_setting.remove_success": "User removed successfully!",
    "validation.is_contract_address": "The contract address is invalid.",
    "validation.is_contract_address_exist":
      "Contract address has already been existed.",
    "trading_information.btn_back": "Back",
    "trading_information.btn_delete": "Delete",
    "trading_information.btn_edit": "Edit",
    "trading_information.btn_next": "Next",
    "trading_information.btn_register_trading_pair": "Register trading pair",
    "trading_information.delete_msg": "Success",
    "trading_information.fee": "Fee",
    "trading_information.taker_fee": "Taker Fee",
    "trading_information.maker_fee": "Maker Fee",
    "trading_information.taker_fee_percent": "Taker Fee (%)",
    "trading_information.maker_fee_percent": "Maker Fee (%)",
    "trading_information.heading_title": "Register New ERC20",
    "trading_information.market_price": "Market Price",
    "trading_information.price_precision": "Price Precision",
    "trading_information.minimum_amount": "Minimum Amount",
    "trading_information.quantity_precision": "Quantity Precision",
    "trading_information.minimum_total": "Minimum Total",
    "trading_information.zone": "Zone",
    "trading_information.zone_0": "Default",
    "trading_information.zone_1": "ERC20",
    "trading_information.pair": "Pair",
    "trading_information.trading_information": "Trading Information",
    "trading_information.precision_max":
      "The precision field must be {precision} or less.",
    "account_setting.payout_currency": "Payout Currency",
    "account_setting.enable_airdrop": "Enable Dividend",
    "account_setting.enable_wallet_airdrop": "Enable Pay Fee By OGX",
    "account_setting.trading_with_themselves": "Enable self-trading:     ",
    "account_setting.user_pass_kyc": "Show only KYC user: ",
    "account_setting.show_leaderboard_on_date_from_to": " Ranking from ",
    "account_setting.to": "to",
    "account_setting.show_leaderboard_on_date":
      "Leaderboard only show data in date range from",
    "account_setting.enable_wallet_airdrop_pay_amal":
      "Using Dividend To Pay Amal Fee",
    "account_setting.edit": "Edit",
    "account_setting.cancel": "Cancel",
    "account_setting.apply": "Apply",
    "account_setting.list_account": "List Account",
    "account_setting.updated_time": "Updated Time",
    "account_setting.lock_period": "Lock Period",
    "account_setting.unlock_percent_period": "Unlock Percent/Period",
    "account_setting.default_lock_period": "Default lock period",
    "account_setting.default_lock_period1": "default lock period",
    "account_setting.default_lock_period2": "lock period",
    "account_setting.default_unlock_percent_period":
      "Default unlock percent/period",
    "account_setting.default_unlock_percent_period1":
      "default unlock percent/period",
    "account_setting.default_unlock_percent_period2": "unlock percent/period",
    "account_setting.airdrop_payout_amount": "Total dividend payment amount",
    "account_setting.airdrop_payout_amount1": "total dividend payout amount",
    "account_setting.set_payout_time": "Set payout time",
    "account_setting.set_payout_time1": "set payout time",
    "account_setting.set_minumum_amount": "Set minimum amount",
    "account_setting.set_minumum_amount1": "set minimum amount",
    "account_setting.total_supply": "Total Supply",
    "account_setting.total_supply1": "total supply",
    "account_setting.airdrop.search": "Search by email",
    "account_setting.add_account": "ADD ACCOUNT",
    "account_setting.add_user": "ADD USER",
    "withdrawal_information.back": "Back",
    "withdrawal_information.currency": "Currency",
    "withdrawal_information.day": "day",
    "withdrawal_information.days": "days",
    "withdrawal_information.fee": "Fee",
    "withdrawal_information.fee_percent": "Fee %",
    "withdrawal_information.taker_fee_percent": "Taker Fee %",
    "withdrawal_information.marker_fee_percent": "Marker Fee %",
    "withdrawal_information.heading_title": "Register new ERC20",
    "withdrawal_information.limit": "Limit",
    "withdrawal_information.minimum_withdrawal": "Minimum withdrawal",
    "withdrawal_information.minimum_withdrawal_erc20": "Minimum Withdrawal",
    "withdrawal_information.next": "Next",
    "withdrawal_information.tab_title": "Withdrawal Information",
    "withdrawal_information.time_reset": "Time reset",
    "withdrawal_information.level_1": "Level 1",
    "withdrawal_information.level_2": "Level 2",
    "withdrawal_information.level_3": "Level 3",
    "withdrawal_information.level_4": "Level 4",
    referralSetting_update_success: "Success: Updated successfully!",
    "review_register_erc_2_0.*_check_register_erc20_information":
      "* Check register erc20 information",
    "review_register_erc_2_0.amount_limit": "Amount Limit",
    "review_register_erc_2_0.btn_back": "Back",
    "review_register_erc_2_0.btn_register": "register",
    "review_register_erc_2_0.contract_address": "Contract Address",
    "review_register_erc_2_0.currency": "Currency",
    "review_register_erc_2_0.days": "days",
    "review_register_erc_2_0.decimals": "Decimals",
    "review_register_erc_2_0.error_msg":
      "Have errors during setup. Please register again!",
    "review_register_erc_2_0.explorer": "Explorer",
    "review_register_erc_2_0.fee": "Fee",
    "review_register_erc_2_0.icon": "Icon",
    "review_register_erc_2_0.limit": "Limit",
    "review_register_erc_2_0.market_price": "Market Price",
    "review_register_erc_2_0.minimum_amount": "Minimum Amount",
    "review_register_erc_2_0.quantity_precision": "Quantity Precision",
    "review_register_erc_2_0.minimum_total": "Minimum Total",
    "review_register_erc_2_0.minimum_withdrawal": "Minimum Withdrawal",
    "review_register_erc_2_0.pair": "Pair",
    "review_register_erc_2_0.precision": "Precision",
    "review_register_erc_2_0.register_new_erc20": "Register new ERC20",
    "review_register_erc_2_0.required_confirmations": "Required confirmations",
    "review_register_erc_2_0.time_reset": "Time Reset",
    "review_register_erc_2_0.token": "Token",
    "review_register_erc_2_0.token_information": "Token Information",
    "review_register_erc_2_0.token_name": "Token Name",
    "review_register_erc_2_0.total_limit": "Total Limit",
    "review_register_erc_2_0.trading_fee": "Trading Fee",
    "review_register_erc_2_0.trading_limit": "Trading limit",
    "review_register_erc_2_0.trading_pair": "Trading Pair",
    "review_register_erc_2_0.type": "Type",
    "review_register_erc_2_0.withdrawal_information": "Withdrawal Information",
    "review_register_erc_2_0.success_msg": "Register successfully!",
    "review_register_erc_2_0.taker_fee": "Taker Fee",
    "review_register_erc_2_0.maker_fee": "Maker Fee",
    "review_register_erc_2_0.level_1": "Level 1",
    "review_register_erc_2_0.level_2": "Level 2",
    "review_register_erc_2_0.level_3": "Level 3",
    "review_register_erc_2_0.level_4": "Level 4",
    remittance_complete_registration: "Remittance complete registration",
    "remittance_ordered.amount": "Amount",
    "remittance_ordered.btn_detail": "DETAIL",
    "remittance_ordered.btn_search": "Search",
    "remittance_ordered.currency": "Currency",
    "remittance_ordered.execution_date": "Execution Date",
    "remittance_ordered.heading_title": "Remitted Transactions",
    "remittance_ordered.no": "No",
    "remittance_ordered.transaction_id": "Transaction ID",
    "remittance_ordered.user_name": "Email",
    "remittance_ordered.withdraw_approval": "Withdrawal approver",
    "remittance_transaction_page.administrator_information":
      "Administrator information",
    "remittance_transaction_page.withdrawal_currency": "Withdraw currency",
    "remittance_transaction_page.withdrawal_address": "Withdraw address",
    "remittance_transaction_page.transaction_detail": "Transaction detail",
    "remittance_transaction_page.customer_information": "Customer information",
    "remittance_transaction_page.transaction_hash": "Transaction hash",
    "remittance_transaction_page.remark": "Remarks",
    "remittance_transaction_page.approved_date": "Approved Date",
    "remittance_transaction_page.approved_by": "Approved By",
    "remittance_transaction_page.country": "Country",
    "remittance_transaction_page.created_date": "Created Date",
    "remittance_transaction_page.sent_at": "Sent at",
    "remittance_transaction_page.sent_by": "Send by",
    "remittance_transaction_page.email": "Email",
    "remittance_transaction_page.header_title":
      "[Detail] Transaction: {transaction_id}",
    "remittance_transaction_page.quantity": "Amount",
    "remittance_transaction_page.transaction_id": "Transaction ID",
    remittance_transactions: "Remitted transactions",
    remitted_transaction: "Remitted Transaction",
    "erc20_loading_modal.create_account_message": "Create Accounts ....",
    "erc20_loading_modal.create_migration_message": "Create Migration ....",
    "erc20_loading_modal.done_message": "Done ....",
    "erc20_loading_modal.loading_message": "Loading....",
    "erc20_loading_modal.set_market_price_message": "Set Market Prices ....",
    "erc20_loading_modal.update_masterdata_message": "Update Masterdata ....",
    eth_wallet_information: "ETH WALLET INFORMATION",
    "user_not_confirmed.amount": "Amount",
    "user_not_confirmed.btn_search": "Search",
    "user_not_confirmed.created_date": "Created Date",
    "user_not_confirmed.currency": "Currency",
    "user_not_confirmed.no": "No",
    "user_not_confirmed.title": "User Not Confirmed",
    "user_not_confirmed.transaction_id": "Transaction ID",
    "user_not_confirmed.user_name": "Email",
    "user_not_confirmed.user_not_confirmed": "User Not Confirmed",
    "pending_transaction_page.approved_date": "Approved Date",
    "pending_transaction_page.back": "Back",
    "pending_transaction_page.birthdate": "Birthdate",
    "pending_transaction_page.country": "Country",
    "pending_transaction_page.created_date": "Created Date",
    "pending_transaction_page.customer_information": "CUSTOMER INFORMATION",
    "pending_transaction_page.detail": "Detail",
    "pending_transaction_page.email": "Email",
    "pending_transaction_page.header_title":
      "[Detail] Transaction: {transaction_id}",
    "pending_transaction_page.name": "Name",
    "pending_transaction_page.open_notification_rejected":
      "The rejected transaction has been completed.",
    "pending_transaction_page.open_notification_verified":
      "The approval transaction has been completed.",
    "pending_transaction_page.quantity": "Amount",
    "pending_transaction_page.reject": "Reject",
    "pending_transaction_page.remarks": "Remarks",
    "pending_transaction_page.tel": "Tel",
    "pending_transaction_page.transaction": "Transaction",
    "pending_transaction_page.transaction_detail": "Transaction detail",
    "pending_transaction_page.transaction_id": "Transaction ID",
    "pending_transaction_page.trezor_address_for_deposit":
      "Trezor address for deposit",
    "pending_transaction_page.trezor_path_for_deposit":
      "Trezor path for deposit",
    "pending_transaction_page.verify": "Verify",
    "pending_transaction_page.withdrawal_address": "Withdrawal address",
    "pending_transaction_page.withdrawal_currency": "Withdrawal currency",
    "pending_transaction_page.field_require": "Indicates required fields",
    "rejected_transaction_page.administrator_information":
      "Administrator information",
    "rejected_transaction_page.withdrawal_currency": "Withdraw currency",
    "rejected_transaction_page.withdrawal_address": "Withdraw address",
    "rejected_transaction_page.transaction_detail": "Transaction detail",
    "rejected_transaction_page.customer_information": "Customer information",
    "rejected_transaction_page.transaction_hash": "Transaction hash",
    "rejected_transaction_page.remark": "Remarks",
    "rejected_transaction_page.country": "Country",
    "rejected_transaction_page.created_date": "Created Date",
    "rejected_transaction_page.email": "Email",
    "rejected_transaction_page.header_title":
      "[Detail] Transaction: {transaction_id}",
    "rejected_transaction_page.quantity": "Amount",
    "rejected_transaction_page.reject_by": "Reject by",
    "rejected_transaction_page.reject_date": "Reject Date",
    "rejected_transaction_page.transaction_id": "Transaction ID",
    transactions_pending: "Pending Transactions",
    transactions_history: "History Transactions",
    "salespoint.buy_history.buy_history": "Buy History",
    "salespoint.buy_history.amal_buy_history": "AMAL Buy History",
    "salespoint.buy_history.date": "Date",
    "salespoint.buy_history.pair": "Pair",
    "salespoint.buy_history.coin": "Coin",
    "salespoint.buy_history.all": "All",
    "salespoint.buy_history.search_by_email": "Search by email",
    "salespoint.buy_history.search": "Search",
    "salespoint.buy_history.reset": "Reset",
    "salespoint.buy_history.buyer_email": "Email",
    "leaderboard_item.trading_volume_ranking": "User Trading Volume Ranking",
    "leaderboard.spotExchange": "Spot Exchange",
    "leaderboard.marginExchange": "Margin Exchange",
    "leaderboard_table_title.Ranking": "Ranking",
    "leaderboard_table_title.Email": "Email",
    "leaderboard_table_title.TradingVolume": "Trading Volume",
    "leaderboard.show_on_user_ui": "Show on User's UI",
    "leaderboard.show_extra_setting": "Advanced Settings",
    "referral_item.referral_history": "Referral History",
    "referral_item.referral_setting": "Referral Setting",
    "referral_item.refund_rate": "Refund rate",
    "referral_item.number_of_referral_level": "Number of referral level",
    "referral_item.next_program_conditional": "Next Program Conditional",
    "referral_item.people": "People",
    "referral_item.referral_enable": "Enable Referral",
    "referral_item.entry_program": "Entry Program",
    "referral_item.next_program": "Next Program",
    "referral_item.refund_percent_at_level": "Referral percent at level",
    "cold_wallet_setting.airdrop_history": "User Unlock Status",
    "cold_wallet_setting.airdrop_payment_history": "Dividend Payment History",
    "cold_wallet_setting.cashback_history": "Cashback History",
    "cold_wallet_setting.account_setting": "Account Setting",
    "cold_wallet_setting.title_page": "Cold Wallet Setting",
    "cold_wallet_setting.receive_title": "Receive Address",
    "cold_wallet_setting.btc_address": "BTC Address",
    "cold_wallet_setting.bch_address": "BCH Address",
    "cold_wallet_setting.eth_address": "ETH/ERC20 Address",
    "cold_wallet_setting.xrp_address": "XRP Address",
    "cold_wallet_setting.ltc_address": "LTC Address",
    "cold_wallet_setting.eos_address": "EOS Address",
    "cold_wallet_setting.ada_address": "ADA Address",
    "cold_wallet_setting.usdt_address": "USDT Address",
    "cold_wallet_setting.usd_address": "USD Address",
    "cold_wallet_setting.address": "{currency} Address",
    "cold_wallet_setting.min_balance": "Min Balance",
    "cold_wallet_setting.max_balance": "Max Balance",
    "cold_wallet_setting.notification_title": "Notification",
    "cold_wallet_setting.holder_email": "Cold Wallet Holder Email",
    "cold_wallet_setting.holder_name": "Cold Wallet Holder Name",
    "cold_wallet_setting.holder_mobile": "Cold Wallet Holder Mobile No",
    "cold_wallet_setting.save_message": "Saving....",
    "cold_wallet_setting.address_valid.invalid": "The address is not valid.",
    "cold_wallet_setting.address.invalid":
      "The coin address field is required.",
    "cold_wallet_setting.min_balance.invalid":
      "The min balance field is required.",
    "cold_wallet_setting.max_balance.invalid":
      "The max balance field is required.",
    "cold_wallet_setting.min_balance.error":
      "The minimum balance must be greater than 0.",
    "cold_wallet_setting.max_balance.error":
      "The maximum balance must be greater than 0.",
    "cold_wallet_setting.max_min.invalid":
      "The max balance must be bigger than the min balance!",
    "cold_wallet_setting.holder_email.invalid":
      "The cold wallet holder email field is required.",
    "cold_wallet_setting.holder_email_format.invalid":
      "This email address is invalid",
    "cold_wallet_setting.holder_name.invalid":
      "The cold wallet holder name field is required.",
    "cold_wallet_setting.holder_mobile_no.invalid":
      "The cold wallet holder mobile no field is required.",
    "cold_wallet_setting.xrp.sub_address_placeholder": "XRP Tag (Optional)",
    "cold_wallet_setting.eos.sub_address_placeholder": "EOS Tag (Optional)",
    "permission.role.required": "The Role field is required.",
    "funds.transaction_history.no_deposit_data": "You have no deposit history",
    "funds.transaction_history.no_withdraw_data":
      "You have no withdrawal history",
    "withdraw_setting.currency": "Currency",
    "menu.user_group_setting": "Group Setting",
    "group_setting.date": "Date",
    "group_setting.search_by_name": "Search by name",
    "group_setting.search": "Done",
    "group_setting.reset": "Reset",
    "group_setting.add_new": "Add Group",
    "group_setting.name": "Name",
    "group_setting.memo": "Memo",
    "group_setting.delete_data": "Do you want to delete this group?",
    "table.unlocked_balance": "Unlocked Balance",
    "table.total_airdrop_balance": "Total Dividend Balance",
    "table.airdrop_balance": "Dividend Balance",
    "table.available_airdrop_balance": "Available Dividend Balance",
    "table.perpetual_airdrop_balance": "Perpetual Dividend Balance",
    "table.available_perpetual_airdrop_balance":
      "Available Perpetual Dividend Balance",
    "table.created_date": "Created Date",
    "table.unlock_date": "Unlock Date",
    "table.payment_date": "Payment Date",
    "group_setting.edit": "Edit Group",
    "margin_orders.title": "Margin Orders",
    "margin_orders.open_order": "Open Order",
    "margin_orders.active_order": "Active Order",
    "margin_orders.order_history": "Order History",
    "margin_orders.order_value": "Order Value",
    "margin_orders.orders.updated_at": "Time",
    "margin_orders.orders.email": "Email",
    "margin_orders.orders.original_id": "Original Id",
    "margin_orders.orders.account_id": "User ID",
    "margin_orders.orders.instrument_symbol": "Instrument Symbol",
    "margin_orders.orders.side": "Side",
    "margin_orders.orders.type": "Type",
    "margin_orders.orders.quantity": "Quantity",
    "margin_orders.orders.price": "Price",
    "margin_orders.orders.lock_price": "Lock Price",
    "margin_orders.orders.remaining": "Remaining",
    "margin_orders.orders.executed_price": "Executed Price",
    "margin_orders.orders.stop_type": "Stop Type",
    "margin_orders.orders.stop_price": "Stop Price",
    "margin_orders.orders.stop_condition": "Stop Condition",
    "margin_orders.orders.trigger": "Trigger",
    "margin_orders.orders.time_in_force": "Time In Force",
    "margin_orders.orders.fee": "Fee",
    "margin_orders.orders.trail_value": "Trail Value",
    "margin_orders.orders.vertex_price": "Vertex Price",
    "margin_orders.orders.status": "Status",
    "margin_orders.orders.is_post_only": "Is Post Only",
    "margin_orders.orders.is_hidden": "Is Hidden",
    "margin_orders.orders.is_reduce_only": "Is Reduce Only",
    "margin_orders.orders.pair_type": "Pair Type",
    "margin_orders.orders.reference_id": "Reference Id",
    "margin_orders.orders.note": "Note",
    "margin_orders.orders.text": "Text",
    "instrument.init_margin_required": "The init rate field is required.",
    "instrument.sum_greater_than_0":
      "Sum of Maker Fee and Taker Fee must be greater than or equal to 0.",
    "account.detail.affiliate.id_account": "User ID",
    "account.detail.affiliate.email": "Email",
    "account.detail.affiliate.level": "Level",
    "account.detail.affiliate.creationTime": "Creation Time",
    "account.detail.affiliate.tab.downline": "Downline",
    "account.detail.affiliate.tab.upline": "Upline",
    "account.detail.affiliate.inputplace.search": "Search by ID",
    "account.detail.affiliate.btn.search": "Search",
    "account.detail.activityhistory.id_account": "ID",
    "account.detail.activityhistory.ip": "IP Address",
    "account.detail.activityhistory.device": "Device",
    "account.detail.activityhistory.creationTime": "Creation Time",
    "account.detail.openOrder.tab.spot": "Spot",
    "account.detail.openOrder.tab.futures": "Futures",
    "account.detail.openOrder.filter.date": "Date",
    "account.detail.openOrder.filter.pair": "Pair",
    "account.detail.openOrder.filter.type": "Type",
    "account.detail.openOrder.filter.status": "Status",
    "account.detail.openOrder.inputplace.search": "Search by ID",
    "account.detail.openOrder.btn.search": "Search",
    "account.detail.openOrder.btn.refresh": "Refresh",
    "account.detail.openOrder.table.orderId": "Order ID",
    "account.detail.openOrder.table.pair": "Pair",
    "account.detail.openOrder.table.side": "Side",
    "account.detail.openOrder.table.type": "Type",
    "account.detail.openOrder.table.filled": "Filled/Quantity",
    "account.detail.openOrder.table.trigger": "Trigger",
    "account.detail.openOrder.table.price": "Price",
    "account.detail.openOrder.table.creationTime": "Creation Time",
    "account.detail.openOrder.table.status": "Status",
    "account.detail.openOrder.table.action": "Action",
    "account.detail.openOrder.table.column.sideBuy": "Buy",
    "account.detail.openOrder.table.column.sideSell": "Sell",
    "account.detail.openOrder.table.column.typeLimit": "Limit",
    "account.detail.openOrder.table.column.statusPending": "Pending",
    "account.detail.openOrder.table.column.statusPartial": "Partial Filled",
    "account.detail.dialog.confirm.title": "Can you cancel this order?",
    "account.detail.dialog.confirm.btn": "Confirm",
    "account.detail.dialog.close.btn": "Close",
    "account.detail.orderHistory.tab.spot": "Spot",
    "account.detail.orderHistory.tab.futures": "Futures",
    "account.detail.orderHistory.filter.date": "Date",
    "account.detail.orderHistory.filter.pair": "Pair",
    "account.detail.orderHistory.filter.type": "Type",
    "account.detail.orderHistory.filter.status": "Status",
    "account.detail.orderHistory.inputplace.search": "Search by ID",
    "account.detail.orderHistory.btn.search": "Search",
    "account.detail.orderHistory.btn.refresh": "Refresh",
    "account.detail.orderHistory.table.orderId": "Order ID",
    "account.detail.orderHistory.table.pair": "Pair",
    "account.detail.orderHistory.table.side": "Side",
    "account.detail.orderHistory.table.type": "Type",
    "account.detail.orderHistory.table.filled": "Filled/Quantity",
    "account.detail.orderHistory.table.avgPrice": "Avg. Price",
    "account.detail.orderHistory.table.time": "Time",
    "account.detail.orderHistory.table.status": "Status",
    "account.detail.orderHistory.table.action": "Action",
    "account.detail.orderHistory.table.column.sideBuy": "Buy",
    "account.detail.orderHistory.table.column.sideSell": "Sell",
    "account.detail.orderHistory.table.column.typeLimit": "Limit",
    "account.detail.orderHistory.table.column.statusPending": "Pending",
    "account.detail.orderHistory.table.column.statusFilled": "Filled",
    "account.detail.orderHistory.table.column.statusCanceled": "Canceled",
    "account.detail.tradeHistory.tab.spot": "Spot",
    "account.detail.tradeHistory.tab.futures": "Futures",
    "account.detail.tradeHistory.filter.date": "Date",
    "account.detail.tradeHistory.filter.pair": "Pair",
    "account.detail.tradeHistory.inputplace.search": "Search by ID",
    "account.detail.tradeHistory.btn.search": "Search",
    "account.detail.tradeHistory.btn.refresh": "Refresh",
    "account.detail.tradeHistory.table.tradeId": "Trade ID",
    "account.detail.tradeHistory.table.buyId": "Buy ID",
    "account.detail.tradeHistory.table.sellId": "Sell ID",
    "account.detail.tradeHistory.table.pair": "Pair",
    "account.detail.tradeHistory.table.side": "Side",
    "account.detail.tradeHistory.table.qty": "Qty",
    "account.detail.tradeHistory.table.price": "Price",
    "account.detail.tradeHistory.table.time": "Time",
    "account.detail.tradeHistory.table.action": "Action",
    "account.detail.tradeHistory.table.column.sideBuy": "Buy",
    "account.detail.tradeHistory.table.column.sideSell": "Sell",


    "menu.dashboard": "Dashboard",
    "menu.account": "Account",
    "menu.account.account_list": "Account List",
    "menu.account.kyc": "KYC",
    "menu.fund": "Fund",
    "menu.fund.withdrawals": "Withdrawals",
    "menu.fund.deposit": "Deposit",
    "menu.spot": "Spot",
    "menu.spot.market": "Market",
    "menu.spot.open_order": "Open Order",
    "menu.spot.order_book": "Order Book",
    "menu.spot.order_history": "Order History",
    "menu.spot.trade_history": "Trade History",
    "menu.futures": "Futures",
    "menu.futures.market": "Market",
    "menu.futures.open_order": "Open Order",
    "menu.futures.order_book": "Order Book",
    "menu.futures.order_history": "Order History",
    "menu.futures.trade_history": "Trade History",
    "menu.futures.open_positions": "Open Positions",
    "menu.futures.positions_history": "Positions History",
    "menu.administrator": "Administrator",
    "menu.administrator.admin_account":"Admin Account",
    "menu.administrator.role": "Roles",
    "menu.site_setting": "Site Setting",
    "menu.wallet": "Wallet",
    "menu.wallet.register_erc20": "Register ERC-20",
    "menu.wallet.cold_wallet": "Cold Wallet",
    "menu.users": "Users",
    "menu.users.user_information": "User Information",
    "menu.users.user_kyc": "User KYC",
    "menu.users.user_kycsumsub": "User KYC Sumsub",
    "menu.users.user_setting": "User Setting",
    "menu.users.user_enable_fee_setting": "User Enable Fee Setting",
    "menu.users.device_management": "Device Management",
    "menu.referral": "Referral",
    "menu.referral.referral_history": "Referral History",
    "menu.referral.referral_setting": "Referral Setting",
    "menu.exchange_setting": "Exchange Setting",
    "menu.exchange_setting.exchange_balances": "Exchange Balances",
    "menu.exchange_setting.transaction_setting": "Transaction Setting",
    "menu.exchange_setting.tradding_setting": "Tradding Setting",
    "menu.exchange_setting.user_withdrawal_setting": "User Withdrawal Setting",
    "menu.exchange_setting.bank_setting": "Bank Setting",
    "menu.exchange_setting.fee": "Fee",
    "menu.transaction_history": "Transaction History",
    "menu.transaction_history.deposit_usd": "Deposit USD",
    "menu.transaction_history.withdraw_usd": "Withdraw USD",
    "menu.transaction_history.deposit": "Deposit",
    "menu.transaction_history.withdraw": "Withdraw",
    "menu.marketing": "Marketing",
    "menu.marketing.notices": "Notices",
    "menu.marketing.email_marketing": "Email Marketing",
    "menu.reward_center": "Reward Center",
    "menu.cms": "CMS",
    "menu.cms.notification": "Notifications",
    "menu.cms.mail_template": "Mail Temapltes",
    "menu.cms.announcement": "Announcement",
    "menu.cms.faq": "FAQ",
    "menu.cms.qna": "1:1 Inquiry",
    "menu.partner_admin": "Partner Admin",
    "modal.confirm": "CONFIRM",
    "order_type.limit": "Limit",
    "order_type.market": "Market",
    "order_type.stop_limit": "Stop Limit",
    "order_type.stop_market": "Stop Market",
    "order_type.trailing_stop": "Trailing Stop",
    "order_type.liquidation": "Liquidation",
    "order_type.take_profit_market": "Take Profit Market",
    "order_type.stop_loss_market": "Stop Loss Market"
};