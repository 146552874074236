<template>
    <div class="tradeHistory-container">
        <div class="tradeHistory-actions">
            <div class="tradeHistory-actions__tab">
                <button class="tradeHistory-btn" :class="{ active: openOrderTypeTab === 0 }"
                    @click="setOrderHistoryTypeTabTypeTab(0)">{{
                    $t('account.detail.tradeHistory.tab.spot') }}</button>
                <button class="tradeHistory-btn" :class="{ active: openOrderTypeTab === 1 }"
                    @click="setOrderHistoryTypeTabTypeTab(1)">{{
                    $t('account.detail.tradeHistory.tab.futures') }}</button>
            </div>

            <!-- <div class="tradeHistory-actions__filters">
                <div>
                    <div class="filter-title">{{ $t('account.detail.tradeHistory.filter.date') }}</div>
                    <div class="filter-flex">
                        <date-picker v-model="filters.startDate" class="date-picker-input" :format="formatDate"
                            ref="startDate"></date-picker>
                        <date-picker v-model="filters.endDate" class="date-picker-input" :format="formatDate"
                            ref="endDate"></date-picker>
                    </div>
                </div>

                <div>
                    <div class="filter-title">{{ $t('account.detail.tradeHistory.filter.pair') }}</div>

                    <div class="filter-flex">
                        <select-box v-model="filters.pair.coin" :placeholder="$t('order.tradeHistory.all')"
                            :options="pairCoinData" class="selectbox100" />
                        <div>/</div>
                        <select-box v-model="filters.pair.market" :placeholder="$t('order.tradeHistory.all')"
                            :options="pairMarketData" class="selectbox100" />
                    </div>
                </div>

                <div class="filter-flex">
                    <input title type="text" :placeholder="$t('account.detail.tradeHistory.inputplace.search')"
                        @keyup.enter="search" class="form-control" name="searchKey" v-model="filters.searchKey">
                    <button class="tradeHistory-btn__search" @click="search()">{{
                    $t('account.detail.tradeHistory.btn.search')
                }}</button>
                    <button class="tradeHistory-btn__search" @click="refresh()">{{
                    $t('account.detail.tradeHistory.btn.refresh')
                }}</button>
                </div>
            </div> -->
        </div>

        <trade-history-spot v-if="openOrderTypeTab === 0" :account-id="$route.query.id" />
        <trade-history-futures v-if="openOrderTypeTab === 1" :account-id="$route.query.id" />
        <!-- <data-table-affiliate :getData="getOrderHistoryRecords" ref="datatable" :column="12" :limit="10" :inLine="true"
            class="scroll" :total-user="tableParam.totalRecords">
            <th class="text-left" data-sort-field="id account accountId tradeId">{{
                    $t('account.detail.tradeHistory.table.tradeId') }}
            </th>
            <th class="text-left" data-sort-field="buyId">{{ $t('account.detail.tradeHistory.table.buyId') }}
            </th>
            <th class="text-left" data-sort-field="sellId">{{ $t('account.detail.tradeHistory.table.sellId') }}
            </th>
            <th class="text-center" data-sort-field="sellId">&emsp;{{ $t('account.detail.tradeHistory.table.pair') }}
            </th>
            <th class="text-center" data-sort-field="side">&emsp;{{ $t('account.detail.tradeHistory.table.side') }}</th>
            <th class="text-left" data-sort-field="type">{{ $t('account.detail.tradeHistory.table.qty') }}</th>
            <th class="text-left" data-sort-field="filled">{{ $t('account.detail.tradeHistory.table.price') }}
            </th>
            <th class="text-left" data-sort-field="time">{{
                    $t('account.detail.tradeHistory.table.time') }}</th>
            <th class="text-center" data-sort-field="action">&emsp;{{ $t('account.detail.tradeHistory.table.action') }}
            </th>

            <template slot="body" slot-scope="props">
                <tr>
                    <td class="text-left">
                        <span>{{ props.item.accountId }}</span>
                    </td>
                    <td class="text-left">
                        <span>{{ props.item.accountId }}</span>
                    </td>
                    <td class="text-left">
                        <span>{{ props.item.accountId }}</span>
                    </td>

                    <td class="text-center">
                        <span>{{ "SOL/USDT" }}</span>
                    </td>
                    <td class="text-center">
                        <span
                            :class="{ 'side-buy': props.item.accountId % 2 === 0, 'side-sell': props.item.accountId % 2 !== 0 }">{{
                    props.item.accountId % 2 === 0
                        ? $t('account.detail.tradeHistory.table.column.sideBuy') :
                        $t('account.detail.tradeHistory.table.column.sideSell') }}</span>
                    </td>
                    <td class="text-left">
                        <div class="text-top"><span>{{ "0.22196200" }}</span></div>
                    </td>
                    <td class="text-left">
                        <div class="text-top"><span>{{ "66,000.54" }}</span></div>
                    </td>
                    <td class="text-center">
                        <p style="text-align: left">{{ formatDate(props.item.updatedAt) }}</p>
                        <p style="text-align: left">{{ formatDate(props.item.updatedAt, true) }}</p>
                    </td>

                    <td class="text-center">
                        <img class="icon-action" src="@/assets/images/future/List.svg"
                            @click="actions({ accountId: props.item.accountId })" />
                    </td>
                </tr>
            </template>
        </data-table-affiliate> -->
    </div>
</template>

<script>

// import moment from "moment";
// import DatePicker from 'vuejs-datepicker';
// import rf from "../../lib/RequestFactory";
// import Modal from "../../components/Modal";
// import SelectBox from "../../components/SelectBox";
// import InputTextAndNumber from "../../components/InputTextAndNumber.vue";
// import UtilsUIComponent from "../../common/UtilsUIComponent";
import TradeHistorySpot from "../../pages/orders/TradeHistory.vue";
import TradeHistoryFutures from "../../pages/futures_records/TradeHistoryFutures.vue";

export default {
    name: "TradeHistory",
    components: {
        // Modal,
        // InputTextAndNumber,
        // DatePicker,
        // SelectBox,
        TradeHistorySpot,
        TradeHistoryFutures
    },
    data() {
        return {
            openOrderTypeTab: 0,
            // tableParam: {
            //     page: 1,
            //     limit: 10,
            //     totalRecords: 10,
            // },
            // pairCoinData: ["All", "BTC"],
            // pairMarketData: ["All", "USDT"],
            // filters: {
            //     startDate: moment().subtract(1, 'week').toDate(),
            //     endDate: moment().toDate(),
            //     pair: { coin: "All", market: "All" },
            //     searchKey: "",
            // },

        }
    },
    // watch: {
    //     isActive() {
    //         this.$refs.datatable.refresh();
    //     },
    //     filters: {
    //         handler(newFilters, oldFilters) {
    //             if (moment(this.formatDate(newFilters.endDate)).isBefore(this.formatDate(newFilters.startDate))) {
    //                 this.filters = { ...this.filters, endDate: oldFilters.startDate }
    //                 this.$refs.endDate.setDate(oldFilters.startDate);
    //             }
    //         },
    //         deep: true
    //     }
    // },
    methods: {
        // actions: function (dataItem) {
        //     if (dataItem) {
        //         UtilsUIComponent.showModal({
        //             type: 'confirm',
        //             title: window.i18n.t("Would you like to detail the page?"),
        //             btnConfirmLabel: window.i18n.t("account.detail.dialog.confirm.btn"),
        //             btnCancelLabel: window.i18n.t("account.detail.dialog.close.btn"),
        //             onConfirm: () => {
        //                 console.log("Cancel Oke")
        //             }
        //         });
        //     }
        // },
        setOrderHistoryTypeTabTypeTab: function (tabValue) {
            this.openOrderTypeTab = tabValue
        },
        // search: function () {
        //     console.log('Search Key', this.filters.searchKey)
        //     this.$refs.datatable.filter(this.filters.searchKey);
        // },
        // refresh: function () {
        //     console.log('Search Key', this.filters.searchKey)
        //     this.filters = {
        //         startDate: moment().subtract(1, 'week').toDate(),
        //         endDate: moment().toDate(),
        //         pair: { coin: "All", market: "All" },
        //         type: "All",
        //         status: "All",
        //         searchKey: "",
        //     }
        // },
        // getOrderHistoryRecords(params) {
        //     // const records = rf.getRequest('AccountRequest').getAffiliateAccounts(params);

        //     let meta = {}
        //     meta.symbol = this.filters.searchKey;
        //     params = Object.assign(params, meta);
        //     params.size = params.limit || 5;
        //     delete params.limit;

        //     const records = rf.getRequest('AdminRequest').getOrderFutureRecords(params); //Test
        //     records.then(data => {
        //         this.tableParam.totalRecords = data.metadata.total;
        //     })
        //     return records
        // },
        // getTypes() {
        //     return window._.map(COMMON_CONST.USER_INFORMATION_TYPE, item => {
        //         return { id: item, name: `${item.charAt(0).toUpperCase()}${item.slice(1)}` };
        //     });
        // },

        // getStatus() {
        //     return window._.map(COMMON_CONST.USER_INFORMATION_STATUS, item => {
        //         return { id: item, name: `${item.charAt(0).toUpperCase()}${item.slice(1)}` };
        //     });
        // },
        // formatDate(date, isTime) {
        //     if (isTime) return moment(date).format('HH:mm:ss')
        //     return moment(date).format('YYYY-MM-DD');
        // },
    },
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/variables";

.tradeHistory-container {
/*
    .icon-action {
        width: 20px;
        height: 20px;
        margin: 6px;
        cursor: pointer;
    }

    .side {
        &-buy {
            color: #00DD31;
        }

        &-sell {
            color: #FF5757;
        }
    }

    .status {
        padding: 10px;
        border-radius: 4px;

        &.pending {
            background-color: #FFE352;
        }

        &.partialFilled {
            background-color: #FFC590;
        }

        &.filled {
            background-color: #53D768;
        }

        &.canceled {
            background-color: #FF8C8A;
        }
    }


    .filter-title {
        color: #999999;
        font-family: "Roboto-Regular";
        margin-bottom: 10px;
    }

    .filter-flex {
        display: flex;
        align-items: center;
        gap: 10px;
    } */

    .tradeHistory-btn {
        background-color: transparent;
        padding: 10px;
        color: #999999;
        border: 0px transparent;
        margin-right: 10px;

        &.active {
            border-bottom: 2px solid #2D2D2D;
            color: black;
            font-family: "Roboto-Bold";
        }

        &__search {
            background-color: #00DDB3;
            color: white;
            font-family: "Roboto-Bold";
            border: none;
            min-width: 87px;
            border-radius: 2px;
            text-transform: uppercase;
            height: 30px;
        }
    }
}

.tradeHistory-actions {
    display: flex;
    flex-direction: column;
    margin: 50px 0px;
    margin-bottom: 0;

    &__tab {
        margin-bottom: 20px;
    }

    &__filters {
        display: flex;
        align-items: end;
        gap: 30px;
    }
}
</style>