<template>
  <div id="manual_dividend" class="boxCore manual_dividend history ">
    <div class="datatable history">
      <div class="filter-container">
      <!-- <div class="filter-container" v-if="product_base == 'margin'"> -->
        <div class="select_transaction_container">
          <div class="tab-header">
            <ul>
              <li :class="{'active': filters.type == exchangeType.spot}">
                <a
                        href="javascript:void(0)"
                        @click="selectTab(exchangeType.spot)"
                ><span>{{$t('common.spot_exchange')}}</span></a>
              </li>
              <li :class="{'active': filters.type === exchangeType.margin}">
                <a
                        href="javascript:void(0)"
                        @click="selectTab(exchangeType.margin)"
                ><span>{{ $t('common.margin_exchange') }}</span></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="filter-container">
        <div class="filter-item date mr_left0">
          <div class="text-date">{{ $t('orders.order_history.date') }}:</div>
          <date-picker v-model="startDate" class="date-picker-input no-right-radius" :format="customFormatter"></date-picker>
          <date-picker v-model="endDate" class="date-picker-input no-left-radius" :format="customFormatter"></date-picker>
        </div>
        <div class="filter-item pair" >
          <div class="label-text text-pair">
            <span v-if="filters.type == exchangeType.margin">{{ $t('funds.balances.coin') }}</span>
            <span v-else>{{ $t('order.open_order.market') }}:</span>
            </div>
          <div class="toolbar-element2">
            <select-box v-model="filters.market"
                        :placeholder="$t('order.order_history.all')"
                        class="select-market" :options="markets"
            />
          </div>
        </div>
        <div class="filter-item pair" >
          <div class="label-text text-pair">
            <span v-if="filters.type == exchangeType.margin">{{ $t('funds.contract') }}</span>
            <span v-else>{{ $t('order.order_history.pair') }}:</span>
          </div>
          <div class="toolbar-element2">
            <select-box
                    v-model="filters.currency"
                    :placeholder="$t('order.order_history.all')"
                    :options="coins"/>
          </div>
        </div>

        <div class="filter-item balance" >
          <div class="label-text text-pair">{{ $t('user_wallet.balance') }}:</div>
          <div class="toolbar-element2">
            <select-box
                    v-model="filters.balance"
                    :placeholder="$t('order.order_history.all')"
                    :options="balances"/>
          </div>
        </div>
        
        <div class="filter-item amount">
          <div class="label-text text-pair">{{ $t('airdrop_setting.email') }}:</div>
          <div class="toolbar-element2">
            <input title="" type="text" :placeholder="$t('orders.order_history.search_by_email')" @keyup.enter="search"
                   class="form-control" name="searchKey" v-model="filters.email"/>
          </div>
        </div>

        <div class="filter-item btn-action">
          <button class="btn btn-primary" @click="search()">{{ $t('orders.order_history.search') }}</button>
          <button class="btn btn-reset" @click="refresh()">{{ $t('orders.order_history.reset') }}</button>
        </div>
      </div>


        <data-table-4 :getData="getData"
        
                  :limit="10"
                  :column='9'
                  :onPageLoad="true"
                  @DataTable:finish="onDatatableFinish"
                  ref="datatable" class="scroll">
        <th class="text-left " data-sort-field="user_id">{{ $t('m_account.id') }}</th>
        <th class="text-left " data-sort-field="email">{{ $t('base_deposit_withdraw_title_email') }}</th>
        <th class="text-left" data-sort-field="filter_from">{{ $t('airdrop_setting.date_from_to') }}</th>
        <th class="text-left " data-sort-field="total_trade_volume">Trading Volume</th>
        <th class="text-left " data-sort-field="coin"><span v-if="filters.type == 'spot'">{{ $t('airdrop_setting.market') }}</span><span v-if="filters.type == 'margin'">{{ $t('airdrop_setting.contract') }}</span></th>
        <th class="text-left " data-sort-field="balance">{{ $t('menu.wallet') }}</th>
        <th class="text-left " data-sort-field="bonus_amount">Paid Amount</th>
        <th class="text-left " data-sort-field="status">{{ $t('airdrop_setting.status') }}</th>
        <th class="text-left " data-sort-field="created_at">{{ $t('table.created_date')}} </th>
        <template slot="body" slot-scope="props">
          <tr>
            <td class="text-left" style="min-width: 100px;">
              {{ props.item.user_id }}
            </td>
            <td class="text-left" style="min-width: 200px;">
              {{ props.item.email }}
            </td>
            <td class="text-left" style="min-width: 112px;">
              {{ props.item.filter_from | formatDate }} <br/>
              {{ props.item.filter_to | formatDate }}
            </td>
            <td class="text-left" style="min-width: 150px;">
              {{ props.item.total_trade_volume | toNumber}}
            </td>

            <td class="text-left" v-if="filters.type == exchangeType.margin" style="min-width: 134px;">
              {{ props.item.coin | uppercase  }}
            </td>
            <td class="text-left" v-else style="min-width: 134px;">
              {{ props.item.coin | uppercase  }} / {{ props.item.market | uppercase  }} 
            </td>
            <td class="text-left" style="min-width: 211px;">
              {{ props.item.balance | getWallet}}
            </td>
            <td class="text-left" style="min-width: 150px;">
              {{ props.item.bonus_amount | toNumber}} {{ props.item.bonus_currency | uppercase}}
            </td>
            <td class="text-left" style="min-width: 110px;">
              {{ props.item.status | upperFirst}}
              <!-- {{ props.item.status | getStatus}} -->
            </td>
            <td class="text-left" style="min-width: 172px;">
              {{ props.item.created_at }}
            </td>

          </tr>
        </template>
      </data-table-4>
    </div>
  </div>
</template>

<script>
  import rf from '../../lib/RequestFactory';
  import BigNumber from 'bignumber.js';
  import DatePicker from "vuejs-datepicker";
  import moment from "moment";
  import COMMON_CONST from "../../common/Const";
  import SelectBox from "../../components/SelectBox";
  import CoinInput from '../../common/CoinInput.vue'
  import CurrencyInput from "../../components/CurrencyInput";
  import Modal from "../../components/Modal";
import { filter } from 'minimatch';
import DataTable4 from "../../components/DataTable4";

  export default {
    components: {
      DatePicker,
      SelectBox,
      CoinInput,
      CurrencyInput,
      DataTable4
    },
    props: {
      disabled: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        product_base: COMMON_CONST.PRODUCT_BASE,
        titlePage: window.i18n.t("airdrop_setting.manual_dividend_history"),
        startDate: moment().subtract(1, 'week').toDate(),
        endDate: moment().toDate(),
        filters: {
          market: "",
          currency: "",
          searchKey: "",
          balance:"",
          type: COMMON_CONST.TYPE_EXCHANGE_BALANCE,
        },

        exchangeType : {
          'spot': COMMON_CONST.TYPE_EXCHANGE_BALANCE,
          'margin': COMMON_CONST.TYPE_MARGIN_BALANCE
        },
        balances: [
          {id:'all', name: 'All'},
          {id:COMMON_CONST.TYPE_MAIN_BALANCE.toLowerCase(), name: COMMON_CONST.MAIN_BALANCE},
          {id:COMMON_CONST.TYPE_AIRDROP_BALANCE.toLowerCase(), name: COMMON_CONST.DIVIDEND_NORMAL},
          {id:COMMON_CONST.TYPE_DIVIDEND_BONUS_BALANCE.toLowerCase(), name: COMMON_CONST.DIVIDEND_BY_ADMIN}
        ],
        markets: [],
        allCoins: [],
        coins: [],
        lstChecked: '',
        isChecked : false,
        datatableRows: [],
        modalTitle: window.i18n.t("airdrop_setting.bonus.bonus_setting"),
        precision: 8,
        lstUsers: [],
      }
    },

    watch: {
      "filters.market" : function (currency) {
        switch (this.filters.type) {
          case this.exchangeType.spot:
            this.coins = [];
            this.coins.push({id: 'all', name: 'All'});
            this.allCoins.map((obj) => {
              let pair = obj.name.split('/');
              if (pair[1] == currency.name) {
                this.coins.push({id: obj.id, name: obj.name});
              }
            });
            break;
          case this.exchangeType.margin:
            this.coins = [];
            this.coins.push({id: 'all', name: 'All'});
            this.allCoins.map((obj) => {
              if (obj.root == currency.name) {
                this.coins.push({id: obj.id, name: obj.name});
              }
            });
            break;
          default: break;
        }
        this.filters.coin = this.coins[0];
      },


    },
    created() {
      this.requestSpot();
    },
    filters: {
      formatDate: function (val) {
        return val ? val.substring(0, 10) : '';
      },
      getStatus: function(val) {
        const status = COMMON_CONST.BONUS_BALANCE_TRANSFER_STATUS;
        let str = status[val];
        return window.i18n.t(str);
      }
    },
    methods: {

      requestMargin() {
        this.coins = [];
        rf.getRequest('AdminRequest').getInstrument([]) .then(res => {
          const root = [];
          this.allCoins = [];
          window._.map(res.data, (item) => {
            root.push({id: item.root_symbol, name: item.root_symbol});
            this.allCoins.push({id: item.id, 'name': item.symbol, 'root': item.root_symbol});
          });
          // Truncate duplicate elements
          let output = [];
          output.push({id: 'all', name: 'All'});
          let keys   = [];
          root.forEach(function (item) {
            var key = item['id'];
            if (keys.indexOf(key) === -1) {
              keys.push(key);
              output.push(item);
            }
          });
          this.markets = output;
        });
      },
      requestSpot() {
        // get all coin
        this.allCoins = [];
        rf.getRequest('MasterdataRequest')
                .getAll().then(res => {
          try {
            window._.map(res.coin_settings, item => {
              this.allCoins.push({id: item.id, name: (item.coin + '/' + item.currency).toUpperCase()});
            });
          } catch (ex) {
            console.log(ex);
          }
        });
        // get all markets
        rf.getRequest('AdminRequest').getPriceGroupCurrency().then(res => {
          try {
            this.markets = [];
            this.markets.push({ id: 'all', name: 'All' });
            window._.map(res.data, item => {
              this.markets.push({ id: item.currency, name: item.currency.toUpperCase() });
            });
          }
          catch (ex) {
            console.error(ex);
          }
        });
      },

      selectTab(type) {
        this.coins = [];
        this.allCoins = [];
        this.resetInput();
        this.filters.type = type;
        this.$refs.datatable.refresh();
        switch (type) {
          case this.exchangeType.margin:
            this.requestMargin();
            break;
          case this.exchangeType.spot:
            this.requestSpot();
            break;
          default: break;
        }

      },
      convertTime(time) {
        let stillUtc = moment.utc(time).toDate();
        let local = moment(stillUtc).local().format('YYYY-MM-DD HH:mm:ss');
        return local;
      },

      search() {
        let filterParams = {
          start_date: (moment(this.startDate)
            .startOf("day")
            .format("YYYY-MM-DD HH:mm:ss")),
          end_date: (moment(this.endDate)
            .endOf("day")
            .format("YYYY-MM-DD HH:mm:ss")),
          email: this.filters.email,
          balance: this.filters.balance.id
        };
        if (moment(this.startDate).format('YYYY MM DD') > moment(this.endDate).format('YYYY MM DD')) {
          this.$toastr("error", this.$t("common.date.warning"));
          return;
        }
        if (this.filters.searchKey) {
          filterParams.search_key = this.filters.searchKey;
        }
        if (this.filters.currency && this.filters.currency.id != 'all') {
          filterParams.coin = this.filters.currency.name.split('/')[0].toLowerCase();
        }
        if (this.filters.market) {
          filterParams.market = this.filters.market.id;
        }
        this.$refs.datatable.filter(filterParams);
        // this.$refs.datatable.filter(this.searchParams());
      },
      getData(params) {
        let filterParams = {
          start_date: (moment(this.startDate)
            .startOf("day")
            .format("YYYY-MM-DD HH:mm:ss")),
          end_date: (moment(this.endDate)
            .endOf("day")
            .format("YYYY-MM-DD HH:mm:ss")),
        };
        filterParams.type = this.filters.type;
        // filterParams. = this.filters.type;
        return rf.getRequest('AdminRequest').getManualDividendHistory(Object.assign(params, filterParams)).then(res => {
          if(res.data != undefined) {
            return res.data;
          }
        }).catch(error => {
          console.log(error);
        });
      },
      refresh() {
        this.resetInput();
        this.isChecked = false;
        this.$refs.datatable.refresh();
      },

      resetInput() {
        this.filters = Object.assign(this.filters, {
          market: "",
          coin: "",
          balance: "",
          email: ""
        });

        this.startDate = moment().subtract(1, 'week').toDate();
        this.endDate = moment().toDate();
      },

      onDatatableFinish() {
        const datatable = this.$refs.datatable;
      },

      capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
      },

      formatDateTime(date) {
        return moment(date, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss');
      },

      customFormatter(date) {
        return moment(date).format('YYYY - MM - DD');
      },

    },
    mounted() {
      this.$emit('EVENT_PAGE_CHANGE', this);
    }

    // ALl on load binding

  }
</script>

<style lang="scss" scoped>
@import "@/assets/sass/variables";
  .select_transaction_container {
    padding: 10px 0px 20px 0px;
    .input-radio {
      label {
        display: inline;
        font-weight: normal;
        text-align: center;
        margin-right: 25px;
        span {
          font-size: $font_big;
        }
        input[type="radio"] {
          display: inline-block;
          margin-top: 0px;    
          position: relative;
          top: -2px;
        }
      }
    } 
  }
  .tab-header {
    display: block;
    width: 100%;
    float: left;
    margin: 10px 0px 23px 0px;
    ul {
      list-style: none;
      padding-left: 0px;
      li {
        float: left;
        margin-right: 25px;
        font-size: $font_big;
        color: $color-grey-dark;
        display: block;
        >a {
          color: $color-grey-dark;
          font-family: $font-family-roboto-medium;
          font-weight: 500;
          font-size: $font_big;
          display: block;
          float: right;
          &::before {
            content: "";
            display: inline-block;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            border: solid 1px #979797;
          }
          span {
            padding-left: 5px;
          }
        }
        &.active {
          >a {
            &::before {
              border: solid 4px $color-jungle-green;
            }
              color: $color-jungle-green;
          }
        }
      }
    }
  }
  .empty-data {
    text-align: center;
    padding-top: 15px;
    background-color: $color_white;
  }
  .w_20 {
    width: 20%
  }
  .w_25 {
    width: 25%;
  }
  .opacity-row {
    opacity: 0.4;
  }
  .text-wrap {
    word-wrap: break-word;
  }
  .toolbar-element2 {
    display: inline-block;
    float: left;
  }
  .bw-element {
    float: left;
    font-size: $font_medium;
    margin-left: 5px;
    height: 30px;
    line-height: 30px;
    margin-right: 5px;
    color: $color-grey-dusty;
  }
  .checkbox {
    margin-top: 0px;
    display: inline-block;
    font-size: $font_root;
    margin-bottom: 0px;
    font-weight: normal;
    height: 30px;
    line-height: 30px;
    input[type="checkbox"] {
      display: none;
    }
    span {
      position: relative;
      content: "";
      display: inline-block;
      border: 1px solid $color_alto;
      width: 16px;
      height: 16px;
      top: 3px;
      left: 8px;
      border-radius: 2px;
      margin-right: 8px;
      position: relative;
      background-color: $color_white;
    }
    input:checked {
      & + span{
        &:after{
          opacity: 0.9;
          content: '';
          position: absolute;
          width: 14px;
          height: 7px;
          background: transparent;
          top: 2px;
          left: 0px;
          border: 3px solid $color_blue_logo;
          border-top: none;
          border-right: none;
          transform: rotate(-45deg);
          -webkit-transform: rotate(-45deg);
          -moz-transform: rotate(-45deg);
          -o-transform: rotate(-45deg);
          -ms-transform: rotate(-45deg);
        }
      }
    }
  }
  .manual_dividend .history {
    margin-bottom: 100px;
    .filter-container {
      margin: 10px 0px 30px 0px;
      height: 50px;
      .filter-item.kyc{
        .toolbar-element2{
          width: 100px;
        }
      }
      .filter-item {
        float: left;
        margin: 0 5px;
        &.mr_left0 {
          margin-left: 0px;
        }
        input {
          margin-top: 23px;
          display: inline-block;
          width: 145px;
          font-family: $font-family-roboto;
          &:hover, &:active, &:focus {
            border-color: $color-jungle-green;
          }
        }
        select {
          width: 65px;
          display: inline-block;
          font-family: $font-family-roboto;
          font-size: $font_root;
          &:hover, &:active, &:focus {
            border-color: $color-jungle-green;
          }
        }
        button {
          width: auto;
          margin: 23px 2px 0px 2px;
          height: 30px;
          font-size: $font-smaller;
          font-family: $font-family-roboto;
        }
        .btn-reset {
          background-color: $color-caribbean-green;
          border: 1px solid $color-caribbean-green;
          width: 80px;
          text-transform: uppercase;
          color: $color-white;
          font-weight: 600;
          &:hover {
            border-color: $color-aquamarine;
            background-color: $color-aquamarine;
          }
        }
        .btn-primary {
          padding: 0;
          min-width: 80px;
          &:hover {
            border-color: $color-aquamarine;
            background-color: $color-aquamarine;
          }
        }

      }
      .pair {
        input {
          width: 65px;
        }
      }
      .balance{
        .toolbar-element2 {
          width: 150px;
          margin-left: 15px;
          min-width: 172px;
          
        }
      }
      .amount {
        input {
          margin-top: 0px;
          height: 30px;
          margin-left: 16px;
        }
      }
      .text-date {
        color: $color-grey-dusty;
        margin-bottom: 5px;
        font-size: 13px;
        font-family: Roboto-Regular;
      }
      .text-pair {
        font-family: Roboto-Regular;
        padding-left: 16px;
        color: $color-grey-dusty;
        margin-bottom: 5px;
        font-size: 13px;
      }
      .side {
        margin: 0 10px;
      }
    }
    .form-control {
      background: $color-white-smoke;
      height: 30px;
      font-size: $font_root;
      padding-left: 11px;
      padding-right: 0;
    }
    .datatable{
      button.btnForm.bt-action{
        padding-left: 5px !important;
        padding-right: 5px !important;
        font-weight: 400 !important;
      }
      table {
        thead {
          th{
            padding: 8px 10px 4px 14px !important;
            &:first-child:after{
              width: 15px;
            }
          }
        }
      }
    }
    .buy {
      color: $text-color-jade;
    }
    .sell {
      color: $text-color-red;
    }
    .btn-detail {
      background-color: $color-caribbean-green;
      padding: 2px 10px;
      font-size: $font-smaller;
      color: $color-white;
      border:1px solid $color-caribbean-green;
      border-radius: 3px;
      text-transform: uppercase;
      font-family: $font-family-roboto;
      &:hover{
        background-color: $color-aquamarine;
        border-color: $color-aquamarine;
      };
    }
  }
  .form-group-detail {
    .group-detail-form-group {
      .group-detail-form-label{
        width: 100%;
        color: $color-grey-dusty;
        float: left;
        font-weight: normal;
        font-family: $font-family-roboto;
        margin-bottom: 0px;
        margin-top: 10px;
      }

      .group-detail-form-value {
        width: 100%;
        padding: 8px 16px;
        border: 1px solid #dee3eb;
        height: 35px;
        line-height: 35px;
        border-radius: 3px;
        font-family: $font-family-roboto;
        // font-weight: 600;
        &:focus {
          border-color: $color-jungle-green;
        }
      }
      .textarea_input {
        height: 120px;
      }
    }
    .error-box {
      display: block;
      float: left;
      overflow: hidden;
      width: 100%;
      span {
        font-family: $font-family-roboto-medium !important;
      }
    }
    .is-error {
      color: #ff5252 !important;
    }
    .input-error {
      border: 1px solid #f74940 !important;
    }
  }
</style>
<style lang="scss">
@import "@/assets/sass/variables";
  .history{
    table tbody td {
      padding-right:0px !important;
    }
  }
    .tab-header {
      display: block;
      width: 100%;
      float: left;
      margin: 10px 0px 23px 0px;
      ul {
        list-style: none;
        padding-left: 0px;
        li {
          float: left;
          margin-right: 25px;
          font-size: $font_big;
          color: $color-grey-dark;
          display: block;
          >a {
            color: $color-grey-dark;
            font-family: $font-family-roboto-medium;
            font-weight: 500;
            font-size: $font_big;
            display: block;
            float: right;
            &::before {
              content: "";
              display: inline-block;
              width: 12px;
              height: 12px;
              border-radius: 50%;
              border: solid 1px #979797;
            }
            span {
              padding-left: 5px;
            }
          }
          &.active {
            >a {
              &::before {
                border: solid 4px $color-jungle-green;
              }
              color: $color-jungle-green;
            }
          }
        }
      }
    }

    .filter-item.balance {
      .sc_search_select {
        .group_search_select {
          .button_drop_search {
            &:hover, &:active, &:focus {
              border-color: $color-jungle-green !important;
              i.icon-arrow1 {
                color: $color-jungle-green !important;
              }
            }
          }
          .box_list_search_select {
            width: 116% !important;
          }
        }
      }
    }

    .filter-item.pair {
      .sc_search_select {
        .group_search_select {
          .button_drop_search {
            height: 30px;
            margin-left: 15px;
            width: 80px;
            margin-right: 15px;
            padding: 5px 10px 5px 10px !important;
            border-radius: 3px;
            font-size: $font-small;
            font-family: $font-family-roboto;
            i.icon-arrow1 {
              margin-left: 5px;
              color: $color_dove_gray;
            }
            &:hover, &:active, &:focus {
              border-color: $color-jungle-green;
              i.icon-arrow1 {
                color: $color-jungle-green;
              }
            }
          }
          .box_list_search_select {
            left: 14px;
            width: 96px !important;
            .list_search_select {
              li {
                font-family: $font-family-roboto;
                font-size: $font-small;
                &:hover, &:active, &:focus {
                  color: $color-jungle-green;
                }
              }
            }
          }
        }
      }
    }
    .filter-item.balance {
      .toolbar-element2 {
          width: 150px;
          margin-left: 15px;
          min-width: 172px;
        .sc_search_select {
          .group_search_select {
            .button_drop_search {
              height: 30px;
              padding-top: 5px;
            }
          }
        }
      }
    }
    .error-msg{
      color: $color-red-main;
    }

    .unit {
      top:50%;
      transform: translateY(-50%);
      right: 0;
      position: absolute;
      // width: 200px;
      text-align: center;
    }
    .borderrrr {
      border: solid 1px #299b82;
    }
    .error {
      border: solid 1px $color-red-main;
      color: $color-red-main
    }
    .Rectangle-6-Copy-9 {
      position: relative;
      overflow: hidden;
      display: block;
      padding-bottom: 1px;
      text-align: left;
      border-radius: 3px;
    }
</style>