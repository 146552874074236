import BaseRequest from './BaseRequest'

export default class MappingInstrument extends BaseRequest {

    getBitmexInstruments() {
        let url = '/admin/api/bitmex/bitmex-instruments';
        return this.get(url, {});
    }

    getMappingInstrument(){
        let url = '/admin/api/bitmex/get-mapping-instrument';
        return this.get(url,{})
    }

    getMappingSetting(){
        let url = '/admin/api/bitmex/get-mapping-setting';
        return this.get(url,{})
    }

    updateMappingInstrument(params){
        let url = '/admin/api/bitmex/update-mapping-instrument';
        return this.put(url,params);
    }
}
