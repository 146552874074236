 <template>
  <div class="trading-list-page" id="page_review_erc20">
    <div class="content-page table-device-list-wrapper">
      <div class="content-box">
        <div class="box-item">
<!--          <p class="box__title font-size-16">{{ $t('review_register_erc_2_0.token_information') }}</p>-->
          <div class="box__infor">
            <div class="item">
              <span class="infor__name">{{ $t('review_register_erc_2_0.contract_address') }}</span>
              <div class="infor__content">
                <p class="text">{{ data.params.coin_setting.contract_address }}</p>
              </div>
            </div>
            <div class="item">
              <span class="infor__name">{{ $t('review_register_erc_2_0.token_name') }}</span>
              <div class="infor__content">
                <p class="text">{{ data.params.coin_setting.name }}</p>
              </div>
            </div>
            <div class="item">
              <span class="infor__name">{{ $t('review_register_erc_2_0.token') }}</span>
              <div class="infor__content">
                <p class="text">{{ data.params.coin_setting.symbol }}</p>
              </div>
            </div>
            <div class="item">
              <span class="infor__name">{{ $t('review_register_erc_2_0.icon') }}</span>
              <div class="infor__content">
                <img class="coin-icon" :src="data.params.coin_setting.image_base64"
                     :alt="data.params.coin_setting.symbol">
              </div>
            </div>
            <div class="item">
              <span class="infor__name">{{ $t('review_register_erc_2_0.decimals') }}</span>
              <div class="infor__content disable">
                <p class="text">{{ data.params.coin_setting.decimals }}</p>
              </div>
            </div>
            <div class="item">
              <span class="infor__name">{{ $t('review_register_erc_2_0.required_confirmations') }}</span>
              <div class="infor__content disable">
                <p class="text">{{ data.params.coin_setting.required_confirmations }}</p>
              </div>
            </div>
            <div class="item">
              <span class="infor__name">{{ $t('review_register_erc_2_0.type') }}</span>
              <div class="infor__content disable">
                <p class="text">{{ data.params.coin_setting.type }}</p>
              </div>
            </div>
            <div class="item">
              <span class="infor__name">{{ $t('review_register_erc_2_0.explorer') }}</span>
              <div class="infor__content disable" disabled>
                <p class="text">{{ data.params.coin_setting.explorer }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="box-item">
          <div class="box__title font-size-16">{{ $t('review_register_erc_2_0.trading_pair') }}</div>
          <data-table3 :get-data="getTradingSettings" width-table="740px" class="table-01">
            <th>{{ $t('review_register_erc_2_0.pair') }}</th>
            <th>{{ $t('review_register_erc_2_0.market_price') }}</th>
            <th>{{ $t('review_register_erc_2_0.precision') }}</th>
            <th>{{ $t('review_register_erc_2_0.minimum_amount') }}</th>
            <th>{{ $t('review_register_erc_2_0.quantity_precision') }}</th>
            <th>{{ $t('review_register_erc_2_0.minimum_total') }}</th>
            <th>{{ $t('trading_information.zone') }}</th>
            <template slot="body" slot-scope="props">
              <tr>
                <td>{{ props.item.coin | uppercase }} / {{ props.item.currency | uppercase }}</td>
                <td>{{ props.item.market_price | formatCurrencyAmount(props.item.currency, '0') }} {{ props.item.currency | uppercase }}</td>
                <td>{{props.item.price_precision | formatCurrencyAmount(props.item.currency, '0') }}</td>
                <td>{{ props.item.minimum_quantity | formatCurrencyAmount(props.item.coin, '0') }} {{ props.item.coin | uppercase }}</td>
                <td>{{ convertNumberToPrecision(props.item.quantity_precision)  }} {{ props.item.coin | uppercase }}</td>
                <td>{{ props.item.minimum_amount | formatCurrencyAmount(props.item.currency, '0') }} {{ props.item.currency | uppercase }}</td>
                <td>{{ props.item.zone | zoneName }}</td>
              </tr>
            </template>
          </data-table3>
        </div>
        <div class="box-item">
          <div class="box__title">{{ $t('review_register_erc_2_0.trading_fee') }}</div>
          <data-table3 :get-data="getTradingSettings" width-table="740px" class="table-02">
            <th>{{ $t('review_register_erc_2_0.pair') }}</th>
            <th>{{ $t('trading_information.taker_fee_percent') }}</th>
            <th>{{ $t('trading_information.maker_fee_percent') }}</th>
            <template slot="body" slot-scope="props">
              <tr>
                <td>{{ props.item.coin | uppercase }} / {{ props.item.currency | uppercase }}</td>
                <td>{{ props.item.taker_fee }}</td>
                <td>{{ props.item.maker_fee }}</td>
              </tr>
            </template>
          </data-table3>
        </div>
        <div class="box-item">
          <p class="box__title">{{ $t('review_register_erc_2_0.withdrawal_information') }}</p>
          <div class="box__infor">
            <div class="item">
              <span class="infor__name">{{ $t('review_register_erc_2_0.currency') }}</span>
              <div class="infor__content">
                <p class="text">{{ data.params.withdrawal_setting.currency | uppercase }}</p>
              </div>
            </div>
            <div class="item">
              <span class="infor__name">{{ $t('review_register_erc_2_0.fee') }}</span>
              <div class="infor__content disable">
                <p class="text">
                  {{ data.params.withdrawal_setting.fee | formatCurrencyAmount(data.params.withdrawal_setting.currency, '0') }} {{ data.params.withdrawal_setting.currency | uppercase }}
                </p>
              </div>
            </div>
            <div class="item">
              <span class="infor__name">{{ $t('review_register_erc_2_0.minimum_withdrawal') }}</span>
            </div>
            <div class="item1" v-for="item in [1, 2, 3, 4]">
              <span class="infor__name1">Level {{item}}</span>
              <div class="infor__content">
                <p class="text">
                  {{ data.params.withdrawal_setting['minium_withdrawal' + item] | formatCurrencyAmount(data.params.withdrawal_setting.currency, '0') }}
                  {{ data.params.withdrawal_setting.currency | uppercase }}
                </p>
              </div>
            </div>
            <div class="clearfix clearfix_10"></div>
            <!-- ================================================== -->
            <div class="item">
              <span class="infor__name">{{ $t('review_register_erc_2_0.limit') }}</span>
            </div>
            <div class="item1" v-for="item in [1, 2, 3, 4]">
              <span class="infor__name1">Level {{item}}</span>
              <div class="infor__content">
                <p class="text">
                  {{ data.params.withdrawal_setting['limit' + item] | formatCurrencyAmount(data.params.withdrawal_setting.currency, '0') }}
                  {{ data.params.withdrawal_setting.currency | uppercase }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="warning-text">
        {{ $t('review_register_erc_2_0.*_check_register_erc20_information') }}
      </div>

      <div class="redirect-box">
        <button class="button-prev" @click="$emit('pre-step')">
          {{ $t('review_register_erc_2_0.btn_back') }}
        </button>
        <button class="btn button-next" @click="register">{{ $t('review_register_erc_2_0.btn_register') }}</button>
      </div>
    </div>
    <loading-modal/>
  </div>
</template>

<script>
  import ModalTradingPairInformation from '../../../modals/TradingPairInformation.vue';
  import LoadingModal from '../../../modals/Erc20LoadingModal.vue';
  import rf from '../../../lib/RequestFactory';
  import BigNumber from 'bignumber.js';

  export default {
    data () {
      return {
        isLoading: false,
      }
    },
    inject: ['data'],
    components: {
      ModalTradingPairInformation,
      LoadingModal
    },
    mounted () {
      window.onbeforeunload = () => {
        return 'Are you sure you want to leave?';
      };
    },
    methods: {
      convertPrecisionToNumber (precision) {
        return Math.abs(new BigNumber(`${precision || 0}`).e);
      },
      convertNumberToPrecision (number) {
        if (!(parseInt(number) > 0)) {
          return '0';
        }
        return '0.' + '0'.repeat(number - 1) + 1;
      },
      getTradingSettings () {
        return new Promise((resolve) => {
          let data = this.data.params.trading_setting;
          this.rows = data;
          return resolve({ data });
        });
      },

      register () {
        if (this.isLoading) {
          return;
        }

        this.isLoading = true;

        this.$modal.show('erc20-modal-loading');
        this.data.params.coin_setting.symbol = _.toLower(this.data.params.coin_setting.symbol);
        this.data.params.trading_setting = this.data.params.trading_setting.map(item => ({
          ...item,
          quantity_precision: this.convertNumberToPrecision(item.quantity_precision)
        }));
          rf.getRequest('MasterdataRequest').registerErc20(this.data.params).then(res => {
              this.isLoading = false;
              this.$modal.hide('erc20-modal-loading');
              if(res.data.status) {
                this.$toasted.show(
                  window.i18n.t('review_register_erc_2_0.success_msg'),
                  {
                    position: 'bottom-right',
                    duration: 3000,
                    className: 'content_toasted toast-success-erc20',
                  }
                );

                this.$emit('back-step1')
                this.data.params.coin_setting = {};
                this.data.params.coin_setting.image = undefined;
                this.data.params.trading_setting = [];
                this.data.params.withdrawal_setting = {};
              } else {
                this.$toasted.show(
                  window.i18n.t('Register Erc20 Fail'),
                  {
                    position: 'bottom-right',
                    duration: 3000,
                    className: 'content_toasted toasted-fail-erc20',
                  }
                );
              }


        }).catch(error => {
          console.log(error);
          this.$modal.hide('erc20-modal-loading');

          this.$toasted.show(
            window.i18n.t('Register Erc20 Fail'),
            {
              position: 'bottom-right',
              duration: 3000,
              className: 'content_toasted toasted-fail-erc20',
            }
          );
        }).finally(() => {
          this.isLoading = false;
          this.$modal.hide('erc20-modal-loading');
        });
      },
    }
  };
</script>

<style lang="scss" scoped>
@import "@/assets/sass/variables";
  $columnTableMap1: (
    1: 109px,
    2: 134px,
    3: 137px,
    4: 142px,
    5: 144px,
  );

  $columnTableMap2: (
    1: 165px,
    2: 169px,
    3: 169px,
    4: 163px,
  );

  .trading-list-page {
    padding: 10px 0px;
    position: relative;

    .button-prev, .button-next {
      letter-spacing: 0.92px;
      width: 120px;
      text-align: center;
      padding: 5px;
      font-weight: 500;
      font-family: $font-family-roboto-bold;
      line-height: 20px;
      height: 35px;
      border-radius: 3px;
      font-size: $font-smaller;
      color: $color-white;
      text-align: center;
      text-transform: uppercase;
      transition: 0.5s;
      background-color: $color-caribbean-green;
      border : 1px solid $color-caribbean-green;
      &.disable {
        cursor: not-allowed;
      }
      &:hover{
        background-color: $color-aquamarine;
        border : 1px solid $color-aquamarine;
        color: $color-white;
      }
    }
    .button-prev  {
      width: 90px;
      float: left;
      margin-right: 13px;
    }

    .heading-title {
      padding-bottom: 25px;
      border-bottom: 1px solid $color_alto;
      margin-bottom: 30px;
    }

    .content-page {
      margin-bottom: 34px;
    }

    .description-content {
      font-size: 16px;
      margin-bottom: 25px;
    }

    table {
      max-width: 740px;
      table-layout: fixed;

      tr td {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .table-01 {
      @each $index, $width in $columnTableMap1 {
        th {
          &:nth-child(#{$index}) {
            min-width: $width;
          }
        }
      }
      th {
        font-family: $font-family-roboto;
        font-size: $font-small;
        &:nth-child(4) {
          padding: 5px 10px 5px 15px;
        }
      }
    }

    .table-02 {
      @each $index, $width in $columnTableMap2 {
        th {
          &:nth-child(#{$index}) {
            width: $width;
          }
        }
      }
      th {
        font-family: $font-family-roboto;
        font-size: $font-small;
        &:nth-child(4) {
          padding: 10px;
        }
      }
    }

    .content-box {
      margin-bottom: 30px;
    }

    .box-item {
      margin-bottom: 42px;
      &:last-child {
        margin-bottom: 0;
      }

      .box__title {
        font-size: $font_big;
        color: $color-grey-dark;
        margin-bottom: 16px;
        font-family: $font-family-roboto-medium;
        text-transform: uppercase;
        line-height: 19px;
        font-weight: 500;
        &.font-size-16 {
          font-size: $font_semi_big;
        }
      }

      .box__infor {
        .infor__name {
          font-size: $font_root;
          line-height: 2.5;
          color: $color-grey-dusty;
          display: inline-block;
          width: 235px;
          font-family: $font-family-roboto;
        }
        .infor__name1 {
          font-size: $font_small;
          line-height: 2.5;
          color: $color-grey-dusty;
          display: inline-block;
          width: 225px;
          font-family: $font-family-roboto;
        }
        .infor__content {
          font-size: $font_root;
          line-height: 2.5;
          color: $color-grey-dark;
          display: inline-block;
          .text {
            margin-bottom: 0;
            font-size: $font_root;
            font-family: $font-family-roboto-medium;
          }
          .coin-icon {
            width: 20px;
            // border-radius: 50%;
          }
        }
      }
    }

    .warning-text {
      font-size: $font-small;
      line-height: 2.23;
      color: $color-denim;
      margin-bottom: 15px;
      font-family: $font-family-roboto;
    }
  }
  .item1 {
    height: 25px;
    margin-left: 10px;
    font-size: $font-small;
  }
  .clearfix_10 {
    height: 10px;
  }
</style>

<style lang="scss">
@import "@/assets/sass/variables";
  #page_review_erc20 {
    .settingReviewSrc20 {
      .v--modal { 
        background-color: transparent;
        box-shadow: none;
      }
      .popup__title {
        // display: n
      }
    }
  }
  .content_toasted {
    // position: relative;
    pointer-events: auto;
    // overflow: hidden;
    margin: 0 0 6px !important;
    padding: 15px 15px 15px 50px !important;
    width: 300px !important;
    height: 50px !important;
    border-radius: 3px 3px 3px 3px !important;
    background-position: 15px center;
    background-repeat: no-repeat;
    box-shadow: 0 0 12px $color_grey !important;
    color: $color-white !important;
    opacity: 0.8 !important;
    position: fixed !important;
    z-index: 999999 !important;
    pointer-events: none  !important;
    right: 12px !important;
    top: auto !important;
    bottom: 6px !important;
  }
  .toast-success-erc20 {
    background-color: #51A351 !important;
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAADsSURBVEhLY2AYBfQMgf///3P8+/evAIgvA/FsIF+BavYDDWMBGroaSMMBiE8VC7AZDrIFaMFnii3AZTjUgsUUWUDA8OdAH6iQbQEhw4HyGsPEcKBXBIC4ARhex4G4BsjmweU1soIFaGg/WtoFZRIZdEvIMhxkCCjXIVsATV6gFGACs4Rsw0EGgIIH3QJYJgHSARQZDrWAB+jawzgs+Q2UO49D7jnRSRGoEFRILcdmEMWGI0cm0JJ2QpYA1RDvcmzJEWhABhD/pqrL0S0CWuABKgnRki9lLseS7g2AlqwHWQSKH4oKLrILpRGhEQCw2LiRUIa4lwAAAABJRU5ErkJggg==") !important;
  }
  .toasted-fail-erc20 {
    background-color: #BD362F !important;
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAHOSURBVEhLrZa/SgNBEMZzh0WKCClSCKaIYOED+AAKeQQLG8HWztLCImBrYadgIdY+gIKNYkBFSwu7CAoqCgkkoGBI/E28PdbLZmeDLgzZzcx83/zZ2SSXC1j9fr+I1Hq93g2yxH4iwM1vkoBWAdxCmpzTxfkN2RcyZNaHFIkSo10+8kgxkXIURV5HGxTmFuc75B2RfQkpxHG8aAgaAFa0tAHqYFfQ7Iwe2yhODk8+J4C7yAoRTWI3w/4klGRgR4lO7Rpn9+gvMyWp+uxFh8+H+ARlgN1nJuJuQAYvNkEnwGFck18Er4q3egEc/oO+mhLdKgRyhdNFiacC0rlOCbhNVz4H9FnAYgDBvU3QIioZlJFLJtsoHYRDfiZoUyIxqCtRpVlANq0EU4dApjrtgezPFad5S19Wgjkc0hNVnuF4HjVA6C7QrSIbylB+oZe3aHgBsqlNqKYH48jXyJKMuAbiyVJ8KzaB3eRc0pg9VwQ4niFryI68qiOi3AbjwdsfnAtk0bCjTLJKr6mrD9g8iq/S/B81hguOMlQTnVyG40wAcjnmgsCNESDrjme7wfftP4P7SP4N3CJZdvzoNyGq2c/HWOXJGsvVg+RA/k2MC/wN6I2YA2Pt8GkAAAAASUVORK5CYII=") !important;
  }
</style>