import BaseModelRequest from '../lib/BaseModelRequest'

export default class NoticeRequest extends BaseModelRequest {

  getModelName() {
    return 'notices'
  }

  getNotices(params) {
    let url = '/admin/api/notices';
    return this.get(url, params);
  }

  getNotice(id, params) {
    let url = '/admin/api/notices/' + id;
    return this.get(url, params);
  }

  createNotice(params) {
    let url = '/admin/api/notices';
    return this.post(url, params);
  }

  updateNotice(id, params) {
    let url = '/admin/api/notices/' + id;
    return this.put(url, params);
  }

  removeNotice(id, params) {
    let url = '/admin/api/notices/' + id;
    return this.del(url, params);
  }
}
