<template>
  <div class="userInformation boxCore clearfix mg_bot50" id="permissions">

    <div class="filter_container">
      <span class="title_item">
        <router-link class="btn btn_create_permisson" to="/permissions/edit">
            <span class="icon-plus"></span> Create Permission
        </router-link>
      </span>
      <div class="search_box form-group">
        <input type="text" placeholder="Search" v-on:keyup.enter="search" class="form-control search_input" name="searchKey" v-model="searchKey"/>
      </div>
    </div>

    <div class="row">
      <div class="col-xs-12">
         <div class="datatable">
          <data-table :getData="getData1" ref="datatable1" :limit="10" :column="5" class="scroll">
            <th class="text-left">{{ $t('Id') }}</th>
            <th class="text-left" data-sort-field="name">Name</th>
            <th class="text-left" data-sort-field="email">{{ $t('common.placeholders.email') }}</th>
            <th class="text-left" data-sort-field="email">{{ $t('admin.role') }}</th>
            <th class="text-right"></th>
            <template slot="body" slot-scope="props">
              <tr>
                <td class="text-left">{{ props.item.id }}</td>
                <td class="text-left"><span>{{ props.item.name }}</span></td>
                <td class="text-left"><span>{{ props.item.email }}</span></td>                
                <td class="text-left">
                  <span v-if="props.item.role == 1">Root</span>
                  <span v-else>Admin</span>
                </td>
                <td class="text-right">
                  <!-- <template v-if="!isSuperAdmin(props.item)"> -->
                  <template>
                    <button type="button" @click="deleteAdmin(props.item)" class="btn btn_close_network"><i class="icon-close2"></i></button>
                    <button type="button" @click="editAdmin(props.item)" class="btn btn_edit_network"><i class="icon-edit"></i></button>
                  </template>
                </td>
              </tr>
            </template>
          </data-table>
        </div>
      </div>
      <!-- <div class="col-xs-6">
        <div class="datatable table-role">
            <data-table :getData="getData2" ref="datatable2" :limit="10" :column="2" class="scroll">
              <th class="text-left">{{ $t('admin.role') }}</th>
              <th class="text-right"></th>
              <template slot="body" slot-scope="props">
                <tr>
                  
                  <td class="text-left">
                    <span v-if="props.item.role == 1">Root</span>
                    <span v-else>Admin</span>
                  </td>
                  <td class="text-right">
                    <button class="btn btn_success" @click="onClickedDisableDeposit(props.item.id)" v-if="(props.item.role == 1)">{{ $t('withdraw_setting.enable') }}</button>
                    <button class="btn btn_danger" @click="onClickedEnableDeposit(props.item.id)" v-if="(props.item.role == 2)">{{ $t('withdraw_setting.disable') }}</button>
                  </td>
                </tr>
              </template>
            </data-table>
          </div>
      </div> -->
    </div>
   
    <!-- <modal name="deleteAdmin" width="420" title="">
      <div slot="body" class="body-popup">
          {{ $t('email.delete_data') }}
      </div>
    </modal> -->

  </div>
</template>

<script>
  import rf from '../../lib/RequestFactory';
  import Const from '../../common/Const';

  export default {
    data() {
      return {
        titlePage: this.$t('Permission'),
        searchKey: '',
        params: null,
      }
    },

    watch: {
      params(newParams) {
        this.$refs.datatable2.refresh();
      }
    },

    methods: {
      refresh() {
        this.$refs.datatable1.refresh();
        this.$refs.datatable2.refresh();
      },

      search() {
        this.refresh();
      },

      getData1(params) {
        this.params = params;
        if (!window._.isEmpty(this.searchKey)) {
          params.search_key = this.searchKey;
        }
        return rf.getRequest('AdminRequest').getAdmins(params);
      },

      getData2(params) {
        params = this.params != null ? this.params : params;
        if (!window._.isEmpty(this.searchKey)) {
          params.search_key = this.searchKey;
        }
        return rf.getRequest('AdminRequest').getAdmins(params);
      },

      editAdmin(row) {
        this.$router.push({
          path: '/permissions/edit',
          query: {
              id: row ? row.id : null
          }
        });
      },

      deleteAdmin(item) {
        window.ConfirmationModal.show({
          type: 'confirm',
          title: this.$t('email.delete_data'),
          btnCancelLabel: window.i18n.t('common.action.cancel'),
          btnConfirmLabel: window.i18n.t('common.action.confirm'),
          onConfirm: () => {
            this.requestHandler(item);
          },
          onCancel: () => {}
        });
      },

      requestHandler(item) {
        rf.getRequest('AdminRequest').deleteAdministrator(item.id).then(res => {
            this.refresh();
        }).catch(error => {
          if (!error.response) {
            this.showError(window.i18n.t('common.message.network_error'));
            return;
          }
          this.convertRemoteErrors(error);
        });
      },

      isSuperAdmin(admin) {
        return admin.role === Const.ROLE_SUPER_ADMIN;
      }
    },

    mounted() {
      this.$emit('EVENT_PAGE_CHANGE', this);
    }
  }
</script>

<style lang="scss" scoped>
@import "@/assets/sass/variables";

  .btn_create_permisson {
    width: 200px;
    height: 30px;
    border-radius: 3px;
    background-color: $color-caribbean-green;
    border: 1px solid $color-caribbean-green;
    font-size: $font-smaller;
    color: $color-white;
    letter-spacing: 0.9px;
    text-align: center;
    text-transform: uppercase;
    padding: 5px;
    font-family: $font-family-roboto-bold;
    font-weight: 700;
    .icon-plus {
      font-size: 10px;
      margin-right: 3px;
      line-height: 20px;
    }
    &:hover, &:active  {
      background-color: $color-aquamarine;
      border-color: $color-aquamarine;
      color: $color-white;
    }
  }
  .filter_container {
    margin: 8px 0px 15px 0px;
    display: flex;
    justify-content: space-between;
      align-items: center;

    .title_item {
      color: $color_mine_shaft;
      font-size: $font_big_20;
      font-weight: 500;
      line-height: 28px;
    }
    .search_box {
      width: 215px;
      max-width: 100%;
      margin-bottom: 0px;
      .search_input {
        background-color: transparent;
        height: 28px;
        border: 1px solid $color_alto;
        padding: 4px 15px;
        line-height: 20px;
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: $font-small;
      }
    }
  }

  .btn_edit_network {
    background-color: transparent !important;
    font-size: $font_semi_big;
    color: $color-jungle-green;
    height: 27px;
    padding: 2px 0px;
    line-height: 23px;
    float: right;
    margin-left: 15px;
    &:active {
      box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0);
    }
    &:hover {
      color: $color-jungle-green;
    }
  }
  .btn_save_network {
    background-color: transparent !important;
    font-size: $font_semi_big;
    color: $color-jungle-green;
    height: 27px;
    padding: 2px 0px;
    line-height: 23px;
    float: right;
    margin-left: 15px;
    .icon-save:before {
      color: $color-jungle-green;
    }
    &:active {
      box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0);
    }
    &:hover {
      color: $color-jungle-green;
      .icon-save:before {
        color: $color-jungle-green;
      }
    }
  }
  .btn_close_network {
    background-color: transparent !important;
    font-size: $font_semi_big;
    height: 27px;
    padding: 2px 0px;
    line-height: 23px;
    float: right;
    margin-left: 15px;
    color: $color-jungle-green;
    .icon-save:before {
    color: $color-jungle-green;
    }
    &:active {
      box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0);
    }
    &:hover {
      color: $color-jungle-green;
      .icon-save:before {
        color: $color-jungle-green;
      }
    }
  }

  .btn_success,
  .btn_danger {
    min-width: 70px;
    text-align: center;
    line-height: 21px;
    padding: 0px 5px;
    border: 1px solid;
    background-color: transparent;
    border-radius: 3px;
    font-family: $font_family_root;
    height: 23px;
    font-size: $font-smaller;
    font-weight: 600;
    text-transform: uppercase;
    transition: 0.5s;
    // &:hover {
    //   background-color: $color_corn;
    //   border-color: $color_corn;
    //   color: $color_white;
    //   transition: 0.5s;
    // }
  }
  .btn_danger {
    border-color: $color-jungle-green;
    color: $color-jungle-green; 
  }
  .btn_success {
    border-color: $color-aquamarine;
    background-color: $color-aquamarine;
    color: $color-white;
  }

  .datatable {
    .form-control {
      background-color: transparent;
      height: 27px;
      border: 1px solid $color_alto;
      padding: 3px 13px;
      line-height: 20px;
      width: 160px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  .icon-edit , .icon-close2{
    font-size: $font_semi_big;
    &:hover{
      color: $color-eden;
    }
  }
</style>

<style lang="scss">
@import "@/assets/sass/variables";
  .table-role {
    .VuePagination {
      display: none;
      background-color: transparent;
    } 
  }
</style>

<style lang="scss">
@import "@/assets/sass/variables";
  #permissions {
    .sc_search_select {
      .group_search_select {
        .button_drop_search {
          width: 55px;
          height: 30px !important;
          padding: 5px 10px 5px 10px !important;
          border-radius: 3px;
          margin-right: 20px;
          font-size: $font-small;
          font-family: $font-family-roboto;
          i.icon-arrow1 {
            margin-left: 5px;
            color: $color_dove_gray;
          }
          &:hover {
            border-color: $color-jungle-green;
            i.icon-arrow1 {
              color: $color-jungle-green;
            }
          }
          
        }
        .box_list_search_select {
          .list_search_select {
            li {
              font-family: $font-family-roboto;
              font-size: $font-small;
              &:hover, &:active, &:focus {
                color: $color-jungle-green;
              }
            }
          }
        }
      }
    }
  }
</style>