<template>
  <div class="order-history clearfix mg_bot50" id="order-history">
    <div class="filter-container">
      <div class="filter-item date">
        <div class="text-pair">{{ $t('orders.order_history.date') }}:</div>
        <date-picker v-model="startDate" class="date-picker-input no-right-radius" :format="customFormatter"></date-picker>
        <date-picker v-model="endDate" class="date-picker-input no-left-radius" :format="customFormatter"></date-picker>
      </div>
      <div class="filter-item">
        <input title="" type="text" :placeholder="$t('order.order_history.search')" @keyup.enter="search"
               class="form-control" name="searchKey" v-model="filters.searchKey"/>
      </div>
      <div class="filter-item btn-action">
        <button class="btn btn-primary" @click="search()">{{ $t('orders.order_history.search') }}</button>
        <button class="btn btn-reset" @click="refresh()">{{ $t('orders.order_history.reset') }}</button>
      </div>
    </div>
    <div class="datatable" id="table_main_margin_position_history">
      <data-table-4 :getData="getData" ref="datatable" :msgEmptyData="$t('margin.table_position.empty_msg')" :column="11" class="scroll">
        <th data-sort-field="created_at" class="text-left">{{ $t('margin.orders.position.created_at') }}</th>
        <th data-sort-field="symbol" class="text-left">{{ $t('margin.orders.position.symbol') }}</th>
        <th data-sort-field="owner_email" class="text-left">{{ $t('margin.orders.position.owner_email') }}</th>
        <th data-sort-field="owner_email" class="text-left">{{ $t('margin.orders.position.manager_owner_email') }}</th>
        <th data-sort-field="current_qty" class="text-left">{{ $t('margin.orders.position.current_qty') }}</th>
        <th data-sort-field="entry_value" class="text-left">{{ $t('margin.orders.position.value') }}</th>
        <th data-sort-field="entry_price" class="text-left">{{ $t('margin.orders.position.entry_price') }}</th>
        <th data-sort-field="liquidation_price" class="text-left">{{ $t('margin.orders.position.liquidation_price') }}</th>
        <th data-sort-field="leverage" class="text-left">{{ $t('margin.orders.position.leverage') }}</th>
        <th data-sort-field="init_margin" class="text-left">{{ $t('margin.orders.position.margin') }}</th>
        <th data-sort-field="risk_limit" class="text-left">{{ $t('margin.orders.position.risk_limit') }}</th>
        <th data-sort-field="risk_value" class="text-left">{{ $t('margin.orders.position.risk_value') }}</th>
        <th class="text-left">{{ $t('margin.orders.position.mark_price') }}</th>
        <th data-sort-field="unrealised_pnl" class="text-left min-width-130"> <span class="fl-right">{{ $t('margin.orders.position.unrealised_pnl') }}</span></th>
        <template slot="body" slot-scope="props">
          <tr>
            <td class="text-left">{{props.item.created_at}}</td>
            <td class="text-left">{{props.item.symbol}}</td>
            <td class="text-left w_125">
              <div class="item_email_setting">
                  <span class="txt_email_setting">{{ props.item.owner_email }}</span>
                  <span class="tooltip_email_setting">{{ props.item.owner_email }}</span>
              </div>
            </td>
            <td class="text-left w_125">
              <div class="item_email_setting">
                  <span class="txt_email_setting">{{ props.item.manager_email }}</span>
                  <span class="tooltip_email_setting">{{ props.item.manager_email }}</span>
              </div>
            </td>
            <td class="text-left">{{props.item.current_qty | formatCurrencyAmount(null, '0')}}</td>
            <td class="text-left">{{props.item.entry_value | formatCurrencyAmount(null, '0') | formatMarginValue}}</td>
            <td class="text-left">{{props.item.entry_price | formatCurrencyAmount(null, '0')}}</td>
            <td class="text-left">{{props.item.liquidation_price | formatMarginNumber(6)}}</td>
            <td class="text-left">{{props.item.leverage | formatCurrencyAmount(null, '0')}}</td>
            <td class="text-left">{{props.item.init_margin | formatCurrencyAmount(null, '0')}}</td>
            <td class="text-left">{{props.item.risk_limit | formatCurrencyAmount(null, '0')}}</td>
            <td class="text-left">{{props.item.risk_value | formatCurrencyAmount(null, '0')}}</td>
            <td class="text-left">{{props.item.mark_price | formatCurrencyAmount(null, '0')}}</td>
            <td class="text-right">{{props.item.unrealised_pnl | formatCurrencyAmount(null, '0')}}</td>
          </tr>
        </template>
      </data-table-4>
    </div>
    
  </div>
</template>

<script>
  import _ from "lodash";
  import rf from '../../lib/RequestFactory';
  import Modal from '../../components/Modal';
  import DatePicker from 'vuejs-datepicker';
  import moment from 'moment';
  import MasterdataUtils from '@/desktop/common/MasterdataUtils';
  import COMMON_CONST from '../../common/Const';
  import SelectBox from '../../components/SelectBox';
  import DataTable4 from "../../components/DataTable4"

  export default {
    components: {
      Modal,
      DatePicker,
      SelectBox,
      DataTable4
    },
    data() {
      return {
        titlePage: window.i18n.t('margin.positions'),
        startDate: moment().subtract(1, 'week').toDate(),
        endDate: new Date(),
        window: window,
        coin: '',
        filters: {
          coin: '',
          currency: '',
          tradeType: '',
          searchKey: '',
        },
        idDetail : null,
        hideCanceled: false,
        coins: [],
        coinMasters: [],
        isShowCoinOption: false,
        trades: [],
        commonConst: COMMON_CONST,
        tradeType: [
          { id: "buy", name: window.i18n.t('orders.order_history.buy') },
          { id: "sell", name: window.i18n.t('orders.order_history.sell') }
        ],
        markets: [],
      }
    },
    methods: {
      search() {
        let timez = moment.tz.guess();
        let filterParams = {
          start_date: moment(this.startDate).startOf('day').format('x'),
          end_date: moment(this.endDate).endOf('day').format('x'),
          timezone: timez,
        };
        if (moment.unix(this.startDate / 1000).format("YYYY-MM-DD") > moment.unix(this.endDate / 1000).format("YYYY-MM-DD")) {
          this.$toastr('error', this.$t('common.date.warning'));
          return;
        }
        if (this.filters.searchKey) {
          filterParams.search_key = this.filters.searchKey;
        }
        if (this.hideCanceled) {
          filterParams.hide_canceled = 1;
        }
        if (this.filters.coin) {
          filterParams.coin = this.filters.coin;
        }
        if (this.filters.currency) {
          filterParams.currency = this.filters.currency.id;
        }
        if (this.filters.tradeType) {
          filterParams.trade_type = this.filters.tradeType.id;
        }
        this.$refs.datatable.filter(filterParams);
      },
      refresh() {
        this.requestCoinList();
        this.resetInput();
        this.$refs.datatable.refresh();
      },
      resetInput() {
        this.filters = Object.assign(this.filters, {coin: '', currency: '', tradeType: '', searchKey: ''});
        this.hideCanceled = false;
        this.startDate = moment().subtract(1, 'week').toDate();
        this.endDate = new Date();
        $(".datatable").find("th").attr("data-sort-order", "");
      },
      onSearchCoin() {
        this.coins = _.filter(this.coinMasters, (item) => {
          return _.isMatch(item.toUpperCase(), this.filters.coin.toUpperCase());
        });
      },
      onSelectCoin(coin) {
        this.isShowCoinOption = false;
        this.filters.coin = coin.toUpperCase();
      },
      clickOut() {
        this.isShowCoinOption = false;
      },
      showCoinOption() {
        this.isShowCoinOption = true;
        this.coins = this.coinMasters;
      },
      getData(params) {
        let timez = moment.tz.guess();
        let defaultParams = {
          start_date: moment(this.startDate).startOf('day').format('x'),
          end_date: moment(this.endDate).endOf('day').format('x'),
          timezone: timez,
        };

        return rf.getRequest('MarginOrderRequest').getPositions(Object.assign(defaultParams, params))
          .then(res => {
            if (res.data && res.data.data) {
              res.data.data = window._.filter(res.data.data, item => {
                if (item.current_qty != 0 && item.current_qty != undefined && item.current_qty != '' && item.current_qty != null) {
                  return item;
                }
              });
            }
            return res;
          });
      },
      /*
      getMarkets() {
        return window._.map(COMMON_CONST.MARKETS, item => {
          return { id: item, name: item.toUpperCase() };
        });
      },
      */
      requestCoinList() {
         rf.getRequest('CoinSettingRequest').getCoinList().then(res => {
          this.coinMasters = window._.map(res.data, 'coin')
        });
      },
      async viewDetail(id) {
        this.orderActive = id;
        this.idDetail = id;
        CommonModal.show('orderDetailModal', {
          position: 'center',
          mask: true,
          buttons: [
            {
              label: 'OK',
              focused: true,
              style: {
                width: '140px',
                background: 'rgb(0, 112, 192)'
              },
              callback: () => {
                CommonModal.hide('orderDetailModal');
              }
            }
          ]
        });
      },
      getTradeHistory(params) { 
        let orderId = this.idDetail;
        return rf.getRequest('OrderRequest').getTradeByOrder(orderId, params);
      },
      customFormatter(date) {
        return moment(date).format('YYYY - MM - DD');
      },
    },
    watch: {
      hideCanceled : function() {
        this.search();
      }
    },
    mounted() {
      this.$emit('EVENT_PAGE_CHANGE', this);
      this.requestCoinList();
    },
    created() {
      rf.getRequest('AdminRequest').getPriceGroupCurrency().then(res => {
        try {
          window._.map(res.data, item => {
            if (typeof item.currency !== 'undefined') {
              this.markets.push({ id: item.currency, name: item.currency.toUpperCase() });
            }
          });
        }
        catch (ex) {
          console.error(ex);
        }
      });
    }
  }
</script>

<style lang="scss">  
@import "@/assets/sass/variables";
  .fl-right {
    float: right;
  }
  .min-width-130 {
    min-width: 130px;
  }
  .date-picker-input {
    display: inline-block;
    input {
      padding: 0 5px 0px 23px;
      width: 110px !important;
      border-radius: 3px;
      -webkit-box-shadow: none;
      box-shadow: none;
      height: 30px;
      line-height: 30px;
      border: 1px solid #d2d6de;
      background-image: url('@/assets/images/icon/date-time.svg') !important;
      background-color: $color-white-smoke !important;
      background-size: 12px;
      background-position: center left 5px;
      background-repeat: no-repeat !important;
      color: $color-grey-dusty;
      &:hover, &:active, &:focus {
        border-color: $color-jungle-green;
        z-index: 9;
      }
    }
  }
  .no-left-radius {
    input {
      border-radius: 0px 3px 3px 0px;
      margin-left: -1px;
    }
  }
  .no-right-radius {
    input {
      border-radius: 3px 0px 0px 3px;
    }
  }
  #order-history {
    .sc_search_select {
      .group_search_select {
        .button_drop_search {
          height: 32px;
          padding: 6px 10px 6px 10px;
          border-radius: 10px;
          font-size: $font-small;
          font-family: $mulish-regular;
          span{
            font-size: $font-small;
          }
          i.icon-arrow1 {
            margin-left: 5px;
            color: $color_dove_gray;
          }
          &:hover, &:active, &:focus {
            border-color: $color-jungle-green;
            i.icon-arrow1 {
              color: $color-jungle-green;
            }
          }
        }
        .box_list_search_select {
          .list_search_select {
            li {
              font-family: $mulish-regular;
              &:hover, &:active, &:focus {
                color: $color-jungle-green;
              }
            }
          }
        }
      }
    }

    .modal-dialog{
      min-width: 80%;
      min-height: 400px;
      position: relative;
      top: -10%;
    }
    .modal-content{
      min-height: 400px;
      .modal-footer {
        text-align: center;
        margin-top: 30px;
      }
      .modal-header{
        padding: 30px 16px 16px 20px ;
        .modal-title{
          font-family: Roboto-Medium;
          font-size: 18px;
          font-weight: 500;
          color : $color-grey-dark;
          text-transform: none;
        }
      }
      .modal-body{
        padding : 0;
      }
    }
  }
  #table_main_margin_position_history {
    table {
      thead {
        tr {
          white-space: nowrap ;
          text-overflow: ellipsis;
          th {
            padding: 5px 3px 5px 15px;
            &:last-child {
              padding-right: 15px;
              padding-left: 2px;
            }
          }
        }
      }
      tbody {
        tr {
          white-space: nowrap;
          text-overflow: ellipsis;
          td {
            padding: 8px 2px 4px 15px;
            &:last-child {
              padding-right: 15px;
              padding-left: 2px;
            }
          }
        }
      }
    }
  }
</style>

<style lang="scss" scoped>  
@import "@/assets/sass/variables";
  .item_email_setting {
      display: inline-block;
      float: left;
      position: relative;
      .txt_email_setting {
        display: block;
        max-width: 150px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .tooltip_email_setting {
        position: absolute;
        top: 0px;
        left: 0px;
        line-height: 20px;
        padding: 5px 20px;
        left: 100%;
        background-color: $color_white;
        white-space: nowrap;
        width: auto;
        z-index: 10;
        font-size: $font_root;
        font-weight: 500;
        color: $color_mine_shaft;
        transition: 0.5s;
        display: none;
        box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.4);
        &:after {
          right: 100%;
          top: 50%;
          border: solid transparent;
          content: " ";
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
          border-color: rgba(136, 183, 213, 0);
          border-right-color: $color_white;
          border-width: 5px;
          margin-top: -5px;
        }
      }
      &:hover {
        .tooltip_email_setting {
          display: block;
          transition: 0.5s;
        }
      }
    }
  .min_85 {
    min-width: 85px;
  }
  .min_110 {
    min-width: 110px;
  }
  .w_125 {
    width: 125px;
  }
  .item_email_user {
    display: inline-block;
    float: left;
    position: relative;
    .txt_email_user {
      display: block;
      max-width: 65px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .tooltip_email_user {
      position: absolute;
      top: 0px;
      left: 0px;
      line-height: 20px;
      padding: 5px 20px;
      left: 100%;
      background-color: $color_white;
      white-space: nowrap;
      width: auto;
      z-index: 10;
      font-size: $font_root;
      font-weight: 500;
      color: $color_mine_shaft;
      transition: 0.5s;
      display: none;
      box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.4);
      &:after {
        right: 100%;
        top: 50%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: rgba(136, 183, 213, 0);
        border-right-color: $color_white;
        border-width: 5px;
        margin-top: -5px;
      }
    }
    &:hover {
      .tooltip_email_user {
        display: block;
        transition: 0.5s;
      }
    }
  }
  .text-left{
    text-align: left;
  }
  .order-history {
    .filter-container {
      .form_check_hiden {
        float: left;
        margin-top: 25px;
        margin-right: 5px;
        margin-left: 5px;
        .input_check_hiden {
          float: left;
          width: 15px;
          height: 15px;
          margin: 8px 10px 5px 0px;
          cursor: pointer;
        }
        .label_check_hiden {
          float: left;
          height: 30px;
          line-height: 20px;
          padding: 5px 0px;
          margin: 0px;
          cursor: pointer;
          color: $color-grey-dusty;
          font-size: $font_smaller;
          font-weight: normal;
          font-family: $font-family-roboto;
        }
      }
    }
  }
  .table_popup_order_history {
    min-width: 1100px;
    min-height: 315px;
  }
  .body-popup {
    max-height: 400px;
    overflow-y: auto;
    table {
      thead {
        border-bottom: 1pt solid $color-alto;
        th:last-child{
          text-align: right;
        }
        th {
          padding: 5px 0px 0px 15px !important;
          text-align: left;
          color: $color_grey;
          font-family: Roboto-Medium;
          font-size: 13px;
          font-weight: 500;
          
          &.text-c{
            text-align: center;
          }
        }
        th:last-child{
          padding: 5px 20px 0px 15px !important;
          span {
            float: right;
          }
        }
      }
      tbody {
        // overflow-y: auto;
        // height: 200px !important;
        tr {
          td:last-child{
            text-align: right;
            padding-right: 18px !important;
            
          }
          td:first-child{
            min-width: 82px;
          }
          td:nth-child(2){
            min-width: 170px;
          }
          td:nth-child(3){
            min-width: 250px;
            white-space: nowrap; 
            overflow: hidden;
            text-overflow: ellipsis;
          }
          td:nth-child(4){
            min-width: 120px;
          }
          td:nth-child(5){
            min-width: 70px;
          }
          td:nth-child(6){
            min-width: 90px;
          }
          td:nth-child(7){
            min-width: 100px;
          }
          td:nth-child(8){
            min-width: 120px;
          }
          td:nth-child(9){
            min-width: 100px;
          }
          td {
            text-align: left;
            padding-top: 5px;
            font-family: Roboto-Regular;
            font-size: 14px;
            color : $color-grey-dark;
            line-height: 2.86;
            &.f-td{
              text-align: center;
            }
            &.type-buy{
              color : $color-jungle-green;
            }
            &.type-sell{
              color : $color_red_text;
            }
          }
        }
      }
    }
  }
  .empty-data {
    text-align: center;
    padding-top: 15px;
    background-color: $color_white; 
  }
  .opacity-row {
    opacity: 0.4;
  }
  .text-wrap {
    word-wrap: break-word;
  }
  .toolbar-element2 {
    display: inline-block;
    float: left;
  }
  .bw-element {
    float: left;
    font-size: $font_medium;
    margin-left: 5px;
    height: 30px;
    line-height: 30px;
    margin-right: 5px;
    color: $color-grey-dusty;
  }
  .checkbox {
    margin-top: 0px;
    display: inline-block;
    font-size: $font_root;
    margin-bottom: 0px;
    font-weight: normal;
    height: 30px;
    line-height: 30px;
    input[type="checkbox"] {
      display: none;
    }
    span {
      position: relative;
      content: "";
      display: inline-block;
      border: 1px solid $color_alto;
      width: 16px;
      height: 16px;
      top: 3px;
      left: 8px;
      border-radius: 2px;
      margin-right: 8px;
      position: relative;
      background-color: $color_white;
    }
    input:checked {
      & + span{
        &:after{
          opacity: 0.9;
          content: '';
          position: absolute;
          width: 14px;
          height: 7px;
          background: transparent;
          top: 2px;
          left: 0px;
          border: 3px solid $color_blue_logo;
          border-top: none;
          border-right: none;
          transform: rotate(-45deg);
          -webkit-transform: rotate(-45deg);
          -moz-transform: rotate(-45deg);
          -o-transform: rotate(-45deg);
          -ms-transform: rotate(-45deg);
        }
      }
    }
  }

  .order-history {
    .filter-container {
      margin: 10px 0px 30px 0px;
      height: 55px;
      .filter-item {
        float: left;
        margin: 0 5px;
        input {
          margin-top: 23px;
          display: inline-block;
          width: 145px;
          font-family: $font-family-roboto;
          &:hover, &:active, &:focus {
            border-color: $color-jungle-green;
          }
        }
        select {
          width: 65px;
          display: inline-block;
          font-family: $font-family-roboto;
          font-size: $font_root;
          &:hover, &:active, &:focus {
            border-color: $color-jungle-green;
          }
        }
        button {
          width: auto;
          margin: 23px 2px 0px 2px;
          height: 30px;
          font-size: $font-smaller;
          font-family: $font-family-roboto-bold;
        }
        .btn-reset {
          background-color: $color-caribbean-green;
          border: 1px solid $color-caribbean-green;
          width: 80px;
          text-transform: uppercase;
          color: $color-white;
          font-weight: 500;
          &:hover {
            border-color: $color-aquamarine;
            background-color: $color-aquamarine;
          }
        }
        .btn-primary {
          padding: 0;
          min-width: 80px;
          &:hover {
            border-color: $color-aquamarine;
            background-color: $color-aquamarine;
          }
        }
        .coin-input {
          position: relative;
          display: inline-block;
          margin: 0 5px;
          margin-right: 0px;
          font-size: $font-small;
          font-family: $font-family-roboto;
          .coin-option {
            background-color: $color-white;
            max-height: 210px;
            display: block;
            overflow-y: auto;
            margin-top: 5px;            
            position: absolute;
            width: 79px;
            left: 5px;
            z-index: 10;
            padding: 12px 0px;
            box-shadow: 0px 3px 12px rgba(49, 49, 49, 0.51);
            &:after {
                bottom: 100%;
                left: 50%;
                border: solid transparent;
                content: " ";
                display: block;
                height: 0;
                width: 0;
                position: absolute;
                pointer-events: none;
                border-color: rgba(136, 183, 213, 0);
                border-bottom-color: $color_white;
                border-width: 5px;
                margin-left: -5px;
            }
            .option {
              display: block;
              width: 100%;
              line-height: 20px;
              cursor: pointer;
              padding: 3px 14px;
              font-size: 14px;
              overflow: hidden;
              &:hover {
                color: $color-jungle-green;
              }
            }
          }
        }
      }
      .pair {
        input {
          width: 65px;
        }
        .text-pair {
          padding-left:  10px;
        }
      }
      .text-pair {
        color: $color-grey-dusty;
        font-family: $font-family-roboto;
        font-size: $font-small;
        margin-bottom: 5px;
      }
      .side {
        margin: 0 10px;
      }
    }
    .form-control {
      background: $color-white-smoke;
      height: 30px;
      font-size: $font_small;
      padding-left: 11px;
      padding-right: 0;
    }
    .datatable{
      button.btnForm.bt-action{
        padding-left: 5px !important;
        padding-right: 5px !important;
        font-weight: 400 !important;
      }
    }
    .buy {
      color: $text-color-jade;
    }
    .sell {
      color: $text-color-red;
    }
    .btn-detail {
      background-color: $color-caribbean-green;
      padding: 2px 10px;
      font-size: $font-smaller;
      color: $color-white;
      border:1px solid $color-caribbean-green;
      border-radius: 3px;
      text-transform: uppercase;
      font-family: $font-family-roboto;
      &:hover{
        background-color: $color-aquamarine;
        border-color: $color-aquamarine;
      };
    }
  }
  #table_main_margin_position_history {
   
    table {
      thead {
        tr {
          white-space: nowrap ;
          text-overflow: ellipsis;
          th {
            padding: 5px 3px 5px 15px;
            &:last-child {
              padding-right: 15px;
              padding-left: 2px;
            }
          }
        }
      }
      tbody {
        tr {
          white-space: nowrap;
          text-overflow: ellipsis;
          td {
            padding: 8px 2px 4px 15px;
            &:last-child {
              padding-right: 15px;
              padding-left: 2px;
            }
          }
        }
      }
    }
  
  }
</style>
