<template>
    <div class="copy-container" style="position: relative; display: inline-block;">
        <!-- Copy button with an icon -->
        <button @click="copyText">
            <img src="@/assets/images/icon/copy_icon.svg" />
        </button>

        <!-- Popover -->
        <div v-if="showPopover" class="copy-popover">
            Copied!
        </div>
    </div>
</template>

<script>
export default {
    props: {
        textToCopy: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            showPopover: false, // To control the visibility of the popover
        };
    },
    methods: {
        copyText() {
            // Create a temporary textarea element
            const textarea = document.createElement('textarea');
            textarea.value = this.textToCopy;
            document.body.appendChild(textarea);

            // Select the text and copy it to the clipboard
            textarea.select();
            document.execCommand('copy');

            // Remove the temporary textarea
            document.body.removeChild(textarea);

            // Show the popover
            this.showPopover = true;

            // Hide the popover after 2 seconds
            setTimeout(() => {
                this.showPopover = false;
            }, 2000);
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/variables";

.copy-container {
    position: relative;
    
    button {
        color: white;
        border: none;
        padding: 8px;
        font-size: 16px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 6px;
    }

    button img {
        margin-right: 5px;
        width: 14px;
        height: 14px;
    }

    button:hover {
        background-color: $color-bright-gray;
    }
    
    button:focus {
        background-color: $color-white-smoke;
    }

    .copy-popover {
        position: absolute;
        transform: translateX(-50%);
        margin-top: 8px;
        padding: 8px;
        background-color: black;
        color: white;
        border-radius: 4px;
        font-size: 14px;
        white-space: nowrap;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
        z-index: 10;
        opacity: 0.9;
    }
}
</style>