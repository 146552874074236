export default {
  LIST_NETWORKS: [
    "Facebook",
    "Twitter",
    "Telegram",
    "Linkerdin",
    "Youtube",
    "WhatsApp",
    "Reddit",
    "Instagram",
    "Google Plus",
    "WeChat",
  ],
  ICON_CLASSES: [
    "icon-facebook",
    "icon-twitter",
    "icon-telegram",
    "icon-linkedin",
    "icon-youtube3",
    "icon-whatsapp3",
    "icon-reddit3",
    "icon-instagram3",
    "icon-google-plus",
    "icon-wechat",
  ],
  PRODUCT_BASE: "margin",
  MARKETS: ["mgc", "eth", "btc"],
  AIRDROP_LIST_COINS: {
    btc: "BTC",
    eth: "ETH",
    amal: "AMAL",
    all: "ALL",
  },
  MENU: [
    {
      type: "item",
      icon: "icon-chart2",
      name: window.i18n.t("menu.dashboard"),
      isHasPermission: false,
      router: {
        name: "dashboard",
      },
    },
    {
      type: "tree",
      icon: "icon-user",
      name: window.i18n.t("menu.account"),
      isHasPermission: false,
      items: [
        {
          type: "item",
          icon: "",
          name: window.i18n.t("menu.account.account_list"),
          router: {
            name: "account_list",
            subRoutes: "/account/detail",
          },
        },
        {
          type: "item",
          icon: "",
          name: window.i18n.t("menu.account.kyc"),
          router: {
            name: "account_kyc",
            subRoutes: "/account/kyc/detail",
          },
        },
      ],
    },
    {
      type: "tree",
      icon: "icon-money",
      name: window.i18n.t("menu.fund"),
      isHasPermission: false,
      items: [
        {
          type: "item",
          icon: "",
          name: window.i18n.t("menu.fund.withdrawals"),
          router: {
            name: "WithdrawalsFund",
          },
        },
        {
          type: "item",
          icon: "",
          name: window.i18n.t("menu.fund.deposit"),
          router: {
            name: "DepositFund",
          },
        },
      ],
    },
    {
      type: "tree",
      icon: "icon-order",
      name: window.i18n.t("menu.spot"),
      isHasPermission: false,
      items: [
        {
          type: "item",
          icon: "",
          name: window.i18n.t("menu.spot.market"),
          router: {
            name: "market_spot",
          },
        },
        {
          type: "item",
          icon: "",
          name: window.i18n.t("menu.spot.open_order"),
          router: {
            name: "open_order",
          },
        },
        {
          type: "item",
          icon: "",
          name: window.i18n.t("menu.spot.order_book"),
          router: {
            name: "order_book",
          },
        },
        {
          type: "item",
          icon: "",
          name: window.i18n.t("menu.spot.order_history"),
          router: {
            name: "order_history",
          },
        },
        {
          type: "item",
          icon: "",
          name: window.i18n.t("menu.spot.trade_history"),
          router: {
            name: "trade_history",
          },
        },
      ],
    },
    {
      type: "tree",
      icon: "icon-future-record",
      name: window.i18n.t("menu.futures"),
      isHasPermission: false,
      items: [
        {
          type: "item",
          icon: "",
          name: window.i18n.t("menu.futures.market"),
          router: {
            name: "MarketFutures",
          },
        },
        {
          type: "item",
          icon: "",
          name: window.i18n.t("menu.futures.open_order"),
          router: {
            name: "OpenOrderFutures",
          },
        },
        {
          type: "item",
          icon: "",
          name: window.i18n.t("menu.futures.order_book"),
          router: {
            name: "OrderBookFutures",
          },
        },
        {
          type: "item",
          icon: "",
          name: window.i18n.t("menu.futures.order_history"),
          router: {
            name: "OrderHistoryFutures",
          },
        },
        {
          type: "item",
          icon: "",
          name: window.i18n.t("menu.futures.trade_history"),
          router: {
            name: "TradeHistoryFutures",
          },
        },
        {
          type: "item",
          icon: "",
          name: window.i18n.t("menu.futures.open_positions"),
          router: {
            name: "OpenPositionFutures",
          },
        },
        {
          type: "item",
          icon: "",
          name: window.i18n.t("menu.futures.positions_history"),
          router: {
            name: "PositionHistoryFutures",
          },
        },
      ],
    },
    {
      type: "tree",
      icon: "icon-exchange-setting",
      name: "Resource",
      name: window.i18n.t('menu.resource'),
      isHasPermission: false,
      items: [
        {
          type: "item",
          icon: "",
          name: "Network",
          name: window.i18n.t('menu.resource.networks'),
          router: {
            name: "Networks",
          },
        },
        {
          type: "item",
          icon: "",
          name: "Coin",
          name: window.i18n.t('menu.resource.coins'),
          router: {
            name: "Coins",
          },
        },
        // {
        //   type: "item",
        //   icon: "",
        //   name: window.i18n.t('menu.resource.wallets'),
        //   router: {
        //     name: "Wallets",
        //   },
        // },
      ]
    },
    // {
    //   type: "tree",
    //   icon: "icon-exchange-setting",
    //   name: window.i18n.t('menu.administrator'),
    //   isHasPermission: false,
    //   items: [
    //     {
    //       type: "item",
    //       icon: "",
    //       name: "Admin Account",
    //       name: window.i18n.t('menu.administrator.admin_account'),
    //       router: {
    //         name: "AdminAccount",
    //       },
    //     },
    //     {
    //       type: "item",
    //       icon: "",
    //       name: "Roles",
    //       name: window.i18n.t('menu.administrator.role'),
    //       router: {
    //         name: "Roles",
    //       },
    //     },
    //   ]
    // },
    {
      type: "item",
      icon: "icon-setting",
      name: window.i18n.t("menu.site_setting"),
      isHasPermission: false,
      router: {
        name: "site_setting",
      },
    },
    // {
    //   type: "tree",
    //   icon: "icon-wallet1",
    //   name: window.i18n.t("menu.wallet"),
    //   isHasPermission: false,
    //   items: [
    //     {
    //       type: "item",
    //       icon: "",
    //       name: window.i18n.t("menu.wallet.register_erc20"),
    //       router: {
    //         name: "setting:register-erc20",
    //       },
    //     },
    //     {
    //       type: "item",
    //       icon: "",
    //       name: window.i18n.t("menu.wallet.cold_wallet"),
    //       router: {
    //         name: "ColdWalletSetting",
    //       },
    //     },
    //   ],
    // },
    // {
    //   type: "tree",
    //   icon: "icon-user2",
    //   name: window.i18n.t("menu.users"),
    //   isHasPermission: false,
    //   items: [
    //     {
    //       type: "item",
    //       icon: "",
    //       name: window.i18n.t("menu.users.user_information"),
    //       router: {
    //         name: "users",
    //       },
    //     },
    //     {
    //       type: "item",
    //       icon: "",
    //       name: window.i18n.t("menu.users.user_kyc"),
    //       router: {
    //         name: "kyc",
    //         subRoutes: "/kyc/detail",
    //       },
    //     },
    //     {
    //       type: "item",
    //       icon: "",
    //       name: window.i18n.t("menu.users.user_kycsumsub"),
    //       router: {
    //         name: "kyc-sumsub",
    //         subRoutes: "/kyc-sumsub/detail",
    //       },
    //     },
    //     // {
    //     //     type: 'item',
    //     //     icon: '',
    //     //     name: 'User AMAL Net Ranking',
    //     //     router: {
    //     //         name: 'amal_net'
    //     //     }
    //     // },
    //     {
    //       type: "item",
    //       icon: "",
    //       name: window.i18n.t("menu.users.user_setting"),
    //       router: {
    //         name: "user_setting",
    //       },
    //     },
    //     {
    //       type: "item",
    //       icon: "",
    //       name: window.i18n.t("menu.users.user_enable_fee_setting"),
    //       router: {
    //         name: "user_enable_fee_setting",
    //       },
    //     },
    //     {
    //       type: "item",
    //       icon: "",
    //       name: window.i18n.t("menu.users.device_management"),
    //       router: {
    //         name: "device_management",
    //       },
    //     },
    //     // {
    //     //     type: 'item',
    //     //     icon: '',
    //     //     name: 'Group Setting',
    //     //     router: {
    //     //         name: 'user_group_setting'
    //     //     }
    //     // },
    //     // {
    //     //     type: 'item',
    //     //     icon: '',
    //     //     name: 'Trading Volume Ranking',
    //     //     router: {
    //     //     name: 'tradingVolumeRanking'
    //     //     }
    //     // },
    //   ],
    // },
    {
      type: "tree",
      icon: "icon-referrals",
      name: window.i18n.t("menu.referral"),
      isHasPermission: false,
      items: [
        {
          type: "item",
          icon: "",
          name: window.i18n.t("menu.referral.referral_history"),
          router: {
            name: "ReferralHistory",
          },
        },
        {
          type: "item",
          icon: "",
          name: window.i18n.t("menu.referral.referral_setting"),
          router: {
            name: "ReferralSetting",
          },
        },
      ],
    },
    // {
    //     type: 'tree',
    //     icon: 'icon-chart2' ,
    //     isHasPermission: false,
    //     name: 'Sales Point',
    //     items: [
    //     {
    //         type: 'item',
    //         icon: '',
    //         name: 'AMAL Setting',
    //         router: {
    //             name: 'setting:sales-point'
    //         }
    //     },
    //     {
    //         type: 'item',
    //         icon: '',
    //         name: 'AMAL Buy History',
    //         router: {
    //             name: 'buy_history'
    //         }
    //     },
    //     ]
    // },
    {
      type: "tree",
      icon: "icon-exchange-setting",
      name: window.i18n.t("menu.exchange_setting"),
      isHasPermission: false,
      items: [
        {
          type: "item",
          icon: "",
          name: window.i18n.t("menu.exchange_setting.exchange_balances"),
          router: {
            name: "exchange_balance",
          },
        },
        // {
        //     type: 'item',
        //     icon: '',
        //     name: 'Exchange Balances Detail',
        //     router: {
        //         name: 'exchange_balance_detail'
        //     }
        // },
        // {
        //     type: 'item',
        //     icon: '',
        //     name: 'Exchange Profit Detail',
        //     router: {
        //         name: 'profit'
        //     }
        // },
        // {
        //     type: 'item',
        //     icon: '',
        //     name: 'Exchange Balances Detail',
        //     router: {
        //         name: 'exchange_balance_detail'
        //     }
        // },
        {
          type: "item",
          icon: "",
          name: window.i18n.t("menu.exchange_setting.transaction_setting"),
          router: {
            name: "withdraw_setting",
          },
        },
        {
          type: "item",
          icon: "",
          name: window.i18n.t("menu.exchange_setting.tradding_setting"),
          router: {
            name: "enable_trading_setting",
          },
        },
        {
          type: "item",
          icon: "",
          name: window.i18n.t("menu.exchange_setting.user_withdrawal_setting"),
          router: {
            name: "enable_withdrawal_setting",
          },
        },
        {
          type: "item",
          icon: "",
          name: window.i18n.t("menu.exchange_setting.bank_setting"),
          router: {
            name: "bank_setting",
          },
        },
        {
          type: "item",
          icon: "",
          name: window.i18n.t("menu.exchange_setting.fee"),
          router: {
            name: "trading_fee_setting",
          },
        },
      ],
    },
    // {
    //   type: 'tree',
    //   icon: 'icon-exchange-setting',
    //   name: 'Circuit Breaker',
    //   isHasPermission: false,
    //   items: [
    //     {
    //       type: 'item',
    //       icon: '',
    //       name: 'Circuit Breaker Setting',
    //       router: {
    //         name: 'circuit_breaker_setting'
    //       }
    //     },
    //   ]
    // },
    // {
    //   type: "tree",
    //   icon: "icon-transaction",
    //   name: window.i18n.t("menu.transaction_history"),
    //   isHasPermission: false,
    //   items: [
    //     {
    //       type: "item",
    //       icon: "",
    //       name: window.i18n.t("menu.transaction_history.deposit_usd"),
    //       router: {
    //         name: "Deposit USD",
    //       },
    //     },
    //     {
    //       type: "item",
    //       icon: "",
    //       name: window.i18n.t("menu.transaction_history.withdraw_usd"),
    //       router: {
    //         name: "Withdraw USD",
    //       },
    //     },
    //     {
    //       type: "item",
    //       icon: "",
    //       name: window.i18n.t("menu.transaction_history.deposit"),
    //       router: {
    //         name: "Deposit",
    //       },
    //     },
    //     {
    //       type: "item",
    //       icon: "",
    //       name: window.i18n.t("menu.transaction_history.withdraw"),
    //       router: {
    //         name: "Withdraw",
    //       },
    //     },
    //   ],
    // },
    {
      type: "tree",
      icon: "icon-megaphone",
      name: window.i18n.t("menu.marketing"),
      isHasPermission: false,
      items: [
        {
          type: "item",
          icon: "",
          name: window.i18n.t("menu.marketing.notices"),
          router: {
            name: "Notice",
            subRoutes: "notice-edit",
          },
        },
        {
          type: "item",
          icon: "",
          name: window.i18n.t("menu.marketing.email_marketing"),
          router: {
            name: "Emails Marketing",
            subRoutes: "emailMarketingEdit,emailMarketingSend",
          },
        },
      ],
    },
    //////////////////////////////////////////////////////
    // {
    //   type: 'tree',
    //   icon: 'icon-airdrop',
    //   name: 'Dividend',
    //   isHasPermission: false,
    //   items: [
    //     {
    //         type: 'item',
    //         icon: '',
    //         name: 'User Unlock Status',
    //         router: {
    //             name: 'AirdropHistory'
    //         }
    //     },
    //     {
    //       type: 'item',
    //       icon: '',
    //       name: 'Dividend Payment History',
    //       router: {
    //         name: 'AirdropPaymentHistory'
    //       }
    //     },
    //     {
    //       type: 'item',
    //       icon: '',
    //       name: 'Dividend Setting',
    //       router: {
    //         name: 'AirdropSetting'
    //       }
    //     },
    //     {
    //         type: 'item',
    //         icon: '',
    //         name: 'Cashback History',
    //         router: {
    //           name: 'CashbackHistory'
    //         }
    //     },
    //     {
    //       type: 'item',
    //       icon: '',
    //       name: 'Manual Payment Dividend',
    //       router: {
    //           name: 'DividendSettingManual'
    //       }
    //     },
    //     {
    //       type: 'item',
    //       icon: '',
    //       name: 'Manual Paid History',
    //       router: {
    //           name: 'ManualDividendHistory'
    //       }
    //     },
    //     {
    //       type: 'item',
    //       icon: '',
    //       name: 'Auto Dividend Setting',
    //       router: {
    //         name: 'AutoDividendSetting'
    //       }
    //     },
    //     {
    //       type: 'item',
    //       icon: '',
    //       name: 'Auto Dividend Paid History',
    //       router: {
    //           name: 'AutoDividendHistory'
    //       }
    //     },
    //   ]
    // },
    // {
    //     type: 'tree',
    //     icon: 'icon-order',
    //     name: 'Margin Orders',
    //     isHasPermission: false,
    //     items: [
    //         {
    //             type: 'item',
    //             icon: '',
    //             name: 'Order',
    //             router: {
    //                 name: 'OrderMargin'
    //             }
    //         },
    //         {
    //             type: 'item',
    //             icon: '',
    //             name: 'Positions',
    //             router: {
    //                 name: 'PositionsMargin'
    //             }
    //         },
    //         {
    //             type: 'item',
    //             icon: '',
    //             name: window.i18n.t('menu.orders.trade_history'),
    //             router: {
    //                 name: 'TradeHistoryMargin'
    //             }
    //         },
    //         {
    //             type: 'item',
    //             icon: '',
    //             name: window.i18n.t('trade.funding_history'),
    //             router: {
    //                 name: 'FundingHistory'
    //             }
    //         },
    //         {
    //             type: 'item',
    //             icon: '',
    //             name: 'Trading Setting',
    //             router: {
    //                 name: 'MarginTradingSetting'
    //             }
    //         },
    //         {
    //             type: 'item',
    //             icon: '',
    //             name: 'Risk Management',
    //             router: {
    //                 name: 'RiskManagement'
    //             }
    //         },
    //         {
    //             type: 'item',
    //             icon: '',
    //             name: 'Entry Management',
    //             router: {
    //                 name: 'EntryManagement'
    //             }
    //         },
    //         {
    //             type: 'item',
    //             icon: '',
    //             name: 'Entry Payment Manual',
    //             router: {
    //                 name: 'EntryLeaderboardPaymentManual'
    //             }
    //         },
    //         {
    //             type: 'item',
    //             icon: '',
    //             name: 'Entry Manual Paid History',
    //             router: {
    //                 name: 'ManualEntryLeaderboardPaidHistory'
    //             }
    //         },
    //     ]
    // },
    ///////////////////////////////////////////////////////////////////
    // {
    //     type: 'tree',
    //     icon: 'icon-exchange-setting',
    //     name: 'Pass Order to Bitmex',
    //     isHasPermission: false,
    //     items:[
    //     {
    //         type: 'item',
    //         icon: '',
    //         name: 'Mapping Instrument',
    //         router: {
    //         name: 'BitmexMappingInstrument'
    //         }
    //     },
    //     {
    //         type: 'item',
    //         icon: '',
    //         name: 'Traced Account',
    //         router: {
    //         name: 'TracedAccount'
    //         }
    //     },
    //     {
    //         type: 'item',
    //         icon: '',
    //         name: 'Bitmex Account',
    //         router: {
    //         name: 'BitmexAccount'
    //         }
    //     },
    //     {
    //         type: 'item',
    //         icon: '',
    //         name: 'Mapping Order',
    //         router: {
    //         name: 'MappingOrder'
    //         }
    //     }
    //     ],
    // },
    // {
    //     type: 'item',
    //     icon: 'icon-setting',
    //     name: 'Instruments',
    //     isHasPermission: false,
    //     router: {
    //         name: 'Instruments'
    //     },
    // }
    // {
    //     type: 'item',
    //     icon: 'icon-setting',
    //     name: 'Instruments Futures',
    //     isHasPermission: false,
    //     router: {
    //         name: 'Instruments Futures'
    //     },
    // },
    {
      type: "item",
      icon: "icon-diamond",
      name: window.i18n.t("menu.reward_center"),
      isHasPermission: false,
      router: {
        name: "Reward Center",
      },
    },
    {
      type: "tree",
      icon: "icon-menu",
      name: "CMS",
      isHasPermission: false,
      items: [
    //     {
    //       type: "item",
    //       name: window.i18n.t('menu.cms.notification'),
    //       router: {
    //         name: "NotificationList",
    //         subRoutes: "NotificationDetail,NotificationEdit,NotificationCreate"
    //       },
    //     },
    //     {
    //       type: "item",
    //       name: window.i18n.t('menu.cms.mail_template'),
    //       router: {
    //         name: "MailDetail",
    //         subRoutes: "MailDetail,MailEdit,MailCreate"
    //       },
    //     },
    //     {
    //       type: "item",
    //       name: window.i18n.t('menu.cms.announcement'),
    //       router: {
    //         name: "AnnouncementList",
    //         subRoutes: "AnnouncementDetail,AnnouncementEdit,AnnouncementCreate"
    //       },
    //     },
    //     {
    //       type: "item",
    //       name: window.i18n.t('menu.cms.faq'),
    //       router: {
    //         name: "FaqList",
    //         subRoutes: "FaqDetail,FaqEdit,FaqCreate"
    //       },
    //     },
        {
          type: "item",
          name: window.i18n.t('menu.cms.qna'),
          router: {
            name: "QnaList",
            subRoutes: "QnaDetail,QnaEdit,QnaCreate"
          },
        },
      ]
    },
    {
      type: "item",
      icon: "icon-link",
      name: window.i18n.t('menu.partner_admin'),
      isHasPermission: false,
      link: process.env.VUE_APP_PARTNER_ADMIN_URL + `/auth?token=${localStorage.getItem("token")}`,
      // params: () => {
      //   const token = localStorage.getItem("token");
      //   return `/auth?token=${token}`;
      // },
    },
    // {
    //   type: "item",
    //   icon: "icon-exchange-setting",
    //   name: "Hot Search",
    //   isHasPermission: false,
    //   router: {
    //     name: "Hot Search",
    //   },
    // },
  ],
  ROLE_SUPER_ADMIN: "Super Admin",
  PRODUCT_BASE: "margin",
  USER_INFORMATION_TYPE: ["all", "normal", "bot"],
  USER_INFORMATION_STATUS: ["all", "inactive", "active"],
  IS_TESTER_STATUS: ["inactive", "active", "waiting"],
  MAX_LENGTH_INPUT: 190,
  LIST_COIN: ["ada", "bch", "btc", "eos", "eth", "ltc", "xrp"],
  USER_LEVEL: ["all", "1", "2", "3", "4", "5"],
  USER_KYC_STATUS: ["all", "Pending", "Verified", "Rejected"],
  USER_FA: ["all", "On", "Off"],
  USER_ACTIVE_STATUS: ["all", "Active", "Inactive"],

  ACTIVE: "active",
  CURRENCY_BTC: "btc",
  CURRENCY_ETH: "eth",
  CURRENCY_AMAL: "amal",
  CURRENCY_USDT: "usdt",
  CURRENCY_TOKEN: "erc20",

  MAIN_BALANCE: "Main Balance",
  DIVIDEND_NORMAL: "Dividend Balance",
  DIVIDEND_BY_ADMIN: "Dividend Perpetual Balance",
  FUNDING: "funding",
  TRADING: "trading",
  TYPE_EXCHANGE_BALANCE: "spot",
  TYPE_MARGIN_BALANCE: "margin",
  TYPE_MAIN_BALANCE: "main",
  TYPE_MAM_BALANCE: "mam",
  TYPE_AIRDROP_BALANCE: "airdrop",
  TYPE_DIVIDEND_BONUS_BALANCE: "dividend_bonus",
  BONUS_BALANCE_TRANSFER_STATUS: { 1: "pending", 2: "success", 3: "fail" },
  ORDER_STATUS: {
    ALL: "",
    PENDING: window.i18n.t("common.order_status.pending"),
    OPEN: window.i18n.t("common.order_status.open"),
    PARTIAL_FILLED: window.i18n.t("common.order_status.partial_filled"),
    FILLED: window.i18n.t("common.order_status.filled"),
    STOPPING: window.i18n.t("common.order_status.stopping"),
    CANCEL: window.i18n.t("common.order_status.canceled"),
  },
};
