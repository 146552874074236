<template>
    <div class="coins_page boxCore" id="coins_page">
        <div class="d-flex w-100">
            <div class="filter-template">
                <div class="label-text">&nbsp;</div>
                <button class="btn btn-reset" @click="refresh()">{{ $t('orders.open_order.reset') }}</button>
            </div>
            <div class="filter-template">
                <div class="label-text">&nbsp;</div>
                <router-link class="btn btn-reset" to="/coin/create">
                    <span class="icon-plus"></span>
                    {{ $t('resource.networks.btn_new_coin') }}</router-link>
            </div>
        </div>

        <div class="table-futures">
            <data-table-4 :getData="getData" ref="datatable" :column="5" :limit="10" :inLine="true" class="scroll"
                :total-user="totalRecords">
                <th class="text-left">{{ $t('resource.coins.id') }}</th>
                <th class="text-left">{{ $t('resource.coins.info') }}</th>
                <!-- <th class="text-center">{{ $t('resource.coins.work_on') }}</th> -->
                <th class="text-center">{{ $t('resource.coins.status') }}</th>
                <th class="text-left">{{ $t('resource.coins.updated_time') }}</th>
                <th class="text-center">{{ $t('resource.coins.action') }}</th>

                <template slot="body" slot-scope="props">
                    <tr>
                        <td class="text-left">
                            {{ props.item.id }}
                        </td>
                        <td class="text-left">
                            <div class="text-top">{{ props.item?.coin | uppercase }} </div>
                            <div class="text-bottom text-second">{{ props.item?.name | upperFirst }}
                            </div>
                        </td>
                        <!-- <td class="text-center">
                            <span class="status">
                                <div class="bt" v-for="item in props.item?.wallets || ['Spot', 'Future']">{{ item }}
                                </div>
                            </span>
                        </td> -->
                        <td class="text-center">
                            <span class="status">
                                <div class="switch-container">
                                    <label class="switch" @click="onChangeStatus(props.item)">
                                        <input type="checkbox" :checked="props.item?.status === 1"
                                            :disabled="true">
                                        <span class="slider round"></span>
                                    </label>
                                </div>
                            </span>
                        </td>
                        <td class="text-left">
                            <div class="text-top">{{ new Date(props.item.updated_at) | formatTimeStamp('YYYY-MM-DD') }} </div>
                            <div class="text-bottom">{{ new Date(props.item.updated_at) | formatTimeStamp('HH:mm:ss') }}</div>
                        </td>
                        <td class="text-left">
                            <span class="action">
                                <img @click="gotoDetail(props.item.id)" class="imge"
                                    src="@/assets/images/icon/edit-icon.svg" width="24px" height="24px" />
                            </span>
                        </td>
                    </tr>
                </template>
            </data-table-4>
        </div>

        <div class="clearfix clearfix-40"></div>

        <modal class="confirm_modal" name="confirm_modal" :title="$t('account.modal.confirm')">
            <div v-if="dataDetail?.status === 0" slot="body" class="body-popup">
                {{ $t('resource.coins.popup_enable_content') }}
            </div>
            <div v-else slot="body" class="body-popup">
                {{ $t('resource.coins.popup_disable_content') }}
            </div>
        </modal>

        <modal class="confirm_modal" :enableClose="enableClose" name="loadingUpload">
            <div slot="body">
                <vue-loading type="bars" v-if="isLoading" color="#2DAC91" :size="{ width: '60px', height: '80px' }">
                </vue-loading>
            </div>
        </modal>
    </div>
</template>

<script>

import rf from "../../lib/RequestFactory";
import Modal from "../../components/Modal";
import DataTable4 from "../../components/DataTable4.vue";

export default {
    components: {
        Modal,
        DataTable4,
    },
    data() {
        return {
            titlePage: window.i18n.t('menu.resource.coins'),
            isLoading: false,
            enableClose: true,
            dataDetail: null,
            totalRecords: null,
            pagination: null
        }
    },
    watch: {
    },
    methods: {
        search(filterParams) {
            this.$refs.datatable.filter(filterParams);
        },
        listenForNotification() {
            window.Echo.channel('App.Models.Admin')
                .listen('AdminNotificationUpdated', () => {
                    this.$refs.datatable.refresh();
                });
        },
        getData(params) {
            const records = rf.getRequest('MultiChainRequest').getCoins(params);
            records.then(data => {
                this.totalRecords = data.data.total;
                this.pagination = {
                    page: params?.page,
                    limit: params?.limit
                }
            })
            return records;
        },
        gotoDetail(id) {
            this.$router.push({
                name: 'CoinEdit',
                query: { id: id  },
                params: { pagination: this.pagination }
            });
        },
        onChangeStatus(value) {
            const params = {
                id: value.id,
                status: value.status === 1 ? 0 : 1
            }
            this.dataDetail = value;
            this.showModalConfirm('confirm_modal', this.changeStatus, params);
        },
        changeStatus(params) {
            rf.getRequest('MultiChainRequest').updateCoin(params).then(res => {
                if (res?.success) {
                    this.$toastr('success', this.$t('resource.coins.toast_update_success'));
                    this.search(this.pagination);
                } else {
                    this.$toastr('error', this.$t('resource.coins.toast_update_success'));
                }
            });
            this.dataDetail = null;
        },
        showModalConfirm(modalName, callback, params) {
            CommonModal.show(modalName, {
                position: 'center',
                mask: true,
                buttons: [
                    {
                        label: window.i18n.t('common.action.no'),
                        class: "btn-no",
                        style: {
                            background: 'rgb(191, 191, 191);'
                        },
                        callback: () => {
                            CommonModal.hide(modalName);
                        }
                    },
                    {
                        label: window.i18n.t('common.action.yes'),
                        focused: true,
                        style: {
                            background: 'rgb(0, 112, 192)'
                        },
                        callback: () => {
                            CommonModal.hide(modalName);
                            this.loadingUploadBegin();
                            callback(params);
                            this.loadingUploadFinish();
                        }
                    }
                ]
            });
        },
        loadingUploadBegin() {
            this.enableClose = false;
            this.isLoading = true;
            CommonModal.show('loadingUpload');
        },
        loadingUploadFinish() {
            CommonModal.hide('loadingUpload');
            this.isLoading = false;
            this.enableClose = true;
        },
    },
    created() {
    },
    mounted() {
        this.$emit('EVENT_PAGE_CHANGE', this);
    },
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/variables";

.d-flex {
    display: flex;
    justify-content: end;
}

#coins_page {
    table {
        thead {
            th {
                padding-left: 8px !important;
                padding-right: 8px;

                &:first-child {
                    width: 10%;
                    min-width: 70px;
                }

                &:nth-child(2) {
                    width: 20%;
                    min-width: 100px;
                }

                &:nth-child(3) {
                    width: 15%;
                    min-width: 100px;
                }

                &:nth-child(4) {
                    width: 20%;
                    min-width: 110px;
                }

                &:nth-child(5) {
                    width: 15%;
                    min-width: 130px;
                }

                &:nth-child(6) {
                    width: 15%;
                    min-width: 130px;
                    max-width: 130px;
                }

                &::after {
                    display: none;
                }
            }
        }
    }
}

td {
    word-break: break-all;
    vertical-align: middle !important;
    border-bottom: solid 1px $color-white-smoke;
    padding: 8px !important;

    .bt {
        padding: 4px 16px;
        border-radius: 4px;
        color: $color_black_logo;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: default;
        background-color: $color_alto;
    }

    .status {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
    }

    .action {
        display: flex;
        gap: 8px;
        align-items: center;
        justify-content: center;

        img {
            cursor: pointer;
        }

        .btc {
            width: 72px;
            height: 28px;
            display: flex;
            align-items: center;
            justify-content: center;

            &.cancel {
                color: #FF8C8A;
                border: 1px solid #FF8C8A;
                border-radius: 1px;
            }

            &.approve {
                color: #53D768;
                border: 1px solid #53D768;
                border-radius: 1px;
            }
        }
    }

    .text-second {
        color: $color_grey;
    }
}

.datatable {
    font-family: $font-family-roboto !important;
}

ul {
    list-style-type: none;
    padding: 0px;
}

.clearfix-40 {
    display: block;
    clear: both;
    height: 40px;
}

.coins_page {
    font-family: $font_family_root;
}

.form-control {
    background: $color_concrete;
    height: 30px;
    font-size: $font-small;
    padding-left: 11px;
    padding-right: 0;

    &:hover,
    &:focus {
        border-color: $color-jungle-green;
    }
}

.datatable {
    button.btnForm.bt-action {
        padding-left: 5px !important;
        padding-right: 5px !important;
        font-weight: 400 !important;
    }
}

.buy {
    color: $text-color-jade;
}

.sell {
    color: $text-color-red;
}

.toolbar-element {
    display: inline-block;
    float: left;
    width: 120px;
}

td {
    font-family: $font-family-roboto;
}

.table-futures {
    margin-top: 24px;
}

.filter-template {
    margin-right: 15px;

    .btn-reset {
        background: $color-caribbean-green;
        border-radius: 10px;
        color: $color_white;
        font-weight: 700;
        height: 32px;

        .icon-plus {
            color: $color-white;
            margin-right: 8px;
        }
    }

    .label-text {
        color: $dark-1;
        font-weight: 500;
        font-size: 14px;
        margin-bottom: 7px;
        margin-left: 5px;
        font-family: $mulish-regular;
        color: $color-grey-dusty;
    }

    .btn-reset {
        background: $color-caribbean-green;
        border-radius: 10px;
        color: $color_white;
        font-weight: 700;
        height: 32px;
    }

    .btn-search {
        margin: 0 10px;
    }

    .search_symbol {
        background: $color-white;
        border: 1px solid #C7CBD3 !important;
        border-radius: 10px !important;
        width: 197px;
        height: 32px;
    }
}
</style>

<style lang="scss">
@import "@/assets/sass/variables";

#coins_page {
    .modal {
        .body-popup {
            font-family: $mulish-medium;

            p,
            strong {
                font-family: $mulish-medium;
            }
        }

        .btn-no {
            background-color: $color-bright-gray;
            border-color: $color-bright-gray;
            color: $color-black;

            &:hover {
                opacity: 0.7;
            }
        }
    }
}
</style>