<template>
  <div class="userInformation boxCore clearfix mg_bot50">
    <div class="row row-0">

      <div class="col-xs-12 col-lg-6">
        <div class="head_userInformation clearfix">
          <div class="row">
            <div class="col-xs-6 head_userInformation_0">
              <div class="text-left">
                <div class="form-group">
                  <label><div class="text-deposit-withdraw">{{$t('withdraw_setting.withdraw')}}</div></label>
                  <div class="button-deposit-withdraw">
                    <button @click="onClickedEnableAllWithdraw()" class="btn btn_enable_all mgr_15" :disabled="!enableWithdraw">{{ $t('withdraw_setting.enable_all') }}</button>
                    <button @click="onClickedDisableAllWithdraw()" class="btn btn_disable_all button-deposit-withdraw-1" :disabled="!disableWithdraw">{{ $t('withdraw_setting.disable_all') }}</button>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xs-6 head_userInformation_1">
              <div class="text-right">
                <div class="form-group">
                  <label class="deposit-padding"><div class="text-deposit-withdraw">{{$t('withdraw_setting.deposit')}}</div></label>
                  <div class="button-deposit-withdraw">
                    <button @click="onClickedeEnableAllDeposit()" class="btn btn_enable_all button-deposit-withdraw-2" :disabled="!enableDeposit">{{ $t('withdraw_setting.enable_all') }}</button>
                    <button @click="onClickedDisableAllDeposit()" class="btn btn_disable_all mgl_15" :disabled="!disableDeposit">{{ $t('withdraw_setting.disable_all') }}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="clearfix"></div>

        <div class="datatable">
          <data-table :getData="getData" ref="datatable" :limit="10" :column="4" class="scroll">
            <th class="text-left header-0">{{ $t('withdraw_setting.id') }}</th>
            <th data-sort-field="coin" class="sort text-left header-1">{{ $t('withdraw_setting.name') }}</th>
            <th class="text-left header-2">{{$t('withdraw_setting.withdraw')}}</th>
            <th class="header-3">{{$t('withdraw_setting.deposit')}}</th>
            <template slot="body" slot-scope="props">
              <tr>
                <td class="text-left">{{ props.item.id }}</td>
                <td class="withdraw-coin text-left body-1">{{ props.item.coin }}</td>
                <td class="text-left body-2">
                  <button
                    @click="onClickedChangeStatusWithdraw(props.item.id, props.item.is_withdraw)"
                    :class="{ btn_enable: props.item.is_withdraw, btn_disable: !props.item.is_withdraw }"
                    class="btn">
                    {{ $t('withdraw_setting.' + `${ props.item.is_withdraw ? 'enable' : 'disable'}`) }}
                  </button>
                </td>
                <td class="body-3">
                  <button
                    @click="onClickedChangeStatusDeposit(props.item.id, props.item.is_deposit)"
                    :class="{ btn_enable: props.item.is_deposit, btn_disable: !props.item.is_deposit }"
                    class="btn">
                    {{ $t('withdraw_setting.' + `${ props.item.is_deposit ? 'enable' : 'disable'}`) }}
                  </button>
                </td>
              </tr>
            </template>
          </data-table>
        </div>
      </div>

    </div>

    <v-dialog/>

  </div>
</template>

<script>
    import rf from '../../lib/RequestFactory';
    import InputOnlyNumber from '../../common/InputOnlyNumber';
    import Modal from '../../components/Modal';
    import ConfirmationModal from '../../common/ConfirmationModal';
    export default {
        name: "withdraw-setting",
        components: {
        InputOnlyNumber,
        ConfirmationModal,
        Modal,
        selectedTransaction: {}
      },
      data() {
        return {
          enableDeposit : false,
          enableWithdraw : false,
          disableDeposit : false,
          disableWithdraw: false,
          titlePage: this.$t('withdraw_setting.withdraw_setting'),
        }
      },
      methods: {
        showModal (config) {
          const type = config.type;
          const title = config.title;
          const content = config.content;
          const customContent = config.customContent;
          let btnCancelLabel = config.btnCancelLabel || this.$t('common.action.no');
          let btnConfirmLabel = config.btnConfirmLabel || this.$t('common.action.yes');
          if (config.noAction) {
            btnCancelLabel = null;
            btnConfirmLabel = null;
          }
          const onConfirm = config.onConfirm;
          const onCancel = config.onCancel;
          window.ConfirmationModal.show({
            type: type,
            title: title,
            content: content,
            customContent: customContent,
            btnCancelLabel: btnCancelLabel,
            btnConfirmLabel: btnConfirmLabel,
            onConfirm: onConfirm,
            onCancel: onCancel
          });
        },
        getData(params) {
          return rf.getRequest('CoinSettingRequest').getCoinList(params);
        },
        getlistWithdraw(){
          let params = {"page":1,"limit":10};
          this.enableDeposit = false;
          this.enableWithdraw = false;
          this.disableDeposit = false;
          this.disableWithdraw = false;
          rf.getRequest('CoinSettingRequest').getCoinList(params).then(res=>{
            let dataCoins = res.data;
            let _self = this;
            if(dataCoins){
              dataCoins.forEach(function(data){
                if(data.is_deposit === 0){
                  _self.enableDeposit = true
                }else{
                  _self.disableDeposit = true;
                }
                if(data.is_withdraw === 0){
                  _self.enableWithdraw = true
                }else{
                  _self.disableWithdraw = true;
                }
              })
            }
          });
        },
        onClickedChangeStatusWithdraw(id, isWithdraw) {
          this.showModal({
            type: 'confirm',
            title: isWithdraw ? this.$t('withdraw_setting.disable_confirm') : this.$t('withdraw_setting.enable_confirm'),
            onConfirm: () => {
              rf.getRequest('CoinSettingRequest').updateSetting(id, {is_withdraw: !isWithdraw}).then(res => {
                this.getlistWithdraw();
                this.$refs.datatable.refresh()
              });
            }
          });
        },
        onClickedDisableAllWithdraw () {
          this.showModal({
            type: 'confirm',
            title: this.$t('withdraw_setting.disable_all_confirm'),
            onConfirm: () => {
              
              rf.getRequest('CoinSettingRequest').updateSettingAll({is_withdraw: 0}).then(res => {
                this.getlistWithdraw();
                this.$refs.datatable.refresh()
              });
            }
          });
        },
        onClickedEnableAllWithdraw () {
          this.showModal({
            type: 'confirm',
            title: this.$t('withdraw_setting.enable_all_confirm'),
            onConfirm: () => {
              rf.getRequest('CoinSettingRequest').updateSettingAll({is_withdraw: 1}).then(res => {
                this.getlistWithdraw();
                this.$refs.datatable.refresh()
              });
            }
          });
        },
        onClickedChangeStatusDeposit(id, isDeposit) {
          this.showModal({
            type: 'confirm',
            title: isDeposit ? this.$t('withdraw_setting.disable_deposit_confirm') : this.$t('withdraw_setting.enable_deposit_confirm'),
            onConfirm: () => {
              rf.getRequest('CoinSettingRequest').updateSetting(id, {is_deposit: !isDeposit}).then(res => {
                this.getlistWithdraw();
                this.$refs.datatable.refresh()
              });
            }
          });
        },
        onClickedDisableAllDeposit () {
          this.showModal({
            type: 'confirm',
            title: this.$t('withdraw_setting.disable_all_deposit_confirm'),
            onConfirm: () => {
              rf.getRequest('CoinSettingRequest').updateSettingAll({is_deposit: 0}).then(res => {
                this.getlistWithdraw();
                this.$refs.datatable.refresh()
              });
            }
          });
        },
        onClickedeEnableAllDeposit () {
          this.showModal({
            type: 'confirm',
            title: this.$t('withdraw_setting.enable_all_deposit_confirm'),
            onConfirm: () => {
              rf.getRequest('CoinSettingRequest').updateSettingAll({is_deposit: 1}).then(res => {
                this.getlistWithdraw();
                this.$refs.datatable.refresh()
              });
            }
          });
        },
      },
      mounted() {
          this.$emit('EVENT_PAGE_CHANGE', this);
          this.getlistWithdraw();
      }
    }
</script>

<style lang="scss" scoped>
@import "@/assets/sass/variables";
  th, td {
    font-family: $font_family_root;
  }
  .withdraw-coin {
    // width: 45%;
    text-transform: uppercase;
    
  }
  .btn{
    font-family: $font-family-roboto !important;
  }
  .text-deposit-withdraw {
    font-family: $font-family-roboto-medium;
    font-size: $font_big;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
  }
  .deposit-padding {
    padding-right: 155px;
  }
  
  .content_modal_withdrawSetting {
    color: $color_mine_shaft;
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    line-height: $font_big_24;
  }
  .mgl_15 {
    margin-left: 15px;
  }
  .mgr_15 {
    margin-right: 15px;
  }
  .userInformation {
    margin-top: 5px;
    .head_userInformation {
      .form-group {
        display: flex;
        flex-direction: column;
        
        label {
          text-align: left;
          color: $color_mine_shaft;
          font-size: $font_big_20;
          font-weight: 500;
          line-height: 24px;
          margin: 0px 0px 10px 0px;
        }
        .btn_disable_all,
        .btn_enable_all {
          min-width: 100px;
          text-align: center;
          line-height: 21px;
          padding: 0px 5px;
          border: 1px solid;
          background-color: transparent;
          font-family: $font_family_root;
          border-radius: 3px;
          height: 25px;
          font-size: $font-smaller;
          font-weight: 600;
          text-transform: uppercase;
          transition: 0.5s;
          &:disabled{
            background-color : $color-bright-gray;
            border-color : $color-bright-gray;
            color: $color_mine_shaft;
          }
        }
        .btn_enable_all {
          border-color: $color-caribbean-green;
          background-color: transparent;
          color: $color-jungle-green;
          &:hover {
            background-color: $color-aquamarine;
            border-color: $color-aquamarine;
            color: $color-white;
            transition: 0.5s;
          }
          &:disabled{
            background-color : $color-bright-gray;
            border-color : $color-bright-gray;
            color: $color_mine_shaft;
          }
        }
        .btn_disable_all {
          border-color: $color-denim;
          color: $color-denim;
          &:hover {
            background-color: $color-denim;
            border-color: $color-denim;
            color: $color-white;
            transition: 0.5s;
          }
          &:disabled{
            background-color : $color-bright-gray;
            border-color : $color-bright-gray;
            color: $color_mine_shaft;
          }
        }
      }
    }
    .btn_enable,
    .btn_disable {
      min-width: 70px;
      text-align: center;
      line-height: 21px;
      padding: 0px 5px;
      border: 1px solid;
      background-color: transparent;
      font-family: $font_family_root;
      border-radius: 3px;
      height: 23px;
      font-size: $font-smaller;
      font-weight: 600;
      text-transform: uppercase;
      transition: 0.5s;
      &:hover {
        background-color: $color-caribbean-green;
        border-color: $color-caribbean-green;
        color: $color_white;
        transition: 0.5s;
      }
    }
    .btn_disable {
      border-color: $color-denim;
      background-color: transparent;
      color: $color-denim;
      &:hover {
        background-color: $color-denim;
        border-color: $color-denim;
        color: $color-white;
        transition: 0.5s;
      }
    }
    .btn_enable {
      border-color: $color-caribbean-green;
      background-color: transparent;
      color: $color-caribbean-green;
      /*
      &:hover {
        background-color: $color_white;
        border-color: $color-denim;
        color: $color-denim;
        transition: 0.5s;
      }
      */
    }
  }
   .datatable, .row-0, .head_userInformation{
    width: 65%;
    min-width: 500px;
   }
  .header-0, .header-1, .header-2, .header-3{
    width: 25%;
  }
  .header-2, .header-3{
    padding-left: 26px;
  }
  .header-1, .body-1{
    padding-left: 0px !important;
  }
  .head_userInformation{
    .row{
      margin: 0px;
      margin: 0 -8px;
    }
    .head_userInformation_0, .head_userInformation_1 {
      padding: 0 8px;
    }
  }
  .button-deposit-withdraw{
    display: flex;
    .button-deposit-withdraw-1, .button-deposit-withdraw-2{
      width: 50%;
    }
  }
  .head_userInformation_0, .head_userInformation_1{
    width: 50%;
    padding: 0px;
  }
  .body-2, .body-3{
  text-align: center;
  }
  .deposit-padding {
    margin: 0px 9px 10px 0px !important;
  }
  .button-deposit-withdraw-2{
      margin-right: 7px !important;
  }
</style>