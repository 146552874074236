import BaseRequest from '../lib/BaseRequest'

export default class BitmexMappingOrder extends BaseRequest {

    getMappingOrder(params) {
        const url = '/admin/api/bitmex/mapping-order';
        return this.get(url, params);
    }

    updateMaxRetry(params) {
        const url = '/admin/api/bitmex/update-max-retry';
        return this.post(url, params);
    }
}
