<template>
    <div>
    <div class="row">
      <div class="col-xs-5">
        <h5>Available Pairs</h5>
        <ul class="list-group">
          <li
            v-for="item in availableItems"
            :key="item.value"
            class="list-group-item"
            :class="{ 'list-group-item-active': isSelected(item) }"
            @click="toggleSelection(item)"
          >
            {{ item.label }}
          </li>
        </ul>
      </div>

    <div class="col-xs-2 text-center">
        <div class="btn-group-vertical">
            <button type="button" @click="moveSelectedToRight" class="btn btn-block btn-primary">>></button>
            <button type="button" @click="moveSelectedToLeft" class="btn btn-block btn-primary"><<</button>
        </div>
    </div>


      <div class="col-xs-5">
        <h5>Selected Pairs</h5>
        <ul class="list-group">
          <li
            v-for="item in selectedItems"
            :key="item.value"
            class="list-group-item"
            :class="{ 'list-group-item-active': isSelected(item) }"
            @click="toggleSelection(item)"
          >
            {{ item.label }}
          </li>
        </ul>
        <div 
            v-if="selectedItems.length !== 0"
        >
            <button class="update-btn"
            @click="updateHotSearch()">
                UPDATE
            </button>
        </div>
      </div>
    </div>
  </div>
</template>
  
  
<script>
import HotSearchRequest from '../../requests/HotSearchRequest';

export default {
  name: 'MultiselectComponent',
  data() {
    return {
      availableItems: [
        { value: 1, text: 'Item 1' },
        { value: 2, text: 'Item 5' },
        { value: 3, text: 'Item 2' },
        { value: 4, text: 'Item 4' },
        { value: 5, text: 'Item 3' }
      ],
      titlePage: 'HOT SEARCH',
      selectedItems: [],
      selected: [],
    };
  },
  methods: {
     getData() {
    const hotSearchRequest = new HotSearchRequest();

      return hotSearchRequest.getHotSearch().then(res => {
        this.selectedItems = res.data.options.filter((v)=> res.data.values.includes(v.value))
        return this.availableItems = res.data.options.filter((v)=> !res.data.values.includes(v.value))
      })
    },
    toggleSelection(item) {
      const index = this.selected.findIndex(i => i.value === item.value);
      if (index > -1) {
        this.selected.splice(index, 1);
      } else {
        this.selected.push(item);
      }
    },
    isSelected(item) {
      return this.selected.some(i => i.value === item.value);
    },
    moveSelectedToRight() {
      const selectedItems = this.availableItems.filter(item => this.selected.some(i => i.value === item.value));
      this.selectedItems = [...this.selectedItems, ...selectedItems];
      this.availableItems = this.availableItems.filter(item => !this.selected.some(i => i.value === item.value));
      this.selected = [];
    },
    moveSelectedToLeft() {
      const selectedItems = this.selectedItems.filter(item => this.selected.some(i => i.value === item.value));
      this.availableItems = [...this.availableItems, ...selectedItems];
      this.selectedItems = this.selectedItems.filter(item => !this.selected.some(i => i.value === item.value));
      this.selected = [];
    },
    updateHotSearch(){
        const hotSearchRequest = new HotSearchRequest();
        let body = []
        this.selectedItems.map((v)=> body = [...body, v.value])
        hotSearchRequest.updateHotSearch({'symbols':body})
        .then((res) => {
            if(res.success){
                alert('Update Success')
            }
        }
        )
    }
  },
  mounted() {
    this.$emit('EVENT_PAGE_CHANGE', { titlePage: 'HOT SEARCH' });
  },
  created() {
    this.getData()
  }
};
</script>

  
<style scoped>
.row{
    display: flex;
}
.list-group-item {
  cursor: pointer;
  position: relative;
  padding: 10px;
}
.list-group-item-active {
  background-color: #007bff; 
  color: white;
}
.btn-group-vertical {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.btn {
  margin-bottom: 5px;
}
.update-btn{
    background: #0e454c;
    color: white;
    border-radius: 10px;
    padding: 5px;
}
.update-btn:hover{
    background: #118696;
    color: white;
    border-radius: 10px;
    padding: 5px;
}
</style>
  