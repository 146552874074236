// import Vue from 'vue'
// import Vuex from 'vuex'
// import * as getters from './getters'
// import * as actions from './actions'
// import mutations from './mutations'
//
// Vue.use(Vuex);
//
// const state = {
//   usdTransactions: {},
//   unreadMessagesCount: 0,
//   unreadNotificationsCount: 0,
//   remainTasksCount: 0,
//   currentUser: {},
// };
//
// export default new Vuex.Store({
//   state,
//   getters,
//   actions,
//   mutations
// })
//

import Vue from 'vue';
import Vuex from 'vuex';
import _ from 'lodash';
import rf from '../lib/RequestFactory';

Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        masterdata: localStorage.getItem('masterdata') ? JSON.parse(localStorage.getItem('masterdata')) : undefined,
        masterdataFuture: localStorage.getItem('masterdata_future') ? JSON.parse(localStorage.getItem('masterdata_future')) : undefined,
    },
    getters: {
        getCoinFullName: (state) => (coin) => {
            const item = _.chain(state.masterdata.coins || []).find((item) => item.coin === coin).value();
            if (item) {
                return item.name;
            }
            return '';
        },
        getCountryName: (state) => (countryId) => {
            if (!state.masterdata.countries) {
                return '';
            }
            const country = _.find(state.masterdata.countries || [], (country) => country.id === countryId) || {};
            return country.name;
        },

        coins (state) {
            return _.filter(state.masterdata.coins, (coin) => coin.env === process.env.MIX_BLOCKCHAIN_NETWORK) || {};
        },

        getTransactionUrl: (state, getters) => (currency, transactionId) => {
            const item = _.chain(getters.coins || []).find((item) => item.coin === currency).value();
            if (item && item.transaction_tx_path) {
                return item.transaction_tx_path.replace(/\{\$transaction_id\}/g, transactionId);
            }
            return '';
        },
    },
    mutations: {
        updateMasterdata (state, data) {
            state.masterdata = data;
        },
        updateMasterdataFuture (state, data) {
            state.masterdataFuture = data;
        },
    },
    actions: {
        getMasterdata ({ commit }) {
            return new Promise((resolve) => {
                rf.getRequest('MasterdataRequest').getAll().then((res) => {
                    commit('updateMasterdata', res);
                    resolve(res);
                });
            });
        },
        getMasterdataFuture ({ commit }) {
            return new Promise((resolve) => {
                rf.getRequest('MasterdataRequest').getAllFuture().then((res) => {
                    commit('updateMasterdataFuture', res);
                    resolve(res);
                });
            });
        },
        getUserKycs({commit}){
            return new Promise((resolve) => {
                rf.getRequest('AdminRequest').getUserKyc().then((res) => {
                    commit('updateMasterdata', res);
                    resolve(res);
                });
            });
        },

        init () {
            this.dispatch('getMasterdata');
            // this.dispatch('getMasterdataFuture');
        },
    },
});

export default store;

