<template>
  <div class="profile-container">
    <div class="profile">
      <div class="profile-header">
        <div>{{ $t("account.spot_future.spot_profile") }}</div>
        <button class="btn btn-update" @click="onUpdateSpot">{{ $t('account.account_detail.update') }}</button>
      </div>
      <div class="profile-body">
        <div class="profile-item">
          <span>{{ $t("account.spot_future.allow_trade") }}</span>
          <div class="switch-container">
            <label class="switch">
              <input type="checkbox" v-model="spot.spot_trade_allow"
                @input="event => spot.spot_trade_allow = event.target.checked">
              <span class="slider round"></span>
            </label>
          </div>
        </div>
        <div class="profile-item">
          <span>{{ $t("account.spot_future.market_maker") }}</span>
          <div class="switch-container">
            <label class="switch">
              <input type="checkbox" v-model="spot.spot_market_marker_allow"
                @input="event => spot.spot_market_marker_allow = event.target.checked">
              <span class="slider round"></span>
            </label>
          </div>
        </div>
        <div class="profile-item">
          <span>{{ $t("account.spot_future.trading_fee") }}</span>
          <div class="switch-container">
            <label class="switch">
              <input type="checkbox" v-model="spot.spot_trading_fee_allow"
                @input="event => spot.spot_trading_fee_allow = event.target.checked">
              <span class="slider round"></span>
            </label>
          </div>
        </div>
        <div class="profile-item trading-pair">
          <!-- <span>{{ $t("account.spot_future.pre_trading") }}</span> -->
          <multi-select-box :custom-label="$t('account.spot_future.pre_trading')" :options="enablePrePairSpot"
            :value="spot.spot_coin_pair_trade" :on-change="selected => spot.spot_coin_pair_trade = selected" />
        </div>
      </div>
    </div>
    <div class="profile">
      <div class="profile-header">
        <div>{{ $t("account.spot_future.future_profile") }}</div>
        <button class="btn btn-update" @click="onUpdateFutures">{{ $t('account.account_detail.update') }}</button>
      </div>
      <div class="profile-body">
        <div class="profile-item">
          <span>{{ $t("account.spot_future.allow_trade") }}</span>
          <div class="switch-container">
            <label class="switch">
              <input type="checkbox" v-model="futures.allowTrade"
                @input="event => futures.allowTrade = event.target.checked">
              <span class="slider round"></span>
            </label>
          </div>
        </div>
        <div class="profile-item">
          <span>{{ $t("account.spot_future.market_maker") }}</span>
          <div class="switch-container">
            <label class="switch">
              <input type="checkbox" v-model="futures.isMarketMaker"
                @input="event => futures.isMarketMaker = event.target.checked">
              <span class="slider round"></span>
            </label>
          </div>
        </div>
        <div class="profile-item">
          <span>{{ $t("account.spot_future.trading_fee") }}</span>
          <div class="switch-container">
            <label class="switch">
              <input type="checkbox" v-model="futures.enableTradingFee"
                @input="event => futures.enableTradingFee = event.target.checked">
              <span class="slider round"></span>
            </label>
          </div>
        </div>
        <div class="profile-item trading-pair">
          <!-- <span>{{ $t("account.spot_future.pre_trading") }}</span> -->
          <multi-select-box :custom-label="$t('account.spot_future.pre_trading')" :options="enablePrePairFutures"
            :value="futures.preTradingPair || []" :on-change="selected => futures.preTradingPair = selected" />
        </div>
      </div>
    </div>
    <modal class="spotFuture-modal" :name="'updateSpot'" :title="$t('account.modal.confirm')">
      <div slot="body" class="body-popup text-center">
        <p class="main-content">{{ $t('account.spot_future.confirm_update_spot') }}</p>
        <p>{{ $t('account.account_detail.account_id') }}: <strong>{{ userId }}</strong></p>
      </div>
    </modal>
    <modal class="spotFuture-modal" :name="'updateFuture'" :title="$t('account.modal.confirm')">
      <div slot="body" class="body-popup text-center">
        <p class="main-content">{{ $t('account.spot_future.confirm_update_futures') }}</p>
        <p>{{ $t('account.account_detail.account_id') }}: <strong>{{ userId }}</strong></p>
      </div>
    </modal>
    <modal class="kyc_modal" :enableClose="enableClose" name="loadingUpload">
      <div slot="body">
        <vue-loading type="bars" v-if="isLoading" color="#2DAC91" :size="{ width: '60px', height: '80px' }">
        </vue-loading>
      </div>
    </modal>
  </div>
</template>

<script>

import moment from "moment";
import rf from "../../lib/RequestFactory";
import Modal from "../../components/Modal";
import MultiSelectBox from "../../components/MultiSelectBox.vue";

export default {
  name: "SpotAndFutureProfile",
  components: {
    Modal,
    MultiSelectBox
  },
  props: {
    userId: {
      type: String
    }
  },
  data() {
    return {
      spot: {
        spot_trade_allow: false,
        spot_market_marker_allow: false,
        spot_trading_fee_allow: false,
        spot_coin_pair_trade: []
      },
      futures: {
        allowTrade: false,
        enableTradingFee: false,
        isMarketMaker: false,
        preTradingPair: []
      },
      enablePrePairSpot: [],
      enablePrePairFutures: [],
      isLoading: false,
      enableClose: false
    }
  },
  methods: {
    getData() {
      rf.getRequest("AdminRequest").getSpotProfile(this.$route.query.id)
        .then(res => {
          this.spot = {
            ...res.data,
            spot_coin_pair_trade: res.data.spot_coin_pair_trade.map((item) => {
              return { id: item, name: item.toUpperCase() }
            })
          }
        })
      rf.getRequest("AdminRequest").getFuturesProfile({ userId: this.$route.query.id })
        .then(res => {
          this.futures = {
            ...res.data,
            preTradingPair: res.data.preTradingPair?.map((item) => {
              return { id: item, name: item }
            }) || []
          }
          console.log(this.futures)
        })
    },
    getOptionPrePairSpot() {
      rf.getRequest("AdminRequest").getPairSpotProfile('enable')
        .then((res) => {
          this.enablePrePairSpot = res.data.map((item) => {
            return { id: item, name: item.toUpperCase() }
          })
        })
    },
    getOptionPrePairFutures() {
      rf.getRequest("AdminRequest").getPairFuturesProfile()
        .then((res) => {
          this.enablePrePairFutures = res.data.map((item) => {
            return { id: item.name, name: item.name.toUpperCase() }
          })
        })
    },
    onUpdateSpot() {
      const params = {
        ...this.spot,
        spot_coin_pair_trade: this.spot.spot_coin_pair_trade.map((item) => item.id)
      }
      this.showModalConfirm('updateSpot', this.handleUpdateSpot, params)
    },
    onUpdateFutures() {
      const params = {
        userId: this.$route.query.id,
        ...this.futures,
        preTradingPair: this.futures.preTradingPair.map(item => item.id)
      };
      this.showModalConfirm('updateFuture', this.handleUpdateFutures, params);
    },
    handleUpdateSpot(params) {
      rf.getRequest("AdminRequest").updateSpotProfile(
        this.$route.query.id,
        params
      ).then(res => {
        if (res?.success) {
          this.$toastr('success', this.$t('account.spot_future.update_successful'));
          this.getData();
        } else {
          this.$toastr('error', this.$t('account.spot_future.update_failed'));
        }
      })
    },
    handleUpdateFutures(params) {
      rf.getRequest("AdminRequest").updateFuturesProfile(
        params
      ).then(res => {
        if (res?.code === 200) {
          this.$toastr('success', this.$t('account.spot_future.update_successful'));
          this.getData();
        } else {
          this.$toastr('error', this.$t('account.spot_future.update_failed'));
        }
      })
    },
    showModalConfirm(modalName, callback, params) {
      CommonModal.show(modalName, {
        position: 'center',
        mask: true,
        title: this.$t('account.modal.confirm'),
        buttons: [
          {
            label: window.i18n.t('common.action.no'),
            class: "btn-no",
            style: {
              background: 'rgb(191, 191, 191);'
            },
            callback: () => {
              CommonModal.hide(modalName);
            }
          },
          {
            label: window.i18n.t('common.action.yes'),
            focused: true,
            style: {
              background: 'rgb(0, 112, 192)'
            },
            callback: () => {
              CommonModal.hide(modalName);
              this.loadingUploadBegin();
              callback(params);
              this.loadingUploadFinish();
            }
          }
        ]
      });
    },
    loadingUploadBegin() {
      this.enableClose = false;
      this.isLoading = true;
      CommonModal.show('loadingUpload');
    },

    loadingUploadFinish() {
      CommonModal.hide('loadingUpload');
    },
  },
  created() {
    this.getData();
  },
  mounted() {
    this.getOptionPrePairSpot();
    this.getOptionPrePairFutures();
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/variables";

.profile-container {
  display: flex;
  flex-wrap: wrap;
  gap: 80px;
  margin-top: 24px;

  .profile {
    width: calc(50% - 40px);
    background-color: $color-white;
    padding: 16px 24px;
    border-radius: 10px;

    .profile-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px 0;
      border-bottom: 1px solid $color-bright-gray;

      div {
        font-size: medium;
        color: $color-gray-dark;
        font-weight: 700;
      }
    }

    .profile-body {
      display: flex;
      flex-wrap: wrap;
      padding-top: 16px;
      gap: 16px;

      .profile-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: calc(50% - 8px);
        padding: 8px 12px;
        border: 1px solid $color-bright-gray;
        border-radius: 10px;

        &.trading-pair {
          padding: 0;
          border: none;
        }

        span {
          color: $color-gray-dark;
        }
      }
    }
  }

  .btn {
    background: $color-caribbean-green;
    border-radius: 10px;
    color: $color_white;
    font-weight: 700;
    width: 78px;
    height: 32px;
  }

  .main-content {
    font-size: medium;

    &+div {
      text-align: center;
      margin-top: 12px;
    }
  }
}
</style>

<style lang="scss">
@import "@/assets/sass/variables";
</style>