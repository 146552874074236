<template>
    <div class="coinEdit_page" id="coinEdit_page">
        <div class="action">
            <button class="btn btn-cancel" @click="handleBack()">{{
                $t('resource.networks.btn_cancel') }}</button>
            <button v-if="$route.query.id" class="btn btn-update" @click="onSubmitUpdateCoin()">{{
                $t('resource.networks.btn_update') }}</button>
            <button v-if="!$route.query.id" class="btn btn-update" @click="onSubmitCreateCoin()">{{
                $t('resource.networks.btn_create') }}</button>
        </div>
        <div class="form-container">
            <!-- Main Inputs -->
            <div class="form-group">
                <label class="label-text" for="name">{{ $t('resource.coins.name') }}</label>
                <input @focus="resetErrors" :maxlength="maxLength" :class="{ 'error': errors.has('name') }"
                    v-model="formData.name" type="text" id="name" :placeholder="$t('resource.coins.enter_name')">
                <div v-show="errors.has('name')" class="invalid-feedback">{{ errors.first('name') }}</div>
            </div>

            <div class="form-group">
                <label class="label-text" for="symbol">{{ $t('resource.coins.symbol') }}</label>
                <input @focus="resetErrors" :maxlength="maxLength" :class="{ 'error': errors.has('symbol') }"
                    type="text" id="symbol" :placeholder="$t('resource.coins.enter_symbol')" v-model="formData.coin"
                    :disabled="$route.query.id">
                <div v-show="errors.has('symbol')" class="invalid-feedback">{{ errors.first('symbol') }}</div>
            </div>

            <!-- <div class="form-group">
                <label class="label-text" for="exchanges">{{ $t('resource.coins.exchanges') }}</label>
                <multi-select-box :on-focus="resetErrors" :maxlength="maxLength"
                    :class="{ 'error': errors.has('exchanges') }" :options="exchangeOptions" id="exchanges"
                    :value="formData.exchanges" :on-change="selected => formData.exchanges = selected" />
                <div v-show="errors.has('exchanges')" class="invalid-feedback">{{ errors.first('exchanges') }}</div>
            </div> -->

            <div class="form-group">
                <label class="label-text" for="price">{{ $t('resource.coins.price') }}</label>
                <div class="input-group">
                    <input @focus="resetErrors" :maxlength="maxLength" :class="{ 'error': errors.has('price') }"
                        v-model="formData.usd_price" type="number" id="price" placeholder="0"><span
                        class="unit">USD</span>
                </div>
                <div v-show="errors.has('price')" class="invalid-feedback">{{ errors.first('price') }}</div>
            </div>

            <div class="form-group">
                <label class="label-text" for="precision">{{ $t('resource.coins.precision') }}</label>
                <input @focus="resetErrors" :maxlength="maxLength" :class="{ 'error': errors.has('precision') }"
                    type="text" id="precision" :placeholder="$t('resource.coins.enter_precision')"
                    v-model="formData.decimal">
                <div v-show="errors.has('precision')" class="invalid-feedback">{{ errors.first('precision') }}</div>
            </div>

            <!-- <div class="form-group">
                <label class="label-text" for="wallets">{{ $t('resource.coins.wallets') }}</label>
                <select-box :on-focus="resetErrors" :maxlength="maxLength" :class="{ 'error': errors.has('wallets') }"
                    id="wallets" :options="walletOptions" :value="formData.wallets"
                    :on-change="selected => formData.wallets = selected" />
                <div v-show="errors.has('wallets')" class="invalid-feedback">{{ errors.first('wallets') }}</div>
            </div> -->

            <div class="form-group">
                <label class="label-text no-label"></label>
                <div class="form-group w-100 status">
                    <label class="label-text" for="explorer-url">{{ $t('resource.coins.fixed_price') }}</label>
                    <checkbox :checked.sync="formData.is_fixed_price === 1"
                        :handle-checked="value => formData.is_fixed_price = Number(value)">
                    </checkbox>
                </div>
            </div>


            <div class="form-group">
                <label class="label-text no-label"></label>
                <div class="form-group w-100 status">
                    <label class="label-text" for="explorer-url">{{ $t('resource.coins.enable') }}</label>
                    <checkbox :checked.sync="formData.status === 1"
                        :handle-checked="value => formData.status = Number(value)">
                    </checkbox>
                </div>
            </div>

            <div class="form-group">
                <label class="label-text">{{ $t('resource.coins.upload_coin_icon') }}</label>
                <div class="form-group image" :class="{ 'error': errors.has('icon_image') }">
                    <div class="upload-image">
                        <input name="photo" type="file" accept="image/*" class="btn-upload" @input="getImgData" />
                        <img v-if="!imageUpload" src="@/assets/images/icon/upload-image-icon.svg" class="upload-img" />
                        <img v-else :src="imageUpload" class="preview-img" />
                    </div>
                </div>
                <div v-show="errors.has('icon_image')" class="invalid-feedback">{{
                    errors.first('icon_image') }}</div>
            </div>

        </div>

        <div class="break-line">
            <div class="label">{{ $t('resource.coins.networks_setting') }}</div>
        </div>
        <!-- Networks Settings -->
        <div v-for="(network, index) in networks" :key="network.id" class="network-section">
            <h4 class="network-label">
                {{ $t('resource.coins.network') }} {{ index + 1 }}
                <img src="@/assets/images/icon/delete-icon.svg" @click="removeNetwork(index)" />
            </h4>
            <div class="form-container">
                <div class="form-group status">
                    <label class="label-text" for="explorer-url">{{ $t('resource.coins.allow_withdraw') }}</label>
                    <checkbox :checked.sync="network.network_withdraw_enable === 1"
                        :handle-checked="value => handleCheck(value, 'network_withdraw_enable', index)">
                    </checkbox>
                </div>

                <div class="form-group status">
                    <label class="label-text" for="explorer-url">{{ $t('resource.coins.allow_deposit') }}</label>
                    <checkbox :checked.sync="network.network_deposit_enable === 1"
                        :handle-checked="value => handleCheck(value, 'network_deposit_enable', index)">
                    </checkbox>
                </div>

                <div class="form-group status">
                    <label class="label-text" for="explorer-url">{{ $t('resource.coins.enable') }}</label>
                    <checkbox :checked.sync="network.network_enable === 1"
                        :handle-checked="value => handleCheck(value, 'network_enable', index)">
                    </checkbox>
                </div>

                <div class="form-group w-66">
                    <label class="label-text" for="contract-address">{{ $t('resource.coins.contract_address') }}</label>
                    <input @focus="resetErrors" :maxlength="maxLength" :id="`contract_address[${index}]`"
                        :class="{ 'error': errors.has(`contract_address[${index}]`) }"
                        v-model="network.contract_address" type="text"
                        :placeholder="$t('resource.coins.enter_contract_address')"
                        @keyup="validateAddress(network, index)">
                    <div v-show="errors.has(`contract_address[${index}]`)" class="invalid-feedback">{{
                        errors.first(`contract_address[${index}]`) }}</div>
                </div>

                <div class="form-group">
                    <label class="label-text" for="network">{{ $t('resource.coins.network') }}</label>
                    <select-box :on-focus="resetErrors" :maxlength="maxLength"
                        :class="{ 'error': errors.has(`network[${index}]`) }" :value="network.network"
                        :placeholder="$t('resource.coins.enter_network')" :options="networkOptions"
                        :on-change="value => onChangeNetwork(value, index)" />
                    <div v-show="errors.has(`network[${index}]`)" class="invalid-feedback">{{
                        errors.first(`network[${index}]`) }}</div>
                </div>

                <div class="form-group w-66">
                    <label class="label-text" for="token-explorer">{{ $t('resource.coins.token_explorer') }}</label>
                    <input @focus="resetErrors" :maxlength="maxLength" :id="`token_explorer[${index}]`"
                        :class="{ 'error': errors.has(`token_explorer[${index}]`) }"
                        v-model="network.token_explorer_url" type="text"
                        :placeholder="$t('resource.coins.enter_token_explorer')">
                    <div v-show="errors.has(`token_explorer[${index}]`)" class="invalid-feedback">{{
                        errors.first(`token_explorer[${index}]`) }}</div>
                </div>

                <div class="form-group">
                    <label class="label-text" for="explorer-url">{{ $t('resource.coins.explorer_url') }}</label>
                    <input @focus="resetErrors" :maxlength="maxLength" :id="`explorer_url[${index}]`"
                        :class="{ 'error': errors.has(`explorer_url[${index}]`) }" v-model="network.explorer_url"
                        type="text" :placeholder="$t('resource.coins.enter_explorer_url')">
                    <div v-show="errors.has(`explorer_url[${index}]`)" class="invalid-feedback">{{
                        errors.first(`explorer_url[${index}]`) }}
                    </div>
                </div>

                <div class="form-group">
                    <label class="label-text" for="withdraw-fee">{{ $t('resource.coins.withdraw_fee') }}</label>
                    <input @focus="resetErrors" :maxlength="maxLength" :id="`withdraw_fee[${index}]`"
                        :class="{ 'error': errors.has(`withdraw_fee[${index}]`) }" v-model="network.withdraw_fee"
                        type="number" placeholder="0">
                    <div v-show="errors.has(`withdraw_fee[${index}]`)" class="invalid-feedback">{{
                        errors.first(`withdraw_fee[${index}]`) }}
                    </div>
                </div>

                <!-- <div class="form-group">
                    <label class="label-text" for="min-deposit">{{ $t('resource.coins.min_deposit') }}</label>
                    <input @focus="resetErrors" :maxlength="maxLength" :id="`min_deposit[${index}]`"
                        :class="{ 'error': errors.has(`min_deposit[${index}]`) }" v-model="network.min_deposit"
                        type="number" placeholder="0">
                    <div v-show="errors.has(`min_deposit[${index}]`)" class="invalid-feedback">{{
                        errors.first(`min_deposit[${index}]`) }}
                    </div>
                </div> -->

                <div class="form-group">
                    <label class="label-text" for="min-withdraw">{{ $t('resource.coins.min_withdraw') }}</label>
                    <input @focus="resetErrors" :maxlength="maxLength" :id="`min_withdraw[${index}]`"
                        :class="{ 'error': errors.has(`min_withdraw[${index}]`) }" v-model="network.min_withdraw"
                        type="number" placeholder="0">
                    <div v-show="errors.has(`min_withdraw[${index}]`)" class="invalid-feedback">{{
                        errors.first(`min_withdraw[${index}]`) }}
                    </div>
                </div>
            </div>
        </div>

        <!-- Add Item Button -->
        <button class="btn-add" @click="addNetwork">
            <span class="icon-plus"></span>
            {{ $t('resource.coins.add_item') }}
        </button>

        <modal class="confirm_modal" name="delete_modal" :title="$t('account.modal.confirm')">
            <div slot="body" class="body-popup">
                {{ $t('resource.coins.popup_delete_network_content') }}
            </div>
        </modal>

        <modal class="confirm_modal" name="update_modal" :title="$t('account.modal.confirm')">
            <div slot="body" class="body-popup">
                {{ $t('resource.coins.popup_update_content') }}
            </div>
        </modal>

        <modal class="confirm_modal" name="create_modal" :title="$t('account.modal.confirm')">
            <div slot="body" class="body-popup">
                {{ $t('resource.coins.popup_create_content') }}
            </div>
        </modal>

        <modal class="confirm_modal" :enableClose="enableClose" name="loadingUpload">
            <div slot="body">
                <vue-loading type="bars" v-if="isLoading" color="#2DAC91" :size="{ width: '60px', height: '80px' }">
                </vue-loading>
            </div>
        </modal>
    </div>
</template>

<script>
import rf from "../../lib/RequestFactory";
import Modal from "../../components/Modal";
import MultiSelectBox from "../../components/MultiSelectBox.vue";
import Const from "../../common/Const";
import Checkbox from "../../components/Checkbox.vue";
import SelectBox from "../../components/SelectBox";
import Utils from "../../common/Utils";

export default {
    name: "CoinEdit",
    components: {
        Modal,
        MultiSelectBox,
        Checkbox,
        SelectBox
    },
    watch: {
        errors: {
            handler: function (newVal) {
                console.log('newVal', newVal)

            },
            deep: true
        }
    },
    data() {
        return {
            titlePage: this.$route.query.id ? window.i18n.t('resource.coins.title_edit') : window.i18n.t('resource.coins.title_create'),
            isLoading: false,
            enableClose: true,
            formData: {
                name: "",
                coin: "",
                usd_price: "",
                decimal: "",
                is_fixed_price: 0,
                status: 0,
                enable: 0,
                icon_image: ""
            },
            networks: [
                {
                    id: Utils.uuid(),
                    network_withdraw_enable: 0,
                    network_deposit_enable: 0,
                    network_enable: 0,
                    contract_address: '',
                    network: null,
                    token_explorer_url: '',
                    explorer_url: '',
                    withdraw_fee: '',
                    min_deposit: '',
                    min_withdraw: '',
                    is_new: true
                }
            ],
            networksSelected: [],
            exchangeOptions: [
                { id: 'binance', name: "BINANCE" },
                { id: 'coinbase', name: "COINBASE" },
            ],
            walletOptions: [
                { id: 'spot', name: "SPOT" },
                { id: 'future', name: "FUTURE" },
            ],
            networkOptions: [
                { id: 0, name: "ETHEREUM" },
                { id: 1, name: "BINANCE" },
                { id: 2, name: "SONALA" },
            ],
            dataNetworks: null,
            maxLength: Const.MAX_LENGTH_INPUT,
            imageUpload: ""
        };
    },
    methods: {
        getData() {
            Promise.all([
                rf.getRequest('MultiChainRequest').getCoinDetail(this.$route.query.id),
                rf.getRequest('MultiChainRequest').getNetworks({ limit: 50 })
            ])
                .then((res) => {
                    this.formData = res[0].data;
                    this.imageUpload = res[0].data.icon_image;
                    this.networkOptions = res[1].data.data.filter((item) => item.enable === 1);
                    this.networks = res[0].data.network_coins.map((item) => {
                        const network = res[1].data.data.find((element) => item.network_id === element.id);
                        this.networksSelected.push(network.id);
                        return {
                            ...item,
                            network: { id: item.network_id },
                            explorer_url: network.explorer_url,
                        }
                    });
                })
        },
        getDataNetwork() {
            rf.getRequest('MultiChainRequest').getNetworks({ limit: 50 })
                .then(res => {
                    if (res?.success) {
                        this.networkOptions = res.data.data.filter((item) => item.enable === 1);
                    }
                })
                .catch(error => {
                    this.$toastr('error', this.$t('Error'));
                })
        },
        handleCheck(value, field, index) {
            this.networks[index][field] = Number(value);
        },
        addNetwork() {
            this.networksSelected.push("");
            this.networks.push({
                id: Utils.uuid(),
                network_withdraw_enable: 0,
                network_deposit_enable: 0,
                network_enable: 0,
                contract_address: '',
                network: null,
                token_explorer_url: '',
                explorer_url: '',
                withdraw_fee: '',
                min_deposit: '',
                min_withdraw: '',
                is_new: true
            });
        },
        async onSubmitCreateCoin() {
            const isValid = await this.validateAll();
            if (!isValid) return;
            const params = this.formatParams();
            this.showModalConfirm('create_modal', this.createCoin, params);
        },
        async onSubmitUpdateCoin() {
            const isValid = await this.validateAll();
            if (!isValid) return;
            const params = this.formatParams();
            this.showModalConfirm('update_modal', this.updateCoin, params);
        },
        createCoin(params) {
            rf.getRequest('MultiChainRequest').createCoin(params).then(res => {
                if (res?.success) {
                    this.$toastr('success', this.$t('resource.coins.toast_create_success'));
                    this.handleBack();
                } else {
                    this.$toastr('error', this.$t('resource.coins.toast_create_fail'));
                }
            });
        },
        updateCoin(params) {
            rf.getRequest('MultiChainRequest').updateCoin(params).then(res => {
                if (res?.success) {
                    this.$toastr('success', this.$t('resource.coins.toast_update_success'));
                    this.handleBack();
                } else {
                    this.$toastr('error', this.$t('resource.coins.toast_update_fail'));
                }
            });
        },
        removeNetwork(index) {
            if (!this.networks[index].is_new) {
                this.onDeleteNetwork(this.networks[index]);
            } else {
                this.networks.splice(index, 1);
                this.networksSelected.splice(index, 1);
            }
        },
        onDeleteNetwork(value) {
            this.showModalConfirm('delete_modal', this.deleteNetwork, value.id);
        },
        deleteNetwork(params) {
            rf.getRequest('MultiChainRequest').deleteNetworkCoin(params).then(res => {
                if (res?.success) {
                    this.$toastr('success', this.$t('resource.coins.toast_delete_success'));
                    this.getAccountDetail();
                } else {
                    this.$toastr('error', this.$t('resource.coins.toast_delete_fail'));
                }
            })
                .catch(error => {
                    this.$toastr('error', this.$t('resource.coins.toast_delete_fail'));
                })
        },
        showModalConfirm(modalName, callback, params) {
            CommonModal.show(modalName, {
                position: 'center',
                mask: true,
                buttons: [
                    {
                        label: window.i18n.t('common.action.no'),
                        class: "btn-no",
                        style: {
                            background: 'rgb(191, 191, 191);'
                        },
                        callback: () => {
                            CommonModal.hide(modalName);
                        }
                    },
                    {
                        label: window.i18n.t('common.action.yes'),
                        focused: true,
                        style: {
                            background: 'rgb(0, 112, 192)'
                        },
                        callback: () => {
                            CommonModal.hide(modalName);
                            this.loadingUploadBegin();
                            callback(params);
                            this.loadingUploadFinish();
                        }
                    }
                ]
            });
        },
        onChangeNetwork(value, index) {
            console.log(value);
            this.networks[index].network = { id: value.id };
            this.networks[index].explorer_url = value.explorer_url;
            this.networksSelected[index] = value.id;
            this.validateSelect(this.networks[index], index);
        },
        formatParams() {
            return {
                ...this.formData,
                icon_image: this.imageUpload,
                network_coins: this.networks.map((item) => {
                    const network = {
                        ...item,
                        network_id: item.network.id,
                    }
                    if (network.is_new) delete network.id;
                    return network;
                })
            }
        },
        async validateSelect(network, index) {
            if (network.network?.id && network.contract_address) {
                const params = {
                    contract_address: network.contract_address,
                    network_id: network.network.id,
                }
                await rf.getRequest("MultiChainRequest").validateContractAddress(params)
                    .then((res) => {
                        this.errors.remove(`contract_address[${index}]`);
                    })
                    .catch(error => {
                        this.errors.remove(`contract_address[${index}]`);
                        this.errors.add({ field: `contract_address[${index}]`, msg: this.$t('resource.coins.error_address_contract_field_invalid') });
                    })
            }
        },
        validateAddress: _.debounce(function (network, index) { this.validateSelect(network, index) }, 500),
        async validateAll() {
            if (!this.formData.name) {
                this.errors.add({ field: 'name', msg: this.$t('resource.coins.error_name_field') });
            }
            if (!this.formData.coin) {
                this.errors.add({ field: 'symbol', msg: this.$t('resource.coins.error_symbol_field') });
            }
            if (!this.formData.usd_price) {
                this.errors.add({ field: 'price', msg: this.$t('resource.coins.error_price_field') });
            }
            if (!this.formData.decimal) {
                this.errors.add({ field: 'precision', msg: this.$t('resource.coins.error_precision_field') });
            }
            if (!this.imageUpload) {
                this.errors.add({ field: 'icon_image', msg: this.$t('resource.coins.error_icon_image_field') });
            }
            if (this.networks.length > 0) {
                await this.validateNetworks();
            }
            if (this.errors.count() > 0) {
                return false;
            }
            return true;
        },
        async validateNetworks() {
            for (const [index, network] of this.networks.entries()) {
                if (!network.contract_address.trim()) {
                    this.errors.add({ field: `contract_address[${index}]`, msg: this.$t('resource.coins.error_address_contract_field') });
                }
                if (!network.network) {
                    this.errors.add({ field: `network[${index}]`, msg: this.$t('resource.coins.error_network_field') });
                }
                if (network.contract_address && network.network.id) {
                    await this.validateSelect(network, index);
                }
                if (!network.token_explorer_url?.trim()) {
                    this.errors.add({ field: `token_explorer[${index}]`, msg: this.$t('resource.coins.error_token_explorer_field') });
                }
                if (!network.explorer_url?.trim()) {
                    this.errors.add({ field: `explorer_url[${index}]`, msg: this.$t('resource.coins.error_explorer_url_field') });
                }
                if (!network.withdraw_fee) {
                    this.errors.add({ field: `withdraw_fee[${index}]`, msg: this.$t('resource.coins.error_withdraw_fee_field') });
                }
                // if (!network.min_deposit) {
                //     this.errors.add({ field: `min_deposit[${index}]`, msg: this.$t('resource.coins.error_min_deposit_field') });
                // }
                if (!network.min_withdraw) {
                    this.errors.add({ field: `min_withdraw[${index}]`, msg: this.$t('resource.coins.error_min_withdraw_field') });
                }
            };
        },
        loadingUploadBegin() {
            this.enableClose = false;
            this.isLoading = true;
            CommonModal.show('loadingUpload');
        },
        loadingUploadFinish() {
            CommonModal.hide('loadingUpload');
            this.isLoading = false;
            this.enableClose = true;
        },
        handleBack() {
            this.$router.push({
                name: "Coins",
                params: { pagination: this.$route.params.pagination }
            })
        },
        getImgData(event) {
            this.errors.clear();
            const files = event.target.files[0];
            if (files) {
                const reader = new FileReader();
                reader.addEventListener("load", (e) => {
                    this.imageUpload = e.target.result;
                });
                reader.readAsDataURL(files);
            }
        }
    },
    computed: {
        filterNetworkOptions: function () {
            return this.networkOptions.filter((network) => {
                if (!this.networksSelected.includes(network.id)) return network;
            })
        }
    },
    mounted() {
        this.$emit('EVENT_PAGE_CHANGE', this);
        if (this.$route.query.id) this.getData();
        else this.getDataNetwork();
    },
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/variables";

.coinEdit_page {
    background-color: $color-white;
    border-radius: 10px;
    padding: 30px 40px;
    margin-top: 30px;

    .action {
        display: flex;
        justify-content: right;
        margin-bottom: 30px;
    }

    .btn {
        margin: 0 5px;
        width: auto;
        margin-top: 18px;
        height: 32px;
        font-family: $mulish-bold;
        border-radius: 10px;
        text-transform: uppercase;

        &.btn-update {
            border: 1px solid $color-caribbean-green;
            color: $color_white;
            background-color: $color-caribbean-green;

            &:hover {
                background-color: $color-aquamarine;
                border-color: $color-aquamarine;
            }
        }

        &.btn-cancel {
            background-color: $color-bright-gray;
            border-color: $color-bright-gray;
            color: $color-black;

            &:hover {
                opacity: 0.7;
            }
        }
    }

    .form-container {
        display: flex;
        flex-wrap: wrap;
        gap: 10px 20px;
    }

    .form-group {
        display: flex;
        flex-direction: column;
        width: calc((100% - 40px) / 3);

        &.status {
            border-radius: 10px;
            margin-top: 10px;
            margin-bottom: 0;
            padding: 8px 16px;
            border: 1px solid $color-bright-gray;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            .label-text {
                margin: 0;
                font-family: $mulish-medium;
                font-size: 16px;
            }
        }

        &.image {
            margin: 0;
            border-radius: 10px;
            border: 1px solid $color-bright-gray;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            width: 50%;
            aspect-ratio: 1/1;
            overflow: hidden;

            &.error {
                border: solid 1px $color-red-main !important;
            }

            .label-text {
                margin: 0;
                font-family: $mulish-medium;
                font-size: 16px;
            }

            .upload-image {
                position: relative;
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;

                .btn-upload {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    opacity: 0;

                    &:hover {
                        cursor: pointer;
                    }
                }

                .upload-img {
                    border-radius: 6px;
                    width: 60px;
                    height: 60px;
                }

                .preview-img {
                    height: 100%;
                    width: auto;
                    object-fit: fill;
                }
            }
        }

        &.w-100 {
            width: calc(100%);
        }

        &.w-66 {
            width: calc(66% - 1px);
        }

        .input-group {
            position: relative;

            .unit {
                margin-right: 10px;
                font-family: $mulish-medium;
            }
        }
    }

    .label-text {
        font-size: $font-root;
        color: $color-grey-dusty;
        font-family: $mulish-regular;
        font-weight: 400;
        margin-bottom: 7px;
        padding-left: 5px;

        &.no-label {
            display: inline-block;
            height: 10px;
        }
    }

    input[type=text],
    input[type=number] {
        padding: 8px 16px;
        border: 1px solid $color-bright-gray !important;
        border-radius: 4px;
        font-size: 16px;
        border-radius: 10px !important;
        font-family: $mulish-medium;
        box-shadow: none !important;
        height: auto;

        &.error {
            border: solid 1px $color-red-main !important;
        }

        &:disabled {
            background-color: $color_grey_body !important;
        }
    }

    input[type=number] {
        padding-right: 50px;
    }

    .invalid-feedback {
        color: $color-red-main;
        font-size: 14px;
    }

    .break-line {
        margin-top: 40px;
        margin-bottom: 30px;
        border-top: 1px solid $color-alto;
        position: relative;
        display: flex;
        align-items: center;

        .label {
            margin-left: 40px;
            background-color: $color-white;
            padding: 0 16px;
            font-family: $mulish-medium;
            font-size: large;
            color: $color-black;
            position: absolute;
        }
    }

    .network-section {
        margin-bottom: 30px;

        .form-container {
            .form-group.status {
                margin-bottom: 15px;
            }
        }
    }

    .network-label {
        font-family: $mulish-bold;

        img {
            margin-left: 16px;
            margin-top: -2px;
            width: 18px;
            height: 18px;
            cursor: pointer;

            &:hover {
                opacity: 0.8;
            }
        }
    }

    .btn-add {
        color: $color-caribbean-green;
        border-radius: 10px;
        font-weight: 700;
        height: 32px;
        font-family: $mulish-medium;
        border: none;
        font-size: medium;
        background: none;
        transition: all linear 0.3s;
        padding: 0 16px;
        margin-left: 30px;

        &:hover {
            background-color: $color_grey_head;
        }

        .icon-plus {
            color: $color-caribbean-green;
            margin-right: 8px;
        }
    }
}
</style>


<style lang="scss">
@import "@/assets/sass/variables";

#coinEdit_page {
    .multi-select-dropdown {
        border-radius: 10px;
        border: 1px solid $color-bright-gray;

        &.error {
            border: 1px solid $color-red-main;
        }

        .selected-items {
            height: 38.84px;
            gap: 8px;
            border: none;

            .selected-item {
                margin: 0;
            }

            input {
                display: none;
            }
        }
    }

    .sc_search_select {
        border-radius: 10px;
        border: 1px solid $color-bright-gray;

        &.error {
            border: 1px solid $color-red-main;
        }

        .group_search_select {
            .button_drop_search {
                height: 38.84px;
                padding: 10px 16px;
                border-radius: 10px;
                font-family: $mulish-medium;
                background-color: $color-white;
                border: none;

                span {
                    font-family: $mulish-medium;
                    font-size: medium;
                }

                i.icon-arrow1 {
                    color: $color_dove_gray;
                    font-size: 14px;
                }
            }
        }

        .box_list_search_select {
            .list_search_select {
                border-radius: 10px;

                li {
                    font-family: $font-family-roboto;
                    font-size: $font-small;
                    padding: 6px 12px;

                    &:first-child {
                        display: none;
                    }

                    &:hover,
                    &:active,
                    &:focus {
                        color: $color-jungle-green;
                    }
                }
            }
        }
    }

    .modal {
        .body-popup {
            font-family: $mulish-medium;

            p,
            strong {
                font-family: $mulish-medium;
            }
        }

        .btn-no {
            background-color: $color-bright-gray;
            border-color: $color-bright-gray;
            color: $color-black;

            &:hover {
                opacity: 0.7;
            }
        }
    }
}
</style>