<template>
    <div class="networkEdit_page" id="networkEdit_page">
        <div class="form-container">
            <div v-if="$route.query.id" class="form-group status">
                <label class="label-text" for="explorer-url">{{ $t('resource.networks.active_status') }}</label>
                <checkbox :checked="dataNetwork.enable === 1"
                    :handle-checked="value => dataNetwork.enable = Number(value)"></checkbox>
            </div>

            <div class="form-group w-50">
                <label class="label-text" for="name">{{ $t('resource.networks.name') }}</label>
                <input @focus="resetErrors" :maxlength="maxLength" :class="{ 'error': errors.has('name') }" type="text"
                    id="name" :placeholder="$t('resource.networks.enter_name')" v-model="dataNetwork.name">
                <div v-show="errors.has('name')" class="invalid-feedback">{{ errors.first('name') }}</div>
            </div>

            <div class="form-group w-50">
                <label class="label-text" for="symbol">{{ $t('resource.networks.symbol') }}</label>
                <input @focus="resetErrors" :maxlength="maxLength" :class="{ 'error': errors.has('symbol') }"
                    type="text" id="symbol" :placeholder="$t('resource.networks.enter_symbol')"
                    v-model="dataNetwork.symbol" :disabled="$route.query.id">
                <div v-show="errors.has('symbol')" class="invalid-feedback">{{ errors.first('symbol') }}</div>
            </div>

            <div class="form-group w-50">
                <label class="label-text" for="network_code">{{ $t('resource.networks.code') }}</label>
                <input @focus="resetErrors" :maxlength="maxLength" :class="{ 'error': errors.has('network_code') }" type="text"
                    id="network_code" :placeholder="$t('resource.networks.enter_code')" v-model="dataNetwork.network_code">
                <div v-show="errors.has('network_code')" class="invalid-feedback">{{ errors.first('network_code') }}</div>
            </div>

            <div class="form-group w-50">
                <label class="label-text" for="chain-id">{{ $t('resource.networks.chain_id') }}</label>
                <input @focus="resetErrors" :maxlength="maxLength" :class="{ 'error': errors.has('chain_id') }"
                    type="text" id="chain-id" :placeholder="$t('resource.networks.enter_chain_id')"
                    v-model="dataNetwork.chain_id">
                <div v-show="errors.has('chain_id')" class="invalid-feedback">{{ errors.first('chain_id') }}</div>
            </div>

            <div class="form-group w-50">
                <label class="label-text" for="currency">{{ $t('resource.networks.currency') }}</label>
                <input @focus="resetErrors" :maxlength="maxLength" :class="{ 'error': errors.has('currency') }"
                    type="text" id="currency" :placeholder="$t('resource.networks.enter_currency')"
                    v-model="dataNetwork.currency">
                <div v-show="errors.has('currency')" class="invalid-feedback">{{ errors.first('currency') }}</div>
            </div>

            <div class="form-group w-50">
                <label class="label-text" for="deposit-confirmation">{{ $t('resource.networks.deposit_confirmation')
                    }}</label>
                <input @focus="resetErrors" :maxlength="maxLength" :class="{ 'error': errors.has('deposit_confirmation') }"
                    type="text" id="deposit-confirmation"
                    :placeholder="$t('resource.networks.enter_deposit_confirmation')"
                    v-model="dataNetwork.deposit_confirmation">
                <div v-show="errors.has('deposit_confirmation')" class="invalid-feedback">{{ errors.first('deposit_confirmation')
                    }}</div>
            </div>

            <div class="form-group w-100">
                <label class="label-text" for="explorer-url">{{ $t('resource.networks.explorer_url') }}</label>
                <input @focus="resetErrors" :maxlength="maxLength" :class="{ 'error': errors.has('explorer_url') }"
                    type="text" id="explorer-url" :placeholder="$t('resource.networks.enter_explorer_url')"
                    v-model="dataNetwork.explorer_url">
                <div v-show="errors.has('explorer_url')" class="invalid-feedback">{{ errors.first('explorer_url') }}
                </div>
            </div>

            <div class="form-group status">
                <label class="label-text" for="explorer-url">{{ $t('resource.networks.allow_deposit') }}</label>
                <checkbox :checked.sync="dataNetwork.network_deposit_enable === 1"
                    :handle-checked="value => dataNetwork.network_deposit_enable = Number(value)"></checkbox>
            </div>

            <div class="form-group status">
                <label class="label-text" for="explorer-url">{{ $t('resource.networks.allow_withdraw') }}</label>
                <checkbox :checked.sync="dataNetwork.network_withdraw_enable === 1"
                    :handle-checked="value => dataNetwork.network_withdraw_enable = Number(value)"></checkbox>
            </div>

            <div class="form-group action">
                <button class="btn btn-cancel" @click="handleBack()">{{ $t('resource.networks.btn_cancel')
                    }}</button>
                <button v-if="$route.query.id" class="btn btn-update" @click="updateNetwork()">{{
                    $t('resource.networks.btn_update')
                }}</button>
                <button v-else class="btn btn-update" @click="createNetwork()">{{ $t('resource.networks.btn_create')
                    }}</button>
            </div>
        </div>
        <modal class="updateNetwork_modal" name="updateNetwork" title="CONFIRM">
            <div v-if="$route.query.id" slot="body" class="body-popup">
                {{ $t('resource.networks.popup_update_confirm') }}
            </div>
            <div v-else slot="body" class="body-popup">
                {{ $t('resource.networks.popup_create_confirm') }}
            </div>
        </modal>
        <modal class="kyc_modal" :enableClose="enableClose" name="loadingUpload">
            <div slot="body">
                <vue-loading type="bars" v-if="isLoading" color="#2DAC91" :size="{ width: '60px', height: '80px' }">
                </vue-loading>
            </div>
        </modal>
    </div>
</template>

<script>
import rf from "../../lib/RequestFactory";
import Modal from "../../components/Modal";
import Checkbox from "../../components/Checkbox.vue";
import CONST from '../../common/Const';

export default {
    name: "NetworkEdit",
    components: {
        Modal,
        Checkbox
    },
    data() {
        return {
            titlePage: this.$route.query.id ? window.i18n.t('resource.networks.title_edit') : window.i18n.t('resource.networks.title_create'),
            dataNetwork: {
                symbol: "",
                name: "",
                currency: "",
                network_code: "",
                chain_id: "",
                network_deposit_enable: 0,
                network_withdraw_enable: 0,
                deposit_confirmation: "",
                explorer_url: "",
            },
            maxLength: CONST.MAX_LENGTH_INPUT,
            isLoading: false,
            enableClose: false
        }
    },
    methods: {
        validate() {
            this.errors.clear();
            if (!this.dataNetwork.name) {
                this.errors.add({ field: 'name', msg: this.$t('resource.networks.error_name_field') });
            }
            if (!this.dataNetwork.symbol) {
                this.errors.add({ field: 'symbol', msg: this.$t('resource.networks.error_symbol_field') });
            }
            if (!this.dataNetwork.network_code) {
                this.errors.add({ field: 'network_code', msg: this.$t('resource.networks.error_code_field') });
            }
            if (this.dataNetwork.chain_id.toString() === '') {
                this.errors.add({ field: 'chain_id', msg: this.$t('resource.networks.error_chain_id_field') });
            }
            if (!this.dataNetwork.currency) {
                this.errors.add({ field: 'currency', msg: this.$t('resource.networks.error_currency_field') });
            }
            if (!this.dataNetwork.deposit_confirmation) {
                this.errors.add({ field: 'deposit_confirmation', msg: this.$t('resource.networks.error_deposit_confirmation_field') });
            }
            if (!this.dataNetwork.explorer_url) {
                this.errors.add({ field: 'explorer_url', msg: this.$t('resource.networks.error_explorer_url_field') });
            }
            if (this.errors.count() > 0) {
                return false;
            }
            return true;
        },
        updateNetwork() {
            if (!this.validate()) return;
            this.showModalConfirm('updateNetwork', this.submitUpdateNetwork);
        },
        createNetwork() {
            if (!this.validate()) return;
            this.showModalConfirm('updateNetwork', this.submitCreateNetwork);
        },
        showModalConfirm(modalName, callback, params) {
            CommonModal.show(modalName, {
                position: 'center',
                mask: true,
                buttons: [
                    {
                        label: window.i18n.t('common.action.no'),
                        class: "btn-no",
                        style: {
                            background: 'rgb(191, 191, 191);'
                        },
                        callback: () => {
                            CommonModal.hide(modalName);
                        }
                    },
                    {
                        label: window.i18n.t('common.action.yes'),
                        focused: true,
                        class: "btn-yes",
                        style: {
                            background: 'rgb(0, 112, 192)'
                        },
                        callback: () => {
                            CommonModal.hide(modalName);
                            this.loadingUploadBegin();
                            callback(params);
                            this.loadingUploadFinish();
                        }
                    }
                ]
            });
        },
        submitUpdateNetwork() {
            const params = this.dataNetwork;
            rf.getRequest('MultiChainRequest').updateNetwork(params).then(res => {
                if (res.success) {
                    this.$toastr('success', this.$t('resource.networks.toast_update_success'));
                    this.handleBack();
                }
            }).catch(error => {
                this.$toastr('error', this.$t('resource.networks.toast_update_fail'));
                console.error(error);
            });
        },
        submitCreateNetwork() {
            const params = {
                ...this.dataNetwork,
                enable: 1
            }
            rf.getRequest('MultiChainRequest').createNetwork(params).then(res => {
                if (res.success) {
                    this.$toastr('success', this.$t('resource.networks.toast_create_success'));
                    this.handleBack();
                }
            }).catch(error => {
                this.$toastr('error', this.$t('resource.networks.toast_create_fail'));
                console.error(error);
            });
        },
        loadingUploadBegin() {
            this.enableClose = false;
            this.isLoading = true;
            CommonModal.show('loadingUpload');
        },
        loadingUploadFinish() {
            CommonModal.hide('loadingUpload');
        },
        getDataNetworkDetail() {
            rf.getRequest('MultiChainRequest').getNetworkDetail(this.$route.query.id)
                .then((res) => {
                    this.dataNetwork = {
                    ...res.data,
                    enable: res.data.enable,
                    network_deposit_enable: res.data.network_deposit_enable,
                    network_withdraw_enable: res.data.network_withdraw_enable
                }})
        },
        handleBack() {
            this.$router.push({
                name: "Networks",
                params: { 
                    params: {
                        currency: this.$route.params.currency
                    }, 
                    pagination: this.$route.params.pagination 
                }
            })
        }
    },
    created() {

    },
    mounted() {
        this.$emit('EVENT_PAGE_CHANGE', this);
        if (this.$route.query.id) this.getDataNetworkDetail();
    },
}

</script>

<style lang="scss" scoped>
@import "@/assets/sass/variables";

.networkEdit_page {
    background-color: $color-white;
    border-radius: 10px;
    padding: 30px 40px;
    margin-top: 30px;

    .form-container {
        display: flex;
        flex-wrap: wrap;
        gap: 10px 20px;
        max-width: 800px;
    }

    .form-group {
        display: flex;
        flex-direction: column;
        width: 100%;

        &.w-50 {
            height: 87px;
            margin-bottom: 0 !important;
            width: calc(780px / 2);
        }

        &.w-100 {
            width: 100%;
            height: 87px;
            margin-bottom: 0 !important;
        }

        &.status {
            border-radius: 10px;
            margin-top: 10px;
            padding: 16px;
            border: 1px solid $color-bright-gray;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            .label-text {
                margin: 0;
                font-family: $mulish-medium;
                font-size: 16px;
            }
        }

        &.action {
            display: block;
            margin-top: 32px;

            .btn {
                background: $color-caribbean-green;
                border-radius: 10px;
                font-weight: 700;
                height: 36px;
                font-family: $mulish-medium;
                margin-right: 20px;
                width: 120px;

                &.btn-cancel {
                    background: $grey-3;
                }
            }
        }
    }

    .label-text {
        font-size: $font-root;
        color: $color-grey-dusty;
        font-family: $mulish-regular;
        font-weight: 400;
        margin-bottom: 7px;
        padding-left: 5px;
    }

    input[type=text] {
        padding: 8px 16px;
        border: 1px solid $color-bright-gray !important;
        border-radius: 4px;
        font-size: 16px;
        border-radius: 10px !important;
        font-family: $mulish-medium;
        box-shadow: none !important;

        &.error {
            border: solid 1px $color-red-main !important;
        }

        &:disabled {
            background-color: $color_grey_body !important;
        }
    }

    .invalid-feedback {
        color: $color-red-main;
        font-size: 14px;
    }
}
</style>

<style lang="scss">
@import "@/assets/sass/variables";

.modal-content .modal-footer {
    text-align: center;
    margin-top: 30px;
}

#networkEdit_page {
    font-family: $font_family_root;

    .modal-dialog {
        min-width: 400px;
        position: relative;
        top: -10%;
    }

    .modal-content {
        text-align: center;

        .modal-footer {
            text-align: center;
            margin-top: 30px;
        }

        .modal-header {
            padding: 30px 16px 16px 20px;

            .modal-title {
                font-family: Roboto-Medium;
                font-size: 18px;
                font-weight: 500;
                color: $color-grey-dark;
                text-transform: none;
            }
        }

        .modal-body {
            padding: 0;
        }

        .btn-no {
            background-color: $color-bright-gray;
            border-color: $color-bright-gray;
            color: $color-black;

            &:hover {
                opacity: 0.7;
            }
        }
    }
}
</style>