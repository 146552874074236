<template>
  <div class="group_setting_pages boxCore clearfix mg_bot50">
    <div class="list-group">
      <div class="filter-container">
      <div class="filter-item date">
        <div class="text-pair">{{ $t('group_setting.date') }}:</div>
        <date-picker v-model="startDate" class="date-picker-input no-right-radius" format="yyyy-MM-dd"></date-picker>
      </div>
      <div class="filter-item date">
        <div class="text-pair">&nbsp;</div>
        <date-picker v-model="endDate" class="date-picker-input no-left-radius" format="yyyy-MM-dd"></date-picker>
      </div>
      <div class="filter-item">
        <input title="" type="text" :placeholder="$t('group_setting.search_by_name')" @keyup.enter="search"
               class="form-control" name="searchKey" v-model="filters.searchKey"/>
      </div>
      <div class="filter-item">
        <button class="btn btn-primary" @click="search()">{{ $t('group_setting.search') }}</button>
        <button class="btn btn-reset" @click="refresh()">{{ $t('group_setting.reset') }}</button>
      </div>
      <div class="filter-item right">
        <button class="btn btn-success" @click="addNew()">
            <span class="glyphicon glyphicon-plus"></span>  {{ $t('group_setting.add_new') }}
        </button>
      </div>
    </div>
    </div>
    <div class="datatable" style="width: 100%">
      <data-table :getData="getListGroup" ref="list_group" :column="4" class="scroll" :storageName="'objTableUserGroupSetting'">
        <th class="text-left width-name" width="30%" data-sort-field="name">{{ $t('group_setting.name') }}</th>
        <th class="text-left width-memo" width="35%" data-sort-field="memo">{{ $t('group_setting.memo') }}</th>
        <th class="text-left" width="20%" data-sort-field="created_at">{{ $t('group_setting.date') }}</th>
        <th class="text-left" width="15%"></th>

        <template slot="body" slot-scope="props">
          <tr>
            <td class="text-left width-name">
              <div class="over-flow-text" :title="props.item.name">{{ props.item.name }}</div>
            </td>
            <td class="text-left width-memo">
              <div class="over-flow-text" :title="props.item.memo">{{ props.item.memo }}</div>
            </td>
            <td class="text-left" >
              {{ props.item.created_at }}
            </td>
            <td class="text-center">
              <span class="btnForm bt-action btn-icon icon-edit" @click="editGroup(props.item)"></span>
              <span class="btnForm bt-delete btn-icon icon-close2" @click="deleteGroup(props.item.id)"></span>
            </td>
          </tr>
        </template>
      </data-table>
    </div>
    <modal name="detailGroup" width="590" id="modal_add_group" :title="titleDetail">
      <div slot="body" class="body-popup">
        <div class="form-group-detail">
          <div class="group-detail-form-group">
            <label class="group-detail-form-label">{{ $t('group_setting.name') }}</label>
            <input class="group-detail-form-value" maxlength="190" id="input-title" type="text" v-model="detailData.name" :class="{'input-error': errors.has('name')}" />
            <div class="clearifx"></div>
            <div class="error-box">
              <span v-show="errors.has('name')" class="f-left is-error">{{ errors.first('name') }}</span>
            </div>
          </div>
          <div class="group-detail-form-group">
            <label class="group-detail-form-label">{{ $t('group_setting.memo') }}</label>
            <textarea class="group-detail-form-value textarea_input" maxlength="190" id="input-title" type="text" v-model="detailData.memo" :class="{'input-error': errors.has('memo')}" />
            <div class="clearifx"></div>
            <div class="error-box">
              <span v-show="errors.has('memo')" class="f-left is-error">{{ errors.first('memo') }}</span>
            </div>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
  import rf from '../../lib/RequestFactory';
  import Modal from '../../components/Modal';
  import DatePicker from 'vuejs-datepicker';
  import moment from 'moment';

  export default {
    components: {
      DatePicker,
      Modal,
    },
    data() {
      let data = {
        titlePage: window.i18n.t('menu.user_group_setting'),
        startDate: moment().toDate(),
        endDate: moment().add(6, 'days').toDate(),
        filters: {
          searchKey: '',
        },
        titleDetail: this.$t('group_setting.add_new'),
        detailData: {
          id: null,
          name: null,
          memo: null
        }
      }

      if (localStorage.getItem('objTableUserGroupSetting') !== null) {
        try {
          let storageData = JSON.parse(localStorage.getItem('objTableUserGroupSetting'));

          data.startDate = storageData.startDate;
          data.endDate = storageData.endDate;
          data.filters.searchKey = storageData.searchKey;
        }
        catch (ex) {
          console.error(ex);
        }
      }
      else {
        let storageData = {
          startDate: data.startDate,
          endDate: data.endDate,
          searchKey: data.filters.searchKey,
          page: 1
        }
        localStorage.setItem('objTableUserGroupSetting', JSON.stringify(storageData));
      }

      return data;
    },
    
    methods: {
      search() {
        let filterParams = this.getRangeDate();
        if(this.startDate > this.endDate) {
          this.$toastr('error', this.$t('common.date.warning'));
          return;
        }
        if (this.filters.searchKey) {
          filterParams.search_key = this.filters.searchKey;
        }
        this.saveFilterParam();
        this.$refs.list_group.filter(filterParams);
      },

      refresh() {
        this.resetInput();
        this.search();
      },

      resetInput() {
        this.filters = Object.assign({searchKey: ''});
        this.startDate = moment().subtract(1, 'week').toDate();
        this.endDate = moment().toDate();
      },

      getListGroup(params) {
        const defaultParams = this.getRangeDate();

        // try {
        //   let storageData = JSON.parse(localStorage.getItem('objTableUserGroupSetting'));
        //   params.search_key = storageData.searchKey;
        //   params.page = storageData.page;
        //   params.limit = storageData.limit;
        // }
        // catch(ex) {
        //   console.log(ex);
        // }
        return rf.getRequest('AdminRequest').getListUserGroupSetting(Object.assign(defaultParams, params));
      },

      getRangeDate() {
        let defaultParams = {};
        if(this.startDate) {
          defaultParams.start_date = moment(this.startDate).format('YYYY-MM-DD');
        }
        if(this.endDate) {
          defaultParams.end_date = moment(this.endDate).format('YYYY-MM-DD');
        }
        return defaultParams;
      },

      saveFilterParam() {
        try {
          let storageData = JSON.parse(localStorage.getItem('objTableUserGroupSetting'));
          storageData.startDate = this.startDate;
          storageData.endDate = this.endDate;
          storageData.searchKey = this.filters.searchKey;
          storageData.page = 1;
          localStorage.setItem('objTableUserGroupSetting', JSON.stringify(storageData));
        }
        catch (ex) {
          console.error(ex);
        }
      },

      deleteGroup(row) {
        ConfirmationModal.show({
          type: 'confirm',
          title: this.$t('group_setting.delete_data'),
          content         : '',
          btnCancelLabel  : this.$t('common.action.no'),
          btnConfirmLabel : this.$t('common.action.yes'),
          size            : 'small',
          onConfirm       : () => {
            return rf.getRequest('AdminRequest').deleteGroupSetting(row).then(res => {
              this.$toastr('success', this.$t('common.alert.delete.success'));
              this.search();
            });
          },
          onCancel        : () => {}
        });
      },

      editGroup(row) {
        this.detailData = {
          id: row.id,
          name: row.name,
          memo: row.memo
        }
        this.titleDetail = this.$t('group_setting.edit');
        this.openPopupDetail();
      },

      addNew() {
        this.detailData = {
          id: null,
          name: null,
          memo: null
        }
        this.titleDetail = this.$t('group_setting.add_new');
        this.openPopupDetail();
      },

      openPopupDetail() {
        this.errors.clear();
        CommonModal.show("detailGroup", {
          position: "group-setting-detail",
          mask: true,
          buttons: [
            {
              label: this.$t('common.action.save'),
              focused: true,
              style: {
                width: "140px",
                background: "rgb(0, 112, 192)"
              },
              callback: () => {
                if (this.detailData.id === null) {
                  if(!this.detailData.name) {	
                    this.errors.add({ field: 'name', msg: this.$t('site_setting.social_network.errors.required.name') });
                    return;	
                  }
                  rf.getRequest('AdminRequest').addGroupSetting(this.detailData).then(res => {
                    this.$toastr('success', window.i18n.t('common.alert.create.success'));
                    CommonModal.hide("detailGroup");
                    this.search();
                  }).catch(error=>{
                    if (error.response.data.errors) {
                      window._.each(error.response.data.errors, (value, key) => {
                        this.errors.add({ field: key, msg: value[0] });
                      });
                    }
                    else {
                      this.$toastr('error', error.response.data.message);
                    }
                  });
                }
                else {
                  if(!this.detailData.name) {	
                    this.errors.add({ field: 'name', msg: this.$t('site_setting.social_network.errors.required.name') });
                    return;	
                  }
                  rf.getRequest('AdminRequest').updateGroupSetting(this.detailData).then(res => {
                    this.$toastr('success', window.i18n.t('common.alert.update.success'));
                    CommonModal.hide("detailGroup");
                    this.search();
                  }).catch(error=>{
                    if (error.response.data.errors) {
                      window._.each(error.response.data.errors, (value, key) => {
                        this.errors.add({ field: key, msg: value[0] });
                      });
                    }
                    else {
                      this.$toastr('error', error.response.data.message);
                    }
                  });
                }
              }
            },
            {
              label: this.$t('common.action.cancel'),
              focused: true,
              style: {
                width: "140px",
                background: "rgb(0, 112, 192)"
              },
              callback: () => {
                CommonModal.hide("detailGroup");
              }
            }
          ]
        });
      }
    },

    mounted() {
      this.$emit('EVENT_PAGE_CHANGE', this);
    }
  }
</script>

<style lang="scss">
@import "@/assets/sass/variables";
  .date-picker-input {
    display: inline-block;
    float: left;
    input {
      padding: 0 10px 0px 30px;
      width: 120px !important;
      border-radius: 3px;
      -webkit-box-shadow: none;
      box-shadow: none;
      height: 30px;
      line-height: 30px;
      border: 1px solid #d2d6de;
      background-image: url('@/assets/images/icon/date-time.svg') !important;
      background-color: $color-white-smoke !important;
      background-size: 16px;
      background-position: center left 10px;
      background-repeat: no-repeat !important;
      color: $color-grey-dusty;
      font-size: $font-small;
      font-family: "Roboto-Regular", sans-serif;
      &:hover, &:active, &:focus {
        border-color: $color-jungle-green;
        z-index: 9;
      }
    }
  }
  .no-left-radius {
    input {
      border-radius: 0px 3px 3px 0px;
      margin-left: -1px;
    }
  }
  .no-right-radius {
    input {
      border-radius: 3px 0px 0px 3px;
    }
  }
  #modal_add_group {
    .modal-dialog {
      min-height: 330px;
    }
    .modal-content {
      height: auto;
      .modal-body {
        padding: 0px 70px 15px 70px;
      }
      .modal-footer {
        padding-bottom: 40px;
      }
    }
    .modal-title {
      text-align: center;
      margin-top: 40px;
      font-family: $font-family-roboto-medium;
    }
  }
</style>

<style lang="scss" scoped>
@import "@/assets/sass/variables";
  .group_setting_pages {
    
    .list-group {
      width: 100%;
      display: block;
    }
    .filter-container {
      margin: 10px 0px 30px 0px;
      float: left;
      width: 100%;
      .filter-item {
        float: left;
        input {
          display: inline-block;
          margin-top: 23px;
          margin-left: 25px;
          height: 30px;
          &:hover, &:active, &:focus {
            border-color: $color-jungle-green;
          }
        }
        .form-control {
          width: 145px;
          background: $color-white-smoke;
          font-family: $font-family-roboto;
        }
        button {
          margin: 0 5px;
          width: auto;
          margin-top: 23px;
          margin-right: 0;
          margin-left: 5px;
          font-family: $font-family-roboto;
        }
        .btn-reset {
          text-transform: uppercase;
          height: 30px;
          padding: 0 20px;
          font-size: $font-smaller;
          background-color: $color-caribbean-green;
          border: 1px solid $color-caribbean-green;
          width: 85px;
          color: $color-white;
          font-weight: 600;
          &:hover {
            border-color: $color-aquamarine;
            background-color: $color-aquamarine;
          }
        }
        .btn-primary {
          padding: 0;
          height: 30px;
          margin-left: 10px;
          font-size: $font-smaller;
          min-width: 85px;
          background-color: $color-caribbean-green;
          border: 1px solid $color-caribbean-green;
          &:hover {
            border-color: $color-aquamarine;
            background-color: $color-aquamarine;
          }
        }
        .btn-success {
          background-color: $color-caribbean-green;
          height: 30px;
          line-height: 30px;
          padding: 0 15px;
          margin-left: 15px;
          border: 0px;
          text-transform: uppercase;
          font-size: $font-smaller;
          font-family: $font-family-roboto;
          font-weight: 600;
          &:hover {
            border-color: $color-aquamarine;
            background-color: $color-aquamarine;
          }
        }
        a {
          margin-top: 23px;
        }
        .text-pair {
          color: $color-grey-dusty;
          margin-bottom: 5px;
          font-family: $font-family-roboto;
          font-size: $font-small;
        }
      }
      .right {
        float: right;
      }
    }

    .search_input {
      width: 250px;
      display: inline-block;
      margin: 0px 10px;
    }

    .datatable{
      table {
        td {
          span {
            &.btn-icon {
              cursor: pointer;
              color: $color-jungle-green;
              padding: 0;
              line-height: 20px;
              &:hover{
                color: $color-blue-custom;
              };
            }
          }
        }
        .icon-close2 {
          margin: 0 10px;
          font-size: $font_semi_big;
        }
        .icon-edit {
          font-size: $font_semi_big;
        }
      }
      .width-name {
        .over-flow-text {
          max-width: 260px;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .width-memo {
        .over-flow-text {
          max-width: 350px;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    .form-group-detail {
      .group-detail-form-group {
        .group-detail-form-label{
          width: 100%;
          color: $color-grey-dusty;
          float: left;
          font-weight: normal;
          font-family: $font-family-roboto;
          margin-bottom: 0px;
          margin-top: 10px;
        }

        .group-detail-form-value {
          width: 100%;
          padding: 8px 16px;
          border: 1px solid #dee3eb;
          height: 35px;
          line-height: 35px;
          border-radius: 3px;
          font-family: $font-family-roboto;
          // font-weight: 600;
          &:focus {
            border-color: $color-jungle-green;
          }
        }
        .textarea_input {
          height: 120px;
        }
      }
      .error-box {
        display: block;
        float: left;
        overflow: hidden;
        width: 100%;
        span {
          font-family: $font-family-roboto-medium !important;
        }
      }
      .is-error {
        color: #ff5252 !important;
      }
      .input-error {
        border: 1px solid #f74940 !important;
      }
    }
  }
</style>