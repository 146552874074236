<template>
  <div class="user-information mg_bot50" id="userInformation">
    <div class="filter-container">
      <div class="filter-item date">
        <div class='title-text'>{{ $t('orders.open_order.date') }}:</div>
        <date-picker v-model="startDate" class="date-picker-input no-right-radius" format="yyyy - MM - dd"></date-picker>
        <date-picker v-model="endDate" class="date-picker-input no-left-radius" format="yyyy - MM - dd"></date-picker>
      </div>

      <div class="filter-item pair">
        <div class="label-text title-text text-pair">{{ $t('user.type') }}:</div>
        <div class="toolbar-element2">
          <select-box
            v-model="filters.type"
            :placeholder="$t('order.order_history.all')"
            :options="getTypes()"
            class="selectbox100"
          />
        </div>
      </div>

      <div class="filter-item pair">
        <div class="label-text title-text text-pair">{{ $t('user.status') }}:</div>
        <div class="toolbar-element2">
          <select-box
            v-model="filters.status"
            :placeholder="$t('order.order_history.all')"
            :options="getStatus()"
            class="selectbox100"
          />
        </div>
      </div>

      <!-- <div class="filter-item pair">
        <div class="label-text title-text text-pair">{{ $t('user.group') }}:</div>
        <div class="toolbar-element2">
          <select-box
            v-model="filters.group"
            :placeholder="$t('order.order_history.all')"
            :options="groupSetting"
            class="selectbox100"
          />
        </div>
      </div> -->

      <div class="filter-item">
        <input
          title
          type="text"
          :placeholder="$t('orders.open_order.search_by_email')"
          @keyup.enter="search"
          class="form-control"
          name="searchKey"
          v-model="filters.searchKey"
        >
      </div>

      <div class="filter-item">
        <button class="btn btn-search" @click="search()">{{ $t('orders.open_order.search') }}</button>
        <button class="btn btn-reset" @click="refresh()">{{ $t('orders.open_order.reset') }}</button>
      </div>
    </div>

    <!-- <div class="filter-container tool-button">
      <div class="filter-item">
        <button class="btn btn-search" @click="addToGroup()">
          <span class="glyphicon glyphicon-plus"></span>{{ $t('user.action.add_to_group') }}
        </button>
        <button class="btn btn-remove" @click="removeFromGroup()">{{ $t('user.action.delete_from_group') }}</button>

      </div>
    </div> -->

    <div class="datatable">
      <data-table :getData="getData" :totalUser="numberOfUser" ref="datatable" :limit="10" :column="8" @DataTable:finish="onDatatableFinish" class="scroll">
        <!-- <th class="text-center non-sort" width="5%">
          <input type="checkbox" :checked="(lstChecked.split('--').length === datatableRows.length) && lstChecked !== ''" @click="chkAllClick($event.target.checked)" />
        </th> -->
        <th class="text-left" width="8%" data-sort-field="created_at">{{ $t('user.date') }}</th>
        <th class="text-left" width="25%" data-sort-field="email">{{ $t('user.email') }}</th>
        <th class="text-left" width="12%" data-sort-field="security_level">{{ $t('user.security_level') }}</th>
        <!-- <th class="text-left" width="7%" data-sort-field="group_count">{{ $t('user.group') }}</th> -->
        <th class="text-left" width="7%" data-sort-field="type">{{ $t('user.type') }}</th>
        <th class="text-left" width="8%" data-sort-field="status">{{ $t('user.status') }}</th>
<!--        <th class="text-left" data-sort-field="memo">{{ $t('user.memo') }}</th>-->
        <th class="text-right" width="5%"></th>

        <template slot="body" slot-scope="props">
          <tr v-bind:class="{inactive: props.item.status == 'inactive'}">
            <!-- <td class="text-center">
              <input type="checkbox" :checked="lstChecked.indexOf('-' + props.item.id + '-') >= 0" @click="checkItemClick(props.item, $event.target.checked)" />
            </td> -->
            <td class="text-left">{{ props.item.created_at | formatDate }}</td>
            <td class="text-left">
              <div class="item_email_user">
                <span class="txt_email_user">{{ props.item.email }}</span>
                <span class="tooltip_email_user">{{ props.item.email }}</span>
              </div>
            </td>
            <td class="text-left">
              <span v-if="props.item.editable">
                <select-box :options="userLevelList" class="box_select_user" v-model="props.item.security_level"/>
              </span>
              <span v-else>{{ props.item.security_level }}</span>
            </td>
            <!-- <td class="text-left">
              <div class="item_email_user">
                {{ props.item.group_count }}
                <span v-if ="props.item.group_count != 0" class="tooltip_email_user tooltip_group_user">{{ props.item.group_name }}</span>
              </div>
            </td> -->
            <td class="text-left">
              <span v-if="props.item.editable">
                <select-box :options="userTypeList" class="box_select_user" v-model="props.item.type"/>
              </span>
              <span v-else>{{ props.item.type | labelUserType }}</span>
            </td>
            <td class="text-left">
              <span v-if="props.item.editable">
                <select-box :options="userStatusList" class="box_select_user" v-model="props.item.status"/>
              </span>
              <span v-else>{{ props.item.status | statusLabel }}</span>
            </td>
<!--            <td class="text-left">-->
<!--              <span v-if="props.item.editable">-->
<!--                <input class="form-control" v-model="props.item.memo" maxlength="190" />-->
<!--              </span>-->
<!--              <span v-else>{{ props.item.memo }}</span>-->
<!--            </td>-->
            <td class="text-right">
              <button type="button" v-if="props.item.editable" @click="updateUserInformation(props.item)" class="btn btn_save_user" :disabled="props.item.submitting">
                <i class="icon-save"></i>
              </button>
              <button type="button" v-else @click="props.item.editable = !props.item.editable" class="btn btn_edit_user">
                <i class="icon-edit"></i>
              </button>
            </td>
          </tr>
        </template>
      </data-table>
    </div>

    <modal name="addGroup" width="590">
      <div slot="body" class="body-popup">
        <div class="text-center group-head">{{ $t('user.action.add_to_group') }}</div>
        <div class="group-list">
          <div class="group-item" v-for="(item, index) in groupSetting" :key="index">
            <input type="checkbox" :id="'chk_' + item.id" :checked="lstCheckedAdd.indexOf('-' + item.id + '-') >= 0" @change="onChangeCheckbox(item, $event.target.checked)" />
            <label :for="'chk_' + item.id">{{item.name}}</label>
          </div>
        </div>
      </div>
    </modal>

    <modal name="removeGroup" width="590">
      <div slot="body" class="body-popup">
        <div class="text-center group-head">{{ $t('user.action.delete_from_group') }}</div>
        <div id="select-box-user-modal">
          <select-box
            v-model="groupRemove"
            :placeholder="$t('common.chose_select')"
            :options="groupSetting"
            class="select-box-full"
          />
        </div>
        <div class="group-list user-list">
          <div class="group-item group-item-del" v-for="(item, index) in userGroup" :key="index">
            <input type="checkbox" :id="'chk_' + item.group_id + '_' + item.user_id" @change="onChangeRemove(item, $event.target.checked)" />
            <span class="checkmark_box"></span>
            <label :for="'chk_' + item.group_id + '_' + item.user_id">{{item.user_email}}</label>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
  import rf from '../../lib/RequestFactory';
  import InputOnlyNumber from '../../common/InputOnlyNumber';
  import SelectBox from '../../components/SelectBox';
  import Modal from "../../components/Modal";
  import DatePicker from "vuejs-datepicker";
  import moment from "moment";
  import COMMON_CONST from "../../common/Const";

  export default {
    components: {
      InputOnlyNumber,
      Modal,
      DatePicker,
      SelectBox
    },
    data() {
      return {
        titlePage: this.$t('user.user_information'),
        searchKey: '',
        userTypeList: [
            this.$t('user.normal'),
            this.$t('user.bot'),
            // this.$t('user.referrer'),
        ],
        userStatusList: [
            this.$t('user.inactive'),
            this.$t('user.active'),
        ],
        maxSecurityLevels: [1, 2, 3, 4, 5],
        params: {},

        startDate: moment()
        .subtract(6, "day")
        .toDate(),
        endDate: new Date(),
        coin: "",
        filters: {
          type: "",
          status: "",
          group: "",
          searchKey: "",
        },
        userLevelList: [1, 2, 3, 4],
        groupSetting: [],
        lstChecked: '',
        datatableRows: [],
        lstCheckedAdd: '',
        groupRemove: '',
        userGroup: [],
        lstCheckedRemove: '',
        numberOfUser: 0,
      }
    },
    watch: {
      groupRemove() {
        if (this.groupRemove === '') {
          this.userGroup = [];
          return;
        }
        let params = {
          group: this.groupRemove.id,
          page: 1,
          limit: 1000000
        }
        rf.getRequest('AdminRequest').getListUserGroup(params).then(res => {
          this.userGroup = res.data.data;
        }).catch(error=>{
          console.error(error);
        });
      },
    },
    filters: {
      formatDate: function (val) {
          return val ? val.substring(0, 10) : '';
      },
      statusLabel: function (val) {
          if(val === 'inactive') return window.i18n.t('user.inactive');
          return window.i18n.t('user.active');
      },

      labelUserType: function (val) {
          switch (val) {
              case 'bot': return 'bot';
              default: return window.i18n.t('user.normal');
          }
      },
    },

    methods: {
      customFormatter(date) {
        return moment(date).format('YYYY - MM - DD');
      },
      search() {
        let filterParams = {
          start_date: moment(this.startDate)
            .startOf("day")
            .format("x"),
          end_date: moment(this.endDate)
            .endOf("day")
            .format("x")
        };
        if (this.customFormatter(this.startDate) === this.customFormatter(this.endDate)) {
          filterParams = {
            start_date: moment(this.startDate)
              .startOf("day")
              .format("x"),
            end_date: moment(this.startDate)
              .endOf("day")
              .format("x")
          }
        }
        if (this.customFormatter(this.startDate) > this.customFormatter(this.endDate)) {
          this.$toastr("error", this.$t("common.date.warning"));
          return;
        }
        if (this.filters.searchKey) {
          filterParams.search_key = this.filters.searchKey;
        }
        // if (this.filters.type) {
        //   filterParams.type = this.filters.type.id;
        // }
        // if (this.filters.status) {
        //   filterParams.status = this.filters.status.id;
        // }
        if (this.filters.group) {
          filterParams.group = this.filters.group.id;
        }
        this.$refs.datatable.filter(filterParams);
      },
      refresh() {
        this.resetInput();
        this.$refs.datatable.refresh();
      },
      resetInput() {
        this.filters = Object.assign(this.filters, {
          type: "",
          status: "",
          group: "",
          searchKey: ""
        });
        this.startDate = moment()
          .subtract(6, "day")
          .toDate();
        this.endDate = new Date();
        $(".datatable").find("th").attr("data-sort-order", "");
      },
      
      getTypes() {
        return window._.map(COMMON_CONST.USER_INFORMATION_TYPE, item => {
          return { id: item, name: `${item.charAt(0).toUpperCase()}${item.slice(1)}` };
        });
      },

      getStatus() {
        return window._.map(COMMON_CONST.USER_INFORMATION_STATUS, item => {
          return { id: item, name: `${item.charAt(0).toUpperCase()}${item.slice(1)}` };
        });
      },

      onDatatableFinish() {
        window._.each(this.$refs.datatable.rows, item => {
          this.$set(item, 'editable', false);
          this.$set(item, 'submitting', false);
        });
      },

      getData(params) {
        if (this.customFormatter(this.startDate) > this.customFormatter(this.endDate)) {
          this.$toastr("error", this.$t("common.date.warning"));
          return;
        }

        params.start_date = moment(this.startDate).startOf("day").format("x");
        params.end_date = moment(this.endDate).endOf("day").format("x");
        
        if (this.filters.searchKey) {
          params.search_key = this.filters.searchKey;
        }
        if (this.filters.type && this.filters.type.id !== "all") {
          params.type = this.filters.type.id;
        }
        if (this.filters.status && this.filters.status.id !== "all") {
          params.status = this.filters.status.id;
        }
        if (this.filters.group) {
          params.group = this.filters.group.id;
        }

        return rf.getRequest('UserRequest').getUsers(params)
          .then(res => {
            return res;
          });
      },

      getTotalUser() {
        return rf.getRequest('UserRequest').getTotalUsers()
          .then(res => {
              this.numberOfUser = res.data;
          });
      },

      updateUserInformation(item) {
        this.updateUserInfo(item);
      },

      updateUserInfo(user) {
        const params = {
          id: user.id,
          status: user.status,
          max_security_level: user.max_security_level,
          realAccountNo: user.real_account_no,
          virtualAccountNo: user.virtual_account_no,
          accountNote: user.account_note,
          referrerId: user.referrer_id,
          type: user.type,
          security_level: user.security_level,
          memo: user.memo,
        };

        // Disable Save button
        user.submitting = true;

        rf.getRequest('UserRequest').updateUserInfo(params).then(res => {
          user.editable = !user.editable;
          user.submitting = false;
          this.$toastr('success', this.$t('user.succes'));
        });
      },
      blockTabBrowser(e){
        if (e.key == 'Tab') {
          //e.preventDefault();
        }
      },

      chkAllClick(isChecked) {
        this.datatableRows = this.$refs.datatable.rows;
        window._.forEach(this.datatableRows, item => {
          this.checkItemClick(item, isChecked);
        });
      },

      checkItemClick(item, isChecked) {
        this.datatableRows = this.$refs.datatable.rows;

        if (isChecked && this.lstChecked.indexOf('-' + item.id + '-') < 0) {
          this.lstChecked += '-' + item.id + '-';
        }

        if (!isChecked && this.lstChecked.indexOf('-' + item.id + '-') >= 0) {
          this.lstChecked = this.lstChecked.replace('-' + item.id + '-', '');
        }
      },


      addToGroup() {
        this.lstCheckedAdd = '';
        if (this.lstChecked === '') {
          this.$toastr('error', window.i18n.t('user.alert.no_user_selected'));
          return;
        }
        else {
          let arrUserCheck = this.lstChecked.replace(/--/g, '+').replace(/-/g, '').split('+');
          if (arrUserCheck.length === 1) {
            let params = {
              user: arrUserCheck[0],
              page: 1,
              limit: 1000000
            }
            rf.getRequest('AdminRequest').getListUserGroup(params).then(res => {
              for (var i = 0; i < res.data.data.length; i++) {
                this.lstCheckedAdd += '-' + res.data.data[i].group_id + '-';
              }
              }).catch(error=>{
                console.error(error);
              });
            }
          }
          this.errors.clear();
          CommonModal.show("addGroup", {
            position: "add-group",
            mask: true,
            buttons: [
              {
                label: this.$t('common.action.cancel'),
                focused: true,
                style: {
                  width: "140px",
                  background: "rgb(0, 112, 192)"
                },
                callback: () => {
                  CommonModal.hide("addGroup");
                }
              },
              {
                label: this.$t('common.action.save'),
                focused: true,
                style: {
                  width: "140px",
                  background: "rgb(0, 112, 192)"
                },
                callback: () => {
                  if (this.lstCheckedAdd === "") {
                    this.$toastr('error', window.i18n.t('user.alert.no_group_selected'));
                    return;
                  }
                  let arrUser = this.lstChecked.replace(/--/g, '+').replace(/-/g, '').split('+');
                  let arrGroup = this.lstCheckedAdd.replace(/--/g, '+').replace(/-/g, '').split('+');
                  let params = {
                    lst_user: arrUser,
                    lst_group: arrGroup
                  }
                  rf.getRequest('AdminRequest').updateUserGroup(params).then(res => {
                    this.$toastr('success', window.i18n.t('common.alert.create.success'));
                    CommonModal.hide("addGroup");
                    this.lstChecked = '';
                    this.search();
                  }).catch(error=>{
                    console.error(error);
                  });
                }
              }
            ]
          });
        },

        removeFromGroup(){
          this.groupRemove = '';
          CommonModal.show("removeGroup", {
            position: "remove-group",
            mask: true,
            buttons: [
              {
                label: this.$t('common.action.cancel'),
                focused: true,
                style: {
                  width: "140px",
                  background: "rgb(0, 112, 192)"
                },
                callback: () => {
                  CommonModal.hide("removeGroup");
                }
              },
              {
                label: this.$t('common.action.save'),
                focused: true,
                style: {
                  width: "140px",
                  background: "rgb(0, 112, 192)"
                },
                callback: () => {
                  if (this.groupRemove === '') {
                    this.$toastr('error', window.i18n.t('user.alert.no_group_selected'));
                    return;
                  }
                  if (this.lstCheckedRemove === '') {
                    this.$toastr('error', window.i18n.t('user.alert.no_user_selected'));
                  }
                  let arrRemove = this.lstCheckedRemove.split('--');
                  let arrParam = [];
                  for (var i = 0; i < arrRemove.length; i++) {
                    let arrItem = arrRemove[i].replace(/-/g, '').split('|');
                    let objRemove = {
                      user_id: arrItem[1],
                      group_id: arrItem[0]
                    }
                    arrParam.push(objRemove);
                  }
                  rf.getRequest('AdminRequest').deleteUserGroup({lst_remove: arrParam}).then(res => {
                    this.$toastr('success', window.i18n.t('common.alert.delete.success'));
                    CommonModal.hide("removeGroup");
                    this.lstCheckedRemove = '';
                    this.groupRemove = '';
                    this.search();
                  }).catch(error=>{
                    console.error(error);
                  });
                }
              }
            ]
          });
        },

        onChangeCheckbox(item, isChecked) {
          if (isChecked && this.lstCheckedAdd.indexOf('-' + item.id + '-') < 0) {
            this.lstCheckedAdd += '-' + item.id + '-';
          }
          if (!isChecked && this.lstCheckedAdd.indexOf('-' + item.id + '-') >= 0) {
            this.lstCheckedAdd = this.lstCheckedAdd.replace('-' + item.id + '-', '');
          }
        },

        onChangeRemove(item, isChecked) {
          if (isChecked && this.lstCheckedRemove.indexOf('-' + item.group_id + '|' + item.user_id + '-') < 0) {
            this.lstCheckedRemove += '-' + item.group_id + '|' + item.user_id + '-';
          }
          if (!isChecked && this.lstCheckedRemove.indexOf('-' + item.group_id + '|' + item.user_id + '-') >= 0) {
            this.lstCheckedRemove = this.lstCheckedRemove.replace('-' + item.group_id + '|' + item.user_id + '-', '');
          }
        }
      },

    mounted() {
      this.$emit('EVENT_PAGE_CHANGE', this);
      this.getTotalUser();
    },

    beforeDestroy() {
      document.removeEventListener('keydown',this.blockTabBrowser);
    },

    created() {
      rf.getRequest('AdminRequest').getListUserGroupSetting({
        page: 1,
        limit: 1000000
      }).then(res => {
        this.groupSetting = res.data.data;
      }).catch(error=>{
        console.error(error);
      });
      document.addEventListener('keydown', this.blockTabBrowser);
    }
  }
</script>

<style lang="scss">
@import "@/assets/sass/variables";
.group-list.user-list {
  .group-item {
    position: relative;
    &.group-item-del {
      .checkmark_box {
        top: 4px;
      }
      label {
        padding-left: 22px;
      }
    }
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 12px;
      width: 12px;
      z-index: 2;
    }
    .checkmark_box {
      position: absolute;
      top: 0;
      left: 0;
      height: 12px;
      width: 12px;
      background-color: $color-white;
      border: 1px solid $color_grey;
    }
    .checkmark_box:after {
      left: 3px;
      top: 0px;
      width: 4px;
      height: 7px;
      border: solid $color-white;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
    .checkmark_box:after {
        content: "";
        position: absolute;
        display: none;
    }
    input:checked ~ .checkmark_box {
      background-color: $color-aquamarine;
      border: 1px solid $color-blue-hover;
    }
    input:checked ~ .checkmark_box:after {
      display: block;
    }
  }
}
.datatable{
  font-family: $font-family-roboto !important;
}
.date-picker-input {
  display: inline-block;
  input {
    padding: 0 5px 0px 23px;
    width: 110px !important;
    border-radius: 3px;
    -webkit-box-shadow: none;
    box-shadow: none;
    height: 30px;
    line-height: 30px;
    border: 1px solid #d2d6de;
    background-image: url('@/assets/images/icon/date-time.svg') !important;
    background-color: $color-white-smoke !important;
    background-size: 12px !important;
    background-position: center left 5px !important;
    background-repeat: no-repeat !important;
    color: $color-grey-dusty;
    &:hover, &:active, &:focus {
      border-color: $color-jungle-green;
      z-index: 9;
    }
  }
}
.no-left-radius {
    input {
      border-radius: 0px 3px 3px 0px;
      margin-left: -1px;
    }
  }
  .no-right-radius {
    input {
      border-radius: 3px 0px 0px 3px;
    }
  }
.modal-content .modal-footer {
  text-align: center;
  margin-top: 30px;
}
.selectbox100{
  width: 100px !important;
}
#userInformation {
  font-family: $font-family-roboto;
  .sc_search_select {
    .group_search_select {
      .button_drop_search {
        // width: 55px;
        height: 30px !important;
        padding: 5px 10px 5px 10px !important;
        border-radius: 3px;
        font-size: $font-small;
        font-family: $font-family-roboto;
        i.icon-arrow1 {
          margin-left: 5px;
        }
        span {
          color: $color-grey-dusty !important;
        }
        &:hover, &:active, &:focus {
          border-color: $color-jungle-green;
          i.icon-arrow1 {
            color: $color-jungle-green;
          }
        }
        &.active {
          border-color: $color-jungle-green;
          i.icon-arrow1 {
            color: $color-jungle-green;
          }
        }
      }
      .box_list_search_select {
        .list_search_select {
          li {
            font-family: $font-family-roboto;
            font-size: $font-small;
            &:hover, &:active, &:focus {
              color: $color-jungle-green;
            }
          }
        }
      }
    }
  }
  .select-box-full {
    .group_search_select {
      .button_drop_search {
        height: 35px !important;
        font-size: $font_root;
        span {
          font-size: $font_root;
          line-height: 22px;
        }
        i.icon-arrow1 {
          font-size: $font_root;
        }
      }
      .box_list_search_select {
        ul {
          max-height: 180px;
          overflow-y: scroll;
        }
      }
    }
  }
  .box_select_user {
    .group_search_select {
      .button_drop_search {
        width: 90px !important;
        border-radius: 3px;
        i.icon-arrow1 {
          margin-left: 0px;
        }
      }
    }
  }
  td:first-child{
    padding: 8px 10px 4px 10px !important;
  }
}
@media only screen and (max-width: 1280px){
   body{
      min-width: 1280px !important;
    }
}
</style>

<style lang="scss" scoped>
@import "@/assets/sass/variables";

.title-text {
  font-family: $font-family-roboto;
  font-size: $font-small;
  color: $color-grey-dusty;
  padding-bottom: 3px;
}

table {
  thead {
    th {
      padding: 8px 10px 4px 10px !important;
      // text-align: center;
      color: $color_grey;
      &.non-sort {
        &::after {
          margin-left: 0px !important;
          width: 0px !important;
          height: 0px !important;
        }
      }
    }
  }
  tbody {
    tr {
      
      td {
        // text-align: center;
        vertical-align: middle;
        // padding-top: 5px;
        font-family: $font-family-roboto;
        padding: 8px 10px 4px 10px !important;
        .form-control {
          background-color: transparent !important;
          border-radius: 0px;
        }
      }
      
    }
  }
  
}


.user-information {
  .filter-container {
    margin: 5px 0px 15px 0px;
    height: 60px;
    .filter-item {
      float: left;
      margin: 0 5px;
      input {
        margin-top: 21px;
        display: inline-block;
        width: 145px;
        &:hover, &:active, &:focus {
          border-color: $color-jungle-green;
        }
      }
      select {
        width: 65px;
        display: inline-block;
        font-family: $font-family-roboto;
        font-size: $font_root;
        &:hover, &:active, &:focus {
          border-color: $color-jungle-green;
        }
      }
      button {
        margin: 0 5px;
        width: auto;
        margin-top: 21px;
        height: 30px;
        font-size: $font-smaller;
        font-family: $font-family-roboto;
        &:hover, &:active {
          border-color: $color-aquamarine;
          background-color: $color-aquamarine;
        }
      }
      .btn-search, .btn-reset, .btn-remove {
        margin-left: 0px;
        margin-right: 5px;
        font-family: $font-family-roboto-bold;
        font-size: $font-smaller;
        border-radius: 3px;
        border: 1px solid $color-caribbean-green;
        width: 80px;
        color: $color_white;
        text-transform: uppercase;
        background-color: $color-caribbean-green;
        font-weight: 600;
      }
      .btn-remove {
        border: 1px solid $color-denim;
        color: $color-denim;
        background-color: transparent;
        &:hover, &:active, &:focus {
          color: $color-white;
        }
      }
    }
    .date {
      margin-right: 15px;
    }
    .pair {
      margin-right: 15px;
    }
  }
  .tool-button {
    margin-bottom: 15px;
    height: 30px;
    .btn {
      margin-top: 0px !important;
      min-width: 200px;
      .glyphicon {
        margin-right: 5px;
      }
    }
  }
  .form-control {
    background: $color-white-smoke;
    height: 30px;
    font-size: 13px;
    padding-left: 11px;
    padding-right: 0;
  }
  .datatable {
    button.btnForm.bt-action {
      padding-left: 5px !important;
      padding-right: 5px !important;
      font-weight: 400 !important;
    }
  }
  .buy {
      color: $text-color-jade;
    }
  .sell {
    color: $text-color-red;
  }
  .btn-detail {
    background-color: $color-caribbean-green;
    padding: 2px 10px;
    font-size: $font-smaller;
    color: $color-white;
    border:1px solid $color-caribbean-green;
    border-radius: 3px;
    text-transform: uppercase;
    font-family: $font-family-roboto;
    &:hover{
      background-color: $color-aquamarine;
      border-color: $color-aquamarine;
    };
  }
  .icon-notfound {
    font-size: 40px;
    padding-right: 15px;
  }
  .toolbar-element2 {
    display: inline-block;
    float: left;
  }
  .group-head {
    font-size: 20px;
    margin-bottom: 20px;
    font-family: $font-family-roboto-medium;
  }
  .group-list {
    border: 1px solid $color_alto;
    border-radius: 3px;
    padding: 15px 15px;
    height: 210px;
    overflow-y: scroll;
    .group-item{
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0px;
      }
      input{
        margin: 0px;
        margin-right: 10px;
      }
      label{
        font-family: $font-family-roboto;
        font-weight: normal;
        margin: 0px;
      }
    }
  }
  .user-list {
    height: 170px;
    margin-top: 15px;
  }
}
</style>

<style lang="scss" scoped>
@import "@/assets/sass/variables";
  .box_select_level {
    width: 40px;
  }
  .text-pair{
    padding-left: 16px;
  }
  .box_select_user {
    width: 80px;
    .group_search_select {
      width: 80px;
      .button_drop_search {
        width: 80px !important;
      }
    }
  }
  .cl1 {
    min-width: 52px;
  }
  .cl2 {
    min-width: 80px;
  }
  .cl3{
    min-width: 150px;
  }
  .cl4 {
      min-width: 95px;
  }
  .cl5 {
    min-width: 122px;
  }
  .cl6{
    min-width: 120px;
  }
  .cl7 {
    min-width: 130px;
  }
  .cl8 {
    min-width: 80px;
  }
  .cl9 {
    min-width: 90px;
  }
  // .cl3 {
  //   width: 280px;
  // }
  .cl10 {
    min-width: 50px;
  }
  // .cl4::after {
  //   width: 0px;
  //   height: 0px;
  //   display: none;
  // }
  .user-information {
    // width: 100%;
    .edit-input-number {
      input {
        width: 80px;
        padding-left: 5px;
        background: transparent;
        box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0);
        border-radius: 0px;
        border: 1px solid $color_alto;
      }
    }
    .select_user {
      box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0);
      border-radius: 0px;
      width: 80px;
      height: 27px;
      border: 1px solid $color_alto;
      line-height: 20px;
      padding: 3px 5px;
      background-color: transparent;
    }
    .item_email_user {
      position: relative;
      .txt_email_user {
        display: block;
        max-width: 240px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .tooltip_email_user {
        position: absolute;
        top: 0px;
        line-height: 20px;
        padding: 5px 20px;
        left: 45%;
        background-color: $color_white;
        white-space: nowrap;
        width: auto;
        z-index: 10;
        font-size: $font_root;
        font-weight: 500;
        color: $color_mine_shaft;
        transition: 0.5s;
        display: none;
        box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.4);
        &:after {
          right: 100%;
          top: 50%;
          border: solid transparent;
          content: " ";
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
          border-color: rgba(136, 183, 213, 0);
          border-right-color: $color_white;
          border-width: 5px;
          margin-top: -5px;
        }
      }
      &:hover {
        .tooltip_email_user {
          display: block;
          transition: 0.5s;
        }
      }
      .tooltip_group_user {
        left: 20px;
      }
    }
    .btn_save_user {
      background-color: transparent !important;
      font-size: $font_semi_big;
      height: 27px;
      padding: 2px 0px;
      line-height: 23px;
      float: right;
      margin-left: 15px;
      margin-right: 15px;
      .icon-save:before {
      color: $color_dove_gray;
      }
      &:active {
        box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0);
      }
      &:hover {
        color: $color-jungle-green;
        .icon-save:before {
          color: $color-jungle-green;
        }
      }
    }
    .btn_edit_user {
      background-color: transparent !important;
      font-size: $font_semi_big;
      height: 27px;
      padding: 2px 0px;
      line-height: 23px;
      float: right;
      margin-left: 15px;
      margin-right: 15px;
      .icon-save:before {
      color: $color_dove_gray;
      }
      &:active {
        box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0);
      }
      &:hover {
        color: $color-jungle-green;
        .icon-save:before {
          color: $color-jungle-green;
        }
      }
    }
  }

  // @media only screen and (min-width: 1399px) {
  //  .user-information .item_email_user .txt_email_user {
  //     max-width: 250px;
  //  }
  // }
</style>

<style lang="scss">
@import "@/assets/sass/variables";
  #select-box-user-modal {
    .sc_search_select {
      .group_search_select {
        .button_drop_search {
          font-family: $font-family-roboto-medium !important;
          span {
            color: $color_mine_shaft !important;
          }
        }
      }
    }
  }
</style>