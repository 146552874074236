<template>
  <div class="instrusmentResource_pages boxCore" id="instrusmentResource_page">
    <div class="instrusment_container clearfix">
      <div class="content-wrap">
        <div class="row">
          <div class="col-xs-7 min_750">
            <div class="box-content clearfix">
              <!-- row 1 -->
              <div class="row item">
                <div class="col-xs-6">
                  <div class="form-group row flex-group">
                    <label for="symbol-instrument" class="col-sm-4 col-form-label mw">{{$t('instruments.symbol')}}</label>
                    <div class="col-sm-8">
                      <input
                              ref="symbol"
                              id="symbol-instrument"
                              :maxlength="maxLength"
                              autocomplete="off"
                              name="symbol"
                              @focus="removeValidate('symbol')"
                              @focusout="changeSymbol"
                              data-vv-validate-on="none"
                              v-validate="'required|min:1|max:72'"
                              data-vv-as="symbol"
                              type="text" class="form-control"
                              :class="{ errorInput: errors.has('symbol') }"
                              v-model="dataInstrument.symbol"/>
                      <span class="error-message" v-if="errors.has('symbol')"> {{ errors.first('symbol') }} </span>
                    </div>
                  </div>
                </div>
                <div class="col-xs-6">
                  <div class="form-group row flex-group">
                    <label for="settlement-fee" class="col-sm-4 col-form-label mw">{{$t('instruments.settlement_fee')}}</label>
                    <div class="col-sm-8">
                      <div>
                        <number-input
                                v-inputOnlyTwoRationalNumbers
                                ref="settlement-fee"
                                id="settlement-fee"
                                :precision="8"
                                :rational-number="2"
                                :restrict-step-price="true"
                                name="settlement_fee"
                                class="form-control padding-unit"
                                @focus="removeValidate('settlement_fee')"
                                :class="{ errorInput: errors.has('settlement_fee')}"
                                v-model="dataInstrument.settlement_fee"
                                
                        >

                        </number-input>

                        <span class="unit w-20"
                              :class="{ 'error-message' : errors.has('settlement_fee')}">%</span>
                      </div>
                      <span class="error-message" v-if="errors.has('settlement_fee')"> {{ errors.first('settlement_fee') }} </span>
                    </div>
                  </div>
                </div>
              </div>
              <!-- row 2 -->
              <div class="row item">
                <div class="col-xs-6">
                  <div class="form-group row flex-group">
                      <label for="symbol-root" class="col-sm-4 col-form-label mw mw">{{$t('instruments.root_symbol')}}</label>
                      <div class="col-sm-8" @click="removeValidate('root_symbol')">
                        <DropDownList
                                @onSelectValue="dropdownChange"
                                id="symbol-root"
                                :paramRequest='{"root_symbol": dataDropdown.root_symbol}'
                                :key="keyDropDown.root_symbol"
                                :class="{ errorDropdown: errors.has('root_symbol')}"
                                name="root_symbol"
                                data-vv-as="root_symbol"
                        />
                        <span class="error-message" v-if="errors.has('root_symbol')"> {{ errors.first('root_symbol') }} </span>
                      </div>
                  </div>
                </div>
                <div class="col-xs-6">
                  <div class="form-group row flex-group">
                    <label for="reference-index" class="col-sm-4 col-form-label mw">{{$t('instruments.reference_index')}}</label>
                    <div class="col-sm-8" @click="removeValidate('reference_index')">
                      <DropDownList
                              @onSelectValue='dropdownChange'
                              id="reference-index"
                              :paramRequest='{"reference_index":dataDropdown.reference_index}'
                              :key="keyDropDown.reference_index"
                              :class="{ errorDropdown: errors.has('reference_index') && dataInstrument.root_symbol }"
                              name="reference_index"
                              data-vv-as="reference_index"
                              :isDisabled="(dataInstrument.root_symbol) ? false : true"

                      />
                      <span class="error-message"
                            v-if="errors.has('reference_index') && dataInstrument.root_symbol"> {{ errors.first('reference_index') }} </span>
                    </div>
                  </div>
                </div>
              </div>
              <!-- row 3 -->
              <div class="row item">
                <div class="col-xs-6">
                  <div class="form-group row flex-group">
                    <label for="state"
                            class="col-sm-4 col-form-label mw">{{$t('instruments.state')}}</label>
                      <div class="col-sm-8">
                        <div class="tab-header" id="state">
                        <ul>
                            <li :class="{'active': dataInstrument.state === 1}">
                                <a href="javascript:void(0)" @click="selectTab(1)">
                                    <span>{{ $t('instruments.open') }}</span>
                                </a>
                            </li>
                            <li :class="{'active': dataInstrument.state === 0}">
                                <a
                                        href="javascript:void(0)"
                                        @click="selectTab(0)"
                                ><span>{{ $t('instruments.pending') }}</span></a>
                            </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xs-6">
                  <div class="form-group row flex-group">
                    <label for="fundingBase-index" class="col-sm-4 col-form-label mw">{{$t('instruments.funding_base_index')}}</label>
                    <div class="col-sm-8" @click="removeValidate('funding_base_index')">
                      <DropDownList
                              @onSelectValue='dropdownChange'
                              id="fundingBase-index"
                              :paramRequest='{"funding_base_index":dataDropdown.funding_base_index}'
                              :key="keyDropDown.funding_base_index"
                              :class="{ errorDropdown: errors.has('funding_base_index') && dataInstrument.type ==='PERPETUAL' && dataInstrument.base_underlying }"
                              name="funding_base_index"
                              data-vv-as="funding_base_index"
                              :isDisabled="(dataInstrument.type ==='PERPETUAL' && dataInstrument.base_underlying) ? false : true"
                      />
                      <span class="error-message"
                            v-if="errors.has('funding_base_index') && dataInstrument.type ==='PERPETUAL' && dataInstrument.base_underlying"> {{ errors.first('funding_base_index') }} </span>
                    </div>
                  </div>
                </div>
              </div>
              <!-- row 4 -->
              <div class="row item">
                <div class="col-xs-6">
                  <div class="form-group row flex-group">
                    <label for="type"
                            class="col-sm-4 col-form-label mw">{{$t('instruments.type')}}</label>
                    <div class="col-sm-8" @click="removeValidate('type')">
                      <DropDownList
                              @onSelectValue='dropdownChange'
                              id="type"
                              :paramRequest='{"type": dataDropdown.type}'
                              :class="{ errorDropdown: errors.has('type') }"
                              name="type"
                              data-vv-as="type"
                      />
                      <span class="error-message" v-if="errors.has('type')"> {{ errors.first('type') }} </span>
                    </div>
                  </div>
                </div>
                <div class="col-xs-6">
                  <div class="form-group row flex-group">
                    <label for="funding-quote-index" class="col-sm-4 col-form-label mw">{{$t('instruments.funding_quote_index')}}</label>
                    <div class="col-sm-8" @click="removeValidate('funding_quote_index')">
                      <DropDownList
                              id="funding-quote-index"
                              @onSelectValue='dropdownChange'
                              :paramRequest='{"funding_quote_index": dataDropdown.funding_quote_index}'
                              :key="keyDropDown.funding_quote_index"
                              :class="{ errorDropdown: errors.has('funding_quote_index') && dataInstrument.type ==='PERPETUAL' && dataInstrument.quote_currency}"
                              name="funding_quote_index"
                              data-vv-as="funding quote index"
                              :isDisabled="(dataInstrument.type ==='PERPETUAL' && dataInstrument.quote_currency) ? false : true"
                      />
                      <span class="error-message"
                            v-if="errors.has('funding_quote_index') && dataInstrument.type ==='PERPETUAL' && dataInstrument.quote_currency"> {{ errors.first('funding_quote_index') }} </span>
                    </div>
                  </div>
                </div>
              </div>
              <!-- row 5 -->
              <div class="row item">
                <div class="col-xs-6">
                  <div class="form-group row flex-group">
                    <label for="date-Expiry" class="col-sm-4 col-form-label mw">{{$t('instruments.expiry')}}</label>
                    <div class="col-sm-8" v-if="this.dataInstrument.type==='FUTURE'">
                      <div>
                        <datetime
                                id="date-Expiry"
                                v-model="dataInstrument.expiry"
                                class="datepicker-input"
                                :class="{errorDropdown: errors.has('expiry')}"
                        />
                        <span class="unit w-20"
                              :class="{'error-message': errors.has('expiry') || errors.has('incorrectExpiry')}"><span
                                class="icon-calendar"></span></span>
                      </div>
                      <span class="error-message"> {{ errors.first('expiry') }} </span>
                      <span class="error-message"> {{ errors.first('incorrectExpiry') }} </span>
                    </div>
                    <div class="col-sm-8" v-else>
                      <input type="text" class="form-control" disabled/>
                    </div>
                  </div>
                </div>
                <div class="col-xs-6">
                  <div class="form-group row flex-group">
                    <label for="funding-premium-index" class="col-sm-4 col-form-label mw">{{$t('instruments.funding_premium_index')}}</label>
                    <div class="col-sm-8" @click="removeValidate('funding_premium_index')">
                      <DropDownList
                              id="funding-premium-index"
                              @onSelectValue='dropdownChange'
                              :paramRequest='{"funding_premium_index" : dataDropdown.funding_premium_index}'
                              :key="keyDropDown.funding_premium_index"
                              :class="{ errorDropdown: errors.has('funding_premium_index') && dataInstrument.type ==='PERPETUAL' && dataInstrument.root_symbol }"
                              :isDisabled="(dataInstrument.type ==='PERPETUAL' && dataInstrument.root_symbol) ? false : true"
                      />
                      <span class="error-message"
                            v-if="errors.has('funding_premium_index') && dataInstrument.type ==='PERPETUAL' && dataInstrument.root_symbol"> {{ errors.first('funding_premium_index') }} </span>
                    </div>
                  </div>
                </div>
              </div>
              <!-- row 6 -->
              <div class="row item">
                <div class="col-xs-6">
                  <div class="form-group row flex-group">
                    <label for="base-underlying" class="col-sm-4 col-form-label mw">{{$t('instruments.base_underlying')}}</label>
                    <div class="col-sm-8" @click="removeValidate('base_underlying')">
                      <DropDownList
                              id="base-underlying"
                              @onSelectValue='dropdownChange'
                              :paramRequest='{"base_underlying":dataDropdown.root_symbol}'
                              :key="keyDropDown.root_symbol"
                              :class="{ errorDropdown: errors.has('base_underlying') }"
                      />
                      <span class="error-message" v-if="errors.has('base_underlying')"> {{ errors.first('base_underlying') }} </span>
                    </div>
                  </div>
                </div>
                <div class="col-xs-6">
                    <div class="form-group row flex-group">
                        <label for="tick-size" class="col-sm-4 col-form-label mw">{{$t('instruments.tick_size')}}</label>
                        <div class="col-sm-8">
                            <number-input
                                    v-inputOnlyTwoRationalNumbers
                                    id="tick-size"
                                    :restrict-step-price="true"
                                    :rational-number="16"
                                    :precision="8"
                                    class="form-control"
                                    v-model="dataInstrument.tick_size"
                                    data-vv-as="tick size"
                                    :class="{ errorInput: errors.has('tick_size') }"
                                    @focus="removeValidate('tick_size')"
                                    name="tick_size"
                            >
                            </number-input>
                            <span class="error-message" v-if="errors.has('tick_size')"> {{ errors.first('tick_size') }} </span>
                        </div>
                    </div>
                </div>
              </div>
              <!-- row 7 -->
              <div class="row item">
                <div class="col-xs-6">
                    <div class="form-group row flex-group">
                        <label for="quote-currency" class="col-sm-4 col-form-label mw">{{$t('instruments.quote_currency')}}</label>
                        <div class="col-sm-8" @click="removeValidate('quote_currency')">
                            <DropDownList
                                    id="quote-currency"
                                    @onSelectValue='dropdownChange'
                                    :paramRequest='{"quote_currency" : dataDropdown.root_symbol}'
                                    :key="keyDropDown.root_symbol"
                                    :class="{ errorDropdown: errors.has('quote_currency') }"

                            />
                            <span class="error-message" v-if="errors.has('quote_currency')"> {{ errors.first('quote_currency') }} </span>
                        </div>
                    </div>
                  </div>
                  <div class="col-xs-6">
                    <div class="form-group row flex-group">
                        <label for="max-price" class="col-sm-4 col-form-label mw">{{$t('instruments.max_price')}}</label>
                        <div class="col-sm-8">
                            <number-input
                                    v-input-only-two-rational-numbers
                                    id="max-price"
                                    :rational-number="16"
                                    :precision="8"
                                    class="form-control"
                                    v-model="dataInstrument.max_price"
                                    :class="{ errorInput: errors.has('max_price')}"
                                    name="max_price"
                                    data-vv-as="max price"
                                    @focus="removeValidate('max_price')"
                            >
                            </number-input>
                            <span class="error-message" v-if="errors.has('max_price')"> {{ errors.first('max_price') }} </span>
                        </div>
                    </div>
                </div>
              </div>
              <!-- row 8 -->
              <div class="row item">
                <div class="col-xs-6">
                    <div class="form-group row flex-group">
                        <label for="underlying-symbol" class="col-sm-4 col-form-label mw">{{$t('instruments.underlying_symbol')}}</label>
                        <div class="col-sm-8" @click="removeValidate('underlying_symbol')">
                            <DropDownList
                                    id="underlying-symbol"
                                    @onSelectValue='dropdownChange'
                                    :paramRequest='{"underlying_symbol":dataDropdown.root_symbol}'
                                    :key="keyDropDown.root_symbol"
                                    :class="{ errorDropdown: errors.has('underlying_symbol') }"
                            />
                            <span class="error-message" v-if="errors.has('underlying_symbol')"> {{ errors.first('underlying_symbol') }} </span>
                        </div>
                    </div>
                  </div>
                  <div class="col-xs-6">
                    <div class="form-group row flex-group">
                        <label for="max-order-qty" class="col-sm-4 col-form-label mw">{{$t('instruments.max_order_qty')}}</label>
                        <div class="col-sm-8" @click="removeValidate('max_order_qty')">
                            <number-input
                                    v-input-only-two-rational-numbers
                                    id="max-order-qty"
                                    :rational-number="16"
                                    class="form-control"
                                    :allow-negative="true"
                                    v-model="dataInstrument.max_order_qty"
                                    :class="{errorInput: errors.has('max_order_qty')}"
                                    name="max_order_qty"
                                    data-vv-as="max order qty"
                                    @focus="removeValidate('max_order_qty')"
                            ></number-input>
                            <span class="error-message" v-if="errors.has('max_order_qty')"> {{ errors.first('max_order_qty') }} </span>
                        </div>
                    </div>
                </div>
              </div>
              <!-- row 9 -->
              <div class="row item">
                <div class="col-xs-6">
                    <div class="form-group row flex-group">
                        <label for="init-margin" class="col-sm-4 col-form-label mw">{{$t('instruments.init_margin')}}</label>
                        <div class="col-sm-8">
                            <div>
                                <number-input
                                        ref="init-margin"
                                        v-input-only-two-rational-numbers
                                        id="init-margin"
                                        :rational-number="3"
                                        :restrict-step-price="true"
                                        class="form-control padding-unit"
                                        :precision="8"
                                        v-model="dataInstrument.init_margin"
                                        @blur="changeValueRequire('init_margin')"
                                        :class="{errorInput: errors.has('init_margin')}"
                                        data-vv-as="init rate"
                                        name="init_margin"
                                        @focus="removeValidate('init_margin')"
                                ></number-input>
                                <span class="unit w-20"
                                      :class="{ 'error-message' : errors.has('init_margin')}">%</span>
                            </div>
                            <span class="error-message" v-if="errors.has('init_margin')"> {{ errors.first('init_margin') }} </span>
                        </div>
                    </div>
                  </div>
                  <div class="col-xs-6">
                    <div class="form-group row flex-group">
                        <label for="multiplier" class="col-sm-4 col-form-label mw">{{$t('instruments.multiplier')}}</label>
                        <div class="col-sm-8">
                            <number-input
                                    v-inputLowerOne
                                    id="multiplier"
                                    :rational-number="1"
                                    :allowNegative="true"
                                    :precision="8"
                                    class="form-control"
                                    v-model="dataInstrument.multiplier"
                                    :class="{errorInput: errors.has('multiplier')}"
                                    data-vv-as="multiplier"
                                    @keyup.native="checkMultiplier"
                                    name="multiplier"
                                
                                    @focus="removeValidate('multiplier')"
                            ></number-input>
                            <span class="error-message"> {{ errors.first('multiplier') }} </span>
                        </div>
                    </div>
                </div>
              </div>
              <!-- row 10 -->
              <div class="row item">
                <div class="col-xs-6">
                    <div class="form-group row flex-group">
                        <label for="maint-margin" class="col-sm-4 col-form-label mw">{{$t('instruments.maint_margin')}}</label>
                        <div class="col-sm-8">
                            <div>
                                <number-input
                                        v-input-only-two-rational-numbers
                                        id="maint-margin"
                                        class="form-control padding-unit"
                                        v-model="dataInstrument.maint_margin"
                                        v-validate="'required|min:0|max:100'"
                                        :rational-number="2"
                                        data-vv-as="maint rate"
                                        name="maint_margin"
                                        :precision="8"
                                        data-vv-validate-on="none"
                                        @blur="checkValidateData('maint-margin')"
                                        :class="{ errorInput: errors.has('maint_margin') }"
                                        @focus="removeValidate('maint_margin')"
                                />
                                <span class="unit w-20"
                                      :class="{ 'error-message' : errors.has('maint_margin')}">%</span>
                            </div>
                            <span class="error-message" v-if="errors.has('maint_margin')"> {{ errors.first('maint_margin') }} </span>
                        </div>
                    </div>
                  </div>
                  <div class="col-xs-6">
                    <div class="form-group row flex-group">
                        <label for="risk-limit" class="col-sm-4 col-form-label mw">{{$t('instruments.risk_limit')}}</label>
                        <div class="col-sm-8">
                            <number-input
                                    v-input-only-two-rational-numbers
                                    id="risk-limit"
                                    class="form-control"
                                    :rational-number="16"
                                    :precision="8"
                                    :restrict-step-price="true"
                                    @focus="removeValidate('risk_limit')"
                                    v-model="dataInstrument.risk_limit"
                                    @blur = "changeValueRequire('risk-limit')"
                                    data-vv-as="risk limit"
                                    name="risk_limit"
                                    data-vv-validate-on="none"
                                    :class="{errorInput: errors.has('risk_limit')}"
                            >
                            </number-input>
                            <span class="error-message" v-if="errors.has('risk_limit')"> {{ errors.first('risk_limit') }} </span>
                        </div>
                    </div>
                </div>
              </div>
              <!-- row 11 -->
              <div class="row item">
                <div class="col-xs-6">
                    <div class="form-group row flex-group">
                        <label for="maker-fee" class="col-sm-4 col-form-label mw">{{$t('instruments.maker_fee')}}</label>
                        <div class="col-sm-8">
                            <div>
                                <number-input
                                        v-input-only-two-rational-numbers
                                        id="maker-fee"
                                        :restrict-step-price="true"
                                        :rational-number="2"
                                        :precision="8"
                                        class="form-control padding-unit"
                                        v-model="dataInstrument.maker_fee"
                                        data-vv-validate-on="none"
                                        :allow-negative="true"
                                        @focus="removeValidate('maker_fee')"
                                        data-vv-as="maker fee"
                                        name="maker_fee"
                                        :class="{ errorInput: errors.has('maker_fee')}"
                                ></number-input>
                                <span class="unit w-20"
                                      :class="{ 'error-message' : errors.has('maker_fee')}">%</span>
                            </div>
                            <span class="error-message" v-if="errors.has('maker_fee')"> {{ errors.first('maker_fee') }} </span>
                        </div>
                    </div>

                </div>
                <div class="col-xs-6">
                    <div class="form-group row flex-group">
                        <label for="risk-step" class="col-sm-4 col-form-label mw">{{$t('instruments.risk_step')}}</label>
                        <div class="col-sm-8">
                            <number-input
                                    v-input-only-two-rational-numbers
                                    id="risk-step"
                                    :rational-number="16"
                                    :restrict-step-price="true"
                                    :precision="8"
                                    class="form-control"
                                    @focus="removeValidate('risk_step')"
                                    v-model="dataInstrument.risk_step"
                                    v-validate="'required|min:0.05|max:100'"
                                    @blur="checkValidateData('risk_step')"
                                    data-vv-validate-on="none"
                                    name="risk_step"
                                    data-vv-as="risk step"
                                    :class="{ errorInput: errors.has('risk_step')}"
                            ></number-input>
                            <span class="error-message" v-if="errors.has('risk_step')"> {{ errors.first('risk_step') }} </span>
                        </div>
                    </div>
                </div>
              </div>
              <div class="row item">
                  <div class="col-xs-6">
                    <div class="form-group row flex-group">
                        <label for="taker-fee" class="col-sm-4 col-form-label mw">{{$t('instruments.taker_fee')}}</label>
                        <div class="col-sm-8">
                            <div>
                                <number-input
                                        v-input-only-two-rational-numbers
                                        id="taker-fee"
                                        :restrictStepPrice="true"
                                        @focus="removeValidate('taker_fee')"
                                        class="form-control padding-unit"
                                        v-model="dataInstrument.taker_fee"
                                        :class="{ errorInput: errors.has('taker_fee') }"
                                        data-vv-as="taker fee"
                                        :rational-number="2"
                                        :allow-negative ="true"
                                        name="taker_fee"
                                        :precision="8"
                                        data-vv-validate-on="none"
                                        v-validate="'required|min:0.05|max:100'"
                                ></number-input>
                                <span class="unit w-20"
                                        :class="{ 'error-message' : errors.has('taker_fee')}">%</span>
                            </div>
                            <span class="error-message" v-if="errors.has('taker_fee')"> {{ errors.first('taker_fee') }} </span>
                        </div>
                    </div>
                  </div>
                  <div class="col-xs-6">
                    <div class="form-group row flex-group">
                      <label for="settlement-index" class="col-sm-4 col-form-label mw">{{$t('instruments.settlement_index')}}</label>
                      <div class="col-sm-8" @click="removeValidate('settlement_index')">
                        <DropDownList
                                @onSelectValue='dropdownChange'
                                id="settlement-index"
                                :paramRequest='{"settlement_index":dataDropdown.settlement_index}'
                                :key="keyDropDown.settlement_index"
                                :class="{ errorDropdown: errors.has('settlement_index') && dataInstrument.root_symbol }"
                                name="settlement_index"
                                data-vv-as="settlement_index"
                                :isDisabled="(dataInstrument.root_symbol) ? false : true"

                        />
                        <span class="error-message"
                              v-if="errors.has('settlement_index') && dataInstrument.root_symbol"> {{ errors.first('settlement_index') }} </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
      <div class="button-group">
          <button class="btn btn-primary mr-10" @click.stop="cancel()">{{$t('instruments.cancel')}}</button>
          <button class="btn btn-primary mr-10" @click.stop="submit()">{{$t('instruments.submit')}}</button>
      </div>
    </div>
  </div>
</template>

<script>
    import rf from '../../lib/RequestFactory';
    import CurrencyInput from '../../components/CurrencyInput';
    import CoinInput from '../../common/CoinInput.vue';
    import DatePicker from 'vuejs-datepicker';
    import moment from 'moment';
    import DropDownList from "../../common/DropDownList";
    import datetime from 'vuejs-datetimepicker';
    import NumberInput from "../../common/NumberInput";
    import CONST from '../../common/Const'

    const INSTRUMENT_TYPES = {
      CALL_OPTION: 0,
      FUTURE: 1,
      PERPETUAL: 2,
      PUT_OPTION: 3,
    };

    export default {
        name: "InstrumentResource",
        data() {
            return {
                titlePage: this.$t('instruments.create_ins'),
                dataMaster: {},
                dataInstrument: {
                    symbol: "",
                    settlement_fee: "",
                    root_symbol: "",
                    reference_index: "",
                    settlement_index: "",
                    state: 1,
                    funding_base_index: "",
                    type: "",
                    funding_quote_index: "",
                    expiry: moment().format('YYYY-MM-DD H:m:s'),
                    funding_premium_index: "",
                    base_underlying: "",
                    tick_size: "",
                    quote_currency: "",
                    max_price: "",
                    underlying_symbol: "",
                    max_order_qty: "",
                    init_margin: "",
                    multiplier: "",
                    maint_margin: "",
                    risk_limit: "",
                    maker_fee: "",
                    risk_step: "",
                    taker_fee: "",
                },
                dataParams: {},
                dataDropdown: {
                    symbol: [],
                    root_symbol: [],
                    type: ["FUTURE", "PERPETUAL"],
                    reference_index: [],
                    settlement_index: [],
                    funding_base_index: [],
                    funding_quote_index: [],
                    funding_premium_index: [],
                },
                keyDropDown: {
                    root_symbol: 0,
                    symbol: 0,
                    reference_index: 0,
                    settlement_index: 0,
                    funding_base_index: 0,
                    funding_quote_index: 0,
                    funding_premium_index: 0
                },
                maxLength: CONST.MAX_LENGTH_INPUT,
            }
        },
        components: {
            NumberInput,
            CoinInput,
            DatePicker,
            datetime,
            DropDownList,
            NumberInput
        },
        methods: {
            selectTab(tab) {
                this.dataInstrument.state = tab;
            },
            precisionPrice() {
                return this.contract ? parseFloat(this.contract.tick_size) : 0.5;
            },
            resetErrors() {
                this.errors.clear();
            },
            removeZeroAfter(val) {
                this.dataInstrument[val] = this.dataInstrument[val] ? Number(this.dataInstrument[val].toString()) : '';
            },
            dropdownChange(valueReturn) {
                switch (valueReturn.params) {
                    case 'type' :
                        this.changeTypeDropdown(valueReturn);
                        break;
                    case 'root_symbol' :
                        this.changeRootSymbolDropdown(valueReturn);
                        break;
                    case 'base_underlying' :
                        this.changeBaseUnderDropdown(valueReturn);
                        break;
                    case 'quote_currency' :
                        this.changeQuoteCurreny(valueReturn);
                        break;
                    default:
                        break;
                }
                this.dataInstrument[valueReturn.params] = valueReturn.value;
            },
            changeTypeDropdown(data) {
                if (data.value == "PERPETUAL") {
                    this.keyDropDown.funding_quote_index += 1;
                    this.keyDropDown.funding_premium_index += 1;
                    this.keyDropDown.funding_base_index += 1;
                    this.errors.remove('funding_quote_index');
                    this.errors.remove('funding_base_index');
                    this.errors.remove('funding_premium_index');
                } else {
                    this.errors.remove('expiry');
                }
            },
            changeRootSymbolDropdown(valueReturn) {
                let dataReferenceIndex = [];
                let dataFundingPremiumIndex = [];
                this.dataDropdown.reference_index = [];
                this.dataDropdown.funding_premium_index = [];
                this.dataMaster.forEach(function (value) {
                    if (value.root_symbol && value.root_symbol == valueReturn.value) dataReferenceIndex.push(value.symbol);
                });
                this.errors.remove('reference_index');
                this.errors.remove('settlement_index');
                this.errors.remove('funding_base_index');
                this.errors.remove('funding_premium_index');
                this.dataDropdown.reference_index = dataReferenceIndex;
                this.dataDropdown.settlement_index = dataReferenceIndex;
                this.dataDropdown.funding_premium_index = dataReferenceIndex;
                this.keyDropDown.reference_index += 1;
                this.keyDropDown.settlement_index += 1;
                this.keyDropDown.funding_premium_index += 1;
            },
            changeBaseUnderDropdown(valueReturn) {
                let dataFundingBaseIndex = [];
                this.dataMaster.forEach(function (value) {
                    if (value.root_symbol && value.root_symbol == valueReturn.value) dataFundingBaseIndex.push(value.symbol);
                })
                this.dataDropdown.funding_base_index = dataFundingBaseIndex;
                this.keyDropDown.funding_base_index += 1;
                this.checkBUvsQC(valueReturn, 1)
            },
            checkBUvsQC(data, index){
                if(index == 1) {
                    if(this.dataInstrument.quote_currency && data.value && data.value == this.dataInstrument.quote_currency){
                        this.errors.add({
                            field: "quote_currency",
                            msg: window.i18n.t('instruments.value.must.difference', {field1: 'quote currency', field2: 'base underlying'})
                        });
                        return false;
                    }else{
                        this.errors.remove('quote_currency');
                    }
                }else if(index == 2) {
                    if(this.dataInstrument.base_underlying && data.value && data.value == this.dataInstrument.base_underlying){
                        this.errors.add({
                            field: "base_underlying",
                            msg: window.i18n.t('instruments.value.must.difference', {field1: 'base underlying',field2: 'quote currency'})
                        });
                    } else{
                        this.errors.remove('base_underlying');
                    }
                    return false;
                }else{
                    if(this.dataInstrument.base_underlying && this.dataInstrument.quote_currency && this.dataInstrument.quote_currency == this.dataInstrument.base_underlying){
                        this.errors.add({
                            field: "base_underlying",
                            msg: window.i18n.t('instruments.value.must.difference', {field1: 'base underlying',field2: 'quote currency'})
                        });
                        return false;
                    }
                }
                return true;
            },
            changeQuoteCurreny(valueReturn) {
                let dataFundingQuoteIndex = [];
                this.dataMaster.forEach(function (value) {
                    if (value.root_symbol && value.root_symbol == valueReturn.value) dataFundingQuoteIndex.push(value.symbol);
                })
                this.dataDropdown.funding_quote_index = dataFundingQuoteIndex;
                this.keyDropDown.funding_quote_index += 1;
                this.checkBUvsQC(valueReturn, 2)
            },
            changeSymbol() {
                if (!this.dataInstrument.symbol) {

                }
            },
            changeDatePicker(check) {
                check ? this.errors.remove('expiry') : this.errors.add({
                    field: "expiry",
                    msg: window.i18n.t('instruments.validate.required', {field: 'expiry'})
                });
            },
            changeValueRequire(data) {
                if(data == 'init_margin') {
                    if(Number(this.dataInstrument.maint_margin) && Number(this.dataInstrument.init_margin) && Number(this.dataInstrument.init_margin) <= Number(this.dataInstrument.maint_margin)){
                        this.dataInstrument.maint_margin = 0;
                    }
                }
                if(data == 'risk_limit') {
                    if(Number(this.dataInstrument.risk_step) && Number(this.dataInstrument.risk_limit) && Number(this.dataInstrument.risk_limit) <= Number(this.dataInstrument.risk_step)){
                        this.dataInstrument.maint_margin = 0;
                    }
                }
                return true

            },

            checkDataWithZero(value, field, name) {
                if (value <= 0) {
                    this.errors.add({
                        field: field,
                        msg: window.i18n.t('airdrop.error.field_min', {field_name: name})
                    });
                    return false;
                }
                return true;
            },
            
            checkValidateData(data) {
                if(data=='maint-margin') {
                    if(!Number(this.dataInstrument.init_margin) ){
                        if(Number(this.dataInstrument.maint_margin) >0) {
                            this.errors.add({
                                field: 'init_margin',
                                msg: window.i18n.t('airdrop.error.field_min', {field_name: 'Init Rate'})
                            });
                            return false;
                        }
                    }else{
                        if(Number(this.dataInstrument.maint_margin) && Number(this.dataInstrument.maint_margin) >= Number(this.dataInstrument.init_margin)){
                            this.errors.add({
                                field: 'maint_margin',
                                msg: window.i18n.t('instruments.value.must.lower')
                            });
                            return false;
                        }
                    }

                }
                if(data=='risk_step') {
                    if(!Number(this.dataInstrument.risk_limit) ){
                        if(Number(this.dataInstrument.risk_step) >0) {
                            this.errors.add({
                                field: 'risk_limit',
                                msg: window.i18n.t('instruments.validate.required', {field: 'risk step'})
                            });
                            return false;
                        }
                    }
                }
                return true;
            },
            customFormatter() {
                return 'YYYY-MM-DD H:m:s';
            },
            cancel() {
                this.$router.push({name: 'Instruments'});
            },
            validatefornegative(event) {

            },
            checkMultiplier(event){
                if(Number(event.target.value) > 1){
                    this.dataInstrument.multiplier = 1;
                }else if(Number(event.target.value) < -1){
                    this.dataInstrument.multiplier = -1;
                }
            },
            removeValidate(data) {
                this.errors.remove(data);
            },
            async validateData() {
                let data = await Object.keys(this.dataInstrument).map(
                    k => {
                        if (typeof this.dataInstrument[k] == 'string') {
                            this.dataInstrument[k] = this.dataInstrument[k].trim();
                        } else {
                            this.dataInstrument[k] = this.dataInstrument[k];
                        }
                    });
                let dataMaster = Object.assign({},this.dataInstrument);
                if(dataMaster.type== "FUTURE") {
                    delete dataMaster.funding_base_index;
                    delete dataMaster.funding_quote_index;
                    delete dataMaster.funding_premium_index;
                } else if(dataMaster.type =="PERPETUAL"){
                    delete dataMaster.expiry;
                }
                let validates = await Object.keys(dataMaster).forEach(
                    l => {
                        if (l == "state" && dataMaster[l] === "") {
                            this.errors.add({
                                field: l,
                                msg: window.i18n.t('instruments.validate.required', {field: _.replace(l, /\_/gi, ' ')})
                            });
                        }
                        if (l != "state" && !dataMaster[l]) {
                            this.errors.add({
                                field: l,
                                msg: window.i18n.t('instruments.validate.required', {field: _.replace(l, /\_/gi, ' ')})
                            });
                        }
                    });
                var ts = new Date();
                if(ts.toISOString() > dataMaster.expiry) {
                    this.errors.add({
                        field: 'expiry',
                        msg: window.i18n.t('instruments.validate.incorrectExpiry')
                    });
                }
                let validateMainMargin = await this.checkValidateData('maint-margin');
                let validateRiskStep = await this.checkValidateData('risk_step');
                let validateDropdowns = await this.checkBUvsQC({},0);
                let check  = validateMainMargin && validateRiskStep && validateDropdowns && this.errors.items <= 0  ?  true :  false;
                this.dataParams = dataMaster;
                check = this.checkDataWithZero(this.dataInstrument.init_margin, 'init_margin', 'Init Rate') && check;
                check = this.checkDataWithZero(this.dataInstrument.maint_margin, 'maint_margin', 'Maint Rate') && check;
                check = this.checkDataWithZero(this.dataInstrument.settlement_fee, 'settlement_fee', 'Settlement Fee') && check;
                check = this.checkDataWithZero(this.dataInstrument.tick_size, 'tick_size', 'Tick Size') && check;
                check = this.checkDataWithZero(this.dataInstrument.max_price, 'max_price', 'Max Price') && check;
                check = this.checkDataWithZero(this.dataInstrument.max_order_qty, 'max_order_qty', 'Max Order Qty') && check;
                check = this.checkDataWithZero(this.dataInstrument.risk_limit, 'risk_limit', 'Risk Limit') && check;
                check = this.checkDataWithZero(this.dataInstrument.risk_step, 'risk_step', 'Risk Step') && check; 
                if (Number(this.dataInstrument.maker_fee) + Number(this.dataInstrument.taker_fee) < 0) {
                    this.errors.add({
                        field: 'taker_fee',
                        msg: window.i18n.t('instrument.sum_greater_than_0')
                    });
                    check = false;
                }
                return  check;
            },
            async submit() {
                this.errors.clear();
                const validate = await this.validateData();
                if (validate) {
                    window.ConfirmationModal.show({
                    type: 'primary',
                    title: this.$t('instrument.confirm_remove'),
                    btnCancelLabel: window.i18n.t('common.action.no'),
                    btnConfirmLabel: window.i18n.t('common.action.yes'),
                    onConfirm: () => {
                        let data = Object.assign({},this.dataParams);
                        data.init_margin = Number(data.init_margin) ? Number(data.init_margin)/100 : 0;
                        data.maint_margin = Number(data.maint_margin) ? Number(data.maint_margin)/100 : 0;
                        data.maker_fee = Number(data.maker_fee) ? Number(data.maker_fee)/100 : 0;
                        data.taker_fee = Number(data.taker_fee) ? Number(data.taker_fee)/100 : 0;
                        data.state = Number(data.state) == 1 ? 'Open' : 'Pending';
                        data.type = data.type == 'FUTURE' ? INSTRUMENT_TYPES.FUTURE : INSTRUMENT_TYPES.PERPETUAL;
                        data.settlement_fee = Number(data.settlement_fee) ? Number(data.settlement_fee)/100 : 0;
                        data.multiplier = Number(data.multiplier)>1 ? 1 : (Number(data.multiplier)< -1  ? -1 : Number(data.multiplier));
                        if (data.type != 2) {
                            data.expiry = this.convertToUtcTime(data.expiry);
                        }
                        rf.getRequest("InstrumentRequest").createInstrument(data)
                            .then(res => {
                                if (res.success === true) {
                                    this.$toastr('success', window.i18n.t('instruments.create.success'));
                                    this.$router.push({name: 'Instruments'});
                                }else if(res.success===false){
                                    this.$toastr('error', res.message);
                                }
                            })
                            .catch(e => {
                                if(e.response && e.response.data) {
                                    this.$toastr('error', e.response.data.message);
                                    return ;
                                }
                                Message.error(window.i18n.t('common.message.network_error'), {}, {position: "bottom_left"});
                            });
                        
                    }
                    });
                }
            },
            convertToUtcTime(date) {
                var dateFormat = 'YYYY-MM-DD HH:mm:ss';
                return moment(date).utc().format(dateFormat);
            },
            getDataDropDown() {
                rf.getRequest("InstrumentRequest").getInstrumentDropdown()
                    .then(res => {
                        let rootSymbol = [];
                        let symbol = [];
                        if (res.data) {
                            this.dataMaster = res.data;
                            res.data.forEach(function (value) {
                                if (value.root_symbol && !rootSymbol.includes(value.root_symbol)) rootSymbol.push(value.root_symbol);
                                if (value.symbol && !symbol.includes(value.symbol)) symbol.push(value.symbol);
                            })
                            if (rootSymbol) {
                                this.dataDropdown.root_symbol = rootSymbol;
                                this.keyDropDown.root_symbol += 1;
                            }
                            if (symbol) {
                                this.dataDropdown.symbol = symbol;
                                this.keyDropDown.symbol += 1;
                            }
                        }
                    })
                    .catch(e => {
                        if (!error.response) {
                            Message.error(window.i18n.t('common.message.network_error'), {}, {position: "bottom_left"});
                            return;
                        }
                        this.convertRemoteErrors(error);
                    });
            },
            resetDataFollowType(check) {
                if (check) {
                    this.dataInstrument.funding_premium_index = "";
                    this.dataInstrument.funding_base_index = "";
                    this.dataInstrument.funding_quote_index = "";
                    
                }
            }
        },
        watch: {
            'dataInstrument.expiry': function (newVal, oldVal) {
                newVal && newVal !== oldVal ? this.changeDatePicker(true) : this.changeDatePicker(false);
            },
            'dataInstrument.type': function (newVal, oldVal) {
                newVal && newVal !== oldVal ? this.resetDataFollowType(true) : this.resetDataFollowType(false);
            }
        }
        ,
        created() {
            this.getDataDropDown();
        },
        mounted(){
            this.$emit('EVENT_PAGE_CHANGE', this);
        },
        directives: {
            "inputOnlyTwoRationalNumbers": {
                bind(el, binding, vnode) {
                    el.addEventListener('blur', () => {
                        // el.value = el.value ? Number(el.value.toString()) : '';
                        // if (String(el.value).includes(',')) {
                        //     el.value = String(el.value).replace(/\D/g, "");
                        // }
                        // if (!String(el.value).includes('.') && (Number(String(el.value).replace(/\D/g, "")) ==0)) {
                        //     el.value = el.value.slice(0, -1);
                        // }
                    })
                }
            },
            "inputLowerOne" :{
                bind(el, binding, vnode){
                    el.addEventListener('keyup', () => {
                        // if (String(el.value).includes(',')) {
                        //     el.value = String(el.value).replace(/\D/g, "");
                        // }
                        // // if (!String(el.value).includes('.')){
                        //     if(Math.abs((Number(String(el.value).replace(/\D/g, "")) > 1))) {
                        //         console.log(Math.abs((Number(String(el.value).replace(/\D/g, "")))));
                        //         if (String(el.value).includes('-')) {
                        //             console.log(el.value);
                        //             console.log(Number('-' + ((String(el.value).replace(/\D/g, "")).substring(0, 2)).replace(/-/g, "")));
                        //             vnode.context.multiplier = Number('-1');
                        //             el.value = Number('-1');
                        //         } else {
                        //             vnode.context.multiplier = Number('1');
                        //             el.value = Number('1');
                        //         }
                        //     }
                        // // }
                    })
                }
            }
        }
    }
</script>
<style lang="scss" scoped>
@import "@/assets/sass/variables";
    .min_750 {
      min-width: 750px;
    }
    .mw {
        min-width: 155px;
    }
    .error-message {
        color: $color-denim;
    }

    .errorInput {
        border: 1px solid $color-denim !important;
        border-radius: 5px;

    }

    .button-group {
        padding-top: 20px;
    }

    .form-group {
        .padding-unit {
            padding-right: 30px;
        }
    }

    input {
        background-color: #f8f8f8;
    }

    label {
        font-weight: normal;
        font-size: 14px;
        color: $color-grey-dusty;
        margin-bottom: unset;
        font-family: "Barlow-Medium";
    }

    .w-20 {
        width: 20px;
    }

    .unit {
        top: 18px;
        transform: translateY(-50%);
        right: 23px;
        position: absolute;
        width: 20px;
        text-align: center;
        text-transform: uppercase;
    }

    .tab-header {
        display: block;
        width: 100%;
        float: left;
        padding-top: 10px;

        ul {
            list-style: none;
            padding-left: 0px;

            li:first-child {
                margin-right: 30px;
            }

            li {
                float: left;
                font-size: $font_small;
                color: $color-grey-dark;
                display: block;

                > a {
                    color: $color-grey-dark;
                    font-family: 'Roboto-Regular';
                    font-weight: 500;
                    font-size: $font_small;
                    display: block;
                    float: right;

                    &::before {
                        content: "";
                        display: inline-block;
                        width: 12px;
                        height: 12px;
                        border-radius: 50%;
                        border: solid 1px #979797;
                    }

                    span {
                        color: $color-grey-dusty;
                        font-family: 'Roboto-Regular';
                        padding-left: 5px;
                        font-size: $font_small;
                        font-weight: normal;
                    }
                }

                &.active {
                    > a {
                        &::before {
                            border: solid 4px $color-jungle-green;
                        }

                        color: $color-jungle-green;
                    }
                }
            }
        }
    }

    @media (min-width: 768px) {
        .flex-group {
            display: -webkit-flex;
            display: flex;
            /*-webkit-align-items: center;*/
            /*align-items: center;*/
            label {
                top: 3px;
            }

        }
        /*.form-horizontal .flex-group .control-label {*/
        /*  padding-top: 0;*/
        /*}*/
    }
</style>
<style lang="scss">
@import "@/assets/sass/variables";

    #instrusmentResource_page {
        .calender-div {
            .year-month-wrapper {
                background-color: $color-jungle-green;

                .nav-l, .nav-r {
                    background-color: $color-jungle-green;
                }
            }

            .days, .okButton {
                color: $color-jungle-green;
            }

            .port {
                &:hover, &:active {
                    color: $color-jungle-green;
                }
            }

            .activePort {
                color: $color_white;
                background-color: $color-jungle-green;

                &:hover, &:active {
                    color: $color_white;
                    background-color: $color-jungle-green;
                }
            }

            .time-picker {
                .active {
                    background-color: $color-jungle-green;
                }
            }
        }

        input {
            min-width: unset;
        }

        input[type=number]::-webkit-inner-spin-button,
        input[type=number]::-webkit-outer-spin-button {
            /* display: none; <- Crashes Chrome on hover */
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
        }

        input[type=number] {
            -moz-appearance: textfield; /* Firefox */
        }

        font-family: "Barlow-Medium";

        .errorDropdown {
            input {
                color: $color-denim;
                border: 1px solid $color-denim !important;
            }

            .icon-dropdown-input {
                color: $color-denim;
            }
        }

        .datepicker-input {
            div {
                input {
                    width: 100%;
                    border: 1px solid #cccccc;
                    border-radius: 3px;
                    height: 35px;
                    padding-left: 10px;
                    background-color: #f8f8f8;
                    font-size: 14px;
                    font-family: "Barlow-Medium";
                    color: $color-grey-dark;
                    padding-right: 30px;
                }
            }

            .vdp-datepicker__calendar {
                width: unset;
            }
        }

        .coin-inputt {
            width: 100%;
            height: 35px;

            .icon-coin-input {
                left: unset;
                right: 10px;
            }

            .coin-option {
                width: 100%;
            }
        }
    }
</style>