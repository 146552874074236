<template>
  <div class="deposit_pages boxCore" id="order_future">
    <div class="d-flex w-100">
      <div class="filter-template">
        <div class='label-text'>{{ $t('funds.balances.from') }}</div>
        <date-picker v-model="startDate" class="date-picker-input" :format="customFormatter"></date-picker>
      </div>
      <div class="filter-template">
        <div class='label-text'>{{ $t('funds.history.to') }}</div>
        <date-picker v-model="endDate" class="date-picker-input" :format="customFormatter"></date-picker>
      </div>
      <div class="filter-template">
        <div class="label-text text-pair date-text">{{ $t('orders.open_order.symbol') }}</div>
        <div class="toolbar-element2">
          <select-box v-model="filters.symbol" :placeholder="$t('order.order_history.all')" :options="SymbolData" />
        </div>
      </div>
      <!-- <div class="filter-template">
        <div class='label-text'>Type</div>
        <div class="input-dropdown" v-click-outside="clickOut2">
          <input type="text" @click="showDropdownType" :placeholder=" $t('order.order_history.all') " class="form-control" v-model="typeFilter" readonly/>
          <span class="icon-coin-input" @click="showDropdownType"><i class="fa" :class="!isShowTypeOptions? 'fa-angle-down' : 'fa-angle-up' "></i></span>
          <div class="coin-option" v-if="isShowTypeOptions">
            <span class="option" @click="onSelectDropdown(null, 'type')">{{ $t('order.order_history.all') }}</span>
            <span class="option" v-for="type in TypesData" @click="onSelectDropdown(type, 'type')">{{ type.name  }}</span>

          </div>
        </div>
      </div>
      <div class="filter-template">
        <div class='label-text'>Status</div>
        <div class="input-dropdown" v-click-outside="clickOut3">
          <input type="text" @click="showDropdownStatus" :placeholder=" $t('order.order_history.all') " class="form-control" v-model="statusFilter" readonly/>
          <span class="icon-coin-input" @click="showDropdownStatus"><i class="fa" :class="!isShowStatusOptions? 'fa-angle-down' : 'fa-angle-up' "></i></span>
          <div class="coin-option" v-if="isShowStatusOptions">
            <span class="option" @click="onSelectDropdown(null, 'status')">{{ $t('order.order_history.all') }}</span>
            <span class="option" v-for="type in StatusData" @click="onSelectDropdown(type, 'status')">{{ type.name  }}</span>

          </div>
        </div>
      </div> -->
      <div class="filter-template">
        <div class="label-text">&nbsp;</div>
        <input
            title
            type="text"
            :placeholder="$t('account.detail.affiliate.inputplace.search')"
            @keyup.enter="search"
            class="form-control search_symbol"
            name="searchKey"
            v-model="filters.searchKey"
        >
      </div>
      <div class="filter-template">
        <div class="label-text">&nbsp;</div>
        <button class="btn btn-reset btn-search" @click="search()">{{ $t('orders.open_order.search') }}</button>
        <button class="btn btn-reset" @click="refresh()">{{ $t('orders.open_order.reset') }}</button>
        <button v-if="!accountId" class="btn btn-download" @click="downloadExcel()">
          <img src="@/assets/images/download-icon.svg" width="12" />
          {{ $t("orders.trade_history.data") }}
        </button>
      </div>
    </div>

    <div class="table-futures">
      <data-table-futures :getData="getFutureTradeHistoryRecords" ref="datatable" :column="12" :limit="10" :inLine="true" class="scroll" :total-user="totalRecords">
        <th class="text-left">{{ $t('order.open_order.id_trade') }}</th>
        <th v-if="!accountId" class="text-left">{{ $t('order.open_order.id_account') }}</th>
        <th class="text-left">{{ $t('order.open_order.id_buy') }}</th>
        <th class="text-left">{{ $t('order.open_order.id_sell') }}</th>
        <th class="text-left">{{ $t('order.open_order.symbol') }}</th>
        <th class="text-left">{{ $t('order.open_order.side') }}</th>
        <th class="text-left">{{ $t('order.open_order.qty') }}</th>
        <th class="text-left">{{ $t('order.open_order.price') }}</th>
        <!-- <th class="text-left" data-sort-field="trigger">{{ $t('order.open_order.trigger') }}</th>
        <th class="text-left" data-sort-field="price">{{ $t('order.open_order.price') }}</th> -->
        <th class="text-left">{{ $t('order.open_order.time') }}</th>
        <!-- <th data-sort-field="status" class="text-center">{{$t('funds.history.status')}}</th> -->
        <th class="text-center">{{$t('order.open_order.action')}}</th>

        <template slot="body" slot-scope="props">
          <tr>
            <td class="text-left" >
              {{props.item.id}}
            </td>
            <td v-if="!accountId" class="text-left">
              <!-- {{props.item.accountId}} -->
              <span class="text-account" v-if="props.item.side == 'sell'" @click="detailAccount(props.item.buyUserId)">{{ props.item.buyUserUid }}</span>
              <span class="text-account" @click="detailAccount(props.item.sellUserId)">{{ props.item.sellUserUid }}</span>
            </td>
            <td class="text-left">
              {{props.item.buyUserUid}}
            </td>
            <td class="text-left">
              {{ props.item.sellUserUid }}
            </td>
            <td class="text-left" >
              {{props.item.symbol }}
            </td>
            <td class="text-left">
              <div class="buy" v-if="props.item.side === 'BUY'">
                {{props.item.side | firstLetterCapitalize}}
              </div>
              <div class="sell" v-else>
                {{props.item.side | firstLetterCapitalize}}
              </div>
            </td>
            <td class="text-left" >
              <!-- {{props.item.quantity | to2Precision}} -->
              {{props.item.quantity | formatCurrencyAmountDecimal(8, "0")}}
            </td>
            <td class="text-left" >
              {{props.item.price | formatCurrencyAmountDecimal(8, "0")}}
            </td>
            <td class="text-left" >
              <div class="text-top">{{ props.item.createdAt | dateFormat2('YYYY-MM-dd') }} </div>
              <div class="text-bottom">{{ props.item.createdAt | timeFormat2('HH:mm:ss') }}</div>
            </td>
            <td class="text-center" >
            <span class="img-all">
                <img 
                  @click="handleOpen(props.item)"
                  class="imge" 
                  src="@/assets/images/future/List.svg" 
                  width="17px" 
                  height="17px"/>
            </span>&emsp;
            </td>
          </tr>
        </template>
      </data-table-futures>
    </div>
    <modal class="kyc_modal" :enableClose="enableClose" name="loadingUpload">
      <div slot="body">
        <vue-loading type="bars" v-if="isLoading" color="#2DAC91" :size="{ width: '60px', height: '80px' }">
        </vue-loading>
      </div>
    </modal>
    <div class="clearfix clearfix-40"></div>
    <right-slide :dataDetail="dataDetail" :rightSlide="rightSlide" :onClose="handleClose" />
  </div>
</template>

<script>

import moment from "moment";
import rf from "../../lib/RequestFactory";
import DatePicker from 'vuejs-datepicker';
import Modal from "../../components/Modal";
import SelectBox from "../../components/SelectBox";
import RightSlide from "../../components/RightSlide.vue";

export default {
  name: "OrderFuturesRecords",
  components: {
    Modal,
    DatePicker,
    SelectBox,
    RightSlide
  },
  props: {
    accountId: {
      type: String
    }
  },
  data() {
    return {
      titlePage: window.i18n.t("menu.futures.trade_history"),
      searchKey: '',
      isActive: true,
      startDate: moment().subtract(1, 'week').toDate(),
      endDate: moment().toDate(),
      filters: {
        searchKey: "",
        symbol: null,
        status: null,
        type: null,
      },
      symbolFilter: null,
      typeFilter: null,
      statusFilter: null,
      isLoading: false,
      TypesData: [
          {name: 'Limit', value: 'LIMIT'},
          {name: 'Market', value: 'MARKET'},
          {name: 'Stop Limit', value: 'STOP_LIMIT'},
          {name: 'Stop Market', value: 'STOP_MARKET'},
          {name: 'Trailing Stop', value: 'trailing_stop'},
      ],
      SymbolData: [{ id: "", name: window.i18n.t("order.order_history.all") }],
      StatusData: [
          {name: 'Filled', value: 'FILLED'},
          {name: 'Canceled', value: 'CANCELED'},
      ],
      isShowSideOptions: false,
      isShowTypeOptions: false,
      isShowStatusOptions: false,
      totalRecords: 0,
      enableClose: false,
      rightSlide: false,
      dataDetail: null,
    }
  },
  watch: {
    isActive() {
      this.$refs.datatable.refresh();
    }
  },
  methods: {
    selectTab(tab) {
      this.isActive = tab;
      this.filters.searchKey = '';
      this.$refs.datatable.refresh();
      this.startDate = moment().subtract(1, 'week').toDate();
      this.endDate = moment().toDate();
    },
    onSelectDropdown(value, type) {
      if (type == 'symbol') {
        this.isShowSideOptions = false;
        if( value === null ) {
          this.symbolFilter = 'All'
          this.filters[type] = null
        }else  {
          this.symbolFilter =  value?.name
          this.filters[type] = value.value;
        }
      } else if (type == 'status') {
        this.isShowStatusOptions = false;
        if( value === null ) {
          this.statusFilter = 'All'
          this.filters[type] = null
        }else  {
          this.statusFilter =  value?.name
          this.filters[type] = value.value;
        }
      } else {
        this.isShowTypeOptions = false;
        if( value === null ) {
          this.typeFilter = 'All'
          this.filters[type] = null
        }else  {
          this.typeFilter =  value?.name
          this.filters[type] = value.value;
        }
      }
      //   this.filters[type] = null
      // }else if ( type !== 'side') {
      //   this.typeFilter =  value?.name
      //   this.filters[type] = value.value;
      // } else {
      //   console.log(22321)
      //   this.filters[type] = value
      // }
    },
    clickOut1() {
      this.isShowSideOptions = false;
    },
    clickOut2() {
      this.isShowTypeOptions = false;
    },
    clickOut3() {
      this.isShowStatusOptions = false;
    },
    showDropdownSide() {
      this.isShowSideOptions = !this.isShowSideOptions;
    },
    showDropdownType() {
      this.isShowTypeOptions = !this.isShowTypeOptions;
    },
    showDropdownStatus() {
      this.isShowStatusOptions = !this.isShowStatusOptions;
    },

    customFormatter(date) {
      return moment(date).format('YYYY-MM-DD');
    },
    search() {
      let filterParams = {
        start_date: moment(this.startDate)
            .startOf("day")
            .format("x"),
        end_date: moment(this.endDate)
            .endOf("day")
            .format("x")
      };
      if (this.customFormatter(this.startDate) === this.customFormatter(this.endDate)) {
        filterParams = {
          start_date: moment(this.startDate)
              .startOf("day")
              .format("x"),
          end_date: moment(this.startDate)
              .endOf("day")
              .format("x")
        }
      }
      if (this.customFormatter(this.startDate) > this.customFormatter(this.endDate)) {
        this.$toastr("error", this.$t("common.date.warning"));
        return;
      }
      if (this.filters.searchKey) {
        filterParams.search_key = this.filters.searchKey;
      }
      this.$refs.datatable.filter(filterParams);
    },
    refresh() {
      this.resetInput();
      this.$refs.datatable.refresh();
    },
    resetInput() {
      this.filters = Object.assign(this.filters, {
        searchKey: "",
        side: null,
        symbol: null,
        status: null,
        type: null,
      });
      this.typeFilter = null
      this.symbolFilter = null
      this.startDate = moment().subtract(1, 'week').toDate();
      this.endDate = moment().toDate();
      $(".datatable").find("th").attr("data-sort-order", "");
    },
    listenForNotification() {
      window.Echo.channel('App.Models.Admin')
          .listen('AdminNotificationUpdated', () => {
            this.$refs.datatable.refresh();
          });
    },
    getFutureTradeHistoryRecords(params) {
      let filterParams = {
        start_date: this.customFormatter(this.startDate),
        end_date: this.customFormatter(this.endDate)
      };
      if (this.customFormatter(this.startDate) === this.customFormatter(this.endDate)) {
        filterParams = {
          start_date: this.customFormatter(this.startDate),
          end_date: this.customFormatter(this.endDate)
        }
      }
     
      let meta = {
        // isActive: this.isActive,
        from: filterParams.start_date,
        to: filterParams.end_date,
      };
      if (this.filters.symbol) {
        meta.symbol = this.filters.symbol.id;
      }
      if (this.filters.searchKey) {
        meta.search_key = this.filters.searchKey;
      }
      if (this.filters.side) {
        meta.side = this.filters.side;
      }
      if (this.filters.type) {
        meta.type = this.filters.type;
      }
      if (this.accountId || this.accountId != '') {
        meta.userId = this.accountId;
      }
      params = Object.assign(params, meta);
      params.size = params.limit || 5;
      delete params.limit;
      const records = rf.getRequest('AdminRequest').getFutureTradeHistoryRecords(params);
      records.then(data => {
        this.totalRecords = data.metadata.total;
      })
      return records;
    },
    getCurrencyPositionRecord(value) {
       if(value.contractType === 'COIN_M') {
        // return value.asset
        return 'Cont'
      }else {
        const index = value.symbol?.indexOf(value.asset);
        // return value.symbol?.slice(0, index) + value.symbol?.slice(index + value?.asset?.length);
        return value.symbol
      }
    },
    // filledOrder(value) {
    //   return new BigNumber(value.quantity).minus(value.remaining)
    // }

    loadingUploadBegin() {
      this.enableClose = false;
      this.isLoading = true;
      CommonModal.show('loadingUpload');
    },
    loadingUploadFinish() {
      CommonModal.hide('loadingUpload');
    },
    handleClose() {
      this.dataDetail = null;
      this.rightSlide = false;
    },
    handleOpen(data) {
      // console.log(data)
      this.dataDetail = data;
      this.rightSlide = true;
    },

    getOptionData() {
      this.SymbolData = [{ id: "", name: window.i18n.t("order.order_history.all") }];
      rf.getRequest("AdminRequest")
        .getInstruments()
        .then((res) => {
          res?.data.map((item) => {
            this.SymbolData.push({
              id: item.name,
              name: item.symbol
            })
          })
        });
    },

    downloadExcel() {
      let filterParams = {
        from: moment(this.startDate).format('YYYY-MM-DD'),
        to: moment(this.endDate).format('YYYY-MM-DD')
      };
      if (this.startDate > this.endDate && this.startDate.toDateString() != this.endDate.toDateString()) {
        this.$toastr("error", this.$t("common.date.warning"));
        return;
      }
      if (this.filters.symbol) {
        filterParams.symbol = this.filters.symbol.id;
      }
      if (this.filters.searchKey) {
        filterParams.search_key = this.filters.searchKey;
      }

        this.loadingUploadBegin();
      rf.getRequest('AdminRequest').exportTradeHistoryFutureRecords(filterParams).then(res => {
        if (res?.code === 200) {
        const byteCharacters = atob(res.data.base64Data);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);

        // Create a Blob from ArrayBuffer
        const blob = new Blob([byteArray], {
            type:'application/vnd.openxmlformatsofficedocument.spreadsheetml.sheet',
        });

        // Create a temporary anchor element
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = res.data.fileName; // File name
        document.body.appendChild(link);

        // Programmatically click the link to trigger the download
        link.click();

        // Clean up
        document.body.removeChild(link);
        window.URL.revokeObjectURL(link.href);
        }
              this.loadingUploadFinish();
      }).catch(error => {
        alert(error)
        console.log("🚀 ~ rf.getRequest ~ error:", error)
              this.loadingUploadFinish();
      })
    },
    detailAccount(id) {
      this.$router.push({
        path: '/account/detail',
        query: { id: id },
      });
    },
  },
  created() {
    this.getOptionData();
  },
  mounted() {
    this.$emit('EVENT_PAGE_CHANGE', this);
    this.listenForNotification();
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/variables";
.d-flex {
  display: flex;
}
.w-100 {
  width: 100%;
}
.buy {
  color: $text-color-jade!important;
}
.sell {
  color: $text-color-red!important;
}
#order_future {
  table {
    thead {
      th {
        &:first-child {
          width: 9%;
          min-width: 100px;
        }
        &:nth-child(2) {
          width: 9%;
          min-width: 120px;
        }
        &:nth-child(3) {
          width: 8%;
          min-width: 100px;
        }
        &:nth-child(4) {
          width: 9%;
          min-width: 100px;
        }
        &:nth-child(5) {
          width: 11%;
          min-width: 120px;
        }
        &:nth-child(6) {
          width: 13%;
          min-width: 125px;
        }
        &:nth-child(7) {
          width: 10%;
          min-width: 110px;
        }
        &:nth-child(8) {
          width: 9%;
          min-width: 100px;
        }
        &:nth-child(9) {
          width: 9%;
          min-width: 100px;
        }
        &:nth-child(10) {
          width: 6%;
          min-width: 80px;
        }
      }
    }
  }
}

.no-left-radius {
  input {
    border-radius: 0px 3px 3px 0px;
  }
}
.no-right-radius {
  input {
    border-radius: 3px 0px 0px 3px;
  }
}
td{
  word-break: break-all;
  vertical-align: middle!important;
  border-bottom: solid 1px $color-white-smoke;
  .bt {
    width: 100px;
    height: 36px;
    border-radius: 3px;
    color: $color_black_logo;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 12px;
    cursor: pointer;
    &.filled {
      background: $color-filled;
    }
    &.canceled {
      background: $color-cancel;
    }
  }
  .img-all {
    .imge {
      margin: 0 8px;
      cursor: pointer;
    }
  }
  .text-account {
    text-decoration: underline;
    color: $color-blue-custom;
    cursor: pointer;
  }
}
.datatable{
  font-family: $font-family-roboto !important;
}

ul {
  list-style-type: none;
  padding: 0px;
}
.clearfix-40 {
  display: block;
  clear: both;
  height: 40px;
}
.deposit_pages {
  font-family: $font_family_root;
  .filter_container {
    margin: 12px 0px;
    .title_item {
      color: $color_mine_shaft;
      font-size: $font_big_20;
      font-weight: 500;
      line-height: 28px;
      float: left;
    }
    .search_box {
      display: inline-block;
      float: right;
      width: 215px;
      max-width: 100%;
      .search_input {
        background-color: transparent;
        height: 28px;
        border: 1px solid $color_alto;
        padding: 4px 15px;
        line-height: 20px;
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: $font-small;
      }
    }
  }
  .item_email_user {
    display: inline-block;
    float: left;
    position: relative;
    .txt_email_user {
      display: block;
      max-width: 170px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .tooltip_email_user {
      position: absolute;
      top: 0px;
      left: 0px;
      line-height: 20px;
      padding: 5px 20px;
      left: 100%;
      background-color: $color_white;
      white-space: nowrap;
      width: auto;
      z-index: 10;
      font-size: $font_root;
      font-weight: 500;
      color: $color_mine_shaft;
      transition: 0.5s;
      display: none;
      box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.4);
      &:after {
        right: 100%;
        top: 50%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: rgba(136, 183, 213, 0);
        border-right-color: $color_white;
        border-width: 5px;
        margin-top: -5px;
      }
    }
    &:hover {
      .tooltip_email_user {
        display: block;
        transition: 0.5s;
      }
    }
  }
  .item_bank_user {
    display: inline-block;
    float: left;
    position: relative;
    .txt_bank_user {
      display: block;
      max-width: 250px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .tooltip_bank_user {
      position: absolute;
      top: 0px;
      left: 0px;
      line-height: 20px;
      padding: 5px 20px;
      left: 100%;
      background-color: $color_white;
      // white-space: nowrap;
      width: 250px;
      z-index: 10;
      font-size: $font_root;
      font-weight: 500;
      color: $color_mine_shaft;
      transition: 0.5s;
      display: none;
      box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.4);
      &:after {
        right: 100%;
        top: 12px;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: rgba(136, 183, 213, 0);
        border-right-color: $color_white;
        border-width: 5px;
        margin-top: -5px;
      }
    }
    &:hover {
      .tooltip_bank_user {
        display: block;
        transition: 0.5s;
      }
    }
  }
  .btn_Confirm,
  .btn_Reject {
    background-color: $color-caribbean-green;
    color: $color-white;
    text-transform: uppercase;
    width: 82px;
    height: 25px;
    line-height: 20px;
    padding: 0px 9px;
    text-align: center;
    font-size: $font-smaller;
    font-family: $font-family-roboto-bold;
    border: 1px solid $color-caribbean-green;
    border-radius: 3px;
    transition: 0.5s;
    &:hover {
      background-color: $color-aquamarine;
      border-color: $color-aquamarine;
      transition: 0.5s;
    }
  }
  .btn_Reject {
    margin-left: 10px;
  }
  .list_modal_deposit {
    margin-bottom: 25px;
    padding: 0px;
    li {
      line-height: 20px;
      margin-bottom: 10px;
      color: $color_dove_gray;
      font-size: $font_root;
      font-weight: 500;
      .text-right {
        font-weight: 600;
        color: $color_mine_shaft;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  .content_modal_deposit {
    color: $color_mine_shaft;
    font-size: $font_semi_big;
    font-family: $font-family-roboto-medium;
    font-weight: 500;
    text-align: center;
    line-height: 24px;
  }
}
.select_transaction_container {
  padding: 10px 0px 20px 0px;
  .input-radio {
    label {
      display: inline;
      font-weight: normal;
      text-align: center;
      margin-right: 25px;
      span {
        font-size: $font_big;
      }
      input[type="radio"] {
        display: inline-block;
        margin-top: 0px;
        position: relative;
        top: -2px;
      }
    }
  }
}
.filter-container {
  margin: 10px 0 30px 0px;
  height: 60px;
  .filter-item {
    .label-text {
      font-size: $font-small;
      color: $color-grey-dusty;
      padding-bottom: 4px;
    }
    float: left;
    margin: 0px;
    margin-right: 20px;
    input {
      background-color: $color-white-smoke;
      margin-top: 22px;
      display: inline-block;
      width: 145px;
    }
    select {
      width: 65px;
      display: inline-block;
    }
    button {
      margin: 0 5px;
      width: auto;
      margin-top: 22px;
      height: 30px;
      font-size: 13px;
    }
    .btn-search, .btn-reset {
      font-size: $font-smaller;
      font-family: $font-family-roboto-bold;
      border-radius: 10px;
      border: 1px solid $color-caribbean-green;
      width: 85px;
      color: $color_white;
      text-transform: uppercase;
      background-color: $color-caribbean-green;
      &:hover {
        background-color: $color-aquamarine;
        border-color: $color-aquamarine;
        transition: 0.5s;
      }
    }
  }
  .btn-action {
    margin-left: -10px;
  }
}
.form-control {
  background: $color_concrete;
  height: 30px;
  font-size: $font-small;
  padding-left: 11px;
  padding-right: 0;
  &:hover, &:focus {
    border-color: $color-jungle-green;
  }
}
.toolbar-element2 {
    display: inline-block;
    float: left;
    width: 100%;
    min-width: 110px;
  }
.datatable {
  button.btnForm.bt-action {
    padding-left: 5px !important;
    padding-right: 5px !important;
    font-weight: 400 !important;
  }
}
.buy {
  color: $text-color-jade;
}
.sell {
  color: $text-color-red;
}
.toolbar-element2 {
  display: inline-block;
  float: left;
  width: 65px;
}
td {
  font-family: $font-family-roboto;
}
.table-futures {
  margin-top: 24px;
}
.filter-template {
  margin-right: 15px;
  .label-text {
    color: $dark-1;
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 7px;
  }
  .date-picker-input {
    display: inline-block;
    float: left;
    ::v-deep{
      input {
        padding: 0 5px 0px 36px;
        width: 135px !important;
        height: 32px;
        border: 1px solid #C7CBD3!important;
        border-radius: 10px!important;
        background-image: url('@/assets/images/icon/date-time.svg') !important;
        background-color: $color-white-smoke !important;
        background-size: 12px !important;
        background-position: center left 12px !important;
        background-repeat: no-repeat !important;
        color: $dark-1;
        &:hover, &:active, &:focus {
          border-color: $color-jungle-green;
          z-index: 9;
        }
      }
    }

  }
  .btn-reset {
    background: $color-caribbean-green;
    border-radius: 10px;
    color: $color_white;
    font-weight: 700;
    width: 78px;
    height: 32px;
  }
  .btn-search {
    margin: 0 10px;
  }
  .btn-download {
    background: $color-caribbean-green;
    border-radius: 10px;
    color: $color_white;
    font-weight: 700;
    width: 95px;
    height: 32px;
    margin-left: 10px;
  }
  .search_symbol {
    background: $color-white;
    border: 1px solid #C7CBD3!important;
    border-radius: 10px!important;
    width: 197px;
    height: 32px;
  }
}
.tab-header {
  display: block;
  width: 100%;
  float: left;
  margin: 10px 0px 23px 0px;
  ul {
    list-style: none;
    padding-left: 0px;
    li {
      float: left;
      margin-right: 25px;
      font-size: $font_big;
      color: $color-grey-dark;
      display: block;
      >a {
        color: $color-grey-dark;
        font-family: $font-family-roboto-medium;
        font-weight: 500;
        font-size: $font_big;
        display: block;
        float: right;
        &::before {
          content: "";
          display: inline-block;
          width: 12px;
          height: 12px;
          border-radius: 50%;
          border: solid 1px #979797;
        }
        span {
          padding-left: 5px;
        }
      }
      &.active {
        >a {
          &::before {
            border: solid 4px $color-jungle-green;
          }
          color: $color-jungle-green;
        }
      }
    }
  }
}
.input-dropdown {
  position: relative;
  input {
    background: $color-white;
    border: 1px solid #C7CBD3!important;
    border-radius: 10px!important;
    width: 110px;
    height: 32px;
    cursor: pointer;
  }
  .icon-coin-input {
    position: absolute;
    right: 10px;
    top: 50%;
    transform:translateY(-50%);
    cursor: pointer;
    color:#667186;
    i {
      font-size: 22px;
    }
  }
  .coin-option {
    position: absolute;
    top: 37px;
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0px 4px 15px rgba(0, 19, 54, 0.15);
    border-radius: 10px;
    padding: 17px 0;
    z-index: 3;
    &:after {
      bottom: 100%;
      left: 50%;
      border: solid transparent;
      content: " ";
      display: block;
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-color: rgba(136, 183, 213, 0);
      border-bottom-color: $color-white;
      border-width: 5px;
      margin-left: -5px;
    }
    .option {
      display: flex;
      align-items: center;
      width: 100%;
      line-height: normal;
      cursor: pointer;
      padding-left: 14px;
      font-size: 14px;
      font-weight: 500;
      overflow: hidden;
      color: #001336;
      height: 32px;

      &:hover {
        background: $color-athens;
      }
    }
  }
}
</style>

<style lang="scss">
@import "@/assets/sass/variables";
#deposit_fiat_pages {
  .sc_search_select {
    .group_search_select {
      .button_drop_search {
        width: 55px;
        height: 30px !important;
        padding: 5px 10px 5px 10px !important;
        border-radius: 3px;
        margin-right: 20px;
        font-size: $font-small;
        font-family: $font-family-roboto;
        i.icon-arrow1 {
          margin-left: 5px;
          color: $color_dove_gray;
        }
        &:hover {
          border-color: $color-jungle-green;
          i.icon-arrow1 {
            color: $color-jungle-green;
          }
        }

      }
      .box_list_search_select {
        .list_search_select {
          li {
            font-family: $font-family-roboto;
            font-size: $font-small;
            &:hover, &:active, &:focus {
              color: $color-jungle-green;
            }
          }
        }
      }
    }
  }
}
</style>