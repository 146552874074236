<template>
  <div id="exchange_balance" class="clearfix mg_bot50">
    <div class=" max_width">

      <div class="datatable">
        <data-table2 :getData="getData" :limit="10" :column="3" class="scroll">
          <th colspan="2">{{this.$t('funds.balances.curency')}}</th>
          <th>{{this.$t('funds.balances.value')}}</th>
          <template slot="body" slot-scope="props">
            <tr>
              <td class="exchange-coin" rowspan="8">{{ props.index }}</td>
              <td >{{ $t('exchange.table.hot_wallet_address') }}</td>
              <td >{{  props.item.hotWalletAddress }}</td>
            </tr>
            <tr>
              <td >{{ $t('exchange.table.hot_wallet_balance') }}</td>
              <td >{{  props.item.hotWalletBalance  }} <span class="exchange-coin">{{props.index}}</span></td>
            </tr>
            <tr>
              <td >{{ $t('exchange.table.cold_wallet_balance') }}</td>
              <td >{{  props.item.amountCollectColdWallet }} <span class="exchange-coin">{{props.index}}</span></td>
            </tr>
            <tr>
              <td >{{ $t('exchange.table.hot_wallet_collected_fee') }}</td>
              <td >{{  props.item.feeCollectHotWallet }} <span class="exchange-coin">{{convertTypeFee(props.item.token_type)}}</span></td>
            </tr>
            <tr>
              <td >{{ $t('exchange.table.cold_wallet_collected_fee') }}</td>
              <td >{{  props.item.feeCollectColdWallet }} <span class="exchange-coin">{{convertTypeFee(props.item.token_type)}}</span></td>
            </tr>
            <tr>
              <td >{{ $t('exchange.table.total_wallet_balance') }}</td>
              <td >{{  props.item.totalBalance }} <span class="exchange-coin">{{props.index}}</span></td>
            </tr>
            <tr>
              <td >{{ $t('exchange.table.total_user_balance') }}</td>
              <td >{{  props.item.balances }} <span class="exchange-coin">{{props.index}}</span></td>
            </tr>
            <tr>
              <td class="font-bold">{{ $t('exchange.table.profit')}}</td>
              <td class="font-bold">{{ subTotal(props.item.totalBalance, props.item.balances) }}
                <span class="exchange-coin">{{props.index}}</span>
              </td>
            </tr>
          </template>
        </data-table2>
      </div>
    </div>
  </div>
</template>

<script>
  import BigNumber from 'bignumber.js';
  import rf from '../../lib/RequestFactory';


  export default {
    components: {
    },
    data() {
      return {
        titlePage: this.$t('exchange_balance.exchange_balance'),
      }
    },

    methods: {
      convertTypeFee(type) {
        if(!type) {
          return 'USD'
        } else if(type === 'polygon') {
          return 'MATIC'
        } else if (type.includes('token')) {
          const index = type.indexOf('_')
          return type.slice(0, index)
        } else if (type === 'tron') {
          return 'TRX'
        } else {
          return type.toUpperCase()
        }
      },
      getDataBuyPath(props, path) {
        return window._.get(props, path , 0);
      },
      getData(params) {
        return rf.getRequest('CoinSettingRequest').getWalletBalances(params);
      },
      subTotal(totalBalance, balance) {
        return new BigNumber(totalBalance || 0 ).sub(balance  || 0 ).toString();
      }
    },

    mounted() {
      this.$emit('EVENT_PAGE_CHANGE', this);
    }
  }
</script>

<style lang="scss" scoped>
@import "@/assets/sass/variables";

  #exchange_balance {
    margin-top: 10px;
    .font-bold{
      font-weight: bold;
      background:$color_catskill_white;
    }
    .max_width {
      display: block;
      // width: 700px;
      max-width: 100%;
    }

    .exchange-coin {
      width: 7%;
      text-transform: uppercase;
    }

    th {
      font-family: $font-family-roboto;
      font-size: $font-small;
      padding: 5px 25px 4px 15px !important;
    }

    th:nth-child(2){
      border-left: 1px solid $color_alto;
    }

    td {
      font-family: $font-family-roboto;
    }
    tr td:first-child{
      border-right: 1px solid $color_alto;
      border-bottom: 1px solid $color_alto;
    }
    tr td:nth-child(2){
      border-top: 1px solid $color_alto;
      border-bottom: 1px solid $color_alto;
      border-left: 1px solid $color_alto;
    }
    tr td:last-child{
      border-left: 1px solid $color_alto;
      border-bottom: 1px solid $color_alto;
    }

    .text-right::after {
      display: none;
    }
  }
</style>