<template>
  <div class="userInformation boxCore" id="tradingFeeSetting">

    <div class="content_TradingFeeSetting clearfix">
      <div class="filter_container clearfix">
        <span class="title_item">{{ $t('trading_fee_setting.trading_selectMarket') }}</span>
        <select-box v-model="currency" :options="markets"/>
      </div>
      <div class="clearfix"></div>

      <div class="datatable">
        <data-table :getData="getData" ref="tradeFeeDatatable" :limit="10" :column="6" class="scroll" @DataTable:finish="onDatatableFinish">
          <th class="text-left col-coin">{{ $t('trading_fee_setting.coin') }}</th>
          <th class="text-left col-fee-taker">
            {{ $t('trading_fee_setting.fee_taker') }} (%)
          </th>
          <th class="text-left col-fee-maker">{{ $t('trading_fee_setting.fee_maker') }} (%)</th>
          <th class="last-table text-right"></th>
          <template slot="body" slot-scope="props">
            <tr>
              <td class="text-left trading-coin">
                {{props.item.coin}}
              </td>
              <td class="text-left">
                <input-only-number
                  v-if="props.item.editable"
                  name="fee_taker"
                  class="form-control fl-left"
                  v-model="props.item.fee_taker"
                  @dirty="onHandleDirtyData($event, props.item)"
                  maxlength="7"
                  precision="4"
                />
                <span v-else>{{!props.item.fee_taker ? "" : Number(props.item.fee_taker)  | toNumber}} {{!props.item.fee_taker ? '' : '%'}}</span>
              </td>
              <td class="text-left">
                <input-only-number v-if="props.item.editable" class="form-control fl-left" name="fee_maker" precision="4"
                  v-model="props.item.fee_maker" @dirty="onHandleDirtyData($event, props.item)"  maxlength="7"/>
                <span v-else>{{!props.item.fee_maker ? "" : Number(props.item.fee_maker) | toNumber}} {{!props.item.fee_maker ? '' : '%'}}</span>
              </td>
              <td class="text-right">
                <button v-if="props.item.editable" class="btn btn_save_network" @click="revert(props.item)">
                  <i class="icon-reload3"></i>
                </button>
                <button v-if="props.item.editable" class="btn btn_save_network" @click="change(props.item)">
                  <i class="icon-save"></i>
                </button>
                <button v-else class="btn btn_edit_network" @click="edit(props.item)">
                  <i class="icon-edit"></i>
                </button>
              </td>
            </tr>
          </template>
        </data-table>
      </div>
    </div>

    <div class="clearfix clearfix-40"></div>
    <div class="line_page"></div>

    <div class="content_WithdrawFeeSetting clearfix">
      <withdraw-fee-setting/>
    </div>

    
  </div>
</template>
<script>
  import rf from '../../lib/RequestFactory';
  import InputOnlyNumber from '../circuit_breaker/InputOnlyNumberCircuitBreaker';
  import WithdrawFeeSetting from './WithdrawFeeSetting';
  import SelectBox from '../../components/SelectBox';
  import BigNumber from 'bignumber.js';
  // import dataTableFee from '../../components/DataTableFee'

  export default {
    components: {
      WithdrawFeeSetting,
      InputOnlyNumber,
      SelectBox,
    },
    data() {
      return {
        titlePage: this.$t('trading_fee_setting.trading_fee_setting'),
        showInputFee: false,
        currency: {id: 'btc', name: 'BTC'},
        markets: [],
      }
    },

    watch: {
      currency(newValue) {
        this.$refs.tradeFeeDatatable.refresh();
      }
    },
    computed: {
      trimZeroNumber : function(item) {
        return item.fixed(2);
      },
    },

    methods: {

      getData(params) {
        let defaultParams = {
          currency: this.currency.id
        };
        return rf.getRequest('AdminRequest').getMarketFeeSetting(Object.assign(defaultParams, params))
        .then(res => {
          let dataRewrite = res.data;
          let newData = res.data.data;
          newData.forEach(el => {
            el.id,
            el.currency,
            el.fee_taker = el.fee_taker ? new  BigNumber(el.fee_taker).toFixed(4).replace(/(?:(\.\d*?[1-2]+)|\.)0*$/gm, "$1")  : 0,
            el.fee_maker = el.fee_maker ? new  BigNumber(el.fee_maker).toFixed(4).replace(/(?:(\.\d*?[1-2]+)|\.)0*$/gm, "$1")  : 0,
            el.coin
          });
          dataRewrite.data = newData;
          return {
            data: dataRewrite,
          };
        });
      },

      onDatatableFinish() {
        window._.each(this.$refs.tradeFeeDatatable.rows, item => {
          this.$set(item, 'editable', false);
          this.$set(item, 'isDirty', false);
        });
      },

      onHandleDirtyData($event, item) {
        item.isDirty = $event;
      },

      edit(item) {
        item.revert = {
          fee_taker: item.fee_taker,
          fee_maker: item.fee_maker
        };
        item.editable = !item.editable;
      },
      resetErrors(){
        this.error_validate.fee_taker = false;
        this.error_validate.free_maker = false;
      },
      checkValidatePercent(item){ 
        if(!item.fee_taker || !item.fee_taker.trim() || item.fee_taker == 0) {
          this.$toastr('error', this.$t('trading_fee_setting.validate.required',{0: this.$t('tradding_fee_setting.fee_taker') }));
          return false;
        }
        if(!item.fee_maker || !item.fee_maker.trim() || item.fee_maker == 0) {
          this.$toastr('error', this.$t('trading_fee_setting.validate.required',{0: this.$t('tradding_fee_setting.fee_maker') }));
          return false;
        }
        let fee_taker = item.fee_taker.split(".");
        let fee_maker = item.fee_maker.split(".");
        if( Number(fee_taker[0]) > 100 || ( Number( fee_taker[0] ) == 100 && Number(fee_taker[1] )>0 ) ){
          // this.error_validate = {fee_taker : true};
          this.$toastr('error', this.$t('trading_fee_setting.under_101_percent',{0: this.$t('tradding_fee_setting.fee_taker') }));
          return false;
        }
        if( Number(fee_maker[0]) > 100 || ( Number( fee_maker[0] ) == 100 && Number(fee_maker[1] )>0 ) ){
          // this.error_validate = {fee_taker : true};
          this.$toastr('error', this.$t('trading_fee_setting.under_101_percent',{0: this.$t('tradding_fee_setting.fee_maker') }));
          return false;
        }
        return true;
      },

      async change(item) {
        // No change data.
        if (!item.isDirty) {
          item.fee_taker =  new  BigNumber(item.fee_taker).toFixed(4).replace(/(?:(\.\d*?[1-2]+)|\.)0*$/gm, "$1");
          item.fee_maker =  new  BigNumber(item.fee_maker).toFixed(4).replace(/(?:(\.\d*?[1-2]+)|\.)0*$/gm, "$1");
          item.editable = !item.editable;
          return;
        }
        let validator = await this.checkValidatePercent(item);
        /*
        if (!this.validate()) {
          this.$toastr('error', this.$t('trading_fee_setting.msg_coin_holding'));
          return;
        }
        */
       if(validator) {
         item.fee_taker =  new  BigNumber(item.fee_taker).toFixed(4).replace(/(?:(\.\d*?[1-2]+)|\.)0*$/gm, "$1");
         item.fee_maker =  new  BigNumber(item.fee_maker).toFixed(4).replace(/(?:(\.\d*?[1-2]+)|\.)0*$/gm, "$1");
        ConfirmationModal.show({
          type            : 'confirm',
          title           : this.$t('trading_fee_setting.update_confirm'),
          content         : '',
          btnCancelLabel  : this.$t('common.action.no'),
          btnConfirmLabel : this.$t('common.action.yes'),
          size            : 'small',
          onConfirm       : () => {
            const params = {
              id: item.id,
              currency: this.currency.id,
              coin: item.coin,
              fee_taker: !item.fee_taker ? 0 : item.fee_taker,
              fee_maker: !item.fee_maker ? 0 : item.fee_maker
            };
            rf.getRequest('AdminRequest').updateMarketFeeSetting(params).then(res => {
              item.editable = !item.editable;
              this.$toastr('success', this.$t('trading_fee_setting.update_success_msg'));
            }).catch(error => {
              if (!error.response) {
                this.$toastr('error', this.$t('common.message.network_error'));
                return;
              }
            });
          },
          onCancel        : () => {}
        })
       }
      },

      revert(item) {
        item.fee_taker = item.revert.fee_taker;
        item.fee_maker = item.revert.fee_maker;
        item.isDirty = false;
      },

      validate() {
        const isValidAmountHolding = window._.chain(this.$refs.tradeFeeDatatable.rows)
          .map(item => {
            item.level = parseInt(item.level);
            item.mgc_amount = parseInt(item.mgc_amount);
            return item;
          })
          .orderBy(['level'])
          .map(item => item.mgc_amount)
          .reduce((a,v) => ( a !== false) && (a < v) ? v : false, -Infinity)
          .value();
        return isValidAmountHolding;
      },
    },

    mounted() {
      this.$emit('EVENT_PAGE_CHANGE', this);
    },

    created() {
      rf.getRequest('AdminRequest').getPriceGroupCurrency().then(res => {
        try {
          window._.map(res.data, item => {
            if (typeof item.currency !== 'undefined') {
              this.markets.push({ id: item.currency, name: item.currency.toUpperCase() });
            }
          });
        }
        catch (ex) {
          console.error(ex);
        }
      });
    }
  }
</script>
<style lang="scss">
@import "@/assets/sass/variables";
  
  #tradingFeeSetting {
    .sc_search_select {
      float: left;
      min-width: 55px;
      .group_search_select {
        .button_drop_search {
          height: 30px !important;
          padding: 5px 10px !important;
          span {
            font-size: $font-small;
            font-family: $font-family-roboto;
          }
          i {
            color: $color-grey-dusty;
          }
        }
        .list_search_select {
          li {
            font-size: $font-small;
            font-family: $font-family-roboto;
            &:hover, &:active, &:focus {
              color: $color-jungle-green;
            }
          }
        }
      }
    }
    .filter_container {
      .sc_search_select {
        margin-left: 15px;
        min-width: 85px;
      }
    }
  }
</style>
<style lang="scss" scoped>
@import "@/assets/sass/variables";
  .input-error {
    border: 1px solid $color-denim !important;
  }
  .per-left{

  }
  .fl-left{
    float: left;
  }
  th, td {
    font-family: $font_family_root;
    &.trading-coin {
      text-transform: uppercase;
    }
    &.col-coin {
      width: 20%;
    }
    &.col-fee-taker {
      width: 30%;
    }
    &.col-fee-maker {
      width: 30%;
    }
  }

  .last-table {
    width: 100px;
  }
  .line_page {
    display: block;
    width: 100%;
    height: 1px;
    background-color: $color_alto; 
  }
  .clearfix-40 {
    display: block;
    clear: both;
    height: 40px;
  }

  .userInformation {

    .filter_container {
      margin: 10px 0px 20px 0px;
      .title_item {
        color: $color_mine_shaft;
        font-size: $font_big;
        font-family: $font-family-roboto-medium;
        line-height: 28px;
        float: left;
      }
      .search_box {
        display: inline-block;
        float: right;
        width: 215px;
        max-width: 100%;
        .search_input {
          background-color: transparent;
          height: 28px;
          border: 1px solid $color_alto;
          padding: 4px 15px;
          line-height: 20px;
          width: 100%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          font-size: $font-small;
        }
      }
    }
    .datatable {
      .form-control {
        background-color: transparent;
        height: 27px;
        border: 1px solid $color_alto;
        padding: 3px 13px;
        line-height: 20px;
        width: 140px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
    .btn_edit_network {
      background-color: transparent !important;
      font-size: $font_semi_big;
      color: $color-jungle-green;
      height: 27px;
      padding: 2px 0px;
      line-height: 23px;
      margin-left: 15px;
      &:active {
        box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0);
      }
      &:hover {
        color: $color-jungle-green;
      }
    }
    .btn_save_network {
      background-color: transparent !important;
      font-size: $font_semi_big;
      color: $color-jungle-green;
      height: 27px;
      padding: 2px 0px;
      line-height: 23px;
      margin-left: 15px;
      .icon-save:before {
        color: $color-jungle-green;
      }
      &:active {
        box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0);
      }
      &:hover {
        color: $color-jungle-green;
        .icon-save:before {
          color: $color-jungle-green;
        }
      }
    }
  }
</style>