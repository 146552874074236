<template>
  <div id="faq_edit">
    <div class="page960">
      <div class="section">
        <div class="form-notice-detail-container">
          <div id="notice-detail">
            <div class="notice-header">
              <div class="notice-header-item">
                <span class="notice-header-label">{{ $t('cms.qna.question_author') }}: </span>
                <span class="notice-header-value">{{ record?.user_id || "" }}</span>
              </div>
              <div class="notice-header-item">
                <span class="notice-header-label">{{ $t('cms.qna.question_created') }}: </span>
                <span class="notice-header-value">{{ record?.created_at | formatDate }}</span>
              </div>
            </div>
            <!-- <div class="notice-detail-form-group">
              <label class="notice-detail-form-label">{{ $t('cms.faq.category') }}</label>
              <div class="f-left">
                <div class="input-dropdown" v-click-outside="clickOutsideDropdown">
                  <input type="text" @click="showDropdown" :placeholder="$t('cms.faq.select_category')"
                    class="form-control" v-model="record.type" />
                  <span class="icon-coin-input" @click="showDropdown"><i class="fa"
                      :class="!isShowDropdown ? 'fa-angle-down' : 'fa-angle-up'"></i></span>
                  <div class="coin-option" v-if="isShowDropdown">
                    <span class="option create-new" @click="createNewType">
                        <span class="icon-plus"></span>
                        {{ $t('cms.faq.create_category') }}</span>
                    <span class="option" v-for="option in typesOption" @click="onSelectDropdown(option)">{{ option.name
                      }}</span>
                  </div>
                </div>
              </div>
              <div class="error-box">
                <span v-show="errors.has('type')" class="f-left is-error">{{ errors.first('type') }}</span>
              </div>
            </div> -->
            <!-- <div class="notice-detail-form-group">
              <label class="notice-detail-form-label">{{ $t('cms.faq.language') }}</label>
              <div class="f-left"><select-box class="notice-detail-form-value" v-model="record.lang"
                  :class="{ 'input-error': errors.has('lang') }" :options="langOptions"
                  :placeholder="$t('cms.faq.select_language')" /></div>
              <div class="clearifx"></div>
              <div class="error-box">
                <span v-show="errors.has('lang')" class="f-left is-error">{{ errors.first('lang') }}</span>
              </div>
            </div> -->
            <div class="notice-detail-form-group">
              <label class="notice-detail-form-label">{{ $t('cms.faq.category') }}</label>
              <div class="f-left"><input :maxlength="maxLength" class="notice-detail-form-value" id="input-name"
                  v-model="record.type" :class="{ 'input-error': errors.has('name') }" /></div>
              <div class="clearifx"></div>
              <div class="error-box">
                <span v-show="errors.has('name')" class="f-left is-error">{{ errors.first('name') }}</span>
              </div>
            </div>
            <div class="notice-detail-form-group">
              <label class="notice-detail-form-label">{{ $t('cms.faq.question') }}</label>
              <div class="f-left"><textarea maxlength="maxLength" class="notice-detail-form-value" id="textarea"
                  v-model="record.question" :class="{ 'input-error': errors.has('name') }" /></div>
              <div class="clearifx"></div>
              <div class="error-box">
                <span v-show="errors.has('name')" class="f-left is-error">{{ errors.first('name') }}</span>
              </div>
            </div>
            <div class="notice-detail-form-group notice-image clearfix" style="margin-top: 50px;">
              <div class="code-container">
                <editor :text-data="textData" :on-change="handleChangeEditor" />
              </div>
            </div>
            <div class="clearfix"></div>
            <div class="submit">
              <button v-if="record.status === 2" class="btn-cancel" @click="cancel">{{ $t('Back') }}</button>
              <button v-if="record.status === 1" class="btn-cancel" @click="cancel">{{ $t('notice.cancel') }}</button>
              <button v-if="record.status === 1" class="file-btn" @click="submitNotice(record.id)">{{
                $t('notice.submit') }}</button>
            </div>
          </div>
        </div>
      </div>
      <message></message>
      <modal class="submitFaq_modal" name="submitFaq" :title="$t('CONFIRM')">
        <div slot="body" class="body-popup" style="font-size: 16px; padding: 10px 20px 0px;">
          {{ $t('qna.submit_data') }}
        </div>
      </modal>
      <modal class="newType_modal" name="newType" :title="$t('cms.notification.add_new_type')">
        <div slot="body" class="body-popup">
          <div class="notice-detail-form-group clearfix">
            <div class="f-left"><input :maxlength="maxLength" class="notice-detail-form-value" id="input-newType"
                :placeholder="$t('cms.notification.enter_type')" v-model="newType"
                :class="{ 'input-error': errors.has('newType') }" />
            </div>
            <div class="clearifx"></div>
            <div class="error-box">
              <span v-show="errors.has('newType')" class="f-left is-error">{{ errors.first('newType')
                }}</span>
            </div>
          </div>
        </div>
      </modal>
    </div>
  </div>
</template>

<script>
import rf from '../../../lib/RequestFactory';
import DatePicker from 'vuejs-datepicker';
import moment from 'moment';
import CONST from '../../../common/Const';
import Message from '@/desktop/components/common/Message';
import SelectBox from '../../../components/SelectBox.vue';
import Modal from '../../../components/Modal.vue';
import Editor from '../../../components/Editor.vue';

export default {
  components: {
    DatePicker,
    Message,
    SelectBox,
    Modal,
    Editor,
  },

  data() {
    return {
      noticeId: this.$route.query.id,
      titlePage: this.$route.query.id ? window.i18n.t('cms.qna.title_edit') : window.i18n.t('cms.qna.title_create'),
      started_at: new Date(),
      ended_at: moment().add(6, 'day').toDate(),
      record: null,
      maxLength: CONST.MAX_LENGTH_INPUT,
      validateErrors: {},
      isShowDropdown: false,
      newType: "",
      isLoading: false,
      enableClose: false,
      isPreview: false,
      langOptions: [
        { id: 'en', name: "English" },
        { id: 'ko', name: "Korean" },
        { id: 'vi', name: "Vietnamese" }
      ],
      typesOption: [
        { value: 'maintenance', name: window.i18n.t('cms.announcement.type_maintenance') },
        { value: 'coin_listings', name: window.i18n.t('cms.announcement.type_coin_listings') },
        { value: 'latest_news', name: window.i18n.t('cms.announcement.type_latest_news') },
      ],

      textData: '',
    };
  },
  methods: {
    submitNotice(id) {
      const params = {
        id: id,
        data: { answer: this.textData }
      }
      this.showModalConfirm("submitFaq", this.replyQuestion, params)
    },

    replyQuestion(params) {
      rf.getRequest("NotificationRequest").updateInquiry(params.id, params.data)
        .then((res) => {
          if (res?.success) {
            this.$toastr('success', window.i18n.t('common.alert.update.success'));
            this.getDetailInquiry();
          } else {
            this.$toastr('error', window.i18n.t('Failed'));
          }
        })
        .catch((error) => {
          this.$toastr('error', window.i18n.t('Something wrong!'));
        })
    },

    cancel() {
      this.$router.go(-1);
    },

    onSelectDropdown(value) {
      this.record.type = value.name;
      this.clickOutsideDropdown();
    },
    showDropdown() {
      this.isShowDropdown = !this.isShowDropdown;
    },
    clickOutsideDropdown() {
      this.isShowDropdown = false;
    },
    // createNewType() {
    //   this.showModalConfirm("newType", this.addNewType, { type: this.newType })
    // },
    showModalConfirm(modalName, callback, params) {
      CommonModal.show(modalName, {
        position: 'center',
        mask: true,
        buttons: [
          {
            label: window.i18n.t('common.action.cancel'),
            class: "btn-no",
            style: {
              background: 'rgb(191, 191, 191);'
            },
            callback: () => {
              CommonModal.hide(modalName);
            }
          },
          {
            label: window.i18n.t('common.action.create'),
            focused: true,
            class: "btn-yes",
            style: {
              background: 'rgb(0, 112, 192)'
            },
            callback: () => {
              CommonModal.hide(modalName);
              this.loadingUploadBegin();
              callback(params);
              this.loadingUploadFinish();
            }
          }
        ]
      });
    },
    addNewType() {
      // code request api 
    },
    loadingUploadBegin() {
      this.enableClose = false;
      this.isLoading = true;
      CommonModal.show('loadingUpload');
    },
    loadingUploadFinish() {
      CommonModal.hide('loadingUpload');
    },
    handleChangeEditor(value) {
      this.textData = value
    },
    async getQnaType() {
      const res = await rf.getRequest('NotificationRequest').getListTypeInquiry();
      if (res && res?.data?.length) {
        const listType = res.data.map((item) => ({
                    id: `${item.id}`,
                    name: window.i18n.t(`cms.qna.category_${item.id}`)
                }))
                this.typesOption = [{ id: "all", name: window.i18n.t("order.order_history.all") }, ...listType];
      }
    },
    getDetailInquiry() {
      rf.getRequest('NotificationRequest').getDetailInquiry(this.noticeId).then(res => {
        this.record = res.data || {};
        this.record.type = res.data.inquiry_type.name;
        this.textData = res.data.answer;
      });
    }
  },

  mounted() {
    this.$emit('EVENT_PAGE_CHANGE', this);
    if (!this.noticeId) {
      return;
    }
    this.getQnaType()
    this.getDetailInquiry()
  }
}

String.prototype.formatUnicorn = String.prototype.formatUnicorn ||
  function () {
    "use strict";
    var str = this.toString();
    if (arguments.length) {
      var t = typeof arguments[0];
      var key;
      var args = ("string" === t || "number" === t) ?
        Array.prototype.slice.call(arguments)
        : arguments[0];

      for (key in args) {
        str = str.replace(new RegExp("\\{" + key + "\\}", "gi"), args[key]);
      }
    }
    return str;
  };
</script>

<style lang="scss">
@import "@/assets/sass/variables";

#faq_edit {
  .sc_search_select {
    .group_search_select {
      .button_drop_search {
        height: 35px;
        padding: 8px 10px 8px 10px;
        border-radius: 10px;
        font-size: $font-small;
        font-family: $font-family-roboto;
        background-color: $color-white;
        width: 580px;

        span {
          font-family: $mulish-regular;
          font-size: 14px;
        }

        i.icon-arrow1 {
          margin-left: 5px;
          color: $color_dove_gray;
        }

        &:hover {
          border-color: $color-jungle-green;

          i.icon-arrow1 {
            color: $color-jungle-green;
          }
        }
      }

      .box_list_search_select {
        .list_search_select {
          border-radius: 10px;

          li {
            font-family: $font-family-roboto;
            font-size: $font-small;
            padding: 6px 12px;

            &:hover,
            &:active,
            &:focus {
              color: $color-jungle-green;
            }
          }
        }
      }
    }
  }

  .btn-no {
    background-color: $color-bright-gray;
    border-color: $color-bright-gray;
    color: $color-black;

    &:hover {
      opacity: 0.7;
    }
  }

  .modal {
    input {
      width: 300px;
      padding: 13px 12px;
      outline: none;
      border: 1px solid $color-alto;
      height: 40px;
      line-height: 35px;
      background-color: $color-white;
      border-radius: 3px;
      font-family: $mulish-regular;
      border-radius: 10px;

      &:hover,
      &:active,
      &:focus {
        border-color: $color-jungle-green;
      }
    }
  }


}
</style>

<style lang="scss" scoped>
@import "@/assets/sass/variables";

.is-error {
  display: inline-block;
  line-height: 20px;
  padding-top: 5px;
}

.box_datetime_a {
  width: 580px;
  max-width: 100%
}

.is-error {
  color: #ff5252 !important;
}

.input-error {
  border: 1px solid #ff5252 !important;
}

.f-left {
  float: left;
}

.required {
  color: #f00;
}

.error-box {
  display: block;
  float: left;
  overflow: hidden;
  width: 100%;

  span {
    font-family: $font-family-roboto-medium !important;
  }
}

.page960 {
  width: 1129px;
  min-width: 1129px;
}

.f-left {
  float: left;
}

button[disabled] {
  opacity: 0.4 !important;
}

#faq_edit {
  .mail-preview {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #00000046;

    .mail-container {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;

      .btn-close {
        position: absolute;
        top: 30px;
        right: 30px;
        background: none;
        border: none;

        .icon-close {
          color: $color-white;
        }
      }

      .content {
        background-color: $color-white;
        padding: 50px 100px;
      }
    }
  }

  .notice-header {
    background-color: #fff;
    border-radius: 10px;
    padding: 20px 24px;
    margin: 16px 0;
    width: 70%;

    .notice-header-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .notice-header-label {
      color: #888;
      padding: 5px 0;
    }

    .notice-header-value {
      font-weight: 600;
    }
  }
}

.form-notice-detail-container {
  padding: 0px 40px 0 0 !important;

  .notice-detail-country {
    min-height: 30px;

    .notice-detail-form-label {
      margin-right: 22px;
      width: 130px;
      text-align: right;
      font-size: 14px;
      color: black;
      float: left;
      margin-bottom: 0px;
      margin-top: 10px;
    }
  }

  .filed-verification {
    .filed {
      .tip {
        font-size: 12px;
        color: #666;
        padding: 20px 0;
      }
    }
  }

  .notice-detail-form-group {
    height: auto;
    float: left;
    min-width: 500px;

    &.notice-image {
      height: auto;
      margin-top: 15px;

      span {
        color: $color_dove_gray;
        font-family: $font-family-roboto;
        font-weight: normal;
      }
    }

    .notice-detail-form-label {
      width: 100%;
      text-align: left;
      font-size: 14px;
      color: $color_dove_gray;
      float: left;
      font-weight: normal;
      font-family: $mulish-regular;
      margin-bottom: 0px;
      margin-top: 10px;
      margin-left: 5px;

      &.label-inline {
        display: inline;
        width: auto;
        margin-top: 24px;
        margin-right: 16px;
      }
    }

    .switch-container {
      margin-top: 22px;
    }

    .upload-file {
      display: inline-block;
      margin-left: 10px;
    }

    .col-md-6 {
      input {
        width: 275px;
      }
    }

    .code-container {
      position: relative;
      display: flex;
      gap: 36px;
    }

    textarea {
      width: 580px;
      max-height: 150px;
      padding: 13px 12px;
      outline: none;
      border: 1px solid $color-alto;
      background-color: $color-white;
      border-radius: 3px;
      font-family: $mulish-regular;
      border-radius: 10px;
      resize: vertical;
      field-sizing: content;

      &:hover,
      &:active,
      &:focus {
        border-color: $color-jungle-green;
      }
    }

    input {
      width: 580px;
      padding: 13px 12px;
      outline: none;
      border: 1px solid $color-alto;
      height: 35px;
      line-height: 35px;
      background-color: $color-white;
      border-radius: 3px;
      font-family: $mulish-regular;
      border-radius: 10px;

      &:hover,
      &:active,
      &:focus {
        border-color: $color-jungle-green;
      }
    }

    .preView {
      width: 580px;
      height: 360px;
      background-color: #d8d8d8;
      position: relative;
      float: left;
      margin-top: 10px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .file-btn {
      background-color: $color-white-smoke;
      color: $color-jungle-green;
      width: 90px;
      height: 23px;
      text-align: center;
      position: relative;
      padding: 0 10px;
      display: inline-block;
      cursor: pointer;
      font-size: $font_mini_mini;
      border: 1px solid $color-caribbean-green;
      text-transform: uppercase;
      border-radius: 3px;
      font-weight: 600;
      line-height: 23px;
      font-family: $font-family-roboto;

      &:hover {
        background-color: $color-aquamarine;
        border: 1px solid $color-aquamarine;
        color: $color-white;
      }
    }

    input[type=file] {
      position: absolute;
      left: 0;
      top: 0;
      cursor: pointer;
      height: 100%;
      width: 100%;
      opacity: 0;
    }
  }

  .submit {
    padding: 30px 0 30px 0;
    text-align: left;

    .file-btn {
      background-color: $color-caribbean-green;
      color: $color-white;
      height: 35px;
      position: relative;
      padding: 0px 20px;
      display: inline-block;
      cursor: pointer;
      font-size: $font-smaller;
      border: 1px solid $color-caribbean-green;
      text-align: center;
      text-transform: uppercase;
      font-weight: 600;
      border-radius: 10px;

      &:hover {
        background-color: $color-aquamarine;
        border-color: $color-aquamarine;
      }
    }

    .btn-cancel {
      background-color: $color-bright-gray;
      color: $color-black;
      height: 35px;
      position: relative;
      padding: 0px 20px;
      display: inline-block;
      cursor: pointer;
      font-size: $font-smaller;
      border: 1px solid $color-bright-gray;
      text-align: center;
      text-transform: uppercase;
      font-weight: 600;
      border-radius: 10px;
      margin-right: 10px;

      &:hover {
        opacity: 0.7;
      }
    }

    button {
      position: absolute;
      left: 0;
      top: 0;
      cursor: pointer;
      height: 32px;
      width: 98px;
      font-family: $font-family-roboto;
    }
  }

  .input-dropdown {
    position: relative;

    input {
      background: $color-white;
      border: 1px solid #C7CBD3 !important;
      border-radius: 10px !important;
      width: 580px;
      height: 35px;
      cursor: pointer;
    }

    .icon-coin-input {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
      color: #667186;

      i {
        font-size: 22px;
      }
    }

    .coin-option {
      position: absolute;
      top: 37px;
      width: 100%;
      background: #FFFFFF;
      box-shadow: 0px 4px 15px rgba(0, 19, 54, 0.15);
      border-radius: 10px;
      padding: 17px 0;
      z-index: 3;

      &:after {
        bottom: 100%;
        left: 50%;
        border: solid transparent;
        content: " ";
        display: block;
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: rgba(136, 183, 213, 0);
        border-bottom-color: $color-white;
        border-width: 5px;
        margin-left: -5px;
      }

      .option {
        display: flex;
        align-items: center;
        width: 100%;
        line-height: normal;
        cursor: pointer;
        padding-left: 14px;
        font-size: 14px;
        font-weight: 500;
        overflow: hidden;
        color: #001336;
        height: 32px;

        &:hover {
          background: $color-athens;
        }

        &.create-new {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 16px;
          border: 1px dashed $color-filled-status;
          border-radius: 4px;
          margin: 0 16px;
          width: calc(100% - 32px);
        }
      }
    }
  }
}
</style>