import BaseRequest from '../lib/BaseRequest'

export default class MarginOrderRequest extends BaseRequest {

  getOrders(params) {
    let url = '/admin/api/margin-orders/order';
    return this.get(url, params);
  }

  getTradeHistory(params) {
    let url = '/admin/api/margin-orders/trading-history';
    return this.get(url, params);
  }

  getPositions(params) {
    let url = '/admin/api/margin-orders/position';
    return this.get(url, params);
  }

  getRiskInformation(params) {
    let url = '/admin/api/margin-orders/risk-information';
    return this.get(url, params);
  }

  fetchUserGroupData (params) {
    let url = '/admin/api/margin-orders/fetch-user-group';
    return this.get(url, params);
  }

  getUserGroupRiskInformation(params){
    let url = '/admin/api/margin-orders/user-group-risk-information';
    return this.get(url, params);
  }

  getUserGroupPositionInfo(params){
    let url = '/admin/api/margin-orders/user-group-position';
    return this.get(url,params);
  }

  addUserGroupRiskInformation(params){
    let url ='/admin/api/margin-orders/user-group-risk-information/create';
    return this.post(url,params);
  }

  deleteUserGroupRiskInformation(params){
    let url ='/admin/api/margin-orders/user-group-risk-information/delete';
    return this.del(url,params);
  }

  getAverageEntryPrice(params){
    let url ='/admin/api/margin-orders/aver-entry-price';
    return this.get(url, params);
  }

  getAllContests (params) {
    let url ='/admin/api/margin-orders/all-contest';
    return this.get(url, params);
  }
  getAllContestsHavingTradingVolume (params) {
    let url ='/admin/api/margin-orders/contest-having-volume';
    return this.get(url, params);
  }
  getAllTeamByContest (params) {
    let url ='/admin/api/margin-orders/all-team-by-contest';
    return this.get(url, params);
  }

  getAllEntriesByTeam (params) {
    let url ='/admin/api/margin-orders/entries-by-team';
    return this.get(url, params);
  }
  getAllEntriesByContest (params) {
    let url ='/admin/api/margin-orders/entries-by-contest';
    return this.get(url, params);
  }

  getEntriesByContest (params) {
    let url ='/admin/api/margin-orders/single-entry-by-contest';
    return this.get(url, params);
  }

  getTeamEntryByContest(params){
    let url ='/admin/api/margin-orders/team-entry';
    return this.get(url,params);
  }

  getMemberByTeamId(params){
    let url='/admin/api/margin-orders/team-member';
    return this.get(url,params);
  }

  createNewContest(params){
    let url ='/admin/api/margin-orders/create-contest';
    return this.post(url,params);
  }

  startContest(params){
    let url = '/admin/api/margin-orders/start-contest';
    return this.put(url,params);
  }

  stopContest(params){
    let url = '/admin/api/margin-orders/stop-contest';
    return this.put(url,params);
  }

  editContest(params){
    let url = '/admin/api/margin-orders/edit-contest';
    return this.put(url,params);
  }
}
