import BaseRequest from './BaseRequest'

export default class HotSearchRequest extends BaseRequest {
    getHotSearch() {
        let url = '/admin/market/hot-symbols';
        return this.get(url);
    }
    updateHotSearch(data){
        let url = '/admin/market/hot-symbols';
        return this.put(url, data)
    }
}