<template>
  <div class="userInformation boxCore" id="circuit_breaker_setting">

    <div class="content_TradingFeeSetting clearfix">
      
      <div class="clearfix clearfix-15"></div>
      <!-- Enable Circuit Breaker Togger -->
      <div class="group_head_airdrop_setting enable-circuit-breaker">
        <span class="title_item_airdrop_setting">{{ $t('circuit_breaker_setting.enable_circuit_breaker') }}</span>
        <label class="switch">
          <input type="checkbox" v-model="isActive" @click="confirmChangeStatus" :disabled="isSubmittingChangeStatus">
          <span>
            <i></i>
          </span>
        </label>
      </div>

      <!-- Select Markets -->
      <div class="filter_container clearfix">
        <span class="title_item">
          {{ $t('circuit_breaker_setting.select_market') }}
        </span>
        <select-box v-model="currency" class="select-market" :options="markets"/>
      </div>
      <div class="clearfix"></div>

      <div class="datatable">
        <data-table :getData="getData" ref="circuitBreakerSettingDatatable" :limit="10" :column="6" class="scroll" @DataTable:finish="onDatatableFinish">
          <th class="text-left col-coin">{{ $t('circuit_breaker_setting.coin') }}</th>
          <th class="text-left col-range-listen-time">{{ $t('circuit_breaker_setting.range_listen_time') }}</th>
          <th class="text-left col-range-percent">{{ $t('circuit_breaker_setting.range_percent') }}</th>
          <th class="text-left col-block-time">{{ $t('circuit_breaker_setting.block_time') }}</th>
          <th class="text-left col-enable-disable">{{ $t('circuit_breaker_setting.enable_column') }}</th>
          <th class="text-right last-col"></th>
          <template slot="body" slot-scope="props">

            <tr>
              <td class="text-left trading-coin">
                {{ props.item.coin }}
              </td>

              <td class="text-left">

                <input-only-number
                  v-if="props.item.editable"
                  name="fee_taker"
                  class="form-control fl-left"
                  v-model="props.item.range_listen_time"
                  @dirty="onHandleDirtyData($event, props.item)"
                  precision="3"
                  maxlength="16"
                />

                <span v-else>
                  {{ !props.item.range_listen_time ? "" : Number(props.item.range_listen_time)  | toNumber }}
                  <!--{{!props.item.range_listen_time ? '' : ''}}-->
                </span>
              </td>


              <td class="text-left">
                <input-only-number
                  v-if="props.item.editable"
                  class="form-control fl-left"
                  name="circuit_breaker_percent"
                  v-model="props.item.circuit_breaker_percent"
                  @dirty="onHandleDirtyData($event, props.item)"
                  precision="2"
                  maxlength="16"
                />
                <span v-else>
                  {{ !props.item.circuit_breaker_percent ? "" : Number(props.item.circuit_breaker_percent) | toNumber }}
                  <!--{{!props.item.circuit_breaker_percent ? '' : ''}}-->
                </span>
              </td>
              <td class="text-left">
                <input-only-number
                  v-if="props.item.editable"
                  class="form-control fl-left"
                  name="block_time"
                  v-model="props.item.block_time"
                  @dirty="onHandleDirtyData($event, props.item)"
                  precision="3"
                  maxlength="16"
                />
                <span v-else>
                  {{ !props.item.block_time ? "" : Number(props.item.block_time) | toNumber }}
                  <!--{{!props.item.block_time ? '' : ''}}-->
                </span>
              </td>


              <td class="text-left">

                <!--<div class="group_head_airdrop_setting" v-if="props.item.editable">-->
                  <!--<label class="switch">-->
                    <!--<input type="checkbox" v-model="props.item.isActive" @click="toggleEnable(props.item)">-->
                    <!--&lt;!&ndash;<input v-else type="checkbox" v-model="props.item.isActive" disabled>&ndash;&gt;-->
                    <!--<span>-->
                      <!--<i></i>-->
                    <!--</span>-->
                  <!--</label>-->
                <!--</div>-->

                <select-box v-if="props.item.editable" :options="statuses" v-model="props.item.isActive" class="box_select_user" customLabel @input="toggleEnableSelect(props.item)">
                  <template slot="label_selected" slot-scope="props">
                    <span>{{ props.selected ? $t('Enable') : $t('Disable') }}</span>
                  </template>
                  <template slot="option" slot-scope="props">
                    <span class="full_name_coin_select">{{ props.option ? $t('Enable') : $t('Disable') }}</span>
                  </template>
                </select-box>

                <span v-else class="circuit-breaker-status" :class="props.item.isActive ? 'status-enabled' : 'status-disabled'">
                  {{ props.item.isActive ? $t('circuit_breaker_setting.enabled') : $t('circuit_breaker_setting.disabled') }}
                </span>

              </td>


              <td class="text-right">
                <button v-if="props.item.editable" class="btn btn_save_network" @click="revert(props.item)">
                  <i class="icon-reload3"></i>
                </button>
                <button v-if="props.item.editable" class="btn btn_save_network" @click="change(props.item)">
                  <i class="icon-save"></i>
                </button>
                <button v-else class="btn btn_edit_network" @click="edit(props.item)">
                  <i class="icon-edit"></i>
                </button>
              </td>


            </tr>
          </template>
        </data-table>
      </div>
    </div>
  
    <div class="clearfix clearfix-40"></div>

  </div>
</template>
<script>
  import rf from '../../lib/RequestFactory';
  import InputOnlyNumber from './InputOnlyNumberCircuitBreaker';
  import SelectBox from '../../components/SelectBox';
  import BigNumber from 'bignumber.js';

  const STATUS_ENABLE = 'enable';
  const STATUS_DISABLE = 'disable';
  const STATUS_ALLOW_TRADING = 0;
  const STATUS_BLOCK_TRADING = 1;

  export default {
    components: {
      InputOnlyNumber,
      SelectBox,
    },
    data() {
      return {
        titlePage: this.$t('circuit_breaker_setting.title'),
        // showInputFee: false,
        statuses: [0, 1],
        currency: {id: 'btc', name: 'BTC'},
        markets: [],
        isActive: false,
        isSubmittingChangeStatus: false,
        isSubmittingCoinPair: false,
      }
    },

    watch: {
      currency(newValue) {
        this.$refs.circuitBreakerSettingDatatable.refresh();
      }
    },
    computed: {
      trimZeroNumber : function(item) {
        return item.fixed(2);
      },
    },

    methods: {

      getData(params) {
        let defaultParams = {
          currency: this.currency.id
        };
        return rf.getRequest('AdminRequest').getCoinPairSetting(Object.assign(defaultParams, params))
        .then(res => {
          let dataRewrite = res.data;
          let newData = res.data.data;
          newData.forEach(el => {
            el.id,
            el.currency || '',
            el.range_listen_time = el.range_listen_time ? (new BigNumber(el.range_listen_time).toFixed(3)) : el.range_listen_time,
            el.circuit_breaker_percent = el.circuit_breaker_percent ? (new BigNumber(el.circuit_breaker_percent)).toFixed(2) : el.circuit_breaker_percent,
            el.block_time = el.block_time ? (new BigNumber(el.block_time)).toFixed(3) : el.block_time,
            el.coin || '',
            el.isActive = (el.status != STATUS_DISABLE), // Default: If status is null --> Enable Trading
            el.blockTrading = el.block_trading // Default: If status is null --> Enable Trading
          });
          dataRewrite.data = newData;
          return {
            data: dataRewrite,
          };
        });
      },

      onDatatableFinish() {
        window._.each(this.$refs.circuitBreakerSettingDatatable.rows, item => {
          this.$set(item, 'editable', false);
          this.$set(item, 'isDirty', false);
        });
      },

      onHandleDirtyData($event, item) {
        item.isDirty = $event;

        // console.log('onHandleDirtyData---------->', item);
        // if (item.range_listen_time) {
        //   setTimeout(() => {
        //     item.range_listen_time = this.removePrecision(item.range_listen_time, 3);
        //     console.log('item--after', item.range_listen_time);
        //   }, 3);
        // }

        // if (item.circuit_breaker_percent) {
        //   setTimeout(() => {
        //     item.circuit_breaker_percent = this.removePrecision(item.circuit_breaker_percent, 3);
        //   }, 3);
        // }
        //
        // if (item.block_time) {
        //   setTimeout(() => {
        //     item.block_time = this.removePrecision(item.block_time, 3);
        //   }, 3);
        // }

      },
      removePrecision(value, maxPrecision) {
        let x = (value + '').split('.');
        console.log(x);

        let x1 = x[0];
        let x2 = x[1];

        if (!x2) return value;

        if (x2.length > maxPrecision) {
          return new BigNumber(value).toFixed(maxPrecision);
        }

        return value;
      },

      toggleEnableSelect(item) {
        item.isDirty = true;
      },

      toggleEnable(item) {
        item.isActive = !item.isActive;
        item.isDirty = true;
      },

      edit(item) {
        item.revert = {
          range_listen_time: item.range_listen_time,
          circuit_breaker_percent: item.circuit_breaker_percent,
          block_time: item.block_time,
          status: item.status,
          isActive: item.isActive,
        };
        item.editable = !item.editable;
      },
      resetErrors() {
        this.error_validate.range_listen_time = false;
        this.error_validate.circuit_breaker_percent = false;
        this.error_validate.block_time = false;
        this.error_validate.status = false;
        this.error_validate.isActive = false;
      },
      checkValidatePercent(item) {
        if(!item.fee_taker || !item.fee_taker.trim() || item.fee_taker == 0) {
          this.$toastr('error', this.$t('trading_fee_setting.validate.required',{0: this.$t('tradding_fee_setting.fee_taker') }));
          return false;
        }
        if(item.fee_taker.substring(item.fee_taker.length - 1 , item.fee_taker.length) == '.') {
          this.$toastr('error', this.$t('trading_fee_setting.validate.isvalid',{0: this.$t('tradding_fee_setting.fee_taker') }));
          return false;
        }
        if(!item.fee_maker || !item.fee_maker.trim() || item.fee_maker == 0) {
          this.$toastr('error', this.$t('trading_fee_setting.validate.required',{0: this.$t('tradding_fee_setting.fee_maker') }));
          return false;
        }
        if(item.fee_maker.substring(item.fee_maker.length - 1 , item.fee_maker.length) == '.'){
          this.$toastr('error', this.$t('trading_fee_setting.validate.isvalid',{0: this.$t('tradding_fee_setting.fee_maker') }));
          return false;
        }
        let fee_taker = item.fee_taker.split(".");
        let fee_maker = item.fee_maker.split(".");
        if( Number(fee_taker[0]) > 100 || ( Number( fee_taker[0] ) == 100 && Number(fee_taker[1] )>0 ) ){
          // this.error_validate = {fee_taker : true};
          this.$toastr('error', this.$t('trading_fee_setting.under_101_percent',{0: this.$t('tradding_fee_setting.fee_taker') }));
          return false;
        }
        if(Number(fee_taker[1]).toString().length >2) {
          this.$toastr('error', this.$t('trading_fee_setting.decial_part_under_2_character',{0: this.$t('tradding_fee_setting.fee_taker') }));
          return false;
        } 
        console.log(fee_taker[1]);
        if( Number(fee_maker[0]) > 100 || ( Number( fee_maker[0] ) == 100 && Number(fee_maker[1] )>0 ) ){
          // this.error_validate = {fee_taker : true};
          this.$toastr('error', this.$t('trading_fee_setting.under_101_percent',{0: this.$t('tradding_fee_setting.fee_maker') }));
          return false;
        }
        console.log(fee_maker[1]);
         if(Number(fee_maker[1]).toString().length > 2) {
          this.$toastr('error', this.$t('trading_fee_setting.decial_part_under_2_character',{0: this.$t('tradding_fee_setting.fee_maker') }));
          return false;
        } 
        return true;
      },

      validateData(item) {
        console.log('validate', item);
        if (!item.circuit_breaker_percent) {
          this.$toastr('error', this.$t('circuit_breaker_setting.validation.circuit_breaker_percent'));
          return false;
        }
        if (new BigNumber(item.circuit_breaker_percent).isZero()) {
          this.$toastr('error', this.$t('circuit_breaker_setting.validation.circuit_breaker_percent.empty_value'));
          return false;
        }

        if (!item.range_listen_time) {
          this.$toastr('error', this.$t('circuit_breaker_setting.validation.range_listen_time'));
          return false;
        }
        if (new BigNumber(item.range_listen_time).isZero()) {
          this.$toastr('error', this.$t('circuit_breaker_setting.validation.range_listen_time.empty_value'));
          return false;
        }

        if (!item.block_time) {
          this.$toastr('error', this.$t('circuit_breaker_setting.validation.block_time'));
          return false;
        }
        if (new BigNumber(item.block_time).isZero()) {
          this.$toastr('error', this.$t('circuit_breaker_setting.validation.block_time.empty_value'));
          return false;
        }

        return true;
      },

      async change(item) {
        // No change data.
        if (!item.isDirty) {
          item.editable = !item.editable;
          return;
        }

        // let validator = await this.checkValidatePercent(item);
        let validator = this.validateData(item);
        /*
        if (!this.validate()) {
          this.$toastr('error', this.$t('trading_fee_setting.msg_coin_holding'));
          return;
        }
        */
        if(validator) {

          ConfirmationModal.show({
            type            : 'confirm',
            title           : this.$t('circuit_breaker_setting.update_confirm'),
            content         : '',
            btnCancelLabel  : this.$t('common.action.no'),
            btnConfirmLabel : this.$t('common.action.yes'),
            size            : 'small',
            onConfirm       : () => {
              this.isSubmittingCoinPair = true;
              const params = {
                id: item.id,
                currency: this.currency.id,
                coin: item.coin,
                range_listen_time: item.range_listen_time,
                circuit_breaker_percent: item.circuit_breaker_percent,
                block_time: item.block_time,
                status: item.isActive ? STATUS_ENABLE : STATUS_DISABLE,
              };
              rf.getRequest('AdminRequest').updateCoinPairSetting(params).then(res => {
                this.isSubmittingCoinPair = false;
                item.editable = !item.editable;

                if (res.data && res.data.id) {
                  item.id = res.data.id;
                }
                this.$toastr('success', this.$t('circuit_breaker_setting.update_success_msg'));
              }).catch(error => {
                this.isSubmittingCoinPair = false;
                if (error && error.response && error.response.data && error.response.data.message) {
                  this.$toastr('error', error.response.data.message);
                  return;
                }

                this.$toastr('error', this.$t('common.message.network_error'));
              });
            },
            onCancel        : () => {}
          })
        }
      },

      revert(item) {
        item.range_listen_time = item.revert.range_listen_time;
        item.circuit_breaker_percent = item.revert.circuit_breaker_percent;
        item.block_time = item.revert.block_time;
        item.status = item.revert.status;
        item.isActive = item.revert.isActive;

        item.isDirty = false;
      },

      confirmChangeStatus() {
        let oldStatusActive = this.isActive;
        ConfirmationModal.show({
          type            : 'confirm',
          title           : this.$t('circuit_breaker_setting.update_enable_confirm'),
          content         : '',
          btnCancelLabel  : this.$t('common.action.no'),
          btnConfirmLabel : this.$t('common.action.yes'),
          size            : 'small',
          onConfirm       : () => {
            this.changeStatus();
          },
          onCancel        : () => {
            this.isActive = oldStatusActive;
          }
        })
      },
      changeStatus() {
        this.isSubmittingChangeStatus = true;
        let oldStatusActive = !this.isActive;
        let currentStatusActive = this.isActive;
        let params = {
          'status' : (currentStatusActive ? STATUS_ENABLE : STATUS_DISABLE),
        };

        rf.getRequest('AdminRequest').changeStatusCircuitBreakerSetting(params).then(res => {
          this.isSubmittingChangeStatus = false;
          if (res && res.data && res.success) {
            if (res.data.status) {
              this.isActive = (res.data.status == STATUS_ENABLE);
            } else {
              this.isActive = currentStatusActive;
            }
            this.$toastr('success', this.$t('user.succes'));
            return true;
          }

          this.isActive = oldStatusActive;
          this.$toastr('error', this.$t('common.message.network_error'));

        }).catch(error => {
          this.isSubmittingChangeStatus = false;
          this.isActive = oldStatusActive;
          if (error && error.response && error.response.data && error.response.data.message) {
            this.$toastr('error', error.response.data.message);
            return;
          }
          this.$toastr('error', this.$t('common.message.network_error'));
        });

      },

    },

    mounted() {
      this.$emit('EVENT_PAGE_CHANGE', this);
    },

    created() {
      rf.getRequest('AdminRequest').getPriceGroupCurrency().then(res => {
        try {
          window._.map(res.data, item => {
            if (typeof item.currency !== 'undefined') {
              this.markets.push({ id: item.currency, name: item.currency.toUpperCase() });
            }
          });
        }
        catch (ex) {
          console.error(ex);
        }
      });

      return rf.getRequest('AdminRequest').getCircuitBreakerSetting({})
        .then(res => {
          if (res && res.data && res.data.status) {
            this.isActive = (res.data.status == STATUS_ENABLE);
          } else {
            this.isActive = STATUS_DISABLE;
          }
        })
        .catch(err => {
          console.log(err);
          this.$toastr('error', this.$t('common.message.network_error'));
        });

    }
  }
</script>
<style lang="scss">
@import "@/assets/sass/variables";
  
  #circuit_breaker_setting {
    .select-market {
      &.sc_search_select {
        .group_search_select { 
          .button_drop_search {
            span {
              color: $color-grey-dark;
              font-family: $font-family-roboto-medium;
            }
          }
          .list_search_select {
            li {
              font-size: 13px;
              &:hover {
                color: $color-white;
                background-color: $color-caribbean-green;
              }
            }
          }
        }
      }
    }
  }
</style>
<style lang="scss" scoped>
@import "@/assets/sass/variables";
  .clearfix-15 {
    clear: both;
    display: block;
    width: 100%;
    height: 15px;
  }
  .input-error {
    border: 1px solid $color-denim !important;
  }
  .per-left{

  }
  .fl-left{
    float: left;
  }
  th, td {
    font-family: $font_family_root;
    &.trading-coin {
      text-transform: uppercase;
    }
    &.col-coin {
      width: 10%;
    }
    &.col-range-listen-time {
      width: 20%;
    }
    &.col-range-percent {
      width: 20%;
    }
    &.col-block-time {
      width: 20%;
    }
    &.col-enable-disable {
      width: 15%;
    }
    &.last-col {
      width: 15%;
    }
    .status-enabled {
      color: $color-jungle-green;
    }
    .status-disabled {
      color: $color_alizarin_crimson;
    }
  }

  .line_page {
    display: block;
    width: 100%;
    height: 1px;
    background-color: $color_alto; 
  }
  .clearfix-40 {
    display: block;
    clear: both;
    height: 40px;
  }

  #circuit_breaker_setting {
    .box_select_user {
      width: 100px;
      .list_search_select {
        min-width: 100%;
        width: 100%;
      }

    }
    .button_drop_search {
      height: 35px !important;
      padding: 7px 6px !important;
    }
    .custom-font, .custom-font span, .box_select_user span {
      font-family: $font-family-roboto !important;
      font-size: $font_root !important;
    }
    .enable-circuit-breaker {
      padding-bottom: 10px;
    }
    .circuit-breaker-status {
      font-family: $font-family-roboto-bold;
    }
    .group_head_airdrop_setting {
      display: block;
      .title_item_airdrop_setting {
        display: inline-block;
        float: left;
        font-size: $font_big;
        color: $color-grey-dark;
        font-family: $font-family-roboto-medium;
        line-height: 20px;
        margin-right: 12px;
      }
    }

    .sc_search_select {
      float: left;
      min-width: 55px;
      .group_search_select {
        .button_drop_search {

          span {
            font-size: $font-small;
            font-family: $font-family-roboto;
          }
          i {
            color: $color-grey-dusty;
          }
        }
        .list_search_select {
          li {
            font-size: $font-small;
            font-family: $font-family-roboto;
            &:hover, &:active, &:focus {
              color: $color-jungle-green;
            }
          }
        }
      }
    }
    .filter_container {
      .sc_search_select {
        margin-left: 15px;
        min-width: 85px;
      }
    }

    .switch {
      margin-bottom: 0px;
      display: inline-block;
      vertical-align: middle;
      cursor: pointer;
      input {
        display:none;
        &:checked +span {
          background: $color-jungle-green;
          text-align: left;
          &:before {
            content: "ON";
            color: $color_white;
            font-size: 12px;
          }
          i {
            left: 35px;
          }
          &:after {
            content: " ";
            color: $color_white;
          }
        }

      }
      span {
        display: inline-block;
        padding: 0px 6px 0 9px;
        width: 54px;
        height: 20px;
        line-height: 20px;
        border-radius: 11px;
        background: $color_grey_icon_light;
        position: relative;
        color: $color_white;
        font-size: 14px;
        text-align: right;
        &:after {
          content: "OFF";
          color: $color_white;
          line-height: 20px;
          font-size: 12px;
        }
        i {
          display: inline-block;
          width: 20px;
          height: 20px;
          border-radius: 100%;
          background: $color_white;
          position: absolute;
          left: 0;
          top: 0px;
          box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.5);
          -webkit-transition: all 0.3s ease-in-out;
          -moz-transition: all 0.3s ease-in-out;
          -ms-transition: all 0.3s ease-in-out;
          -o-transition: all 0.3s ease-in-out;
          transition: all 0.3s ease-in-out;
        }
      }
    }

    .filter_container {
      margin: 10px 0px 25px 0px;
      .title_item {
        color: $color_mine_shaft;
        font-size: $font_big;
        font-family: $font-family-roboto-medium;
        line-height: 28px;
        float: left;
      }
      .select-market {
        font-family: $font-family-roboto;
        color: $color-grey-dark;
        font-size: $font-small;
      }
      .search_box {
        display: inline-block;
        float: right;
        width: 215px;
        max-width: 100%;
        .search_input {
          background-color: transparent;
          height: 28px;
          border: 1px solid $color_alto;
          padding: 4px 15px;
          line-height: 20px;
          width: 100%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          font-size: $font-small;
        }
      }
    }



    .datatable {
      .form-control {
        background-color: transparent;
        height: 27px;
        border: 1px solid $color_alto;
        padding: 3px 13px;
        line-height: 20px;
        width: 140px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
    .btn_edit_network {
      background-color: transparent !important;
      font-size: $font_semi_big;
      color: $color-jungle-green;
      height: 27px;
      padding: 2px 0px;
      line-height: 23px;
      margin-left: 15px;
      &:active {
        box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0);
      }
      &:hover {
        color: $color-jungle-green;
      }
    }
    .btn_save_network {
      background-color: transparent !important;
      font-size: $font_semi_big;
      color: $color-jungle-green;
      height: 27px;
      padding: 2px 0px;
      line-height: 23px;
      margin-left: 15px;
      .icon-save:before {
        color: $color-jungle-green;
      }
      &:active {
        box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0);
      }
      &:hover {
        color: $color-jungle-green;
        .icon-save:before {
          color: $color-jungle-green;
        }
      }
    }
  }
</style>