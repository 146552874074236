export default {
  "window.suffix_title": "거래소",
  "about.about_us": "회사 소개",
  "about.advantage": "우리의 장점",
  "about.coin": "여러 코인 지원",
  "about.coin_reason": "BTC, ETH, LTC, ...",
  "about.device": "모든 기기 지원",
  "about.device_reason": "다양한 언어로 지원 및 FAQ 제공",
  "about.envi": "다국어 지원",
  "about.envi_reason": "웹, 안드로이드, iOS, 모바일 웹",
  "about.liqui": "높은 유동성",
  "about.liqui_reason": "풍부한 자원과 파트너들",
  "about.perform": "높은 성능",
  "about.perform_reason": "초당 1,400,000 주문 처리 가능",
  "about.product": "검증된 제품",
  "about.product_reason":
    "{APP_NAME} 플랫폼은 이미 30개 이상의 거래소에 배포되었습니다. 모든 기기와 여러 언어를 지원하여 원활한 사용자 경험을 제공합니다.",
  "about.resources": "산업 자원",
  "about.resources_reason":
    "우리는 산업 리더들과 견고한 관계를 가지고 있습니다. 우리 플랫폼을 지원하려는 투자자와 고문들의 목록을 보면 알 수 있습니다.",
  "about.safe": "안전성 및 안정성",
  "about.safe_reason": "다층 및 다중 클러스터 시스템 아키텍처",
  "about.team_strong": "강력한 팀",
  "about.team_strong_reason":
    "우리 팀은 월스트리트와 암호화폐 금융 모두에서 경험을 보유하고 있습니다. 또한 성공적인 스타트업 경력을 보유하고 있습니다.",
  "about.tech": "뛰어난 기술",
  "about.tech_reason":
    "당사의 인증된 매칭 엔진은 초당 1,400,000개의 주문을 처리할 수 있으며, 이를 통해 {APP_NAME}은 현재 시장에서 가장 빠른 거래소 중 하나입니다.",
  "about.tit":
    "Launched in 2018 {APP_NAME} is a  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, qttuis nostrud exercitation ullamco labor ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
  "account.address_manager": "주소 관리",
  "account.address_manager_content":
    "주소 관리 기능을 사용하면 각 출금 주소에 대해 메모를 저장하고 작성할 수 있습니다. 선택적 화이트리스트 기능은 출금 주소를 화이트리스트에 등록된 주소로만 제한하여 자금을 보호하는 데 도움을 줍니다.",
  "account.bank": "{0}",
  "account.btn_general": "일반",
  "account.btn_pending": "대기 중",
  "account.btn_rejected": "거부되었습니다",
  "account.btn_unverified": "인증되지 않음",
  "account.btn_uver_bank": "인증되지 않음",
  "account.btn_verified": "인증됨",
  "account.btn_verify_bank": "인증하기",
  "account.button_show": "더 보기",
  "account.change": "변경하기",
  "account.daily_higher_limit": "한도 증가",
  "account.daily_limit_withdraw": "24시간 출금 한도",
  "account.date": "날짜",
  "account.device": "장치",
  "account.device_management": "장치 관리",
  "account.devices_allowed":
    "이 장치들은 현재 귀하의 계정에 접근할 수 있습니다.",
  "account.disable": "비활성화",
  "account.discount": "수수료를 USD로 지불 시 (25% 할인)",
  "account.enable": "활성화",
  "account.error_bank": "* 출금 및 보안 변경에 사용됨",
  "account.google_auth": "구글 인증",
  "account.lost_google_authenticator": "구글 인증을 잃으셨나요?",
  "account.google_auth_content": "출금 및 보안 변경에 사용됨",
  "account.identity.choose_file": "파일 선택",
  "account.identity.country": "국가/지역",
  "account.identity.errors.character_limit":
    "이 필드는 {min}과 {max} 사이의 숫자만 포함해야 합니다.",
  "account.identity.errors.exist": "신분증이 이미 존재합니다.",
  "account.identity.errors.format_number":
    "이 필드는 0과 9 사이의 숫자만 포함하며, 특수 문자는 포함하지 않습니다.",
  "account.identity.errors.maxsize": "이미지 크기는 2MB 이하여야 합니다.",
  "account.identity.errors.required": "이 필드는 필수입니다.",
  "account.identity.example": "예시",
  "account.identity.female": "여성",
  "account.identity.fullname": "전체 이름",
  "account.identity.header_identity": "개인",
  "account.identity.header_verification": "인증 서류",
  "account.identity.identityID": "유효한 신분증",
  "account.identity.identity_back": "신분증 뒷면/여권 데이터 페이지",
  "account.identity.identity_cover": "신분증 앞면/여권 표지",
  "account.identity.identity_front": "신분증 앞면",
  "account.identity.identity_image_back":
    "사진이 완전하고 선명하게 보이도록 확인해 주세요. PNG 또는 JPG 형식이어야 하며, 신분증은 유효 기간 내여야 합니다.",
  "account.identity.identity_valid_card": "유효한 신분증",
  "account.identity.image_identity":
    "여권이 없으면, 운전면허증 또는 국가 신분증 앞면 사진을 업로드해 주세요.",
  "account.identity.image_passport":
    "이미지는 PNG 또는 JPG 형식으로 제출해야 하며, 사진의 주제가 완전하고 선명하게 보이도록 해야 합니다.",
  "account.identity.image_passport_data":
    "사진이 완전하고 선명하게 보이도록 확인해 주세요. PNG 또는 JPG 형식이어야 하며, 신분증은 유효 기간 내여야 합니다.",
  "account.identity.male": "남성",
  "account.identity.passport": "여권/신분증 번호",
  "account.identity.selfie": "사진 ID와 메모가 포함된 셀카",
  "account.identity.sex": "성별",
  "account.identity.submit": "제출",
  "account.identity.success": "생성 성공되었습니다.",
  "account.identity.update_success": "업데이트 성공되었습니다",
  "account.identity.sure_back":
    "여권이 없으면, 운전면허증 또는 국가 신분증 앞면 사진을 업로드해 주세요.",
  "account.identity.sure_front":
    "여권이 없으면, 운전면허증 또는 국가 신분증 앞면 사진을 업로드해 주세요.",
  "account.identity.text_condition":
    "업로드된 이미지는 얼굴과 텍스트 정보가 명확하게 보여야 합니다.",
  "account.identity.text_date": "오늘 날짜가 적힌 메모",
  "account.identity.text_face": "얼굴이 명확하게 보이도록",
  "account.identity.text_image": "사진 ID가 명확하게 보이도록",
  "account.identity.text_selfie":
    "신분증 앞면을 들고 있는 사진을 제공해 주세요. 같은 사진에 {APP_NAME}과 오늘 날짜를 표시하십시오. 얼굴이 명확하게 보이고 모든 여권 세부 사항이 읽을 수 있도록 하세요.",
  "account.identity.text_write": '"{APP_NAME}"이라는 단어가 포함된 메모',
  "account.identity.verification_other":
    "이 인증 옵션은 베트남 본토 외 모든 국가 및 지역의 ID를 가진 사용자에게 적용됩니다. 인증 과정에서 실제 신분을 사용해야 합니다. 개인 정보는 안전하게 보호됩니다. 사용 가능한 정부 발행 ID 형태는 다음과 같습니다:",
  "account.identity.verification_other1": "1. 여권",
  "account.identity.verification_other2": "2. 운전면허증",
  "account.identity.verification_other3": "3. 정부 발행 신분증",
  "account.invalid_device":
    "본인이 익숙하지 않거나 오랫동안 사용하지 않은 장치에서 로그인한 것으로 보입니다. 보안을 위해 확인 이메일이 귀하의 이메일 주소로 전송되었습니다.",
  "account.ip": "IP",
  "account.ip_address": "IP 주소",
  "account.last_login": "최근 로그인",
  "account.last_login_time": "최근 로그인 시간",
  "account.location": "위치",
  "account.login": "로그인 비밀번호",
  "account.lv": "레벨",
  "account.modal.agreement_otp.policy_content_text1": "1. 이메일 제목:",
  "account.modal.agreement_otp.policy_content_text2": "Google 인증기 중지 요청",
  "account.modal.agreement_otp.policy_content_text3": "2. 이메일 본문",
  "account.modal.agreement_otp.policy_content_text4": "- 실명",
  "account.modal.agreement_otp.policy_content_text5": "- 귀하의 휴대전화 번호",
  "account.modal.agreement_otp.policy_content_text6":
    "- {APP_NAME} 가입 시 이메일",
  "account.modal.agreement_otp.policy_content_text7": "3. 첨부파일",
  "account.modal.agreement_otp.policy_content_text8": "- 신분증 앞면",
  "account.modal.agreement_otp.policy_content_text9": "- 신분증 뒷면",
  "account.modal.agreement_otp.policy_content_text10":
    "- 신분증과 메모가 포함된 셀카",
  "account.modal.agreement_otp.policy_content_text11":
    "사진이 완전하고 선명하게 보이도록 확인해주세요.",
  "account.modal.agreement_otp.policy_content_text12":
    "신분증 앞면을 들고 있는 사진을 제공해주세요.",
  "account.modal.agreement_otp.policy_content_text13":
    "같은 사진에 bit-Castle과 오늘 날짜를 표시해주세요.",
  "account.modal.agreement_otp.policy_content_text14":
    "얼굴이 선명하게 보이고 모든 여권 정보가 명확하게 읽을 수 있도록 하세요.",
  "account.modal.agreement_otp.policy_content":
    "<span>복구 코드를 잃어버리고 Google 인증기를 중지할 수 없으시다면, help@mangoexchange로 요청 이메일을 보내주세요.</span>",
  "account.modal.agreement_otp.title": "복구 코드 분실",
  "account.modal.cancel_2fa.guide":
    '<p class="font-18">2단계 인증(OTP)을 중지하려면 Google 인증기 앱에서 6자리 인증 번호를 입력해야 합니다.</p><p class="font-18">휴대폰을 분실하거나 앱을 삭제한 경우, 아래 입력란에 16자리 복구 코드를 입력하여 인증을 비활성화할 수 있습니다.</p>',
  "account.modal.cancel_2fa.input_blank":
    "Google 인증기 OTP 코드를 입력해야 합니다.",
  "account.modal.cancel_2fa.otp": "OTP",
  "account.modal.cancel_2fa.otp_code": "인증 번호",
  "account.modal.cancel_2fa.otp_policy": "복구 코드도 분실하셨나요?",
  "account.modal.cancel_2fa.otp_restore": "복구 코드",
  "account.modal.cancel_2fa.stop_use_otp": "OTP 사용 중지",
  "account.modal.cancel_2fa.success": "OTP 비활성화 성공",
  "account.modal.cancel_2fa.title": "Google 인증기 비활성화",
  "account.modal.confirm": '<span class="font-18">확인</span>',
  "account.modal.confirm_trusted_device":
    "선택한 신뢰할 수 있는 장치를 삭제하시겠습니까?",
  "account.modal.date_time_pick.text1": "날짜 범위:",
  "account.modal.date_time_pick.text2": "마지막 24시간",
  "account.modal.date_time_pick.text3": "전날",
  "account.modal.date_time_pick.text4": "지난 2주",
  "account.modal.date_time_pick.text5": "최신",
  "account.modal.date_time_pick.text6": "지난 달",
  "account.modal.date_time_pick.text7": "지난 3개월",
  "account.modal.date_time_pick.text8": "사용자 지정 (최대 범위 3개월)",
  "account.modal.date_time_pick.text9": "내보내기",
  "account.modal.date_time_pick.title": "수수료 내역 내보내기",
  "account.modal.lost_otp.guide":
    "2단계 인증(OTP)을 중지하려면 복구 코드를 입력해야 합니다.",
  "account.modal.lost_otp.otp": "OTP",
  "account.modal.lost_otp.otp_code": "인증 번호",
  "account.modal.lost_otp.otp_policy": "복구 코드도 분실하셨나요?",
  "account.modal.lost_otp.otp_restore": "복구 코드",
  "account.modal.lost_otp.stop_use_otp": "OTP 사용 중지",
  "account.modal.lost_otp.title": "OTP 사용 중지",
  "account.ni_bank": "NI 은행 계좌",
  "account.no_commission_history": "수수료 내역이 없습니다.",
  "account.no_referral_history": "추천 내역이 없습니다.",
  "account.otp.authentication_failed": "인증 실패",
  "account.otp.back": "이전 단계",
  "account.otp.backup_text1":
    "Google 인증기를 재설정하려면 지원 티켓을 열어야 하며 처리에 최소 7일이 소요됩니다.",
  "account.otp.backup_text2":
    "이 키를 종이에 기록해 주세요. 이 키는 휴대폰 분실 시 Google 인증기를 복구할 수 있게 해줍니다.",
  "account.otp.backup_title": "STEP 3. 백업 복구 코드 저장",
  "account.otp.enable_des": "Google 인증기 활성화",
  "account.otp.enable_step1": "1. 앱 다운로드",
  "account.otp.enable_step2": "2. QR 코드 스캔",
  "account.otp.enable_step3": "3. 백업 복구 코드",
  "account.otp.enable_step4": "4. Google 인증기 활성화",
  "account.otp.enable_text1": "앱을 설치했습니다.",
  "account.otp.enable_text2": "QR 코드를 스캔했습니다.",
  "account.otp.enable_text3": "16자리 키를 이미 기록했습니다.",
  "account.otp.enable_title": "STEP 4. Google 인증기 활성화",
  "account.otp.install_text": "Google 인증기 앱을 다운로드하여 설치하세요.",
  "account.otp.install_title": "1단계: 앱 다운로드",
  "account.otp.next": "다음 단계",
  "account.otp.qr_text":
    "QR 코드를 스캔할 수 없을 경우, 이 코드를 앱에 수동으로 입력하세요.",
  "account.otp.qt_title": "2단계: QR 코드 스캔",
  "account.otp.success": "OTP 활성화 완료",
  "account.otp.txt_pass": "로그인 비밀번호",
  "account.phone_number_in_use":
    "이 전화번호는 이미 {APP_NAME} 계정에 연결되어 있습니다.",
  "account.recent_activity": "최근 활동",
  "account.referral_code": "귀하의 추천 코드:",
  "account.referral_notification":
    "친구를 {APP_NAME}에 추천하면 최대 20% 커미션을 받을 수 있습니다. 링크를 공유하세요:",
  "account.referral_program": "추천 프로그램",
  "account.submit": "인증 서류 제출",
  "account.change_verify_kyc": "인증 서류 변경",
  "account.title_device": "현재 귀하의 계정에 접근 가능한 장치",
  "account.tooltip1":
    "이 기능이 활성화되면 귀하의 계정은 화이트리스트 출금 주소로만 출금할 수 있습니다.",
  "account.tooltip2":
    "이 기능이 비활성화되면 모든 출금 주소로 출금이 가능합니다.",
  "account.turn_off_mode_whitelist":
    "이 기능을 비활성화하면 모든 출금 주소로 출금이 가능합니다. 비활성화하시겠습니까?",
  "account.turn_on_mode_whitelist":
    "이 기능을 활성화하면 화이트리스트 출금 주소로만 출금이 가능합니다. 활성화하시겠습니까?",
  "account.white_list": "화이트리스트",
  "account.white_list_off": "화이트리스트 끄기",
  "account.white_list_on": "화이트리스트 켜기",
  "account.withdrawal_address": "출금 주소 관리",
  "address.add_address": "출금 주소 관리",
  "address.add_success": "생성 완료!",
  "address.add_whitelist": "화이트리스트에 추가",
  "address.address": "주소",
  "address.all": "전체",
  "address.coin": "코인",
  "address.coin_name": "코인 이름",
  "address.confirm_remove_address": "삭제하시겠습니까?",
  "address.date": "날짜",
  "address.delete": "삭제",
  "address.deleted_success": "삭제되었습니다.",
  "address.errors.address_required": "주소는 필수 항목입니다.",
  "address.errors.blockchain_address": "출금 주소가 유효하지 않습니다.",
  "address.errors.coin_required": "코인 필드가 필수입니다.",
  "address.errors.exist_withdraw_address": "해당 주소가 이미 존재합니다.",
  "address.errors.label_required": "라벨 필드가 필수입니다.",
  "address.errors.max_length": "{field}는 {amount} 초과할 수 없습니다.",
  "address.errors.min_length": "{field}는 {amount} 이상이어야 합니다.",
  "address.errors.tag_required": "목적지 태그 필드는 필수 항목입니다.",
  "address.label": "라벨",
  "address.label_hint": "라벨은 20자를 초과할 수 없습니다.",
  "address.memo": "메모/ 태그",
  "address.none": "주소 없음.",
  "address.not_whitelisted": "와이트리스트되지 않습니다.",
  "address.only_dis": "와이트리스트된 주소만 노출됩니다.",
  "address.remove_whitelist": "와이트리스트에서 제거됩니다.",
  "address.select_coin": "코인 선택",
  "address.submit": "확인",
  "address.tag": "태그",
  "address.tooltip1":
    "해당 기능이 켜지면 와이트리스트된 출금 주소로만 출금이 가능합니다.",
  "address.tooltip2": "해당 기능이 꺼지면 아무 주소나 출금이 가능합니다.",
  "address.turn_off_mode_whitelist":
    "해당 기능이 꺼지면 아무 주소나 출금이 가능합니다. 진행하시겠습니까?",
  "address.turn_on_mode_whitelist":
    "해당 기능이 켜지면 와이트리스트된 출금 주소로만 출금이 가능합니다.  진행하시겠습니까?",
  "address.turn_on_whitelist": "와이트리스트 출금 기능을 먼저 켜주세요.",
  "address.update_white_list": "와이트리스트 출금 기능을 먼저 켜주세요.",
  "address.updated_success": "업데이트되었습니다.",
  "address.white_list_off": "와이트리스트가 꺼졌습니다.",
  "address.white_list_on": "와이트리스트가 켜졌습니다.",
  "address.whitelisted": "와이트리스트되었습니다.",
  "address.withdrawal_address": "출금주소관리",





  'admin.withdraw_status_pending': '펜딩',
  'admin.withdraw_status_success': '성공하였습니다.',
  'auth.confirmation_email_subject': '【Exchange】에서 계정을 활성화하세요 {APP_NAME}',
  'auth.email_confirm_success': '이메일이 확인되었습니다.',
  'auth.email_confirmation_code_invalid': '활성화 코드가 유효하지 않습니다.',
  'auth.failed': '이 자격 증명은 당사의 기록과 일치하지 않습니다.',
  'auth.forgot_password': '비밀번호를 잊으셨습니까?',
  'auth.otp_code_invalid': '구글 인증 코드가 유효하지 않습니다.',
  'auth.otp_code_required': '구글 인증 코드 입력란은 필수입니다.',
  'auth.otp_not_used': '다음 인증 코드가 생성될 때까지 기다려 주세요.',
  'auth.send_mail_failed': '이메일 전송에 실패했습니다. 다시 시도해 주세요.',
  'auth.throttle': '로그인 시도가 너무 많습니다. {seconds} 초 후에 다시 시도해 주세요.',
  'change_password_form.confirm': '보안 설정 변경 후 24시간 동안 출금이 제한됩니다.',
  'change_password_form.confirm_new_password': '새 비밀번호를 다시 입력하세요.',
  'change_password_form.error_confirm': '새 비밀번호 확인이 일치하지 않습니다.',
  'change_password_form.error_duplicated_password': '새 비밀번호는 이전 비밀번호와 달라야 합니다.',
  'change_password_form.error_message': '비밀번호가 변경되지 않았습니다!!!',
  'change_password_form.new_password': '새 비밀번호',
  'change_password_form.old_password': '기존 비밀번호',
  'change_password_form.require_confirm': '새 비밀번호 확인은 필수입니다.',
  'change_password_form.require_new': '새 비밀번호는 필수입니다.',
  'change_password_form.require_old': '기존 비밀번호는 필수입니다.',
  'change_password_form.submit_button': '제출',
  'change_password_form.submit_button_processing': '처리 중...',
  'change_password_form.successful_message': '비밀번호가 성공적으로 변경되었습니다!',
  'change_password_form.title': '비밀번호 변경',
  'client_download.desktop': '데스크탑',
  'client_download.destop_content': '저희 데스크탑 클라이언트는 원활하고 효율적인 거래 경험을 제공하기 위해 맞춤 설계되었습니다.',
  'client_download.more_way': '{APP_NAME}을 다양한 방법으로 얻으실 수 있습니다.',
  'client_download.provide_tool': '저희는 이동 중에도 거래에 필요한 도구들을 제공합니다.',
  'client_download.sp_download': '스마트폰 앱',
  'client_download.track': '어디서든 거래를 추적하고 관리하세요!',
  'common.refresh': '새로 고침',
  'common.action.cancel': '취소',
  'common.action.no': '아니요',
  'common.action.ok': '확인',
  'common.action.yes': '예',
  'common.confirm': '확인',
  'common.datatable.data_empty': '데이터가 없습니다.',
  'common.datatable.empty_data': '데이터가 없습니다.',
  'common.error.required': '{field} 필드는 필수 항목입니다.',
  'common.max': '최대 금액',
  'common.no_select': '선택되지 않음',
  'common.order_status.canceled': '취소',
  'common.order_status.filled': '체결완료',
  'common.order_status.partial_filled': '부분 체결',
  'common.placeholders.email': '이메일',
  'common.status.expired': '만료되었습니다.',
  'common.placeholders.login_password': '비밀번호를 입력하세요.',
  'common.placeholders.otp': '구글 인증 코드',
  'common.placeholders.password': '비밀번호',
  'common.placeholders.referral': '추천인 ID (선택 사항)',
  'common.placeholders.repeat_password': '비밀번호 재입력',
  'common.processing': '처리 중...',
  'common.trade_type.buy': '매수',
  'common.trade_type.sell': '매도',
  'common.transaction_status.cancel': '취소됨',
  'common.transaction_status.error': '오류',
  'common.transaction_status.pending': '펜딩',
  'common.transaction_status.rejected': '거부되었습니다.',
  'common.transaction_status.success': '송공',
  'common.transaction_status.submitted': '제출되었습니다.',
  'common.transaction_type.internal': '내부',
  'common.transaction_type.external': '외부',
  'common.window_title': '{APP_NAME}',
  'common_action.cancel': '취소',
  'common_action.confirm': '확인',
  'common_action.ok': '확인',
  'common.date.warning': '날짜 범위가 잘못되었습니다. 종료 날짜가 시작 날짜보다 빠릅니다!',
  'contact.contact_us': '문의',
  'contact.got_question': '질문 있으신가요?',
  'contact.non_account_related': '계정과 관련되지 않은 문의 사항은 온라인 커뮤니티에 참여하여 문의하세요:',
  'contact.please': '아직 찾고 계신 것을 찾지 못하셨나요?',
  'contact.please_check': '먼저 {APP_NAME} FAQ를 확인하여 질문에 대한 답변이 이미 있는지 확인해 주세요.',
  'contact.submit_request': '요청을 제출하기',
  'contact.view_suport': '지원 문서 보기',
  'currency.ada.fullname': 'Cardano',
  'currency.ada.shortname': 'ADA',
  'currency.mgc.fullname': 'Mango Coin',
  'currency.mgc.shortname': 'MGC',
  'currency.bch.fullname': 'Bitcoin Cash',
  'currency.bch.shortname': 'BCH',
  'currency.btc.fullname': 'Bitcoin',
  'currency.btc.shortname': 'BTC',
  'currency.eos.fullname': 'EOS',
  'currency.eos.shortname': 'EOS',
  'currency.eth.fullname': 'Ethereum',
  'currency.eth.shortname': 'ETH',
  'currency.tomo.fullname': 'TomoChain',
  'currency.tomo.shortname': 'TOMO',
  'currency.tusd.fullname': 'TrueUSD',
  'currency.tusd.shortname': 'TUSD',
  'currency.usd.fullname': 'United States Dollar',
  'currency.usd.shortname': 'USD',
  'currency.xlm.fullname': 'Stellar',
  'currency.xlm.shortname': 'XLM',
  'currency.xrp.fullname': 'Ripple',
  'currency.xrp.shortname': 'XRP',
  'emails.account': '계정',
  'emails.account_info': '은행：{bank}, 구좌번호：{accountNumber}, 구좌명의：{name}',
  'emails.amount': '금액',
  'emails.cannot_click_link': '링크를 클릭할 수 없으면 주소를 복사하여 주소바에 붙여주세요. 감사합니다.',
  'emails.confirm_email': '계정을 활성화하기 위해 아랫 링크를 클릭하세요. 이메일 확인을 통해서 고객에게 더 편하고 안전한 크립토 거래를 제공합니다.',
  'emails.confirm_kyc': '고객님의 KYC는 ',
  'emails.date': '시간:',
  'emails.deposit_alerts.text1': '입금 요청을 접수하였습니다.',
  'emails.deposit_alerts.text2': '입금 요청을 접수하였습니다.',
  'emails.deposit_alerts.text3': ' {APP_NAME}를 통해 해당 금액을 고객의 잔액에 추가되었습니다. ',
  'emails.deposit_alerts.title': '【{APP_NAME}】리필 요청이 진행되었습니다.',
  'emails.deposit_rejected_text2': '입금 규칙, 계좌 정보, 입금 코드를 확인하고 다시 시도해 주세요.',
  'emails.deposit_rejected_title': '【{APP_NAME}】 입금이 실패하였습니다.',
  'emails.deposit_usd_alerts.account_name': '예금주',
  'emails.deposit_usd_alerts.account_no': '계좌번호',
  'emails.deposit_usd_alerts.amount': '금액',
  'emails.deposit_usd_alerts.bank_branch': '은행 지점',
  'emails.deposit_usd_alerts.bank_name': '은행명',
  'emails.deposit_usd_alerts.code': '코드',
  'emails.deposit_usd_alerts.text1': '시스템에서 방금 사용자의 충전 거래 확인 요청을 받았습니다.',
  'emails.deposit_usd_alerts.text2': '거래 정보',
  'emails.deposit_usd_alerts.title': '새로운 충전 요청',
  'emails.received_liquid_position.subject': '[GS Exchange] 청산 알림: 귀하의 {symbol} 포지션이 청산되었습니다.',
  'emails.liquid_position_line_1': '귀하의 {symbol} 숏 포지션 {current_qty} 계약이 청산되었습니다.',
  'emails.liquid_position_line_2': '{symbol}의 마크 가격이 최근 {mark_price}로 상승했습니다. 귀하의 포지션의 청산 가격은 {liquidation_price}였습니다. 해당 포지션은 청산 엔진에 의해 청산되었습니다.',
  'emails.liquid_position_line_3': '{symbol} 포지션 세부 사항',
  'emails.liquid_position_line_4': '{leverage} 레버리지로 XBTUSD의 {current_qty} 계약을 숏 포지션으로 보유하고 있었습니다.',
  'emails.liquid_position_line_5': '{maint_margin}%의 유지 마진으로, 고객님의 청산 가격은 {liquidation_price}였으며, 파산 가격은 {bankrupt_price}였습니다(즉, 0% 마진).',
  'emails.liquid_position_line_6': '청산 당시 {date}, {symbol} 지수는 {index_price}까지 상승했습니다. 고객님이 거래하고 있던 계약인 {symbol}의 마크 가격은 {mark_price}였으며, 이는 해당 지수를 기반으로 합니다.',
  'emails.liquid_position.side': '거래방향',
  'emails.liquid_position.qty': '수량',
  'emails.liquid_position.lev': '레버리지',
  'emails.liquid_position.mark_price': '마크 가격',
  'emails.liquid_position.liq_price': '청산 가격',
  'emails.hello': '안녕하세요,',
  'emails.kyc_rejected': '거부되었습니다.',
  'emails.kyc_tilte': '【{APP_NAME}】에서 KYC를 확인하세요.',
  'emails.kyc_verified': '인증되었습니다.',
  'emails.new_deposit_amount': '금액:',
  'emails.new_deposit_text1': '입금 요청을 접수하였습니다.',
  'emails.new_deposit_title': '새로운 입금 요청',
  'emails.new_device.text1': '고객님이 최근 새로운 장치에서 {APP_NAME} 계정에 로그인하려고 시도하셨습니다. 보안상의 이유로, {APP_NAME} 계정에 접근하기 전에 추가적인 확인 절차가 필요합니다.',
  'emails.new_device.text2': '이 활동이 정상적인 경우, 새 장치를 아래에 인증할 수 있습니다.',
  'emails.new_login.text1': '시스템에서 사용되지 않은 IP 주소로 계정에 로그인한 것을 감지했습니다.',
  'emails.new_login.text2': '이 활동이 본인 작업이 아니시다면, 해당 기기를 비활성화하시고 즉시 저희에게 연락해 주시기 바랍니다.',
  'emails.new_withdrawal_text1': '출금 요청을 접수하였습니다.',
  'emails.new_withdrawal_title': '새로운 출금 요청',
  'emails.no_reply': '이것은 자동 메시지입니다. 회신하지 마십시오.',
  'emails.on': '활성화',
  'emails.say_hello': '안녕하세요 {email}?',
  'emails.team': '{APP_NAME} 팀',
  'emails.team_address': '(CO) {APP_NAME} - xxx 거리',
  'emails.team_inc': '모든 권리를 보유한 © {APP_NAME} 주식회사.',
  'emails.team_inform': '정보를 제공해 드리겠습니다.',
  'emails.team_service_center': '서비스 센터 / 이메일. <a href="mailto:cs@mango.exchange" target="_blank">cs@mango.exchange</a> / 전화번호. +84 915673038',
  'emails.team_slogan': '최고의 안호화폐 거래',
  'emails.thankyou': '감사합니다,',
  'emails.warning': '이 활동이 본인의 작업이 아니라면 즉시 저희에게 연락해 주세요.',
  'emails.warning_title': '{APP_NAME} 보안 경고',
  'emails.welcome': '{APP_NAME}에 오신 것을 환영합니다.',
  'emails.withdraw_alerts.text1': '출금 요청',
  'emails.withdraw_alerts.text2': '{APP_NAME} 계정에서 출금이 성공적으로 처리되었습니다.',
  'emails.withdraw_alerts.text3': '여기에서 확인 상태를 추적할 수 있습니다.',
  'emails.withdraw_alerts.title': '【{APP_NAME}】출금 요청이 성공적으로 처리되었습니다!',
  'emails.withdraw_errors_alerts.text1': '귀하의 출금 요청 거래',
  'emails.withdraw_errors_alerts.text2': '방금 실패했습니다. 걱정하지 마세요, 귀하의 사건은 처리 중입니다. 저희로부터 피드백을 기다려 주세요.',
  'emails.withdraw_errors_alerts.title': '【{APP_NAME}】출금 오류',
  'emails.withdraw_usd_alerts.account_name': '예금주',
  'emails.withdraw_usd_alerts.account_no': '계좌번호',
  'emails.withdraw_usd_alerts.amount': '금액',
  'emails.withdraw_usd_alerts.bank_branch': '은행 지점',
  'emails.withdraw_usd_alerts.bank_name': '은행명',
  'emails.withdraw_usd_alerts.text1': '시스템이 사용자 출금 요청을 방금 받았습니다.',
  'emails.withdraw_usd_alerts.text2': '거래 정보',
  'emails.withdraw_usd_alerts.title': '새로운 출금 요청',
  'emails.withdrawal_approved_text1': ':email님, 귀하의 출금 요청이 처리되었습니다.',
  'emails.withdrawal_approved_title': '【{APP_NAME}】 출금 완료',
  'exchange.basic.chart.btn_add_chart': '차트 추가',
  'exchange.basic.chart.buy_order': '매수 주문',
  'exchange.basic.chart.candle': '캔들 차트',
  'exchange.basic.chart.depth': '깊이',
  'exchange.basic.chart.msg_close_chart': '이 차트를 닫으시겠습니까?',
  'exchange.basic.chart.price': '가격',
  'exchange.basic.chart.sell_order': '매도 주문',
  'exchange.basic.label_hour': '시간',
  'exchange.basic.label_minute': '분',
  'exchange.basic.time.hour': '{number} 시',
  'exchange.basic.time.hours': '{number} 시',
  'exchange.basic.time.minute': '{number} 분',
  'exchange.basic.time.minutes': '{number} 분',
  'exchange.basic.time.one_day': '1일',
  'exchange.basic.time.one_month': '1달',
  'exchange.basic.time.one_week': '1주',
  'exchange.basic.trading_page.change_24h': '24시간 변경',
  'exchange.basic.trading_page.confirm': '확인하기',
  'exchange.basic.trading_page.evaluation_amount': '평가하기',
  'exchange.basic.trading_page.high': '높은',
  'exchange.basic.trading_page.high_24h': '24시간 최고',
  'exchange.basic.trading_page.holding_balance': '잔고',
  'exchange.basic.trading_page.last_price': '최종 가격',
  'exchange.basic.trading_page.low': '낮은',
  'exchange.basic.trading_page.low_24h': '24시간 최저',
  'exchange.basic.trading_page.total_purchase_amount': '구매됨',
  'exchange.basic.trading_page.volume': '거래량',
  'exchange.basic.trading_page.volume_24h': '24시간 거래량',
  'exchange.basic.trading_page.yeild': '수익',
  'exchange.basic.trading_page.yeild_tooltip.row1': 'USD 변환된 값을 기준으로.',
  'exchange.basic.trading_page.yeild_tooltip.row2': 'BTC, ETH 시장도 USD 값으로 변환됩니다.',
  'exchange.basic.trading_page.yeild_tooltip.row3': "입출금의 경우, 입금은 입금 시점의 현재 USD를 기준으로 '매수'로 간주되며, 출금은 출금 시점의 현재 USD를 기준으로 '매도'로 간주됩니다.",
  'exchange.basic.trading_page.yeild_tooltip.row4': '수익률 = (평가 금액 - 총 매수 금액) ÷ 총 매수 금액 × 100',
  'exchange.basic.trading_page.yeild_tooltip.row5': '판매된 자산은 계산에 포함되지 않습니다. 현재 보유 중인 자산만 해당됩니다.',
  'exchange.basic.trading_page.yeild_tooltip.row6': '이 수익률은 투자에 대한 절대적인 수익을 나타내지 않습니다. 투자 참고용으로만 사용하시기 바랍니다.',
  'favourite.market_price.change': '변경',
  'favourite.market_price.coin': '이름',
  'favourite.market_price.current_price': '최종 가격',
  'favourite.market_price.favorites': '즐겨찾기',
  'favourite.market_price.percent_day_before': '± %',
  'favourite.market_price.transaction_amount': '거래 금액',
  'favourite.market_price.volume': '거래량',
  'fee_guide.actual_purcharse': '실제 구매 금액',
  'fee_guide.actual_sell': '실제 판매',
  'fee_guide.commission_rate': '거래량에 따른 수수료율',
  'fee_guide.commission_tip1': '{APP_NAME}는 위와 같이 거래량에 따라 다른 수수료를 적용합니다. 수수료 등급은 변경될 수 있습니다.',
  'fee_guide.commission_tip2': '거래량 순위는 매일 00:00에 한 번 산정됩니다.',
  'fee_guide.commission_tip3': '메이커 / 테이커 수수료는 동일합니다.',
  'fee_guide.example': '예:',
  'fee_guide.fee': '수수료',
  'fee_guide.fomula_actual_pucharse': '',
  'fee_guide.for_example': '예를 들어',
  'fee_guide.formula_actual_sell': '',
  'fee_guide.formula_fee': '',
  'fee_guide.formula_sell_fee': '',
  'fee_guide.formula_subtotal': '',
  'fee_guide.formula_transaction_value': '',
  'fee_guide.last_30_days': '지난 30일',
  'fee_guide.less_than': '미만',
  'fee_guide.level_name': '레벨:',
  'fee_guide.level_title': '현재 귀하의 레벨은',
  'fee_guide.rating': '레벨',
  'fee_guide.subtotal': '총 USD (거래 금액)',
  'fee_guide.text13': '메이커 / 테이커 수수료는 동일합니다.',
  'fee_guide.text14': '매수 주문의 경우, 체결된 수량에서 수수료율에 해당하는 수량을 차감한 값이 실제 수량이 됩니다.',
  'fee_guide.text15': '매도 주문의 경우, 판매 가격에서 수수료율을 차감한 금액이 실제 판매 가격이 됩니다.',
  'fee_guide.text16': '주문이 체결된 경우, 수수료율은 0.2%, 가격은 5,000,000 USD이며 수량은 1 BTC입니다.',
  'fee_guide.text20': '매도 주문이 체결된 경우, 수수료율은 0.2%, 가격은 5,000,000 USD이며 수량은 1 BTC입니다.',
  'fee_guide.text24': '실제 수수료, 할인 등은 주문 화면에서 확인하실 수 있습니다.',
  'fee_guide.title': '수수료 정보',
  'fee_guide.transaction_fee': '1. 거래 수수료 계산 방법',
  'fee_guide.transaction_value': 'Payment USD (Transaction Value)',
  'funds.action.deposit': '입금',
  'funds.action.placeholder_input_coin': '코인 키워드를 입력해주세요.',
  'funds.action.withdraw': '출금',
  'funds.balances.avaliable_balance': '사용 가능한 잔액',
  'funds.balances.balance': '잔액',
  'funds.balances.no_data': '자금이 부족합니다.',
  'funds.balances.btc_value': 'BTC 가치',
  'funds.balances.coin': '코인 ',
  'funds.balances.deposit': '입금',
  'funds.balances.estimated_value': '예상 가치',
  'funds.balances.hide': '숨기기',
  'funds.balances.hide_small_assets': '작은 잔액 숨김처리',
  'funds.balances.in_order': '주문',
  'funds.balances.in_use': '이용',
  'funds.balances.infomation': '정보',
  'funds.balances.name': '이름',
  'funds.balances.show_all': '전체 노출',
  'funds.balances.small_balance': '작은 밸런스',
  'funds.balances.small_balance_notice': '{amount} BTC보다 작은 밸런스입니다.',
  'funds.balances.total_balance': '도달 밸런스',
  'funds.balances.withdrawal': '출금',
  'funds.balances.suspend': '벤딩',
  'funds.balances.trade': '거래',
  'funds.balances.withdrawal_limit_24': '24시간 출금 한도',
  'funds.deposit.available_balance': '사용 가능 잔액',
  'funds.deposit.choice': '선택',
  'funds.deposit.coppy_address': '주소 복사',
  'funds.deposit.copy_error': '복사 실패',
  'funds.deposit.copy_success': '복사됨',
  'funds.deposit.copy_tag': '태그 복사',
  'funds.deposit.deposit_address': '입금 주소',
  'funds.deposit.deposit_tag': '입금 태그',
  'funds.deposit.history': '내역',
  'funds.deposit.important.text2': "<span class='icon-favorites-2'></span> 이 입금 주소로는 :name만 전송하세요. 다른 통화를 이 주소로 전송하면 입금이 손실될 수 있습니다.",
  'funds.deposit.in_order': '진행 중',
  'funds.deposit.not_arrive': '입금이 도착하지 않았습니다',
  'funds.deposit.no_data': '입금 내역이 없습니다.',
  'funds.deposit.show_qr': 'QR 코드 보기',
  'funds.deposit.tips.text1': '참고 사항',
  'funds.deposit.tips.text2': '{name}은(는) {count} 네트워크 확인 후 즉시 입금됩니다.',
  'funds.deposit.tips.text3': "입금을 완료한 후, <a class='link-primary' href='/funds/history?type=deposit' target='_blank'>입금 내역</a> 페이지에서 진행 상황을 확인할 수 있습니다.",
  'funds.deposit.title_page': '입금',
  'funds.deposit.tooltip': "입금이 오랜 시간 동안 도착하지 않으면, 이는 블록체인의 혼잡 또는 거래 미완료로 인한 것일 수 있습니다. <a class='link-primary' href='#'>자세한 참고</a>",
  'funds.deposit.total_balance': '총 잔액',
  'funds.deposit.view_all': '모두 보기',
  'funds.deposit.what': '{name}은 무엇인가요?',
  'funds.deposit_usd.cancel_deposit_usd': '취소',
  'funds.deposit_usd.deposit_amount': '입금 금액',
  'funds.deposit_usd.deposit_note': '아래 정보를 기준으로 송금을 진행해 주세요. 저희가 확인 후 해당 금액을 귀하의 계좌로 이체하겠습니다. 감사합니다!',
  'funds.deposit_usd.errors.min_amount': '최소 출금 금액은 {min_amount}보다 커야 합니다.',
  'funds.deposit_usd.errors.required': '{field} 필드는 필수 항목입니다.',
  'funds.deposit_usd.guide.text1': '1. 입금 금액과 동일한 금액을 보내주세요.',
  'funds.deposit_usd.guide.text10': '4. 지불이 지연될 경우, 고객 지원팀에 문의해 주세요.',
  'funds.deposit_usd.guide.text11': '필수 입금 금액과 송금 금액이 다르거나 입금 코드를 입력하는 것을 잊으신 경우, 지원 센터에 요청을 제출해 주세요.',
  'funds.deposit_usd.guide.text12': '※ 출금은 은행 시스템의 점검 시간 동안 제한될 수 있습니다.',
  'funds.deposit_usd.guide.text2': '- 입금 금액이 실제 입금 금액과 다를 경우, 입금이 처리되지 않습니다.',
  'funds.deposit_usd.guide.text3': "2. 반드시 '입금 코드'를 입력해 주세요.",
  'funds.deposit_usd.guide.text4': '- 입금 시, 입금 코드를 이체 내용으로 입력해 주세요. 반드시 입금 코드만 이체 내용으로 사용하시고, 다른 내용을 입력하지 않도록 주의해 주세요. 입금 코드를 입력하지 않으면 입금 과정이 지연될 수 있습니다.',
  'funds.deposit_usd.guide.text7': '3. 입금은 최대 10분 이내에 처리됩니다.',
  'funds.deposit_usd.guide.text8': '- 정상적인 경우, 입금 처리에는 평균적으로 약 3~5분이 걸립니다.',
  'funds.deposit_usd.guide.text9': '입금이 처리되면 알려드리겠습니다.',
  'funds.deposit_usd.notes': '주의사항',
  'funds.deposit_usd.other_deposit': '다른 거래하기',
  'funds.deposit_usd.pending_transaction.account_name': '예금주',
  'funds.deposit_usd.pending_transaction.account_no': '계좌번호',
  'funds.deposit_usd.pending_transaction.amount': '금액',
  'funds.deposit_usd.pending_transaction.bank_branch': '은행 지점',
  'funds.deposit_usd.pending_transaction.bank_name': '은행명',
  'funds.deposit_usd.pending_transaction.code': '코드',
  'funds.deposit_usd.submit_deposit_usd': '접수',
  'funds.deposit_usd.success_message': '거래가 진행 중입니다.',
  'funds.deposit_usd.transaction_history_table.account': '계정',
  'funds.deposit_usd.transaction_history_table.amount': '계정',
  'funds.deposit_usd.transaction_history_table.bank': '은행',
  'funds.deposit_usd.transaction_history_table.code': '코드',
  'funds.deposit_usd.transaction_history_table.status': '상태',
  'funds.deposit_usd.transaction_history_table.time': '시간',
  'funds.history.address': '주소',
  'funds.history.amount': '금액',
  'funds.history.coin': '코인',
  'funds.history.date': '날짜',
  'funds.history.deposit': '입금',
  'funds.history.export_complete_deposit_history': '입금 내역이 추출되었습니다.',
  'funds.history.export_complete_withdraw_history': '출금 내역이 추출되었습니다.',
  'funds.history.from': 'From',
  'funds.history.history': '기록',
  'funds.history.infomation': '정보',
  'funds.history.status': '상태',
  'funds.history.tag': '태그',
  'funds.history.to': 'To',
  'funds.history.transaction_history': '거래 내역',
  'funds.history.txid': 'Txid',
  'funds.history.withdrawal': '출금',
  'funds.select_coin': ':action할 코인/토큰 선택',
  'funds.withdraw_usd.account_info': '계좌 정보',
  'funds.withdraw_usd.account_name': '계좌명',
  'funds.withdraw_usd.account_no': '계좌 번호',
  'funds.withdraw_usd.amount': '금액',
  'funds.withdraw_usd.available': '사용 가능',
  'funds.withdraw_usd.bank_branch': '은행 지점',
  'funds.withdraw_usd.bank_info': '은행 정보',
  'funds.withdraw_usd.bank_name': '은행 이름',
  'funds.withdrawals.add_new_address': '아래에 새로운 출금 주소를 입력하세요',
  'funds.withdrawals.amount': '금액',
  'funds.withdrawals.id': 'ID',
  'funds.withdrawals.type': '유형',
  'funds.withdrawals.sender': '발신자',
  'funds.withdrawals.receiver': '수신자',
  'funds.withdrawals.hash': '해시',
  'funds.withdrawals.status': '상태',
  'funds.withdrawals.created_time': '생성 시간',
  'funds.withdrawals.action': '작업',
  'funds.withdrawals.available_balance': '사용 가능 잔액',
  'funds.withdrawals.choice': '선택',
  'funds.withdrawals.no_data': '출금 기록이 없습니다.',
  'funds.withdrawals.confirm_otp': 'OTP 확인',
  'funds.withdrawals.confirm_withdraw': '출금 확인',
  'funds.withdrawals.did_not_receive_the_mail': '메일을 받지 못했습니다.',
  'funds.withdrawals.empty_whitelist.address_management': '주소 관리',
  'funds.withdrawals.empty_whitelist.text': '화이트리스트 주소가 없습니다.',
  'funds.withdrawals.error_message.address_not_exist_in_whitelist': '이 주소는 화이트리스트 주소에 없습니다.',
  'funds.withdrawals.error_message.amount_withdraw_is_positive': '출금 금액은 음수여야 합니다.',
  'funds.withdrawals.error_message.minimum_withdraw': '허용된 출금 금액보다 적습니다.',
  'funds.withdrawals.error_message.not_enough_balance': '계좌에 거래를 진행하기에 충분한 금액이 없습니다.',
  'funds.withdrawals.error_message.over_daily_limit': '오늘의 출금 한도를 초과했습니다',
  'funds.withdrawals.error_message.over_limit': '한도를 초과하여 출금하셨습니다.',
  'funds.withdrawals.error_message.over_one_time_limit': '한 번 초과 출금하셨습니다.',
  'funds.withdrawals.errors.account_no_length': '계좌 번호가 유효하지 않습니다.',
  'funds.withdrawals.errors.blockchain_address': '출금 주소가 유효하지 않습니다.',
  'funds.withdrawals.errors.exist_withdraw_address': '이 주소는 이미 존재합니다.',
  'funds.withdrawals.errors.max_balance': '계좌 잔액이 부족합니다.',
  'funds.withdrawals.errors.max_once_amount': '출금하신 금액이 한도를 초과했습니다.',
  'funds.withdrawals.errors.min_amount': '최소 출금 금액은 {min_amount}입니다.',
  'funds.withdrawals.errors.not_allowed_withdraw': '귀하의 보안 수준은 출금을 허용하지 않습니다.',
  'funds.withdrawals.errors.otp_length_digits': '인증 코드는 6자리여야 합니다.',
  'funds.withdrawals.errors.otp_required': '인증 코드 필드는 필수 항목입니다.',
  'funds.withdrawals.errors.required': ':field 필드는 필수 항목입니다.',
  'funds.withdrawals.history': '내역',
  'funds.withdrawals.important.text1': '중요',
  'funds.withdrawals.important.text2': '최소 출금',
  'funds.withdrawals.important.text3': '크라우드펀딩이나 ICO로 직접 출금하지 마세요. 해당 판매에서 나오는 토큰은 귀하의 계좌에 입금되지 않습니다.',
  'funds.withdrawals.in_order': '진행 중',
  'funds.withdrawals.input_address_error': '유효하지 않은 주소',
  'funds.withdrawals.loading': '처리 중...',
  'funds.withdrawals.submit': '제출하기',
  'funds.withdrawals.success_message': '성공한 거래',
  'funds.withdrawals.tips.text1': '주의하세요.',
  'funds.withdrawals.tips.text2': '출금 요청을 제출하시면, 확인 이메일을 보내드립니다. 이메일에 있는 확인 링크를 클릭하여 절차를 완료해 주세요.',
  'funds.withdrawals.tips.text3': "출금을 완료한 후에는 <a style='color: #265ac3;font-weight: bold;' href='/funds/history?type=withdraw' target='_blank'>내역</a> 페이지에서 진행 상황을 확인할 수 있습니다.",
  'funds.withdrawals.title_page': '출금',
  'funds.withdrawals.tooltip': "이메일을 찾을 수 없다면, 스팸 또는 다른 폴더를 확인해 주세요. <a href=':url' target='_blank'>아직 받지 못하셨나요?</a>",
  'funds.withdrawals.total_balance': '총 잔액',
  'funds.withdrawals.transaction_fee': '거래 수수료',
  'funds.withdrawals.use_a_new_address': '새 주소 사용',
  'funds.withdrawals.view_all': '모두 보기',
  'funds.withdrawals.what': '{name}은 무엇인가요?',
  'funds.withdrawals.withdrawal_address': '출금 주소',
  'funds.withdrawals.withdrawal_limit_24': '24시간 출금 한도',
  'funds.withdrawals.withdrawal_placeholder_address': '주소',
  'funds.withdrawals.withdrawal_placeholder_available': '가능',
  'funds.withdrawals.withdrawal_placeholder_label': '라벨',
  'funds.withdrawals.xrp_tag': '출금 태그',
  'funds.withdrawals.you_will_get': '받게 될 금액',
  'funds.disable_coin_msg': '계약이 동결되었습니다. 업그레이드를 기다리는 중',
  'landing.change': '24시간 변경',
  'landing.chart.change': '변경',
  'landing.chart.last_price': '최종 금액',
  'landing.clients_download': '다운로드',
  'landing.clients_text1': '{APP_NAME}을(를) 얻는 다양한 방법',
  'landing.clients_text2': '언제 어디서나 거래를 위한 필요한 도구들을 제공했습니다.',
  'landing.coin': '코인',
  'landing.coin_markets': '{coin} 마켓',
  'landing.favorites': '즐겨찾기',
  'landing.high': '24시간 최고',
  'landing.last_price': '최종 가격',
  'landing.low': '24시간 최저',
  'landing.pair': '거래쌍',
  'landing.price': '가격',
  'landing.register': '지금 등록하기',
  'landing.gotrade': '거래로 가기',
  'landing.slogan': '세계를 교환하다',
  'landing.volume': '24시간 거래량',
  'landing.current_rate': '현재 <br/> 환율',
  'login.active_device_failed': '귀하의 인증 링크가 유효하지 않거나 만료되었습니다!',
  'login.active_device_success': '새로운 기계의 인증에 성공했습니다. 다시 로그인해 주세요!<p></p><p><strong>장치: </strong> {platform} {operating_system}</p><p><strong>IP 주소: </strong> {latest_ip_address}</p>',
  'login.confirm_failed': '귀하의 확인 링크가 유효하지 않거나 만료되었습니다',
  'login.confirm_success': '귀하의 이메일이 확인되었습니다!',
  'login.dont_has_account': '계정이 필요합니다',
  'login.forgot': '비밀번호를 잊으셨나요?',
  'login.sub_title': '방문 중인 사이트를 확인해 주세요',
  'login.title': '로그인',
  'login.title_otp': '구글 인증',
  'm_account.btn_continue': '이해했습니다, 계속 진행',
  'm_account.change_pass': '비밀번호 변경',
  'm_account.gg_auth': '구글 인증',
  'm_account.go_pc': '{APP_NAME} PC 웹사이트로 가셔서 Google Authenticator 설정을 완료해 주세요.',
  'm_account.id': '아이디',
  'm_account.identity_auth': '신원 인증',
  'm_account.language': '언어',
  'm_account.logout': '로그아웃',
  'm_account.lv': '레벨',
  'm_account.referral.commission': '수수료',
  'm_account.referral.commission_rate': '수수료율',
  'm_account.referral.count': '번호',
  'm_account.referral.detail': '프로그램 상세',
  'm_account.referral.detail_notice': '{APP_NAME}은 시장 상황 변화, 사기 위험, 또는 기타 관련 있다고 판단되는 이유로 언제든지 추천 프로그램의 조건을 변경할 권리를 보유합니다.',
  'm_account.referral.estimate': '예상된 커미션',
  'm_account.referral.get_link': '1. 링크 받기',
  'm_account.referral.info_get_link': '회원 가입 후 추천 링크와 QR 코드를 생성하세요.',
  'm_account.referral.info_invite': '친구에게 추천 링크나 QR 코드를 통해 가입을 유도하고, 거래 완료 시 보상을 받으세요.',
  'm_account.referral.info_reward': ' 실시간으로 최대 40%의 커미션을 받으실 수 있습니다.',
  'm_account.referral.invite': '2. 친구 추천',
  'm_account.referral.line1': '2018년 5월 19일 0:00 AM (UTC)부터 시행됩니다.',
  'm_account.referral.line2': '추천 프로그램을 통해 받는 커미션은 초기에는 20%로 설정됩니다. 그러나 계정에 :COIN_HOLDING 이상의 금액이 보유되어 있는 경우, 이 비율은 40%로 증가합니다.',
  'm_account.referral.line3': '추천인이 각 거래를 완료하면 수수료 커미션이 실시간으로 즉시 귀하의 {APP_NAME} 계정으로 전송되며, 원래 수수료가 지불된 토큰/암호화폐로 지급됩니다.',
  'm_account.referral.line4': '추천할 수 있는 친구의 수에는 제한이 없지만, 당사는 언제든지 추천 프로그램 규칙을 조정하거나 변경할 권리를 보유합니다.',
  'm_account.referral.line5': '각 추천인은 귀하의 추천 링크, QR 코드 또는 추천 ID를 통해 가입해야 합니다.',
  'm_account.referral.line6': '{APP_NAME}은 중복 계정 또는 허위 계정을 확인하며, 이러한 계정에 대해서는 추천 보너스를 지급하지 않습니다. 중복되거나 공유된 재정은 실격 사유가 됩니다.',
  'm_account.referral.my_referral': '내 추천',
  'm_account.referral.notice': '*중요한 안내:',
  'm_account.referral.referral_friend': '추천 친구',
  'm_account.referral.referral_id': '내 추천 ID:',
  'm_account.referral.referral_link': '추천 링크:',
  'm_account.referral.reward': '3. 보상 받기',
  'm_account.referral.text29': '친구에게 추천하고 QR 코드 스캔으로 암호화폐 받으세요.',
  'm_account.referral.title': '추천 프로그램',
  'm_account.title': '구글 인증 코드를 {APP_NAME} 고객 지원 팀을 포함하여 누구에게도 공유하지 마세요.',
  'm_account.unverified': '미인증되었습니다.',
  'm_account.verified': '인증되었습니다.',
  'm_common.action.cancel': '취소',
  'm_common.action.confirm': '확인',
  'm_common.action.more': '더 보기',
  'm_common.action.off': 'OFF',
  'm_common.action.on': 'ON',
  'm_common.time.hour': '{number} 시간',
  'm_common.time.hours': '{number} 시간',
  'm_common.time.label_minute': '분',
  'm_common.time.minute': '{number} 분',
  'm_common.time.minutes': '{number} 분',
  'm_exchange.chart.amount': '금액',
  'm_exchange.chart.buy': '매수',
  'm_exchange.chart.candle': '캔들',
  'm_exchange.chart.charts': '차트',
  'm_exchange.chart.depth': '호가',
  'm_exchange.chart.high': '고가',
  'm_exchange.chart.low': '저가',
  'm_exchange.chart.market_trade_history': '시장가 거래 내역',
  'm_exchange.chart.price': '가격',
  'm_exchange.chart.sell': '매도',
  'm_exchange.chart.time': '시간',
  'm_exchange.chart.trade_detail': '거래 내역',
  'm_exchange.chart.trade_history': '거래 내역',
  'm_exchange.chart.volume': '거래 금액',
  'm_exchange.open_order.open_order': '미체결 주문',
  'm_exchange.trade.available': '가능',
  'm_exchange.trade.buy': '매수',
  'm_exchange.trade.sell': '매도',
  'm_exchange.trade.trade': '거래',
  'm_funds.coin_info.available': '주문 가능',
  'm_funds.coin_info.deposit': '입금',
  'm_funds.coin_info.estimate': '예상',
  'm_funds.coin_info.go_to_trade': '거래로 이동',
  'm_funds.coin_info.in_order': '주문 대기 중',
  'm_funds.coin_info.total': '총액',
  'm_funds.coin_info.withdraw': '출금',
  'm_funds.current': '현재가',
  'm_funds.deposit.save_qr_code': 'QR코드 저장',
  'm_funds.deposit.select_coin': '코인 선택',
  'm_funds.deposit_usd.available_balance': '사용 가능한 잔액',
  'm_funds.deposit_usd.deposit_amount': '입금액',
  'm_funds.deposit_usd.deposit_usd_tab': 'USD 입금',
  'm_funds.deposit_usd.errors.required': '{field} 필드는 필수 항목입니다.',
  'm_funds.deposit_usd.guide.text1': '1. 입금액과 동일한 금액을 송금해 주시기 바랍니다.',
  'm_funds.deposit_usd.guide.text10': '4. 입금 지연이 발생한 경우 고객센터로 문의해 주시기 바랍니다.',
  'm_funds.deposit_usd.guide.text11': '- 입금액과 송금액이 다르거나 입금 코드를 입력하지 않은 경우, 고객센터에 요청을 제출해 주시기 바랍니다.',
  'm_funds.deposit_usd.guide.text12': '※ 은행 시스템 점검 시간 동안 출금이 제한될 수 있습니다.',
  'm_funds.deposit_usd.guide.text2': '- 입금액이 실제 입금액과 다를 경우, 입금 처리가 진행되지 않습니다.',
  'm_funds.deposit_usd.guide.text3': "2. '입금 코드'를 반드시 입력해 주세요.",
  'm_funds.deposit_usd.guide.text4': '- 입금 시 이체 내용란에 입금 코드를 입력해 주세요. 이체 내용란에는 반드시 입금 코드만 입력해야 하며, 다른 내용을 추가하지 마세요. 입금 코드를 입력하지 않을 경우 입금 처리가 지연될 수 있습니다.',
  'm_funds.deposit_usd.guide.text7': '3. 입금은 최대 10분 이내에 처리됩니다.',
  'm_funds.deposit_usd.guide.text8': '- 일반적인 경우, 입금 처리 시간은 약 3분에서 5분 정도 소요됩니다.',
  'm_funds.deposit_usd.guide.text9': '입금이 처리되면 알려드리겠습니다.',
  'm_funds.deposit_usd.history_tab': '내역',
  'm_funds.deposit_usd.in_order': '주문 대기 중',
  'm_funds.deposit_usd.notes': '메모해 주세요.',
  'm_funds.deposit_usd.submit_deposit_usd': '제출',
  'm_funds.deposit_usd.total_balance': '총 잔액',
  'm_funds.deposit_usd.transaction_history_table.cancel': '취소',
  'm_funds.deposit_usd.transaction_history_table.no': '아니오',
  'm_funds.deposit_usd.transaction_history_table.text1': '취소하시겠습니까?',
  'm_funds.deposit_usd.transaction_history_table.yes': '예',
  'm_funds.transaction_history.deposit_history': '입금 내역',
  'm_funds.transaction_history.no_deposit_history': '입금 내역이 없습니다.',
  'm_funds.transaction_history.no_withdraw_history': '출금 내역이 없습니다.',
  'm_funds.transaction_history.withdraw_history': '출금 내역',
  'm_funds.withdraw_usd.history_tab': '내역',
  'm_funds.withdraw_usd.limit_and_notice': '한도 및 공지',
  'm_funds.withdraw_usd.withdraw_usd_tab': '출금 USD',
  'm_funds.withdrawals.but_withdraw': '출금',
  'm_funds.withdrawals.but_withdrawal': '출금',
  'm_funds.withdrawals.errors.required': '필수 항목입니다.',
  'm_funds.withdrawals.fee': '수수료',
  'm_funds.withdrawals.limit_and_notice': '한도 및 공지',
  'm_funds.withdrawals.limit_and_notice_modal.in_use': '사용 중',
  'm_funds.withdrawals.limit_and_notice_modal.title': '한도 및 공지',
  'm_funds.withdrawals.limit_and_notice_modal.withdrawal_limit_24': '24시간 출금 한도',
  'm_funds.withdrawals.notes': '<span> 최소 출금: {min} {coin}</span>',
  'm_funds.withdrawals.notes2': '크라우드펀딩이나 ICO로 직접 출금하지 마세요. 해당 판매의 토큰은 계좌에 입금되지 않습니다.',
  'm_funds.withdrawals.notes3': '출금 요청을 제출한 후, 확인 이메일을 발송합니다. 이메일에 있는 확인 링크를 클릭해주세요.',
  'm_funds.withdrawals.select_coin': '코인 선택',
  'm_funds.withdrawals.withdrawal_amount': '출금 금액',
  'm_landing.change_24h': '24시간 변화율',
  'm_landing.deposit': '입금',
  'm_landing.gainers': '상승 코인',
  'm_landing.losers': '하락 코인',
  'm_landing.more': '더 보기',
  'm_landing.support': '지원',
  'm_landing.top24btc': 'BTC 24시간 탑 거래량',
  'm_landing.withdraw': '출금',
  'm_menu.deposits': '입금',
  'm_menu.home': '홈',
  'm_menu.language': '언어',
  'm_menu.markets': '시장',
  'm_order_history.avg': '평균',
  'm_order_history.conditions': '조건',
  'm_order_history.more': '더 보기',
  'm_order_history.trade_detail': '거래 상세',
  'm_order_history.trading_price': '거래 가격',
  'm_pair_navigation.change_24h': '24시간 변화율',
  'm_pair_navigation.last_price': '마지막 가격',
  'm_pair_navigation.name': '이름',
  'm_pair_navigation.vol': '거래량',
  'menu.about': '정보',
  'menu.account': '계좌',
  'menu.address': '주소 관리',
  'menu.api': 'API',
  'menu.apply_to_list': '목록에 신청하기',
  'menu.balances': '잔액',
  'menu.change_password': '비밀번호 변경',
  'menu.contact': '연락처',
  'menu.deposit_usd': 'USD 입금',
  'menu.deposits': '입금',
  'menu.estimated_value': '예상 가치',
  'menu.exchange': '거래소',
  'menu.fees': '거래소 수수료',
  'menu.funds': '자금',
  'menu.history': '내역',
  'menu.history_title': '기록',
  'menu.info': '정보',
  'menu.join_us': '가입하기',
  'cms.notification.type': '유형',
  'cms.notification.type_all': '전체',
  'cms.notification.type_system_message': '시스템 메시지',
  'cms.notification.type_event': '이벤트',
  'cms.notification.type_campaign': '캠페인',
  'cms.notification.type_new_listing': '신규 상장',
  'cms.notification.type_copy_trade': '트레이드를 복사하기',
  'cms.notification.type_announcement': '공지사항',
  'cms.notification.search_by': '제목, 메시지로 검색',
  'cms.notification.created_time': '생성 시간',
  'cms.notification.id': '아이디',
  'cms.notification.language': '언어',
  'cms.notification.title': '제목',
  'cms.notification.message': '메시지',
  'cms.notification.status': '상태',
  'cms.notification.action': '액션',
  'cms.notification.image': '이미지',
  'cms.notification.popup_content_close': '이 알림을 닫으시겠습니까?',
  'cms.notification.popup_content_open': '이 알림을 열으시겠습니까?',
  'cms.notification.toast_close_success': '닫기 성공했습니다.',
  'cms.notification.toast_close_failed': '닫기 실패했습니다',
  'cms.notification.toast_open_success': '열기 성공했습니다',
  'cms.notification.toast_open_failed': '열기 실패했습니다',
  'cms.notification.toast_update_failed': '업데이트 실패했습니다',
  'cms.notification.toast_update_successful': '업데이트 성공했습니다',
  'cms.notification.toast_create_failed': '생성 실패했습니다',
  'cms.notification.toast_create_successful': '생성 성공했습니다',
  'cms.notification.href_url': 'URL 링크',
  'cms.notification.title_create': '알림: 만들기',
  'cms.notification.title_edit': '알림: 수정하기',
  'cms.notification.popup_content_edit': '이 알림을 업데이트하시겠습니까?',
  'cms.notification.select_type': '유형 선택하기',
  'cms.notification.create_type': '유형 만들기',
  'cms.notification.add_new_type': '새 유형 추가',
  'cms.notification.enter_type': '유형 입력',
  'cms.notification.new': '새로 만들기',
  'cms.mail_template.created_time': '생성 시간',
  'cms.mail_template.id': '아이디',
  'cms.mail_template.language': '언어',
  'cms.mail_template.name': '이름',
  'cms.mail_template.subject': '제목',
  'cms.mail_template.type': '유형',
  'cms.mail_template.action': '액션',
  'cms.mail_template.default': '기본',
  'cms.mail_template.select_language': '언어 선택...',
  'cms.mail_template.select_type': '유형 선택...',
  'cms.mail_template.title_edit': '이메일 템플릿: 수정하기',
  'cms.mail_template.title_create': '이메일 템플릿: 새로 만들기',
  'cms.mail_template.create_type': '유형 만들기',
  'cms.mail_template.toast_update_failed': '업데이트 실패했습니다',
  'cms.mail_template.toast_update_successful': '업데이트 성공했습니다',
  'cms.mail_template.toast_create_failed': '생성 실패했습니다',
  'cms.mail_template.toast_create_successful': '생성 성공했습니다',
  'cms.announcement.search_by': '제목, 카테고리로 검색',
  'cms.announcement.created_time': '생성 시간',
  'cms.announcement.id': '아이디',
  'cms.announcement.language': '언어',
  'cms.announcement.title': '제목',
  'cms.announcement.status': '상태',
  'cms.announcement.category': '카테고리',
  'cms.announcement.action': '액션',
  'cms.announcement.popular': '인기',
  'cms.announcement.select_language': '언어 선택...',
  'cms.announcement.select_category': '카테고리 선택...',
  'cms.announcement.create_category': '카테고리 생성',
  'cms.announcement.title_edit': '공지사항: 수정하기',
  'cms.announcement.title_create': '공지사항: 새로 만들기',
  'cms.announcement.toast_update_failed': '업데이트 실패했습니다.',
  'cms.announcement.toast_update_successful': '업데이트 성공했습니다',
  'cms.announcement.toast_create_failed': '생성 실패했습니다',
  'cms.announcement.toast_create_successful': '생성 성공했습니다',
  'cms.announcement.toast_delete_success': '삭제 성공했습니다',
  'cms.announcement.toast_delete_failed': '삭제 실패했습니다',
  'cms.announcement.type_maintenance': '유지보수',
  'cms.announcement.type_coin_listings': '코인 상장',
  'cms.announcement.type_latest_news': '최신 뉴스',
  'cms.announcement.add_new_category': '새 카테고리 추가',
  'cms.announcement.enter_category': '카테고리 입력',
  'cms.announcement.active_status': '활성 상태',
  'cms.faq.search_by': '질문으로 검색',
  'cms.faq.created_time': '생성 시간',
  'cms.faq.id': '아이디',
  'cms.faq.language': '언어',
  'cms.faq.question': '질문',
  'cms.faq.status': '상태',
  'cms.faq.category': '문의 유형',
  'cms.faq.action': '관리',
  'cms.faq.status_1': '답변대기',
  'cms.faq.status_2': '답변완료',
  'cms.faq.select_language': '언어 선택...',
  'cms.faq.select_category': '카테고리 선택...',
  'cms.faq.active_status': '활성 상태',
  'cms.faq.toast_delete_success': '삭제 성공했습니다',
  'cms.faq.toast_delete_failed': '삭제 실패했습니다',
  'cms.faq.toast_update_failed': '업데이트 실패했습니다',
  'cms.faq.toast_update_successful': '업데이트 성공했습니다',
  'cms.faq.toast_create_failed': '생성 실패했습니다',
  'cms.faq.toast_create_successful': '생성 성공했습니다',
  'cms.faq.popup_delete_confirm': '이 질문을 삭제하시겠습니까?',
  'cms.faq.user_id': '사용자 ID',
  'cms.faq.title_edit': 'FAQ: 수정하기',
  'cms.faq.title_create': 'FAQ: 새로 만들기',
  'cms.qna.title_edit': '문의 상세',
  'cms.qna.title_create': '문의사항: 새로 만들기',
  'cms.qna.category_1': 'Account Functions',
  'cms.qna.category_2': 'Crypto Deposit/Withdrawal',
  'cms.qna.category_3': 'Spot Trading',
  "cms.qna.question_author": "질문 등록자",
  "cms.qna.question_created": "질문 등록일",
  'menu.lang.en': '영어어',
  'menu.lang.ja': '일본어',
  'menu.lang.vis': '비사얀어',
  'menu.lang.il': '일로카노어',
  'menu.lang.tl': '타갈로그어',
  'menu.login': '로그인',
  'menu.logout': '로그아웃',
  'menu.news': '뉴스',
  'menu.one_day': '1일',
  'menu.one_month': '1개월',
  'menu.one_week': '1주일',
  'menu.open_orders': '미체결 주문',
  'menu.or': '또는',
  'menu.order_history': '주문 내역',
  'menu.privacy': '개인정보 처리방침',
  'menu.register': '회원가입',
  'menu.support': '지원',
  'menu.support_withdrawal': '출금 가이드',
  'menu.terms': '이용 약관',
  'menu.three_months': '3개월',
  'menu.trade_history': '거래 기록',
  'menu.transaction_history': '거래 내역',
  'menu.withdraw_usd': 'USD 출금',
  'menu.withdrawals': '출금',
  'menu.cms.notification': '알림',
  'menu.cms.mail_template': '이메일 템플릿',
  'menu.cms.announcement': '공지사항',
  'menu.cms.faq': '자주 묻는 질문',
  'menu.cms.qna': '문의',
  'messages.error.balance_insufficient': '잔액이 부족합니다',
  'messages.error.spot.balance_insufficient': '거래소 잔액이 부족합니다',
  'messages.error.airdrop.balance_insufficient': '에어드롭 잔액이 부족합니다',
  'messages.error.margin.balance_insufficient': '마진 잔액이 부족합니다',
  'messages.error.main.available_balance_insufficient': '사용 가능한 잔액이 부족합니다',
  'messages.error.spot.available_balance_insufficient': '사용 가능한 거래소 잔액이 부족합니다',
  'messages.error.airdrop.available_balance_insufficient': '사용 가능한 에어드롭 잔액이 부족합니다',
  'messages.error.margin.available_balance_insufficient': '사용 가능한 마진 잔액이 부족합니다',
  'messages.error.buy.empty_base_price': '매수 스탑 가격을 입력해주세요.',
  'messages.error.buy.empty_limit': '매수 제한 가격을 입력해주세요.',
  'messages.error.buy.empty_price': '매수가를 입력해주세요.',
  'messages.error.buy.empty_quantity': '매수 수량을 입력해주세요.',
  'messages.error.empty_total': '총액이 필요합니다.',
  'messages.error.minimum_base_price': '스탑 가격은 최소 {minimum} 이상이어야 합니다.',
  'messages.error.minimum_limit': '제한 가격은 최소 {minimum} 이상이어야 합니다.',
  'messages.error.minimum_price': '가격은 최소 {minimum} 이상이어야 합니다.',
  'messages.error.minimum_quantity': '수량은 최소 {minimum} 이상이어야 합니다.',
  'messages.error.minimum_total': '총액은 최소 {minimum} 이상이어야 합니다.',
  'messages.error.sell.empty_base_price': '매도 스탑 가격을 입력해주세요.',
  'messages.error.sell.empty_limit': '매도 제한 가격을 입력해주세요.',
  'messages.error.sell.empty_price': '매도가를 입력해주세요.',
  'messages.error.sell.empty_quantity': '매도 수량을 입력해주세요.',
  'messages.insufficient_balance': '잔액이 부족합니다.',
  'messages.send_contact_success': '이메일 전송이 완료되었습니다.',
  'messages.send_contact_wrong_data': '잘못된 데이터',
  'messages.sesstion_terminated': '다른 기기 또는 브라우저에서 이 계정으로 로그인하여 현재 세션이 종료되었습니다.',

  'messages.unauthorize': '권한 없음',
  'messages.your_otp_code': '인증 코드는 {otp}입니다.',
  'order.open_order.no_data': '현재 진행 중인 주문이 없습니다.',
  'order.open_order.action': '관리',
  'order.open_order.amount': '수량',
  'order.open_order.cancel': '취소',
  'order.open_order.cancel_all': '모두 취소',
  'order.open_order.cancel_all_message': '모든 주문을 취소하시겠습니까?',
  'order.open_order.cancel_limit_order': '지정가 주문 취소',
  'order.open_order.cancel_limit_price_message': '모든 지정가 주문을 취소하시겠습니까?',
  'order.open_order.cancel_one_message': '이 주문을 취소하시겠습니까?',
  'order.open_order.cancel_order_success': '주문이 취소되었습니다.',
  'order.open_order.cancel_orders_success': '주문들이 취소되었습니다.',
  'order.open_order.cancel_stop_limit_order': '스탑 리미트 주문 취소',
  'order.open_order.cancel_stop_limit_price_message': '모든 스탑 리미트 주문을 취소하시겠습니까?',
  'order.open_order.cancel_stop_market_order': '스탑 마켓 주문 취소',
  'order.open_order.cancel_stop_market_price_message': '모든 스탑 마켓 주문을 취소하시겠습니까?',
  'order.open_order.date': '날짜',
  'order.open_order.deposit': '입금',
  'order.open_order.empty_open_order': '진행 중인 주문이 없습니다.',
  'order.open_order.filled': '체결',
  'order.open_order.hide_other_pairs': '다른 페어 숨기기',
  'order.open_order.hide_small_assets': '소액 자산 숨기기',
  'order.open_order.infomation': '정보',
  'order.open_order.limit_order': '지정가 주문',
  'order.open_order.market': '시장',
  'order.open_order.open_order': '진행 중인 주문',
  'order.open_order.pair': '거래 페어',
  'order.open_order.price': '가격',
  'order.open_order.side': '사이드',
  'order.open_order.size': '사이즈',
  'order.open_order.stop_limit_order': '스탑 리미트 주문 취소',
  'order.open_order.total': '총액',
  'order.open_order.trigger_conditions': '트리거 조건',
  'order.open_order.type': '유형',
  'order.open_order.withdrawal': '출금',
  'order.open_order.withdrawal_limit_24': '24시간 출금 한도',
  'order.order_book.amount': '금액',
  'order.order_book.decimals': '소수점',
  'order.order_book.price': '가격',
  'order.order_book.total': '총액',
  'order.order_form.amount': '수량',
  'order.order_form.balance': '{param} 잔액',
  'order.order_form.buy': '매수',
  'order.order_form.limit': '리미트',
  'order.order_form.limited_price': '리미트',
  'order.order_form.login': '로그인',
  'order.order_form.or': '또는',
  'order.order_form.price': '가격',
  'order.order_form.register': '등록',
  'order.order_form.sell': '매도',
  'order.order_form.stop': '스탑',
  'order.order_form.stop_limit_tooltip': '스탑 리밋 주문은 특정 트리거 가격에 도달하면 지정된 가격으로 코인을 매수 또는 매도하는 주문입니다.',
  'order.order_form.stop_market_tooltip': '스탑 마켓 주문은 특정 트리거 가격에 도달하면 시장가로 코인을 매수 또는 매도하는 주문입니다.',
  'order.order_form.success': '{trade_type} 주문이 성공적으로 생성되었습니다!',
  'order.order_form.total': '총액',
  'order.order_form.trade': '거래할',
  'order.order_form.view_more': '더 보기...',
  'order.order_history.action': '액션',
  'order.order_history.no_data': '주문 내역이 없습니다.',
  'order.order_history.all': '전체',
  'order.order_history.amount': '금액',
  'order.order_history.average': '평균',
  'order.order_history.buy': '매수',
  'order.order_history.cancel': '취소',
  'order.order_history.success': '성공',
  'order.order_history.cancel_all': '모든 취소',
  'order.order_history.coin': '코인',
  'order.order_history.date': '날짜',
  'order.order_history.deposit': '입금',
  'order.order_history.empty_order_history': '주문 내역이 없습니다.',
  'order.order_history.export_complete_order_history': '전체 주문 내역 내보내기',
  'order.order_history.export_complete_order_history_tooltip': '6개월 이내의 전체 주문 내역만 내보낼 수 있습니다.',
  'order.order_history.export_complete_trade_history': '전체 거래 내역 출력',
  'order.order_history.export_complete_trade_history_tooltip': '최근 6개월의 거래 내역 출력',
  'order.order_history.fee': '수수료료',
  'order.order_history.filled': '체결됨',
  'order.order_history.hide_all_canceled': '취소건 숨김',
  'order.order_history.infomation': '정보',
  'order.order_history.limit_order': '지정가 주문',
  'order.order_history.market': '시장',
  'order.order_history.order_history': '주문 내역',
  'order.order_history.pair': '페어',
  'order.order_history.price': '가격',
  'order.order_history.reset': '초기화',
  'order.order_history.search': '검색',
  'order.order_history.sell': '매도',
  'order.order_history.side': '사이드',
  'order.order_history.status': '상태',
  'order.order_history.stop_limit_order': '스톱 지정가 주문',
  'order.order_history.total': '총',
  'order.order_history.trigger_conditions': '트리거 조건',
  'order.order_history.type': '유형',
  'order.order_history.withdrawal': '출금',
  'order.order_history.withdrawal_limit_24': '24시간 출금 한도',
  'order.recent_trades.market': '시장',
  'order.recent_trades.recent_trades': '최근 거래',
  'order.recent_trades.yours': '나의 거래',
  'order.trade_history.action': '관리',
  'order.trade_history.no_data': '거래 내역이 없습니다.',
  'order.trade_history.all': '전체',
  'order.trade_history.amount': '수량',
  'order.trade_history.average': '평균',
  'order.trade_history.buy': '매수',
  'order.trade_history.cancel': '최소',
  'order.trade_history.cancel_all': '전체 취소',
  'order.trade_history.coin': '코인',
  'order.trade_history.date': '날짜',
  'order.trade_history.deposit': '입금',
  'order.trade_history.export_complete_trade_history': '전체 거래 내역 출력',
  'order.trade_history.fee': '수수료',
  'order.trade_history.filled': '체결됨',
  'order.trade_history.hide_all_canceled': '취소건 숨김',
  'order.trade_history.infomation': '정보',
  'order.trade_history.limit_order': '지정가 주문',
  'order.trade_history.market': '시장',
  'order.trade_history.my_history': '나의 주문 내역',
  'order.trade_history.pair': '페어',
  'order.trade_history.price': '가격',
  'order.trade_history.reset': '초기화',
  'order.trade_history.search': '검색',
  'order.trade_history.sell': '매도',
  'order.trade_history.side': '사이드',
  'order.trade_history.status': '상태',
  'order.trade_history.stop_limit_order': '스톱 지정가 주문',
  'order.trade_history.total': '총',
  'order.trade_history.trade_history': '거래 내역',
  'order.trade_history.trigger_conditions': '트리거 조건',
  'order.trade_history.type': '유형',
  'order.trade_history.withdrawal': '출금',
  'order.trade_history.withdrawal_limit_24': '24시간 출금 한도',
  'order_mobile.charts': '차트',
  'order_mobile.group': '그룹',
  'order_mobile.max_amount': '최대 수량',
  'order_mobile.open_order': '대기 주문',
  'order_mobile.total': '총',
  'order_mobile.trade': '거래',
  'passwords.password': '비밀번호는 최소 6자 이상이어야 하며 비빌번호 확인과 일치해야 합니다.',
  'passwords.reset': '비밀번호가 재설정되어 이제 로그인할 수 있습니다.',
  'passwords.sent': '비밀번호 재설정 링크를 이메일로 전송했습니다!',
  'passwords.token': '비밀번호 재설정 토큰이 잘못되었습니다.',
  'passwords.user': '해당 이메일 주소로 사용자를 찾을 수 없습니다.',
  'refferal.alert': '로그인하지 않으셨습니다. 로그인하여 친구들과 공유하세요.',
  'refferal.alert_bank_account_content': 'NI 은행 계좌를 확인하기 전에 <span style="color: #f29600;">KYC</span>를 제출하세요. KYC 정보를 사용하여 은행 계좌를 확인합니다.',
  'refferal.confirm_bank_account_content': '아직 은행 계좌가 없습니다. 계좌를 만드는 데 도움을 주세요.',
  'refferal.confirm_bank_account_title': 'KYC 정보를 사용하여 USD 출금을 위해 NI 은행 계좌를 검증하시겠습니까?',
  'refferal.get_rewards': '리워드 받가',
  'refferal.login': '로그인',
  'refferal.not_on_site': '아직 {APP_NAME}을(를) 사용하지 않으시나요?',
  'refferal.register': '가입하기',
  'refferal.copy_link': '링크 복사 ',
  'refferal.text1': '등록하고 추천 링크와 QR 코드를 생성하세요.',
  'refferal.text10': '추천받은 친구',
  'refferal.text11': '날짜',
  'refferal.text12': '프로그램 세부 정보',
  'refferal.text13': '2018/05/19 0:00 AM (UTC)부터 유효합니다.',
  'refferal.text14': '추천 프로그램에서 받는 수수료는 처음에는 20%로 설정됩니다. 500 {COIN_HOLDING} 이상을 보유한 계정의 경우 이 비율은 40%로 증가합니다.',
  'refferal.text15': '추천인이 각 거래를 완료하면 수수료 수수료가 실시간으로 귀하의 {APP_NAME} 계정으로 즉시 전송되며, 원래 수수료가 지불된 토큰/암호화폐로 귀하에게 지급됩니다.',
  'refferal.text16': '추천할 수 있는 친구 수에는 제한이 없습니다. 하지만 당사는 언제든지 추천 프로그램 규칙을 조정하거나 변경할 권리를 보유합니다.',
  'refferal.text18': '각 추천인은 추천 링크, QR 코드 또는 추천 ID를 통해 등록되어야 합니다.',
  'refferal.text19': '{APP_NAME}은 중복 또는 가짜 계정을 확인하고 이러한 계정에 추천 보너스를 지급하지 않습니다. 중복 또는 공유 자금은 실격 처리됩니다.',
  'refferal.text2': '친구 초대',
  'refferal.text20': '주의사항',
  'refferal.text21': '{APP_NAME}은 시장 상황의 변화, 사기 위험 또는 당사가 관련이 있다고 생각되는 기타 요인으로 인해 언제든지 추천 프로그램의 약관을 변경할 권리가 있습니다.',
  'refferal.text22': '커미션 ',
  'refferal.text23': '추정된 <br/> 커미션 가치',
  'refferal.text24': '최근 커미션 내역',
  'refferal.text25': '복사 ',
  'refferal.text26': '출력',
  'refferal.text3': '추천 링크나 QR 코드를 통해 친구를 초대하여 등록하고 친구가 거래를 완료하면 보상을 받을 수 있습니다.',
  'refferal.text4': '수령 금액',
  'refferal.text5': '실시간으로 최대 40%의 커미션을 받을 수 있습니다.',
  'refferal.text6': '추천 ID:',
  'refferal.text7': '커미션 비율',
  'refferal.text8': '추천 링크',
  'refferal.text9': '공유',
  'refferal.title': '추천 프로그램',
  'refferal.title1': '링크를 가져가기.',
  'refferal.top': 'NO.',
  'register.error_terms': '약관을 동의해주세요.',
  'register.has_account': '이미 계정이 있으신가요?',
  'register.sub_title': '계정 등록 ',
  'register.success': '계정을 확인하기 위해서 메일박스를 체크하세요.',
  'register.terms_1': '에 동의합니다',
  'register.terms_2': '약관',
  'register.title': '등록',
  'reset_password.resetted_password': '비밀번호가 재설정되었습니다. 지금 로그인하세요.',
  'reset_password.send_mail_title': '',
  'reset_password.sent_mail': '새 비밀번호가 등록된 이메일로 전송되었습니다. 24시간 이내 이메일에 적힌 안내를 따라하여 비밀번호를 재설정하세요.',
  'reset_password.sub_title_1': '이메일을 입력하세요',
  'reset_password.sub_title_2': '새 비밀번호를 입력하세요',
  'reset_password.submit_btn': '접수',
  'reset_password.title': '비밀번호 재설정',
  'reset_password.title_forgot': '비밀번호 찾기',
  'service_center.send_contact_subject': '{APP_NAME}로 연락처를 보내기',
  'shared_components.alert.error': '에러',
  'shared_components.alert.primary': '정보',
  'shared_components.alert.success': '성공',
  'shared_components.alert.warning': '약함',
  'shared_components.common.confirmation_modal.awesome': '최고',
  'shared_components.common.confirmation_modal.danger': '위험',
  'shared_components.common.confirmation_modal.info': '정보',
  'shared_components.common.confirmation_modal.warning': '약함',
  'shared_components.google_authentication_modal.lost_code': '구글 인증 정보를 잃어버리셨습니다.',
  'shared_components.google_authentication_modal.otp_code_invalid': '구글 인증 코드가 유효하지 않습니다.',
  'shared_components.google_authentication_modal.submit_button': '제출',
  'shared_components.google_authentication_modal.title': '구글 인증',
  'shared_components.pagination.next': '다음',
  'shared_components.pagination.pre': '이전',
  'masterdata.text': '마스터 데이터가 변경되었습니다.</br>페이지를 다시 로드해주세요.',
  'unregistered_session.login': '로그인',
  'unregistered_session.text': '현재 세션이 종료되었습니다. 다시 로그인해주세요!',
  'validation.messages._default': '{0} 값이 유효하지 않습니다.',
  'validation.messages.after': '{0}는 {1} 이후여야 합니다.',
  'validation.messages.after2': '{0}는 {1} 이후 또는 동일해야 합니다.',
  'validation.messages.alpha_dash': '{0} 필드는 알파벳, 숫자, 대시(-), 밑줄(_)을 포함할 수 있습니다.',
  'validation.messages.alpha_num': '{0} 필드는 알파벳과 숫자만 포함할 수 있습니다.',
  'validation.messages.alpha_spaces': '{0} 필드는 알파벳과 공백만 포함할 수 있습니다.',
  'validation.messages.alpha': '{0} 필드는 알파벳 문자만 포함할 수 있습니다.',
  'validation.messages.before': '{0}는 {1} 이전이어야 합니다.',
  'validation.messages.before2': '{0}는 {1} 이전 또는 동일해야 합니다.',
  'validation.messages.between': '{0} 필드는 {1}에서 {2} 사이여야 합니다.',
  'validation.messages.confirmed': '{0} 확인이 일치하지 않습니다.',
  'validation.messages.credit_card': '{0} 필드가 유효하지 않습니다.',
  'validation.messages.date_between': '{0}는 {1}에서 {2} 사이여야 합니다.',
  'validation.messages.date_format': '{0}는 {1} 형식이어야 합니다.',
  'validation.messages.decimal': '{0} 필드는 숫자여야 하며 {1} 소수점을 포함할 수 있습니다.',
  'validation.messages.digits': '{0} 필드는 숫자여야 하며 정확히 {1} 자릿수를 포함해야 합니다.',
  'validation.messages.dimensions': '{0} 필드는 {1} 픽셀 x {2} 픽셀이어야 합니다.',
  'validation.messages.email': '{0} 필드는 유효한 이메일이어야 합니다.',
  'validation.messages.ext': '{0} 필드는 유효한 파일이어야 합니다.',
  'validation.messages.image': '{0} 필드는 이미지여야 합니다.',
  'validation.messages.in': '{0} 필드는 유효한 값이어야 합니다.',
  'validation.messages.integer': '{0} 필드는 정수여야 합니다.',
  'validation.messages.ip': '{0} 필드는 유효한 IP 주소여야 합니다.',
  'validation.messages.length': '{0} 길이는 {1}이어야 합니다.',
  'validation.messages.length2': '{0} 길이는 {1}에서 {2} 사이여야 합니다.',
  'validation.messages.max': '{0} 필드는 {1}자를 초과할 수 없습니다.',
  'validation.messages.max_value': '{0} 필드는 {1} 이하이어야 합니다.',
  'validation.messages.mimes': '{0} 필드는 유효한 파일 형식을 가져야 합니다.',
  'validation.messages.min': '{0} 필드는 최소 {1}자 이상이어야 합니다.',
  'validation.messages.min_value': '{0} 필드는 {1} 이상이어야 합니다.',
  'validation.messages.not_in': '{0} 필드는 유효한 값이어야 합니다.',
  'validation.messages.numeric': '{0} 필드는 숫자 문자만 포함할 수 있습니다.',
  'validation.messages.regex': '{0} 필드 형식이 유효하지 않습니다.',
  'validation.messages.required': '{0} 필드는 필수 항목입니다.',
  'validation.messages.size': '{0} 크기는 {1}보다 작아야 합니다.',
  'validation.messages.url': '{0} 필드는 유효한 URL이 아닙니다.',
  'validation.accepted': '{attribute}는 반드시 수락되어야 합니다.',
  'validation.active_url': '{attribute}는 유효한 URL이 아닙니다.',
  'validation.after': '{attribute}는 {date} 이후의 날짜여야 합니다.',
  'validation.after_or_equal': '{attribute}는 {date} 이후 또는 동일한 날짜여야 합니다.',
  'validation.alpha': '{attribute}는 문자만 포함할 수 있습니다.',
  'validation.alpha_dash': '{attribute}는 문자, 숫자, 대시만 포함할 수 있습니다.',
  'validation.alpha_num': '{attribute}는 문자와 숫자만 포함할 수 있습니다.',
  'validation.array': '{attribute}는 배열이어야 합니다.',
  'validation.attributes.account_name': '계좌명',
  'validation.attributes.account_number': '계좌 번호',
  'validation.attributes.bank_id': '은행',
  'validation.attributes.base_price': '스톱 가격',
  'validation.attributes.date_of_birth': '생년월일',
  'validation.attributes.new_password': '새 비밀번호',
  'validation.attributes.otp_recovery_code': '복구 코드',
  'validation.attributes.password': '비밀번호',
  'validation.attributes.quantity': '금액',
  'validation.attributes.wallet_address': '지갑 주소',
  'validation.attributes.withdrawal_address': '출금 주소',
  'validation.before': '{attribute}는 {date} 이전의 날짜여야 합니다.',
  'validation.before_or_equal': '{attribute}는 {date} 이전 또는 동일한 날짜여야 합니다.',
  'validation.between.array': '{attribute}는 {min}에서 {max} 항목 사이여야 합니다.',
  'validation.between.file': '{attribute}는 {min}에서 {max} 킬로바이트 사이여야 합니다.',
  'validation.between.numeric': '{attribute}는 {min}에서 {max} 사이여야 합니다.',
  'validation.between.string': '{attribute}는 {min}에서 {max} 문자 사이여야 합니다.',
  'validation.boolean': '{attribute} 필드는 true 또는 false여야 합니다.',
  'validation.confirmed': '{attribute} 확인이 일치하지 않습니다.',
  'validation.correct_otp': '인증 코드가 올바르지 않습니다.',
  'validation.custom.agree_term.required': '이용 약관을 읽고 동의해야 합니다.',
  'validation.custom.attribute-name.rule-name': '사용자 정의 메시지',
  'validation.custom.base_price.min': '스톱 가격은 최소 {min} 이상이어야 합니다.',
  'validation.custom.base_price.precision': '스톱 가격은 {precision}의 정밀도를 가져야 합니다.',
  'validation.custom.id_back.image': '파일은 이미지여야 합니다.',
  'validation.custom.id_back.mimes': '파일은 {values} 형식의 파일이어야 합니다.',
  'validation.custom.id_front.image': '파일은 이미지여야 합니다.',
  'validation.custom.id_front.mimes': '파일은 {values} 형식의 파일이어야 합니다.',
  'validation.custom.id_selfie.image': '파일은 이미지여야 합니다.',
  'validation.custom.id_selfie.mimes': '파일은 {values} 형식의 파일이어야 합니다.',
  'validation.custom.password.confirmed': '비밀번호는 비밀번호 입력란과 일치해야 합니다.',
  'validation.custom.password.correct_password': '비밀번호가 올바르지 않습니다.',
  'validation.custom.password.min': '비밀번호는 최소 8자, 대문자, 소문자, 숫자를 포함해야 하며 비밀번호 확인란과 일치해야 합니다.',
  'validation.custom.password.regex': '비밀번호는 최소 8자, 대문자, 소문자, 숫자를 포함해야 하며 비밀번호 확인란과 일치해야 합니다.',
  'validation.custom.price.min': '가격은 최소 {min} 이상이어야 합니다.',
  'validation.custom.price.precision': '가격은 {precision}의 정밀도를 가져야 합니다.',
  'validation.custom.quantity.min': '금액은 최소 {min} 이상이어야 합니다.',
  'validation.custom.quantity.precision': '금액은 {precision}의 정밀도를 가져야 합니다.',
  'validation.custom.total.min': '총액은 최소 {min} 이상이어야 합니다.',
  'validation.custom.total.precision': '총액은 {precision}의 정밀도를 가져야 합니다.',
  'validation.date': '{attribute}는 유효한 날짜가 아닙니다.',
  'validation.date_format': '{attribute}가 :format 형식과 일치하지 않습니다.',
  'validation.different': '{attribute}와 {other}는 서로 달라야 합니다.',
  'validation.digits': '{attribute}는 :digits 자리 숫자여야 합니다.',
  'validation.digits_between': '{attribute}는 {min}에서 {max} 자리 사이의 숫자여야 합니다.',
  'validation.dimensions': '{attribute}는 {min} 자리에서 {max} 자리 사이여야 합니다.',
  'validation.distinct': '{attribute}의 이미지 크기가 유효하지 않습니다.',
  'validation.email': '{attribute} 필드에 중복된 값이 있습니다.',
  'validation.exists': '{attribute}는 유효한 이메일 주소여야 합니다.',
  'validation.file': '선택된 {attribute}가 유효하지 않습니다.',
  'validation.filled': '{attribute}는 파일이어야 합니다.',
  'validation.image': '{attribute} 필드에는 값이 있어야 합니다.',
  'validation.in': '{attribute}는 이미지여야 합니다.',
  'validation.in_array': '선택된 {attribute}가 유효하지 않습니다.',
  'validation.integer': '{attribute} 필드는 {other}에 존재하지 않습니다.',
  'validation.ip': '{attribute}는 정수여야 합니다.',
  'validation.ipv4': '{attribute}는 유효한 IP 주소여야 합니다.',
  'validation.ipv6': '{attribute}는 유효한 IPv4 주소여야 합니다.',
  'validation.is_withdrawal_address': '{attribute}는 유효한 IPv6 주소여야 합니다.',
  'validation.json': '{attribute}가 유효하지 않습니다.',
  'validation.max.array': '{attribute}는 유효한 JSON 문자열이어야 합니다.',
  'validation.max.file': '{attribute}는 {max} 항목을 초과할 수 없습니다.',
  'validation.max.numeric': '{attribute}는 {max}킬로바이트를 초과할 수 없습니다.',
  'validation.max.string': '{attribute}는 {max}를 초과할 수 없습니다.',
  'validation.mimes': '{attribute}는 {values} 유형의 파일이어야 합니다.',
  'validation.mimetypes': '{attribute}는 {values} 유형의 파일이어야 합니다.',
  'validation.min.array': '{attribute}는 최소 {min} 항목이 있어야 합니다.',
  'validation.min.file': '{attribute}는 최소 {min}킬로바이트여야 합니다.',
  'validation.min.numeric': '{attribute}는 최소 {min} 이상이어야 합니다.',
  'validation.min.string': '{attribute}는 최소 {min}자 이상이어야 합니다.',
  'validation.min_value': '{attribute}는 최소 {min} 이상이어야 합니다.',
  'validation.not_in': '선택된 {attribute}가 유효하지 않습니다.',
  'validation.numeric': '{attribute}는 숫자여야 합니다.',
  'validation.otp': '인증에 실패했습니다.',
  'validation.otp_not_used': '다음 인증 코드를 생성할 때까지 기다려 주세요.',
  'validation.otp_or_google_auth_required': 'Google 인증 코드를 입력해야 합니다.',
  'validation.password_white_space': '비밀번호는 공백을 포함할 수 없습니다.',
  'validation.precision': '{attribute}는 {precision}의 정밀도를 가져야 합니다.',
  'validation.present': '{attribute} 필드는 반드시 존재해야 합니다.',
  'validation.regex': '{attribute} 형식이 유효하지 않습니다.',
  'validation.required': '{attribute} 필드는 필수 항목입니다.',
  'validation.required_if': '{other}가 {value}일 때 {attribute} 필드는 필수 항목입니다.',
  'validation.required_unless': '{other}가 {values}에 포함되지 않을 때 {attribute} 필드는 필수 항목입니다.',
  'validation.required_with': '{values}가 존재할 때 {attribute} 필드는 필수 항목입니다.',
  'validation.required_with_all': '{values}가 존재할 때 {attribute} 필드는 필수 항목입니다.',
  'validation.required_without': '{values}가 존재하지 않을 때 {attribute} 필드는 필수 항목입니다.',
  'validation.required_without_all': '{values} 중 어느 것도 존재하지 않을 때 {attribute} 필드는 필수 항목입니다.',
  'validation.same': '{attribute}와 {other}는 일치해야 합니다.',
  'validation.size.array': '{attribute}는 {size} 항목을 포함해야 합니다.',
  'validation.size.file': '{attribute}는 {size}킬로바이트여야 합니다.',
  'validation.size.numeric': '{attribute}는 {size}여야 합니다.',
  'validation.size.string': '{attribute}는 {size}자여야 합니다.',
  'validation.string': '{attribute}는 문자열이어야 합니다.',
  'validation.timezone': '{attribute}는 유효한 영역이어야 합니다.',
  'validation.unique': '{attribute}는 이미 사용 중입니다.',
  'validation.unique_email': '이메일은 이미 사용 중입니다.',
  'validation.unique_referrer_code': '추천 코드는 유효하지 않습니다.',
  'validation.unique_withdrawal_address_in_user': '출금 주소가 이미 존재합니다.',
  'validation.uploaded': '{attribute} 업로드에 실패했습니다.',
  'validation.url': '{attribute} 형식이 유효하지 않습니다.',
  'validation.verified_email': '이메일이 아직 확인되지 않았습니다.',
  'validation.verify_otp_or_google_auth': 'Google 인증 코드 필드를 정확히 입력해야 합니다.',
  'validation.verify_otp_recovery_code': '{attribute}가 유효하지 않습니다.',
  'validation.verify_otp_recovery_code_with_auth': '{attribute}가 올바르지 않습니다.',
  'withdraw_guide.currency': '통화',
  'withdraw_guide.deposit_fee': '입금 수수료',
  'withdraw_guide.email': '이메일 인증',
  'withdraw_guide.fee': '수수료',
  'withdraw_guide.fee_withdrawal': '출금 수수료',
  'withdraw_guide.free': '무료',
  'withdraw_guide.gotp': 'Google OTP',
  'withdraw_guide.identity_verification': '신원 인증',
  'withdraw_guide.level': '단계 구분',
  'withdraw_guide.level_name': '레벨',
  'withdraw_guide.level_title': '현재 인증 레벨은',
  'withdraw_guide.limit_tip1': '출금 한도는 개인 인증 레벨에 따라 다릅니다.',
  'withdraw_guide.limit_tip2': '시장 상황 및 해당 은행(은행)의 규정에 따라 위의 출금 한도는 사전 공지 없이 변경될 수 있습니다.',
  'withdraw_guide.limit_tip3': '사기 거래가 의심되는 경우 출금이 제한될 수 있습니다.',
  'withdraw_guide.limit_tip4': '출금 수수료는 통화 금액에 관계없이 고정 금액으로 부과됩니다.',
  'withdraw_guide.limit_tip5': '암호화폐 출금의 경우, 실제 수수료는 블록체인 네트워크 상태에 따라 표시된 수수료보다 높거나 낮아질 수 있습니다.',
  'withdraw_guide.limit_title1': '인증 단계별 출금 한도',
  'withdraw_guide.limit_title2': '입출금 수수료 및 최소 출금액',
  'withdraw_guide.minimum_withdrawal': '최소 출금액',
  'withdraw_guide.one_day': '1일',
  'withdraw_guide.one_time': '1회',
  'withdraw_guide.phone': '전화 인증',
  'withdraw_guide.security_method': '인증 방법',
  'withdraw_guide.support_withdrawal': '입출금 안내',
  'withdraw_guide.usd': 'USD',
  'trade_type.buy': '매수',
  'trade_type.sell': '매도',
  'common.message.network_error': '서버에 연결할 수 없습니다.',
  'common.status.success': '성공',
  'common.status.pending': '대기 중',
  'common.status.cancel': '취소됨',
  'common.status.error': '오류',
  'common.search': '검색',
  'common.action.confirm': '확인',
  'common.action.reject': '거부',
  'common.action.create': '생성',
  'common.kyc.pending': '대기 중',
  'common.kyc.verified': '확인됨',
  'common.order_status.pending': '대기 중',
  'common.empty_data': '데이터가 없습니다.',
  'menu.orders.orders': '주문',
  'menu.orders.spot': '현물',
  'menu.orders.open_order': '미체결 주문',
  'menu.orders.order_history': '주문 내역',
  'menu.orders.trade_history': '거래 내역',
  'menu.notices': '공지사항',
  'menu.email_marketing': '이메일 마케팅',
  'menu.home': '홈',
  'usd_transaction.time': '시간',
  'usd_transaction.user': '이메일',
  'usd_transaction.account': '계정',
  'usd_transaction.bank_name': '은행',
  'usd_transaction.bank_branch': '지점',
  'usd_transaction.status': '상태',
  'usd_transaction.amount': '금액',
  'kyc.list': 'KYC 목록',
  'kyc.input_search': '검색',
  'kyc.created_at': '시간',
  'kyc.email': '이메일',
  'kyc.name': '성명',
  'kyc.country': '국가',
  'kyc.id_number': '여권/ID 번호',
  'kyc.status': '상태',
  'kyc.gender': '성별',
  'kyc.id_front': '신분증 앞면/여권 표지',
  'kyc.id_back': '신분증 뒷면/여권 데이터 페이지',
  'kyc.id_selfie': '사진 ID와 메모가 포함된 셀카',
  'kyc.btn_detail': '상세 정보',
  'kyc.btn_verify': '확인',
  'kyc.btn_reject': '거부',
  'kyc.notice_verify_confirm': '이 KYC를 확인하시겠습니까?',
  'kyc.notice_reject_confirm': '이 KYC를 거부하시겠습니까?',
  'kyc.detail_kyc': 'KYC 세부 정보',
  'kyc.search': '검색',
  'kyc.reset': '초기화',
  'kyc.data': '데이터',
  'kyc.action': '관리',
  'kyc.status_pending': '대기 중',
  'kyc.status_verified': '승인완료',
  'kyc.status_unverified': '미진행',
  'kyc.status_rejected': '거부',
  'kyc.2fa.on': '켜짐',
  'kyc.2fa.off': '꺼짐',
  'kyc.account_id': '사용자 ID',
  'user.id': 'ID',
  'user.user': '사용자',
  'user.user_information': '사용자 정보',
  'user.date': '날짜',
  'user.email': '이메일',
  'user.fee_level': '수수료 수준',
  'user.security_level': '보안 수준',
  'user.max_security_level': '최대 보안 수준',
  'user.referrer_code': '추천 코드',
  'user.referrer_mail': '추천인 이메일',
  'user.type': '유형',
  'user.status': '상태',
  'user.normal': '일반',
  'user.bot': '봇',
  'user.inactive': '비활성',
  'user.active': '활성',
  'user.save': '저장',
  'user.edit': '수정',
  'user.device': '장치',
  'user.recent_activity': '최근 활동',
  'device_management.title_device_management': '장치 관리',
  'user.succes': '성공적으로 업데이트되었습니다!',
  'user.user_setting': '사용자 설정',
  'notice.edit_title_page': '공지사항 등록',
  'notice.new_title_page': '공지사항 등록',
  'notice.title': '제목',
  'notice.date': '날짜',
  'notice.search': '검색',
  'notice.reset': '초기화',
  'notice.new': '새로 만들기',
  'notice.support': '연결 링크',
  'notice.start': '시작됨',
  'notice.end': '종료됨',
  'notice.edit': '수정',
  'notice.delete': '삭제',
  'notice.start_at': '시작일',
  'notice.end_at': '종료일',
  'notice.banner': '배너',
  'notice.submit': '등록',
  'notice.cancel': '취소',
  'notice.confirm': '확인',
  'notice.search_by_title': '제목으로 검색.....',
  'notice.choose_file': '파일 선택',
  'notice.delete_data': '이 공지를 삭제하시겠습니까?',
  'notice.submit_data': '이 공지를 제출하시겠습니까?',
  'notice.errors.required': '이 필드는 필수입니다.',
  'notice.errors.maxsize': '이미지 크기는 2MB 이하이어야 합니다.',
  'notice.errors.date': '종료 날짜는 시작 날짜보다 더 나중이어야 합니다.',
  'notice.noticeEdit': '공지 수정',
  'qna.submit_data': '이 답변을 제출하시겠습니까?',
  'orders.open_order.open_order': '대기 주문',
  'orders.open_order.date': '날짜',
  'orders.open_order.from': '시작',
  'orders.open_order.to': '종료',
  'orders.open_order.pair': '거래쌍',
  'orders.open_order.symbol': '심볼 ',
  'orders.open_order.country': '국가',
  'orders.open_order.search_by_email': '이메일별로 검색',
  'orders.open_order.search': '검색',
  'orders.open_order.reset': '초기화 ',
  'orders.open_order.time': '시간 ',
  'orders.open_order.trade_id': '거래 아이디',
  'orders.open_order.order_id': '주문 아이디',
  'orders.open_order.email': '이메일',
  'orders.open_order.price': '가격',
  'orders.open_order.filled': '체결완료',
  'orders.open_order.fee': '수수료',
  'orders.open_order.total': '총',
  'orders.open_order.type': '유형',
  'orders.open_order.action': '관리',
  'orders.open_order.amount': '수량',
  'orders.open_order.trigger_condition': '트리거 조건',
  'orders.open_order.status': '상태',
  'orders.open_order.average': '평균',
  'orders.open_order.side': '사이드',
  'orders.open_order.buy': '매수',
  'orders.open_order.sell': '매도',
  'orders.open_order.all': '전체',
  'orders.open_order.trade_history': '거래내역',
  'orders.open_order.index': '번호',
  'orders.open_order.view_detail': '상세 보기',
  'orders.open_order.market': '시장',
  'orders.open_order.cancel_successful': '취소가 완료되었습니다. ',
  'orders.open_order.cancel_failed': '취소가 실패했습니다. ',
  'orders.open_order.cancel_modal_content': '해당 주문을 취소하겠습니까?',
  'orders.order_history.order_history': '주문 내역',
  'orders.order_history.date': '날짜',
  'orders.order_history.pair': '페어',
  'orders.order_history.search_by_email': '이메일별로 검색',
  'orders.order_history.search': '검색',
  'orders.order_history.reset': '초기화 ',
  'orders.order_history.time': '시간 ',
  'orders.order_history.trade_id': '거래 아이디',
  'orders.order_history.order_id': '주문 아이디',
  'orders.order_history.email': '이메일',
  'orders.order_history.price': '가격',
  'orders.order_history.filled': '체결완료',
  'orders.order_history.fee': '수수료',
  'orders.order_history.total': '총',
  'orders.order_history.type': '유형',
  'orders.order_history.action': '관리',
  'orders.order_history.amount': '수량',
  'orders.order_history.trigger_condition': '트리거 조건',
  'orders.order_history.status': '상태',
  'orders.order_history.average': '평균가',
  'orders.order_history.quantity': '수량',
  'orders.order_history.account_id': '유저 아이디',
  'orders.order_history.side': '사이드',
  'orders.order_history.buy': '매수',
  'orders.order_history.sell': '매도',
  'orders.order_history.all': '전체',
  'orders.order_history.hide_all_canceled': '취소건 숨김',
  'orders.order_history.trade_history': '거래 내역',
  'orders.order_history.index': '번호',
  'orders.order_history.view_detail': '상세 보기',
  'orders.order_history.market': '시장 ',
  'orders.trade_history.trade_history': '거래내역',
  'orders.trade_history.date': '날짜',
  'orders.trade_history.pair': '거래쌍',
  'orders.trade_history.search_by_email': '이메일별로 검색',
  'orders.trade_history.search': '검색',
  'orders.trade_history.reset': '초기화',
  'orders.trade_history.time': '시간',
  'orders.trade_history.trade_id': '거래 아이디',
  'orders.trade_history.original_buy_order_id': '최초 매수 주문 ID',
  'orders.trade_history.buyer_email': '매수자 이메일',
  'orders.trade_history.original_sell_order_id': '최초 매도 주문 ID',
  'orders.trade_history.seller_email': '매도자 이메일',
  'orders.trade_history.price': '가격',
  'orders.trade_history.filled': '체결됨',
  'orders.trade_history.buy_fee': '매수 수수료',
  'orders.trade_history.sell_fee': '매도 수수료',
  'orders.trade_history.total': '총',
  'orders.trade_history.market': '시장',
  'orders.trade_history.all': '전체',
  'orders.trade_history.data': '데이터',
  'orders.trade_history.account_id': '유저 아이디',
  'orders.trade_history.buy_id': '매수 아이디',
  'orders.trade_history.sell_id': '매도 아이디',
  'orders.trade_history.side': '사이드',
  'orders.trade_history.quantity': '수량',
  'orders.trade_history.action': '관리',
  'account.status.active': '활성화',
  'account.status.inactive': '비활성화',
  'account.list.level': '등급',
  'account.list.kyc_status': 'KYC 상태',
  'account.list.fa': '2FA',
  'account.list.active_status': '활성 상태',
  'account.list.search_by': '계정 ID, 이메일로 검색',
  'account.account_detail.message_change_status_success': '활성화 상태 변경이 완료했습니다!',
  'account.account_detail.message_change_status_fail': '활성화 상태 변경이 실패했습니다!',
  'account.account_detail.joined_time': '가입 시간',
  'account.account_detail.activation_status': '활성화 상태',
  'account.account_detail.email': '이메일',
  'account.account_detail.phone_number': '전화번호',
  'account.account_detail.level': '레벨',
  'account.account_detail.kyc_status': 'KYC 상태',
  'account.account_detail': '계정 상세',
  'account.account_detail.account_id': '사용자 아이디',
  'account.account_detail.spot_future': '현물/선물 프로필',
  'account.account_detail.balance': '잔액',
  'account.account_detail.logs': '로그',
  'account.account_detail.transactions': '거래 내역',
  'account.account_detail.affiliate': '제휴',
  'account.account_detail.activity_history': '활동 내역',
  'account.account_detail.open_order': '미체결 주문',
  'account.account_detail.order_history': '주문 내역',
  'account.account_detail.trade_history': '거래 내역',
  'account.account_detail.update': '업데이트',
  'account.account_detail.confirm_active': '이 계정을 활성화하시겠습니까?',
  'account.account_detail.confirm_deactive': '이 계정을 비활성화하시겠습니까?',
  'account.spot_future.confirm_update_spot': '현물 프로필을 업데이트하시겠습니까?',
  'account.spot_future.confirm_update_futures': '선물 프로필을 업데이트하시겠습니까?',
  'account.spot_future.spot_profile': '현물 프로필',
  'account.spot_future.future_profile': '선물 프로필',
  'account.spot_future.allow_trade': '거래 허용',
  'account.spot_future.market_maker': '마켓 메이커 계좌',
  'account.spot_future.trading_fee': '거래 수수료',
  'account.spot_future.pre_trading': '프리마켓 거래쌍',
  'account.spot_future.popup_text_content': '이 계정을 비활성화하시겠습니까?',
  'account.spot_future.update_successful': '업데이트를 성공했습니다!',
  'account.spot_future.update_failed': '업데이트를 실패했습니다!',
  'account.account_detail.coin': '코인',
  'account.account_detail.in_order': '주문 중',
  'account.account_detail.available': '이용 가능',
  'account.account_detail.total': '총액',
  'account.account_detail.action': '관리',
  'account.account_detail.balance_spot': '현물 잔액',
  'account.account_detail.balance_future': '선물 잔액',
  'account.logs.spot': '현물 잔액 로그',
  'account.logs.futures': '선물 잔액 로그',
  'account.logs.id': '아이디',
  'account.logs.type': '유형',
  'account.logs.amount': '금액',
  'account.logs.note': '메모',
  'account.logs.create_time': '생성 시간',
  'account.transaction.withdraw_transaction': '출금 거래',
  'account.transaction.deposit_transaction': '입금 거래',
  'account.transaction.transfer_transaction': '이체 거래',
  'account.transaction.id': '아이디',
  'account.transaction.type': '형식',
  'account.transaction.amount': '금액',
  'account.transaction.receiver': '수신자',
  'account.transaction.hash': '해시',
  'account.transaction.status': '상태',
  'account.transaction.created_time': '생성 시간',
  'account.transaction.action': '액션',
  'account.transaction.from': '로부터',
  'account.transaction.to': '까지',
  'email.new_title_page': '이메일 마케팅 생성',
  'email.new': '이메일 마케팅 생성',
  'email.title': '제목',
  'email.content': '내용',
  'email.submit_data': '정말로 하시겠습니까?',
  'email.delete_data': '삭제하시겠습니까?',
  'email.send_all': '모든 사용자에게 전송합니다',
  'email.list_user': '목록에 있는 사용자에게 전송합니다',
  'withdraw_fee_setting.title': '출금 수수료 설정',
  'withdraw_fee_setting.daily_limit': '데일리 한도',
  'withdraw_setting.change_confirm': '출금 수수료를 변경하시겠습니까?',
  'withdraw_fee_setting.disable_confirm': '출금을 비활성화하시겠습니까?',
  'withdraw_fee_setting.disable_all_confirm': '모든 출금을 비활성화하시겠습니까?',
  'withdraw_fee_setting.enable_confirm': '출금을 활성화하시겠습니까?',
  'withdraw_fee_setting.enable_all_confirm': '출금을 활성화하시겠습니까?',
  'withdraw_fee_setting.disable_deposit_confirm': '입금을 비활성화하시겠습니까?',
  'withdraw_fee_setting.disable_all_deposit_confirm': '모든 입금을 비활성화하시겠습니까?',
  'withdraw_fee_setting.enable_deposit_confirm': '입금을 활성화하시겠습니까?',
  'withdraw_fee_setting.enable_all_deposit_confirm': '모든 입금을 활성화하시겠습니까?',
  'withdraw_fee_setting.update_success_msg': '성공적으로 업데이트되었습니다!',
  'withdraw_fee_setting.disable_success_msg': '비활성화 성공',
  'withdraw_fee_setting.disable_all_success_msg': '모든 비활성화 성공',
  'withdraw_fee_setting.enable_success_msg': '활성화 성공',
  'withdraw_fee_setting.enable_all_success_msg': '모든 활성화 성공',
  'withdraw_fee_setting.withdraw_setting': '거래 설정',
  'site_setting.android_app_link': 'Android 앱 링크',
  'site_setting.site_email': '이메일',
  'site_setting.app_name': '앱 이름',
  'site_setting.short_name': '짧은 이름',
  'site_setting.copyright': '저작권',
  'site_setting.facebook_icon': '페이스북 아이콘',
  'site_setting.facebook_link': '페이스북 링크',
  'site_setting.ios_app_link': 'iOS 앱 링크',
  'site_setting.language': '언어',
  'site_setting.site_phone_number': '전화번호',
  'site_setting.site_setting': '사이트 설정',
  'site_setting.twitter_icon': '트위터 아이콘',
  'site_setting.twitter_link': '트위터 링크',
  'site_setting.update_success_msg': '성공적으로 업데이트되었습니다!',
  'site_setting.youtube_icon': '유튜브 아이콘',
  'site_setting.youtube_link': '유튜브 링크',
  'site_setting.name': '텍스트',
  'site_setting.icon': '파일',
  'site_setting.icon_hover': '텍스트',
  'site_setting.link': '텍스트',
  'site_setting.general': '일반',
  'site_setting.mobile_application': '모바일 애플리케이션',
  'site_setting.name_table': '이름',
  'site_setting.icon_table': '아이콘',
  'site_setting.icon_hover_table': '아이콘 호버',
  'site_setting.type_table': '유형',
  'site_setting.link_table': '링크',
  'site_setting.add_row_table': '+ 추가하기',
  'site_setting.enable': '활성',
  'site_setting.disable': '비활성',
  'site_setting.hot_search_setting': 'Hot Search 설정',
  'site_setting.available_pairs': '사용 가능한 거래쌍',
  'site_setting.selected_pairs': '선택된 거래쌍',
  'site_setting.update': '업데이트',

  'site_setting.status_table': '상태',
  'site_setting.social_network.title': '소셜 네트워크',
  'site_setting.social_network.confirm_remove': '이 소셜 네트워크를 삭제하시겠어요?',
  'site_setting.social_network.create_success': '소셜 네트워크가 성공적으로 추가되었습니다!',
  'site_setting.social_network.update_success': '소셜 네트워크가 성공적으로 업데이트되었습니다!',
  'site_setting.social_network.remove_success': '소셜 네트워크가 성공적으로 제거되었습니다!',
  'site_setting.social_network.errors.required.name': '이름 필드는 필수입니다.',
  'site_setting.social_network.errors.required.icon_class': '아이콘 클래스 필드는 필수입니다.',
  'site_setting.social_network.errors.required.type': '유형 필드는 필수입니다.',
  'trading_fee_setting.fee_maker': '메이커 수수료',
  'trading_fee_setting.fee_taker': '테이커 수수료',
  'trading_fee_setting.mgc_taker': 'MGC 금액',
  'trading_fee_setting.id': '아이디',
  'trading_fee_setting.trading_fee_setting': '수수료',
  'trading_fee_setting.trading_feeSetting': '거래 수수료 설정',
  'trading_fee_setting.trading_withdrawFeeSetting': '출금 수수료 설정',
  'trading_fee_setting.update_confirm': '거래 수수료를 변경하시겠습니까?',
  'trading_fee_setting.update_success_msg': '성공적으로 업데이트되었습니다!',
  'button.done': '완료',
  'button.reset': '초기화',
  'user_setting.coin': '코인',
  'user_setting.total_users': '총 사용자:',
  'user_setting.blockchain_address': '블록체인 주소',
  'user_setting.balance': '잔액',
  'user_setting.available_balance': '사용 가능 잔액',
  'user_setting.confirm_off_otp': 'OTP를 비활성화하시겠습니까?',
  'user_setting.confirm_on_otp': 'OTP를 활성화하시겠습니까?',
  'user_setting.update_otp_msg': 'OTP 설정이 성공적으로 업데이트되었습니다!',
  'user_setting.confirm_disable_account': '계정을 비활성화하시겠습니까?',
  'user_setting.disable_account_success_msg': '계정이 성공적으로 비활성화되었습니다.',
  'user_setting.confirm_enable_account': '계정을 활성화하시겠습니까?',
  'user_setting.enable_account_success_msg': '계정이 성공적으로 활성화되었습니다.',
  'not_found.title': '페이지를 찾을 수 없습니다.',
  'not_found.text1': '찾고 계신 페이지를 찾을 수 없습니다.',
  'not_found.text2': '이전 페이지로 돌아가거나, 홈페이지를 방문하거나, 고객 지원팀에 문의하세요.',
  'not_found.landing': '랜딩',
  'login.admin': '관리자 로그인',
  'login.email': '이메일 주소',
  'login.email2': '이메일',
  'user.referrer': '추천인',
  'user.otp': 'OTP',
  'email.errors.required': '모든 사용자 또는 사용자 목록을 보내야 합니다.',
  'email.email_title': '이것은 제목입니다.',
  'email.email_content': '이것은 내용입니다.',
  'email.fail': '실패',
  'exchange_balance.available_balance': '사용 가능 잔액',
  'exchange_balance.exchange_balance': '거래소 잔액',
  'exchange_fee.level': '등급',
  'withdraw_setting.disable_all': '모두 비활성화',
  'withdraw_setting.enable_all': '모두 활성화',
  'withdraw_setting.enable': '활성화',
  'withdraw_setting.disable': '비활성화',
  'withdraw_fee_setting.id': '아이디',
  'withdraw_fee_setting.currency': '통화',
  'withdraw_fee_setting.limit': '한도',
  'withdraw_fee_setting.fee': '수수료',
  'withdraw_fee_setting.is_visible': '표시 여부',
  'withdraw_fee_setting.minium_withdrawal': '최소 출금액',














  'dashboard.total_account': '총 계정',
  'dashboard.total_verified': '총 인증된 계정',
  'dashboard.total_secured': '총 보안 계정',
  'dashboard.total_deposited': '총 입금된 계정',
  'dashboard.total_withdrawal': '총 출금',
  'dashboard.total_deposit': '총 입금',
  'dashboard.total_withdrawal_usd': '총 출금 금액 (USD)',
  'dashboard.total_deposited_usd': '총 입금 금액 (USD)',
  'dashboard.total_new_account': '총 신규 계정',
  'entry_manual_payment.team_name': '팀 이름',
  'entry_manual_payment.team_id': '팀 ID',
  'entry_manual_payment.leader': '리더',
  'entry_manual_payment.number_of_me mber': '팀원 수',
  'entry_manual_payment.total_volume': '총 거래량',
  'entry_manual_payment.total_roe': '총 수익률',
  'referrer.error.field_required': '{field_name} 필드는 필수입니다.',
  'referral_setting.refund_rate': '환불 비율',
  'referral_setting.refund_rate_too_big': '백분율 값은 100 이하이어야 합니다.',
  'referral_setting.next_program_conditional': '다음 프로그램 조건부',
  'referral_setting.require_total_percent': '모든 레벨의 총 비율은 100%를 초과하면 안 됩니다.',
  'referral_setting.percent_at_lv_1': '1단계 추천 비율',
  'referral_setting.percent_at_lv_2': '2단계 추천 비율',
  'referral_setting.percent_at_lv_3': '3단계 추천 비율',
  'referral_setting.percent_at_lv_4': '4단계 추천 비율',
  'referral_setting.percent_at_lv_5': '5단계 추천 비율',
  'notifications.confirmation_reset_password.receiving_text': '계정에 대한 비밀번호 재설정 요청을 받았기 때문에 이 메시지를 수신하셨습니다.',
  'notifications.confirmation_reset_password.please_click': '비밀번호를 재설정하려면 아래 링크를 클릭하세요.',
  'notifications.confirmation_reset_password.valid_24h': '위 링크는 24시간 동안 유효합니다.',
  'notifications.confirmation_reset_password.please_confirm': '이 메시지를 받은 후 24시간 이내에 비밀번호 변경을 완료해 주세요.',
  'notifications.deposit_withdraw_usd_alerts.hello': '안녕하세요',
  'account.email_desc': '비활성화하면 로그인, 출금, 입금 알림을 받을 수 없습니다.',
  'account.email_disable_confirm': '알림 수신을 비활성화하시겠습니까?',
  'account.identity.errors.fullName.required': '전체 이름 필드는 필수입니다.',
  'account.identity.errors.passportID.required': '여권/ID 번호 필드는 필수입니다.',
  'account.identity.errors.id_front.required': '신분증 뒷면/여권 데이터 페이지 필드는 필수입니다.',
  'account.identity.errors.id_back.required': '신분증 뒷면/여권 데이터 페이지 필드는 필수입니다.',
  'account.identity.errors.id_selfie.required': '사진 ID와 메모가 포함된 셀카 필드는 필수입니다.',
  'account.line': '라인',
  'account.line.desc': '로그인, 출금, 입금 알림을 받으려면 계정을 활성화하세요.',
  'admin.change_password-success': '관리자 비밀번호가 변경되었습니다.',
  'admin.login_again': '다시 로그인하세요!',
  'aml.setting.titlecheckbox': '웹에 표시',
  'approval_pending': '승인 대기 중',
  'approval_pending.amount': '금액',
  'approval_pending.approval_request': '승인 요청',
  'approval_pending.created_date': '생성일',
  'approval_pending.currency': '통화',
  'approval_pending.detail': '상세 정보',
  'approval_pending.title': '승인 대기 중',
  'approval_pending.transaction_id': '거래 ID',
  'approval_pending.user_name': '이메일',
  'approval_pending.view': '보기',
  'approval_request': '승인 요청',
  'approved_at': '승인일',
  'approved_by': '승인자',
  'approved_order.amount': '금액',
  'approved_order.btn_detail': '상세 정보',
  'approved_order.btn_search': '검색',
  'approved_order.btn_view': '보기',
  'approved_order.created_date': '생성일',
  'approved_order.currency': '통화',
  'approved_order.heading_title': '승인된 거래',
  'approved_order.no': '번호',
  'approved_order.transaction_id': '거래 ID',
  'approved_order.user_name': '이메일',
  'approved_order.withdraw_approval': '출금 승인자',
  'approved_transaction': '승인된 거래',
  'approved_transaction_page.approved_date': '승인일',
  'approved_transaction_page.administrator_information': '관리자 정보',
  'approved_transaction_page.withdrawal_currency': '출금 통화',
  'approved_transaction_page.withdrawal_address': '출금 주소',
  'approved_transaction_page.country': '국가',
  'approved_transaction_page.created_date': '생성일',
  'approved_transaction_page.email': '이메일',
  'approved_transaction_page.header_title': '[상세 정보] 거래: {transaction_id}',
  'approved_transaction_page.quantity': '금액',
  'approved_transaction_page.transaction_id': '거래 ID',
  'approved_transaction_page.transaction_detail': '거래 상세 정보',
  'approved_transaction_page.customer_information': '고객 정보',
  'approved_transaction_page.transaction_hash': '거래 해시',
  'approved_transaction_page.remark': '비고',
  'approved_transactions': '승인된 거래',
  'bank_setting.bank_setting': '은행설정',
  'bank_setting.bank_name': '은행',
  'bank_setting.bank_branch': '은행 지점',
  'bank_setting.account_name': '예금주',
  'bank_setting.account_no': '계좌번호',
  'bank_setting.edit': '수정',
  'bank_setting.return': '반환',
  'bank_setting.save': '저장',
  'bank_setting.error.lose_bank_name': '은행 이름 필드는 필수 항목입니다.',
  'bank_setting.error.lose_bank_branch': '은행 지점 필드는 필수 항목입니다.',
  'bank_setting.error.lose_account_name': '계좌명 필드는 필수 항목입니다.',
  'bank_setting.error.lose_account_no': '계좌번호 필드는 필수 항목입니다.',
  'bank_setting.update_success_msg': '성공적으로 업데이트되었습니다!',
  'base_deposit_withdraw_title_time': '시간',
  'base_deposit_withdraw_title_email': '이메일',
  'base_deposit_withdraw_title_transactionId': '거래 ID',
  'base_deposit_withdraw_title_currency': '통화',
  'base_deposit_withdraw_title_amount': '금액/수수료',
  'base_deposit_withdraw_title_status': '상태',
  'base_deposit_withdraw_title_collect': '수집',
  'change_password_form.title_confirm': '비밀번호 확인',
  'change_password_form.error_password': '비밀번호가 올바르지 않습니다!',
  'common.all': '전체',
  'common.action.send': '보내기',
  'common.action.refund': '확인',
  'common.action.send_email': '이메일 보내기',
  'common.action.return': '반환',
  'common.action.save': '저장',
  'common.action.edit': '편집',
  'common.action.enable': '활성화',
  'common.action.disable': '비활성화',
  'common.action.waiting': '대기 중',
  'common.action.close': '닫기',
  'common.announcements': '공지사항',
  'common.begin.trading': '지금 거래를 시작하세요!',
  'common.create_account_amanpuri': '거래소에서 계정을 생성하세요.',
  'common.datatable.no_data': '데이터가 없습니다.',
  'common.datatable.no_data2': '결과를 찾을 수 없습니다.',
  'common.exchange.now': '지금 교환하세요.',
  'common.error.required_airdrop': '{field} 필드는 필수 항목입니다.',
  'common.margin_exchange.desc': '최대 100배 레버리지. 만기 없는 거래. 업계 최고 수준의 보안. 비트코인의 가장 진보된 거래 플랫폼에 오신 것을 환영합니다.',
  'common.margin_exchange.title': '만기가 없는 고레버리지 계약!',
  'common.market_info': '시장 정보',
  'common.mobile_app': '모바일 앱',
  'common.mobile_app.desc': '스마트폰 앱. 어디서든 거래를 추적하고 관리하세요!',
  'common.mobile_app.title': '이동 중에도 거래를 만들고 추적하세요!',
  'common.chose_select': '선택',
  'common.order_status.stopping': '중지',
  'common.order_status.open': '열림',
  'common.order_status.untriggered': '미발동',
  'common.order_status.executed': '실행됨',
  'common.order_status.executing': '실행 중',
  'common.order_status.removed': '제거됨',
  'common.order_status.expired': '만료됨',
  'common.order_status.new': '신규',
  'common.order_type.limit': '지정가',
  'common.order_type.market': '시장가',
  'common.order_type.stop_limit': '지정가 정지',
  'common.order_type.stop_market': '시장가 정지',
  'common.order_type.take_profit_market': '시장가 이익 실현',
  'common.order_type.take_profit_limit': '지정가 이익 실현',
  'common.order_type.oco': 'OCO',
  'common.order_type.ifd': 'IFD',
  'common.order_type.trailing_stop': '추적 정지',
  'common.order_type.liquidation': '청산',
  'common.order_type.post_only': '게시 전용',
  'common.placeholders.confirm_password': '비밀번호 확인',
  'common.sales_point': '판매 포인트',
  'common.sales_point.desc': '몇 분 안에 한 지갑에서 다른 지갑으로 전송하세요. 130개 이상의 암호화폐와 24/7 실시간 지원을 제공하는 사용하기 쉬운 서비스입니다. 정말 간단합니다.',
  'common.sales_point.title': '암호화폐를 교환하는 가장 빠른 방법',
  'common.slogan_create_account': '안전하고 신뢰할 수 있는 비트코인 거래소',
  'common.spot_exchange': '현물 거래',
  'common.future_exchange': '선물 거래',
  'common.margin_exchange': '마진 거래소',
  'common.spot_exchange.desc': '몇 분 안에 한 지갑에서 다른 지갑으로 전송하세요. 130개 이상의 암호화폐와 24/7 실시간 지원을 제공하는 사용하기 쉬운 서비스입니다. 정말 간단합니다.',
  'common.spot_exchange.title': '가장 진보된 암호화폐 거래소입니다.',
  'common.trade_type.all': '전체',
  'common.date.warning2': '날짜 범위가 잘못되었습니다!',
  'common.alert.create.success': '성공적으로 생성되었습니다!',
  'common.alert.update.success': '성공적으로 업데이트되었습니다!',
  'common.alert.delete.success': '성공적으로 삭제되었습니다!',
  'common.Internet.connection.fail': '인터넷 연결 실패!',
  'contact.message.sendEmailSuccess': '이메일이 성공적으로 전송되었습니다!',
  'currency.fullname.variable': 'currency.{currency_name}.fullname',
  'currency.amal.fullname': '거래소',
  'currency.amal.shortname': 'AMAL',
  'currency.ltc.fullname': '라이트코인',
  'currency.ltc.shortname': 'LTC',
  'currency.usdt.fullname': 'USDT',
  'currency.usdt.shortname': 'USDT',
  'denial_page.amount': '금액',
  'denial_page.btn_detail': '상세 정보',
  'denial_page.btn_search': '검색',
  'denial_page.created_date': '생성일',
  'denial_page.currency': '통화',
  'denial_page.heading_title': '거부된 거래',
  'denial_page.no': '번호',
  'denial_page.transaction_id': '거래 ID',
  'denial_page.user_name': '이메일',
  'bank': '{bank}',
  'account_number': '{accountNumber}',
  'account_holder': '{accountHolder}',
  'emails.unlock_airdrop_fail.text1': '죄송합니다.',
  'emails.unlock_airdrop_fail.text2': '잠금 해제 실패로 인해 이를 알립니다.',
  'emails.unlock_airdrop_fail.text3': '배당 잔액에서 AMAL을 취소하고 다음 기간에 다시 시도하세요.',
  'emails.unlock_airdrop_fail.title': '【{APP_NAME}】배당 잠금 해제 실패',
  'emails.unlock_airdrop_fail_alert.title': '배당 잠금 해제 실패',
  'emails.unlock_airdrop_fail_alert.text1': '시스템이 사용자의 배당 지갑에서 AMAL 잠금 해제를 실패했습니다.',
  'emails.unlock_airdrop_fail_alert.text2': '잠금 해제 세부 정보',
  'emails.unlock_airdrop_fail_alert.email': '이메일',
  'emails.unlock_airdrop_fail_alert.amount': '금액',
  'emails.new_login.new_ip_title': '【Exchange】경고: 새 IP에서 로그인 성공',
  'emails.footer.the_best_cryptocurrency': '최고의 암호화폐 거래',
  'emails.footer.service_center': '고객 센터',
  'emails.registed.confirmation_email_subject': '【Exchange】{APP_NAME}에서 계정을 활성화하세요',
  'emails.registed.click_on_link': '등록이 완료되지 않았습니다. 아래 링크를 클릭하여 인증을 완료하세요. 이메일을 인증함으로써 더 편리하고 안전한 암호화폐 거래 서비스를 제공하기 위해 노력하고 있습니다.',
  'emails.registed.valid_24h': '위 링크는 24시간 동안 유효합니다.',
  'emails.registed.please_complete': '이 이메일을 받은 후 24시간 이내에 계정 인증을 완료하세요.',
  'emails.registed.thank_you': '감사합니다!',
  'emails.confirmation_reset_password.subject': '【Exchange】비밀번호 재설정',
  'emails.confirmation_reset_password.dear_account': '안녕하세요.',
  'emails.confirmation_reset_password.receiving_text': '계정에 대한 비밀번호 재설정 요청을 받았기 때문에 이 이메일을 수신하셨습니다.',
  'emails.confirmation_reset_password.please_click': '비밀번호를 재설정하려면 아래 링크를 클릭하세요.',
  'emails.confirmation_reset_password.valid_24h': '위 링크는 24시간 동안 유효합니다.',
  'emails.confirmation_reset_password.please_confirm': '이 이메일을 받은 후 24시간 이내에 비밀번호 변경을 완료하세요.',
  'emails.confirmation_reset_password.thank_you': '감사합니다!',
  'emails.login_new_device.subject': '【Exchange】경고: 새 기기 승인',
  'emails.login_new_device.dear_account': '안녕하세요.',
  'emails.login_new_device.attemped_access': '최근에 새 기기에서 거래소 계정에 로그인하려고 시도하셨습니다. 보안 조치로 인해 거래소 계정에 접근하기 전에 추가 확인이 필요합니다.',
  'emails.login_new_device.email': '이메일',
  'emails.login_new_device.device': '기기',
  'emails.login_new_device.time': '시간',
  'emails.login_new_device.ip': 'IP 주소',
  'emails.login_new_device.legitimate_activity': '이것이 합법적인 활동이라면, 아래에서 새 기기를 승인할 수 있습니다.',
  'emails.login_new_device.team': '팀',
  'emails.login_new_device.thank_you': '감사합니다!',
  'emails.confirm_kyc.dear_name': '친애하는 {성/이름} 님',
  'emails.confirm_kyc.subject': '【Exchange】KYC 인증: 상태',
  'emails.confirm_kyc.title_header': '【Exchange】: KYC 상태',
  'emails.confirm_kyc.line_1': '거래소 지원입니다.',
  'emails.confirm_kyc.line_2': '기다려주셔서 감사합니다.',
  'emails.confirm_kyc.line_3': '신원 확인 인증이 완료되었으므로, 여기에서 사용자 페이지에 로그인하여 심사 결과를 확인해 주세요.',
  'emails.confirm_kyc.line_4': '내 페이지 상단에 신원 확인 서류를 제출해야 한다는 메시지가 표시되지 않으면, 신원 확인이 성공적으로 완료된 것입니다.',
  'emails.confirm_kyc.line_5': '내 페이지 상단에 메시지가 표시되면 부적절한 제출 또는 이미지 오류로 인해 승인되지 않은 것이므로 신원 확인 서류를 다시 확인하고 다시 제출해 주세요.',
  'emails.confirm_kyc.line_6': '제출된 서류가 부적합한 이유를 알려드릴 수는 없지만, 주로 아래와 같은 이유가 있습니다. 사진이 다음 항목에 해당하지 않는지 확인해 주세요.',
  'emails.confirm_kyc.line_7': '개인 신분 증명서가 명시되지 않음',
  'emails.confirm_kyc.line_8': '신분 증명서에 있는 얼굴과 셀카 사진이 다름',
  'emails.confirm_kyc.line_9': '제출된 문서에 신분증, 거래소 이름, 날짜, 본인이 함께 표시되지 않음',
  'emails.confirm_kyc.line_10': '정면을 향하지 않음',
  'emails.confirm_kyc.line_11': '셀카 사진에 얼굴 전체가 나오지 않음 (머리 전체가 나오지 않음)',
  'emails.confirm_kyc.line_12': '이미지가 어둡고 얼굴이 선명하지 않음',
  'emails.confirm_kyc.line_13': '이미지 업로드 실패',
  'emails.confirm_kyc.line_14': '번거롭게 해드려 죄송합니다. 협조해 주셔서 감사합니다.',
  'emails.contact.subject': '【Exchange】문의하기',
  'emails.contact.dear_account': '안녕하세요.',
  'emails.contact.line_1': '문의해 주셔서 감사합니다.',
  'emails.contact.line_2': '담당자가 다시 연락드리겠습니다.',
  'emails.received_verify_document.dear_account': '안녕하세요.',
  'emails.received_verify_document.subject': '【Exchange】신원 인증 서류를 접수했습니다.',
  'emails.received_verify_document.line_1': '거래소를 지속적으로 이용해 주셔서 감사합니다.',
  'emails.received_verify_document.line_2': '신원 확인 서류를 접수했습니다.',
  'emails.received_verify_document.line_3': '제출된 서류 순서대로 신원 확인 서류를 검토할 예정입니다.',
  'emails.received_verify_document.line_4': '확인될 때까지 기다려 주세요.',
  'emails.received_verify_document.line_5': '신청 상태에 따라 추가 시간이 필요할 수 있습니다. 양해해 주셔서 감사합니다.',
  'emails.received_verify_document.line_6': '거래소에 대한 지속적인 지원을 부탁드립니다.',
  'emails.received_verify_document.line_7': '이 이메일은 발신 전용 이메일 주소에서 발송되었으며 회신할 수 없습니다.',
  'emails.withdraw_verify.subject': '【Exchange】출금 확인',
  'emails.withdraw_verify.line_1': '안녕하세요.',
  'emails.withdraw_verify.line_2': '다음과 같은 출금 요청이 있습니다:',
  'emails.withdraw_verify.line_3': '통화',
  'emails.withdraw_verify.line_4': '수량',
  'emails.withdraw_verify.line_5': '출금 주소',
  'emails.withdraw_verify.line_6': '생성일',
  'emails.withdraw_verify.line_7': '아래 링크를 클릭하여 이 거래를 확인해 주세요.',
  'emails.withdraw_verify.line_8': '감사합니다!',
  'emails.withdraw_alerts.subject': '【Exchange】출금 확인',
  'emails.withdraw_alerts.line_1': '안녕하세요.',
  'emails.withdraw_alerts.line_2': '다음 출금 요청이 승인되었습니다.',
  'emails.withdraw_alerts.line_3': '통화',
  'emails.withdraw_alerts.line_4': '수량',
  'emails.withdraw_alerts.line_5': '출금 주소',
  'emails.withdraw_alerts.line_6': '생성일',
  'emails.withdraw_alerts.line_7': '여기에서 출금 처리로 이동하겠습니다.',
  'emails.withdraw_alerts.line_8': '거래소를 지속적으로 이용해 주셔서 감사합니다.',
  'emails.withdraw_alerts.line_9': '이 이메일은 발신 전용 이메일 주소에서 발송되었습니다.',
  'emails.withdraw_alerts.line_10': '따라서 회신하더라도 내용을 식별할 수 없습니다.',
  'emails.withdraw_alerts.line_11': '감사합니다!',
  'emails.withdraw_errors_alerts.subject': '【거래소】출금 거부',
  'emails.withdraw_errors_alerts.line_1': '안녕하세요.',
  'emails.withdraw_errors_alerts.line_2': '다음 출금 요청이 거부되었습니다.',
  'emails.withdraw_errors_alerts.line_3': '통화',
  'emails.withdraw_errors_alerts.line_4': '수량',
  'emails.withdraw_errors_alerts.line_5': '출금 주소',
  'emails.withdraw_errors_alerts.line_6': '생성일',
  'emails.withdraw_errors_alerts.line_7': '알 수 없는 오류로 인해 요청을 거부해야 했습니다.',
  'emails.withdraw_errors_alerts.line_8': '요청을 신중히 확인한 후 다시 제출해 주세요.',
  'emails.withdraw_errors_alerts.line_9': '감사합니다!',
  'emails.withdrawal_canceled.subject': '【거래소】출금 취소됨',
  'emails.withdrawal_canceled.dear_name': '안녕하세요 <strong>{email}</strong> 님,',
  'emails.withdrawal_canceled.line_1': '아래 출금 요청이 거래소 시스템에 의해 취소되었습니다.',
  'emails.withdrawal_canceled.line_2': '통화',
  'emails.withdrawal_canceled.line_3': '수량',
  'emails.withdrawal_canceled.line_4': '출금 주소',
  'emails.withdrawal_canceled.line_5': '생성일',
  'emails.withdrawal_canceled.line_6': '요청이 취소되는 두 가지 경우가 있습니다.',
  'emails.withdrawal_canceled.line_7': '사례 1: 관리자가 특정 이유로 요청을 취소했습니다.',
  'emails.withdrawal_canceled.line_8': '사례 2: 요청이 만료되었습니다.',
  'emails.withdrawal_canceled.line_9': '감사합니다!',
  'emails.ban_account.subject': '【거래소】계정 잠금',
  'emails.ban_account.dear_name': '안녕하세요 <strong>{email}</strong>,',
  'emails.ban_account.line_1': '계정이 거래소 관리자에 의해 잠금 처리되었습니다.',
  'emails.ban_account.line_2': '현재 로그인할 수 없습니다.',
  'emails.ban_account.line_3': '계정을 잠금 해제하려면 이 링크로 연락해 주세요:',
  'emails.deposit_withdraw_usd_alerts.hello': '안녕하세요 <strong>{email}</strong>',
  'emails.deposit_withdraw_usd_alerts.time': '시간',
  'emails.deposit_withdraw_usd_alerts.deposit.approved.title': '【거래소】입금 완료',
  'emails.deposit_withdraw_usd_alerts.deposit.approved.line_1': '입금 요청이 완료되었습니다.',
  'emails.deposit_withdraw_usd_alerts.deposit.approved.line_2': '로그인하여 잔액을 확인하십시오.',
  'emails.deposit_withdraw_usd_alerts.deposit.approved.amount': '입금 금액',
  'emails.deposit_withdraw_usd_alerts.deposit.rejected.title': '【거래소】입금 실패',
  'emails.deposit_withdraw_usd_alerts.deposit.rejected.line_1': '입금 요청이 실패했습니다.',
  'emails.deposit_withdraw_usd_alerts.deposit.rejected.line_2': '입금 규칙, 계좌 정보, 입금 코드를 확인하시고 다시 시도해 주세요.',
  'emails.deposit_withdraw_usd_alerts.deposit.rejected.amount': '입금 금액',
  'emails.deposit_withdraw_usd_alerts.withdrawal.approved.title': '【거래소】출금 완료',
  'emails.deposit_withdraw_usd_alerts.withdrawal.approved.line_1': '출금 요청이 완료되었습니다.',
  'emails.deposit_withdraw_usd_alerts.withdrawal.approved.amount': '출금 금액',
  'emails.deposit_withdraw_usd_alerts.withdrawal.approved.account_bank': '계좌 은행',
  'emails.deposit_withdraw_usd_alerts.withdrawal.approved.account_number': '계좌 번호',
  'emails.deposit_withdraw_usd_alerts.withdrawal.approved.account_holder': '계좌 소유자',
  'emails.deposit_withdraw_usd_alerts.withdrawal.rejected.title': '【거래소】출금 실패',
  'emails.deposit_withdraw_usd_alerts.withdrawal.rejected.line_1': '출금 요청이 실패했습니다.',
  'emails.deposit_withdraw_usd_alerts.withdrawal.rejected.line_2': '출금 규칙, 계좌 정보를 확인하시고 다시 시도해 주세요.',
  'emails.deposit_withdraw_usd_alerts.withdrawal.rejected.amount': '출금 금액',
  'emails.deposit_withdraw_usd_alerts.withdrawal.rejected.account_bank': '계좌 은행',
  'emails.deposit_withdraw_usd_alerts.withdrawal.rejected.account_number': '계좌 번호',
  'emails.deposit_withdraw_usd_alerts.withdrawal.rejected.account_holder': '계좌 소유자',
  'emails.change_coldWallet_setting.subject': '【거래소】콜드 월렛 설정 변경 완료',
  'emails.change_coldWallet_setting.line_1': '콜드 월렛 설정이 변경되어 이 이메일을 받았습니다.',
  'emails.change_coldWallet_setting.line_2': '변경된 설정 세부 사항:',
  'emails.change_coldWallet_setting.line_3': '{coin} 주소가 {oldAddress}에서 {newAddress}로 변경되었습니다.',
  'emails.change_coldWallet_setting.line_4': '콜드 월렛 소유자 이메일이 {oldEmail}에서 {newEmail}로 변경되었습니다.',
  'emails.register_completed.subject': '【거래소】등록 완료',
  'emails.register_completed.hello': '안녕하세요 <strong>{email}</strong>,',
  'emails.register_completed.line_1': '축하합니다, 등록이 완료되었습니다.',
  'emails.register_completed.line_2': '거래를 시작하려면 {APP_NAME}에 로그인하십시오.',
  'beta_tester.add_beta_tester': '베타 테스터 추가',
  'beta_tester.is_tester': '테스터 여부',
  'beta_tester.active_beta_tester_success': '베타 테스터 활성화 성공!',
  'beta_tester.active_beta_tester_fail': '베타 테스터 활성화 실패!',
  'beta_tester.active': '활성 상태',
  'beta_tester.inactive': '비활성 상태',
  'beta_tester.waiting': '대기 중',
  'exchange_balance_detail.title': '거래소 잔액 상세',
  'exchange_balance_detail.daily': '일별',
  'exchange_balance_detail.hour': '시간별',
  'exchange_balance_detail.weekly': '주간',
  'exchange_balance_detail.monthly': '월간',
  'exchange_balance_detail.yearly': '연간',
  'exchange_balance_detail.search_place_holder': '{0} 형식으로 검색',
  'exchange_balance_detail.data_table.title.from': '출발지',
  'exchange_balance_detail.data_table.title.to': '도착지',
  'exchange_balance_detail.data_table.search_message.required': '{0}로 검색하는 것은 필수입니다!',
  'exchange_balance_detail.data_table.search_message.isNotValid': '{0}로 검색한 내용이 유효하지 않습니다!',
  'exchange.table.hot_wallet_address': '핫 월렛 주소',
  'exchange.table.hot_wallet_balance': '핫 월렛 잔액',
  'exchange.table.cold_wallet_balance': '콜드 월렛 잔액',
  'exchange.table.hot_wallet_collected_fee': '핫 월렛 수수료 수집',
  'exchange.table.cold_wallet_collected_fee': '콜드 월렛 수수료 수집',
  'exchange.table.total_wallet_balance': '전체 월렛 잔액',
  'exchange.table.total_user_balance': '전체 사용자 잔액',
  'exchange.table.profit': '거래소 수익',
  'funds.balances.amount_is_positive': '금액이 양수입니다',
  'funds.balances.amount_can_not_greater_than_available_balance': '금액은 사용 가능한 잔액보다 클 수 없습니다.',
  'funds.balances.amount_can_not_greater_than_exchange_available_balance_without_orderbook': '사용 가능한 잔액이 부족합니다.',
  'funds.balances.approx': '대략적인 AMAL 값',
  'funds.balances.assign': '할당',
  'funds.balances.convert_small_balance_success': '잔액 변환 성공',
  'funds.balances.curency': '통화',
  'funds.balances.exchange_balance': '거래소 잔액',
  'funds.balances.convert_to_amal': 'AMAL로 변환',
  'funds.balances.convert_sm_balance': '작은 잔액을 AMAL로 변환',
  'funds.balances.from': 'From',
  'funds.balances.interval': '간격',
  'funds.balances.main_account': '주요 계좌',
  'main_balance': '주요 잔액',
  'airdrop_balance': '배당 잔액',
  'dividend_bonus_balance': '배당 보너스 잔액',
  'funds.balances.margin_balance': '마진 잔액',
  'funds.balances.mam_balance': 'MAM 잔액',
  'funds.balances.min_amount_is_invalid': '금액은 최소 0.00000001이어야 합니다.',
  'funds.balances.P_L': '손익',
  'funds.balances.revoke': '취소',
  'funds.balances.value': '값',
  'funds.balances.to': 'To',
  'funds.balances.tranfer': '이체',
  'funds.balances.transfer': '이체',
  'funds.balances.transfer_balance_fails': '잔액 이체 실패',
  'funds.balances.transfer_balance_success': '잔액 이체 성공',
  'funds.withdrawals.add_whitelist_address': '화이트리스트 주소가 없습니다. {APP_NAME} PC 웹사이트에서 주소를 관리하세요.',
  'funds.withdrawals.network': '네트워크',
  'funds.contracts': '계약',
  'funds.contract': '계약',
  'landing.banner.desc': '최대 100배 레버리지. 만료일 없는 거래. 업계 최고의 보안. 비트코인의 가장 진보된 거래 플랫폼에 오신 것을 환영합니다.',
  'landing.banner.title.span': '스마트 비트코인 -',
  'landing.banner.title.text': '가상 화폐 거래소',
  'm_salespoint.buy_history': '구매 내역',
  'instruments.create': '등록하기',
  'instruments.create_ins': '상품 만들기',
  'instruments.create_new_tier': '새 티어 만들기',
  'instruments.tier': '티어',
  'instruments.trading_tier': '거래 티어',
  'instruments.position_bracket': '포지션 브래킷',
  'instruments.previous': '이전',
  'instruments.future_coinM': '선물 상품 COIN-M',
  'instruments.future_usdM': '선물 상품 USD-M',
  'instruments.menu': '상품',
  'instruments.symbol': '심볼',
  'instruments.root_symbol': '루트 심볼',
  'instruments.state': '상태',
  'instruments.type': '유형',
  'instruments.underlying': '기초 자산 및 견적 통화',
  'instruments.contract_multiplier': '계약 배수',
  'instruments.collateral': '담보 자산',
  'instruments.tarker_fee': '메이커/테이커 수수료',
  'instruments.tarker_fee_percent': '테이커 수수료 (%)',
  'instruments.limit_buy_order_price_cap_ratio': '제한된 매수 주문 가격 상한 비율 (%)',
  'instruments.limit_buy_order_price_floor_ratio': '제한된 매수 주문 가격 하한 비율 (%)',
  'instruments.create.min_tick_size': '최소 틱 사이즈',
  'instruments.min_price': '최소 가격',
  'instruments.create.max_price': '최대 가격',
  'instruments.min_order_price': '최소 주문 금액',
  'instruments.max_order_price': '최대 주문 금액',
  'instruments.figures_for_price': '가격에 대한 최대 소수점 자릿수',
  'instruments.figures_for_size': '크기에 대한 최대 소수점 자릿수',
  'instruments.liquidateion': '청산 정산 수수료 (%)',
  'instruments.create.max_leverage': '최대 레버리지',
  'instruments.max-nation': '최대 명목 가치',
  'instruments.min-nation': '최소 명목 가치',
  'instruments.min-price-movement': '최소 가격 변동',
  'instruments.min-price-movement.tip': '최소 틱 설정과 동일해야 합니다.',
  'instruments.impact-nation': '영향 마진 명목',
  'instruments.marker_fee_percent': '마커 수수료 (%)',
  'instruments.min_tick_size': '최소 틱 사이즈',
  'instruments.price_movement': '최소 가격 변동',
  'instruments.min_max_price': '최저가/최고가',
  'instruments.limit_order_Price': '제한 주문 가격 매수 상한/\n매도 하한 비율',
  'instruments.min_max_order_amt': '최소/최대 주문 금액',
  'instruments.min_max_notional': '최저/최고 명목가치',
  'instruments.max_leverage': '최대 레버리지',
  'instruments.maintenace_margin': '유지 마진 비율',
  'instruments.maintenace_amount': '유지 금액',
  'instruments.decimal_figures': '크기에 대한 최대 소수점 자릿수',
  'instruments.contract_multipliter': '계약 배수',
  'instruments.decimal_figures_price': '가격에 대한 최대 소수점 자릿수',
  'instruments.decimal_figures_fee': '청산 정산 수수료',
  'instruments.margin_notional': '영향 마진 명목',
  'instruments.expiry': '만료일',
  'instruments.base_underlying': '기본 기초 자산',
  'menu.instruments_future': '선물 상품',
  'title.instruments_future': '선물 상품 USDⓈ-M',
  'instruments.quote_currency': '견적 통화',
  'instruments.underlying_symbol': '기초 심볼',
  'instruments.init_margin': '초기 비율',
  'instruments.maint_margin': '유지 비율',
  'instruments.maker_fee': '메이커 수수료',
  'instruments.taker_fee': '테이커 수수료',
  'instruments.settlement_fee': '결제 수수료',
  'instruments.reference_index': '기준 지수',
  'instruments.settlement_index': '결제 지수',
  'instruments.funding_base_index': '펀딩 기본 지수',
  'instruments.funding_quote_index': '펀딩 견적 지수',
  'instruments.funding_premium_index': '펀딩 프리미엄 지수',
  'instruments.tick_size': '틱 사이즈',
  'instruments.max_price': '최대 가격',
  'instruments.max_order_qty': '최대 주문 수량',
  'instruments.multiplier': '배수',
  'instruments.risk_limit': '리스크 한도',
  'instruments.risk_step': '리스크 단계',
  'instruments.create_instrument': '상품 생성',
  'instruments.open': '열기',
  'instruments.close': '닫기',
  'instruments.pending': '대기 중',
  'instruments.cancel': '취소',
  'instruments.submit': '제출',
  'instruments.validate.incorrectExpiry': '만료일은 오늘보다 커야 합니다.',
  'instruments.validate.invalidExpiry': '만료일이 유효하지 않습니다.',
  'instruments.validate.required': '{field} 필드는 필수입니다.',
  'instruments.create.success': '생성 성공',
  'instruments.dropdown.placeholder': '-- 선택해 주세요 --',
  'instruments.value.must.lower': '유지 비율 값은 초기 비율 값보다 작아야 합니다.',
  'instruments.value.must.difference': '{field1}는 {field2}보다 더 어렵습니다.',
  'instruments.remove_success': '삭제 성공',
  'instruments.remove_failed': '상품 제거 실패',
  'instruments.types.call_option': '콜 옵션',
  'instruments.types.future': '선물',
  'instruments.types.perpetual': '영구 계약',
  'instruments.types.put_option': '풋 옵션',
  'menu.margin_exchange': '마진 거래',
  'reward_center.title_page': '보상 관리',
  'reward_center.title_page_create': '새 보상 추가',
  'reward_center.title_page_edit': '보상 수정',
  'reward_center.create': '새 보상 추가',
  'reward.remove_reward_center': '이 보상을 삭제하시겠습니까?',
  'reward_center.no': '아이디',
  'reward_center.voucher.name': '바우처 이름',
  'reward_center.beneficiary': '적용대상',
  'reward_center.currency': '통화',
  'reward_center.trading_volume': '거래량',
  'reward_center.reward': '보상',
  'reward_center.expired_time': '만료 시간 (일)',
  'reward_center.label.currency': '통화',
  'reward_center.unique_voucher': '바우처 이름이 이미 존재합니다.',
  'voucher.no.create': '새 바우처를 추가하기 전에 현재 바우처를 삭제하세요.',
  'cms.announcement.popup_delete_confirm': '이 공지를 삭제하시겠습니까?',
  'resource.networks.id': '아이디',
  'resource.networks.info': '정보',
  'resource.networks.active_status': '활성 상태',
  'resource.networks.allow_deposit': '입금 허용',
  'resource.networks.allow_withdraw': '출금 허용',
  'resource.networks.updated_time': '업데이트 시간',
  'resource.networks.action': '작업',
  'resource.networks.search_by': '통화로 검색',
  'resource.networks.new_network': '새 네트워크',
  'resource.networks.name': '이름',
  'resource.networks.symbol': '심볼',
  'resource.networks.code': '코드',
  'resource.networks.chain_id': '체인 아이디',
  'resource.networks.currency': '통화',
  'resource.networks.deposit_confirmation': '입금 확인',
  'resource.networks.explorer_url': '탐색기 URL',
  'resource.networks.enter_name': '이름 입력',
  'resource.networks.enter_symbol': '심볼 입력',
  'resource.networks.enter_code': '코드 입력',
  'resource.networks.enter_chain_id': '체인 아이디 입력',
  'resource.networks.enter_currency': '통화 입력',
  'resource.networks.enter_deposit_confirmation': '입금 확인 입력',
  'resource.networks.enter_explorer_url': '탐색기 URL 입력',
  'resource.networks.btn_cancel': '취소',
  'resource.networks.btn_create': '생성',
  'resource.networks.btn_update': '업데이트',
  'resource.networks.btn_new_coin': '새로운 코인',
  'resource.networks.toast_create_success': '생성 성공',
  'resource.networks.toast_create_failed': '생성 실패',
  'resource.networks.toast_update_success': '업데이트 성공',
  'resource.networks.toast_update_fail': '업데이트 실패',
  'resource.networks.title_edit': '네트워크: 편집',
  'resource.networks.title_create': '네트워크: 생성',
  'resource.networks.popup_update_confirm': '이 네트워크를 업데이트 하시겠습니까?',
  'resource.networks.popup_create_confirm': '이 네트워크를 생성 하시겠습니까?',
  'resource.networks.error_name_field': '이름 필드는 필수입니다.',
  'resource.networks.error_symbol_field': '심볼 필드는 필수입니다.',
  'resource.networks.error_code_field': '코드 필드는 필수입니다.',
  'resource.networks.error_chain_id_field': '체인 아이디 필드는 필수입니다.',
  'resource.networks.error_currency_field': '통화 필드는 필수입니다.',
  'resource.networks.error_deposit_confirmation_field': '입금 확인 필드는 필수입니다.',
  'resource.networks.error_explorer_url_field': '탐색기 URL 필드는 필수입니다.',
  'resource.coins.id': '아이디',
  'resource.coins.info': '정보',
  'resource.coins.work_on': '지갑 작업',
  'resource.coins.status': '상태',
  'resource.coins.updated_time': '업데이트 시간',
  'resource.coins.action': '작업',
  'resource.coins.popup_disable_content': '이 코인을 비활성화 하시겠습니까?',
  'resource.coins.popup_enable_content': '이 코인을 활성화 하시겠습니까?',
  'resource.coins.popup_update_content': '이 코인을 업데이트 하시겠습니까?',
  'resource.coins.popup_create_content': '이 코인을 생성 하시겠습니까?',
  'resource.coins.popup_delete_network_content': '이 네트워크를 삭제 하시겠습니까?',
  'resource.coins.btn_cancel': '취소',
  'resource.coins.btn_confirm': '확인',
  'resource.coins.btn_create': '생성',
  'resource.coins.toast_disable_success': '비활성화 성공',
  'resource.coins.toast_disable_fail': '비활성화 실패',
  'resource.coins.toast_enable_success': '활성화 성공',
  'resource.coins.toast_enable_fail': '활성화 실패',
  'resource.coins.toast_create_success': '생성 성공',
  'resource.coins.toast_create_fail': '생성 실패',
  'resource.coins.toast_update_success': '업데이트 성공',
  'resource.coins.toast_update_fail': '업데이트 실패',
  'resource.coins.toast_delete_success': '삭제 성공',
  'resource.coins.toast_delete_fail': '삭제 실패',
  'resource.coins.title_edit': '코인: 편집',
  'resource.coins.title_create': '코인: 생성',
  'resource.coins.name': '이름',
  'resource.coins.symbol': '심볼',
  'resource.coins.exchanges': '거래소',
  'resource.coins.price': '가격',
  'resource.coins.precision': '정밀도',
  'resource.coins.wallets': '지갑',
  'resource.coins.fixed_price': '고정 가격',
  'resource.coins.networks_setting': '네트워크 설정',
  'resource.coins.allow_withdraw': '출금 허용',
  'resource.coins.allow_deposit': '입금 허용',
  'resource.coins.enable': '활성화',
  'resource.coins.upload_coin_icon': '코인 아이콘 업로드',
  'resource.coins.contract_address': '계약 주소',
  'resource.coins.network': '네트워크',
  'resource.coins.token_explorer': '토큰 탐색기 URL',
  'resource.coins.enter_token_explorer': '토큰 탐색기 URL 입력',
  'resource.coins.explorer_url': '탐색기 URL',
  'resource.coins.withdraw_fee': '출금 수수료',
  'resource.coins.min_deposit': '최소 입금',
  'resource.coins.min_withdraw': '최소 출금',
  'resource.coins.add_item': '항목 추가',
  'resource.coins.enter_symbol': '심볼 입력',
  'resource.coins.enter_contract_address': '계약 주소 입력',
  'resource.coins.enter_network': '네트워크 입력',
  'resource.coins.enter_explorer_url': '탐색기 URL 입력',
  'resource.coins.enter_name': '이름 입력',
  'resource.coins.enter_precision': '정밀도 입력',
  'resource.coins.error_name_field': '이름 필드는 필수입니다.',
  'resource.coins.error_address_contract_field_invalid': '계약 주소 필드가 유효하지 않습니다.',
  'resource.coins.error_symbol_field': '심볼 필드는 필수입니다.',
  'resource.coins.error_exchanges_field': '거래소 필드는 필수입니다.',
  'resource.coins.error_price_field': '가격 필드는 필수입니다.',
  'resource.coins.error_precision_field': '정밀도 필드는 필수입니다.',
  'resource.coins.error_wallets_field': '지갑 필드는 필수입니다.',
  'resource.coins.error_address_contract_field': '계약 주소 필드는 필수입니다.',
  'resource.coins.error_network_field': '네트워크 필드는 필수입니다.',
  'resource.coins.error_token_explorer_field': '토큰 탐색기 URL 필드는 필수입니다.',
  'resource.coins.error_explorer_url_field': '탐색기 URL 필드는 필수입니다.',
  'resource.coins.error_withdraw_fee_field': '출금 수수료 필드는 필수입니다.',
  'resource.coins.error_min_deposit_field': '최소 입금 필드는 필수입니다.',
  'resource.coins.error_min_withdraw_field': '최소 출금 필드는 필수입니다.',
  'resource.coins.error_icon_image_field': '아이콘 이미지 필드는 필수입니다.',
  'resource.wallets.btn_refresh': '새로고침',
  'resource.wallets.network': '네트워크',
  'resource.wallets.withdraw': '출금',
  'resource.wallets.move_fund': '자금 이동',
  'resource.wallets.vault': '금고',
  'resource.wallets.balance': '잔액',
  'resource.wallets.label_wallet_config': '지갑 설정',
  'bots.symbol': '심볼',
  'bots.search_by': '아이디로 검색',
  'bots.btn_search': '검색',
  'bots.refresh': '새로고침',
  'bots.btn_new_bots': '새 봇',
  'bots.btn_delete': '삭제',
  'bots.btn_cancel': '취소',
  'bots.btn_update': '업데이트',
  'bots.btn_add_item': '항목 추가',
  'bots.id': '아이디',
  'bots.type': '유형',
  'bots.api_key': 'API 키',
  'bots.status': '상태',
  'bots.updated_time': '업데이트 시간',
  'bots.action': '작업',
  'bots.toast_delete_success': '삭제 성공',
  'bots.toast_delete_fail': '삭제 실패',
  'bots.toast_create_success': '생성 성공',
  'bots.toast_create_fail': '생성 실패',
  'bots.toast_update_success': '업데이트 성공',
  'bots.toast_update_fail': '업데이트 실패',
  'bots.popup_delete_content': '이 봇을 삭제하시겠습니까?',
  'bots.popup_create_content': '이 봇을 생성하시겠습니까?',
  'bots.popup_update_content': '이 봇을 업데이트하시겠습니까?',
  'bots.title_edit': '주문서 봇: 편집',
  'bots.title_create': '주문서 봇: 생성',
  'bots.start_sleep_time': '비활성 시간 시작',
  'bots.end_sleep_time': '비활성 시간 종료',
  'bots.secret_key': '비밀 키',
  'bots.min_quote_quantity': '최소 호가 수량',
  'bots.max_quote_quantity': '최대 호가 수량',
  'bots.base_precision': '기준 정밀도',
  'bots.quote_precision': '호가 정밀도',
  'bots.asks': '매도 호가',
  'bots.bids': '매수 호가',
  'bots.quote': '쿼트',
  'bots.enter_start_sleep_time': '비활성 시간 시작 입력',
  'bots.enter_end_sleep_time': '비활성 시간 종료 입력',
  'bots.enter_secret_key': '비밀 키 입력',
  'bots.enter_min_quote_quantity': '최소 쿼트 수량 입력',
  'bots.enter_max_quote_quantity': '최대 쿼트 수량 입력',
  'bots.enter_base_precision': '기준 정밀도 입력',
  'bots.enter_quote_precision': '쿼트 정밀도 입력',
  'bots.enter_api_key': 'API 키 입력',
  'bots.select_type': '유형 선택',
  'bots.select_symbol': '심볼 선택',
  'bots.error_name_field': '이름 필드는 필수입니다.',
  'bots.error_start_sleep_time_field': '비활성 시간 시작 필드는 필수입니다.',
  'bots.error_end_sleep_time_field': '비활성 시간 종료 필드는 필수입니다.',
  'bots.error_symbol_field': '심볼 필드는 필수입니다.',
  'bots.error_api_key_field': 'API 키 필드는 필수입니다.',
  'bots.error_secret_key_field': '비밀 키 필드는 필수입니다.',
  'bots.error_min_quote_quantity_field': '최소 쿼트수량 필드는 필수입니다.',
  'bots.error_max_quote_quantity_field': '최대 쿼트 수량 필드는 필수입니다.',
  'bots.error_percent_field': '백분율 필드는 필수입니다.',
  'bots.error_quote_field': '쿼트 필드는 필수입니다.',
  'admin.account.title': '관리자 계정',
  'admin.account.title_create': '관리자 계정: 생성',
  'admin.account.title_edit': '관리자 계정: 편집',
  'admin.account.search_by': '이메일로 검색',
  'admin.account.btn_search': '검색',
  'admin.account.btn_refresh': '새로고침',
  'admin.account.btn_new_account': '새 계정',
  'admin.account.btn_cancel': '취소',
  'admin.account.btn_update': '업데이트',
  'admin.account.btn_create': '생성',
  'admin.account.btn_delete': '삭제',
  'admin.account.btn_confirm': '확인',
  'admin.account.id': '아이디',
  'admin.account.email': '이메일',
  'admin.account.2fa': '2단계 인증',
  'admin.account.created_time': '생성 시간',
  'admin.account.updated_time': '업데이트 시간',
  'admin.account.action': '작업',
  'admin.account.password': '비밀번호',
  'admin.account.role': '역할',
  'admin.account.enter_password': '비밀번호 입력',
  'admin.account.enter_email': '이메일 입력',
  'admin.account.select_role': '역할 선택',
  'admin.account.toast_delete_success': '삭제 성공',
  'admin.account.toast_delete_fail': '삭제 실패',
  'admin.account.toast_create_success': '생성 성공',
  'admin.account.toast_create_fail': '생성 실패',
  'admin.account.toast_update_success': '업데이트 성공',
  'admin.account.toast_update_fail': '업데이트 실패',
  'admin.account.popup_delete_content': '이 관리자 계정을 삭제하시겠습니까?',
  'admin.account.popup_create_content': '이 관리자 계정을 생성하시겠습니까?',
  'admin.account.popup_update_content': '이 관리자 계정을 업데이트하시겠습니까?',
  'admin.account.error_password_field': '비밀번호 필드는 필수입니다.',
  'admin.account.error_email_field': '이메일 필드는 필수입니다.',
  'admin.account.error_role_field': '역할 필드는 필수입니다.',
  'menu.sales_point': '판매 포인트',
  'menu.wallets': '지갑',
  'menu.withdraw_usd_admin': 'USD 인출',
  'menu.resource': '리소스 관리',
  'menu.resource.networks': '네트워크',
  'menu.resource.coins': '코인',
  'menu.resource.wallets': '지갑',
  'menu.orderbook_bots': '주문서 봇',
  'menu.administrator': '관리자',
  'menu.administrator.admin_account': '관리자 계정',
  'menu.administrator.role': '역할',
  'margin.positions': '포지션',
  'margin.trade_history.time': '시간',
  'margin.trade_history.trade_id': '거래 아이디',
  'margin.trade_history.buy_order': '구매 주문',
  'margin.trade_history.sell_order': '판매 주문',
  'margin.trade_history.symbol': '심볼',
  'margin.trade_history.buy_fee': '구매 수수료',
  'margin.trade_history.fee': '수수료',
  'margin.trade_history.sell_fee': '판매 수수료',
  'margin.trade_history.buyer_email': '구매자 이메일/구매 수수료',
  'margin.trade_history.seller_email': '판매자 이메일/판매 수수료',
  'margin.trade_history.price': '가격',
  'margin.trade_history.quantity': '수량',
  'margin.trade_history.amount': '금액',
  'trade.exec_type_sell': '거래',
  'trade.exec_type_buy': '거래',
  'trade.exec_type_funding': '펀딩',
  'trade.funding_history': '펀딩 내역',
  'margin.trade_history.buy_fee_rate': '매수 수수료율',
  'margin.trade_history.fee_rate': '수수료율',
  'margin.trade_history.sell_fee_rate': '매도 수수료율',
  'margin.trade_history.exec_type': '실행 유형',
  'margin.trade_history.type': '유형',
  'margin.trade_history.buy_account': '매수 계정',
  'margin.trade_history.sell_account': '매도 계정',
  'margin.orders.position.creator_id': '생성자 아이디',
  'margin.orders.position.owner_id': '사용자 아이디',
  'margin.orders.position.owner_email': '소유자 이메일',
  'margin.orders.position.manager_email': '관리자 이메일',
  'margin.orders.position.manager_owner_email': '소유자 이메일 관리',
  'margin.orders.position.commission': '수수료',
  'margin.orders.position.symbol': '심볼',
  'margin.orders.position.currency': '통화',
  'margin.orders.position.underlying': '기초 자산',
  'margin.orders.position.quote_currency': '견적 통화',
  'margin.orders.position.leverage': '레버리지',
  'margin.orders.position.unrealised_pnl': '미실현 손익',
  'margin.orders.position.opening_qty': '초기 수량',
  'margin.orders.position.opening_cost': '초기 비용',
  'margin.orders.position.current_qty': '사이즈',
  'margin.orders.position.current_cost': '현재 비용',
  'margin.orders.position.realised_cost': '실현된 비용',
  'margin.orders.position.unrealised_cost': '미실현 비용',
  'margin.orders.position.risk_limit': '위험 한도',
  'margin.orders.position.risk_value': '위험 값',
  'margin.orders.position.deleverage_percentile': '디레버리지 백분위수',
  'margin.orders.position.margin': '마진',
  'margin.orders.position.init_margin': '초기 비율',
  'margin.orders.position.maint_margin': '유지 비율',
  'margin.orders.position.required_init_margin_percent': '요구 초기 비율 (%)',
  'margin.orders.position.required_maint_margin_percent': '요구 유지 비율 (%)',
  'margin.orders.position.avg_cost_price': '평균 비용 가격',
  'margin.orders.position.margin_call_price': '마진 콜 가격',
  'margin.orders.position.liquidation_price': '청산 가격',
  'margin.orders.position.bankrupt_price': '파산 가격',
  'margin.orders.position.mark_price': '기준 가격',
  'margin.orders.position.mark_value': '기준 값',
  'margin.orders.position.entry_price': '진입 가격',
  'margin.orders.position.entry_value': '진입 값',
  'margin.orders.position.value': '값',
  'margin.orders.position.email': '이메일',
  'margin.orders.position.open_order_buy_qty': '미체결 주문 구매 수량',
  'margin.orders.position.open_order_sell_qty': '미체결 주문 판매 수량',
  'margin.orders.position.open_order_buy_value': '미체결 주문 구매 금액',
  'margin.orders.position.open_order_sell_value': '미체결 주문 판매 금액',
  'margin.orders.position.multiplier': '승수',
  'margin.orders.position.is_cross': '크로스 여부',
  'margin.orders.position.created_at': '시간',
  'margin.orders.trade_history.buy_owner_email': '구매자 이메일',
  'margin.orders.trade_history.owner_email': '소유자 이메일',
  'margin.orders.trade_history.sell_owner_email': '판매자 이메일',
  'margin.orders.trade_history.buy_manager_email': '구매 관리자 이메일',
  'margin.orders.trade_history.sell_manager_email': '판매 관리자 이메일',
  'margin.orders.trade_history.manage_sell_owner_email': '판매자 이메일 관리',
  'margin.orders.trade_history.manage_buy_owner_email': '구매자 이메일 관리',
  'margin.table_trade_history.empty_msg': '거래 내역이 없습니다.',
  'margin.table_funding_history.empty_msg': '자금 내역이 없습니다.',
  'margin.table_order_history.empty_msg': '주문 내역이 없습니다.',
  'margin.table_order_active.empty_msg': '활성 주문이 없습니다.',
  'margin.table_position.empty_msg': '포지션이 없습니다.',
  'margin.limit_id': '제한_{id}',
  'margin.stop_limit_id': '중지_제한_{id}',
  'margin.liquidation': '청산',
  'margin.insurance_liquidation': '보험 청산',
  'margin.insurance_funding': '보험 자금',
  'margin.balance': '마진 잔액',
  'margin.available_balance': '사용 가능한 마진 잔액',
  'messages.disable_coin_msg': '계약이 동결되었습니다. 업그레이드를 기다리는 중입니다.',
  'modal_trading_pair_information.amount_limit': '금액 제한',
  'modal_trading_pair_information.btn_cancel': '취소',
  'modal_trading_pair_information.btn_submit': '제출',
  'modal_trading_pair_information.day': '일',
  'modal_trading_pair_information.days': '일',
  'modal_trading_pair_information.fee': '수수료',
  'modal_trading_pair_information.taker': '테이커 수수료',
  'modal_trading_pair_information.market_price': '시장 가격',
  'modal_trading_pair_information.minimum_amount': '최소 금액',
  'modal_trading_pair_information.quantity_precision': '수량 정밀도',
  'modal_trading_pair_information.minimum_total': '최소 총액',
  'modal_trading_pair_information.pair': '거래쌍',
  'modal_trading_pair_information.popup_title': '거래 쌍 정보',
  'modal_trading_pair_information.precision': '정밀도',
  'modal_trading_pair_information.time_reset': '시간 재설정',
  'modal_trading_pair_information.total_limit': '총 한도',
  'modal_trading_pair_information.trading_fee': '거래 수수료',
  'modal_trading_pair_information.trading_limit': '거래 한도',
  'modal_trading_pair_information.trading_pair': '거래 쌍',
  'modal.change_password': '비밀번호 변경',
  'common.validate.confirm_password': '비밀번호 확인',
  'order.open_order.id_account': '사용자 아이디',
  'order.open_order.id_buy': '매수 아이디',
  'order.open_order.id_order': '주문 아이디',
  'order.open_order.id_position': '포지션 아이디',
  'order.open_order.id_sell': '매도 아이디',
  'order.open_order.id_trade': '거래 아이디',
  'order.open_order.liq_price': '청산가',
  'order.open_order.margin': '마진',
  'order.open_order.position': '포지션',
  'order.open_order.quantity': '수량',
  'order.open_order.qty': '수량',
  'order.open_order.select_future': '선물 선택',
  'order.open_order.realized_pnl': '실현된 손익',
  'order.open_order.symbol': '심볼',
  'order.open_order.tiered': '단계별 MMR',
  'order.open_order.time': '시간',
  'order.open_order.time_open': '진입 시간',
  'order.open_order.time_close': '종료 시간',
  'order.open_order.trigger': '트리거',
  'order.open_order.roe': 'ROE',
  "order.open_order.avg_entry_price": "평균 진입가",
  "order.open_order.avg_exit_price": "평균 종료가격",
  'order.order_form.unrealized': '미실현 손익',
  'order.order_form.roe': '수익률 (ROE)',
  'register.sendConfirmEmail': '확인 이메일 보내기',
  'reset_passsword_complete': '비밀번호 재설정 완료',
  'reset_password.sent_mail_2': '이메일 수신 후 24시간 이내에 URL에 접속하여 비밀번호를 재설정하세요.',
  'sales_point.sales_point': '판매 포인트',
  'sales_point.title': 'AMAL 설정',
  'sales_point.price_setting': '가격 설정',
  'sales_point.total_amal_sold': '총 판매된 AMAL',
  'sales_point.currency': '통화',
  'sales_point.total_received': '총 수령',
  'sales_point.price_setting.default_price': '기본 가격',
  'sales_point.price_setting.presenter': '추천인 가격',
  'sales_point.price_setting.presentee': '추천받은 가격',
  'sales_point.price_setting.total_supply': '총 공급량',
  'sales_point.price_setting.remaining_supply': '남은 공급량',
  'sales_point.indeicates_required': '* 필수 입력 필드임을 나타냅니다',
  'sales_point.price_setting.amal_btc': 'AMAL/BTC',
  'sales_point.price_setting.amal_eth': 'AMAL/ETH',
  'sales_point.price_setting.amal_usdt': 'AMAL/USDT',
  'sales_point.price_setting.asterisk': '*',
  'sales_point.price_setting.usd': 'USD',
  'sales_point.price_setting.amal': 'AMAL',
  'sales_point.edit': '편집',
  'sales_point.submit': '제출',
  'sales_point.return': '돌아가기',
  'sales_point.clear': '삭제',
  'sales_point.update_success_msg': '성공적으로 업데이트되었습니다!',
  'sales_point.update_error_msg': '알 수 없는 오류.',
  'sales_point.error.lose_default_price': '기본 가격 필드는 필수입니다.',
  'sales_point.error.lose_referrer_commision': '추천인 커미션 필드는 필수입니다.',
  'sales_point.error.lose_referred_bonus': '추천 보너스 가격 필드는 필수입니다.',
  'sales_point.error.lose_total_supply': '총 공급량 필드는 필수입니다.',
  'sales_point.error.price_persenter': '추천인 가격 값은 0보다 커야 합니다.',
  'sales_point.error.price_persentee': '추천된 가격 값은 0보다 커야 합니다.',
  'sales_point.error.price_default_price': '기본 가격 값은 0보다 커야 합니다.',
  'sales_point.error.price_total_supply': '총 공급량 값은 0보다 커야 합니다.',
  'sales_point.bonus_level_1': '보너스 레벨 1',
  'sales_point.bonus_level_2': '보너스 레벨 2',
  'sales_point.bonus': '보너스',
  'sales_point.referrer_commision': '추천인 커미션',
  'sales_point.referred_bonus': '추천 보너스',
  'sales_point.error.bonus': '두 보너스 필드는 필수이거나 비어 있어야 합니다.',
  'sales_point.error.bonus_percent': '백분율 값은 100 이하이어야 합니다.',
  'sales_point.error.bonus_diff_0': '백분율 값은 0과 달라야 합니다.',
  'sales_point.error.take_fee_required': '테이크 수수료 (%) 필드는 필수입니다.',
  'sales_point.error.maker_fee_required': '메이커 수수료 (%) 필드는 필수입니다.',
  'sales_point.error.bonus_diff_precision_0': '정밀도 값은 0보다 커야 합니다.',
  'sales_point.error.precision_required': '정밀도 필드는 필수입니다.',
  'sales_point.error.market_than_0': '시장 가격 값은 0보다 커야 합니다.',
  'sales_point.error.market_required': '시장 가격 필드는 필수입니다.',
  'sales_point.error.minimum_amount_than_0': '최소 금액 값은 0보다 커야 합니다.',
  'sales_point.error.minimum_amount_required': '최소 금액 필드는 필수입니다.',
  'sales_point.error.quantity_precision_than_0': '수량 정밀도 값은 0보다 커야 합니다.',
  'sales_point.error.quantity_precision_required': '수량 정밀도 필드는 필수입니다.',
  'sales_point.error.minimum_total_than_0': '최소 총합 값은 0보다 커야 합니다.',
  'sales_point.error.minimum_total_required': '최소 총합 필드는 필수입니다.',
  'sales_point.error.bonus_1_2': '보너스 레벨 1은 보너스 레벨 2보다 작아야 합니다.',
  'validation.custom.image': '파일은 이미지여야 합니다.',
  'validation.custom.image.mimes': '파일은 {values} 형식이어야 합니다.',
  'validation.authen_code_required': '인증 코드 필드는 필수입니다.',
  'errors.required': '이 필드는 필수입니다.',
  'validation.unverified_email': '계정을 활성화하려면 이메일을 확인하세요.',
  'minimum_withdrawal': '레벨',
  'usd_transaction.user1': '사용자',
  'usd_transaction.account_name': '계정 이름',
  'transactions.deposit_usd.yes': '예',
  'transactions.deposit_usd.no': '아니요',
  'transactions.deposit_usd.confirm': '이 거래를 확인하시겠습니까?',
  'transactions.deposit_usd.reject': '이 거래를 거부하시겠습니까?',
  'transactions.withdrawal.send': '이 거래를 전송하시겠습니까?',
  'transactions.withdrawal.cancel': '이 거래를 취소하시겠습니까?',
  'kyc.msg_verified': '관리자에 의해 KYC가 승인되었습니다. 정보를 변경할 수 없습니다.',
  'kyc.msg_verified.successfully': '성공적으로 인증되었습니다!',
  'kyc.msg_rejected.successfully': '성공적으로 거부되었습니다!',
  'user.level': '레벨',
  'user.kyc_status': 'KYC 상태',
  'user.fa': '2단계 인증',
  'user.active_status': '활성 상태',
  'user.amal_net_title': '사용자 AMAL 순위',
  'user.profit': '거래소 수익 세부 정보',
  'user.receive_fee': '수수료 받기',
  'user.referral_fee': '추천 수수료',
  'user.amal_in': 'AMAL 구매',
  'user.amal_out': 'AMAL 판매',
  'user.amal_net': 'AMAL 순수익',
  'user.ranking': '순위',
  'user.group': '그룹',
  'user.memo': '메모',
  'user.enable_fee.enable': '활성화',
  'user.enable_fee.disable': '비활성화',
  'user.action.add_to_group': '그룹에 추가',
  'user.action.delete_from_group': '그룹에서 삭제',
  'user.alert.no_user_selected': '최소 한 명의 사용자를 선택해야 합니다!',
  'user.alert.no_group_selected': '최소 한 개의 그룹을 선택해야 합니다!',
  'mam.manage_sub_orders': '하위 주문 관리',
  'mam.mam_funds': '자금',
  'mam.join': '가입',
  'notice.errors.title.required': '제목 필드는 필수입니다.',
  'notice.errors.support_url.required': '링크 지원 필드는 필수입니다.',
  'notice.errors.banner_url.required': '배너 필드는 필수입니다.',
  'notice.banner_url': '배너',
  'notice.validate.image_type': '파일은 .jpg, .jpeg, .png 유형이어야 합니다.',
  'orders.open_order.data': '데이터',
  'orders.open_order.search_by_email_id': '이메일 또는 아이디로 검색',
  'orders.open_order.search_by_coin': '코인으로 검색',
  'orders.open_order.order_transaction_id': '주문 거래 ID',
  'orders.open_order.transaction_owner': '거래 진행자',
  'orders.open_order.created_date': '생성 날짜',
  'orders.order_book.order_id': '오픈 ID',
  'orders.order_book.price': '가격',
  'orders.order_book.quantity': '수량',
  'orders.order_book.total': '총계',
  'orders.open_order.account_id': '사용자 ID',
  'orders.open_order.quantity': '수량',
  'orders.open_order.trigger': '트리거',
  'account.transaction.network': '네트워크',
  'email.modal': '이 이메일 마케팅을 제출하시겠습니까?',
  'email.edit_title_page': '이메일 마케팅 편집',
  'email.send_title_page': '이메일 보내기',
  'email.send': '보내기',
  'email.errors.title.required': '제목 필드는 필수입니다.',
  'email.errors.content.required': '내용 필드는 필수입니다.',
  'withdraq_fial_popup_confirmation_label': '이 거래를 확인하시겠습니까?',
  'currency.amal': 'AMAL',
  'salespoint.balances': '잔액',
  'salespoint.bonus': '보너스',
  'salespoint.buy': '매수',
  'salespoint.buy_history': '매수 내역',
  'salespoint.buy_history.amount': '금액',
  'salespoint.buy_history.amal': 'AMAL',
  'salespoint.buy_history.bonus': '보너스 토큰',
  'salespoint.buy_history.currency': '통화',
  'salespoint.buy_history.time': '시간',
  'salespoint.buy_history.cash_back': '추천 수수료',
  'salespoint.buy_history.referrer_email': '추천인 이메일',
  'salespoint.buy_history.referred_email': '추천받은 이메일',
  'salespoint.buy_history.rate': '비율 (%)',
  'salespoint.buy_success': '주문이 완료되었습니다!',
  'salespoint.buy_fail': '주문에 실패했습니다!',
  'salespoint.export_complete_buy_history': '완료된 AMAL 내역 내보내기',
  'salespoint.export_complete_buy_history_tooltip': '6개월 내 완료된 구매 내역만 내보내기',
  'salespoint.login': '로그인',
  'salespoint.or': '또는',
  'salespoint.register': '회원가입',
  'salespoint.to_trade': '거래하기 위해',
  'salespoint.total_remaining_supply': '총 AMAL 잔여 공급량',
  'site_setting.phone_number': '전화번호',
  'site_setting.phone_number_valid': '전화번호는 '+' 문자와 국가 코드로 시작해야 하며, 길이는 7~15자리여야 합니다.',
  'site_setting.social_network.errors.required.phone_number': '전화번호 필드는 필수입니다.',
  'trading_fee_setting.coin_hodling': '{COIN_HOLDING}보유',
  'trading_fee_setting.msg_coin_holding': '코인 보유 설정은 각 레벨마다 증가해야 합니다.',
  'user_setting.reward': '총 보상:',
  'trading_fee_setting.coin': '코인',
  'trading_fee_setting.trading_selectMarket': '시장 선택',
  'trading_fee_setting.under_101_percent': '{0} 필드는 100%를 초과할 수 없습니다.',
  'trading_fee_setting.validate.required': '{0} 필드는 필수입니다.',
  'trading_fee_setting.validate.isvalid': '{0} 필드가 유효합니다!',
  'trading_fee_setting.decial_part_under_2_character': '{0} 필드의 소수점 자릿수는 2를 초과할 수 없습니다.',
  'trading_fee_setting.decial_part_under_8_character': '{0} 필드의 소수점 자릿수는 8을 초과할 수 없습니다.',
  'trading_fee_setting.dailyLimit_higher_Limit': '한도 값은 일일 한도보다 작아야 합니다.',
  'tradding_fee_setting.fee_taker': '테이커 수수료',
  'tradding_fee_setting.fee_maker': '메이커 수수료',
  'circuit_breaker_setting.title': '서킷 브레이커 설정',
  'circuit_breaker_setting.select_market': '시장 선택',
  'circuit_breaker_setting.enable_circuit_breaker': '서킷 브레이커 활성화',
  'circuit_breaker_setting.coin': '코인',
  'circuit_breaker_setting.range_listen_time': '범위 청취 시간 (시간)',
  'circuit_breaker_setting.range_percent': '범위 백분율 (%)',
  'circuit_breaker_setting.block_time': '차단 시간 (시간)',
  'circuit_breaker_setting.enable_column': '상태',
  'circuit_breaker_setting.enabled': '활성화',
  'circuit_breaker_setting.disabled': '비활성화',
  'circuit_breaker_setting.update_confirm': '이 기록을 변경하시겠습니까?',
  'circuit_breaker_setting.update_success_msg': '성공적으로 업데이트되었습니다!',
  'circuit_breaker_setting.update_enable_confirm': '상태를 변경하시겠습니까?',
  'circuit_breaker_setting.validation.block_trading': '거래 코인 쌍이 차단 중입니다!',
  'circuit_breaker_setting.validation.block_time': '차단 시간 필드는 필수입니다.',
  'circuit_breaker_setting.validation.range_listen_time': '범위 청취 시간 필드는 필수입니다.',
  'circuit_breaker_setting.validation.circuit_breaker_percent': '범위 백분율 필드는 필수입니다.',
  'circuit_breaker_setting.validation.range_listen_time.empty_value': '범위 청취 시간 값은 0보다 커야 합니다.',
  'circuit_breaker_setting.validation.circuit_breaker_percent.empty_value': '범위 백분율 값은 0보다 커야 합니다.',
  'circuit_breaker_setting.validation.block_time.empty_value': '차단 시간 값은 0보다 커야 합니다.',
  'circuit_breaker_setting.update_fail_msg': '업데이트 실패했습니다!',
  'auto_dividend.confirm_off_pair': '이 거래쌍을 비활성화하시겠습니까?',
  'auto_dividend.confirm_off_pair_margin': '이 계약을 비활성화하시겠습니까?',
  'auto_dividend.confirm_on_pair': '이 거래쌍을 활성화하시겠습니까?',
  'auto_dividend.confirm_on_pair_margin': '이 계약을 활성화하시겠습니까?',
  'auto_dividend.update_status_msg': '성공적으로 업데이트되었습니다!',
  'entry_leaderboard.contest': '대회',
  'entry_leaderboard.manual': '리더보드 수동 결제 항목',
  'entry_leaderboard.manual1': '수동 결제 항목',
  'entry_leaderboard.team': '팀',
  'entry_leaderboard.team_id': '팀 아이디',
  'enable_fee.this_market': '이 시장에 모든 거래쌍 추가',
  'enable_fee.all_market': '모든 거래쌍 추가',
  'enable_fee.user_setting.confirm_off': '수수료 설정을 비활성화하시겠습니까?',
  'enable_fee.user_setting.confirm_on': '수수료 설정을 활성화하시겠습니까?',
  'enable_fee.user_setting.update_success': '수수료 사용자 설정을 성공적으로 업데이트했습니다!',
  'enable_fee.user_setting.update_fail': '수수료 사용자 설정 업데이트 실패!',
  'enable_fee.title': '사용자 수수료 설정 활성화',
  'enable_fee.remove_success': '사용자가 성공적으로 제거되었습니다!',
  'enable_fee.remove_fail': '사용자 제거 실패했습니다!',
  'enable_fee.currency': '통화',
  'enable_fee.coin': '코인',
  'enable_fee.no': '번호',
  'enable_fee.status': '상태',
  'enable_fee.fee_charge': '수수료 청구',
  'enable_fee.user_was_exist': '해당 계정은 이미 존재합니다.',
  'enable_fee.user_not_exist': '해당 계정은 존재하지 않습니다.',
  'enable_withdrawal.this_market': '이 시장에 모든 거래쌍 추가',
  'enable_withdrawal.all_market': '모든 거래쌍 추가',
  'enable_withdrawal.all_coins': '모든 코인 추가',
  'enable_withdrawal.user_setting.confirm_off': '출금 설정을 비활성화하시겠습니까?',
  'enable_withdrawal.user_setting.confirm_on': '출금 설정을 활성화하시겠습니까?',
  'enable_withdrawal.user_setting.update_success': '출금 설정 사용자 설정을 성공적으로 업데이트했습니다!',
  'enable_withdrawal.user_setting.update_fail': '출금 설정 사용자 설정 업데이트 실패!',
  'enable_withdrawal.title': '사용자 출금 설정',
  'enable_withdrawal.remove_success': '사용자가 성공적으로 제거되었습니다!',
  'enable_withdrawal.remove_fail': '사용자 제거 실패!',
  'enable_withdrawal.currency': '통화',
  'enable_withdrawal.coin': '코인',
  'enable_withdrawal.no': '번호',
  'enable_withdrawal.status': '상태',
  'enable_withdrawal.user_was_exist': '계정은 이미 존재합니다.',
  'enable_withdrawal.user_not_exist': '계정은 존재하지 않습니다.',
  'enable_trading.this_market': '이 시장에 모든 거래쌍을 추가하세요.',
  'enable_trading.all_market': '모든 거래쌍 추가',
  'enable_trading.all_contract': '모든 계약 추가',
  'enable_trading.remove_this_market': '이 시장에서 모든 거래쌍 제거',
  'enable_trading.remove_all_market': '모든 거래쌍 제거',
  'enable_trading.remove_all_contract': '모든 계약 제거',
  'enable_trading.is_mam': 'MAM 사용자에게 추가',
  'enable_trading.remove_is_mam': 'MAM 사용자에게 제거',
  'enable_trading.all_coins': '모든 코인 추가',
  'enable_trading.user_setting.confirm_off': '거래 설정을 비활성화하시겠습니까?',
  'enable_trading.user_setting.confirm_on': '거래 설정을 활성화하시겠습니까?',
  'enable_trading.user_setting.update_success': '거래 설정 사용자 설정을 성공적으로 업데이트했습니다!',
  'enable_trading.user_setting.update_fail': '거래 설정 사용자 설정 업데이트 실패!',
  'enable_trading.title': '거래 설정',
  'enable_trading.remove_success': '사용자가 성공적으로 제거되었습니다!',
  'enable_trading.remove_fail': '사용자 제거 실패!',
  'enable_trading.currency': '통화',
  'enable_trading.coin': '코인',
  'enable_trading.contract': '계약',
  'enable_trading.mam_user': 'MAM 사용자',
  'enable_trading.is_beta_tester': '베타 테스터',
  'enable_trading.no': '번호',
  'enable_trading.status': '상태',
  'enable_trading.user_was_exist': '계정은 이미 존재합니다.',
  'enable_trading.user_not_exist': '계정은 존재하지 않습니다.',
  'enable_trading.show_beta_tester': '베타 테스터 보기',
  'email.email': '이메일',
  'email.status': '상태',
  'email.message': '메시지',
  'exchange_balance.available_exchange_balance': '사용 가능한 거래소 잔액',
  'withdraw_setting.id': '아이디',
  'withdraw_setting.enable_confirm': '출금을 활성화하시겠습니까?',
  'withdraw_setting.enable_all_confirm': '모든 통화에 대해 출금을 활성화하시겠습니까?',
  'withdraw_setting.disable_deposit_confirm': '입금을 비활성화하시겠습니까?',
  'withdraw_setting.disable_all_deposit_confirm': '모든 통화의 입금을 비활성화하시겠습니까?',
  'withdraw_setting.enable_deposit_confirm': '입금을 활성화하시겠습니까?',
  'withdraw_setting.enable_all_deposit_confirm': '모든 통화의 입금을 활성화하시겠습니까?',
  'withdraw_setting.name': '이름',
  'withdraw_setting.deposit': '입금',
  'withdraw_setting.withdraw': '출금',
  'withdraw_setting.update_success_msg': '성공적으로 업데이트되었습니다!',
  'withdraw_setting.disable_success_msg': '성공적으로 비활성화되었습니다!',
  'withdraw_setting.disable_all_success_msg': '모든 항목이 성공적으로 비활성화되었습니다!',
  'withdraw_setting.enable_success_msg': '성공적으로 활성화되었습니다!',
  'withdraw_setting.enable_all_success_msg': '모든 항목이 성공적으로 활성화되었습니다!',
  'withdraw_setting.withdraw_setting': '거래 설정',
  'withdraw_setting.disable_confirm': '출금을 비활성화하시겠습니까?',
  'withdraw_setting.disable_all_confirm': '모든 통화의 출금을 비활성화하시겠습니까?',
  'user.login_history': '내역',
  'trezor_address_for_deposit': '입금을 위한 Trezor 주소',
  'trezor_device': 'Trezor 장치',
  'trezor_device.created_at': '생성 일자',
  'trezor_device.device_id': '장치 아이디',
  'trezor_device.device_name': '장치 이름',
  'trezor_device.disable': '비활성화',
  'trezor_device.enable': '활성화',
  'trezor_device.model': '모델',
  'trezor_device.register_new_device': 'Trezor 생성',
  'trezor_device.title': 'Trezor 장치',
  'trezor_device_information': 'Trezor 장치 정보',
  'trezor_label': 'TREZOR 라벨',
  'trezor_path_for_deposit': '입금을 위한 Trezor 경로',
  'trezor_wallet': 'Trezor 지갑',
  'trezor_wallet.address': '주소',
  'trezor_wallet.balance': '잔액',
  'trezor_wallet.currency': '통화',
  'trezor_wallet.details': '세부 사항',
  'trezor_wallet.device': '장치',
  'trezor_wallet.device_name': '장치 이름',
  'trezor_wallet.path': '경로',
  'trezor_wallet.summary': '요약',
  'trezor_wallet.title': 'Trezor 지갑',
  'trezor_wallet.total_wallet': '총 지갑',
  'modal.trezor_device_information': 'Trezor 장치 정보',
  'modal.device_name': '장치 이름',
  'modal.device_id': '장치 아이디',
  'modal.model': '모델',
  'datatable_empty': '테이블에 데이터가 없습니다.',
  'datatable_search_empty': '검색과 일치하는 결과가 없습니다.',
  'wallet.create_receive_address.currency': '통화',
  'wallet.create_receive_address.currency_list': '통화 목록',
  'wallet.create_receive_address.generate_address': '주소 생성',
  'wallet.create_receive_address.the_number_of_remaining': '남은 개수',
  'wallet.create_receive_address.title': '수신 주소 생성',
  'wallet.create_receive_address.get_req_success': '주소가 성공적으로 생성되었습니다!',
  'wallet.create_receive_address.return_req_leave': '떠나시겠습니까?',
  'wallet.create_receive_address.address': '{coin_name} 주소',
  'wallet.create_receive_address.coin_title': '{coin_full_name} ({coin_name}) 수신 주소 생성',
  'wallet.create_receive_address.total_number': '총 개수',
  'wallet.create_receive_address.lose_address': '{field} 필드는 필수입니다.',
  'receive_address_creation': '수신 주소 생성',
  'receive_address_creation.warning_text': '입력 값은 정수이어야 하며 0보다 커야 합니다.',
  'receive_address_creation.author': '작성자',
  'receive_address_creation.input_contents_to_be_created': '생성할 내용을 입력하세요.',
  'receive_address_creation.number_created': '생성된 개수',
  'receive_address_creation.please_do_not_use_trezor_for_transmission': '전송에 TREZOR를 사용하지 마세요.',
  'receive_address_creation.start_creating': '생성 시작',
  'receive_address_creation.title': '수신 주소 생성',
  'receive_address_creation.trezor_label': 'TREZOR 라벨',
  'user_wallet': '사용자 지갑',
  'user_wallet.address': '주소',
  'user_wallet.available_balance': '사용 가능한 잔액',
  'user_wallet.balance': '잔액',
  'user_wallet.currency': '통화',
  'user_wallet.details': '세부 사항',
  'user_wallet.device_name': '기기 이름',
  'user_wallet.path': '경로',
  'user_wallet.summary': '요약',
  'user_wallet.title': '사용자 지갑',
  'user_wallet.total_wallet': '총 지갑',
  'user_wallet.total_wallet_2': '총 지갑',
  'user_wallet.user_name': '이메일',
  'coin_information.btn_back': '뒤로 가기',
  'coin_information.btn_choose_file': '파일 선택',
  'coin_information.btn_next': '다음',
  'coin_information.contact_address': '계약 주소',
  'coin_information.decimals': '소수점',
  'coin_information.explorer': '탐색기',
  'coin_information.heading_title': '새로운 ERC20 등록',
  'coin_information.icon': '아이콘',
  'coin_information.image_note': '이미지는 png 형식의 파일이어야 합니다.',
  'coin_information.image_error': '파일은 .png 형식의 파일이어야 합니다.',
  'coin_information.required_confirmations': '필요한 확인',
  'coin_information.required_confirmations_explorer': '필요한 확인 탐색기',
  'coin_information.tab_title': '토큰 정보',
  'coin_information.token': '토큰',
  'coin_information.token_name': '토큰 이름',
  'coin_information.type': '유형',
  'airdrop_setting.remaining': '남은 금액',
  'airdrop_active': '활성화',
  'airdrop_setting.airdrop_total_paid': '총 지급된 금액',
  'airdrop_setting.airdrop_remaining': '배당금 잔액',
  'airdrop_setting.date_from_to': '날짜 (시작-종료)',
  'airdrop_setting.date_to': '종료 날짜',
  'airdrop_setting.search_date_range': '날짜 (시작-종료)',
  'airdrop_setting.airdrop_setting': '배당 설정',
  'account_setting.symbol': '심볼',
  'account_setting.leverage': '레버리지',
  'account_setting.crossMargin': '크로스 마진 여부',
  'account_setting.deleveragePercentile': '디레버리지 퍼센타일',
  'account_setting.currentQty': '현재 수량',
  'account_setting.markPrice': '마크 가격',
  'account_setting.realisedPnl': '실현된 손익',
  'account_setting.unrealisedPnl': '미실현 손익',
  'account_setting.liquidPrice': '청산 가격',
  'account_setting.bankruptPrice': '파산 가격',
  'account_setting.initMargin': '초기 비율',
  'account_setting.maintMargin': '유지 비율',
  'account_setting.position': '포지션',
  'bitmex.account': 'Bitmex 계정',
  'account_setting.key_id': '키 아이디',
  'account_setting.ker_secret': '키 비밀 번호',
  'bitmex.is_cross': '크로스 여부',
  'bitmex.isleverage': '레버리지 여부',
  'account_setting.key_name': '키 이름',
  'account_setting.email': '이메일',
  'account_setting.balance': '잔액',
  'account_setting.available_balance': '사용 가능 잔액',
  'airdrop_setting.status': '상태',
  'airdrop_setting.dividend_setting_manual': '수동 배당 지급',
  'airdrop_setting.minimum_amount': '최소 금액',
  'airdrop_setting.deposit_amount': '입금 금액',
  'airdrop_setting.number_of_referrer': '추천인',
  'airdrop_setting.amal_holding': 'AMAL 보유',
  'airdrop_setting.kyc': 'KYC',
  'airdrop_setting.self_trading': '자기 거래',
  'airdrop_setting.send_bonus': '보너스 지급',
  'airdrop_setting.refund_bonus': '보너스 환불',
  'airdrop_setting.send_bonus_success': '보너스 지급 성공!',
  'airdrop_setting.cashback_success': '캐시백 성공!',
  'airdrop_setting.refund_bonus_success': '보너스 환불 성공!',
  'airdrop_setting.send_bonus_error': '보너스 지급 실패',
  'airdrop_setting.refund_bonus_error': '보너스 환불 실패',
  'airdrop_setting.auto_dividend_setting.confirm_status_disable': '선택한 모든 레코드를 비활성화하시겠습니까?',
  'airdrop_setting.auto_dividend_setting.confirm_status_enable': '선택한 모든 레코드를 활성화하시겠습니까?',
  'airdrop_setting.refund_amount_missing': '금액 필드는 필수입니다.',
  'airdrop_setting.amount_missing': '이 필드는 필수입니다.',
  'airdrop_setting.deposit_missing': '입금 금액 필드는 필수입니다.',
  'airdrop_setting.deposit_than_0': '입금 금액은 0보다 커야 합니다.',
  'airdrop_setting.holding_missing': 'AMAL 보유 필드는 필수입니다.',
  'airdrop_setting.holding_than_0': 'AMAL 보유는 0보다 커야 합니다.',
  'airdrop_setting.referrer_missing': '추천인 필드는 필수입니다.',
  'airdrop_setting.referrer_than_0': '추천인은 0보다 커야 합니다.',
  'airdrop_setting.payout_amount_missing': '지급 금액 필드는 필수입니다.',
  'airdrop_setting.dividend_manual.pay_amount': '지급 금액',
  'airdrop_setting.dividend_manual.refund_amount': '금액',
  'airdrop_setting.dividend_manual.pay_for': '지갑',
  'airdrop_setting.auto_dividend_history': '자동 배당 지급 내역',
  'airdrop_setting.manual_dividend_history': '수동 지급 내역',
  'airdrop_setting.bonus.status': '상태',
  'airdrop_setting.bonus.last_paid': '마지막 지급',
  'auto_dividend.confirm_off_all_pair': '모든 거래쌍을 비활성화하시겠습니까?',
  'auto_dividend.confirm_on_all_pair': '모든 거래쌍을 활성화하시겠습니까?',
  'pending': '대기 중',
  'success': '성공',
  'fail': '실패',
  'airdrop_setting.bonus.bonus_setting': '보너스 설정',
  'airdrop_setting.bonus.refund_bonus_setting': '캐시백 받기',
  'airdrop_setting.trading_volume': '거래량',
  'airdrop_setting.auto_dividend_title': '자동 배당 설정',
  'airdrop_setting.enable_auto_dividend': '활성화',
  'airdrop_setting.time_apply_from': '적용 시작',
  'airdrop_setting.time_apply_to': '적용 종료',
  'airdrop_setting.market': '거래쌍',
  'airdrop_setting.contract': '계약',
  'airdrop_setting.minimum_trade_amount': '수량',
  'airdrop_setting.max_bonus': '최대 보너스',
  'airdrop_setting.reset_bonus': '보너스 초기화',
  'airdrop_setting.confirm_reset_bonus': '보너스를 초기화하시겠습니까?',
  'airdrop_setting.max_bonus_low': '최대 보너스',
  'airdrop_setting.payout_amount': '지급 금액',
  'airdrop_setting.payout_for': '지급 지갑',
  'airdrop_setting.total_bonus_dividend': '총 보너스 배당금',
  'airdrop_setting.currency': '통화',
  'airdrop_setting.email': '이메일',
  'airdrop_setting.email1': '이메일',
  'airdrop_setting.period': '기간',
  'airdrop_setting.lock_period': '잠금 기간',
  'airdrop_setting.unlock_percent': '해제 비율',
  'airdrop_setting.unlock_percent_period': '해제 비율/기간',
  'airdrop_setting.confirm_remove': '이 사용자를 제거하시겠습니까?',
  'airdrop.error.field_required': '{field_name} 필드는 필수입니다.',
  'airdrop.error.field_min': '{field_name}의 값은 0보다 커야 합니다.',
  'airdrop.error.field_max': '{field_name}의 값은 100보다 작아야 합니다.',
  'airdrop.error.total_supply_too_big': '총 공급량은 210,000,000을 초과할 수 없습니다.',
  'airdrop_setting.remove_success': '사용자가 성공적으로 제거되었습니다!',
  'validation.is_contract_address': '계약 주소가 유효하지 않습니다.',
  'validation.is_contract_address_exist': '계약 주소가 이미 존재합니다.',
  'trading_information.btn_back': '뒤로 가기',
  'trading_information.btn_delete': '삭제',
  'trading_information.btn_edit': '수정',
  'trading_information.btn_next': '다음',
  'trading_information.btn_register_trading_pair': '거래쌍 등록',
  'trading_information.delete_msg': '성공',
  'trading_information.fee': '수수료',
  'trading_information.taker_fee': '테이커 수수료',
  'trading_information.maker_fee': '메이커 수수료',
  'trading_information.taker_fee_percent': '테이커 수수료 (%)',
  'trading_information.maker_fee_percent': '메이커 수수료 (%)',
  'trading_information.heading_title': '새로운 ERC20 등록',
  'trading_information.market_price': '시장 가격',
  'trading_information.price_precision': '가격 정밀도',
  'trading_information.minimum_amount': '최소 금액',
  'trading_information.quantity_precision': '수량 정밀도',
  'trading_information.minimum_total': '최소 총액',
  'trading_information.zone': '구역',
  'trading_information.zone_0': '기본',
  'trading_information.zone_1': 'ERC20',
  'trading_information.pair': '거래쌍',
  'trading_information.trading_information': '거래 정보',
  'trading_information.precision_max': '정밀도 필드는 {precision} 이하이어야 합니다.',
  'account_setting.payout_currency': '지급 통화',
  'account_setting.enable_airdrop': '배당 활성화',
  'account_setting.enable_wallet_airdrop': 'OGX로 수수료 지불 활성화',
  'account_setting.trading_with_themselves': '자기 거래 활성화:',
  'account_setting.user_pass_kyc': 'KYC 사용자만 표시:',
  'account_setting.show_leaderboard_on_date_from_to': '순위 (시작)',

  'account_setting.show_leaderboard_on_date': '리더보드는 지정된 날짜 범위 내의 데이터만 표시됩니다.',
  'account_setting.enable_wallet_airdrop_pay_amal': '배당금을 사용하여 AMAL 수수료를 지불',
  'account_setting.edit': '수정',
  'account_setting.cancel': '취소',
  'account_setting.apply': '적용',
  'account_setting.list_account': '계정 목록',
  'account_setting.updated_time': '업데이트 시간',
  'account_setting.lock_period': '잠금 기간',
  'account_setting.unlock_percent_period': '해제 비율/기간',
  'account_setting.default_lock_period': '기본 잠금 기간',
  'account_setting.default_lock_period1': '기본 잠금 기간',
  'account_setting.default_lock_period2': '잠금 기간',
  'account_setting.default_unlock_percent_period': '기본 해제 비율/기간',
  'account_setting.default_unlock_percent_period1': '기본 해제 비율/기간',
  'account_setting.default_unlock_percent_period2': '해제 비율/기간',
  'account_setting.airdrop_payout_amount': '총 배당 지급 금액',
  'account_setting.airdrop_payout_amount1': '총 배당 지급 금액',
  'account_setting.set_payout_time': '지급 시간 설정',
  'account_setting.set_payout_time1': '지급 시간 설정',
  'account_setting.set_minumum_amount': '최소 금액 설정',
  'account_setting.set_minumum_amount1': '최소 금액 설정',
  'account_setting.total_supply': '총 공급량',
  'account_setting.total_supply1': '총 공급량',
  'account_setting.airdrop.search': '이메일로 검색',
  'account_setting.add_account': '계정 추가',
  'account_setting.add_user': '사용자 추가',
  'withdrawal_information.back': '뒤로 가기',
  'withdrawal_information.currency': '통화',
  'withdrawal_information.day': '일',
  'withdrawal_information.days': '일수',
  'withdrawal_information.fee': '수수료',
  'withdrawal_information.fee_percent': '수수료 %',
  'withdrawal_information.taker_fee_percent': '테이커 수수료 %',
  'withdrawal_information.marker_fee_percent': '메이커 수수료 %',
  'withdrawal_information.heading_title': '새로운 ERC20 등록',
  'withdrawal_information.limit': '지정가',
  'withdrawal_information.minimum_withdrawal': '최소 출금',
  'withdrawal_information.minimum_withdrawal_erc20': '최소 출금',
  'withdrawal_information.next': '다음',
  'withdrawal_information.tab_title': '출금 정보',
  'withdrawal_information.time_reset': '시간 초기화',
  'withdrawal_information.level_1': '레벨 1',
  'withdrawal_information.level_2': '레벨 2',
  'withdrawal_information.level_3': '레벨 3',
  'withdrawal_information.level_4': '레벨 4',
  'referralSetting_update_success': '성공: 업데이트 완료!',
  'review_register_erc_2_0.*_check_register_erc20_information': '* ERC20 정보 등록을 확인하세요',
  'review_register_erc_2_0.amount_limit': '금액 한도',
  'review_register_erc_2_0.btn_back': '뒤로 가기',
  'review_register_erc_2_0.btn_register': '등록',
  'review_register_erc_2_0.contract_address': '계약 주소',
  'review_register_erc_2_0.currency': '통화',
  'review_register_erc_2_0.days': '일수',
  'review_register_erc_2_0.decimals': '소수점 자리수',
  'review_register_erc_2_0.error_msg': '설정 중 오류가 발생했습니다. 다시 등록해주세요!',
  'review_register_erc_2_0.explorer': '탐색기',
  'review_register_erc_2_0.fee': '수수료',
  'review_register_erc_2_0.icon': '아이콘',
  'review_register_erc_2_0.limit': '지정가',
  'review_register_erc_2_0.market_price': '시장 가격',
  'review_register_erc_2_0.minimum_amount': '최소 금액',
  'review_register_erc_2_0.quantity_precision': '수량 정밀도',
  'review_register_erc_2_0.minimum_total': '최소 총액',
  'review_register_erc_2_0.minimum_withdrawal': '최소 출금',
  'review_register_erc_2_0.pair': '거래쌍',
  'review_register_erc_2_0.precision': '정밀도',
  'review_register_erc_2_0.register_new_erc20': '새로운 ERC20 등록',
  'review_register_erc_2_0.required_confirmations': '필요한 확인',
  'review_register_erc_2_0.time_reset': '시간 초기화',
  'review_register_erc_2_0.token': '토큰',
  'review_register_erc_2_0.token_information': '토큰 정보',
  'review_register_erc_2_0.token_name': '토큰 이름',
  'review_register_erc_2_0.total_limit': '총 지정가',
  'review_register_erc_2_0.trading_fee': '거래 수수료',
  'review_register_erc_2_0.trading_limit': '거래 한도',
  'review_register_erc_2_0.trading_pair': '거래쌍',
  'review_register_erc_2_0.type': '유형',
  'review_register_erc_2_0.withdrawal_information': '출금 정보',
  'review_register_erc_2_0.success_msg': '등록 성공!',
  'review_register_erc_2_0.taker_fee': '테이커 수수료',
  'review_register_erc_2_0.maker_fee': '메이커 수수료',
  'review_register_erc_2_0.level_1': '레벨 1',
  'review_register_erc_2_0.level_2': '레벨 2',
  'review_register_erc_2_0.level_3': '레벨 3',
  'review_register_erc_2_0.level_4': '레벨 4',
  'remittance_complete_registration': '송금 완료 등록',
  'remittance_ordered.amount': '금액',
  'remittance_ordered.btn_detail': '상세',
  'remittance_ordered.btn_search': '검색',
  'remittance_ordered.currency': '통화',
  'remittance_ordered.execution_date': '실행 날짜',
  'remittance_ordered.heading_title': '송금된 거래',
  'remittance_ordered.no': '아니오',
  'remittance_ordered.transaction_id': '거래 아이디',
  'remittance_ordered.user_name': '이메일',
  'remittance_ordered.withdraw_approval': '출금 승인자',
  'remittance_transaction_page.administrator_information': '관리자 정보',
  'remittance_transaction_page.withdrawal_currency': '출금 통화',
  'remittance_transaction_page.withdrawal_address': '출금 주소',
  'remittance_transaction_page.transaction_detail': '거래 상세',
  'remittance_transaction_page.customer_information': '고객 정보',
  'remittance_transaction_page.transaction_hash': '거래 해시',
  'remittance_transaction_page.remark': '비고',
  'remittance_transaction_page.approved_date': '승인 날짜',
  'remittance_transaction_page.approved_by': '승인자',
  'remittance_transaction_page.country': '국가',
  'remittance_transaction_page.created_date': '생성 날짜',
  'remittance_transaction_page.sent_at': '전송 시각',
  'remittance_transaction_page.sent_by': '발송자',
  'remittance_transaction_page.email': '이메일',
  'remittance_transaction_page.header_title': '[상세] 거래: {transaction_id}',
  'remittance_transaction_page.quantity': '금액',
  'remittance_transaction_page.transaction_id': '거래 아이디',
  'remittance_transactions': '송금된 거래',
  'remitted_transaction': '송금된 거래',
  'erc20_loading_modal.create_account_message': '계정 생성 중....',
  'erc20_loading_modal.create_migration_message': '마이그레이션 생성 중....',
  'erc20_loading_modal.done_message': '완료....',
  'erc20_loading_modal.loading_message': '로딩 중....',
  'erc20_loading_modal.set_market_price_message': '시장 가격 설정 중....',
  'erc20_loading_modal.update_masterdata_message': '마스터 데이터 업데이트 중....',
  'eth_wallet_information': 'ETH 지갑 정보',
  'user_not_confirmed.amount': '금액',
  'user_not_confirmed.btn_search': '검색',
  'user_not_confirmed.created_date': '생성 날짜',
  'user_not_confirmed.currency': '통화',
  'user_not_confirmed.no': '아니오',
  'user_not_confirmed.title': '사용자 미확인',
  'user_not_confirmed.transaction_id': '거래 아이디',
  'user_not_confirmed.user_name': '이메일',
  'user_not_confirmed.user_not_confirmed': '사용자 미확인',
  'pending_transaction_page.approved_date': '승인 날짜',
  'pending_transaction_page.back': '뒤로 가기',
  'pending_transaction_page.birthdate': '생년월일',
  'pending_transaction_page.country': '국가',
  'pending_transaction_page.created_date': '생성 날짜',
  'pending_transaction_page.customer_information': '고객 정보',
  'pending_transaction_page.detail': '상세',
  'pending_transaction_page.email': '이메일',
  'pending_transaction_page.header_title': '[상세] 거래: {transaction_id}',
  'pending_transaction_page.name': '이름',
  'pending_transaction_page.open_notification_rejected': '거절된 거래가 완료되었습니다.',
  'pending_transaction_page.open_notification_verified': '승인된 거래가 완료되었습니다.',
  'pending_transaction_page.quantity': '금액',
  'pending_transaction_page.reject': '거절',
  'pending_transaction_page.remarks': '비고',
  'pending_transaction_page.tel': '전화번호',
  'pending_transaction_page.transaction': '거래',
  'pending_transaction_page.transaction_detail': '거래 상세',
  'pending_transaction_page.transaction_id': '거래 아이디',
  'pending_transaction_page.trezor_address_for_deposit': '입금을 위한 Trezor 주소',
  'pending_transaction_page.trezor_path_for_deposit': '입금을 위한 Trezor 경로',
  'pending_transaction_page.verify': '확인',
  'pending_transaction_page.withdrawal_address': '출금 주소',
  'pending_transaction_page.withdrawal_currency': '출금 통화',
  'pending_transaction_page.field_require': '필수 필드를 나타냅니다.',
  'rejected_transaction_page.administrator_information': '관리자 정보',
  'rejected_transaction_page.withdrawal_currency': '출금 통화',
  'rejected_transaction_page.withdrawal_address': '출금 주소',
  'rejected_transaction_page.transaction_detail': '거래 상세',
  'rejected_transaction_page.customer_information': '고객 정보',
  'rejected_transaction_page.transaction_hash': '거래 해시',
  'rejected_transaction_page.remark': '비고',
  'rejected_transaction_page.country': '국가',
  'rejected_transaction_page.created_date': '생성 날짜',
  'rejected_transaction_page.email': '이메일',
  'rejected_transaction_page.header_title': '[상세] 거래: {transaction_id}',
  'rejected_transaction_page.quantity': '금액',
  'rejected_transaction_page.reject_by': '거절자',
  'rejected_transaction_page.reject_date': '거절 날짜',
  'rejected_transaction_page.transaction_id': '거래 아이디',
  'transactions_pending': '대기 중인 거래',
  'transactions_history': '거래 내역',
  'salespoint.buy_history.buy_history': '구매 내역',
  'salespoint.buy_history.amal_buy_history': 'AMAL 구매 내역',
  'salespoint.buy_history.date': '날짜',
  'salespoint.buy_history.pair': '거래쌍',
  'salespoint.buy_history.coin': '코인',
  'salespoint.buy_history.all': '전체',
  'salespoint.buy_history.search_by_email': '이메일로 검색',
  'salespoint.buy_history.search': '검색',
  'salespoint.buy_history.reset': '초기화',
  'salespoint.buy_history.buyer_email': '이메일',
  'leaderboard_item.trading_volume_ranking': '사용자 거래량 순위',
  'leaderboard.spotExchange': '현물 거래소',
  'leaderboard.marginExchange': '마진 거래소',
  'leaderboard_table_title.Ranking': '순위',
  'leaderboard_table_title.Email': '이메일',
  'leaderboard_table_title.TradingVolume': '거래량',
  'leaderboard.show_on_user_ui': '사용자 UI에 표시',
  'leaderboard.show_extra_setting': '고급 설정',
  'referral_item.referral_history': '추천 내역',
  'referral_item.referral_setting': '추천 설정',
  'referral_item.refund_rate': '환불 비율',
  'referral_item.number_of_referral_level': '추천 레벨 수',
  'referral_item.next_program_conditional': '다음 프로그램 조건',
  'referral_item.people': '사람',
  'referral_item.referral_enable': '추천 활성화',
  'referral_item.entry_program': '입장 프로그램',
  'referral_item.next_program': '다음 프로그램',
  'referral_item.refund_percent_at_level': '레벨별 추천 비율',
  'cold_wallet_setting.airdrop_history': '사용자 잠금 해제 상태',
  'cold_wallet_setting.airdrop_payment_history': '배당금 지급 내역',
  'cold_wallet_setting.cashback_history': '캐시백 내역',
  'cold_wallet_setting.account_setting': '계정 설정',
  'cold_wallet_setting.title_page': '콜드 월렛 설정',
  'cold_wallet_setting.receive_title': '수신 주소',
  'cold_wallet_setting.btc_address': 'BTC 주소',
  'cold_wallet_setting.bch_address': 'BCH 주소',
  'cold_wallet_setting.eth_address': 'ETH/ERC20 주소',
  'cold_wallet_setting.xrp_address': 'XRP 주소',
  'cold_wallet_setting.ltc_address': 'LTC 주소',
  'cold_wallet_setting.eos_address': 'EOS 주소',
  'cold_wallet_setting.ada_address': 'ADA 주소',
  'cold_wallet_setting.usdt_address': 'USDT 주소',
  'cold_wallet_setting.usd_address': 'USD 주소',
  'cold_wallet_setting.address': '{통화} 주소',
  'cold_wallet_setting.min_balance': '최소 잔액',
  'cold_wallet_setting.max_balance': '최대 잔액',
  'cold_wallet_setting.notification_title': '알림',
  'cold_wallet_setting.holder_email': '콜드 월렛 보유자 이메일',
  'cold_wallet_setting.holder_name': '콜드 월렛 보유자 이름',
  'cold_wallet_setting.holder_mobile': '콜드 월렛 보유자 휴대폰 번호',
  'cold_wallet_setting.save_message': '저장 중....',
  'cold_wallet_setting.address_valid.invalid': '주소가 유효하지 않습니다.',
  'cold_wallet_setting.address.invalid': '코인 주소 필드는 필수입니다.',
  'cold_wallet_setting.min_balance.invalid': '최소 잔액 필드는 필수입니다.',
  'cold_wallet_setting.max_balance.invalid': '최대 잔액 필드는 필수입니다.',
  'cold_wallet_setting.min_balance.error': '최소 잔액은 0보다 커야 합니다.',
  'cold_wallet_setting.max_balance.error': '최대 잔액은 0보다 커야 합니다.',
  'cold_wallet_setting.max_min.invalid': '최대 잔액은 최소 잔액보다 커야 합니다!',
  'cold_wallet_setting.holder_email.invalid': '콜드 월렛 보유자 이메일 필드는 필수입니다.',
  'cold_wallet_setting.holder_email_format.invalid': '이 이메일 주소는 유효하지 않습니다.',
  'cold_wallet_setting.holder_name.invalid': '콜드 월렛 보유자 이름 필드는 필수입니다.',
  'cold_wallet_setting.holder_mobile_no.invalid': '콜드 월렛 보유자 휴대폰 번호 필드는 필수입니다.',
  'cold_wallet_setting.xrp.sub_address_placeholder': 'XRP 태그 (선택 사항)',
  'cold_wallet_setting.eos.sub_address_placeholder': 'EOS 태그 (선택 사항)',
  'permission.role.required': '역할 필드는 필수입니다.',
  'funds.transaction_history.no_deposit_data': '입금 내역이 없습니다.',
  'funds.transaction_history.no_withdraw_data': '출금 내역이 없습니다.',
  'withdraw_setting.currency': '통화',
  'menu.user_group_setting': '그룹 설정',
  'group_setting.date': '날짜',
  'group_setting.search_by_name': '이름으로 검색',
  'group_setting.search': '완료',
  'group_setting.reset': '초기화',
  'group_setting.add_new': '그룹 추가',
  'group_setting.name': '이름',
  'group_setting.memo': '메모',
  'group_setting.delete_data': '이 그룹을 삭제하시겠습니까?',
  'table.unlocked_balance': '잠금 해제 잔액',
  'table.total_airdrop_balance': '총 배당 잔액',
  'table.airdrop_balance': '배당 잔액',
  'table.available_airdrop_balance': '사용 가능한 배당 잔액',
  'table.perpetual_airdrop_balance': '영구 배당 잔액',
  'table.available_perpetual_airdrop_balance': '사용 가능한 영구 배당 잔액',
  'table.created_date': '생성 날짜',
  'table.unlock_date': '잠금 해제 날짜',
  'table.payment_date': '결제 날짜',
  'group_setting.edit': '그룹 수정',
  'margin_orders.title': '마진 주문',
  'margin_orders.open_order': '미체결 주문',
  'margin_orders.active_order': '활성 주문',
  'margin_orders.order_history': '주문 내역',
  'margin_orders.order_value': '주문 가치',
  'margin_orders.orders.updated_at': '시간',
  'margin_orders.orders.email': '이메일',
  'margin_orders.orders.original_id': '원래 아이디',
  'margin_orders.orders.account_id': '사용자 아이디',
  'margin_orders.orders.instrument_symbol': '상품 심볼',
  'margin_orders.orders.side': '매매 방향',
  'margin_orders.orders.type': '유형',
  'margin_orders.orders.quantity': '수량',
  'margin_orders.orders.price': '가격',
  'margin_orders.orders.lock_price': '고정 가격',
  'margin_orders.orders.remaining': '잔여',
  'margin_orders.orders.executed_price': '체결 가격',
  'margin_orders.orders.stop_type': '스탑 유형',
  'margin_orders.orders.stop_price': '스탑 가격',
  'margin_orders.orders.stop_condition': '스탑 조건',
  'margin_orders.orders.trigger': '트리거',
  'margin_orders.orders.time_in_force': '유효 시간',
  'margin_orders.orders.fee': '수수료',
  'margin_orders.orders.trail_value': '추적 값',
  'margin_orders.orders.vertex_price': '정점 가격',
  'margin_orders.orders.status': '상태',
  'margin_orders.orders.is_post_only': '포스트 전용 여부',
  'margin_orders.orders.is_hidden': '숨김 여부',
  'margin_orders.orders.is_reduce_only': '감소 전용 여부',
  'margin_orders.orders.pair_type': '거래쌍 유형',
  'margin_orders.orders.reference_id': '참조 아이디',
  'margin_orders.orders.note': '메모',
  'margin_orders.orders.text': '텍스트',
  'instrument.init_margin_required': '초기 비율 필드는 필수입니다.',
  'instrument.sum_greater_than_0': '메이커 수수료와 테이커 수수료의 합은 0 이상이어야 합니다.',
  'account.detail.affiliate.id_account': '사용자 아이디',
  'account.detail.affiliate.email': '이메일',
  'account.detail.affiliate.level': '레벨',
  'account.detail.affiliate.creationTime': '생성 시간',
  'account.detail.affiliate.tab.downline': '하위 라인',
  'account.detail.affiliate.tab.upline': '상위 라인',
  'account.detail.affiliate.inputplace.search': '아이디로 검색',
  'account.detail.affiliate.btn.search': '검색',
  'account.detail.activityhistory.id_account': '아이디',
  'account.detail.activityhistory.ip': 'IP 주소',
  'account.detail.activityhistory.device': '디바이스',
  'account.detail.activityhistory.creationTime': '생성 시간',
  'account.detail.openOrder.tab.spot': '현물',
  'account.detail.openOrder.tab.futures': '선물',
  'account.detail.openOrder.filter.date': '날짜',
  'account.detail.openOrder.filter.pair': '거래쌍',
  'account.detail.openOrder.filter.type': '유형',
  'account.detail.openOrder.filter.status': '상태',
  'account.detail.openOrder.inputplace.search': '아이디로 검색',
  'account.detail.openOrder.btn.search': '검색',
  'account.detail.openOrder.btn.refresh': '새로고침',
  'account.detail.openOrder.table.orderId': '주문 아이디',
  'account.detail.openOrder.table.pair': '거래쌍',
  'account.detail.openOrder.table.side': '사이드',
  'account.detail.openOrder.table.type': '유형',
  'account.detail.openOrder.table.filled': '체결/수량',
  'account.detail.openOrder.table.trigger': '트리거',
  'account.detail.openOrder.table.price': '가격',
  'account.detail.openOrder.table.creationTime': '생성 시간',
  'account.detail.openOrder.table.status': '상태',
  'account.detail.openOrder.table.action': '작업',
  'account.detail.openOrder.table.column.sideBuy': '매수',
  'account.detail.openOrder.table.column.sideSell': '매도',
  'account.detail.openOrder.table.column.typeLimit': '지정가',
  'account.detail.openOrder.table.column.statusPending': '대기 중',
  'account.detail.openOrder.table.column.statusPartial': '부분 체결',
  'account.detail.dialog.confirm.title': '이 주문을 취소하시겠습니까?',
  'account.detail.dialog.confirm.btn': '확인',
  'account.detail.dialog.close.btn': '닫기',
  'account.detail.orderHistory.tab.spot': '현물',
  'account.detail.orderHistory.tab.futures': '선물',
  'account.detail.orderHistory.filter.date': '날짜',
  'account.detail.orderHistory.filter.pair': '거래쌍',
  'account.detail.orderHistory.filter.type': '유형',
  'account.detail.orderHistory.filter.status': '상태',
  'account.detail.orderHistory.inputplace.search': '아이디로 검색',
  'account.detail.orderHistory.btn.search': '검색',
  'account.detail.orderHistory.btn.refresh': '새로고침',
  'account.detail.orderHistory.table.orderId': '주문 아이디',
  'account.detail.orderHistory.table.pair': '거래쌍',
  'account.detail.orderHistory.table.side': '매매 방향',
  'account.detail.orderHistory.table.type': '유형',
  'account.detail.orderHistory.table.filled': '체결/수량',
  'account.detail.orderHistory.table.avgPrice': '평균 가격',
  'account.detail.orderHistory.table.time': '시간',
  'account.detail.orderHistory.table.status': '상태',
  'account.detail.orderHistory.table.action': '작업',
  'account.detail.orderHistory.table.column.sideBuy': '매수',
  'account.detail.orderHistory.table.column.sideSell': '매도',
  'account.detail.orderHistory.table.column.typeLimit': '지정가',
  'account.detail.orderHistory.table.column.statusPending': '대기 중',
  'account.detail.orderHistory.table.column.statusFilled': '체결 완료',
  'account.detail.orderHistory.table.column.statusCanceled': '취소됨',
  'account.detail.tradeHistory.tab.spot': '현물',
  'account.detail.tradeHistory.tab.futures': '선물',
  'account.detail.tradeHistory.filter.date': '날짜',
  'account.detail.tradeHistory.filter.pair': '거래쌍',
  'account.detail.tradeHistory.inputplace.search': '아이디로 검색',
  'account.detail.tradeHistory.btn.search': '검색',
  'account.detail.tradeHistory.btn.refresh': '새로고침',
  'account.detail.tradeHistory.table.tradeId': '거래 아이디',
  'account.detail.tradeHistory.table.buyId': '매수 아이디',
  'account.detail.tradeHistory.table.sellId': '매도 아이디',
  'account.detail.tradeHistory.table.pair': '거래쌍',
  'account.detail.tradeHistory.table.side': '매매 방향',
  'account.detail.tradeHistory.table.qty': '수량',
  'account.detail.tradeHistory.table.price': '가격',
  'account.detail.tradeHistory.table.time': '시간',
  'account.detail.tradeHistory.table.action': '작업',
  'account.detail.tradeHistory.table.column.sideBuy': '매수',
  'account.detail.tradeHistory.table.column.sideSell': '매도',


  'menu.dashboard': '대시보드',
  'menu.account': '계정관리',
  'menu.account.account_list': '계정 리스트',
  'menu.account.kyc': 'KYC',
  'menu.fund': '펀딩 관리',
  'menu.fund.withdrawals': '출금',
  'menu.fund.deposit': '입금',
  'menu.spot': '현물 관리',
  'menu.spot.market': '시장',
  'menu.spot.open_order': '미체결 주문',
  'menu.spot.order_book': '호가창',
  'menu.spot.order_history': '주문 내역',
  'menu.spot.trade_history': '거래 내역',
  'menu.futures': '선물 관리',
  'menu.futures.market': '시장',
  'menu.futures.open_order': '미체결 주문',
  'menu.futures.order_book': '호가창',
  'menu.futures.order_history': '주문 내역',
  'menu.futures.trade_history': '거래 내역',
  'menu.futures.open_positions': '미체결 포지션',
  'menu.futures.positions_history': '포지션 내역',
  'menu.administrator': '관리자 관리',
  'menu.administrator.admin_account':'관리자 계정',
  'menu.administrator.role': '역할',
  'menu.site_setting': '사이트 설정',
  'menu.wallet': '지갑 관리',
  'menu.wallet.register_erc20': 'ERC-20 등록',
  'menu.wallet.cold_wallet': '콜드 윌렛',
  'menu.users': '회원 관리',
  'menu.users.user_information': '회원 정보',
  'menu.users.user_kyc': '회원 KYC 인증',
  'menu.users.user_kycsumsub': '회원 KYC Sumsub 인증',
  'menu.users.user_setting': '회원 설정',
  'menu.users.user_enable_fee_setting': '회원 수수료',
  'menu.users.device_management': '장치 관리',
  'menu.referral': '추천 관리',
  'menu.referral.referral_history': '추천 내역',
  'menu.referral.referral_setting': '추천 설정',
  'menu.exchange_setting': '거래소 설정',
  'menu.exchange_setting.exchange_balances': '거래소 잔액',
  'menu.exchange_setting.transaction_setting': '트랜잭션 설정',
  'menu.exchange_setting.tradding_setting': '거래 설정',
  'menu.exchange_setting.user_withdrawal_setting': '유저 출금 설정',
  'menu.exchange_setting.bank_setting': '은행 설정',
  'menu.exchange_setting.fee': '수수료',
  'menu.transaction_history': '거래 기록',
  'menu.transaction_history.deposit_usd': 'USD입금',
  'menu.transaction_history.withdraw_usd': 'USD출금',
  'menu.transaction_history.deposit': '입금',
  'menu.transaction_history.withdraw': '출금',
  'menu.marketing': '마케팅',
  'menu.marketing.notices': '공지사항',
  'menu.marketing.email_marketing': '이메일 마케팅',
  'menu.reward_center': '보상 센터',
  'menu.cms': '고객센터',
  'menu.cms.notification': 'Notifications',
  'menu.cms.mail_template': 'Mail Temapltes',
  'menu.cms.announcement': 'Announcement',
  'menu.cms.faq': 'FAQ',
  'menu.cms.qna': '1:1문의',
  'menu.partner_admin': '파트너 관리 사이트',
  'status.all': 'All',
  'modal.confirm': '주문 취소',
  'order_type.limit': '지정가',
  'order_type.market': '시장가',
  'order_type.stop_limit': '역지정가',
  'order_type.stop_market': '역시장가',
  'order_type.trailing_stop': '추적 손절매',
  'order_type.liquidation': '청산',
  'order_type.take_profit_market': '시장가로 이익실현',
  'order_type.stop_loss_market': '시장가로 손실제한'
};
