<template>
  <div id="change-password-modal">
    <modal :name="modalName" width="500">
      <template slot="body">
        <div slot="body" class="change-password-modal">
          <div class="icon">
            <span class="icon-moon icon-password"></span>
            <div class="modal-title">
              <span class="title-l"></span>
              <h3>{{ $t('change_password_form.title') }}</h3>
              <span class="title-l"></span>
            </div>
          </div>
          <div v-show="messageRespons">
            <div class="row_form" style="text-align:center">
               <div class="input-group">
                 <div class="success-checkmark">
                  <div class="check-icon">
                    <span class="line-icon line-tip"></span>
                    <span class="line-icon line-long"></span>
                    <div class="icon-circle"></div>
                    <div class="icon-fix"></div>
                  </div>
                </div>
                 <h4 >{{$t('admin.change_password-success')}}</h4>
                 <h4 >{{$t('admin.login_again')}}</h4>
               </div>
            </div>
          </div>
          <div v-show="!messageRespons">
            <div v-show="!isSubmitedOldPW">
              <div class="row_form">
                <div class="input-group">
                  <input
                    :maxlength="maxLengthInput"
                    type="password"
                    @focus="resetErrors"
                    data-vv-validate-on="none"
                    v-validate="'required'"
                    autocomplete="off"
                    name="password"
                    :placeholder="$t('change_password_form.old_password')"
                    data-vv-as="old password"
                    class="form-control"
                    v-model="password"
                    :class="{ error: errors.has('password') }">
                  <span class="invalid-feedback" v-if="errors.has('password')">
                    {{ errors.first('password') }}
                  </span>
                </div>
              </div>
            </div>
            <div v-show="isSubmitedOldPW">
              <div class="row_form" >
                <div class="input-group">
                  <input
                    ref="new_password"
                    :maxlength="maxLengthInput"
                    type="password"
                    autocomplete="off"
                    @focus="resetErrors"
                    data-vv-validate-on="none"
                    v-validate="'required|min:8|max:72|verify_password'"
                    name="new_password"
                    data-vv-as="new password"
                    :placeholder="$t('change_password_form.new_password')"
                    class="form-control"
                    v-model="new_password"
                    :class="{ error: errors.has('new_password') }">
                  <span class="invalid-feedback" v-if="errors.has('new_password')">
                    {{ errors.first('new_password') }}
                  </span>
                </div>
              </div>
              <div class="row_form">
                <div class="input-group">
                  <input
                    :maxlength="maxLengthInput"
                    type="password"
                    autocomplete="off"
                    @focus="resetErrors"
                    data-vv-validate-on="none"
                    v-validate="'required'"
                    data-vv-as="confirm password"
                    name="new_password_confirm"
                    :placeholder="$t('change_password_form.confirm_new_password')"
                    class="form-control"
                    v-model="new_password_confirm"
                    :class="{ error: errors.has('new_password_confirm') }">
                  <span class="invalid-feedback" v-if="errors.has('new_password_confirm')">
                    {{ errors.first('new_password_confirm') }}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div v-show="!messageRespons">
          <div class="form-group btn-group">
            <button name="btnSubmit" :disabled="isSubmitting" class="btn-primary" @click="!isSubmitedOldPW ? confirmPassword() : changePassword()">{{ isSubmitedOldPW ? $t('change_password_form.title') : $t('change_password_form.title_confirm') }}</button>
          </div>
          </div>
          <div v-show="messageRespons">
            <div class="form-group btn-group">
              <button name="btnSubmit" :disabled="isSubmitting" class="btn-primary btn-logoutAD" @click.stop="$refs.logout.submit()">{{ $t('common.action.ok') }}</button>
              <form action="/admin/logout" method="POST" ref=logout>
                  <input type="hidden" name="_token" :value="csrfToken"/>
                </form>
            </div>
          </div>
        </div>
      </template>
    </modal>
  </div>
</template>
<script>

  import Modal from '../components/Modal';
  import rf from '../lib/RequestFactory';
  import Consts from '../common/Const';
   import VeeValidate from 'vee-validate';
  
    window.ChangePassword = {
        show: function(configs){
            window.CommonModal.show('ChangePassword');
        }
    }
  export default {
    components: {
      Modal,
    },
    data() {
      return {
        csrfToken: window.csrf_token,
        isShowing: false,
        isSubmitting: false,
        isSubmitedOldPW: false,
        messageRespons: false,
        modalName:'ChangePassword',
        maxLengthInput: Consts.MAX_LENGTH_INPUT,
        password: '',
        new_password: '',
        new_password_confirm: '',
        isChangedPassword: false,
      }
    },
    watch: {

    },
    methods: {
      show () {
        const modal = $('#change-password-modal');
        modal.find('.close').unbind('click').click(this.configs.onCancel);
        modal.find('.btn-cancel').unbind('click').click(this.configs.onCancel);
        modal.find('.btn-confirm').unbind('click').click(this.configs.onConfirm);
        modal.modal('show');
      },
      hide () {
        $('#change-password-modal').modal('hide');
      },
      resetErrors() {
        if (this.errors.any()) {
          this.errors.clear();
        }
      },

      getParams() {
        return {
          'password': this.password,
          'new_password': this.new_password,
          'new_password_confirm': this.new_password_confirm,
        };
      },

      async confirmPassword() {
        this.isSubmitting = true;
        this.resetErrors();
        let oldPassword = await this.$validator.validate('password');
        if (!oldPassword) {
          this.isSubmitting = false;
          $('button[name="btnSubmit"]').blur();
          return false;
        } else {
          rf.getRequest('AdminRequest').checkOldAdminPassword({password : this.password})
          .then(res => {
            this.isSubmitting = false;
            let check = res.data;
            if(res.data) {
              this.isSubmitedOldPW = true;
            }else {
              this.errors.add({
                field: 'password',
                msg: this.$t('change_password_form.error_password')
              });
            }
          })
          .catch(error => {
            this.isSubmitting = false;
            if (error.response && error.response.status === 422) {
              this.onError(error.response.data.errors || []);
            } else {
              Message.error(window.i18n.t('common.message.network_error'));
            }
          });
        }
      },
      async changePassword() {
        this.isSubmitting = true;
        this.resetErrors();
        let CnewPassword = await this.$validator.validate('new_password');
        let CnewconfPassword = await this.$validator.validate('new_password_confirm');

        let password = this.password;
        let new_password = this.new_password;
        let new_password_confirm = this.new_password_confirm;
        let check = false;
        if(password == new_password) {
          this.errors.add({field: 'new_password_confirm', msg: this.$t('passwords.same_old_password')});
        } else if (new_password !== new_password_confirm) {
          this.errors.add({field: 'new_password_confirm', msg: this.$t('validation.messages.confirmed2',{0:this.$t('common.validate.confirm_password')})});
        } else {
          check = true;
        }
        if (CnewPassword && CnewconfPassword && check) {
          this.isSubmitting = false;
          let params = {
            "password": this.password,
            "newPassword" : this.new_password, 
          }
          rf.getRequest('AdminRequest').changeAdminPassword(params).then(res => {
            this.messageRespons = true;
            this.onChangePassword();
          }).catch(error => {
            this.isSubmitting = false;

            if (error.response.status === 422) {
              this.onError(error.response.data.errors || []);
            } else {
              Message.error(window.i18n.t('common.message.network_error'));
            }

          });
        }else {
            this.isSubmitting = false;
        }
        
      },

      onChangePassword() {
        const modal = $('#change-password-modal');
        $("#change-password-modal .close").remove();
        this.isSubmitting = false;
        setTimeout(() => {
          modal.find('.btn-logoutAD').unbind('click').click();
          window.CommonModal.hide(this.modalName);
        }, 5000);
      },

      onError(errors) {
        this.errors.clear();
        _.forEach(errors, (messages, field) => {
          _.forEach(messages, (message) => {
            if (typeof message !== 'undefined' && typeof message === 'string') {
              this.errors.add(field, message.replace('{attribute}', window.i18n.t('change_password_form.' + field)));
            }
            else {
              this.errors.add(field, message);
            }
          });
        });

      },


      resetData() {
        this.isSubmitting = false;
        this.isChangedPassword = false;
      },
      getEventHandlers() {
        return {
          showCommonModal: this.onModalShowing,
        };
      },
      onModalShowing(modalName) {
        if(modalName == 'ChangePassword') {
          this.isShowing = true;
        }
      },
    },
    created(){
      this.$on('hideCommonModal', (modalName) => {
        if (modalName !== this.modalName){
          return;
        }
        this.isShowing = false;
      });
      VeeValidate.Validator.extend('verify_password', {
        getMessage: field => window.i18n.t("validation.custom.password.regex"),
        validate: value => {
            var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
            return strongRegex.test(value);
        }
      });
    },
    mounted() {
      window.addEventListener('keyup', (event) => {
        if (event.keyCode === 13 && this.modalName == 'ChangePassword' && this.isShowing) { 
          this.submit();
        }
      });
    },
  }
</script>
<style lang="scss">
@import "@/assets/sass/variables";

  #change-password-modal {
    .modal-dialog {      
      // width: 550px !important;
    }
    .modal-content {
      border-radius: 3px;
      height: unset !important;
    }
    .modal-footer {
      padding: 15px !important;
    }
    .input-group .form-control {
    
      font-family: $font-family-roboto;
      border: 0px;
      border-bottom: 1px solid $color-alto;
      padding-left: 0px;
      &:hover, &:active, &:focus {
        border-color: $color-jungle-green;
      }
    }
    .modal-body {
      padding: 0px 55px 5px;
    }

    .input-group .form-control.error {
      border-color: $color-alizarin-crimson;
    }
    .icon-close {
      &:hover {
        background-color: $color_concrete;
        color: $color-grey;
      }
    }

  }
</style>
<style lang="scss" scoped>
@import "@/assets/sass/variables";
  /**
 * Extracted from: SweetAlert
 * Modified by: Istiak Tridip
 */
.success-checkmark {
    width: 80px;
    height: 115px;
    margin: 0 auto;
    
    .check-icon {
        width: 80px;
        height: 80px;
        position: relative;
        border-radius: 50%;
        box-sizing: content-box;
        border: 4px solid #4CAF50;
        
        &::before {
            top: 3px;
            left: -2px;
            width: 30px;
            transform-origin: 100% 50%;
            border-radius: 100px 0 0 100px;
        }
        
        &::after {
            top: 0;
            left: 30px;
            width: 60px;
            transform-origin: 0 50%;
            border-radius: 0 100px 100px 0;
            animation: rotate-circle 4.25s ease-in;
        }
        
        &::before, &::after {
            content: '';
            height: 100px;
            position: absolute;
            background: #FFFFFF;
            transform: rotate(-45deg);
        }
        
        .line-icon {
            height: 5px;
            background-color: #4CAF50;
            display: block;
            border-radius: 2px;
            position: absolute;
            z-index: 10;
            
            &.line-tip {
                top: 46px;
                left: 14px;
                width: 25px;
                transform: rotate(45deg);
                animation: icon-line-tip 0.75s;
            }
            
            &.line-long {
                top: 38px;
                right: 8px;
                width: 47px;
                transform: rotate(-45deg);
                animation: icon-line-long 0.75s;
            }
        }
        
        .icon-circle {
            top: -4px;
            left: -4px;
            z-index: 10;
            width: 80px;
            height: 80px;
            border-radius: 50%;
            position: absolute;
            box-sizing: content-box;
            border: 4px solid rgba(76, 175, 80, .5);
        }
        
        .icon-fix {
            top: 8px;
            width: 5px;
            left: 26px;
            z-index: 1;
            height: 85px;
            position: absolute;
            transform: rotate(-45deg);
            background-color: #FFFFFF;
        }
    }
}

@keyframes rotate-circle {
    0% {
        transform: rotate(-45deg);
    }
    5% {
        transform: rotate(-45deg);
    }
    12% {
        transform: rotate(-405deg);
    }
    100% {
        transform: rotate(-405deg);
    }
}

@keyframes icon-line-tip {
    0% {
        width: 0;
        left: 1px;
        top: 19px;
    }
    54% {
        width: 0;
        left: 1px;
        top: 19px;
    }
    70% {
        width: 50px;
        left: -8px;
        top: 37px;
    }
    84% {
        width: 17px;
        left: 21px;
        top: 48px;
    }
    100% {
        width: 25px;
        left: 14px;
        top: 45px;
    }
}

@keyframes icon-line-long {
    0% {
        width: 0;
        right: 46px;
        top: 54px;
    }
    65% {
        width: 0;
        right: 46px;
        top: 54px;
    }
    84% {
        width: 55px;
        right: 0px;
        top: 35px;
    }
    100% {
        width: 47px;
        right: 8px;
        top: 38px;
    }
}
  //end
  .group_center_option{
    padding-top: 5px;
    text-align: right;
    a{
      font-size: $font-small;
      color: $color-blue-custom;
      font-family: $font-family-roboto;
    }
  }
  .modal-header{
      padding-bottom: 0 !important;
  }
  .modal-title {
    display: flex;
    align-items: center;
    .title-l {
        margin: 10px 0px 10px 0px;
        height: 1px;
        width: 10%;
        background-color: $color_concrete;
    }
    .title-l2{
      width: 10%;
      margin: 10px 0px 10px 0px;
      height: 1px;
      background-color: $color_concrete;
    }
  }
  .modal-headerTitle{
    font-size: $font-big-20;
    line-height: $font-title-size-big;
    width: 80%;
    font-family: $font-family-roboto-medium;
    color: $color-eden;
  }
  .input-group {
      display: grid;
  }
  .form-control{
    border-radius: 0px !important;
  }

  .change-password-modal {
    .icon {
      text-align: center;
      span {
        font-size: 45px;
        color: $color-eden;
      }
      h3 {
        font-size: $font-title-size-small-25;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 30px;
        letter-spacing: normal;
        color: $color-eden;
        margin-bottom: 10px;
        margin-top: 10px;
        width: 80%;
      }
    }
    label {
      font-weight: normal;
      color: $color-black;
      margin-bottom: 0px;
    }
    .note_header {
      color: $color-black;
      line-height: 24px;
      margin-bottom: 16px;
    }
    .row_form {
      margin-top: 15px;
      position: relative;
    }

    .btn-group {
      text-align: center;
      margin-top: 5px;
      margin-bottom: 0px;
      width: 100%;
      .btn-primary {
        background-color: $color-caribbean-green;
        font-family: $font-family-roboto-bold;
        line-height: 25px;
        letter-spacing: 1.08px;
        border-radius: 3px;
        margin-top: $font-medium;
        margin-bottom: $font-big-20;
        border: 1px solid $color-caribbean-green;
        width: 100%;
        height: 40px;
        text-transform: uppercase;
        &:hover, &:active, &:focus {
          background-color: $color-aquamarine;
          border: 1px solid $color-aquamarine;
        }
      }
    }
    .invalid-feedback {
      font-size: $font-small;
      color: $color-denim;
    }
  }
  .input-group-prepend {
    height: 100%;
    span {
      line-height: 44px;
    }
  }
</style>