<template>
  <div class="deposit_pages boxCore mg_bot50" id="kyc_page">
    <div class="pending_transaction">
      <div class="filter_container">
        <span class="title_item">
          {{ $t('kyc.list') }}
        </span>
        <span class="search_box">
          <input type="text" :placeholder="$t('kyc.input_search')" @keyup.enter= "refresh" class="form-control search_input" name="searchKey" v-model="kycSearchKey"/>
          <button class="btn btn-search" @click="refresh">{{ $t('orders.open_order.search') }}</button>
          <button class="btn btn-reset" @click="reset">{{ $t('orders.open_order.reset') }}</button>
        </span>
      </div>
      <div class="datatable">
        <data-table :getData="getListKycs" ref="list_kyc" :column="7" class="scroll" :msgEmptyData="$t('common.datatable.no_data')">
          <th class="cl1 text-left" data-sort-field="created_at">{{ $t('kyc.created_at') }}</th>
          <th class="cl2 text-left" data-sort-field="email">{{ $t('kyc.email') }}</th>
          <th class="cl3 text-left" data-sort-field="full_name">{{ $t('kyc.name') }}</th>
          <th class="cl4 text-left" data-sort-field="country">{{ $t('kyc.country') }}</th>
          <th class="cl5 text-left" data-sort-field="id_number">{{ $t('kyc.id_number') }}</th>
          <th class="cl6 text-left" >{{ $t('kyc.status') }}</th>
          <th class="cl7 text-right"></th>
          <template slot="body" slot-scope="props">
            <tr class="line_kyc">
              <td class="cl1 text-left" >
                <span>{{ convertToLocalTime(props.item.created_at) }}</span>
                <!-- <span>{{ props.item.created_at | dateFormat }}</span>
                <span>{{ props.item.created_at | timeFormat }}</span> -->
              </td>
              <td class="cl2 text-left">
                <div class="item_email_setting">
                  <span class="txt_email_setting">{{ props.item.email }}</span>
                  <span class="tooltip_email_setting">{{ props.item.email }}</span>
                </div>
              </td>
              <td class="cl3 text-left">
                <div class="item_name_setting">
                  <span class="txt_name_setting">{{ props.item.full_name  }}</span>
                  <span class="tooltip_name_setting">{{ props.item.full_name }}</span>
                </div>
              </td>
              <td class="cl4 text-left" >
                {{ props.item.country }}
              </td>
              <td class="cl5 text-left">
                {{ props.item.id_number}}
              </td>
              <td class="cl6 text-left">
                {{ props.item.status }}
              </td>
              <td class="cl7 text-right" >
                <button class="btn btn_detail_user" @click="detailKyc(props.item)">{{ $t('kyc.btn_detail') }}</button>
              </td>
            </tr>
          </template>
        </data-table>
      </div>
    </div>
  </div>
</template>

<script>
  import rf from '../../lib/RequestFactory';
  import moment from "moment";
  import { mapGetters, mapActions } from 'vuex';
  import _ from "lodash";

  export default {
    props: ['items'],
    data() {
      return {
        titlePage: 'Kyc',
        kycSearchKey: this.$route.params.items ? this.$route.params.items : '',
        detail: false,
      }
    },
    
    methods: {
      /*
      ...mapActions([
        'getKycs'
      ]),
      */
      convertToLocalTime(date) {
        var dateFormat = 'YYYY-MM-DD HH:mm:ss';
        var testDateUtc = moment.utc(date);
        var localDate = testDateUtc.local();
        return localDate.format(dateFormat);
      },
      refresh() {
        this.$refs.list_kyc.refresh();
      },
      reset() {
        console.log(1)
        this.kycSearchKey = '';
        this.$refs.list_kyc.refresh();
      },
      getListKycs(params) {
        let meta = {};
        if (this.kycSearchKey) {
          meta.search_key = this.kycSearchKey;
        }else{
          meta.search_key = this.$route.params.items ? this.$route.params.items : "";
        }
        return this.getKycs(Object.assign({}, params, meta));
      },

      detailKyc(row) {
        this.$router.push({
          name: 'kyc-detail',
          params:{
            items: this.kycSearchKey
          },
          query: {
            id: row ? row.id : null,
          },
          props: true
        });
      },

      getKycs(params) {
        return rf.getRequest('AdminRequest').getUserKyc(params);
      }
    },
    mounted() {
      if(this.$route.params.isVerifyKycSuccess === true){
        this.$toastr('success', this.$t('kyc.msg_verified.successfully'));
      }
      if(this.$route.params.isrejectKycSuccess === true){
        this.$toastr('success', this.$t('kyc.msg_rejected.successfully'));
      }
      this.$emit('EVENT_PAGE_CHANGE', this);
      
    }
  }
</script>

<style lang="scss" scoped>
@import "@/assets/sass/variables";
  td{
    word-break: break-all;
  }
  .datatable{
    font-family: $font-family-roboto !important;
  }
  .cl1{
    width: 310px;
  }
  .cl2,.cl3 {
    width: 280px;
  }
  .cl4, .cl6{
    width: 175px;
  }
  .cl5{
    width: 310px;
  }


  .cl7 {
    width: 70px;
  }
  .deposit_pages {
    .item_email_setting {
      display: inline-block;
      float: left;
      position: relative;
      .txt_email_setting {
        display: block;
        max-width: 150px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .tooltip_email_setting {
        position: absolute;
        top: 0px;
        left: 0px;
        line-height: 20px;
        padding: 5px 20px;
        left: 100%;
        background-color: $color_white;
        white-space: nowrap;
        width: auto;
        z-index: 10;
        font-size: $font_root;
        font-weight: 500;
        color: $color_mine_shaft;
        transition: 0.5s;
        display: none;
        box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.4);
        &:after {
          right: 100%;
          top: 50%;
          border: solid transparent;
          content: " ";
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
          border-color: rgba(136, 183, 213, 0);
          border-right-color: $color_white;
          border-width: 5px;
          margin-top: -5px;
        }
      }
      &:hover {
        .tooltip_email_setting {
          display: block;
          transition: 0.5s;
        }
      }
    }
    .item_name_setting {
      display: inline;
      float: left;
      position: relative;
      .txt_name_setting {
        display: block;
        max-width: 280px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .tooltip_name_setting {
        position: absolute;
        top: 0px;
        left: 0px;
        line-height: 20px;
        padding: 5px 20px;
        left: 100%;
        background-color: $color_white;
        white-space: nowrap;
        width: auto;
        z-index: 10;
        font-size: $font_root;
        font-weight: 500;
        color: $color_mine_shaft;
        transition: 0.5s;
        display: none;
        box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.4);
        &:after {
          right: 100%;
          top: 50%;
          border: solid transparent;
          content: " ";
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
          border-color: rgba(136, 183, 213, 0);
          border-right-color: $color_white;
          border-width: 5px;
          margin-top: -5px;
        }
      }
      &:hover {
        .tooltip_name_setting {
          display: block;
          transition: 0.5s;
        }
      }
    }

    .filter_container {
      margin: 12px 0px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .title_item {
        color: $color_mine_shaft;
        font-size: $font_big_20;
        font-weight: 500;
        line-height: 28px;
        float: left;
      }
      .search_box {
        display: flex;
        gap: 5px;
        .search_input {
          width: 200px;
          background-color: transparent;
          height: 30px;
          border: 1px solid $color_alto;
          padding: 4px 15px;
          line-height: 20px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          font-size: $font-small;
        }
        .btn-search, .btn-reset {
          margin: 0px;
          font-family: $font-family-roboto-bold;
          font-size: $font-smaller;
          border-radius: 3px;
          border: 1px solid $color-caribbean-green;
          width: 80px;
          height: 30px;
          color: $color_white;
          text-transform: uppercase;
          background-color: $color-caribbean-green;
          font-weight: 600;
          &:hover, &:active {
            border-color: $color-aquamarine;
            background-color: $color-aquamarine;
          }
        }
      }
    }
    .btn_detail_user {
      float: right;
      background-color: $color-caribbean-green;
      text-transform: uppercase;
      width: 65px;
      height: 25px;
      line-height: 19px;
      padding: 0px 9px;
      text-align: center;
      font-size: $font-smaller;
      font-weight: 600;
      color: $color_white;
      border: 1px solid $color-caribbean-green;
      border-radius: 3px;
      margin-left: 15px;
      transition: 0.5s;
      &:hover {
        background-color: $color-aquamarine;
        border-color: $color-aquamarine;
        color: $color_white;
        transition: 0.5s;
      }
    }
  }

  @media only screen and (min-width: 1399px) {
     .deposit_pages .item_email_setting .txt_email_setting {
        max-width: 250px;
     }
  }
  
</style>

<style lang="scss">
@import "@/assets/sass/variables";
  #kyc_page {
    padding-bottom: 50px;
    .sc_search_select {
      .group_search_select {
        .button_drop_search {
          width: 55px;
          height: 30px !important;
          padding: 5px 10px 5px 10px !important;
          border-radius: 3px;
          margin-right: 20px;
          font-size: $font-small;
          font-family: $font-family-roboto;
          i.icon-arrow1 {
            margin-left: 5px;
            color: $color_dove_gray;
          }
          &:hover {
            border-color: $color-jungle-green;
            i.icon-arrow1 {
              color: $color-jungle-green;
            }
          }
          
        }
        .box_list_search_select {
          .list_search_select {
            li {
              font-family: $font-family-roboto;
              font-size: $font-small;
              &:hover, &:active, &:focus {
                color: $color-jungle-green;
              }
            }
          }
        }
      }
    }
    table thead th {
      padding: 5px 25px 4px 15px !important;
    }
  }
</style>