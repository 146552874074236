<template>
  <div class="multi-select-dropdown" :class="disabled" v-click-outside="turnOffSelect">
    <div class="selected-items" @click="toggleDropdown">
      <div class="label" :class="{ active: isOpen || searchQuery || selectedItems.length }">{{ customLabel }}</div>
      <div class="selected-item" v-for="item in selectedItems" :key="item.id">
        {{ item.name }}
        <span class="remove" @click.stop="removeItem(item)">x</span>
      </div>
      <input type="text" v-model="searchQuery" @click.stop="openDropdown" @input="filterItems" />
      <span class="arrow" :class="{ 'arrow-up': isOpen, 'arrow-down': !isOpen }">
        <i class="icon-arrow1"></i>
      </span>
    </div>
    <div class="dropdown" v-if="isOpen">
      <div class="dropdown-item" v-for="item in filteredItems" :key="item.id" @click="selectItem(item)"
        :class="{ selected: !!selectedItems.find(element => element.id === item.id) }">
        {{ item.name }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    customLabel: {
      type: String,
      default: ''
    },
    options: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Array,
      default: () => []
    },
    onChange: {
      type: Function,
      default: () => { }
    },
    onFocus: {
      type: Function,
      default: () => { }
    }
  },
  watch: {
    value: function (newVal) {
      this.selectedItems = newVal;
    },
    options: function (newVal) {
      this.items = newVal;
      this.filteredItems = newVal;
    }
  },
  data() {
    return {
      isOpen: false,
      selectedItems: [],
      searchQuery: '',
      items: [
        { id: 1, name: 'Option 1' },
        { id: 2, name: 'Option 2' },
        { id: 3, name: 'Option 3' },
      ],
      filteredItems: [],
    };
  },
  methods: {
    toggleDropdown() {
      this.isOpen = !this.isOpen;
      this.onFocus();
    },
    openDropdown() {
      this.isOpen = true;
    },
    turnOffSelect() {
      this.isOpen = false;
    },
    selectItem(item) {
      if (!this.selectedItems.find(element => item.id === element.id)) {
        this.selectedItems.push(item);
        this.onChange(this.selectedItems);
      }
    },
    removeItem(item) {
      this.selectedItems = this.selectedItems.filter(i => i.id != item.id);
      this.onChange(this.selectedItems);
    },
    filterItems() {
      this.filteredItems = this.items.filter(item =>
        item.name.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
    },
  },
  mounted() {
    this.items = this.options;
    this.filterItems();
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/variables";

.multi-select-dropdown {
  width: 100%;
  height: 100%;
  background-color: white;
  position: relative;
}

.selected-items {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border-radius: 10px;
  border: 1px solid $color-bright-gray;
  padding: 8px 12px;
  padding-right: 34px;
  cursor: pointer;
  position: relative;
  height: 100%;
}

.label {
  position: absolute;
  left: 10px;
  font-size: 14px;
  z-index: 10;
  color: $color-gray-dark !important;
  transition: all 0.3s ease;
  font-weight: 400;
  font-family: Arial, Helvetica, sans-serif;
  color: $color_alto_gray;
}

.label.active {
  top: -10px;
  left: 5px;
  background-color: white;
  color: #666;
}

.selected-item {
  background: #e4e4e4;
  border-radius: 3px;
  padding: 3px 5px;
  margin: 2px;
  font-size: small;
  z-index: 10;
}

.remove {
  margin-left: 5px;
  color: red;
  cursor: pointer;
}

.dropdown {
  position: absolute;
  width: 100%;
  border-radius: 8px;
  max-height: 150px;
  overflow-y: auto;
  background: #fff;
  z-index: 10;
  padding: 8px 0;
  top: 45px;
  box-shadow: 0px 3px 12px rgba(49, 49, 49, 0.51);

  &::after {
    display: none;
  }
}

.dropdown-item {
  padding: 5px 15px;
  cursor: pointer;
}

.dropdown-item:hover {
  background: #f0f0f0;
}

.selected {
  background: #e0e0e0;
}

input[type=text] {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  position: absolute;

  &:hover {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    flex-grow: 1;
  }
}

.arrow {
  position: absolute;
  display: inline-block;
  right: 12px;
  height: 14px;
  width: 14px;
  border-left: transparent;
  border-right: transparent;
  border-top: transparent;
  border-bottom: transparent;
  transition: transform 0.3s ease;

  i.icon-arrow1 {
    color: $color_dove_gray;
  }
}

.arrow-up {
  transform: rotate(180deg);
}

.arrow-down {
  transform: rotate(0deg);
}
</style>
