<template>
  <div id="app">
    <div class="admin_page" v-if="token">
      <div class="wrapper">
        <navi-bar />
        <slider :slide-menu-items="slideMenuItems" />
        <content-wrap />
        <modal />
        <confirmation-modal></confirmation-modal>
      </div>
    </div>
    <Login v-else />
  </div>
</template>

<script>
import Login from '@/pages/Login'
import NaviBar from '@/common/NaviBar'
import Slider from '@/common/Slider'
import ContentWrap from '@/common/ContentWrap'
import Modal from './components/Modal'
import ConfirmationModal from './common/ConfirmationModal'
import Const from './common/Const'

import 'ckeditor5/ckeditor5.css';

export default {
  components: {
    Login,
    NaviBar,
    Slider,
    ContentWrap,
    Modal,
    ConfirmationModal,
  },
  data() {
    return {
      token: localStorage.getItem('token'),
      slideMenuItems: Const.MENU,
    }
  },
}
</script>
<style lang="scss">
@import '@/assets/sass/main.scss';
</style>

<style lang="scss" scoped>
.admin_page {
  height: 100%;
}

.wrapper {
  background-color: #ffffff;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #ddd;
}

::-webkit-scrollbar-thumb:hover {
  background: #ccc;
}
</style>
