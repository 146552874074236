<template>
  <div>
    <div class="page960">
      <div class="section">
        <div class="form-permission-container">
          <div id="permission">
            <div class="permission-form-group">
              <label class="permission-form-label">Name</label>
              <div class="clearfix"></div>
              <input
                  v-model="params.name"
                  @focus="resetError"
                  :placeholder="$t('Name')"
                  :class="{ error: errors.has('name') }"
                  name="name"
                  data-vv-validate-on="none"
                  v-validate="'required'"
                  type="text"
                  class="permission-form-value">
              <div class="clearfix"></div>
              <div class="error-box">
                <span v-show="errors.has('name')" class="f-left is-error">{{ errors.first('name') }}</span>
              </div>
            </div>

            <div class="permission-form-group">
                <label class="permission-form-label">{{ $t('common.placeholders.email') }}</label>
                <div class="clearfix"></div>
                <input
                  v-model="params.email"
                  @focus="resetError"
                  :placeholder="$t('common.placeholders.email')"
                  :class="{ error: errors.has('email') }"
                  name="email"
                  data-vv-validate-on="none"
                  v-validate="'required|email'"
                  type="text"
                  class="permission-form-value">
                <div class="clearfix"></div>
                <div class="error-box">
                  <span v-show="errors.has('email')" class="f-left is-error">{{ errors.first('email') }}</span>
                </div>
            </div>

            <div class="permission-form-group">
              <label class="permission-form-label">{{ $t('common.placeholders.password') }}:</label>
              <div class="clearfix"></div>
              <input
                v-model="params.password" 
                @focus="resetError"
                :placeholder="$t('common.placeholders.password')"
                :class="{ 'input-error': errors.has('password') }"
                name="password"
                data-vv-validate-on="none"
                v-validate="'required'"
                type="password"
                class="permission-form-value" />
              <div class="clearfix"></div>
              <div class="error-box">
                <span v-show="errors.has('password')" class="f-left is-error">{{ errors.first('password') }}</span>
              </div>
            </div>

            <div class="permission-form-group">
              <label class="permission-form-label">Role</label>
              <div class="list_checkRole clearfix">
                <div class="checkbox" v-for="item in fullPermissions" :key="item.index">
                  <label><input type="checkbox" v-model="item.isChecked">{{ item.name }}</label>
                </div>
              </div>
              <div class="error-box">
                <span v-show="errors.has('permissions')" class="f-left is-error">{{ errors.first('permissions') }}</span>
              </div>
            </div>

            <div class="submit">
              <button class="btn btn_cancel" @click="cancel()">{{ $t('notice.cancel') }}</button>
              <button class="btn btn_submit" @click="submit()">{{ $t('notice.submit') }}</button>
            </div>
          </div>
        </div>
      </div>
    </div
>  </div>
</template>

<script>
  import rf from '../../lib/RequestFactory';
  import Const from '../../common/Const';
  import RemoveErrorsMixin from '../../common/RemoveErrorsMixin';

  export default {
    mixins: [RemoveErrorsMixin],
    data() {
      return {
        AdminId: this.$route.query.id,
        titlePage: this.AdminId ? window.i18n.t('Edit Administrator') : window.i18n.t('Create New Administrator'),
        params: {
          name: '',
          email: '',
          password: '',
          permissions: [],
        },

        administratorId: this.$route.query.id || null,
        fullPermissions: this.getAllPermissions(),
      };
    },
    watch: {
      'params.permissions'(newValue, oldValue) {
        const permissions = window._.map(newValue, item => item.name);
        window._.each(this.fullPermissions, item => {
          item.isChecked = window._.includes(permissions, item.value);
        });
      }
    },
    methods: {
        getAllPermissions() {
          return window._.map(Const.MENU, item => {
            return { value: item.name, name: item.name, isChecked: false };
          });
        },

        async submit(id) {
          this.resetError();
          await this.$validator.validate('name');
          await this.$validator.validate('email');
          await this.$validator.validate('password');

          this.params.permissions = window._.chain(this.fullPermissions)
                .filter(item => item.isChecked)
                .map(item => item.value)
                .value();
          
          if (this.params.permissions.length === 0) {
            this.errors.items.push (
              {
                field: "permissions",
                msg: window.i18n.t('permission.role.required'),
                scope: null,
                vmId: this.errors.vmId,
                rule: "required"
              }
            )
          }

          if (this.errors.any()) {
            return;
          }

          window.ConfirmationModal.show({
            type: 'confirm',
            title: this.$t('email.submit_data'),
            btnCancelLabel: window.i18n.t('common.action.cancel'),
            btnConfirmLabel: window.i18n.t('common.action.confirm'),
            onConfirm: () => {
              
              this.requestHandler();
            },
            onCancel: () => {}
          });
      },

      requestHandler() {
        this.getRequest().then(res => {
            this.$router.push({ name: 'Permissions' });
        }).catch(error => {
          if (!error.response) {
            this.showError(window.i18n.t('common.message.network_error'));
            return;
          }
          this.convertRemoteErrors(error);
          if (this.errors.has('permissions')) {}
          //  this.$toastr('error', this.errors.first('permissions'));
        });
      },

      getRequest() {
        if (this.administratorId) {
          this.params = Object.assign(this.params, { id: this.administratorId });
          return rf.getRequest('AdminRequest').updateAdministrator(this.params);
        }
        return rf.getRequest('AdminRequest').createAdministrator(this.params);
      },

      cancel() {
        this.$router.push({ name: 'Permissions' });
      },
    },

    mounted() {
      this.$emit('EVENT_PAGE_CHANGE', this);
      if (!this.administratorId) {
        return;
      }
      rf.getRequest('AdminRequest').getAdministratorById(this.administratorId).then(res => {
        this.params = Object.assign(this.params, res.data, { password: '$2y$10$/Bdv3NjVwN2sg5A3Hitxt.c6KEvw/8oVyeAfNviQ'});
      });
    }
  }
</script>

<style lang="scss" scoped>
@import "@/assets/sass/variables";

  .form-permission-container {

      #permission {
        margin-top: 20px;

        .permission-form-group {
          margin-bottom: 15px;
          
          input {  
            border-radius: 3px;
            border: solid 1px $color_alto;
            background-color: $color-white-smoke;
          }

          .permission-form-label {
            color: $color_dove_gray;
            font-size: $font_root;
            font-weight: 500;
            line-height: 17px;
            margin-bottom: 5px;
          }
          .permission-form-value {
            width: 360px;
            max-width: 100%;
            height: 35px;
            padding: 8px 15px;
            line-height: 20px;
            font-size: $font_root;
            font-weight: 500;
            color: $color_mine_shaft;
            border: 1px solid $color_alto;
          }
          .list_checkRole {
            width: 360px;
            max-width: 100%;
            border: 1px solid $color_alto;
            padding: 15px;

            .checkbox {
              line-height: 20px;
              font-size: $font_root;
              font-weight: 500;
              color: $color_mine_shaft;
              margin-top: 10px;
              margin-bottom: 10px;
            }
          }
        }
      }
  }
  .error-box {
    color: $color_red_text;
  }
  .btn_cancel,
  .btn_submit {
    line-height: 20px;
    height: 35px;
    padding: 7px 35px;
    min-width: 90px;
    border-radius: 3px;
    font-size: $font_root;
    font-weight: 600;
    letter-spacing: 0.9px;
    text-align: center;
    text-transform: uppercase;
    font-size: 12px;
    font-family: $font-family-roboto-bold;
    color: $color-white;
    &:hover, &:active  {
      background-color: $color-aquamarine;
      border-color: $color-aquamarine;
      color: $color-white;
    }
  }
  .btn_cancel {
    background-color: $color-caribbean-green;
    border: 1px solid $color-caribbean-green;
    margin-right: 15px;
  }
  .btn_submit {
    background-color: $color-caribbean-green;
    border: 1px solid $color-caribbean-green;
  }
   
  .tit_modal_confir {
    margin-bottom: 10px;
    margin-top: 10px;
    line-height: 25px;
    text-align: center;
    font-size: $font_big_20;
    color: $color_mine_shaft;
  }
</style>
