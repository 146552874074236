<template>
  <div id="base_bitmex_account" class="boxCore">
    <div class="transaction">
      <div class="clearfix clearfix-20"></div>
     <!--  <div class="filter_container clearfix">
       <span class="title_item">{{ $t('account_setting.payout_currency') }}</span>
     </div> -->

      <div class="clearfix"></div>
      <div class="content_setting clearfix">
        <div class="left_content_setting">
          <div class="group_input_left clearfix">
            <label>{{ $t('account_setting.key_id')  }}</label>
            <div class= "input_view">
                <input  @focus="magic_flag1 = true" @blur="magic_flag1 = false" 
                  v-bind:class="{ able: isEdit, 'able-active': !isEdit, 'error': errors.has('key')}" 
                  :data-vv-as="$t('account_setting.default_lock_period')" v-model="settings.apiKey" :disabled="isEdit" />
            </div>
            
          </div>
          <div class="clearfix"></div>
          <div class="group_input_left clearfix">
            <label>{{ $t('account_setting.ker_secret')  }}</label>
            <div class= "input_view">
                <input @focus="magic_flag1 = true" @blur="magic_flag1 = false" 
                  v-bind:class="{ able: isEdit, 'able-active': !isEdit,'error': errors.has('key')}" v-model="settings.apiSecret" :disabled="isEdit" />
            </div>
            <div class="error-box">
              <span v-show="errors.has('key')" class="f-left is-error">{{ errors.first('key') }}</span>
            </div>
          </div>
           <div class="loading-wrap" v-if="isVerifyAddress">
            <div class="loading-content">
              <vue-loading :size="{ width: '20px', height: '20px' }" type="spin" class="loading-icon" color="#0f8ddb"/>
            </div>
          </div>
          <div class="clearfix"></div>
          <!-- <div class="group_input_left clearfix">
            <div class="clearfix clearfix-15"></div>
            <div class="form-check group_airdrop_payout clearfix">
              <label class="form-check-label" :disabled="isEdit" v-bind:class="{ active: coinActive.cross }">
                <span class="icon_check_label"></span>
                <input type="radio" class="form-check-input icon_check_payout" name="optradio" :checked="coinActive.cross" :disabled="isEdit" v-on:change="changeStatusRadio('cross')">
                <span class="txt-inactive">{{$t('bitmex.is_cross')}}</span>
              </label>
            </div>
            <div class="clearfix"></div>
            <div class="clearfix clearfix-15"></div>
            <div class="form-check group_airdrop_payout clearfix">
              <div class="group_input_ardrop_payout">
                <currency-input class="input_airdrop_payout" :precision="8" type="text" :disabled="isEdit || !coinActive.leverage" v-model="coinAmount.eth"/>
              </div>
              <label class="form-check-label" :disabled="isEdit" v-bind:class="{ active: coinActive.leverage }">
                <span class="icon_check_label"></span>
                <input type="radio" class="form-check-input icon_check_payout" name="optradio" :checked="coinActive.leverage" :disabled="isEdit" v-on:change="changeStatusRadio('leverage')">
                <span class="txt-inactive">{{$t('bitmex.isleverage')}}</span>
              </label>
            </div>
            <div class="clearfix"></div>
            <div class="error-box">
              <span v-show="errors.has('payout_amount_eth')" class="f-left is-error">{{ errors.first('payout_amount_eth') }}</span>
            </div>
            <div class="clearfix clearfix-15"></div>
          </div> -->
          <div class="clearfix"></div>
          <div class="group_input_left clearfix">
            <label>{{ $t('account_setting.key_name')  }}</label>
            <div class= "input_view">
                <input  disabled="disabled" v-model="settings.key_name" name="">
            </div>
          </div>
          <div class="group_input_left clearfix">
            <label>{{ $t('account_setting.email')  }}</label>
            <div class="input_view" id ="input_time">
              <input  disabled="disabled" v-model="settings.email" name="">
            </div>
          </div>
          <div class="clearfix"></div>
          <div class="group_input_left clearfix">
            <label>{{ $t('account_setting.balance')  }}</label>
            <div class= "input_view">
                <input disabled="disabled" v-model="settings.balance" name="" :precision="8">
                <span class="unit" v-bind:class="{ 'abled': isEdit, 'abled-active': !isEdit}">{{$t('currency.btc.shortname')}}</span>
            </div>
          </div>
          <div class="group_input_left clearfix">
            <label>{{ $t('account_setting.available_balance')  }}</label>
            <div class= "input_view">
                <input disabled="disabled" v-model="settings.available_balance" name="" :precision="8">
                <span class="unit" v-bind:class="{ 'abled': isEdit, 'abled-active': !isEdit}">{{$t('currency.btc.shortname')}}</span>
            </div>
          </div>
          <div class="clearfix"></div>
        </div>
        <div class="clearfix"></div>
        <div class="group_btn_edit">
          <button class="btn btn-edit" v-if="isActive && isEdit" @click="editSetting">{{ $t('sales_point.edit') }}</button>
          <div v-else>
            <button class="btn btn-edit" v-if="isActive && !isEdit" @click="resetSetting">{{ $t('sales_point.return') }}</button>
            <button class="btn btn-edit" v-if="isActive && !isEdit" @click="submitAccount">{{ $t('sales_point.submit') }}</button>
          </div>
        </div>
      </div>

      <div class="clearfix clearfix-50"></div>
      <div class="line_setting"></div>
      <div class="clearfix clearfix-20"></div>
      
      <div class="content_table_aridrop" id ="table_airdrop_content">
        <div class="head_table_aridrop clearfix">
          <span class="title_table_aridrop">{{ 'Positions' }}</span>
        </div>
        <div class="clearfix clearfix-15"></div>
        <div class="datatable">
          <data-table-4 :getData="getData" ref="datatable" :limit="10" :column="20" class="scroll">
            
            <th class="non-sort"></th>
            <th class="text-left" >{{ $t('account_setting.symbol') }} </th>
            <th class="text-left" >{{ $t('account_setting.leverage')}}</th>
            <th class="text-left" >{{ $t('account_setting.crossMargin')}}</th>
            <th class="text-left" >{{ $t('account_setting.currentQty')}}</th>
            <th class="text-left non-sort1" >{{ 'Entry Price' }}</th>
            <th class="text-left" >{{ $t('account_setting.markPrice')}}</th>
            <th class="text-left" >{{ $t('account_setting.liquidPrice')}}</th>
            <th class="text-left" >{{ $t('account_setting.bankruptPrice')}}</th>
            <th class="text-left" >{{ $t('account_setting.unrealisedPnl')}}</th>
            <th class="text-left" >{{ $t('account_setting.realisedPnl')}}</th>
            <th class="text-left" >{{ $t('account_setting.initMargin')}}</th>
            <th class="text-right" >{{ $t('account_setting.maintMargin')}}</th>

            <template slot="body" slot-scope="props">
              <tr>
                <td :key='randomInteger(index, 10000)' class="text-left">
                  <button v-if="!getEditable(props.item)" class="btn btn-edit-table" @click="editColumn(props.item)"><i class="icon-edit"></i></button>
                  <button v-if="getEditable(props.item)" class="btn btn-edit-table" @click="resetColumn(props.item)"><i class="icon-reload3"></i></button>
                  <button v-if="getEditable(props.item)" class="btn btn-save-table" @click="saveColumn(props.item)"><i class="icon-save"></i></button>
                </td>
                <td class="text-left">
                    <span class="txt_email_user">{{ props.item.symbol }}</span>
                </td>
                <td :key='randomInteger(index, 10000)' class="text-left">
                  <span v-if="!getEditable(props.item)" class="txt_email_user">{{ props.item.leverage | formatMarginNumber(2, '0') }}</span>
                  <currency-input :maxLengthCus='4' :precision="2" class="input_table w_60px" v-else v-model="props.item.newLeverage" :disabled="props.item.isDisableLeverage"
                    v-bind:class="{ 'able': props.item.isDisableLeverage, 'able-active': !props.item.isDisableLeverage}"/>
                </td>
                <td :key='randomInteger(index, 10000)' class="text-left">
                  <span v-if="!getEditable(props.item)" class="txt_email_user">{{ props.item.crossMargin ? 'Cross' : 'Isolated' }}</span>
                  <input v-else type="checkbox" v-model="props.item.newCrossMargin" @click="handleClickCheckBox(props.item)">
                </td>
                <td class="text-left">
                    <span class="txt_email_user">{{ props.item.currentQty }}</span>
                </td>
                <td class="text-left">
                    <span class="txt_email_user">{{ props.item.avgEntryPrice }}</span>
                </td>
                <td class="text-left">
                    <span class="txt_email_user">{{ props.item.markPrice }}</span>
                </td>
                <td class="text-left">
                    <span class="txt_email_user">{{ props.item.liquidationPrice }}</span>
                </td>
                <td class="text-left">
                    <span class="txt_email_user">{{ props.item.bankruptPrice }}</span>
                </td>
                <td class="text-left">
                    <span class="txt_email_user">{{ (props.item.unrealisedPnl * 0.00000001) | formatMarginNumber(8, '0') }}</span>
                </td>
                <td class="text-left">
                    <span class="txt_email_user">{{ (props.item.realisedPnl * 0.00000001) | formatMarginNumber(8, '0') }}</span>
                </td>
                 <td class="text-left">
                    <span class="txt_email_user">{{ (props.item.initMargin * 0.00000001) | formatCurrencyAmount(8, '0') }}</span>
                </td>
                <td class="text-right">
                    <span class="txt_email_user">{{ (props.item.maintMargin * 0.00000001) | formatCurrencyAmount(8, '0') }}</span>
                </td>
              
              </tr>
            </template>
          </data-table-4>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
  import rf from '../../lib/RequestFactory';
  import BigNumber from 'bignumber.js';
  import DatePicker from "vuejs-datepicker";
  import Modal from "../../components/Modal";
  import moment from "moment";
  import COMMON_CONST from "../../common/Const";
  import SelectBox from '../../components/SelectBox';
  import TimePicker from 'vue2-timepicker';
  import CurrencyInput from '../../components/CurrencyInput';
  import momentTz from "moment-timezone";
  import DataTable4 from "../../components/DataTable4";
  export default {
    components: {
      DatePicker,
      SelectBox,
      Modal,
      TimePicker,
      CurrencyInput,
      DataTable4,
    },
    props: {
    },
    data() {
      return {
        titlePage: window.i18n.t("bitmex.account"),
        currency: '',
        time: {
          HH: '0',
          mm: '0',
        },
        ACTIVE: 'Active',
        INACTIVE: 'Inactive',
        amount: {name: 'AML'},
        markets: Object.values(COMMON_CONST.AIRDROP_LIST_COINS),
        MARKETS_AMOUNT: ['AML', 'ETH', 'BTC'],
        isActive: true,
        isActiveDividendWallet : false,
        isVerifyAddress: false,
        settings: {
          'apiKey' :  "",
          'apiSecret' :  "",
        },
        oldSetting: {},
        search : '',
        newEmail:'',
        newPeriod: '',
        newPercent: '',
        itemUserOld: {},
        currentUser: false,
        itemUser: null,
        isEdit: true,
        newUser: {},
        hasError: false,
        showErrorModal: false,
        coinAmount: {
          'btc' : 0,
          'eth' : 0,
          'amal': 0,
        },
        oldCoinAmount : {},
        btc_total_paid: 0,
        amal_total_paid: 0,
        eth_total_paid: 0,
        coinActive: {
          'cross': false,
          'leverage': false,
        },
        crossMargin: [{id: 0, name: 'Cross'}, {id: 1, name: 'Isolated'}],
        checkTime: true,
        oldActive:{},
        maxLengthInput: COMMON_CONST.MAX_LENGTH_INPUT,
        timezone: 0,
        index: 0,
        dataTable: [],
        isEditable: false,
      }
    },
    watch: {
      "settings.apiKey" : function (newValue ,oldValue) {
        if (!newValue || !this.settings.apiSecret || newValue == "") return;
        this.isVerifyAddress = true;
        // this.resetSettings();
        this.getSettings(false); 
      },
      "settings.apiSecret"(newValue ,oldValue) {
        if (!newValue || !this.settings.apiKey || newValue == "") return;
        this.isVerifyAddress = true;
        // this.resetSettings();
        this.getSettings(false);
      },

    }, 
    methods: {
      getEditable (item) {
        console.log(_.find(this.dataTable, ['symbol', item.symbol]).isEdit)
        return _.find(this.dataTable, ['symbol', item.symbol]).isEdit;
      },
      setEditable (item, isEdit = false) {
        this.isEditable = isEdit;
        this.dataTable = _.map(this.dataTable, (record) => {
          record.isEdit = record.symbol == item.symbol ? this.isEditable : record.isEdit;
          return record;
        });
      },
      randomInteger(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
      },
      handleClickCheckBox (item) {
        item.newCrossMargin = !item.newCrossMargin;
        item.isDisableLeverage = item.newCrossMargin;
        this.index++;
      },
      editColumn(item) {
        item.newLeverage = item.leverage;
        item.newCrossMargin = item.crossMargin;
        item.isDisableLeverage = item.newCrossMargin;
        this.setEditable(item, true);
        // item.isEdit = true;
        this.index++;
      },
      resetColumn(item) {
        this.setEditable(item);
        // item.isEdit = false;
        this.index++;
      },
      saveColumn(item) {
        if (!this.validateTable(item)) return;
        let leverage = 0;
        if (!item.newCrossMargin) {
          leverage = item.newLeverage;
        }
        rf.getRequest('BitmexAccountRequest').setLeverage({ leverage, symbol: item.symbol }).then(res => {
          if (res && res.data) {
            if (typeof res.data === 'object') {
              item.leverage = res.data.leverage;
              item.crossMargin = res.data.crossMargin;
              this.$refs.datatable.refresh();
              this.$toastr('success', this.$t("Updated successfully!"));
              // item.isEdit = false;
              this.setEditable(item);
              this.index++;
            } else {
              let strError = res.data.replace(' (HTTPError) ', '');
              this.$toastr('error', strError.slice(-1) == '.' ? strError : (strError + '.'));
            }
          }
        }).catch(e => {
          this.$toastr('error', 'Bitmex error!');
          // item.isEdit = false;
          this.setEditable(item);
          this.index++;
        });
      },
      changeStatusRadio(value) {
        this.coinActive.cross = false;
        this.coinActive.leverage = false;
        this.coinActive[value] = true;
        this.currency = value;
      },
      validateTable (item) {
        this.errors.clear();

        if (!item.newCrossMargin && !item.newLeverage) {
          this.$toastr('error', 'The leverage field is required.');
          this.errors.add({field: 'default', msg: 'error'});
        }

        if (!item.newCrossMargin && item.newLeverage < 1) {
          this.$toastr('error', 'Leverage must not be smaller than 1.');
          this.errors.add({field: 'default', msg: 'error'});
        }

        if (this.errors.count() > 0) {
          return false;
        }

        this.errors.clear();

        return true; 

      },
      validate() {
        this.errors.clear();

        if (!this.settings.apiKey) {
          this.$toastr('error', this.$t('common.error.required', { field: 'key id' }));
          this.errors.add({field: 'default', msg: 'error'});
        }

        if (!this.settings.apiSecret) {
          this.$toastr('error', this.$t('common.error.required', { field: 'key secret' }));
          this.errors.add({field: 'default', msg: 'error'});
        }

        if (this.errors.count() > 0) {
          return false;
        }

        return true;   
      },
      editSetting() {
        this.isEdit = false;
      },

      resetSetting() {
        this.settings = {
          ...this.oldSetting
        };
        // this.getSettings();
        this.errors.clear();
        this.isEdit = true;
        
      },

      getKey() {
        rf.getRequest('BitmexAccountRequest').getKey().then(res => {
          this.settings.apiKey = res.data ? res.data.key_id : '';
          this.settings.apiSecret = res.data ? res.data.key_secret : '';
          this.getSettings(true);
        })
      }, 
      getData(params) {
        let default_params = {
          'apiKey' : this.settings.apiKey,
          'apiSecret' : this.settings.apiSecret
        };
        return rf.getRequest('BitmexAccountRequest').getPosition(default_params).then(res => {
          if (typeof res.data === 'object') {
            let keepEdit = false;
            let temp = { ...this.dataTable };
            if (this.dataTable.length > 0) {
              keepEdit = true;
            }
            this.dataTable = _.map(res.data, (record) => {
              if (keepEdit) record.isEdit = _.find(temp, ['symbol', item.symbol]).isEdit;
              else record.isEdit = false;
              return record;
            });
            return res;
          } else {
            return [];
          }
        })
      },
      submitAccount() {
        let params = {
          'email' : this.settings.email,
          'key_id' : this.settings.apiKey,
          'key_secret' : this.settings.apiSecret,
          'key_name' : this.settings.key_name,
          'account_id' : this.settings.account_id
        }
        if (!this.validate()) return;
        rf.getRequest('BitmexAccountRequest').submitAccount(params).then(res => {
          this.$toastr('success', this.$t("address.updated_success"));
          // this.oldSetting.apiKey = this.settings.apiKey;
          // this.oldSetting.apiSecret = this.settings.apiSecret;
          this.oldSetting = {... this.settings};
          this.isEdit = true;
        }).catch(e => {
          this.$toastr('error', 'Error: Unauthenticated!');
          this.settings = {
            ...this.oldSetting
          };
          this.isEdit = false;
        });
      },
      resetSettings() {
        this.settings.account_id = '';
        this.settings.key_name = '';
        this.settings.balance = '';
        this.settings.available_balance = '';
        this.settings.email = '';
      },
      applySettings(res) {
        this.settings.account_id = res.data.account_id;
        this.settings.key_name = res.data.key_name;
        this.settings.balance = res.data.balance * 0.00000001;
        this.settings.available_balance = res.data.available_balance * 0.00000001;
        this.settings.email = res.data.email;
      },
      getSettings(save = false) {
        this.isActive = false;
        let params = {
          'apiKey' : this.settings.apiKey,
          'apiSecret' : this.settings.apiSecret
        };
        this.errors.clear();
        
        if (!this.settings.apiKey && !this.settings.apiSecret) {
          this.isActive = true;
          return;
        }
        rf.getRequest('BitmexAccountRequest').getBitmexAccount(params).then(res => {
          if (res.data.account_id && res.data.account_id == "B") {
            this.isVerifyAddress = false;
            this.resetSettings();
            this.errors.add({field: 'key', msg: 'Please input valid data.'});
            this.isActive = true;
            return;
          }
          this.applySettings(res);
          this.$refs.datatable.refresh();
          if (save) {
            // this.oldSetting.apiKey = this.settings.apiKey;
            // this.oldSetting.apiSecret = this.settings.apiSecret;
            this.oldSetting = {... this.settings};
          }
          this.isVerifyAddress = false;
          this.isActive = true;
        }).catch(ex => {
          console.log("fail");
          console.log(ex);
          this.isVerifyAddress = false;
          this.isActive = true;
        });
      },

      
    
    },
    mounted() {
      this.$emit('EVENT_PAGE_CHANGE', this);
      this.errors.clear();
      this.isActive = false;
      this.getKey();
    },
  }
</script>

<style lang="scss" scoped>
@import "@/assets/sass/variables";
  .non-sort {
    min-width: 100px;
  }
  .non-sort1 {
    min-width: 115px;
  }
  .error-modal {
    border: solid 1px $color-red-main;
    border-radius: 3px;
    
  }
  input[type='number'] {
    -moz-appearance:textfield;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  .error {
   border: solid 1px $color-red-main !important;
   border-radius: 3px !important;
  }

  .error-box {
    color : $color-red-main;
  }

  .clearfix-10 {
    display: block;
    height: 10px;
  }
  .clearfix-15 {
    display: block;
    height: 15px;
  }
  .pt-5{
    margin-bottom: 15px;
  }
  .clearfix-20 {
    display: block;
    height: 20px;
  }
  .clearfix-50 {
    display: block;
    height: 50px;
  }
  .after-none {
    &:after {
      display: none;
    }
  }

  
  .w_60px {
    width: 60px;
  }
  .w_120px {
    width: 120px;
  }
  .w_170px {
    width: 170px;
  }
  .w_200px {
    width: 200px;
  }
  .input_table {
    border-radius: 3px;
    border: solid 1px $color_alto;
    height: 28px;
    padding: 4px 12px;
    font-size: $font_root;
    color: $color-grey-dark;
    line-height: 20px;
    background-color: transparent;
    &:hover,
    &:active,
    &:focus {
      border-color: $color-jungle-green;
    }
  }

  #base_bitmex_account {
    .group_head_airdrop_setting {
      display: block;
      .title_item_airdrop_setting {
        display: inline-block;
        float: left;
        font-size: $font_big;
        color: $color-grey-dark;
        font-family: $font-family-roboto-medium;
        line-height: 20px;
        margin-right: 12px;
      }
    }
    .form-group-detail {
      .group-detail-form-group {
        .group-detail-form-label{
          width: 100%;
          color: $color-grey-dusty;
          float: left;
          font-weight: normal;
          font-family: $font-family-roboto;
          margin-bottom: 0px;
          margin-top: 10px;
        }

        .group-detail-form-value {
          width: 100%;
          padding: 8px 16px;
          border: 1px solid #dee3eb;
          height: 35px;
          line-height: 35px;
          border-radius: 3px;
          font-family: $font-family-roboto;
          // font-weight: 600;
          &:focus {
            border-color: $color-jungle-green;
          }
        }
        #input-airdrop-title {
          &.error-modal {
            border: solid 1px $color-red-main;
            border-radius: 3px;
          }
        }
        .textarea_input {
          height: 120px;
        }
      }
      .error-box {
        display: block;
        float: left;
        overflow: hidden;
        width: 100%;
        span {
          font-family: $font-family-roboto-medium !important;
        }
      }
      .is-error {
        color: #ff5252 !important;
      }
    }
    .switch {
      margin-bottom: 0px;
      display: inline-block;
      vertical-align: middle;
      cursor: pointer;
      input {
        display:none;
        &:checked +span {
          background: $color-jungle-green;
          text-align: left;
          &:before {
            content: "ON";
            color: $color_white;
            font-size: 12px;
          }
          i {
            left: 35px;
          }
          &:after {
            content: " ";
            color: $color_white;
          }
        }

      }
      span {
        display: inline-block;
        padding: 0px 6px 0 9px;
        width: 54px;
        height: 20px;
        line-height: 20px;
        border-radius: 11px;
        background: $color_grey_icon_light;
        position: relative;
        color: $color_white;
        font-size: 14px;
        text-align: right;
        &:after {
          content: "OFF";
          color: $color_white;
          line-height: 20px;
          font-size: 12px;
        }
        i {
          display: inline-block;
          width: 20px;
          height: 20px;
          border-radius: 100%;
          background: $color_white;
          position: absolute;
          left: 0;
          top: 0px;
          box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.5);
          -webkit-transition: all 0.3s ease-in-out;
          -moz-transition: all 0.3s ease-in-out;
          -ms-transition: all 0.3s ease-in-out;
          -o-transition: all 0.3s ease-in-out;
          transition: all 0.3s ease-in-out;
        }
      }
    }
    .filter_container {
      margin: 10px 0px 25px 0px;
      .title_item {
        color: $color_mine_shaft;
        font-size: $font_big;
        font-family: $font-family-roboto-medium;
        line-height: 28px;
        float: left;
      }
    }
    .line_setting {
      background-color: $color_alto;
      display: block;
      width: 100%;
      height: 1px;
    }
    .content_setting {
      .left_content_setting {
        width: 390px;
        display: block;
        float: left;
        padding-right: 30px;
        .group_airdrop_payout {
          display: block;
          width: 100%;
          .group_input_ardrop_payout {
            display: block;
            float: right;
            width: calc(100% - 170px);
            position: relative;
            .input_airdrop_payout {
              width: 100%;
              height: 35px;
              line-height: 20px;
              padding: 8px 60px 7px 15px;
              font-size: $font_root;
              font-family: $font-family-roboto-medium;
              color: $color-grey-dark;
              border-radius: 3px;
              border: 1px solid $color_alto;
              &:hover,
              &:active,
              &:focus {
                border-color: $color-jungle-green;
              }
              &[disabled] {
                background-color: $color-bright-gray;
                border-color: $color_alto;
                cursor: not-allowed;
                &:hover,
                &:active,
                &:focus {
                  border-color: $color_alto;
                }
              }
            }
            .coin_airdrop_payout {
              position: absolute;
              top: 2px;
              right: 12px;
              padding: 6px 0px 5px 5px;
              line-height: 20px;
              font-size: $font_root;
              font-family: $font-family-roboto-medium;
              color: $color-grey-dark;
            }
          }
          .form-check-label {
            width: 170px;
            display: block;
            float: left;
            min-height: 35px;
            margin: 0px;
            font-weight: 500;
            color: $color-grey-dark;
            font-size: $font_root;
            font-family: $font-family-roboto-medium;
            padding: 7px 0px 7px 0px;
            line-height: 20px;
            cursor: pointer;
            .icon_check_label {
              display: block;
              width: 12px;
              height: 12px;
              border-radius: 50%;
              position: relative;
              float: left;
              border: solid 1px #979797;
              margin: 4px 5px 4px 0px;
            }
            &.active {
              .icon_check_label {
                border: 4px solid #2dac91;
              }
              .txt-inactive{
                color: #2dac91;
              }
            }
            &[disabled] {
              cursor: not-allowed !important;
            }
            .icon_check_payout {
              margin: 4px 5px 4px 0px;
              float: left;
              position: relative;
              display: none;
            }
          }
        }
        .group_input_left {
          display: block;
          width: 100%;
          margin-bottom: 15px;
          label {
            font-family: $font-family-roboto;
            font-size: $font_root;
            color: $color-grey-dusty;
            margin-bottom: 4px;
            line-height: 20px;
            font-weight: 500;
          }
          .input_view {
            width: 100%;
            display: flex;
            position: relative;
            .input_left {
              width: 75%;
              margin-right: 15px;
              float: left;
              }
            input {
              width: 100%;
              height: 35px;
              line-height: 20px;
              padding: 8px 40px 7px 15px;
              font-size: $font_root;
              font-family: $font-family-roboto-medium;
              color: $color-grey-dark;
              border-radius: 3px;
              border: 1px solid $color_alto;
              &[disabled] {
                background-color: $color-bright-gray;
              }
            }
            .select_right_input {
              width: 85px;
              float: right;
              padding: 0;
              margin-left: auto;
              padding-left:24px;
            }
            .select_dropdown_right_input {
              width: 85px;
              float: right;
              padding: 0;
              margin-left: auto;
              background-color: $color_white;
            }
            .unit {
              position: absolute;
              line-height: 20px;
              font-size: $font_root;
              font-family: $font-family-roboto-medium;
              color: $color-grey-dark;
              right: 10px;
              // top: 8px;
            }
          }
        }
      }
      .right_content_setting {
        width: calc(100% - 390px);
        display: block;
        float: left;
        .w_90px {
          width: 90px;
        }
        .box_table_right_setting {
          display: block;
          width: 500px;
          max-width: 100%;
          background-color: $color-white;
          margin-top: 23px;
          table {
            width: 100%;
            th {
              height: 38px;
              padding: 9px 18px 9px 20px;
              line-height: 20px;
              border-bottom: 1px solid $color-white-smoke;
              font-size: $font-small;
              color: $color_grey;
              font-family: $font-family-roboto-medium;
              font-weight: 500;
            }
            td {
              height: 40px;
              line-height: 20px;
              padding: 10px 18px 10px 20px;
              font-size: $font_root;
              color: $color-grey-dark;
              font-family: $font-family-roboto;
            }
          }
        }
      }
      .btn-edit {
        width: 90px;
        height: 35px;
        margin-right: 15px;
        background-color: $color-caribbean-green;
        border-color: $color-jungle-green;
        border-radius: 3px;
        color: $color-white;
        text-transform: uppercase;
        letter-spacing: 0.92px;
        font-size: 12px;
        font-family: $font-family-roboto-medium;
        padding: 9px;
        margin-top: 10px;
        &:hover {
          background-color: $color-aquamarine;
          border-color: $color-aquamarine;
        }
      }
    } 
    .content_table_aridrop {
      .title_table_aridrop {
        color: $color_mine_shaft;
        font-size: $font_big;
        font-family: $font-family-roboto-medium;
        line-height: 28px;
        float: left;
      }
      .search_table_aridrop {
        width: 215px;
        height: 30px;
        border-radius: 3px;
        border: solid 1px $color_alto;
        background-color: transparent;
        font-size: 13px;
        color: $color-grey-dusty;
        font-family: $font-family-roboto;
        display: block;
        float: right;
        padding: 5px 15px;
        &:hover,
        &:active,
        &:focus {
          border-color: $color-jungle-green;
        }
      }
      .btn-add {
        height: 22px;
        border-radius: 3px;
        background-color: $color-caribbean-green;
        border: 1px solid $color-caribbean-green;
        font-size: $font_mini_mini;
        letter-spacing: 0.77px;
        color: $color-white;
        text-align: center;
        padding: 1px 12px;
        padding-top: 2px;
        display: block;
        float: right;
        i {
          font-size: 8px;
          position: relative;
          top: 0px;
          margin-right: 4px;
        }
        &:hover {
          border-color: $color-aquamarine;
          background-color: $color-aquamarine;
        }
      }
      .btn-save-table,
      .btn-close-table,
      .btn-edit-table {
        width: 20px;
        height: 20px;
        text-align: center;
        line-height: 20px;
        padding: 0px;
        text-align: center;
        border: 0px;
        background-color: transparent;
        font-size: 16px;
        color: $color-jungle-green;
        margin-left: 7px;
        i {
          &:before {
            color: $color-jungle-green;
          }
        }
      }
    }
  }
</style>

<style lang="scss">  
@import "@/assets/sass/variables";
  
  #input_time {
    .input_time {
      width: 100%;
    }
    .display-time {
      width: 100%;
      height: 35px;
      line-height: 20px;
      padding: 8px 40px 7px 15px;
      font-size: 14px;
      font-family: "Roboto-Medium", sans-serif;
      color: #333333;
      border-radius: 3px;
      border: 1px solid #CFCFCF;
    }
  }
  #base_bitmex_account {
    .vue-loading {
      margin: 0;
    }
    .sc_search_select {
      float: left;
      width: 85px;
      &[disabled] {
        background-color: $color-bright-gray;
      }
      .group_search_select {
        .button_drop_search {
          height: 35px !important;
          padding: 8px 10px !important;
          span {
            font-size: $font-small;
            font-family: $font-family-roboto;
          }
          i {
            color: $color-grey-dusty;
          }
        }
        .list_search_select {
          li {
            font-size: $font-small;
            font-family: $font-family-roboto;
            &:hover, &:active, &:focus {
              color: $color-jungle-green;
            }
          }
        }
      }
    }
    .list_button_modal {
      padding-top: 20px;
    }
    #table_airdrop_content {
      .sc_search_select{
        width: 55px;
      }
    }
  }
</style>