<template>
  <div id="device-manager" class="mg_bot50 clearfix">
    <data-table :getData="getDevices" :widthTable="'100%'" :classHeader="'header-css'"
      @DataTable:finish="onDatatableFinished" ref="datatable" :limit="10" :column="4" class="datatable scroll">
      <template slot="colgroup_slot">
        <colgroup>
          <col width="45%" />
          <col width="25%" />
          <col width="15%" />
          <col width="15%" />
        </colgroup>
      </template>
      <th class="text-left">{{$t('user.device')}}</th>
      <th class="text-left">{{$t('user.recent_activity')}}</th>
      <th class="text-left cl_ip">{{$t('account.ip_address')}}</th>
      <th class="text-right"></th>
      <template slot="body" slot-scope="props">
        <tr class="device-manager" :class="props.index % 2 === 0 ? 'odd' : 'active'">
          <td class="device-name text-left">
            <div class="over-flow-text" :title="genTitle(props.item.kind, props.item.operating_system, props.item.platform)">
              {{ props.item.kind | uppercaseFirst | trimString }}, {{ props.item.operating_system | trimString }}, {{ props.item.platform | trimString }}
            </div>
          </td>
          <td class="text-left">{{ convertDate(props.item.updated_at) }}</td>
          <td class="text-left cl_ip">{{ props.item.latest_ip_address }}</td>
          <td class="text-right">
            <button class="actions btn button_close_history" @click.prevent="confirmRemoveDevice(props.item)">
              <i class="icon-close2"></i>
            </button>
            <button class="actions btn button_drop_history" @click="activeRow(props.item)">
              <i class="glyphicon" :class="props.item.isActiveRow ? 'icon-arrow2' : 'icon-arrow1'"></i>
            </button>
            
          </td>
        </tr>
        <template v-if="props.item.isActiveRow">
          <tr
            class="device-manager text-left"
            :class="{ odd: ((props.index + 1) % 2 === 0) }"
            v-for="connection in props.item.user_connection_histories">
            <td></td>
            <td>{{ connection.created_at | timestampToDate }}</td>
            <td>{{ connection.ip_address }}</td>
            <td></td>
          </tr>
        </template>
      </template>
    </data-table>
  </div>
</template>

<script>
  import rf from '../../lib/RequestFactory';
  import moment from 'moment';

  export default {
    props: {
      userId: { type: [Number, String] }
    },
    data() {
      return {
        moment: moment
      }
    },
    watch: {
      userId(newValue) {
        this.$refs.datatable.refresh();
      }
    },
    methods: {
      convertDate(date) {
        var dateFormat = 'YYYY-MM-DD HH:mm:ss';
        var testDateUtc = moment(date);
        return testDateUtc.format(dateFormat);
      },  
      genTitle(kind, operating_system, platform) {
        return window._.startCase(kind) + ', ' + operating_system + ', ' + platform;
      },
      onDatatableFinished() {
        if (!this.$refs.datatable || !this.$refs.datatable.rows) {
          return;
        }
        window._.each(this.$refs.datatable.rows, item => {
          item.isActiveRow = false;
        });
      },
      activeRow(item) {
        item.isActiveRow = !item.isActiveRow;
        this.$forceUpdate();
      },
      getDevices (params) {
        if (window._.isEmpty(`${this.userId}`)) {
          return new Promise(() => {});
        }
        this.isActiveRow = -1;
        params.userId = this.userId;
        return rf.getRequest('UserRequest').getDeviceRegister(params);
      },
      refresh() {
        this.$refs.datatable.refresh();
      },
      confirmRemoveDevice (deviceId) {
        window.ConfirmationModal.show({
          type: 'primary',
          title: '',
          content: this.$t('account.modal.confirm_trusted_device'),
          btnCancelLabel: window.i18n.t('common.action.no'),
          btnConfirmLabel: window.i18n.t('common.action.yes'),
          onConfirm: () => {
            this.removeDevice(deviceId);
          }
        });
      },
      removeDevice (item) {
        const params = {
          userId: item.user_id,
          deviceId: item.id
        };
        rf.getRequest('UserRequest').deleteDevice(params).then((res) => {
          this.$toastr('success', this.$t('address.deleted_success'));
          this.refresh();
        });
      },
    },
  }
</script>

<style lang="scss" scoped>
@import "@/assets/sass/variables";

  .device-manager {
    &.active {
      height: auto; 
      max-height: auto;
      background-color: transparent; 
    }
  }
  .icon-arrow2, .icon-arrow1, .icon-close2{
    font-size:16px;
    color: $color-jungle-green;
    &:hover{
      color: $color-eden;
    }

  }

  #device-manager {
    max-height: 441px;
    overflow-y: auto; 
    .datatable {
      .width-title {
        min-width: 160px;
        max-width: 160px;
      }
      button {
        background: transparent;
        box-shadow: none;
        border: 0;
        width: 27px;
        height: 27px;
        padding: 4px;
        float: right;
        &:hover {
          background-color: transparent;
          color: $color_yellow_text;
        }
      }
    }
  }
  .button_close_history {
    margin-left: 10px;
  }
  .cl_ip {
    min-width: 115px;
  }
</style>
<style lang="scss">
@import "@/assets/sass/variables";

  @media (max-width: 1400px) {
    #device-manager{
      table {
        thead {
          th {
            padding: 5px 3px;
          }
        }
        tbody {
          td {
            font-size: $font_root;
            padding: 8px 5px 4px 5px;
          }
        }
        .device-name {
          .over-flow-text {
            max-width: 300px;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
</style>