import BaseRequest from '../lib/BaseRequest'

export default class SettingRequest extends BaseRequest {

    getTransactionUnits(params) {
        let url = '/admin/api/transaction-units';
        return this.get(url, params);
    }

    updateTransactionUnits(params) {
        let url = '/admin/api/transaction-units';
        return this.put(url, params);
    }

    getTransactionFees(params) {
        let url = '/admin/api/transaction-fees';
        return this.get(url, params);
    }

    updateTransactionFees(params) {
        let url = '/admin/api/transaction-fees';
        return this.put(url, params);
    }

    getWithdrawalFees(params) {
        let url = '/admin/api/withdrawal-fees';
        return this.get(url, params);
    }

    updateWithdrawalFees(params) {
        let url = '/admin/api/withdrawal-fees';
        return this.put(url, params);
    }

    editSiteSetting() {
        let url = '/admin/api/site-settings';
        return this.get(url);
    }

    updateSiteSetting(id, params) {
        let url = `/admin/api/site-settings/update`;
        return this.post(url, params);
    }

    getSocialNetworks() {
        let url = '/admin/api/socical-networks';
        return this.get(url);
    }

    addSocialNetwork(params) {
        let url = '/admin/api/socical-networks';
        return this.post(url, params);
    }

    updateSocialNetWork(params) {
        let url = '/admin/api/socical-networks/update';
        return this.post(url, params);
    }

    removeSocialNetwork(id) {
        let url = `/admin/api/socical-networks/${id}`;
        return this.del(url);
    }
    updateRemainAMLSetting(params) {
        let url =  `/admin/api/save-show-remain-aml`;
        return this.post(url, params);
    }
    getRemainAMLSetting() {
        let url = `/api/get-show-remain-aml`;
        return this.get(url);
    }
}
