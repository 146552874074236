<template>
  <div class="deposit_pages boxCore mg_bot50" id="kycSumsub_page">
    <div class="pending_transaction">
      <div class="kycSumsub_page-filter">
        <div class="filter-template">
          <div class='label-text'>{{ $t('funds.balances.from') }}</div>
          <date-picker v-model="startDate" class="date-picker-input" :format="customFormatter"></date-picker>
        </div>
        <div class="filter-template">
          <div class='label-text'>{{ $t('funds.history.to') }}</div>
          <date-picker v-model="endDate" class="date-picker-input" :format="customFormatter"></date-picker>
        </div>
        <div class="filter-template type">
          <div class="label-text date-text">{{ $t('orders.open_order.country') }}</div>
          <div class="toolbar-element">
            <select-box v-model="filters.country" :placeholder="$t('order.order_history.all')"
              :options="countryOptions" />
          </div>
        </div>
        <div class="filter-template status">
          <div class="label-text date-text">{{ $t('orders.open_order.status') }}</div>
          <div class="toolbar-element">
            <select-box v-model="filters.status" :placeholder="$t('order.order_history.all')"
              :options="statusOptions" />
          </div>
        </div>
        <div class="filter-template">
          <div class="label-text">&nbsp;</div>
          <input title type="text" :placeholder="$t('account.detail.affiliate.inputplace.search')" @keyup.enter="search"
            class="form-control search_symbol" name="searchKey" v-model="searchKey">
        </div>
        <div class="filter-template">
          <div class="label-text">&nbsp;</div>
          <button class="btn btn-reset btn-search" @click="search()">{{ $t('kyc.search') }}</button>
          <button class="btn btn-reset" @click="refresh()">{{ $t('kyc.reset') }}</button>
          <button class="btn btn-download" @click="downloadExcel()">
            <img src="@/assets/images/download-icon.svg" width="12" />
            {{ $t('kyc.data') }}
          </button>
        </div>
      </div>
      <div class="datatable">
        <data-table :getData="getListKycs" ref="list_kyc" :column="7" class="scroll"
          :msgEmptyData="$t('common.datatable.no_data')">
          <th class="cl1 text-left" data-sort-field="created_at">{{ $t('kyc.created_at') }}</th>
          <th class="cl2 text-left" data-sort-field="account_id">{{ $t('kyc.account_id') }}</th>
          <!-- <th class="cl2 text-left" data-sort-field="email">{{ $t('kyc.email') }}</th> -->
          <th class="cl3 text-left" data-sort-field="full_name">{{ $t('kyc.name') }}</th>
          <th class="cl4 text-left" data-sort-field="country">{{ $t('kyc.country') }}</th>
          <!-- <th class="cl5 text-left" data-sort-field="id_number">{{ $t('kyc.id_number') }}</th> -->
          <th class="cl6 text-left">{{ $t('kyc.status') }}</th>
          <th class="cl7 text-right">{{ $t('kyc.action') }}</th>
          <template slot="body" slot-scope="props">
            <tr class="line_kyc">
              <td class="cl1 text-left">
                <span>{{ props.item.creation_time | dateFormat }}</span> <br />
                <span>{{ props.item.creation_time | timeFormat }}</span>
              </td>
              <td class="cl2 text-left">
                <div class="item_email_setting">
                  <span class="text-account" @click="gotoAccDetail(props.item.user_id)">{{ props.item.accountID
                    }}</span>
                  <span class="tooltip_email_setting">{{ props.item.email }}</span>
                </div>
              </td>
              <td class="cl3 text-left">
                <div class="item_name_setting">
                  <span class="txt_name_setting">{{ props.item.full_name }}</span>
                  <span class="tooltip_name_setting">{{ props.item.full_name }}</span>
                </div>
              </td>
              <td class="cl4 text-left">
                {{ props.item.country }}
              </td>
              <!-- <td class="cl5 text-left">
                {{ props.item.id_number}}
              </td> -->
              <td class="cl6 text-left">
                <div :class="`status status-${props.item.status}`">{{ props.item | kyc_status }}</div>
              </td>
              <td class="cl7 text-right">
                <div class="action-wrapper">
                  <img v-if="props.item.status != 'pending'" src="@/assets/images/icon/tick-disabled-icon.svg"
                    width="20" />
                  <img v-else class="btn-icon" src="@/assets/images/icon/tick-icon.svg" width="20" @click="verifyKyc(props.item.id)" />
                  <img v-if="props.item.status != 'pending'" src="@/assets/images/icon/cancel-disabled-icon.svg"
                    width="20" />
                  <img v-else class="btn-icon" src="@/assets/images/icon/cancel-icon.svg" width="20" @click="rejectKyc(props.item.id)" />
                  <img class="btn-icon" src="@/assets/images/icon/detail-icon.svg" width="20" @click="detailKyc(props.item)" />
                </div>

              </td>
            </tr>
          </template>
        </data-table>
      </div>
    </div>
    <modal class="kyc_modal" name="verifyKyc" title="">
      <div slot="body" class="body-popup">
        {{ $t('kyc.notice_verify_confirm') }}
      </div>
    </modal>
    <modal class="kyc_modal" name="rejectKyc" title="">
      <div slot="body" class="body-popup">
        {{ $t('kyc.notice_reject_confirm') }}
      </div>
    </modal>

    <modal class="kyc_modal" :enableClose="enableClose" name="loadingUpload">
      <div slot="body">
        <vue-loading type="bars" v-if="isLoading" color="#2DAC91" :size="{ width: '60px', height: '80px' }">
        </vue-loading>
      </div>
    </modal>
  </div>
</template>

<script>
import rf from '../../lib/RequestFactory';
import moment from "moment";
import { mapGetters, mapActions } from 'vuex';
import DatePicker from 'vuejs-datepicker';
import _ from "lodash";
import countries from "../../common/country";
import SelectBox from "../../components/SelectBox.vue";
import Modal from '../../components/Modal';

export default {
  props: ['items'],
  components: {
    DatePicker,
    SelectBox,
    Modal
  },
  data() {
    return {
      titlePage: 'Kyc',
      searchKey: this.$route.params.items ? this.$route.params.items : '',
      detail: false,
      startDate: moment().subtract(1, 'week').toDate(),
      endDate: moment().toDate(),
      countryOptions: [{ id: '', name: window.i18n.t("order.order_history.all") }, ...countries],
      statusOptions: [
        { id: '', name: window.i18n.t("order.order_history.all") },
        { id: 'pending', name: window.i18n.t("kyc.status_pending") },
        { id: 'verified', name: window.i18n.t("kyc.status_verified") },
        { id: 'rejected', name: window.i18n.t("kyc.status_rejected") },
      ],
      filters: {
        country: null,
        status: null
      },
      isLoading: false,
      enableClose: false
    }
  },
  computed: {
    filteredOptions() {
      return this.countryOptions.filter(option =>
        option.label.toLowerCase().includes(this.countryFilter.toLowerCase())
      );
    },
  },
  methods: {
    /*
    ...mapActions([
      'getKycs'
    ]),
    */
    convertToLocalTime(date) {
      var dateFormat = 'YYYY-MM-DD HH:mm:ss';
      var testDateUtc = moment.utc(date);
      var localDate = testDateUtc.local();
      return localDate.format(dateFormat);
    },
    search() {
      this.$refs.list_kyc.filter()
    },
    refresh() {
      this.resetInput();
      this.$refs.list_kyc.refresh();
    },
    getListKycs(params) {
      let filterParams = {
        start_date: moment(this.startDate)
          .startOf("day")
          .format("x"),
        end_date: moment(this.endDate)
          .endOf("day")
          .format("x"),
        size: params.limit,
      };
      delete params.limit
      
      if (this.startDate > this.endDate && this.startDate.toDateString() != this.endDate.toDateString()) {
        this.$toastr("error", this.$t("common.date.warning"));
        return;
      }
      if (this.searchKey) {
        // filterParams.search_key = this.searchKey;
        filterParams.s = this.searchKey;
      }
      if (this.filters.country && this.filters.country.id != '') {
        filterParams.country = this.filters.country.name;
      }
      if (this.filters.status && this.filters.status.id != '') {
        filterParams.status = this.filters.status.id;
      }
      return this.getKycs(Object.assign({}, params, filterParams));
    },
    downloadExcel() {
      let filterParams = {
        start_date: moment(this.startDate)
          .startOf("day")
          .format("x"),
        end_date: moment(this.endDate)
          .endOf("day")
          .format("x")
      };
      if (this.startDate > this.endDate && this.startDate.toDateString() != this.endDate.toDateString()) {
        this.$toastr("error", this.$t("common.date.warning"));
        return;
      }
      if (this.searchKey) {
        // filterParams.search_key = this.searchKey;
        filterParams.s = this.searchKey;
      }
      if (this.filters.country && this.filters.country.id != '') {
        filterParams.country = this.filters.country.name;
      }
      if (this.filters.status && this.filters.status.id != '') {
        filterParams.status = this.filters.status.id;
      }

      rf.getRequest('AdminRequest').exportKYCData(filterParams).then(res => {
        if (res?.success) {
          window.open(`${process.env.VUE_APP_API_URL}/${res.data.path}`, '_blank')
        }
      })
    },

    detailKyc(row) {
      this.$router.push({
        name: 'kyc-sumsub-detail',
        params: {
          items: this.kycSearchKey,
          startDate: this.startDate,
          endDate: this.endDate,
          filters: this.filters,
          searchKey: this.searchKey
        },
        query: {
          id: row ? row.id : null,
        },
        props: true
      });
    },
    resetInput() {
      this.filters = Object.assign(this.filters, {
        country: null,
        status: null
      });
      this.searchKey = "";
      this.startDate = moment()
        .subtract(6, "day")
        .toDate();
      this.endDate = new Date();

      $(".datatable").find("th").attr("data-sort-order", "");
    },
    getKycs(params) {
      return rf.getRequest('AdminRequest').getKycList(params);
    },
    customFormatter(date) {
      return moment(date).format('YYYY-MM-DD');
    },
    loadingUploadBegin() {
      this.enableClose = false;
      this.isLoading = true;
      CommonModal.show('loadingUpload');
    },

    loadingUploadFinish() {
      CommonModal.hide('loadingUpload');
    },

    verifyKyc(id) {
      this.showModalConfirm('verifyKyc', this.submitVerifyKyc, id);
    },

    rejectKyc(id) {
      this.showModalConfirm('rejectKyc', this.submitRejectKyc, id);
    },
    showModalConfirm(modalName, callback, params) {
      CommonModal.show(modalName, {
        position: 'center',
        mask: true,
        buttons: [
          {
            label: window.i18n.t('common.action.no'),
            style: {
              background: 'rgb(191, 191, 191);'
            },
            callback: () => {
              CommonModal.hide(modalName);
            }
          },
          {
            label: window.i18n.t('common.action.yes'),
            focused: true,
            style: {
              background: 'rgb(0, 112, 192)'
            },
            callback: () => {
              CommonModal.hide(modalName);
              this.loadingUploadBegin();
              callback(params);
              this.loadingUploadFinish();
            }
          }
        ]
      });
    },
    submitVerifyKyc(data) {
      rf.getRequest('AdminRequest').verifyKycUser(data).then(res => {
        if (res.success) {
          this.$toastr('success', this.$t('kyc.msg_verified.successfully'));
        }
      }).catch(error => {
        console.error(error);
      });
      this.search();
    },
    submitRejectKyc(data) {
      rf.getRequest('AdminRequest').rejectKycUser(data).then(res => {
        if (res.success) {
          this.$toastr('success', this.$t('kyc.msg_rejected.successfully'));
        }
      }).catch(error => {
        console.error(error);
      });
      this.search();
    },
    gotoAccDetail(id) {
      this.$router.push({
        path: '/account/detail',
        query: { id: id }
      });
    },
    getOptionData() {
      this.countryOptions = [{ id: '', name: window.i18n.t("order.order_history.all") }]
      this.statusOptions = [{ id: '', name: window.i18n.t("order.order_history.all") }]
      rf.getRequest('AdminRequest')
        .getOptionDataKYC('status')
        .then(res => {
          res?.data.map((item) => {
            this.statusOptions.push({
              id: item,
              name: window.i18n.t(`kyc.status_${item}`)
            })
          })
        })
      rf.getRequest('AdminRequest')
        .getOptionDataKYC('country')
        .then(res => {
          res?.data.map((item) => {
            this.countryOptions.push({
              id: item,
              name: item
            })
          })
        })
    },
    receiveParams() {
      const params = this.$route.params;
      if (Object.keys(params).length) {
        this.startDate = moment(params.startDate).toDate();
        this.endDate = moment(params.endDate).toDate();
        this.filters = params.filters;
        this.searchKey = params.searchKey;
      }
    }
  },
  created() {
    this.getOptionData();
    this.receiveParams();
  },
  mounted() {
    if (this.$route.params.isVerifyKycSuccess === true) {
      this.$toastr('success', this.$t('kyc.msg_verified.successfully'));
    }
    if (this.$route.params.isrejectKycSuccess === true) {
      this.$toastr('success', this.$t('kyc.msg_rejected.successfully'));
    }
    this.$emit('EVENT_PAGE_CHANGE', this);

  }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

#kycSumsub_page {
  font-family: $font_family_root;

  .sc_search_select {
    .group_search_select {
      .button_drop_search {
        height: 32px;
        padding: 6px 10px 6px 10px;
        border-radius: 10px;
        font-size: $font-small;
        font-family: $mulish-regular;
        background-color: $color-white;
        width: 100%;

        span {
          font-size: $font-small;
        }

        i.icon-arrow1 {
          margin-left: 5px;
          color: $color_dove_gray;
        }

        &:hover {
          border-color: $color-jungle-green;

          i.icon-arrow1 {
            color: $color-jungle-green;
          }
        }
      }

      .box_list_search_select {
        .list_search_select {
          border-radius: 10px;

          li {
            font-family: $mulish-regular;
            font-size: $font-small;
            padding: 6px 12px;

            &:hover,
            &:active,
            &:focus {
              color: $color-jungle-green;
            }
          }
        }
      }
    }
  }

  .modal-dialog {
    width: 400px;
    position: relative;
    top: -10%;

  }

  .modal-content {

    .modal-footer {
      text-align: center;
      margin-top: 30px;
    }

    .modal-header {
      padding: 30px 16px 16px 20px;

      .modal-title {
        font-family: Roboto-Medium;
        font-size: 18px;
        font-weight: 500;
        color: $color-grey-dark;
        text-transform: none;
      }
    }

    .modal-body {
      text-align: center;
      padding: 0;
    }
  }
}
</style>

<style lang="scss" scoped>
@import "@/assets/sass/variables";

td {
  word-break: break-all;
}

.datatable {
  margin-top: 30px;
  font-family: $font-family-roboto !important;
}

.cl1 {
  width: 310px;
}

.cl2,
.cl3 {
  width: 280px;
}

.cl4,
.cl6 {
  width: 175px;
}

.cl5 {
  width: 310px;
}


.cl7 {
  width: 70px;
}

.deposit_pages {
  .kycSumsub_page-filter {
    display: flex;
    flex-wrap: wrap;
  }

  .action-wrapper {
    display: flex;
    gap: 8px;
    justify-content: end;

    .btn-icon {
      cursor: pointer;
    }
  }

  .filter-template {
    margin-right: 15px;

    &.filter-status {
      width: 120px;
    }

    &.country-filter {
      width: 150px;
    }

    .input-dropdown input {
      width: 100%;
    }

    .coin-option {
      background-color: $color-white;
      max-height: 210px;
      display: block;
      overflow-y: auto;
      z-index: 10;
      padding: 12px 0px;
      box-shadow: 0px 3px 12px rgba(49, 49, 49, 0.51);
    }

    .label-text {
      color: $color-grey-dusty;
      font-weight: 500;
      font-size: 14px;
      margin-bottom: 7px;
      margin-left: 5px;
      font-family: $mulish-regular;
    }

    .date-picker-input {
      display: inline-block;
      float: left;

      ::v-deep {
        input {
          padding: 0 5px 0px 36px;
          width: 135px !important;
          height: 32px;
          border: 1px solid #C7CBD3 !important;
          border-radius: 10px !important;
          background-image: url('@/assets/images/icon/date-time.svg') !important;
          background-color: $color-white !important;
          background-size: 12px !important;
          background-position: center left 12px !important;
          background-repeat: no-repeat !important;
          color: $dark-1;

          &:hover,
          &:active,
          &:focus {
            border-color: $color-jungle-green;
            z-index: 9;
          }
        }
      }

    }

    .btn-reset {
      background: $color-caribbean-green;
      border-radius: 10px;
      color: $color_white;
      font-weight: 700;
      width: 78px;
      height: 32px;
    }

    .btn-search {
      margin: 0 10px;
    }

    .btn-download {
      background: $color-caribbean-green;
      border-radius: 10px;
      color: $color_white;
      font-weight: 700;
      width: 95px;
      height: 32px;
      margin-left: 10px;

      img {
        margin-right: 4px;
        margin-top: -2px;
      }
    }

    .search_symbol {
      background: $color-white;
      border: 1px solid #C7CBD3 !important;
      border-radius: 10px !important;
      width: 197px;
      height: 32px;
    }

    .toolbar-element {
      width: 150px;
      height: 32px;
    }
  }

  table tbody td {

    div,
    span {
      font-family: $mulish-regular;
    }

    .text-account {
      text-decoration: underline;
      color: $color-picton-blue;
      cursor: pointer;

      &:hover {
        color: $color-cornflower-blue-light;
      }
    }

    .status {
      width: 100px;
      height: 36px;
      border-radius: 3px;
      color: $color_black_logo;
      display: flex;
      justify-content: center;
      align-items: center;

      &.status-pending {
        background: $color-pending-status;
      }

      &.status-verified {
        background: $color-filled-status;
      }

      &.status-rejected {
        background: $color-cancel;
      }
    }
  }
}

@media only screen and (min-width: 1399px) {
  .deposit_pages .item_email_setting .txt_email_setting {
    max-width: 250px;
  }
}
</style>