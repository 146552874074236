<template>
  <div class="content_usersetting mg_bot50 clearfix" id="user_settings_fee">
    <div>
      <div class="select_transaction_container clearfix">
          <div class="tab-header">
            <ul>
              <li :class="{'active': settingFor === 'spot'}">
                <a
                  href="javascript:void(0)"
                  @click="selectTab('spot')"
                ><span>{{$t('common.spot_exchange')}}</span></a>
              </li>
              <li :class="{'active': settingFor === 'margin'}">
                <a
                  href="javascript:void(0)"
                  @click="selectTab('margin')"
                ><span>{{ $t('common.margin_exchange') }}</span></a>
              </li>
            </ul>
          </div>
        </div>
    </div>
    <div class="filter_container">
      <!-- Select Markets -->
      <div class="left-div">
        <div class=" filter-item market-select-area">
          <div class="d-flex">
            <span class="title_item select-market-text">
              {{ $t('circuit_breaker_setting.select_market') }}
            </span>
            <select-box v-model="currency"  :options="markets" placeholder="BTC"/>
          </div>
        </div>
        <div class=" filter-item market-select-area">
          <div class="d-flex">
            <span class="title_item select-market-text">
              {{ $t('airdrop_setting.kyc')}}
            </span>
            <select-box v-if ="settingFor=='spot'" v-model="settingsUser['dividend_kyc_spot']" class="select-market" :options="chosens_kyc" placeholder="On"/>
            <select-box v-if ="settingFor=='margin'" v-model="settingsUser['dividend_kyc_margin']" class="select-market" :options="chosens_kyc" placeholder="On"/>
          </div>
        </div>
        <div class=" filter-item market-select-area">
          <div class="d-flex">
            <span class="title_item select-market-text">
              {{ $t('airdrop_setting.self_trading') }}
            </span>
            <select-box v-if="settingFor=='spot'" v-model="settingsUser['self_trading_auto_dividend_spot']"  :options="chosens" placeholder="On"/>
            <select-box v-if="settingFor=='margin'" v-model="settingsUser['self_trading_auto_dividend_margin']"  :options="chosens" placeholder="On"/>
          </div>
        </div>
      </div>
      <div class="switch_group">
          <button class="btn btn-success" @click="updateAllStatus(currency, 1)">ENABLE ALL</button>
          <button class="btn btn-danger" @click="updateAllStatus(currency, 0)">DISABLE ALL</button>
      </div>
      <!-- <div class="clearfix"></div> -->

    </div>
    <div class="datatable auto_dividend">
      <data-table-4 :getData="getData" ref="datatable" :limit="100" :column='9' class="scroll">
          <th class="text-left" v-if="settingFor == 'spot'">{{ $t('airdrop_setting.market') }}</th>
          <th class="text-left" v-else>{{ $t('funds.contract') }}</th>
          <th class="text-left" >{{ $t('airdrop_setting.minimum_trade_amount') }}</th>
          <th class="text-left" >{{ $t('airdrop_setting.payout_amount')}}</th>
          <th class="text-left" >{{ $t('airdrop_setting.payout_for')}}</th>
          <th class="text-left mw-130" >{{ $t('airdrop_setting.airdrop_total_paid')}}</th>
          <th class="text-left" >{{ $t('airdrop_setting.max_bonus')}}</th>
          <th class="text-left" >{{ $t('airdrop_setting.time_apply_from')}}</th>
          <th class="text-left" >{{ $t('airdrop_setting.time_apply_to')}}</th>
          <th class="text-right" ></th>
          <th class="text-right" ></th>
          <th class="text-right" ></th>
        <template slot="body" slot-scope="props">
          <tr v-bind:class="{ vertical_align: errors.count() > 0 }">
            <td class="text-left row-1">
              <span class="txt_email_user" v-if="settingFor=='spot'">{{ props.item.coin | uppercase}}/{{props.item.market | uppercase}}</span>
              <span class="txt_email_user" v-else>{{props.item.coin | uppercase}}</span>
            </td>
            <td class="text-left row-2">
              <div class="container-input">
                <span v-if = "!props.item.isEdit" class="txt_email_user">{{ props.item.lot | formatCurrencyAmount(null, '0')}} BTC</span>
                <div class="input-container" v-else>
                  <currency-input :precision="8" v-model="lot"/>  
                  <span class="lotCoin">{{ $t('currency.btc.shortname') | uppercase}}</span>
                </div>
              </div>
              <div class="error-box" v-if="props.item.isEdit">
                <span v-show="errors.has('lot')" class="f-left is-error">{{ errors.first('lot') }}</span>
                <span v-show="errors.has('lot_0')" class="f-left is-error">{{ errors.first('lot_0') }}</span>
              </div>
            </td>
            <td class="text-left row-3">
              <span v-if = "!props.item.isEdit" class="txt_email_user">{{ props.item.payout_amount | formatCurrencyAmount(null, '0')}} {{ props.item.payout_coin | uppercase}}</span>
              <div class="container-input" v-else>
                <div class="flex-div input-container">
                  <currency-input :precision="8" v-model="payoutAmount"/>
                  <select-box class="select-market pay-coin" :options="listCoin" v-model="payCoin"  :value="payCoin"/>
                </div>
                <div class="error-box" v-if="props.item.isEdit">
                  <span v-show="errors.has('payout_amount')" class="f-left is-error">{{ errors.first('payout_amount') }}</span>
                  <span v-show="errors.has('payout_amount_0')" class="f-left is-error">{{ errors.first('payout_amount_0') }}</span>
                </div>
              </div>
            </td>
            <td class="text-left row-4">
              <span v-if = "!props.item.isEdit" class="txt_email_user">{{ props.item.payfor | getWallet}}</span>
              <select-box v-else  class="select-market pay-wallet" :options="listWallet"  v-model="payFor" />
            </td>
            <td class="text-left row-4 mw-130"><span class="txt_email_user"> {{props.item.total_paid | formatCurrencyAmount(null, '0')}} {{props.item.payout_coin  | uppercase}} </span></td>
            <td class="text-left row-2">
              <div class="container-input">
                <span v-if ="!props.item.isEdit" class="txt_email_user">{{ props.item.max_bonus | formatCurrencyAmount(null, '0')}} {{ props.item.payout_coin  | uppercase}}</span>
                <div class="input-container" v-else>
                  <currency-input :precision="8" v-model="max_bonus"/>  
                  <span class="lotCoin">{{ props.item.payout_coin  | uppercase}}</span>
                </div>
              </div>
              <div class="error-box" v-if="props.item.isEdit">
                <span v-show="errors.has('max_bonus')" class="f-left is-error">{{ errors.first('max_bonus') }}</span>
                <span v-show="errors.has('max_bonus_0')" class="f-left is-error">{{ errors.first('max_bonus_0') }}</span>
              </div>
            </td>
            <td class="text-left container-input row-5">
              <div class="flex-div">
                <span v-if = "!props.item.isEdit" class="txt_email_user">{{ props.item.time_from}}</span>
                <date-picker v-else v-model="timeFrom" class="date-picker-input" :format="customFormatter"></date-picker>
              </div>
              <div class="error-box" v-if="props.item.isEdit">
                <span v-show="errors.has('time_from')" class="f-left is-error">{{ errors.first('time_from') }}</span>
              </div>
            </td>
            <td class="text-left container-input row-6">
              <div class="flex-div">
                <span v-if = "!props.item.isEdit" class="txt_email_user">{{ props.item.time_to}}</span>
                <date-picker v-else v-model="timeTo" class="date-picker-input" :format="customFormatter"></date-picker>
              </div>
              <div class="error-box" v-if="props.item.isEdit">
                <span v-show="errors.has('time_to')" class="f-left is-error">{{ errors.first('time_to') }}</span>
                <span v-show="errors.has('time_to_is_smaller')" class="f-left is-error">{{ errors.first('time_to_is_smaller') }}</span>
              </div>
            </td>
            <td class="text-left row-7">
              <button class="btn btn-edit-table" v-if="props.item.isEdit" @click="resetColumn(props.item)"><i class="icon-reload3"></i></button>
              <button class="btn btn-edit-table" v-if="!props.item.isEdit" @click="writeColumn(props.item, props)"><i class="icon-edit"></i></button>
              <button class="btn btn-save-table" v-if="props.item.isEdit" @click="confirmColumn(props.item)"><i class="icon-save"></i></button>
            </td>
            
            <td class="text-right row-8">
              <button class="btn btn-reset" @click.stop="resetMaxBonus(props.item)">{{ $t('airdrop_setting.reset_bonus') }}</button>
            </td>
             
            <td class="text-right row-8">
              <button class="btn" :disabled="props.item.isEdit" :class="props.item.enable ? 'btn_enable' : 'btn_disable'"
                    @click.stop="onClickedUpdateStatus(props.item)">
                    {{ props.item.enable ? $t('common.action.enable') : $t('common.action.disable') }}
              </button>
            </td>
          </tr>
        </template>
      </data-table-4>
    </div>
</div>
</template>

<script>
  import rf from '../../lib/RequestFactory';
  import BigNumber from 'bignumber.js';
  import Numeral from '../../lib/numeral';
  import SelectBox from '../../components/SelectBox';
  import Modal from "../../components/Modal";
  import moment from "moment";
  import DatePicker from "vuejs-datepicker";
  import COMMON_CONST from "../../common/Const";
  import en from "../../lang/en";
  import DataTable4 from "../../components/DataTable4";


  export default {
    components: {
      SelectBox,
      Modal,
      DatePicker,
      DataTable4
    },
    data() {
      return {
        titlePage: this.$t('airdrop_setting.auto_dividend_title'),
        hasItem: {},
        payoutAmount:"",
        payFor:"",
        payCoin:{},
        timeTo: "",
        timeFrom: "",
        payoutCoin: "",
        lot: "",
        settings: {},
        oldSetting: {},
        hasError: false,
        isEdit: true,
        startDate: moment().subtract(1, 'week').toDate(),
        endDate: moment().toDate(),
        markets: [],
        listMarketsSpot: [],
        listMarketsMargin: [],
        listCoin: [],
        listWallet: [],
        currency: {id: 'btc', name: 'BTC'},
        chosens:[{id: 0, name: 'Off'}, {id: 1, name: 'On'}],
        chosens_kyc:[{id: 0, name: 'All'}, {id: 1, name: 'Verified'}],
        settingsUser: {
          self_trading_auto_dividend_spot: {id: 0, name: 'Off'},
          self_trading_auto_dividend_margin: {id: 0, name: 'Off'},
          dividend_kyc_spot: {id: 1, name: 'Verified'},
          dividend_kyc_margin: {id: 1, name: 'Veriried'}
        },
        coinList: [],
        selectedCoinPair: {},
        hasError: false,
        showErrorModal: false,
        newValue: {},
        settingFor: 'spot',
        max_bonus: 0,
      }
    },
    computed: {
      self_trading_auto_dividend_spot() {
        return this.settingsUser.self_trading_auto_dividend_spot;
      },
      self_trading_auto_dividend_margin() {
        return this.settingsUser.self_trading_auto_dividend_margin;
      },
      dividend_kyc_spot() {
        return this.settingsUser.dividend_kyc_spot;
      },
      dividend_kyc_margin() {
        return this.settingsUser.dividend_kyc_margin;
      }
    },
    watch: {
      currency(newValue) {
        console.log(this.currency, "currency");
        this.$refs.datatable.refresh();
      },
      settingFor(newValue) {
        console.log(this.currency, "currency");
      },
      self_trading_auto_dividend_spot(newValue) {
        let obj = {
          key : 'self_trading_auto_dividend_spot',
          value: newValue.id
        }
        this.changeStatusSetting(obj);
      },
      self_trading_auto_dividend_margin(newValue) {
        let obj = {
          key : 'self_trading_auto_dividend_margin',
          value: newValue.id
        }
        this.changeStatusSetting(obj);
      },
      dividend_kyc_spot(newValue) {
        let obj = {
          key : 'dividend_kyc_spot',
          value: newValue.id
        }
        this.changeStatusSetting(obj);
      },
      dividend_kyc_margin(newValue) {
        let obj = {
          key : 'dividend_kyc_margin',
          value: newValue.id
        }
        this.changeStatusSetting(obj);
      },
      
      payCoin() {
        if(this.payCoin.name == 'AMAL') {
          this.listWallet = [
            {id:COMMON_CONST.MAIN_BALANCE.toLowerCase(), name: COMMON_CONST.MAIN_BALANCE},
            {id:COMMON_CONST.DIVIDEND_NORMAL.toLowerCase(), name: COMMON_CONST.DIVIDEND_NORMAL},
            {id:COMMON_CONST.DIVIDEND_BY_ADMIN.toLowerCase(), name: COMMON_CONST.DIVIDEND_BY_ADMIN}
          ];
        } else {
           this.payFor = {id:COMMON_CONST.MAIN_BALANCE.toLowerCase(), name: COMMON_CONST.MAIN_BALANCE};
          this.listWallet = [{id:COMMON_CONST.MAIN_BALANCE.toLowerCase(), name: COMMON_CONST.MAIN_BALANCE}];
        }
      }
    },
    methods: {
      updateAllStatus(currency, enable) {
        const title = enable ? this.$t('auto_dividend.confirm_on_all_pair') : this.$t('auto_dividend.confirm_off_all_pair');
        this.showModal({
          type: 'confirm',
          title: title,
          onConfirm: () => {
            let params = {
              "market" : currency.id,
              "enable" : enable,
              "setting_for" : this.settingFor
            };
            rf.getRequest('AdminRequest').updateAllStatus(params).then(res => {
              this.$toastr('success', window.i18n.t('auto_dividend.update_status_msg'));
              this.$refs.datatable.refresh();
            });
          }
        });
      },
      customFormatter(date) {
        return moment(date).format('YYYY - MM - DD');
      },
      trimNumber(number) {
        return number == null ? number : new BigNumber(number);
      },
      selectTab (tab) {
        this.settingFor = tab;
        this.currency = {id: 'btc', name: 'BTC'};
        switch (tab) {
            case 'margin':
                this.markets=this.listMarketsMargin
                break;
            case 'spot':
                this.markets=this.listMarketsSpot
                break;
            default: break;
        }
        this.$refs.datatable.refresh();
      },
      findObjectFromChosens(value) {
        return this.chosens.find(el => {
          return el.id == value;
        })
      },
      findObjectFromChosensKyc(value) {
        return this.chosens_kyc.find(el => {
          return el.id == value;
        })
      },
      changeStatusSetting(value) {
        rf.getRequest('LeaderboardRequest').changeSetting({key : value.key, value: value.value});
      },
      

      getData(params={}) {
        let defaultParams = {
          "market" : this.currency.id,
          "setting_for" : this.settingFor
        }

        return rf.getRequest('AdminRequest').getAutoDividendSetting(Object.assign(defaultParams, params)).then(res =>{
          let data = res || res.data;
          data.data.forEach(element => {
            element['isReset'] = false;
            element['isEdit'] = false;
          });
          return data;
        });
      },
      getAllCoin() {
        rf.getRequest('AdminRequest').getAllCoin().then(res => {
          try {
            window._.map(res.data, item => {
              if (typeof item.coin !== 'undefined') {
                this.listCoin.push({ id: item.coin, name: item.coin.toUpperCase() });
              }
            });
          }
          catch (ex) {
            console.error(ex);
          }
        });
      },
      writeColumn(item, props) {
        this.errors.clear();
        if(!this.hasItem) {
          this.hasItem = item;
        } else {
          this.hasItem.isEdit = false;
          this.hasItem = item;
        }
        
        item.isEdit = true;
        this.payCoin = {id: item.payout_coin.toLowerCase(), name:item.payout_coin};
        this.payFor = {id: this.convertWallet(item.payfor).toLowerCase(), name: this.convertWallet(item.payfor)};
        this.lot = this.trimNumber(item.lot);
        this.max_bonus = this.trimNumber(item.max_bonus);
        this.payoutAmount = this.trimNumber(item.payout_amount);
        this.timeFrom = item.time_from;
        this.timeTo = item.time_to;
      },
      resetColumn(item) {
        item.isEdit = false;
        this.errors.clear();
      },
      getSettingsAdmin() {
        rf.getRequest('LeaderboardRequest').getSelfTradingSettings().then(res=>{
          for (let key in this.settingsUser) {
            console.log(key);
            let obj = null;
            if (key.includes('kyc')) {
              obj =  this.findObjectFromChosensKyc(res.data.find(el=> {
                if ( el.key == key) return el;
              }).value);
            } else {
              obj =  this.findObjectFromChosens(res.data.find(el=> {
                if ( el.key == key) return el;
              }).value);
            }
            this.settingsUser[key] = obj;
          }
        })
      },
      convertWallet(wallet) {
        if(wallet == COMMON_CONST.MAIN_BALANCE) return 'main';
        if(wallet ==COMMON_CONST.DIVIDEND_NORMAL) return 'airdrop';
        if(wallet == COMMON_CONST.DIVIDEND_BY_ADMIN) return 'dividend_bonus';

        if(wallet == 'main') return COMMON_CONST.MAIN_BALANCE;
        if(wallet == 'airdrop') return COMMON_CONST.DIVIDEND_NORMAL;
        if(wallet == 'dividend_bonus') return COMMON_CONST.DIVIDEND_BY_ADMIN;

      },
      validateSetting() {
        this.errors.clear();
        if(this.lot == 0) {
          this.errors.add({field: 'lot_0', msg: this.$t('airdrop.error.field_min',{field_name: 'lot'})});
        }
        if(this.lot == undefined) {
          this.errors.add({field: 'lot', msg: this.$t('airdrop.error.field_required',{field_name: (this.$t('airdrop_setting.minimum_trade_amount')).toLowerCase()})});
        }
        if(this.max_bonus == undefined) {
          this.errors.add({field: 'max_bonus', msg: this.$t('airdrop.error.field_required',{field_name: (this.$t('airdrop_setting.max_bonus_low')).toLowerCase()})});
        }
        if(this.max_bonus == 0) {
          this.errors.add({field: 'max_bonus_0', msg: this.$t('airdrop.error.field_min',{field_name: (this.$t('airdrop_setting.max_bonus_low')).toLowerCase()})});
        }
        if(this.payoutAmount == 0) {
          this.errors.add({field: 'payout_amount_0', msg: this.$t('airdrop.error.field_min',{field_name: 'payout amount'})});
        }
        if(this.payoutAmount == undefined) {
          this.errors.add({field: 'payout_amount', msg: this.$t('airdrop.error.field_required',{field_name: (this.$t('airdrop_setting.payout_amount')).toLowerCase()})});
        }
        if(!this.timeFrom) {
          this.errors.add({field: 'time_from', msg: this.$t('airdrop.error.field_required',{field_name: (this.$t('airdrop_setting.time_apply_from')).toLowerCase()})});
        }
        if(!this.timeTo) {
          this.errors.add({field: 'time_to', msg: this.$t('airdrop.error.field_required',{field_name: (this.$t('airdrop_setting.time_apply_to')).toLowerCase()})});
        }
        if( moment(this.timeTo).format("YYYY-MM-DD") <  moment(this.timeFrom).format("YYYY-MM-DD")) {
          this.errors.add({field: 'time_to_is_smaller', msg: this.$t('common.date.warning2')});
        }
        if(this.errors.count() > 0) return false;
        return true;
      },
      async confirmColumn(item) {
        let isValid = await this.validateSetting();
        if(!isValid) return;
        item.isEdit = false;
        item.lot = this.lot;
        item.payout_amount = this.payoutAmount;
        item.payout_coin = this.payCoin.name;
        item.max_bonus = this.max_bonus;
        item.payfor = this.convertWallet(this.payFor.name);
        item.time_from = moment(this.timeFrom).format("YYYY-MM-DD");
        item.time_to = moment(this.timeTo).format("YYYY-MM-DD");
        rf.getRequest('AdminRequest').updateAutoDividendSetting(item).then(res => {
          this.$toastr('success', window.i18n.t('auto_dividend.update_status_msg'));
          this.$refs.datatable.refresh();
        });
      },
      async onClickedUpdateStatus(setting) {
        if(this.settingFor == COMMON_CONST.TYPE_MARGIN_BALANCE) {
          var title = setting && setting.enable ? this.$t('auto_dividend.confirm_off_pair_margin') : this.$t('auto_dividend.confirm_on_pair_margin');  
        } else {
          var title = setting && setting.enable ? this.$t('auto_dividend.confirm_off_pair') : this.$t('auto_dividend.confirm_on_pair');
        }
        this.showModal({
          type: 'confirm',
          title: title,
          onConfirm: () => {
            let params = {
              "market": setting.market,
              "coin" : setting.coin,
              "enable" : !setting.enable
            }
            rf.getRequest('AdminRequest').updateStatus(params).then(res => {
              this.$toastr('success', window.i18n.t('auto_dividend.update_status_msg'));
              this.$refs.datatable.refresh();
            });
          }
        });
      },

      async resetMaxBonus(setting) {
        this.showModal({
          type: 'confirm',
          title: this.$t('airdrop_setting.confirm_reset_bonus'),
          onConfirm: () => {
            let params = {
              "market": setting.market,
              "coin" : setting.coin,
              "payout_coin" : setting.payout_coin,
            }
            rf.getRequest('AdminRequest').resetMaxBonus(params).then(res => {
              this.$toastr('success', window.i18n.t('auto_dividend.update_status_msg'));
              this.$refs.datatable.refresh();
            });
          }
        });
      },

      showModal (config) {
        const type = config.type;
        const title = config.title;
        const content = config.content;
        const customContent = config.customContent;

        let btnCancelLabel = config.btnCancelLabel || this.$t('common.action.no');
        let btnConfirmLabel = config.btnConfirmLabel || this.$t('common.action.yes');
        if (config.noAction) {
          btnCancelLabel = null;
          btnConfirmLabel = null;
        }
        const onConfirm = config.onConfirm;
        const onCancel = config.onCancel;

        window.ConfirmationModal.show({
          type: type,
          title: title,
          content: content,
          customContent: customContent,
          btnCancelLabel: btnCancelLabel,
          btnConfirmLabel: btnConfirmLabel,
          onConfirm: onConfirm,
          onCancel: onCancel
        });
      },

      loadMarketsMargin() {
        rf.getRequest('AdminRequest').getInstrument([]).then(res => {
          try {
            const response = [];
            window._.map(res.data, (item) => {
                response.push({id: item.root_symbol, name: item.root_symbol});
            });

            let output = [];
            let keys   = [];
            response.forEach(function (symbol) {
                var key = symbol['id'];
                if (keys.indexOf(key) === -1) {
                    keys.push(key);
                    output.push(symbol);
                }
            });
            this.listMarketsMargin = output;
          }
          catch (ex) {
            console.error(ex);
          }
        });
      },
      loadMarketsSpot() {
        rf.getRequest('AdminRequest').getPriceGroupCurrency().then(res => {
          try {
            window._.map(res.data, item => {
              if (typeof item.currency !== 'undefined') {
                this.markets.push({ id: item.currency, name: item.currency.toUpperCase() });
                this.listMarketsSpot.push({ id: item.currency, name: item.currency.toUpperCase() });
              }
            });
          }
          catch (ex) {
            console.error(ex);
          }
        });
      },
      showModal (config) {
        const type = config.type;
        const title = config.title;
        const content = config.content;
        const customContent = config.customContent;
        let btnCancelLabel = config.btnCancelLabel || this.$t('common.action.no');
        let btnConfirmLabel = config.btnConfirmLabel || this.$t('common.action.yes');
        if (config.noAction) {
          btnCancelLabel = null;
          btnConfirmLabel = null;
        }
        const onConfirm = config.onConfirm;
        const onCancel = config.onCancel;
        window.ConfirmationModal.show({
          type: type,
          title: title,
          content: content,
          customContent: customContent,
          btnCancelLabel: btnCancelLabel,
          btnConfirmLabel: btnConfirmLabel,
          onConfirm: onConfirm,
          onCancel: onCancel
        });
      }
    },
    created() {
      document.addEventListener('keydown', this.blockTabBrowser);
      this.loadMarketsSpot();
      this.loadMarketsMargin();
      this.getAllCoin();
      this.getData();
    },
    beforeDestroy() {
      document.removeEventListener('keydown',this.blockTabBrowser);
    },
    mounted() {
      this.$emit('EVENT_PAGE_CHANGE', this);
      this.getSettingsAdmin();
    }
  }
</script>
<style lang="scss" scoped>
@import "@/assets/sass/variables";
  .btn-success {
    background-color: #2DAC91;
    &:hover {
      background-color: #2E9BDB;
    }
  }
    .btn-reset {
      background-color: $color-caribbean-green;
      border: 1px solid $color-caribbean-green;
      width: 106px;
      text-transform: uppercase;
      color: $color-white;
      font-size: 13px;
      height: 29px;
      &:hover {
        border-color: $color-aquamarine;
        background-color: $color-aquamarine;
      }
    }
  .btn-danger {
    background-color: #E2221E;
  }
  .row-1{
    min-width: 111px !important;
  }
  .mw-130 {
    min-width: 130px;
    white-space: nowrap;
  }
  td.row-1{
    padding-left: 10px !important;
  }
  .row-5{
    min-width: 235px !important;
  }
  .row-6{
    min-width: 215px !important;
  }
  .lotCoin {
    margin-left: 5px;
    margin-top: 3px;
    min-width: 40px;
  }
  
  #user_settings_fee table tbody td {
    padding: 5px 20px 4px 10px !important;
}
// .mw-110{
//   input{
//     max-width: 110px;
//   }
// }
  .input-container {
    display: flex;
    min-width: 210px;
    input {
      width: 100%;
      height: 30px;
      line-height: 20px;
      padding: 8px 2px 7px 6px;
      font-size: $font_root;
      font-family: $font-family-roboto-medium;
      color: $color-grey-dark;
      border-radius: 3px;
      border: 1px solid $color_alto;
    }
  }
  .vertical_align {
    .text-left {
      vertical-align: top !important;
    }
  }
  .select_transaction_container {
    padding: 10px 0px 20px 0px;
    .input-radio {
      label {
        display: inline;
        font-weight: normal;
        text-align: center;
        margin-right: 25px;
        span {
          font-size: $font_big;
        }
        input[type="radio"] {
          display: inline-block;
          margin-top: 0px;    
          position: relative;
          top: -2px;
        }
      }
    } 
  }
  .select-market {
    margin-left: 1%;
  }
  .tab-header {
    display: block;
    width: 100%;
    float: left;
    margin: 10px 0px 23px 0px;
    ul {
      list-style: none;
      padding-left: 0px;
      li {
        float: left;
        margin-right: 25px;
        font-size: $font_big;
        color: $color-grey-dark;
        display: block;
        >a {
          color: $color-grey-dark;
          font-family: $font-family-roboto-medium;
          font-weight: 500;
          font-size: $font_big;
          display: block;
          float: right;
          &::before {
            content: "";
            display: inline-block;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            border: solid 1px #979797;
          }
          span {
            padding-left: 5px;
          }
        }
        &.active {
          >a {
            &::before {
              border: solid 4px $color-jungle-green;
            }
              color: $color-jungle-green;
          }
        }
      }
    }
  }
  #base_airdrop_setting {
    .switch {
      margin-bottom: 0px;
      display: inline-block;
      vertical-align: middle !important;
      cursor: pointer !important;
      input {
        display:none;
        &:checked +span {
          background: $color-jungle-green;
          text-align: left;
          &:before {
            content: "ON";
            color: $color_white;
            font-size: 12px;
          }
          i {
            left: 35px;
          }
          &:after {
            content: " ";
            color: $color_white;
          }
        }

      }
      span {
        display: inline-block;
        padding: 0px 6px 0 9px;
        width: 54px;
        height: 20px;
        line-height: 20px;
        border-radius: 11px;
        background: $color_grey_icon_light;
        position: relative;
        color: $color_white;
        font-size: 14px;
        text-align: right;
        &:after {
          content: "OFF";
          color: $color_white;
          line-height: 20px;
          font-size: 12px;
        }
        i {
          display: inline-block;
          width: 20px;
          height: 20px;
          border-radius: 100%;
          background: $color_white;
          position: absolute;
          left: 0;
          top: 0px;
          box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.5);
          -webkit-transition: all 0.3s ease-in-out;
          -moz-transition: all 0.3s ease-in-out;
          -ms-transition: all 0.3s ease-in-out;
          -o-transition: all 0.3s ease-in-out;
          transition: all 0.3s ease-in-out;
        }
      }
    }
  }
  .w-70 {
    width: 70% !important;
  }
  .w-25 {
    width: 25% !important;
    margin-left: 5%;
  }
  .mg-30  {
    margin: 6px 30px;
  }
  .fl_l {
    float:  left;
    margin-right: 20%;
    width: 40% !important;
    display: block !important;
  }
  .fl_r {
    float:  right;
    width: 40% !important;
    display: block !important;
  }
  .cl_address {
    width: 350px;
  }
  .cl_50 {
    width: 50px;
  }
  .cl_80 {
    width: 80px;
  }
  .cl_110 {
    width: 110px;
  }
  .cl_120 {
    max-width: 140px;
  }
  .cl_140 {
    max-width: 140px;
  }
  .flex-div{
    display: flex;
    input {
      padding-left: 3px;
    }
  }
  .cl_170 {
    max-width: 170px;
  }
  .font14 {
    font-size: $font-root !important;
  }
  .status-enabled {
    color: $color-jungle-green;
  }
  .status-disabled {
    color: $color_alizarin_crimson;
  }
  .content_modal_withdrawSetting {
    color: $color_mine_shaft;
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    line-height: $font_big_24;
  }
  .form-check {
    margin-top: 10px;
  }
  .form-check-label {
    color: #666666;
    font-weight: normal;
    font-family: $font-family-roboto;
  }

  .content_usersetting {

    .btn_enable {
      background-color: transparent;
      text-transform: uppercase;
      width: 70px;
      height: 25px;
      line-height: 20px;
      padding: 0px 0px;
      text-align: center;
      font-size: $font-smaller;
      font-weight: 600;
      color: $color-caribbean-green;
      border: 1px solid $color-caribbean-green;
      border-radius: 3px;
      transition: 0.5s;
      &:hover {
        background-color: $color-aquamarine;
        border-color: $color-aquamarine;
        color: $color_white;
        transition: 0.5s;
      }
    }
    .btn_disable {
      background-color: transparent;
      text-transform: uppercase;
      width: 70px;
      height: 25px;
      line-height: 20px;
      padding: 0px 0px;
      text-align: center;
      font-size: $font-smaller;
      font-weight: 600;
      color: $color_alizarin_crimson;
      border: 1px solid $color_alizarin_crimson;
      border-radius: 3px;
      transition: 0.5s;
      &:hover {
        background-color: $color_alizarin_crimson;
        border-color: $color_alizarin_crimson;
        color: $color_white;
        transition: 0.5s;
      }
    }
    .btn_view {
      float: right;
      background-color: $color-caribbean-green;
      text-transform: uppercase;
      width: 55px;
      height: 25px;
      line-height: 20px;
      padding: 0px 9px;
      text-align: center;
      font-size: $font-smaller;
      font-weight: 600;
      color: $color-white;
      border: 1px solid $color-caribbean-green;
      border-radius: 3px;
      transition: 0.5s;
      &:hover {
        background-color: $color-aquamarine;
        border-color: $color-aquamarine;
        color: $color_white;
        transition: 0.5s;
      }
    }

    .filter_container {
      .switch_group {
        float:right;
        .btn {
          height: 30px !important;
          margin-right: 5px;
          font-size: 12px;
        }
      }
      margin: 7px 0px 15px 0px;

      .title_item {
        color: $color-grey-dark;
        font-size: $font_root;
        font-family: $font-family-roboto;
        font-weight: 500;
        line-height: 30px;
        float: left;
        margin-right: 11px;
      }
      .search_box {
        padding-right: 0px;
        display: inline-block;
        float: right;
        width: 215px;
        max-width: 100%;
        margin-bottom: 0px;
        color: $color-grey-dusty;
        font-family: $font-family-roboto;
        .search_input {
          background-color: transparent;
          height: 28px;
          border: 1px solid $color_alto;
          padding: 4px 15px;
          line-height: 20px;
          width: 100%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          font-size: $font-small;
        }
      }
    }
    .btn_detail_user {
      float: right;
      background-color: transparent;
      text-transform: uppercase;
      width: 65px;
      height: 23px;
      line-height: 20px;
      padding: 0px 9px;
      text-align: center;
      font-size: $font-smaller;
      font-weight: 600;
      color: $color-jungle-green;
      border: 1px solid $color-caribbean-green;
      border-radius: 20px;
      margin-left: 15px;
      transition: 0.5s;
      &:hover {
        background-color: $color_corn;
        border-color: $color_corn;
        color: $color_white;
        transition: 0.5s;
      }
    }

    .item_email_setting {
      display: inline-block;
      position: relative;
      vertical-align: middle;
      .txt_email_setting {
        display: block;
        min-width: 130px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .tooltip_email_setting, .tooltip_address_setting {
        position: absolute;
        top: 0px;
        line-height: 20px;
        padding: 5px 20px;
        left: 100%;
        background-color: $color_white;
        white-space: nowrap;
        width: auto;
        z-index: 10;
        font-size: $font-smaller;
        font-weight: 500;
        color: $color_mine_shaft;
        transition: 0.5s;
        display: none;
        box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.4);
        &:after {
          right: 100%;
          top: 50%;
          border: solid transparent;
          content: " ";
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
          border-color: rgba(136, 183, 213, 0);
          border-right-color: $color_white;
          border-width: 5px;
          margin-top: -5px;
        }
      }
      &:hover {
        .tooltip_email_setting, .tooltip_address_setting {
          display: block;
          transition: 0.5s;
        }
      }
      .tooltip_address_setting {
        top: 100%;
        left: 0;
        &:after {
          left: 25%;
          bottom: 100%;
          top: initial;
          border-bottom-color: $color_white;
          border-right-color: transparent;
        }
      }
    }
  }

  @media only screen and (min-width: 1399px) {
     .content_usersetting .item_email_setting  .txt_email_setting {
        max-width: 250px;
     }
  }

  .box_table_UserSetting_right {
    min-height: 439px;
    background-color: $color_white;
    // max-height: 440px;
    overflow-y: auto;
    overflow-x: auto;
  }
  .box_UserSetting_right {
    // min-height: 440px;
    // background-color: $color_white;
  }
  .detail_balance {
    text-overflow: ellipsis;
    width: 150px;
    overflow: hidden;
    vertical-align: middle;
  }
  .detail_available_balance {
    text-overflow: ellipsis;
    width: 180px;
    overflow: hidden;
    vertical-align: middle;
  }


  .add-user-enable-fee {
    .is-error {
      color: $color_red_text !important;
    }
    .error-box {
      display: block;
      float: left;
      overflow: hidden;
      width: 100%;
      span {
        font-family: $font-family-roboto-medium !important;
      }
    }
  }

  .btn-add {
    height: 22px;
    border-radius: 3px;
    background-color: $color-caribbean-green;
    border: 1px solid $color-caribbean-green;
    font-size: $font_mini_mini;
    letter-spacing: 0.77px;
    color: $color-white;
    text-align: center;
    padding: 1px 12px;
    padding-top: 2px;
    display: block;
    float: right;
    i {
      font-size: 8px;
      position: relative;
      top: 0px;
      margin-right: 4px;
    }
    &:hover {
      border-color: $color-aquamarine;
      background-color: $color-aquamarine;
    }
  }
  .btn-save-table,
  .btn-close-table,
  .btn-edit-table {
    width: 20px;
    height: 20px;
    text-align: center;
    line-height: 20px;
    padding: 0px;
    text-align: center;
    border: 0px;
    background-color: transparent;
    font-size: 16px;
    color: $color-jungle-green;
    margin-left: 7px;
    i {
      &:before {
        color: $color-jungle-green;
      }
    }
  }

  .form-group-detail {
    .group-detail-form-group {
      .group-detail-form-label{
        width: 100%;
        color: $color-grey-dusty;
        float: left;
        font-weight: normal;
        font-family: $font-family-roboto;
        margin-bottom: 0px;
        margin-top: 10px;
      }

      .group-detail-form-value {
        width: 100%;
        padding: 8px 16px;
        border: 1px solid #dee3eb;
        height: 35px;
        line-height: 35px;
        border-radius: 3px;
        font-family: $font-family-roboto;
        // font-weight: 600;
        &:focus {
          border-color: $color-jungle-green;
        }
      }
      #input-airdrop-title {
        &.error-modal {
          border: solid 1px $color-red-main;
          border-radius: 3px;
        }
      }
      .textarea_input {
        height: 120px;
      }
    }
    .error-box {
      display: block;
      float: left;
      overflow: hidden;
      width: 100%;
      span {
        font-family: $font-family-roboto-medium !important;
      }
    }
    .is-error {
      color: #ff5252 !important;
    }
  }

  .filter_container {
    margin: 10px 0px 25px 0px;
    display: flex;
    justify-content: space-between;
    .title_item {
      color: $color_mine_shaft;
      font-size: $font_big;
      font-family: $font-family-roboto-medium;
    }
    .left-div {
      display: flex;
      .filter-item {
        .d-flex {
          display: flex;
          }
      }
    }
    .select-market {
      margin-left: 2% !important;
      font-family: $font-family-roboto;
      color: $color-grey-dark;
      font-size: $font-small;
      float: left;
    }
    .search_box {
      display: inline-block;
      float: right;
      width: 215px;
      max-width: 100%;
      .search_input {
        background-color: transparent;
        height: 28px;
        border: 1px solid $color_alto;
        padding: 4px 15px;
        line-height: 20px;
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: $font-small;
      }
    }
  }

  .table_UserSetting_right {
    background-color: $color_white;
    margin: 0;

    thead {
      font-size: $font-small;
      white-space: nowrap;
      th{
        position: relative;
        background-color: $color_white;
        line-height: 15px;
        font-weight: 500;
        color: $color_grey;
        padding: 5px 25px 4px 15px !important;
        border-bottom: 1px solid $color_alto;
        height: 38px;
        display: table-cell;
        vertical-align: inherit;
        font-family: $font-family-roboto-medium;
      }
      
    }
    tbody {
       tr {
        vertical-align: top;
        overflow-y: hidden;
        transition-property: height;
        transition-duration: 0.3s, 0.3s;
        transition-timing-function: ease, ease-in;
        height: auto;
        background-color: $color_white;
        &:last-child {
          td {
            .item_email_setting {
              vertical-align: middle;
              .tooltip_address_setting {
                top: inherit;
                bottom: 100%;
                &::after {
                  top: inherit;
                  bottom: -10px;
                  border-top-color: $color_white;
                  border-bottom-color: transparent;
                }
              }
            }
          }
        }

        td {
          height: 40px;
          overflow: initial;
          line-height: 23px;
          font-size: 14px !important;
          font-weight: 500;
          color: $color_mine_shaft;
          // padding: 8px 15px 4px 15px;
          border-top: 1px solid $color_catskill_white;
          font-family: $font-family-roboto;
          vertical-align: middle;
          padding: 4px 8px;
        }
      }
    }

  }
  .text-bold-coin{
    text-overflow: ellipsis;
    width: 53px;
    overflow: hidden;
  }
  .text-bold-address{
    text-overflow: ellipsis;
    width: 320px;
    overflow: hidden;
    vertical-align: middle;
  }
  .text-bold-balance{
    text-overflow: ellipsis;
    width: 83px;
    overflow: hidden;
  }
  .text-bold-available-balance{
    text-overflow: ellipsis;
    max-width: 340px;
    overflow: hidden;
  }
  .market-select-area {
    padding-left: 0;
    .select-market-text {
      margin-right: 20px;
      color: #333333;
      font-size: 18px;
      font-family: "Roboto-Medium", sans-serif;
      line-height: 28px;
      float: left;
    }
  }

  .col-left-user {
    min-width: 330px;
  }
  .col-right-user {
    max-width: calc(100% - 330px);
  }
  .error-modal {
    border: solid 1px $color-red-main;
    border-radius: 3px;
    
  }
  input[type='number'] {
    -moz-appearance:textfield;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  .error {
   border: solid 1px $color-red-main !important;
   border-radius: 3px !important;
  }

  .error-box {
    color : $color-red-main;
  }

  .clearfix-10 {
    display: block;
    height: 10px;
  }
  .clearfix-15 {
    display: block;
    height: 15px;
  }
  .clearfix-20 {
    display: block;
    height: 20px;
  }
  .clearfix-50 {
    display: block;
    height: 50px;
  }
  .after-none {
    &:after {
      display: none;
    }
  }

  
  .w_120px {
    width: 120px;
  }
  .w_170px {
    width: 170px;
  }
  .w_200px {
    width: 200px;
  }
  .input_table {
    border-radius: 3px;
    border: solid 1px $color_alto;
    height: 28px;
    padding: 4px 12px;
    font-size: $font_root;
    color: $color-grey-dark;
    line-height: 20px;
    background-color: transparent;
    &:hover,
    &:active,
    &:focus {
      border-color: $color-jungle-green;
    }
  }

  #base_airdrop_setting {
    .group_head_airdrop_setting {
      display: block;
      .title_item_airdrop_setting {
        display: inline-block;
        float: left;
        font-size: $font_big;
        color: $color-grey-dark;
        font-family: $font-family-roboto-medium;
        line-height: 20px;
        margin-right: 12px;
      }
    }
    .form-group-detail {
      .group-detail-form-group {
        .group-detail-form-label{
          width: 100%;
          color: $color-grey-dusty;
          float: left;
          font-weight: normal;
          font-family: $font-family-roboto;
          margin-bottom: 0px;
          margin-top: 10px;
        }

        .group-detail-form-value {
          width: 100%;
          padding: 8px 16px;
          border: 1px solid #dee3eb;
          height: 35px;
          line-height: 35px;
          border-radius: 3px;
          font-family: $font-family-roboto;
          // font-weight: 600;
          &:focus {
            border-color: $color-jungle-green;
          }
        }
        #input-airdrop-title {
          &.error-modal {
            border: solid 1px $color-red-main;
            border-radius: 3px;
          }
        }
        .textarea_input {
          height: 120px;
        }
      }
      .error-box {
        display: block;
        float: left;
        overflow: hidden;
        width: 100%;
        span {
          font-family: $font-family-roboto-medium !important;
        }
      }
      .is-error {
        color: #ff5252 !important;
      }
    }
    .filter_container {
      margin: 10px 0px 25px 0px;
      .title_item {
        color: $color_mine_shaft;
        font-size: $font_big;
        font-family: $font-family-roboto-medium;
        line-height: 28px;
        float: left;
      }
    }
    .line_setting {
      background-color: $color_alto;
      display: block;
      width: 100%;
      height: 1px;
    }
    .content_setting {
      .left_content_setting {
        width: 390px;
        display: block;
        float: left;
        padding-right: 30px;
        .group_airdrop_payout {
          display: block;
          width: 100%;
          .group_input_ardrop_payout {
            display: block;
            float: left;
            width: calc(100% - 110px);
            position: relative;
            .input_airdrop_payout {
              width: 100%;
              height: 35px;
              line-height: 20px;
              padding: 8px 60px 7px 15px;
              font-size: $font_root;
              font-family: $font-family-roboto-medium;
              color: $color-grey-dark;
              border-radius: 3px;
              border: 1px solid $color_alto;
              &:hover,
              &:active,
              &:focus {
                border-color: $color-jungle-green;
              }
              &[disabled] {
                background-color: $color-bright-gray;
                border-color: $color_alto;
                cursor: not-allowed;
                &:hover,
                &:active,
                &:focus {
                  border-color: $color_alto;
                }
              }
            }
            .coin_airdrop_payout {
              position: absolute;
              top: 2px;
              right: 12px;
              padding: 6px 0px 5px 5px;
              line-height: 20px;
              font-size: $font_root;
              font-family: $font-family-roboto-medium;
              color: $color-grey-dark;
            }
          }
          .form-check-label {
            width: 110px;
            display: block;
            float: right;
            min-height: 35px;
            margin: 0px;
            font-weight: 500;
            color: $color-grey-dark;
            font-size: $font_root;
            font-family: $font-family-roboto-medium;
            padding: 7px 0px 7px 15px;
            line-height: 20px;
            cursor: pointer;
            .icon_check_label {
              display: block;
              width: 12px;
              height: 12px;
              border-radius: 50%;
              position: relative;
              float: left;
              border: solid 1px #979797;
              margin: 4px 5px 4px 0px;
            }
            &.active {
              .icon_check_label {
                border: 4px solid #2dac91;
              }
              .txt-inactive{
                color: #2dac91;
              }
            }
            &[disabled] {
              cursor: not-allowed !important;
            }
            .icon_check_payout {
              margin: 4px 5px 4px 0px;
              float: left;
              position: relative;
              display: none;
            }
          }
        }
        .group_input_left {
          display: block;
          width: 100%;
          margin-bottom: 15px;
          label {
            font-family: $font-family-roboto;
            font-size: $font_root;
            color: $color-grey-dusty;
            margin-bottom: 4px;
            line-height: 20px;
            font-weight: 500;
          }
          .input_view {
            width: 100%;
            display: flex;
            position: relative;
            .input_left {
              width: 75%;
              margin-right: 15px;
              float: left;
              }
            input {
              width: 100%;
              height: 35px;
              line-height: 20px;
              padding: 8px 40px 7px 15px;
              font-size: $font_root;
              font-family: $font-family-roboto-medium;
              color: $color-grey-dark;
              border-radius: 3px;
              border: 1px solid $color_alto;
              &[disabled] {
                background-color: $color-bright-gray;
              }
            }
            .select_right_input {
              width: 85px;
              float: right;
              padding: 0;
              margin-left: auto;
              padding-left:24px;
            }
            .select_dropdown_right_input {
              width: 85px;
              float: right;
              padding: 0;
              margin-left: auto;
              background-color: $color_white;
            }
            .unit {
              position: absolute;
              line-height: 20px;
              font-size: $font_root;
              font-family: $font-family-roboto-medium;
              color: $color-grey-dark;
              right: 10px;
              // top: 8px;
            }
          }
        }
      }
      .right_content_setting {
        width: calc(100% - 390px);
        display: block;
        float: left;
        .w_90px {
          width: 90px;
        }
        .box_table_right_setting {
          display: block;
          width: 500px;
          max-width: 100%;
          background-color: $color-white;
          margin-top: 23px;
          table {
            width: 100%;
            th {
              height: 38px;
              padding: 9px 18px 9px 20px;
              line-height: 20px;
              border-bottom: 1px solid $color-white-smoke;
              font-size: $font-small;
              color: $color_grey;
              font-family: $font-family-roboto-medium;
              font-weight: 500;
            }
            td {
              height: 40px;
              line-height: 20px;
              padding: 10px 18px 10px 20px;
              font-size: $font_root;
              color: $color-grey-dark;
              font-family: $font-family-roboto;
            }
          }
        }
      }
      .btn-edit {
        width: 90px;
        height: 35px;
        margin-right: 15px;
        background-color: $color-caribbean-green;
        border-color: $color-jungle-green;
        border-radius: 3px;
        color: $color-white;
        text-transform: uppercase;
        letter-spacing: 0.92px;
        font-size: 12px;
        font-family: $font-family-roboto-medium;
        padding: 9px;
        margin-top: 10px;
        &:hover {
          background-color: $color-aquamarine;
          border-color: $color-aquamarine;
        }
      }
    } 
    .content_table_aridrop {
      .title_table_aridrop {
        color: $color_mine_shaft;
        font-size: $font_big;
        font-family: $font-family-roboto-medium;
        line-height: 28px;
        float: left;
      }
      .search_table_aridrop {
        width: 215px;
        height: 30px;
        border-radius: 3px;
        border: solid 1px $color_alto;
        background-color: transparent;
        font-size: 13px;
        color: $color-grey-dusty;
        font-family: $font-family-roboto;
        display: block;
        float: right;
        padding: 5px 15px;
        &:hover,
        &:active,
        &:focus {
          border-color: $color-jungle-green;
        }
      }
      .btn-add {
        height: 22px;
        border-radius: 3px;
        background-color: $color-caribbean-green;
        border: 1px solid $color-caribbean-green;
        font-size: $font_mini_mini;
        letter-spacing: 0.77px;
        color: $color-white;
        text-align: center;
        padding: 1px 12px;
        padding-top: 2px;
        display: block;
        float: right;
        i {
          font-size: 8px;
          position: relative;
          top: 0px;
          margin-right: 4px;
        }
        &:hover {
          border-color: $color-aquamarine;
          background-color: $color-aquamarine;
        }
      }
      .btn-save-table,
      .btn-close-table,
      .btn-edit-table {
        width: 20px;
        height: 20px;
        text-align: center;
        line-height: 20px;
        padding: 0px;
        text-align: center;
        border: 0px;
        background-color: transparent;
        font-size: 16px;
        color: $color-jungle-green;
        margin-left: 7px;
        i {
          &:before {
            color: $color-jungle-green;
          }
        }
      }
    }
  }

</style>


<style lang="scss">
@import "@/assets/sass/variables";
  #user_settings_fee {
    table {
      th {
        padding: 5px 25px 4px 10px !important;
      }
      td.row-1 {
        padding-left: 10px;
      }
    }
  }
  .auto_dividend{
    .tableContainer {
      .box-table4 {
        padding-bottom:300px !important;
      }
    }
  }
  .switch_group {
    .btn {
      border: 0px !important;
    }
  }
  .container-input {
    input {
      padding-left: 3px;
    }
    .date-picker-input {
      input {
        background-color: #ffffff !important;
      }
    }
  }
  .datatable {
    thead {
      white-space: nowrap;
    }
    .pay-wallet {
      .group_search_select {
        .button_drop_search {
          width: 188px !important;
          background-color: #ffffff;
        }
      }
    }
    .pay-coin {
      .group_search_select {
        .button_drop_search {
          background-color: #ffffff;
        }
      }
    }
  }
  .h-35 {
    input {
      width: 144px !important;
      height: 35px !important;
      padding: 0 10px 0px 35px;
      line-height: 28px;
      background-image: url('@/assets/images/icon/date-time.svg') !important;
      background-size: 16px;
      background-position: center left 10px;
      background-repeat: no-repeat !important;
      border: 1px solid #d2d6de;
      box-shadow: none;
    }
  }
  .date_dis_input {
    input {
      background-color: #ececec !important;
    }
  }
  .date_active_input {
    input {
      background-color: #fff !important;
    }
  }
  .dis_input {
    background-color: #ececec !important;
  }
  .active_input {
    background-color: #fff !important;
  }
  .date-picker-input{
    input {
      background-color: red;
    }
  }
  #input_time {
    .input_time {
      width: 100%;
    }
    .display-time {
      width: 100%;
      height: 35px;
      line-height: 20px;
      padding: 8px 40px 7px 15px;
      font-size: 14px;
      font-family: "Roboto-Medium", sans-serif;
      color: #333333;
      border-radius: 3px;
      border: 1px solid #CFCFCF;
    }
  }
  #base_airdrop_setting {
    .sc_search_select {
      float: left;
      &[disabled] {
        background-color: $color-bright-gray;
      }
      .group_search_select {
        .button_drop_search {
          height: 35px !important;
          // width: 100% !important;
          padding: 8px 10px !important;
          // background-color: $color-bright-gray;
          span {
            font-size: $font-small;
            font-family: $font-family-roboto;
          }
          i {
            color: $color-grey-dusty;
          }

        }
        .list_search_select {
          li {
            font-size: $font-small;
            font-family: $font-family-roboto;
            &:hover, &:active, &:focus {
              color: $color-jungle-green;
            }
          }
        }
      }
    }
    .list_button_modal {
      padding-top: 20px;
    }
    #table_airdrop_content {
      .sc_search_select{
        width: 55px;
      }
    }
  }
</style>