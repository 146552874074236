<template>
  <div class="order-history clearfix mg_bot50" id="order-history">
    <div class="order-history-filter">
      <div class="filter-template">
        <div class='label-text'>{{ $t('funds.balances.from') }}</div>
        <date-picker v-model="startDate" class="date-picker-input" :format="customFormatter"></date-picker>
      </div>
      <div class="filter-template">
        <div class='label-text'>{{ $t('funds.history.to') }}</div>
        <date-picker v-model="endDate" class="date-picker-input" :format="customFormatter"></date-picker>
      </div>
      <div class="filter-template pair-selector">
        <div class="label-text text-pair date-text">{{ $t('orders.open_order.pair') }}</div>
        <div class="coin-selector toolbar-element2" v-click-outside="clickOut">
          <input type="text" @click="showCoinOption()" @keyup="onSearchCoin()"
            :placeholder="$t('order.order_history.coin')" @keyup.enter="search" class="form-control coin-input"
            v-model="filters.coin">
          <div class="coin-option" v-if="isShowCoinOption">
            <span class="option" v-for="coin in coins" @click="onSelectCoin(coin)" :key="coin">{{ coin | uppercase
              }}</span>
          </div>
        </div>
        <span class="bw-element">/</span>
        <div class="toolbar-element2">
          <select-box v-model="filters.currency" :placeholder="$t('order.order_history.all')" :options="markets" />
        </div>
      </div>
      <div class="filter-template type">
        <div class="label-text date-text">{{ $t('orders.open_order.type') }}</div>
        <div class="toolbar-element3">
          <select-box v-model="filters.tradeType" :placeholder="$t('order.order_history.all')" :options="tradeType" />
        </div>
      </div>
      <div class="filter-template status">
        <div class="label-text date-text">{{ $t('orders.open_order.status') }}</div>
        <div class="toolbar-element3">
          <select-box v-model="filters.status" :placeholder="$t('order.order_history.all')" :options="statusType" />
        </div>
      </div>
      <div class="filter-template">
        <div class="label-text">&nbsp;</div>
        <input title type="text" :placeholder="$t('account.detail.affiliate.inputplace.search')" @keyup.enter="search" class="form-control search_symbol"
          name="searchKey" v-model="filters.searchKey">
      </div>
      <div class="filter-template">
        <button class="btn btn-reset btn-search" @click="search()">{{ $t('orders.open_order.search') }}</button>
        <button class="btn btn-reset" @click="refresh()">{{ $t('orders.open_order.reset') }}</button>
        <button v-if="!accountId" class="btn btn-download" @click="downloadExcel()">
          <img src="@/assets/images/download-icon.svg" width="12" />
          {{ $t('orders.open_order.data') }}
        </button>
      </div>
    </div>

    <div class="table-history" id="table_main_order_history">
      <data-table-4 :getData="getData" ref="datatable" :column="11" class="scroll" :inLine="true"
        :total-user="totalRecords" :limit="10">
        <th data-sort-field="orderID" class="cl1">{{ $t('orders.order_history.order_id') }}</th>
        <th v-if="!accountId" data-sort-field="accountID" class="cl2 text-center">{{ $t('orders.order_history.account_id') }}</th>
        <th data-sort-field="coin" class="cl3 ">
          {{ $t('orders.order_history.pair') }}
        </th>
        <th data-sort-field="trade_type" class="cl4 ">
          {{ $t('orders.order_history.side') }}
        </th>
        <th data-sort-field="type" class="cl5 ">
          {{ $t('orders.order_history.type') }}
        </th>
        <th data-sort-field="quantity" class="cl6 ">
          {{ $t('orders.order_history.filled') }}/{{ $t('orders.order_history.quantity') }}
        </th>
        <th data-sort-field="executed_price" class="cl7 ">{{ $t('orders.order_history.average') }}</th>
        <th data-sort-field="time" class="cl8 text-left ">{{ $t('orders.order_history.time') }}</th>
        <th data-sort-field="status" class="cl9 ">{{ $t('orders.order_history.status') }}</th>
        <th>{{ $t('orders.order_history.action') }}</th>
        <template slot="body" slot-scope="props">
          <tr
            :class="{ 'opacity-row': window._.get(props.item, 'status') == 'canceled' && window._.get(props.item, 'executed_quantity') == 0 }">
            <td class="cl1">
              <div>{{ window._.get(props.item, 'orderID') }}</div>
            </td>
            <td v-if="!accountId" class="cl2 text">
              <div class="item_email_user text-center">
                <span v-if="window._.get(props.item, 'accountID')" class="txt_email_user" @click="detailAccount(props.item)">{{ window._.get(props.item, 'accountID') }}</span>
                <span v-else class="txt_email_user" @click="detailAccount(props.item)">{{ window._.get(props.item, 'user_id') }}</span>
              </div>
            </td>
            <td class="cl3">
              <div>{{ window._.get(props.item, 'coin') | uppercase }}/{{ window._.get(props.item, 'currency') |
          uppercase
                }}</div>
            </td>
            <td class="cl4">
              <div
                :class="{ buy: window._.get(props.item, 'trade_type') === 'buy', sell: window._.get(props.item, 'trade_type') === 'sell' }">
                {{ window._.get(props.item, 'trade_type') | tradeType }}</div>
            </td>
            <td class="cl5">
              <div>{{ props.item | order_type }}</div>
            </td>
            <td class="cl6">
              <div>{{ window._.get(props.item, 'executed_quantity') |
          formatCurrencyAmountDecimal(window._.get(props.item, 'coin'), "0") }} /</div>
              <div>{{ window._.get(props.item, 'quantity') | formatCurrencyAmountDecimal(window._.get(props.item,
          'coin'), "0")
                }}</div>
            </td>
            <td class="cl7">
              {{ window._.get(props.item, 'executed_price') | to2Precision }}
            </td>
            <td class="cl8 text-left">
              <div>{{ window._.get(props.item, 'time') | formatTimeStamp('YYYY-MM-DD') }}</div>
              <div>{{ window._.get(props.item, 'time') | formatTimeStamp('HH:mm:ss') }}</div>
            </td>
            <td class="cl9"
              :class="{ 'opacity-row': props.item.status == 'canceled' && props.item.executed_quantity == 0 }">
              <div class="status" :class="`${props.item.status}-status`">{{ props.item | order_status }}</div>
            </td>
            <td class="cl11"
              :class="{ 'opacity-row': props.item.status == 'canceled' && props.item.executed_quantity == 0 }">
              <div class="action-wrap">
                <img class="btn-detail" src="@/assets/images/icon/detail-icon.svg" width="20"
                  @click="handleOpen(props.item)" />
              </div>
            </td>
          </tr>
        </template>
      </data-table-4>
    </div>
    <modal name="orderDetailModal" :title="$t('orders.order_history.trade_history')">
      <div slot="body" class="body-popup" style="font-size: 10pt">
        <data-table :getData="getTradeHistory" :column="9" class="scroll table_popup_order_history">
          <th data-sort-field="order_Id" class="text-c">{{ $t('orders.order_history.order_id') }}</th>
          <th data-sort-field="created_at">{{ $t('orders.order_history.date') }}</th>
          <th data-sort-field="Email">{{ $t('orders.order_history.email') }}</th>
          <th data-sort-field="coin">{{ $t('orders.order_history.pair') }}</th>
          <th data-sort-field="transaction_type">{{ $t('orders.order_history.side') }}</th>
          <th data-sort-field="price">{{ $t('orders.order_history.price') }}</th>
          <th data-sort-field="quantity">{{ $t('orders.order_history.filled') }}</th>
          <th data-sort-field="Fee">{{ $t('orders.order_history.fee') }}</th>
          <th data-sort-field="amount" class="arrow-afleft"><span>{{ $t('orders.order_history.total') }}</span></th>
          <template slot="body" slot-scope="props">
            <tr>
              <td class="f-td">
                <!-- <span >{{ window._.get(props.item,'order_Id')}}</span> -->
                <span v-if="window._.get(props.item, 'buyer_email') == orderDetail.email">{{
          window._.get(props.item, 'sell_order_id') || '' }}</span>
                <span v-else>{{ window._.get(props.item, 'buy_order_id') || '' }}</span>
              </td>
              <td>{{ window._.get(props.item, 'created_at') | timestampToDate }}</td>
              <td>
                <!-- <span class="text-wrap" >{{ window._.get(props.item,'Email') }}</span> -->
                <span v-if="window._.get(props.item, 'buyer_email') == orderDetail.email">{{
          window._.get(props.item, 'seller_email') || '' }}</span>
                <span v-else>{{ window._.get(props.item, 'buyer_email') || '' }}</span>
              </td>
              <td>{{ window._.get(props.item, 'coin') | uppercase }}/{{ window._.get(props.item, 'currency') | uppercase
                }}</td>
              <td v-if="orderDetail.trade_type == 'buy'" class="type-sell">{{ 'Sell' || '' }}</td>
              <td v-else class="type-buy">{{ 'Buy' || '' }}</td>
              <!-- <td v-if="window._.get(props.item,'transaction_type') =='buy'" class ="type-buy">{{ window._.get(props.item,'transaction_type') | tradeType }}</td>
              <td v-else class ="type-sell">{{ window._.get(props.item,'transaction_type') | tradeType }}</td> -->
              <td>
                <span class="text-wrap"
                  v-if="window._.get(props.item, 'type') == 'market' || window._.get(props.item, 'type') == 'stop_market'">Market</span>
                <span class="text-wrap" v-else>{{ window._.get(props.item, 'price') | convertToBigNumber |
          formatCurrencyAmount(window._.get(props.item, 'currency'), "0") }}</span>
              </td>
              <td>{{ window._.get(props.item, 'quantity') | formatCurrencyAmount(window._.get(props.item, 'coin')) }}
              </td>
              <td>
                <span>{{ window._.get(props.item, 'Fee') | formatCurrencyAmount(window._.get(props.item, 'coin'), '0')
                  }}</span>
              </td>
              <td>{{ window._.get(props.item, 'amount') | formatCurrencyAmount(window._.get(props.item, 'coin')) }}</td>
            </tr>
          </template>
        </data-table>

      </div>
      <div slot="footer"></div>
    </modal>
    <right-slide :dataDetail="dataDetail" :rightSlide="rightSlide" :onClose="handleClose" />
  </div>
</template>

<script>
import _ from "lodash";
import rf from '../../lib/RequestFactory';
import Modal from '../../components/Modal';
import DatePicker from 'vuejs-datepicker';
import moment from 'moment';
import MasterdataUtils from '@/desktop/common/MasterdataUtils';
import COMMON_CONST from '../../common/Const';
import SelectBox from '../../components/SelectBox';
import DataTable4 from "../../components/DataTable4";
import RightSlide from "../../components/RightSlide";

export default {
  components: {
    Modal,
    DatePicker,
    SelectBox,
    DataTable4,
    RightSlide
  },
  props: {
    accountId: {
      type: String
    }
  },
  data() {
    return {
      titlePage: window.i18n.t('menu.spot.order_history'),
      startDate: moment().subtract(6, "day").toDate(),
      endDate: new Date(),
      window: window,
      coin: '',
      filters: {
        coin: window.i18n.t('orders.open_order.all'),
        currency: '',
        tradeType: '',
        searchKey: '',
      },
      // idDetail : null,
      orderDetail: null,
      hideCanceled: false,
      coins: [],
      coinMasters: [],
      statusType: [],
      tradeType: [],
      isShowCoinOption: false,
      trades: [],
      commonConst: COMMON_CONST,
      markets: [],
      rightSlide: false,
      dataDetail: null,
      totalRecords: 0
    }
  },
  methods: {
    search() {
      let filterParams = {
        start_date: moment(this.startDate).startOf('day').format('x'),
        end_date: moment(this.endDate).endOf('day').format('x')
      };
      if (this.startDate > this.endDate && this.startDate.toDateString() != this.endDate.toDateString()) {
        this.$toastr('error', this.$t('common.date.warning'));
        return;
      }
      if (this.filters.searchKey) {
        filterParams.search_key = this.filters.searchKey;
      }
      if (this.hideCanceled) {
        filterParams.hide_canceled = 1;
      }
      if (this.filters.coin) {
        filterParams.coin = this.filters.coin === window.i18n.t('orders.open_order.all') ? '' : this.filters.coin;
      }
      if (this.filters.currency && this.filters.currency.id !== '') {
        filterParams.currency = this.filters.currency.id;
      }
      if (this.filters.tradeType && this.filters.tradeType.id != '') {
        filterParams.type = this.filters.tradeType.id;
      }
      if (this.filters.status && this.filters.status.id != '') {
        filterParams.status = this.filters.status.id;
      }
      this.$refs.datatable.filter(filterParams);
    },
    refresh() {
      // this.requestCoinList();
      this.resetInput();
      this.$refs.datatable.refresh();
    },
    resetInput() {
      this.filters = Object.assign(this.filters, { coin: window.i18n.t('orders.open_order.all'), currency: '', tradeType: '', status: '', searchKey: '' });
      this.hideCanceled = false;
      this.startDate = moment().subtract(6, "day").toDate();
      this.endDate = new Date();
      $(".datatable").find("th").attr("data-sort-order", "");
    },
    onSearchCoin() {
      this.coins = _.filter(this.coinMasters, (item) => {
        return _.isMatch(item.toUpperCase(), this.filters.coin.toUpperCase());
      });
    },
    onSelectCoin(coin) {
      this.isShowCoinOption = false;
      this.filters.coin = coin === window.i18n.t('orders.open_order.all') ? window.i18n.t('orders.open_order.all') : coin.toUpperCase();
    },
    clickOut() {
      this.isShowCoinOption = false;
    },
    showCoinOption() {
      this.isShowCoinOption = true;
      this.coins = this.coinMasters;
    },
    getData(params) {
      let defaultParams = {
        ...params,
        start_date: moment(this.startDate).startOf('day').format('x'),
        end_date: moment(this.endDate).endOf('day').format('x'),
        size: params.limit || 10
      };
      delete defaultParams.limit

      if (this.accountId) return this.getOrderHistoryByAccount(defaultParams)

      const response = rf.getRequest('OrderRequest').getOrderHistory(defaultParams);
      response.then(response => {
        this.totalRecords = response.data.total;
      })
      return response;
    },
    getOrderHistoryByAccount(params) {
      const response = rf.getRequest('UserRequest').getOrderHistorySpot(this.accountId, params);
      response.then(response => {
        this.totalRecords = response.data.total;
      })
      return response;
    },
    /*
    getMarkets() {
      return window._.map(COMMON_CONST.MARKETS, item => {
        return { id: item, name: item.toUpperCase() };
      });
    },
    */
    requestCoinList() {
      rf.getRequest('CoinSettingRequest').getCoinList().then(res => {
        this.coinMasters = window._.map(res.data, 'coin')
      });
    },
    async viewDetail(order) {
      this.orderActive = order.id;
      this.orderDetail = order;
      // this.orderActive = id;
      // this.idDetail = id;
      CommonModal.show('orderDetailModal', {
        position: 'center',
        mask: true,
        buttons: [
          {
            label: 'OK',
            focused: true,
            style: {
              width: '140px',
              background: 'rgb(0, 112, 192)'
            },
            callback: () => {
              CommonModal.hide('orderDetailModal');
              this.orderActive = null;
              this.orderDetail = null;
            }
          }
        ]
      });
    },
    getTradeHistory(params) {
      let orderId = this.orderDetail.id;
      return rf.getRequest('OrderRequest').getTradeByOrder(orderId, params);
    },
    customFormatter(date) {
      return moment(date).format('YYYY - MM - DD');
    },
    handleClose() {
      this.dataDetail = null
      this.rightSlide = false
    },
    handleOpen(data) {
      console.log(data)
      this.dataDetail = data
      this.rightSlide = true
    },
    getOptionData() {
      this.tradeType = [{ id: '', name: window.i18n.t('orders.open_order.all') }]
      this.statusType = [{ id: '', name: window.i18n.t('orders.open_order.all') }]
      this.markets = [{ id: '', name: window.i18n.t('orders.open_order.all') }]
      rf.getRequest('OrderRequest')
        .getOptionFilterOrderHistory('type')
        .then(res => {
          res?.data.map((item) => {
            this.tradeType.push({
              id: item,
              name: window.i18n.t(`common.order_type.${item}`)
            })
          })
        })
      rf.getRequest('OrderRequest')
        .getOptionFilterOrderHistory('status')
        .then(res => {
          res?.data.map((item) => {
            this.statusType.push({
              id: item,
              name: window.i18n.t(`common.order_status.${item}`)
            })
          })
        })
      rf.getRequest('OrderRequest')
        .getOptionFilterOrderHistory('currency')
        .then(res => {
          res?.data.map((item) => {
            this.markets.push({
              id: item,
              name: item.toUpperCase()
            })
          })
        })
      rf.getRequest('OrderRequest')
        .getOptionFilterOrderHistory('coin')
        .then(res => {
          this.coinMasters = [window.i18n.t('orders.open_order.all'), ...res?.data]
        })
    },
    downloadExcel() {
      let filterParams = {
        start_date: moment(this.startDate)
          .startOf("day")
          .format("x"),
        end_date: moment(this.endDate)
          .endOf("day")
          .format("x")
      };
      if (this.startDate > this.endDate && this.startDate.toDateString() != this.endDate.toDateString()) {
        this.$toastr("error", this.$t("common.date.warning"));
        return;
      }
      if (this.filters.searchKey) {
        filterParams.search_key = this.filters.searchKey;
      }
      if (this.filters.coin) {
        filterParams.coin = this.filters.coin;
      }
      if (this.filters.currency && this.filters.currency.id != 'all') {
        filterParams.currency = this.filters.currency.id;
      }
      if (this.filters.tradeType && this.filters.tradeType.id != 'all') {
        filterParams.trade_type = this.filters.tradeType.id;
      }
      if (this.filters.status && this.filters.status.id != 'all') {
        filterParams.status = this.filters.status.id;
      }

      rf.getRequest('OrderRequest').exportDataOrderHistory(filterParams).then(res => {
        console.log(res)
        if (res?.success) {
          window.open(`${process.env.VUE_APP_API_URL}/${res.data.path}`, '_blank')
        }
      })
    },
    detailAccount(row) {
      this.$router.push({
        path: '/account/detail',
        query: { id: row.user_id },
      });
    },
  },
  watch: {
    hideCanceled: function () {
      this.search();
    }
  },
  mounted() {
    this.$emit('EVENT_PAGE_CHANGE', this);
    // this.requestCoinList();
    this.getOptionData();
  },
  created() {
    // rf.getRequest('AdminRequest').getPriceGroupCurrency().then(res => {
    //   try {
    //     this.markets.push({ id: "all", name: window.i18n.t('orders.open_order.all') });
    //     window._.map(res.data, item => {
    //       if (typeof item.currency !== 'undefined') {
    //         this.markets.push({ id: item.currency, name: item.currency.toUpperCase() });
    //       }
    //     });
    //   }
    //   catch (ex) {
    //     console.error(ex);
    //   }
    // });
  }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.date-picker-input {
  display: inline-block;
  float: left;

  ::v-deep {
    input {
      padding: 0 5px 0px 36px;
      width: 135px !important;
      height: 32px;
      border: 1px solid #C7CBD3 !important;
      border-radius: 10px !important;
      background-image: url('@/assets/images/icon/date-time.svg') !important;
      background-color: $color-white !important;
      background-size: 12px !important;
      background-position: center left 12px !important;
      background-repeat: no-repeat !important;
      color: $dark-1;

      &:hover,
      &:active,
      &:focus {
        border-color: $color-jungle-green;
        z-index: 9;
      }
    }
  }
}

.no-left-radius {
  input {
    border-radius: 0px 3px 3px 0px;
    margin-left: -1px;
  }
}

.no-right-radius {
  input {
    border-radius: 3px 0px 0px 3px;
  }
}

.modal-content .modal-footer {
  text-align: center;
  margin-top: 30px;
}

#order-history {
  font-family: $font_family_root;

  .sc_search_select {
    .group_search_select {
      .button_drop_search {
        height: 32px;
        padding: 6px 10px 6px 10px;
        border-radius: 10px;
        font-size: $font-small;
        font-family: $font-family-roboto;
        background-color: $color-white;
        width: 100%;

        span {
          font-size: $font-small;
        }

        i.icon-arrow1 {
          margin-left: 5px;
          color: $color_dove_gray;
        }

        &:hover {
          border-color: $color-jungle-green;

          i.icon-arrow1 {
            color: $color-jungle-green;
          }
        }
      }

      .box_list_search_select {
        .list_search_select {
          border-radius: 10px;

          li {
            font-family: $font-family-roboto;
            font-size: $font-small;
            padding: 6px 12px;

            &:hover,
            &:active,
            &:focus {
              color: $color-jungle-green;
            }
          }
        }
      }
    }
  }

  .modal-dialog {
    min-width: 80%;
    min-height: 400px;
    position: relative;
    top: -10%;

  }

  .modal-content {
    min-height: 400px;

    .modal-footer {
      text-align: center;
      margin-top: 30px;
    }

    .modal-header {
      padding: 30px 16px 16px 20px;

      .modal-title {
        font-family: Roboto-Medium;
        font-size: 18px;
        font-weight: 500;
        color: $color-grey-dark;
        text-transform: none;
      }
    }

    .modal-body {
      padding: 0;
    }
  }
}
</style>

<style lang="scss" scoped>
@import "@/assets/sass/variables";

.d-flex {
  display: flex;
  align-items: end;
}

.w-100 {
  width: 100%;
}

.buy {
  color: $text-color-jade !important;
}

.sell {
  color: $text-color-red !important;
}

.table-history {
  table {
    thead {
      th {
        &:first-child {
          width: 7%;
          min-width: 120px;
        }

        &:nth-child(2) {
          width: 9%;
          min-width: 120px;
        }

        &:nth-child(3) {
          width: 8%;
          min-width: 150px;
        }

        &:nth-child(4) {
          width: 9%;
          min-width: 100px;
        }

        &:nth-child(5) {
          width: 11%;
          min-width: 140px;
        }

        &:nth-child(6) {
          width: 13%;
          min-width: 140px;
        }

        &:nth-child(7) {
          width: 10%;
          min-width: 200px;
        }

        &:nth-child(8) {
          width: 9%;
          min-width: 200px;
        }

        &:nth-child(9) {
          width: 9%;
          min-width: 150px;
        }

        &:nth-child(10) {
          min-width: 70px;

          &::after {
            display: none;
          }
        }

        &:nth-child(11) {
          width: 8%;
          min-width: 90px;
        }
      }
    }

    tbody {
      td {
        &:first-child {
          min-width: 70px;
        }

        &:nth-child(2) {
          width: 9%;
          min-width: 100px;
        }

        &:nth-child(3) {
          width: 8%;
          min-width: 150px;
        }

        &:nth-child(4) {
          width: 9%;
          min-width: 100px;
        }

        &:nth-child(5) {
          width: 11%;
          min-width: 120px;
        }

        &:nth-child(6) {
          width: 13%;
          min-width: 125px;
        }

        &:nth-child(7) {
          width: 10%;
          min-width: 200px;
        }

        &:nth-child(8) {
          width: 9%;
          min-width: 200px;
        }

        &:nth-child(9) {
          width: 9%;
          min-width: 150px;
        }

        &:nth-child(10) {
          width: 150px;
          min-width: 50px;
          max-width: 50px;
        }

        &:nth-child(11) {
          width: 8%;
          min-width: 90px;
        }

        .txt_email_user {
          text-decoration: underline;
          color: $color-blue-custom;
          cursor: pointer;
        }

        .status {
          min-height: 32px;
          width: 100px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: $color-wood-smoke;
          border-radius: 4px;
          padding-left: 4px;
          padding-right: 4px;

          &.pending-status {
            background-color: $color-pending-status;
          }

          &.open-status {
            background-color: $color-open-status;
          }

          &.partial-status {
            background-color: $color-partial-status;
          }

          &.filled-status {
            background-color: $color-filled-status;
          }

          &.canceled-status {
            background-color: $color-cancel-status;
          }
        }

        .btn-detail {
          cursor: pointer;
        }
      }
    }
  }
}

.no-left-radius {
  input {
    border-radius: 0px 3px 3px 0px;
  }
}

.no-right-radius {
  input {
    border-radius: 3px 0px 0px 3px;
  }
}

.datatable {
  font-family: $font-family-roboto !important;
}

ul {
  list-style-type: none;
  padding: 0px;
}

.clearfix-40 {
  display: block;
  clear: both;
  height: 40px;
}

.select_transaction_container {
  padding: 10px 0px 20px 0px;

  .input-radio {
    label {
      display: inline;
      font-weight: normal;
      text-align: center;
      margin-right: 25px;

      span {
        font-size: $font_big;
      }

      input[type="radio"] {
        display: inline-block;
        margin-top: 0px;
        position: relative;
        top: -2px;
      }
    }
  }
}

.form-control {
  background: $color_concrete;
  height: 32px;
  font-size: $font-small;
  padding-left: 11px;
  padding-right: 0;

  &:hover,
  &:focus {
    border-color: $color-jungle-green;
  }
}

.datatable {
  button.btnForm.bt-action {
    padding-left: 5px !important;
    padding-right: 5px !important;
    font-weight: 400 !important;
  }
}

.buy {
  color: $text-color-jade;
}

.sell {
  color: $text-color-red;
}

.toolbar-element2 {
  display: inline-block;
  float: left;
  width: 80px;
}

.toolbar-element3 {
  display: inline-block;
  float: left;
  width: 100%;
}

td {
  font-family: $font-family-roboto;
}

.table-history {
  margin-top: 24px;
}

#order-history {
  .order-history-filter {
    display: flex;
    flex-wrap: wrap;
  }

  .filter-template {
    margin-right: 15px;

    &:nth-child(3) {
      min-width: 180px;
    }

    &:nth-child(4) {
      min-width: 120px;
    }

    &:nth-child(5) {
      min-width: 120px;
      display: block;
    }

    &:nth-child(6) {
      display: flex;
      height: 100%;
      padding-top: 27px;
    }

    &:nth-child(7) {
      display: flex;
      height: 100%;
      padding-top: 27px;
    }

    .label-text {
      color: $dark-1;
      font-weight: 500;
      font-size: 14px;
      margin-bottom: 7px;
      padding-left: 5px;
      font-family: $mulish-regular;
    }

    .date-picker-input {
      display: inline-block;
      float: left;

      ::v-deep {
        input {
          padding: 0 5px 0px 36px;
          width: 135px !important;
          height: 32px;
          border: 1px solid #C7CBD3 !important;
          border-radius: 10px !important;
          background-image: url('@/assets/images/icon/date-time.svg') !important;
          background-color: $color-white !important;
          background-size: 12px !important;
          background-position: center left 12px !important;
          background-repeat: no-repeat !important;
          color: $dark-1;

          &:hover,
          &:active,
          &:focus {
            border-color: $color-jungle-green;
            z-index: 9;
          }
        }
      }
    }

    .btn-reset {
      background: $color-caribbean-green;
      border-radius: 10px;
      color: $color_white;
      font-weight: 700;
      width: 78px;
      height: 32px;
    }

    .btn-search {
      margin: 0 10px;
    }

    .btn-download {
      background: $color-caribbean-green;
      border-radius: 10px;
      color: $color_white;
      font-weight: 700;
      width: 95px;
      height: 32px;
      margin-left: 10px;
    }

    .search_symbol {
      background: $color-white;
      border: 1px solid #C7CBD3 !important;
      border-radius: 10px !important;
      width: 197px;
      height: 32px;
    }

    .coin-selector {
      width: 75px;
      position: relative;

      .coin-input {
        display: inline-block;
        margin: 0 5px;
        margin-right: 0px;
        border-radius: 10px !important;
        background-color: $color-white;
        height: 32px;
        width: 100%;
      }

      .coin-option {
        background-color: $color-white;
        max-height: 210px;
        display: block;
        overflow-y: auto;
        margin-top: 5px;
        position: absolute;
        width: 79px;
        left: 5px;
        z-index: 10;
        padding: 12px 0px;
        box-shadow: 0px 3px 12px rgba(49, 49, 49, 0.51);
        border-radius: 10px;

        &:after {
          bottom: 100%;
          left: 50%;
          border: solid transparent;
          content: " ";
          display: block;
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
          border-color: rgba(136, 183, 213, 0);
          border-bottom-color: $color_white;
          border-width: 5px;
          margin-left: -5px;
        }

        .option {
          display: block;
          width: 100%;
          line-height: 20px;
          cursor: pointer;
          padding: 3px 14px;
          overflow: hidden;

          &:hover {
            color: $color-jungle-green;
          }
        }
      }
    }

    .form-control {
      background: $color-white-smoke;
      height: 32px;
      font-size: 13px;
      padding-left: 11px;
      padding-right: 0;
      border-radius: 10px !important;
    }

    .bw-element {
      float: left;
      font-size: 15px;
      margin-left: 10px;
      height: 32px;
      line-height: 32px;
      margin-right: 5px;
      color: $color-grey-dusty;
    }
  }
}

.tab-header {
  display: block;
  width: 100%;
  float: left;
  margin: 10px 0px 23px 0px;

  ul {
    list-style: none;
    padding-left: 0px;

    li {
      float: left;
      margin-right: 25px;
      font-size: $font_big;
      color: $color-grey-dark;
      display: block;

      >a {
        color: $color-grey-dark;
        font-family: $font-family-roboto-medium;
        font-weight: 500;
        font-size: $font_big;
        display: block;
        float: right;

        &::before {
          content: "";
          display: inline-block;
          width: 12px;
          height: 12px;
          border-radius: 50%;
          border: solid 1px #979797;
        }

        span {
          padding-left: 5px;
        }
      }

      &.active {
        >a {
          &::before {
            border: solid 4px $color-jungle-green;
          }

          color: $color-jungle-green;
        }
      }
    }
  }
}

.input-dropdown {
  position: relative;

  input {
    background: $color-white;
    border: 1px solid #C7CBD3 !important;
    border-radius: 10px !important;
    width: 110px;
    height: 32px;
    cursor: pointer;
  }

  .icon-coin-input {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    color: #667186;

    i {
      font-size: 22px;
    }
  }

  .coin-option {
    position: absolute;
    top: 37px;
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0px 4px 15px rgba(0, 19, 54, 0.15);
    border-radius: 10px;
    padding: 17px 0;
    z-index: 3;

    &:after {
      bottom: 100%;
      left: 50%;
      border: solid transparent;
      content: " ";
      display: block;
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-color: rgba(136, 183, 213, 0);
      border-bottom-color: $color-white;
      border-width: 5px;
      margin-left: -5px;
    }

    .option {
      display: flex;
      align-items: center;
      width: 100%;
      line-height: normal;
      cursor: pointer;
      padding-left: 14px;
      font-size: 14px;
      font-weight: 500;
      overflow: hidden;
      color: #001336;
      height: 32px;

      &:hover {
        background: $color-athens;
      }
    }
  }

  #order-history .sc_search_select .group_search_select .button_drop_search {
    height: 34px;
  }
}
</style>
