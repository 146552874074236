import BaseRequest from '../lib/BaseRequest'

export default class AdminRequest extends BaseRequest {

  clearCache() {
    let url = '/admin/api/clear-cache';
    return this.post(url);
  }

  getBankAccounts(params) {
    let url = '/admin/api/bank-accounts';
    return this.get(url);
  }

  createBankAccount(params) {
    let url = '/admin/api/bank-account';
    return this.post(url, params);
  }

  updateBankAccount(params) {
    let url = '/admin/api/bank-account';
    return this.put(url, params);
  }

  deleteBankAccount(params) {
    let url = '/admin/api/bank-account';
    return this.del(url, params);
  }

  getCustomerUsdTotal(params) {
    let url = '/admin/api/customer-usd-total';
    return this.get(url, params);
  }

  getWallet(currency, params) {
    let url = '/admin/api/wallets/' + currency;
    return this.get(url, params);
  }

  createWallet(params) {
    let url = '/admin/api/wallets';
    return this.post(url, params);
  }

  removeWallet(id) {
    let url = '/admin/api/wallets/' + id;
    return this.del(url);
  }

  getUsers(params) {
    let url = '/admin/api/users2';
    return this.get(url, params);
  }

  getTransactions(params) {
    let url = '/admin/api/transactions';
    return this.get(url, params);
  }

  getUsdTransaction(params) {
    let url = '/admin/api/usd-transactions';
    return this.get(url, params);
  }

  getOrderFutureRecords(params) {
    let url = 'api/v1/order';
    return this.getFuture(url, params);
  }
  cancelOrderFutureRecord(params) {
    let url = 'api/v1/order/admin-cancel-order';
    return this.delFuture(url, params);
  }
  // deleteOrderFutureRecords(id) {
  //   let url = 'api/v1/order/' + id;
  //   return this.del(url, id);
  // }
  exportOrderFutureRecords(params) {
    let url = 'api/v1/order/excel';
    return this.getFuture(url, params);
  }
  getFutureOrderHistoryRecords(params) {
    let url = "api/v1/order";
    return this.getFuture(url, params);
  }
  getFutureTradeHistoryRecords(params) {
    let url = 'api/v1/trade';
    return this.getFuture(url, params);
  }
  exportTradeHistoryFutureRecords(params) {
    let url = 'api/v1/trade/trade-history/excel';
    return this.getFuture(url, params);
  }
  getFuturePositionsHistoryRecords(params) {
    let url = 'api/v1/positions/position-history';
    return this.getFuture(url, params);
  }
  exportFuturePositionsHistoryRecords(params) {
    let url = 'api/v1/positions/position-history/excel';
    return this.getFuture(url, params);
  }
  getPositionFutureRecords(params) {
    let url = 'api/v1/positions/admin';
    return this.getFuture(url, params);
  }
  exportFutureOpenPositionsRecords(params) {
    let url = 'api/v1/positions/admin/excel';
    return this.getFuture(url, params);
  }
  get24hPrices(params) {
    let url = 'api/v1/ticker/24h';
    return this.getFuture(url, params);
  }
  getTradeFutureRecords(params) {
    let url = 'api/v1/trade';
    return this.getFuture(url, params);
  }

  rejectUsdTransaction(params) {
    let url = '/admin/api/reject-usd-transaction';
    return this.put(url, params);
  }

  confirmUsdTransaction(params) {
    let url = '/admin/api/confirm-usd-transaction';
    return this.put(url, params);
  }

  sendTransaction(params) {
    let url = '/admin/api/send-transaction';
    return this.put(url, params);
  }

  cancelTransaction(params) {
    let url = '/admin/api/cancel-transaction';
    return this.put(url, params);
  }

  getUserBalances(params) {
    let url = '/admin/api/user_balances';
    return this.get(url, params);
  }

  getUserAccessHistories(params) {
    let url = '/admin/api/user_access_histories';
    return this.get(url, params);
  }

  getOrderHistory(params) {
    let url = '/admin/api/orders';
    return this.get(url, params);
  }

  getAMALNet(params) {
    let url = '/admin/api/amal-net';
    return this.get(url, params);
  }

  getProfit(params) {
    let url = '/admin/api/profit';
    return this.get(url, params);
  }

  getWithdrawalLimits() {
    let url = '/admin/api/withdrawal-limits';
    return this.get(url);
  }

  updateWithdrawalLimit(params) {
    let url = '/admin/api/withdrawal-limit';
    return this.put(url, params);
  }

  updateCoinStepUnitByUsd(params) {
    let url = '/admin/api/currency-step-unit-usd';
    return this.put(url, params);
  }

  getCurrencyStepUnitsByUsd(params) {
    let url = '/admin/api/currency-step-unit-usd';
    return this.get(url, params);
  }

  getDepositPageBadge() {
    let url = '/admin/api/deposit-badge';
    return this.get(url);
  }

  getMasterDataFuture() {
    let url = 'api/v1/master-data';
    return this.getFuture(url);
  }

  getInstruments() {
    let url = 'api/v1/instruments';
    return this.getFuture(url);
  }

  getWithdrawPageBadge() {
    let url = '/admin/api/withdraw-badge';
    return this.get(url);
  }

  getUserKyc(params, meta) {
    let url = '/admin/api/user-kyc';
    return this.get(url, params, meta);
  }

  getDetailUserKyc(params) {
    let url = '/admin/api/user-kyc/detail';
    return this.get(url, params);
  }

  verifyKyc(params) {
    let url = '/admin/api/user-kyc/verify';
    return this.put(url, params);
  }

  rejectKyc(params) {
    let url = '/admin/api/user-kyc/reject';
    return this.put(url, params);
  }

  getUserKycSumsub(params, meta) {
    let url = '/admin/api/user-kyc/sumsub';
    return this.get(url, params, meta);
  }

  getDetailUserKycSumsub(params) {
    let url = '/admin/api/user-kyc/sumsub/detail';
    return this.get(url, params);
  }

  verifyKycSumsub(params) {
    let url = '/admin/api/user-kyc/sumsub/verify';
    return this.put(url, params);
  }

  rejectKycSumsub(params) {
    let url = '/admin/api/user-kyc/sumsub/reject';
    return this.put(url, params);
  }

  getNotices(params) {
    let url = '/admin/api/admin-notice';
    return this.get(url, params);
  }

  getEditNotice(params) {
    let url = '/admin/api/admin-notice/edit';
    return this.get(url, params);
  }

  updateNotice(params) {
    let url = '/admin/api/admin-notice/update';
    return this.post(url, params);
  }

  createNotice(params) {
    let url = '/admin/api/admin-notice/create';
    return this.post(url, params);
  }

  deleteNotice(id) {
    let url = '/admin/api/admin-notice/delete/' + id;
    return this.del(url, id);
  }

  getUserLoginHistory(params) {
      let url = '/admin/api/user-login-history/';
      return this.get(url, params);
  }
  getListEmailMarketing(params) {
      let url = '/admin/api/email-marketing';
      return this.get(url, params);
  }

  editEmailMarketing(params) {
        let url = '/admin/api/email-marketing/edit';
        return this.get(url, params);
  }

  updateEmailMarketing(params) {
      let url = '/admin/api/email-marketing/update';
      return this.post(url, params);
  }
  createEmailMarketing(params) {
      let url = '/admin/api/email-marketing/create';
      return this.post(url, params);
  }

  deleteEmailMarketing(id) {
      let url = '/admin/api/email-marketing/delete/' + id;
      return this.del(url, id);
  }

  sendEmailMarketing(params) {
      let url = '/admin/api/email-marketing/send';
      return this.post(url, params);
  }

  getEmailMarketingSendedHistories(id, params) {
    let url = '/admin/api/email-marketing/sent-emails/' + id;
    return this.get(url, params);
  }

  getCountries() {
      const url = '/admin/api/countries';
      return this.get(url);
  }

  getAdmins(params) {
    const url = '/admin/api/administrators';
    return this.get(url, params);
  }

  getAdministratorById(id) {
    const url = `/admin/api/administrators/${id}`;
    return this.get(url);
  }

  updateAdministrator(params) {
    const url = '/admin/api/administrators/update';
    return this.post(url, params);
  }

  createAdministrator(params) {
    const url = '/admin/api/administrators/create';
    return this.post(url, params);
  }

  deleteAdministrator(id) {
    const url = '/admin/api/administrators/delete';
    return this.del(url, { id: id });
  }

  getAllUser () {
    const url = '/api/get-all';
    return this.get(url);
  }

  getAdmin (params) {
    let url = `/api/get-admin/`;
    return this.get(url, params);
  }

  getPriceGroupCurrency() {
    let url = '/admin/api/price-group-currency';
    return this.get(url);
  }

  getAllCoin() {
    let url = '/admin/api/get-all-coin';
    return this.get(url);
  }

  getMarketFeeSetting(params) {
    let url = '/admin/api/market-fee-setting';
    return this.get(url, params);
  }

  updateMarketFeeSetting(params) {
    let url = `/admin/api/market-fee-setting`;
    return this.put(url, params);
  }

  getEnableFeeSetting(params) {
    let url = '/admin/api/enable-fee-setting';
    return this.get(url, params);
  }

  getUserEnableFeeSetting(params) {
    let url = '/admin/api/enable-fee-setting/user-settings';
    return this.get(url, params);
  }

  createUserEnableFeeSetting(params) {
    let url = '/admin/api/enable-fee-setting/add-user-settings';
    return this.post(url, params);
  }

  updateUserEnableFeeSetting(params) {
    let url = '/admin/api/enable-fee-setting/update-user-settings';
    return this.put(url, params);
  }

  deleteUserEnableFeeSetting(params) {
    let url = '/admin/api/enable-fee-setting/user-settings/' + params.id + '/delete';
    return this.post(url, params);
  }

  getEnableWithdrawalSetting(params) {
    let url = '/admin/api/enable-withdrawal-setting';
    return this.get(url, params);
  }

  getUserEnableWithdrawalSetting(params) {
    let url = '/admin/api/enable-withdrawal-setting/user-settings';
    return this.get(url, params);
  }

  createUserEnableWithdrawalSetting(params) {
    let url = '/admin/api/enable-withdrawal-setting/add-user-settings';
    return this.post(url, params);
  }

  updateUserEnableWithdrawalSetting(params) {
    let url = '/admin/api/enable-withdrawal-setting/update-user-settings';
    return this.put(url, params);
  }

  deleteUserEnableWithdrawalSetting(params) {
    let url = '/admin/api/enable-withdrawal-setting/user-settings/' + params.id + '/delete';
    return this.post(url, params);
  }

  getEnableTradingSetting(params) {
    let url = '/admin/api/enable-trading-setting';
    return this.get(url, params);
  }

  getUserEnableTradingSetting(params) {
    let url = '/admin/api/enable-trading-setting/user-settings';
    return this.get(url, params);
  }

  createUserEnableTradingSetting(params) {
    let url = '/admin/api/enable-trading-setting/add-user-settings';
    return this.post(url, params);
  }

  updateUserEnableTradingSetting(params) {
    let url = '/admin/api/enable-trading-setting/update-user-settings';
    return this.put(url, params);
  }

  updateEnableCoinSetting(params) {
    let url = '/admin/api/enable-trading-setting/update-coin-settings';
    return this.put(url, params);
  }

  deleteUserEnableTradingSetting(params) {
    let url = '/admin/api/enable-trading-setting/user-settings/' + params.id + '/delete';
    return this.post(url, params);
  }

  changeStatusCircuitBreakerSetting(params) {
    let url = '/admin/api/circuit-breaker/change-status';
    return this.post(url, params);
  }

  getCircuitBreakerSetting(params) {
    let url = '/admin/api/circuit-breaker/settings';
    return this.get(url, params);
  }

  updateCircuitBreakerSetting(params) {
    let url = '/admin/api/circuit-breaker/settings';
    return this.post(url, params);
  }

  getCoinPairSetting(params) {
    let url = '/admin/api/circuit-breaker/coin-pair-setting';
    return this.get(url, params);
  }

  updateCoinPairSetting(params) {
    let url = '/admin/api/circuit-breaker/update-coin-pair-setting';
    return this.post(url, params);
  }
  getColdWalletSetting() {
    let url = '/admin/api/cold-wallet-setting';
    return this.get(url);
  }

  updateColdWalletSetting(params) {
    let url = `/admin/api/cold-wallet-setting`;
    return this.put(url, params);
  }

  sendMailUpdateColdWallet(params) {
    let url = '/admin/api/cold-wallet-setting/send-mail-to-update-cold-wallet';
    return this.post(url, params);
  }

  getListUserGroupSetting(params) {
    let url = '/admin/api/user-group-setting';
    return this.get(url, params);
  }

  deleteGroupSetting(id) {
    let url = '/admin/api/user-group-setting/' + id;
    return this.del(url);
  }

  addGroupSetting(params) {
    let url = '/admin/api/user-group-setting';
    return this.post(url, params);
  }

  updateGroupSetting(params) {
    let url = '/admin/api/user-group-setting/update';
    return this.post(url, params);
  }

  getListUserGroup(params) {
    let url = '/admin/api/user-group';
    return this.get(url, params);
  }

  updateUserGroup(params) {
    let url = '/admin/api/user-group';
    return this.post(url, params);
  }

  deleteUserGroup(params) {
    let url = '/admin/api/user-group/delete';
    return this.post(url, params);
  }

  getListUserAirdropSetting(params) {
    let url = '/admin/api/airdrop/user-settings';
    return this.get(url, params);
  }

  getPairs(params) {
    let url = '/admin/api/airdrop/get-pairs';
    return this.get(url, params);
  }

  getAutoDividendSetting(params) {
    let url = '/admin/api/auto-dividend/setting';
    return this.get(url, params);
  }

  updateAllStatus(params) {
    let url = '/admin/api/auto-dividend/update-all-status';
    return this.post(url, params);
  }

  updateStatus(params) {
    let url = '/admin/api/auto-dividend/update-status';
    return this.post(url, params);
  }
  
  resetMaxBonus(params) {
    let url = '/admin/api/auto-dividend/reset-max-bonus';
    return this.post(url, params);
  }

  updateAutoDividendSetting(params) {
    let url = '/admin/api/auto-dividend/update-setting';
    return this.post(url, params);
  }

  updateUserAirdropSetting(params) {
    let id = params['user_id'];
    let url = '/admin/api/airdrop/user-settings/'+id+'/update';
    return this.put(url,params);
  }
  
  deleteUserAirdropSetting(params) {
    let id = params['user_id'];
    let url = '/admin/api/airdrop/user-settings/'+id+'/delete';
    return this.del(url,params);
  }

  changeStatusAirdropSetting(params) {
    let url = '/admin/api/airdrop/change-status';
    return this.post(url,params);
  }

  changeStatusFeeWallet(params) {
    let url = '/admin/api/airdrop/change-status-fee-wallet';
    return this.post(url,params);
  }
  changeStatusEnableFeeWallet(params) {
    let url = '/admin/api/airdrop/change-status-enable-fee-wallet';
    return this.post(url,params);
  }

  createAirdropUserSetting(params) {
    let url = '/admin/api/airdrop/user-settings';
    return this.post(url,params);
  }


  getAirdropSetting() {
    let url = '/admin/api/airdrop/settings';
    return this.get(url);
  }

  getAirdropSettingToRender(timezone) {
    let url = '/admin/api/airdrop/render-settings';
    return this.get(url,timezone);
  }

  getAllAirdropSetting() {
    let url = '/admin/api/airdrop/all-settings';
    return this.get(url);
  }

  updateAirdropSetting(params) {
    let url = '/admin/api/airdrop/settings';
    return this.post(url,params);
  }

  getAirdropHistory(params) {
    let url = '/admin/api/airdrop/history';
    return this.get(url,params);
  }

  getAirdropPaymentHistory(params) {
    let url = '/admin/api/airdrop/payment-history';
    return this.get(url,params);
  }

  getCashbackHistory(params) {
    let url = '/admin/api/airdrop/cashback-history';
    return this.get(url,params);
  }

  getTotalBonus(params) {
    let url = '/admin/api/airdrop/total-bonus';
    return this.get(url,params);
  }

  // list trading volume ranking

  getTradingVolumneRanking(params) {
    let url = '/admin/api/airdrop/volume-ranking';
    return this.get(url, params);
  }

  // Apply bonus
  applyBonusBalance(params) {
    let url = '/admin/api/bonus-balance';
    return this.post(url, params);
  }

  // Refund bonus
  refundBonusBalance(params) {
    let url = '/admin/api/refund-bonus-balance';
    return this.post(url, params);
  }

  // Get manual dividend history

  getManualDividendHistory(params) {
    let url = '/admin/api/airdrop/manual-dividend-history'
    return this.get(url, params);
  }


  // Get manual dividend history

  getAutoDividendHistory(params) {
    let url = '/admin/api/airdrop/auto-dividend-history'
    return this.get(url, params);
  }

  checkOldAdminPassword(params) {
    let url = '/admin/admin-group/checkOldPassword';
    return this.post(url, params);
  }
  
  changeAdminPassword(params) {
    let url = '/admin/admin-group/changeAdminPassword';
    return this.put(url,params);
  }
  
  changeStatusReferralSetting(params) {
    let url = '/admin/api/referral/change-status';
    return this.post(url, params);
  }

  getReferralSetting() {
    let url = '/admin/api/referral/settings';
    return this.get(url);
  }

  updateReferralSetting(params) {
    let url = '/admin/api/referral/settings';
    return this.post(url, params);
  }

  getReferralHistory(params) {
    let url = '/admin/api/referral/history';
    return this.get(url, params);
  }

  getInformationCoins() {
    let url = '/admin/api/get-infomation-coins';
    return this.get(url);
  }

  getInstrument(params) {
    let url = '/admin/api/instruments/all';
    return this.get(url, params);
  }

  getContractSettings (params) {
    let url = '/admin/api/margin/contract-settings';
    return this.get(url, params);
  }

  updateContractSetting (params) {
    let url = '/admin/api/margin/update-contract';
    return this.put(url, params);
  }

  updateFeeStatusSetting (params) {
    let url = '/admin/api/margin/update-fee-status';
    return this.put(url, params);
  }

  getTradingSettings (params) {
    let url = '/admin/api/margin/trading-settings';
    return this.get(url, params);
  }

  updateTradingSetting (params) {
    let url = '/admin/api/margin/update-trading-setting';
    return this.put(url, params);
  }

  addTradingSetting (params) {
    let url = '/admin/api/margin/add-trading-setting';
    return this.post(url, params);
  }

  deleteTradingSetting (params) {
    let url = '/admin/api/margin/delete-trading-setting';
    return this.post(url, params);
  }

  getInstrumentFuture(params) {
    let url = 'api/v1/instruments/list-contract';
    return this.getFuture(url, params);
  }

  createInstrumentFuture(params) {
    let url = 'api/v1/instruments';
    return this.postFuture(url, params);
  }
  getDetailInstrument(params) {
    let url = 'api/v1/instruments/detail-contract';
    return this.getFuture(url, params);
  }

  updateInstrument(params) {
    let url = 'api/v1/instruments/update-contract';
    return this.putFuture(url, params);
  }

  getAsset(params) {
    let url = 'api/v1/assets';
    return this.getFuture(url, params);
  }

  getWithdrawTransaction(params) {
    let url = '/admin/spot/transactions/withdraw';
    return this.get(url, params);
  }

  exportWithdrawTransaction(params) {
    let url = '/admin/spot/transactions/withdraw/export';
    return this.get(url, params);
  }

  getOptionDataWithdraw(params) {
    let url = `/admin/spot/transactions/withdraw/${params}`;
    return this.get(url);
  }

  getDepositTransaction(params) {
    let url = '/admin/spot/transactions/deposit';
    return this.get(url, params);
  }

  exportDepositTransaction(params) {
    let url = '/admin/spot/transactions/deposit/export';
    return this.get(url, params);
  }

  getOptionDataDeposit(params) {
    let url = `/admin/spot/transactions/deposit/${params}`;
    return this.get(url);
  }

  getAccountDataDashboard(params) {
    let url = '/admin/dashboard/logs/account/history';
    return this.get(url, params);
  }

  getTransactionDataDashboard(params) {
    let url = `/admin/dashboard/logs/transactions/history/${params}`;
    return this.get(url);
  }

  getKycList(params) {
    let url = '/admin/account/kyc';
    return this.get(url, params);
  }

  getOptionDataKYC(params) {
    let url = `/admin/account/kyc/params/${params}`;
    return this.get(url);
  }

  getKycDetail(id) {
    let url = `/admin/account/kyc/${id}`;
    return this.get(url);
  }

  verifyKycUser(id) {
    let url = `/admin/account/kyc/${id}?status=verified`;
    return this.post(url);
  }

  rejectKycUser(id) {
    let url = `/admin/account/kyc/${id}?status=rejected`;
    return this.post(url);
  }

  exportKYCData(params) {
    let url = '/admin/account/kyc/export';
    return this.get(url, params);
  }

  // profile API
  getPairSpotProfile(type) {
    let url = `/admin/account/coin/${type}/trade/spot`;
    return this.get(url);
  }

  getSpotProfile(id) {
    let url = `/admin/account/${id}/spot/setting/profile`;
    return this.get(url);
  }

  updateSpotProfile(id, params) {
    let url = `/admin/account/${id}/spot/setting/profile`;
    return this.post(url, params);
  }

  getPairFuturesProfile() {
    let url = `api/v1/instruments`;
    return this.getFuture(url);
  }

  getFuturesProfile(params) {
    let url = `api/v1/user/trade-setting`;
    return this.getFuture(url, params);
  }

  updateFuturesProfile(params) {
    let url = `api/v1/user/trade-setting`;
    return this.postFuture(url, params);
  }

  // orderbook futures

  getOrderbookSymbol(params) {
    let url = `api/v1/orderbook/${params}`;
    return this.getFuture(url);
  }
}
