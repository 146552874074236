import { render, staticRenderFns } from "./CoinEdit.vue?vue&type=template&id=19689c21&scoped=true"
import script from "./CoinEdit.vue?vue&type=script&lang=js"
export * from "./CoinEdit.vue?vue&type=script&lang=js"
import style0 from "./CoinEdit.vue?vue&type=style&index=0&id=19689c21&prod&lang=scss&scoped=true"
import style1 from "./CoinEdit.vue?vue&type=style&index=1&id=19689c21&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19689c21",
  null
  
)

export default component.exports