<template>
  <aside id="slider" class="main-sidebar">
    <section class="sidebar">
      <div class="title_setting_sidebar">
      </div>
      <ul data-widget="tree" class="sidebar-menu">
        <slide-item v-for="(item, index) in slideMenuItems" :data="item" :key="index" :type="item.type"
          :isHeader="item.isHeader" :icon="item.icon" :name="item.name" :badge="item.badge" :items="item.items"
          :router="item.router" :link="item.link" :params="item.params" />
      </ul>
    </section>
  </aside>
</template>

<script>
import { mapGetters } from 'vuex';
import SlideItem from '@/components/SlideItem';
import rf from '../lib/RequestFactory';
import Const from './Const';
import RouterUtils from './RouterUtils';

export default {
  components: {
    SlideItem
  },
  props: {
    slideMenuItems: {
      type: Array,
      default: []
    },
  },
  computed: {
    ...mapGetters([
      'currentUser'
    ])
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/variables";

.main-sidebar {
  background-color: $color-eden-dark;
}

@media (max-width: 767px) {
  .main-sidebar {
    padding-top: 0;
  }
  .main-sidebar__mobile {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}
</style>

<style lang="scss">
@import "@/assets/sass/variables";

#slider {
  .sidebar-menu {
    .RouteAlone {

      .pull-right-container {
        display: none;
      }

      &.activeRouteAlone {

        .name_item {
          color: $color_white;
        }
      }
    }
  }
}
</style>
