import { render, staticRenderFns } from "./Kyc.vue?vue&type=template&id=317dc114&scoped=true"
import script from "./Kyc.vue?vue&type=script&lang=js"
export * from "./Kyc.vue?vue&type=script&lang=js"
import style0 from "./Kyc.vue?vue&type=style&index=0&id=317dc114&prod&lang=scss&scoped=true"
import style1 from "./Kyc.vue?vue&type=style&index=1&id=317dc114&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "317dc114",
  null
  
)

export default component.exports