<template>
    <div class="account-transaction" id="account-transaction">
        <div class="tabs-container">
            <div class="tab-item" v-for="(value, index) in tabs" :key="index"
                :class="index == tabActive && 'tab-active'" @click="tabActive = index">{{
                    value }}</div>
        </div>
        <div class="d-flex w-100" style="margin: 24px 0;">
            <div class="filter-template">
                <div class='label-text'>{{ $t('funds.balances.from') }}</div>
                <date-picker v-model="filters.startDate" class="date-picker-input"
                    :format="customFormatter"></date-picker>
            </div>
            <div class="filter-template">
                <div class='label-text'>{{ $t('funds.history.to') }}</div>
                <date-picker v-model="filters.endDate" class="date-picker-input"
                    :format="customFormatter"></date-picker>
            </div>
            <div v-if="tabActive != 2" class="filter-template type">
                <div class="label-text date-text">{{ $t('orders.open_order.type') }}</div>
                <div class="toolbar-element2">
                    <select-box v-model="filters.type" :placeholder="$t('order.order_history.all')"
                        :options="TypesData" />
                </div>
            </div>
            <div v-if="tabActive != 2" class="filter-template status">
                <div class="label-text date-text">{{ $t('orders.open_order.status') }}</div>
                <div class="toolbar-element2">
                    <select-box v-model="filters.status" :placeholder="$t('order.order_history.all')"
                        :options="StatusData" />
                </div>
            </div>
        </div>
        <data-table-4 v-if="tabActive == 0 || tabActive == 1" :getData="getData" :limit="10" :column='9'
            :onPageLoad="true" @DataTable:finish="onDatatableFinish" ref="datatable" class="scroll">
            <th class="text-left " data-sort-field="id">{{ $t('account.transaction.id') }}</th>
            <th class="text-left ">{{ $t('account.transaction.type') }}</th>
            <th class="text-left " data-sort-field="amount">{{ $t('account.transaction.amount') }}</th>
            <th class="text-left" data-sort-field="receiver">{{ $t('account.transaction.receiver') }}</th>
            <th class="text-left " data-sort-field="hash">{{ $t("account.transaction.hash") }}</th>
            <th class="text-left ">{{ $t("account.transaction.network") }}</th>
            <th class="text-left " data-sort-field="status">{{ $t("account.transaction.status") }}</th>
            <th class="text-left " data-sort-field="created_time">{{ $t("account.transaction.created_time") }}</th>
            <th class="text-left ">{{ $t("account.transaction.action") }}</th>
            <template slot="body" slot-scope="props">
                <tr>
                    <td class="text-left" style="min-width: 100px;">
                        {{ props.item.id }}
                    </td>
                    <td class="text-left" style="min-width: 130px;">
                        {{ props.item.type | uppercase }}
                    </td>
                    <td class="text-left" style="min-width: 160px;">
                        {{ Number(props.item.amount) | formatCurrencyAmountDecimal(8, '0') }} {{ props.item.currency |
                            uppercase
                        }}
                    </td>
                    <td v-if="props.item.type == 'INTERNAL'" class="text-left text_receiver" style="min-width: 211px;">
                        {{ props.item.receiver }}
                    </td>
                    <td v-else class="text-left" style="min-width: 211px;">
                        {{ props.item.receiver }}
                        <copy-button :text-to-copy="`${props.item.receiver}`"></copy-button>
                    </td>
                    <td v-if="props.item.type == 'INTERNAL'" class="text-left text_receiver" style="min-width: 211px;">
                        --
                    </td>
                    <td v-else class="text-left" style="min-width: 211px;">
                        {{ props.item.hash }}
                        <copy-button :text-to-copy="props.item.hash"></copy-button>
                    </td>
                    <td class="text-left">
                        {{ networks[props.item.network_id] || "" }}
                    </td>
                    <td class="text-left">
                        <div class="status" :class="`${props.item.status}-status`">{{ props.item.status |
                            transactionStatus }}</div>
                    </td>
                    <td class="text-left" style="min-width: 172px;">
                        <div>{{ new Date(props.item.creation_time) | formatTimeStamp('YYYY-MM-DD') }}</div>
                        <div>{{ new Date(props.item.creation_time) | formatTimeStamp('HH:mm:ss') }}</div>
                    </td>
                    <td class="text-left" style="min-width: 100px;">
                        <div class="action-wrap">
                            <img src="@/assets/images/icon/detail-icon.svg" width="20"
                                @click="handleOpen(props.item)" />
                        </div>
                    </td>
                </tr>
            </template>
        </data-table-4>
        <data-table-4 v-else :getData="getData" :limit="10" :column='9' :onPageLoad="true"
            @DataTable:finish="onDatatableFinish" ref="datatable" class="scroll">
            <th class="text-left " data-sort-field="id">{{ $t('account.transaction.id') }}</th>
            <th class="text-left " data-sort-field="source">{{ $t('account.transaction.from') }}</th>
            <th class="text-left" data-sort-field="destination">{{ $t('account.transaction.to') }}</th>
            <th class="text-left " data-sort-field="amount">{{ $t("account.transaction.amount") }}</th>
            <th class="text-left " data-sort-field="created_time">{{ $t("account.transaction.created_time") }}</th>
            <th class="text-left " data-sort-field="status">{{ $t("account.transaction.status") }}</th>
            <template slot="body" slot-scope="props">
                <tr>
                    <td class="text-left" style="min-width: 150px;">
                        {{ props.item.id }}
                    </td>
                    <td class="text-left" style="min-width: 134px;">
                        {{ props.item.source | uppercase }}
                    </td>
                    <td class="text-left" style="min-width: 134px;">
                        {{ props.item.destination | uppercase }}
                    </td>
                    <td class="text-left">
                        {{ Number(props.item.amount) | formatCurrencyAmountDecimal(2, '0') }} {{ props.item.coin |
                            uppercase
                        }}
                    </td>
                    <td class="text-left" style="min-width: 172px;">
                        <div>{{ new Date(props.item.created_at) | formatTimeStamp('YYYY-MM-DD') }}</div>
                        <div>{{ new Date(props.item.created_at) | formatTimeStamp('HH:mm:ss') }}</div>
                    </td>
                    <td class="text-left">
                        <div class="status" :class="`${props.item.status}-status`">{{ props.item.status |
                            transactionStatus }}</div>
                    </td>
                </tr>
            </template>
        </data-table-4>
        <right-slide :dataDetail="dataDetail" :rightSlide="rightSlide" :onClose="handleClose" />
    </div>
</template>

<script>

import moment from "moment";
import rf from "../../lib/RequestFactory";
import RightSlide from "../../components/RightSlide";
import DataTable4 from "../../components/DataTable4.vue";
import DatePicker from 'vuejs-datepicker';
import CopyButton from "../../components/CopyButton.vue";
import SelectBox from "../../components/SelectBox.vue";

export default {
    name: "Account Detail",
    components: {
        RightSlide,
        DataTable4,
        DatePicker,
        CopyButton,
        SelectBox
    },
    data() {
        return {
            tabs: [
                window.i18n.t("account.transaction.withdraw_transaction"),
                window.i18n.t("account.transaction.deposit_transaction"),
                window.i18n.t("account.transaction.transfer_transaction"),
            ],
            TypesData: [
                { id: '', name: window.i18n.t("order.order_history.all") },
                { name: 'INTERNAL', id: 'internal' },
                { name: 'EXTERNAL', id: 'external' },
            ],
            StatusData: [
                { id: '', name: window.i18n.t("order.order_history.all") },
                { id: 'success', name: window.i18n.t("order.order_history.success") },
                { id: 'cancel', name: window.i18n.t("order.order_history.cancel") },
            ],
            filters: {
                type: null,
                status: null,
                startDate: moment().subtract(1, 'week').toDate(),
                endDate: moment().toDate(),
            },

            totalRecords: 0,
            tabActive: 0,
            rightSlide: false,
            dataDetail: null,
            networks: {}
        }
    },
    watch: {
        tabActive: function () {
            this.resetInput();
        },
        filters: {
            handler(value) {
                this.search();
            },
            deep: true
        }
    },
    methods: {
        search() {
            let filterParams = {
                start_date: moment(this.filters.startDate)
                    .startOf("day").format('x'),
                end_date: moment(this.filters.endDate)
                    .endOf("day").format('x')
            };
            if (this.customFormatter(this.filters.startDate) > this.customFormatter(this.filters.endDate)) {
                this.$toastr("error", this.$t("common.date.warning"));
                return;
            }
            this.$refs.datatable.filter(filterParams)
        },
        getData(params) {
            const filterParams = {
                ...params,
                start_date: moment(this.filters.startDate)
                    .startOf("day").format('x'),
                end_date: moment(this.filters.endDate)
                    .endOf("day").format('x'),
                size: params.limit
            }

            if (this.filters.type) {
                filterParams.type = this.filters.type.id;
            }
            if (this.filters.status) {
                filterParams.status = this.filters.status.id;
            }

            delete filterParams.limit
            const id = this.$route.query.id;
            let response = {}

            if (this.tabActive === 0) response = this.getWithdrawTransaction(id, filterParams)
            if (this.tabActive === 1) response = this.getDepositTransaction(id, filterParams)
            if (this.tabActive === 2) response = this.getTransferTransaction(id, filterParams)

            response.then((res) => {
                this.totalRecords = res.data.total
            })
            return response
        },
        getWithdrawTransaction(id, params) {
            return rf.getRequest('TransactionRequest').getTransactionWithdrawByAccount(id, params);
        },
        getDepositTransaction(id, params) {
            return rf.getRequest('TransactionRequest').getTransactionDepositByAccount(id, params);
        },
        getTransferTransaction(id, params) {
            return rf.getRequest('TransactionRequest').getTransactionTransferByAccount(id, params);
        },
        customFormatter(date) {
            return moment(date).format('YYYY-MM-DD');
        },
        resetInput() {
            this.filters = Object.assign(this.filters, {
                status: null,
                type: null,
                startDate: moment().subtract(1, 'week').toDate(),
                endDate: moment().toDate()
            });
            $(".datatable").find("th").attr("data-sort-order", "");
        },
        onDatatableFinish() {
            const datatable = this.$refs.datatable;
        },
        handleClose() {
            this.dataDetail = null
            this.rightSlide = false
        },
        handleOpen(data) {
            this.dataDetail = data
            this.rightSlide = true
        },
        getDataNetworks() {
            rf.getRequest('MultiChainRequest').getNetworks({ limit: 50 })
                .then(res => {
                    res.data.data.map((network) => {
                        this.networks[`${network.id}`] = `${network.currency.toUpperCase()} | ${network.name}`
                    })
                })
        }
    },
    created() {
        this.getDataNetworks();
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/variables";

.account-transaction {
    .tabs-container {
        display: flex;
        margin: 20px 0;
        margin-bottom: 15px;

        .tab-item {
            font-weight: 600;
            padding: 16px 10px;
            padding-right: 30px;
            color: $color-gray-dark;
            cursor: pointer;

            &:hover {
                background-color: $color-bright-gray;
            }

            &.tab-active {
                border-bottom: 2px solid $color-wood-smoke;
                color: $color-wood-smoke;
            }
        }
    }

    .d-flex {
        display: flex;
    }

    .filter-template {
        margin-right: 15px;

        .label-text {
            color: $dark-1;
            font-weight: 500;
            font-size: 14px;
            margin-bottom: 7px;
        }

        .date-picker-input {
            display: inline-block;
            float: left;

            ::v-deep {
                input {
                    padding: 0 5px 0px 36px;
                    width: 135px !important;
                    height: 32px;
                    border: 1px solid #C7CBD3 !important;
                    border-radius: 10px !important;
                    background-image: url('@/assets/images/icon/date-time.svg') !important;
                    background-color: $color-white !important;
                    background-size: 12px !important;
                    background-position: center left 12px !important;
                    background-repeat: no-repeat !important;
                    color: $dark-1;

                    &:hover,
                    &:active,
                    &:focus {
                        border-color: $color-jungle-green;
                        z-index: 9;
                    }
                }
            }

        }

        .label-text {
            color: $dark-1;
            font-weight: 500;
            font-size: 14px;
            margin-bottom: 7px;
            margin-left: 5px;
            font-family: $mulish-regular;
        }

        .toolbar-element2 {
            display: inline-block;
            float: left;
            width: 120px;
        }

        .btn-reset {
            background: $color-caribbean-green;
            border-radius: 3px;
            color: $color_white;
            font-weight: 700;
            width: 78px;
            height: 32px;
        }

        .btn-search {
            margin: 0 10px;
        }

        .btn-download {
            background: $color-caribbean-green;
            border-radius: 3px;
            color: $color_white;
            font-weight: 700;
            width: 95px;
            height: 32px;
            margin-left: 10px;
        }

        .search_symbol {
            background: $color-white;
            border: 1px solid #C7CBD3 !important;
            border-radius: 10px !important;
            width: 197px;
            height: 32px;
        }
    }

    table {
        tbody {
            td {
                .status {
                    height: 32px;
                    width: 100px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: $color-wood-smoke;
                    border-radius: 4px;

                    &.success-status {
                        background-color: $color-filled-status !important;
                    }

                    &.cancel-status {
                        background-color: $color-cancel-status !important;
                    }

                    &.pending-status {
                        background-color: $color-pending-status !important;
                    }
                }

                &:nth-child(6) {
                    width: 13%;
                    min-width: 150px;
                }
            }
        }
    }
}
</style>

<style lang="scss">
@import "@/assets/sass/variables";

#account-transaction {
    .sc_search_select {
        .group_search_select {
            .button_drop_search {
                height: 32px;
                padding: 6px 10px 6px 10px !important;
                border-radius: 10px;
                font-size: $font-small;
                font-family: $mulish-regular;
                background-color: $color-white;

                i.icon-arrow1 {
                    margin-left: 5px;
                    color: $color_dove_gray;
                }

                &:hover {
                    border-color: $color-jungle-green;

                    i.icon-arrow1 {
                        color: $color-jungle-green;
                    }
                }
            }

            .box_list_search_select {
                .list_search_select {
                    border-radius: 10px;

                    li {
                        font-family: $font-family-roboto;
                        font-size: $font-small;

                        &:hover,
                        &:active,
                        &:focus {
                            color: $color-jungle-green;
                        }
                    }
                }
            }
        }
    }
}
</style>