import BaseRequest from './BaseRequest';
export default class InstrumentRequest extends BaseRequest {
    getInstrumentDropdown ()
    {
        let url = '/admin/api/instruments/settings';
        return this.get(url);
    }
    createInstrument(params) {
        let url = "/admin/api/instruments/create";
        return this.post(url, params);
    }
    getInstruments(params) {
        let url = "/admin/api/instruments";
        return this.get(url, params);
    }
    deleteInstruments(params) {
        let id = params["id"];
        let url = "/admin/api/instruments/" + id + "/delete";
        return this.del(url, params);
    }
    updateInstruments(params) {
        let url = "/admin/api/instruments/update";
        return this.post(url, params);
    }
    getCoinActive() {
        let url = "/admin/api/instruments/get-coin-active";
        return this.get(url);
    }
    getCoinIndexActive(params) {
        let url = "/admin/api/instruments/get-coin-index-active";
        return this.get(url, params);
    }
}