import BaseRequest from '../lib/BaseRequest'

export default class ValidateRequest extends BaseRequest {
    validateAddress(params) {
        let url = `/admin/api/cold-wallet-setting/validate-address`;
        return this.get(url, params);
    }

    validateWithInternalAddress(params) {
        let url = `/admin/api/cold-wallet-setting/validate-addressfrom-internal`;
        return this.get(url, params);
    }
    commonValidateAddress(params) {
        let url = `/admin/api/cold-wallet-setting/common-validate-address`;
        return this.get(url, params);
    }
}