<template>
  <div class="content-mapping-instrument" id="mapping-instrument">
    <div class="row" style="margin-bottom: 20px">
      <div class="col-xs-2" style="padding-top: 5px">Default Retry</div>
      <div class="col-xs-2">
        <currency-input :precision="0" class="form-control" v-model="defaultRetry"
          name="defaultRetry"
          :disabled="!isEdit"
          :class="[isEdit ? '' : 'disabled-select']"
        />
      </div>
    </div>
    <div class="row" style="margin-bottom: 20px">
      <div class="col-xs-2" for="site_email">Email</div>
      <div class="col-xs-2">
        <input
          maxlength='190'
          class="form-control" 
          name="email"
          data-vv-validate-on="none"
          v-validate="{required: false, regex: /^\w+([\.-]?\w+)+@\w+([\.:]?\w+)+(\.[a-zA-Z0-9]{2,3})+$/ }"
          id="site_email" 
          v-model="email"
          @focus="resetError"
          :disabled="!isEdit"
          :class="{ error: errors.has('email') }"/>
        <span class="invalid-feedback"> {{ errors.first('email') }}<span v-if="errors.has('email')">{{'.'}}</span> </span>
      </div>
    </div>
    <div class="row">
      <table class="w-100">
        <thead>
          <tr>
            <th class="text-center">Exchange Instrument</th>
            <th class="text-center">Bitmex Instrument</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(instrument,index) in amanInstrument" :key="index">
            <td class="text-center">{{instrument.symbol}}</td>
            <td class="justify-content-center">
                <select-box
                 class="w-20"
                 :class="[isEdit ? '' : 'disabled-select']"
                :options="bitmexInstrument"
                 v-model="amanInstrument[index].bitmex_instrument"
                ></select-box>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row button-row">
      <div class="group-button">
        <button v-if="!isEdit" @click="isEdit = true" class="btn btn-primary">Edit</button>
        <button v-if="isEdit" @click="onReturnClick" class="btn btn-primary mr-2 btn-return">Return</button>
        <button v-if="isEdit" @click="submitData" class="btn btn-primary">Save</button>
      </div>
    </div>
  </div>
</template>

<script>
  import rf from '../../lib/RequestFactory';
  import SelectBox from "../../components/SelectBox";
  import RemoveErrorsMixin from '../../common/RemoveErrorsMixin';

  export default {
    name: "MappingInstrument",
    components:{
        SelectBox
    },
    mixins: [RemoveErrorsMixin],
    data: function () {
      return {
        titlePage: 'Mapping Instrument',
        amanInstrument:[],
        bitmexInstrument:[],
        isEdit:false,
        defaultRetry: 0,
        email: null,
      }
    },
    methods:{
      getData:function(){
        rf.getRequest('MappingInstrument').getMappingInstrument().then((response)=>{
            this.amanInstrument = response.data;
        })
      },
      getBitmexInstrument:function(){
        rf.getRequest('MappingInstrument').getBitmexInstruments().then((response)=>{
          const temp = [];
          temp.push('None');
          window._.each(response.data,(value)=>{
              temp.push(value.symbol);
          });
          this.bitmexInstrument = temp;
        })
      },
      getMappingSetting:function(){
        rf.getRequest('MappingInstrument').getMappingSetting().then((response)=>{
          this.defaultRetry = response.data.default_retry ? response.data.default_retry : '0';
          this.email = response.data.email ? response.data.email : null;
        })
      },
      async submitData () {
        this.resetError();
        await this.$validator.validateAll();
        if (this.errors.any()) {
          return;
        }
        if (!this.defaultRetry) {
          this.$toastr('error', 'The default retry field must have value.');
          return;
        }
        if (this.defaultRetry > 30) {
          this.$toastr('error', 'The default retry value must be less than or equal to 30.');
          return;
        }
        try {
          await rf.getRequest('MappingInstrument').updateMappingInstrument({ ...this.amanInstrument, default_retry: this.defaultRetry, email: this.email });
          this.isEdit = false;
          this.getMappingSetting();
          this.$toastr('success', window.i18n.t('address.updated_success'));
        } catch {
          this.$toastr('error', window.i18n.t('circuit_breaker_setting.update_fail_msg'));
        }
      },
      onReturnClick:function () {
        this.isEdit = false;
        this.resetError();
        this.getData();
        this.getMappingSetting();
      }
    },
    created() {
      this.getBitmexInstrument();
      this.getData();
      this.getMappingSetting();
    },
    mounted() {
      this.$emit('EVENT_PAGE_CHANGE', this);
    }
  }
</script>

<style lang="scss" scoped>
@import "@/assets/sass/variables";

.content-mapping-instrument{
  .error {
    border: 1px solid #e2221e !important;
  }
  .invalid-feedback {
    color: #e2221e;
  }
  min-height: 250px;
  margin-right: auto;
  margin-left: auto;
  padding: 10px 20px 0px 25px;
  table{
    width: 100%;
    background-color: #ffffff;
    overflow-y: hidden;
    thead{
      border: none;
      overflow: hidden;
      white-space: nowrap;
      th{
        line-height: 15px;
        font-size: $font-big;
        font-weight: 500;
        padding: 15px 15px;
        border-bottom: 1px solid $color_alto;
        font-family: $font-family-roboto-medium;
        &:last-child{
          border-left: 1px solid $color_alto;
        }
      }
    }
    tbody{
      tr {
        vertical-align: top;
        overflow-y: hidden;
        transition-property: height;
        transition-duration: 0.3s, 0.3s;
        transition-timing-function: ease, ease-in;
        height: auto;
        background-color: $color_white;
        border-bottom: 1px solid $color_catskill_white;
        &.active {
          height: 100px;
          max-height: 300px;
          background-color: $color_grey_select;
          transition-property: height;
          transition-duration: 0.3s, 0.3s;
          transition-timing-function: ease, ease-in;
          .glyphicon-menu-down {
            transition-duration: 0.5s;
            transform: rotate(180deg);
          }
        }
        &.empty-data {
          td {
            text-align: center;
            color: $color_grey;
            line-height: 200px;
          }
        }
      }
      td {
        height: 40px;
        overflow: initial;
        line-height: 23px;
        font-size: $font_root;
        font-weight: 500;
        color: $color_mine_shaft;
        padding: 8px 15px 4px 15px;
        border-top: 1px solid $color_catskill_white;
        &:last-child{
          border-left: 1px solid $color_alto;
        }
      }
    }
  }
}
.w-100{
  width: 100%;
}
.w-20{
  width: 20%;
}
.justify-content-center{
  display: flex;
  justify-content: center;
}

.button-row{
  display: flex;
  margin-top: 20px;
  .group-button{
    margin-left: auto;
    .btn-return{
      margin-right: 10px;
    }
  }

}
</style>
<style lang="scss">
@import "@/assets/sass/variables";

  #mapping-instrument{
  .sc_search_select {
    .button_drop_search {
      span {
        font-size: 14px;
        color: $color_mine_shaft;
      }
    }
  }
  .disabled-select{
    pointer-events: none;
    cursor: default;
    text-decoration: none;
    color: black;
    .button_drop_search{
      background-color: #ececec;
    }
  }
}
</style>