<template>
  <div class="slice-container" :class="!rightSlide ? 'slice-container-hide' : ''" id="outSideRight" @click="onClickOutside($event)">
    <div class="right-slide" :class="!rightSlide ? 'right-slide' : ''" >
      <div class="right-slide-header">
        <img src="@/assets/images/icon/back-icon.svg" width="14" @click="onClose()" />
      </div>
      <div v-if="dataDetail" class="right-slide-content">
        <table>
          <thead>
            <th>Key</th>
            <th>Value</th>
          </thead>
          <tbody>
            <tr v-for="[key, value] in Object.entries(dataDetail)" :key="key">
              <td>{{ key }}</td>
              <td>{{ value }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    dataDetail: {
      type: Object,
      default: null
    },
    rightSlide: {
      type: Boolean,
      default: false
    },
    onClose: {
      type: Function,
      default: () => {}
    }
  },
  watch: {
    dataDetail: function(newVal) {
      // console.log(newVal)
    },
  },
  methods:{
    onClickOutside (event) {
      if(event.target.id && event.target.id === "outSideRight"){
        this.onClose()
      }
    },
  }
};
// onMounted(() => {
//   // childRef.value will hold an instance of <Child />
// })

</script>
<style lang="scss" scoped>
@import "@/assets/sass/variables";

.slice-container {
    background-color: rgba(0, 0, 0, 0.267);
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 2000;
    top: 0;
    right: 0;
    transition: all 300ms linear;
    display: flex;
    justify-content: end;
    overflow: hidden;

    &.slice-container-hide {
      width: 0;
    }
    
    .right-slide {
      width: 450px;
      height: 100%;
      position: relative;
      background-color: #fff;
      top: 0;
      right: 0;
      padding: 16px;
      z-index: 10;
      transition: all 300ms linear;
      overflow: hidden;

      &.right-slide-hide {
        width: 0;
      }

      .right-slide-header {
        height: 32px;
        img {
          cursor: pointer;
        }
      }

      .right-slide-content {
        overflow-y: scroll;
        height: 95%;
        table {
          width: 100%;
        }
        thead {
          border-bottom: 1px solid $color-grey;
        }
        th {
          padding: 10px 10px;
          color: $color-grey;
          font-family: $mulish-regular;
        }
        tr {
          border-bottom: 1px solid $color_concrete;
        }
        td {
          padding: 10px 10px;
          font-family: $mulish-regular;

          &:first-child {
            font-weight: 600;
          }
        }
      }
    }
  }
</style>
