<template>
  <div id="base_deposit_withdraw" class="boxCore">
    <div class="transaction">
      
      <div v-if="type == 'withdraw'">
        <div class="select_transaction_container">
          <div class="tab-header">
            <ul>
              <li :class="{'active': transactionStatus === 'pending'}">
                <a
                  href="javascript:void(0)"
                  @click="selectTab('pending')"
                ><span>{{ $t('transactions_pending') }}</span></a>
              </li>
              <li :class="{'active': transactionStatus === 'history'}">
                <a
                  href="javascript:void(0)"
                  @click="selectTab('history')"
                ><span>{{ $t('transactions_history') }}</span></a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="filter-container">
        <div class="filter-item date">
          <div class='label-text'>{{ $t('orders.open_order.date') }}:</div>
          <date-picker v-model="startDate" class="date-picker-input no-right-radius" :format="customFormatter"></date-picker>
          <date-picker v-model="endDate" class="date-picker-input no-left-radius" :format="customFormatter"></date-picker>
        </div>
        <div class="filter-item pair">
          <div class="label-text">{{ $t('user_wallet.currency') }}:</div>
          <!-- <div class="toolbar-element2">
            <select-box
              v-model="filters.currency"
              :placeholder="$t('order.order_history.all')"
              :options="coins"
            />
          </div> -->
          <CoinInput @onCoinChange='onCoinChange' ref="coinInput"/>
        </div>
        <div class="filter-item pair" v-if="type == 'deposit'">
          <div class="label-text text-pair">{{ $t('base_deposit_withdraw_title_collect') }}:</div>        
          <select-box
              v-model="filters.collect"
              :placeholder="$t('order.order_history.all')"
              :options="collectStatus"
          />
        </div>
        <div class="filter-item input_search">
          <input
            title
            type="text"
            :placeholder="$t('orders.open_order.search_by_email')"
            @keyup.enter="search"
            class="form-control input-search"
            name="searchKey"
            v-model="filters.searchKey"
          >
        </div>
        <div class="filter-item btn-action">
          <button class="btn btn-search" @click="search()">{{ $t('orders.open_order.search') }}</button>
          <button class="btn btn-reset" @click="refresh()">{{ $t('orders.open_order.reset') }}</button>
        </div>
      </div>

      <div class="datatable">
        <data-table :getData="getPendingTransactions"
                    :limit="10"
                    :column='6'
                    @DataTable:finish="onDatatableFinish"
                    ref="datatable" class="scroll">
          <th class="text-left" data-sort-field="created_at">{{ $t('base_deposit_withdraw_title_time') }}</th>
          <th class="text-left" data-sort-field="email">{{ $t('base_deposit_withdraw_title_email') }}</th>
          <th class="text-left">{{ $t('base_deposit_withdraw_title_transactionId') }}</th>
          <!-- <th class="text-left" data-sort-field="blockchain_address">Address</th> -->
          <th class="text-left min_w_100px" data-sort-field="currency">{{ $t('base_deposit_withdraw_title_currency') }}</th>
          <th class="text-left min_w_120px" data-sort-field="amount">{{ $t('base_deposit_withdraw_title_amount') }}</th>
          <th v-if="type == 'withdraw' && transactionStatus =='history'" class="text-left min_w_95px" data-sort-field="status">{{ $t('base_deposit_withdraw_title_status') }}</th>
          <th v-else class="text-left min_w_95px">{{ $t('base_deposit_withdraw_title_status') }}</th>
          <th v-if="type == 'deposit'" data-sort-field="collect" class="text-left min_w_95px"> {{ $t('base_deposit_withdraw_title_collect')}}</th>
          <template slot="body" slot-scope="props">
            <tr>
              <td class="text-left" >
                <div>
                  {{ props.item.created_at | timestampToDate }}
                </div>
              </td>
              <td class="text-left">
                <div class="item_email_user">
                  <span class="txt_email_user">{{ props.item.email }}</span>
                </div>
              </td>
              <td class="text-left" >
                <div class="text-break">{{ props.item.is_external == 0 ? props.item.transaction_id : props.item.tx_hash }}</div>
              </td>
              <td class="text-left">
                {{ props.item.currency | uppercase }}
              </td>
              <td class="text-left">
                <div>{{ getFullAmount(props.item.amount, props.item.fee) | formatCurrencyAmount(props.item.currency, '0') }}</div>
                <div>{{ props.item.fee | formatCurrencyAmount(props.item.currency, '0') }}</div>
              </td>
              <td class="text-left">
                <div v-if="type == 'withdraw' && transactionStatus == 'pending'">
                  <div v-if='!props.item.approved_by'>
                    <button class="btn btn_Confirm" @click="sendTransaction(props.item)">{{ $t('common.action.send_email') }}</button>
                    <button class="btn btn_Reject" @click="cancelTransaction(props.item)">{{ $t('common.action.cancel') }}</button>
                  </div>
                  <div v-else>{{ props.item.status | uppercaseFirst}}</div>
                </div>
                <div v-else-if="props.item.status === 'error'">{{ 'Failed' }}</div>
                <div v-else>{{ props.item.status | uppercaseFirst}}</div>
              </td>
              <td class="text-left"  v-if="type == 'deposit'">
                <div>
                  {{ props.item.collect | uppercaseFirst}}
                </div>
              </td>
            </tr>
          </template>
        </data-table>
      </div>
    </div>
    <modal :name="modalName" title="Error Details" :hasModalFooter="false">
      <template slot="body">
        <div class="error-detail">{{ transactionErrorDetail }}</div>
      </template>
    </modal>
  </div>
</template>

<script>
  import rf from '../../lib/RequestFactory';
  import Modal from '../../components/Modal';
  import BigNumber from 'bignumber.js';
  import DatePicker from "vuejs-datepicker";
  import moment from "moment";
  import COMMON_CONST from "../../common/Const";
  import SelectBox from "../../components/SelectBox";
  import CoinInput from '../../common/CoinInput.vue'

  export default {
    components: {
      Modal,
      DatePicker,
      SelectBox,
      CoinInput
    },
    props: {
      type: {
        type: String,
        default: 'deposit'
      }
    },
    data() {
      return {
        searchKey: '',
        transactionStatus: this.getTransactionStatus(),
        modalName: 'DetailErrorModal',
        transactionErrorDetail: '',
        titlePage: window.i18n.t("orders.open_order.open_order"),
        startDate: moment().subtract(6, 'day').toDate(),
        endDate: moment().toDate(),
        coin: "",
        filters: {
          currency: "",
          searchKey: "",
          collect: ""
        },
        coins: [],
        coinMasters: [],
        isShowCoinOption: false,
        trades: [],
        markets: [],
        collectStatus: ["All",'Collected', 'Open']
      }
    },
    watch: {
      transactionStatus() {
        this.resetInput()
        this.$refs.coinInput.refresh();
        this.$refs.datatable.refresh();
      }
    },
    methods: {
      getTransactionStatus() {
        return this.type == 'deposit' ? 'history' : 'pending';
      },
      sendTransaction(transaction) {
        let data = {
          transaction_id: transaction.transaction_id
        };
        ConfirmationModal.show({
          type: 'confirm',
          title: window.i18n.t('transactions.withdrawal.send'),
          content         : '',
          btnCancelLabel  : this.$t('common.action.no'),
          btnConfirmLabel : this.$t('common.action.yes'),
          size            : 'small',
          onConfirm       : () => {
            rf.getRequest('AdminRequest').sendTransaction(data).then(res => {
              this.$toastr('success', this.$t('common.alert.update.success'));
              this.refresh();
            }).catch(e => {
              this.$refs.datatable.refresh();
              if (e.response && e.response.data && e.response.data.message) {
                this.$toastr('error', e.response.data.message);
              }
            });
          },
          onCancel        : () => {}
        });
      },

      cancelTransaction(transaction) {
        let data = {
          transaction_id: transaction.transaction_id
        };
        ConfirmationModal.show({
          type: 'confirm',
          title: window.i18n.t('transactions.withdrawal.cancel'),
          content         : '',
          btnCancelLabel  : this.$t('common.action.no'),
          btnConfirmLabel : this.$t('common.action.yes'),
          size            : 'small',
          onConfirm       : () => {
            rf.getRequest('AdminRequest').cancelTransaction(data).then(res => {
              this.$toastr('success', this.$t('common.alert.update.success'));
              this.refresh();
            }).catch(e => {
              this.$refs.datatable.refresh();
              if (e.response && e.response.data && e.response.data.message) {
                this.$toastr('error', e.response.data.message);
              }
            });
          },
          onCancel        : () => {}
        });
      },
      selectTab(tab) {
        this.transactionStatus = tab;
      },
      onCoinChange(currency) {
        this.filters.currency = currency;
      },
      search() {
        let filterParams = {
          start_date: (moment(this.startDate)
            .startOf("day")
            .format("x")/1000).toFixed(0),
          end_date: (moment(this.endDate)
            .endOf("day")
            .format("x")/1000).toFixed(0)
        };
        if (this.customFormatter(this.startDate) === this.customFormatter(this.endDate)) {
          filterParams = {
            start_date: (moment(this.startDate)
              .startOf("day")
              .format("x")/1000).toFixed(0),
            end_date: (moment(this.startDate)
              .endOf("day")
              .format("x")/1000).toFixed(0)
          }
        }
        if (this.customFormatter(this.startDate) > this.customFormatter(this.endDate)) {
          this.$toastr("error", this.$t("common.date.warning"));
          return;
        }
        if (this.filters.searchKey) {
          filterParams.search_key = this.filters.searchKey;
        }
        if (this.filters.currency) {
          filterParams.currency = this.filters.currency;
        }
        if (this.filters.collect !== 'All') {
          filterParams.collect = this.filters.collect;
        }
        this.$refs.datatable.filter(filterParams);
        
      },
      refresh() {
        this.requestCoinList();
        this.resetInput();
        this.$refs.datatable.refresh();
        this.$refs.coinInput.refresh();
      },
      resetInput() {
        this.filters = Object.assign(this.filters, {
          currency: "",
          searchKey: "",
          collect: ""
        });
        this.startDate = moment().subtract(6, "day").toDate();
        this.endDate = moment().toDate();
        $(".datatable").find("th").attr("data-sort-order", "");
      },
      /*
      getMarkets() {
        return window._.map(COMMON_CONST.MARKETS, item => {
          return { id: item, name: item.toUpperCase() };
        });
      },
      */

      getPendingTransactions(params) {
        const ASC = 'asc';
        const DESC = 'desc';
        const meta = {
          type: this.type,
          status: this.transactionStatus, 
          start_date: (moment(this.startDate)
            .startOf("day")
            .format("x")/1000).toFixed(0),
          end_date: (moment(this.endDate)
            .endOf("day")
            .format("x")/1000).toFixed(0)
        }
        if (!window._.isEmpty(this.searchKey)) {
          meta.search_key = this.searchKey;
        }

        let paramRefactor = undefined
        params = Object.assign(params, meta);
        if (params.type === 'withdraw' && params.sort === 'amount') {
          // amount is a Negative number
          paramRefactor = Object.assign(params, { sort_type: params.sort_type === ASC ? DESC : ASC })
        }
        else  {
          paramRefactor = params
        }
        return rf.getRequest('AdminRequest').getTransactions(paramRefactor);
      },

      onDatatableFinish() {
        const datatable = this.$refs.datatable;
        // Only sort 'amount' field a again. Because amount field can be negative (withdraw) or positive (deposit).
        if (datatable.params.sort !== 'amount') {
          return;
        }
        const result = window._.chain(datatable.rows)
          .map(item => {
            item.amount = Math.abs(parseFloat(item.amount));
            return item;
          })
          // .orderBy([datatable.params.sort], [datatable.params.sort_type])
          .value();
          
        this.$refs.datatable.rows = result;
      },

      // Get amount which hasn't calculate fee yet.
      getFullAmount(reductionAmount, fee) {
        return new BigNumber(`${Math.abs(reductionAmount)}`).add(`${fee}`).toString();

      },
      formatDateTime(date) {
        return moment(date, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss');
      },
      customFormatter(date) {
        return moment(date).format('YYYY - MM - DD');
      },
      // onSearchCoin() {
      //   try {
      //     this.coins = _.filter(this.coinMasters, (item) => {
      //       return _.isMatch(item.toUpperCase(), this.filters.currency.toUpperCase());
      //     });
      //   }
      //   catch(e) {
      //     console.log(e);
      //   }
      // },
      // showCoinOption() {
      //   this.isShowCoinOption = !this.isShowCoinOption;
      //   this.coins = this.coinMasters && this.coinMasters.map(x => x.toUpperCase());
      // },
      requestCoinList() {
        rf.getRequest("CoinSettingRequest")
          .getCoinList()
          .then(res => {
            if(window._.map(res.data, "coin")) {
              this.coinMasters = window._.map(res.data, "coin");
            }
          })
          .catch(e => {
            console.log(e)
          });
      },
      // onSelectCoin(coin) {
      //   this.isShowCoinOption = false;
      //   this.filters.currency = coin;
      // },
      // clickOut() {
      //   this.isShowCoinOption = false;
      // },

    },
    created() {
      this.requestCoinList();
      rf.getRequest('AdminRequest').getPriceGroupCurrency().then(res => {
        try {
          window._.map(res.data, item => {
            if (typeof item.currency !== 'undefined') {
              this.markets.push({ id: item.currency, name: item.currency.toUpperCase() });
            }
          });
        }
        catch (ex) {
          console.error(ex);
        }
      });
    }
  }
</script>



<style lang="scss">
@import "@/assets/sass/variables";
  // @media only screen and (max-width: 1380px) {
  //   table {
  //       thead {
  //         th {
  //           padding: 0px 10px !important;
  //         }
  //       }
  //     }
  // }
  // @media only screen and (max-width: 1725px){
  //   .deposit_pages .btn_Reject{
  //       // margin-top: 5px;
  //       margin-left: 0 !important;
  //   }
  // }

  #base_deposit_withdraw {
    @media only screen and (max-width: 1380px) {
      table {
          // min-width: 1380px;
          thead {
            th {
              padding: 0px 10px !important;
            }
          }
        }
    }
    @media only screen and (max-width: 1725px){
      .deposit_pages .btn_Reject{
          margin-top: 5px;
          margin-left: 0 !important;
      }
    }

    .filter-container {
      display: block;
      float: left;
      width: 100%;
      margin: 5px 0px 30px 0px;
      .filter-item {
        display: inline-block;
        float: left;
        margin: 0 0 0 0;
        margin-right: 20px;
        .label-text {
          line-height: 15px;
          margin-bottom: 7px;
          color: $color-grey-dusty;
          font-size: $font-small;
          padding: 0 0 0 0;
        }
        .text-pair {
          padding-left: 16px;
        }
        &.date {
          width: 222px;
          .date-picker-input {
            input {
              width: 110px !important;
              display: block;
              background-size: 12px 12px;
              padding: 8px 0px 7px 25px;
              background-position: center left 8px;
            }
          }
        }
        &.pair {
          min-width: 65px;
          .coin-inputt.toolbar-element22 {
            input {
              padding: 8px 5px 7px 11px;
              margin: 0 0 0 0;
              width: 100%;
            }
            .coin-option {
              left: 0px;
            }
            .icon-coin-input {
              i {
                font-size: 16px;
                color: $color-grey-dusty;
              }
            }
          }
        }
        &.input_search {
          width: 145px;
          margin-right: 10px;
          input {
            margin: 22px 0 0 0;
            padding: 8px 5px 7px 16px;
            color: $color-grey-dusty;
            font-size: $font-small;
            font-family: $font-family-roboto;
            height: 30px;
            background-color: transparent;
          }
        }
        &.btn-action {
          .btn {
            background-color: $color-caribbean-green;
            border: 1px solid $color-caribbean-green;
            line-height: 20px;
            width: 85px;
            color: $color-white;
            text-align: center;
            text-transform: uppercase;
            transition: 0.5s;
            margin: 22px 10px 0px 0px;
            letter-spacing: 0.9px;
            font-size: $font-smaller;
            font-family: $font-family-roboto-bold;
            padding: 5px;
            height: 30px;
            font-weight: 500;
            &:hover, &:active {
              background-color: $color-aquamarine;
              border-color: $color-aquamarine;
            }
          }
        }
      }
    }
    .select_transaction_container {
      display: block;
      width: 100%;
      float: left;
      margin: 10px 0px 15px 0px;
      ul {
        display: block;
        width: 100%;
        float: left;
        padding: 0 0 0 0;
        li {
          float: left;
          margin-right: 25px;
          font-size: 18px;
          color: $color-grey-dark;
          display: block;
          a {
            color: $color-grey-dark;
            font-family: $font-family-roboto-medium, sans-serif;
            font-weight: 500;
            font-size: $font_big;
            display: block;
            float: right;
            &:before {
              content: "";
              display: inline-block;
              width: 12px;
              height: 12px;
              border-radius: 50%;
              border: solid 1px #979797;
              margin-right: 3px;
            }
          }
          &.active {
            a {
              color: $color-jungle-green;
              &:before {
                border: solid 4px $color-jungle-green;
              }
            }
          }
        }
      }
    }
  }
</style>

<style lang="scss">
@import "@/assets/sass/variables";
  .min_w_100px {
    min-width: 100px;
  }
  .min_w_120px {
    min-width: 120px;
  }
  .min_w_95px {
    min-width: 95px;
  }
  #base_deposit_withdraw {
    .sc_search_select {
      .group_search_select {
        .button_drop_search {
          width: 92px;
          height: 30px !important;
          padding: 7px 10px 5px 10px !important;
          border-radius: 3px;
          margin-right: 20px;
          font-size: $font-small;
          font-family: $font-family-roboto;
          i.icon-arrow1 {
            margin-left: 5px;
            color: $color_dove_gray;
          }
          &:hover {
            border-color: $color-jungle-green;
            i.icon-arrow1 {
              color: $color-jungle-green;
            }
          }
          
        }
        .box_list_search_select {
          .list_search_select {
            li {
              font-family: $font-family-roboto;
              font-size: $font-small;
              &:hover, &:active, &:focus {
                color: $color-jungle-green;
              }
            }
          }
        }
      }
    }
    .btn_Confirm,
    .btn_Reject {
        background-color: $color-caribbean-green;
        color: $color-white;
        text-transform: uppercase;
        width: 88px;
        height: 25px;
        line-height: 20px;
        padding: 0px 9px;
        text-align: center;
        font-size: $font-smaller;
        font-family: $font-family-roboto-bold;
        border: 1px solid $color-caribbean-green;
        border-radius: 3px;
        transition: 0.5s;
        &:hover {
          background-color: $color-aquamarine;
          border-color: $color-aquamarine;
          transition: 0.5s;
        }
    }
    .btn_Reject {
      margin-left: 10px;
    }
    @media (max-width: 1280px) {
      .btn_Reject {
        margin-top: 5px;
        margin-left: 0px !important;
      }
    }
  }
  #deposit_pages,
  #withdrawal_pages {
    td {
      padding-right: 10px;
    }
    @media (max-width: 1280px) {
      .btn_Reject{
        margin-top: 0;
      }
    }
    @media only screen and (max-width: 1380px) {
      table {
        thead {
          th {
            padding-left: 15px !important;
          }
        }
      }
    }
    @media only screen and (max-width: 1725px){
      .deposit_pages .btn_Reject{
        margin-top: 0;
      }
    }
  }
</style>