<template>
    <div class="dashboard" id="dashboard_pages">
        <div class="d-flex">
            <div class="block-item">
                <div>{{ $t('dashboard.total_account') }}</div>
                <div>{{ dataAccount.account_total }}</div>
            </div>
            <div class="block-item">
                <div>{{ $t('dashboard.total_verified') }}</div>
                <div>{{ dataAccount.account_verified }}</div>
            </div>
            <div class="block-item">
                <div>{{ $t('dashboard.total_secured') }}</div>
                <div>{{ dataAccount.account_secured }}</div>
            </div>
            <div class="block-item">
                <div>{{ $t('dashboard.total_deposited') }}</div>
                <div>{{ dataAccount.account_deposited }}</div>
            </div>
        </div>
        <div class="chart-wrapper">
            <bar :data="{ labels: labels, datasets: datasetsAccount }" :options="options" />
        </div>
        <div class="break-line">
            <div class="line"></div>
            <div class="toolbar-element">
                <select-box v-model="symbolFilter" :placeholder="$t('order.order_history.all')" :options="symbolData" />
            </div>
        </div>
        <div class="d-flex">
            <div class="block-item">
                <div>{{ $t('dashboard.total_withdrawal') }}</div>
                <div>{{ dataTransaction.withdraw | to2Precision }}</div>
            </div>
            <div class="block-item">
                <div>{{ $t('dashboard.total_deposit') }}</div>
                <div>{{ dataTransaction.deposit | to2Precision }}</div>
            </div>
            <div class="block-item">
                <div>{{ $t('dashboard.total_withdrawal_usd') }}</div>
                <div>{{ dataTransaction.withdraw_usd | to2Precision }}</div>
            </div>
            <div class="block-item">
                <div>{{ $t('dashboard.total_deposited_usd') }}</div>
                <div>{{ dataTransaction.deposit_usd | to2Precision }}</div>
            </div>
        </div>
        <div class="chart-wrapper">
            <bar :data="{ labels: labels, datasets: datasetsTransaction }" :options="options" />
        </div>
    </div>
</template>

<script>
import rf from '../../lib/RequestFactory';
import SelectBox from "../../components/SelectBox.vue";
import moment from "moment-timezone";
import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    BarElement,
    CategoryScale,
    LinearScale
} from 'chart.js'
import { Bar } from 'vue-chartjs'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

export default {
    components: {
        Bar,
        SelectBox
    },
    data() {
        return {
            titlePage: window.i18n.t('menu.dashboard'),
            symbolData: [
                { name: 'SOL', id: 'SOL' },
                { name: 'BNB', id: 'BNB' },
                { name: 'BTC', id: 'BTC' },
                { name: 'ETH', id: 'ETH' },
                { name: 'XRP', id: 'XRP' },
            ],
            dataChart: {
                labels: [
                    'January',
                    'February',
                    'March',
                    'April',
                    'May',
                    'June',
                    'July',
                    'August',
                    'September',
                    'October',
                    'November',
                    'December'
                ],
                datasets: [
                    {
                        label: 'One',
                        backgroundColor: '#FFC590',
                        data: [40, 20, 12, 39, 10, 40, 39, 80, 40, 20, 12, 11],
                        borderRadius: 10
                    },
                    {
                        label: 'Two',
                        backgroundColor: '#00DDB3',
                        data: [40, 20, 12, 39, 10, 40, 39, 80, 40, 20, 12, 11],
                        borderRadius: 10
                    },
                    {
                        label: 'Three',
                        backgroundColor: '#FF8C8A',
                        data: [40, 20, 12, 39, 10, 40, 39, 80, 40, 20, 12, 11],
                        borderRadius: 10
                    },
                ]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        position: 'top',
                        align: 'end',
                        labels: {
                            usePointStyle: true,
                            pointStyle: 'rectRounded'
                        }
                    }
                },
                scales: {
                    x: {
                        grid: {
                            display: false,
                        }
                    },
                    y: {
                        grid: {
                            color: '#f8f8f8'
                        }
                    }
                },
            },
            labels: [],
            datasetsAccount: [],
            datasetsTransaction: [],
            dataAccount: {},
            dataTransaction: {},
            symbolFilter: { name: 'SOL', id: 'SOL' },
            isShowCoinOption: false
        }
    },
    methods: {
        showDropdownCoin() {
            this.isShowCoinOption = !this.isShowCoinOption;
        },
        onSelectCoin(coin) {
            this.isShowCoinOption = false;
            this.symbolFilter = coin.toUpperCase();
        },
        getLast30Dates() {
            const dates = [];
            const today = new Date();

            for (let i = 0; i < 30; i++) {
                const date = new Date(today);
                date.setDate(today.getDate() - i);
                const formattedDate = date.toISOString().split('T')[0];
                dates.push(formattedDate);
            }

            this.labels = dates.reverse()
        },
        getLast30Datasets(list, key) {
            const datasets = [];
            const datasetsObject = this.handleObject(list, key);
            const today = new Date();

            for (let i = 0; i < 30; i++) {
                const date = new Date(today);
                date.setDate(today.getDate() - i);
                datasets.push(datasetsObject[this.customFormatter(date)] || 0)
            }

            return datasets.reverse();
        },
        handleObject(list, key) {
            const datasetsObject = {}
            list.map((item) => {
                datasetsObject[item.date] = item[key]
            })
            return datasetsObject
        },
        customFormatter(date) {
            return moment(date).format('YYYY-MM-DD');
        },
        getAccountData() {
            rf.getRequest("AdminRequest")
                .getAccountDataDashboard()
                .then(res => {
                    if (res.success) {
                        this.dataAccount = res.data.total;
                        this.datasetsAccount = [
                            {
                                label: window.i18n.t('dashboard.total_new_account'),
                                backgroundColor: '#FFC590',
                                data: this.getLast30Datasets(res.data.sub30day.account_total, "user_count"),
                                borderRadius: 10
                            },
                            {
                                label: window.i18n.t('dashboard.total_verified'),
                                backgroundColor: '#00DDB3',
                                data: this.getLast30Datasets(res.data.sub30day.account_verified, "user_verified"),
                                borderRadius: 10
                            },
                            {
                                label: window.i18n.t('dashboard.total_deposited'),
                                backgroundColor: '#FF8C8A',
                                data: this.getLast30Datasets(res.data.sub30day.account_secured, "user_secured"),
                                borderRadius: 10
                            },
                        ]
                    }
                });
        },
        getTransactionData() {
            rf.getRequest("AdminRequest")
                .getTransactionDataDashboard(this.symbolFilter.id.toLowerCase())
                .then(res => {
                    if (res.success) {
                        this.dataTransaction = res.data.total;
                        this.datasetsTransaction = [
                            {
                                label: window.i18n.t('dashboard.total_withdrawal'),
                                backgroundColor: '#FFC590',
                                data: this.getLast30Datasets(res.data.sub30day.withdraw, "amount"),
                                borderRadius: 10
                            },
                            {
                                label: window.i18n.t('dashboard.total_withdrawal_usd'),
                                backgroundColor: '#00DDB3',
                                data: this.getLast30Datasets(res.data.sub30day.withdraw_usd, "amount"),
                                borderRadius: 10
                            },
                            {
                                label: window.i18n.t('dashboard.total_deposit'),
                                backgroundColor: '#FF8C8A',
                                data: this.getLast30Datasets(res.data.sub30day.deposit, "amount"),
                                borderRadius: 10
                            },
                            {
                                label: window.i18n.t('dashboard.total_deposited_usd'),
                                backgroundColor: '#4db2ee',
                                data: this.getLast30Datasets(res.data.sub30day.deposit_usd, "amount"),
                                borderRadius: 10
                            },
                        ]
                    }
                });
        }
    },
    watch: {
        symbolFilter: function() {
            this.getTransactionData();
        }
    },
    mounted() {
        this.$emit('EVENT_PAGE_CHANGE', this);
    },
    created() {
        this.getLast30Dates()
        this.getAccountData()
        this.getTransactionData()
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/variables";

.dashboard {
    height: 100%;
    font-family: $mulish-regular;

    .d-flex {
        display: flex;
        gap: 32px;
        margin: 20px 0;

        .block-item {
            padding: 20px 40px;
            width: calc((100% - 32px * 3) / 4);
            background-color: $color-white;
            border-radius: 10px;

            div:first-child {
                font-size: 16px;
                font-weight: 500;
                color: $color-grey;
            }

            div:last-child {
                font-size: 24px;
                font-weight: 700;
                color: $color-eden;
            }
        }
    }

    .chart-wrapper {
        padding: 20px 30px;
        height: 400px;
        background-color: $color-white;
        border-radius: 10px;
        margin: 32px 0;
    }

    .break-line {
        position: relative;
    }

    .line {
        margin: 50px 0;
        border-bottom: 1px solid $color-grey;
    }

    .toolbar-element {
        position: absolute;
        top: -16px;
        left: 100px;
        width: 150px;
    }
}
</style>

<style lang="scss">
@import "@/assets/sass/variables";

#dashboard_pages {
    .sc_search_select {
        .group_search_select {
            .button_drop_search {
                width: 100%;
                height: 32px;
                padding: 6px 10px 6px 10px;
                border-radius: 10px;
                font-size: $font-small;
                font-family: $mulish-regular;
                background-color: $color-white;

                i.icon-arrow1 {
                    margin-left: 5px;
                    color: $color_dove_gray;
                }

                &:hover {
                    border-color: $color-jungle-green;

                    i.icon-arrow1 {
                        color: $color-jungle-green;
                    }
                }

            }

            .box_list_search_select {
                .list_search_select {
                    border-radius: 10px;

                    li {
                        font-family: $font-family-roboto;
                        font-size: $font-small;

                        &:hover,
                        &:active,
                        &:focus {
                            color: $color-jungle-green;
                        }
                    }
                }
            }
        }
    }
}
</style>