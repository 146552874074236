<template>
    <div id="manual_dividend" class="boxCore manual_dividend ">
        <div class="datatable">
            <div class="filter-container">
                <div class="select_transaction_container">
                    <div class="tab-header">
                        <ul>
                            <li :class="{'active': filters.type == exchangeType.spot}">
                                <a
                                        href="javascript:void(0)"
                                        @click="selectTab(exchangeType.spot)"
                                ><span>{{$t('common.spot_exchange')}}</span></a>
                            </li>
                            <li :class="{'active': filters.type === exchangeType.margin}">
                                <a
                                        href="javascript:void(0)"
                                        @click="selectTab(exchangeType.margin)"
                                ><span>{{$t('common.margin_exchange')}}</span></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="filter-container m-flex">
                <div class="filter-item date mr_left0">
                    <div class="text-date">{{ $t('orders.order_history.date') }}:</div>
                    <date-picker v-model="startDate" class="date-picker-input no-right-radius" :format="customFormatter"></date-picker>
                    <date-picker v-model="endDate" class="date-picker-input no-left-radius" :format="customFormatter"></date-picker>
                </div>
                <div class="filter-item kyc kyc ">
                    <div class="label-text text-pair ml-14">{{ $t('airdrop_setting.kyc') }}:</div>
                    <div class="toolbar-element2 kyc">
                        <select-box
                                v-model="filters.kyc"
                                :placeholder="filters.kyc.name"
                                :options="kyc"/>
                    </div>
                </div>
                <div class="clearfix col-xs-1 market-select-area">
                    <div>
                    <div class="label-text text-pair minimum-amount">{{ $t('airdrop_setting.self_trading') }}:</div>
                    <select-box v-if="filters.type=='spot'" v-model="settingsUser['self_trading_manual_dividend_spot']" class="select-market" :options="chosens" placeholder="On"/>
                    <select-box v-if="filters.type=='margin'" v-model="settingsUser['self_trading_manual_dividend_margin']" class="select-market" :options="chosens" placeholder="On"/>
                    </div>
                </div>
                <div class="filter-item amount">
                    <div class="label-text text-pair minimum-amount">User:</div>
                    <input title="" type="text" :placeholder="$t('orders.open_order.search_by_email_id')"
                        class="form-control" name="searchKey" v-model="filters.searchKey"/>
                </div>
            </div>
            <div class="filter-container m-flex">
                <div class="filter-item market mr_left0" >
                    <div class="label-text text-pair">
                        <span v-if="filters.type == exchangeType.margin">{{ $t('funds.balances.coin') }}:</span>
                        <span v-else>{{ $t('order.open_order.market') }}:</span>
                    </div>
                    <div class="toolbar-element2">
                        <select-box v-model="filters.market"
                                    :placeholder="filters.market.name"
                                    class="select-market" :options="markets"
                        />
                    </div>
                </div>
                <div class="filter-item pair" >
                    <div class="label-text text-pair ml-14">
                        <span v-if="filters.type == exchangeType.margin">{{ $t('funds.contracts') }}:</span>
                        <span v-else>{{ $t('order.order_history.pair') }}:</span>
                    </div>
                    <div class="toolbar-element2">
                        <select-box
                                v-model="filters.pair"
                                :placeholder="filters.pair.name"
                                :options="pairs"/>
                    </div>
                </div>

                <div class="filter-item amount">
                    <div class="label-text text-pair minimum-amount">{{ $t('airdrop_setting.minimum_amount') }}:</div>
                    <div class="group_input_ardrop_payout">
                        <div class="slb-fl-30">
                        <select-box v-if="nullSearchKey" v-model="filters.minimum_amount_option"
                                :placeholder="filters.minimum_amount_option.name"
                                :options="minimum_amount_options"
                                />
                        <div v-else id="disable-div" v-bind:disabled="true">
                            
                            <div  class="button_drop_search">
                                <span>Disable</span> 
                                <!-- <i class="icon-arrow1"></i> -->
                            </div>
                        </div>
                        <!-- <currency-input @focus="listError.deposit = false" :value="filters.depositAmount" v-bind:class="{ 'form-control': true, 'pdt60': true , 'error': this.listError.deposit }" :precision="precision"  v-model="filters.depositAmount" @keyup.enter="search" v-bind:disabled="!this.filters.deposit_coin.id"/> -->
                        <currency-input @focus="listError.filterAmount = false" :value="filters.minAmount" v-bind:class="{ 'form-control': true, 'pdt60': true , 'error': this.listError.filterAmount }" :precision="precision"  v-model="filters.minAmount" @keyup.enter="search" v-bind:disabled="!this.filters.minimum_amount_option.id" />
                        <span class="coin_airdrop_payout">{{$t('currency.btc.shortname')}}</span>
                        </div>
                    </div>
                    <div class="error-msg mt-33" v-if="this.listError.filterAmount">{{$t('airdrop_setting.amount_missing')}}</div>
                </div>
                
                <div class="filter-item amount referrer">
                    <div class="label-text text-pair minimum-amount">{{ $t('airdrop_setting.deposit_amount') }}:</div>
                    <div class="group_input_ardrop_payout">
                        <div class="slb-fl-30">
                        <select-box v-if="nullSearchKey" v-model="filters.deposit_coin"
                                :placeholder="filters.deposit_coin.name"
                                :options="listDepositCoin"/>
                        <div v-else id="disable-div" v-bind:disabled="true">
                            <div  class="button_drop_search">
                                <span>Disable</span> 
                                <!-- <i class="icon-arrow1"></i> -->
                            </div>
                        </div>
                        <!-- <currency-input @focus="listError.deposit = false" :value="filters.depositAmount" v-bind:class="{ 'form-control': true, 'pdt60': true , 'error': this.listError.deposit }" :precision="precision"  v-model="filters.depositAmount" @keyup.enter="search" v-bind:disabled="!this.filters.deposit_coin.id"/> -->
                        <input type="number" v-model="filters.depositAmount" v-bind:class="{ 'form-control': true, 'pdt60': true , 'error': this.listError.deposit }"@keyup.enter="search" v-bind:disabled="!this.filters.deposit_coin.id"/>
                        </div>
                    </div>
                    <div class="error-msg mt-33" v-if="this.listError.deposit">{{$t('airdrop_setting.deposit_missing')}}</div>
                    <div class="error-msg mt-33" v-if="this.listError.deposit_than_0">{{$t('airdrop_setting.deposit_than_0')}} </div>
                </div>
                
                <div class="filter-item amount  referrer">
                    <div class="label-text text-pair minimum-amount">{{ $t('airdrop_setting.number_of_referrer') }}:</div>
                    <div class="slb-fl-30">
                        <select-box v-if="nullSearchKey" v-model="filters.level_referrer"
                                :placeholder="filters.level_referrer.name"
                                :options="level_referrers"/>
                        <div v-else id="disable-div" v-bind:disabled="true">
                            <div  class="button_drop_search">
                                <span>Disable</span> 
                                <!-- <i class="icon-arrow1"></i> -->
                            </div>
                        </div>
                        <currency-input @focus="listError.referrer = false" :value="filters.referrer" v-bind:class="{ 'form-control': true, 'error': this.listError.referrer }" :precision="0"  v-model="filters.referrer" @keyup.enter="search" v-bind:disabled="!this.filters.level_referrer.id"/>
                    </div>
                    <div class="error-msg" v-if="this.listError.referrer">{{$t('airdrop_setting.referrer_missing')}}</div>
                    <div class="error-msg" v-if="this.listError.referrer_than_0">{{$t('airdrop_setting.referrer_than_0')}}  </div>
                </div>
                <div class="filter-item amount referrer">
                    <div class="label-text text-pair minimum-amount">{{ $t('airdrop_setting.amal_holding') }}:</div>
                    <div class="slb-fl-30-1">
                        <select-box v-if="nullSearchKey" v-model="filters.amal_holding"
                                :placeholder="filters.amal_holding.name"
                                :options="amal_holding_wallet"/>
                        <div v-else id="disable-div" v-bind:disabled="true">
                            <div  class="button_drop_search_1">
                                <span>Disable</span> 
                                <!-- <i class="icon-arrow1"></i> -->
                            </div>
                        </div>
                        <currency-input @focus="listError.number_amal_holding = false" :value="filters.number_amal_holding" v-bind:class="{ 'form-control': true, 'error': this.listError.number_amal_holding }" :precision="8"  v-model="filters.number_amal_holding" @keyup.enter="search" v-bind:disabled="!this.filters.amal_holding.id"/>
                    </div>
                    <div class="error-msg" v-if="this.listError.number_amal_holding">{{$t('airdrop_setting.holding_missing')}}</div>
                    <div class="error-msg" v-if="this.listError.number_amal_holding_than_0">{{$t('airdrop_setting.holding_than_0')}}  </div>
                </div>
                
                
                
            </div>
            <div class="filter-container tool-button flex-justify">
                <div class="filter-item btn-action">
                    <button class="btn btn-reset" @click="search()">{{ $t('orders.order_history.search') }}</button>
                    <button class="btn btn-reset" @click="refresh()">{{ $t('orders.order_history.reset') }}</button>
                </div>
                <div>
                    <div class="filter-item">   
                        <button @click="showBonusModal()" class="btn btn-add btn-primary"
                            :disabled="lstChecked.length == 0"
                        >
                            <i class="icon-plus"></i>&nbsp;&nbsp;
                            {{ $t('airdrop_setting.send_bonus') }}
                        </button>

                    </div>
                    <div class="filter-item">
                        <button @click="showRefundModal()" class="btn btn-add btn-primary"
                            :disabled="lstChecked.length == 0"
                        >
                            <i class="icon-plus"></i>&nbsp;&nbsp;
                            {{ $t('airdrop_setting.bonus.refund_bonus_setting') }}
                        </button>

                    </div>
                </div>
            </div>


            <data-table-5 :getData="getData"
                            :limit="10"
                            :column='6'
                            :onPageLoad="false"
                            @DataTable:finish="onDatatableFinish"
                            ref="datatable" class="scroll">
                    <th class="text-center non-sort" width="5%">
                        <input type="checkbox" v-model="isChecked"   @click="chkAllClick($event.target.checked)" />
                    </th>
                    <th class="text-left " >{{ $t('m_account.id') }}</th>
                    <th class="text-left " >{{ $t('base_deposit_withdraw_title_email') }}</th>
                    <th class="text-left ">{{ $t('airdrop_setting.trading_volume') }}</th>
                    <th class="text-left ">{{ $t('m_funds.deposit_usd.deposit_amount') }}</th>
                    <th class="text-left" >{{ $t('m_funds.deposit_usd.amal_amount') }}</th>
                    <th class="text-right">{{ $t('airdrop_setting.number_of_referrer') }}</th>
                    <template slot="body" slot-scope="props">
                        <tr>
                            <td class="text-center">
                                <input type="checkbox" :checked="lstChecked.indexOf('-' + props.item.user_id + '-') >= 0" @click="checkItemClick(props.item, $event.target.checked)" />
                            </td>
                            <td class="text-left">
                                <span class="txt_email_user">{{ props.item.user_id }}</span>
                            </td>
                            <td class="text-left">
                                <div class="item_email_user">
                                    <span class="txt_email_user">{{ props.item.email }}</span>
                                </div>
                            </td>
                            <td class="text-left" >
                                <div class="text-break" v-if="filters.type == exchangeType.margin">{{trimNumber(props.item.total_volume)}} {{ $t('currency.btc.shortname') | uppercase}}</div>
                                <div class="text-break" v-else>{{trimNumber(props.item.total_volume)}} {{ $t('currency.btc.shortname') | uppercase}}</div>
                            </td>
                            <td v-if ="props.item.deposit" class="text-left">
                                <div> {{props.item.deposit | formatCurrencyAmount(null, 0)}} {{ props.item.deposit_coin | uppercase}}</div>
                            </td> 
                            <td v-else class="text-left">--</td>
                            <td class="text-left" v-if ="props.item.amal_holding">
                                <div> {{props.item.amal_holding | formatCurrencyAmount(null, 0)}} {{ $t('currency.amal.shortname') | uppercase}}</div>
                            </td>
                            <td class="text-left" v-else>--</td>
                            <td class="text-right" v-if ="props.item.referrer">
                                <div> {{props.item.referrer | formatCurrencyAmount(null, 0)}}</div>
                            </td>
                            <td v-else class="text-right">--</td>

                        </tr>
                    </template>
            </data-table-5>
        </div>
        <modal name="sendBonus" width="590" id="modal_send_bonus" :title="modalTitle">
            <div slot="body" class="body-popup">
                <div class="form-group-detail">
                    <div class="group-detail-form-group pay-amount">
                        <label class="group-detail-form-label">{{ $t('airdrop_setting.dividend_manual.pay_amount') }}</label>
                        <div class="clearifx"></div>
                        <div class="bonus-detail">
                            <div class="container-input">
                                <currency-input @focus="listError.bonusAmount = false"  :precision="precision" @keyup.enter="applyBonus" v-bind:class="{ 'form-control': true }" v-model="pay.amount" />
                                <span v-show="errors.has('bonusAmount')" class="f-left is-error">{{ errors.first('bonusAmount') }}</span>
                            </div>
                            <div class="toolbar-element2">
                                <select-box v-model="pay.currency"
                                            :placeholder="pay.currency.name"
                                            class="select-currency" :options="listCoin"
                                />
                            </div>
                        </div>
                        <div class="clearifx"></div>
                    </div>
                    <div class="clearifx"></div>
                    <div class="group-detail-form-group">
                        <label class="group-detail-form-label">{{ $t('airdrop_setting.dividend_manual.pay_for') }}</label>
                            <select-box v-model="pay.wallet"
                                        :placeholder="wallets[0].name"
                                        class="select-wallet" :options="wallets"
                            />
                        <div class="clearifx"></div>
                        <div class="error-box">
                            <span v-show="errors.has('memo')" class="f-left is-error">{{ errors.first('memo') }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </modal>
        <modal name="refundBonus" width="590" id="modal_send_bonus" :title="refundModalTitle">
            <div slot="body" class="body-popup">
                <div class="form-group-detail">
                    <div class="group-detail-form-group pay-amount">
                        <label class="group-detail-form-label">{{ $t('airdrop_setting.dividend_manual.refund_amount') }}</label>
                        <div class="clearifx"></div>
                        <div class="bonus-detail">
                            <div class="container-input">
                                <currency-input  :precision="precision" @keyup.enter="applyRefund" v-bind:class="{ 'form-control': true }" v-model="refundAmount" />
                                <span v-show="errors.has('refundAmount')" class="f-left is-error">{{ errors.first('refundAmount') }}</span>
                            </div>
                            <div class="toolbar-element2">
                                <select-box v-model="refundCoin[0]"
                                            :placeholder="refundCoin[0].name"
                                            class="select-currency" :options="refundCoin"
                                />
                            </div>
                        </div>
                        <div class="clearifx"></div>
                    </div>
                    <div class="clearifx"></div>
                    <div class="group-detail-form-group">
                        <label class="group-detail-form-label">{{ $t('menu.wallet') }}</label>
                            <select-box v-model="refundWallet[0]"
                                        :placeholder="refundWallet[0].name"
                                        class="select-wallet" :options="refundWallet"
                            />
                        <div class="clearifx"></div>
                        <div class="error-box">
                            <span v-show="errors.has('memo')" class="f-left is-error">{{ errors.first('memo') }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
    import rf from '../../lib/RequestFactory';
    import BigNumber from 'bignumber.js';
    import DatePicker from "vuejs-datepicker";
    import moment from "moment";
    import COMMON_CONST from "../../common/Const";
    import SelectBox from "../../components/SelectBox";
    import CoinInput from '../../common/CoinInput.vue'
    import CurrencyInput from "../../components/CurrencyInput";
    import Modal from "../../components/Modal";
    import DataTable5 from "../../components/DataTable5";
    export default {
        components: {
            DatePicker,
            SelectBox,
            CoinInput,
            Modal,
            CurrencyInput,
            DataTable5
        },
        props: {
            disabled: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                product_base: COMMON_CONST.PRODUCT_BASE,
                titlePage: window.i18n.t("airdrop_setting.dividend_setting_manual"),
                startDate: moment().subtract(1, 'week').toDate(),
                endDate: moment().toDate(),
                chosens:[{id: 0, name: 'Off'}, {id: 1, name: 'On'}],
                settingsUser: {
                    self_trading_manual_dividend_spot: {id: 0, name: 'Off'},
                    self_trading_manual_dividend_margin: {id: 0, name: 'Off'},
                },
                nullSearchKey: true,
                filters: {
                    market: "",
                    currency: "",
                    minAmount: "0",
                    pair: "",
                    type: COMMON_CONST.TYPE_EXCHANGE_BALANCE,
                    kyc: 'all',
                    searchKey: '',
                    deposit_coin: {id: null, name:"Disable"},
                    amal_holding: {id: null, name:"Disable"},
                    depositAmount: "0",
                    referrer: "0",
                    number_amal_holding:"0",
                    level_referrer: {id: null, name: 'Disable'},
                    minimum_amount_option: {id: null, name: 'Disable'},
                },
                listError: {
                  filterAmount: false,
                  bonusAmount: false,
                  referrer: false,
                  deposit: false,
                  number_amal_holding: false,
                  referrer_than_0: false,
                  deposit_than_0: false,
                  number_amal_holding_than_0: false,
                  
                },
                minimum_amount_options: [
                    {id: null, name: 'Disable'},
                    {id: 1, name: 'Enable'},
                ],
                level_referrers:[
                    {id: null, name: 'Disable'},
                    {id: 1, name: 'Level 1'},
                    {id: 2, name: 'Level 2'},
                    {id: 3, name: 'Level 3'},
                    {id: 4, name: 'Level 4'},
                    {id: 5, name: 'Level 5'},
                ],
                amal_holding_wallet: [
                    {id: null, name: 'Disable'},
                    {id: 'all', name: 'All'},
                    {id: 'perpetual_dividend_balance', name: 'Dividend Perpetual Balance'},
                    {id: 'dividend_balance', name: 'Dividend Balance'},
                ],
                refundAmount: 0,
                exchangeType : {
                    'spot': COMMON_CONST.TYPE_EXCHANGE_BALANCE,
                    'margin': COMMON_CONST.TYPE_MARGIN_BALANCE
                },
                pay: {
                    amount: "",
                    currency: "",
                    wallet: "",
                },
                kyc: [
                    {id: 'all', name: 'All'},
                    // eslint-disable-next-line standard/object-curly-even-spacing
                    {id: '0', name: 'Not Verified' },
                    {id: '1', name: 'Verified'}
                ],
                currencies:  [
                    {id: "btc" , name: "BTC"},
                    {id: 'eth', name: "ETH"},
                    {id: 'amal', name: "AMAL"}
                ],
                wallets: [
                    {id:COMMON_CONST.TYPE_MAIN_BALANCE.toLowerCase(), name: COMMON_CONST.MAIN_BALANCE},
                    {id:COMMON_CONST.TYPE_AIRDROP_BALANCE.toLowerCase(), name: COMMON_CONST.DIVIDEND_NORMAL},
                    {id:COMMON_CONST.TYPE_DIVIDEND_BONUS_BALANCE.toLowerCase(), name: COMMON_CONST.DIVIDEND_BY_ADMIN}
                ],
                refundCoin : [{id:COMMON_CONST.CURRENCY_AMAL.toLowerCase(), name: COMMON_CONST.CURRENCY_AMAL.toUpperCase()}],
                refundWallet : [{id:COMMON_CONST.TYPE_DIVIDEND_BONUS_BALANCE.toLowerCase(), name: COMMON_CONST.DIVIDEND_BY_ADMIN}],
                pairs: [],
                allPairs: [],
                markets: [],
                lstChecked: '',
                isChecked : false,
                isOnline: true,
                datatableRows: [],
                modalTitle: window.i18n.t("airdrop_setting.bonus.bonus_setting"),
                refundModalTitle: window.i18n.t("airdrop_setting.bonus.refund_bonus_setting"),
                precision: 8,
                lstUsers: [],
                allUserHasChecked: [],
                rootSymbols: [],
                childSymbols: [],
                listCoin: [],
                listDepositCoin: [],
            }
        },
        computed: {
            self_trading_manual_dividend_spot() {
                return this.settingsUser.self_trading_manual_dividend_spot;
            },
            self_trading_manual_dividend_margin() {
                return this.settingsUser.self_trading_manual_dividend_margin;
            },
            minimum_amount_status() {
                return this.filters.minimum_amount_option.id;
            },
            deposit_status() {
                return this.filters.deposit_coin.id;
            },
            referrer_status() {
                return this.filters.amal_holding.id;
            },
            holding_status() {
                return this.filters.level_referrer.id;
            },
            

        },
        watch: {
            minimum_amount_status: function(newV) {
                if (newV) {
                    this.filters.deposit_coin = {id: null, name:"Disable"};
                    this.filters.amal_holding = {id: null, name:"Disable"};
                    this.filters.level_referrer = {id: null, name: 'Disable'};
                }
            },
            deposit_status: function(newV) {
                if (newV) this.filters.minimum_amount_option =  {id: null, name:"Disable"};
            },
            referrer_status: function(newV) {
                if (newV) this.filters.minimum_amount_option = {id: null, name:"Disable"};
            },
            holding_status: function(newV) {
                if (newV) this.filters.minimum_amount_option = {id: null, name:"Disable"};
            },


            "filters.market" : function (currency) {
                if (this.filters.type == this.exchangeType.spot) {
                    this.pairs = [];
                    let coin = currency.name;
                    this.allPairs.map((obj) => {
                        let pair = obj.name.split('/');
                        if (pair[1] == coin) {
                            this.pairs.push({id: pair[0].toLowerCase(), name: obj.name});
                        }
                    });
                }
                if (this.filters.type == this.exchangeType.margin) {
                    this.pairs = [];
                    let coin = currency.name;
                    this.childSymbols.map((obj) => {
                        if (obj.root == coin) {
                            this.pairs.push({id: obj.id, name: obj.name});
                        }
                    });
                }
                
                if(this.pairs && this.pairs[0]) this.filters.pair = this.pairs[0];
            },
            "pay.currency": function (currency) {
                // Full balance list with amal currency
                if(currency.id == "amal") {
                    this.wallets = [
                        {id:COMMON_CONST.TYPE_MAIN_BALANCE.toLowerCase(), name: COMMON_CONST.MAIN_BALANCE},
                        {id:COMMON_CONST.TYPE_AIRDROP_BALANCE.toLowerCase(), name: COMMON_CONST.DIVIDEND_NORMAL},
                        {id:COMMON_CONST.TYPE_DIVIDEND_BONUS_BALANCE.toLowerCase(), name: COMMON_CONST.DIVIDEND_BY_ADMIN}
                        ];
                } else {
                    // only main balance with remains
                    this.wallets = [
                        {id:COMMON_CONST.TYPE_MAIN_BALANCE.toLowerCase(), name: COMMON_CONST.MAIN_BALANCE},  
                    ];
                }
            },
            self_trading_manual_dividend_spot(newValue) {
                let obj = {
                key : 'self_trading_manual_dividend_spot',
                value: newValue.id
                }
                this.changeStatusSetting(obj);
            },
            self_trading_manual_dividend_margin(newValue) {
                let obj = {
                key : 'self_trading_manual_dividend_margin',
                value: newValue.id
                }
                this.changeStatusSetting(obj);
            },
            "filters.searchKey" : function (newText){
                if (this.filters.searchKey == "") {
                    this.nullSearchKey = true;
                    

                } else {
                    this.nullSearchKey = false;
                    this.filters.deposit_coin = {id: null, name:"Disable"};
                    this.filters.amal_holding = {id: null, name:"Disable"};
                    this.filters.level_referrer = {id: null, name: 'Disable'};
                    this.filters.minimum_amount_option = {id: null, name:"Disable"};
                }
            }
        },
        created() {
            this.getAllCoin();
            this.pay.currency = this.currencies[0];
            this.pay.wallet = this.wallets[0];
            this.filters.kyc = this.kyc[0];
            this.requestSpot();
            this.getSettingsAdmin();
        },
        methods: {
            getAllCoin() {
                this.listCoin = [];
                rf.getRequest('AdminRequest').getAllCoin().then(res => {
                    try {
                        window._.map(res.data, item => {
                            if (typeof item.coin !== 'undefined') {
                                this.listCoin.push({ id: item.coin, name: item.coin.toUpperCase() });
                    }
                    });
                }
                catch (ex) {
                    console.error(ex);
                }
                this.listDepositCoin = [{id: null, name: "Disable"}].concat(this.listCoin);
                });
                
            },
            validateFilter() {
                let check = true;
                if (!this.filters.minAmount) {
                    this.listError.filterAmount = true;
                    check = false;
                } else {
                    this.listError.filterAmount = false;
                }
                if (this.filters.deposit_coin.id ) {
                    if (!this.filters.depositAmount) {
                        this.listError.deposit = true;
                        check = false;
                    }
                    if (this.filters.depositAmount === 0) {
                        this.listError.deposit_than_0 = true;
                        check = false;
                    }
                    
                } else {
                    this.listError.deposit = false;
                    this.listError.deposit_than_0 = false;
                }
                if (this.filters.level_referrer.id) {
                    if (!this.filters.referrer) {
                        this.listError.referrer = true;
                        check = false;
                    }
                    if (this.filters.referrer == 0) {
                        this.listError.referrer_than_0 = true;
                        check = false;
                    }
                } else {
                    this.listError.referrer = false;
                    this.listError.referrer_than_0 = false;
                }
                if (this.filters.amal_holding.id) {
                    if (!this.filters.number_amal_holding) {
                        this.listError.number_amal_holding = true;
                        check = false;
                    }
                    if (this.filters.number_amal_holding == 0) {
                        this.listError.number_amal_holding_than_0 = true;
                        check = false;
                    }
                } else {
                    this.listError.number_amal_holding = false;
                    this.listError.number_amal_holding_than_0 = false;
                }
                console.log(check);
                return check;
            },
            changeStatusSetting(value) {
                rf.getRequest('LeaderboardRequest').changeSetting({key : value.key, value: value.value});
            },
            getSettingsAdmin() {
                rf.getRequest('LeaderboardRequest').getSelfTradingSettings().then(res=>{
                for (let key in this.settingsUser) {
                    console.log(key);
                    let obj =  this.findObjectFromChosens(res.data.find(el=> {
                    if ( el.key == key) return el;
                    }).value);
                    this.settingsUser[key] = obj;
                }
                })
            },
            findObjectFromChosens(value) {
                return this.chosens.find(el => {
                return el.id == value;
                })
            },
            searchParams() {
                let params = {};
                params.type = this.filters.type;
                params.start_date = (moment(this.startDate)
                    .startOf("day")
                    .format("YYYY-MM-DD HH:mm:ss")),
                params.end_date = (moment(this.endDate)
                    .endOf("day")
                    .format("YYYY-MM-DD HH:mm:ss")),
                params.coin = this.filters.type == COMMON_CONST.TYPE_EXCHANGE_BALANCE ? this.filters.pair.name.split("/")[0].toLowerCase() : this.filters.pair.name.toUpperCase();
                params.market = this.filters.market.id;
                params.volume =  -1;
                if (this.filters.minimum_amount_option && this.filters.minimum_amount_option.id) {
                    params.volume = this.filters.minAmount;
                }
                params.searchKey = this.filters.searchKey;
                if (this.filters.level_referrer.id) {
                    params.referrer = this.filters.referrer;
                    params.level_referrer = this.filters.level_referrer.id;
                }
                if (this.filters.deposit_coin.id) {
                    params.deposit = this.filters.depositAmount;
                    params.deposit_coin = this.filters.deposit_coin.id;
                }
                if (this.filters.amal_holding.id) {
                    params.amal_holding = this.filters.amal_holding.id.toString();
                    params.number_amal_holding = this.filters.number_amal_holding;
                }
                if (this.filters.kyc.id != "all") {
                    params.kyc = this.filters.kyc.id;
                }
                return params;
            },
            requestSpot() {
                this.allPairs = [];
                this.pairs = [];
                rf.getRequest('MasterdataRequest')
                    .getAll().then(res => {
                    try {
                        window._.map(res.coin_settings, item => {
                            this.allPairs.push({id: item.id, name: (item.coin + '/' + item.currency).toUpperCase()});
                            if (item.currency == this.defaultMarket) {
                                this.pairs.push({id: item.id, name: (item.coin + '/' + item.currency).toUpperCase()});
                                this.filters.pair = {id: item.id, name: (item.coin + '/' + item.currency).toUpperCase()};
                            }
                        });
                    } catch (ex) {
                        console.log(ex);
                    }
                });
                // Get all markets
                rf.getRequest('AdminRequest').getPriceGroupCurrency().then(res => {
                    this.markets = []
                    try {
                        window._.map(res.data, item => {
                            this.markets.push({ id: item.currency, name: item.currency.toUpperCase() });
                            if (item.currency == 'usd') {
                                this.filters.market = { id: item.currency, name: item.currency.toUpperCase() };
                            }
                        });
                    }
                    catch (ex) {
                        console.error(ex);
                    }
                });
            },
            requestMargin() {
                this.childSymbols = [];
                rf.getRequest('AdminRequest').getInstrument([])
                    .then(res => {
                        const response = [];
                        window._.map(res.data, (item) => {
                            response.push({id: item.root_symbol, name: item.root_symbol});
                            this.childSymbols.push({id: item.id, 'name': item.symbol, 'root': item.root_symbol});
                        });
                        let output = [];
                        let keys   = [];
                        response.forEach(function (symbol) {
                            var key = symbol['id'];
                            if (keys.indexOf(key) === -1) {
                                keys.push(key);
                                output.push(symbol);
                            }
                        });
                        this.markets = output;
                        this.filters.market = output[0];
                        let coin = output[0].name;
                        this.childSymbols.map((obj) => {
                            if (obj.root == coin) {
                                this.pairs.push({id: obj.id, name: obj.name});
                            }
                        });
                        this.filters.pair = this.pairs[0];
                    });
            },
            selectTab(type) {
                this.filters.type = type;
                this.resetInput();
                this.isChecked = false;
                this.$refs.datatable.clear();
                switch (type) {
                    case this.exchangeType.margin:
                        this.requestMargin();
                        break;
                    case this.exchangeType.spot:
                        this.requestSpot();
                        break;
                    default: break;
                }
            },
            convertTime(time) {
                let stillUtc = moment.utc(time).toDate();
                let local = moment(stillUtc).local().format('YYYY-MM-DD HH:mm:ss');
                return local;
            },
            trimNumber(number) {
                return number == null ? number : new BigNumber(number);
            },
            search() {
                this.listError.filterAmount = false;
                this.listError.deposit = false;
                this.listError.referrer = false;
                this.listError.number_amal_holding = false;
                this.listError.deposit_than_0 = false;
                this.listError.referrer_than_0 = false;
                this.listError.number_amal_holding_than_0 = false;
                this.isChecked = false;
                this.lstChecked = '';
                if (!this.validateFilter()) {
                    return;
                }
                
                // if (this.startDate > this.endDate) {
                //     console.log(this.startDate, " === ", this.endDate);
                //     this.$toastr("error", this.$t("common.date.warning"));
                //     return;
                // }
                if (moment.unix(this.startDate / 1000).format("YYYY-MM-DD") > moment.unix(this.endDate / 1000).format("YYYY-MM-DD")) {
                    this.$toastr('error', this.$t('common.date.warning'));
                    return;
                }
                this.$refs.datatable.filter(this.searchParams());
            },
            getData(params) {
                let defaultParams = {};
                defaultParams.type = this.filters.type;
                defaultParams.start_date = (moment(this.startDate)
                    .startOf("day")
                    .format("YYYY-MM-DD HH:mm:ss")),
                defaultParams.end_date = (moment(this.endDate)
                    .endOf("day")
                    .format("YYYY-MM-DD HH:mm:ss")),
                defaultParams.coin = this.filters.type == COMMON_CONST.TYPE_EXCHANGE_BALANCE ? this.filters.pair.name.split("/")[0].toLowerCase() : this.filters.pair.name.toUpperCase();
                defaultParams.market = this.filters.market.id;
                return rf.getRequest('AdminRequest').getTradingVolumneRanking(Object.assign(params, defaultParams )).then(res => {
                    if(res.data != undefined) {
                        this.lstUsers = res.data.data;
                        return res.data;
                    }
                }).catch(error => {
                    console.log(error);
                });
            },
            refresh() {
                this.resetInput();
                this.isChecked = false;
            },
            resetInput() {
                this.listError.filterAmount = false;
                this.listError.deposit = false;
                this.listError.referrer = false;
                this.filters = Object.assign(this.filters, {
                    currency: "",
                    minAmount: "0",
                    pair: this.pairs[0],
                    kyc: this.kyc[0],
                    market:this.markets[0],
                    searchKey: '',
                    depositAmount: "0",
                    referrer: "0",
                    number_amal_holding: "0",
                });
                
                this.filters.level_referrer = {id: null, name: 'Disable'};
                this.filters.deposit_coin = {id: null, name:"Disable"}; 
                this.filters.amal_holding = {id: null, name:"Disable"};
                this.filters.minimum_amount_option = {id: null, name:"Disable"};
                this.startDate = moment().subtract(1, 'week').toDate();
                this.endDate = moment().toDate();
                this.$refs.datatable.clear();
            },
            onDatatableFinish() {
                const datatable = this.$refs.datatable;
            },
            capitalizeFirstLetter(string) {
                return string.charAt(0).toUpperCase() + string.slice(1);
            },
            // Get amount which hasn't calculate fee yet.
            getFullAmount(reductionAmount, fee) {
                return new BigNumber(`${Math.abs(reductionAmount)}`).add(`${fee}`).toString();
            },
            formatDateTime(date) {
                return moment(date, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss');
            },
            customFormatter(date) {
                return moment(date).format('YYYY - MM - DD');
            },
            showBonusModal() {
                this.errors.clear();
                this.pay.currency = {id: 'btc', name: 'BTC'};
                CommonModal.show('sendBonus', {
                    position: "center",
                    mask: true,
                    buttons: [
                        {
                            label: this.$t('common.action.cancel'),
                            focused: true,
                            style: {
                                width: "140px",
                                background: "rgb(0, 112, 192)"
                            },
                            callback: () => {
                                CommonModal.hide("sendBonus");
                            }
                        },
                        {
                            label: this.$t('common.action.send'),
                            focused: true,
                            style: {
                                width: "140px",
                                background: "rgb(0, 112, 192)"
                            },
                            callback: () => {
                                this.applyBonus();
                            }
                        }
                    ],
                });
            },
            showRefundModal() {
                this.errors.clear();
                this.refundAmount = 0;
                this.pay.currency = {id: 'btc', name: 'BTC'};
                CommonModal.show('refundBonus', {
                    position: "center",
                    mask: true,
                    buttons: [
                        {
                            label: this.$t('common.action.cancel'),
                            focused: true,
                            style: {
                                width: "140px",
                                background: "rgb(0, 112, 192)"
                            },
                            callback: () => {
                                CommonModal.hide("refundBonus");
                            }
                        },
                        {
                            label: this.$t('common.action.refund'),
                            focused: true,
                            style: {
                                width: "140px",
                                background: "rgb(0, 112, 192)"
                            },
                            callback: () => {
                                this.applyRefund();
                            }
                        }
                    ],
                });
            },
            applyBonus() {
                if(!this.isOnline){
                    this.$toastr('error', this.$t('common.Internet.connection.fail'));
                    return false;
                }
                if (!this.pay.amount) {
                    this.errors.add({field: 'bonusAmount', msg: this.$t('airdrop_setting.payout_amount_missing')});
                    return false;
                } else {
                    this.listError.filterAmount = false;
                }
                let lstCheckedUsers = [];
                window._.filter(this.allUserHasChecked, (item) => {
                    if (this.lstChecked.indexOf('-' + item.user_id + '-') >= 0) {
                        lstCheckedUsers.push({
                            'user_id': item.user_id,
                            'total_volume': item.total_volume,
                            'amount': this.pay.amount,
                            'wallet': this.pay.wallet.id,
                            'email': item.email,
                            'coin': window._.get(item, "coin", "usd"),
                            'market': window._.get(item, "market", "usdt"),
                            'filter_from': this.startDate,
                            'filter_to': this.endDate,
                            'type': this.filters.type,
                            'bonus_currency': this.pay.currency.id,
                        });
                    }
                });
                rf.getRequest('AdminRequest').applyBonusBalance(lstCheckedUsers)
                    .then(data => {
                        if (data.success) {
                            this.$toastr('success', this.$t('airdrop_setting.send_bonus_success'));
                            CommonModal.hide('refundBonus');
                            // this.isChecked = false;
                            // this.chkAllClick(this.isChecked);
                            this.pay.amount = '';
                        } else {
                            CommonModal.hide('sendBonus');
                            this.$toastr('error', this.$t('airdrop_setting.send_bonus_error'))
                        }
                    }).catch(error => { 
                    })
            },
            applyRefund() {
                if(!this.isOnline){
                    this.$toastr('error', this.$t('common.Internet.connection.fail'));
                    return false;
                }
                if (!this.refundAmount) {
                    this.errors.add({field: 'refundAmount', msg: this.$t('airdrop_setting.refund_amount_missing')});
                    return false;
                }
                let lstCheckedUsers = [];
                window._.filter(this.allUserHasChecked, (item) => {
                    if (this.lstChecked.indexOf('-' + item.user_id + '-') >= 0) {
                        lstCheckedUsers.push({
                            'user_id': item.user_id,
                            'amount': this.refundAmount,
                            'wallet': this.refundWallet[0],
                            'email': item.email,
                            'coin': window._.get(item, "coin", "usd"),
                            'market': window._.get(item, "market", "usdt"),
                            'filter_from': this.startDate,
                            'filter_to': this.endDate,
                            'type': this.filters.type,
                            'bonus_currency': this.pay.currency.id,
                            'cashback': true
                        });
                    }
                });
                rf.getRequest('AdminRequest').refundBonusBalance(lstCheckedUsers)
                    .then(data => {
                        if (data.success) {
                            this.$toastr('success', this.$t('airdrop_setting.cashback_success'));
                            CommonModal.hide('sendBonus');
                            // this.chkAllClick(false);
                            this.pay.amount = '';
                        } else {
                            CommonModal.hide('refundBonus');
                            this.$toastr('error', this.$t('airdrop_setting.refund_bonus_error'))
                        }
                    }).catch(error => { 
                            this.$toastr('error', this.$t('airdrop_setting.send_bonus_error'))
                    })
            },
            chkAllClick(isChecked) {
                this.datatableRows = this.$refs.datatable.rows;
                window._.forEach(this.datatableRows, item => {
                    this.checkItemClick(item, isChecked);
                });
            },
            checkItemClick(item, isChecked) {
                this.datatableRows = this.$refs.datatable.rows;
                const isUserExists = this.allUserHasChecked.find(el=>el.user_id == item.user_id) !== undefined
                if(!isUserExists){
                    this.allUserHasChecked.push(item)
                }
                if (isChecked && this.lstChecked.indexOf('-' + item.user_id + '-') < 0) {
                    this.lstChecked += '-' + item.user_id + '-';
                }
                if (!isChecked && this.lstChecked.indexOf('-' + item.user_id + '-') >= 0) {
                    this.lstChecked = this.lstChecked.replace('-' + item.user_id + '-', '');
                }
            },
        },
        mounted() {
            this.$emit('EVENT_PAGE_CHANGE', this);
            window.addEventListener('online',(event) =>{
                this.isOnline = true;
            });
            window.addEventListener('offline',(event) =>{
                this.$toastr('error', this.$t('common.Internet.connection.fail'));
                this.isOnline = false;
            });
        }
        // ALl on load binding
    }
</script>

<style lang="scss" scoped>
@import "@/assets/sass/variables";
    .m-flex {
        display: flex;
    }
    input[type='number'] {
        -moz-appearance:textfield;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }
    .flex-justify {
        display: flex;
        justify-content: space-between;
    }
    .mt-33 {
        margin-top: 33px;
    }
    .slb-fl-30{
        display: flex;
    }
    .slb-fl-30-1{
        display: flex;
    }
    .empty-data {
        text-align: center;
        padding-top: 15px;
        background-color: $color_white;
    }
    .group_input_ardrop_payout {
            display: block;
            float: left;
            width: calc(100% - 60px);
            position: relative;
            .input_airdrop_payout {
              width: 100%;
              height: 35px;
              line-height: 20px;
              padding: 8px 60px 7px 15px;
              font-size: $font_root;
              font-family: $font-family-roboto-medium;
              color: $color-grey-dark;
              border-radius: 3px;
              border: 1px solid $color_alto;
              &:hover,
              &:active,
              &:focus {
                border-color: $color-jungle-green;
              }
              &[disabled] {
                background-color: $color-bright-gray;
                border-color: $color_alto;
                cursor: not-allowed;
                &:hover,
                &:active,
                &:focus {
                  border-color: $color_alto;
                }
              }
            }
            .coin_airdrop_payout {
              position: absolute;
              top: -1px;
            //   right: 12px;
              padding: 6px 0px 5px 5px;
              line-height: 20px;
              right: -52px;
              font-size: $font_root;
              font-family: $font-family-roboto-medium;
              color: #666666;
            }
          }
    .w_20 {
        width: 20%
    }
    .w_25 {
        width: 25%;
    }
    .opacity-row {
        opacity: 0.4;
    }
    .text-wrap {
        word-wrap: break-word;
    }
    .toolbar-element2 {
        display: inline-block;
        float: left;
    }
    .bw-element {
        float: left;
        font-size: $font_medium;
        margin-left: 5px;
        height: 30px;
        line-height: 30px;
        margin-right: 5px;
        color: $color-grey-dusty;
    }
    .checkbox {
        margin-top: 0px;
        display: inline-block;
        font-size: $font_root;
        margin-bottom: 0px;
        font-weight: normal;
        height: 30px;
        line-height: 30px;
        input[type="checkbox"] {
            display: none;
        }
        span {
            position: relative;
            content: "";
            display: inline-block;
            border: 1px solid $color_alto;
            width: 16px;
            height: 16px;
            top: 3px;
            left: 8px;
            border-radius: 2px;
            margin-right: 8px;
            position: relative;
            background-color: $color_white;
        }
        input:checked {
            & + span{
                &:after{
                    opacity: 0.9;
                    content: '';
                    position: absolute;
                    width: 14px;
                    height: 7px;
                    background: transparent;
                    top: 2px;
                    left: 0px;
                    border: 3px solid $color_blue_logo;
                    border-top: none;
                    border-right: none;
                    transform: rotate(-45deg);
                    -webkit-transform: rotate(-45deg);
                    -moz-transform: rotate(-45deg);
                    -o-transform: rotate(-45deg);
                    -ms-transform: rotate(-45deg);
                }
            }
        }
    }
    .manual_dividend {
        .filter-container {
            margin: 10px 0px 30px 0px;
            height: 50px;
            .filter-item.kyc{
                .toolbar-element2{
                    // width: 100px;
                }
            }
            .filter-item {
                float: left;
                margin: 0 5px;
                &.mr_left0 {
                    margin-left: 0px;
                }
                input {
                    margin-top: 23px;
                    display: inline-block;
                    width: 160px;
                    font-family: $font-family-roboto;
                    &:hover, &:active, &:focus {
                        border-color: $color-jungle-green;
                    }
                }
                select {
                    width: 65px;
                    display: inline-block;
                    font-family: $font-family-roboto;
                    font-size: $font_root;
                    &:hover, &:active, &:focus {
                        border-color: $color-jungle-green;
                    }
                }
                button {
                    width: auto;
                    margin: 23px 2px 0px 2px;
                    height: 30px;
                    font-size: $font-smaller;
                    font-family: $font-family-roboto;
                }
                .btn-reset {
                    background-color: $color-caribbean-green;
                    border: 1px solid $color-caribbean-green;
                    width: 80px;
                    text-transform: uppercase;
                    color: $color-white;
                    font-weight: 600;
                    &:hover {
                        border-color: $color-aquamarine;
                        background-color: $color-aquamarine;
                    }
                }
                .btn-primary {
                    padding: 0;
                    min-width: 80px;
                    &:hover {
                        border-color: $color-aquamarine;
                        background-color: $color-aquamarine;
                    }
                }
            }
            .pair {
                input {
                    width: 65px;
                }
            }
            .amount {
                input {
                    margin-top: 0px;
                    margin-left: 0px;
                }
                .pdt60 {
                    padding-right: 40px;
                }
                &.referrer{
                    input{
                        max-width: 111px;
                }
            }
            }
            .text-date {
                color: $color-grey-dusty;
                margin-bottom: 5px;
                font-size: 13px;
                font-family: Roboto-Regular;
            }
            .text-pair {
                font-family: Roboto-Regular;
                color: $color-grey-dusty;
                margin-bottom: 5px;
                font-size: 13px;
                &.ml-14{
                    margin-left: 14px;
                }
            }
            
            .minimum-amount {
                // padding-left: 13px !important;
                
            }
            .side {
                margin: 0 10px;
            }
        }
        .form-control {
            background: $color-white-smoke;
            height: 30px;
            font-size: $font_root;
            padding-left: 11px;
            padding-right: 0;
        }
        .datatable{
            button.btnForm.bt-action{
                padding-left: 5px !important;
                padding-right: 5px !important;
                font-weight: 400 !important;
            }
            table {
                thead {
                    th{
                        padding: 8px 10px 4px 14px !important;
                       &:first-child:after{
                           width: 6px;
                       }
                    }
                }
            }
        }
        .buy {
          color: $text-color-jade;
        }
        .sell {
            color: $text-color-red;
        }
        .btn-detail {
            background-color: $color-caribbean-green;
            padding: 2px 10px;
            font-size: $font-smaller;
            color: $color-white;
            border:1px solid $color-caribbean-green;
            border-radius: 3px;
            text-transform: uppercase;
            font-family: $font-family-roboto;
            &:hover{
                background-color: $color-aquamarine;
                border-color: $color-aquamarine;
            };
        }
    }
    .form-group-detail {
        .group-detail-form-group {
            .button_drop_search {
                height: 30px !important;
            }
            .group-detail-form-label{
                width: 100%;
                color: $color-grey-dusty;
                float: left;
                font-weight: normal;
                font-family: $font-family-roboto;
                margin-bottom: 0px;
                margin-top: 10px;
            }
            .group-detail-form-value {
                width: 100%;
                padding: 8px 16px;
                border: 1px solid #dee3eb;
                height: 35px;
                line-height: 35px;
                border-radius: 3px;
                font-family: $font-family-roboto;
                // font-weight: 600;
                &:focus {
                    border-color: $color-jungle-green;
                }
            }
            .textarea_input {
                height: 120px;
            }
        }
        .error-box {
            display: block;
            float: left;
            overflow: hidden;
            width: 100%;
            span {
                font-family: $font-family-roboto-medium !important;
            }
        }
        .is-error {
            color: #ff5252 !important;
            font-family: "Roboto-Regular", sans-serif; 
        }
        .input-error {
            border: 1px solid #f74940 !important;
        }
    }
</style>
<style lang="scss">
@import "@/assets/sass/variables";
    .bonus-detail {
        width: 100%;
        display: inline-flex;
        .container-input {
            width: 100%;
            flex-flow: column;
            display: flex;
        }
    }
    #modal_send_bonus {
        .list_button_modal {
            margin-top: 120px !important;
        }
        input {
            height: 35px !important;
        }
        .sc_search_select {
            .group_search_select {
                .button_drop_search {
                    height: 35px !important;
                    padding-top: 8px;
                }
            }
        }
        .modal {
            .modal-dialog {
                .modal-content {
                    .modal-title {
                        text-transform: none;
                    }
                }
            }
        }
    }
    #disable-div {
        .button_drop_search {
            height: 30px;
            min-width: 65px !important;
            padding: 5px 0px 6px 6px !important;
            border-radius: 3px;
            font-size: $font-small;
            font-family: $font-family-roboto;
            float: left;
            color: #666666;
            font-size: 12px;
            line-height: 18px;
            border: 1px solid #CFCFCF;
            border-radius: 3px;
            i.icon-arrow1 {
                margin-left: 5px;
                color: #333333;;
                padding: 4px 2px;
                font-size: 11px;
                float: right;
                
            }
            &:hover{
                    cursor: not-allowed;
            }
        }
        .button_drop_search_1 {
            height: 30px;
            min-width: 170px !important;
            padding: 5px 0px 6px 6px !important;
            border-radius: 3px;
            font-size: $font-small;
            font-family: $font-family-roboto;
            float: left;
            color: #666666;
            font-size: 12px;
            line-height: 18px;
            border: 1px solid #CFCFCF;
            border-radius: 3px;
            i.icon-arrow1 {
                margin-left: 5px;
                color: #333333;;
                padding: 4px 2px;
                font-size: 11px;
                float: right;
            }
            &:hover{
                    cursor: not-allowed;
            }
        }
        
    }
    #manual_dividend {
        .tab-header {
            display: block;
            width: 100%;
            float: left;
            margin: 10px 0px 23px 0px;
            ul {
                list-style: none;
                padding-left: 0px;
                li {
                    float: left;
                    margin-right: 25px;
                    font-size: $font_big;
                    color: $color-grey-dark;
                    display: block;
                    >a {
                        color: $color-grey-dark;
                        font-family: $font-family-roboto-medium;
                        font-weight: 500;
                        font-size: $font_big;
                        display: block;
                        float: right;
                        &::before {
                            content: "";
                            display: inline-block;
                            width: 12px;
                            height: 12px;
                            border-radius: 50%;
                            border: solid 1px #979797;
                        }
                        span {
                            padding-left: 5px;
                        }
                    }
                    &.active {
                        >a {
                            &::before {
                                border: solid 4px $color-jungle-green;
                            }
                            color: $color-jungle-green;
                        }
                    }
                }
            }
        }
        .filter-item.market {
            .sc_search_select {
                .group_search_select {
                    .button_drop_search {
                        height: 30px;
                        min-width: 112px !important;
                        margin-right: 15px;
                        padding: 5px 10px 5px 10px !important;
                        border-radius: 3px;
                        font-size: $font-small;
                        font-family: $font-family-roboto;
                        i.icon-arrow1 {
                            margin-left: 5px;
                            color: $color_dove_gray;
                        }
                        &:hover, &:active, &:focus {
                            border-color: $color-jungle-green;
                            i.icon-arrow1 {
                                color: $color-jungle-green;
                            }
                        }
                    }
                    .box_list_search_select {
                        left: 14px;
                        width: 85px;;
                        .list_search_select {
                            li {
                                font-family: $font-family-roboto;
                                font-size: $font-small;
                                &:hover, &:active, &:focus {
                                    color: $color-jungle-green;
                                }
                            }
                        }
                    }
                }
            }
        }
        .filter-item.pair {
            .sc_search_select {
                .group_search_select {
                    .button_drop_search {
                        height: 30px;
                        margin-left: 15px;
                        min-width: 112px !important;
                        margin-right: 15px;
                        padding: 5px 10px 5px 10px !important;
                        border-radius: 3px;
                        font-size: $font-small;
                        font-family: $font-family-roboto;
                        i.icon-arrow1 {
                            margin-left: 5px;
                            color: $color_dove_gray;
                        }
                        &:hover, &:active, &:focus {
                            border-color: $color-jungle-green;
                            i.icon-arrow1 {
                                color: $color-jungle-green;
                            }
                        }
                    }
                    .box_list_search_select {
                        left: 14px;
                        width: 85px;;
                        .list_search_select {
                            li {
                                font-family: $font-family-roboto;
                                font-size: $font-small;
                                &:hover, &:active, &:focus {
                                    color: $color-jungle-green;
                                }
                            }
                        }
                    }
                }
            }
        }
        .filter-item.kyc {
            .sc_search_select {
                .group_search_select {
                    .button_drop_search {
                        height: 30px;
                        margin-left: 15px;
                        min-width: 105px !important;
                        margin-right: 15px;
                        padding: 5px 10px 5px 10px !important;
                        border-radius: 3px;
                        font-size: $font-small;
                        font-family: $font-family-roboto;
                        i.icon-arrow1 {
                            margin-left: 5px;
                            color: $color_dove_gray;
                        }
                        &:hover, &:active, &:focus {
                            border-color: $color-jungle-green;
                            i.icon-arrow1 {
                                color: $color-jungle-green;
                            }
                        }
                    }
                    .box_list_search_select {
                        left: 14px;
                        width: 105px;;
                        .list_search_select {
                            li {
                                font-family: $font-family-roboto;
                                font-size: $font-small;
                                &:hover, &:active, &:focus {
                                    color: $color-jungle-green;
                                }
                            }
                        }
                    }
                }
            }
        }
        .slb-fl-30{
            .button_drop_search {
                height: 30px;
                padding: 5px 5px;
            }
            .group_search_select{
                min-width: 65px;
                .box_list_search_select.active{
                    min-width: 77px;
                    li {
                        &:hover {
                            color:#2DAC91;
                        }
                    }
                }
            }
        }
        .slb-fl-30-1{
            display: flex;
            .button_drop_search {
                height: 30px;
                padding: 5px 5px;
            }
            .group_search_select{
                min-width: 170px;
                .box_list_search_select.active{
                    min-width: 200px;
                    li {
                        &:hover {
                            color:#2DAC91;
                        }
                    }
                }
            }
        }
        .error-msg{
            color: $color-red-main;
        }
        .pay-amount{
            position: relative;
        }
        .filter-item.amount{
            position: relative;
            margin-left: 20px;
            .error-msg{
                position: absolute;
                // margin-left: 13px;
            }
        }
        .filter-container.tool-button{
            margin:  15px 0px 0px 0px!important;
            .filter-item{
                button{
                    min-width: 200px;
                    margin: 0px;
                }
            }
        }
        .unit {
            top:50%;
            transform: translateY(-50%);
            right: 0;
            position: absolute;
            // width: 200px;
            text-align: center;
        }
        .borderrrr {
            border: solid 1px #299b82;
        }
        .error {
            border: solid 1px $color-red-main;
            color: $color-red-main
        }
        .Rectangle-6-Copy-9 {
            position: relative;
            overflow: hidden;
            display: block;
            padding-bottom: 1px;
            text-align: left;
            border-radius: 3px;
        }
        #modal_send_bonus {
            .modal-dialog {
                min-height: 330px;
            }
            .modal-content {
                height: auto;
                .modal-body {
                    padding: 0px 70px 15px 70px;
                }
                .modal-footer {
                    padding-bottom: 40px;
                }
            }
            .toolbar-element2
            {
                .select-currency{
                    width: 100%;
                    display: inline-block;
                }
                width: 53%;
                float:right;
            }
            .select-wallet {
                width: 100%;
                float: left;
                margin-bottom: 10px;
                .group_search_select {
                    .button_drop_search {
                        height: 35px !important;
                        padding-top: 8px;
                    }
                }
            }
            input {
                width: 70%;
                display: inline-block;
                background-color: #ffffff;
                height: 28px;
            }
            .modal-title {
                text-align: center;
                margin-top: 40px;
                font-family: $font-family-roboto-medium;
            }
        }
    }
</style>