<template>
  <div class="affiliate-container">
    <div class="affiliate-actions">
      <div class="tabs-container">
        <div class="tab-item" v-for="(value, index) in tabs" :key="index" :class="index == tabActive && 'tab-active'"
          @click="tabActive = index">{{
          value }}</div>
      </div>

      <div class="affiliate-actions__search">
        <input title type="text" :placeholder="$t('account.detail.affiliate.inputplace.search')" @keyup.enter="search"
          class="form-control" name="searchKey" v-model="searchKey">
        <button class="affiliate-btn__search" @click="search()">{{ $t('account.detail.affiliate.btn.search') }}</button>
      </div>
    </div>

    <data-table-affiliate :getData="getData" ref="datatable" :column="12" :limit="10" :inLine="true" class="scroll"
      :total-user="totalRecords">
      <th class="text-left" data-sort-field="id account accountId">{{ $t('account.detail.affiliate.id_account') }}</th>
      <th class="text-left" data-sort-field="email">{{ $t('account.detail.affiliate.email') }}</th>
      <th class="text-center" data-sort-field="level">&emsp;{{ $t('account.detail.affiliate.level') }}</th>
      <th class="text-left" data-sort-field="time-create">{{ $t('account.detail.affiliate.creationTime') }}</th>

      <template slot="body" slot-scope="props">
        <tr>
          <td class="text-left">
            {{ props.item.accountID }}
          </td>
          <td class="text-left">
            {{ props.item.email }}
          </td>
          <td class="text-center">
            {{ props.item.level ?? 1 }}
          </td>
          <td class="text-left">
            <p style="text-align: left">{{ formatDate(props.item.creation_time) }}</p>
            <p style="text-align: left">{{ formatDate(props.item.creation_time, true) }}</p>
          </td>
        </tr>
      </template>
    </data-table-affiliate>
  </div>
</template>

<script>

import moment from "moment";
import rf from "../../lib/RequestFactory";
import Modal from "../../components/Modal";
import InputTextAndNumber from "../../components/InputTextAndNumber.vue";

export default {
  name: "Affiliate",
  components: {
    Modal,
    InputTextAndNumber
  },
  data() {
    return {
      tabs: [
        window.i18n.t("account.detail.affiliate.tab.downline"),
        window.i18n.t("account.detail.affiliate.tab.upline")
      ],
      tabActive: 0,
      affiliateData: [],
      searchKey: "",
      totalRecords: 0
    }
  },
  watch: {
    isActive() {
      this.$refs.datatable.refresh();
    },
    tabActive() {
      this.searchKey = '';
      this.$refs.datatable.refresh();
    }
  },
  methods: {
    setAffiliateTypeTab: function (tabValue) {
      this.tabActive = tabValue
    },
    search: function () {
      this.$refs.datatable.filter({s: this.searchKey});
    },
    getData(params) {

      let meta = {}
      meta.s = this.searchKey;
      params = Object.assign(params, meta);
      params.size = params.limit || 5;
      delete params.limit;

      let response = {};
      if (this.tabActive === 0) response = this.getDownData(params);
      if (this.tabActive === 1) response = this.getUpData(params);
      response.then(res => {
        this.totalRecords = res.data.total;
      })
      return response
    },
    getDownData(params) {
      const id = this.$route.query.id;
      return rf.getRequest('UserRequest').getDownAffiliate(id, params);
    },
    getUpData(params) {
      const id = this.$route.query.id;
      return rf.getRequest('UserRequest').getUpAffiliate(id, params);
    },
    formatDate(date, isTime) {
      if (isTime) return moment(date).format('HH:mm:ss')
      return moment(date).format('YYYY-MM-DD');
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/variables";

.affiliate-container {

  .tabs-container {
    display: flex;
    margin: 20px 0;
    margin-bottom: 15px;

    .tab-item {
      font-weight: 600;
      padding: 16px 10px;
      padding-right: 30px;
      color: $color-gray-dark;
      cursor: pointer;

      &:hover {
        background-color: $color-bright-gray;
      }

      &.tab-active {
        border-bottom: 2px solid $color-wood-smoke;
        color: $color-wood-smoke;
      }
    }
  }

  table {
    thead {
      th {
        padding: 16px 0;
      }
    }
    tbody {
      td {
        vertical-align: middle;
        font-family: $mulish-medium;
        p {
          font-family: $mulish-medium;
        }
      }
    }
  }
}

.affiliate-actions {
  display: flex;
  align-items: center;
  margin: 50px 0px;

  &__tab {
    margin-bottom: 20px;
  }

  &__search {
    display: flex;
    gap: 30px;
    margin-left: 120px;

    .form-control {
      border-radius: 10px !important;
    }

    .affiliate-btn__search {
      border-radius: 10px;
      background-color: $color-caribbean-green;
      border: none;
      font-weight: bold;
      padding: 0 10px;
    }
  }
}
</style>