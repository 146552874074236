import BaseRequest from './BaseRequest'

export default class TakeProfitRequest extends BaseRequest {

    index(params)
    {
        let url = '/admin/api/take-profits/';
        return this.get(url, params);
    }

    store(params)
    {
        let url = '/admin/api/take-profits/';
        return this.post(url, params);
    }
}
