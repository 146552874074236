import BaseRequest from '../lib/BaseRequest';
import MasterdataUtils from '@/desktop/common/MasterdataUtils';

export default class MasterdataRequest extends BaseRequest {

  async getAll() {
    let data = MasterdataUtils.getCachedMasterdata();
    if (!data) {
      data = await this._get();
    }
    return data;
  }

  async getAllFuture() {
    let data = MasterdataUtils.getCachedMasterdataFuture();
    if (!data) {
      data = await this._getFuture();
    }
    return data;
  }

  find(table, id) {
    return new Promise((resolve, reject) => {
      this.getAll()
          .then(data => {
            let record = data[table].find(row => parseInt(row.id) === parseInt(id));
            return resolve(record);
          })
    });
  }

  getTable(api, params) {
    let url = `/api/masterdata`;
    if (!params) params = {};
    params._table = api;
    return this.get(url, params);
  }

  _get() {
    let url = '/api/masterdata';
    return this.get(url)
      .then((res) => {
        return MasterdataUtils.saveMasterdata(res.dataVersion, res.data);
      })
      .then(data => {
        return MasterdataUtils.getCachedMasterdata();
      });
  }

  _getFuture() {
    let url = 'api/v1/master-data';
    return this.getFuture(url)
      .then((res) => {
        return MasterdataUtils.saveMasterdataFuture(res.dataVersion, res.data);
      })
      .then(data => {
        return MasterdataUtils.getCachedMasterdataFuture();
      });
  }

  updataMasterdata(params) {
      let url = '/admin/masterdata/update-masterdata';
      return this.put(url, params);
  }

  registerErc20(params) {
      let url = '/admin/api/register-erc20';
      return this.post(url, params);
  }

  getUrlPrefix () {
    return '/api/v1';
  }
}
