<template>
    <div id="ckeditor">
        <ckeditor v-model="editorData" :editor="editor" :config="editorConfig" @input="onChange" />
    </div>
</template>

<script>
import {
    ClassicEditor,
    Bold, Code, Italic, Strikethrough, Subscript, Superscript, Underline,
    Mention, Paragraph, Heading, Undo, Alignment, Link, Table, List,
    FontFamily, FontSize, FontColor,
    // Image, ImageToolbar, ImageCaption, ImageStyle, ImageResize, LinkImage, ImageInsert
} from 'ckeditor5';

export default {
    props: {
        textData: {
            type: String,
        },
        onChange: {
            type: Function,
            default: () => {}
        }
    },
    data() {
        return {
            editor: ClassicEditor,
            editorData: '',
            editorConfig: {
                plugins: [
                    Bold, Code, Italic, Strikethrough, Subscript, Superscript, Underline,
                    Mention, Paragraph, Heading, Undo, Alignment, Link, Table, List,
                    FontFamily, FontSize, FontColor,
                    // Image, ImageToolbar, ImageCaption, ImageStyle, ImageResize, LinkImage, ImageInsert
                ],
                toolbar: [
                    'undo', 'redo', '|',
                    'heading', '|',
                    'fontFamily', 'fontSize', 'fontColor', '|',
                    'bold', 'italic', 'underline', 'strikethrough', 'code', 'subscript', 'superscript', '|',
                    'link', 'insertImage', 'blockQuote', 'insertTable', '|',
                    'alignment:left', 'alignment:right', 'alignment:center', 'alignment:justify', '|',
                    'indent', 'outdent', '|',
                    'sourceEditing', '|',  // Adds source code editing
                    'bulletedList', 'numberedList',
                ],
                table: {
                    contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells']
                },
            }
        };
    },
    methods: {
        onInput(value) {
            this.editorData = value;
            this.onChange(value);
        }
    },
    mounted() {
        this.editorData = this.textData;
    }
}

</script>
<style lang="scss" scoped>
@import "@/assets/sass/variables";

#ckeditor {
    .ck {
        overflow: hidden;

        &.ck-reset {
            border-radius: 10px;
            border: 1px solid $color_alto;
        }

        &.ck-editor__top {
            border-radius: 10px;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }

        &.ck-editor__top .ck-sticky-panel .ck-sticky-panel__content {
            border: none;
        }

        .ck-editor__editable {
            min-height: 400px;
            height: 400px;
            border: none;
            border-top: 1px solid $color_alto;
            border-radius: 10px;

            &:focus,
            &:hover {
                border: none !important;
                border-top: 1px solid $color_alto !important;
            }
        }

        .ck-button.ck-on {
            background: #eefffc;

            .ck-icon.ck-icon_inherit-color *:not([fill]) {
                fill: $color-caribbean-green;
            }
        }
    }
}
</style>