<template>
    <div class="notice">
      <div class="supportArea">
        <span class="title_item">
          <span class="title_text">공지사항</span>
        </span>
        <span class="search_box">
          <span class="search_input">
            <input title="조회" type="text"
                   class="input" name="search"
                   placeholder="검색" v-model="search" v-on:keyup.enter="loadNotice()"/>
            <img class="icon_search" src="/images/icon/search_black.png"/>
          </span>
          <button class="search_btn btn-blue"  v-on:click="loadNotice()">조회</button>
        </span>
      </div>
  
      <div class="box-view">
          <!-- View -->
          <div class="view-item view-notice modal-content" v-show="showBoxNotice.view">
            <div class="modal-header">
              <div class="created-at">
                <p>{{noticeData.created_at | dateFormatSupport }}</p>
                <span>{{noticeData.created_at | time }}</span>
              </div>
              <div class="title" v-html="noticeData.title"></div>
              <div class="author">
                <p>작성자</p>
                <span>{{noticeData.author}}</span>
              </div>
              <div class="view">
                <p>조회수</p>
                <span>{{noticeData.view_count}}</span></div>
            </div>
            <div class="modal-body">
              <div class="wrap-content">
                <textarea name="name" rows="11" style="width: 100%; height: 100%; background:#ffffff" autofocus disabled v-model="noticeData.content"></textarea>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn-cancel btn btn-default" v-on:click="showModalEditNotice()">수정</button>
              <button type="button" class="btn-cancel btn btn-default" v-on:click="showModalRemoveNotice()">삭제</button>
            </div>
          </div>
          <!-- View -->
  
          <!-- Edit -->
          <div class="view-item edit-notice modal-content" v-show="showBoxNotice.edit">
            <div class="modal-header">
              <div class="created-at">
                <p>{{noticeData.created_at | dateFormatSupport }}</p>
                <span>{{noticeData.created_at | time }}</span>
              </div>
              <div class="title" v-html="noticeData.title"></div>
              <div class="author">
                <p>작성자</p>
                <span>{{noticeData.author}}</span>
              </div>
              <div class="view">
                <p>조회수</p>
                <span>{{noticeData.view_count}}</span></div>
            </div>
            <div class="modal-body">
              <div class="wrap-content">
                <textarea name="name" rows="11" style="width: 100%; height: 100%;" autofocus v-model="noticeData.content"></textarea>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn-cancel btn btn-default"  v-on:click="updateNotice()">수정</button>
            </div>
          </div>
          <!-- Edit -->
  
          <!-- Add -->
          <div class="view-item add-notice modal-content" v-show="showBoxNotice.add">
            <div class="modal-body">
              <div class="wrap-content">
                <div class="row_form">
                  <label for="title" class="lb-notice">제목</label>
                  <input id="title" v-model="noticeForm.title" class="inp-notice"/>
                    <span v-if="noticeForm.errors.title" class="help is-danger">{{ noticeForm.errors.title }}</span>
                  </input>
                </div>
  
                <div class="row_form">
                  <textarea name="" cols="30" rows="15"  class="form-control" id="content"  v-model="noticeForm.content" autofocus></textarea>
                  <span v-if="noticeForm.errors.content"  class="help is-danger">{{ noticeForm.errors.content }}</span>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn-cancel btn btn-default"  v-on:click="checkForm()">등록</button>
            </div>
          </div>
          <!-- Add -->
      </div>
  
      <div class="datatable">
          <table class="notice_table">
            <thead>
            <tr>
              <th class="col1">No.</th>
              <th class="col2">작성일</th>
              <th class="col3">제목</th>
              <th class="col4">작성자</th>
              <th class="col5">조회수</th>
            </tr>
            </thead>
            <!-- Sample -->
            <tbody>
            <tr v-for="(item, index) in notices">
              <td class="col1 font_open_sans">
                {{ totalRecord - (page - 1) * perPage - index}}
              </td>
              <td class="col2 font_open_sans">
                  {{ item.created_at | dateFormatSupport }}
              </td>
              <td class="col3 text-left">
                    <a href="#" v-on:click="showModalViewNotice(item)">{{ item.title }}</a>
              </td>
              <td class="col4 text-left">
                {{ item.author }}
              </td>
              <td class="col5">
                {{ item.view_count }}
              </td>
            </tr>
            </tbody>
          </table>
      </div>
  
      <div class="clear"></div>
      <div class="content-footer">
        <div class="left">
          <!-- <template v-if="lastPage > 1">
            <pagination ref="pagination" class="text-right" :per-page="perPage" :records="totalRecord"
                @Pagination:page="onPageChange"></pagination>
          </template> -->
        </div>
        <div class="right">
          <button class="writing_btn"  v-on:click="showModalAddNotice()">글쓰기</button>
        </div>
      </div>
      <div class="clear"></div>
  
      <!-- Modal REMOVE NOTICE-->
      <div class="modal fade remove-notice" id="removeNotice" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
        <div class="modal-dialog" role="document"  style="width:262px; height:191px">
          <div class="modal-content">
            <div class="modal-body">
              <p >게시물을 삭제하시겠습니까?</p>
              <div class="wrap-foot">
                <button type="button" class="btn btn-default bt-cancel" data-dismiss="modal">취소</button>
                <button type="button" class="btn btn-primary bt-approved" data-dismiss="modal" @click="removeNotice()">확인</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--END Modal REMOVE NOTICE-->
  
    </div>
  </template>
  
  <script>
    import rf from '../../../lib/RequestFactory'
    import Modal from '../../../components/Modal.vue'
  
    export default {
      components: {
        Modal
      },
      data() {
        return {
          titlePage: 'NOTICE',
          notices: [],
          search: '',
          totalRecord: 0,
          lastPage: 0,
          page: 1,
          perPage: 13,
          noticeForm: {
            title: '',
            content: '',
            errors: {
              title: '',
              content: ''
            }
          },
          noticeData: {
            id: 0,
            title: '',
            content: '',
            author: '',
            view_count: 0,
            created_at: '',
            errors:{}
          },
          showBoxNotice: {
            edit: false,
            view: false,
            add: false
          }
        }
      },
      methods: {
        loadNotice: function () {
          rf.getRequest('NoticeRequest').getNotices (
            {
              key: this.search,
              page: this.page,
              limit: this.perPage
            }).then(res => {
            this.notices = res.data.data;
            this.page = parseInt(res.data.current_page);
            this.totalRecord = parseInt(res.data.total);
            this.lastPage = parseInt(res.data.last_page);
            this.perPage = parseInt(res.data.per_page);
          })
        },
        checkForm:function(e) {
          if(this.noticeForm.title && this.noticeForm.content){
            this.createNotice();
          }else{
            this.noticeForm.errors = {};
            if(!this.noticeForm.title) this.noticeForm.errors.title = 'Title Requied';
            if(!this.noticeForm.content) this.noticeForm.errors.content = 'content Requied';
            e.preventDefault();
          }
        },
        createNotice: function(){
          let params = {
            'title': this.noticeForm.title,
            'content': this.noticeForm.content
          }
          rf.getRequest('NoticeRequest').createNotice(params).then(res => {
            CommonModal.hide('add_notice_form');
            this.clearnoticeForm();
            this.loadNotice();
          }).catch((error) => {
            this.noticeForm.errors = error.response.data.errors;
          });
        },
        clearnoticeForm() {
          this.noticeForm.title = '';
          this.noticeForm.content = '';
        },
        onPageChange(newPage) {
          this.page = newPage;
          this.loadNotice()
        },
        showBox: function(name){
          this.showBoxNotice.add = false;
          this.showBoxNotice.view = false;
          this.showBoxNotice.edit = false;
          switch (name) {
            case 'add':
              this.showBoxNotice.add = true;
              break;
            case 'view':
              this.showBoxNotice.view = true;
              break;
              break;
            case 'edit':
              this.showBoxNotice.edit = true;
              break;
            default:
              this.showBoxNotice.add = false;
              this.showBoxNotice.view = false;
              this.showBoxNotice.edit = false;
          }
        },
        showModalAddNotice: function(){
          this.showBox('add');
        },
        showModalRemoveNotice: function(){
          this.showBoxNotice.view = false;
          $('#removeNotice').modal();
        },
        showModalViewNotice: function(notice){
          this.showBox('view');
          this.noticeData = notice;
        },
        showModalEditNotice: function(){
          this.showBox('edit');
        },
        updateNotice() {
          this.showBox('view');
          let params = {
            'content': this.noticeData.content
          }
          rf.getRequest('NoticeRequest').updateNotice(this.noticeData.id, params).then(res => {
            CommonModal.hide('edit_notice_form');
            this.loadNotice();
          }).catch((error) => {
            this.noticeForm.errors = error.response.data.errors;
          });
        },
        removeNotice() {
          rf.getRequest('NoticeRequest').removeNotice(this.noticeData.id).then(res => {
            this.clearnoticeForm();
            this.loadNotice();
          }).catch((error) => {
          });
        },
        handleTitlePage() {
            switch(this.$forceUpdate.pathname) {
                case '/notifications/detail':
                    return window.i18n.t('')
            }
        }
      },
  
      mounted: function () {
        this.$emit('EVENT_PAGE_CHANGE', this);
        this.loadNotice()
      }
    }
  
  </script>
  
  <style lang="scss">
    .notice {
      .datatable {
        width: 857px;
        min-width: 857px;
        table {
          width: 100%;
          box-shadow: 6px 3px 7px -1px #888;
          thead {
            background: white;
            border-bottom: 1px solid rgb(191, 191, 191);
            th {
              font-size: 12.7px;
              height: 38px;
              color: black;
            }
          }
          tbody {
            tr {
              font-size: 12.7px;
              height: 38px;
              color: black;
              background: white;
              border-bottom: 1px solid rgb(237, 237, 237);
            }
          }
          .col1{
            width: 57px;
            text-align: center;
          }
          .col2{
            width: 113px;
            text-align: center;
          }
          .col3{
            width: 472px;
            text-align: center;
          }
          .col4{
            width: 113px;
            text-align: center;
          }
          .col5{
            width: 108px;
            text-align: center;
          }
        }
      }
  
      .box-view{
        margin-bottom: 25px;
        .view-item{
          width: 860px;
          height: 436px;
        }
        ::-webkit-scrollbar {
            height: 8px;
            width: 8px;
            background: #FFF;
            -webkit-border-radius: 1ex;
        }
        ::-webkit-scrollbar-thumb {
          position: fixed;
            background: #d7d7d7;
            opacity: 0.2;
            -webkit-border-radius: 1ex;
            -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
        }
        ::-webkit-scrollbar-corner {
            background: #1a1a1a;
        }
        .modal-content{
          border-radius: 0px !important;
          box-shadow: 3px 3px 3px #888;
          border: none;
          .modal-header {
              padding-bottom: 25 !important;
              padding-left: 38px !important;
              padding-top: 25px !important;
              display: block;
              text-align: center;
              border-bottom: 1px solid #b1b1b1;
              p{
                margin-bottom: -7px;
              }
              .created-at{
                float:left;
                width: 80px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                font-size: 10pt;
              }
              .title{
                float:left;
                width: 490px;
                line-height: 36px;
                font-weight: bold;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                padding: 0 5px;
                font-size: 11pt !important;
              }
              .author{
                float:left;
                width: 160px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                font-size: 10pt;
              }
              .view{
                float: left;
                width: auto;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                font-size: 10pt;
              }
          }
          .modal-body {
            font-size: 11pt !important;
            padding-right: 30px !important;
            padding-left: 38px !important;
            height: 300px;
            overflow-y: scroll;
  
            .lb-notice{
              float:left;
              font-weight: normal;
              margin-right: 19px;
              line-height: 46px;
              font-size: 11pt;
            }
            .inp-notice{
              width: 732px;
              height: 46px;
              background: #ffffff;
              border-radius: 0px;
              color: #000;
              border: 1px solid #d9d9d9;
              cursor: default;
              padding: 0 5px;
              line-height: 1;
              margin-bottom: 0px;
              font-size: 11pt;
              font-weight: bold;
            }
            textarea{
              border-radius: 0px;
              width: 779px;
              border: none !important;
              box-shadow: none !important;
              &:hover{
                // border: 1px solid rgb(169, 169, 169) !important;
              }
            }
            span.is-danger{
              font-size: 13px;
            }
          }
          .modal-footer{
            padding: 15px 25px;
            border-top: 1px solid #b1b1b1;
            .btn{
              color: #fff;
              background: #006cb8;
              width: 69px;
              height: 28px;
              font-size: 9pt;
              padding: 4px 12px !important;
            }
          }
        }
        .add-notice{
          .modal-body {
            height: 375px !important;
          }
        }
      }
      .remove-notice{
        .modal-content{
          border-radius: 0px !important;
          p{
            margin-top: 50px;
            margin-bottom: 50px;
            font-size: 11pt;
            text-align: center;
            font-weight: bold;
          }
          .wrap-foot{
            text-align: center;
            .btn{
              width: 80px;
              height: 38px;
            }
            .bt-cancel{
              color: #fff;
              background: #7b7b7b;
              margin-right: 6px;
            }
            .bt-approved{
              color: #fff;
              background: #006cb8;
            }
          }
        }
      }
      .edit-notice{
        .modal-body{
          padding-left: 38px;
          padding-right: 38px;
        }
      }
    }
  </style>
  
  <style lang="scss" scoped>
    .notice {
      min-width: 857px;
      width: 857px;
  
      .btn-blue{
        background-color: #0070c0 !important;
        color: #fff;
      }
      .supportArea{
        margin-bottom: 30px;
        .title_item {
          .title_text {
            font-size: 14px;
            margin-right: 5px;
            font-family: "Nanum Square OTF ExtraBold", sans-serif;
            font-weight: bold;
          }
        }
        .search_box{
          float: right;
          padding-bottom: 5px;
        }
      }
  
  
      .writing_btn{
          text-align: center;
          border: 1px solid #e1e0e0;
          background-color: rgb(0, 112, 192);
          border-radius: 4px;
          height: 28px;
          width: 50px;
          margin-bottom: 0px;
          font-size: 11.4px;
          color: white;
        }
  
  
      .content-footer {
        width: 857px;
        height: 66px;
        margin: auto;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
  
        .left {
          width: 60%;
          float: left;
          text-align: left;
          font-size: 16px;
          padding-top: 20px;
  
          label {
            color: #000;
            font-weight: 200;
          }
          .input_search {
            width: 193px;
            /* height: 28px; */
            float: left;
            text-align: center;
            font-size: 11.45px;
            border: 1px solid rgb(191, 191, 191);
            border-radius: 3px;
            position: relative;
            img {
              position: absolute;
              top: 6px;
              right: 8px;
            }
            input {
              border-radius: 5px;
              border: 0px;
              text-align: center;
              height: 27px;
              font-size: 11.45px;
              width: 100%;
              padding-right: 25px;
            }
          }
          .btn_search {
            font-size: 11.45px;
            float: left;
            margin-left: 7px;
            text-align: right;
            background-color: rgb(0, 112, 192);
            padding: 0px 20px;
            border-radius: 3px;
            height: 28px;
            width: 68px;
          }
        }
        .right {
          width: 40%;
          margin-top: -10px;
          text-align: right;
        }
      }
    }
  </style>
  