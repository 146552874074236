<template>
  <div class="order-history clearfix mg_bot50" id="traced-bot">
    <data-table :getData="getData" ref="datatable" :limit="10" :column="3" class="scroll height-custom">
      <th class="text-left" >{{ $t('user.email') }}</th>
      <th class="text-left" style="padding-top: 7px">
        <div class="form-button">
          <button @click="onClickedEnableAllAccount()" class="btn btn_enable_all">{{ $t('withdraw_setting.enable_all') }}</button>
          <button @click="onClickedDisableAllAccount()" class="btn btn_disable_all">{{ $t('withdraw_setting.disable_all') }}</button>
        </div>
      </th>
      <th class="text-right">
        <button @click="addAccount()" class="btn btn-add">
          <i class="icon-plus"></i>
          {{ 'ADD ACCOUNT' }}
        </button>
      </th>

      <template slot="body" slot-scope="props">
        <tr>
          <td class="text-left font14">
            <div class="item_email_setting font14">
              <span class="txt_email_setting font14">{{ props.item.email }}</span>
              <span class="tooltip_email_setting font14">{{ props.item.email }}</span>
            </div>
          </td>
          <td class="text-left font14">
            <button class="btn"
              :class="props.item.is_active ? 'btn_enable' : 'btn_disable'"
              @click.stop="onClickedUpdateBot(props.item)">
              {{ props.item.is_active ? $t('common.action.enable') : $t('common.action.disable') }}
            </button>
          </td>
          <td class="text-center">
            <button class="btn btn-close-table" @click="confirmDeleteColumn(props.item.id)"><i class="icon-close2"></i></button>
          </td>
        </tr>
      </template>
    </data-table>


    <modal name="addUserEnableFee" width="450">
      <div slot="body" class="body-popup add-user-enable-fee">
        <div class="form-group-detail">
            <div class="group-detail-form-group">
              <label class="group-detail-form-label">{{ $t('airdrop_setting.email') }}</label>
              <input class="group-detail-form-value" maxlength="190"
                      id="input-airdrop-title" type="text" name= "email" v-model="newUser.email"
                      data-vv-validate-on=""
                      :data-vv-as="$t('email')" v-validate="'required|email'"
                      v-bind:class="{'error-modal': errors.has('email')}"
              />
              <div class="clearifx"></div>
              <div class="error-box">
                <span v-show="showErrorModal && errors.has('email')" class="f-left is-error">{{ errors.first('email') + '.' }}</span>
              </div>
            </div>
        </div>
      </div>
    </modal>

    <modal name="deleteUserEnableFee" width="450">
      <div slot="body" class="body-popup add-user-enable-fee">
        <div class="form-group-detail">
          <div class="group-detail-form-group">
            <label class="group-detail-form-label-1">{{ 'Do you want to remove this account?' }}</label>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
  import rf from '../../lib/RequestFactory';
  import Modal from '../../components/Modal';
  import DatePicker from 'vuejs-datepicker';
  import SelectBox from '../../components/SelectBox';
  import moment from "moment";

  export default {
    components: {
      Modal,
      DatePicker,
      SelectBox,
    },
    data() {
      return {
        titlePage: 'Traced Account',
        startDate: moment().subtract(1, 'week').toDate(),
        endDate: new Date(),
        riskInformation: {},
        filterContract: "",
        newUser:{},
        showErrorModal: false,
        contractOptions:[],
        key : 1,
        averageEntryPrice: 0,
        positionInformation:{},
      }
    },
    methods: {
      onClickedEnableAllAccount () {
        this.showModal({
          type: 'confirm',
          title: "Do you want to enable all account?",
          onConfirm: () => {
            rf.getRequest('BitmexTracedBotRequest').updateAll({is_active: 1}).then(res => {
              this.refresh();
              this.$toastr('success', 'Update account successfully!');
            }).catch(e => {
              this.$toastr('error', window.i18n.t('Update account fail!'));
            });
          }
        });
      },
      onClickedDisableAllAccount () {
        this.showModal({
          type: 'confirm',
          title: "Do you want to disable all account?",
          onConfirm: () => {
            
            rf.getRequest('BitmexTracedBotRequest').updateAll({is_active: 0}).then(res => {
              this.refresh();
              this.$toastr('success', 'Update account successfully!');
            }).catch(e => {
              this.$toastr('error', window.i18n.t('Update account fail!'));
            });
          }
        });
      },
      showModal (config) {
        const type = config.type;
        const title = config.title;
        const content = config.content;
        const customContent = config.customContent;

        let btnCancelLabel = config.btnCancelLabel || this.$t('common.action.no');
        let btnConfirmLabel = config.btnConfirmLabel || this.$t('common.action.yes');
        if (config.noAction) {
          btnCancelLabel = null;
          btnConfirmLabel = null;
        }
        const onConfirm = config.onConfirm;
        const onCancel = config.onCancel;

        window.ConfirmationModal.show({
          type: type,
          title: title,
          content: content,
          customContent: customContent,
          btnCancelLabel: btnCancelLabel,
          btnConfirmLabel: btnConfirmLabel,
          onConfirm: onConfirm,
          onCancel: onCancel
        });
      },
      onClickedUpdateBot (setting) {
        const title = setting && setting.is_active ?
                        'Do you want to disable this account?' :
                        'Do you want to enable this account?';
        let params = {
            ...setting,
            'is_active': !setting.is_active,
        };

        this.showModal({
          type: 'confirm',
          title: title,
          onConfirm: () => {
            rf.getRequest('BitmexTracedBotRequest').updateBot(params)
            .then(res => {
              setting.is_active = !setting.is_active;
              this.$toastr('success', 'Update account successfully!');
            }).catch(e => {
              this.$toastr('error', window.i18n.t('Update account fail!'));
            });
          }
        });
      },
      getData (params) {
        return rf
          .getRequest("BitmexTracedBotRequest")
          .getAllBot(params);
      },
      refresh () {
        this.$refs.datatable.refresh();
      },
      addAccount () {
        this.showErrorModal = false;
        this.errors.clear();
        this.newUser = {};

        CommonModal.show("addUserEnableFee", {
          position: "add-group customModal",
          mask: true,
          buttons: [
            {
              label: this.$t('common.action.cancel'),
              focused: true,
              style: {
                width: "140px",
                background: "rgb(0, 112, 192)"
              },
              callback: () => {
                this.showErrorModal = false;
                CommonModal.hide("addUserEnableFee");
              }
            },
            {
              label: this.$t('common.action.ok'),
              focused: true,
              style: {
                width: "140px",
                background: "rgb(0, 112, 192)"
              },
              callback: () => {
                this.onConfirmAddUser();
              }
            }
          ]
        });
      },
      async onConfirmAddUser () {
        let isValid = await this.validateModal();
        this.showErrorModal = false;
        if (!isValid) {
          this.showErrorModal = true;
          return ;
        }
        try {
          await rf.getRequest('BitmexTracedBotRequest').createBot(this.newUser);
          CommonModal.hide("addUserEnableFee");
          this.$toastr('success', window.i18n.t('common.alert.create.success'));
          this.newUser = {};
          this.refresh();
        } catch (e) {
          console.log ('Error: ' + e)
          if (e.response.data.message) {
            this.$toastr('error', e.response.data.message);
          }
        }
      },
      async validateModal () {
        this.errors.clear();
        await this.$validator.validate('email');
        if (this.errors.count() > 0) {
            return false;
        }
        return true;
      },
      async deleteColumn (id) {
        const params = {
          id
        };
        try {
          await rf.getRequest('BitmexTracedBotRequest').deleteBot(params)
          this.$toastr('success', 'Remove account successfully!');
          CommonModal.hide("deleteUserEnableFee");
          this.refresh();
        } catch (e) {
          console.log ('Error: ' + e)
          this.$toastr('error', window.i18n.t('enable_trading.remove_fail'));
        }
      },
      confirmDeleteColumn (item) {
        CommonModal.show("deleteUserEnableFee", {
          position: "add-group customModal",
          mask: true,
          buttons: [
            {
              label: this.$t('common.action.no'),
              focused: true,
              style: {
                width: "140px",
                background: "rgb(0, 112, 192)"
              },
              callback: () => {
                this.showErrorModal = false;
                CommonModal.hide("deleteUserEnableFee");
              }
            },
            {
              label: this.$t('common.action.yes'),
              focused: true,
              style: {
                width: "140px",
                background: "rgb(0, 112, 192)"
              },
              callback: () => {
                this.deleteColumn(item);
              }
            }
          ]
        });
      },
    },
    mounted () {
      this.$emit('EVENT_PAGE_CHANGE', this);
    },
    async created () {
    }
  }
</script>

<style lang="scss" scoped>
@import "@/assets/sass/variables";

  .btn_enable {
    background-color: transparent;
    text-transform: uppercase;
    width: 70px;
    height: 25px;
    line-height: 20px;
    padding: 0px 0px;
    margin-left: 65px;
    text-align: center;
    font-size: $font-smaller;
    font-weight: 600;
    color: $color-caribbean-green;
    border: 1px solid $color-caribbean-green;
    border-radius: 3px;
    transition: 0.5s;
    &:hover {
      background-color: $color-aquamarine;
      border-color: $color-aquamarine;
      color: $color_white;
      transition: 0.5s;
    }
  }
  .btn_disable {
    background-color: transparent;
    text-transform: uppercase;
    width: 70px;
    height: 25px;
    line-height: 20px;
    padding: 0px 0px;
    margin-left: 65px;
    text-align: center;
    font-size: $font-smaller;
    font-weight: 600;
    color: $color_alizarin_crimson;
    border: 1px solid $color_alizarin_crimson;
    border-radius: 3px;
    transition: 0.5s;
    &:hover {
      background-color: $color_alizarin_crimson;
      border-color: $color_alizarin_crimson;
      color: $color_white;
      transition: 0.5s;
    }
  }

  .item_email_setting {
    display: inline-block;
    float: left;
    position: relative;
    .txt_email_setting {
      display: block;
      max-width: 250px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .tooltip_email_setting {
      position: absolute;
      top: 0px;
      left: 0px;
      line-height: 20px;
      padding: 5px 20px;
      left: 100%;
      background-color: $color_white;
      white-space: nowrap;
      width: auto;
      z-index: 10;
      font-size: $font_root;
      font-weight: 500;
      color: $color_mine_shaft;
      transition: 0.5s;
      display: none;
      box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.4);
      &:after {
        right: 100%;
        top: 50%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: rgba(136, 183, 213, 0);
        border-right-color: $color_white;
        border-width: 5px;
        margin-top: -5px;
      }
    }
    &:hover {
      .tooltip_email_setting {
        display: block;
        transition: 0.5s;
      }
    }
  }

.btn-close-table {
  width: 17px;
  height: 17px;
  text-align: center;
  line-height: 20px;
  padding: 0px;
  text-align: center;
  border: 0px;
  background-color: transparent;
  font-size: 16px;
  color: $color-denim;
  margin-left: 7px;
  i {
    &:before {
      color: $color-denim;
    }
  }
}

.add-user-enable-fee {
    .is-error {
        color: $color_red_text !important;
    }
    .error-box {
        display: block;
        float: left;
        overflow: hidden;
        width: 100%;
        span {
            font-family: $font-family-roboto-medium !important;
        }
    }
}
.mt-1{
    margin-top:10px;
}

.btn-add {
    height: 25px;
    width: 120px;
    border-radius: 3px;
    background-color: $color-caribbean-green;
    border: 1px solid $color-caribbean-green;
    font-size: $font_mini_mini;
    letter-spacing: 0.77px;
    color: $color-white;
    text-align: center;
    padding: 1px 12px;
    padding-top: 2px;
    display: block;
    float: right;
    i {
        font-size: 8px;
        position: relative;
        top: 0px;
        margin-right: 4px;
    }
    &:hover {
        border-color: $color-aquamarine;
        background-color: $color-aquamarine;
    }
}

.row{
    margin-right: 0;
    margin-left: 0;
}

.min_85 {
    min-width: 85px;
}

.min_110 {
    min-width: 110px;
}

.w_125 {
    width: 125px;
}
.text-left {
    text-align: left;
}
.form-group-detail {
    .group-detail-form-group {
        .group-detail-form-label{
            width: 100%;
            color: $color-grey-dusty;
            float: left;
            font-weight: normal;
            font-family: $font-family-roboto;
            margin-bottom: 0px;
            margin-top: 10px;
        }
        .group-detail-form-label-1{
            width: 100%;
            margin-bottom: 30px;
            margin-top: 10px;
            line-height: 25px;
            text-align: center;
            font-size: 20px;
            color: rgb(42, 47, 50);
            font-family: "Roboto-Regular", sans-serif;
            font-size: 16px;
            font-weight: 600;
        }

        .group-detail-form-value {
            width: 100%;
            padding: 8px 16px;
            border: 1px solid #dee3eb;
            height: 35px;
            line-height: 35px;
            border-radius: 3px;
            font-family: $font-family-roboto;
            // font-weight: 600;
            &:focus {
                border-color: $color-jungle-green;
            }
        }
        #input-airdrop-title {
            &.error-modal {
                border: solid 1px $color-red-main;
                border-radius: 3px;
            }
        }
        .textarea_input {
            height: 120px;
        }
    }
    .error-box {
        display: block;
        float: left;
        overflow: hidden;
        width: 100%;
        span {
            font-family: $font-family-roboto-medium !important;
        }
    }
    .is-error {
        color: #ff5252 !important;
    }
}

.order-history {
  .datatable {
    button.btnForm.bt-action {
      padding-left: 5px !important;
      padding-right: 5px !important;
      font-weight: 400 !important;
    }
  }
  .buy {
    color: $text-color-jade;
  }
  .sell {
    color: $text-color-red;
  }
}

.form-button {
  display: inline-block;
  .btn_disable_all,
  .btn_enable_all {
    min-width: 100px;
    text-align: center;
    line-height: 21px;
    padding: 0px 5px;
    border: 1px solid;
    background-color: transparent;
    font-family: $font_family_root;
    border-radius: 3px;
    height: 25px;
    font-size: $font-smaller;
    font-weight: 600;
    text-transform: uppercase;
    transition: 0.5s;
    &:disabled{
      background-color : $color-bright-gray;
      border-color : $color-bright-gray;
      color: $color_mine_shaft;
    }
  }
  .btn_enable_all {
    border-color: $color-caribbean-green;
    color: $color-white;
    background-color: $color-caribbean-green;
    &:hover {
      background-color: $color-aquamarine;
      border-color: $color-aquamarine;
      color: $color-white;
      transition: 0.5s;
    }
    &:disabled{
      background-color : $color-bright-gray;
      border-color : $color-bright-gray;
      color: $color_mine_shaft;
    }
  }
  .btn_disable_all {
    border-color: $color-denim;
    color: $color-white;
    background-color: $color-denim;
    &:hover {
      background-color: $color-denim;
      border-color: $color-denim;
      color: $color-white;
      transition: 0.5s;
    }
    &:disabled{
      background-color : $color-bright-gray;
      border-color : $color-bright-gray;
      color: $color_mine_shaft;
    }
  }
}
</style>
<style lang="scss">
@import "@/assets/sass/variables";
  #traced-bot {
    .toolbar-element2 {
      .button_drop_search {
        width: 100px;
      }
    }
    .sc_search_select {
      .button_drop_search {
        width: 50px;
      }
    }
    .modal-dialog {
      height: auto;
      .modal-content {
        height: auto;
        .modal-body {
          padding: 0px 30px 15px 30px;
          .group-detail-form-label {
            margin-bottom: 5px;
          }
        }
        .modal-footer {
          padding-bottom: 30px;
        }
      }
    }
  }
</style>