<template>
    <div class="qna_pages boxCore clearfix mg_bot50" id="qna_pages">
        <div class="filter-container">
            <!-- <div class="filter-item date">
                <div class="text-pair">{{ $t('notice.start_at') }}:</div>
                <date-picker v-model="startDate" class="date-picker-input no-right-radius"
                    :format="customFormatter"></date-picker>
            </div>
            <div class="filter-item date">
                <div class="text-pair">{{ $t('notice.end_at') }}:</div>
                <date-picker v-model="endDate" class="date-picker-input no-left-radius"
                    :format="customFormatter"></date-picker>
            </div> -->
            <div class="filter-item type">
                <div class="text-pair date-text">{{ $t('cms.faq.category') }}</div>
                <div class="toolbar-element">
                    <select-box v-model="filters.type" :placeholder="$t('order.order_history.all')"
                        :options="typesOption" />
                </div>
            </div>
            <!-- <div class="filter-item">
                <input title="" type="text" :placeholder="$t('cms.faq.search_by')" @keyup.enter="search"
                    class="form-control" name="searchKey" v-model="filters.searchKey" />
            </div> -->
            <div class="filter-item">
                <button class="btn btn-reset" @click="search()">
                    {{ $t('notice.search') }}
                </button>
                <button class="btn btn-reset" @click="refresh()">
                    {{ $t('notice.reset') }}
                </button>
            </div>
            <div class="filter-item right">
                <!-- <router-link class="btn btn-reset" to="/faq/create">
                    <span class="icon-plus"></span>
                    {{ $t('cms.notification.new') }}</router-link> -->
            </div>
        </div>
        <div class="list-notice">
            <div class="datatable" style="width: 100%">
                <data-table :getData="getData" ref="list_notice" :column="9" class="scroll">
                    <th class="text-left width-title" data-sort-field="creation_time">
                        {{ $t('cms.faq.created_time') }}
                    </th>
                    <th class="text-left width-title" data-sort-field="username">
                        {{ $t('cms.faq.user_id') }}
                    </th>
                    <th class="text-left width-title" data-sort-field="id">
                        {{ $t('cms.faq.id') }}
                    </th>
                    <th class="text-left width-title" data-sort-field="category">
                        {{ $t('cms.faq.category') }}
                    </th>
                    <!-- <th class="text-left width-title" data-sort-field="title">
                        {{ $t('cms.faq.language') }}
                    </th> -->
                    <th class="text-left width-title" data-sort-field="language">
                        {{ $t('cms.faq.question') }}
                    </th>
                    <th class="text-left width-support" data-sort-field="status">
                        {{ $t('cms.faq.status') }}
                    </th>
                    <th class="text-center">
                        {{ $t('cms.faq.action') }}
                    </th>
                    <template slot="body" slot-scope="props">
                        <tr>
                            <td class="text-left width-title">
                                <div class="over-flow-text">
                                    <div class="text-top">{{ props.item.created_at | dateFormat2('YYYY-MM-DD') }}
                                    </div>
                                    <div class="text-bottom">{{ props.item.created_at | timeFormat2('HH:mm:ss') }}
                                    </div>
                                </div>
                            </td>
                            <td class="text-left width-title">
                                <div class="over-flow-text">
                                    {{ props.item.user_id || "" }}
                                </div>
                            </td>
                            <td class="text-left width-title">
                                <div class="over-flow-text">
                                    {{ props.item.id }}
                                </div>
                            </td>
                            <td class="text-left width-title">
                                <div class="over-flow-text">
                                    {{ props.item.inquiry_type.name }}
                                </div>
                            </td>
                            <!-- <td class="text-left width-title">
                                <div class="over-flow-text">
                                    {{ props.item.language || "Vietnamese" }}
                                </div>
                            </td> -->
                            <td class="text-left width-title">
                                <div class="over-flow-text">
                                    {{ props.item.question }}
                                </div>
                            </td>
                            <td class="text-left width-title">
                                <div :class="`status-${props.item.status || 'active'}`">
                                    <!-- {{ props.item.status || 'active' | account_status }} -->
                                    {{ $t(`cms.faq.status_${props.item.status}`) }}
                                </div>
                            </td>
                            <td class="text-center">
                                <div class="action-wrap">
                                    <img v-if="props.item.status === 1" class="btn-icon"
                                        src="@/assets/images/icon/send-icon.svg" width="20"
                                        @click="sendAnswer(props.item)" />
                                    <img v-if="props.item.status === 2" @click="sendAnswer(props.item)" class="btn-icon"
                                        src="@/assets/images/future/List.svg" width="20px" height="20px" />
                                    <!-- <img class="btn-icon" src="@/assets/images/icon/delete-icon.svg" width="20"
                                        @click="onDelete(props.item)" /> -->
                                </div>

                            </td>
                        </tr>
                    </template>
                </data-table>
            </div>
        </div>
        <modal class="deleteFaq_modal" name="deleteFaq" title="CONFIRM">
            <div slot="body" class="body-popup">
                {{ $t('cms.faq.popup_delete_confirm') }}
            </div>
        </modal>
        <modal class="kyc_modal" :enableClose="enableClose" name="loadingUpload">
            <div slot="body">
                <vue-loading type="bars" v-if="isLoading" color="#2DAC91" :size="{ width: '60px', height: '80px' }">
                </vue-loading>
            </div>
        </modal>
    </div>
</template>

<script>
import rf from '../../../lib/RequestFactory';
import SelectBox from '../../../components/SelectBox.vue';
import DatePicker from 'vuejs-datepicker';
import moment from 'moment';
import Modal from '../../../components/Modal.vue';
import { lowerCase } from 'lodash';

export default {
    components: {
        DatePicker,
        SelectBox,
        Modal
    },
    data() {
        return {
            titlePage: window.i18n.t('menu.cms.qna'),
            // startDate: moment().subtract(1, 'week').toDate(),
            // endDate: moment().toDate(),
            IMAGE: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSUdIe37t-Pej9f7MEf41fw4WB04jSaIfnD0Q&s',
            typesOption: [
                { value: 'maintenance', name: window.i18n.t('cms.announcement.type_maintenance') },
                { value: 'coin_listings', name: window.i18n.t('cms.announcement.type_coin_listings') },
                { value: 'latest_news', name: window.i18n.t('cms.announcement.type_latest_news') },
            ],
            filters: {
                searchKey: '',
                type: null
            },
            isLoading: false,
            enableClose: false
        }
    },

    methods: {
        customFormatter(date) {
            return moment(date).format('YYYY - MM - DD')
        },
        async getQnaType() {
            const res = await rf.getRequest('NotificationRequest').getListTypeInquiry();
            if (res && res?.data?.length) {
                const listType = res.data.map((item) => ({
                    id: `${item.id}`,
                    name: window.i18n.t(`cms.qna.category_${item.id}`)
                }))
                this.typesOption = [{ id: "all", name: window.i18n.t("order.order_history.all") }, ...listType];
            }
        },
        search() {
            let filterParams = {};
            // if (this.startDate > this.endDate) {
            //     this.$toastr('error', this.$t('common.date.warning'))
            //     return
            // }
            // if (this.filters.searchKey) {
            //     filterParams.search_key = this.filters.searchKey
            // }
            if (this.filters.type) {
                filterParams.type_id = this.filters.type.id
            }
            this.$refs.list_notice.filter(filterParams)
        },

        refresh() {
            this.resetInput()
            this.search()
        },

        resetInput() {
            this.filters = Object.assign({ category: null });
        },

        getData(params) {
            const newParams = {
                ...params,
                size: params?.limit || 10
            }
            delete newParams.limit
            return rf
                .getRequest('NotificationRequest')
                .getInquiries(Object.assign(newParams))
        },

        sendAnswer(row) {
            this.$router.push({
                name: 'QnaEdit',
                query: {
                    id: row ? row.id : null,
                },
            })
        },
        showModalConfirm(modalName, callback, params) {
            CommonModal.show(modalName, {
                position: 'center',
                mask: true,
                buttons: [
                    {
                        label: window.i18n.t('common.action.no'),
                        class: "btn-no",
                        style: {
                            background: 'rgb(191, 191, 191);'
                        },
                        callback: () => {
                            CommonModal.hide(modalName);
                        }
                    },
                    {
                        label: window.i18n.t('common.action.yes'),
                        focused: true,
                        class: "btn-yes",
                        style: {
                            background: 'rgb(0, 112, 192)'
                        },
                        callback: () => {
                            CommonModal.hide(modalName);
                            this.loadingUploadBegin();
                            callback(params);
                            this.loadingUploadFinish();
                        }
                    }
                ]
            });
        },
        loadingUploadBegin() {
            this.enableClose = false;
            this.isLoading = true;
            CommonModal.show('loadingUpload');
        },
        loadingUploadFinish() {
            CommonModal.hide('loadingUpload');
        },
    },

    mounted() {
        this.$emit('EVENT_PAGE_CHANGE', this);
        this.getQnaType();
        this.search();
    },
}
</script>

<style lang="scss">
@import '@/assets/sass/variables';

#qna_pages {
    .sc_search_select {
        width: 250px;

        .group_search_select {
            .button_drop_search {
                height: 32px;
                padding: 6px 10px 6px 10px !important;
                border-radius: 10px;
                font-size: $font-small;
                font-family: $mulish-regular;
                background-color: $color-white;

                i.icon-arrow1 {
                    margin-left: 5px;
                    color: $color_dove_gray;
                }

                &:hover {
                    border-color: $color-jungle-green;

                    i.icon-arrow1 {
                        color: $color-jungle-green;
                    }
                }
            }

            .box_list_search_select {
                .list_search_select {
                    border-radius: 10px;

                    li {
                        font-family: $font-family-roboto;
                        font-size: $font-small;

                        &:hover,
                        &:active,
                        &:focus {
                            color: $color-jungle-green;
                        }
                    }
                }
            }
        }
    }

    .modal {
        .body-popup {
            font-family: $mulish-medium;

            p,
            strong {
                font-family: $mulish-medium;
            }
        }

        .btn-no {
            background-color: $color-bright-gray;
            border-color: $color-bright-gray;
            color: $color-black;

            &:hover {
                opacity: 0.7;
            }
        }
    }
}
</style>

<style lang="scss" scoped>
@import '@/assets/sass/variables';

.qna_pages {
    .filter-container {
        display: flex;
        gap: 16px;
        align-items: end;
        margin-bottom: 24px;

        .filter-item {
            &:last-child {
                margin-right: 0;
                margin-left: auto;
            }

            .text-pair {
                color: $dark-1;
                font-weight: 500;
                font-size: 14px;
                margin-bottom: 7px;
                margin-left: 5px;
                font-family: $mulish-regular;
            }

            .date-picker-input {
                display: inline-block;
                float: left;

                ::v-deep {
                    input {
                        padding: 0 5px 0px 36px;
                        width: 135px !important;
                        height: 32px;
                        border: 1px solid #C7CBD3 !important;
                        border-radius: 10px !important;
                        background-image: url('@/assets/images/icon/date-time.svg') !important;
                        background-color: $color-white !important;
                        background-size: 12px !important;
                        background-position: center left 12px !important;
                        background-repeat: no-repeat !important;
                        color: $dark-1;

                        &:hover,
                        &:active,
                        &:focus {
                            border-color: $color-jungle-green;
                            z-index: 9;
                        }
                    }
                }

            }

            .btn-reset {
                background: $color-caribbean-green;
                border-radius: 10px;
                color: $color_white;
                font-weight: 700;
                height: 32px;
                margin-left: 16px;

                .icon-plus {
                    color: $color-white;
                    margin-right: 8px;
                }
            }

            .form-control {
                background: $color-white;
                height: 32px;
                font-size: 13px;
                padding-left: 11px;
                padding-right: 0;
                border-radius: 10px !important;
            }
        }
    }

    .datatable {
        table {
            thead {
                th {
                    &:last-child {
                        &::after {
                            display: none;
                        }
                    }

                    &:nth-child(8) {
                        &::after {
                            display: none;
                        }
                    }
                }
            }

            tbody {
                tr {
                    td:first-child {
                        min-width: 82px;
                    }

                    td:nth-child(2) {
                        min-width: 50px;
                    }

                    td:nth-child(3) {
                        min-width: 80px;
                    }

                    td:nth-child(4) {
                        min-width: 100px;
                    }

                    td:nth-child(5) {
                        min-width: 200px;
                        width: 300px;
                        padding-top: 15px;

                        display: -webkit-box;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: pre;
                    }

                    td:nth-child(6) {
                        min-width: 90px;
                    }

                    td:nth-child(7) {
                        min-width: 100px;
                    }

                    td:nth-child(8) {
                        min-width: 120px;
                        height: 70px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }

                    td:nth-child(9) {
                        min-width: 100px;
                    }

                    td {
                        // text-align: center;
                        padding: 5px 10px;

                        .status-active {
                            width: 100px;
                            height: 36px;
                            border-radius: 3px;
                            color: rgb(45, 45, 45);
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            cursor: default;
                            background: $color-filled-status;
                        }

                        .status-inactive {
                            width: 100px;
                            height: 36px;
                            border-radius: 3px;
                            color: rgb(45, 45, 45);
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            cursor: default;
                            background: $color-cancel-status;
                        }

                        .action-wrap {
                            display: flex;
                            justify-content: center;
                            gap: 16px;
                        }

                        .btn-icon {
                            width: 24px;
                            height: 24px;
                            cursor: pointer;

                            &:hover {
                                opacity: 0.8;
                            }
                        }
                    }
                }
            }
        }

        .image-wrap {
            width: 80px;
            height: 50px;
            overflow: hidden;

            img {
                width: 100%;
                height: auto;
                object-fit: cover;
            }
        }
    }
}
</style>