import BaseRequest from './BaseRequest'

export default class MultiChainRequest extends BaseRequest {

  getNetworks(params) {
    let url = '/admin/api/networks';
    return this.get(url, params);
  }

  getNetworkDetail(id) {
    let url = `/admin/api/networks/${id}`;
    return this.get(url);
  }

  createNetwork(params) {
    let url = '/admin/api/networks/create';
    return this.post(url, params);
  }

  updateNetwork(params) {
    let url = `/admin/api/networks/update/${params.id}`;
    return this.post(url, params);
  }

  deleteNetwork(id) {
    let url = `/admin/api/networks/${id}`;
    return this.del(url);
  }

  getCoins(params) {
    let url = '/admin/api/coins';
    return this.get(url, params);
  }

  getCoinDetail(id) {
    let url = `/admin/api/coins/${id}`;
    return this.get(url);
  }

  updateCoin(params) {
    let url = `/admin/api/coins/update/${params.id}`;
    return this.post(url, params);
  }

  createCoin(params) {
    let url = '/admin/api/coins/create';
    return this.post(url, params);
  }

  getNetworkCoin(id) {
    let url = `/admin/api/network-coins/${id}`;
    return this.get(url);
  }

  updateNetworkCoin(id, params) {
    let url = `/admin/api/network-coins/update/${id}`;
    return this.post(url, params);
  }

  createNetworkCoin(params) {
    let url = '/api/network-coins/create';
    return this.post(url, params);
  }

  deleteNetworkCoin(id) {
    let url = `/admin/api/network-coins/delete/${id}`;
    return this.del(url);
  }

  validateContractAddress(params) {
    let url = '/admin/api/validate-contract-address';
    return this.get(url, params);
  }
}