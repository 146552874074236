import BigNumber from "bignumber.js";
import MasterdataUtils from "./MasterdataUtils";
import Numeral from '../lib/numeral';
import rf from '../lib/RequestFactory';

export default {
  qs: function (key) {
    key = key.replace(/[*+?^$.[]{}()|\\\/]/g, "\\$&"); // escape RegEx meta chars
    const match = location.href.match(new RegExp("[?&]"+key+"=([^&]+)(&|$)"));
    return (match && decodeURIComponent(match[1].replace(/\+/g, " ")) || null);
  },

  setI18nLocale(locale) {
    window.i18n.locale = locale;
    window.app.$broadcast('UPDATED_LOCALE', locale);
  },

  formatCurrencyAmount(amount, currency, zeroValue) {
    // let numberOfDecimalDigits = 8;//currency === 'usd' ? 6 : 10;
    // let format = numberOfDecimalDigits == 0 ?
    //   '0,0' :
    //   '0,0.[' + Array(numberOfDecimalDigits + 1).join('0') + ']';
    // if (window._.isNil(zeroValue)) {
    //   zeroValue = '';
    // }

    // return (amount && parseFloat(amount) != 0) ? Numeral(amount).format(format) : zeroValue;
    let numberOfDecimalDigits = 8;

    if (window._.isNil(zeroValue)) {
      zeroValue = '';
    }

    if (amount && parseFloat(amount) != 0) {
      let parts = amount.toString().split('.');
      let integerPart = parts[0];
      let decimalPart = parts[1] || '';
      
      if (decimalPart.length > numberOfDecimalDigits) {
        decimalPart = decimalPart.substring(0, numberOfDecimalDigits);
      } else {
        decimalPart = decimalPart.replace(/0+$/, '');
      }
      
      let formattedAmount = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      
      if (decimalPart.length > 0) {
        formattedAmount += '.' + decimalPart;
      }

      return formattedAmount;
    } else {
      return zeroValue;
    }
  },
  formatCurrencyAmountDecimal(amount, numberOfDecimalDigits, zeroValue) {

    if (window._.isNil(zeroValue)) {
      zeroValue = '';
    }

    if (amount && parseFloat(amount) != 0) {
      let parts = amount.toString().split('.');
      let integerPart = parts[0];
      let decimalPart = parts[1] || '';
      
      if (decimalPart.length > numberOfDecimalDigits) {
        decimalPart = decimalPart.substring(0, numberOfDecimalDigits);
      } else {
        decimalPart = decimalPart.replace(/0+$/, '');
      }
      
      let formattedAmount = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      
      if (decimalPart.length > 0) {
        formattedAmount += '.' + decimalPart;
      }

      return formattedAmount;
    } else {
      return zeroValue;
    }
  },

  formatliquidationPrice(amount, currency, zeroValue) {
    let numberOfDecimalDigits = 8;
    let format = numberOfDecimalDigits == 0 ?
      '0,0' :
      '0,0.[' + Array(numberOfDecimalDigits + 1).join('0') + ']';
    if (window._.isNil(zeroValue)) {
      zeroValue = '';
    }

    return (amount && parseFloat(amount) > 0) ? Numeral(amount).format(format) : '--';
  },
  //
  // formatCurrencyAmount (amount, currency, zeroValue) {
  //   const numberOfDecimalDigits = currency === 'usd' ? 2 : 8;
  //   if (window._.isNil(zeroValue)) {
  //     zeroValue = '';
  //   }
  //
  //   const rawValue = new BigNumber(`${amount}`).toFixed(numberOfDecimalDigits);
  //   return (amount && parseFloat(amount) !== Infinity && !isNaN(amount)) ? new BigNumber(rawValue).toFormat() : zeroValue;
  // },

  formatMarginNumber(amount, numberOfDecimalDigits = 4, zeroValue = null) {
    let format = numberOfDecimalDigits == 0 ?
      '0,0' :
      '0,0.[' + Array(numberOfDecimalDigits + 1).join('0') + ']';

    return (amount && parseFloat(amount) != 0) ? Numeral(amount).format(format) : zeroValue ? zeroValue : '--';
  },

  formatMarginValue(amount) {
    return Numeral(Math.abs(amount.toString().replace(',', ''))).value();
  },

  roundCurrencyAmount(amount, currency, zeroValue) {
    let numberOfDecimalDigits = 10;//currency === 'usd' ? 6 : 10;
    let format = numberOfDecimalDigits == 0 ?
      '0' :
      '0[.' + Array(numberOfDecimalDigits + 1).join('0') + ']';
    if (window._.isNil(zeroValue)) {
      zeroValue = '';
    }

    return (amount && parseFloat(amount) != 0) ? Numeral(amount).format(format) : zeroValue;
  },

  getCurrencyName(value) {
    return value ? value.toUpperCase() : value;
  },

  getTimzoneOffset() {
    let d = new Date();
    return d.getTimezoneOffset();
  },

  async isWalletAddress(currency, address){
    switch(currency){
      case 'usd':
        //TO DO
        return /^.+$/.test(address);
      case "eos":
        const resultEOS = await this.validateAddress({ currency: 'eos', address: address });
        if(resultEOS) {
          return true;
        }
        return false;
      case "xrp":
        const resultXRP = await this.validateAddress({ currency: 'xrp', address: address });
        if(resultXRP) {
          return true;
        }
        return false;
      case "etc":
        return /^[0-9A-HJ-NP-Za-km-z]{26,35}$/.test(address);
      case "eth":
      case "tomo":
      case "knc":
      case "tusd":
        if (/^(0x)?[0-9a-fA-F]{40}$/.test(address)) {
          return true;
        }
        return false;
      case "usdt":
      case "btc":
        const resultBTC = await this.validateAddress({ currency: 'btc', address: address });
        if(resultBTC) {
          return true;
        }
        return false;
      case "bch":
        const resultBCH = await this.validateAddress({ currency: 'bch', address: address });
        if(resultBCH) {
          return true;
        }
        return false;
      case "wbc":
        return /^[123mn][1-9A-HJ-NP-Za-km-z]{26,35}$/.test(address);
      case "neo":
        return /^[A][1-9A-HJ-NP-Za-km-z]{26,35}$/.test(address);
      case "dash":
        return /^[0-9A-HJ-NP-Za-km-z]{26,35}$/.test(address);
      case "ltc":
        return /^[1-9A-HJ-NP-Za-km-z]{26,35}$/.test(address);

    }
    //TODO: Add validator for tusd, ais, xlm, ada, eos
    return true;
  },

  getTransactionUrl(currency, transactionId) {
    let erc20Tokens = ['wbc', 'knc', 'tomo', 'tusd'];
    if (erc20Tokens.indexOf(currency) >= 0) {
      currency = 'erc20';
    }
    switch (currency) {
      case 'btc':
        return 'https://blockchain.info/tx/' + transactionId;
      case 'eth':
      case 'erc20':
        return 'https://etherscan.io/tx/' + transactionId;
      case 'etc':
        return 'https://gastracker.io/tx/' + transactionId;
      case 'bch':
        return 'https://explorer.bitcoin.com/bch/tx/' + transactionId;
      case 'xrp':
        return 'https://xrpcharts.ripple.com/#/transactions/' + transactionId;
      case 'ltc':
        return 'https://live.blockcypher.com/ltc/tx/' + transactionId;
      case 'neo':
        return 'https://neotracker.io/tx/' + transactionId;
      case 'dash':
        return 'https://explorer.dash.org/tx/' + transactionId;
      default:
        return '';
    }
  },

  trimEndZero(value) {
    const dot = '.';
    const strValue = `${value}`;
    if (strValue.indexOf(dot) === -1) {
      return strValue;
    }
    const trimEndZero = window._.trimEnd(strValue, '0');
    return window._.trimEnd(trimEndZero, dot);
  },

  uuid () {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  },

  convertNumberToPrecision (number) {
    if (!(parseInt(number) > 0)) {
      return '0';
    }
    return '0.' + '0'.repeat(number - 1) + 1;
  },

  convertPrecisionToNumber (precision) {
    precision = parseFloat(precision);
    return Math.max(Math.abs(new BigNumber(`${precision}`).e), 0);
  },

  async getPrecisionMax(currency) {
    if (!currency) {
      return '';
    }
    const priceGroups = await MasterdataUtils.getOneTable('price_groups');
    const item = _.chain(priceGroups)
      .filter(priceGroup => priceGroup.currency === currency)
      .maxBy((priceGroup) => this.convertPrecisionToNumber(priceGroup.value))
      .value();

    return this.convertPrecisionToNumber(item.value);
  },

  trimEnd(value, ...chars) {
    return window._.trimEnd(`${value}`, chars);
  },

  convertObjToQueryUrl (obj) {
    return Object.keys(obj).map((k) => {
      return k + '=' + (obj[k] === null ? '' : encodeURIComponent(obj[k]));
    }).join('&');
  },

  async validateAddress(params) {
    const result = await rf.getRequest('ValidateRequest').validateAddress(params);
    return result.data;
  }
};
