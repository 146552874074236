<template>
  <div id="content_detail_kyc" class="clearfix mg_bot50">
    <div class="form-kyc-detail-container">
      <button class="btn-back" @click="handleBack()">Back</button>
      <div id="kyc-detail">
        <div class="info_kyc clearfix">
          <div class="row">
            <div class="col-xs-4">
              <div class="kyc-detail-form-group">
                <div class="kyc-detail-form-label group_left">{{ $t('kyc.email') }}:</div>
                <div class="kyc-value">
                  <span class="kyc-detail-form-value">{{ detailKyc.email }}</span>
                  <!-- <span class="tooltip-kyc-detail-form-value">{{ detailKyc.email }}</span> -->
                </div>
              </div>
              <div class="kyc-detail-form-group">
                <div class="kyc-detail-form-label group_left">{{ $t('kyc.name') }}:</div>
                <div class="kyc-value">
                  <div class="kyc-detail-form-value">{{ detailKyc.full_name | uppercaseFirst }}</div>
                  <!-- <span class="tooltip-kyc-detail-form-value">{{ detailKyc.full_name | uppercaseFirst}}</span> -->
                </div>
              </div>
              <!-- <div class="kyc-detail-form-group">
                <div class="kyc-detail-form-label group_left">{{ $t('kyc.gender') }}:</div>
                <div class ="kyc-value">
                  <div class="kyc-detail-form-value">{{ detailKyc.gender | uppercaseFirst}}</div>
                </div>
              </div> -->
              <!-- </div>
            <div class="col-xs-8"> -->
              <div class="kyc-detail-form-group">
                <div class="kyc-detail-form-label group_right">{{ $t('kyc.country') }}:</div>
                <div class="kyc-value">
                  <div class="kyc-detail-form-value">
                    {{ detailKyc.country | uppercaseFirst }}
                  </div>
                </div>
              </div>
              <!-- <div class="kyc-detail-form-group">
                <div class="kyc-detail-form-label group_right">{{ $t('kyc.id_number') }}:</div>
                <div class ="kyc-value">
                  <div class="kyc-detail-form-value">
                    {{ detailKyc.id_number}}
                  </div>
                </div>
              </div> -->
            </div>
          </div>
        </div>

        <div class="clearfix"></div>

        <div class="kyc_image clearfix">
          <div class="row">
            <div class="col-xs-4">
              <div class="kyc-detail-form-image">
                <div class="label-image">{{ $t('kyc.id_front') }}:</div>
                <img class="value-image" v-if="detailKyc.id_front" :src="`${detailKyc.id_front}`" />
              </div>
            </div>
            <div class="col-xs-4">
              <div class="kyc-detail-form-image">
                <div class="label-image">{{ $t('kyc.id_back') }}:</div>
                <img class="value-image" v-if="detailKyc.id_back" :src="`${detailKyc.id_back}`" />
              </div>
            </div>
            <div class="col-xs-4">
              <div class="kyc-detail-form-image">
                <div class="label-image">{{ $t('kyc.id_selfie') }}:</div>
                <img class="value-image" v-if="detailKyc.id_selfie" :src="`${detailKyc.id_selfie}`" />
              </div>
            </div>
          </div>
        </div>

        <div class="submit clearfix">
          <button class="btn btn_verify mr-10" @click="verifyKyc(detailKyc.id)"
            :disabled="detailKyc.status === 'verified' || detailKyc.status === 'rejected'">{{ $t('kyc.btn_verify')
            }}</button>
          <button class="btn btn_reject mr-10" @click="rejectKyc(detailKyc.id)"
            :disabled="detailKyc.status === 'rejected' || detailKyc.status === 'verified'">{{ $t('kyc.btn_reject')
            }}</button>
        </div>
      </div>
    </div>

    <div class="clearfix"></div>

    <modal class="kyc_modal" name="verifyKyc" title="">
      <div slot="body" class="body-popup">
        {{ $t('kyc.notice_verify_confirm') }}
      </div>
    </modal>
    <modal class="kyc_modal" name="rejectKyc" title="">
      <div slot="body" class="body-popup">
        {{ $t('kyc.notice_reject_confirm') }}
      </div>
    </modal>

    <modal class="kyc_modal" :enableClose="enableClose" name="loadingUpload">
      <div slot="body">
        <vue-loading type="bars" v-if="isLoading" color="#2DAC91" :size="{ width: '60px', height: '80px' }">
        </vue-loading>
      </div>
    </modal>
  </div>
</template>

<script>
import rf from '../../lib/RequestFactory';
import Modal from '../../components/Modal';
import { mapGetters, mapActions } from 'vuex';
import CONST from '../../common/Const'

export default {
  props: ['items'],
  components: {
    Modal,
  },

  data() {
    return {
      titlePage: window.i18n.t('kyc.detail_kyc'),
      detailKyc: {},
      isLoading: false,
      enableClose: true,
      apiUrl: process.env.VUE_APP_STORAGE_URL,
    };
  },
  methods: {
    loadingUploadBegin() {
      this.enableClose = false;
      this.isLoading = true;
      CommonModal.show('loadingUpload');
    },

    loadingUploadFinish() {
      CommonModal.hide('loadingUpload');
    },

    verifyKyc(id) {
      this.showModalConfirm('verifyKyc', this.submitVerifyKyc, id);
    },

    rejectKyc(id) {
      this.showModalConfirm('rejectKyc', this.submitRejectKyc, id);
    },

    showModalConfirm(modalName, callback, params) {
      CommonModal.show(modalName, {
        position: 'center',
        mask: true,
        buttons: [
          {
            label: window.i18n.t('common.action.no'),
            style: {
              background: 'rgb(191, 191, 191);'
            },
            callback: () => {
              CommonModal.hide(modalName);
            }
          },
          {
            label: window.i18n.t('common.action.yes'),
            focused: true,
            style: {
              background: 'rgb(0, 112, 192)'
            },
            callback: () => {
              CommonModal.hide(modalName);
              this.loadingUploadBegin();
              callback(params);
            }
          }
        ]
      });
    },

    submitVerifyKyc(data) {
      rf.getRequest('AdminRequest').verifyKycUser(data).then(res => {
        let dataItem = this.$route.params.items ? this.$route.params.items : "";
        this.$router.push({
          name: 'kyc-sumsub',
          params: {
            items: dataItem,
            isVerifyKycSuccess: true
          },
          props: true
        });
      }).catch(error => {
        console.error(error);
      });
      this.loadingUploadFinish();
    },

    submitRejectKyc(data) {
      rf.getRequest('AdminRequest').rejectKycUser(data).then(res => {
        let dataItem = this.$route.params.items ? this.$route.params.items : "";
        this.$router.push({
          name: 'kyc-sumsub',
          params: {
            items: dataItem,
            isrejectKycSuccess: true
          },
          props: true
        });
      }).catch(error => {
        console.error(error);
      });
      this.loadingUploadFinish();
    },

    handleBack() {
      const params = this.$route.params;
      this.$router.push({
        name: 'kyc-sumsub',
        params: params,
        props: true
      });
    }
  },

  mounted() {
    this.$emit('EVENT_PAGE_CHANGE', this);
    const id = this.$route.query.id;
    rf.getRequest('AdminRequest').getKycDetail(id).then(res => {
      this.detailKyc = res.data || {};
    });
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/variables";

.mr-10 {
  margin-right: 10px;
}

#content_detail_kyc {

  .form-kyc-detail-container {
    padding-top: 5px;

    .btn-back {
      margin-bottom: 10px;
      background-color: $color-caribbean-green;
      border: 1px solid $color-caribbean-green;
      font-size: 12px;
      line-height: 1;
      padding: 7px 10px;
      border-radius: 3px;
      font-weight: bold;
      color: #ffffff;
      text-transform: uppercase;
      transition: 0.5s;
      font-family: "Roboto-Regular", sans-serif;

      &:hover {
        background-color: $color-aquamarine;
        border-color: $color-aquamarine;
      }
    }

    .info_kyc {
      .kyc-detail-form-group {
        display: flex;
        margin-bottom: 10px;
        line-height: 20px;
        font-size: $font_root;
        font-weight: 500;
        color: $color_dove_gray;

        .kyc-detail-form-label {
          display: inline-block;
          float: left;

          &.group_left {
            min-width: 92px;
            margin-right: 28px;
          }

          &.group_right {
            min-width: 135px;
            margin-right: 5px;
          }
        }

        .kyc-value {
          display: flex;

          .tooltip-kyc-detail-form-value {
            visibility: hidden;
            position: absolute;
            z-index: 10;
            background-color: $color_white;
            left: 61%;
            transition: 0.5s;
            font-size: $font_root;
            font-weight: 500;
            color: $color_mine_shaft;
            padding: 5px 20px;
            // white-space: nowrap;
            word-wrap: break-word;
            max-width: 300px;
            width: auto;
            box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.4);

            &:after {
              right: 100%;
              top: 12px;
              border: solid transparent;
              content: " ";
              height: 0;
              width: 0;
              position: absolute;
              pointer-events: none;
              border-color: rgba(136, 183, 213, 0);
              border-right-color: $color_white;
              border-width: 5px;
              margin-top: -5px;
            }
          }

          .kyc-detail-form-value {
            color: $color_mine_shaft;
            font-weight: 600;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          &:hover .tooltip-kyc-detail-form-value {
            visibility: visible;
          }


        }

      }
    }

    .kyc_image {
      margin-top: 35px;

      .kyc-detail-form-image {
        margin-bottom: 33px;

        .label-image {
          color: $color_dove_gray;
          font-size: $font_root;
          font-weight: 500;
          line-height: 20px;
          margin-bottom: 15px;
        }

        .value-image {
          width: 100%;
          background-color: $color_alto_gray;
          min-height: 200px;
          display: block;
        }
      }
    }

    .submit {

      .btn_verify {
        background-color: $color-caribbean-green;
        border: 1px solid $color-caribbean-green;
        line-height: 20px;
        height: 35px;
        padding: 7px 35px;
        min-width: 100px;
        border-radius: 3px;
        font-size: $font-smaller;
        font-weight: bold;
        color: $color_white;
        text-align: center;
        text-transform: uppercase;
        transition: 0.5s;
        font-family: $font-family-roboto;

        &:active,
        &:focus,
        &:hover {
          background-color: $color-aquamarine;
          border-color: $color-aquamarine;
          color: $color_white;
          transition: 0.5s;
        }

        ;

        &:disabled {
          background-color: $color-bright-gray !important;
          color: $color-grey-dark !important;
          border: $color-bright-gray !important;
        }
      }

      .btn_reject {
        background-color: $color-caribbean-green;
        border: 1px solid $color-caribbean-green;
        line-height: 20px;
        height: 35px;
        padding: 7px 35px;
        min-width: 100px;
        border-radius: 3px;
        font-size: $font-smaller;
        font-weight: 600;
        color: $color-white;
        text-align: center;
        text-transform: uppercase;
        transition: 0.5s;
        font-family: $font-family-roboto;

        &:active,
        &:focus,
        &:hover {
          background-color: $color-aquamarine;
          border-color: $color-aquamarine;
          color: $color_white;
          transition: 0.5s;
        }

        ;

        &:disabled {
          background-color: $color-bright-gray !important;
          color: $color-grey-dark !important;
          border: $color-bright-gray !important;
        }
      }
    }

  }
}

.body-popup {
  font-size: 16px !important;
  font-family: $font-family-roboto-medium !important;
}
</style>

<style lang="scss" scoped>
@import "@/assets/sass/variables";

.kyc_modal .modal-content {
  height: 225px !important;
}

#content_detail_kyc {
  .modal-dialog {
    width: 400px;

    .modal-body {
      color: $color_mine_shaft;
      font-size: $font_big_20;
      font-weight: 500;
      text-align: center;
      line-height: 24px;
      // padding: 55px 20px 20px 20px; 
    }
  }
}
</style>
