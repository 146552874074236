<template>
  <div id="airdrop_history" class="boxCore airdrop_history">
    <div class="transaction">
      <div class="filter-container">
        <div class="filter-item date mr_left0">
          <div class="text-date">{{ $t('orders.order_history.date') }}:</div>
          <date-picker v-model="startDate" class="date-picker-input no-right-radius"
            :format="customFormatter"></date-picker>
          <date-picker v-model="endDate" class="date-picker-input no-left-radius"
            :format="customFormatter"></date-picker>
        </div>
        <div class="filter-item">
          <input title="" type="text" :placeholder="$t('orders.order_history.search_by_email')" @keyup.enter="search"
            class="form-control" name="searchKey" v-model="filters.searchKey" />
        </div>
        <div class="filter-item btn-action">
          <button class="btn btn-primary" @click="search()">{{ $t('orders.order_history.search') }}</button>
          <button class="btn btn-reset" @click="refresh()">{{ $t('orders.order_history.reset') }}</button>
        </div>
      </div>

      <div class="datatable">
        <data-table :getData="getData" :limit="10" :column='7' @DataTable:finish="onDatatableFinish" ref="datatable"
          class="scroll">
          <th class="text-left" data-sort-field="email">{{ $t('base_deposit_withdraw_title_email') }}</th>
          <th class="text-left" data-sort-field="currency">{{ $t('base_deposit_withdraw_title_currency') }}</th>
          <th class="text-left" data-sort-field="amount">{{ $t('orders.order_history.amount') }}</th>
          <th class="text-left" data-sort-field="unlocked_balance">{{ $t('table.unlocked_balance') }}</th>
          <th class="text-left min_w_100px" data-sort-field="total_balance">{{ $t('table.total_airdrop_balance') }}</th>
          <th class="text-left min_w_120px" data-sort-field="created_at">{{ $t('table.unlock_date') }}</th>
          <th class="text-left min_w_95px" data-sort-field="status">{{ $t('base_deposit_withdraw_title_status') }}</th>
          <template slot="body" slot-scope="props">
            <tr>
              <td class="text-left">
                <div class="item_email_user">
                  <span class="txt_email_user">{{ props.item.email }}</span>
                </div>
              </td>
              <td class="text-left">
                <div class="text-break">{{ props.item.currency | uppercase }}</div>
              </td>
              <td class="text-left">
                <div class="text-break">{{ props.item.amount | formatCurrencyAmount(null, '0') }}</div>
              </td>
              <td class="text-left">
                {{ props.item.unlocked_balance | formatCurrencyAmount(null, '0') }}
              </td>
              <td class="text-left">
                {{ props.item.total_balance | formatCurrencyAmount(null, '0') }}
              </td>
              <td class="text-left">
                {{ convertTime(props.item.updated_at) }}
              </td>
              <td class="text-left">
                {{ capitalizeFirstLetter(props.item.status) }}
              </td>
            </tr>
          </template>
        </data-table>
      </div>
    </div>
  </div>
</template>

<script>
import rf from '../../lib/RequestFactory';
import BigNumber from 'bignumber.js';
import DatePicker from "vuejs-datepicker";
import moment from "moment";
import COMMON_CONST from "../../common/Const";
import SelectBox from "../../components/SelectBox";
import CoinInput from '../../common/CoinInput.vue'

export default {
  components: {
    DatePicker,
    SelectBox,
    CoinInput
  },
  props: {
    type: {
      type: String,
      default: 'deposit'
    }
  },
  data() {
    return {
      searchKey: '',
      titlePage: window.i18n.t("cold_wallet_setting.airdrop_history"),
      startDate: moment().subtract(1, 'week').toDate(),
      endDate: moment().toDate(),
      coin: "",
      filters: {
        currency: "",
        searchKey: ""
      },
      coins: [],
      coinMasters: [],
      isShowCoinOption: false,
      trades: [],
      markets: Object.values(COMMON_CONST.AIRDROP_LIST_COINS),
    }
  },
  methods: {
    onCoinChange(currency) {
      this.filters.currency = currency;
    },
    search() {
      let filterParams = {
        start_date: (moment(this.startDate)
          .startOf("day")
          .format("x") / 1000).toFixed(0),
        end_date: (moment(this.endDate)
          .endOf("day")
          .format("x") / 1000).toFixed(0)
      };
      if (moment(this.startDate).format('YYYY MM DD') > moment(this.endDate).format('YYYY MM DD')) {
        this.$toastr("error", this.$t("common.date.warning"));
        return;
      }
      if (this.filters.searchKey) {
        filterParams.search_key = this.filters.searchKey;
      }
      if (this.filters.currency) {
        filterParams.currency = this.filters.currency;
      }
      this.$refs.datatable.filter(filterParams);

    },
    convertTime(time) {
      let stillUtc = moment.utc(time).toDate();
      let local = moment(stillUtc).local().format('YYYY-MM-DD HH:mm:ss');
      return local;
    },
    refresh() {
      this.resetInput();
      this.$refs.datatable.refresh();
    },
    resetInput() {
      this.filters = Object.assign(this.filters, {
        currency: "",
        searchKey: ""
      });
      this.startDate = moment().subtract(1, 'week').toDate();
      this.endDate = moment().toDate();
      $(".datatable").find("th").attr("data-sort-order", "");
    },

    getData(params) {
      return rf.getRequest('AdminRequest').getAirdropHistory(params).then(res => {
        return res.data;
      });
    },

    getPendingTransactions(params) {
      const meta = {
        type: this.type,
        status: this.transactionStatus
      }
      if (!window._.isEmpty(this.searchKey)) {
        meta.search_key = this.searchKey;
      }
      params = Object.assign(params, meta);
      return rf.getRequest('AdminRequest').getTransactions(params).catch(err => {
        console.error(err);
      });
    },

    onDatatableFinish() {
      const datatable = this.$refs.datatable;
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },

    // Get amount which hasn't calculate fee yet.
    getFullAmount(reductionAmount, fee) {
      return new BigNumber(`${Math.abs(reductionAmount)}`).add(`${fee}`).toString();

    },
    formatDateTime(date) {
      return moment(date, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss');
    },
    customFormatter(date) {
      return moment(date).format('YYYY - MM - DD');
    },

  },

  mounted() {
    this.$emit('EVENT_PAGE_CHANGE', this);
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/variables";

.empty-data {
  text-align: center;
  padding-top: 15px;
  background-color: $color_white;
}

.opacity-row {
  opacity: 0.4;
}

.text-wrap {
  word-wrap: break-word;
}

.toolbar-element2 {
  display: inline-block;
  float: left;
}

.bw-element {
  float: left;
  font-size: $font_medium;
  margin-left: 5px;
  height: 30px;
  line-height: 30px;
  margin-right: 5px;
  color: $color-grey-dusty;
}

.checkbox {
  margin-top: 0px;
  display: inline-block;
  font-size: $font_root;
  margin-bottom: 0px;
  font-weight: normal;
  height: 30px;
  line-height: 30px;

  input[type="checkbox"] {
    display: none;
  }

  span {
    position: relative;
    content: "";
    display: inline-block;
    border: 1px solid $color_alto;
    width: 16px;
    height: 16px;
    top: 3px;
    left: 8px;
    border-radius: 2px;
    margin-right: 8px;
    position: relative;
    background-color: $color_white;
  }

  input:checked {
    &+span {
      &:after {
        opacity: 0.9;
        content: '';
        position: absolute;
        width: 14px;
        height: 7px;
        background: transparent;
        top: 2px;
        left: 0px;
        border: 3px solid $color_blue_logo;
        border-top: none;
        border-right: none;
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
      }
    }
  }
}

.airdrop_history {
  .filter-container {
    margin: 10px 0px 30px 0px;
    height: 50px;

    .filter-item {
      float: left;
      margin: 0 5px;

      &.mr_left0 {
        margin-left: 0px;
      }

      input {
        margin-top: 23px;
        display: inline-block;
        width: 145px;
        font-family: $font-family-roboto;

        &:hover,
        &:active,
        &:focus {
          border-color: $color-jungle-green;
        }
      }

      select {
        width: 65px;
        display: inline-block;
        font-family: $font-family-roboto;
        font-size: $font_root;

        &:hover,
        &:active,
        &:focus {
          border-color: $color-jungle-green;
        }
      }

      button {
        width: auto;
        margin: 23px 2px 0px 2px;
        height: 30px;
        font-size: $font-smaller;
        font-family: $font-family-roboto;
      }

      .btn-reset {
        background-color: $color-caribbean-green;
        border: 1px solid $color-caribbean-green;
        width: 80px;
        text-transform: uppercase;
        color: $color-white;
        font-weight: 600;

        &:hover {
          border-color: $color-aquamarine;
          background-color: $color-aquamarine;
        }
      }

      .btn-primary {
        padding: 0;
        min-width: 80px;

        &:hover {
          border-color: $color-aquamarine;
          background-color: $color-aquamarine;
        }
      }

      .coin-input {
        position: relative;
        display: inline-block;
        margin: 0 5px;
        margin-right: 0px;
        font-size: $font-small;
        font-family: $font-family-roboto;

        .coin-option {
          background-color: $color-white;
          max-height: 210px;
          display: block;
          overflow-y: auto;
          margin-top: 5px;
          position: absolute;
          width: 79px;
          left: 5px;
          z-index: 10;
          padding: 12px 0px;
          box-shadow: 0px 3px 12px rgba(49, 49, 49, 0.51);

          &:after {
            bottom: 100%;
            left: 50%;
            border: solid transparent;
            content: " ";
            display: block;
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
            border-color: rgba(136, 183, 213, 0);
            border-bottom-color: $color_white;
            border-width: 5px;
            margin-left: -5px;
          }

          .option {
            display: block;
            width: 100%;
            line-height: 20px;
            cursor: pointer;
            padding: 3px 14px;
            font-size: 14px;
            overflow: hidden;

            &:hover {
              color: $color-jungle-green;
            }
          }
        }
      }
    }

    .pair {
      input {
        width: 65px;
      }
    }

    .text-date {
      color: $color-grey-dusty;
      margin-bottom: 5px;
      font-size: 13px;
      font-family: Roboto-Regular;
    }

    .text-pair {
      font-family: Roboto-Regular;
      padding-left: 16px;
      color: $color-grey-dusty;
      margin-bottom: 5px;
      font-size: 13px;
    }

    .side {
      margin: 0 10px;
    }
  }

  .form-control {
    background: $color-white-smoke;
    height: 30px;
    font-size: $font_root;
    padding-left: 11px;
    padding-right: 0;
  }

  .datatable {
    button.btnForm.bt-action {
      padding-left: 5px !important;
      padding-right: 5px !important;
      font-weight: 400 !important;
    }
  }

  .buy {
    color: $text-color-jade;
  }

  .sell {
    color: $text-color-red;
  }

  .btn-detail {
    background-color: $color-caribbean-green;
    padding: 2px 10px;
    font-size: $font-smaller;
    color: $color-white;
    border: 1px solid $color-caribbean-green;
    border-radius: 3px;
    text-transform: uppercase;
    font-family: $font-family-roboto;

    &:hover {
      background-color: $color-aquamarine;
      border-color: $color-aquamarine;
    }

    ;
  }
}
</style>
<style lang="scss">
@import "@/assets/sass/variables";

#airdrop_history {
  .filter-item.pair {
    .sc_search_select {
      .group_search_select {
        .button_drop_search {
          height: 30px;
          margin-left: 15px;
          width: 73px;
          margin-right: 15px;
          padding: 5px 10px 5px 10px !important;
          border-radius: 3px;
          font-size: $font-small;
          font-family: $font-family-roboto;

          i.icon-arrow1 {
            margin-left: 5px;
            color: $color_dove_gray;
          }

          &:hover,
          &:active,
          &:focus {
            border-color: $color-jungle-green;

            i.icon-arrow1 {
              color: $color-jungle-green;
            }
          }
        }

        .box_list_search_select {
          left: 14px;
          width: 73px;
          ;

          .list_search_select {
            li {
              font-family: $font-family-roboto;
              font-size: $font-small;

              &:hover,
              &:active,
              &:focus {
                color: $color-jungle-green;
              }
            }
          }
        }
      }
    }
  }

  .modal-dialog {
    min-width: 80%;
    min-height: 400px;
    position: relative;
    top: -10%;
  }

  .modal-content {
    min-height: 400px;

    .modal-footer {
      text-align: center;
      margin-top: 30px;
    }

    .modal-header {
      padding: 30px 16px 16px 20px;

      .modal-title {
        font-family: Roboto-Medium;
        font-size: 18px;
        font-weight: 500;
        color: $color-grey-dark;
        text-transform: none;
      }
    }

    .modal-body {
      padding: 0;
    }
  }
}
</style>