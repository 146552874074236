<template>
  <div id="exchange_balance_detail" class="clearfix mg_bot50">
    <div class="select_transaction_container">
      <div class="filter-item date">
        <div class="text-pair">{{ $t('salespoint.buy_history.date') }}:</div>
        <date-picker :disabled-picker="historyTab !== 'hours'" :class="{'blurDatePicker': historyTab === 'hour'}" @selected="changeDatePicker" v-model="searchDatePK" class="date-picker-input no-right-radius" :format="customFormatter"></date-picker>
      </div>
      <div class="tab-header">
        <ul>
          <li :class="{'active': historyTab === 'hours'}">
            <a href="javascript:void(0)" @click="selectTab('hours')">
              <span>{{ $t('exchange_balance_detail.hour') }}</span>
            </a>
          </li>
          <li :class="{'active': historyTab === 'daily'}">
            <a
              href="javascript:void(0)"
              @click="selectTab('daily')"
            ><span>{{ $t('exchange_balance_detail.daily') }}</span></a>
          </li>
          <li :class="{'active': historyTab === 'weekly'}">
            <a
              href="javascript:void(0)"
              @click="selectTab('weekly')"
            ><span>{{ $t('exchange_balance_detail.weekly') }}</span></a>
          </li>
          <li :class="{'active': historyTab === 'monthly'}">
            <a
              href="javascript:void(0)"
              @click="selectTab('monthly')"
            ><span>{{ $t('exchange_balance_detail.monthly') }}</span></a>
          </li>
          <li :class="{'active': historyTab === 'yearly'}">
            <a
              href="javascript:void(0)"
              @click="selectTab('yearly')"
            ><span>{{ $t('exchange_balance_detail.yearly') }}</span></a>
          </li>
        </ul>
      </div>
      <span class="search_box">
        <input type="text" 
          :placeholder="$t('exchange_balance_detail.search_place_holder', {0:historyTab ==='yearly' ? 'YYYY' : (historyTab ==='monthly' ? 'YYYY-MM' : 'YYYY-MM-DD')})"
          @keyup.enter= "searchProfitDate" class="form-control search_input" 
          name="searchKey" @paste="checkDateCopyValid" 
          @keyup="checkDateValid" 
          :maxlength="maxlengthDate " autocomplete="off"
          v-date-only
          v-model="searchKey"/>
      </span>
    </div>

    <div class="datatable" id="tableProfit">
      <data-table-4 :getData="getDataTable" :limit="10" ref="list_profit" :column=" Number(columnHeaderName ? Object.keys(columnHeaderName).length : 0)" class="scroll" :msgEmptyData="$t('common.datatable.no_data')">
        <th 
        v-for="(columnNames, key) in columnHeaderName" 
        :data-sort-field="[columnNames === 'From' ?'From' : (columnNames === 'To' ? 'To' : (columnNames ==='Month' ? 'Month' : (columnNames === 'Year' ? 'Year'  : (columnNames === 'Day' ? 'Day'  : '' ))))]"  
        :item = "columnNames && columnNames" :class="'cl' + (1 + key) " class="text-left"
        >{{ columnNames | uppercase }}</th>
        <template slot="body" slot-scope="props">
          <tr>
            <td v-for="(dataProfit, key ) in props.item" :class="[Number(dataProfit) === 0 , 'min-85']">{{String(dataProfit).indexOf(".") >= 0 ? changeBigNumber(dataPit) : dataProfit  }}</td>
          </tr>
        </template>
        <template v-if=" window._.isEmpty(columnHeaderName) && historyTab ==='hours' ">
            <td class="empty-data">
              <span class="icon-nodata"></span>
              <span>{{ msgEmptyData || $t('common.datatable.no_data') }}</span>
            </td>
        </template>
      </data-table-4>
    </div>
  </div>
</template>

<script>
  import BigNumber from 'bignumber.js';
  import rf from '../../lib/RequestFactory';
  import DatePicker from 'vuejs-datepicker';
  import SelectBox from '../../components/SelectBox';
  import CoinInput from '../../common/CoinInput.vue';
  import moment from 'moment';
  import DataTable4 from "../../components/DataTable4"


  export default {
    components: {
      DatePicker,
      SelectBox,
      CoinInput,
      DataTable4
    },
    data() {
      return {
        titlePage: this.$t('exchange_balance_detail.title'),
        searchDatePK: moment().toDate(),
        columnHeaderName: {},
        window: window,
        searchKey:'',
        searchKeySucess : '',
        maxlengthDate : 10,
        historyTab: 'hours',
        msgEmptyData: this.$t('common.datatable.no_data'),
        refreshSort: false
      }
    },
    methods: {
      changeBigNumber(data){
        return new BigNumber(data);
      },
      checkDateValid() {
        if(this.historyTab === 'yearly'){
          this.searchKey = this.searchKey.replace(/[^0-9]/, "");
        }else{
          this.searchKey = this.searchKey.replace(/[^0-9\/.-]/, "");
        }
      },
      checkDateCopyValid(event){
        event.preventDefault();
        this.searchKey = this.searchKey.replace(/[^0-9\/.-]\/gm/, "");
      },
      getDataTable(params) {
        let param = {};
        param.type = this.historyTab ? this.historyTab : 'hours';
        if(this.historyTab === 'hours') {
          param.date = this.searchKeySucess ? this.searchKeySucess : this.searchDatePK;
        }else{
          if(this.searchKeySucess) {
            param.date = this.searchKeySucess;
          }
        }
        let data;
        if(params) {
          data =  Object.assign(params, param);
        }
        if (this.refreshSort) {
          data.sort= "";
          data.sort_type = "";
          this.refreshSort = false;
        }
        return rf.getRequest('ExchangeBalanceRequest').getExchangeBalance(data)
          .then(res=>{
            let keyArr  = res.data && res.data.data ? Object.keys(res.data.data) : ' ';
            if(this.historyTab && this.historyTab !='hours'){
              this.columnHeaderName = res.data && res.data.data && res.data.data[0] ? Object.keys(res.data.data[keyArr[0]]): {};
            }else {
              this.columnHeaderName = res.data && res.data[0] ? Object.keys(res.data[0]): {};
            }
            return res;
          }
        );
      },
      checkValidYearly(){
        let regexOnlyNumber = new RegExp('/[0-9]/');
        let parseDate = new Date(this.searchKey);
        if(String(this.searchKey).length === 4) {
         this.searchKeySucess = moment().years(this.searchKey).format('YYYY-MM-DD');
          this.$refs.list_profit.refresh();
        }else{
          this.$toastr("error", this.$t("exchange_balance_detail.data_table.search_message.isNotValid" ,{0:this.historyTab}));
          return;
        }
      },
      checkValidMonth(){
        let parseDate = new Date(this.searchKey);
        let regexfullDate = new RegExp('\d{2,4}[\/.-]\d{2,4}');
        if(parseDate && parseDate != 'Invalid Date' && !regexfullDate.test(this.searchKey) && String(this.searchKey).length === 7){
          this.searchKeySucess = moment(this.searchKey).format('YYYY-MM-DD');
          this.$refs.list_profit.refresh();
        }else {
          this.$toastr("error", this.$t("exchange_balance_detail.data_table.search_message.isNotValid",{0: this.historyTab}));
          return;
        }
      } ,
      checkValidDate(){
        let parseDate = new Date(this.searchKey);
        let regexfullDate = new RegExp('\d{2,4}[\/.-]\d{2}[\/.-]\d{2,4}');
        if(parseDate && parseDate != 'Invalid Date' && !regexfullDate.test(this.searchKey) && String(this.searchKey).length === 10){
          this.searchKeySucess = moment(this.searchKey).format('YYYY-MM-DD');
          this.$refs.list_profit.refresh();
        }else {
          this.$toastr("error", this.$t("exchange_balance_detail.data_table.search_message.isNotValid",{0: this.historyTab}));
          return;
        }
      },
      searchProfitDate(param) {
        this.checkDateValid();
        if(this.searchKey) {
          this.searchKeySucess = '';

          switch(this.historyTab) {
            case 'yearly' :
              this.checkValidYearly();
              break;
            case 'monthly':
              this.checkValidMonth();
              break;
            default:
              this.checkValidDate();
              break;
          } 
        } else { 
          this.$toastr("error", this.$t("exchange_balance_detail.data_table.search_message.required",{0:this.historyTab}));
          return;
        }
      },
      changeDatePicker(date){
        this.searchDatePK = date;
        this.searchKey = '';
        this.$refs.list_profit.refresh();
      },
      customFormatter(date) {
        return moment(date).format('YYYY - MM - DD');
      },
      selectTab(tab) {
         this.refreshSort = true;
         $("#tableProfit").find("th").attr("data-sort-order", "");
        this.historyTab = tab ;
        this.searchKey = '';
        this.searchKeySucess ='';
        this.maxlengthDate = tab === 'monthly' ? 7 : (tab ==='yearly' ? 4 : 10);
        this.$refs.list_profit.refresh();
      },
    },
    directives: {
      "date-only": {
        bind(el, binding) {
          el.value = insertNumber(el.value);
          binding.value = el.value;
        },

        inserted(el, bind) {
          el.value = insertNumber(el.value);
          bind.value = el.value;
        },

        update(el, bind) {
          el.value = insertNumber(el.value);
          bind.value = el.value;
        },
      }
    },
    mounted() {
      this.getDataTable();
      this.$emit('EVENT_PAGE_CHANGE', this);
    }
  }
  function insertNumber(newValue) {
    newValue = "" + newValue;
    newValue = newValue.match(/[0-9\/.-]/gi) ? newValue.match(/[0-9\/.-]/gi).join('') : "";
    return newValue;
  }
</script>

<style lang="scss" scoped>
@import "@/assets/sass/variables";
  .min-85{
    min-width: 85px;
  }
  .empty-data {
    text-align: center;
    span {
      vertical-align: middle;
      color: $color_grey;
      line-height: 200px;

      p {
        margin: 0px;
      }
    }
  }
  .select_transaction_container {
    display: flex;
    font-family: 'Roboto-Regular';
    color: $color-grey-dusty;
    .input-radio {
      label {
        display: inline;
        font-weight: normal;
        text-align: center;
        margin-right: 25px;

        span {
          font-size: $font_big;
        }

        input[type="radio"] {
          display: inline-block;
          margin-top: 0px;
          position: relative;
          top: -2px;
        }
      }
    }

    .tab-header {
      display: block;
      width: 100%;
      float: left;
      margin: 28px 0px 15px 20px;

      ul {
        list-style: none;
        padding-left: 0px;

        li {
          float: left;
          margin-right: 25px;
          font-size: $font_small;
          color: $color-grey-dark;
          display: block;

          > a {
            color: $color-grey-dark;
            font-family: 'Roboto-Regular';
            font-weight: 500;
            font-size: $font_small;
            display: block;
            float: right;

            &::before {
              content: "";
              display: inline-block;
              width: 12px;
              height: 12px;
              border-radius: 50%;
              border: solid 1px #979797;
            }

            span {
              color: $color-grey-dusty;
              font-family: 'Roboto-Regular';
              padding-left: 5px;
              font-size: $font_small;
              font-weight: normal;
            }
          }

          &.active {
            > a {
              &::before {
                border: solid 4px $color-jungle-green;
              }

              color: $color-jungle-green;
            }
          }
        }
      }
    }
    .search_box{
      padding-top: 20px;
    }
  }

  .opacity-row {
    opacity: 0.4;
  }


  #exchange_balance_detail {
    margin-top: 5px;
    .filter-container {
      margin: 40px 0px 30px 0px;
      height: 60px;
      .filter-item {
        float: left;

        input {
          margin-top: 23px;
          margin-right: 5px;
          display: inline-block;
          width: 145px;
          font-family: $font-family-roboto;
          &:hover, &:active, &:focus {
            border-color: $color-jungle-green;
          }
        }
        select {
          width: 65px;
          display: inline-block;
          font-family: $font-family-roboto;
          font-size: $font_root;
          &:hover, &:active, &:focus {
            border-color: $color-jungle-green;
          }
        }
        button {
          width: auto;
          margin: 23px 2px 0px 2px;
          height: 30px;
          font-size: $font-smaller;
          font-family: $font-family-roboto;
        }
        .btn-reset {
          background-color: $color-caribbean-green;
          border: 1px solid $color-caribbean-green;
          width: 80px;
          text-transform: uppercase;
          color: $color-white;
          font-weight: 600;
          &:hover {
            border-color: $color-aquamarine;
            background-color: $color-aquamarine;
          }
        }
        .btn-primary {
          padding: 0;
          min-width: 80px;
          background-color: $color-caribbean-green;
          border: 1px solid $color-caribbean-green;
          &:hover {
            border-color: $color-aquamarine;
            background-color: $color-aquamarine;
          }
        }
        .coin-input {
          width: 70px;
          position: relative;
          display: inline-block;
          font-size: $font-small;
          font-family: $font-family-roboto;
          background-color: $color-bright-gray;
          border: 1px solid $color_alto;
          border-radius: 3px;
          padding: 5px 10px 5px 10px;
          margin-right: 0px;
          height: 30px;
          .coin-option {
            background-color: $color-white;
            max-height: 210px;
            display: block;
            overflow-y: auto;
            margin-top: 5px;
            position: absolute;
            width: 79px;
            left: 5px;
            z-index: 10;
            padding: 12px 0px;
            box-shadow: 0px 3px 12px rgba(49, 49, 49, 0.51);
            &:after {
              bottom: 100%;
              left: 50%;
              border: solid transparent;
              content: " ";
              display: block;
              height: 0;
              width: 0;
              position: absolute;
              pointer-events: none;
              border-color: rgba(136, 183, 213, 0);
              border-bottom-color: $color_white;
              border-width: 5px;
              margin-left: -5px;
            }
            .option {
              display: block;
              width: 100%;
              line-height: 20px;
              cursor: pointer;
              padding: 3px 14px;
              overflow: hidden;
              &:hover {
                color: $color-jungle-green;
              }
            }
          }
        }
        .text-pair {
          font-size: $font-small;
          font-family: $font-family-roboto !important;
          color: $color-grey-dusty;
          margin-bottom: 5px;
        }
        .search_box {
          display: inline-block;
          float: right;
          width: 215px;
          max-width: 100%;
          .search_input {
            background-color: transparent;
            height: 28px;
            border: 1px solid $color_alto;
            padding: 4px 15px;
            line-height: 20px;
            width: 100%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-size: $font-small;
          }
        }
      }
      .pair {
        padding: 0px 20px;
        input {
          width: 65px;
        }
      }
    }
    .form-control {
      background: $color-white-smoke;
      height: 30px;
      font-size: $font_small;
      padding-left: 11px;
      padding-right: 0;
      width: 215px;
    }
    .toolbar-element2 {
      display: inline-block;
      float: left;
    }
    .bw-element {
      float: left;
      font-size: $font_medium;
      margin-left: 5px;
      height: 30px;
      line-height: 30px;
      margin-right: 5px;
      color: $color-grey-dusty;
    }
    .datatable{
      button.btnForm.bt-action{
        padding-left: 5px !important;
        padding-right: 5px !important;
        font-weight: 400 !important;
      }
    }

    margin-top: 10px;
    .font-bold{
      font-weight: bold;
      background:$color_catskill_white;
    }
    .max_width {
      display: block;
      // width: 700px;
      max-width: 100%;
    }

    .exchange-coin {
      width: 7%;
      text-transform: uppercase;
    }

    th {
      font-family: $font-family-roboto;
      font-size: $font-small;
      padding: 5px 25px 4px 15px !important;
      min-width: 100px;
    }
    th:first-child{
      min-width: 120px;
    }
    td {
      font-family: $font-family-roboto;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .text-right::after {
      display: none;
    }
  }
</style>
