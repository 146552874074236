import BaseRequest from './BaseRequest'

export default class WalletRequest extends BaseRequest {

  getUserWalletSummary(params) {
    let url = '/api/wallet/user-wallet-summary';
    return this.get(url, params);
  }

  getTrezorWalletSummary(params) {
    let url = '/api/wallet/trezor-wallet-summary';
    return this.get(url, params);
  }

  getUserWallets(params) {
    let url = '/api/wallet/user-wallets';
    return this.get(url, params);
  }

  getTrezorWallets(params) {
    let url = '/api/wallet/trezor-wallets';
    return this.get(url, params);
  }

  getErc20ContractInformation(params) {
    let url = '/admin/api/erc20-contract-information';
    return this.get(url, params);
  }


}
