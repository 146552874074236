<template>
  <li class="item_navbar_right RouteAlone" :class="{ activeRouteAlone: router.name === $route.name }"
    v-if="type === 'item'" @click="linkTarget ? openNewTab() : gotoRoute(router.name)">
    <i class="icon_item" :class="icon"></i> <span class="text_item">{{ name }}</span>
  </li>
  <li v-else class="item_navbar_right" :class="[
    isHeader ? 'header' : type === 'item' ? '' : 'treeview',
    menuOpen && hasActiveItem ? 'menu-open' : '',
  ]" @click="menuOpen = !menuOpen">
    <div>
      <i class="icon_item" :class="icon"></i>
      <span class="name_item">{{ name }}</span>
      <span class="pull-right-container" v-if="!isHeader">
        <small v-if="badge && badge.data" class="label pull-right"
          :class="[badge.type === 'String' ? 'bg-green' : 'label-primary']">{{ badge.data }}</small>
        <i v-else class="fa fa-angle-down pull-right"></i>
      </span>
    </div>
    <ul class="treeview-menu" v-if="items.length > 0" :style="menuOpen ? 'display: block' : ''">
      <li v-for="(item, index) in items" :class="{ active: isActiveSubMenu(item) }" :key="index"
        @click.stop="gotoRoute(item.router.name)">
        <i :class="item.icon"></i> {{ item.name }}
      </li>
    </ul>
  </li>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'item',
    },
    isHeader: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: '',
    },
    name: {
      type: String,
    },
    badge: {
      type: Object,
      default() {
        return {}
      },
    },
    items: {
      type: Array,
      default() {
        return []
      },
    },
    router: {
      type: Object,
      default() {
        return {
          name: '',
        }
      },
    },
    link: {
      type: String,
      default: '',
    },
    params: {
      type: String | undefined,
      default: '',
    },
  },
  data() {
    return {
      menuOpen: false,
    }
  },
  computed: {
    hasActiveItem() {
      const item = _.find(this.items, (item) => {
        return (
          item.router.name === this.$route.name ||
          (item.router.subRoutes &&
            item.router.subRoutes.includes(this.$route.name)) ||
          (item.router.subRoutes &&
            item.router.subRoutes.includes(this.$route.path))
        )
      })
      if (item) {
        return true
      }
      return false
    },
    linkTarget() {
      if (!this.link) return ''
      return this.link + this.params
    }
  },

  methods: {
    clickItem() {
      console.log(this.type)
    },
    openNewTab() {
      window.open(this.linkTarget, "__blank")
    },
    gotoRoute(routeName) {
      if (this.$route.name != routeName) {
        this.$router.push({ name: routeName })
      }
    },
    isActiveSubMenu(item) {
      return (
        item.router.name === this.$route.name ||
        (item.router.subRoutes &&
          item.router.subRoutes.includes(this.$route.name)) ||
        (item.router.subRoutes &&
          item.router.subRoutes.includes(this.$route.path))
      )
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/sass/variables';

.sidebar-collapse {
  .main-sidebar {
    .item_navbar_right.treeview {
      .fa {
        display: none;
      }

      .name_item {
        display: none;
      }

      .treeview-menu {
        margin-top: -44px;

        li {
          padding-left: 24px;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .sidebar-collapse {
    .main-sidebar {
      .item_navbar_right.treeview {
        .fa {
          display: initial;
        }

        .name_item {
          display: initial;
        }

        .treeview-menu {
          margin-top: 0;

          li {
            padding-left: 50px;
          }
        }
      }
    }
  }
}
</style>

<style lang="scss" scoped>
@import '@/assets/sass/variables';


.icon-future-record {
  background-image: url('@/assets/images/icon/futureRecords.svg') !important;
  min-height: 18px;
}

.item_navbar_right {
  position: relative;
  margin: 0;
  padding: 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);

  &.RouteAlone {
    cursor: pointer;
    font-size: 14px;
    line-height: 20px;
    padding: 15px 20px;
    color: #fff;
    overflow: hidden;

    .icon_item {
      width: 18px;
      font-size: $font_big;
      line-height: 20px;
      float: left;
      color: $color_white;
      margin-right: 10px;
    }

    .text_item {
      color: white;
      margin-left: 8px;
    }

    .name_item {
      color: $color_white;
      display: inline;
      overflow: hidden;
      max-width: calc(100% - 50px);
      text-overflow: ellipsis;
      float: left;
    }
  }

  >div {
    cursor: pointer;
    position: relative;
    overflow: hidden;
    display: block;
    color: $color_white;
    font-size: $font_root;
    line-height: 20px;
    padding: 15px 20px;
    border-left: 0px solid transparent !important;

    .icon_item {
      width: 18px;
      font-size: $font_big;
      line-height: 20px;
      float: left;
      color: $color_white;
      margin-right: 10px;
    }

    .name_item {
      color: $color_white;
      display: inline-block;
      overflow: hidden;
      max-width: calc(100% - 50px);
      text-overflow: ellipsis;
      float: left;
      margin-left: 8px;
    }

    .pull-right-container {
      position: absolute;
      right: 10px;
      top: 50%;
      margin-top: -7px;

      .fa-angle-down {
        width: auto;
        height: auto;
        padding: 0;
        margin-right: 10px;
        transition: transform 0.5s ease, -webkit-transform 0.5s ease;
        transform: rotate(0deg);
        color: $color-jungle-green;
      }
    }
  }

  .treeview-menu {
    background-color: $color_eden !important;
    display: none;
    list-style: none;
    padding: 0px;
    margin: 0px;

    li {
      cursor: pointer;
      font-size: 13px;
      color: #ccc;
      line-height: 20px;
      padding: 6px 0 6px 50px;

      &:hover {
        color: $color_white;
      }
    }
  }
}

@media (max-width: 767px) {
  .item_navbar_right {
    .treeview-menu {
      background-color: $color_eden !important;
      display: none;
      list-style: none;
      padding: 0px;
      margin: 0px;

      li {
        cursor: pointer;
        font-size: 13px;
        color: #ccc;
        line-height: 20px;
        padding: 6px 0 6px 50px;

        &:hover {
          color: $color_white;
        }
      }
    }
  }
}
</style>
