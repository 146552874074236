<template>
  <div class="deposit_pages boxCore" id="deposit_pages">
    <div class="deposit_pages-filter">
      <div class="filter-template">
        <div class='label-text'>{{ $t('funds.balances.from') }}</div>
        <date-picker v-model="startDate" class="date-picker-input" :format="customFormatter"></date-picker>
      </div>
      <div class="filter-template">
        <div class='label-text'>{{ $t('funds.history.to') }}</div>
        <date-picker v-model="endDate" class="date-picker-input" :format="customFormatter"></date-picker>
      </div>
      <!--
      <div class="filter-template">
        <div class='label-text'>Symbol</div>
        <div class="input-dropdown" v-click-outside="clickOut1">
          <input type="text" @click="showDropdownSide" :placeholder=" $t('order.order_history.all') " class="form-control" v-model="symbolFilter" readonly/>
          <span class="icon-coin-input" @click="showDropdownSide"><i class="fa" :class="!isShowSideOptions? 'fa-angle-down' : 'fa-angle-up' "></i></span>
          <div class="coin-option" v-if="isShowSideOptions">
            <span class="option" @click="onSelectDropdown(null, 'symbol')">{{ $t('order.order_history.all') }}</span>
            <span class="option" v-for="type in SymbolData" @click="onSelectDropdown(type, 'symbol')">{{ type.name  }}</span>
          </div>
        </div>
      </div>
      -->
      <div class="filter-template type">
        <div class="label-text date-text">{{ $t('orders.open_order.type') }}</div>
        <div class="toolbar-element">
          <select-box v-model="filters.type" :placeholder="$t('order.order_history.all')" :options="typesData" />
        </div>
      </div>
      <div class="filter-template status">
        <div class="label-text date-text">{{ $t('orders.open_order.status') }}</div>
        <div class="toolbar-element">
          <select-box v-model="filters.status" :placeholder="$t('order.order_history.all')" :options="statusData" />
        </div>
      </div>
      <div class="filter-template">
        <div class="label-text">&nbsp;</div>
        <input title type="text" :placeholder="$t('account.detail.openOrder.inputplace.search')" @keyup.enter="search" class="form-control search_symbol"
          name="searchKey" v-model="filters.searchKey">
      </div>
      <div class="filter-template">
        <div class="label-text">&nbsp;</div>
        <button class="btn btn-reset btn-search" @click="search()">{{ $t('orders.open_order.search') }}</button>
        <button class="btn btn-reset" @click="refresh()">{{ $t('orders.open_order.reset') }}</button>
        <button class="btn btn-download" @click="download()">
          <img src="@/assets/images/download-icon.svg" width="12" />
          {{ $t('orders.open_order.data') }}
        </button>
      </div>
    </div>

    <div class="table-futures">
      <data-table-4 :getData="getDepositTransaction" ref="datatable" :column="12" :limit="10" :inLine="true"
        class="scroll" :total-user="totalRecords">
        <th class="text-left" data-sort-field="id">{{ $t('funds.withdrawals.id') }}</th>
        <th class="text-left" data-sort-field="type">{{ $t('funds.withdrawals.type') }}</th>
        <th class="text-left" data-sort-field="sender">{{ $t('funds.withdrawals.sender') }}</th>
        <th class="text-left" data-sort-field="amount">{{ $t('funds.withdrawals.amount') }}</th>
        <th class="text-left" data-sort-field="receiver">{{ $t('funds.withdrawals.receiver') }}</th>
        <th class="text-left" data-sort-field="hash">{{ $t('funds.withdrawals.hash') }}</th>
        <th class="text-left">{{ $t('funds.withdrawals.network') }}</th>
        <th class="text-center" data-sort-field="status">{{ $t('funds.withdrawals.status') }}</th>
        <th class="text-left" data-sort-field="created_at">{{ $t('funds.withdrawals.created_time') }}</th>
        <th class="text-center">{{ $t('funds.withdrawals.action') }}</th>

        <template slot="body" slot-scope="props">
          <tr>
            <td class="text-left">
              {{ props.item.id }}
            </td>
            <td class="text-left">
              <div class="text-top">{{ props.item.type | uppercase }} </div>
            </td>
            <td class="text-left ">
              <span class="text-account" @click="gotoAccDetail(props.item.sender)">{{ props.item.sender }}</span>
            </td>
            <td class="text-left">
              {{ props.item.amount | convertToBigNumber | formatCurrencyAmountDecimal(8, '0') }} {{ props.item.currency
                | uppercase }}
            </td>
            <td class="text-left">
              <span v-if="props.item.type == 'internal'" class="text-account"
                @click="gotoAccDetail(props.item.receiver)">{{ props.item.receiver }}</span>
              <span v-else>{{ props.item.receiver }}</span>
            </td>
            <td class="text-left">
              {{ props.item.hash }}
            </td>
            <td class="text-left">
              {{ networks[props.item.network_id] || "" }}
            </td>
            <td class="text-left">
              <span class="status">
                <div class="bt" :class="props.item.status">{{ props.item.status | transactionStatus }}</div>
              </span>
            </td>
            <td class="text-left">
              <div class="text-top">{{ props.item.creation_time | formatTimeStamp('YYYY-MM-DD') }} </div>
              <div class="text-bottom">{{ props.item.creation_time | formatTimeStamp('HH:mm:ss') }}</div>
            </td>
            <td class="text-left">
              <span class="action">
                <button v-if="props.item.type == 'internal'" @click="openList()" class="btc cancel"
                  :disabled="props.item.status != 'pending'">Cancel</button>
                <button v-if="props.item.type == 'internal'" @click="openList()" class="btc approve"
                  :disabled="props.item.status != 'pending'">Approve</button>
                <img @click="handleOpen(props.item)" class="imge" src="@/assets/images/future/List.svg" width="24px"
                  height="24px" />
              </span>
            </td>
          </tr>
        </template>
      </data-table-4>
    </div>

    <div class="clearfix clearfix-40"></div>
    <right-slide :dataDetail="dataDetail" :rightSlide="rightSlide" :onClose="handleClose" />
  </div>
</template>

<script>

import moment from "moment";
import COMMON_CONST from "../../common/Const";
import rf from "../../lib/RequestFactory";
import { mapActions } from "vuex";
import DatePicker from 'vuejs-datepicker';
import Modal from "../../components/Modal";
import SelectBox from "../../components/SelectBox";
import BigNumber from "bignumber.js";
import DataTable4 from "../../components/DataTable4.vue";
import RightSlide from "../../components/RightSlide.vue";

export default {
  name: "OrderFuturesRecords",
  components: {
    Modal,
    DatePicker,
    SelectBox,
    DataTable4,
    RightSlide
  },
  data() {
    return {
      titlePage: window.i18n.t('menu.fund.deposit'),
      searchKey: '',
      isActive: true,
      startDate: moment().subtract(1, 'week').toDate(),
      endDate: moment().toDate(),
      filters: {
        searchKey: "",
        status: null,
        type: null,
      },
      isLoading: false,
      typesData: [
        { id: '', name: window.i18n.t("order.order_history.all") },
        { name: 'Internal', id: 'internal' },
        { name: 'External', id: 'external' },
      ],
      statusData: [
        { id: '', name: window.i18n.t("order.order_history.all") },
        { id: 'pending', name: window.i18n.t('common.transaction_status.pending') },
        { id: 'success', name: window.i18n.t('common.transaction_status.success') },
        { id: 'cancel', name: window.i18n.t('common.transaction_status.cancel') },
      ],
      totalRecords: 0,
      dataDetail: null,
      rightSlide: false,
      networks: {}
    }
  },
  watch: {
    isActive() {
      this.$refs.datatable.refresh();
    }
  },
  methods: {
    selectTab(tab) {
      this.isActive = tab;
      this.filters.searchKey = '';
      this.$refs.datatable.refresh();
      this.startDate = moment().subtract(1, 'week').toDate();
      this.endDate = moment().toDate();
    },
    customFormatter(date) {
      return moment(date).format('YYYY-MM-DD');
    },
    search() {
      let filterParams = {
        start_date: moment(this.startDate)
          .startOf("day")
          .format("x"),
        end_date: moment(this.endDate)
          .endOf("day")
          .format("x")
      };
      if (this.customFormatter(this.startDate) === this.customFormatter(this.endDate)) {
        filterParams = {
          start_date: moment(this.startDate)
            .startOf("day")
            .format("x"),
          end_date: moment(this.startDate)
            .endOf("day")
            .format("x")
        }
      }
      if (this.customFormatter(this.startDate) > this.customFormatter(this.endDate)) {
        this.$toastr("error", this.$t("common.date.warning"));
        return;
      }
      if (this.filters.searchKey) {
        filterParams.search_key = this.filters.searchKey;
      }
      this.$refs.datatable.filter(filterParams);
    },
    refresh() {
      this.resetInput();
      this.$refs.datatable.refresh();
    },
    download() {
      alert("vcx")
    },
    onClose() {
      alert("close")
    },
    openList() {
      alert("list")
    },
    resetInput() {
      this.filters = Object.assign(this.filters, {
        searchKey: "",
        side: null,
        symbol: null,
        status: null,
        type: null,
      });
      this.startDate = moment().subtract(1, 'week').toDate();
      this.endDate = moment().toDate();
      $(".datatable").find("th").attr("data-sort-order", "");
    },
    listenForNotification() {
      window.Echo.channel('App.Models.Admin')
        .listen('AdminNotificationUpdated', () => {
          this.$refs.datatable.refresh();
        });
    },
    getDepositTransaction(params) {
      let filterParams = {
        start_date: moment(this.startDate)
          .startOf("day")
          .format("x"),
        end_date: moment(this.endDate)
          .endOf("day")
          .format("x")
      };
      if (this.customFormatter(this.startDate) === this.customFormatter(this.endDate)) {
        filterParams = {
          start_date: moment(this.startDate)
            .startOf("day")
            .format("x"),
          end_date: moment(this.endDate)
            .endOf("day")
            .format("x")
        }
      }

      let meta = {
        // isActive: this.isActive,
        start_date: filterParams.start_date,
        end_date: filterParams.end_date,
      };
      if (this.filters.searchKey) {
        meta.search_key = this.filters.searchKey;
      }
      if (this.filters.status) {
        meta.status = this.filters.status.id.toLowerCase();
      }
      if (this.filters.type) {
        meta.type = this.filters.type.id.toLowerCase();
      }
      params = Object.assign(params, meta);
      params.size = params.limit || 5;
      delete params.limit;
      const records = rf.getRequest('AdminRequest').getDepositTransaction(params);
      records.then(data => {
        this.totalRecords = data.data.total;
      })
      return records;
    },
    downloadExcel() {
      let filterParams = {
        start_date: moment(this.startDate)
          .startOf("day")
          .format("x"),
        end_date: moment(this.endDate)
          .endOf("day")
          .format("x")
      };
      if (this.startDate > this.endDate && this.startDate.toDateString() != this.endDate.toDateString()) {
        this.$toastr("error", this.$t("common.date.warning"));
        return;
      }
      if (this.filters.searchKey) {
        filterParams.search_key = this.filters.searchKey;
      }
      if (this.filters.type && this.filters.type.id != '') {
        filterParams.type = this.filters.type.id.toLowerCase();
      }
      if (this.filters.status && this.filters.status.id != '') {
        filterParams.status = this.filters.status.id.toLowerCase();
      }

      rf.getRequest('AdminRequest').exportDepositTransaction(filterParams).then(res => {
        if (res?.success) {
          window.open(`${process.env.VUE_APP_API_URL}/${res.data.path}`, '_blank')
        }
      })
    },
    filledOrder(value) {
      return new BigNumber(value.quantity).minus(value.remaining)
    },
    handleClose() {
      this.dataDetail = null
      this.rightSlide = false
    },
    handleOpen(data) {
      this.dataDetail = data
      this.rightSlide = true
    },
    gotoAccDetail(id) {
      this.$router.push({
        path: 'account/detail',
        query: { id: id }
      });
    },
    getOptionData() {
      this.typesData = [{ id: '', name: window.i18n.t("order.order_history.all") }]
      this.statusData = [{ id: '', name: window.i18n.t("order.order_history.all") }]
      rf.getRequest('AdminRequest')
        .getOptionDataDeposit('status')
        .then(res => {
          res?.data.map((item) => {
            this.statusData.push({
              id: item,
              name: window.i18n.t(`common.transaction_status.${item}`)
            })
          })
        })
      rf.getRequest('AdminRequest')
        .getOptionDataDeposit('type')
        .then(res => {
          res?.data.map((item) => {
            this.typesData.push({
              id: item,
              name: window.i18n.t(`common.transaction_type.${item}`)
            })
          })
        })
      rf.getRequest('MultiChainRequest').getNetworks({ limit: 50 })
        .then(res => {
          res.data.data.map((network) => {
            this.networks[`${network.id}`] = `${network.currency.toUpperCase()} | ${network.name}`
          })
        })
    }
  },
  created() {
    this.getOptionData();
  },
  mounted() {
    this.$emit('EVENT_PAGE_CHANGE', this);
    this.listenForNotification();

  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/variables";

.d-flex {
  display: flex;
}

.w-100 {
  width: 100%;
}

.buy {
  color: $text-color-jade !important;
}

.sell {
  color: $text-color-red !important;
}

#deposit_pages {
  table {
    thead {
      th {
        &:first-child {
          width: 2%;
          min-width: 100px;
        }

        &:nth-child(2) {
          width: 8%;
          min-width: 100px;
        }

        &:nth-child(3) {
          width: 5%;
          min-width: 100px;
        }

        &:nth-child(4) {
          width: 12%;
          min-width: 110px;
        }

        &:nth-child(5) {
          width: 15%;
          min-width: 130px;
        }

        &:nth-child(6) {
          width: 20%;
          min-width: 130px;
        }

        &:nth-child(7) {
          width: 14%;
          min-width: 150px;
          padding-left: 20px;
        }

        &:nth-child(8) {
          width: 9%;
          min-width: 110px;
        }

        &:nth-child(9) {
          width: 8%;
          min-width: 120px;

          &::after {
            display: none;
          }
        }

        &:nth-child(10) {
          width: 70px;
          min-width: 70px;

          &::after {
            display: none;
          }
        }
      }
    }
  }
}

.no-left-radius {
  input {
    border-radius: 0px 3px 3px 0px;
  }
}

.no-right-radius {
  input {
    border-radius: 3px 0px 0px 3px;
  }
}

td {
  word-break: break-all;
  vertical-align: middle !important;
  border-bottom: solid 1px $color-white-smoke;

  .bt {
    width: 80px;
    height: 30px;
    border-radius: 3px;
    color: $color_black_logo;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;

    &.pending {
      background: $color-pending-status;
    }

    &.success {
      background: $color-filled-status;
    }

    &.cancel {
      background: $color-cancel-status;
    }
  }

  .status {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .action {
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: center;

    .btc {
      width: 72px;
      height: 28px;
      display: flex;
      align-items: center;
      justify-content: center;

      &.cancel {
        color: #FF8C8A;
        border: 1px solid #FF8C8A;
        border-radius: 1px;
      }

      &.approve {
        color: #53D768;
        border: 1px solid #53D768;
        border-radius: 1px;
      }
    }
  }
}

.datatable {
  font-family: $font-family-roboto !important;
}

ul {
  list-style-type: none;
  padding: 0px;
}

.clearfix-40 {
  display: block;
  clear: both;
  height: 40px;
}

.deposit_pages {
  font-family: $font_family_root;

  .deposit_pages-filter {
    display: flex;
    flex-wrap: wrap;
  }

  .filter_container {
    margin: 12px 0px;

    .title_item {
      color: $color_mine_shaft;
      font-size: $font_big_20;
      font-weight: 500;
      line-height: 28px;
      float: left;
    }

    .search_box {
      display: inline-block;
      float: right;
      width: 215px;
      max-width: 100%;

      .search_input {
        background-color: transparent;
        height: 28px;
        border: 1px solid $color_alto;
        padding: 4px 15px;
        line-height: 20px;
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: $font-small;
      }
    }
  }

  .item_email_user {
    display: inline-block;
    float: left;
    position: relative;

    .txt_email_user {
      display: block;
      max-width: 170px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .tooltip_email_user {
      position: absolute;
      top: 0px;
      left: 0px;
      line-height: 20px;
      padding: 5px 20px;
      left: 100%;
      background-color: $color_white;
      white-space: nowrap;
      width: auto;
      z-index: 10;
      font-size: $font_root;
      font-weight: 500;
      color: $color_mine_shaft;
      transition: 0.5s;
      display: none;
      box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.4);

      &:after {
        right: 100%;
        top: 50%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: rgba(136, 183, 213, 0);
        border-right-color: $color_white;
        border-width: 5px;
        margin-top: -5px;
      }
    }

    &:hover {
      .tooltip_email_user {
        display: block;
        transition: 0.5s;
      }
    }
  }

  .item_bank_user {
    display: inline-block;
    float: left;
    position: relative;

    .txt_bank_user {
      display: block;
      max-width: 250px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .tooltip_bank_user {
      position: absolute;
      top: 0px;
      left: 0px;
      line-height: 20px;
      padding: 5px 20px;
      left: 100%;
      background-color: $color_white;
      // white-space: nowrap;
      width: 250px;
      z-index: 10;
      font-size: $font_root;
      font-weight: 500;
      color: $color_mine_shaft;
      transition: 0.5s;
      display: none;
      box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.4);

      &:after {
        right: 100%;
        top: 12px;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: rgba(136, 183, 213, 0);
        border-right-color: $color_white;
        border-width: 5px;
        margin-top: -5px;
      }
    }

    &:hover {
      .tooltip_bank_user {
        display: block;
        transition: 0.5s;
      }
    }
  }

  .btn_Confirm,
  .btn_Reject {
    background-color: $color-caribbean-green;
    color: $color-white;
    text-transform: uppercase;
    width: 82px;
    height: 25px;
    line-height: 20px;
    padding: 0px 9px;
    text-align: center;
    font-size: $font-smaller;
    font-family: $font-family-roboto-bold;
    border: 1px solid $color-caribbean-green;
    border-radius: 3px;
    transition: 0.5s;

    &:hover {
      background-color: $color-aquamarine;
      border-color: $color-aquamarine;
      transition: 0.5s;
    }
  }

  .btn_Reject {
    margin-left: 10px;
  }

  .list_modal_deposit {
    margin-bottom: 25px;
    padding: 0px;

    li {
      line-height: 20px;
      margin-bottom: 10px;
      color: $color_dove_gray;
      font-size: $font_root;
      font-weight: 500;

      .text-right {
        font-weight: 600;
        color: $color_mine_shaft;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .content_modal_deposit {
    color: $color_mine_shaft;
    font-size: $font_semi_big;
    font-family: $font-family-roboto-medium;
    font-weight: 500;
    text-align: center;
    line-height: 24px;
  }
}

.select_transaction_container {
  padding: 10px 0px 20px 0px;

  .input-radio {
    label {
      display: inline;
      font-weight: normal;
      text-align: center;
      margin-right: 25px;

      span {
        font-size: $font_big;
      }

      input[type="radio"] {
        display: inline-block;
        margin-top: 0px;
        position: relative;
        top: -2px;
      }
    }
  }
}

.form-control {
  background: $color_concrete;
  height: 30px;
  font-size: $font-small;
  padding-left: 11px;
  padding-right: 0;

  &:hover,
  &:focus {
    border-color: $color-jungle-green;
  }
}

.datatable {
  button.btnForm.bt-action {
    padding-left: 5px !important;
    padding-right: 5px !important;
    font-weight: 400 !important;
  }
}

.buy {
  color: $text-color-jade;
}

.sell {
  color: $text-color-red;
}

.toolbar-element {
  display: inline-block;
  float: left;
  width: 120px;
}

td {
  font-family: $font-family-roboto;
}

.table-futures {
  margin-top: 24px;
}

.filter-template {
  margin-right: 15px;

  .label-text {
    color: $dark-1;
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 7px;
    margin-left: 5px;
    font-family: $mulish-regular;
    color: $color-grey-dusty;
  }

  .date-picker-input {
    display: inline-block;
    float: left;

    ::v-deep {
      input {
        padding: 0 5px 0px 36px;
        width: 135px !important;
        height: 32px;
        border: 1px solid #C7CBD3 !important;
        border-radius: 10px !important;
        background-image: url('@/assets/images/icon/date-time.svg') !important;
        background-color: $color-white !important;
        background-size: 12px !important;
        background-position: center left 12px !important;
        background-repeat: no-repeat !important;
        color: $dark-1;

        &:hover,
        &:active,
        &:focus {
          border-color: $color-jungle-green;
          z-index: 9;
        }
      }
    }

  }

  .btn-reset {
    background: $color-caribbean-green;
    border-radius: 10px;
    color: $color_white;
    font-weight: 700;
    width: 78px;
    height: 32px;
  }

  .btn-search {
    margin: 0 10px;
  }

  .btn-download {
    background: $color-caribbean-green;
    border-radius: 10px;
    color: $color_white;
    font-weight: 700;
    width: 95px;
    height: 32px;
    margin-left: 10px;
  }

  .search_symbol {
    background: $color-white;
    border: 1px solid #C7CBD3 !important;
    border-radius: 10px !important;
    width: 197px;
    height: 32px;
  }
}

.input-dropdown {
  position: relative;

  input {
    background: $color-white;
    border: 1px solid #C7CBD3 !important;
    border-radius: 10px !important;
    width: 110px;
    height: 32px;
    cursor: pointer;
  }

  .icon-coin-input {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    color: #667186;

    i {
      font-size: 22px;
    }
  }

  .coin-option {
    position: absolute;
    top: 37px;
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0px 4px 15px rgba(0, 19, 54, 0.15);
    border-radius: 10px;
    padding: 17px 0;
    z-index: 3;

    &:after {
      bottom: 100%;
      left: 50%;
      border: solid transparent;
      content: " ";
      display: block;
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-color: rgba(136, 183, 213, 0);
      border-bottom-color: $color-white;
      border-width: 5px;
      margin-left: -5px;
    }

    .option {
      display: flex;
      align-items: center;
      width: 100%;
      line-height: normal;
      cursor: pointer;
      padding-left: 14px;
      font-size: 14px;
      font-weight: 500;
      overflow: hidden;
      color: #001336;
      height: 32px;

      &:hover {
        background: $color-athens;
      }
    }
  }
}
</style>

<style lang="scss">
@import "@/assets/sass/variables";

#deposit_pages {
  .sc_search_select {
    .group_search_select {
      .button_drop_search {
        width: 100%;
        height: 32px;
        padding: 6px 10px 6px 10px;
        border-radius: 10px;
        font-size: $font-small;
        font-family: $mulish-regular;
        background-color: $color-white;

        i.icon-arrow1 {
          margin-left: 5px;
          color: $color_dove_gray;
        }

        &:hover {
          border-color: $color-jungle-green;

          i.icon-arrow1 {
            color: $color-jungle-green;
          }
        }

      }

      .box_list_search_select {
        .list_search_select {
          border-radius: 10px;

          li {
            font-family: $font-family-roboto;
            font-size: $font-small;

            &:hover,
            &:active,
            &:focus {
              color: $color-jungle-green;
            }
          }
        }
      }
    }
  }
}
</style>