<template>
<div class="coin-inputt toolbar-element22" v-click-outside="clickOut">
  <input type="text" @click="showCoinOption()" @keyup="onSearchCoin()" :placeholder=" $t('order.order_history.all') " class="form-control coin-inputt" v-model="currency" readonly/>
  <span class="icon-coin-input" @click="showCoinOption()"><i class="fa fa-angle-down"></i></span>
  <div class="coin-option" v-if="isShowCoinOption">
    <span class="option" @click="onSelectCoin(null)">{{ $t('order.order_history.all') }}</span>
    <span class="option" v-for="coin in coins" :key="coin" @click="onSelectCoin(coin)">{{ coin | uppercase }}</span>
  </div>
</div>
</template>

<script>
  import rf from '../lib/RequestFactory';

  export default {
    props: ['isBuyHistory'],
    data () {
      return {
        currency: '',
        coins: [],
        coinMasters: [],
        isShowCoinOption: false,
      }
    },
    methods: {
      onSearchCoin() {
        try {
          this.coins = _.filter(this.coinMasters, (item) => {
            
            return _.isMatch(item.toUpperCase(), this.filters.currency.toUpperCase());
          });
        }
        catch(e) {
          console.log(e);
        }
      },
      showCoinOption() {
        this.isShowCoinOption = !this.isShowCoinOption;
        window._.remove(this.coinMasters, x => {
          // if(this.isBuyHistory) {
          //   if(!['USD', 'BTC', 'ETH','USDT'].includes(x.toUpperCase())) {
          //     return x.toUpperCase();
          //   }
          // }
          // else {
          //   return x.toUpperCase() == 'USD';
          // }
        });
        this.coins = this.coinMasters.map(x => x.toUpperCase());
      },
      requestCoinList() {
        rf.getRequest("CoinSettingRequest").getCoinList()
          .then(res => {
            if(window._.map(res.data, "coin")) {
              this.coinMasters = window._.map(res.data, "coin");
              const usdIndex = this.coinMasters.indexOf('usd');
              const usdtIndex = this.coinMasters.indexOf('usdt');
              if (usdIndex && usdtIndex) {
                const temp = this.coinMasters[usdIndex];
                this.coinMasters[usdIndex] = this.coinMasters[usdtIndex];
                this.coinMasters[usdtIndex] = temp;
              }
            }
          })
          .catch(e => {
            console.log(e)
          });
      },
      refresh() {
        this.currency = null;
        this.$emit('onCoinChange', this.currency);
      },
      onSelectCoin(coin) {
        this.isShowCoinOption = false;
        this.currency = coin;
        this.$emit('onCoinChange', this.currency)
      },
      clickOut() {
        this.isShowCoinOption = false;
      },
    },
    created() {
      this.requestCoinList();
    },
  }
</script>

<style lang="scss" scoped>
@import "@/assets/sass/variables";
  .coin-inputt {
    background: transparent;
    position: relative;
    display: inline-block;
    height: 30px;
    width: 70px;
    font-family: $font-family-roboto;
    font-size: $font-small;
    cursor: pointer;
    &:hover, &:active{
      border-color: $color-jungle-green;
    }
    .icon-coin-input {
      position: absolute;
      left: 50px;
      top: 50%;
      transform:translateY(-50%);
      cursor: pointer;
      i {
        font-size: 22px;
      }
    }
    .coin-option {
      background-color: $color-white;
      max-height: 135px;
      display: block;
      overflow-y: auto;
      margin-top: 5px;
      position: absolute;
      width: 79px;
      left: 5px;
      z-index: 10;
      padding: 12px 0px;
      box-shadow: 0px 3px 12px rgba(49, 49, 49, 0.51);
      &:after {
          bottom: 100%;
          left: 50%;
          border: solid transparent;
          content: " ";
          display: block;
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
          border-color: rgba(136, 183, 213, 0);
          border-bottom-color: $color-white;
          border-width: 5px;
          margin-left: -5px;
      }
      .option {
        display: block;
        width: 100%;
        line-height: normal;
        cursor: pointer;
        padding: 3px 14px;
        font-size: 13px;
        overflow: hidden;
        &:hover {
          color: $color-white;
          background: $color-jungle-green-light;
        }
      }
    }
  }
</style>
