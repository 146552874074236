<template>
  <div class="trade-history clearfix mg_bot50" id="trade_history">
    <div class="d-flex w-100">
      <div class="filter-template">
        <div class='label-text'>{{ $t('funds.balances.from') }}</div>
        <date-picker v-model="startDate" class="date-picker-input" :format="customFormatter"></date-picker>
      </div>
      <div class="filter-template">
        <div class='label-text'>{{ $t('funds.history.to') }}</div>
        <date-picker v-model="endDate" class="date-picker-input" :format="customFormatter"></date-picker>
      </div>
      <div class="filter-template pair-selector">
        <div class="label-text text-pair date-text">{{ $t('orders.open_order.pair') }}</div>
        <div class="coin-selector toolbar-element2" v-click-outside="clickOut">
          <input type="text" @click="showCoinOption()" @keyup="onSearchCoin()"
            :placeholder="$t('order.order_history.coin')" @keyup.enter="search" class="form-control coin-input"
            v-model="filters.coin">
          <div class="coin-option" v-if="isShowCoinOption">
            <span class="option" v-for="coin in coins" @click="onSelectCoin(coin)" :key="coin">{{ coin | uppercase
              }}</span>
          </div>
        </div>
        <span class="bw-element">/</span>
        <div class="toolbar-element2">
          <select-box v-model="filters.currency" :placeholder="$t('order.order_history.all')" :options="markets" />
        </div>
      </div>
      <div class="filter-template">
        <div class="label-text">&nbsp;</div>
        <input title type="text" :placeholder="'Search by ID'" @keyup.enter="search" class="form-control search_symbol"
          name="searchKey" v-model="filters.searchKey">
      </div>
      <div class="filter-template">
        <div class="label-text">&nbsp;</div>
        <button class="btn btn-reset btn-search" @click="search()">{{ $t('orders.trade_history.search') }}</button>
        <button class="btn btn-reset" @click="refresh()">{{ $t('orders.trade_history.reset') }}</button>
        <button v-if="!accountId" class="btn btn-reset" @click="downloadExcel()">
          <img src="@/assets/images/download-icon.svg" width="12" />
          {{ $t('orders.trade_history.data') }}
        </button>
      </div>
    </div>
    <div class="datatable">
      <data-table-4 :getData="getData" ref="datatable" :column="10" class="scroll" :inLine="true"
        :total-user="totalRecords" :limit="10">
        <th data-sort-field="id" class="cl1">{{ $t('orders.trade_history.trade_id') }}</th>
        <th v-if="!accountId" data-sort-field="account_id" class="cl2 text-center">{{ $t('orders.trade_history.account_id') }}</th>
        <th data-sort-field="buy_order_id" class="cl3">{{ $t('orders.trade_history.buy_id') }}</th>
        <th data-sort-field="sell_order_id" class="cl4">{{ $t('orders.trade_history.sell_id') }}</th>
        <th data-sort-field="coin" class="cl5">{{ $t('orders.trade_history.pair') }}</th>
        <th data-sort-field="transaction_type" class="cl6">{{ $t('orders.trade_history.side') }}</th>
        <th data-sort-field="quantity" class="cl7">{{ $t('orders.trade_history.quantity') }}</th>
        <th data-sort-field="price" class="cl8">{{ $t('orders.trade_history.price') }}</th>
        <th data-sort-field="created_at" class="text-left cl1">{{ $t('orders.trade_history.time') }}</th>
        <th class="text-left">{{ $t('orders.trade_history.action') }}</th>
        <template slot="body" slot-scope="props">
          <tr>
            <td class="cl1"
              :class="{ 'opacity-row': props.item.status == 'canceled' && props.item.executed_quantity == 0 }">{{
          props.item.id }}</td>
            <td v-if="!accountId" class="cl2 text-center"
              :class="{ 'opacity-row': props.item.status == 'canceled' && props.item.executed_quantity == 0 }">
              <span class="text-account" v-if="props.item.accountID" @click="detailAccount(props.item)">{{ props.item.accountID }}</span>
              <span class="text-account" v-else-if="props.item.transaction_type == 'sell'" @click="detailAccount(props.item)">{{ props.item.buyer_id }}</span>
              <span class="text-account" v-else @click="detailAccount(props.item)">{{ props.item.seller_id }}</span>
            </td>
            <td class="cl3"
              :class="{ 'opacity-row': props.item.status == 'canceled' && props.item.executed_quantity == 0 }">
              {{ props.item.buy_order_id }}
            </td>
            <td class="cl4"
              :class="{ 'opacity-row': props.item.status == 'canceled' && props.item.executed_quantity == 0 }">
              {{ props.item.sell_order_id }}
            </td>
            <td class="cl5"
              :class="{ 'opacity-row': props.item.status == 'canceled' && props.item.executed_quantity == 0 }">
              {{ `${props.item.coin}/${props.item.currency}` | uppercase }}
            </td>
            <td v-if="props.item.transaction_type == 'buy'" class="type-buy cl6"
              :class="{ 'opacity-row': props.item.status == 'canceled' && props.item.executed_quantity == 0 }">
              Buy
            </td>
            <td v-else="props.item.transaction_type == 'sell'" class="type-sell cl6"
              :class="{ 'opacity-row': props.item.status == 'canceled' && props.item.executed_quantity == 0 }">
              Sell
            </td>
            <td class="cl7"
              :class="{ 'opacity-row': props.item.status == 'canceled' && props.item.executed_quantity == 0 }">{{
          props.item.quantity | convertToBigNumber | formatCurrencyAmountDecimal(8, "0") }}</td>
            <td class="cl8"
              :class="{ 'opacity-row': props.item.status == 'canceled' && props.item.executed_quantity == 0 }">
              <span v-if="props.item.type == 'market' || props.item.type == 'stop_market'">{{
          $t('orders.trade_history.market') }}</span>
              <span v-else>{{ props.item.price | convertToBigNumber | formatCurrencyAmount(props.item.currency,
          "0") }}</span>
            </td>
            <td class="cl9 text-left"
              :class="{ 'opacity-row': props.item.status == 'canceled' && props.item.executed_quantity == 0 }">
              <div>{{ props.item.created_at | formatTimeStamp('YYYY-MM-DD') }}</div>
              <div>{{ props.item.created_at | formatTimeStamp('HH:mm:ss') }}</div>
            </td>
            <td class="cl10"
              :class="{ 'opacity-row': props.item.status == 'canceled' && props.item.executed_quantity == 0 }">
              <div class="action-wrap">
                <img class="btn-detail" src="@/assets/images/icon/detail-icon.svg" width="20"
                  @click="handleOpen(props.item)" />
              </div>
            </td>
          </tr>
        </template>
      </data-table-4>
    </div>
    <right-slide :dataDetail="dataDetail" :rightSlide="rightSlide" :onClose="handleClose" />
  </div>
</template>

<script>
import rf from '../../lib/RequestFactory';
import Modal from '@/desktop/components/common/Modal';
import DatePicker from 'vuejs-datepicker';
import moment from 'moment';
import MasterdataUtils from '@/desktop/common/MasterdataUtils';
import { ORDER_STATUS } from '../../common/Const';
import SelectBox from '../../components/SelectBox';
import DataTable4 from "../../components/DataTable4";
import RightSlide from "../../components/RightSlide";

export default {
  components: {
    Modal,
    DatePicker,
    SelectBox,
    DataTable4,
    RightSlide
  },
  props: {
    accountId: {
      type: String
    }
  },
  data() {
    return {
      titlePage: window.i18n.t('menu.spot.trade_history'),
      startDate: moment().subtract(6, "day").toDate(),
      endDate: new Date(),
      coin: '',
      filters: {
        coin: window.i18n.t('orders.open_order.all'),
        currency: '',
        searchKey: '',
        type: '',
      },
      coins: [],
      coinMasters: [],
      isShowCoinOption: false,
      trades: [],
      markets: [],
      rightSlide: false,
      dataDetail: null,
      totalRecords: 0
    }
  },
  methods: {
    customFormatter(date) {
      return moment(date).format('YYYY - MM - DD');
    },
    search() {
      let filterParams = {
        start_date: moment(this.startDate).startOf('day').format('x'),
        end_date: moment(this.endDate).endOf('day').format('x')
      };
      if (this.startDate > this.endDate && this.startDate.toDateString() != this.endDate.toDateString()) {
        this.$toastr('error', this.$t('common.date.warning'));
        return;
      }
      if (this.filters.searchKey) {
        filterParams.search_key = this.filters.searchKey;
      }
      if (this.filters.coin) {
        filterParams.coin = this.filters.coin === "All" ? "" : this.filters.coin;
      }
      if (this.filters.currency && this.filters.currency.id !== '') {
        filterParams.currency = this.filters.currency.id;
      }
      this.$refs.datatable.filter(filterParams);
    },
    refresh() {
      // this.requestCoinList();
      this.resetInput();
      this.$refs.datatable.refresh();
    },
    resetInput() {
      this.filters = Object.assign(this.filters, { coin: window.i18n.t('orders.open_order.all'), currency: '', searchKey: '' });
      this.startDate = moment().subtract(6, "day").toDate();
      this.endDate = new Date();
      $(".datatable").find("th").attr("data-sort-order", "");
      this.$refs.datatable.refresh();
    },
    onSearchCoin() {
      this.coins = _.filter(this.coinMasters, (item) => {
        return _.isMatch(item.toUpperCase(), this.filters.coin.toUpperCase());
      });
    },
    onSelectCoin(coin) {
      this.isShowCoinOption = false;
      this.filters.coin = coin === window.i18n.t('orders.open_order.all') ? window.i18n.t('orders.open_order.all') : coin.toUpperCase();
    },
    clickOut() {
      this.isShowCoinOption = false;
    },
    showCoinOption() {
      this.isShowCoinOption = true;
      this.coins = this.coinMasters;
    },
    getData(params) {
      let defaultParams = {
        ...params,
        start_date: moment(this.startDate).startOf('day').format('x'),
        end_date: moment(this.endDate).endOf('day').format('x'),
        size: params.limit
      };
      delete defaultParams.limit

      if (this.filters.coin) {
        defaultParams.coin = this.filters.coin === window.i18n.t('orders.open_order.all') ? "" : this.filters.coin;
      }
      if (this.filters.currency && this.filters.currency.id !== '') {
        defaultParams.currency = this.filters.currency.id;
      }

      if (this.accountId) return this.getDataByAccount(defaultParams);

      const response = rf.getRequest('OrderRequest').getDataTradeHistory(defaultParams)
      response.then(response => {
        if (response?.success)
          this.totalRecords = response.data.total;
      })
      return response
    },
    getDataByAccount(params) {
      const response = rf.getRequest('UserRequest').getTradeHistorySpot(this.accountId, params);
      response.then(response => {
        if (response?.success)
          this.totalRecords = response.data.total;
      })
      return response;
    },
    downloadExcel() {
      let filterParams = {
        start_date: moment(this.startDate)
          .startOf("day")
          .format("x"),
        end_date: moment(this.endDate)
          .endOf("day")
          .format("x")
      };
      if (this.startDate > this.endDate && this.startDate.toDateString() != this.endDate.toDateString()) {
        this.$toastr("error", this.$t("common.date.warning"));
        return;
      }
      if (this.filters.searchKey) {
        filterParams.search_key = this.filters.searchKey;
      }
      if (this.filters.coin) {
        filterParams.coin = this.filters.coin;
      }
      if (this.filters.currency && this.filters.currency.id != '') {
        filterParams.currency = this.filters.currency.id;
      }

      rf.getRequest('OrderRequest').exportDataTradeHistory(filterParams).then(res => {
        console.log(res)
        if (res?.success) {
          window.open(`${process.env.VUE_APP_API_URL}/${res.data.path}`, '_blank')
        }
      })
    },
    /*
    getMarkets() {
      return window._.map(COMMON_CONST.MARKETS, item => {
        return { id: item, name: item.toUpperCase() };
      });
    },
    */
    // requestCoinList() {
    //   rf.getRequest('CoinSettingRequest').getCoinList().then(res => {
    //     this.coinMasters = window._.map(res.data, 'coin')
    //   });
    // },
    getOptionData() {
      this.markets = [{ id: '', name: window.i18n.t('orders.open_order.all') }]
      rf.getRequest('OrderRequest')
        .getOptionFilterTradeHistory('currency')
        .then(res => {
          res?.data.map((item) => {
            this.markets.push({
              id: item,
              name: item.toUpperCase()
            })
          })
        })
      rf.getRequest('OrderRequest')
        .getOptionFilterTradeHistory('coin')
        .then(res => {
          this.coinMasters = [window.i18n.t('orders.open_order.all'), ...res?.data]
        })
    },
    handleClose() {
      this.dataDetail = null
      this.rightSlide = false
    },
    handleOpen(data) {
      console.log(data)
      this.dataDetail = data
      this.rightSlide = true
    },
    detailAccount(row) {
      const id = row.transaction_type == 'sell' ? row.buyer_id : row.seller_id;
      this.$router.push({
        path: '/account/detail',
        query: { id: id },
      });
    },
  },

  mounted() {
    this.$emit('EVENT_PAGE_CHANGE', this);
    // this.requestCoinList();
  },
  created() {
    this.getOptionData();
    // rf.getRequest('AdminRequest').getPriceGroupCurrency().then(res => {
    //   try {
    //     this.markets.push({ id: "all", name: window.i18n.t('orders.open_order.all') });
    //     window._.map(res.data, item => {
    //       if (typeof item.currency !== 'undefined') {
    //         this.markets.push({ id: item.currency, name: item.currency.toUpperCase() });
    //       }
    //     });
    //   }
    //   catch (ex) {
    //     console.error(ex);
    //   }
    // });
  }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.d-flex {
  display: flex;
}

.no-left-radius {
  input {
    border-radius: 0px 3px 3px 0px;
    margin-left: -1px;
  }
}

.no-right-radius {
  input {
    border-radius: 3px 0px 0px 3px;
  }
}

.modal-content .modal-footer {
  text-align: center;
  margin-top: 30px;
}

#trade_history {
  .sc_search_select {
    .group_search_select {
      .button_drop_search {
        height: 32px;
        padding: 6px 10px 6px 10px !important;
        border-radius: 10px;
        font-size: $font-small;
        font-family: $mulish-regular;
        background-color: $color-white;

        span {
          font-size: $font-small;
        }

        i.icon-arrow1 {
          margin-left: 5px;
          color: $color_dove_gray;
        }

        &:hover {
          border-color: $color-jungle-green;

          i.icon-arrow1 {
            color: $color-jungle-green;
          }
        }
      }

      .box_list_search_select {
        .list_search_select {
          border-radius: 10px;

          li {
            font-family: $font-family-roboto;
            font-size: $font-small;

            &:hover,
            &:active,
            &:focus {
              color: $color-jungle-green;
            }
          }
        }
      }
    }
  }
}
</style>

<style lang="scss" scoped>
@import "@/assets/sass/variables";

td.cl1,
td.cl2 {
  min-width: 115px;
}

td.cl3 {
  max-width: 250px;
}

td.cl4 {
  max-width: 250px;
}

td.cl5,
td.cl6 {
  min-width: 155px;
}

td.cl7 {
  min-width: 115px;
}

td.cl8,
td.cl9,
td.cl10 {
  min-width: 120px;
}

.item_email_user {
  display: inline-block;
  float: left;
  position: relative;
  width: 100%;

  .txt_email_user {
    display: block;
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .tooltip_email_user {
    position: absolute;
    top: 0px;
    left: 0px;
    line-height: 20px;
    padding: 5px 20px;
    left: 100%;
    background-color: $color_white;
    white-space: nowrap;
    width: auto;
    z-index: 10;
    font-size: $font_root;
    font-weight: 500;
    color: $color_mine_shaft;
    transition: 0.5s;
    display: none;
    box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.4);

    &:after {
      right: 100%;
      top: 50%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-color: rgba(136, 183, 213, 0);
      border-right-color: $color_white;
      border-width: 5px;
      margin-top: -5px;
    }
  }

  &:hover {
    .tooltip_email_user {
      display: block;
      transition: 0.5s;
    }
  }
}

.text-left {
  text-align: left;
}

.opacity-row {
  opacity: 0.4;
}

.trade-history {
  .filter-template {
    margin-right: 15px;
    margin-bottom: 30px;

    &:nth-child(3) {
      width: 180px;
    }

    .label-text {
      color: $dark-1;
      font-weight: 500;
      font-size: 14px;
      margin-bottom: 7px;
      margin-left: 5px;
      font-family: $mulish-regular;
    }

    .date-picker-input {
      display: inline-block;
      float: left;

      ::v-deep {
        input {
          padding: 0 5px 0px 36px;
          width: 135px !important;
          height: 32px;
          border: 1px solid #C7CBD3 !important;
          border-radius: 10px !important;
          background-image: url('@/assets/images/icon/date-time.svg') !important;
          background-color: $color-white !important;
          background-size: 12px !important;
          background-position: center left 12px !important;
          background-repeat: no-repeat !important;
          color: $dark-1;

          &:hover,
          &:active,
          &:focus {
            border-color: $color-jungle-green;
            z-index: 9;
          }
        }
      }

    }

    .btn-reset {
      background: $color-caribbean-green;
      border-radius: 3px;
      color: $color_white;
      font-weight: 700;
      width: 78px;
      height: 32px;
      margin-right: 10px;
      border-radius: 10px;

      img {
        margin-right: 4px;
        margin-top: -2px;
      }
    }

    .btn-search {
      margin: 0 10px;
    }

    .search_symbol {
      background: $color-white;
      border: 1px solid #C7CBD3 !important;
      border-radius: 10px !important;
      width: 197px;
      height: 32px;
    }

    .coin-selector {
      width: 75px;
      position: relative;

      .coin-input {
        display: inline-block;
        margin: 0 5px;
        margin-right: 0px;
        border-radius: 10px !important;
        background-color: $color-white;
        height: 32px;
        width: 100%;
      }

      .coin-option {
        background-color: $color-white;
        max-height: 210px;
        display: block;
        overflow-y: auto;
        margin-top: 5px;
        position: absolute;
        width: 79px;
        left: 5px;
        z-index: 10;
        padding: 12px 0px;
        box-shadow: 0px 3px 12px rgba(49, 49, 49, 0.51);
        border-radius: 10px;

        &:after {
          bottom: 100%;
          left: 50%;
          border: solid transparent;
          content: " ";
          display: block;
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
          border-color: rgba(136, 183, 213, 0);
          border-bottom-color: $color_white;
          border-width: 5px;
          margin-left: -5px;
        }

        .option {
          display: block;
          width: 100%;
          line-height: 20px;
          cursor: pointer;
          padding: 3px 14px;
          overflow: hidden;

          &:hover {
            color: $color-jungle-green;
          }
        }
      }
    }

    .form-control {
      background: $color-white-smoke;
      height: 32px;
      font-size: 13px;
      padding-left: 11px;
      padding-right: 0;
      border-radius: 10px !important;
    }

    .bw-element {
      float: left;
      font-size: 15px;
      margin-left: 10px;
      height: 32px;
      line-height: 32px;
      margin-right: 5px;
      color: $color-grey-dusty;
    }
  }

  .toolbar-element2 {
    display: inline-block;
    float: left;
    width: 80px;
  }

  .bw-element {
    float: left;
    font-size: $font_medium;
    margin-left: 5px;
    height: 30px;
    line-height: 30px;
    margin-right: 5px;
    color: $color-grey-dusty;
  }

  .datatable {
    button.btnForm.bt-action {
      padding-left: 5px !important;
      padding-right: 5px !important;
      font-weight: 400 !important;
    }

    table {
      thead {
        th {
          padding: 5px 0px 0px 15px !important;
          text-align: left;
          color: $color_grey;
          font-size: 13px;
          font-weight: 500;

          &.text-center {
            text-align: center;
          }

          &[data-sort-order="asc"] {
            color: #2dac91;
          }

          &[data-sort-order="desc"] {
            color: #2dac91;
          }

          &:nth-child(1) {
            width: 50px;
          }
        }

        tr {
          :nth-child(5) {
            width: 150px;
          }

          :nth-child(6) {
            width: 100px;
          }
        }
      }

      tbody {
        td:first-child {
          min-width: 50px;
        }

        td:nth-child(2) {
          min-width: 100px;

          .text-account {
            text-decoration: underline;
            color: $color-blue-custom;
            cursor: pointer;
          }
        }

        td:nth-child(3) {
          min-width: 100px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        td:nth-child(4) {
          min-width: 100px;
        }

        td:nth-child(5) {
          min-width: 150px;
        }

        td:nth-child(6) {
          min-width: 120px;
        }

        td:nth-child(7) {
          min-width: 120px;
        }

        td:nth-child(8) {
          min-width: 120px;
        }

        td:nth-child(9) {
          min-width: 150px;
        }

        td:nth-child(10) {
          min-width: 50px;
        }

        td {
          padding-top: 5px;

          &.type-buy {
            color: $color-jungle-green;
          }

          &.type-sell {
            color: $color_red_text;
          }

          .action-wrap {
            img {
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
</style>
