<template>
    <div class="notification_pages boxCore clearfix mg_bot50" id="notification_pages">
        <div class="filter-container">
            <!-- <div class="filter-item date">
                <div class="text-pair">{{ $t('notice.start_at') }}:</div>
                <date-picker v-model="startDate" class="date-picker-input no-right-radius"
                    :format="customFormatter"></date-picker>
            </div>
            <div class="filter-item date">
                <div class="text-pair">{{ $t('notice.end_at') }}:</div>
                <date-picker v-model="endDate" class="date-picker-input no-left-radius"
                    :format="customFormatter"></date-picker>
            </div> -->
            <div class="filter-item type">
                <div class="text-pair date-text">{{ $t('orders.open_order.type') }}</div>
                <div class="toolbar-element">
                    <select-box v-model="filters.type" :placeholder="$t('order.order_history.all')"
                        :options="typesOption" />
                </div>
            </div>
            <div class="filter-item">
                <input title="" type="text" :placeholder="$t('cms.notification.search_by')" @keyup.enter="search"
                    class="form-control" name="searchKey" v-model="filters.searchKey" />
            </div>
            <div class="filter-item">
                <button class="btn btn-reset" @click="search()">
                    {{ $t('notice.search') }}
                </button>
                <button class="btn btn-reset" @click="refresh()">
                    {{ $t('notice.reset') }}
                </button>
            </div>
            <div class="filter-item right">
                <router-link class="btn btn-reset" to="/notifications/create"><span class="icon-plus"></span>
                    {{ $t('cms.notification.new') }}</router-link>
            </div>
        </div>
        <div class="list-notice">
            <div class="datatable" style="width: 100%">
                <data-table :getData="getData" ref="list_notice" :column="9" class="scroll">
                    <th class="text-left width-title" data-sort-field="creation_time">
                        {{ $t('cms.notification.created_time') }}
                    </th>
                    <th class="text-left width-title" data-sort-field="id">
                        {{ $t('cms.notification.id') }}
                    </th>
                    <th class="text-left width-title" data-sort-field="type">
                        {{ $t('cms.notification.type') }}
                    </th>
                    <th class="text-left width-support" data-sort-field="language">
                        {{ $t('cms.notification.language') }}
                    </th>
                    <th class="text-left width-date" data-sort-field="title">
                        {{ $t('cms.notification.title') }}
                    </th>
                    <th class="text-left width-date" data-sort-field="message">
                        {{ $t('cms.notification.message') }}
                    </th>
                    <th class="text-left width-date">
                        {{ $t('cms.notification.image') }}
                    </th>
                    <th class="text-center width-date">
                        {{ $t('cms.notification.status') }}
                    </th>
                    <th class="text-center">
                        {{ $t('cms.notification.action') }}
                    </th>
                    <template slot="body" slot-scope="props">
                        <tr>
                            <td class="text-left width-title">
                                <div class="over-flow-text" :title="props.item.title">
                                    <div class="text-top">{{ props.item.creation_time | dateFormat2('YYYY-MM-DD') }}
                                    </div>
                                    <div class="text-bottom">{{ props.item.creation_time | timeFormat2('HH:mm:ss') }}
                                    </div>
                                </div>
                            </td>
                            <td class="text-left width-title">
                                <div class="over-flow-text" :title="props.item.title">
                                    {{ props.item.id }}
                                </div>
                            </td>
                            <td class="text-left width-title">
                                <div class="over-flow-text" :title="props.item.title">
                                    {{ props.item.type || "Announcement" }}
                                </div>
                            </td>
                            <td class="text-left width-title">
                                <div class="over-flow-text" :title="props.item.title">
                                    {{ props.item.language || "Vietnamese" }}
                                </div>
                            </td>
                            <td class="text-left width-title">
                                <div class="over-flow-text" :title="props.item.title">
                                    {{ props.item.title }}
                                </div>
                            </td>
                            <td class="text-left width-support">
                                <div class="over-flow-text" :title="props.item.support_url">
                                    {{ props.item.support_url }}
                                </div>
                            </td>
                            <td class="text-left width-date">
                                <div class="image-wrap">
                                    <img :src="IMAGE" />
                                </div>
                            </td>
                            <td class="text-left width-date">
                                <div class="switch-container">
                                    <label class="switch" @click="changeStatus">
                                        <input type="checkbox" :checked="props.item.status" :disabled="true">
                                        <span class="slider round"></span>
                                    </label>
                                </div>
                            </td>
                            <td class="text-center">
                                <img class="btn-icon" src="@/assets/images/icon/edit-icon.svg" width="20" @click="editNotice(props.item)" />
                            </td>
                        </tr>
                    </template>
                </data-table>
            </div>
            <modal class="confirm_modal" name="confirm_modal" :title="$t('account.modal.confirm')">
                <div v-if="dataNotice?.status == 'inactive'" slot="body" class="body-popup">
                    {{ $t('cms.notification.popup_content_close') }}
                </div>
                <div v-else slot="body" class="body-popup">
                    {{ $t('cms.notification.popup_content_open') }}
                </div>
            </modal>

            <modal class="confirm_modal" :enableClose="enableClose" name="loadingUpload">
                <div slot="body">
                    <vue-loading type="bars" v-if="isLoading" color="#2DAC91" :size="{ width: '60px', height: '80px' }">
                    </vue-loading>
                </div>
            </modal>
        </div>
    </div>
</template>

<script>
import rf from '../../../lib/RequestFactory';
import Modal from '../../../components/Modal';
import SelectBox from '../../../components/SelectBox.vue';
import DatePicker from 'vuejs-datepicker';
import moment from 'moment';

export default {
    components: {
        DatePicker,
        Modal,
        SelectBox
    },
    data() {
        return {
            titlePage: window.i18n.t('menu.cms.notification'),
            // startDate: moment().subtract(1, 'week').toDate(),
            // endDate: moment().toDate(),
            IMAGE: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSUdIe37t-Pej9f7MEf41fw4WB04jSaIfnD0Q&s',
            typesOption: [
                { id: '', name: window.i18n.t('cms.notification.type_all') },
                { id: 'system_message', name: window.i18n.t('cms.notification.type_system_message') },
                { id: 'event', name: window.i18n.t('cms.notification.type_event') },
                { id: 'campaign', name: window.i18n.t('cms.notification.type_campaign') },
                { id: 'new_listing', name: window.i18n.t('cms.notification.type_new_listing') },
                { id: 'copy_trade', name: window.i18n.t('cms.notification.type_copy_trade') },
                { id: 'announcement', name: window.i18n.t('cms.notification.type_announcement') },
            ],
            filters: {
                searchKey: '',
                type: null
            },
            isLoading: false,
            enableClose: false,
            dataNotice: false
        }

    },

    methods: {
        customFormatter(date) {
            return moment(date).format('YYYY - MM - DD')
        },
        search() {
            // let filterParams = this.getRangeDate()
            // if (this.startDate > this.endDate) {
            //     this.$toastr('error', this.$t('common.date.warning'))
            //     return
            // }
            if (this.filters.searchKey) {
                filterParams.search_key = this.filters.searchKey
            }
            if (this.filters.type) {
                filterParams.type = this.filters.type
            }
            this.$refs.list_notice.filter(filterParams)
        },

        refresh() {
            this.resetInput()
            this.search()
        },

        resetInput() {
            this.filters = Object.assign({ searchKey: '', type: null });
            // this.startDate = moment().subtract(1, 'week').toDate()
            // this.endDate = moment().toDate()
        },

        getData(params) {
            return rf
                .getRequest('AdminRequest')
                .getNotices(Object.assign(params))
        },

        // getRangeDate() {
        //     let defaultParams = {}
        //     if (this.startDate) {
        //         defaultParams.start_date = moment(this.startDate)
        //             .startOf('day')
        //             .format('x')
        //     }
        //     if (this.endDate) {
        //         defaultParams.end_date = moment(this.endDate).endOf('day').format('x')
        //     }
        //     return defaultParams
        // },

        editNotice(row) {
            this.$router.push({
                name: 'NotificationEdit',
                query: {
                    id: row ? row.id : null,
                },
            })
        },

        changeStatus(notification) {
            const params = {
                id: notification?.id,
                status: notification.status == 'open' ? 'close' : 'open'
            }
            this.dataNotice = notification;
            this.showModalConfirm('confirm_modal', this.changeStatusNotice, params);
        },
        changeStatusNotice(params) {
            // rf.getRequest('UserRequest').changeActiveStatusAccount(params).then(res => {
            //     if (res?.success) {
            //         this.$toastr('success', this.$t('account.account_detail.message_change_status_success'));
            //         this.getAccountDetail();
            //     } else {
            //         this.$toastr('error', this.$t('account.account_detail.message_change_status_fail'));
            //     }
            // })
            alert(params);
        },
        showModalConfirm(modalName, callback, params) {
            CommonModal.show(modalName, {
                position: 'center',
                mask: true,
                buttons: [
                    {
                        label: window.i18n.t('common.action.no'),
                        class: "btn-no",
                        style: {
                            background: 'rgb(191, 191, 191);'
                        },
                        callback: () => {
                            CommonModal.hide(modalName);
                        }
                    },
                    {
                        label: window.i18n.t('common.action.yes'),
                        focused: true,
                        style: {
                            background: 'rgb(0, 112, 192)'
                        },
                        callback: () => {
                            CommonModal.hide(modalName);
                            this.loadingUploadBegin();
                            callback(params);
                            this.loadingUploadFinish();
                            this.dataNotice = null;
                        }
                    }
                ]
            });
        },
        loadingUploadBegin() {
            this.enableClose = false;
            this.isLoading = true;
            CommonModal.show('loadingUpload');
        },
        loadingUploadFinish() {
            this.enableClose = true;
            this.isLoading = false;
            CommonModal.hide('loadingUpload');
        },
    },

    mounted() {
        this.$emit('EVENT_PAGE_CHANGE', this)
    },
}
</script>

<style lang="scss">
@import '@/assets/sass/variables';

#notification_pages {
    .sc_search_select {
        .group_search_select {
            .button_drop_search {
                height: 32px;
                padding: 6px 10px 6px 10px !important;
                border-radius: 10px;
                font-size: $font-small;
                font-family: $mulish-regular;
                background-color: $color-white;

                i.icon-arrow1 {
                    margin-left: 5px;
                    color: $color_dove_gray;
                }

                &:hover {
                    border-color: $color-jungle-green;

                    i.icon-arrow1 {
                        color: $color-jungle-green;
                    }
                }
            }

            .box_list_search_select {
                .list_search_select {
                    border-radius: 10px;

                    li {
                        font-family: $font-family-roboto;
                        font-size: $font-small;

                        &:hover,
                        &:active,
                        &:focus {
                            color: $color-jungle-green;
                        }
                    }
                }
            }
        }
    }

    .modal {
        .body-popup {
            font-family: $mulish-medium;

            p,
            strong {
                font-family: $mulish-medium;
            }
        }

        .btn-no {
            background-color: $color-bright-gray;
            border-color: $color-bright-gray;
            color: $color-black;

            &:hover {
                opacity: 0.7;
            }
        }
    }
}
</style>

<style lang="scss" scoped>
@import '@/assets/sass/variables';

.notification_pages {
    .filter-container {
        display: flex;
        gap: 16px;
        align-items: end;
        margin-bottom: 24px;

        .filter-item {
            &:last-child {
                margin-right: 0;
                margin-left: auto;
            }

            .text-pair {
                color: $dark-1;
                font-weight: 500;
                font-size: 14px;
                margin-bottom: 7px;
                margin-left: 5px;
                font-family: $mulish-regular;
            }

            .date-picker-input {
                display: inline-block;
                float: left;

                ::v-deep {
                    input {
                        padding: 0 5px 0px 36px;
                        width: 135px !important;
                        height: 32px;
                        border: 1px solid #C7CBD3 !important;
                        border-radius: 10px !important;
                        background-image: url('@/assets/images/icon/date-time.svg') !important;
                        background-color: $color-white !important;
                        background-size: 12px !important;
                        background-position: center left 12px !important;
                        background-repeat: no-repeat !important;
                        color: $dark-1;

                        &:hover,
                        &:active,
                        &:focus {
                            border-color: $color-jungle-green;
                            z-index: 9;
                        }
                    }
                }

            }

            .btn-reset {
                background: $color-caribbean-green;
                border-radius: 10px;
                color: $color_white;
                font-weight: 700;
                height: 32px;
                margin-left: 16px;

                .icon-plus {
                    color: $color-white;
                    margin-right: 8px;
                }
            }

            .form-control {
                background: $color-white;
                height: 32px;
                font-size: 13px;
                padding-left: 11px;
                padding-right: 0;
                border-radius: 10px !important;
            }

            .toolbar-element {
                width: 150px;
            }
        }
    }

    .datatable {
        table {
            thead {
                th {
                    &:last-child {
                        &::after {
                            display: none;
                        }
                    }

                    &:nth-child(8) {
                        &::after {
                            display: none;
                        }
                    }
                }
            }

            tbody {
                tr {
                    td:first-child {
                        min-width: 82px;
                    }

                    td:nth-child(2) {
                        min-width: 50px;
                    }

                    td:nth-child(3) {
                        min-width: 150px;
                    }

                    td:nth-child(4) {
                        min-width: 150px;
                    }

                    td:nth-child(5) {
                        min-width: 70px;
                    }

                    td:nth-child(6) {
                        min-width: 90px;
                    }

                    td:nth-child(7) {
                        min-width: 100px;
                    }

                    td:nth-child(8) {
                        min-width: 120px;
                        height: 70px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }

                    td:nth-child(9) {
                        min-width: 100px;
                    }

                    td {
                        // text-align: center;
                        padding: 5px 10px;

                        &.type-buy {
                            color: $color-jungle-green;
                        }

                        &.type-sell {
                            color: $color_red_text;
                        }

                        .btn-icon {
                            cursor: pointer;
                        }
                    }
                }
            }
        }

        .image-wrap {
            width: 80px;
            height: 50px;
            overflow: hidden;

            img {
                width: 100%;
                height: auto;
                object-fit: cover;
            }
        }
    }
}
</style>