import { render, staticRenderFns } from "./InputOnlyNumber.vue?vue&type=template&id=06b2d920&scoped=true"
import script from "./InputOnlyNumber.vue?vue&type=script&lang=js"
export * from "./InputOnlyNumber.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06b2d920",
  null
  
)

export default component.exports