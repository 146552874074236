import AdminRequest from '../requests/AdminRequest';
import ExchangeBalanceRequest from '../requests/ExchangeBalanceRequest'
import MasterdataRequest from '../requests/MasterdataRequest';
import UserRequest from '../requests/UserRequest';
import SettingRequest from '../requests/SettingRequest';
import FeeRequest from '../requests/FeeRequest';
import NoticeRequest from '../requests/NoticeRequest';
import NotificationRequest from '../requests/NotificationRequest';
import OrderRequest from '../requests/OrderRequest';
import CoinSettingRequest from '../requests/CoinSettingRequest';
import TrezorRequest from '../requests/TrezorRequest';
import WalletRequest from '../requests/WalletRequest';
import HotWalletRequest from '../requests/HotWalletRequest';
import TransactionRequest from '../requests/TransactionRequest';
import SalespointRequest from '../requests/SalespointRequest';
import ValidateRequest from '../requests/ValidateRequest';
import TakeProfitRequest from '../requests/TakeProfitRequest';
import MarginOrderRequest from '../requests/MarginOrderRequest';
import InstrumentRequest from "../requests/InstrumentRequest";
import LeaderboardRequest from '../requests/LeaderboardRequest';
import MappingInstrument from '../requests/MappingInstrument';
import BitmexTracedBotRequest from '../requests/BitmexTracedBotRequest';
import BitmexAccountRequest from "../requests/BitmexAccountRequest";
import BitmexMappingOrder from "../requests/BitmexMappingOrder";
import RewardCenterRequest from "@/requests/RewardCenter";
import HotSearchRequest from '../requests/HotSearchRequest'
// This request was added on August 8th by Mr Peter
import AccountRequest from "../requests/AccountRequest";
import MultiChainRequest from "../requests/MultiChainRequest";

const requestMap = {
    AdminRequest,
    ExchangeBalanceRequest,
    MasterdataRequest,
    UserRequest,
    SettingRequest,
    NoticeRequest,
    FeeRequest,
    NotificationRequest,
    OrderRequest,
    CoinSettingRequest,
    TrezorRequest,
    WalletRequest,
    HotWalletRequest,
    LeaderboardRequest,
    TakeProfitRequest,
    TransactionRequest,
    SalespointRequest,
    ValidateRequest,
    MarginOrderRequest,
    InstrumentRequest,
    MappingInstrument,
    LeaderboardRequest,
    BitmexTracedBotRequest,
    BitmexAccountRequest,
    BitmexMappingOrder,
    RewardCenterRequest,
    AccountRequest,
    HotSearchRequest,
    MultiChainRequest
};

const instances = {};

export default class RequestFactory {

    static getRequest(classname)
    {
        let RequestClass = requestMap[classname];
        if (!RequestClass) {
            throw new Error('Invalid request class name: ' + classname);
        }

        let requestInstance = instances[classname];
        if (!requestInstance) {
            requestInstance = new RequestClass();
            instances[classname] = requestInstance;
        }

        return requestInstance;
    }

}
